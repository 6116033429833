import FBEmitter from 'fbemitter';
import React, { PureComponent, RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { AssetStr, AttributionStr, DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackRisk } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { GridCellParams, GridOverlay } from '@mui/x-data-grid-pro';
import riskStoreInstance from './riskStore';
import { AggregateCube, CubeKeys, FilterCube, SortCube } from '../qwack/cubeUtils';
import _ from 'lodash';
import { TO_ResultCubeRow } from '../qwack/to_ResultCubeRow';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, ReferenceLine, Bar, Tooltip, ComposedChart } from 'recharts';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';

interface DeltaGammaViewState {
    resultCube: TO_ResultCube | undefined,
    mainRef: RefObject<HTMLDivElement>,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    selectedUnderlying: string | undefined,
    units: string,
    grouping: string,
}

export interface DeltaGammaViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const selectedMetric = "AssetGamma";
const MetricUnits = ["Native", "Currency"];
const AssetAttrib =`${AssetStr}-${AttributionStr}`;
const Groupings = ["Underlying", AssetStr, AssetAttrib, AttributionStr];
const GroupingsAllowingNative = ["Underlying"];

const AssetSortOrder = ["FX", "CM", "EQ", "FI"];

export class DeltaGammaView extends React.Component<DeltaGammaViewProps, DeltaGammaViewState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: DeltaGammaViewProps) {
        super(props)
        var asOf = props.getState("dg_asOf");
        this.state = {
            resultCube: undefined,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            rowExpanded: undefined,
            selectedSet: DefaultSetName,
            selectedUnderlying: undefined,
            units: MetricUnits[0],
            grouping: Groupings[0]
        };;
        this.updatePVs = this.updatePVs.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.controls = this.controls.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = riskStoreInstance.addChangeListener(this.updatePVs);
        this.updatePVs();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("dg_asOf", dStr);
        this.updatePVs(d);
        this.setState({ asOf: d, });
    }

    async calcPVs() {
        this.setState({ awaitingRefresh: true, resultCube: null });
        const { selectedSet, asOf } = this.state;
        await ComputeQwackRisk(asOf?.toDate() ?? new Date(), selectedMetric, selectedSet);

    }

    updatePVs(asOf?: Moment, set?: string) {
        if (!asOf)
            asOf = this.state.asOf;

        if (!set)
            set = this.state.selectedSet;

        let cube = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, selectedMetric)
        this.props.onChangeState("awaitingRefresh", "false");
        this.setState({ resultCube: cube, awaitingRefresh: false });
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }


    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toFixed(4)}</div>
        }
        else
            return <div></div>;
    }

    controls() {
        const { awaitingRefresh, asOf, selectedSet, resultCube, selectedUnderlying, units, grouping } = this.state;
        var availableUnderlyings = resultCube ? CubeKeys(resultCube, "Underlying") : new Array<string>();
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                        <Grid item container spacing={1} style={{ width: "90%" }}>
                            <Grid item>
                                <WrappedDatePicker
                                    key="dgDatePicker"
                                    style={{ width: "120px" }}
                                    value={asOf?.toDate()}
                                    onChange={this.onChangeDate}
                                    minDate={EarliestDate}
                                    emptyLabel="Live"
                                    clearLabel="Live"
                                    label={"As-Of"} />
                            </Grid>
                            <Grid item>
                                <MarketDataSetSelector
                                    id="set"
                                    name="set"
                                    label="Set"
                                    value={selectedSet}
                                    onChange={(set: string) => {
                                        this.props.onChangeState("selectedSet", set)
                                        this.props.onChangeState("selectedSet", set)
                                        this.updatePVs(null, set);
                                        this.setState({ selectedSet: set });
                                    }} />
                            </Grid>
                            <Grid item>
                                <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcPVs()}>Compute</Button>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "200px" }}
                                    id="underlying"
                                    name="underlying"
                                    label="Underlying"
                                    value={selectedUnderlying ?? "Underlying..."}
                                    onChange={(d) => {
                                        var ul = d.target.value as string;
                                        if (ul === "Underlying...")
                                            ul = null;
                                        this.props.onChangeState("selectedUnderlying", ul)
                                        this.setState({ selectedUnderlying: ul });
                                    }}>
                                    {["Underlying...", ...availableUnderlyings].map(a =>
                                        <MenuItem key={"ulx_" + a} value={a}>{a.replaceAll(" Underlying Index", "")}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "120px" }}
                                    id="Units"
                                    name="Units"
                                    label="Units"
                                    value={units}
                                    onChange={(d) => {
                                        var ul = d.target.value as string;
                                        this.props.onChangeState("units", ul)
                                        this.setState({ units: ul });
                                    }}>
                                    {MetricUnits.filter(m => GroupingsAllowingNative.includes(grouping) ? true : m !== "Native").map(a =>
                                        <MenuItem key={"unitz_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "140px" }}
                                    id="Grouping"
                                    name="Grouping"
                                    label="Grouping"
                                    value={grouping}
                                    onChange={(d) => {
                                        var ul = d.target.value as string;
                                        this.props.onChangeState("grouping", ul)
                                        if (GroupingsAllowingNative.includes(ul))
                                            this.setState({ grouping: ul });
                                        else
                                            this.setState({ grouping: ul, units: "Currency" });
                                    }}>
                                    {Groupings.map(a =>
                                        <MenuItem key={"groupingz_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    getGroupFilter(grouping: string) {
        switch (grouping) {
            case AssetAttrib:
                return [AssetStr, AttributionStr];
            default:
                return [grouping];
        }
    }

    getGroupLabel(grouping: string, cube: TO_ResultCube, rowIx: number) {
        switch (grouping) {
            case AssetAttrib:
                var assetIx = cube.fieldNames.indexOf(AssetStr);
                var attribIx = cube.fieldNames.indexOf(AttributionStr);
                var rowMeta = cube.rows[rowIx];
                return `${rowMeta.metaData[assetIx]}~${rowMeta.metaData[attribIx]}`;
            default:
                var groupingIx = cube.fieldNames.indexOf(grouping);
                var rowMeta2 = cube.rows[rowIx];
                return `${rowMeta2.metaData[groupingIx]}`;
        }
    }

    render() {
        const { resultCube, selectedUnderlying, units, grouping } = this.state;

        var inputCubeRaw = _.cloneDeep(resultCube);
        var ccyCube = _.cloneDeep(resultCube);
        if (ccyCube) {
            var refPriceIx = ccyCube?.fieldNames?.indexOf("RefPrice");
            ccyCube.rows.forEach(row => {
                var price = parseFloat(row.metaData[refPriceIx]);
                row.value *= price;
            });
        }

        var inputCube = units === "Currency" ? ccyCube : inputCubeRaw;

        var aggregatedDeltasByPoint: TO_ResultCube = {} as TO_ResultCube;
        var aggregatedGammasByPoint: TO_ResultCube = {} as TO_ResultCube;
        var aggregatedDeltasByGroup: TO_ResultCube = {} as TO_ResultCube;
        var aggregatedGammasByGroup = new Map<string, TO_ResultCubeRow>();

        var specialSortVectors = {  };
        specialSortVectors[AssetStr] = AssetSortOrder;

        var chartData = new Array<any>();

        if (inputCube) {
            const groupFilter = this.getGroupFilter(grouping);
            var filterMetric: { [field: string]: string } = {};
            filterMetric["Metric"] = "Delta";
            var filteredDelta = FilterCube(inputCube, filterMetric);
            aggregatedDeltasByGroup = AggregateCube(filteredDelta, groupFilter);
            aggregatedDeltasByGroup = SortCube(aggregatedDeltasByGroup, groupFilter, specialSortVectors);
            filterMetric["Metric"] = "Gamma";
            var filteredGamma = FilterCube(inputCube, filterMetric);
            var gammasByUl = AggregateCube(filteredGamma, groupFilter);
            gammasByUl.rows.forEach((row, ix) => {
                if (Math.abs(row.value) > 0.001) {
                    var key = this.getGroupLabel(grouping, gammasByUl, ix);
                    aggregatedGammasByGroup.set(key, row);
                }
            });

            var filter: { [field: string]: string } = {};
            var decomp = selectedUnderlying?.split("~");
            if (decomp) {
                for (var i = 0; i < decomp.length; i++) {
                    filter[groupFilter[i]] = decomp[i];
                }

            }

            var filtered = FilterCube(filteredDelta, filter);
            aggregatedDeltasByPoint = AggregateCube(filtered, ["PointLabel"]);
            filtered = FilterCube(filteredGamma, filter);
            aggregatedGammasByPoint = AggregateCube(filtered, ["PointLabel"]);

            filterMetric["Metric"] = "Delta";
            var filteredDeltaByCcy = FilterCube(ccyCube, filterMetric);
            var byAsset = AggregateCube(filteredDeltaByCcy, groupFilter);
            byAsset.rows.forEach((row, ix) => {
                var key = this.getGroupLabel(grouping, byAsset, ix).replaceAll(" Underlying Index", "");
                var s = { Asset: key, Delta: Math.round(row.value / 100000) / 10 }
                chartData.push(s);
            });

        }

        const formatOps = { maximumFractionDigits: 0 };
        var deltaColor = userStoreInstance.GetTheme().border_color;

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="DeltaGammaContainer">
                        <div className="RiskControls">{this.controls()}</div>
                        <div className="DeltaGammaLowerContainer">
                            <div className="DeltaGammaByUlTable">
                                <Typography variant="h6">By {grouping}</Typography>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead />
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="HeaderRow">{grouping}</TableCell>
                                                <TableCell className="HeaderRow RA">Delta</TableCell>
                                                <TableCell className="HeaderRow RA">Gamma</TableCell>
                                            </TableRow>
                                            {aggregatedDeltasByGroup?.rows?.map((a, ix) => {
                                                return a ? (
                                                    <TableRow key={`deltaGrpRow-${ix}`} hover onDoubleClick={() => this.setState({ selectedUnderlying: selectedUnderlying === this.getGroupLabel(grouping, aggregatedDeltasByGroup, ix) ? undefined : this.getGroupLabel(grouping, aggregatedDeltasByGroup, ix) })}>
                                                        <TableCell className={selectedUnderlying === a.metaData[0] ? "SelectedRow" : "NonSelectedRow"}>{this.getGroupLabel(grouping, aggregatedDeltasByGroup, ix).replaceAll(" Underlying Index", "")}</TableCell>
                                                        <TableCell className={selectedUnderlying === a.metaData[0] ? "SelectedRow RA" : "NonSelectedRow RA"}>{a.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                        <TableCell className={selectedUnderlying === a.metaData[0] ? "SelectedRow RA" : "NonSelectedRow RA"}>{aggregatedGammasByGroup.get(this.getGroupLabel(grouping, aggregatedDeltasByGroup, ix))?.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                    </TableRow>) : null;
                                            })}
                                            {aggregatedDeltasByGroup?.rows ? <TableRow key={"totalDelta"}>
                                                <TableCell className="HeaderRow">Total</TableCell>
                                                <TableCell className="HeaderRow">{_.sum(aggregatedDeltasByGroup.rows.map(r => r.value)).toLocaleString(undefined, formatOps)}</TableCell>
                                                <TableCell className="HeaderRow">{_.sum(Array.from(aggregatedGammasByGroup.values()).map(r => r.value)).toLocaleString(undefined, formatOps)}</TableCell>
                                            </TableRow> : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            {selectedUnderlying ? <div className="DeltaGammaPointTable">
                                <Typography variant="h6">{selectedUnderlying.replaceAll(" Underlying Index", "")}</Typography>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead />
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="HeaderRow">Label</TableCell>
                                                <TableCell className="HeaderRow">Delta</TableCell>
                                                <TableCell className="HeaderRow">Gamma</TableCell>
                                            </TableRow>
                                            {aggregatedDeltasByPoint?.rows?.map((a, ix) => {
                                                return a ? (
                                                    <TableRow key={a.metaData[0]} hover>
                                                        <TableCell className="NonSelectedRow">{isNaN(Number(a.metaData[0])) ? a.metaData[0] : listedInstrumentStoreInstance.getInstrumentById(Number(a.metaData[0])).description}</TableCell>
                                                        <TableCell className="NonSelectedRow">{a?.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                        <TableCell className="NonSelectedRow">{aggregatedGammasByPoint?.rows[ix]?.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                    </TableRow>) : null;
                                            })}
                                            {aggregatedDeltasByPoint?.rows ? <TableRow key={"totalDelta"}>
                                                <TableCell className="HeaderRow">Total</TableCell>
                                                <TableCell className="HeaderRow">{_.sum(aggregatedDeltasByPoint.rows.map(r => r.value)).toLocaleString(undefined, formatOps)}</TableCell>
                                                <TableCell className="HeaderRow">{_.sum(aggregatedGammasByPoint.rows.map(r => r.value)).toLocaleString(undefined, formatOps)}</TableCell>
                                            </TableRow> : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div> :
                                <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 200px)", width: "50%", textAlign: "center", paddingRight: "10px" }}>
                                    <Typography variant="h6">Net Delta by {grouping} (USD)</Typography>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart key="deltaChart" width={300} height={500} data={chartData} layout="vertical" >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <YAxis width={100} dataKey="Asset" type="category" tick={<CustomizedAxisTick />} stroke={userStoreInstance.GetTheme().color} />
                                            <XAxis type="number" unit="m" stroke={userStoreInstance.GetTheme().color} />
                                            <Tooltip />
                                            <ReferenceLine x={0} stroke="#000" />
                                            <Bar dataKey="Delta" fill={deltaColor} />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            }
                        </div>
                    </div></ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

class CustomizedAxisTick extends PureComponent<any, {}> {
    render() {
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy="0.25em" dx={-5} width={30} textAnchor="end" fill={userStoreInstance.GetTheme().border_color} overflow="elipsis" style={{ fontSize: "small" }}>
                    {payload.value.toString().slice(0, 12)}
                </text>
            </g>
        );
    }
}

