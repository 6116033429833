import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { MainLayout, LayoutTab, TabTemplate, LayoutComponentWithTab, NewTabRequest } from './layoutModels';
import { get, post, put } from '../utils/httpUtils';
import { v4 } from 'uuid';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';

export class LoadLayoutEvent extends TypedEvent<MainLayout> { }
export class LoadLayoutFailedEvent extends TypedEvent<string> { }
export class LoadTemplatesEvent extends TypedEvent<TabTemplate[]> { }
export class LoadTemplatesFailedEvent extends TypedEvent<string> { }
export class AddLayoutObjectEvent extends TypedEvent<LayoutComponentWithTab> { }
export class UpdateLayoutObjectEvent extends TypedEvent<LayoutComponentWithTab> { }
export class AddTabEvent extends TypedEvent<NewTabRequest> { }
export class DeleteTabEvent extends TypedEvent<number> { }
export class PreferenceUpdateEvent extends TypedEvent<string[]> { }
export class MenuCollapseEvent extends TypedEvent<{collapsed: boolean, tabId: number}> { }

export async function LoadLayout() {
    const layout = await get<MainLayout>("Layout");
    if (layout.payload == null) {
        AppDispatcher.dispatch(new LoadLayoutFailedEvent("Failed to load layout from server"));
        UpdateLoadingStatus("Layout", LoadingStatus.Failed);
    } else {
        AppDispatcher.dispatch(new LoadLayoutEvent(layout.payload));
        UpdateLoadingStatus("Layout", LoadingStatus.Completed);
    }
}

export function UpdateLayout(layout: MainLayout) {
    AppDispatcher.dispatch(new LoadLayoutEvent(layout));
}

export function SetMenuCollapseFn(tabId: number, collapsed: boolean){
    AppDispatcher.dispatch(new MenuCollapseEvent({collapsed, tabId}));
}

export async function LoadTemplates() {
    const templates = await get<TabTemplate[]>('Layout/getUserTemplates');
    if (templates.payload == null) {
        AppDispatcher.dispatch(new LoadLayoutFailedEvent("Failed to load templates from server"));
        UpdateLoadingStatus("LayoutTemplates", LoadingStatus.Failed);
    } else {
        AppDispatcher.dispatch(new LoadTemplatesEvent(templates.payload));
        UpdateLoadingStatus("LayoutTemplates", LoadingStatus.Completed);
    }
}

export function UpdatePreference(key: string, value: string) {
    AppDispatcher.dispatch(new PreferenceUpdateEvent([key, value]));
}

export async function SaveLayout(layout: MainLayout) {
    var success = await post<boolean>("Layout", layout);
    if (!success)
        console.log("Failure to save layout");
}

export async function AddLayoutObject(tabId: number | undefined, objectType: string, props: any) {
    AddLayoutObjectWithKey(v4(), tabId, objectType, props);
}

export async function AddLayoutObjectWithKey(key: string, tabId: number | undefined, objectType: string, props: any) {
    AppDispatcher.dispatch(new AddLayoutObjectEvent({ tabId: tabId, component: { tabId: tabId, key: key, type: objectType, props: props===undefined?null:props, state: null, title: null } }));
}

export async function UpdateLayoutObjectProps(key: string, tabId: number, props: any) {
    AppDispatcher.dispatch(new UpdateLayoutObjectEvent({ tabId: tabId, component: { tabId: tabId, key: key, props: props===undefined?null:props, state: null, title: null, type: null } }));
}

export async function SaveTemplate(templateName: string, template: LayoutTab) {
    await put<boolean>(`Layout/saveUserTemplate/${templateName}`, template);
    await LoadTemplates();
}

export function AddTab(tabName: string, isFlexibleTab: boolean) {
    AppDispatcher.dispatch(new AddTabEvent({ tabName, isFlexibleTab }));
}
export function DeleteTab(tabId: number) {
    AppDispatcher.dispatch(new DeleteTabEvent(tabId));
}