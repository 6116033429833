export enum OptionStructureType { 
    Generic=0,
    Fence=1,
    Seagul=2,
    CallSpread=3,
    PutSpread=4,
    SingleOption=5,
    Straddle=6,
    Strangle=7
}
