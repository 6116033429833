import FBEmitter from 'fbemitter';
import React, { PureComponent, RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackGammaLadder } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { GridCellParams, GridOverlay } from '@mui/x-data-grid-pro';
import riskStoreInstance from './riskStore';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { CubeKeys, CubeToMatrix, FilterCube } from '../qwack/cubeUtils';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Legend, ReferenceLine, Line } from 'recharts';
import _ from 'lodash';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface GammaLadderViewState {
    resultCube: TO_ResultCube | undefined,
    mainRef: RefObject<HTMLDivElement>,
    nSteps: number | undefined,
    stepSize: number | undefined,
    assetId: number | undefined,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    units: string,
    selectedMetric: string
}

export interface GammaLadderViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const MetricUnits = ["Currency"];

const Metrics = ["Delta", "PV"];

export class GammaLadderView extends React.Component<GammaLadderViewProps, GammaLadderViewState>{
    eventSubscriptionRisk: FBEmitter.EventSubscription | undefined;
    constructor(props: GammaLadderViewProps) {
        super(props)
        var asOf = props.getState("gl_asOf");
        var nSteps = props.getState("gl_nSteps");
        var stepSize = props.getState("gl_stepSize");
        var assetId = props.getState("gl_assetId");
        this.state = {
            resultCube: undefined,
            nSteps: nSteps ? Number(nSteps) : undefined,
            stepSize: stepSize ? Number(stepSize) : undefined,
            assetId: assetId ? Number(assetId) : undefined,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            rowExpanded: undefined,
            selectedSet: DefaultSetName,
            units: MetricUnits[0],
            selectedMetric: Metrics[0]
        };;
        this.updateLadder = this.updateLadder.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.controls = this.controls.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionRisk = riskStoreInstance.addChangeListener(this.updateLadder);
        this.updateLadder();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionRisk) {
            this.eventSubscriptionRisk.remove();
            this.eventSubscriptionRisk = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("gl_asOf", dStr);
        this.updateLadder(d);
        this.setState({ asOf: d, });
    }

    async calcLadder() {
        this.setState({ awaitingRefresh: true, resultCube: null });
        const { selectedSet, asOf, stepSize, nSteps, assetId, selectedMetric } = this.state;
        var metricStr = selectedMetric === "Delta" ? "QwackGamma" : "QwackPvMatrix";
        await ComputeQwackGammaLadder(asOf.toDate(), assetId.toString(), stepSize, nSteps, selectedSet, metricStr);
    }

    updateLadder(asOf?: Moment, set?: string, assetId?: number) {
        if (!asOf)
            asOf = this.state.asOf;

        if (!set)
            set = this.state.selectedSet;

        if (!assetId)
            assetId = this.state.assetId;

        var metricStr = this.state.selectedMetric === "Delta" ? "QwackGamma" : "QwackPvMatrix";

        let cube0 = riskStoreInstance.getQwackLadder(asOf?.toDate(), assetId, set, metricStr);
        var refreshDone = (!this.state.awaitingRefresh) || cube0;
        this.setState({ resultCube: cube0, awaitingRefresh: !refreshDone });
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }


    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toFixed(4)}</div>
        }
        else
            return <div></div>;
    }

    controls() {
        const { awaitingRefresh, asOf, selectedSet, assetId, stepSize, nSteps, selectedMetric } = this.state;
        var availableInsIds = listedInstrumentStoreInstance.getInstruments().filter(l => l?.type === "Index").map(l => l.listedInstrumentId);
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                        <Grid item container spacing={1} style={{ width: "90%" }}>
                            <Grid item>
                                <WrappedDatePicker
                                    style={{ width: "120px" }}
                                    value={asOf?.toDate()}
                                    onChange={this.onChangeDate}
                                    minDate={EarliestDate}
                                    emptyLabel="Live"
                                    clearLabel="Live"
                                    label="As-Of" />
                            </Grid>
                            <Grid item>
                                <MarketDataSetSelector
                                    id="set"
                                    name="set"
                                    label="Set"
                                    value={selectedSet}
                                    onChange={(set: string) => {
                                        this.props.onChangeState("selectedSet", set)
                                        this.props.onChangeState("selectedSet", set)
                                        this.updateLadder(null, set);
                                        this.setState({ selectedSet: set });
                                    }} />
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "100px" }}
                                    id="metric"
                                    name="metric"
                                    label="Metric"
                                    value={selectedMetric}
                                    onChange={(m) => {
                                        var metric = m.target.value;
                                        this.props.onChangeState("gl_selectedMetric", metric)
                                        this.setState({ selectedMetric: metric });
                                    }}>
                                    {Metrics.map(a =>
                                        <MenuItem key={"mex_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "200px" }}
                                    id="underlying"
                                    name="underlying"
                                    label="Underlying"
                                    value={assetId}
                                    onChange={(d) => {
                                        var ul = d.target.value as number;
                                        this.props.onChangeState("gl_assetId", ul.toString())
                                        this.setState({ assetId: ul });
                                    }}>
                                    {availableInsIds.map(a =>
                                        <MenuItem key={"ulx_" + a} value={a}>{listedInstrumentStoreInstance.getInstrumentById(a)?.description?.replaceAll(" Underlying Index", "")}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "120px", height: "30px" }}
                                    label={"Step Size"}
                                    value={stepSize}
                                    placeholder="Step Size"
                                    onChange={(e) => {
                                        var stepS = parseFloat(e.target.value);
                                        this.props.onChangeState("gl_stepSize", stepS.toString())
                                        this.setState({ stepSize: stepS });
                                    }} />
                            </Grid>
                            <Grid item >
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "120px", height: "30px" }}
                                    label={"# Steps"}
                                    value={nSteps}
                                    placeholder="# Steps"
                                    onChange={(e) => {
                                        var stepS = parseInt(e.target.value);
                                        this.props.onChangeState("gl_nSteps", stepS.toString())
                                        this.setState({ nSteps: stepS });
                                    }} />
                            </Grid>
                            <Grid item>
                                <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcLadder()}>Compute</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    formatNumberVal(val: any) {
        if (!val)
            return null;
        const valAsNum = Number(val);
        if (!isNaN(valAsNum)) {
            const formatOps = { maximumFractionDigits: 0 };

            return Math.abs(valAsNum) < 0.1 ? 0 : valAsNum.toLocaleString(undefined, formatOps);
        }

        var valAsString = val as string;
        if (valAsString)
            return valAsString.includes("~") ? valAsString.split("~")[1] : valAsString

        return val;
    }


    render() {
        const { resultCube, selectedMetric } = this.state;
        var metricCol = resultCube?.fieldNames?.indexOf("Metric");
        var metric = resultCube ? resultCube.rows[0].metaData[metricCol] ?? "PV" :  selectedMetric;
        var gridTitle = metric === "PV" ? "PV Ladder" : "Gamma Ladder"
        var chartTitle = metric === "PV" ? "Change in PV" : "Change in Δ"
        let matrix = new Array<Array<any>>();
        let chartData = new Array<any>();
        let pointLabels = new Array<string>();
        if (resultCube) {
            matrix = CubeToMatrix(resultCube, "Scenario", "PointLabel");
            pointLabels = CubeKeys(resultCube, "PointLabel");
            var scenarios = CubeKeys(resultCube, "Scenario");
            var totalRow = new Array<any>();
            totalRow.push("Total");
            for (var i = 0; i < scenarios.length; i++) {
                var s = { scenario: scenarios[i] };
                for (var p = 0; p < pointLabels.length; p++) {
                    s[pointLabels[p]] = _.sum(FilterCube(resultCube, { Scenario: scenarios[i], PointLabel: pointLabels[p] }).rows.map(r => r.value));
                }
                s[chartTitle] = _.sum(FilterCube(resultCube, { Scenario: scenarios[i] }).rows.map(r => r.value));
                chartData.push(s);
                totalRow.push(s[chartTitle]);
            }
            matrix.push(totalRow);
        }

        var lineChartColor = userStoreInstance.GetTheme().border_color;
        
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="DeltaGammaContainer">
                        <div className="RiskControls">{this.controls()}</div>
                        <div className="GammaLadderLowerContainer">
                            <div className="GammaLadder">
                                <Typography variant="h6">{gridTitle}</Typography>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead />
                                        <TableBody>
                                            {matrix.map((r, ixr) => {
                                                return <TableRow>
                                                    {r.map((c, ixc) => <TableCell align="center" className={ixr === 0 || ixc === 0 ? "HeaderRow" : "CenteredCell"}>{this.formatNumberVal(c)}</TableCell>)}
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="GammaLadderChart">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart key="gammaChart" width={500} height={400} data={chartData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="scenario" type="category" tick={<CustomizedAxisTick />} stroke={userStoreInstance.GetTheme().border_color} />
                                        <YAxis stroke={userStoreInstance.GetTheme().border_color} format="###,###" />
                                        <Legend verticalAlign="top" />
                                        <ReferenceLine y={0} stroke={userStoreInstance.GetTheme().border_color} />
                                        <Line key={chartTitle} dataKey={chartTitle} name={chartTitle} color={lineChartColor} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div></ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

class CustomizedAxisTick extends PureComponent<any, {}> {
    render() {
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill={userStoreInstance.GetTheme().border_color}>
                    {payload.value.toString().split("~")[1]}
                </text>
            </g>
        );
    }
}
