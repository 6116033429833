import { GoodUntil, OrderType } from "../orders/orderModels";
import { Firm } from "../user/userModel";

export interface Price {
    stamp: Date,
    bid: number,
    ask: number,
    rfqId: number,
    ttlMs: number,
    userId: number,
    priceId: number,
    uniqueKey: string,
}

export interface RfqSummary {
    rfqId: number,
    parentRfq: number,
    childRfq: number,
    state: string,
    description: string,
    payoffType: string,
    productId: number,
    lastUpdated: Date,
    created: Date,
    userIdUpdatedBy: number,
    userIdCreatedBy: number,
    userUpdatedBy: string,
    userCreatedBy: string,
    requestingFirm: Firm,
    latestPrice: Price,

    metaData: { [key: string]: string; };
    orderType: OrderType;
    limitLevel: number;
    stopLevel: number;
    adjustmentPrice: number;
    fixingIndex: number;
    goodUntil: Date;
    timeInForce: GoodUntil;
    filledSize: number;
    size: number;

}

export interface RfqSummaryState {
    rfqs: { [rfq: number]: RfqSummary };
}

export interface RfqStateChangeRecord {
    eventId: number;
    rfqId: number;
    userId: number;
    stampUtc: Date;
    event: string;
    comment: string;
    eventBlob: string;
}

export interface RfqAuditStoreState {
    logs: { [rfqId: number]: RfqStateChangeRecord[] };
}

export interface TradeAttempt{
    tradeSide: TradeSide,
    price: Price
}

export enum TradeSide{
    Buy,
    Sell
}