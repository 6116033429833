import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import { RfqAuditUpdatedEvent } from "./rfqActions";
import { RfqAuditStoreState, RfqStateChangeRecord } from "./rfqModels";

class rfqAuditStore extends FluxStore<RfqAuditStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatcherHandler(e), () => ({
            logs: {}
        }))
    }

    private onDispatcherHandler(action: Event) {
        if (action instanceof RfqAuditUpdatedEvent) {
            if (!this.state.logs[action.payload.rfqId])
                this.state.logs[action.payload.rfqId] = new Array<RfqStateChangeRecord>();
            if (!this.state.logs[action.payload.rfqId].some(l => l.eventId === action.payload.eventId)) {
                this.state.logs[action.payload.rfqId].push(action.payload);
                this.emitChange();
            }
        }
    }

    public GetLogs(rfqId: number) {
        return this.state.logs[rfqId];
    }
}

const rfqAuditStoreInstance = new rfqAuditStore(AppDispatcher);
export default rfqAuditStoreInstance;