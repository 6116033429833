import React from 'react';
import FBEmitter from 'fbemitter';
import { ProductSummary } from '../../product/productModels';
import { RefreshRfqSummaries } from '../rfqActions';
import { RfqAuditLogViewer } from './rfqAuditLogViewer';
import RfqDashboardMainDisplayBox from './rfqDashboardMainDisplayBox';
import { RfqSummaryDisplay } from './rfqSummary';
import { RfqSummary } from '../rfqModels';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import rfqSummaryStoreInstance from '../rfqSummaryStore';
import StructuredProductDesigner from '../../homepage/productDesigner/structuredProductDesigner';
import productStoreInstance from '../../product/productStore';
import { LoadProductSummary } from '../../product/productActions';
import { RfqControls } from './rfqControls';
import rfqConnection from '../rfqConnection';
import { renderDate } from '../../utils/helpers';
import { CloneProduct } from '../../homepage/productDesigner/productCloning/productCloneActions';
import { PriceProvider } from './priceProvider';
import Executor from './executor';
import { Button, ButtonBase, Dialog, Divider, Grid, Typography } from '@mui/material';
import {ThemeProvider} from '@mui/material/styles'
import { CheckOutlined, RedoOutlined } from '@mui/icons-material';
import userStoreInstance from '../../user/userStore';
import { getFormTheme } from '../../inputs/formCommon';

type RfqDashboardProps = {

}

export interface UpdateSpeed {
    name: string,
    delay: number
}

//const updateSpeeds: UpdateSpeed[] = [{ name: "Slow", delay: 5000 }, { name: "Fast", delay: 500 }, { name: "Turbo", delay: 250 }, { name: "Paused", delay: 999999999 }];

const NullSelecString = "Select..."

type RfqDashboardState = {
    allQuotes: RfqSummary[],
    awaitingForApproval: RfqSummary[],
    productSummaries: ProductSummary[],
    selectedRfq: RfqSummary | undefined,
    availableStatuses: string[],
    selectedStatus: string | undefined,
    availablePayoffs: string[],
    selectedPayoff: string | undefined,
    availableDates: string[],
    selectedDate: string | undefined,
    showAreYouSureCancel: boolean;
    firstRender: boolean;
}

export class RfqDashboard extends React.Component<RfqDashboardProps, RfqDashboardState>{
    eventSubscriptionRfqs: FBEmitter.EventSubscription | undefined;
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;

    constructor(props: RfqDashboard) {
        super(props)
        this.state = {
            allQuotes: [],
            awaitingForApproval: [],
            productSummaries: [],
            selectedRfq: undefined,
            availablePayoffs: [NullSelecString],
            availableStatuses: [NullSelecString],
            availableDates: [NullSelecString],
            selectedPayoff: NullSelecString,
            selectedStatus: NullSelecString,
            selectedDate: new Date().toDateString(),
            showAreYouSureCancel: false,
            firstRender: true
        };

        this.onAbandonCancelRfq = this.onAbandonCancelRfq.bind(this);
        this.onCancelRfq = this.onCancelRfq.bind(this);
        this.onStartCancelRfq = this.onStartCancelRfq.bind(this);
        this.onStartRefreshRfq = this.onStartRefreshRfq.bind(this);
        this.onCloneRfq = this.onCloneRfq.bind(this);
        this.onPickupRfq = this.onPickupRfq.bind(this);
        this.onDropRfq = this.onDropRfq.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionRfqs = rfqSummaryStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionProducts = productStoreInstance.addChangeListener(() => this.onProductUpdate());
        await RefreshRfqSummaries();

        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionRfqs) {
            this.eventSubscriptionRfqs.remove();
            this.eventSubscriptionRfqs = undefined;
        }
        if (this.eventSubscriptionProducts) {
            this.eventSubscriptionProducts.remove();
            this.eventSubscriptionProducts = undefined;
        }
    }

    onProductUpdate() {
        var products = productStoreInstance.getAllProducts();
        this.setState({ productSummaries: products });
    }

    onChange() {
        var allSummaries = rfqSummaryStoreInstance.GetAllRfqs();
        var allStatuses = new Set(allSummaries.map(x => x.state));
        var allPayoffs = new Set(allSummaries.map(x => x.payoffType));
        var allDates = new Set(allSummaries.map(x => new Date(x.created).toDateString()));

        var availableDates = [NullSelecString, ...allDates];
        var selectedDateClean = availableDates.includes(this.state.selectedDate) ? this.state.selectedDate : availableDates[availableDates.length - 1];
        var selectedRfq = rfqSummaryStoreInstance.GetRfq(this.state.selectedRfq?.rfqId);

        this.setState(
            {
                allQuotes: allSummaries,
                availablePayoffs: [NullSelecString, ...allPayoffs],
                availableStatuses: [NullSelecString, ...allStatuses],
                availableDates: availableDates,
                selectedDate: selectedDateClean,
                selectedRfq: selectedRfq
            });
    }

    renderRfqSummary = (rfq: RfqSummary) => {
        var isSelected = rfq === this.state.selectedRfq;
        return (
            <div key={`button-div-${rfq.rfqId}`} className={isSelected ? "RfqDashboardLeftBarButton Selected" : "RfqDashboardLeftBarButton"}>
                <ButtonBase key={`button-${rfq.rfqId}`} onClick={() => this.setState({ selectedRfq: rfq })} style={{ height: "100%", width: "100%" }}>
                    <div className="RfqDashboardLeftBarButtonInner">
                        <div className={isSelected ? "RfqDashboardLeftBarButtonRfqId Selected" : "RfqDashboardLeftBarButtonRfqId"}>{rfq.rfqId}</div>
                        <div className={isSelected ? "RfqDashboardLeftBarButtonStatus Selected" : "RfqDashboardLeftBarButtonStatus"}>{rfq.state}</div>
                        <div className={isSelected ? "RfqDashboardLeftBarButtonStamp Selected" : "RfqDashboardLeftBarButtonStamp"}>{renderDate(rfq.lastUpdated)}</div>
                        <div className="RfqDashboardLeftBarButtonMain"><Typography variant="subtitle1">{rfq.description}</Typography></div>
                    </div>
                </ButtonBase>
            </div>
        );
    }

    renderPayoffSelect = () => {
        return (<StringTypeDropDown
            key="payoffSelect"
            label="Payoff"
            disabled={false}
            possibles={this.state.availablePayoffs}
            selected={this.state.selectedPayoff}
            onChange={this.onPayoffChange} />
        );
    }
    renderStatusSelect = () => {
        return (<StringTypeDropDown
            key="statusSelect"
            label="Status"
            disabled={false}
            possibles={this.state.availableStatuses}
            selected={this.state.selectedStatus}
            onChange={this.onStatusChange} />);
    }
    renderDateSelect = () => {
        const { selectedDate, availableDates } = this.state;
        var selectedDateClean = availableDates.includes(selectedDate) ? selectedDate : availableDates[availableDates.length - 1];
        return (<StringTypeDropDown
            key="DateSelect"
            label="Date"
            disabled={false}
            possibles={availableDates}
            selected={selectedDateClean}
            onChange={this.onDateChange} />);
    }

    onPayoffChange = (itemName: string) => {
        this.setState({ selectedPayoff: itemName });
    };
    onStatusChange = (itemName: string) => {
        this.setState({ selectedStatus: itemName });
    };
    onDateChange = (itemName: string) => {
        this.setState({ selectedDate: itemName });
    };

    onClickClientFilter = () => {
    }

    async onCancelRfq() {
        this.setState({ showAreYouSureCancel: false });
        await rfqConnection.cancelRfq(this.state.selectedRfq.rfqId);
    }
    onAbandonCancelRfq = () => {
        this.setState({ showAreYouSureCancel: false });
    }
    onStartCancelRfq = () => {
        this.setState({ showAreYouSureCancel: true });
    }
    async onPickupRfq() {
        await rfqConnection.pickUpRfq(this.state.selectedRfq.rfqId);
    }
    async onDropRfq() {
        await rfqConnection.dropRfq(this.state.selectedRfq.rfqId);
    }

    async onStartRefreshRfq() {
        await rfqConnection.refreshRfq(this.state.selectedRfq.rfqId);
    }
    async onCloneRfq() {
        var product = await LoadProductSummary(this.state.selectedRfq.productId);
        CloneProduct(product.spec);
    }

    render() {
        const { productSummaries, selectedRfq, selectedPayoff, selectedStatus, showAreYouSureCancel, selectedDate } = this.state;
        let allSummaries = this.state.allQuotes.sort((a, b) => b.rfqId - a.rfqId);
        if (selectedPayoff && selectedPayoff !== NullSelecString)
            allSummaries = allSummaries.filter(q => q.payoffType === this.state.selectedPayoff);
        if (selectedStatus && selectedStatus !== NullSelecString)
            allSummaries = allSummaries.filter(q => q.state === this.state.selectedStatus);
        if (selectedDate && selectedDate !== NullSelecString)
            allSummaries = allSummaries.filter(q => new Date(q.created).toDateString() === selectedDate);

        let selectedProductSummary = productSummaries.filter(p => p && p.productId === selectedRfq?.productId)[0];
        if (!selectedProductSummary && selectedRfq) {
            LoadProductSummary(selectedRfq.productId)
        }
        let isInternalLeg = selectedRfq && !(selectedRfq.parentRfq === null || selectedRfq.parentRfq === undefined);
        let isPriced = selectedRfq && (selectedRfq.state === "PriceToClient" || selectedRfq.state === "TradeAttemptInProgress");
        return (<ThemeProvider theme={getFormTheme()}>
            <Grid container direction="column" style={{ height: "calc(100vh - 100px)" }}>
                <Grid item>
                    <Dialog open={showAreYouSureCancel} onClose={this.onAbandonCancelRfq}>
                        <div className="RfqDashboardCancelConfirm">
                            <Typography variant="h6" className="RfqDashboardCancelConfirmText">Are you sure you want to cancel?</Typography>
                            <div className="RfqDashboardCancelConfirmButtons">
                                <Button className="MuiButton-outlined PltfmButtonLite" value="I'm Sure" onClick={this.onCancelRfq}>I'm Sure <CheckOutlined /></Button>
                                <Button className="MuiButton-outlined PltfmButtonLite" value="Go Back" onClick={this.onAbandonCancelRfq}>Go Back <RedoOutlined /></Button>
                            </div>
                        </div>
                    </Dialog>
                </Grid>
                <Grid item container spacing={1} width="xl" paddingLeft="12px"  paddingTop= "12px" paddingBottom="7px" justifyContent="flex-start" borderBottom="1px solid" borderColor={userStoreInstance.GetTheme().border_color} >
                    <Grid item>{this.renderPayoffSelect()}</Grid>
                    <Grid item><Divider orientation="vertical" flexItem={true} /></Grid>
                    <Grid item>{this.renderStatusSelect()}</Grid>
                    <Grid item><Divider orientation="vertical" flexItem={true} /></Grid>
                    <Grid item>{this.renderDateSelect()}</Grid>
                </Grid>
                <Grid item container direction="row" width="100%" height="calc(100vh - 160px)" >
                    <Grid item height="100%" width="300px" >
                        <div className="RfqDashboardLeftBar">
                            {allSummaries.map(q => this.renderRfqSummary(q))}
                        </div>
                    </Grid>
                    {this.state.selectedRfq ? <Grid item container direction="column" width="calc(100% - 305px)" height="calc(100vh - 160px)">
                        <Grid item>
                            <RfqDashboardMainDisplayBox title="Controls" width={400} content={<RfqControls
                                key={"ctrl" + selectedRfq.rfqId}
                                selectedRfq={selectedRfq}
                                onCancel={this.onStartCancelRfq}
                                onRefresh={this.onStartRefreshRfq}
                                onClone={this.onCloneRfq}
                                onPickup={this.onPickupRfq}
                                onDrop={this.onDropRfq} />} />
                        </Grid>
                        <Grid item>
                            <RfqDashboardMainDisplayBox title="Summary" width={400} content={<RfqSummaryDisplay
                                key={"summ" + selectedRfq.rfqId}
                                selectedRfq={selectedRfq} />} />
                        </Grid>
                        <Grid item>
                            <RfqDashboardMainDisplayBox key={"AuditBox" + selectedRfq.rfqId} title="Audit"  width={400} content={<RfqAuditLogViewer key={"AuditComp" + selectedRfq.rfqId} rfqId={selectedRfq.rfqId} />} />
                        </Grid>
                        {!selectedProductSummary ? null :
                            <Grid item>
                                <RfqDashboardMainDisplayBox width={650} key={"detaill" + this.state.selectedRfq.rfqId} title="Product" content={<StructuredProductDesigner
                                    key={"detail" + this.state.selectedRfq.rfqId}
                                    counterpartyId=""
                                    clientFirm={this.state.selectedRfq.requestingFirm}
                                    disabled={true}
                                    existingSpec={selectedProductSummary?.spec}
                                    existingRfqSummary={selectedRfq}
                                    dashboardMode={true} />} />
                            </Grid>}
                        {!isInternalLeg && isPriced ?
                            <Grid item>
                                <RfqDashboardMainDisplayBox width={400} key={"exxDash" + this.state.selectedRfq.rfqId} title="Executor" content={<Executor
                                    key={"exDash" + this.state.selectedRfq.rfqId}
                                    onClose={null}
                                    uniqueKey={"exDash" + this.state.selectedRfq.rfqId}
                                    productId={this.state.selectedRfq.productId}
                                    rfqId={this.state.selectedRfq.rfqId}                                    
                                    size={0} />} />
                            </Grid>
                            : null}
                        {isInternalLeg ?
                            <Grid item>
                                <RfqDashboardMainDisplayBox width={400} title="Price" content={<PriceProvider
                                    key={"price" + this.state.selectedRfq.rfqId}
                                    selectedRfq={this.state.selectedRfq} />} />
                            </Grid>
                            : null}
                    </Grid>
                        : <div className="RfqDashboardMainDisplay" />}
                </Grid>
            </Grid>
        </ThemeProvider>
        );
    }
}