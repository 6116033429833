export interface PltfmTheme {
    themeName: string;
    font_family: string;
    font_size_large: string;
    background_color: string;
    background_color_opaque: string;
    border_color: string;
    color: string;
    contrast_background_color: string;
    contrast_border_color: string;
    contrastBorderColorLight: string;
    contrast_color: string;
    contrast_color2: string;
    main_foreground: string;
    windowHeaderBackgroundColor: string;
    windowHeaderForegroundColor: string;
    background_image: string;
    background_size: string;
    pltfmLogo: string;
    button_border_A: string;
    button_fill_A: string;
    button_fill_A_hover: string;
    button_text_A: string;
    contrast_selected_color: string;
    chart_color_up: string;
    chart_color_down: string;
    chart_color_grid: string;
    chart_color_indic_0: string;
    chart_color_indic_1: string;
    chart_color_indic_2: string;
    chart_color_indic_3: string;
    chart_color_indic_4: string;
    chart_color_indic_5: string;
    timer_color_start: string;
    timer_color_middle: string;
    timer_color_end: string;
    scrollFore: string;
    scrollBack: string;
    boxShadowColor: string;
}

const themeLight: PltfmTheme = {
    themeName: "light",
    font_family: "Roboto",
    font_size_large: "44pt",
    background_color: "rgba(255, 255, 255, 0.85)",
    background_color_opaque: "rgba(255, 255, 255, 1.0)",
    border_color: "rgb(26, 62, 91)",
    color: "rgb(26, 62, 91)",
    contrast_background_color: "#F5F5F5",
    contrast_border_color: "#D3D3D3",
    contrastBorderColorLight: "rgba(150, 150, 150, 0.2)",
    contrast_color: "rgb(26, 62, 91)",
    contrast_color2: "rgb(6, 42, 71)",
    main_foreground: "rgb(26, 62, 91)",
    windowHeaderBackgroundColor: "rgb(26, 62, 91)",
    windowHeaderForegroundColor: "white",
    background_image: "url('/images/Picture3.png')",
    background_size: "cover",
    pltfmLogo: "url('./images/PLTFRM_3.png')",
    button_border_A: "rgb(147, 149, 152)", //PltfmGrey
    button_fill_A: "rgb(147, 149, 152)",//"#F5F5F5",
    button_fill_A_hover: "rgb(147, 149, 152)", //PltfmGrey
    button_text_A: "rgb(26, 62, 91)",
    contrast_selected_color: "white",
    chart_color_up: "green",
    chart_color_down: "red",
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "rgb(36, 122, 191)",
    chart_color_indic_1: "rgb(89, 122, 148)",
    chart_color_indic_2: "lightblue",
    chart_color_indic_3: "rgb(159, 43, 104)",
    chart_color_indic_4: "darkgrey",
    chart_color_indic_5: "darkgrey",
    timer_color_start: "#00FF00",
    timer_color_middle: "#0000FF",
    timer_color_end: "#FF0000",
    scrollFore: "rgb(26, 62, 91)",
    scrollBack: "darkgrey",
    boxShadowColor: "rgb(147, 149, 152)",
}

const themeDark: PltfmTheme = {
    themeName: "dark",
    font_family: "Roboto",
    font_size_large: "44pt",
    background_color: "rgba(30, 30, 30, 0.85)",
    background_color_opaque: "rgba(30, 30, 30, 1.0)",
    //border_color: "rgb(187, 189, 192)", //PltfmGrey
    border_color: "rgba(254, 189, 17, 0.7)", //PltfmYellow --
    color: "rgb(207, 209, 212)", //PltfmGrey++
    contrast_background_color: "#28323a",
    contrast_border_color: "#D3D3D3",
    contrastBorderColorLight: "rgba(150, 150, 150, 0.2)",
    contrast_color: "rgb(187, 189, 192)", //PltfmGrey
    contrast_color2: "rgb(207, 209, 212)", //PltfmGrey++
    main_foreground: "rgb(187, 189, 192)", //PltfmGrey
    windowHeaderBackgroundColor: "rgb(20,20,20)",
    windowHeaderForegroundColor: "white",
    background_image: "url('/images/Picture3.png')",
    background_size: "cover",
    pltfmLogo: "url('/images/PLTFRM_2.png')",
    button_border_A: "rgba(254, 189, 17, 0.5)", //"rgb(26, 62, 91)",
    button_fill_A: "rgb(26, 62, 91)",
    button_fill_A_hover: "black",
    button_text_A: "rgb(207, 209, 212)", //PltfmGrey++ ... "rgb(147, 149, 152)", //PltfmGrey
    contrast_selected_color: "darkslategrey",
    chart_color_up: "rgb(76, 187, 23)", //Kelly Green
    chart_color_down: "rgb(255, 49, 49)", //Neon Red
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "rgb(124, 252, 0)",
    chart_color_indic_1: "rgb(64, 224, 208)",
    chart_color_indic_2: "rgb(255, 68, 51)",
    chart_color_indic_3: "rgb(0, 71, 171)",
    chart_color_indic_4: "rgb(255, 105, 180)",
    chart_color_indic_5: "rgb(159, 43, 104)",
    timer_color_start: "#00FF00",
    timer_color_middle: "#0000FF",
    timer_color_end: "#FF0000",
    scrollFore: "rgb(90, 90, 90)",
    scrollBack: "rgb(50, 50, 50)",
    boxShadowColor: "rgb(0, 0, 0)",
}

const themePltfm: PltfmTheme = {
    themeName: "pltfm",
    font_family: "Roboto",
    font_size_large: "44pt",
    background_color: "rgba(26, 62, 91, 0.9)",
    background_color_opaque: "rgba(26, 62, 91, 1.0)",
    border_color: "rgb(254, 189, 17)", //PltfmYellow
    color: "#FFFFFF",
    contrast_background_color: "rgba(147, 149, 152, 0.9)",
    contrast_border_color: "rgb(147, 149, 152)", //PltfmGrey
    contrastBorderColorLight: "rgba(150, 150, 150, 0.9)",
    contrast_color: "rgb(26, 62, 91)",
    contrast_color2: "rgb(6, 42, 71)",
    main_foreground: "rgb(26, 62, 91)",
    windowHeaderBackgroundColor: "rgb(6, 42, 71)",
    windowHeaderForegroundColor: "white",
    background_image: "url('/images/Picture3.png')",
    background_size: "cover",
    pltfmLogo: "url('/images/PLTFRM_2.png')",
    button_border_A: "rgb(254, 189, 17)", //PltfmYellow
    button_fill_A: "rgba(26, 62, 91, 0.815)",
    button_fill_A_hover: "rgba(22, 60, 80, 1.0)", //PltfmGrey
    button_text_A: "white",
    contrast_selected_color: "white",
    chart_color_up: "lime",
    chart_color_down: "orangered",
    // chart_color_up: "rgb(254, 189, 17)", //PltfmYellow
    // chart_color_down: "rgb(147, 149, 152)", //PltfmGrey
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "rgb(190, 214, 32)",
    chart_color_indic_1: "rgb(118, 133, 32)",
    chart_color_indic_2: "lightblue",
    chart_color_indic_3: "lightgreen",
    chart_color_indic_4: "white",
    chart_color_indic_5: "white",
    timer_color_start: "#00FF00",
    timer_color_middle: "#0000FF",
    timer_color_end: "#FF0000",
    scrollFore: "rgb(147, 149, 152)",
    scrollBack: "rgb(6, 42, 71)",
    boxShadowColor: "rgb(6, 42, 71)",
}

const themeMatrix: PltfmTheme = {
    themeName: "matrix",
    //font_family: "Orbitron",
    font_family: "Overpass Mono",
    font_size_large: "40pt",
    background_color: "rgba(0, 0, 0, 0.85)",
    background_color_opaque: "rgba(0, 0, 0, 1.0)",
    border_color: "rgb(0, 196, 0)",
    color: "rgb(3, 148, 3)",
    contrast_background_color: "rgba(0, 0, 0, 0.8)",
    contrast_border_color: "rgb(4, 231, 4)",
    contrastBorderColorLight: "rgba(4, 231, 4, 0.2)",
    contrast_color: "rgb(12, 160, 12)",
    contrast_color2: "rgb(62, 210, 62)",
    main_foreground: "rgb(25, 158, 25)",
    windowHeaderBackgroundColor: "darkgreen",
    windowHeaderForegroundColor: "black",
    background_image: "url('/images/Digital_rain_animation.gif')",
    background_size: "auto",
    pltfmLogo: "url('/images/PLTFRM_2.png')",
    button_border_A: "rgb(8, 92, 8)",
    button_fill_A: "black",
    button_fill_A_hover: "rgb(26, 62, 91)",
    button_text_A: "rgb(4, 245, 4)",
    contrast_selected_color: "rgb(1, 39, 1)",
    chart_color_up: "rgb(4, 245, 4)",
    chart_color_down: "darkgreen",
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "green",
    chart_color_indic_1: "lightgreen",
    chart_color_indic_2: "rgb(79, 121, 66)",
    chart_color_indic_3: "rgb(34, 139, 34)",
    chart_color_indic_4: "rgb(124, 252, 0)",
    chart_color_indic_5: "rgb(80, 200, 120)",
    timer_color_start: "#00FF00",
    timer_color_middle: "#0000FF",
    timer_color_end: "#FF0000",
    scrollFore: "rgb(25, 158, 25)",
    scrollBack: "rgba(30, 50, 30, 0.85)",
    boxShadowColor: "rgb(0, 10, 0)",
}

const themeTron: PltfmTheme = {
    themeName: "tron",
    font_family: "Overpass Mono",
    font_size_large: "40pt",
    background_color: "rgba(9, 1, 46, 0.75)",
    background_color_opaque: "rgba(9, 1, 46, 1.0)",
    border_color: "rgb(1, 238, 238)",
    color: "rgb(0, 196, 0)",
    contrast_background_color: "rgba(0, 0, 0, 0.75)",
    contrast_border_color: "rgb(230, 3, 211)",
    contrastBorderColorLight: "rgba(1, 238, 238, 0.315)",
    contrast_color: "rgb(0, 196, 0)",
    contrast_color2: "rgb(230, 3, 211)",
    main_foreground: "rgb(1, 238, 238)",
    windowHeaderBackgroundColor: "rgba(0, 0, 20)",
    windowHeaderForegroundColor: "rgb(1, 238, 238)",
    background_image: "url('/images/retro_2426631_1280.png')",
    background_size: "100 % 100 %",
    pltfmLogo: "url('/images/PLTFRM_2.png')",
    button_border_A: "rgb(230, 3, 211)",
    button_fill_A: "black",
    button_fill_A_hover: "rgb(26, 62, 91)",
    button_text_A: "rgb(1, 238, 238)",
    contrast_selected_color: "rgb(71, 0, 65)",
    chart_color_up: "rgb(230, 3, 211)",
    chart_color_down: "rgb(1, 238, 238)",
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "purple",
    chart_color_indic_1: "turquoise",
    chart_color_indic_2: "yellow",
    chart_color_indic_3: "pink",
    chart_color_indic_4: "pink",
    chart_color_indic_5: "pink",
    timer_color_start: "#004000",
    timer_color_middle: "#00AA00",
    timer_color_end: "#00FF00",
    scrollFore: "rgb(230, 3, 211)",
    scrollBack: "rgb(0, 0, 20)",
    boxShadowColor: "rgb(0, 50, 50)",
}

const themeAutumn: PltfmTheme = {
    themeName: "autumn",
    font_family: "Zen Kurenaido",
    font_size_large: "44pt",
    background_color: "rgba(255, 255, 255, 0.70)",
    background_color_opaque: "rgba(255, 255, 255, 1.0)",
    border_color: "rgb(112, 59, 6)",
    color: "rgb(22, 61, 7)",
    contrast_background_color: "#F5F5F5",
    contrast_border_color: "#D3D3D3",
    contrastBorderColorLight: "rgba(50, 150, 50, 0.2)",
    contrast_color: "rgb(191, 100, 10)",
    contrast_color2: "rgb(212, 120, 30)",
    main_foreground: "rgb(191, 100, 10)",
    windowHeaderBackgroundColor: "rgb(191, 100, 10)",
    windowHeaderForegroundColor: "white",
    background_image: "url('/images/autumn.jpg')",
    background_size: "cover",
    pltfmLogo: "url('./images/PLTFRM_3.png')",
    button_border_A: "rgb(128, 32, 3)",
    button_fill_A: "rgb(252, 114, 25)",
    button_fill_A_hover: "rgb(204, 52, 6)",
    button_text_A: "rgb(62, 128, 84)",
    contrast_selected_color: "white",
    chart_color_up: "rgb(45, 170, 87)",
    chart_color_down: "rgb(128, 32, 3)",
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "rgb(36, 122, 191)",
    chart_color_indic_1: "rgb(89, 122, 148)",
    chart_color_indic_2: "lightblue",
    chart_color_indic_3: "lightgreen",
    chart_color_indic_4: "darkgrey",
    chart_color_indic_5: "darkgrey",
    timer_color_start: "#00FF00",
    timer_color_middle: "#0000FF",
    timer_color_end: "#FF0000",
    scrollFore: "rgb(15, 140, 57)",
    scrollBack: "darkgrey",
    boxShadowColor: "rgb(147, 149, 152)",
}

const themeBeach: PltfmTheme = {
    themeName: "beach",
    font_family: "Zen Kurenaido",
    font_size_large: "44pt",
    background_color: "rgba(255, 255, 255, 0.70)",
    background_color_opaque: "rgba(255, 255, 255, 1.0)",
    border_color: "rgb(35, 40, 161)",
    color: "rgb(15, 19, 79)",
    contrast_background_color: "#F5F5F5",
    contrast_border_color: "#D3D3D3",
    contrastBorderColorLight: "rgba(50, 50, 150, 0.2)",
    contrast_color: "rgb(191, 100, 10)",
    contrast_color2: "rgb(191, 100, 10)",
    main_foreground: "rgb(212, 120, 30)",
    windowHeaderBackgroundColor: "rgb(68, 103, 184)",
    windowHeaderForegroundColor: "white",
    background_image: "url('/images/beach.jpg')",
    background_size: "cover",
    pltfmLogo: "url('./images/PLTFRM_3.png')",
    button_border_A: "rgb(3, 7, 128)",
    button_fill_A: "rgb(15, 27, 77)",
    button_fill_A_hover: "rgb(242, 250, 10)",
    button_text_A: "rgb(28, 103, 252)",
    contrast_selected_color: "white",
    chart_color_up: "rgb(45, 170, 87)",
    chart_color_down: "rgb(128, 32, 3)",
    chart_color_grid: "rgba(147, 149, 152, 0.2)",
    chart_color_indic_0: "rgb(8, 20, 46)",
    chart_color_indic_1: "rgb(200, 209, 19)",
    chart_color_indic_2: "lightblue",
    chart_color_indic_3: "lightgreen",
    chart_color_indic_4: "darkgrey",
    chart_color_indic_5: "darkgrey",
    timer_color_start: "#00FF00",
    timer_color_middle: "#0000FF",
    timer_color_end: "#FF0000",
    scrollFore: "rgb(38, 103, 184)",
    scrollBack: "darkgrey",
    boxShadowColor: "rgb(147, 149, 152)",
}

export const PltfmThemes: PltfmTheme[] = [themeLight, themeDark, themePltfm, themeMatrix, themeTron, themeAutumn, themeBeach];
