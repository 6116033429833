import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import { GetConfigsForType } from '../user/userActions';
import userSessionConnection from '../userSession/userSessionConnection';
import { get, put } from '../utils/httpUtils';
import { ActiveReportsDataSource, Report } from './reportModels';

export class LoadReportsEvent extends TypedEvent<Report[]> { }
export class LoadDataSetsEvent extends TypedEvent<ActiveReportsDataSource[]> { }
export class UpdateReportEvent extends TypedEvent<Report> { }
export class DeleteReportEvent extends TypedEvent<Report> { }
export class LoadReportsFailedEvent extends TypedEvent<string> { }


export async function LoadReports() {
    var success = true;

    const reports = await get<Report[]>('Reporting/GetReports');
    if (reports.payload == null) {
        success = false;
        AppDispatcher.dispatch(new LoadReportsFailedEvent("Failed to load reports from server"));
    } else {
        AppDispatcher.dispatch(new LoadReportsEvent(reports.payload));
    }

    const endPoints = await get<ActiveReportsDataSource[]>('Reporting/GetDataSets');
    if (endPoints.payload == null) {
        success = false;
        AppDispatcher.dispatch(new LoadReportsFailedEvent("Failed to load endpoints from server"));
    } else {
        AppDispatcher.dispatch(new LoadDataSetsEvent(endPoints.payload));
    }

    //load eod report cfg
    await GetConfigsForType("EoDReportConfig");
    userSessionConnection.subscribeToReportUpdates();
    await UpdateLoadingStatus("Reports", success ? LoadingStatus.Completed : LoadingStatus.Failed);
}

export async function AddOrUpdateReport(report: Report) {
    const response = await put<Report>('Reporting/AddOrUpdateReport', report);
    if (response.payload == null) {
        AppDispatcher.dispatch(new LoadReportsFailedEvent("Failed to add or update order"));
    } else {
        AppDispatcher.dispatch(new UpdateReportEvent(response.payload));
    }

    return response?.payload;
}

export function UpdatedReport(report: Report) {
    AppDispatcher.dispatch(new UpdateReportEvent(report));
}

export async function DeleteReport(report: Report) {
    await put<Report>('Reporting/DeleteReport', report);
    AppDispatcher.dispatch(new DeleteReportEvent(report));
}