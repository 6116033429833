import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import moment, { Moment } from 'moment';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackRisk } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { DataGridPremium, GridCellParams, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import riskStoreInstance from './riskStore';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';
import { CloseOutlined } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import TransferList from '../inputs/transferList';
import { v4 } from 'uuid';
import { MergeCubes } from '../qwack/cubeUtils';

interface FxViewState {
    resultCubeDeltaGamma: TO_ResultCube | undefined,
    resultCubeVega: TO_ResultCube | undefined,
    resultCubeTheta: TO_ResultCube | undefined,
    mainRef: RefObject<HTMLDivElement>,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    selectedUnderlying: string | undefined,
    units: string,
    showFieldChoser: boolean,
    aggregationFields: string[],
    currentKey: string
}

export interface FxViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const Metrics = ["FxGamma", "FxVega", "Theta"];
const MetricUnits = ["Currency"];
const DefaultColumns = ["Underlying", "PltfmType", "Institution", "Account", "AssetId", "Metric", "Value"];

export class FxView extends React.Component<FxViewProps, FxViewState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: FxViewProps) {
        super(props)
        var asOf = props.getState("fxv_asOf");
        this.state = {
            resultCubeDeltaGamma: undefined,
            resultCubeVega: undefined,
            resultCubeTheta: undefined,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            rowExpanded: undefined,
            selectedSet: DefaultSetName,
            selectedUnderlying: undefined,
            units: MetricUnits[0],
            showFieldChoser: false,
            aggregationFields: ['Metric', 'AssetId', 'Institution', 'PltfmType'],
            currentKey: v4()
        };;
        this.updatePVs = this.updatePVs.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.controls = this.controls.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = riskStoreInstance.addChangeListener(this.updatePVs);
        this.updatePVs();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("fxv_asOf", dStr);
        this.updatePVs(d);
        this.setState({ asOf: d, });
    }

    async calcPVs() {
        this.setState({ awaitingRefresh: true, resultCubeDeltaGamma: null });
        const { selectedSet, asOf } = this.state;
        var t0 = ComputeQwackRisk(asOf.toDate(), Metrics[0], selectedSet);
        var t1 = ComputeQwackRisk(asOf.toDate(), Metrics[1], selectedSet);
        var t2 = ComputeQwackRisk(asOf.toDate(), Metrics[2], selectedSet);
        await Promise.all([t0, t1, t2]);
    }

    updatePVs(asOf?: Moment, set?: string) {
        if (!asOf)
            asOf = this.state.asOf;

        if (!set)
            set = this.state.selectedSet;

        let cube0 = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, Metrics[0])
        let cube1 = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, Metrics[1])
        let cube2 = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, Metrics[2])
        var refreshDone = (!this.state.awaitingRefresh) || (cube0 && cube1 && cube2);
        this.setState({ resultCubeDeltaGamma: cube0, resultCubeVega: cube1, resultCubeTheta: cube2, awaitingRefresh: !refreshDone });
        if (refreshDone) {
            this.setState({ currentKey: v4() })
        }
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }


    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = Number(params.value);
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
        }
        else
            return <div></div>;
    }

    customToolbar(props: any) {
        const { awaitingRefresh, asOf, selectedSet } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <GridToolbarContainer>
                <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                    <Grid item container spacing={1} style={{ width: "50%" }}>
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={asOf?.toDate()}
                                onChange={this.onChangeDate}
                                disableFuture
                                minDate={EarliestDate}
                                emptyLabel="Live"
                                clearLabel="Live"
                                label={"As-Of"}
                            />
                        </Grid>
                        <Grid item>
                            <MarketDataSetSelector
                                id="set"
                                name="set"
                                label="Set"
                                value={selectedSet}
                                onChange={(set: string) => {
                                    this.props.onChangeState("selectedSet", set)
                                    this.updatePVs(null, set);
                                    this.setState({ selectedSet: set });
                                }} />
                        </Grid>
                        <Grid item>
                            <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcPVs()}>Compute</Button>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} justifyContent="flex-end" style={{ width: "50%" }}>
                        <Grid item>
                            <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" />
                        </Grid>
                        <Grid item>
                            <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                        </Grid>
                        <Grid item>
                            <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                        </Grid>
                        <Grid item>
                            <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.setState({ showFieldChoser: true })}>Aggregate</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        </ThemeProvider>
        );
    }

    controls() {
        const { awaitingRefresh, asOf, selectedSet } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                <Grid item container spacing={1} style={{ width: "50%" }}>
                    <Grid item>
                        <WrappedDatePicker
                            style={{ width: "120px" }}
                            value={asOf?.toDate()}
                            onChange={this.onChangeDate}
                            minDate={EarliestDate}
                            emptyLabel="Live"
                            clearLabel="Live"
                            label={"As-Of"} />
                    </Grid>
                    <Grid item>
                        <MarketDataSetSelector
                            id="set"
                            name="set"
                            label="Set"
                            value={selectedSet}
                            onChange={(set: string) => {
                                this.props.onChangeState("selectedSet", set)
                                this.props.onChangeState("selectedSet", set)
                                this.updatePVs(null, set);
                                this.setState({ selectedSet: set });
                            }} />
                    </Grid>
                    <Grid item>
                        <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcPVs()}>Compute</Button>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    getColDef(name: string) {
        return { field: name, hideable: true, type: "string", width: 150, headerName: name, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" } as GridColDef;
    }

    render() {
        const { resultCubeDeltaGamma, resultCubeTheta, resultCubeVega, mainRef, awaitingRefresh, showFieldChoser, aggregationFields, currentKey } = this.state;

        var cube = MergeCubes(resultCubeDeltaGamma, resultCubeTheta);
        cube = MergeCubes(cube, resultCubeVega);

        const columns: GridColDef[] = cube && cube.fieldNames ? [
            ...cube.fieldNames.map(f => this.getColDef(f)),
            { field: 'value', width: 175, aggregable: true, groupable: false, hideable: false, filterable: true, headerName: 'Value', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderNumberCell },
        ] : new Array<GridColDef>();

        var ulIx = cube?.fieldNames.indexOf("Underlying");
        const rows = cube && cube.fieldNames ? cube.rows.map((r, ix) => {
            var rowA = { id: ix, value: r.value };
            for (var i = 0; i < cube.fieldNames.length; i++) {
                if (cube.fieldNames[i] === "Underlying")
                    rowA[cube.fieldNames[i]] = r.metaData[i]?.replaceAll(" Underlying Index", "") //new Date(r.metaData[i]);
                else if (cube.fieldNames[i] === "AssetId" && !r.metaData[i]) {
                    rowA[cube.fieldNames[i]] = r.metaData[ulIx]?.replaceAll(" Underlying Index", "")
                }
                else
                    rowA[cube.fieldNames[i]] = r.metaData[i];
            }
            return rowA;
        }) : new Array<any>();

        const columnVisibility: any = {};
        columns.forEach(col => {
            columnVisibility[col.field] = DefaultColumns.includes(col.headerName) && !aggregationFields.includes(col.field)
        });

        const theme = userStoreInstance.GetTheme();

        return (<ThemeProvider theme={getFormTheme()}>
            <div className="NavCalcTableContainer">
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={showFieldChoser}
                    onClose={() => this.setState({ showFieldChoser: false })}>
                    <DialogTitle id="alert-dialog-title" style={{ backgroundColor: theme.background_color_opaque, color: theme.color, borderBottom: "1px solid", borderColor: theme.border_color }}>
                        <div>
                            <Typography variant="h6">Aggregation Fields</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={() => this.setState({ showFieldChoser: false })}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}>
                                <CloseOutlined />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: theme.background_color_opaque, color: theme.color, display: "flex", justifyContent: "center", width: "100%" }}>
                        {showFieldChoser && <div style={{ paddingTop: "1em", backgroundColor: theme.background_color }}>
                            <TransferList
                                chosen={aggregationFields?.map(t => columns.filter(c => c.field === t)[0]?.headerName)}
                                choices={cube?.fieldNames}
                                onChange={(chosen: string[]) => { this.setState({ aggregationFields: chosen }); }}
                                onClose={() => this.setState({ showFieldChoser: false })}
                            />
                        </div>}
                    </DialogContent>
                </Dialog>
                <div className="QwackPvTable" ref={mainRef}>
                    {columns && <DataGridPremium
                        experimentalFeatures={{ aggregation: true }}
                        key={"fxDeltaGridControl" + currentKey}
                        className="QwackPvTableGrid"
                        initialState={{
                            columns: { columnVisibilityModel: columnVisibility },
                            aggregation: { model: { value: 'sum' } }
                        }}
                        rowGroupingModel={aggregationFields}
                        rowGroupingColumnMode="multiple"
                        defaultGroupingExpansionDepth={1}
                        getAggregationPosition={(groupNode=>groupNode?.groupingField === 'Metric' && (groupNode?.groupingKey === "FxSpotDelta" || groupNode?.groupingKey === "FxSpotGamma")  ?  null : 'inline')}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: this.customToolbar,
                            LoadingOverlay: this.loading,
                        }}
                        hideFooter
                        loading={awaitingRefresh} />}
                </div>
            </div></ThemeProvider>
        );
    }
}
