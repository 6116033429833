import React from 'react';
import FBEmitter from 'fbemitter';
import { Alert } from './alertsModels';
import alertStoreInstance from './alertsStore';
import { CloseAlert, LoadAlerts } from './alertsActions';
import { Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import userSessionConnection from '../userSession/userSessionConnection';

export type AlertsListProps = {
    onChangeHeight?: (newHeight: number) => void
} 

type AlertsListComponentState = {
    loading: boolean;
    alerts: Alert[];
    alertTypes: string[];
    height: number;
    anchorEl : any;
    contextAlertId : number;
}

export class AlertsList extends React.Component<AlertsListProps, AlertsListComponentState>{
    eventSubscriptionAlerts: FBEmitter.EventSubscription | undefined;
    eventSubscriptionUser: FBEmitter.EventSubscription | undefined;

    constructor(props: AlertsListProps) {
        super(props);
        this.state = {
            alerts: [],
            alertTypes: [],
            loading: true,
            height: 400,
            anchorEl : null,
            contextAlertId : 0,
        }
    }

    componentWillUnmount() {
        if (this.eventSubscriptionAlerts) {
            this.eventSubscriptionAlerts.remove();
            this.eventSubscriptionAlerts = undefined;
        }
        if (this.eventSubscriptionUser) {
            this.eventSubscriptionUser.remove();
            this.eventSubscriptionUser = undefined;
        }
        userSessionConnection.unsubscribeFromAlerts();
    }

    async componentDidMount() {
        this.eventSubscriptionAlerts = alertStoreInstance.addChangeListener(() => this.onChange());
        userSessionConnection.subscribeToAlerts();
        await LoadAlerts();
    }

    onChange() {
        let items = alertStoreInstance.getAlerts();
        let types = [...new Set(items.map(i => i.alertType))];
        this.setState({ alerts: items, alertTypes: types });
    }

    onRightClick(e : React.MouseEvent<HTMLDivElement, MouseEvent>, alertId : number) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ anchorEl: e.currentTarget, contextAlertId : alertId});
    }

    handleClose = () => {
        this.setState({ anchorEl: null, contextAlertId : 0});
    }

    handleMarkResolved = async () => {
        await CloseAlert(this.state.contextAlertId);
        this.handleClose();
    }

    handleMarkAllResolved = async () => {
        var promises = new Array<Promise<Alert>>();
        for(var i=0;i<this.state.alerts.length;i++)
        {
            var alertId = this.state.alerts[i].alertId;
            promises.push(CloseAlert(alertId));
        }
        await Promise.all(promises);
        this.handleClose();
    }

    render() {
        const { alerts, anchorEl } = this.state;
        return (
            <div className="AlertsList">
                <div className="AlertsListInner">
                    {alerts && alerts.length>0?alerts.slice(0,20).map(k => {
                        return (
                            <div className="AlertsListDateBlock" key={k.alertId}
                            onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onRightClick(e, k.alertId)}>
                                <span>{k.alertType} - {k.description}</span>
                            </div>);
                    }) : <Typography align="center">(Nothing to display)</Typography>}
                </div>
                <Menu id="alertMenu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
                    <MenuItem onClick={this.handleMarkResolved}>Mark As Solved</MenuItem>
                    <MenuItem onClick={this.handleMarkAllResolved}>Mark All As Solved</MenuItem>
                </Menu>
            </div>
        );
    }
}