import React from 'react'
import FBEmitter from 'fbemitter';
import userStoreInstance, { IsDevEnvironment, Username } from '../user/userStore';
import { LoadingInstance, LoadingState, LoadingStatus } from './loadingModels';
import loadingStore from './loadingStore';
import LayoutControl from '../layout/layoutControl';

class Loading extends React.Component<{}, LoadingState>{
    eventSubscription: FBEmitter.EventSubscription | undefined;

    constructor(props: {}) {
        super(props);
        this.state = loadingStore.getState();
        loadingStore.start();
    }

    async componentDidMount() {
        this.eventSubscription = loadingStore.addChangeListener(this.onChange);
    }

    async componentWillUnmount() {
        if (this.eventSubscription !== undefined) {
            this.eventSubscription.remove();
        }
    }

    onChange = () => {
        this.setState(loadingStore.getState());
    }

    onChangeUser = () => {

    }

    renderDevOptions() {
        return;
    }

    displayStatus(item: LoadingInstance) {
        return (<tr key={`LoadingStatusTable-${item.name}`}>
            <td className="LoadingBoxRowTitle">{item.name}</td>
            <td className="LoadingBoxRowData">{LoadingStatus[item.status]}</td>
        </tr>)
    }

    render() {
        const itemsArray = Array.from(this.state.loadingItems.values());
        const theme = userStoreInstance.GetThemeName();
        if (this.state.loaded) {
            return (<LayoutControl username={Username()} isDev={IsDevEnvironment()}></LayoutControl>);
        }
        else {
            return (
                <div className={`LoadingPage theme-${theme}`}>
                    <div className="LoadingBox">
                        <table className="LoadingBoxTable">
                            <thead>
                                <tr><th colSpan={2}>Loading...</th></tr>
                            </thead>
                            <tbody>
                                {itemsArray.map(i => this.displayStatus(i))}
                            </tbody>
                        </table>
                        <div className="PltfmLogo"></div>
                    </div>
                    <div className="LoadingCopyright"> &copy; 2021 </div>
                </div>);
        }
    }
}

export default Loading;