import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import positionStoreInstance from './positionSummaryStore';
import { LoadAccountBalances, UnSubcribeFromLivePositionUpdates } from './positionSummaryActions';
import moment from 'moment';
import {
    Button,
    LinearProgress,
    MenuItem,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import { AccountBalance } from './positionSummaryModels';
import { DataGridPro, GridCellParams, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import { getPositionTableTheme } from './positionSummaryTable';
import userStoreInstance from '../user/userStore';
import { GroupOutlined, RefreshOutlined } from '@mui/icons-material';
import { getFormTheme } from '../inputs/formCommon';
import { renderDate } from '../utils/helpers';
import productStoreInstance from '../product/productStore';
import _ from 'lodash';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface CashBalanceTableState {
    balances: AccountBalance[],
    mainRef: RefObject<HTMLDivElement>,
    awaitingRefresh: boolean,
    groupByCurrency: boolean,
    selectedCurrency: string | undefined
    availableCcys: string[]
}

export interface CashBalanceTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const NullCcy = "Select ccy...";

export class CashBalanceTable extends React.Component<CashBalanceTableProps, CashBalanceTableState>{
    eventSubscriptionPositions: FBEmitter.EventSubscription | undefined;
    constructor(props: CashBalanceTableProps) {
        super(props)
        this.state = {
            balances: [],
            mainRef: React.createRef(),
            awaitingRefresh: false,
            groupByCurrency: false,
            selectedCurrency: undefined,
            availableCcys: productStoreInstance.getAvailableCurrencies()
        };;
        this.updateBalances = this.updateBalances.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionPositions = positionStoreInstance.addChangeListener(this.updateBalances);
        this.updateBalances();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionPositions) {
            this.eventSubscriptionPositions.remove();
            this.eventSubscriptionPositions = undefined;
        }
        await UnSubcribeFromLivePositionUpdates();
    }


    async onClickRefresh() {
        this.setState({ awaitingRefresh: true });
        await LoadAccountBalances();
    }

    customToolbar(props: any) {
        const { awaitingRefresh, groupByCurrency, selectedCurrency, availableCcys } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ padding: "5px" }}>
                            <WrappedSelect
                                id="ccy"
                                name="ccy"
                                label="Currency"
                                value={selectedCurrency ?? NullCcy}
                                onChange={(d) => {
                                    var ccy = d.target.value as string;
                                    if (ccy === NullCcy)
                                        ccy = undefined;
                                    this.setState({ selectedCurrency: ccy });
                                }}>
                                {[NullCcy, ...availableCcys].map(a =>
                                    <MenuItem key={"ccyFilterX_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <Button variant="outlined" size="small" disabled={awaitingRefresh} startIcon={<RefreshOutlined />} onClick={() => this.onClickRefresh()}>Refresh</Button>
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <Button variant="outlined" size="small" disabled={selectedCurrency !== undefined} startIcon={<GroupOutlined />} onClick={() => this.setState({ groupByCurrency: !groupByCurrency })}>{groupByCurrency ? "By Currency" : "Detailed"}</Button>
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <GridToolbarExport {...props} variant="outlined" size="small" />
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <GridToolbarFilterButton {...props} />
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <GridToolbarColumnsButton {...props} variant="outlined" size="small" />
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    updateBalances() {
        let balances = positionStoreInstance.getAccountBalances();
        var ccys = Array.from(new Set(balances.map(b => b.currency)));
        this.setState({ balances, awaitingRefresh: false, availableCcys: ccys });
    }



    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString()}</div>
        }
        else
            return <div></div>;
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    renderDateTimeCell(params: GridCellParams) {
        return <span>{renderDate(new Date(params.value as string))}</span>;
    }

    render() {
        const { balances, mainRef, awaitingRefresh, selectedCurrency } = this.state;

        const filteredBalances = selectedCurrency === undefined ? balances : balances.filter(b => b.currency === selectedCurrency);

        const columns: GridColDef[] = [
            { field: 'date', width: 150, headerName: 'Date', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", renderCell: this.renderDateTimeCell, type: "dateTime" },
            ...filteredBalances.sort((a, b) => a.accountName < b.accountName ? 1 : -1).sort((a, b) => a.currency < b.currency ? 1 : -1).map(b => {
                return { field: `${b.currency}:${b.accountName}`, width: 200, headerName: `${b.currency}:${b.accountName}`, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", renderCell: this.renderNumberCell, type: "number" };
            })];
        if (selectedCurrency !== undefined) {
            columns.splice(1, 0, { field: 'total' + selectedCurrency, width: 150, headerName: selectedCurrency + ":Total", cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", renderCell: this.renderNumberCell, type: "number" })
        }
        var dates = Array.from(new Set(balances.flatMap(b => Object.keys(b.balance))));
        dates.sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf());
        const rows = dates.map((d, ix) => {
            var row = { id: ix, date: new Date(d) };
            filteredBalances.map(b => row[`${b.currency}:${b.accountName}`] = Math.round(b.balance[d] * 100) / 100);
            if (selectedCurrency !== undefined) {
                var total = _.sum(filteredBalances.map(b => b.balance[d]));
                row['total' + selectedCurrency] = Math.round(total * 100) / 100;
            }
            var Abstotal = _.sum(filteredBalances.map(b => b.balance[d] ? Math.abs(b.balance[d]) : 0));
            return Abstotal > 0 ? row : null;
        }).filter(r => r !== null);

        return (
            <div className="PositionSummaryTab" ref={mainRef}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getPositionTableTheme()}>
                        <DataGridPro
                            className="PositionSummaryTabTable"
                            rows={rows}
                            columns={columns}
                            // pagination
                            // autoPageSize={true}
                            components={{
                                Toolbar: this.customToolbar,
                                LoadingOverlay: this.loading,
                            }}
                            loading={awaitingRefresh}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}
