import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore'
import { NewTaskUpdateEvent } from './userSessionActions';
import { LongRunningTaskUpdate, TaskUpdateStoreState } from './pingModels';


class TaskUpdateStore extends FluxStore<TaskUpdateStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            updates: {}
        }))
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewTaskUpdateEvent) {
            const { payload } = action;
            this.state.updates[payload.jobKey] = payload;
            this.emitChange();
        }
    }

    public GetLatestStatus(key: string): LongRunningTaskUpdate {
        return this.state.updates[key];
    }
}

const taskUpdateStoreInstance = new TaskUpdateStore(AppDispatcher);
export default taskUpdateStoreInstance;

