import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import FluxStore from "../FluxStore";
import { ScheduleLog, SchedulesStoreState } from './schedulesModels';
import { ScheduleLogLoadedEvent, SchedulesLoadedEvent, ScheduleUpdateEvent, UpdateScheduleLogs } from './schedulesActions';

class SchedulesStore extends FluxStore<SchedulesStoreState>{

    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            schedules: [],
            scheduleHistory: new Map<number, ScheduleLog[]>(),
            activeHistoryRequests: new Set<number>()
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof SchedulesLoadedEvent) {
            const { payload } = action;
            this.state.schedules = payload;
            this.emitChange();
        }
        else if (action instanceof ScheduleUpdateEvent) {
            const { payload } = action;
            this.state.schedules = this.state.schedules.filter(s => s.scheduleId !== payload.scheduleId);
            this.state.schedules.push(payload);
            this.state.schedules.sort((a, b) => a.scheduleId - b.scheduleId);
            this.emitChange();
        }
        else if (action instanceof ScheduleLogLoadedEvent) {
            const { payload } = action;
            this.state.activeHistoryRequests.delete(payload[0].scheduleId);
            this.state.scheduleHistory.set(payload[0].scheduleId, payload);
            this.emitChange();
        }
    }

    getSchedules() {
        return this.state.schedules;
    }

    getScheduleLogs(scheduleId: number) {
        return this.state.scheduleHistory.get(scheduleId);
    }

    getScheduleLogsLatest(scheduleId: number) {
        return this.state.scheduleHistory.get(scheduleId)?.sort((a, b) => new Date(a.runStarted).valueOf() - new Date(b.runStarted).valueOf())[0];
    }

    async requestScheduleLogs(scheduleId: number) {
        if (!this.state.activeHistoryRequests.has(scheduleId)) {
            this.state.activeHistoryRequests.add(scheduleId);
            await UpdateScheduleLogs(scheduleId);
        }
    }
}

const schedulesStoreInstance = new SchedulesStore(AppDispatcher);
export default schedulesStoreInstance;