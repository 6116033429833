import React from 'react';
import FBEmitter from 'fbemitter';
import { RfqSummary } from '../rfqModels';
import moment from 'moment';
import rfqSummaryStoreInstance from '../rfqSummaryStore';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

type RfqSummaryProps = {
    selectedRfq: RfqSummary | undefined,
}


type RfqSummaryState = {
    data: any[][],
    selectedRfq: RfqSummary | undefined
}

export class RfqSummaryDisplay extends React.Component<RfqSummaryProps, RfqSummaryState>{
    eventSubscriptionRfqs: FBEmitter.EventSubscription | undefined;

    constructor(props: RfqSummaryProps) {
        super(props)
        this.state = {
            data: [],
            selectedRfq: this.props.selectedRfq
        };
        this.onChangeRfq = this.onChangeRfq.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionRfqs = rfqSummaryStoreInstance.addChangeListener(this.onChangeRfq);
    }

    componentWillUnmount() {
        if (this.eventSubscriptionRfqs) {
            this.eventSubscriptionRfqs.remove();
            this.eventSubscriptionRfqs = undefined;
        }
    }

    onChangeRfq() {
        var summary = rfqSummaryStoreInstance.GetRfq(this.props.selectedRfq.rfqId);
        if (summary !== this.state.selectedRfq) {
            this.setState({ selectedRfq: summary });
        }
    }


    truncateifNeeded(val: string, maxLength: number): string {
        return val.length < maxLength ? val : `${val.slice(0, maxLength)}...`;
    }

    renderDataRow(data: any[], rowIx: number) {
        return (<TableRow key={`rfqTbl_row${rowIx}`}>{data.map((d, ix) => this.renderDataCell(d, rowIx, ix))}</TableRow>);
    }

    renderDataCell(data: any, rowIx: number, colIx: number) {
        return (<TableCell style={{ textAlign: "center" }} key={`rfqTbl_cell${rowIx}-${colIx}`}>{data}</TableCell>)
    }

    render() {
        const { selectedRfq } = this.state;
        if (selectedRfq) {
            let lastUpdated = moment.tz(selectedRfq.lastUpdated, "UTC");
            let lastUpdateLocal = lastUpdated.tz(moment.tz.guess());
            //let isInternal = selectedRfq.parentRfq !== null;
            return (<div>
                <TableContainer component={Paper}>
                    <Table className="RfqDashboardTable">
                        <TableHead />
                        <TableBody>
                            <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">Id</TableCell>
                                <TableCell className="RfqDashboardTableValues">{selectedRfq.rfqId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">Description</TableCell>
                                <TableCell className="RfqDashboardTableValues">{selectedRfq.description}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">Requestor</TableCell>
                                <TableCell className="RfqDashboardTableValues">{selectedRfq.userCreatedBy}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">Client</TableCell>
                                <TableCell className="RfqDashboardTableValues">{selectedRfq.requestingFirm?.displayName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">{isInternal ? "Parent Id" : "Child Id"}</TableCell>
                                <TableCell className="RfqDashboardTableValues">{isInternal ? selectedRfq.parentRfq : selectedRfq.childRfq}</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">Updated</TableCell>
                                <TableCell className="RfqDashboardTableValues">{lastUpdateLocal.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="RfqDashboardTableLeftTitles">Status</TableCell>
                                <TableCell className="RfqDashboardTableValues">{selectedRfq.state}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table >
                </TableContainer>
            </div>);
        }
        else
            return null;
    }
}