import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { AcceleratorConfiguration, AcceleratorLocalSetup } from '../accelerator/acceleratorModels';
import acceleratorStoreInstance from '../accelerator/acceleratorStore';
import { SaveAcceleratorSettings, UpdateAcceleratorSettings } from '../accelerator/acceleratorActions';
import { FormSection } from '../inputs/formSection';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { CheckCircleOutline, UndoOutlined } from '@mui/icons-material';

interface AcceleratorSettingsState {
    config: AcceleratorConfiguration;
    localSettings: AcceleratorLocalSetup
}

export interface AcceleratorSettingsProps {

}

export class AcceleratorSettings extends React.Component<AcceleratorSettingsProps, AcceleratorSettingsState>{
    eventSubscriptionAccelerator: FBEmitter.EventSubscription | undefined;
    constructor(props: AcceleratorSettingsProps) {
        super(props)
        this.state = {
            config: undefined,
            localSettings: undefined
        };

        this.onChangeConfig = this.onChangeConfig.bind(this);
        this.onUpdateLocalPort = this.onUpdateLocalPort.bind(this);
        this.onUpdateConfig = this.onUpdateConfig.bind(this);
        this.onUpdateClassName = this.onUpdateClassName.bind(this);
        this.onClickUpdate = this.onClickUpdate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionAccelerator = acceleratorStoreInstance.addChangeListener(this.onChangeConfig);
        await this.onChangeConfig();
    }

    async componentWillUnmount() {
    }

    async onChangeConfig() {
        var config = acceleratorStoreInstance.getConfig();
        if (config) {
            config = JSON.parse(JSON.stringify(config))
            this.setState({ config });
        }
        else {
            await UpdateAcceleratorSettings();
        }
        var localSettings = acceleratorStoreInstance.getLocalSetup();
        if (localSettings) {
            localSettings = JSON.parse(JSON.stringify(localSettings))
            this.setState({ localSettings });
        }
    }

    onUpdateLocalPort(port: string) {
        var asNum = Number(port);
        if (!isNaN(asNum)) {
            const { localSettings } = this.state;
            localSettings.localPort = asNum;
            this.setState({ localSettings });
        }
    }

    onUpdateClassName(className: string) {
        const { config } = this.state;
        config.adapterName = className;
        this.setState({ config });
    }

    onUpdateConfig(cfgStr: string) {
        const { config } = this.state;
        config.adapterConfig = cfgStr;
        this.setState({ config });
    }

    async onClickUpdate(){
        const { config, localSettings } = this.state;
        if(config && localSettings){
            await SaveAcceleratorSettings(localSettings, config);
        }
    }

    anyChanges(config: AcceleratorConfiguration, localSettings: AcceleratorLocalSetup){
        var c = acceleratorStoreInstance.getConfig();
        var l = acceleratorStoreInstance.getLocalSetup();
        return !(config?.adapterConfig===c?.adapterConfig &&
            config?.adapterName === c?.adapterName &&
            localSettings?.localPort === l?.localPort);
    }

    render() {
        const { config, localSettings } = this.state;
        const titleStyle = { width: "175px", textAlign: "right", paddingRight: "1em" } as React.CSSProperties;
        var anyChanges = this.anyChanges(config,localSettings);
        return (
            <ThemeProvider theme={getFormTheme()}>
                <Grid container spacing={2} justifyContent="center" alignContent="space-around" width={"95%"} height={"100%"} >
                    <FormSection label={<Typography variant="h6" style={titleStyle}>Local Settings</Typography>}>
                        <Grid item>
                            <TextField
                                size='small'
                                value={localSettings?.localPort ?? ""}
                                onChange={(v) => this.onUpdateLocalPort(v.currentTarget.value)}
                                label="Local Port"
                                variant='outlined' />
                        </Grid>
                        <Grid item></Grid>
                    </FormSection>
                    <FormSection label={<Typography variant="h6" style={titleStyle}>Accelerator Type</Typography>}>
                        <Grid item>
                            <WrappedSelect
                                id="ClassName"
                                name="ClassName"
                                label="Class Name"
                                autoWidth
                                style={{width:"350px"}}
                                value={config?.adapterName ?? acceleratorStoreInstance.getClassNames()[0]}
                                onChange={(v) => this.onUpdateClassName(v.target.value)}>
                                {acceleratorStoreInstance.getClassNames().map((c) => {
                                    return <MenuItem key={c} value={c}>{c}</MenuItem>
                                })}
                            </WrappedSelect>
                        </Grid>
                        <Grid item>
                            <TextField
                                size='small'
                                value={config?.adapterConfig ?? ""}
                                onChange={(v) => this.onUpdateConfig(v.currentTarget.value)}
                                label="Config String"
                                variant='outlined' />
                        </Grid>
                    </FormSection>
                    <Grid item container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                disabled={!anyChanges}
                                onClick={this.onClickUpdate}
                                endIcon={<CheckCircleOutline />}>Update</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                disabled={!anyChanges}
                                onClick={this.onChangeConfig}
                                endIcon={<UndoOutlined />}>Revert</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>)

    }
}
