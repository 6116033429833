import { GridDensity, GridFilterModel } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

export interface AggregatedPosition {
    //instrument: ListedInstrument,
    instrumentId: number,
    averagePrice: number,
    averagePriceBase: number,
    averagePriceExBro: number,
    averagePriceBuy: number,
    averagePriceSell: number,
    openPosition: number,
    quantityBought: number,
    quantitySold: number,
    realizedPnL: number,
    realizedPnLCcy: string,
    realizedPnLBase: number,
    totalTrades: number,
    lastTraded: Date,
    firstTraded: Date,
    metaData: { [type: string]: string },
    accountName: string,
    positionKey: string
}

export interface AggregatedSummary {
    asOf: string,
    positions: AggregatedPosition[],
    metaKeys: string[],
}

export interface PositionStatus {
    quoteAges: { [insId: number]: Date }
}

export interface PositionSummaryStoreState {
    summary: { [asOf: string]: AggregatedSummary },
    liveSummary: { [asOf: string]: LiveSummary },
    accountBalances: AccountBalance[],
    positionAnalysis: PositionAnalysisRecord[],
    dayTradeAnalysis: DayTradeAnalysisRecord[]
}

export interface LivePosition {
    livePrice: number,
    liveFxRateToBase: number,
    underlyingLivePrice: number,
    delta: number,
    deltaBaseEquiv: number,
    gamma: number,
    vega: number,
    unrealizedPnL: number,
    unrealizedPnLBase: number,
    unrealizedCcy: string,
    aggregatedPosition: AggregatedPosition,
    priceAge?: Date,
    stops: SlTpRecord
}

export interface SlTpRecord {
    stopLevel: number,
    stopSize: number,
    tpLevel: number,
    tpSize: number,
    deleted: boolean,
    listedInstrumentId: number,
    marketStopOrders: InMarketStop[]
}

export interface InMarketStop {
    stopLevel: number;
    stopSizeContracts: number;
    stopOrderType: string;
}

export interface LiveSummary {
    asOf: string,
    metaKeys: string[],
    positions: LivePosition[],
    positionStatus: PositionStatus,
    fxAsPositions: boolean,
    fxTrackRealizedPnL: boolean,
    fxRatesToBase: { [ccy: string]: number } //in format CCY.USD (where USD is base)
}

export interface AccountBalance {
    accountName: string,
    currency: string,
    balance: { [date: string]: number }
}

export class PositionAnalysisRecord {
    positionKey: string;
    positionSeriesNumber: number;
    openingDate: Date;
    closingDate: Date;
    attribution: string;
    asset: string;
    positionMakeUp: string;
    startingDelta: number;
    peakDelta: number;
    meanDelta: number;
    stdDevDelta: number;
    daysToMaxDelta: number
    propDaysAtMaxDelta: number;
    daysPositionOpen: number;
    maxMtm: number;
    minMtm: number;
    meanMtm: number;
    mtmMaxDrawDown: number;
    finalMtm: number;
    stdDevMtm: number;
    daysToMaxMtm: number;
    daysToMinMtm: number;
    correlationDeltaMtm: number;
    correlationMtmTime: number;
}

export class DayTradeAnalysisRecord{
    /* public int ListedInstrumentId { get; set; }
        public DateTime PositionDate { get; set; }
        public decimal PositionLengthTime { get; set; }
        public decimal PositionSizeContracts { get; set; }
        public decimal PositionSizeNominal { get; set; }
        public decimal PnL { get; set; }*/
        listedInstrumentId: number;
        positionDate: Date;
        //postionLengthTime: number;
        positionSizeContracts: number;
        positionSizeNominal: number;
        pnL: number;
}

export interface PositionSummary {
    realizedByAsset: {[asset:string]: number}
    unRealizedByAsset: {[asset:string]: number}
    realized: number;
    unRealized: number;
    // realizedByStrategy: {[asset:string]: number}
    // unRealizedByStrategy: {[asset:string]: number}

    grossExp: number,
    netExp: number,
    delta: number,
    vega: number,

    deltaByAsset: {[asset:string]: number},
    vegaByAsset: {[asset:string]: number},

    stopLossByAsset: {[asset:string]: number},
    takeProfitByAsset: {[asset:string]: number},    
    totalSl: number,
    totalTp: number,
    stopLossBreachesByAsset: {[asset:string]: number},
    takeProfitBreachesByAsset: {[asset:string]: number},
    totalSlBreaches: number,
    totalTpBreaches: number,
}

export interface LiveSummaryStoreState {
    positionSummary: PositionSummary;
}

export interface PositionView {
    name: string;
    id: number;
    showSummary: boolean;
    showDifference: boolean;
    showPnLInPct: boolean;
    asOf?: Date;
    baseline?: Date;
    datesAreRelative: boolean;
    asOfRelative?: number;
    baselineRelative?: number;
    referenceDate?: Date;
    columnsIndices: {[col: string]: number}
    columnWidths: {[col: string]: number}
    columnVisibility: {[col: string]: boolean}
    density: GridDensity;
    filterModel: GridFilterModel;
    gridState: GridInitialStatePro;
    treeDefinition: string[];
    treeView: boolean;
}