const NumberAbbreviation = {
    BILLION: "b",
    MILLION: "m",
    THOUSAND: "k",
};

const NUMBER_ABBREVIATION_REGEX = /((\.\d+)|(\d+(\.\d+)?))(k|m|b)\b/gi;

export const roundValue = (value: number, precision: number = 1) => {
    // round to at most two decimal places
    return Math.round(value * 10 ** precision) / 10 ** precision;
};

export const nanStringToEmptyString = (value: string) => {
    // our evaluation logic isn't perfect, so use this as a final
    // sanitization step if the result was not a number.
    return value === "NaN" ? "" : value;
};

export const expandNumberAbbreviationTerms = (value: string) => {
    // leave empty strings empty
    if (!value) {
        return value;
    }
    return value.replace(NUMBER_ABBREVIATION_REGEX, expandAbbreviatedNumber);
};

export const expandAbbreviatedNumber = (value: string) => {
    if (!value) {
        return value;
    }

    const num = +value.substring(0, value.length - 1);
    const lastChar = value.charAt(value.length - 1).toLowerCase();

    let result: number | null = 0;

    if (lastChar === NumberAbbreviation.THOUSAND) {
        result = num * 1e3;
    } else if (lastChar === NumberAbbreviation.MILLION) {
        result = num * 1e6;
    } else if (lastChar === NumberAbbreviation.BILLION) {
        result = num * 1e9;
    }

    const isValid = result != null && !isNaN(result);

    if (isValid) {
        result = roundValue(result);
    }

    return isValid ? result.toString() : "";
};