import React from 'react';
import FBEmitter from 'fbemitter';
import moment from 'moment';
import { MenuItem, ThemeProvider } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { NewInstrumentForm } from './newInstrumentForm';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ListedInstrument } from './listedInstrumentModels';
import { CreateListedInstrument } from './listedInstrumentActions';
import { WrappedSelect } from '../inputs/wrappedSelect';

const newInstrumentTemplates = ["Bond", "Equity", "Index"];

type AddInstrumentComponentState = {
    selectedNewInstrumentTemplate: string,
}

/* underlyingId,
            assetId,
            type,
            ticker,
            isin,
            primaryExchange,
            optionStrike,

            optionIsCall,

            maturity,

            metaData*/

const NewInstrumentValidationSchema = Yup.object().shape({
    description: Yup.string()
        .required('Required'),
    type: Yup.string()
        .required('Required'),
    ticker: Yup.string()
        .required('Required'),
    primaryExchange: Yup.string()
        .required('Required'),
    ccy: Yup.string()
        .required('Required'),
    optionStrike: Yup.number()
        .when("type", {
            is: "Option",
            then: Yup.number().required('Required').positive(),
        }),
    optionIsCall: Yup.boolean()
        .when("type", {
            is: "Option",
            then: Yup.boolean().required('Required'),
        }),
    maturity: Yup.date()
        .when("type", {
            is: "Option",
            then: Yup.date().required('Required'),
        }),
});

export class AddInstrumentComponent extends React.Component<{}, AddInstrumentComponentState>{
    eventSubscriptionTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: any) {
        super(props)
        this.state = {
            selectedNewInstrumentTemplate: newInstrumentTemplates[0],
        };
    }

    async componentDidMount() {

    }

    componentWillUnmount() {

    }

    async onSubmitNewInstrument(values: ListedInstrument, { setSubmitting }: FormikHelpers<ListedInstrument>) {
        await CreateListedInstrument(values);
        setSubmitting(false);
    }

    renderAddTradeForm() {
        const { selectedNewInstrumentTemplate } = this.state;
        switch (selectedNewInstrumentTemplate) {
            case "Bond":
            case "Equity":
            case "Index":
                return (<Formik
                    onSubmit={this.onSubmitNewInstrument}
                    enableReinitialize={true}
                    initialValues={{ type: selectedNewInstrumentTemplate, multiplier: 1.0, createdUtc: moment.utc().toDate() } as ListedInstrument}
                    validationSchema={NewInstrumentValidationSchema}>
                    {props => <NewInstrumentForm className="NewTradeForm" {...props} />}
                </Formik>);
        }
    }


    render() {
        const { selectedNewInstrumentTemplate } = this.state;
        return (
            <ThemeProvider theme={getFormTheme()}>
                <div style={{padding: "5px"}}>
                    <WrappedSelect
                        id="newTradeTemplate"
                        name="newTradeTemplate"
                        label="Template"
                        value={selectedNewInstrumentTemplate}
                        onChange={(v) => { this.setState({ selectedNewInstrumentTemplate: v.target.value as string }) }}>
                        {newInstrumentTemplates.map(a =>
                            <MenuItem key={"affki" + a} value={a}>{a}</MenuItem>)}
                    </WrappedSelect>
                    <div className="AddTradeMain">{this.renderAddTradeForm()}</div>
                </div>
            </ThemeProvider>
        );
    }
}