import { BookStr, StrategyStr, AssetStr, AttributionStr } from "../globalConstants";

export const DefaultColumns = (metric: string) => {
    switch (metric) {
        case "PV":
        default:
            return ["Instrument", "Underlying", "TradeType", BookStr, StrategyStr, AssetStr, "PointLabel", "PointDate", AttributionStr, "Value"];
        case "AssetDelta":
            return ["Underlying", BookStr, StrategyStr, AssetStr, "PointLabel", "PointDate", AttributionStr, "Value"];
        case "AssetGamma":
            return ["Underlying", BookStr, StrategyStr, AssetStr, "PointLabel", "PointDate", AttributionStr, "Metric", "Value"];
        case "FxDelta":
            return ["Underlying", BookStr, StrategyStr, AssetStr, "AssetId", AttributionStr, "Value"];
        case "FxGamma":
            return ["Underlying", BookStr, StrategyStr, AssetStr, "AssetId", AttributionStr, "Metric", "Value"];
        case "PnLAttribution":
            return ["Step", "SubStep", "SubSubStep", "Value"];
    }
}