import { Designer, Viewer } from "@grapecity/activereports-react";
import React, { useState } from 'react';
import { GetToken } from "../utils/httpUtils";
import * as ArDesignerNs from '@grapecity/activereports/reportdesigner';
import { Report } from "./reportModels";
import { AddOrUpdateReport, DeleteReport, LoadReports } from "./reportActions";
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { typedDeferred } from "../utils/deferred";
import moment from "moment";
import { CancelOutlined, DeleteForeverOutlined, DownloadOutlined, DriveFileRenameOutlineOutlined, ImportExportOutlined, SaveOutlined } from "@mui/icons-material";
import { getFormTheme } from "../inputs/formCommon";
import { WrappedCheckBox } from "../inputs/wrappedCheckBox";
import reportStoreInstance from "./reportStore";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { Core } from "@grapecity/activereports";
import { ApiLocation } from "../globalConstants";
import _ from "lodash";
import userStoreInstance from "../user/userStore";

Core.setLicenseKey("galena.pltfm.app,349518242346337#B0EkXwYUT9sEaBRDb0pUSBplMiFDM7AzK64GavsmW5dFaPdkWDpFOQN4L7xmS9ImVmtWeT3yVDt4QJNkd4UkbHVnQIplehlke0ZDWah7TiNGS4k7ZrEHOqp5VnNVTvw4VX9GN4tUclZDaOllatJjeUtGMplVUnBXUDJmUkxEUapWaTZkboZFZPZGSWJ6Uh3kMClkRuVTQjRWVWF7dwY7ZQN4cCBVdwUzY5wkNn9Ec89mYwAnM0h7bwITQCBVMElnYrlzT72kauhGd7w4MrVlSrckWrEUbVRHU9YGNOR7NCl4YQNzMDpkMDdnWVdEMtt6ZLhEZiojITJCLiQEM5Q4NBFjI0ICSiwSMwYDNykjNwUTM0IicfJye&Qf35Vfi24QRpkI0IyQiwiIzYFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsICN4YDM9ADIxAzMwIjMwIjI0ICdyNkIsICcwFmLtZGdsBnLh9WZsF6ZiojIz5GRiwiIwBXYu4mZ4xGcAZXYnJiOiEmTDJCLiczMzYDNzIDNygTM5kDNzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7h6RDFlYxdFZt34REVFaTZWQShVOlVFMuFGOB3WV9skbBZHR92CMR3COkV6UWJjN8QzZvsURqFVMrN6TxtkMyQDOyhTZDRjWV96Rlp7U5MTSv5We58EcysWdlpGV6siQ7n48v");

const emptyReport = {
    definition: {
        ReportParameters: [{
            DataType: "Date",
            Name: "AsOf",
            Prompt: "As-Of Date",

        }],
        Body: {},
        FixedPage: {}
    },

} as ArDesignerNs.Report;

export const ReportDesignerTab: React.FC = () => {

    const [loadDialogBoxOpen, setLoadDialogBoxOpen] = useState(false);
    const [loadReportId, setLoadReportId] = useState(undefined);
    const [saveDialogBoxOpen, setSaveDialogBoxOpen] = useState(false);
    const [saveReportName, setSaveReportName] = useState(undefined);
    const [saveReportCategory, setSaveReportCategory] = useState(undefined);
    const [newReportInfo, setNewReportInfo] = useState<ArDesignerNs.SaveNewReportInfo>(undefined);
    const [saveReportIsPublic, setSaveReportIsPublic] = useState(false);
    const [dataSources, setDataSources] = useState([]);
    const [previewReport, setPreviewReport] = useState(undefined);
    const [clearNextRender, setclearNextRender] = useState(false);
    const [showDeleteAreYouSure, setShowDeleteAreYouSure] = useState<number>(undefined);
    const [showRename, setShowRename] = useState<number>(undefined);
    const [newReportName, setNewReportName] = useState<string>(undefined);
    const [actionPending, setActionPending] = useState<boolean>(false);

    const viewerRef = React.useRef<Viewer>();
    const designerRef = React.useRef<Designer>();
    const downloadRef = React.useRef<HTMLAnchorElement>();
    const uploadRef = React.useRef<HTMLInputElement>();
    const fileDownloadUrl = React.useRef<string>();

    const saveAsDeferred = React.useRef(typedDeferred<ArDesignerNs.SaveAsResult>());
    const loadDeferred = React.useRef(typedDeferred<ArDesignerNs.Report>());
    const token = React.useRef(undefined);

    React.useEffect(() => {
        token.current = GetToken();
        GetDataConnections();

        const viewerInstance = viewerRef.current?.Viewer;
        viewerInstance?.toolbar.addItem({
            key: "$backToDesigner",
            icon: { type: 'svg', content: <img alt="exit" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABFElEQVRIie3WPUoDURQF4M8fbKzcgeASAgqioEV6XYFgoY0/W8gSTKmVS7AXbEIC/jQuQLAQU1rZCBqLURiMM8mbeRklemCYB/eee4bDnXcvfw0TObFVLGGqYO1XXKIVQjpEL9KzHyLcjSjcDRH+JDVCSF/QSNXpw2SJwqXwLxwTp7iqUnjj430vuQuaIeQyXd3DMWbykkZl9Q46WMhKmC5YeA61ATk1XGMbZ8MWHmT1mrDbq8/6H/udilp9i3pG7Dx1fhLZ6mG4N3Kaa1RWn2AZd1UKb2IXL5KGOgohxxiL85INpPKxuIXFrOBYTqffKZyFR/GWvYcQ4YNIom/Y+04gb6FfwTpmQ744hWdcoF2QP2Z4B1g6g9CiaElbAAAAAElFTkSuQmCC" /> },
            text: "Designer",
            enabled: true,
            title: "Back To Designer",
            action: function () {
                setPreviewReport(undefined);
            },
        });

        designerRef.current?.setReport(emptyReport, 'ask');

    }, []);

    function GetDataConnections() {
        var rawSets = reportStoreInstance.getDataSets();
        const tokenStr = ";Header$Authorization=Bearer " + token;

        rawSets.forEach(set => {
            set.connectionProperties.connectionString = set.connectionProperties.connectionString.replaceAll("[¬%API%¬]", ApiLocation);
            set.connectionProperties.connectionString = set.connectionProperties.connectionString.replaceAll("[¬%TOKEN%¬]", tokenStr);
        });

        var sources = rawSets.map(r => {
            var dataSetObj = r.dataSets.map(ds => {
                return {
                    Name: ds.name,
                    Parent: ds.parent,
                    Fields: ds.fields.map(f => {
                        return { Name: f.name, DataField: f.dataField }
                    }),
                    Query: {
                        DataSourceName: ds.query.commandText !== null ? r.name : ds.query.dataSourceName,
                        CommandText: ds.query.commandText !== null ? ds.query.commandText : undefined
                    },
                    AccentSensitivity: ds.accentSensitivity,
                    CaseSensitivity: ds.caseSensitivity,
                    KanatypeSensitivity: ds.kanatypeSensitivity,
                    WidthSensitivity: ds.widthSensitivity
                }
            });
            var childrenByParent = _.groupBy(dataSetObj, ds => ds.Parent ?? "none");
            return {
                id: r.name,
                title: r.name,
                name: r.name,
                canEdit: true,
                template: {
                    Name: r.name,
                    ConnectionProperties: {
                        DataProvider: r.connectionProperties.dataProvider,
                        ConnectString: r.connectionProperties.connectionString,
                    },
                },
                datasets:
                    r.dataSets.filter(ds => ds.parent === undefined || ds.parent === null).map((ds) => {
                        return {
                            id: ds.name,
                            //canEdit: true,
                            title: ds.name,
                            name: ds.name,
                            template: {
                                Name: ds.name,
                                Fields: ds.fields.map(f => {
                                    return { Name: f.name, DataField: f.dataField }
                                }),
                                Query: {
                                    DataSourceName: ds.query.commandText !== null ? r.name : ds.query.dataSourceName,
                                    CommandText: ds.query.commandText !== null ? ds.query.commandText : undefined
                                },
                                AccentSensitivity: ds.accentSensitivity,
                                CaseSensitivity: ds.caseSensitivity,
                                KanatypeSensitivity: ds.kanatypeSensitivity,
                                WidthSensitivity: ds.widthSensitivity
                            },
                            nestedTemplates: childrenByParent[ds.name]
                        } as ArDesignerNs.DataSetTemplate
                    })
            } as ArDesignerNs.DataSourceTemplate
        });
        setDataSources(sources);
    }


    async function saveReport(info: ArDesignerNs.SaveReportInfo): Promise<ArDesignerNs.SaveResult> {
        if (info.id === undefined) { //re-route to save as
            var newReportInfo = {
                definition: info.definition,
                displayName: info.displayName
            } as ArDesignerNs.SaveNewReportInfo
            setSaveDialogBoxOpen(true);
            setNewReportInfo(newReportInfo);
            return;
        }

        var data = JSON.stringify(info);
        var info2 = JSON.parse(data) as ArDesignerNs.SaveReportInfo;

        const tokenStr = ";Header$Authorization=Bearer " + token;
        info2.definition.DataSources.forEach(ds => {
            ds.ConnectionProperties.ConnectString = ds.ConnectionProperties.ConnectString.replaceAll(ApiLocation, "[¬%API%¬]");
            ds.ConnectionProperties.ConnectString = ds.ConnectionProperties.ConnectString.replaceAll(tokenStr, "[¬%TOKEN%¬]");
        });
        data = JSON.stringify(info2);

        var existing = reportStoreInstance.getReports().filter(r => r.reportId.toString() === info2.id)[0];
        var r = {
            blob: data,
            name: info2.displayName,
            reportId: Number(info2.id),
            category: existing?.category,
            isPublic: existing?.isPublic,
            ownerId: existing?.ownerId
        } as Report;

        await AddOrUpdateReport(r);
        return { displayName: info2.displayName } as ArDesignerNs.SaveResult;
    }

    async function saveNewReport(info: ArDesignerNs.SaveNewReportInfo): Promise<ArDesignerNs.SaveAsResult> {
        setSaveDialogBoxOpen(true);
        setNewReportInfo(info);
        saveAsDeferred.current = typedDeferred<ArDesignerNs.SaveAsResult>();
        return saveAsDeferred.current.promise;
    }

    async function onClickSaveAs() {
        setActionPending(true);
        try {
            newReportInfo.displayName = saveReportName;

            var data = JSON.stringify(newReportInfo);
            var info2 = JSON.parse(data) as ArDesignerNs.SaveNewReportInfo;

            const tokenStr = ";Header$Authorization=Bearer " + token;
            info2.definition.DataSources.forEach(ds => {
                ds.ConnectionProperties.ConnectString = ds.ConnectionProperties.ConnectString.replaceAll(ApiLocation, "[¬%API%¬]");
                ds.ConnectionProperties.ConnectString = ds.ConnectionProperties.ConnectString.replaceAll(tokenStr, "[¬%TOKEN%¬]");
            });
            data = JSON.stringify(info2);

            var r = {
                blob: data,
                name: saveReportName,
                category: saveReportCategory,
                isPublic: saveReportIsPublic,
                createdUtc: moment.utc().toISOString()
            } as Report;

            r = await AddOrUpdateReport(r);

            newReportInfo.id = r.reportId.toString();

            saveAsDeferred.current.resolve({
                displayName: saveReportName,
                id: r.reportId.toString()
            } as ArDesignerNs.SaveAsResult);
            setSaveDialogBoxOpen(false);
        }
        finally {
            setActionPending(false);
        }
    }

    function exitSaveProcess() {
        saveAsDeferred.current.resolve({
            displayName: newReportInfo.displayName,
            id: newReportInfo.id
        } as ArDesignerNs.SaveAsResult);
        setSaveDialogBoxOpen(false);
    }

    async function onLoadReport(): Promise<ArDesignerNs.Report> {
        setLoadDialogBoxOpen(true);
        loadDeferred.current = typedDeferred<ArDesignerNs.Report>();
        return loadDeferred.current.promise;
    }

    function onClickExport() {
        var data = JSON.stringify(newReportInfo);
        var info2 = JSON.parse(data) as ArDesignerNs.SaveNewReportInfo;
        info2.displayName = saveReportName;
        const tokenStr = ";Header$Authorization=Bearer " + token;
        info2.definition.DataSources.forEach(ds => {
            ds.ConnectionProperties.ConnectString = ds.ConnectionProperties.ConnectString.replaceAll(ApiLocation, "[¬%API%¬]");
            ds.ConnectionProperties.ConnectString = ds.ConnectionProperties.ConnectString.replaceAll(tokenStr, "[¬%TOKEN%¬]");
        });
        data = JSON.stringify(info2);

        const blob = new Blob([data]);
        fileDownloadUrl.current = URL.createObjectURL(blob);
        exitSaveProcess();
    }

    function renderSaveDialog() {
        return <ThemeProvider theme={getFormTheme()}>
            <Dialog open={saveDialogBoxOpen} >
                <DialogTitle>Save Report</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} paddingTop="0.5em">
                        <Grid item>
                            <TextField variant="outlined" onChange={(e) => setSaveReportName(e.target.value)} value={saveReportName ?? ""} label="Report Name" />
                        </Grid>
                        <Grid item>
                            <TextField variant="outlined" onChange={(e) => setSaveReportCategory(e.target.value)} value={saveReportCategory ?? ""} label="Report Category" />
                        </Grid>
                        <Grid item>
                            <WrappedCheckBox onChange={(e, c) => setSaveReportIsPublic(c)} value={saveReportIsPublic} label="Public" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="PltfmButtonLite" onClick={onClickExport} disabled={actionPending} endIcon={<ImportExportOutlined />}>Export</Button>
                    <Button className="PltfmButtonLite" onClick={onClickSaveAs} disabled={actionPending} endIcon={<SaveOutlined />}>Save</Button>
                    <Button className="PltfmButtonLite" onClick={exitSaveProcess} disabled={actionPending} endIcon={<CancelOutlined />}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    }

    function onClickLoad() {
        var report = reportStoreInstance.getReports().filter(r => r.reportId === loadReportId)[0];
        if (report) {
            const tokenStr = ";Header$Authorization=Bearer " + token;
            report.blob = report.blob.replaceAll("[¬%API%¬]", ApiLocation);
            report.blob = report.blob.replaceAll("[¬%TOKEN%¬]", tokenStr);
            var arReport = pltfmToArReport(report);
            loadDeferred.current.resolve(arReport);
        }
        else {
            loadDeferred.current.reject("Error loading report");
        }
        setLoadDialogBoxOpen(false);
    }

    async function deleteReport() {
        setActionPending(true);
        try {
            var report = reportStoreInstance.getReports().find(r => r.reportId === showDeleteAreYouSure);
            if (report) {
                await DeleteReport(report);
                await LoadReports();
            }
            setShowDeleteAreYouSure(undefined);
        }
        finally {
            setActionPending(false);
        }
    }

    async function onClickRename() {
        setActionPending(true);
        try {
            var report = reportStoreInstance.getReports().find(r => r.reportId === showRename);
            if (report) {
                report.name = newReportName;
                var arReport = JSON.parse(report.blob) as ArDesignerNs.Report;
                arReport.displayName = newReportName;
                report.blob = JSON.stringify(arReport);
                await AddOrUpdateReport(report);
                await LoadReports();
            }
            setShowRename(undefined);
            setNewReportName(undefined);
        }
        finally {
            setActionPending(false);
        }
    }

    function onImportDataLoad(ev: ProgressEvent<FileReader>) {
        var fileContents = ev.target.result.toString();
        const tokenStr = ";Header$Authorization=Bearer " + token;
        fileContents = fileContents.replaceAll("[¬%API%¬]", ApiLocation);
        fileContents = fileContents.replaceAll("[¬%TOKEN%¬]", tokenStr);
        var report = JSON.parse(fileContents) as ArDesignerNs.Report;
        if (report) {
            loadDeferred.current.resolve(report);
        }
        else {
            loadDeferred.current.reject("Error loading report");
        }
        setLoadDialogBoxOpen(false);
    }
    function onClickImport(event: React.ChangeEvent<HTMLInputElement>) {
        const fileObj = event.target.files[0];
        const reader = new FileReader();
        reader.onload = onImportDataLoad;
        reader.readAsText(fileObj);
    }

    function pltfmToArReport(report: Report) {
        if (report) {
            var arReport = JSON.parse(report.blob) as ArDesignerNs.Report;
            arReport.id = report.reportId.toString();
            return arReport;
        }
        return undefined;
    }

    // function pltfmToArReportInfo(report: Report) {
    //     if (report) {
    //         var arReport = JSON.parse(report.blob) as ArDesignerNs.Report;
    //         arReport.id = report.reportId.toString();
    //         var info = {
    //             id: arReport.id,
    //             displayName: arReport.displayName
    //         } as ArDesignerNs.ReportResourceInfo;
    //         return info;
    //     }
    //     return undefined;
    // }

    function onChangeSelectedReport(event: SelectChangeEvent<any>, child: React.ReactNode) {
        var v = event.target.value as number;
        if (v) {
            setLoadReportId(v);
        }
        else if (v === 0) {
            setLoadReportId(undefined);
        }
    }

    function renderLoadDialog() {
        var allReports = reportStoreInstance.getReports();
        return <ThemeProvider theme={getFormTheme()}>
            <Dialog open={loadDialogBoxOpen} >
                <DialogTitle>Load Report</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} paddingTop="0.5em">
                        <Grid item>
                            <WrappedSelect
                                label="Report"
                                value={loadReportId}
                                onChange={onChangeSelectedReport}
                                style={{ width: "200px" }}
                            >
                                {allReports.map(r => <MenuItem key={r.reportId} value={r.reportId}>{r.name}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                    </Grid>
                    <input type="file" style={{ display: "hidden" }} hidden
                        multiple={false}
                        accept=".json,application/json"
                        onChange={evt => onClickImport(evt)}
                        ref={uploadRef} />
                </DialogContent>
                <DialogActions>
                    <Button className="PltfmButtonLite" onClick={() => uploadRef.current.click()} endIcon={<ImportExportOutlined />}>Import</Button>
                    <Button className="PltfmButtonLite" disabled={loadReportId === undefined} onClick={onClickLoad} endIcon={<DownloadOutlined />}>Load</Button>
                    <Button className="PltfmButtonLite" disabled={loadReportId === undefined} onClick={() => setShowDeleteAreYouSure(loadReportId)} endIcon={<DeleteForeverOutlined />}>Delete</Button>
                    <Button className="PltfmButtonLite" disabled={loadReportId === undefined} onClick={() => setShowRename(loadReportId)} endIcon={<DriveFileRenameOutlineOutlined />}>Rename</Button>
                    <Button className="PltfmButtonLite" onClick={() => setLoadDialogBoxOpen(false)} endIcon={<CancelOutlined />}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    }

    async function onPreview(report: ArDesignerNs.ReportDefinition, resourceLocator?: Partial<Core.ResourceLocator>) {
        setPreviewReport(report);
        if (viewerRef !== undefined && viewerRef.current !== undefined)
            viewerRef.current?.open(report.definition);
    }

    async function onOpen() {
        return emptyReport;
    }

    const previewMode = previewReport !== undefined

    if (fileDownloadUrl.current && !clearNextRender) {
        setclearNextRender(true);
    }

    if (clearNextRender) {
        downloadRef.current.href = fileDownloadUrl.current;
        downloadRef.current.click();
        URL.revokeObjectURL(fileDownloadUrl.current);
        fileDownloadUrl.current = undefined;
        setclearNextRender(false);
    }

    function renderDeleteAreYouSure() {
        return (
            <ThemeProvider theme={getFormTheme()}>
                <Dialog open={showDeleteAreYouSure !== undefined} onClose={() => setShowDeleteAreYouSure(undefined)}  >
                    <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Are you sure?</DialogTitle>
                    <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete report {reportStoreInstance.getReports().find(r => r.reportId === showDeleteAreYouSure)?.name}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                        <Button variant="outlined" disabled={actionPending} onClick={() => deleteReport()}>Delete</Button>
                        <Button variant="outlined" disabled={actionPending} onClick={() => setShowDeleteAreYouSure(undefined)} autoFocus>Cancel</Button>
                    </DialogActions>
                </Dialog >
            </ThemeProvider>);
    }

    function renderRenameReport() {
        var existing = reportStoreInstance.getReports().find(r => r.reportId === showRename)?.name;
        return (<CssBaseline>
            <ThemeProvider theme={getFormTheme()}>
                <Dialog open={showRename !== undefined} onClose={() => setShowRename(undefined)}  >
                    <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Rename report</DialogTitle>
                    <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container spacing={2} paddingTop="0.5em">
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="New Name"
                                        name="New Name"
                                        size="small"
                                        helperText={"New report name"}
                                        label="New Name"
                                        value={newReportName ?? existing}
                                        onChange={(e) => setNewReportName(e.target.value)}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                        <Button variant="outlined" disabled={actionPending} onClick={() => onClickRename()}>Rename</Button>
                        <Button variant="outlined" disabled={actionPending} onClick={() => setShowRename(undefined)} autoFocus>Cancel</Button>
                    </DialogActions>
                </Dialog >
            </ThemeProvider>
            </CssBaseline>);
    }

    return (<div id="designer-host" style={{ paddingTop: "5px", width: "100%", height: "calc(100vh - 100px)" }}>
        {renderSaveDialog()}
        {renderLoadDialog()}
        <div style={{ display: previewMode ? "none" : "block", height: "100%" }}>
            <Designer
                ref={designerRef}
                onSave={saveReport}
                onSaveAs={saveNewReport}
                onOpen={onLoadReport}
                dataSources={dataSources}
                onRender={onPreview}
                onCreate={onOpen}
            />
        </div>
        <div style={{ display: previewMode ? "flex" : "none", flexDirection: "column", height: "100%" }}>
            <Viewer ref={viewerRef} />
        </div>
        <div>
            {renderDeleteAreYouSure()}
            {renderRenameReport()}
        </div>
        <div>
            <Link style={{ display: "none" }}
                download={saveReportName + ".json"}
                href={fileDownloadUrl.current}
                ref={downloadRef}
            >Download</Link>
        </div>
    </div>)

}