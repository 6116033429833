import {
  CssBaseline,
  StyledEngineProvider,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import './App.scss';
import {reduxStore} from './reduxStore'
import BootStrapControl from './bootstrap/bootstrapControl';
import moment from 'moment';

const emptyTheme = createTheme();

export const SaturdayDoW = 5;
export const SundayDoW = 6;

const App: React.FC = () => {
  moment.updateLocale("en", {
    week: {
      dow: 1
    }
  });
  moment.updateLocale("fr", {
    week: {
      dow: 1
    }
  });
  moment.updateLocale("gr", {
    week: {
      dow: 1
    }
  });

  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")
  {
    document.title="<Dbg>";
  }
    
  
  return (
    <Provider store={reduxStore}>
    <CssBaseline>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={emptyTheme}>
          <SnackbarProvider maxSnack={5}>
            <BootStrapControl></BootStrapControl>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CssBaseline>
    </Provider>
  );
}

export default App;
