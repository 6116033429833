import { binarySearch } from "../../utils/math";
import { IInterpolator1D } from "../iInterpolator1d";
import { Interpolator1DType } from "../interpolator1DType";

export default class CubicHermiteSplineInterpolator implements IInterpolator1D {

    _x: number[];
    _y: number[];
    _tangents: number[];
    _minX: number;
    _maxX: number;
    _monotone: boolean = false;

    Type: Interpolator1DType = Interpolator1DType.linear;

    MinX() { return this._minX };
    MaxX() { return this._maxX };

    constructor(x: number[], y: number[], tangents?: number[], monotone?: boolean) {
        this._x = x;
        this._y = y;
        this._minX = x[0];
        this._maxX = x[x.length - 1];
        this._tangents = new Array<number>(x.length);
        this._monotone = monotone;

        if (tangents)
            this._tangents = tangents;
        else
            this.setup();
    }

    findFloorPoint(t: number) {
        var index = binarySearch(this._x, t);
        if (index < 0) {
            index = ~index - 1;
        }

        return Math.min(Math.max(index, 0), this._x.length - 2);
    }

    setup() {
        if (this._x.length === 1)
            return;

        var dk = new Array<number>(this._tangents.length - 1);
        this._tangents[0] = (this._y[1] - this._y[0]) / (this._x[1] - this._x[0]);
        dk[0] = this._tangents[0];
        for (var i = 1; i < this._tangents.length - 1; i++) {
            dk[i] = (this._y[i + 1] - this._y[i]) / (this._x[i + 1] - this._x[i]);
            this._tangents[i] = 0.5 * (dk[i] + dk[i - 1]);
        }
        var l = this._tangents.length - 1;
        this._tangents[l] = (this._y[l] - this._y[l - 1]) / (this._x[l] - this._x[l - 1]);

        if (this._monotone) {
            for (i = 0; i < dk.length; i++) {
                if (dk[i] === 0) {
                    this._tangents[i] = 0;
                    this._tangents[i + 1] = 0;
                }
                else {
                    var a = this._tangents[i] / dk[i];
                    var b = this._tangents[i + 1] / dk[i];
                    if (a > 3)
                        this._tangents[i] = dk[i] * 3.0;
                    if (b > 3)
                        this._tangents[i + 1] = dk[i] * 3.0;
                }
            }
        }
    }

    private H00 = (t: number) => 2 * t * t * t - 3 * t * t + 1.0;
    private H10 = (t: number) => t * t * t - 2 * t * t + t;
    private H01 = (t: number) => -2 * t * t * t + 3 * t * t;
    private H11 = (t: number) => t * t * t - t * t;

    private H00d = (t: number) => 6 * t * t - 6 * t;
    private H10d = (t: number) => 3 * t * t - 4 * t + 1.0;
    private H01d = (t: number) => -6 * t * t + 6 * t;
    private H11d = (t: number) => 3 * t * t - 2 * t;

    private H00dd = (t: number) => 12 * t - 6;
    private H10dd = (t: number) => 6 * t - 4;
    private H01dd = (t: number) => -12 * t + 6;
    private H11dd = (t: number) => 6 * t - 2;

    private H00i = (t: number) => 0.5 * t * t * t * t - t * t * t + t;
    private H10i = (t: number) => 0.25 * t * t * t * t - 2.0 / 3.0 * t * t * t + 0.5 * t * t;
    private H01i = (t: number) => -0.5 * t * t * t * t + t * t * t;
    private H11i = (t: number) => 0.25 * t * t * t * t - 1.0 / 3.0 * t * t * t;

    public Interpolate(t: number) {
        if (this._x.length === 1) {
            return this._y[0];
        }
        else if (t <= this._minX) //linear extrapolation
        {
            return this._y[0] + (this._y[1] - this._y[0]) / (this._x[1] - this._x[0]) * (t - this._x[0]);
        }
        else if (t >= this._maxX) //linear extrapolation
        {
            var k = this._y.length - 1;
            return this._y[k] + (this._y[k] - this._y[k - 1]) / (this._x[k] - this._x[k - 1]) * (t - this._x[k]);
        }
        else {
            k = this.findFloorPoint(t);

            var interval = (this._x[k + 1] - this._x[k]);
            var tt = (t - this._x[k]) / interval;
            return this.H00(tt) * this._y[k]
                + this.H10(tt) * interval * this._tangents[k]
                + this.H01(tt) * this._y[k + 1]
                + this.H11(tt) * interval * this._tangents[k + 1];
        }
    }

    public FirstDerivative(x: number) {
        if (this._x.length === 1) {
            return 0;
        }
        else if (x <= this._minX) //linear extrapolation
        {
            return (this._y[1] - this._y[0]) / (this._x[1] - this._x[0]);
        }
        else if (x >= this._maxX) //linear extrapolation
        {
            var k = this._y.length - 1;
            return (this._y[k] - this._y[k - 1]) / (this._x[k] - this._x[k - 1]);
        }
        else {
            k = this.findFloorPoint(x);

            var interval = (this._x[k + 1] - this._x[k]);
            var t = (x - this._x[k]) / interval;
            var dVdt = this.H00d(t) * this._y[k]
                + this.H10d(t) * interval * this._tangents[k]
                + this.H01d(t) * this._y[k + 1]
                + this.H11d(t) * interval * this._tangents[k + 1];
            var dVdx = 1 / interval;
            return dVdt * dVdx;
        }
    }

    public SecondDerivative(x: number): number {
        if (this._x.length === 1 || x < this._minX || x > this._maxX) //linear extrapolation
        {
            return 0;
        }
        else if (x === this._minX || x === this._maxX) //numerical approx at linear bounds
        {
            var d = 0.00000001;
            return (this.FirstDerivative(x + d / 2) - this.FirstDerivative(x - d / 2)) / d;
        }
        else {
            var k = this.findFloorPoint(x);

            var interval = (this._x[k + 1] - this._x[k]);
            var t = (x - this._x[k]) / interval;
            var d2Vdt2 = this.H00dd(t) * this._y[k]
                + this.H10dd(t) * interval * this._tangents[k]
                + this.H01dd(t) * this._y[k + 1]
                + this.H11dd(t) * interval * this._tangents[k + 1];;
            var d2Vdx2 = (1 / interval) * (1 / interval);
            return d2Vdt2 * d2Vdx2;
        }
    }

    public DefiniteIntegral(a: number, b: number) {
        if (this._x.length === 1) {
            return this._y[0] * (b - a);
        }

        var integral = 0.0;
        var x = a;
        while (x < b) {
            if (x < this._minX) //linear extrapolation
            {
                var y1 = this.Interpolate(this._minX);
                var y0 = this.Interpolate(x);
                integral += (y1 - (y1 - y0) / 2.0) * (this._minX - x);
                x = this._minX;
            }
            else if (x >= this._maxX) //linear extrapolation
            {
                y1 = this.Interpolate(b);
                y0 = this.Interpolate(this._maxX);
                integral += (y1 - (y1 - y0) / 2.0) * (b - this._maxX);
                x = b;
            }
            else {
                var k = this.findFloorPoint(x);

                var fullSegmentR = (k < this._x.length - 2 && this._x[k + 1] < b) || (k === this._x.length - 1 && this._maxX < b);
                var fullSegmentL = x <= this._x[k];
                var fullSegment = fullSegmentL && fullSegmentR;
                var interval = (this._x[k + 1] - this._x[k]);

                if (fullSegment) {
                    var i1 = this.H00i(1.0) * this._y[k]
                        + this.H10i(1.0) * interval * this._tangents[k]
                        + this.H01i(1.0) * this._y[k + 1]
                        + this.H11i(1.0) * interval * this._tangents[k + 1];
                    var i0 = this.H00i(0.0) * this._y[k]
                        + this.H10i(0.0) * interval * this._tangents[k]
                        + this.H01i(0.0) * this._y[k + 1]
                        + this.H11i(0.0) * interval * this._tangents[k + 1];
                    integral += (i1 - i0) * interval;
                    x = k < this._x.length - 2 ? this._x[k + 1] : this._maxX;
                }
                else if (fullSegmentR) {
                    var t = (x - this._x[k]) / interval;
                    i1 = this.H00i(1.0) * this._y[k]
                        + this.H10i(1.0) * interval * this._tangents[k]
                        + this.H01i(1.0) * this._y[k + 1]
                        + this.H11i(1.0) * interval * this._tangents[k + 1];
                    i0 = this.H00i(t) * this._y[k]
                        + this.H10i(t) * interval * this._tangents[k]
                        + this.H01i(t) * this._y[k + 1]
                        + this.H11i(t) * interval * this._tangents[k + 1];
                    integral += (i1 - i0) * interval;
                    x = k < this._x.length - 2 ? this._x[k + 1] : this._maxX;
                }
                else {
                    var t1 = (b - this._x[k]) / interval;
                    var t0 = (x - this._x[k]) / interval;
                    i1 = this.H00i(t1) * this._y[k]
                        + this.H10i(t1) * interval * this._tangents[k]
                        + this.H01i(t1) * this._y[k + 1]
                        + this.H11i(t1) * interval * this._tangents[k + 1];
                    i0 = this.H00i(t0) * this._y[k]
                        + this.H10i(t0) * interval * this._tangents[k]
                        + this.H01i(t0) * this._y[k + 1]
                        + this.H11i(t0) * interval * this._tangents[k + 1];
                    integral += (i1 - i0) * interval;
                    x = b;
                }
            }
        }
        return integral;
    }
}