import moment, { Moment } from "moment";
import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import { GetFixings, GetOptionpricingParams, MarketDataAvailableTypesEvent, MarketDataInstrumentsWithFixingsLoadEvent, MarketDataNewAvailableVolSurfacesEvent, MarketDataNewCandlesRestEvent, MarketDataNewCandleSummariesEvent, MarketDataNewFixingsEvent, MarketDataNewModelEvent, MarketDataNewOptionParamsEvent, MarketDataSetNamesLoadEvent, MarketDataVolSurfaceEvent } from "./marketDataActions";
import { AvailableMarketDataReponse, BlobDescriptor, MarketDataStoreState, OptionPricingParameters } from "./marketDataModels";
import { Candle } from "./models/candle";
import { CandlesSummary } from "./models/getCandleTickersResponse";
import { FixingPoint, SetDescription } from "./models/fixingPoint";
import { TO_AssetFxModel } from "../qwack/to_AssetFxModel";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import { DefaultSetName } from "../globalConstants";
import { TO_GridVolSurface } from "../qwack/to_GridVolSurface";

class MarketDataStore extends FluxStore<MarketDataStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatcherHandler(e), () => ({
            candles: new Map<string, Map<number, { [size: number]: Candle[] }>>(),
            fixings: new Map<string, Map<number, FixingPoint[]>>(),
            setNames: new Array<string>(),
            insWithFixings: new Map<string, number[]>(),
            candleSummaries: {},
            models: new Map<string, TO_AssetFxModel>(),
            optionPricingParams: new Map<string, OptionPricingParameters>(),
            optionPricingParamRequests: new Set<string>(),
            volSurfaceDescriptions: new Map<string, BlobDescriptor[]>(),
            volSurfaceDatesByIns: new Map<number, Set<Date>>(),
            volSurfaces: new Map<string, TO_GridVolSurface>(),
            dataTypesByInsId: new Map<number, AvailableMarketDataReponse>(),
            sets: new Map<number, SetDescription>()
        }))
    }

    private onDispatcherHandler(action: Event) {
        if (action instanceof MarketDataNewCandleSummariesEvent) {
            if (action.payload) {
                this.state.candleSummaries = action.payload.allCandles;
                this.emitChange();
            }
        }
        else if (action instanceof MarketDataNewCandlesRestEvent) {
            var obj = action.payload;
            if (obj.candles !== null) {
                var existingForSet = this.state.candles.get(obj.setName);
                if (!existingForSet) {
                    this.state.candles.set(obj.setName, new Map<number, { [size: number]: Candle[] }>());
                    existingForSet = this.state.candles.get(obj.setName);
                }
                var existingForIns = existingForSet.get(obj.instrumentId);
                if (!existingForIns) {
                    existingForSet.set(obj.instrumentId, {} as { [size: number]: Candle[] });
                    existingForIns = existingForSet.get(obj.instrumentId);
                }

                var fixedDateCandles = obj.candles.map(c => {
                    return {
                        close: c.close,
                        high: c.high,
                        low: c.low,
                        open: c.open,
                        time: new Date(c.time),
                        volume: c.volume
                    } as Candle
                });
                existingForIns[obj.candleSizeSeconds] = fixedDateCandles;
                this.emitChange();
            }
        }
        else if (action instanceof MarketDataSetNamesLoadEvent) {
            var setNames = action.payload;
            this.state.setNames = setNames;
            this.emitChange();
        }
        else if (action instanceof MarketDataInstrumentsWithFixingsLoadEvent) {
            var sets = action.payload;
            sets.forEach(set => {
                this.state.insWithFixings.set(set.setName, set.instrumentIds);
                this.state.setNames.push(set.setName);
                this.state.sets.set(set.setId, set);
            });
            this.emitChange();
        }
        else if (action instanceof MarketDataNewFixingsEvent) {
            var fixings = action.payload;
            var fixingsForSet = this.state.fixings.get(fixings.setName);
            if (!fixingsForSet) {
                fixingsForSet = new Map<number, FixingPoint[]>();
                this.state.fixings.set(fixings.setName, fixingsForSet);
            }
            fixingsForSet.set(fixings.instrumentId, fixings.fixings);
            this.emitChange();
        }
        else if (action instanceof MarketDataNewModelEvent) {
            var model = action.payload;
            this.state.models.set(moment(model.buildDate).format("yyyy-MM-DD"), model);
            this.emitChange();
        }
        else if (action instanceof MarketDataNewOptionParamsEvent) {
            var params = action.payload;
            var key = this.getOptionParamKey(params.valDate, params.ultimateUnderlyingId, params.expiryDate, params.strike, params.isCall);
            this.state.optionPricingParams.set(key, params);
            this.state.optionPricingParamRequests.delete(key);
            this.emitChange();
        }
        else if (action instanceof MarketDataNewAvailableVolSurfacesEvent) {
            var data = action.payload;
            this.state.volSurfaceDescriptions.clear();
            this.state.volSurfaceDatesByIns.clear();

            Object.keys(data).forEach(k => {
                var d = moment(k).format("yyyy-MM-DD");
                this.state.volSurfaceDescriptions.set(d, data[k]);

                data[k].forEach(bd => {
                    var insId = bd.listedInstrumentId ?? Number(bd.key.split("::")[1])
                    var datesForIns = this.state.volSurfaceDatesByIns.get(insId);
                    if (!datesForIns) {
                        datesForIns = new Set<Date>();
                        this.state.volSurfaceDatesByIns.set(insId, datesForIns)
                    }
                    datesForIns.add(new Date(k))
                });
            });
            this.emitChange();
        }
        else if (action instanceof MarketDataVolSurfaceEvent) {
            var r = action.payload;
            var keyVs = this.generateVolSurfaceKey(r.insId, r.valDate);
            this.state.volSurfaces.set(keyVs, r.surface);
            this.emitChange();
        }
        else if (action instanceof MarketDataAvailableTypesEvent) {
            var mdt = action.payload;
            this.state.dataTypesByInsId.set(mdt.listedInstrumentId, mdt);
            this.emitChange();
        }

    }

    private generateVolSurfaceKey(insId: number, valDate: Date) {
        return `${insId}#${moment(valDate).format("yyyy-MM-DD")}`;
    }

    private flatten(a, b) { return a.concat(b); }
    getAssetsWithCandles(): string[] {
        const { candleSummaries } = this.state;
        if (candleSummaries)
            return Array.from(new Set<string>(Object.keys(candleSummaries).map(k => candleSummaries[k].map(c => c.assetName)).reduce(this.flatten, [])));
        else
            return new Array<string>();
    }

    getCandleSummariesForAsset(assetKey: string): CandlesSummary[] {
        if (this.state.candleSummaries)
            return this.state.candleSummaries[assetKey];
        else
            return new Array<CandlesSummary>();
    }

    getCandles(setName: string, insId: number, interval: number): Candle[] {
        var existingForSet = this.state.candles.get(setName);
        if (existingForSet) {
            var existingForIns = existingForSet.get(insId);
            if (existingForIns) {
                return existingForIns[interval];
            }

        }

        return undefined;
    }

    async getFixingsAsync(setName: string, insId: number, preventFetch: boolean = false) {
        var existing = this.state.fixings.get(setName)?.get(insId);
        if (existing) {
            return existing;
        }
        if (!preventFetch) {
            return await GetFixings(setName, insId);
        }
        return null;
    }

    getFixings(setName: string, insId: number, preventFetch: boolean = false) {
        var existing = this.state.fixings.get(setName)?.get(insId);
        if (existing) {
            return existing;
        }
        if (!preventFetch) {
            GetFixings(setName, insId);
        }
        return undefined;
    }

    getPrice(setName: string, insId: number, date?: Date, preventFetch: boolean = false) {
        var existing = this.state.fixings.get(setName)?.get(insId);
        if (existing) {
            var b4 = date !== undefined ? existing.filter(e => moment(e.date).startOf('day').isSameOrBefore(moment(date).startOf('day'))) : existing;
            return b4[b4.length - 1]?.value;
        }
        else if (!preventFetch) {
            if (!insId)
                console.log("undefined instrument id passed to getPrice")
            else
                GetFixings(setName, insId);
        }

        return null;
    }

    fxInsMap = new Map<string, number>();
    fxInsInvMap = new Map<string, boolean>();
    getFxRate(ccy: string) {
        var insId = this.fxInsMap.get(ccy);
        if (!insId) {
            var ins = listedInstrumentStoreInstance.getInstruments().filter(x => x?.type === "Cash" && x?.ccy === ccy && x?.ticker?.length > 3 && x?.ticker?.startsWith("USD"))[0];
            if (ins) {
                insId = ins?.listedInstrumentId;
                this.fxInsMap.set(ccy, insId);
                this.fxInsInvMap.set(ccy, false);
            }
            else { //try inverse
                ins = listedInstrumentStoreInstance.getInstruments().filter(x => x?.type === "Cash" && x?.ccy === "USD" && x?.ticker?.length > 3 && x?.ticker?.startsWith(ccy))[0];
                if (ins) {
                    insId = ins?.listedInstrumentId;
                    this.fxInsMap.set(ccy, insId);
                    this.fxInsInvMap.set(ccy, true);
                }
            }
        }
        if (!insId)
            console.log("Could not find insturment def for fx rate " + ccy)
        else {
            var rate = this.getPrice(DefaultSetName, insId, undefined);
            if (this.fxInsInvMap.get(ccy)) {
                return 1 / rate;
            }
            else
                return rate;
        }

    }

    getSetNames() {
        return this.state.setNames;
    }

    getSetName(setId: number) {
        return this.state.sets.get(setId);
    }

    getSetByName(setName: string) {
        return Array.from(this.state.sets.values()).filter(s => s.setName === setName)[0];
    }

    getInsIds(setName: string) {
        return this.state.insWithFixings.get(setName);
    }

    getModelForDate(date: Date) {
        return this.state.models.get(moment(date)?.format("yyyy-MM-DD"));
    }

    getModelForMoment(date: Moment) {
        return this.state.models.get(date?.format("yyyy-MM-DD"));
    }

    getOptionPricingParams(valDate: Date, insId: number, expiry: Date, strike: number, isCall: boolean) {
        var key = this.getOptionParamKey(valDate, insId, expiry, strike, isCall);
        return this.state.optionPricingParams.get(key);
    }

    getAvailableVolDates() {
        return Array.from(this.state.volSurfaceDescriptions.keys()).map(d => new Date(d));
    }

    getAvailableVolDatesByIns(insId: number) {
        return this.state.volSurfaceDatesByIns.get(insId) ? Array.from(this.state.volSurfaceDatesByIns.get(insId)) : new Array<Date>();
    }

    getAvailableVolSurfacesForDate(date: Date) {
        var volsForDate = this.state.volSurfaceDescriptions.get(moment(date).format("yyyy-MM-DD"));
        if (!volsForDate)
            return undefined;
        var insIds = volsForDate.map(v => v.listedInstrumentId ?? (Number(v.key.split("::")[1])));
        return insIds;
    }

    getVolSurface(insId: number, valDate: Date) {
        var keyVs = this.generateVolSurfaceKey(insId, valDate);
        return this.state.volSurfaces.get(keyVs);
    }

    getDataTypesForInsId(insId: number) {
        return this.state.dataTypesByInsId.get(insId);
    }

    async requestOptionPricingParams(valDate: Date, insId: number, expiry: Date, strike: number, isCall: boolean) {
        var key = this.getOptionParamKey(valDate, insId, expiry, strike, isCall);
        if (!this.state.optionPricingParamRequests.has(key)) {
            this.state.optionPricingParamRequests.add(key);
            await GetOptionpricingParams(valDate, insId, expiry, strike, isCall);
        }
    }

    private getOptionParamKey(valDate: Date, insId: number, expiry: Date, strike: number, isCall: boolean) {
        return `${moment(valDate).format("yyyy-MM-DD")}-${insId}-${moment(expiry).format("yyyy-MM-DD")}-${strike}-${isCall}`;
    }
}

const marketDataStoreInstance = new MarketDataStore(AppDispatcher);
export default marketDataStoreInstance;