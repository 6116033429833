export enum BarrierType {
    KnockOut,
    KnockIn,
}

export enum BarrierObservationType {
    Continuous,
    Daily,
    European
}

export enum BarrierSide {
    Up,
    Down
}