import { Component } from 'react';
import FBEmitter from 'fbemitter';
import marketDataStoreInstance from '../../marketData/marketDataStore';
import { FixingPoint } from '../../marketData/models/fixingPoint';
import { Line, LineChart, ResponsiveContainer } from 'recharts';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import userStoreInstance from '../../user/userStore';


const tickerMap = { SPX: 180796, GSCI: 180794 }

export interface PriceChartWidgetProps {
  onChangeHeight?: (newHeight: number) => void
}

interface ChartPoint { x: Date, y: number }

interface PriceChartWidgetState {
  selectedTicker: string;
  fixingData: FixingPoint[];
  priceData: ChartPoint[];
}

export class PriceChartWidget extends Component<PriceChartWidgetProps, PriceChartWidgetState>{
  eventSubscriptionPrice: FBEmitter.EventSubscription | undefined;
  constructor(props: PriceChartWidgetProps) {
    super(props);
    this.state = {
      selectedTicker: "SPX",
      fixingData: [],
      priceData: []
    };
    this.updateChart = this.updateChart.bind(this);
    this.onChangeTicker = this.onChangeTicker.bind(this);
  }

  async componentDidMount() {
    this.eventSubscriptionPrice = marketDataStoreInstance.addChangeListener(() => this.updateChart());

    await this.updateChart(false);
  }

  componentWillUnmount() {
    if (this.eventSubscriptionPrice)
      this.eventSubscriptionPrice.remove();
  }

  async updateChart(preventFetch: boolean = true) {
    const { selectedTicker, fixingData } = this.state;

    if (selectedTicker) {
      var data = await marketDataStoreInstance.getFixingsAsync("EODHistory", tickerMap[selectedTicker], preventFetch);
      if (data && data !== fixingData && data.length !== fixingData.length) {
        var priceData = data.slice(-500).map(f => { return { x: new Date(f.date), y: f.value } as ChartPoint });
        this.setState({ priceData, fixingData: data });
      }
    }
  }

  async onChangeTicker(ticker: string) {
    const { fixingData } = this.state;
    this.setState({ selectedTicker: ticker });


    var data = await marketDataStoreInstance.getFixingsAsync("EODHistory", tickerMap[ticker], false);
    if (data !== fixingData || data.length !== fixingData.length) {
      var priceData = data.slice(-500).map(f => { return { x: new Date(f.date), y: f.value } as ChartPoint });
      this.setState({ priceData, fixingData: data });
    }

  }

  render() {
    const { priceData, selectedTicker } = this.state;
    var col = userStoreInstance.GetTheme().chart_color_up;
    return <div style={{ top:20, height: 250, width: 260, display: "block", position: "absolute" }}>
      <div className='PriceChartInner'>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={250} height={250} data={priceData}>
            <Line type="monotone" dataKey="y" stroke={col} strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="PriceChartPicker">
        <StringTypeDropDown key={"chartTickerPicker"} possibles={Object.keys(tickerMap)} onChange={this.onChangeTicker} selected={selectedTicker} disabled={false} />
      </div>
    </div>

  }
}
