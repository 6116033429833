import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import { RfqSummariesUpdatedEvent, RfqSummaryUpdatedEvent } from "./rfqActions";
import { RfqSummary, RfqSummaryState } from "./rfqModels";

class RfqSummaryStore extends FluxStore<RfqSummaryState>{
    constructor(dispatcher:typeof AppDispatcher){
        super(dispatcher, (e) => this.onDispatcherHandler(e), () => ({
            rfqs : {}
        }))
    }

    private onDispatcherHandler(action: Event){
        if(action instanceof RfqSummaryUpdatedEvent){
            this.state.rfqs[action.payload.rfqId] = action.payload;
            console.log(action.payload);
            this.emitChange();
        } 
        else if(action instanceof RfqSummariesUpdatedEvent){
            action.payload.forEach(element=>{
                this.state.rfqs[element.rfqId] = element;
                console.log(element);
            })
            this.emitChange();
        }
    }

    public GetRfq(rfqId : number){
        return this.state.rfqs[rfqId];
    }

    public GetAllRfqs() : RfqSummary[]{
        return Object.keys(this.state.rfqs).map(k=>this.state.rfqs[k]);
    }
}

const rfqSummaryStoreInstance = new RfqSummaryStore(AppDispatcher);
export default rfqSummaryStoreInstance;