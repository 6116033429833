import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { Asset, AssetsState } from './assetsModels'
import { LoadedAssetsEvent } from './assetsActions';

class AssetsStore extends FluxStore<AssetsState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            AssetInfos: [],
            FixingIndex: [],
            FixingIndexMap: [],
            AllAssets: [],
            AllAssetsById: new Map<number, Asset>()
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadedAssetsEvent) {
            const { payload } = action;
            this.state.AssetInfos = payload;
            let allAssets = this.state.AssetInfos.flatMap(ai => ai.assetGroups).flatMap(ag => ag.assets);
            this.state.AllAssets = allAssets;
            allAssets.forEach(asset => {
                this.state.AllAssetsById.set(asset.assetId, asset)
            });
            this.emitChange();
        }
    }

    getState() {
        return this.state;
    }

    getFixingIndexesForAsset(assetId: number) {
        // let newGroup = this.state.AssetInfos.flatMap(ai => ai.assetGroups).filter(ag => ag.assets.some(a => a.assetId === assetId))[0];
        // let newClass = this.state.AssetInfos.filter(ac => ac.assetGroups.some(ag => ag === newGroup))[0];
        // return newClass.fixingIndexes;
        var asset = this.getAsset(assetId);
        return asset.fixingIndices;
    }
    allAssets() {
        return this.state.AllAssets;
    }
    getAsset(assetId: number) {
        return this.state.AllAssetsById.get(assetId);
    }
    getAssetByKey(key: string) {
        return this.state.AllAssets.filter(a=>a.key===key)[0];
    }
}

const assetsStoreInstance = new AssetsStore(AppDispatcher);
export default assetsStoreInstance;
