import { getGridBooleanOperators, getGridDateOperators, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators, GridCellParams, GridColDef, GridFilterInputValue, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";
import moment from "moment";
import { AssetStr } from "../globalConstants";
import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import { Trade } from "../trade/tradeModels";
import { AggregatedPosition, LivePosition } from "./positionSummaryModels";

const TreasuryIds = [4172, 4207, 4242, 4278, 175940];

export const getPositionKey = (pos: LivePosition) => {
    return `${pos?.aggregatedPosition?.instrumentId}~${pos?.aggregatedPosition?.realizedPnLCcy}~${pos?.aggregatedPosition?.firstTraded}`;
}

const notOperator: GridFilterOperator = {
    label: 'is not',
    value: 'not',
    
    getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (params: GridCellParams): boolean => {
            return params.value !== filterItem.value;
        };
    },
    InputComponent: GridFilterInputValue,
};

const notContainsOperator: GridFilterOperator = {
    label: 'not contains',
    value: 'not contains',
    getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
        }

        return (params: GridCellParams): boolean => {
            return !params.value.includes(filterItem.value);
        };
    },
    InputComponent: GridFilterInputValue,
};

export const getColCommon = (type: string) => {
    return { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters(type) } as Partial<GridColDef>;
}

export const getFilters = (type: string) => {
    switch (type) {
        case "string":
        default:
            return [...getGridStringOperators(), notOperator, notContainsOperator];
        case "number":
            return [...getGridNumericOperators(), notOperator, notContainsOperator];
        case "boolean":
            return [...getGridBooleanOperators(), notOperator, notContainsOperator];
        case "date":
        case "dateTime":
            return [...getGridDateOperators(type === "dateTime"), notOperator, notContainsOperator];
        case "singleSelect":
            return [...getGridSingleSelectOperators(), notOperator, notContainsOperator];
    }
}

export const colCommon = { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" } as Partial<GridColDef>;

export const getIns = (insId: any) => {
    if (!isNaN(Number(insId))) {
        return listedInstrumentStoreInstance.getInstrumentById(insId);
    }
    else if ((insId as LivePosition) !== undefined) {
        return listedInstrumentStoreInstance.getInstrumentById((insId as LivePosition).aggregatedPosition.instrumentId);
    }
    else if ((insId as AggregatedPosition) !== undefined) {
        return listedInstrumentStoreInstance.getInstrumentById((insId as AggregatedPosition).instrumentId);
    }
}

export const getUltimateParent = (pos: LivePosition) => {
    var ins = getIns(pos?.aggregatedPosition?.instrumentId);
    return getUltimateParentIdFromIns(ins);
}

export const getUltimateParentIdFromIns = (ins: ListedInstrument) => {
    return ins?.underlying?.underlyingId ?? ins?.underlyingId ?? ins?.listedInstrumentId;
}

export const getUltimateParentFromIns = (ins: ListedInstrument) => {
    return ins?.underlying?.underlying ?? ins?.underlying ?? ins;
}

export const isCashBalance = (pos: LivePosition) => {
    return isCashIns(pos?.aggregatedPosition?.instrumentId)
}

export const isCashIns = (insId: number) => {
    var ins = getIns(insId);
    return ins?.type === "Cash" && ins?.primaryExchange === "OTC" && ins?.ticker.length === 3;
}

export const isCashFx = (insId: number) => {
    var ins = getIns(insId);
    return ins?.type === "Cash" && getMetaFromInstrument(ins, AssetStr) === "FX";
}

export const isTreasury = (pos: LivePosition) => {
    return TreasuryIds.includes(getUltimateParent(pos));
}

export const getBbgTickerFromInstrument = (ins: ListedInstrument): string => {
    if (ins == null) return null;
    var bbg = ins.metaData?.filter(m => m.type === "BBG")[0];
    var bbgType = ins.metaData?.filter(m => m.type === "BBGType")[0];
    if (bbg && bbgType) {
        return bbg.data.toLowerCase().includes(bbgType.data.toLowerCase()) ? bbg.data : `${bbg.data} ${bbgType.data}`;
    }
    else if (bbg) {
        return bbg.data
    }
    else if (!(ins.isin == null) && ins.isin.length > 0)
        return `${ins.isin} ISIN`;
    else if (ins.type === "CFD" && ins.underlying !== null)
        return getBbgTickerFromInstrument(ins.underlying);
    else if (ins.type === "Option") {
        if (ins.underlying?.type === "Future") {
            var ulTicker = getBbgTickerFromInstrument(ins.underlying);
            var ult = ulTicker.split(" ");
            var strike = getScaledOptionStrikeForTicker(ins);
            return `${ult[0]}${ins.optionIsCall ? "C" : "P"} ${strike} ${ult[1]}`;
        }
        else if (ins.underlying?.type === "Equity") {
            ulTicker = getBbgTickerFromInstrument(ins.underlying);
            if (ulTicker === null)
                return null;
            ult = ulTicker.split(" ");
            strike = getScaledOptionStrikeForTicker(ins);
            var exp = moment(ins.maturity);
            return `${ult[0]} ${ult[1]} ${exp.format("MM/DD/yy")} ${ins.optionIsCall ? "C" : "P"}${strike} ${ult[2]}`;
        }
    }
    return null;
}

export const getScaledOptionStrikeForTicker = (ins: ListedInstrument) => {
    var inCents = ["RBOB", "Heat", "NYMEX Copper"];
    if (inCents.some(c => ins.underlying.description.includes(c))) {
        return ins.optionStrike * 100;
    }
    else
        return ins.optionStrike;
}

export const getMetaFromInstrument = (ins: ListedInstrument, type: string) => {
    if (ins == null) return null;
    var meta = ins.metaData?.filter(m => m.type === type)[0];
    if (meta)
        return meta.data;
    else if ((ins.type === "CFD" || ins.type === "Option") && ins.underlying !== null)
        return getMetaFromInstrument(ins.underlying, type);
    else
        return null;
}

export const getMetaFromTrade = (trade: Trade, type: string) => {
    if (trade == null) return null;
    var meta = trade.tradeMetaData?.filter(m => m.type === type)[0];
    if (meta)
        return meta.data;
    else
        return null;
}

export const getMetaFromPosition = (pos: LivePosition, type: string) => {
    return pos?.aggregatedPosition?.metaData[type];
  
}