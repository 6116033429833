import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import FBEmitter from 'fbemitter';
import React from 'react';
import userStoreInstance from '../user/userStore';
import { GetInstrumentsNeedingMetaData, UpdateInsMetaData } from './listedInstrumentActions';
import { ListedInstrument, ListedInstrumentMetaData, MissingMetaDataQuery } from './listedInstrumentModels';
import listedInstrumentStoreInstance from './listedInstrumentStore';
import { getFormTheme } from '../inputs/formCommon';
import { QueryBuilderOutlined, SaveAltOutlined, UpdateOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import moment from 'moment';
import { defaultInstrumentQueryMetaTags } from '../globalConstants';
import { Autocomplete } from '@mui/material';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface ListedInstrumentMetaUpdaterState {
    selectedInstruments: ListedInstrument[],
    matchingInstruments: ListedInstrument[],
    checked: number[];
    checkAll: boolean;
    queryRunning: boolean;
    commitRunning: boolean;
    query: MissingMetaDataQuery;
    showQuery: boolean;
    showUpdateBar: boolean;
    massUpdateValues: Map<string, string>;
    updateMap: Map<number, string[]>;
}

const Category = "AdditionalData";

export class ListedInstrumentMetaUpdater extends React.Component<{}, ListedInstrumentMetaUpdaterState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        this.state = {
            selectedInstruments: [],
            matchingInstruments: [],
            checked: [],
            checkAll: false,
            queryRunning: false,
            commitRunning: false,
            query: { uniqueId: v4(), fromDate: moment().subtract(1, "week").toDate(), metaTypes: defaultInstrumentQueryMetaTags } as MissingMetaDataQuery,
            showQuery: true,
            showUpdateBar: true,
            massUpdateValues: new Map<string, string>(),
            updateMap: new Map<number, string[]>()
        };;

        this.onInsSelect = this.onInsSelect.bind(this);
        this.handleCheckAllToggle = this.handleCheckAllToggle.bind(this);
        this.queryUpdate = this.queryUpdate.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.queryUpdate);
    }

    queryUpdate() {
        const { query } = this.state;
        var ins = listedInstrumentStoreInstance.getInstrumentQuery(query.uniqueId);
        if (!ins)
            return;
        this.setState({ matchingInstruments: ins, queryRunning: false, showQuery: ins.length === 0 });
    }

    private onInsSelect(ins: ListedInstrument | string) {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            this.setState({ selectedInstruments: [solidIns] });
        }
    }

    private handleToggle = (value: number) => () => {
        const { checked, matchingInstruments } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        var selectedInstruments = matchingInstruments.filter(m => newChecked.includes(m.listedInstrumentId));

        this.setState({ checked: newChecked, selectedInstruments });
    };

    private handleCheckAllToggle(checked: boolean) {
        const { matchingInstruments } = this.state;
        var idsChecked = new Array<number>();
        if (checked) { //moving to all checked
            idsChecked = matchingInstruments.map(i => i.listedInstrumentId);
        }
        this.setState({ checkAll: checked, checked: idsChecked });
    }

    async runQuery(query: MissingMetaDataQuery) {
        this.setState({ queryRunning: true });
        await GetInstrumentsNeedingMetaData(query);
    }

    renderInstrumentQuery() {
        const { query, queryRunning } = this.state;
        var metaTypes = listedInstrumentStoreInstance.getMetaDataTypesForCategory(Category);
        return (
            <div><StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2}>
                        <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect
                                id="metaTypes"
                                name="metaTypes"
                                label="Meta Types"
                                multiple
                                value={query.metaTypes ?? new Array<string>()}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.metaTypes = d.target.value as string[];
                                    this.setState({ query: q });
                                }}>
                                {metaTypes.map(a =>
                                    <MenuItem key={"insMetaType_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.fromDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.fromDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                disableFuture
                                label={"Since"}
                                emptyLabel="(None)" />
                        </Grid>

                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.query)} disabled={queryRunning}>Run Query</Button>
                        </Grid>
                        {queryRunning ?
                            <Grid item className="ListedInstrumentQueryItem">
                                <CircularProgress />
                            </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider></div>
        );
    }

    async commitChanges() {
        this.setState({ commitRunning: true });
        const { updateMap, matchingInstruments } = this.state;
        var touchedIns = Array.from(updateMap.keys());
        var updatedData = touchedIns.flatMap(ti => {
            var touchedTypesForIns = updateMap.get(ti);
            var ins = matchingInstruments.filter(m => m.listedInstrumentId === ti)[0];
            return touchedTypesForIns.map(tt => {
                var data = ins.metaData.filter(m => m.category === Category && m.type === tt)[0];
                return data;
            });
        });
        await UpdateInsMetaData(updatedData)
        updateMap.clear();
        this.setState({ updateMap, commitRunning: false });
    }

    private massUpdate() {
        const { query, selectedInstruments, massUpdateValues } = this.state;

        query.metaTypes.map(m => {
            var data = massUpdateValues.get(m);
            if (data && data !== null) {
                selectedInstruments.map(s => {
                    this.updateOrAddMetaData(s, m, data);
                    return true;
                });
            }
            return true;
        });
        this.setState({ selectedInstruments });
    }

    renderUpdateBar() {
        const { showUpdateBar, query, massUpdateValues, commitRunning } = this.state;
        if (!showUpdateBar || !query || !query.metaTypes)
            return <div></div>

        return (
            <div><StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2}>
                        {query.metaTypes.map(m =>
                            <Grid item className="ListedInstrumentQueryItem">
                                <Autocomplete
                                    freeSolo
                                    style={{ width: "200px" }}
                                    options={Array.from(listedInstrumentStoreInstance.getMetaDataByType(Category, m))}
                                    id={`mass_update_${m}`}
                                    value={massUpdateValues.get(m) ?? ""}
                                    autoSelect
                                    onChange={(e, v) => { massUpdateValues.set(m, v); this.setState({ massUpdateValues }); }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={m}
                                            margin="normal"
                                            variant="outlined"
                                            error={massUpdateValues.get(m) !== undefined && massUpdateValues.get(m) !== null}
                                            InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                />
                            </Grid>)}
                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButton" startIcon={<UpdateOutlined />} onClick={() => this.massUpdate()}>Update Selected</Button>
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButton" startIcon={<SaveAltOutlined />} disabled={commitRunning} onClick={() => this.commitChanges()}>Commit</Button>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider></div>
        );
    }

    private updateOrAddMetaData(ins: ListedInstrument, type: string, data: string) {
        if (!ins.metaData)
            ins.metaData = [];
        if (!ins.metaData.some(md => md.type === type && md.category === Category))
            ins.metaData.push({ category: Category, data: data, type: type, lastUpdated: moment.utc().toDate(), listedInstrumentId: ins.listedInstrumentId } as ListedInstrumentMetaData);
        else {
            var existing = ins.metaData.filter(md => md.type === type && md.category === Category)[0];
            existing.data = data;
            existing.lastUpdated = moment.utc().toDate();
        }

        var m = this.state.updateMap.get(ins.listedInstrumentId);
        if (!m)
            this.state.updateMap.set(ins.listedInstrumentId, [type]);
        else
            if (!m.includes(type))
                m.push(type);
    }

    private hasBeenUpdated(ins: ListedInstrument, type: string): boolean {
        return this.state.updateMap.get(ins.listedInstrumentId)?.includes(type);
    }

    private getMetaValue(ins: ListedInstrument, type: string) {
        if (!ins.metaData || !ins.metaData.some(md => md.type === type && md.category === Category))
            return "";

        var existing = ins.metaData.filter(md => md.type === type && md.category === Category)[0];
        return existing.data
    }

    render() {
        const { matchingInstruments, checked, checkAll, showQuery, showUpdateBar, query } = this.state;
        const hideUpdateBar = !(matchingInstruments && matchingInstruments.length > 1);
        return (
            <div className="ListedInstrumentMetaEditorTab">
                <div className="ListedInstrumentMetaEditorTabSearch">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <Accordion expanded={showQuery} onChange={() => this.setState({ showQuery: !showQuery })} className="ListedInstrumentMetaEditorTabSearchTop">
                                <AccordionSummary>Search Query</AccordionSummary>
                                <AccordionDetails>{this.renderInstrumentQuery()}</AccordionDetails>
                            </Accordion>
                            {!hideUpdateBar ? <Accordion expanded={showUpdateBar} onChange={() => this.setState({ showUpdateBar: !showUpdateBar })} className="ListedInstrumentMetaEditorTabSearchTop">
                                <AccordionSummary>Batch Update</AccordionSummary>
                                <AccordionDetails>{this.renderUpdateBar()}</AccordionDetails>
                            </Accordion> : null}
                            <div className="ListedInstrumentMetaEditorTabSearchBottomSection">
                                <div className="ListedInstrumentMetaEditorTabSearchResults">
                                    <List key="ListedInstrumentMetaEditorTabSearch">
                                        {matchingInstruments && matchingInstruments.length === 0 ?
                                            <div className="ListedInstrumentMetaEditorNoResults">
                                                <Typography variant="h4">No Matches Found</Typography>
                                            </div> : null}
                                        {matchingInstruments && matchingInstruments.length > 1 ?
                                            <div className="ListedInstrumentMetaEditorResultRow">
                                                <Grid container spacing={2}>
                                                    <Grid item className="ListedInstrumentMetaEditorListItem"><ListItem key={"selectAll"} divider role={undefined} dense button onClick={(e) => this.handleCheckAllToggle(!checkAll)}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={checkAll}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                style={{ color: userStoreInstance.GetTheme().border_color }}
                                                                inputProps={{ 'aria-labelledby': "Check all" }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText id={"checkAll"} primary={"Select All"} />
                                                    </ListItem></Grid>
                                                </Grid></div>
                                            : null}
                                        {matchingInstruments.slice(0, 50).map(s => {
                                            const labelId = `checkbox-list-label-${s.description}`;
                                            return (
                                                <div className="ListedInstrumentMetaEditorResultRow">
                                                    <Grid container spacing={2}>
                                                        <Grid item className="ListedInstrumentMetaEditorListItem"><ListItem key={s.listedInstrumentId} role={undefined} selected={checked.indexOf(s.listedInstrumentId) !== -1} dense button onClick={this.handleToggle(s.listedInstrumentId)}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checked.indexOf(s.listedInstrumentId) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    style={{ color: userStoreInstance.GetTheme().border_color }}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} primary={s.description} />
                                                        </ListItem></Grid>
                                                        {query.metaTypes.map(m =>
                                                            <Grid item><Autocomplete
                                                                freeSolo
                                                                style={{ width: "200px" }}
                                                                options={Array.from(listedInstrumentStoreInstance.getMetaDataByType(Category, m))}
                                                                id={`mass_update_${m}`}
                                                                value={this.getMetaValue(s, m)}
                                                                autoSelect
                                                                onChange={(e, v) => {
                                                                    this.updateOrAddMetaData(s, m, v);
                                                                    this.setState({ matchingInstruments: matchingInstruments });
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={m}
                                                                        margin="normal"
                                                                        error={this.hasBeenUpdated(s, m)}
                                                                        variant="outlined"
                                                                        InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                                            /></Grid>)}
                                                    </Grid></div>);
                                        })}
                                    </List>
                                </div>
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            </div>
        );
    }
}
