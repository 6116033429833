import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";
import { FieldArray, FieldArrayRenderProps, Form } from "formik";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import InstrumentSelection from "../inputs/instrumentSelection";
import ordersStoreInstance from "./orderStore";
import moment, { Moment } from "moment";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import { NumberFormatCustom } from "../inputs/numberFormatInput";
import positionStoreInstance from "../positions/positionSummaryStore";
import { GoodUntil, LeggedOrderAggressiveness, LeggedOrderDetails, Order, OrderComment, OrderType, Venue } from "./orderModels";
import { WrappedDateTimePicker } from "../inputs/wrappedDateTimePicker";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { FormSection } from "../inputs/formSection";
import { CheckCircleOutline, CancelOutlined, AddOutlined, RemoveOutlined } from "@mui/icons-material";
import {
    CssBaseline,
    ThemeProvider,
    Grid,
    Typography,
    MenuItem,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    TextareaAutosize,
    SelectChangeEvent,
    FormLabel,
} from "@mui/material";
import marketDataLiveStoreInstance from "../marketData/marketDataLiveStore";
import _ from "lodash";
import userStoreInstance from "../user/userStore";
import { getMetaFromInstrument, getUltimateParentFromIns } from "../positions/positionsCommon";

export const LeggedOrderTypes = [OrderType.LeggedSpreadLimit, OrderType.LeggedSpreadMarket];
const LimitOrderTypes = [OrderType.LeggedSpreadLimit];

export const LeggedSpreadOrderForm = props => {
    const {
        values: {
            listedInstrumentId,
            leg2InstrumentId,
            orderType,
            goodUntil,
            isLimit,
            limitLevel,
            isBuyOrder,
            size,
            leg2SizeRatio,
            tempQuantity,
            openPosition1,
            openPosition2,
            venuesForIns1,
            venuesForIns2,
            orderVenue,
            leg2OrderVenue,
            comments,
            orderVenueObject1,
            orderVenueObject2,
            account,
            leg2Account,
            timeInForce,
            leggedOrderDetails,
            leg1SliceSize,
            aggressiveness
        },
        lastBookedId,
        canDuplicate,
        onDuplicatePressed,
        errors,
        touched,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        venuesLookup,
        isSubmitting,
        onUpdateOrder
    } = props;


    const setValueLocal = (name: string, value: any) => {
        setFieldValue(name, value);
        //updateOrder();
    }

    const updateOrderLocal = (listedInstrumentId1X?: number, listedInstrumentId2X?: number, limitLevelX?: number, orderTypeX?: OrderType, sizeX?: number, leg2SizeRatioX?: number, isBuyOrderX?: boolean) => {
        var o = {
            limitLevel: limitLevelX ?? limitLevel as number,
            listedInstrumentId: listedInstrumentId1X ?? listedInstrumentId as number,
            leg2InstrumentId: listedInstrumentId2X ?? leg2InstrumentId as number,
            orderType: orderTypeX ?? orderType as OrderType,
            goodUntil: goodUntil as Date,
            isBuyOrder: isBuyOrderX !== undefined ? isBuyOrderX : (isBuyOrder as boolean),
            size: sizeX ?? size as number,
            leg2SizeRatio: leg2SizeRatioX ?? leg2SizeRatio as number,
            orderVenue: orderVenue as string,
            leg2OrderVenue: leg2OrderVenue as string,
            comments: comments as OrderComment[],
            account: account as string,
            leg2Account: leg2Account as string,
            timeInForce: timeInForce as GoodUntil,
        } as Partial<Order>;
        onUpdateOrder(o);
    }

    const ins = listedInstrumentStoreInstance.getInstrumentById(listedInstrumentId);

    if (!isLimit && LimitOrderTypes.includes(orderType))
        setValueLocal("isLimit", true);

    if (timeInForce === undefined)
        setValueLocal("timeInForce", GoodUntil.GoodForDay);

    if (orderType === undefined) {
        setValueLocal("orderType", OrderType.LeggedSpreadLimit);
        updateOrderLocal(undefined, undefined, undefined, OrderType.LeggedSpreadLimit);
    }

    const instrumentSelectedLeg1 = async (instrument: ListedInstrument) => {
        if (listedInstrumentId) {
            marketDataLiveStoreInstance.UnSubscribeToTickStream(listedInstrumentId);
        }
        setFieldValue("listedInstrumentId", instrument.listedInstrumentId);
        setFieldTouched("listedInstrumentId", true, false);

        var venuesMetaForIns = _.uniq(instrument.metaData.filter(x => x.category === 'SourceSystemId').map(x => x.type));
        var venuesForIns = venuesMetaForIns.map(m => venuesLookup[m]).filter(x => Boolean(x));
        if (venuesForIns === undefined || venuesForIns.length === 0)
            venuesForIns = ["(None Available)"]
        setFieldValue("venuesForIns1", venuesForIns);
        setFieldValue("orderVenue", venuesForIns[0]);
        var orderVenueObject = ordersStoreInstance.getOrderVenues()[venuesForIns[0]];
        setFieldValue("orderVenueObject1", orderVenueObject);

        var parentIns = getUltimateParentFromIns(instrument);
        var defaultAcc = getMetaFromInstrument(parentIns, "IbDefaultAccount") ?? orderVenueObject?.capabilities["Accounts"]?.split(",")[0];
        setFieldValue("account", defaultAcc);

        var position = getOpenPositionForInstrument(instrument.listedInstrumentId);
        if (position && position !== 0) {
            setFieldValue("openPosition1", position);
        }
        updateOrderLocal(instrument.listedInstrumentId);
    }

    const instrumentSelectedLeg2 = async (instrument: ListedInstrument) => {
        if (leg2InstrumentId) {
            marketDataLiveStoreInstance.UnSubscribeToTickStream(leg2InstrumentId);
        }
        setFieldValue("leg2InstrumentId", instrument.listedInstrumentId);
        setFieldTouched("leg2InstrumentId", true, false);

        var venuesMetaForIns = _.uniq(instrument.metaData.filter(x => x.category === 'SourceSystemId').map(x => x.type));
        var venuesForIns = venuesMetaForIns.map(m => venuesLookup[m]).filter(x => Boolean(x));
        if (venuesForIns === undefined || venuesForIns.length === 0)
            venuesForIns = ["(None Available)"]
        setFieldValue("venuesForIns2", venuesForIns);
        setFieldValue("leg2OrderVenue", venuesForIns[0]);
        var orderVenueObject = ordersStoreInstance.getOrderVenues()[venuesForIns[0]];
        setFieldValue("orderVenueObject2", orderVenueObject);

        var parentIns = getUltimateParentFromIns(instrument);
        var defaultAcc = getMetaFromInstrument(parentIns, "IbDefaultAccount") ?? orderVenueObject?.capabilities["Accounts"]?.split(",")[0];
        setFieldValue("leg2Account", defaultAcc);

        var position = getOpenPositionForInstrument(instrument.listedInstrumentId);
        if (position && position !== 0) {
            setFieldValue("openPosition2", position);
        }
        updateOrderLocal(undefined, instrument.listedInstrumentId);
    }

    function getOpenPositionForInstrument(insId: number) {
        var pos = positionStoreInstance.getPositionForInstrument(null, Number(insId));
        return pos;
    }

    function onChangeOrderType(e: SelectChangeEvent<any>) {
        var asStr = e.target.value;
        var asEnum: OrderType = OrderType[asStr as keyof typeof OrderType];
        orderTypeSelected({ id: asEnum.valueOf(), value: asEnum.toString() })
    }

    function onChangeTimeInForce(e: SelectChangeEvent<any>) {
        var asStr = e.target.value;
        var asEnum: GoodUntil = GoodUntil[asStr as keyof typeof GoodUntil];
        setValueLocal("timeInForce", asEnum);
    }

    const onChangeSliceSize = (val: string) => {
        var asNum = Number(val);
        if (val?.toLowerCase() === "o" && Boolean(openPosition1))
            asNum = Math.abs(openPosition1);
        if (!isNaN(asNum)) {
            setValueLocal("leg1SliceSize", asNum);
        }
        else
            setValueLocal("leg1SliceSize", val);

        var lo = leggedOrderDetails;
        if (!lo) {
            lo = { leg1SliceSize: asNum } as LeggedOrderDetails;
        }
        else {
            lo.leg1SliceSize = asNum;
        }

        setValueLocal("leggedOrderDetails", lo)
        setValueLocal("orderInstructions", JSON.stringify(lo))
    }

    function onChangeAggressiveness(e: SelectChangeEvent<any>) {
        var asStr = e.target.value;
        var asEnum: LeggedOrderAggressiveness = LeggedOrderAggressiveness[asStr as keyof typeof LeggedOrderAggressiveness];
        setValueLocal("aggressiveness", asEnum);

        var lo = leggedOrderDetails;
        if (!lo) {
            lo = { aggressiveness: asEnum } as LeggedOrderDetails;
        }
        else {
            lo.aggressiveness = asEnum;
        }

        setValueLocal("leggedOrderDetails", lo)
        setValueLocal("orderInstructions", JSON.stringify(lo))
    }

    const orderTypeSelected = (orderType: { id: number, value: string } | string) => {
        if (orderType instanceof String) {
            return;
        }
        else {
            var ot = orderType as { id: number, value: string };
            if (ot?.id !== undefined) {
                setValueLocal("orderType", ot.id);
                setFieldTouched("orderType", true, false);
                if (ot && ot.value) {
                    if (ot.value.includes("Limit")) {
                        setValueLocal("isLimit", true);
                    }
                    else {
                        setValueLocal("isLimit", false);
                    }
                }
                else {
                    setValueLocal("isStop", false);
                    setValueLocal("isLimit", false);
                    setValueLocal("isTrailing", false);
                }
            }
            else
                setFieldTouched("orderType", false, false);
            updateOrderLocal(undefined, undefined, undefined, ot.id);
        }
    }

    const directionSelected = (direction: string, flip: boolean) => {
        var isBuyOrder = direction === "buy";
        if (flip)
            isBuyOrder = !isBuyOrder;
        setValueLocal("isBuyOrder", isBuyOrder);
        setFieldTouched("isBuyOrder", true, false);
        updateOrderLocal(undefined, undefined, undefined, undefined, undefined, undefined, isBuyOrder);
    }

    const onChangeGoodUntil = (d: Moment) => {
        setValueLocal("goodUntil", d.toDate());
    }

    const onChangeSize = (val: string) => {
        var asNum = Number(val);
        if (val?.toLowerCase() === "o" && Boolean(openPosition1))
            asNum = Math.abs(openPosition1);
        if (!isNaN(asNum)) {
            setValueLocal("size", asNum);
            setValueLocal("tempQuantity", asNum * (ins?.multiplier ?? 1.0));
        }
        else
            setValueLocal("size", val);

        updateOrderLocal(undefined, undefined, undefined, undefined, isNaN(asNum) ? undefined : asNum);
    }

    const onChangeQuantity = (val: string) => {
        var asNum = Number(val);
        if (!isNaN(asNum)) {
            setValueLocal("tempQuantity", asNum);
        }
        else
            setValueLocal("tempQuantity", val);
    }

    const onChangeSizeRatio = (val: string) => {
        var asNum = Number(val);
        if (val?.toLowerCase() === "o" && Boolean(openPosition1))
            asNum = Math.abs(openPosition1);
        if (!isNaN(asNum)) {
            setValueLocal("leg2SizeRatio", asNum);
        }
        else
            setValueLocal("leg2SizeRatio", val);

        updateOrderLocal(undefined, undefined, undefined, undefined, undefined, isNaN(asNum) ? undefined : asNum);
    }

    const onChangeLimit = (val: string) => {
        var asNum = Number(val);
        if (!isNaN(asNum)) {
            setValueLocal("limitLevel", asNum);
        }
        else
            setValueLocal("limitLevel", val);

        updateOrderLocal(undefined, undefined, isNaN(asNum) ? undefined : asNum);
    }

    const onChangeOrderVenue1 = (venue: string) => {
        var orderVenueObject = ordersStoreInstance.getOrderVenues()[venue];
        setValueLocal("orderVenue", venue);
        setFieldTouched("orderVenue", true, false);
        setValueLocal("orderVenueObject1", orderVenueObject);
    }

    const onChangeOrderVenue2 = (venue: string) => {
        var orderVenueObject = ordersStoreInstance.getOrderVenues()[venue];
        setValueLocal("leg2OrderVenue", venue);
        setFieldTouched("leg2OrderVenue", true, false);
        setValueLocal("orderVenueObject2", orderVenueObject);
    }

    const onChangeAccount = (account: string) => {
        setValueLocal("account", account);
        setFieldTouched("account", true, false);
    }

    const onEnterPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13 && tempQuantity) {
            e.preventDefault();
            var inContracts = tempQuantity / (ins?.multiplier ?? 1.0);
            setValueLocal("size", inContracts);
            setFieldTouched("size", true, false);
        }
    }

    const onBlur = () => {
        var inContracts = tempQuantity / (ins?.multiplier ?? 1.0);
        if (!isNaN(inContracts)) {
            setValueLocal("size", inContracts);
            setFieldTouched("size", true, false);
        }
    }

    const onCommentChange = (val: string, ix: number) => {
        var name = `comments[${ix}].text`;
        setValueLocal(name, val)
        setFieldTouched(name, true, false);
    }

    const isValidTifForVenue = (x: string) => {
        return !orderVenueObject1?.capabilities || !orderVenueObject1.capabilities["ValidTimeInForce"] || orderVenueObject1.capabilities["ValidTimeInForce"].split(',').includes(x.toString())
    }

    const hasContracts = ins?.multiplier !== 1.0;
    const titleStyle = { width: "175px", textAlign: "right", paddingRight: "1em" } as React.CSSProperties;

    // if (!firstDrawCompleted) {
    //     if (!lastPrice && listedInstrumentId)
    //         instrumentSelected(listedInstrumentStoreInstance.getInstrumentById(listedInstrumentId));
    //     setFieldValue('firstDrawCompleted', true)
    // }

    return (
        <Form onSubmit={handleSubmit} className={className} style={{ width: "100%", padding: "0.5em" }}>
            <CssBaseline>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container item spacing={0} justifyContent="flex-start" >
                        <Grid item container
                            direction="column"
                            spacing={2}
                            justifyContent={"flex-start"}
                            alignContent={"flex-start"}
                            style={{ borderBottom: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, margin: "0.25em", padding: "0.25em", width: "90%" }}>
                            <Grid item height="100%"><Typography variant="h6" style={titleStyle}>Instruments</Typography></Grid>
                            <Grid item container spacing={2} direction="column">
                                <Grid item container direction="row" spacing={2}>
                                    <Grid item>
                                        <InstrumentSelection label="Instrument (Leg 1)" hideExpired={true} listedInstrumentId={listedInstrumentId} onInstrumentSelect={instrumentSelectedLeg1} filterType={undefined} size="small"></InstrumentSelection>
                                    </Grid>
                                    {Boolean(venuesForIns1) && <Grid item>
                                        <WrappedSelect
                                            id="Venue1"
                                            name="Venue1"
                                            label="Leg 1 Venue"
                                            value={orderVenue ?? null}
                                            onChange={(d) => { onChangeOrderVenue1(d.target.value) }}>
                                            {venuesForIns1.map((v, ix) => <MenuItem key={"oda1Vnu" + ix} value={v}>{v}</MenuItem>)}
                                        </WrappedSelect>
                                    </Grid>}
                                    {orderVenue !== "(None Available)" && <Grid item>
                                        <WrappedSelect
                                            id="Account"
                                            name="Account"
                                            label="Leg 1 Account"
                                            value={account ?? ''}
                                            onChange={(d) => { onChangeAccount(d.target.value) }}>
                                            {(orderVenueObject1 as Venue)?.capabilities['Accounts']?.split(',')?.map((a, ix) =>
                                                <MenuItem key={"a1cc" + ix} value={a}>{a}</MenuItem>)}
                                        </WrappedSelect>
                                    </Grid>}
                                </Grid>
                                <Grid item container direction="row" spacing={2}>
                                    <Grid item>
                                        <InstrumentSelection label="Instument (Leg 2)" hideExpired={true} listedInstrumentId={leg2InstrumentId} onInstrumentSelect={instrumentSelectedLeg2} filterType={undefined} size="small"></InstrumentSelection>
                                    </Grid>
                                    {Boolean(venuesForIns2) && <Grid item>
                                        <WrappedSelect
                                            id="Venue2"
                                            name="Venue2"
                                            label="Leg 2 Venue"
                                            value={leg2OrderVenue ?? null}
                                            onChange={(d) => { onChangeOrderVenue2(d.target.value) }}>
                                            {venuesForIns2.map((v, ix) => <MenuItem key={"oda2Vnu" + ix} value={v}>{v}</MenuItem>)}
                                        </WrappedSelect>
                                    </Grid>}
                                    {leg2OrderVenue !== "(None Available)" && <Grid item>
                                        <WrappedSelect
                                            id="Account2"
                                            name="Accoun2t"
                                            label="Leg 2 Account"
                                            value={leg2Account ?? ''}
                                            onChange={(d) => { onChangeAccount(d.target.value) }}>
                                            {(orderVenueObject2 as Venue)?.capabilities['Accounts']?.split(',')?.map((a, ix) =>
                                                <MenuItem key={"a2cc" + ix} value={a}>{a}</MenuItem>)}
                                        </WrappedSelect>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                        <FormSection label={<Typography variant="h6" style={titleStyle}>Order Size</Typography>}>
                            {Boolean(openPosition1) && <Grid item><TextField
                                variant="outlined"
                                size="small"
                                style={{ width: "120px" }}
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id="openPosition1"
                                name="openPosition1"
                                label="Leg 1 Position"
                                value={openPosition1}
                                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                            /></Grid>}
                            {Boolean(openPosition2) && <Grid item><TextField
                                variant="outlined"
                                size="small"
                                style={{ width: "120px" }}
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id="openPosition2"
                                name="openPosition2"
                                label="Leg 2 Position"
                                value={openPosition2}
                                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                            /></Grid>}
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    style={{ width: "150px" }}
                                    id="size"
                                    name="size"
                                    size="small"
                                    helperText={errors.size ? errors.size : ""}
                                    error={Boolean(errors.size)}
                                    label={hasContracts ? "Leg 1 Contracts" : "Leg 1 Quantity"}
                                    value={size ?? ""}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onChange={(e) => onChangeSize(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                    }} />
                            </Grid>
                            {hasContracts && <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    style={{ width: "150px" }}
                                    id="Quantity"
                                    name="Quantity"
                                    size="small"
                                    helperText={errors.size ? errors.size : ""}
                                    error={Boolean(errors.size)}
                                    label="Leg 1 Quantity"
                                    value={tempQuantity ?? (size ? (size * (ins?.multiplier ?? 1.0)) : "")}
                                    onChange={(e) => onChangeQuantity(e.target.value)}
                                    onKeyDown={(e) => onEnterPressed(e)}
                                    onBlur={onBlur}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                            </Grid>}
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    style={{ width: "150px" }}
                                    id="sizeRatio"
                                    name="sizeRatio"
                                    size="small"
                                    helperText={errors.leg2SizeRatio ? errors.leg2SizeRatio : ""}
                                    error={Boolean(errors.leg2SizeRatio)}
                                    label={"Leg 2 Size Ratio"}
                                    value={leg2SizeRatio ?? ""}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onChange={(e) => onChangeSizeRatio(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                    }} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    style={{ width: "150px" }}
                                    id="leg2Contracts"
                                    name="leg2Contracts"
                                    size="small"
                                    helperText={errors.leg2SizeRatio ? errors.leg2SizeRatio : ""}
                                    error={Boolean(errors.leg2SizeRatio)}
                                    label={"Leg 2 Contracts"}
                                    value={leg2SizeRatio * size ?? ""}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                        readOnly: true
                                    }} />
                            </Grid>
                        </FormSection>
                        <FormSection label={<Typography variant="h6" style={titleStyle}>Order Type</Typography>}>
                            <Grid item>
                                <WrappedSelect
                                    id={"orderType"}
                                    name="orderType"
                                    label="Order Type"
                                    style={{ width: "170px" }}
                                    value={orderType === undefined ? '' : OrderType[orderType]}
                                    onChange={(e, c) => onChangeOrderType(e)}>
                                    {Object.keys(OrderType).filter(x => isNaN(Number(x)) && LeggedOrderTypes.includes(OrderType[x])).map((v, ixx) => <MenuItem key={"otype" + ixx} value={v}>{v}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" variant="outlined" size="small" >
                                    <FormLabel>Direction Leg 1</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="buysell"
                                        name="radio-buttons-buysell-group" onChange={(e, v) => directionSelected(v, false)} value={isBuyOrder ? "buy" : "sell"}>
                                        <FormControlLabel value="buy" control={<Radio color="primary" />} label="Buy" />
                                        <FormControlLabel value="sell" control={<Radio color="secondary" />} label="Sell" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" variant="outlined" size="small">
                                    <FormLabel>Direction Leg 2</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="buysell"
                                        name="radio-buttons-buysell-group" onChange={(e, v) => directionSelected(v, true)} value={!isBuyOrder ? "buy" : "sell"}>
                                        <FormControlLabel value="buy" control={<Radio color="primary" />} label="Buy" />
                                        <FormControlLabel value="sell" control={<Radio color="secondary" />} label="Sell" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {isLimit && <Grid item>
                                <TextField
                                    variant="outlined"
                                    style={{ width: "180px" }}
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="limitLevel"
                                    name="limitLevel"
                                    size="small"
                                    helperText={"[Limit] = [Leg 1] - [Leg 2]"}
                                    error={Boolean(errors.limitLevel)}
                                    label="Limit Level"
                                    value={limitLevel}
                                    //onChange={change.bind(null, "limitLevel")}
                                    onChange={(e) => onChangeLimit(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                    }} /></Grid>}
                        </FormSection>
                        <FormSection label={<Typography variant="h6" style={titleStyle}>Validity & Parameters</Typography>}>
                            <Grid item>
                                <WrappedSelect
                                    id="Good"
                                    name="Good"
                                    label="Time In Force"
                                    value={timeInForce === undefined || !isValidTifForVenue(GoodUntil[timeInForce]) ? '' : GoodUntil[timeInForce]}
                                    onChange={(e, c) => onChangeTimeInForce(e)}>
                                    {Object.keys(GoodUntil)
                                        .filter(x => isNaN(Number(x)) && isValidTifForVenue(x))
                                        .map((v, ixx) => <MenuItem key={"tif" + ixx} value={v}>{v}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            {timeInForce === GoodUntil.Time ? <Grid item>
                                <WrappedDateTimePicker
                                    id="gooduntil"
                                    name="gooduntil"
                                    disablePast
                                    helperText={touched.gooduntil ? touched.gooduntil : ""}
                                    error={Boolean(errors.gooduntil)}
                                    label="Good Until (UTC)"
                                    value={goodUntil}
                                    onChange={(d) => onChangeGoodUntil(d)} />
                            </Grid> : null}
                            <Grid item><TextField
                                variant="outlined"
                                style={{ width: "180px" }}
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id="leg1SliceSize"
                                name="leg1SliceSize"
                                size="small"
                                helperText={"Clip size for Leg 1"}
                                error={Boolean(errors.leg1SliceSize)}
                                label="Leg 1 Slice Size"
                                value={leg1SliceSize}
                                //onChange={change.bind(null, "limitLevel")}
                                onChange={(e) => onChangeSliceSize(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                    inputComponent: NumberFormatCustom as any,
                                }} /></Grid>
                            <Grid item>
                                <WrappedSelect
                                    id="aggressive"
                                    name="aggressive"
                                    label="Aggressive"
                                    value={aggressiveness === undefined ? '' : LeggedOrderAggressiveness[aggressiveness]}
                                    onChange={(e, c) => onChangeAggressiveness(e)}>
                                    {Object.keys(LeggedOrderAggressiveness)
                                        .filter(x => isNaN(Number(x)))
                                        .map((v, ixx) => <MenuItem key={"aggress" + ixx} value={v}>{v}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                        </FormSection>

                        <FormSection label={<Typography variant="h6" style={titleStyle}>Comments</Typography>}>
                            <FieldArray name="comments" render={(arrayHelpers: FieldArrayRenderProps) => (
                                <div className="InsMetaDataBox">
                                    {comments && comments.length > 0 ? (
                                        comments.map((c: OrderComment, ix) => (
                                            <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <TextareaAutosize value={c.text} onChange={(event: React.FormEvent<HTMLTextAreaElement>) => { onCommentChange(event.currentTarget.value, ix) }} />
                                                    </Grid>
                                                    <IconButton
                                                        onClick={() => { arrayHelpers.remove(ix); setFieldTouched("comments", true, false) }}
                                                        className="ListedInstrumentEditorFormFieldInner"
                                                        size="large">
                                                        <RemoveOutlined color="inherit" />
                                                    </IconButton>
                                                </Grid>
                                            </div>
                                        ))
                                    ) : null}
                                    <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                                        <Button variant="outlined" onClick={() => arrayHelpers.push({ text: "", created: moment().toISOString(), userId: userStoreInstance.GetUserInfo().userId } as OrderComment)} className="PltfmButtonLite">
                                            <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                                            <ListItemText>Add</ListItemText>
                                        </Button>
                                    </div>
                                </div>
                            )} />
                            <Grid item></Grid>
                        </FormSection>

                        <GridBreak />

                        <Grid item container spacing={2} justifyContent="center">
                            <Grid item><Button
                                className="PltfmButtonLite"
                                type="submit"
                                variant="outlined"
                                disabled={!isValid || canDuplicate || isSubmitting}
                                endIcon={<CheckCircleOutline />}>Create</Button></Grid>
                            {!canDuplicate ? <Grid item><Button
                                className="PltfmButtonLite"
                                type="reset"
                                variant="outlined"
                                onClick={onDuplicatePressed}
                                endIcon={<CancelOutlined />}>Revert</Button></Grid> : null}
                            {canDuplicate ? <Grid item><Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                onClick={onDuplicatePressed}
                                endIcon={<CancelOutlined />}>Duplicate</Button></Grid> : null}
                        </Grid>
                        {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                            <Grid item><Typography variant="subtitle2">Last created id {lastBookedId}</Typography></Grid>
                        </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </CssBaseline>
        </Form>
    );
}
