import React from 'react';
import FBEmitter from 'fbemitter';
import { Trade, TradeQuery } from './tradeModels';
import tradesStoreInstance from './tradeStore';
import { FetchTradesWithQuery } from './tradeActions';
import { ProductPayoffType, ProductSummary } from '../product/productModels';
import productStoreInstance from '../product/productStore';
import { LoadProductSummaries } from '../product/productActions';
import moment from 'moment';
import { UserSummary } from '../user/userModel';
import {
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import { renderDate } from '../utils/helpers';
import { Filter1Outlined, QueryBuilderOutlined } from '@mui/icons-material';
import { getFormTheme } from '../inputs/formCommon';
import userStoreInstance from '../user/userStore';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { EarliestDate } from '../globalConstants';
import { v4 } from 'uuid';
import { TradeBlotterTabProps } from './tradeBlotterTab';
import { BlotterDataRow, GetParam, sizeMapColumns, nameMapColumns, dateTimeColumns, numberColumns, nullUser, nullInsType, validInsTypes, TradeBlotterState } from './tradeBlotterTabComponent';
import { PltfmDateTimePicker } from '../utils/dates';
import { WrappedSelect } from '../inputs/wrappedSelect';


export interface TradeBlotterWindowProps extends TradeBlotterTabProps {
    onChangeHeight?: (newHeight: number) => void
}

interface TradeBlotterWindowState extends TradeBlotterState {
    height: number
}

const defaultColumns = ["Traded", "Trader", "Description", "Payoff", "PayoffSubType", "Size", "Price", "Ccy", "ExternalId"];

export class TradeBlotterWindow extends React.Component<TradeBlotterWindowProps, TradeBlotterWindowState>{
    eventSubscriptionTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    eventSubscriptionUser: FBEmitter.EventSubscription | undefined;
    abortController = new AbortController();

    constructor(props: TradeBlotterWindowProps) {
        super(props)
        this.state = {
            trades: [],
            productsById: new Map<number, ProductSummary>(),
            rowData: [],
            availableColumnsNames: [],
            selectedColumns: defaultColumns,
            tradeDetailVisible: false,
            selectedRow: {} as BlotterDataRow,
            availableUsers: new Map<number, UserSummary>(),
            columns: [],
            mainRef: React.createRef(),
            toolbarRef: React.createRef(),
            width: 0,
            showQuery: false,
            tradeQuery: GetParam(this.props.getState, "tradeBlotterQuery") ?? { fromDate: { absolute: moment.utc().subtract(1, "week").toDate(), relative: undefined }, uniqueId: v4() } as TradeQuery,
            queryRunning: true,
            querySingleId: true,
            height: 500,
            selectedConfig: undefined,
            showSaveQuery: false,
            gridMenuOpen: false,
            toolbarHeight: 30,
            showFullDateTime: false,
            waitingForRfqUpdate: undefined
        };
        this.onChangeColumns = this.onChangeColumns.bind(this);
        this.onChange = this.onChange.bind(this);
        this.CustomToolbar = this.CustomToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionTrades = tradesStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionUser = userStoreInstance.addChangeListener(() => this.onChange());

        var trades = tradesStoreInstance.getTradeQuery(this.state.tradeQuery.uniqueId);
        if (trades)
            await this.onChange();
        else
            await this.runQuery(this.state.tradeQuery);

        const { availableUsers } = this.state;
        userStoreInstance.GetUsers().forEach(user => {
            availableUsers.set(user.userId, user);
        });
    }

    componentWillUnmount() {
        this.abortController.abort();

        if (this.eventSubscriptionTrades) {
            this.eventSubscriptionTrades.remove();
            this.eventSubscriptionTrades = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
        if (this.eventSubscriptionUser) {
            this.eventSubscriptionUser.remove();
            this.eventSubscriptionUser = undefined;
        }
    }

    async runQuery(query: TradeQuery) {
        this.setState({ queryRunning: true });
        this.props.onChangeState("tradeBlotterQueryRunning", "true");
        //await FetchTradesWithQuery(query, this.abortController.signal);
        await FetchTradesWithQuery(query);
    }

    CustomToolbar(props: any) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getPositionTableTheme()}>
                    <GridToolbarContainer className="TradeBlotterDataGrid">
                        <Grid container spacing={0.5}>
                            <Grid item><GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" /></Grid>
                            <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" inputprops={{ size: "small" }} /></Grid>
                            <Grid item><GridToolbarDensitySelector {...props} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" /></Grid>
                            <Grid item><GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" /></Grid>
                            <Grid item><Button
                                variant="outlined"
                                className="MuiButton-outlined PltfmButtonLite"
                                size="small"
                                startIcon={<Filter1Outlined />}
                                onClick={() => { this.setState({ showQuery: !this.state.showQuery }); this.props.onChangeState("tradeBlotterShowQuery", this.state.showQuery ? "false" : "true"); }}>
                                Query
                            </Button></Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    onCellDoubleClick(param: GridCellParams) {
        const { rowData } = this.state;
        this.setState({ selectedRow: rowData.filter(r => r.id === param.row.id)[0], tradeDetailVisible: true });
    }

    async onChange(newWidth?: number, selectedColumns?: string[]) {

        const { productsById, availableUsers, tradeQuery } = this.state;

        var trades = tradesStoreInstance.getTradeQuery(tradeQuery.uniqueId);
        if (!trades)
            return;
        trades = trades.sort((a, b) => a.executedUtc.valueOf() - b.executedUtc.valueOf());
        if (!newWidth)
            newWidth = this.state.width;
        if (!selectedColumns)
            selectedColumns = this.state.selectedColumns;

        let productsNeeded = new Array<number>();

        trades.forEach(trade => {
            if (!productsById.has(trade.productId)) {
                let product = productStoreInstance.getProductById(trade.productId);
                if (product !== undefined && product !== null)
                    productsById.set(trade.productId, product);
                else {
                    productsNeeded.push(trade.productId);
                }
            }
        });
        if (productsNeeded.length > 0) {
            var summaries = await LoadProductSummaries(productsNeeded, this.abortController.signal);
            summaries.forEach(summary => {
                productsById.set(summary.productId, summary);
            });
        }

        let rowData = this.getData(trades, productsById, availableUsers);
        //let maxSizes = this.calcColumnSizes(rowData);
        let availableColumnsSet = new Set<string>();
        rowData.forEach(row => {
            var names = Object.keys(row);
            names.forEach(name => {
                availableColumnsSet.add(name)
            });
        });

        let availableColumnsNames = Array.from(availableColumnsSet);
        let colWidth = 120;
        let columns = availableColumnsNames.sort((a, b) => defaultColumns.indexOf(a) - defaultColumns.indexOf(b)).map(c => {
            return {
                flex: c === "Description" ? 2 : null,
                width: sizeMapColumns.filter(n => n.name === c)[0]?.size ?? colWidth,
                field: c,
                headerName: nameMapColumns.filter(n => n.name === c)[0]?.map ?? c,
                type: dateTimeColumns.includes(c) ? "dateTime" : (numberColumns.includes(c) ? "number" : null),
                renderCell: dateTimeColumns.includes(c) ? this.renderDateTimeCell : null,
                cellClassName: "PositionSummaryTabTableCell",
                headerClassName: "TradeBlotterTableCellHeader",
                hide: !selectedColumns.includes(c),
                resizable: true,
            } as GridColDef;
        });
        this.props.onChangeState("tradeBlotterQueryRunning", "false");
        this.setState({ trades, productsById, rowData, availableColumnsNames, columns, queryRunning: false });
    }

    renderDateTimeCell(params: GridCellParams) {
        return <span>{renderDate(new Date(params.value as string))}</span>;
    }

    getData(trades: Trade[], productsById: Map<number, ProductSummary>, availableUsers: Map<number, UserSummary>) {
        let rowData = trades.sort((a, b) => b.executedUtc.valueOf() - a.executedUtc.valueOf()).map(row => {
            var isListed = row.listedInstrumentId && row.listedInstrumentId > 0;
            var lins = isListed ? listedInstrumentStoreInstance.getInstrumentById(row.listedInstrumentId) : undefined;
            var hasUl = isListed && lins && lins.underlying;
            var ullins = hasUl ? listedInstrumentStoreInstance.getInstrumentById(lins.underlyingId) : undefined;
            var dataRow = {
                id: row.tradeId,
                Traded: row.executedUtc,
                Trader: availableUsers.get(row.createdBy)?.userInfo?.displayName,
                Last: row.lastUpdatedUtc,
                Id: row.tradeId,
                Description: isListed ? lins?.description : productsById.get(row.productId)?.description,
                Payoff: isListed ? lins?.type : ProductPayoffType[productsById.get(row.productId)?.spec?.payoffType],
                PayoffSubType: hasUl ? ullins?.type : productsById.get(row.productId)?.spec?.payoffSubType,
                Size: row.size,
                Ccy: row.currency,
                Price: row.price,
                Status: row.status,
                RfqId: row.rfqId,
                ProductId: row.productId,
            } as BlotterDataRow;
            if (row.tradeMetaData)
                row.tradeMetaData.forEach(meta => {
                    if (meta.category == null)
                        dataRow[meta.type] = meta.data;
                    else
                        dataRow[`${this.mapMetaCategory(meta.category)}-${meta.type}`] = meta.data;
                });
            if (lins?.metaData)
                lins.metaData.forEach(meta => {
                    if (meta.category == null)
                        dataRow[meta.type] = meta.data;
                    else
                        dataRow[`${this.mapMetaCategory(meta.category)}-${meta.type}`] = meta.data;
                });
            return dataRow;
        });
        return rowData;
    }

    mapMetaCategory(c: string) {
        switch (c) {
            case "AdditionalData":
                return "AD";
            case "ExternalId":
                return "EX";
            default:
                return c;
        }
    }

    onChangeColumns(columns: string[]) {
        this.setState({ selectedColumns: columns });
        this.onChange(null, columns);
    }

    updateQuery(query: TradeQuery) {
        var queryStr = JSON.stringify(query);
        this.props.onChangeState("tradeBlotterQuery", queryStr)
    }

    renderTradeQuery() {
        const { showQuery: showFilter, tradeQuery, queryRunning } = this.state;
        if (showFilter) {
            return (
                <div className="TradeBlotterQuery">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <PltfmDateTimePicker
                                        selectedDate={this.state.tradeQuery.fromDate}
                                        onChange={(date) => {
                                            var q = this.state.tradeQuery;
                                            q.fromDate = date;
                                            this.updateQuery(q);
                                            this.setState({ tradeQuery: q })
                                        }}
                                        disabled={false}
                                        label="From Date"
                                        minDate={EarliestDate}
                                        size="small"
                                        width="120px" />
                                </Grid>
                                <Grid item>
                                    <PltfmDateTimePicker
                                        selectedDate={this.state.tradeQuery.toDate}
                                        onChange={(date) => {
                                            var q = this.state.tradeQuery;
                                            q.toDate = date;
                                            this.updateQuery(q);
                                            this.setState({ tradeQuery: q })
                                        }}
                                        disabled={false}
                                        label="To Date"
                                        minDate={EarliestDate}
                                        size="small"
                                        width="120px" />
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="trader"
                                        name="trader"
                                        label="Trader"
                                        value={tradeQuery.trader ?? nullUser}
                                        onChange={(d) => {
                                            var q = this.state.tradeQuery;
                                            q.trader = d.target.value as string;
                                            if (q.trader === nullUser)
                                                q.trader = null;
                                            this.updateQuery(q);
                                            this.setState({ tradeQuery: q });
                                        }}>
                                        {[nullUser, ...userStoreInstance.GetUsers().map(a => a.name)].map(a =>
                                            <MenuItem key={"user_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        label={"Description"}
                                        placeholder="Description"
                                        value={this.state.tradeQuery.description ?? ""}
                                        onChange={(e) => {
                                            var q = this.state.tradeQuery;
                                            q.description = e.target.value;
                                            this.updateQuery(q);
                                            this.setState({ tradeQuery: q });
                                        }} />
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        style={{ minWidth: "145px" }}
                                        id="type"
                                        name="type"
                                        label="Instrument Type"
                                        value={tradeQuery.payoff ?? nullInsType}
                                        onChange={(d) => {
                                            var q = this.state.tradeQuery;
                                            q.payoff = d.target.value as string;
                                            if (q.payoff === nullInsType)
                                                q.payoff = null;
                                            this.updateQuery(q);
                                            this.setState({ tradeQuery: q });
                                        }}>
                                        {[nullInsType, ...validInsTypes].map(a =>
                                            <MenuItem key={"insType_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="onlyMissing"
                                        name="onlyMissing"
                                        label="Fills"
                                        value={tradeQuery.dailyAggregate ? "Daily" : "All Fills"}
                                        onChange={(d) => {
                                            var q = this.state.tradeQuery;
                                            q.dailyAggregate = (d.target.value as string) === "Daily";
                                            this.updateQuery(q);
                                            this.setState({ tradeQuery: q });
                                        }}>
                                        <MenuItem key={"insTypeX_Daily"} value={"Daily"}>Daily</MenuItem>
                                        <MenuItem key={"insTypeX_All Fills"} value={"All Fills"}>All Fills</MenuItem>
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <Button className="PltfmButtonLite" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.tradeQuery)} disabled={queryRunning}>Run Query</Button>
                                </Grid>
                                {queryRunning ?
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid> : null}
                            </Grid>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            );
        }
        return null;
    }

    render() {
        const { rowData, columns, mainRef, showQuery } = this.state;
        if (rowData) {
            return (
                //<div className="TradeBlotterWindow" ref={this.state.mainRef}>
                <div className="TradeBlotterWindow" ref={this.state.mainRef}>
                    {this.renderTradeQuery()}
                    <div className="TradeBlotter" style={{ height: showQuery ? `calc( 100% - 10vh )` : "100%" }}>
                        <div className="TradeBlotterGrid">
                            <div key={`agf-${this.props.id}`} ref={mainRef} style={{ height: "100%", width: "100%", borderRadius: "5px" }}>
                                <DataGridPro
                                    className="TradeBlotterDataGrid"
                                    key={`grid-${this.props.id}`}
                                    rows={rowData}
                                    columns={columns}
                                    components={{
                                        Toolbar: this.CustomToolbar,
                                    }}
                                    hideFooter
                                />
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
        else
            return null;
    }
}