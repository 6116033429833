import React from 'react';
import FBEmitter from 'fbemitter';
import marketDataStoreInstance from '../marketDataStore';
import userStoreInstance from '../../user/userStore';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { Button, Grid, MenuItem, Paper, SelectChangeEvent, StyledEngineProvider, Tab, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tabs } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { WrappedDatePicker } from '../../inputs/wrappedDatePicker';
import { Moment } from 'moment';
import { WrappedSelect } from '../../inputs/wrappedSelect';
import { GetVolSurface } from '../marketDataActions';
import { TO_GridVolSurface } from '../../qwack/to_GridVolSurface';
import GridVolSurface from '../../qwack/gridVolSurface';
import moment from 'moment';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { TabContext, TabPanel } from '@mui/lab';
import { getPositionTableTheme } from '../../positions/positionSummaryTable';
import { getFormTheme } from '../../inputs/formCommon';
import _ from 'lodash';
import { SmileChart } from './smileChart';


export type SurfaceExplorerProps = {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
    fullWidth: boolean;
}

type SurfaceExplorerState = {
    selectedDate: Date,
    availableDates: string[],
    availableInstruments: number[],
    selectedInstrument: number,
    currentTheme: string,
    surfaceTo: TO_GridVolSurface
    surface: GridVolSurface,
    selectedTabId: number
}


export class SurfaceExplorer extends React.Component<SurfaceExplorerProps, SurfaceExplorerState>{
    eventSubscriptionMarketData: FBEmitter.EventSubscription | undefined;
    eventSubscriptionThemes: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: SurfaceExplorerProps) {
        super(props)
        this.state = {
            selectedDate: undefined,
            availableDates: [],
            availableInstruments: [],
            selectedInstrument: parseInt(this.props.getState("selectedInstrument")),
            currentTheme: userStoreInstance.GetThemeName(),
            surfaceTo: undefined,
            surface: undefined,
            selectedTabId: 0
        };

        this.setTheme = this.setTheme.bind(this);
        this.marketDataUpdate = this.marketDataUpdate.bind(this);
        this.shouldDisableDate = this.shouldDisableDate.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeInstrument = this.onChangeInstrument.bind(this);
        this.loadSurface = this.loadSurface.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionThemes = userStoreInstance.addChangeListener(this.setTheme);
        this.eventSubscriptionMarketData = marketDataStoreInstance.addChangeListener(this.marketDataUpdate);
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.onInstrumentChange);
        //await this.marketDataUpdate();
        var availableDates = marketDataStoreInstance.getAvailableVolDates();
        this.setState({ availableDates: availableDates.map(d => d.toDateString()) });
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        if (this.eventSubscriptionMarketData) {
            this.eventSubscriptionMarketData.remove();
            this.eventSubscriptionMarketData = undefined;
        }
        if (this.eventSubscriptionThemes) {
            this.eventSubscriptionThemes.remove();
            this.eventSubscriptionThemes = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
    }

    async marketDataUpdate() {

    }

    onInstrumentChange() {

    }

    setTheme() {
        this.setState({ currentTheme: userStoreInstance.GetThemeName() });
    }

    async onChange() { }


    onChangeDate(date: Moment) {
        var availableIns = marketDataStoreInstance.getAvailableVolSurfacesForDate(date.toDate());
        this.setState({ selectedDate: date.toDate(), availableInstruments: availableIns });
    }

    shouldDisableDate(m: Moment) {
        return !this.state.availableDates.includes(m.toDate().toDateString())
    }

    onChangeInstrument(event: SelectChangeEvent<any>) {
        var insId = Number(event.target.value);
        this.setState({ selectedInstrument: insId });
    }

    async loadSurface() {
        const { selectedDate, selectedInstrument } = this.state;
        var cached = marketDataStoreInstance.getVolSurface(selectedInstrument, selectedDate);
        if (!cached) {
            cached = await GetVolSurface(selectedInstrument, selectedDate);
        }

        if (cached) {
            var surf = new GridVolSurface(cached);
            this.setState({ surfaceTo: cached, surface: surf });
        }
    }

    render() {
        const { selectedDate, availableInstruments, selectedInstrument, surface, selectedTabId } = this.state;

        var SurfaceExplorerData: Data[];
        var SurfaceExplorerLayout: Partial<Layout>;

        if (surface) {
            var strikes5d = _.range(0.05,0.95,0.05);
            //var strikes = [-0.1, -0.25, -0.5, -0.75, -0.9];
            var dates = surface.Expiries.map(e => new Date(e));
            var points = new Array<number[]>();
            surface.Expiries.map((e, eix) => {
                points[eix] = strikes5d.map((k) => {
                    return surface.GetVolForDeltaStrikeExpAsDate(-k, e, 100);
                });
                return null;
            });
            SurfaceExplorerData = [
                {
                    //x: strikes,
                    //y: dates,
                    z: points,
                    type: 'surface',
                    //mode: 'lines+markers',
                    marker: { color: userStoreInstance.GetTheme().chart_color_indic_0 },
                }
            ] as Data[];

            var nExpiryTicks = 8;
            var expiryStep = Math.floor(dates.length / nExpiryTicks);
            var filteredDates = dates.filter((d, ix) => ix === 0 || ix / expiryStep === Math.floor(ix / expiryStep));
            var filteredDateIxs = dates.map((d, ix) => ix === 0 || ix / expiryStep === Math.floor(ix / expiryStep) ? ix : null).filter(x => x !== null);
            SurfaceExplorerLayout = {
                title: { text: "Delta-Space Implied Volatility Surface", font: { color: userStoreInstance.GetTheme().color, family: userStoreInstance.GetTheme().font_family } },
                paper_bgcolor: userStoreInstance.GetTheme().background_color,
                scene: {
                    camera: { eye: { x: 1.5, y: -1.5, z: 0.8 } },
                    xaxis: {
                        title: "Put Delta",
                        ticktext: strikes5d.map(k => `${(k * 100).toFixed(0)}%`),
                        tickvals: strikes5d.map((k, ix) => ix),
                        color: userStoreInstance.GetTheme().color
                    },
                    yaxis: {
                        title: "Expiry",
                        ticktext: filteredDates.map(d => moment(d).format("DDMMMYY")),
                        tickvals: filteredDateIxs,
                        range: [0, dates.length],
                        color: userStoreInstance.GetTheme().color
                    },
                    zaxis: {
                        title: "Implied Vol",
                        nticks: 5,
                        tickformat:"~%",
                        color: userStoreInstance.GetTheme().color
                    },
                    bgcolor: userStoreInstance.GetTheme().background_color
                }
            }
        }

        return (
            <div className={`MarketDashboard`}>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2} direction="column" paddingTop="0.5em" wrap="nowrap">
                        <Grid container spacing={2} item direction="row">
                            <Grid item>
                                <WrappedDatePicker
                                    style={{ width: "120px" }}
                                    value={selectedDate}
                                    onChange={this.onChangeDate}
                                    label="AsOf"
                                    shouldDisableDate={this.shouldDisableDate}
                                />
                            </Grid>
                            {availableInstruments && availableInstruments.length > 0 ? <Grid item>
                                <WrappedSelect
                                    value={!selectedInstrument || isNaN(selectedInstrument) ? 0 : selectedInstrument}
                                    label="Instrument"
                                    onChange={this.onChangeInstrument}                            >
                                    {[0, ...availableInstruments].map(a =>
                                        <MenuItem key={"vs_ins_dd" + a} value={a}>{a === 0 ? "Select Instrument..." : listedInstrumentStoreInstance.getInstrumentById(a)?.description?.split(' Underlying Index')[0] ?? "(Unknown)"}</MenuItem>)}
                                </WrappedSelect>
                            </Grid> : null}
                            {selectedInstrument && selectedInstrument !== 0 ? <Grid item>
                                <Button className="PltfmButtonLite" onClick={this.loadSurface}>Load Surface</Button>
                            </Grid> : null}
                        </Grid>
                        {/* Results pane */}
                        {surface ?
                            <Grid item overflow="auto" height="100%">
                                <TabContext value={selectedTabId.toString()}>
                                    <Tabs key='mdTabs' value={selectedTabId.toString()} onChange={(e, v) => this.setState({ selectedTabId: v })} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                                        <Tab
                                            classes={{ root: "tabTitle" }}
                                            value={"0"}
                                            key={"tabSurf1"}
                                            component={Paper}
                                            id={"tab0"}
                                            label="Surface" />
                                        <Tab
                                            classes={{ root: "tabTitle" }}
                                            value={"1"}
                                            key={"tabSurf2"}
                                            component={Paper}
                                            id={"tab1"}
                                            label="Table" />
                                        <Tab
                                            classes={{ root: "tabTitle" }}
                                            value={"2"}
                                            key={"tabSurf3"}
                                            component={Paper}
                                            id={"tab2"}
                                            label="Smile" />
                                    </Tabs>
                                    <TabPanel
                                        style={{ height: "calc(100% - 50px)" }}
                                        key={"tabPQuery1"}
                                        value={"0"}
                                        children={
                                            <Plot
                                                data={SurfaceExplorerData}
                                                //layout={{width: width, height: height}}
                                                layout={SurfaceExplorerLayout}
                                                config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                                style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                                useResizeHandler={true}
                                            />
                                        } />
                                    <TabPanel
                                        style={{ minHeight: "100px" }}
                                        key={"tabPQuery2"}
                                        value={"1"}
                                        children={<StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={getPositionTableTheme()}>
                                                <TableContainer>
                                                    <Table stickyHeader style={{ overflowY: "auto", height: "100%" }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell variant='head'>Expiry</TableCell>
                                                                <TableCell variant='head'>10d</TableCell>
                                                                <TableCell variant='head'>25d</TableCell>
                                                                <TableCell variant='head'>50d</TableCell>
                                                                <TableCell variant='head'>75d</TableCell>
                                                                <TableCell variant='head'>90d</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {surface.Expiries.map((e, ix) => {
                                                                return <TableRow key={"surfz00" + ix}>
                                                                    <TableCell style={{borderColor:userStoreInstance.GetTheme().contrastBorderColorLight}}>{moment(e).format("yyyy-MM-DD")}</TableCell>
                                                                    <TableCell style={{borderColor:userStoreInstance.GetTheme().contrastBorderColorLight}}>{surface.GetVolForDeltaStrikeExpAsDate(-0.1, new Date(e), 100)?.toFixed(4)}</TableCell>
                                                                    <TableCell style={{borderColor:userStoreInstance.GetTheme().contrastBorderColorLight}}>{surface.GetVolForDeltaStrikeExpAsDate(-0.25, new Date(e), 100)?.toFixed(4)}</TableCell>
                                                                    <TableCell style={{borderColor:userStoreInstance.GetTheme().contrastBorderColorLight}}>{surface.GetVolForDeltaStrikeExpAsDate(-0.5, new Date(e), 100)?.toFixed(4)}</TableCell>
                                                                    <TableCell style={{borderColor:userStoreInstance.GetTheme().contrastBorderColorLight}}>{surface.GetVolForDeltaStrikeExpAsDate(-0.75, new Date(e), 100)?.toFixed(4)}</TableCell>
                                                                    <TableCell style={{borderColor:userStoreInstance.GetTheme().contrastBorderColorLight}}>{surface.GetVolForDeltaStrikeExpAsDate(-0.9, new Date(e), 100)?.toFixed(4)}</TableCell>
                                                                </TableRow>
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </ThemeProvider>
                                        </StyledEngineProvider>} />
                                    <TabPanel
                                        style={{ height: "calc(100% - 50px)" }}
                                        key={"tabPQuery2"}
                                        value={"2"}
                                        children={<SmileChart insId={selectedInstrument} valDate={selectedDate} onChangeState={()=>{}} getState={()=>{return null}} />
                                        } />
                                </TabContext>
                            </Grid>
                            : null}
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }
}