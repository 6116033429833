import {
    Button,
    Grid,
    TextField,
    ThemeProvider,
    Typography,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { Form } from "formik";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import Autocomplete from '@mui/material/Autocomplete';
import moment, { Moment } from "moment";
import navStoreInstance from "./navStore";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";
import { NumberFormatCustom } from "../inputs/numberFormatInput";
import { FormSection } from "../inputs/formSection";

export const SubsRedsForm = (props) => {
    const {
        values: {
            subsRedsId,
            fund,
            shareClass,
            totalValue,
            numberOfShares,
            nav,
            gav,
            official,
            asOfDate,
            investor,
            underlyingInvestor,
            externalId,
            administrator,
        },
        errors,
        //touched,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        canDuplicate,
        onDuplicatePressed,
        lastBookedId
        //metaCollapsable,
        //balanceTrade
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    const filterCcyOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue?.toLowerCase();
        var relevant = options.filter(o => ccyMatchesQuery(o, query))
        return relevant;
    };

    const ccyMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins?.toLowerCase();
        const normalizedQuery = query?.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const onShareClassSelect = (shareClass: string) => {
        if (shareClass === NullShareClass)
            setFieldValue("shareClass", null);
        else
            setFieldValue("shareClass", shareClass);
    }

    const onBookingEntitySelect = (firm: string) => {
        setFieldValue("fund", firm);
    }

    const onChangeAsOf = (d: Moment) => {
        setFieldValue("asOfDate", d);
    }

    const onChangeTotalValue = (v: string) => {
        var asNum = Number(v);

        setFieldValue("totalValue", v);

        if (asNum && Math.sign(asNum) !== Math.sign(numberOfShares) && !isNaN(Number(numberOfShares))) {
            setFieldValue("numberOfShares", -Number(numberOfShares));
        }
    }

    const onChangeNumSharesValue = (v: string) => {
        var asNum = Number(v);

        if (!isNaN(asNum))
            setFieldValue("numberOfShares", asNum);
        else if (v?.toLowerCase() === "i")
            implyNumShares();

    }

    const onChangeGavValue = (v: string, field: string) => {
        var asNum = Number(v);

        if (!isNaN(asNum) && !v.endsWith("."))
            setFieldValue(field, asNum);
        else if (((v.endsWith(".") || v.endsWith("0")) && !isNaN(Number(v.split(".")[0]))))
            setFieldValue(field, v);
        else if (v?.toLowerCase() === "i")
            fetchNavGav();
    }

    const implyNumShares = () => {
        if (Number(totalValue) && Number(gav)) {
            setFieldValue("numberOfShares", Math.round(Number(totalValue) / Number(gav) * 10000) / 10000);
        }
    }

    async function fetchNavGav() {
        if (Boolean(asOfDate) && Boolean(fund)) {
            var n = await navStoreInstance.getNavs(fund, shareClass);
            var forDate = n?.filter(nn => moment(nn.date).startOf('d') === moment(asOfDate).startOf('d'))[0];
            if (forDate) {
                setFieldValue("gav", forDate.gavPerShare);
                setFieldValue("nav", forDate.navPerShare);
            }

        }
    }

    const NullShareClass = "Select Class...";
    const NullFund = "Select Fund...";

    return (
        <Form onSubmit={handleSubmit} className={className}>
            <ThemeProvider theme={getFormTheme()}>
                <Grid container spacing={1} justifyContent="center" >
                    <FormSection label={<Typography variant="h6" width={200}>Fund & Date</Typography>}>
                        <TextField
                            variant="outlined"
                            style={{ width: "100px" }}
                            classes={{ root: "ListedInstrumentEditorFormFieldInner" }}
                            id="Id"
                            name="Id"
                            label="Id"
                            value={subsRedsId ?? "0"}
                            InputProps={{ readOnly: true }}
                            onChange={() => { }} />
                        <WrappedDatePicker
                            disabled={canDuplicate}
                            key={"asOf" + subsRedsId}
                            id="asOf"
                            name="asOf"
                            helperText={errors.asOfDate ? errors.asOfDate : ""}
                            error={errors.asOfDate !== undefined || Boolean(errors.asOfDate)}
                            label="As-Of Date"
                            value={moment.utc(asOfDate).toDate()}
                            size="medium"
                            onChange={(d) => onChangeAsOf(d)} />
                        <Autocomplete
                            classes={{
                                inputRoot: "BasketDesignerAutocomplete",
                                //input: "BasketDesignerAutocompleteInput",
                                popupIndicator: "BasketDesignerAutocompleteIcon",
                                clearIndicator: "BasketDesignerAutocompleteIcon",
                                popper: "AutocompleteGroupLabel",
                            }}
                            disabled={canDuplicate}
                            autoComplete
                            options={[NullFund, ...navStoreInstance.getFunds().map(f => f.name)]}
                            value={fund ?? NullFund}
                            id="fund"
                            getOptionLabel={(firm: string) => firm}
                            style={{ width: 250 }}
                            filterOptions={filterCcyOptions}
                            onChange={(e, v) => onBookingEntitySelect(v)}
                            renderInput={(params) => <TextField {...params}
                                helperText={errors.fund ? errors.fund : ""}
                                error={errors.fund !== undefined || Boolean(errors.fund)}
                                label="Fund"
                                variant="outlined" />} />

                        <Autocomplete
                            classes={{
                                inputRoot: "BasketDesignerAutocomplete",
                                //input: "BasketDesignerAutocompleteInput",
                                popupIndicator: "BasketDesignerAutocompleteIcon",
                                clearIndicator: "BasketDesignerAutocompleteIcon",
                                popper: "AutocompleteGroupLabel",
                            }}
                            disabled={canDuplicate}
                            autoComplete
                            options={[NullShareClass, ...navStoreInstance.getShareClasses(fund)]}
                            value={shareClass ?? NullShareClass}
                            id="shareClass"
                            getOptionLabel={(firm: string) => firm}
                            style={{ width: 250 }}
                            filterOptions={filterCcyOptions}
                            onChange={(e, v) => onShareClassSelect(v)}
                            renderInput={(params) => <TextField {...params}
                                helperText={errors.shareClass ? errors.shareClass : ""}
                                error={errors.shareClass !== undefined || Boolean(errors.shareClass)}
                                label="Share Class"
                                variant="outlined" />} />
                    </FormSection>
                    <FormSection label={<Typography variant="h6" width={200}>Shares & Prices</Typography>}>
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="totalValue"
                            name="totalValue"
                            helperText={errors.size ? errors.size : ""}
                            error={Boolean(errors.size)}
                            label="Total Amount"
                            value={totalValue ?? ""}
                            onChange={(e) => onChangeTotalValue(e.target.value)}
                            onFocus={event => {
                                event.target.select();
                            }}
                            InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="nav"
                            name="nav"
                            helperText={errors.nav ? errors.nav : ""}
                            error={Boolean(errors.nav)}
                            label="NAV"
                            value={nav ?? ""}
                            onChange={(e) => onChangeGavValue(e.target.value, "nav")}
                            InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="gav"
                            name="gav"
                            helperText={errors.gav ? errors.gav : ""}
                            error={Boolean(errors.gav)}
                            label="GAV"
                            value={gav ?? ""}
                            onChange={(e) => onChangeGavValue(e.target.value, "gav")}
                            InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="numberOfShares"
                            name="numberOfShares"
                            helperText={errors.numberOfShares ? errors.numberOfShares : ""}
                            error={Boolean(errors.numberOfShares)}
                            label="Number Of Shares"
                            value={numberOfShares ?? ""}
                            onChange={(e) => onChangeNumSharesValue(e.target.value)}
                            onFocus={event => {
                                event.target.select();
                            }}
                            //InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <FormControlLabel
                            disabled={canDuplicate}
                            id={"officialFc" + subsRedsId}
                            control={<Checkbox
                                id="official"
                                name="official"
                                checked={official ?? false}
                                onChange={(e, c) => setFieldValue("official", c)} />}
                            label="Official" />
                    </FormSection>
                    <FormSection label={<Typography variant="h6" width={200}>Investor</Typography>}>
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="investor"
                            name="investor"
                            helperText={errors.investor ? errors.investor : ""}
                            error={Boolean(errors.investor)}
                            label="Investor"
                            value={investor ?? ""}
                            onChange={change.bind(null, "investor")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="underlyingInvestor"
                            name="underlyingInvestor"
                            helperText={errors.underlyingInvestor ? errors.underlyingInvestor : ""}
                            error={Boolean(errors.underlyingInvestor)}
                            label="U/L Investor"
                            value={underlyingInvestor ?? ""}
                            onChange={change.bind(null, "underlyingInvestor")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="administrator"
                            name="administrator"
                            helperText={errors.administrator ? errors.administrator : ""}
                            error={Boolean(errors.administrator)}
                            label="Administrator"
                            value={administrator ?? ""}
                            onChange={change.bind(null, "administrator")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        <TextField
                            disabled={canDuplicate}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="externalId"
                            name="externalId"
                            helperText={errors.externalId ? errors.externalId : ""}
                            error={Boolean(errors.externalId)}
                            label="External Id"
                            value={externalId ?? ""}
                            onChange={change.bind(null, "externalId")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                    </FormSection>
                    <GridBreak />
                    <Grid item container spacing={2} justifyContent="center">
                        <Grid item><Button
                            className="PltfmButtonLite"
                            type="submit"
                            variant="outlined"
                            disabled={!isValid || canDuplicate}
                            endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
                        <Grid item><Button
                            className="PltfmButtonLite"
                            type="reset"
                            variant="outlined"
                            onClick={onDuplicatePressed}
                            endIcon={<CancelOutlined />}>Revert</Button></Grid>
                        {canDuplicate ? <Grid item><Button
                            className="PltfmButtonLite"
                            variant="outlined"
                            onClick={() => { setFieldValue("subsRedsId", 0); onDuplicatePressed() }}
                            endIcon={<CancelOutlined />}>Duplicate</Button></Grid> : null}
                    </Grid>
                    {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                        <Grid item><Typography variant="subtitle2">Last booked id {lastBookedId}</Typography></Grid>
                    </Grid> : null}
                </Grid>
            </ThemeProvider>
        </Form >
    );
};