import { Moment } from "moment"

export enum DayCountBasis {
    acT360 = 360,
    act360 = 360,
    act_360 = 360,
    acT365 = 365,
    act365 = 365,
    act_365 = 365,
    act_Act = 365,
    act_Act_ISDA = 366,
    act_Act_ICMA = 367,
    act_365F = 3650,
    act365F = 3650,
    acT365F = 3650,
    act_364 = 364,
    act364 = 364,
    acT364 = 364,
    _30_360 = 30360,
    thirty360 = 30360,
    _30E_360 = 303600,
    thirtyE360 = 303600,
    bus252 = 252,
    buS252 = 252,
    bus_252 = 252,
    unity = 1
}

export const CalculateYearFraction = (startDate: Moment, endDate: Moment, basis: DayCountBasis) => {
    switch (basis) {
        case DayCountBasis.acT360:
            return endDate.diff(startDate, 'days') / 360.0;
        case DayCountBasis.acT365:
            {
                if (endDate.year() === startDate.year()) {   //simple case
                    var eoY = endDate.endOf("year");
                    return endDate.diff(startDate, "days") / eoY.dayOfYear();
                }
                else {
                    var nIntermediateYears = endDate.year() - startDate.year() - 1;

                    var eoYe = endDate.endOf("year");
                    var e = endDate.dayOfYear() / eoYe.dayOfYear();

                    var eoYs = startDate.endOf("year");
                    var s = eoYs.diff(startDate, "days") / eoYs.dayOfYear();

                    return s + nIntermediateYears + e;
                }
            }
        case DayCountBasis.acT365F:
        default:
            return endDate.diff(startDate, 'days') / 365.0;
        case DayCountBasis.thirty360:
            {
                var ydiff = endDate.year() - startDate.year();
                var mdiff = endDate.month() - startDate.month();
                var ddiff = endDate.day() - startDate.day();
                return (ydiff * 360 + mdiff * 30 + ddiff) / 360;
            }
        case DayCountBasis.thirtyE360:
            {
                var d1E = Math.min(startDate.day(), 30);
                var d2E = Math.min(endDate.day(), 30);
                var ydiffE = endDate.year() - startDate.year();
                var mdiffE = endDate.month() - startDate.month();
                var ddiffE = d2E - d1E;
                return (ydiffE * 360 + mdiffE * 30 + ddiffE) / 360;
            }
        case DayCountBasis.unity:
            return 1.0;
    }
}