import {
    Button,
    Grid,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    CssBaseline,
} from "@mui/material";
import { Form } from "formik";
import { CancelOutlined, CheckCircleOutline, CopyAllOutlined } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import InstrumentSelection from "../inputs/instrumentSelection";
import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";

export const WhatIfTradeForm = props => {
    const {
        values: { insId,
            price,
            size,
            id
        },
        errors,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        canDuplicate,
        onDuplicatePressed,
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    const duplicate = () => {
        console.log("DUPE");
        onDuplicatePressed();
    }

    const instrumentSelected = (instrument: ListedInstrument) => {
        setFieldValue("insId", instrument.listedInstrumentId);
    }

    const editMode = id!==undefined;

    return (
        <Form onSubmit={handleSubmit} className={className}>
            <CssBaseline>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} justifyContent="center" >
                            <Grid container item spacing={2} justifyContent="center" >
                                <Grid item>
                                    <InstrumentSelection hideExpired={true} listedInstrumentId={insId} onInstrumentSelect={instrumentSelected} filterType={undefined} size="small"></InstrumentSelection>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="size"
                                        name="size"
                                        size="small"
                                        helperText={errors.size ? errors.size : ""}
                                        error={Boolean(errors.size)}
                                        label="Size"
                                        value={size ?? ""}
                                        onChange={change.bind(null, "size")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="price"
                                        name="price"
                                        size="small"
                                        style={{ width: 200 }}
                                        helperText={errors.price ? errors.price : ""}
                                        error={Boolean(errors.price)}
                                        label="Price"
                                        value={price ?? ""}
                                        onChange={change.bind(null, "price")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            </Grid>
                            <GridBreak />
                            <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="submit"
                                    variant="outlined"
                                    disabled={!isValid || canDuplicate}
                                    endIcon={<CheckCircleOutline />}>{editMode ? "Update" : "Add"}</Button></Grid>
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="reset"
                                    variant="outlined"
                                    endIcon={<CancelOutlined />}>Revert</Button></Grid>
                                {editMode ? <Grid item><Button
                                    className="PltfmButtonLite"
                                    variant="outlined"
                                    onClick={()=>duplicate()}
                                    endIcon={<CopyAllOutlined />}>Duplicate</Button></Grid> : null}
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CssBaseline>
        </Form>
    );
};