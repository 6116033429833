import { InteractionStatus } from "@azure/msal-browser";
import { withMsal, WithMsalProps } from "@azure/msal-react";
import React from "react";

class AuthenticateRaw extends React.Component<WithMsalProps, {}>{
    callLogin() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        const msalInstance = this.props.msalContext.instance;

        if(!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            msalInstance.loginRedirect();
        }
    }

    componentDidMount(){
        this.callLogin();
    }

    componentDidUpdate(){
        this.callLogin();
    }

    render()
    {
        return (<div></div>)
    }
}

const Authenticate = withMsal(AuthenticateRaw);

export default Authenticate;