import React from 'react';
import FBEmitter from 'fbemitter';
import { InboxItem } from './inboxModels';
import inboxStoreInstance from './inboxStore';
import StringEnumSelector from '../inputs/stringEnumSelector';
import { RefObject } from 'react';
import { Button, ButtonGroup, Collapse } from '@mui/material';

//import { RespondToRequest } from '../../actions/ApprovalActions';

export interface InboxComponentProps {
    onClose: ((componentKey: string, tabId: number) => void)
    onChangeHeight?: (newHeight: number) => void
    componentKey: string;
    tabId: number;
}

export type InboxComponentState = {
    loading: boolean,
    updated: boolean,
    inboxItems: InboxItem[],
    onlyShowCurrent: boolean,
    showFilterPanel: boolean,
    tagsToFilterOn: string[],
    selectedItem: InboxItem | undefined,
    height: number;
    mainRef: RefObject<HTMLDivElement>,
}

export class Inbox extends React.Component<InboxComponentProps, InboxComponentState>{
    eventSubscriptionInbox: FBEmitter.EventSubscription | undefined;

    constructor(props: InboxComponentProps) {
        super(props);
        this.state = {
            inboxItems: [],
            loading: true,
            onlyShowCurrent: false,
            selectedItem: undefined,
            showFilterPanel: false,
            tagsToFilterOn: new Array<string>(),
            updated: false,
            height: 0,
            mainRef: React.createRef()
        }
    }

    componentDidMount() {
        this.eventSubscriptionInbox = inboxStoreInstance.addChangeListener(() => this.onChange());
        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionInbox) {
            this.eventSubscriptionInbox.remove();
            this.eventSubscriptionInbox = undefined;
        }
    }

    public componentDidUpdate() {
        if (this.props.onChangeHeight) {
            let newHeight = this.state.mainRef?.current?.clientHeight;
            if (newHeight && newHeight !== this.state.height) {
                this.props.onChangeHeight(newHeight);
                this.setState({ height: newHeight });
            }
        }
    }

    onChange() {
        this.setState({ inboxItems: inboxStoreInstance.getInboxItems() })
    }

    onClickOnlyShowCurrent = () => {
        this.setState({ onlyShowCurrent: !this.state.onlyShowCurrent });
    }

    onRightClick(mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, inboxItem: InboxItem) {
        //ContextMenu.show((<Menu><MenuItem text={inboxItem.subject}></MenuItem></Menu>), {left: mouseEvent.clientX, top : mouseEvent.clientY});
        //mouseEvent.preventDefault();
    }

    
    onClickRespond = (verb: string) => {
        if (this.state.selectedItem) {
            //RespondToRequest(verb,this.state.selectedItem.actionKey);
        }
    }

    renderRow(inboxItem: InboxItem) {
        return (
            <div key={`inbox_${inboxItem.uniqueKey}`} className="InboxRow" onContextMenu={e => this.onRightClick(e, inboxItem)}>
                <details onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.currentTarget.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" } as ScrollIntoViewOptions)}>
                    <summary>{inboxItem.subject}</summary>
                    <pre>{inboxItem.payload}</pre>
                    {inboxItem.actionKey === null || inboxItem.actionKey === "" ?
                        null :
                        <ButtonGroup>
                            <Button color="primary" variant="text" onClick={() => this.onClickRespond("Reject")}>Reject</Button>
                            <Button color="secondary" variant="text" onClick={() => this.onClickRespond("Approve")}>Approve</Button>
                        </ButtonGroup>}
                </details>
            </div>
        )
    }

    onChangeTags = (tags: string[]) => {
        if (tags.length === 0) {
            this.setState({ tagsToFilterOn: new Array<string>() });
        }
        else {
            this.setState({ tagsToFilterOn: tags });
        }
    }

    getTags = () => {
        let allTags = this.state.inboxItems.flatMap(x => x.tags);
        let uniqueTags = Array.from(new Set(allTags));
        return uniqueTags;
    }

    renderSelectedItem = () => {
        if (this.state.selectedItem) {
            return (<div style={{ padding: "2px" }}>
                <pre>{this.state.selectedItem.payload}</pre>
            </div>);
        }
        else {
            return (<div></div>);
        }
    }

    renderItemList = () => {
        const items = this.state.inboxItems;
        if (items.length === 0) {
            return (<div className="InboxRow">No messages to display</div>)
        }
        else {
            if (this.state.tagsToFilterOn.length > 0) {
                var filteredItems = items.filter(f => {
                    return f.tags.some(t => {
                        let include = false;
                        if (this.state.tagsToFilterOn && this.state.tagsToFilterOn.includes(t)) {
                            include = true;
                        }
                        return include;
                    });

                });
                return filteredItems.map(i => this.renderRow(i));
            }
            else {
                return items.map(i => this.renderRow(i));
            }
        }

    }

    render() {
        let headerHeight = this.state.showFilterPanel ? 36 + 30 : 30;
        return (
            <div ref={this.state.mainRef} className="InboxSingle">
                <div className="InboxHeader">
                    <div className="InboxHeader FilterButton" >
                        <Button variant={"text"} onClick={() => this.setState({ showFilterPanel: !this.state.showFilterPanel })}>
                            <div className="FilterButtonInner">Filter</div>
                        </Button>
                    </div>
                </div>
                <div >
                    <Collapse in={this.state.showFilterPanel}>
                        <div style={{ display: "inline-flex", width: "100%", backgroundColor: "whitesmoke", height: "36px", padding: "2px" }}>
                            <div style={{ width: "50%", paddingLeft: "5px" }}><StringEnumSelector
                                onChange={this.onChangeTags}
                                fetchValueList={this.getTags}
                                initiallySelected={this.state.tagsToFilterOn}
                                placeholder="Select tags to filter on..." /></div>
                        </div>
                    </Collapse>
                </div>
                <div style={{ display: "block", width: "100%", height: `calc(100% - ${headerHeight}px)`, float: "left", overflowY: "auto", alignItems: "center", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                    {this.renderItemList()}
                </div>
            </div>
        );
    }
}