import _ from 'lodash';
import moment from 'moment';
import dataCacheInstance from '../cache/dataCache';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import { MetaDataQueryResponse } from '../trade/tradeModels';
import { get, put } from '../utils/httpUtils';
import { ListedInstrument, ListedInstrumentMetaData, ListedInstrumentQuery, ListedInstrumentQueryResponse, ListedInstrumentSummary, MissingMetaDataQuery } from './listedInstrumentModels';

export class ListedInstrumentUpdatedEvent extends TypedEvent<ListedInstrument> { }
export class ListedInstrumentUpdatedBatchEvent extends TypedEvent<ListedInstrument[]> { }
export class ListedInstrumentLoadedEvent extends TypedEvent<Map<number, ListedInstrument>> { }
export class ListedInstrumentSummaryLoadedEvent extends TypedEvent<ListedInstrumentSummary[]> { }
export class ListedInstrumentSummaryLoadedFailedEvent extends TypedEvent<string>{ }
export class ListedInstrumentBatchEvent extends TypedEvent<ListedInstrumentQueryResponse> { }
export class ListedInstrumentMetaDataLoadedEvent extends TypedEvent<MetaDataQueryResponse> { }
export class ListedInstrumentLoadedFailedEvent extends TypedEvent<string>{ }
export class ListedInstrumentRootInsLoadedEvent extends TypedEvent<ListedInstrument[]>{ }

export function InstrumentUpdated(instrument: ListedInstrument) {
    fixTimes(instrument);
    dataCacheInstance.SaveInstrument(instrument);
    AppDispatcher.dispatch(new ListedInstrumentUpdatedEvent(instrument));
}

export function InstrumentsUpdated(instruments: ListedInstrument[]) {
    instruments.forEach(instrument => {
        fixTimes(instrument);
    });
    dataCacheInstance.SaveInstruments(instruments);
    AppDispatcher.dispatch(new ListedInstrumentUpdatedBatchEvent(instruments));
}

export async function GetRootInstruments() {
    const ins = await get<ListedInstrument[]>(`ListedInstrument/AllRootInstrumentIds`);
    if (ins.payload != null) {
        ins.payload.forEach(instrument => {
            fixTimes(instrument);
        });
        AppDispatcher.dispatch(new ListedInstrumentRootInsLoadedEvent(ins.payload));
    }
}

export async function GetInstrumentById(instrumentId: number) {
    if (instrumentId && instrumentId !== 0) {
        const ins = await get<ListedInstrument>(`ListedInstrument/ById/${instrumentId}`);
        if (ins.payload != null) {
            InstrumentUpdated(ins.payload);
        }
    }
}

export async function GetInstrumentsById(instrumentIds: number[]) {
    var cleanIds = instrumentIds.filter(i => i!==undefined && i !== 0);
    if (cleanIds.length > 0) {
        const ins = await put<ListedInstrument[]>(`ListedInstrument/ByIds`, cleanIds);
        if (ins.payload != null) {
            AppDispatcher.dispatch(new ListedInstrumentUpdatedBatchEvent(ins.payload));
            return ins.payload;
        }
    }
}

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export async function LoadListedInstrumentSummaries() {
    /*let cachedAssets = await dataCacheInstance.getInstrumentSummaries();
    if (cachedAssets && cachedAssets.length > 0) {
        AppDispatcher.dispatch(new ListedInstrumentSummaryLoadedEvent(cachedAssets));
    }
    else {*/
    const summaries = await get<ListedInstrumentSummary[]>("ListedInstrument/Summaries");
    if (summaries.payload != null) {
        //await dataCacheInstance.saveInstrumentSummaries(summaries.payload);
        AppDispatcher.dispatch(new ListedInstrumentSummaryLoadedEvent(summaries.payload));
    }
    //}

    await GetRootInstruments();
    UpdateLoadingStatus("ListedInstrumentSummaries", LoadingStatus.Completed);
}

export async function LoadListedInstruments() {
    console.log("Starting instrument update process");
    let cachedAssets = await dataCacheInstance.getInstruments();
    const maxRow = cachedAssets ? _.max(cachedAssets.map(a => a.rowVersion)) : 0;
    let assets = await get<ListedInstrument[]>("ListedInstrument/Update?rowVersion=" + (maxRow ?? 0).toString());

    var map = new Map<number, ListedInstrument>();

    cachedAssets.forEach(ca => {
        map.set(ca.listedInstrumentId, ca);
    })

    if (assets.payload) {
        assets.payload.forEach(ins => {
            map.set(ins.listedInstrumentId, ins);
            fixTimes(ins);
            if (ins.underlyingId) {
                ins.underlying = map.get(ins.underlyingId);
            }
        });
        dataCacheInstance.SaveInstruments(assets.payload);
    }

    AppDispatcher.dispatch(new ListedInstrumentLoadedEvent(map));

    const meta = await get<MetaDataQueryResponse>("ListedInstrument/ExistingMetaValue");
    if (meta.payload == null) {
        AppDispatcher.dispatch(new ListedInstrumentLoadedFailedEvent("Failed to load the instrument meta data for the user"));
    }
    else {
        AppDispatcher.dispatch(new ListedInstrumentMetaDataLoadedEvent(meta.payload));
    }

    UpdateLoadingStatus("ListedInstruments", LoadingStatus.Completed);
}

export async function UpdateListedInstrument(instrument: ListedInstrument) {
    const updateResponse = await put<ListedInstrument>("ListedInstrument/UpdateInstrument", instrument);
    if (updateResponse.payload) {
        dataCacheInstance.SaveInstrument(updateResponse.payload);
        InstrumentUpdated(updateResponse.payload);
    }
}


export async function CreateListedInstrument(instrument: ListedInstrument) {
    const udpateResponse = await put<ListedInstrument>("ListedInstrument/GetOrCreateInstrument", instrument);
    if (udpateResponse.payload)
        InstrumentUpdated(udpateResponse.payload);
}

export async function FetchInstrumentWithQuery(query: ListedInstrumentQuery) {
    const tradesResponse = await put<ListedInstrumentQueryResponse>("ListedInstrument/InstrumentQuery", query);
    if (tradesResponse.payload) {
        var response = tradesResponse.payload;
        AppDispatcher.dispatch(new ListedInstrumentBatchEvent(response));
    }
}

export async function GetInstrumentsNeedingMetaData(query: MissingMetaDataQuery) {
    const tradesResponse = await put<ListedInstrument[]>("Trades/InstrumentsNeedingMetaData", query);
    if (tradesResponse.payload) {
        var response = { instruments: tradesResponse.payload, uniqueId: query.uniqueId } as ListedInstrumentQueryResponse;
        AppDispatcher.dispatch(new ListedInstrumentBatchEvent(response));
    }
}

export async function UpdateInsMetaData(data: ListedInstrumentMetaData[]) {
    var response = await put<ListedInstrument[]>("ListedInstrument/UpdateInstrumentsMetaData", data);
    if (response.payload == null) {
        AppDispatcher.dispatch(new ListedInstrumentLoadedFailedEvent("Failed to update the instrument meta data"));
    }
    else {
        response.payload.forEach(p => fixTimes(p));
        dataCacheInstance.SaveInstruments(response.payload);
        AppDispatcher.dispatch(new ListedInstrumentUpdatedBatchEvent(response.payload));
    }
}

export async function BulkMetaUpdate(meta: ListedInstrumentMetaData) {
    const metaResponse = await put<number>("ListedInstrument/BulkMetaUpdate", meta);
    return metaResponse?.payload;
}

function fixTimes(ins: ListedInstrument) {
    if (ins.firstDeliveryDay)
        ins.firstDeliveryDay = moment.utc(ins.firstDeliveryDay).tz(tz, false).toDate();
    if (ins.firstNoticeDay)
        ins.firstNoticeDay = moment.utc(ins.firstNoticeDay).tz(tz, false).toDate();
    if (ins.maturity)
        ins.maturity = moment.utc(ins.maturity).tz(tz, false).toDate();
}