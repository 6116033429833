import React from 'react'
import FBEmitter from 'fbemitter';
import { LoadBootStrapAuth } from './bootstrapActions';
import { BootStrapState } from './bootstrapModels';
import { CircularProgress} from '@mui/material';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import Login from '../login/login';
import Authenticate from '../login/authenticate';
import bootStrapStoreInstance from './bootstrapStore';

class BootStrapControl extends React.Component<{}, BootStrapState>{
    eventSubscription : FBEmitter.EventSubscription | undefined;
    constructor(props){
        super(props)
        this.state = {
            AuthInfo : undefined,
            PublicClientInfo : undefined,
        }
    }
    
    async componentDidMount(){
        this.eventSubscription = bootStrapStoreInstance.addChangeListener(this.onChange);
        await LoadBootStrapAuth();
        
    }

    componentWillUnmount(){
        if(this.eventSubscription){
            this.eventSubscription.remove();
        }
    }

    onChange = () => {
        this.setState(bootStrapStoreInstance.getState());
    }

    render() {
        if(this.state.AuthInfo && this.state.PublicClientInfo){
            return (
                <MsalProvider instance={this.state.PublicClientInfo}>
                    <div className="App">
                        <AuthenticatedTemplate>
                            <Login></Login>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Authenticate></Authenticate>
                        </UnauthenticatedTemplate>
                    </div>
                </MsalProvider>
            );

        }
        else{
            return <div className="LoginBox"><div className="LoginBoxInner"><CircularProgress /></div></div>;
        }
    }
}

export default BootStrapControl;