import {
    DataGridPremium,
    GridColDef,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium';

import { renderDateTimeCell } from '../utils/helpers';
import { useState, useEffect, useRef } from 'react';
import {
    Grid,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import userStoreInstance from '../user/userStore';
import { getFormTheme } from '../inputs/formCommon';
import { EventSubscription } from 'fbemitter';
import ordersStoreInstance from './orderStore';
import { loading } from '../utils/loading';
import { GetOrderHistory } from './orderActions';
import moment from 'moment';
import { OrderType, OrderStatus } from './orderModels';

export const OrderHistoryWidget = props => {
    const { orderId } = props;
    const [rows, setRows] = useState([]); 
    const [ historyRequested, setHistoryRequested] = useState(false);

    var eventSubscriptionOrders = useRef({} as EventSubscription);
    
    useEffect(() => {
        eventSubscriptionOrders.current = ordersStoreInstance.addChangeListener(updateRows);

        function updateRows() {
            var o = ordersStoreInstance.getOrderHistory(orderId)

            if(!o && !historyRequested){
                GetOrderHistory(orderId);
                setHistoryRequested(true);
            }

            const rowsI = o ? o.sort((a, b) => moment(b.periodStart).isBefore(moment(a.periodStart))?1:-1).map((r,ix) => {
                var rowA = {
                    id: ix,
                    ...r
                };

                return rowA;
            }) : new Array<any>();
            setRows(rowsI);
        }

        updateRows();

        var subscription = eventSubscriptionOrders;

        return function CleanUp() {
            subscription?.current?.remove();
        }
    }, [orderId, historyRequested, setHistoryRequested])



    function customToolbar(props: any) {
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer style={{ width: "calc(100vw - 200px)" }}>
                    <Grid container spacing={0} style={{ padding: "5px" }}>
                        {/* <Grid item><Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<RefreshOutlined />} onClick={() => onClickRefresh()}>Refresh</Button></Grid> */}
                        <Grid item><GridToolbarExport {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" style={{ height: "2.4em" }} /></Grid>
                        <Grid item><GridToolbarDensitySelector {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarColumnsButton {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                    </Grid>

                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    const colBase = { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
    const columns: GridColDef[] = [
        //{ field: "orderId", headerName: "Id", type: "number", flex: 0.5, ...colBase },
        {
            field: "orderType",
            headerName: "Type",
            renderCell: (params: GridRenderCellParams) => OrderType[params.value],
            type: "string",
            flex: 1,
            ...colBase
        },
        {
            field: "orderStatus",
            headerName: "Status",
            renderCell: (params: GridRenderCellParams) => OrderStatus[params.value],
            type: "string",
            flex: 1,
            ...colBase
        },
        // {
        //     field: "listedInstrumentId",
        //     headerName: "Instrument",
        //     renderCell: (params: GridRenderCellParams) => listedInstrumentStoreInstance.getInstrumentById(params.value)?.description,
        //     flex: 2.5,
        //     ...colBase
        // },
        {
            field: "insDescription",
            headerName: "Instrument",
            flex: 2.5,
            ...colBase
        },
        {
            field: "approvalMessage",
            headerName: "Message",
            type: "string",
            flex: 1,
            ...colBase
        },
        { field: "size", headerName: "Order Size", type: "number", flex: 1.1, editable: true, ...colBase },
        { field: "filled", headerName: "Filled", type: "number", flex: 1.1, editable: true, ...colBase },
        {
            field: "isBuyOrder",
            headerName: "B/S",
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => Boolean(params.value) ? "Buy" : "Sell",
            ...colBase
        },
        { field: "stopLevel", headerName: "Stop", type: "number", flex: 1, editable: true, ...colBase },
        { field: "limitLevel", headerName: "Limit", type: "number", flex: 1, editable: true, ...colBase },
        { field: "goodUntil", headerName: "Good Until", type: "dateTime", flex: 1, renderCell: (params: GridRenderCellParams) => renderDateTimeCell(params, "GTC"), ...colBase },
        { field: "periodStart", headerName: "Period Start", type: "dateTime", flex: 1, renderCell: (params: GridRenderCellParams) => renderDateTimeCell(params, "GTC"), ...colBase },
        { field: "periodEnd", headerName: "Period End", type: "dateTime", flex: 1, renderCell: (params: GridRenderCellParams) => renderDateTimeCell(params, "GTC"), ...colBase },
        ];

    return (
        <ThemeProvider theme={getFormTheme()}>
            <div className="OrderHistoryWidget">
                <DataGridPremium
                    style={{ border: "none" }}
                    rows={rows}
                    columns={columns}
                    hideFooter
                    disableSelectionOnClick
                    loading={!rows || rows.length===0}
                    density={userStoreInstance.GetGridDensity()}
                    components={{
                        Toolbar: customToolbar,
                        LoadingOverlay: () => loading(undefined),
                    }}
                    classes={{
                        panelWrapper: "GridFilterForm",
                        panel: "GridFilterForm",
                        filterForm: "GridFilterForm",
                        columnsPanel: "GridFilterForm",

                    }} />
            </div>
        </ThemeProvider>
    );

}