import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { ProductState } from './productModels';
import { LoadCommonProductSummariesEvent, LoadConversionFactorsEvent, LoadProductSummariesEvent, LoadProductSummaryEvent } from './productActions';
import { LoadCurrenciesEvent } from '../payoff/payoffActions';

class ProductStore extends FluxStore<ProductState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            commonProductSummaries: [],
            conversionFactors: { factors: [] },
            availableCurrencies: [],
            products: {}
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadCommonProductSummariesEvent) {
            const { payload } = action;
            this.state.commonProductSummaries = payload;
            this.emitChange();
        }
        else if (action instanceof LoadConversionFactorsEvent) {
            const { payload } = action;
            this.state.conversionFactors = payload;
            this.emitChange();
        }
        else if (action instanceof LoadCurrenciesEvent) {
            const { payload } = action;
            this.state.availableCurrencies = payload;
            this.emitChange();
        }
        else if (action instanceof LoadProductSummaryEvent) {
            const { payload } = action;
            this.state.products[payload.productId] = payload;
            this.emitChange();
        }
        else if (action instanceof LoadProductSummariesEvent) {
            const { payload } = action;
            payload.forEach(element=>{
                this.state.products[element.productId] = element;
            })
            this.emitChange();
        }
    }

    getCommonSummaries() {
        return this.state.commonProductSummaries;
    }

    getProductById(id: number) {
        return this.state.products[id];
    }

    getAllProducts() {
        return Object.keys(this.state.products).map(k=>this.getProductById(parseInt(k)));
    }

    getConversionFactorsForAsset(id: number) {
        if (this.state.conversionFactors.factors)
            return this.state.conversionFactors.factors.filter(f => f.assetId === id)[0];
    }

    getAvailableCurrencies() {
        return this.state.availableCurrencies;
    }
}

const productStoreInstance = new ProductStore(AppDispatcher);
export default productStoreInstance;