import FBEmitter from 'fbemitter';
import React, { PureComponent, RefObject } from 'react';
import { LivePosition, LiveSummary } from './positionSummaryModels';
import { LoadValuations } from './positionSummaryActions';
import {
    Grid,
    MenuItem,
    ThemeProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { GetParam } from '../trade/tradeBlotterTabComponent';
import { CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Scatter, Tooltip, XAxis, YAxis, ScatterChart } from 'recharts';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { TO_AssetFxModel } from '../qwack/to_AssetFxModel';
import marketDataStoreInstance from '../marketData/marketDataStore';
import { GetModelForDate } from '../marketData/marketDataActions';
import { EarliestDate } from '../globalConstants';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { getFormTheme } from '../inputs/formCommon';
import _ from 'lodash';
import { TO_Instrument } from '../qwack/to_Instrument';
import { OptionType } from '../qwack/optionType';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { TO_PriceCurve } from '../qwack/to_PriceCurve';
import { PriceCurveType } from '../qwack/priceCurveType';

interface OptionAnalysisState {
    model: TO_AssetFxModel,
    positions: LiveSummary,
    mainRef: RefObject<HTMLDivElement>,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    selectedUnderlyingId: number | undefined,
    rowHover: string | undefined,
}

export interface OptionAnalysisProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class OptionAnalysis extends React.Component<OptionAnalysisProps, OptionAnalysisState>{
    eventSubscriptionModels: FBEmitter.EventSubscription | undefined;

    constructor(props: OptionAnalysisProps) {
        super(props)
        var asOf = props.getState("asOf");
        this.state = {
            model: { assetCurves: {}, assetVols: {} } as TO_AssetFxModel,
            positions: { positions: new Array<LivePosition>() } as LiveSummary,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: GetParam(this.props.getState, "awaitingRefresh") ?? true,
            rowHover: undefined,
            selectedUnderlyingId: undefined
        };;
        this.updateModel = this.updateModel.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.renderControls = this.renderControls.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionModels = marketDataStoreInstance.addChangeListener(this.updateModel);
        this.updateModel();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionModels) {
            this.eventSubscriptionModels.remove();
            this.eventSubscriptionModels = undefined;
        }
    }

    async onClickRefresh() {
        this.setState({ awaitingRefresh: true });
        this.props.onChangeState("awaitingRefresh", "true");
        await LoadValuations(this.state.asOf?.format("yyyy-MM-DD") ?? "now");
    }

    async onChangeDate(d: moment.Moment) {
        if (!d) {
            this.setState({ asOf: null });
            return;
        }

        var awaitingRefresh = false;

        var model = marketDataStoreInstance.getModelForMoment(d);
        this.setState({ asOf: d, model });
        if (!model) {
            if (d.toDate())
                await GetModelForDate(d.toDate());
            awaitingRefresh = true;
        }

        this.setState({ awaitingRefresh: awaitingRefresh });
    }

    async updateModel(asOf?: Moment, selectedUnderlyingId?: number) {
        if (!asOf)
            asOf = this.state.asOf;
        if (!selectedUnderlyingId)
            selectedUnderlyingId = this.state.selectedUnderlyingId;

        let model = marketDataStoreInstance.getModelForMoment(asOf);
        this.props.onChangeState("awaitingRefresh", "false");
        this.setState({ model, awaitingRefresh: false });

        var optionsForUl = this.optionsInPortfolio(model).filter(i => this.optionIsOnId(i, selectedUnderlyingId?.toString()));
        for (var i = 0; i < optionsForUl.length; i++) {
            var o = optionsForUl[i];
            var strikeExp = this.getStrikeAndExpiry(o);
            var optDeets = marketDataStoreInstance.getOptionPricingParams(asOf.toDate(), Number(this.optionInsId(o)), strikeExp.date, strikeExp.price, this.optionIsCall(o));
            if (!optDeets) {
                await marketDataStoreInstance.requestOptionPricingParams(asOf.toDate(), Number(this.optionInsId(o)), strikeExp.date, strikeExp.price, this.optionIsCall(o));
            }   //             await GetOptionpricingParams(asOf.toDate(), Number(this.optionInsId(o)), strikeExp.date, strikeExp.price, this.optionIsCall(o));
        }
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    optionsInPortfolio(model: TO_AssetFxModel) {
        return model?.portfolio?.instruments?.filter(x => x.europeanOption || x.futuresOption) ?? new Array<TO_Instrument>();
    }

    renderControls() {
        const { asOf, model, selectedUnderlyingId } = this.state;
        var activeOptionUlIds = this.optionsInPortfolio(model).map(x => Number(x.europeanOption?.assetId ?? x.futuresOption?.assetId));
        activeOptionUlIds = activeOptionUlIds ? Array.from(new Set(activeOptionUlIds)) : new Array<number>();
        return (
            <div style={{ height: "4em", padding: "0.5em" }}>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={asOf?.toDate()}
                                onChange={this.onChangeDate}
                                disableFuture
                                minDate={EarliestDate}
                                emptyLabel="Live"
                                clearLabel="Live"
                                label={"As-Of"} />
                        </Grid>
                        <Grid item>
                            <WrappedSelect
                                id="Underlying"
                                name="Underlying"
                                label="Underlying"
                                value={selectedUnderlyingId ?? 0}
                                onChange={(d) => {
                                    var ul = d.target.value as number;
                                    if (ul === 0)
                                        ul = null;
                                    this.props.onChangeState("selectedUnderlyingId", ul.toString())
                                    this.setState({ selectedUnderlyingId: ul });
                                    this.updateModel(asOf, ul);
                                }}>
                                {[0, ...activeOptionUlIds].map(a =>
                                    <MenuItem key={"ul_" + a} value={a}>{a === 0 ? "Select Underlying..." : listedInstrumentStoreInstance.getInstrumentById(a)?.description?.replaceAll(" Underlying Index", "")}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }

    getStrikeAndExpiry(ins: TO_Instrument) {
        if (ins.futuresOption)
            return { date: new Date(ins.futuresOption.expiryDate), price: ins.futuresOption.strike, pointLabel: ins.futuresOption.metaData['BBG'] };
        else if (ins.europeanOption)
            return { date: new Date(ins.europeanOption.expiryDate), price: ins.europeanOption.strike, pointLabel: ins.europeanOption.metaData['BBG'] };
        return null;
    }

    optionIsOnId(ins: TO_Instrument, assetId: string) {
        return this.optionInsId(ins) === assetId;
    }

    optionInsId(ins: TO_Instrument) {
        if (ins.futuresOption)
            return ins.futuresOption.assetId;
        else if (ins.europeanOption)
            return ins.europeanOption.assetId;
        return null;
    }

    optionIsCall(ins: TO_Instrument) {
        if (ins.futuresOption)
            return ins.futuresOption.callPut === OptionType.call;
        else if (ins.europeanOption)
            return ins.europeanOption.callPut === OptionType.call;
        return false;
    }

    optionDescription(ins: TO_Instrument) {
        if (ins.futuresOption)
            return `${ins.futuresOption.contractQuantity}x ${moment(ins.futuresOption.expiryDate).format(userStoreInstance.GetDateFormat())} ${ins.futuresOption.strike}${ins.futuresOption.callPut === OptionType.call ? "C" : "P"}`
        else if (ins.europeanOption)
            return `${ins.europeanOption.notional}x ${moment(ins.europeanOption.expiryDate).format(userStoreInstance.GetDateFormat())} ${ins.europeanOption.strike}${ins.europeanOption.callPut === OptionType.call ? "C" : "P"}`
        return false;
    }

    optionSize(ins: TO_Instrument) {
        if (ins.futuresOption)
            return ins.futuresOption.contractQuantity;
        else if (ins.europeanOption)
            return ins.europeanOption.notional;
        return 0;
    }

    getCurveInterpMethod(curve: TO_PriceCurve) {
        if (curve.basicPriceCurve) {
            switch (curve.basicPriceCurve.curveType) {
                case PriceCurveType.linear:
                case PriceCurveType.lme:
                    return "linear";
                default:
                    return "stepBefore";
            }
        }
        return "linear";
    }

    renderCurve() {
        const { model, selectedUnderlyingId, asOf } = this.state;
        var curve = model.assetCurves[selectedUnderlyingId?.toString()]
        if (curve.basicPriceCurve === undefined || curve.basicPriceCurve === null)
            return null;

        var optionsForUl = this.optionsInPortfolio(model).filter(i => this.optionIsOnId(i, selectedUnderlyingId?.toString()));
        var optionsForUlLongCalls = optionsForUl.filter(x => this.optionIsCall(x) && this.optionSize(x) > 0).map(i => this.getStrikeAndExpiry(i))
        var optionsForUlLongPuts = optionsForUl.filter(x => !this.optionIsCall(x) && this.optionSize(x) > 0).map(i => this.getStrikeAndExpiry(i))
        var optionsForUlShortCalls = optionsForUl.filter(x => this.optionIsCall(x) && this.optionSize(x) < 0).map(i => this.getStrikeAndExpiry(i))
        var optionsForUlShortPuts = optionsForUl.filter(x => !this.optionIsCall(x) && this.optionSize(x) < 0).map(i => this.getStrikeAndExpiry(i))
        var maxOptExpiry = _.max(optionsForUl.map(x => this.getStrikeAndExpiry(x).date.valueOf()));

        var maxCurveIx = _.sortedIndex(curve?.basicPriceCurve.pillarDates.map(d => new Date(d)?.valueOf()), maxOptExpiry?.valueOf()) + 3;
        var chartData = curve?.basicPriceCurve?.pillarLabels.filter((x, ix) => ix <= maxCurveIx).map((p, ix) => { return { pointLabel: p, date: new Date(curve?.basicPriceCurve?.pillarDates[ix]), price: curve?.basicPriceCurve?.prices[ix] } })
        var fwdsColor = userStoreInstance.GetTheme().border_color;
        var optionsLongColorCalls = "#50C878";
        var optionsShortColorCalls = "#C70039";
        var optionsLongColorPuts = "#50C878";
        var optionsShortColorPuts = "#C70039";

        var dateLabelMap = new Map<string, string>();
        for (var i = 0; i < curve.basicPriceCurve.pillarDates.length; i++) {
            dateLabelMap.set(moment(curve.basicPriceCurve.pillarDates[i]).format("yyyy-MM-DD"), curve.basicPriceCurve.pillarLabels[i]);
        }

        var optionDetailsLongCalls = optionsForUl.filter(x => this.optionIsCall(x) && this.optionSize(x) > 0).map(i => this.optionDescription(i));
        var optionDetailsShortCalls = optionsForUl.filter(x => this.optionIsCall(x) && this.optionSize(x) < 0).map(i => this.optionDescription(i));
        var optionDetailsLongPuts = optionsForUl.filter(x => !this.optionIsCall(x) && this.optionSize(x) > 0).map(i => this.optionDescription(i));
        var optionDetailsShortPuts = optionsForUl.filter(x => !this.optionIsCall(x) && this.optionSize(x) < 0).map(i => this.optionDescription(i));

        var minDate = (asOf?.toDate() ?? new Date()).valueOf();
        var maxDate = Math.max(maxOptExpiry, _.max(chartData.map(x => x.date.valueOf())));

        var ticks = new Array<number>();
        var d = new Date().valueOf();

        while (d < maxDate) {
            ticks.push(d);
            var dn = new Date(d);
            d = moment(dn).add(1, "day").endOf("month").toDate().valueOf();
        }

        //const interpMethod = this.getCurveInterpMethod(curve);

        return (<div style={{ width: "100%", height: "calc(100% - 5em)", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "70%", height: "100%", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                <ResponsiveContainer width="100%" height="100%">
                    <ScatterChart key="optionChart" width={500} height={400}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" type="number" scale="time" ticks={ticks} stroke={userStoreInstance.GetTheme().border_color} tick={<CustomizedAxisTick />} domain={[minDate, maxDate]} />
                        <YAxis dataKey="price" stroke={userStoreInstance.GetTheme().border_color} domain={[dataMin => dataMin * 0.9, 'auto']} />
                        <Tooltip content={<CustomTooltip pointLabels={dateLabelMap} />} />
                        <Legend verticalAlign="top" />
                        <ReferenceLine y={0} stroke={userStoreInstance.GetTheme().border_color} />
                        <Scatter name={listedInstrumentStoreInstance.getInstrumentById(Number(selectedUnderlyingId)).description.replaceAll(" Underlying Index", "")} fill={fwdsColor} data={chartData} dataKey="price" shape="circle" line />
                        {/* <Line data={chartData} dataKey="price" type={interpMethod}  /> */}
                        <Scatter name={"Long Calls"} fill={optionsLongColorCalls} data={optionsForUlLongCalls} shape='cross' legendType='cross' />
                        <Scatter name={"Short Calls"} fill={optionsShortColorCalls} data={optionsForUlShortCalls} shape='triangle' legendType='triangle' />
                        <Scatter name={"Long Puts"} fill={optionsLongColorPuts} data={optionsForUlLongPuts} shape='square' legendType='square' />
                        <Scatter name={"Short Puts"} fill={optionsShortColorPuts} data={optionsForUlShortPuts} shape='star' legendType='star' />
                    </ScatterChart>
                </ResponsiveContainer>
            </div>
            <div style={{ width: "30%", height: "100%", paddingLeft: "0.5em", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                {!optionDetailsLongCalls || optionDetailsLongCalls.length === 0 ? null :
                    <React.Fragment>
                        <Typography style={{ borderBottom: "2px solid", borderBottomColor: userStoreInstance.GetTheme().border_color, color: optionsLongColorCalls }} variant="h6">Open Long Calls</Typography>
                        {optionDetailsLongCalls.map((o, ix) => <Typography key={"l" + ix} variant="body1">{o}</Typography>)}
                    </React.Fragment>}
                {!optionDetailsShortCalls || optionDetailsShortCalls.length === 0 ? null :
                    <React.Fragment>
                        <div style={{ height: "1em" }}></div>
                        <Typography style={{ borderBottom: "2px solid", borderBottomColor: userStoreInstance.GetTheme().border_color, color: optionsShortColorCalls }} variant="h6">Open Short Calls</Typography>
                        {optionDetailsShortCalls.map((o, ix) => <Typography key={"s" + ix} variant="body1">{o}</Typography>)}
                    </React.Fragment>}
                {!optionDetailsLongPuts || optionDetailsLongPuts.length === 0 ? null :
                    <React.Fragment>
                        <Typography style={{ borderBottom: "2px solid", borderBottomColor: userStoreInstance.GetTheme().border_color, color: optionsLongColorPuts }} variant="h6">Open Long Puts</Typography>
                        {optionDetailsLongPuts.map((o, ix) => <Typography key={"l" + ix} variant="body1">{o}</Typography>)}
                    </React.Fragment>}
                {!optionDetailsShortPuts || optionDetailsShortPuts.length === 0 ? null :
                    <React.Fragment>
                        <div style={{ height: "1em" }}></div>
                        <Typography style={{ borderBottom: "2px solid", borderBottomColor: userStoreInstance.GetTheme().border_color, color: optionsShortColorPuts }} variant="h6">Open Short Puts</Typography>
                        {optionDetailsShortPuts.map((o, ix) => <Typography key={"s" + ix} variant="body1">{o}</Typography>)}
                    </React.Fragment>}
            </div>
        </div>);
    }

    render() {
        const { model, selectedUnderlyingId, mainRef } = this.state;

        if (!model)
            return <div style={{ display: "flex", flexDirection: "column", marginRight: "5px" }}>
                {this.renderControls()}
                <div style={{ position: "relative", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "90%", height: "90%" }}><CircularProgress /></div>
            </div>

        return <div className="StrategySummaryTab" ref={mainRef}>
            {this.renderControls()}
            {selectedUnderlyingId ? this.renderCurve() : null}
        </div>
    }
}

class CustomTooltip extends PureComponent<any, {}> {
    render() {
        const { active, payload } = this.props;
        if (active && payload && payload.length) {
            var cLabel = payload[1].payload.pointLabel;
            return (
                <div className="custom-tooltip">
                    <p className="custom-tooltip-label">{`${moment(payload[0]?.value).format("yyyy-MM-DD")}`}</p>
                    <p className="custom-tooltip-label">{`${cLabel}`}</p>
                    <p className="custom-tooltip-value">{`${Number(payload[1]?.value).toLocaleString(undefined, { maximumFractionDigits: 4 })}`}</p>
                </div>
            );
        }

        return null;
    }
}

class CustomizedAxisTick extends PureComponent<any, {}> {
    render() {
        const { x, y, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill={userStoreInstance.GetTheme().border_color}>
                    {moment(payload.value).format("yyyy-MM-DD")}
                </text>
            </g>
        );
    }
}
