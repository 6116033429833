import { Button, Typography } from '@mui/material';
import { AccountBalanceWalletOutlined, CheckBoxOutlined, LeakAddOutlined, SwapVerticalCircleOutlined, TrackChangesOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import { CashTransactionReport } from './cashTransactionReport';
import { NavCalcTable } from './navCalcTable';
import { NavDiffTable } from './navDiffTable';
import { NavHistoryTable } from './navHistoryTable';
import { SubsRedsComponent } from './subsRedsComponent';
import layoutStoreInstance from '../layout/layoutStore';

interface NavTabState {
    currentMode: NavTabMode,
    collapsed: boolean
}

enum NavTabMode {
    None,
    NAV,
    Official,
    Cash,
    Diff,
    SubsReds
}

export interface NavTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class NavTab extends React.Component<NavTabProps, NavTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: NavTabProps) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: NavTabMode = tabModeStr ? NavTabMode[tabModeStr] : NavTabMode.None;
        this.state = {
            currentMode: tabMode,
            collapsed: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case NavTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case NavTabMode.NAV:
                return <NavCalcTable {...this.props} />
            case NavTabMode.Official:
                return <NavHistoryTable {...this.props} />
            case NavTabMode.Cash:
                return <CashTransactionReport {...this.props} />
            case NavTabMode.Diff:
                return <NavDiffTable {...this.props} />
            case NavTabMode.SubsReds:
                return <SubsRedsComponent {...this.props} />
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: NavTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: NavTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    onClick(mode: NavTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: NavTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>

                                <div style={{ height: "20px" }} />
                                {this.renderButton(NavTabMode.NAV, <SwapVerticalCircleOutlined />, "NAV")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(NavTabMode.Official, <CheckBoxOutlined />, "Official")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(NavTabMode.Cash, <AccountBalanceWalletOutlined />, "Cash")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(NavTabMode.Diff, <TrackChangesOutlined />, "Difference")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(NavTabMode.SubsReds, <LeakAddOutlined />, "Subs/Reds")}

                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}