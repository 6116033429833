import { ConnectionStatus } from "../connections/connectionStatusModel";
import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import { AcceleratorClassNamesEvent, AcceleratorConnectionStatusChangeEvent, AcceleratorSettingsEvent } from "./acceleratorActions";
import { AcceleratorStoreState } from "./acceleratorModels";

class AcceleratorStore extends FluxStore<AcceleratorStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            acceleratorLocalSetup: undefined,
            acceleratorConfig: undefined,
            connectionStatus: ConnectionStatus.Disconnected,
            acceleratorClassNames: []
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof (AcceleratorSettingsEvent)) {
            const { payload } = action;
            if (payload.localSetup)
                this.state.acceleratorLocalSetup = payload.localSetup;
            if (payload.configuration)
                this.state.acceleratorConfig = payload.configuration;
            this.emitChange();
        }
        else if (action instanceof (AcceleratorConnectionStatusChangeEvent)) {
            const { payload } = action;
            this.state.connectionStatus = payload;
            this.emitChange();
        }
        else if (action instanceof (AcceleratorClassNamesEvent)) {
            const { payload } = action;
            this.state.acceleratorClassNames = payload;
            this.emitChange();
        }
    }

    getConnectionStatus() {
        return this.state.connectionStatus;
    }

    getLocalSetup() {
        return this.state.acceleratorLocalSetup;
    }

    getConfig() {
        return this.state.acceleratorConfig;
    }

    getClassNames() {
        return this.state.acceleratorClassNames;
    }
}

const acceleratorStoreInstance = new AcceleratorStore(AppDispatcher);
export default acceleratorStoreInstance;