import React from 'react';
import FBEmitter from 'fbemitter';
import { BasketType } from '../../qwack/basketType';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { Asset } from '../../assets/assetsModels';
import { BasketDefinition } from '../../qwack/basketDefinition';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { Grid, IconButton, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { subRowProps, subCellProps, subTitleProps, subTitleVariant, subValueProps } from './helpers';
import assetsStoreInstance from '../../assets/assetsStore';

export type BasketDesignerProps = {
    stateUpdate: (state: BasketDefinition) => void,
    initialBasketDefinition: BasketDefinition,
    allAssets: Asset[],
    disabled: boolean,
    keyName: any,
    extraKey: string,
    lockedType?: BasketType
    hideLabel?: boolean
    controlLabel?: string
}

export type BasketDesignerState = {
    possibleBasketTypes: string[]
    selectedBasketType: BasketType;
    selectedAssets: (Asset | undefined)[];
    basketDefinition: BasketDefinition;
}

export class BasketDesigner extends React.Component<BasketDesignerProps, BasketDesignerState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: BasketDesignerProps) {
        super(props);
        let selectedAssets = new Array<Asset>();
        if (this.props.initialBasketDefinition && this.props.initialBasketDefinition.basketWeights) {
            var keys = Object.keys(this.props.initialBasketDefinition.basketWeights);
            selectedAssets = keys.map(k => assetsStoreInstance.getAssetByKey(k));
        }
        this.state = {
            basketDefinition: this.props.initialBasketDefinition ?? {
                basketWeights: {},
                basketType: BasketType.SingleAsset,
            } as BasketDefinition,
            possibleBasketTypes: [],
            selectedAssets: selectedAssets,
            selectedBasketType: this.props.lockedType ?? this.props.initialBasketDefinition?.basketType ?? BasketType.SingleAsset
        }

        this.renderAssetSelectors = this.renderAssetSelectors.bind(this);
        this.onChangeNthModifier = this.onChangeNthModifier.bind(this);
        this.filterAssetOptions = this.filterAssetOptions.bind(this);
    }

    public componentWillUnmount() {

    }

    componentDidMount() {
        this.fillPossibleBasketTypes();
        if (!this.state || !this.state.selectedAssets || this.state.selectedAssets.length === 0) {
            this.setState({ selectedAssets: [undefined] });
        }
    }

    fillPossibleBasketTypes = () => {
        const keys = Object.keys(BasketType).filter(k => typeof BasketType[k as any] === "number");
        this.setState({ possibleBasketTypes: keys });
    }

    private handleSuggestValueChange = (asset: Asset, componentId: number) => {
        const { basketDefinition, selectedAssets, selectedBasketType } = this.state;
        if (selectedAssets[componentId] !== asset && selectedAssets[componentId] !== undefined
            && basketDefinition.basketWeights[selectedAssets[componentId].key] !== undefined) //change of existing row
        {
            var existingWeight = basketDefinition.basketWeights[selectedAssets[componentId].key];
            basketDefinition.basketWeights[asset.key] = existingWeight;
            delete basketDefinition.basketWeights[selectedAssets[componentId].key];
        }

        selectedAssets[componentId] = asset;

        if (!basketDefinition.basketWeights || basketDefinition.basketType === BasketType.SingleAsset)
            basketDefinition.basketWeights = {};
        if (!basketDefinition.basketWeights[asset.key])
            basketDefinition.basketWeights[asset.key] = 1.0;

        if (!basketDefinition.currency || selectedBasketType === BasketType.SingleAsset)
            basketDefinition.currency = asset.defaultCcy;

        if (!basketDefinition.fixingIndices || selectedBasketType === BasketType.SingleAsset)
            basketDefinition.fixingIndices = [asset.fixingIndices[0]?.name];

        this.setState({ selectedAssets, basketDefinition });
        this.props.stateUpdate(basketDefinition);
    };

    private filterAssetOptions(options: Asset[], state: any): Asset[] {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.assetMatchesQuery(o, query))
        return relevant;
    }

    private assetMatchesQuery(asset: Asset, query: string): boolean {
        const normalizedTitle = asset.description.toLowerCase();
        const code = asset.name.toLowerCase();
        const allMetaData = asset.assetMetaData ? asset.assetMetaData.map(am => am.data.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${asset.assetId}. ${normalizedTitle} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${asset.assetId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    private onAssetSelect(asset: Asset | string, basketIx: number) {
        var solidAsset = asset as Asset;
        if (solidAsset) {
            this.handleSuggestValueChange(solidAsset, basketIx)
        }
    }

    renderAssetSelectors() {
        const { selectedBasketType, selectedAssets } = this.state;
        const { hideLabel } = this.props;
        switch (selectedBasketType) {
            default:
            case BasketType.SingleAsset:
                if (hideLabel)
                    return (
                        <Grid {...subValueProps} paddingTop="0.25em">
                            {this.props.disabled ?
                                <TextField
                                    key={this.props.extraKey + "ts"}
                                    value={this.state.selectedAssets[0]?.description}
                                    InputProps={{ readOnly: this.props.disabled }}
                                    label="Asset"
                                    size="small"
                                    variant="outlined" /> :
                                <Autocomplete
                                    key={this.props.extraKey + "ac"}
                                    multiple={false}
                                    classes={{
                                        inputRoot: "BasketDesignerAutocomplete",
                                        //input: "BasketDesignerAutocompleteInput",
                                        popupIndicator: "BasketDesignerAutocompleteIcon",
                                        clearIndicator: "BasketDesignerAutocompleteIcon",
                                    }}
                                    size="small"
                                    id="singleAssetSelectxx"
                                    options={this.props.allAssets}
                                    value={this.state.selectedAssets[0] ?? null}
                                    groupBy={(option: Asset) => option.assetClass}
                                    getOptionLabel={(option: Asset) => option.description}
                                    style={{ width: 300, color: "white" }}
                                    filterOptions={this.filterAssetOptions}
                                    onChange={(e, asset: Asset) => this.onAssetSelect(asset, 0)}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label={this.props.controlLabel ?? "Asset"}
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined" />}
                                />}
                        </Grid>

                    );

                return (
                    <Grid {...subRowProps}>
                        <Grid {...subCellProps} width={"100%"}>
                            {!hideLabel && <Grid {...subTitleProps} width={70}><Typography textAlign='right' variant={subTitleVariant}>Asset</Typography></Grid>}
                            <Grid {...subValueProps} paddingTop="0.25em">
                                {this.props.disabled ?
                                    <TextField
                                        key={this.props.extraKey + "ts"}
                                        value={this.state.selectedAssets[0]?.description}
                                        InputProps={{ readOnly: this.props.disabled }}
                                        label="Asset"
                                        variant="outlined" /> :
                                    <Autocomplete
                                        key={this.props.extraKey + "ac"}
                                        multiple={false}
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            //input: "BasketDesignerAutocompleteInput",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                        }}
                                        size="small"
                                        id="singleAssetSelectxx"
                                        options={this.props.allAssets}
                                        value={this.state.selectedAssets[0] ?? null}
                                        groupBy={(option: Asset) => option.assetClass}
                                        getOptionLabel={(option: Asset) => option.description}
                                        style={{ width: 300, color: "white" }}
                                        filterOptions={this.filterAssetOptions}
                                        onChange={(e, asset: Asset) => this.onAssetSelect(asset, 0)}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label={this.props.controlLabel ?? "Asset"}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined" />}
                                    />}
                            </Grid>
                        </Grid>
                    </Grid>);
            case BasketType.EquallyWeightedBasket:
            case BasketType.WeightedBasket:
            case BasketType.BestOf:
            case BasketType.WorstOf:
            case BasketType.NthBestOf:
            case BasketType.NthWorstOf:
                let showWeights = selectedBasketType === BasketType.WeightedBasket;
                return selectedAssets.map((a, ix) =>
                    <Grid {...subRowProps} key={`basketDesigner-${ix}-${a?.key}-${this.props.keyName}`} >
                        <Grid {...subCellProps} width={"100%"}>
                            <Grid {...subTitleProps} width={70}><Typography textAlign='right' variant={subTitleVariant}>{`Asset ${ix + 1}`}</Typography></Grid>
                            <Grid {...subValueProps} paddingTop="0.25em" width="300px">
                                {this.props.disabled ?
                                    <TextField
                                        key={this.props.extraKey + "tss"}
                                        value={this.state.selectedAssets[ix]?.description}
                                        InputProps={{ readOnly: this.props.disabled }}
                                        label="Asset"
                                        variant="outlined" /> :
                                    <Autocomplete
                                        key={this.props.extraKey + "acc"}
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            //input: "BasketDesignerAutocompleteInput",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                        }}
                                        size="small"
                                        id={"singleAssetSelect" + ix}
                                        options={this.props.allAssets}
                                        value={this.state.selectedAssets[ix]}
                                        groupBy={(option) => option.assetClass}
                                        getOptionLabel={(option) => (option as Asset)?.description ?? (option as string)}
                                        style={{ width: 300 }}
                                        filterOptions={this.filterAssetOptions}
                                        onChange={(e, asset) => this.onAssetSelect(asset, ix)}
                                        renderInput={(params) => <TextField {...params} label="Asset" variant="outlined" />}
                                    />}
                            </Grid>
                            {!showWeights ? null : <Grid {...subValueProps} paddingTop="0.25em" marginLeft="0.25em" width="80px">
                                <ValidatedNumericInput units="%" keyName={"basketWeight" + ix} placeholder="Weight" className="AutocallDateGenCoupon" initialValue={this.getRowWeight(a)} onChange={(valAsNum) => { this.updateRowWeight(a, valAsNum); return true; }} disabled={this.props.disabled} style={{ width: "80px" }} />
                            </Grid>}
                            {selectedAssets.length === 1 ? null : <Grid {...subValueProps} width={"25px"}>
                                <IconButton
                                    onClick={() => this.removeAssetRow(ix)}
                                    disabled={this.props.disabled}
                                    size="small"><RemoveCircleOutline /></IconButton>
                            </Grid>}
                            {ix > 0 ? null : <Grid {...subValueProps} width={"25px"}>
                                <IconButton onClick={this.addAssetRow} disabled={this.props.disabled} size="small"><AddCircleOutline /></IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>);
        }
    }

    addAssetRow = () => {
        let selectedAssets = [...this.state.selectedAssets, undefined];
        this.setState({ selectedAssets });
    }

    removeAssetRow = (index: number) => {
        this.setState({
            selectedAssets: this.state.selectedAssets.filter((x, ix) => ix !== index)
        });
    }

    getRowWeight = (asset: Asset) => {
        if (!this.state.basketDefinition || !this.state.basketDefinition.basketWeights)
            return null;
        return this.state.basketDefinition.basketWeights[asset.key];
    }

    setBasketType = (basketType: string) => {
        const { basketDefinition } = this.state;
        var bt = (BasketType as any)[basketType];
        basketDefinition.basketType = bt;
        this.setState({ selectedBasketType: bt, basketDefinition });
        this.props.stateUpdate(basketDefinition);
    }

    updateRowWeight(asset: Asset, weight: number) {
        if (asset) {
            const { basketDefinition } = this.state;
            basketDefinition.basketWeights[asset.key] = weight;
            this.setState({ basketDefinition });
            this.props.stateUpdate(basketDefinition);
        }
    }

    onChangeNthModifier(val: number, valAsStr?: string) {
        const { basketDefinition } = this.state;
        basketDefinition.nthModifier = val;
        this.setState({ basketDefinition });
        this.props.stateUpdate(basketDefinition);
        return !isNaN(val) && val > 0;
    }

    render() {
        if (!this.state || !this.state.possibleBasketTypes || this.state.possibleBasketTypes.length === 0) {
            return <div>Loading...</div>
        }
        const { possibleBasketTypes, selectedBasketType } = this.state;
        const { lockedType, hideLabel } = this.props;
        let showNthModifier = selectedBasketType === BasketType.NthBestOf || selectedBasketType === BasketType.NthWorstOf;

        if (lockedType !== undefined && !showNthModifier) {
            return hideLabel ? this.renderAssetSelectors() : (
                <Grid item container paddingTop="0.25em">
                    {this.renderAssetSelectors()}
                </Grid>)
        }

        return (
            <Grid item container paddingTop="0.25em">
                <Grid {...subRowProps} >
                    {lockedType === undefined && <Grid {...subCellProps} width="350px">
                        <Grid {...subTitleProps} width={70}><Typography textAlign='right' variant={subTitleVariant}>Type</Typography></Grid>
                        <Grid {...subValueProps}><StringTypeDropDown possibles={possibleBasketTypes} selected={possibleBasketTypes[selectedBasketType]} onChange={this.setBasketType} disabled={this.props.disabled} /></Grid>
                    </Grid>}
                    {showNthModifier && <Grid {...subCellProps} width="150px">
                        <Grid {...subTitleProps} width={50}><Typography textAlign='right' variant={subTitleVariant}>Nth</Typography></Grid>
                        <Grid {...subValueProps}><ValidatedNumericInput keyName="nthBox" placeholder='N' disabled={this.props.disabled} initialValue={this.state.basketDefinition?.nthModifier} onChange={this.onChangeNthModifier} style={{ width: "80px" }} /></Grid>
                    </Grid>}
                </Grid>
                {this.renderAssetSelectors()}
            </Grid>)
    }

}
export default BasketDesigner;