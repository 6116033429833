import React from 'react';
import FBEmitter from 'fbemitter';
import StructuredProductDesigner from './structuredProductDesigner';
import { RfqResponse } from '../../rfq/rfq';
import { ProductSpec } from '../../product/productModels';
import productCloneStoreInstance from './productCloning/productCloneStore';
import { Backdrop, Button, IconButton, Typography } from '@mui/material';
import { EmojiObjectsOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import { Firm } from '../../user/userModel';
import { RfqSummary } from '../../rfq/rfqModels';
import rfqSummaryStoreInstance from '../../rfq/rfqSummaryStore';
import userStoreInstance from '../../user/userStore';

export type PD2Props = {
    compactMode: boolean;
}

type PD2State = {
    overlayVisible: boolean;
    existingSpec: ProductSpec | undefined;
    existingRfqSummary: RfqSummary | undefined;
    extraKey: string;
    rfqId: number;
    clientFirm: Firm;
    readonly: boolean;
}

export class ProductDesignerPopUp extends React.Component<PD2Props, PD2State> {
    eventSubscriptionClone: FBEmitter.EventSubscription | undefined;
    constructor(props: PD2Props) {
        super(props);
        this.state = {
            overlayVisible: false,
            existingSpec: undefined,
            existingRfqSummary: undefined,
            extraKey: undefined,
            clientFirm: undefined,
            rfqId: undefined,
            readonly: false
        };
        this.onCloneOrEditEvent = this.onCloneOrEditEvent.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionClone = productCloneStoreInstance.addChangeListener(this.onCloneOrEditEvent)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionClone) {
            this.eventSubscriptionClone.remove();
        }
    }

    onCloneOrEditEvent() {
        let productToClone = productCloneStoreInstance.getProductToClone();
        if (productToClone) {
            this.setState({ readonly: false, overlayVisible: true, existingSpec: productToClone, extraKey: v4(), rfqId: undefined, clientFirm: undefined });
            return;
        }

        let productToEdit = productCloneStoreInstance.getProductToEdit();
        if (productToEdit) {
            var summary = rfqSummaryStoreInstance.GetRfq(productToEdit.rfqId);
            this.setState({ readonly: false, overlayVisible: true, existingSpec: productToEdit.productToEdit, existingRfqSummary: summary, extraKey: v4(), rfqId: productToEdit.rfqId, clientFirm: productToEdit.client });
            return;
        }

        let productToView = productCloneStoreInstance.getProductToView();
        if (productToView) {
            var summary2 = rfqSummaryStoreInstance.GetRfq(productToView.rfqId);
            this.setState({ readonly: true, overlayVisible: true, existingSpec: productToView.productToEdit, existingRfqSummary: summary2, extraKey: v4(), rfqId: productToView.rfqId, clientFirm: productToView.client });
            return;
        }
    }

    onOverlayClosed = () => {
        this.setState({ overlayVisible: false });
    };

    onRfqCreated(rfqResponse: RfqResponse) {
        //AddLayoutObject(undefined, "PricingPanel", { rfqResponse } as PricingPanelProps)
    }

    private RenderLaunchButton = (disabled: boolean) => {
        if (this.props.compactMode) {
            return (
                <div className="productDesignerLauncherCompact">
                    <IconButton
                        color="inherit"
                        onClick={this.onClickAdd}
                        disabled={disabled}
                        size="large">
                        <EmojiObjectsOutlined />
                    </IconButton>
                </div>
            );
        }
        else {
            return (<div className="productDesignerLauncher">
                <Button
                    color="inherit"
                    onClick={this.onClickAdd}
                    disabled={disabled}>
                    Designer<EmojiObjectsOutlined />
                </Button>
            </div>);
        }
    }

    onClickAdd = () => {
        this.setState({ overlayVisible: true, readonly: false, rfqId: undefined });
    };

    render() {
        const { overlayVisible, extraKey, clientFirm, rfqId, readonly } = this.state;
        return (
            <div>{this.RenderLaunchButton(false)}
                <div>
                    <Backdrop
                        open={overlayVisible} style={{ backgroundColor: "rgba(0,0,0,0.70)" }}>
                        <div className="ProductDesignerBox">
                            {readonly && rfqId!==undefined && <Typography style={{backgroundColor:userStoreInstance.GetTheme().contrast_color, color:userStoreInstance.GetTheme().contrast_background_color}} align='center' variant='h5'>RFQ {rfqId}</Typography> }
                            <StructuredProductDesigner
                                counterpartyId={""}
                                key={"pd" + extraKey}
                                extraKey={extraKey}
                                onClose={this.onOverlayClosed}
                                onSubmitRfq={this.onRfqCreated}
                                existingSpec={this.state.existingSpec}
                                existingRfqSummary={this.state.existingRfqSummary}
                                clientFirm={clientFirm}
                                editMode={rfqId !== undefined}
                                disabled={readonly}
                                dashboardMode={readonly}
                            />
                            {/* <ProductDesigner                                 
                                uniqueKey={"pd" + this.context.key}
                                onClose={this.onOverlayClosed}
                                pricerMode={false}
                                name={"pd" + this.context.key}
                                counterpartyId={""} /> */}
                        </div>
                    </Backdrop >
                </div>
            </div>
        );
    }
}

export default ProductDesignerPopUp;