import {
    Button,
    MenuItem,
    Grid,
    TextField,
    ThemeProvider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    FormGroup,
    FormControlLabel,
} from "@mui/material";
import { ListedInstrumentMetaData } from "./listedInstrumentModels";
import { FieldArray, FieldArrayRenderProps, Form } from "formik";
import { AddOutlined, CancelOutlined, CheckCircleOutline, RemoveOutlined } from "@mui/icons-material";
import { getFormTheme } from "../inputs/formCommon";
import listedInstrumentStoreInstance from "./listedInstrumentStore";
import Autocomplete from '@mui/material/Autocomplete';
import productStoreInstance from "../product/productStore";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";
import { WrappedSelect } from "../inputs/wrappedSelect";

export const NewInstrumentForm = props => {
    const {
        values: { id,
            underlyingId,
            ccy,
            type,
            description,
            ticker,
            isin,
            primaryExchange,
            optionStrike,
            optionIsCall,
            maturity,
            metaData
        },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        isSubmitting,
    } = props;

    const nullCcy = "Select Ccy...";

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    const onMetaCategoryChange = (val: string, ix: number) => {
        var name = `metaData[${ix}].category`;
        setFieldValue(name, val)
        setFieldTouched(name, true, false);
    }
    const onMetaTypeChange = (val: string, ix: number) => {
        var name = `metaData[${ix}].type`;
        setFieldValue(name, val)
        setFieldTouched(name, true, false);
    }
    const onMetaDataChange = (val: string, ix: number) => {
        var name = `metaData[${ix}].data`;
        setFieldValue(name, val)
        setFieldTouched(name, true, false);
    }

    const filterCcyOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => ccyMatchesQuery(o, query))
        return relevant;
    };

    const ccyMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const onCcySelect = (ccy: string) => {
        if (productStoreInstance.getAvailableCurrencies().includes(ccy)) {
            setFieldValue("ccy", ccy)
        }
    }

    let showMaturity = type !== "Index" && type !== "Equity" && type !== "Cash";
    let showIsin = type !== "Index" && type !== "Future" && type !== "Cash" && type !== "Option";
    let showOption = type === "Option";
    let somethingChanged = touched && Array.from(Object.keys(touched)).length > 0;
    return (
        <Form onSubmit={handleSubmit} className={className}>
            <ThemeProvider theme={getFormTheme()}>
                <Grid container spacing={1} justifyContent="center" alignContent="center" >
                    <Grid item>
                        <TextField
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="type"
                            name="type"
                            helperText={touched.type ? errors.type : ""}
                            error={touched.type || Boolean(errors.type)}
                            label="Type"
                            value={type}
                            disabled={true}
                            onChange={change.bind(null, "type")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="description"
                            name="description"
                            helperText={touched.description ? errors.description : ""}
                            error={touched.description || Boolean(errors.description)}
                            label="Description"
                            value={description}
                            onChange={change.bind(null, "description")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="ticker"
                            name="ticker"
                            helperText={touched.ticker ? errors.ticker : ""}
                            error={touched.ticker || Boolean(errors.ticker)}
                            label="Ticker"
                            value={ticker}
                            onChange={change.bind(null, "ticker")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                    <Grid item>
                        <Autocomplete
                            classes={{

                                popupIndicator: "BasketDesignerAutocompleteIcon",
                                clearIndicator: "BasketDesignerAutocompleteIcon",
                                popper: "AutocompleteGroupLabel",
                            }}
                            autoComplete
                            options={[nullCcy, ...productStoreInstance.getAvailableCurrencies()]}
                            value={ccy ?? nullCcy}
                            id="currency"
                            getOptionLabel={(ccy: string) => ccy}
                            style={{ width: "170px" }}
                            filterOptions={filterCcyOptions}
                            onChange={(e, v) => onCcySelect(v)}
                            renderInput={(params) => <TextField {...params}
                                label="Currency"
                                variant="outlined"
                                helperText={touched.currency ? errors.currency : ""}
                                error={touched.currency || Boolean(errors.currency)} />} />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="exchange"
                            name="primaryExchange"
                            helperText={touched.primaryExchange ? errors.primaryExchange : ""}
                            error={touched.primaryExchange && Boolean(errors.primaryExchange)}
                            label="Exchange"
                            value={primaryExchange}
                            onChange={change.bind(null, "primaryExchange")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                    {showIsin ? <Grid item>
                        <TextField
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="iSIN"
                            name="isin"
                            helperText={touched.isin ? errors.isin : ""}
                            error={touched.isin || Boolean(errors.isin)}
                            label="ISIN"
                            value={isin}
                            onChange={change.bind(null, "isin")}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid> : null}
                    {showMaturity && !showOption ? <Grid item>
                        <WrappedDatePicker
                            size="medium"
                            id="maturity"
                            name="maturity"
                            helperText={touched.maturity ? errors.maturity : ""}
                            error={touched.maturity || Boolean(errors.maturity)}
                            label="Maturity"
                            value={maturity}
                            onChange={change.bind(null, "maturity")} /></Grid> : null}
                    {showOption ? <Grid container spacing={2} justifyContent="center" classes={{ root: "ListedInstrumentEditorFormGrid" }}>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id="optionStrike"
                                name="optionStrike"
                                helperText={touched.optionStrike ? errors.optionStrike : ""}
                                error={touched.optionStrike || Boolean(errors.optionStrike)}
                                label="Strike"
                                value={optionStrike}
                                onChange={change.bind(null, "optionStrike")}
                                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                        <Grid item><FormGroup><FormControlLabel
                            label={optionIsCall ? "Call" : "Put"}
                            control={<Switch
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id="optionIsCall"
                                name="optionIsCall"
                                value={optionIsCall}
                                onChange={change.bind(null, "optionIsCall")} />} /></FormGroup></Grid>
                        <Grid item><WrappedSelect
                            id="underlyingId"
                            name="underlyingId"
                            error={touched.underlyingId || Boolean(errors.underlyingId)}
                            label="Underlying"
                            value={underlyingId}
                            onChange={change.bind(null, "underlying")}>
                            {listedInstrumentStoreInstance.getInstruments().map(i =>
                                <MenuItem key={"uli" + i.listedInstrumentId} value={i.listedInstrumentId}>{i.description}</MenuItem>)}
                        </WrappedSelect></Grid>
                        <Grid item>
                            <WrappedDatePicker
                                size="medium"
                                id="maturity"
                                name="maturity"
                                helperText={touched.maturity ? errors.maturity : ""}
                                error={touched.maturity || Boolean(errors.maturity)}
                                label="Maturity"
                                value={maturity}
                                onChange={change.bind(null, "maturity")} /></Grid>
                    </Grid> : null}
                    {/* <GridBreak /> */}
                    <Accordion className="ListedInstrumentEditorFormMetaDataContainer" defaultExpanded={true} disabled={true} >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"                >
                            <Typography variant="h6">Meta Data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FieldArray name="metaData" render={(arrayHelpers: FieldArrayRenderProps) => (
                                <div className="InsMetaDataBox">
                                    {metaData && metaData.length > 0 ? (
                                        metaData.map((m: ListedInstrumentMetaData, ix) => (
                                            <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                                                <Grid container spacing={1}>
                                                    <Grid item><Autocomplete
                                                        freeSolo
                                                        style={{ width: "200px" }}
                                                        options={Array.from(listedInstrumentStoreInstance.getMetaDataCategories())}
                                                        id={`metaData[${ix}].category`}
                                                        value={m.category ?? ""}
                                                        autoSelect
                                                        onChange={(e, v) => onMetaCategoryChange(v, ix)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={touched.metaData ? touched.metaData[ix]?.category : false}
                                                                label="Category"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                                    />
                                                    </Grid>
                                                    <Grid item><Autocomplete
                                                        freeSolo
                                                        style={{ width: "200px" }}
                                                        options={Array.from(listedInstrumentStoreInstance.getMetaDataTypesForCategory(m?.category))}
                                                        //classes={{ root: "ListedInstrumentEditorFormField" }}
                                                        id={`metaData[${ix}].type`}
                                                        value={m.type ?? ""}
                                                        autoSelect
                                                        onChange={(e, v) => onMetaTypeChange(v, ix)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={touched.metaData ? touched.metaData[ix]?.type : false}
                                                                label="Label"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                                    />
                                                    </Grid>
                                                    <Grid item><Autocomplete
                                                        freeSolo
                                                        style={{ width: "200px" }}
                                                        options={m === undefined || m.type === undefined || m.type === null ? new Array<string>() : Array.from(listedInstrumentStoreInstance.getMetaDataByType(m?.category, m?.type))}
                                                        //classes={{ root: "ListedInstrumentEditorFormField" }}
                                                        id={`metaData[${ix}].data`}
                                                        value={m.data ?? ""}
                                                        autoSelect
                                                        onChange={(e, v) => onMetaDataChange(v, ix)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                error={touched.metaData ? touched.metaData[ix]?.data : false}
                                                                label="Data"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                                    />
                                                    </Grid>
                                                    <IconButton
                                                        onClick={() => arrayHelpers.remove(ix)}
                                                        className="ListedInstrumentEditorFormFieldInner"
                                                        size="large">
                                                        <RemoveOutlined color="inherit" />
                                                    </IconButton>
                                                </Grid>
                                            </div>
                                        ))
                                    ) : null}
                                    <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                                        <Button variant="outlined" onClick={() => arrayHelpers.push({ listedInstrumentId: id, lastUpdated: new Date() } as ListedInstrumentMetaData)} className="PltfmButtonLite">
                                            <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                                            <ListItemText>Add Meta Data</ListItemText>
                                        </Button>
                                    </div>
                                </div>
                            )} />
                        </AccordionDetails>
                    </Accordion>
                    {/* <GridBreak /> */}
                    <div style={{ display: "flex", justifyContent: "center", width: "80%", bottom: "0%" }}>
                        <Button
                            className="PltfmButtonLite"
                            type="submit"
                            variant="outlined"
                            disabled={!isValid || !somethingChanged || isSubmitting}
                            endIcon={<CheckCircleOutline />}>Submit</Button>
                        <Button
                            className="PltfmButtonLite"
                            type="reset"
                            variant="outlined"
                            endIcon={<CancelOutlined />}>Revert</Button>
                    </div>
                </Grid>
            </ThemeProvider>
        </Form>
    );
};