import moment from 'moment';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { DefaultSetName } from '../globalConstants';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import { TO_AssetFxModel } from '../qwack/to_AssetFxModel';
import { TO_GridVolSurface } from '../qwack/to_GridVolSurface';
import { get } from '../utils/httpUtils';
import { AvailableMarketDataReponse, BlobDescriptor, MarketDataExplorerEditRequest, MarketDataTick, OptionPricingParameters, VolSurfaceResponse } from './marketDataModels';
import { CandlesResponse, FixingsResponse, SetDescription } from './models/fixingPoint';
import { GetCandleTickersByAssetResponse, NewCandlesObject } from './models/getCandleTickersResponse';

export class MarketDataNewTickEvent extends TypedEvent<MarketDataTick> { }
export class MarketDataNewCandleSummariesEvent extends TypedEvent<GetCandleTickersByAssetResponse> { }
export class MarketDataNewCandlesEvent extends TypedEvent<NewCandlesObject> { }
export class MarketDataNewFixingsEvent extends TypedEvent<FixingsResponse> { }
export class MarketDataNewCandlesRestEvent extends TypedEvent<CandlesResponse> { }
export class MarketDataSetNamesLoadEvent extends TypedEvent<string[]> { }
export class MarketDataInstrumentsWithFixingsLoadEvent extends TypedEvent<SetDescription[]> { }
export class MarketDataNewModelEvent extends TypedEvent<TO_AssetFxModel> { }
export class MarketDataNewOptionParamsEvent extends TypedEvent<OptionPricingParameters> { }
export class MarketDataNewAvailableVolSurfacesEvent extends TypedEvent<{ [date: string]: BlobDescriptor[] }> { }
export class MarketDataVolSurfaceEvent extends TypedEvent<VolSurfaceResponse> { }
export class MarketDataAvailableTypesEvent extends TypedEvent<AvailableMarketDataReponse> { }

export class MarketDataExplorerEditEvent extends TypedEvent<MarketDataExplorerEditRequest> { }

export function NewTickRecieved(tick: MarketDataTick) {
    AppDispatcher.dispatch(new MarketDataNewTickEvent(tick));
}

export function NewCandleSummariesRecieved(response: GetCandleTickersByAssetResponse) {
    AppDispatcher.dispatch(new MarketDataNewCandleSummariesEvent(response));
}

export function NewCandlesRecieved(candles: NewCandlesObject) {
    AppDispatcher.dispatch(new MarketDataNewCandlesEvent(candles));
}

export function NewMarketDataExplorerEdit(request: MarketDataExplorerEditRequest) {
    AppDispatcher.dispatch(new MarketDataExplorerEditEvent(request));
}

export async function LoadSetSummaries() {
    //await marketDataConnection.loadTickersByAsset("FTX");

    var setNamesResponse = await get<SetDescription[]>("MarketData/GetSets");
    if (setNamesResponse.payload) {
        AppDispatcher.dispatch(new MarketDataInstrumentsWithFixingsLoadEvent(setNamesResponse.payload));
    }

    await GetAvailableVolsurfaces();

    UpdateLoadingStatus("MarketDataSets", LoadingStatus.Completed);
}

export async function GetAvailableVolsurfaces() {
    var volsResponse = await get<{ [date: string]: BlobDescriptor[] }>("MarketData/GetAvailableSurfaces");
    if (volsResponse.payload) {
        AppDispatcher.dispatch(new MarketDataNewAvailableVolSurfacesEvent(volsResponse.payload));
    }
}

export async function GetVolSurface(insId: number, valDate: Date) {
    var volResponse = await get<TO_GridVolSurface>(`MarketData/GetVolSurfaceTo/CME-EoD/${moment(valDate).format("yyyy-MM-DD")}/${insId}`);
    if (volResponse.payload) {
        var e = {
            insId: insId,
            valDate: valDate,
            surface: volResponse.payload
        } as VolSurfaceResponse;
        AppDispatcher.dispatch(new MarketDataVolSurfaceEvent(e));
        return volResponse.payload;
    }
}

export async function GetAvailableDataForInsId(insId: number) {
    var typesResponse = await get<AvailableMarketDataReponse>(`MarketData/GetAvailableDataTypes/${insId}`);
    if (typesResponse.payload) {
        AppDispatcher.dispatch(new MarketDataAvailableTypesEvent(typesResponse.payload));
    }
}

export async function GetFixings(setName: string, insId: number) {
    var fixingsResponse = await get<FixingsResponse>(`MarketData/GetFixingsForSetInstrument/${setName ?? DefaultSetName}/${insId}`);
    if (fixingsResponse.payload) {
        AppDispatcher.dispatch(new MarketDataNewFixingsEvent(fixingsResponse.payload));
        return fixingsResponse.payload.fixings;
    }
}

export async function GetCandles(setName: string, insId: number, candleSize: number) {
    var candlesResponse = await get<CandlesResponse>(`MarketData/GetCandles/${setName ?? DefaultSetName}/${insId}/${candleSize}`);
    if (candlesResponse.payload) {
        AppDispatcher.dispatch(new MarketDataNewCandlesRestEvent(candlesResponse.payload));
        return candlesResponse.payload.candles;
    }
}

export async function LoadSetNames() {
    var setNames = await get<string[]>(`MarketData/SetNames`);
    if (setNames.payload) {
        AppDispatcher.dispatch(new MarketDataSetNamesLoadEvent(setNames.payload));
    }
    UpdateLoadingStatus("MarketDataSets", LoadingStatus.Completed);
}

export async function GetModelForDate(date: Date) {
    var modelResponse = await get<TO_AssetFxModel>(`MarketData/GetModelForDate/${moment(date).format("yyyy-MM-DD")}`);
    if (modelResponse?.payload) {
        AppDispatcher.dispatch(new MarketDataNewModelEvent(modelResponse.payload));
    }
}

export async function GetOptionpricingParams(valDate: Date, insId: number, expiry: Date, strike: number, isCall: boolean) {
    var modelResponse = await get<OptionPricingParameters>(`MarketData/GetOptionPricingParams/${moment(valDate).format("yyyy-MM-DD")}/${insId}/${moment(expiry).format("yyyy-MM-DD")}/${strike}/${isCall}`);
    if (modelResponse?.payload) {
        AppDispatcher.dispatch(new MarketDataNewOptionParamsEvent(modelResponse.payload));
    }
}