import { Component } from 'react';
import FBEmitter from 'fbemitter';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, CssBaseline } from '@mui/material';
import positionStoreInstance from '../../positions/positionSummaryStore';
import marketDataLiveStoreInstance from '../../marketData/marketDataLiveStore';
import { LiveSummary, PositionSummary } from '../../positions/positionSummaryModels';
import _ from 'lodash';
import { AssetStr, DefaultSetName } from '../../globalConstants';
import marketDataStoreInstance from '../../marketData/marketDataStore';
import { getMetaFromInstrument, getIns } from '../../positions/positionsCommon';
import { addWeekDays } from '../../utils/dates';
import { SubscribeToTicksByIdBulk } from '../../accelerator/acceleratorActions';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import moment from 'moment';
import navStoreInstance from '../../nav/navStore';
import { ComputeFeederMasterNavs, NavsRequested } from '../../nav/navActions';
import { ThemeProvider } from '@mui/styles';
import { getFormTheme } from '../../inputs/formCommon';
import userStoreInstance from '../../user/userStore';
import { metaKeys } from '../../positions/positionSummaryTable';

const DrawDelayMs = 500;

export interface PnlWidgetProps {
  onChangeHeight?: (newHeight: number) => void
}

interface PnlWidgetState {
  live: PositionSummary;
  base: PositionSummary;
  baseNav: number;
  yesterday: string;
  lastUpdated: moment.Moment;
}

class PnlWidget extends Component<PnlWidgetProps, PnlWidgetState>{
  eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
  constructor(props: PnlWidgetProps) {
    super(props);
    this.state = {
      live: undefined,
      base: undefined,
      baseNav: undefined,
      yesterday: undefined,
      lastUpdated: moment().subtract(DrawDelayMs, "milliseconds")
    };
    this.updatePnL = this.updatePnL.bind(this);
  }

  async componentDidMount() {
    this.eventSubscriptionTicks = marketDataLiveStoreInstance.addChangeListener(() => this.updatePnL());

    if (!navStoreInstance.navsAreLoaded() && !navStoreInstance.navsHaveBeenRequested()) {
      NavsRequested();
      await ComputeFeederMasterNavs(DefaultSetName)
    }

    var today = moment().startOf('day');
    var storageKeyYesterday = `PnL-Yesterday-${today.format("yyyy-MM-DD")}`;
    var ytd = sessionStorage.getItem(storageKeyYesterday);

    if (!ytd) {
      var ytdDate = addWeekDays(today, -1);
      ytd = ytdDate.format("yyyy-MM-DD");
      sessionStorage.setItem(storageKeyYesterday, ytd);
      var baseValuation = await positionStoreInstance.getValuationsByMetaAsync(ytd, metaKeys, false);
      var baseSummary = this.generateSummaryData(baseValuation);
      var baseNav = navStoreInstance.getCalculatedNav(ytdDate.toDate(), "MasterFund")?.totalInBase;
      this.setState({ base: baseSummary, baseNav: baseNav });
    }

    var posSummary = await positionStoreInstance.getValuationsByMetaAsync("now", metaKeys, false);
    if (posSummary) {
      await this.updatePnL();
    }
  }

  componentWillUnmount() {
    if (this.eventSubscriptionTicks)
      this.eventSubscriptionTicks.remove();
  }

  async updatePnL() {
    var timeSinceLastDraw = moment().diff(this.state.lastUpdated, "milliseconds");
    if (timeSinceLastDraw > DrawDelayMs) {
      var posSummary = await positionStoreInstance.getValuationsByMetaAsync("now", metaKeys, true);
      var summary = this.generateSummaryData(posSummary);

      var today = moment().startOf('day');
      var storageKeyYesterday = `PnL-Yesterday-${today.format("yyyy-MM-DD")}`;
      var ytd = sessionStorage.getItem(storageKeyYesterday);
      if (ytd && !this.state.base) {
        var baseValuation = await positionStoreInstance.getValuationsByMetaAsync(ytd, metaKeys, true);
        var baseSummary = this.generateSummaryData(baseValuation);
        this.setState({ base: baseSummary });
      }

      if (ytd && !this.state.baseNav) {
        var baseNav = navStoreInstance.getCalculatedNav(new Date(ytd), "MasterFund")?.totalInBase;
        this.setState({ baseNav: baseNav });
      }

      if (posSummary) {
        var insIds = posSummary.positions.filter(p => p.aggregatedPosition.openPosition !== 0 && !marketDataLiveStoreInstance.checkSubscribed(p.aggregatedPosition.instrumentId)).map(p => p.aggregatedPosition.instrumentId);
        if (insIds.length > 0) {
          var ulIndIds = insIds.map(i => {
            var ins = listedInstrumentStoreInstance.getInstrumentById(i);
            return ins.type === "Option" ? ins.underlyingId : null;
          }).filter(i => i !== null);
          var idSet = new Set([...insIds, ...ulIndIds]);

          await SubscribeToTicksByIdBulk(Array.from(idSet));
        }
      }

      this.setState({ live: summary, lastUpdated: moment() });
    }
  }

  getPrice(insId: number, lastPrice: number, fallbackWidth: number): number {

    var bidAsk = marketDataLiveStoreInstance.getBidAsk(insId);
    if (!lastPrice)
        lastPrice = (bidAsk.bid + bidAsk.ask) / 2;

    var tick: number;
    if (!bidAsk.ask || !bidAsk.bid || Math.abs(bidAsk.ask / bidAsk.bid - 1) > fallbackWidth) {
        tick = bidAsk.bid ? Math.max(bidAsk.bid, lastPrice) : lastPrice;
        if (bidAsk.ask) {
            tick = Math.min(bidAsk.ask, tick);
        }
    }
    else {
        tick = (bidAsk.bid + bidAsk.ask) / 2;
    }

    return tick ?? lastPrice;
  }

  generateSummaryData(sum: LiveSummary) {
    if (!sum || !sum.positions)
      return null;
    var isLive = sum.asOf?.toLowerCase() === "now";

    var posByAsset = _.groupBy(sum.positions, p => getMetaFromInstrument(getIns(p.aggregatedPosition.instrumentId), AssetStr));

    var realizedByAsset: { [asset: string]: number } = {};
    var unRealizedByAsset: { [asset: string]: number } = {};

    var realized = 0;
    var unrealized = 0;
    Object.keys(posByAsset).filter(k => k !== "null").forEach(asset => {
      //TODO - make this rely on live fx rates
      var realizedForAsset = _.sum(posByAsset[asset].map(p => p.aggregatedPosition.realizedPnLBase));
      realizedByAsset[asset] = realizedForAsset;
      realized += realizedForAsset;
      if (!isLive) {
        var unRealizedForAsset = _.sum(posByAsset[asset].map(p => p.unrealizedPnLBase));
        if (isNaN(unRealizedForAsset)) {
          var badAssets = posByAsset[asset].filter(p => isNaN(p.unrealizedPnLBase));
          badAssets.forEach(badAss => {
            console.warn(`NaN unrealized PnL for ins ${badAss?.aggregatedPosition?.instrumentId} and date ${sum.asOf}`);
          });
          unRealizedForAsset = _.sum(posByAsset[asset].filter(p => isNaN(p.unrealizedPnLBase)).map(p => p.unrealizedPnLBase));
        }

        unRealizedByAsset[asset] = unRealizedForAsset;
        unrealized += unRealizedForAsset;
      }
    });

    var fallbackWidth = userStoreInstance.GetFallbackPriceWidth();
    sum.positions.filter(p => p.aggregatedPosition.openPosition !== 0).forEach(p => {
      var a = p.aggregatedPosition;
      var ins = getIns(a.instrumentId);
      var price = isLive ? this.getPrice(a.instrumentId, p.livePrice, fallbackWidth) : p.livePrice;
      var asset = getMetaFromInstrument(ins, AssetStr);
      var size = a.openPosition * ins.multiplier;

      if (isLive) {
        if (unRealizedByAsset[asset] === undefined)
          unRealizedByAsset[asset] = 0;
        var fxRate = (p.unrealizedCcy === "USD" ? 1.0 : (marketDataLiveStoreInstance.getFxRate(p.unrealizedCcy) ?? marketDataStoreInstance.getFxRate(p.unrealizedCcy) ?? p.liveFxRateToBase ?? sum.fxRatesToBase[p.unrealizedCcy]));
        if (isNaN(fxRate))
          fxRate = p.liveFxRateToBase;
        var unRealizedForPos = size * (price - p.aggregatedPosition.averagePrice) * (p.unrealizedCcy === "USD" ? 1.0 : fxRate);
        if (isNaN(unRealizedForPos)) {
          console.warn(`NaN unrealized PnL for ins ${p?.aggregatedPosition?.instrumentId} / ${ins?.description} and date ${sum.asOf} (LIVE)`);
          unRealizedForPos = 0;
        }

        unrealized += unRealizedForPos;
        unRealizedByAsset[asset] += unRealizedForPos;
      }
    });

    return {
      realized: realized,
      unRealized: unrealized,
      realizedByAsset: realizedByAsset,
      unRealizedByAsset: unRealizedByAsset,
    } as PositionSummary
  }

  render() {
    const { live, base, baseNav } = this.state;

    var realized = 0;
    var unrealized = 0;

    var realizedPct = 0;
    var unrealizedPct = 0;

    if (live && base) {
      realized = live.realized - base.realized;
      unrealized = live.unRealized - base.unRealized;
    }

    if (baseNav) {
      realizedPct = realized / baseNav;
      unrealizedPct = unrealized / baseNav;
    }

    var col = userStoreInstance.GetTheme().color;
    var upCol = userStoreInstance.GetTheme().chart_color_up;
    var downCol = userStoreInstance.GetTheme().chart_color_down;

    var realisedCol = realized >= 0 ? upCol : downCol;
    var unRealisedCol = unrealized >= 0 ? upCol : downCol;
    var totalCol = (realized + unrealized) >= 0 ? upCol : downCol;

    var cellStyle = { border: "none" } as React.CSSProperties;

    if (baseNav) {
      return (
        <CssBaseline>
          <ThemeProvider theme={getFormTheme()}>
            <div className="PnLWidget">
              <TableContainer style={{ width: "97%", overflow: "hidden" }} >
                <Table size='small' sx={{ width: "97%", height: "140px", tableLayout: "auto", overflow: "hidden" }} >
                  <TableHead />
                  <TableBody >
                    <TableRow>
                      <TableCell width={"20%"} style={cellStyle}><Typography variant="subtitle2" align="center" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>Realised</Typography></TableCell>
                      <TableCell style={cellStyle}><Typography variant="subtitle2" align="left" color={realisedCol} fontFamily={userStoreInstance.GetTheme().font_family}>{realized.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                      <TableCell style={cellStyle}><Typography variant="subtitle2" align="left" color={realisedCol} fontFamily={userStoreInstance.GetTheme().font_family}>{(realizedPct * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"20%"} style={cellStyle}><Typography variant="subtitle2" align="center" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>UnRlsd</Typography></TableCell>
                      <TableCell style={cellStyle}><Typography variant="subtitle2" align="left" color={unRealisedCol} fontFamily={userStoreInstance.GetTheme().font_family}>{unrealized.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                      <TableCell style={cellStyle}><Typography variant="subtitle2" align="left" color={unRealisedCol} fontFamily={userStoreInstance.GetTheme().font_family}>{(unrealizedPct * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"20%"} style={cellStyle}><Typography variant="subtitle2" align="center" fontWeight="bold" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>Total</Typography></TableCell>
                      <TableCell style={cellStyle}><Typography variant="subtitle2" align="left" fontWeight="bold" color={totalCol} fontFamily={userStoreInstance.GetTheme().font_family}>{(unrealized + realized).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                      <TableCell style={cellStyle}><Typography variant="subtitle2" align="left" fontWeight="bold" color={totalCol} fontFamily={userStoreInstance.GetTheme().font_family}>{((unrealizedPct + realizedPct) * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</Typography></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </ThemeProvider>
        </CssBaseline>
      );
    }
    else {
      return (
        <div className="PnLWidget">
          <ThemeProvider theme={getFormTheme()}>
            <Grid container direction="column" justifyContent="center">
              <Grid item>
                <TableContainer>
                  <Table size='small'>
                    <TableHead />
                    <TableBody>
                      <TableRow>
                        <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>Realised</Typography></TableCell>
                        <TableCell style={cellStyle}><Typography variant="subtitle2" color={realisedCol}>{realized.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>UnRealised</Typography></TableCell>
                        <TableCell style={cellStyle}><Typography variant="subtitle2" color={unRealisedCol}>{unrealized.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>Total</Typography></TableCell>
                        <TableCell style={cellStyle}><Typography variant="subtitle2" color={totalCol}>{(unrealized + realized).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
      );
    }

  }
}
export default PnlWidget;