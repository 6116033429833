import { Button, Grid } from '@mui/material';
import { ExitToAppOutlined } from '@mui/icons-material';
import React from 'react'
import { StringTypeDropDown } from '../inputs/assetGroupDropDown';
import { get } from '../utils/httpUtils';
import { UserSummary } from './userModel';
import userStore from './userStore';
import userStoreInstance from './userStore';

type ImpersonateState = {
    userId: number;
    availableUsers: UserSummary[]
}

class Impersonate extends React.Component<{}, ImpersonateState>{
    constructor(props: {}) {
        super(props);
        this.state = {
            userId: userStore.GetUserInfo().userId,
            availableUsers: []
        };
        this.onChangeUser = this.onChangeUser.bind(this);
    }

    async componentDidMount() {
        var response = await get<UserSummary[]>('User/all');
        if (response.payload) {
            this.setState({ availableUsers: response.payload });
        }
    }

    changeUserId(e: React.ChangeEvent<HTMLInputElement>) {
        let value = Number(e.target.value);
        this.setState({ userId: value });
    }

    onChangeUser(selected: string) {
        const { availableUsers } = this.state;
        var user = availableUsers.filter(u => u.name === selected)[0];
        this.setState({ userId: user.userId });
    }

    setCookie() {
        setCookie("impersonateId", this.state.userId.toString(), 1);
        window.location.href = "/";
    }

    render() {
        const { availableUsers, userId } = this.state;
        return (<Grid container spacing={2}>
            {/* <Grid item><Typography style={{ fontFamily: userStoreInstance.GetTheme().font_family}} >Impersonate</Typography></Grid> */}
            <Grid item><StringTypeDropDown className="ImpersonateDropDown" disabled={false} possibles={availableUsers.map(u => u.name)} selected={availableUsers.filter(u => u.userId === userId)[0]?.name} onChange={this.onChangeUser} /></Grid>
            <Grid item><Button style={{ fontFamily: userStoreInstance.GetTheme().font_family }} variant="text" color="inherit" onClick={() => this.setCookie()} title="Login" endIcon={<ExitToAppOutlined />}>Login</Button></Grid>
        </Grid>)
    }
}

function setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export default Impersonate;