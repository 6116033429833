import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Grid,
    MenuItem,
    StyledEngineProvider,
    Typography,
    Paper,
    Tab,
    Tabs,
    LinearProgress,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import _ from 'lodash';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { CalculatedAccountBalance, CashTransaction } from './navModels';
import navStoreInstance from './navStore';
import { ComputeCashTransactions, FetchAccountBalances } from './navActions';
import marketDataStoreInstance from '../marketData/marketDataStore';
import { DataGridPremium, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridCellParams, GridCsvExportOptions, GridOverlay } from '@mui/x-data-grid-premium';
import { renderDate, momentUtcToOADate, dateFromOADate } from '../utils/helpers';
import userStoreInstance from '../user/userStore';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { TabContext, TabPanel } from '@mui/lab';
import moment from 'moment';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { AccountToInstitutionMap, DefaultSetName, EarliestDate } from '../globalConstants';
import { getFilters } from '../positions/positionsCommon'
import { WrappedCheckBox } from '../inputs/wrappedCheckBox';
import { AddUpdateCashComponent } from './addUpdateCashComponent';

interface CashTransactionReportState {
    transactions: CashTransaction[],
    balances: CalculatedAccountBalance[],
    asOfDate: Date,
    mainRef: RefObject<HTMLDivElement>,
    awaitingRefresh: boolean,
    computingCashflows: boolean,
    selectedAccount: string | undefined,
    selectedSet: string | undefined,
    selectedEntity: number | undefined,
    selectedTabId: number,
    filterDateStart: moment.Moment,
    filterDateEnd: moment.Moment,
    includeImplied: boolean
}

export interface CashTransactionReportProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class CashTransactionReport extends React.Component<CashTransactionReportProps, CashTransactionReportState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: CashTransactionReportProps) {
        super(props)
        var selectedEntity = props.getState("selectedEntityCT") ? Number(props.getState("selectedEntityCT")) : undefined;
        this.state = {
            transactions: new Array<CashTransaction>(),
            balances: new Array<CalculatedAccountBalance>(),
            mainRef: React.createRef(),
            asOfDate: moment().utc().endOf('day').subtract(1,"ms").toDate(),
            awaitingRefresh: true,
            computingCashflows: false,
            selectedAccount: undefined,
            selectedSet: DefaultSetName,
            selectedEntity: selectedEntity,
            selectedTabId: 0,
            filterDateStart: undefined,
            filterDateEnd: undefined,
            includeImplied: false
        };;
        this.updateCash = this.updateCash.bind(this);
        this.customToolbarTransactions = this.customToolbarTransactions.bind(this);
        this.customToolbarBalances = this.customToolbarBalances.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = navStoreInstance.addChangeListener(this.updateCash);
        this.updateCash();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    customToolbarTransactions(props: any) {
        const { selectedAccount, selectedSet, transactions, selectedEntity, filterDateStart, filterDateEnd, computingCashflows, includeImplied } = this.state;
        var accounts = transactions ? _.uniq(transactions.map(t => t.account)) : new Array<string>();
        var csvOptions = {} as GridCsvExportOptions;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "0.5em", position: "relative" }}>
                            <Grid container spacing={1} justifyContent="flex-start" alignContent="center" width="150%">
                                <Grid item>
                                    <WrappedSelect
                                        id="set"
                                        name="set"
                                        label="Set"
                                        size='small'
                                        value={selectedSet ?? "Select Set..."}
                                        onChange={(d) => {
                                            var set = d.target.value as string;
                                            if (set === "Select Set...")
                                                set = null;
                                            this.props.onChangeState("selectedSet", set)
                                            this.setState({ selectedSet: set });
                                        }}>
                                        {["Select Set...", ...marketDataStoreInstance.getSetNames()].map(a =>
                                            <MenuItem key={"set_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="entity"
                                        name="entity"
                                        label="Entity"
                                        size='small'
                                        value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === selectedEntity)[0]?.displayName ?? "Select Entity..."}
                                        onChange={(d) => {
                                            var entity = d.target.value as string;
                                            if (entity === "Select Entity...")
                                                entity = null;
                                            this.props.onChangeState("selectedEntityCT", entity)
                                            this.setState({ selectedEntity: userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === entity)[0]?.firmId });
                                        }}>
                                        <MenuItem key={"Entity_def"} value={"Select Entity..."}>{"Select Entity..."}</MenuItem>
                                        {userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName).map(a =>
                                            <MenuItem key={"Entity_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedCheckBox
                                        id="incImpleied"
                                        name="incImpleied"
                                        label="Implied?"
                                        size='small'
                                        value={includeImplied}
                                        onChange={(e, c) => { this.setState({ includeImplied: c }); }} />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" disabled={computingCashflows} onClick={() => this.calcCash()}>Compute</Button>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="account"
                                        name="account"
                                        label="Account"
                                        size='small'
                                        value={selectedAccount ?? "Select Account..."}
                                        onChange={(d) => {
                                            var acc = d.target.value as string;
                                            if (acc === "Select Account...")
                                                acc = null;
                                            this.props.onChangeState("selectedAccount", acc)
                                            this.setState({ selectedAccount: acc });
                                        }}>
                                        <MenuItem key={"acc1def"} value={"Select Account..."}>{"Select Account..."}</MenuItem>
                                        {accounts.map(a =>
                                            <MenuItem key={"Acc1_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        size='small'
                                        value={filterDateStart}
                                        onChange={(d) => this.setState({ filterDateStart: d })}
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="(None)"
                                        clearLabel="(None)"
                                        label={"Filter Start"} />
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        size='small'
                                        value={filterDateEnd}
                                        onChange={(d) => this.setState({ filterDateEnd: d })}
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="(None)"
                                        clearLabel="(None)"
                                        label={"Filter End"} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end" alignContent="center">
                                <Grid item>
                                    {!transactions || computingCashflows ?
                                        <div style={{ padding: "5px" }}><CircularProgress /></div> :
                                        <Typography variant='subtitle2' style={{ paddingTop: "0.5em" }} >{transactions.length} rows</Typography>
                                    }
                                </Grid>
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" csvOptions={csvOptions} />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider >
        );
    }

    async onChangeDate(d: moment.Moment) {
        var bal = await navStoreInstance.getAccountBalances(d?.toDate());
        if (bal)
            this.setState({ asOfDate: d.toDate(), balances: bal });
        else
            this.setState({ asOfDate: d.toDate() });
    }

    async onClickCalculate() {
        const {asOfDate, selectedEntity} = this.state;
        if (asOfDate) {
            this.setState({ awaitingRefresh: true })
            var bal = await FetchAccountBalances(new Date(asOfDate), selectedEntity===1?undefined:selectedEntity);
            if (bal)
                this.setState({ asOfDate: new Date(asOfDate), balances: bal });
            else
                this.setState({ asOfDate: new Date(asOfDate) });

            this.setState({ awaitingRefresh: false })
        }
    }

    customToolbarBalances(props: any) {
        const { asOfDate, balances, awaitingRefresh, selectedEntity } = this.state;
        var csvOptions = { fileName: `PltfmAccBalances-${moment(asOfDate)?.format("yyyy-MM-DD")}` } as GridCsvExportOptions;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "0.5em" }}>
                            <Grid container spacing={2} justifyContent="flex-start" alignContent="center">
                                <Grid item>
                                    <WrappedSelect
                                        style={{ width: "120px" }}
                                        value={selectedEntity && selectedEntity !==0 ? selectedEntity : 1}
                                        onChange={(v)=>this.setState({selectedEntity:Number(v.target.value)})}
                                        size="small"
                                        label={"Entity"}>
                                            <MenuItem key="enMaster" value={1}>Master</MenuItem>
                                            <MenuItem key="enFeeder" value={2}>Feeder</MenuItem>
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        value={asOfDate}
                                        onChange={this.onChangeDate}
                                        size="small"
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="Live"
                                        clearLabel="Live"
                                        label={"As-Of"} />
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="outlined" disabled={awaitingRefresh} onClick={() => this.onClickCalculate()}>Refresh</Button>
                                </Grid>
                                {!balances ? <Grid item>
                                    <div style={{ padding: "5px" }}><CircularProgress /></div>
                                </Grid> :
                                    <Grid item>
                                        <Typography variant='subtitle2' style={{ paddingTop: "0.5em" }}>{balances.length} rows</Typography>
                                    </Grid>}
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end" alignContent="center">
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" csvOptions={csvOptions} />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    async onClickRefresh() {
        this.setState({ awaitingRefresh: true });
        const { selectedSet } = this.state;
        await ComputeCashTransactions(selectedSet);
    }

    async calcCash() {
        this.setState({ computingCashflows: true });
        const { selectedSet, includeImplied } = this.state;
        await ComputeCashTransactions(selectedSet, undefined, !includeImplied);
    }

    updateCash() {
        let cash = navStoreInstance.getCashTransactions();
        this.props.onChangeState("awaitingRefresh", "false");
        this.setState({ transactions: cash, awaitingRefresh: false, computingCashflows: false });
    }

    renderDateTimeCell(params: GridCellParams) {
        return <span>{renderDate(dateFromOADate(params.value as number))}</span>;
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }

    renderTransactionAddEdit() {
        return <AddUpdateCashComponent />
    }

    renderTransactionTable() {
        const { transactions, mainRef, selectedAccount, selectedEntity } = this.state;

        const columns: GridColDef[] = [
            { field: 'transId', width: 150, headerName: 'TransId', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", filterOperators: getFilters("number") },
            { field: 'account', width: 200, headerName: 'Account', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters("string") },
            { field: 'counterparty', width: 200, headerName: 'Counterparty', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters("string") },
            { field: 'tradeId', width: 150, headerName: 'TradeId', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", filterOperators: getFilters("number") },
            { field: 'amount', width: 200, headerName: 'Amount', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", filterOperators: getFilters("number") },
            { field: 'ccy', width: 110, headerName: 'CCY', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters("string") },
            { field: 'category', width: 200, headerName: 'Category', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters("string") },
            { field: 'description', width: 200, headerName: 'Description', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters("string") },
            { field: 'externalId', width: 175, headerName: 'ExternalId', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", filterOperators: getFilters("string") },
            { field: 'valueDate', width: 200, headerName: 'Value Date', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "dateTime", renderCell: this.renderDateTimeCell, filterOperators: getFilters("dateTime") },
            { field: 'bookedUtc', width: 200, headerName: 'Booked (UTc)', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "dateTime", renderCell: this.renderDateTimeCell, filterOperators: getFilters("dateTime") },
            { field: 'updatedUtc', width: 200, headerName: 'Updated (UTC)', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "dateTime", renderCell: this.renderDateTimeCell, filterOperators: getFilters("dateTime") },
        ];

        var filteredTrans = transactions ? (selectedAccount ? transactions.filter(t => t.account === selectedAccount) : transactions) : new Array<CashTransaction>();
        if (selectedEntity)
            filteredTrans = filteredTrans.filter(t => t.homepartyId === selectedEntity);

        const rowData = filteredTrans
            .sort((a, b) => a.description > b.description ? 1 : -1)
            .sort((a, b) => a.transId - b.transId)
            .sort((a, b) => a.bookedUtc > b.bookedUtc ? 1 : -1)
            .map((trans, ix) => {
                return {
                    id: ix,
                    transId: trans.transId,
                    account: trans.account,
                    counterparty: trans.counterparty,
                    tradeId: trans.tradeId,
                    amount: trans.amount,
                    ccy: trans.ccy,
                    category: trans.category,
                    description: trans.description,
                    externalId: trans.externalId,
                    valueDate: momentUtcToOADate(trans.valueDate),
                    bookedUtc: momentUtcToOADate(trans.bookedUtc),
                    updatedUtc: momentUtcToOADate(trans.updatedUtc)
                };
            });

        return (
            <ThemeProvider theme={getFormTheme()}>
                <div className="CashTransactionTableContainer" ref={mainRef}>
                    {!transactions ? this.renderSpinner :
                        <div className="CashTransactionTable">
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={getPositionTableTheme()}>
                                    <DataGridPremium
                                        className="TradeBlotterDataGrid"
                                        key={"cashTransReportGrid"}
                                        density={userStoreInstance.GetGridDensity()}
                                        rows={rowData}
                                        columns={columns}
                                        hideFooter
                                        components={{
                                            Toolbar: this.customToolbarTransactions,
                                        }}
                                    />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </div>}
                </div></ThemeProvider>
        );
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    renderCcyCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
        }
        else
            return <div></div>;
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    renderBalancesTable() {
        const { balances, awaitingRefresh } = this.state;

        const columns: GridColDef[] = [
            { field: 'institution', width: 200, headerName: 'Institution', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'account', width: 200, headerName: 'Account', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'balance', width: 200, headerName: 'Balance', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'ccy', width: 110, headerName: 'CCY', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
        ];

        const rowData = balances
            //.sort((a, b) => a.accountName > b.accountName ? 1 : -1)
            .map((bal, ix) => {
                return {
                    id: ix,
                    institution: AccountToInstitutionMap[bal.accountName] ?? bal.institution,
                    account: bal.accountName,
                    balance: bal.balance,
                    ccy: bal.ccy,
                };
            });

        return (
            <ThemeProvider theme={getFormTheme()}>
                <div className="CashTransactionTableContainer">
                    {!balances ? this.renderSpinner :
                        <div className="CashTransactionTable">
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={getPositionTableTheme()}>
                                    <DataGridPremium
                                        className="TradeBlotterDataGrid"
                                        key={"cashTransReportGrid"}
                                        rows={rowData}
                                        columns={columns}
                                        density={userStoreInstance.GetGridDensity()}
                                        hideFooter
                                        loading={awaitingRefresh}
                                        components={{
                                            Toolbar: this.customToolbarBalances,
                                            LoadingOverlay: this.loading
                                        }}

                                        experimentalFeatures={{ aggregation: true }}
                                        initialState={{
                                            aggregation: { model: { balance: 'sum' } },
                                            columns: { columnVisibilityModel: {
                                                institution: false,
                                                ccy: false,
                                                account: false
                                            }}
                                        }}
                                        rowGroupingModel={["ccy","institution","account"]}
                                        rowGroupingColumnMode="multiple"
                                        defaultGroupingExpansionDepth={1}
                                    />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </div>}
                </div></ThemeProvider>
        );
    }

    render() {
        const { selectedTabId } = this.state;
        return (<div style={{ width: "100%" }}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px", width: "100%" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistory"}
                        component={Paper}
                        id={"tab0"}
                        label="Balances" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabTrans"}
                        component={Paper}
                        id={"tab1"}
                        label="Transactions" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"2"}
                        key={"tabTransAE"}
                        component={Paper}
                        id={"tab2"}
                        label="Add/Update" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 125px)" }}
                    key={"tabPQuery1"}
                    value={"0"}
                    //index={0}
                    children={this.renderBalancesTable()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery2"}
                    value={"1"}
                    //index={1}
                    children={this.renderTransactionTable()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery3"}
                    value={"2"}
                    //index={1}
                    children={this.renderTransactionAddEdit()} />
            </TabContext>
        </div>
        );
    }
}
