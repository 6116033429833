import { FilterOptionsState, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { getUltimateParentFromIns } from '../positions/positionsCommon';

type InstrumentSelectionProps = {
    filterType?: any | undefined,
    restrictedInsList?: number[] | undefined,
    onInstrumentSelect: (instrument: ListedInstrument) => void,
    listedInstrumentId: number | undefined
    width?: string
    size?: "small" | "medium"
    hideExpired?: boolean;
    label?: string;
    filterParentId?: number;
    filterOutExchange?: string
}

type InstrumentSelectionState = {
    listedInstrumentId: number | undefined;
}

const InsNull = { listedInstrumentId: 0, type: "Start typing...", description: "... first two letters" } as ListedInstrument

class InstrumentSelection extends React.Component<InstrumentSelectionProps, InstrumentSelectionState>
{
    constructor(props: InstrumentSelectionProps) {
        super(props);
        this.state = {
            listedInstrumentId: props.listedInstrumentId ?? 0,
        }
    }
    nullIns = { description: "Select Instrument..." } as ListedInstrument;

    getInstruments = () => {
        if (this.props.restrictedInsList)
            return [this.nullIns, ...this.props.restrictedInsList.map(i => listedInstrumentStoreInstance.getInstrumentById(i)).sort((a, b) => a.type > b.type ? 1 : -1)];

        var insPopulation = listedInstrumentStoreInstance.getInstruments();
        if (this.props.hideExpired)
            insPopulation = insPopulation.filter(i => i?.maturity === undefined || i?.maturity === null || moment(i?.maturity).isSameOrAfter(moment()));
        if (this.props.filterType)
            insPopulation = insPopulation.filter(x => x?.type === this.props.filterType);
        if (this.props.filterOutExchange)
            insPopulation = insPopulation.filter(x => x?.primaryExchange !== this.props.filterOutExchange);

        var uniqueDescriptions = _.uniq(insPopulation.filter(x=>x!==undefined).map(x => x?.description));
        if (uniqueDescriptions.length === insPopulation.length)
            return [this.nullIns, ...insPopulation.sort((a, b) => a.type > b.type ? 1 : -1)];
        else {
            var byDesc = _.groupBy(insPopulation, x=>x?.description ?? "unknown");
            Object.keys(byDesc).forEach(k => {
                const grp = byDesc[k];
                if(grp.length>1){
                    grp.forEach((gp,ix)=>{
                        if(gp && !gp.description.includes(gp.primaryExchange))
                            gp.description += ` (${gp.primaryExchange})`;
                        else if(gp)
                            gp.description += ` / ${ix}`;
                    })
                }
            });

            return [this.nullIns, ...insPopulation.sort((a, b) => a.type > b.type ? 1 : -1)];
        }
    }

    onInsSelect = (ins: ListedInstrument | string) => {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            this.setState({ listedInstrumentId: solidIns.listedInstrumentId })
            this.props.onInstrumentSelect(solidIns);
        }
    }

    filterInsOptions = (options: ListedInstrument[], state: FilterOptionsState<ListedInstrument>): ListedInstrument[] => {
        var query = state?.inputValue?.toLowerCase();
        if (query.length < 2)
            return [InsNull];
        var relevant = options.filter(o => !this.props.filterParentId || getUltimateParentFromIns(o)?.listedInstrumentId === this.props.filterParentId).filter(o => this.insMatchesQuery(o, query)).sort((a, b) => b.description.localeCompare(a.description)).sort((a, b) => a.type > b.type ? 1 : -1);
        return relevant;
    }

    insMatchesQuery = (ins: ListedInstrument, query: string): boolean => {
        const normalizedTitle = ins?.description?.toLowerCase();
        const code = ins?.ticker?.toLowerCase();
        const allMetaData = ins?.metaData ? ins.metaData.map(am => am.data?.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${ins?.listedInstrumentId}. ${normalizedTitle} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins?.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    getOptionLabel(o: string | ListedInstrument) {
        var ins = (o as ListedInstrument);
        if (ins?.description) {
            var label = ins?.description?.split(' Underlying Index')[0];
            var dupes = ["Mexican Peso", "Japanese Yen", "Dnr 9 05/15/21", "Chinese Renminbi (Offshore)", "Chinese Yuan", "Swiss Franc", "REPSOL SA", "Sigma Lithium Corp", "DTB Eurostoxx 50", "BTC/USD Perp"]
            if (dupes.includes(label))
                label += "-" + ins.primaryExchange;
            if (label === "Chinese Yuan-IDEALFX")
                label += `-(${ins.ticker})`
            return label;
        }
        return "Select Instrument...";
    }

    render() {
        return (<Autocomplete
            classes={{
                inputRoot: "BasketDesignerAutocomplete",
                popupIndicator: "BasketDesignerAutocompleteIcon",
                clearIndicator: "BasketDesignerAutocompleteIcon",
                popper: "AutocompleteGroupLabel",
            }}
            size={this.props.size ?? "small"}
            autoComplete
            autoHighlight
            autoSelect
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={this.getInstruments()}
            value={listedInstrumentStoreInstance.getInstrumentByIdOrNull(this.state.listedInstrumentId ?? null) ?? this.nullIns}
            id={"listedInstrumentId" + this.props.label}
            groupBy={(ins: ListedInstrument) => ins?.type ?? "Unknown group"}
            getOptionDisabled={(i) => i === InsNull}
            renderGroup={(params) => <div>{Boolean(params.group) ? <Typography variant="h6">{params.group}</Typography> : null}{params.children}</div>}
            getOptionLabel={this.getOptionLabel}
            style={{ width: this.props.width ?? 300 }}
            filterOptions={this.filterInsOptions}
            onChange={(e, v) => this.onInsSelect(v)}
            renderInput={(params) =>
                <TextField {...params}
                    //helperText={touched.listedInstrumentId ? errors.listedInstrumentId : ""}
                    //error={Boolean(errors.listedInstrumentId)}
                    label={this.props.label ?? "Instrument"}
                    variant="outlined" />} />)
    }
}

export default InstrumentSelection;