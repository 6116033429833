import React, { Component } from 'react';
import FBEmitter from 'fbemitter';
import { UserActivityEvent } from '../../userSession/pingModels';
import userActivityStoreInstance from '../../userSession/userActivityStore';
import { RefreshEvents, SubcribeToNewUserActivity, UnSubcribeFromNewUserActivity } from '../../userSession/userSessionActions';
import { renderDate } from '../../utils/helpers';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

export interface UserActivityWidgetProps {

}

interface UserActivityWidgetState {
  events: UserActivityEvent[]

}

class UserActivityWidget extends Component<UserActivityWidgetProps, UserActivityWidgetState>{
  eventSubscriptionUserEvents: FBEmitter.EventSubscription | undefined;
  constructor(props: UserActivityWidgetProps) {
    super(props);
    this.state = {
      events: new Array<UserActivityEvent>()
    }
    this.onNewEvent = this.onNewEvent.bind(this);
  }

  async componentDidMount() {
    await RefreshEvents();
    this.eventSubscriptionUserEvents = userActivityStoreInstance.addChangeListener(() => this.onNewEvent());
    await SubcribeToNewUserActivity();
    this.onNewEvent();
  }

  async componentWillUnmount() {
    await UnSubcribeFromNewUserActivity();
    if (this.eventSubscriptionUserEvents) {
      this.eventSubscriptionUserEvents.remove();
      this.eventSubscriptionUserEvents = undefined;
    }
  }

  onNewEvent() {
    var events = userActivityStoreInstance.GetEvents();
    this.setState({ events });
  }

  render() {
    if (this.state.events.length === 0)
      return (
        <div className="UserActivityWidgetNoEvents" key="UAW">
          (No events)
        </div>
      );
    else {
      const columns: GridColDef[] = [
        { field: 'Time', headerName: 'Time', width: 100, cellClassName: "UserActivityWidgetCell", headerClassName: "UserActivityWidgetCellHeader" },
        { field: 'Event', headerName: 'Event', width: 160, cellClassName: "UserActivityWidgetCell", headerClassName: "UserActivityWidgetCellHeader" },
        { field: 'Comment', headerName: 'Comment', width: 220, cellClassName: "UserActivityWidgetCell", headerClassName: "UserActivityWidgetCellHeader" },
      ];
      let rowData = this.state.events.map((row, ix) => {
        return { id: ix, Time: renderDate(row.stampUtc), Event: row.action, Comment: row.comment };
      })
      return (
        <div className="UserActivityWidget" key="UAW">
          <div className="UserActivityWidgetTable">
            <DataGridPro
              className="UserActivityWidgetGrid"
              rows={rowData}
              columns={columns}
              pageSize={5} />
          </div>
        </div>);
    }
  }
}
export default UserActivityWidget;