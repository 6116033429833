import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    MenuItem,
    TableCell,
    TableRow,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { GetParam } from '../trade/tradeBlotterTabComponent';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackRisk } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { GridCellParams, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, DataGridPro } from '@mui/x-data-grid-pro';
import { DefaultColumns } from './riskReportDefaults';
import riskStoreInstance from './riskStore';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { CloseOutlined } from '@mui/icons-material';
import TransferList from '../inputs/transferList';
import { AggregateCube } from '../qwack/cubeUtils';
import _ from 'lodash';

interface QwackRiskTableState {
    resultCube: TO_ResultCube,
    mainRef: RefObject<HTMLDivElement>,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    hideClosedPositions: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    selectedMetric: string,
    currentError: string | undefined,
    showFieldChoser: boolean,
    aggregationFields: string[],
}

export interface QwackRiskTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const DateColumns = ["PointDate"]
const QwackMetrics = ["PV", "AssetDelta", "AssetVega", "AssetGamma", "Theta", "AnalyticTheta", "FxDelta", "FxGamma", "FxVega"];

export class QwackRiskTable extends React.Component<QwackRiskTableProps, QwackRiskTableState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: QwackRiskTableProps) {
        super(props)
        var asOf = props.getState("qt_asOf");
        this.state = {
            resultCube: {} as TO_ResultCube,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            hideClosedPositions: GetParam(this.props.getState, "hideClosedPositions") ?? true,
            rowExpanded: undefined,
            selectedSet: DefaultSetName,
            selectedMetric: QwackMetrics[0],
            currentError: undefined,
            showFieldChoser: false,
            aggregationFields: [],
        };;
        this.updatePVs = this.updatePVs.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = riskStoreInstance.addChangeListener(this.updatePVs);
        this.updatePVs();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("qt_asOf", dStr);
        this.updatePVs(d);
        this.setState({ asOf: d, });
    }

    async calcPVs() {
        this.setState({ awaitingRefresh: true, resultCube: null });
        const { selectedSet, asOf, selectedMetric } = this.state;
        await ComputeQwackRisk(asOf.toDate(), selectedMetric, selectedSet);

    }

    updatePVs(asOf?: Moment, metric?: string, set?: string) {
        if (!asOf)
            asOf = this.state.asOf;
        if (!metric)
            metric = this.state.selectedMetric;
        if (!set)
            set = this.state.selectedSet;

        let errors = riskStoreInstance.getQwackRiskError(asOf?.toDate(), set, metric);
        if (errors) {
            this.setState({ currentError: "There was an error in calculation", awaitingRefresh: false });
        }
        else {
            let cube = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, metric)
            this.props.onChangeState("awaitingRefresh", "false");
            this.setState({ resultCube: cube, awaitingRefresh: false, currentError: undefined });
        }
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }

    renderRowTitles() {
        return (<TableRow>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color, width: "50px" }} />
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Type</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Group</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Ccy</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Value (CCY)</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Value (USD)</TableCell>
        </TableRow>);
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
        }
        else
            return <div></div>;
    }
    customToolbar(props: any) {
        const { awaitingRefresh, asOf, selectedSet, selectedMetric, currentError } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                            <Grid item container spacing={1} style={{ width: "50%" }}>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        value={asOf?.toDate()}
                                        onChange={this.onChangeDate}
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="Live"
                                        clearLabel="Live"
                                        label={"As-Of"}
                                    />
                                </Grid>
                                <Grid item>
                                    <MarketDataSetSelector
                                        id="set"
                                        name="set"
                                        label="Set"
                                        value={selectedSet}
                                        onChange={(set: string) => {
                                            this.props.onChangeState("selectedSet", set)
                                            this.updatePVs(null, null, set);
                                            this.setState({ selectedSet: set });
                                        }} />
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="metric"
                                        name="metric"
                                        label="Metric"
                                        value={selectedMetric}
                                        onChange={(d) => {
                                            var metric = d.target.value as string;
                                            this.props.onChangeState("selectedMetric", metric)
                                            this.updatePVs(null, metric);
                                            this.setState({ selectedMetric: metric });
                                        }}>
                                        {QwackMetrics.map(a =>
                                            <MenuItem key={"metric_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcPVs()}>Compute</Button>
                                </Grid>
                                {currentError ? <Grid item>
                                    <Typography>{currentError}</Typography>
                                </Grid> : null}
                            </Grid>
                            <Grid item container spacing={1} justifyContent="flex-end" style={{ width: "50%" }}>
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.setState({ showFieldChoser: true })}>Aggregate</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    getColDef(name: string) {
        const { selectedMetric } = this.state;
        if (DateColumns.includes(name))
            return { field: name, hide: !DefaultColumns(selectedMetric).includes(name), filterable: true, width: 150, flex: 1, headerName: name, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" } as GridColDef;

        return { field: name, hide: !DefaultColumns(selectedMetric).includes(name), filterable: true, width: 150, flex: 1, headerName: name, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" } as GridColDef;
    }

    render() {
        const { resultCube, mainRef, awaitingRefresh, showFieldChoser, aggregationFields } = this.state;

        var cube = _.cloneDeep(resultCube);
        if (aggregationFields?.length > 0) {
            cube = AggregateCube(cube, aggregationFields);
        }
        const columns: GridColDef[] = cube && cube.fieldNames ? [
            ...cube.fieldNames.map(f => this.getColDef(f)),
            { field: 'value', width: 175, filterable: true, flex: 1, headerName: 'Value', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderNumberCell },
        ] : new Array<GridColDef>();

        if (aggregationFields?.length > 0) {
            aggregationFields.forEach(a => {
                columns.filter(c => c.field === a)[0].hide = false;
            });
        }

        const rows = cube && cube.fieldNames ? cube.rows.map((r, ix) => {
            var rowA = { id: ix, value: r.value };
            for (var i = 0; i < cube.fieldNames.length; i++) {
                if (cube.fieldNames[i] === "Underlying")
                    rowA[cube.fieldNames[i]] = r.metaData[i].replaceAll(" Underlying Index", "") //new Date(r.metaData[i]);
                else
                    rowA[cube.fieldNames[i]] = r.metaData[i];
            }
            return rowA;
        }) : new Array<any>();

        const theme = userStoreInstance.GetTheme();

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="NavCalcTableContainer">
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            open={showFieldChoser}
                            onClose={() => this.setState({ showFieldChoser: false })}>
                            <DialogTitle id="alert-dialog-title" style={{ backgroundColor: theme.background_color_opaque, color: theme.color, borderBottom: "1px solid", borderColor: theme.border_color }}>
                                <Typography variant="h6">Aggregation Fields</Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={() => this.setState({ showFieldChoser: false })}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}>
                                    <CloseOutlined />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent style={{ backgroundColor: theme.background_color_opaque, color: theme.color, display: "flex", justifyContent: "center", width: "100%" }}>
                                {showFieldChoser && <div style={{ paddingTop: "1em", backgroundColor: theme.background_color }}>
                                    <TransferList
                                        chosen={aggregationFields?.map(t => columns.filter(c => c.field === t)[0]?.headerName)}
                                        choices={resultCube.fieldNames}
                                        onChange={(chosen: string[]) => { this.setState({ aggregationFields: chosen }); }}
                                        onClose={() => this.setState({ showFieldChoser: false })}
                                    />
                                </div>}
                            </DialogContent>
                        </Dialog>
                        <div className="QwackPvTable" ref={mainRef}>
                            <DataGridPro
                                className="QwackPvTableGrid"
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: this.customToolbar,
                                    LoadingOverlay: this.loading,
                                }}
                                hideFooter
                                loading={awaitingRefresh} />
                        </div>
                    </div></ThemeProvider>
            </StyledEngineProvider>
        );
    }
}
