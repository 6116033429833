import FBEmitter from 'fbemitter';
import React, { PureComponent } from 'react';
import {
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Paper,
    Tab,
    Tabs,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import userStoreInstance from '../user/userStore';
import moment from 'moment';
import { getFormTheme } from '../inputs/formCommon';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarFilterButton, GridRowData, GridToolbarContainer, GridToolbarExport, GridOverlay } from '@mui/x-data-grid-pro';
import navStoreInstance from './navStore';
import { NavRecord } from './navModels';
import { CartesianGrid, LineChart, ResponsiveContainer, XAxis, YAxis, Line, Tooltip, Legend, ReferenceLine } from 'recharts';
import _ from 'lodash';
import { TabContext, TabPanel } from '@mui/lab';
import { renderDate } from '../utils/helpers';
import { Formik, FormikHelpers } from 'formik';
import { AddNavRecord, GetNavsForFund } from './navActions';
import { OfficialNavForm } from './officialNavForm';
import * as Yup from 'yup';
import { EditOutlined } from '@mui/icons-material';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface NavHistoryTableState {
    selectedFund: string,
    selectedShareClass: string,
    availableShareClasses: string[],
    navs: NavRecord[],
    selectedTabId: number,
    hasBeenSubmitted: boolean,
    lastBookedId: number | undefined,
    rows: GridRowData[],
    cols: GridColDef[],
    selectedNavData: NavRecord
}

export interface NavHistoryTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const nullFund = "Select Fund...";
const nullShareClass = "Select Class...";


const NavValidationSchema = Yup.object().shape({
    fund: Yup.string()
        .required('Required'),
    // shareClass: Yup.string()
    //     .required('Required'),
    value: Yup.number()
        .required('Required')
        .typeError('Valid amount required, no column seperators allowed'),
    navPerShare: Yup.number()
        .required('Required')
        .min(0)
        .typeError('Valid NAV required, no column seperators allowed'),
    gavPerShare: Yup.number()
        .required('Required')
        .min(0)
        .typeError('Valid GAV required, no column seperators allowed'),
    sharesPreSubsReds: Yup.number()
        .required('Required')
        .min(0)
        .typeError('Valid shares (pre subs/reds), no column seperators allowed'),
    sharesPostSubsReds: Yup.number()
        .required('Required')
        .min(0)
        .typeError('Valid shares (post subs/reds) required, no column seperators allowed'),
    date: Yup.date()
        .required('Required'),
});

export class NavHistoryTable extends React.Component<NavHistoryTableProps, NavHistoryTableState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: NavHistoryTableProps) {
        super(props)

        var fund0 = navStoreInstance.getFunds()[0];
        var availableClasses0 = fund0?.classes;

        this.state = {
            selectedFund: fund0?.name,
            selectedShareClass: availableClasses0[0],
            availableShareClasses: availableClasses0,
            navs: new Array<NavRecord>(),
            selectedTabId: 0,
            hasBeenSubmitted: false,
            lastBookedId: undefined,
            cols: [],
            rows: [],
            selectedNavData: undefined
        };;
        this.customToolbar = this.customToolbar.bind(this);
        this.updateNavs = this.updateNavs.bind(this);
        this.renderDateCell = this.renderDateCell.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.renderHistoryTable = this.renderHistoryTable.bind(this);
        this.onSubmitNav = this.onSubmitNav.bind(this);
        this.onResetNav = this.onResetNav.bind(this);
    }

    async componentDidMount() {
        const { selectedFund, selectedShareClass } = this.state;
        this.eventSubscriptionNavs = navStoreInstance.addChangeListener(this.updateNavs);
        var navs = await navStoreInstance.getNavs(selectedFund, selectedShareClass);
        if (navs)
            this.setState({ navs });
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    customToolbar(props: any) {
        const { selectedFund, selectedShareClass, availableShareClasses } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container spacing={1} alignContent="center" style={{ padding: "5px" }}>
                            <Grid item>
                                <WrappedSelect
                                    id="fund"
                                    name="fund"
                                    label="Fund"
                                    value={selectedFund ?? nullFund}
                                    onChange={async (f) => {
                                        var fund = f.target.value as string;
                                        if (fund === nullFund)
                                            fund = null;
                                        var availableClasses = navStoreInstance.getShareClasses(fund);
                                        var navs = Boolean(availableClasses[0]) ? await navStoreInstance.getNavs(fund, availableClasses[0]) : await navStoreInstance.getNavsForFund(fund);
                                        this.setState({ selectedFund: fund, navs, availableShareClasses: availableClasses, selectedShareClass: availableClasses[0] });
                                    }}>
                                    {[nullFund, ...navStoreInstance.getFunds().map(f => f.name)].map(f =>
                                        <MenuItem key={"fund_" + f} value={f}>{f}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            {availableShareClasses ? <Grid item>
                                <WrappedSelect
                                    id="shareClass"
                                    name="shareClass"
                                    label="Share Class"
                                    value={selectedShareClass ?? nullShareClass}
                                    onChange={async (f) => {
                                        var shareClass = f.target.value as string;
                                        if (shareClass === nullShareClass)
                                            shareClass = null;
                                        var navs = shareClass ? await navStoreInstance.getNavs(selectedFund, shareClass) : await navStoreInstance.getNavsForFund(selectedFund);
                                        if (navs)
                                            this.setState({ selectedShareClass: shareClass, navs });
                                        else
                                            this.setState({ selectedShareClass: shareClass });
                                    }}>
                                    {[nullShareClass, ...availableShareClasses.filter(f => f !== null)].map(f =>
                                        <MenuItem key={"class_" + f} value={f}>{f}</MenuItem>)}
                                </WrappedSelect>
                            </Grid> : null}
                            <Grid item><GridToolbarExport {...props} size="small" className="MuiButton-outlined" /></Grid>
                            <Grid item><GridToolbarFilterButton {...props} style={{ borderColor: userStoreInstance.GetTheme().border_color, height: "2.5em" }} className="MuiButton-outlined" />                            </Grid>
                            <Grid item><GridToolbarColumnsButton {...props} size="small" className="MuiButton-outlined" /></Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    renderDateTimeCell(params: GridCellParams) {
        return <span>{renderDate(new Date(params.value as string))}</span>;
    }

    async updateNavs(fund?: string, shrClass?: string, avClasses?: string[]) {
        const { selectedFund, selectedShareClass, availableShareClasses } = this.state;

        if (!fund)
            fund = selectedFund;
        if (!shrClass)
            shrClass = selectedShareClass;
        if (!avClasses)
            avClasses = availableShareClasses;

        if (fund) {
            var availableClasses = navStoreInstance.getShareClasses(fund);
            if (avClasses !== availableClasses)
                this.setState({ availableShareClasses: availableClasses });
        }

        if (fund && !shrClass) {
            var navs = await navStoreInstance.getNavsForFund(fund);
            this.setState({ navs });
        }
        else if (fund && shrClass) {
            var navs2 = await navStoreInstance.getNavs(fund, shrClass);
            this.setState({ navs: navs2 });
        }
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined,{maximumFractionDigits:4})}</div>
        }
        else
            return <div></div>;
    }

    renderDateCell(params: GridCellParams) {
        if (params.value) {
            var val: Date = new Date(params.value.toString());
            return <div>{this.renderDate(val)}</div>
        }
        else
            return <div></div>;
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    async onSubmitNav(values: NavRecord, { setSubmitting }: FormikHelpers<NavRecord>) {
        this.setState({ hasBeenSubmitted: true });
        var transaction = {
            navId: values.navId,
            fund: values.fund,
            shareClass: values.shareClass,
            value: Number(values.value),
            sharesPreSubsReds: Number(values.sharesPreSubsReds),
            sharesPostSubsReds: Number(values.sharesPostSubsReds),
            navPerShare: Number(values.navPerShare),
            gavPerShare: Number(values.gavPerShare),
            date: values.date,
        } as NavRecord

        var lastBookedId = await AddNavRecord(transaction);
        setSubmitting(false);
        await GetNavsForFund(values.fund);
        this.setState({ lastBookedId });
    }

    onResetNav(values: NavRecord, { resetForm }: FormikHelpers<NavRecord>) {
        //resetForm();
        this.setState({ hasBeenSubmitted: false, lastBookedId: undefined, selectedNavData: undefined });
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    onDuplicatePressed = () => this.setState({ hasBeenSubmitted: false, lastBookedId: undefined });

    renderAddNavForm() {
        const { hasBeenSubmitted, lastBookedId, selectedNavData } = this.state;

        return (<Formik
            key={"navForm-" + (selectedNavData?.navId ?? "new")}
            onSubmit={this.onSubmitNav}
            onReset={this.onResetNav}
            enableReinitialize={true}
            initialValues={selectedNavData ?? {} as NavRecord}
            validationSchema={NavValidationSchema}>
            {props => <OfficialNavForm className="NewTradeForm" {...props} canDuplicate={hasBeenSubmitted} onDuplicatePressed={this.onDuplicatePressed} lastBookedId={lastBookedId} />}
        </Formik>);
    }

    editNavRecord(navId: number) {
        var selectedNav = this.state.navs.filter(n => n.navId === navId)[0];
        this.setState({ selectedNavData: selectedNav, selectedTabId: 1, hasBeenSubmitted: false, lastBookedId: undefined });
    }

    renderHistoryTable() {
        const { navs } = this.state;

        const baseCol = { flex: 1, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
        const columns: GridColDef[] = [
            //{ field: 'navId', width: 50, headerName: 'Id', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number" },
            { field: 'date', width: 150, type: "dateTime", renderCell: this.renderDateCell, ...baseCol },
            { field: 'fund', width: 200, headerName: 'Fund', ...baseCol },
            { field: 'shareClass', width: 120, headerName: 'Class', ...baseCol },
            { field: 'total', width: 175, headerName: 'Total NAV', type: "number", renderCell: this.renderNumberCell, ...baseCol },
            { field: 'navPerShare', width: 175, headerName: 'NAV/Share', type: "number", renderCell: this.renderNumberCell, ...baseCol },
            { field: 'gavPerShare', width: 175, headerName: 'GAV/Share', type: "number", renderCell: this.renderNumberCell, ...baseCol },
            { field: 'sharesPre', width: 175, headerName: 'Shares (Pre)', type: "number", renderCell: this.renderNumberCell, ...baseCol },
            { field: 'sharesPost', width: 175, headerName: 'Shares (Post)', type: "number", renderCell: this.renderNumberCell, ...baseCol },
            {
                field: 'actions',
                width: 175,
                headerName: 'Actions',
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => <IconButton onClick={() => this.editNavRecord(Number(params.id))} size="large"><EditOutlined /></IconButton>
                , ...baseCol
            },
        ];
        const rows = navs ? navs
            .sort((a, b) => b.shareClass < a.shareClass ? 1 : -1)
            .sort((a, b) => b.date.valueOf() > a.date.valueOf() ? 1 : -1)
            .map((n, ix) => {
                return {
                    id: n.navId,
                    navId: n.navId,
                    fund: n.fund,
                    shareClass: n.shareClass,
                    total: n.value,
                    navPerShare: n.navPerShare,
                    gavPerShare: n.gavPerShare,
                    sharesPre: n.sharesPreSubsReds,
                    sharesPost: n.sharesPostSubsReds,
                    date: new Date(n.date)
                };
            }) : new Array<GridRowData>();

        var groupedRowsByClass = _.groupBy(rows, (r) => r.shareClass ?? "");
        var groupedRowsByDate = _.groupBy(rows, (r) => r.date);
        var classes = Object.keys(groupedRowsByClass);
        var dates = Object.keys(groupedRowsByDate);
        var chartData = dates.sort((a, b) => moment(a).isAfter(moment(b)) ? 1 : -1).map(date => {
            var chartDataRow = { date: (new Date(date)).valueOf() };
            classes.map(clas => {
                var forDateAndClass = groupedRowsByClass[clas].filter(g => g.date.valueOf() === new Date(date).valueOf())[0];
                if (forDateAndClass) {
                    chartDataRow["value-" + clas] = forDateAndClass.navPerShare;
                    chartDataRow["total-" + clas] = forDateAndClass.total / 1000000;
                }
                return null;
            });
            return chartDataRow;
        });
        // var chartDataDict = Object.keys(groupedRowsByClass).map(g => {
        //     var rowsForGroup = groupedRowsByClass[g];
        //     return {
        //         name: g ?? "Class", data: rowsForGroup.map(r => {
        //             return { date: r.date.valueOf(), value: r.value, total: r.total / 1000000 }
        //         })
        //     };
        // });

        var classColors = classes.map((c, ix) => userStoreInstance.GetTheme()[`chart_color_indic_${ix}`]);

        return (
            <div className="NavHistory">
                <ThemeProvider theme={getFormTheme()}>
                    <DataGridPro
                        className="PositionSummaryTabTable"
                        rows={rows}
                        columns={columns}
                        hideFooter
                        components={{
                            Toolbar: this.customToolbar,
                            LoadingOverlay: this.loading,
                        }}
                    //loading={awaitingRefresh}
                    />
                </ThemeProvider>
                <div className="NavHistoryChart">
                    <div style={{ width: "50%", height: "100%" }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart key="pnlChart" width={500} height={400} data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" scale="time" tick={<CustomizedAxisTick />} stroke={userStoreInstance.GetTheme().border_color} />
                                <YAxis stroke={userStoreInstance.GetTheme().border_color} />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend verticalAlign="top" />
                                <ReferenceLine y={0} stroke={userStoreInstance.GetTheme().border_color} />
                                {classes.map((c, ix) =>
                                    <Line key={"value-" + c} dataKey={"value-" + c} name={`${c} NAV / Share`} fill={classColors[ix]} />
                                )}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart key="pnlChart" width={500} height={400} data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" scale="time" tick={<CustomizedAxisTick />} stroke={userStoreInstance.GetTheme().border_color} />
                                <YAxis unit="m" stroke={userStoreInstance.GetTheme().border_color} />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend verticalAlign="top" />
                                <ReferenceLine y={0} stroke={userStoreInstance.GetTheme().border_color} />
                                {classes.map((c, ix) =>
                                    <Line key={"total-" + c} dataKey={"total-" + c} name={`${c} Total NAV`} fill={classColors[ix]} />
                                )}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { selectedTabId } = this.state;
        return (<div>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistory"}
                        component={Paper}
                        id={"tab0"}
                        label="History" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabUpdate"}
                        component={Paper}
                        id={"tab1"}
                        label="Update" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery1"}
                    value={"0"}
                    //index={0}
                    children={this.renderHistoryTable()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery2"}
                    value={"1"}
                    //index={1}
                    children={this.renderAddNavForm()} />
            </TabContext>
        </div>
        );
    }
}

class CustomizedAxisTick extends PureComponent<any, {}> {
    render() {
        const { x, y, payload } = this.props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill={userStoreInstance.GetTheme().border_color}>
                    {moment(payload.value).format("yyyy-MM-DD")}
                </text>
            </g>
        );
    }
}

class CustomTooltip extends PureComponent<any, {}> {
    render() {
        const { active, payload, label } = this.props;
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="custom-tooltip-label">{`${moment(label).format("yyyy-MM-DD")}`}</p>
                    <p className="custom-tooltip-value">{`${Number(payload[0].value).toFixed(4)}`}</p>
                </div>
            );
        }
        return null;
    }
}