import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import { Trade } from '../trade/tradeModels';
import { LivePosition, LiveSummary } from './positionSummaryModels';
import positionStoreInstance from './positionSummaryStore';
import { LoadValuationsByMeta, SubcribeToLivePositionUpdates, UnSubcribeFromLivePositionUpdates } from './positionSummaryActions';
import { createTheme } from '@mui/material';
import userStoreInstance from '../user/userStore';
import { GridColDef, LicenseInfo } from '@mui/x-data-grid-pro';
import { GetParam } from '../trade/tradeBlotterTabComponent';
import { SubscribeToJobUpdates, UnSubscribeFromJobUpdates } from '../userSession/userSessionActions';
import { v4 } from 'uuid';
import taskUpdateStoreInstance from '../userSession/taskUpdateStore';
import { SubscribeToTicksByIdBulk } from '../accelerator/acceleratorActions';
import { PositionLiveGrid } from './positionLiveGridNew';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { isMoment, Moment } from 'moment';
import moment from 'moment';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';
import _ from 'lodash';
import { BookStr, StrategyStr } from '../globalConstants';
import acceleratorStoreInstance from '../accelerator/acceleratorStore';
import { ConnectionStatus } from '../connections/connectionStatusModel';

LicenseInfo.setLicenseKey(
    '221f5ef82f3ddafd0fbd87801385ef82Tz01MDM3NCxFPTE2OTQwOTkzMzYzMjEsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

export const getPositionTableTheme = () => {
    return createTheme({
        palette: {
            primary: { main: userStoreInstance.GetTheme().color, contrastText: userStoreInstance.GetTheme().color },
            secondary: { main: userStoreInstance.GetTheme().background_color, contrastText: userStoreInstance.GetTheme().color },
            text: { secondary: userStoreInstance.GetTheme().color },
        },
        typography: {
            allVariants: {
                fontFamily: userStoreInstance.GetTheme().font_family
            }
        },
        components: {
            MuiMenu: {
                styleOverrides: {
                    list: {
                        backgroundColor: userStoreInstance.GetTheme().background_color_opaque
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                        margin: "2px",
                        borderBottom: "2px solid",
                        borderColor: userStoreInstance.GetTheme().background_color,
                        "&:hover": {
                            borderColor: userStoreInstance.GetTheme().border_color,
                            //border: "1px solid",
                            backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
                            opacity: 1.0,
                            margin: "2px"
                        },
                    },

                }
            },
            MuiNativeSelect: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    },
                    select: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    },
                    standard: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    },
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {

                    },
                    head: {
                        fontWeight: "bold",
                        borderBottom: "1px solid",
                        borderBottomColor: userStoreInstance.GetTheme().border_color,
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color + " !important",
                    },
                    body: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                        borderColor: userStoreInstance.GetTheme().contrastBorderColorLight,
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color + " !important",
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().border_color,
                        borderColor: userStoreInstance.GetTheme().border_color,
                        "&$focused": {
                            color: userStoreInstance.GetTheme().color,
                        },
                        "&:hover": {
                            backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
                        },
                    },
                    notchedOutline: {
                        borderColor: userStoreInstance.GetTheme().border_color,
                    },
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color + " !important",
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    }
                }
            },
            // MuiDataGridPro: {
            //     root: {
            //         color: userStoreInstance.GetTheme().color + " !important",
            //     }
            // },
            MuiTableFooter: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    },
                    outlined: {
                        borderColor: userStoreInstance.GetTheme().border_color, // + " !important",
                        height: "3em", // !important",
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                        "&:disabled": {
                            borderColor: userStoreInstance.GetTheme().border_color,
                        }

                    },
                }
            },
        }
    });
}

interface PositionSummaryTableState {
    trades: Trade[],
    summary: LiveSummary,
    baselineSummary: LiveSummary,
    mainRef: RefObject<HTMLDivElement>,
    widthTotal: number,
    widthEven: number,
    asOf: moment.Moment | null,
    baseline: moment.Moment | null,
    showFxAsPosition: boolean,
    awaitingRefresh: boolean,
    showStatusPanel: boolean,
    hideClosedPositions: boolean,
    showTotals: boolean,
    jobUpdateKey: string,
    jobUpdateMessage: string,
    columns: GridColDef[],
    rows: any[],
}

export interface PositionSummaryTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export const metaKeys = [BookStr, StrategyStr];

export class PositionSummaryTable extends React.Component<PositionSummaryTableProps, PositionSummaryTableState>{
    eventSubscriptionPositions: FBEmitter.EventSubscription | undefined;
    eventSubscriptionUpdates: FBEmitter.EventSubscription | undefined;
    eventSubscriptionAccelerator: FBEmitter.EventSubscription | undefined;

    constructor(props: PositionSummaryTableProps) {
        super(props)
        var asOf = props.getState("asOf");
        var baseline = props.getState("baseline");
        this.state = {
            trades: [],
            summary: { positions: new Array<LivePosition>() } as LiveSummary,
            baselineSummary: undefined,
            mainRef: React.createRef(),
            widthTotal: 1000,
            widthEven: 200,
            asOf: asOf && asOf !== "now" && asOf !== "{}" ? moment(asOf) : null,
            baseline: baseline && baseline !== "now" && baseline !== "{}" ? moment(baseline) : null,
            awaitingRefresh: true,
            showStatusPanel: false,
            hideClosedPositions: GetParam(this.props.getState, "hideClosedPositions") ?? true,
            showTotals: GetParam(this.props.getState, "showTotals") ?? false,
            jobUpdateKey: v4(),
            jobUpdateMessage: undefined,
            columns: [],
            rows: [],
            showFxAsPosition: false
        };;
        this.updatePositions = this.updatePositions.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeBaselineDate = this.onChangeBaselineDate.bind(this);
        this.onChangeShowFxAsPosition = this.onChangeShowFxAsPosition.bind(this);
        this.onJobUpdate = this.onJobUpdate.bind(this);
        this.onClickRefresh = this.onClickRefresh.bind(this);
        this.onAcceleratorChange = this.onAcceleratorChange.bind(this)
    }

    async componentDidMount() {
        this.eventSubscriptionPositions = positionStoreInstance.addChangeListener(this.updatePositions);
        this.eventSubscriptionAccelerator = acceleratorStoreInstance.addChangeListener(this.onAcceleratorChange);
        await SubcribeToLivePositionUpdates();
        this.updatePositions(null, false);
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionPositions) {
            this.eventSubscriptionPositions.remove();
            this.eventSubscriptionPositions = undefined;
        }
        if (this.eventSubscriptionUpdates) {
            this.eventSubscriptionUpdates.remove();
            this.eventSubscriptionUpdates = undefined;
        }
        await UnSubcribeFromLivePositionUpdates();
        await UnSubscribeFromJobUpdates(this.state.jobUpdateKey);
    }

    public componentDidUpdate() {
        // if (this.state.mainRef.current) {
        //     let width = this.state.mainRef.current.clientWidth;
        //     if (width !== this.state.widthTotal) {
        //         this.setState({ widthTotal: width, widthEven: (width - 3) / 14 });
        //     }
        // }
    }

    async onAcceleratorChange(){
        var status = acceleratorStoreInstance.getConnectionStatus();
        if(status===ConnectionStatus.Connected)
            await this.subscribeToLivePrices(this.state.summary);
    }

    async onClickRefresh() {
        const { showFxAsPosition, asOf, baseline, jobUpdateKey } = this.state;
        this.setState({ awaitingRefresh: true, summary: undefined, jobUpdateMessage: "Connecting to server..." });
        this.props.onChangeState("awaitingRefresh", "true");

        this.eventSubscriptionUpdates = taskUpdateStoreInstance.addChangeListener(this.onJobUpdate);
        await SubscribeToJobUpdates(jobUpdateKey);

        var t1 = LoadValuationsByMeta(asOf?.format("yyyy-MM-DD") ?? "now", metaKeys, true, showFxAsPosition, jobUpdateKey);
        if (baseline?.format("yyyy-MM-DD")) {
            var t2 = LoadValuationsByMeta(baseline?.format("yyyy-MM-DD"), metaKeys, true, showFxAsPosition, undefined);
            await Promise.all([t1, t2]);
        }
        else
            await t1;
    }

    async onJobUpdate() {
        var status = taskUpdateStoreInstance.GetLatestStatus(this.state.jobUpdateKey);
        if (status?.update !== this.state.jobUpdateMessage) {
            this.setState({ jobUpdateMessage: status?.update });
        }
        if (status?.isFinished) {
            await UnSubscribeFromJobUpdates(this.state.jobUpdateKey);
        }
    }

    onChangeDate(d: moment.Moment) {
        const { showFxAsPosition, jobUpdateKey } = this.state;
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("asOf", dStr);
        var positions = positionStoreInstance.getValuationsByMeta(dStr, metaKeys, true, this.state.showFxAsPosition);
        if (positions)
            this.setState({ asOf: d, summary: positions, awaitingRefresh: false });
        else {
            LoadValuationsByMeta(dStr, metaKeys, false, showFxAsPosition, jobUpdateKey);
            this.setState({ asOf: d, summary: positions, awaitingRefresh: true });
        }
    }

    onChangeBaselineDate(d: moment.Moment) {
        if (isMoment(d)) {
            var dStr = d?.format("yyyy-MM-DD") ?? "";
            this.props.onChangeState("baseline", dStr);
            if (dStr === "")
                this.setState({ baseline: d, baselineSummary: undefined, awaitingRefresh: false });
            else {
                var positions = positionStoreInstance.getValuationsByMeta(dStr, metaKeys, true, this.state.showFxAsPosition);
                if (positions)
                    this.setState({ baseline: d.startOf('day'), baselineSummary: positions, awaitingRefresh: false });
                else {
                    LoadValuationsByMeta(dStr, metaKeys, false, this.state.showFxAsPosition, this.state.jobUpdateKey);
                    this.setState({ baseline: d.startOf('day'), baselineSummary: positions, awaitingRefresh: true });
                }
            }
        }
        else {
            this.setState({ baseline: d, baselineSummary: undefined, awaitingRefresh: false });
        }
    }

    onChangeShowFxAsPosition(newShowFxAsPosition: boolean) {
        const { asOf, baseline, jobUpdateKey } = this.state;
        this.props.onChangeState("showFxAsPosition", newShowFxAsPosition ? "true" : "false");

        var dStr = asOf?.format("yyyy-MM-DD") ?? "now";
        var awaitingRefresh = false;

        var positions = positionStoreInstance.getValuationsByMeta(dStr,metaKeys, true, newShowFxAsPosition);
        if (positions)
            this.setState({ summary: positions, showFxAsPosition: newShowFxAsPosition });
        else {
            LoadValuationsByMeta(dStr, metaKeys, false, newShowFxAsPosition, jobUpdateKey);
            awaitingRefresh = true;
            this.setState({ summary: positions, showFxAsPosition: newShowFxAsPosition });
        }

        var dStrBaseline = baseline?.format("yyyy-MM-DD") ?? "";
        if (dStrBaseline !== "") {
            var positionsBl = positionStoreInstance.getValuationsByMeta(dStrBaseline, metaKeys, true, newShowFxAsPosition);
            if (positionsBl)
                this.setState({ baselineSummary: positions, showFxAsPosition: newShowFxAsPosition });
            else {
                LoadValuationsByMeta(dStr,metaKeys, false, newShowFxAsPosition, this.state.jobUpdateKey);
                awaitingRefresh = true;
                this.setState({ baselineSummary: positions, showFxAsPosition: newShowFxAsPosition });
            }
        }

        this.setState({ awaitingRefresh });
    }

    async updatePositions(asOf?: Moment, preventAutoLoad: boolean = true) {
        if (!asOf)
            asOf = this.state.asOf;
        var refreshComplete = true;
        let positions = positionStoreInstance.getValuationsByMeta(asOf?.format("yyyy-MM-DD") ?? "now", metaKeys, preventAutoLoad, this.state.showFxAsPosition);

        if (!positions)
            refreshComplete = false;
        else {
            positions = JSON.parse(JSON.stringify(positions)) as LiveSummary; //TODO: update to structuredClone
            this.setState({ summary: positions });
        }

        let blPositions: LiveSummary = undefined;
        if (this.state.baseline?.format("yyyy-MM-DD")) {
            blPositions = positionStoreInstance.getValuationsByMeta(this.state.baseline?.format("yyyy-MM-DD"), metaKeys, preventAutoLoad, this.state.showFxAsPosition);
            this.setState({ baselineSummary: blPositions });
            if (!blPositions)
                refreshComplete = false;
        }

        this.setState({ awaitingRefresh: !refreshComplete });

        await this.subscribeToLivePrices(positions);
    }

    async subscribeToLivePrices(positions: LiveSummary) {
        if (positions) {
            var insIds = positions.positions.filter(p => p.aggregatedPosition.openPosition !== 0).map(p => p.aggregatedPosition.instrumentId);
            var ulIndIds = insIds.map(i => {
                var ins = listedInstrumentStoreInstance.getInstrumentById(i);
                return ins?.type === "Option" ? ins.underlyingId : null;
            }).filter(i => i !== null);

            var ccys = _.uniq(positions.positions.map(p => p.unrealizedCcy));
            var ccyIns = ccys.map(c => marketDataLiveStoreInstance.getFxIns(c));

            var idSet = new Set([...insIds, ...ulIndIds, ...ccyIns]);

            await SubscribeToTicksByIdBulk(Array.from(idSet));
        }
    }

    render() {
        const { summary, baselineSummary, jobUpdateMessage, asOf, baseline, awaitingRefresh, showFxAsPosition } = this.state;

        return <PositionLiveGrid
            summary={summary}
            baselineSummary={baselineSummary}
            jobUpdateMessage={jobUpdateMessage}
            asOf={asOf}
            showFxAsPosition={showFxAsPosition}
            onChangeShowFxAsPosition={this.onChangeShowFxAsPosition}
            onChangeAsOfDate={this.onChangeDate}
            baseline={baseline}
            onChangeBaselineDate={this.onChangeBaselineDate}
            awaitingRefresh={awaitingRefresh}
            onClickRefresh={this.onClickRefresh}
        />
    }
}


