import { CancelOutlined, CloseOutlined, DeleteOutlined, DoneOutlineOutlined, WatchLaterOutlined } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, Grid, Typography, List, Divider, ListItemText, ListItemButton, IconButton, DialogActions, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import { GoodUntil, Order, OrderStatus, OrderType } from "./orderModels";
import ordersStoreInstance from "./orderStore";
import userStoreInstance from "../user/userStore";
import { getFormTheme } from "../inputs/formCommon";
import { Formik, FormikHelpers } from "formik";
import marketDataLiveStoreInstance from "../marketData/marketDataLiveStore";
import { EventSubscription } from "fbemitter";
import { OrderEditorEditModeValidationSchema, OrderEditorValidationSchema } from "./orderEditor";
import { LimitOrderTypes, OutrightOrderForm, StopOrderTypes } from "./outrightOrderForm";
import { v4 } from 'uuid';
import { AddOrUpdateOrder, ApproveOrDenyOrderQuestion, CancelOrder } from "./orderActions";
import { BookStr, StrategyStr } from "../globalConstants";
import matronSettingsStoreInstance from "../settings/matron/matronSettingsStore";
import { getUltimateParentFromIns } from "../positions/positionsCommon";

export interface LoadItemSpec {
    name: string;
    category: string;
    id: number;
}

export interface OrdersBoxProps {
    showBox: boolean;
    instrumentId: number;
    onClose: () => void;
    positionToClose?: number;
    book?: string;
    strategy?: string;
}

interface OrdersBoxState {
    selectedOrder: Order;
    activeOrder: Order;
    bid: number;
    ask: number;
    lastUpdate: number;
    cancelOrderId: number;
    orderIdInFlight: number;
    orderInFlight: Order;
    orderInFlightStatus: string;
    canDuplicate: boolean;
}

const updateThrottleMs = 250;

export class OrdersBox extends React.Component<OrdersBoxProps, OrdersBoxState>
{
    eventSubscriptionMarketDataTicks: EventSubscription
    eventSubscriptionOrders: EventSubscription

    constructor(props: OrdersBoxProps) {
        super(props)
        this.state = {
            selectedOrder: undefined,
            bid: undefined,
            ask: undefined,
            lastUpdate: (new Date()).valueOf(),
            cancelOrderId: undefined,
            orderIdInFlight: undefined,
            orderInFlightStatus: undefined,
            orderInFlight: undefined,
            canDuplicate: false,
            activeOrder: undefined
        }

        this.onTick = this.onTick.bind(this);
        this.onSubmitOrder = this.onSubmitOrder.bind(this);
        this.confimCancelOrder = this.confimCancelOrder.bind(this);
        this.onCancelOrder = this.onCancelOrder.bind(this);
        this.onOrderUpdate = this.onOrderUpdate.bind(this);
        this.getNominal = this.getNominal.bind(this);
        this.anyChangesToSave = this.anyChangesToSave.bind(this);
        this.onSaveOrderChanges = this.onSaveOrderChanges.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionMarketDataTicks = marketDataLiveStoreInstance.addChangeListener(this.onTick);
        this.eventSubscriptionOrders = ordersStoreInstance.addChangeListener(this.onOrderUpdate);

        if (this.props.positionToClose) {
            var oda = this.state.selectedOrder ?? {} as Order;
            oda.listedInstrumentId = this.props.instrumentId;
            oda.size = Math.abs(this.props.positionToClose);
            oda.timeInForce = GoodUntil.GoodForDay;
            oda.isBuyOrder = this.props.positionToClose < 0;
            oda.orderType = OrderType.Limit;

            this.setState({ selectedOrder: oda });
        }
    }

    componentWillUnmount(): void {
        if (this.eventSubscriptionMarketDataTicks) {
            this.eventSubscriptionMarketDataTicks.remove();
        }
        if (this.eventSubscriptionOrders) {
            this.eventSubscriptionOrders.remove();
        }
    }

    onOrderUpdate() {
        const { orderIdInFlight, selectedOrder } = this.state;
        if (orderIdInFlight) {
            var o = ordersStoreInstance.getOrders().find(x => x.orderId === orderIdInFlight);
            if (o) {
                this.setState({ orderInFlightStatus: OrderStatus[o.orderStatus], orderInFlight: o })
                if (["Waiting", "Filled", "PartiallyFilled", "Cancelled", "Rejected", "Active"].includes(OrderStatus[o.orderStatus])) {
                    this.setState({ canDuplicate: false, orderIdInFlight: undefined, orderInFlight: undefined, orderInFlightStatus: undefined });
                }
            }
        }
        if (selectedOrder) {
            var o2 = ordersStoreInstance.getOrders().find(x => x.orderId === selectedOrder.orderId);
            if (o2) {
                this.setState({ selectedOrder: o2 })
            }
        }
    }

    onTick() {
        const { bid, ask, lastUpdate } = this.state;
        const { instrumentId } = this.props;

        if ((new Date()).valueOf() - lastUpdate > updateThrottleMs && instrumentId) {
            var bidask = marketDataLiveStoreInstance.getBidAsk(instrumentId);
            if (bidask.bid !== bid)
                this.setState({ bid: bidask.bid, lastUpdate: new Date().valueOf() });
            if (bidask.ask !== ask)
                this.setState({ ask: bidask.ask, lastUpdate: new Date().valueOf() });
        }
    }

    async onSubmitOrder(values: Order, { setSubmitting }: FormikHelpers<Order>) {
        this.setState({ canDuplicate: true });
        var order = {
            orderReferenceId: v4(),
            created: values.created ? new Date(values.created) : new Date(),
            createdById: userStoreInstance.GetUserInfo().userId,
            externalSystemId: values.externalSystemId,
            externalSystem: values.externalSystem,
            limitLevel: LimitOrderTypes.includes(values.orderType) ? values.limitLevel : undefined,
            goodUntil: values.goodUntil,
            listedInstrumentId: values.listedInstrumentId,
            isBuyOrder: values.isBuyOrder,
            orderSource: values.orderSource,
            orderStatus: values.orderStatus ?? OrderStatus.Created,
            orderType: values.orderType,
            size: values.size,
            stopLevel: StopOrderTypes.includes(values.orderType) ? values.stopLevel : undefined,
            orderVenue: values.orderVenue,
            referencePrice: values.referencePrice,
            account: values.account,
            comments: values.comments,
            timeInForce: values.timeInForce,
        } as Order;

        const { book, strategy } = this.props;
        if (book || strategy) {
            var metaData: { [key: string]: string } = {};
            if (book)
                metaData[BookStr] = book;
            if (strategy)
                metaData[StrategyStr] = strategy;
            order.blobData = JSON.stringify({ metaData });
        }

        AddOrUpdateOrder(order).then(result => {
            var latest = ordersStoreInstance.getOrders().find(x => x.orderId === result.order.orderId);
            this.setState({
                orderIdInFlight: latest?.orderId ?? -1,
                orderInFlight: latest,
                orderInFlightStatus: result.errorMessage ?? latest?.orderStatusString
            })
            //setSubmitting(false);
            // if (this.props.onClose !== undefined)
            //     this.props.onClose();
        });
    }

    async confimCancelOrder(orderId: number) {
        const { selectedOrder } = this.state;
        if (selectedOrder && selectedOrder.orderId === orderId) {
            await CancelOrder(selectedOrder);
            this.setState({ cancelOrderId: undefined, selectedOrder: undefined });
        }
        else {
            this.setState({ cancelOrderId: undefined });
        }
    }

    onCancelOrder() {
        const { selectedOrder } = this.state;
        if (selectedOrder)
            this.setState({ cancelOrderId: selectedOrder.orderId });
    }

    async onSaveOrderChanges() {
        const { activeOrder } = this.state;
        if (activeOrder) {
            this.setState({ orderIdInFlight: activeOrder.orderId });
            await AddOrUpdateOrder(activeOrder);
        }
    }

    onOrderChange(o: Order) {
        var activeOrder = this.state.activeOrder;
        if (!activeOrder)
            activeOrder = { listedInstrumentId: this.props.instrumentId} as Order;
        activeOrder.limitLevel = o.limitLevel;
        activeOrder.stopLevel = o.stopLevel;
        activeOrder.isBuyOrder = o.isBuyOrder;
        activeOrder.size = o.size;
        activeOrder.orderType = o.orderType;
        this.setState({ activeOrder });
    }

    anyChangesToSave() {
        const { activeOrder, selectedOrder } = this.state;
        return (activeOrder && selectedOrder && (
            activeOrder.size !== selectedOrder.size ||
            activeOrder.limitLevel !== selectedOrder.limitLevel ||
            activeOrder.stopLevel !== selectedOrder.stopLevel ||
            activeOrder.isBuyOrder !== selectedOrder.isBuyOrder
        ))
    }

    async approveOrderQuestion(orderId: number) {
        const { orderInFlight } = this.state;
        if (orderInFlight && orderInFlight.orderId === orderId) {
            try {
                await ApproveOrDenyOrderQuestion(orderInFlight, true);
            }
            finally {
                this.setState({ orderInFlight: undefined });
            }
        }
        this.setState({ orderInFlight: undefined });
    }

    async denyOrderQuestion(orderId: number) {
        const { orderInFlight } = this.state;
        if (orderInFlight && orderInFlight.orderId === orderId) {
            try {
                await ApproveOrDenyOrderQuestion(orderInFlight, false);
            }
            finally {
                this.setState({ orderInFlight: undefined });
            }
        }
        this.setState({ orderInFlight: undefined });
    }

    getNominal() {
        const { activeOrder, bid, ask } = this.state;
        var nominal: number;
        if (activeOrder) {
            const ins = listedInstrumentStoreInstance.getInstrumentById(activeOrder.listedInstrumentId);
            if (LimitOrderTypes.includes(activeOrder.orderType)) {
                nominal = ins.multiplier * activeOrder.size * activeOrder.limitLevel;
            }
            else if (activeOrder.orderType === OrderType.Stop) {
                nominal = ins.multiplier * activeOrder.size * activeOrder.stopLevel;
            }
            else {
                nominal = ins.multiplier * activeOrder.size * (activeOrder.isBuyOrder ? ask : bid);
            }
        }

        return isNaN(nominal) || nominal === undefined ? undefined : nominal;
    }


    render() {
        const { selectedOrder, activeOrder, bid, ask, cancelOrderId, orderIdInFlight, orderInFlightStatus, orderInFlight, canDuplicate } = this.state;
        const { instrumentId, showBox, onClose, book, strategy } = this.props;
        const activeOrders = ordersStoreInstance.getOrdersForInstrument(instrumentId);
        const canTrade = userStoreInstance.UserHasRole("Trader");

        var col = userStoreInstance.GetTheme().color;
        var colContrast = userStoreInstance.GetTheme().contrast_border_color;

        var cancelOrderString = `Are you sure you want to cancel order ${cancelOrderId}?`

        const fixedMeta = book || strategy ? { book: book, strategy: strategy } : undefined;

        const ins = listedInstrumentStoreInstance.getInstrumentById(instrumentId);
        const parent = ins && getUltimateParentFromIns(ins);
        const areLimitsInPlace = parent && matronSettingsStoreInstance.areControlsInPlace(parent.listedInstrumentId);
        const warning = !areLimitsInPlace ? "No limits defined for instrument" : undefined;

        return (
            <React.Fragment>
                {/*Question answering dialog*/}
                {orderInFlight?.orderStatus === OrderStatus.PendingManualApproval && <ThemeProvider theme={getFormTheme()}><Dialog key="answerOrderApproval"
                    open={canTrade && orderInFlight?.orderStatus === OrderStatus.PendingManualApproval}
                    onClose={() => this.setState({ orderInFlight: undefined })}>
                    <DialogTitle>Approve Order {orderInFlight.orderId} ({listedInstrumentStoreInstance.getInstrumentById(orderInFlight.listedInstrumentId).description})</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">{orderInFlight.approvalMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ orderInFlight: undefined })} startIcon={<WatchLaterOutlined />}>Postpone</Button>
                        <Button onClick={async () => await this.denyOrderQuestion(orderInFlight.orderId)} startIcon={<CancelOutlined />}>Deny</Button>
                        <Button onClick={async () => await this.approveOrderQuestion(orderInFlight.orderId)} startIcon={<DoneOutlineOutlined />}>Confirm</Button>
                    </DialogActions>
                </Dialog></ThemeProvider>}
                {/*CancelAreYouSure dialog*/}
                <ThemeProvider theme={getFormTheme()}>
                    <Dialog key="cancelOrderAreYouSure"
                        open={canTrade && cancelOrderId !== undefined}
                        onClose={() => this.setState({ cancelOrderId: undefined })}>
                        <DialogTitle>Cancel Order</DialogTitle>
                        <DialogContent>
                            <Typography variant="h6">{cancelOrderString}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ cancelOrderId: undefined })} startIcon={<CancelOutlined />}>Abort</Button>
                            <Button onClick={async () => await this.confimCancelOrder(cancelOrderId)} startIcon={<DeleteOutlined />}>Proceed</Button>
                        </DialogActions>
                    </Dialog>
                </ThemeProvider>
                {/*Main dialog*/}
                <Dialog open={showBox} onClose={() => onClose()} fullWidth maxWidth="xl" sx={{ borderWidth: 2, borderColor: "text.primary", borderStyle: "solid" }} >
                    <DialogTitle key="orders-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque, color: col }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%" }}>
                            <div>{`${listedInstrumentStoreInstance.getInstrumentById(instrumentId)?.description} - Order View`}</div>
                            <div>{`Latest: ${bid?.toFixed(4) ?? "-"} / ${ask?.toFixed(4) ?? "-"}`}</div>
                            {activeOrder && <div>{`(Nominal: ${this.getNominal()?.toLocaleString(undefined, { maximumFractionDigits: 0 }) ?? "-"})`}</div>}
                        </div>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}>
                            <CloseOutlined />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque, borderTop: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, paddingTop: "0.5em", height: "85vh", overflowX: "hidden" }} >
                        <ThemeProvider theme={getFormTheme()}>
                            <Grid container direction="row" spacing={1} height="calc(100% - 10px)" marginTop="0.5em">
                                <Grid item container direction="column" width="30%" spacing={1} alignContent="center">
                                    <Grid item>
                                        <Typography textAlign="center" variant="subtitle1" color={col}>Active Orders</Typography>
                                    </Grid>
                                    <Grid item><Divider /></Grid>
                                    <Grid item width="90%">
                                        <List dense>
                                            {activeOrders.filter(o => o.orderVenue === "Pltfm").map((o, ix) => {
                                                var oText = `${o.isBuyOrder ? "Buy" : "Sell"} ${o.size} @ ${o.limitLevel}`;
                                                var oSecondary = `${OrderType[o.orderType]} - ${OrderStatus[o.orderStatus]}`;
                                                return (<ListItemButton style={{ color: col }} key={`oao-${ix}`} onClick={() => this.setState({ selectedOrder: o, activeOrder: JSON.parse(JSON.stringify(o)) })} selected={selectedOrder === o} divider classes={{ divider: "OrderListDivider" }}>
                                                    <ListItemText primary={oText} secondary={oSecondary} secondaryTypographyProps={{ color: colContrast, textAlign: "right", fontSize: "small" }} />
                                                </ListItemButton>)
                                            })}
                                        </List>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="column" width="69%" spacing={1} borderLeft="1px solid" borderColor={userStoreInstance.GetTheme().border_color}>
                                    <Grid item>
                                        <Typography variant="subtitle1" textAlign="center" color={col}>Edit/Create</Typography>
                                    </Grid>
                                    <Grid item><Divider /></Grid>
                                    <Grid container item direction="row" width="100%" spacing={1} justifyContent="space-around">
                                        <Formik initialValues={selectedOrder ?? { listedInstrumentId: instrumentId } as Order}
                                            onSubmit={canTrade ? this.onSubmitOrder : () => { }}
                                            canTrade={canTrade}
                                            validationSchema={selectedOrder ? OrderEditorEditModeValidationSchema : OrderEditorValidationSchema}
                                            enableReinitialize={true}>
                                            {props => <OutrightOrderForm
                                                fixedInsId={instrumentId}
                                                fixedMeta={fixedMeta}
                                                venuesLookup={ordersStoreInstance.getOrderVenuesReverse()}
                                                key="orFrmInner"
                                                className="NewTradeForm"
                                                canDuplicate={canDuplicate}
                                                onUpdateOrder={(o: Order) => { this.onOrderChange(o) }}
                                                onClearPressed={() => this.setState({ selectedOrder: undefined, activeOrder: { listedInstrumentId: instrumentId} as Order })}
                                                onCancelOrder={selectedOrder && selectedOrder.orderId ? this.onCancelOrder : undefined}
                                                onSaveOrder={selectedOrder && selectedOrder.orderId ? this.onSaveOrderChanges : undefined}
                                                anyChangesToSave={this.anyChangesToSave()}
                                                warning={warning}
                                                {...props}>
                                            </OutrightOrderForm>}
                                        </Formik>
                                    </Grid>
                                    {orderIdInFlight && <Grid container item direction="row" width="100%" spacing={1} justifyContent="center">
                                        <Grid item>
                                            <Typography variant="h6">Order Id {orderIdInFlight} - Status: {orderInFlightStatus}</Typography>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </DialogContent>
                </Dialog >
            </React.Fragment>
        );
    }
}


