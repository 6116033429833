import { IMsalContext } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { ApiLocation } from "../globalConstants";

interface IHttpResponse<T> extends Response {
    payload?: T;
}

export const http = async <T>(method: string, body: string | undefined, url: string, signal?: AbortSignal, isPltfmRequest: boolean = true): Promise<IHttpResponse<T>> => {
    let response: IHttpResponse<T> | undefined;
    var res = await httpGet(method,body, url, signal, isPltfmRequest);
    if(res.ok && res.status < 300){
        var payload = await res.json();
        response = res;
        response.payload = payload;
        return response;
    }
    return undefined;
};

async function httpGet(method: string, body: string | undefined, url: string, signal?: AbortSignal, isPltfmRequest: boolean = true) {
    let headers = await GetHeaders(isPltfmRequest);
    const request = new Request(url, { method: method, headers: headers, body: body } as RequestInit);
    let result = fetch(request, { signal: signal });
    return result;
}

export const get = async <T>(path: string, signal?: AbortSignal, absolutePath: Boolean = false): Promise<IHttpResponse<T>> => {
    path = absolutePath ? path : GetAPIUrl() + path;
    return http<T>("get", undefined, path, signal, !absolutePath);
};

export const post = async <T>(path: string, body: any, signal?: AbortSignal): Promise<IHttpResponse<T>> => {
    path = GetAPIUrl() + path;
    return await http<T>("post", JSON.stringify(body), path, signal);
};

export const put = async <T>(path: string, body: any, signal?: AbortSignal): Promise<IHttpResponse<T>> => {
    path = GetAPIUrl() + path;
    return await http<T>("put", JSON.stringify(body), path, signal);
};

async function GetHeaders(appendPltfm: boolean = true) {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    if(appendPltfm)
        headers.append("Authorization", "Bearer " + (await GetToken()));

    if (appendPltfm && userToken.userId) {
        headers.append("PltfmUser", userToken.userId.toString());
    }
    return headers;
}

type userTokenType = {
    userId: string | undefined;
    accessToken: string | undefined;
    isDevMode: boolean;
    context: IMsalContext | undefined;
}

const userToken = { userId: undefined, accessToken: undefined, isDevMode: true, context: undefined } as userTokenType;

export default userToken;

export async function GetToken() {
    if (userToken.context) {
        let loginToken = "";
        try {
            loginToken = (await userToken.context.instance.acquireTokenSilent({ ...loginRequest })).accessToken
        }
        catch
        {
            await userToken.context.instance.acquireTokenRedirect({ ...loginRequest });
            loginToken = (await userToken.context.instance.acquireTokenSilent({ ...loginRequest })).accessToken;
        }
        return loginToken;
    }
    return "";
}

export function GetAPIUrl() {
    return ApiLocation;
}