import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import positionStoreInstance from './positionSummaryStore';
import { ProducePositionAnalysis } from './positionSummaryActions';
import moment from 'moment';
import {
    Button,
    Grid,
    LinearProgress,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import { PositionAnalysisRecord } from './positionSummaryModels';
import { getPositionTableTheme } from './positionSummaryTable';
import userStoreInstance from '../user/userStore';
import { RefreshOutlined } from '@mui/icons-material';
import { getFormTheme } from '../inputs/formCommon';
import { momentUtcToOADate, renderDate } from '../utils/helpers';
import { GridCellParams, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, DataGridPro, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';


interface PositionAnalysisTableState {
    records: PositionAnalysisRecord[],
    mainRef: RefObject<HTMLDivElement>,
    awaitingRefresh: boolean,
}

export interface PositionAnalysisTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class PositionAnalysisTable extends React.Component<PositionAnalysisTableProps, PositionAnalysisTableState>{
    eventSubscriptionPositions: FBEmitter.EventSubscription | undefined;
    constructor(props: PositionAnalysisTableProps) {
        super(props)
        this.state = {
            records: [],
            mainRef: React.createRef(),
            awaitingRefresh: false,
        };;
        this.updateAnalysis = this.updateAnalysis.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionPositions = positionStoreInstance.addChangeListener(this.updateAnalysis);
        this.updateAnalysis();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionPositions) {
            this.eventSubscriptionPositions.remove();
            this.eventSubscriptionPositions = undefined;
        }
    }


    async onClickRefresh() {
        this.setState({ awaitingRefresh: true, records: [] });
        await ProducePositionAnalysis();
    }

    customToolbar(props: any) {
        const { awaitingRefresh } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "5px" }}>
                            <Grid container spacing={2} justifyContent="flex-start" alignContent="center">
                                <Grid item>
                                    <Button className="MuiButton-outlined" disabled={awaitingRefresh} startIcon={<RefreshOutlined />} onClick={() => this.onClickRefresh()}>Calculate</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end" alignContent="center">
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" csvOptions={{ fileName: "pltfmDayTradeAnalysis" }} />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarDensitySelector {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    updateAnalysis() {
        let records = positionStoreInstance.getPositionAnalysis();
        this.setState({ records, awaitingRefresh: false });
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString()}</div>
        }
        else
            return <div></div>;
    }

    renderPercentCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toFixed(2)}</div>
        }
        else
            return <div></div>;
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    renderDateTimeCell(params: GridCellParams) {
        return <span>{renderDate(new Date(params.value as string))}</span>;
    }

    getCellRenderer(key: string) {
        switch (key) {
            case "positionKey":
            case "attribution":
            case "asset":
            case "positionMakeUp":
                return null;
            case "openingDate":
            case "closingDate":
                return this.renderDateTimeCell;
            case "propDaysAtMaxDelta":
                return this.renderPercentCell;
            default:
                return this.renderNumberCell;
        }
    }

    getCellType(key: string) {
        switch (key) {
            case "positionKey":
            case "attribution":
            case "asset":
            case "positionMakeUp":
                return "string";
            case "openingDate":
            case "closingDate":
                return "date";
            default:
                return "number";
        }
    }

    getValueFormatter(key: string) {
        switch (key) {
            case "openingDate":
            case "closingDate":
                return (({ value }) => momentUtcToOADate(moment(value)));
            default:
                return undefined;
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        const { records, mainRef, awaitingRefresh } = this.state;

        var obj = new PositionAnalysisRecord();
        const columns: GridColDef[] = Array.from(Object.keys(obj)).map(k => {
            return {
                field: k,
                width: 200,
                headerName: this.capitalizeFirstLetter(k),
                cellClassName: "PositionSummaryTabTableCell",
                headerClassName: "PositionSummaryTabTableCellHeader",
                renderCell: this.getCellRenderer(k),
                valueFormatter: this.getValueFormatter(k),
                type: this.getCellType(k)
            }
        });

        const rows = records.map((r, ix) => {
            var row = { id: ix };
            Array.from(Object.keys(r)).forEach(k => {
                row[k] = r[k];
            });
            return row;
        }).filter(r => r !== null);

        return (
            <div className="PositionSummaryTab" ref={mainRef}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getPositionTableTheme()}>
                        <DataGridPro
                            className="PositionSummaryTabTable"
                            rows={rows}
                            columns={columns}
                            components={{
                                Toolbar: this.customToolbar,
                                LoadingOverlay: this.loading,
                            }}
                            loading={awaitingRefresh}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}
