import { ConnectionStatus } from '../connections/connectionStatusModel';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { ConnectionStats, GeneralStatsObject, LongRunningTaskUpdate, UserActivityEvent } from './pingModels';
import userSessionConnection from './userSessionConnection';

export class NewConnectionStatsEvent extends TypedEvent<ConnectionStats> { }
export class ConnectionStatusChangeEvent extends TypedEvent<ConnectionStatus> { }
export class NewUserActivityEvent extends TypedEvent<UserActivityEvent> { }
export class NewUserActivityBatchEvent extends TypedEvent<UserActivityEvent[]> { }
export class GeneralStatsUpdatedEvent extends TypedEvent<GeneralStatsObject> { }
export class NewMessageEvent extends TypedEvent<string> { }
export class NewTaskUpdateEvent extends TypedEvent<LongRunningTaskUpdate> { }


export function NewConnectionStats(stats: ConnectionStats) {
    AppDispatcher.dispatch(new NewConnectionStatsEvent(stats))
}
export function ConnectionStatusChange(status: ConnectionStatus) {
    AppDispatcher.dispatch(new ConnectionStatusChangeEvent(status))
}
export function NewUserActivity(event: UserActivityEvent) {
    AppDispatcher.dispatch(new NewUserActivityEvent(event))
}
export function NewUserActivityBatch(events: UserActivityEvent[]) {
    AppDispatcher.dispatch(new NewUserActivityBatchEvent(events))
}
export function GeneralStatsUpdated(stats: GeneralStatsObject) {
    AppDispatcher.dispatch(new GeneralStatsUpdatedEvent(stats))
}
export function NewMessage(message: string) {
    AppDispatcher.dispatch(new NewMessageEvent(message))
}
export function NewTaskUpdate(update: LongRunningTaskUpdate) {
    AppDispatcher.dispatch(new NewTaskUpdateEvent(update))
}

export async function SubcribeToNewUserActivity() {
    await userSessionConnection.subscribeToUserEvents();
}
export async function UnSubcribeFromNewUserActivity() {
    await userSessionConnection.unSubscribeFromUserEvents();
}
export async function RefreshEvents() {
    await userSessionConnection.getUserEvents();
}
export async function SubscribeToStats(type: string) {
    await userSessionConnection.subscribeToStats(type);
}
export async function UnSubscribeFromStats(type: string) {
    await userSessionConnection.unSubscribeToStats(type);
}
export async function SubscribeToJobUpdates(key: string) {
    await userSessionConnection.subscribeToJobUpdates(key);
}
export async function UnSubscribeFromJobUpdates(key: string) {
    await userSessionConnection.unSubscribeFromJobUpdates(key);
}
