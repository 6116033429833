import { BasketDefinition } from "../../qwack/basketDefinition";
import { BarrierDetail } from "../barrierDetail";
import { DateFixedRelative} from "./dateFixedRelative"
import { WrappedPayoff } from "./wrappedPayoff";

export interface Autocall extends WrappedPayoff {
    startDate: DateFixedRelative
    endDate: DateFixedRelative
    callDates: DateFixedRelative[];
    coupons: number[];
    callTriggerIndex: BasketDefinition;
    hasBarrierFeature: boolean;
    barrierTriggerIndex: BasketDefinition;
    barrierDetail: BarrierDetail;
    finalPayoffType: AutoCallFinalPayoff;
}

export enum AutoCallFinalPayoff { OnlyCoupon, UncappedUpside }