import React, { Component, RefObject } from 'react';
import FBEmitter from 'fbemitter';
import { v4 } from 'uuid';
import { GeneralStatsObject } from '../../userSession/pingModels';
import generalStatsStoreInstance from '../../userSession/generalStatsStore';
import { UnSubscribeFromStats, SubscribeToStats } from '../../userSession/userSessionActions';

export type WebCalendarRequest = {
    calendarNames: string[],
    startDate: Date,
    endDate: Date,
}


export interface TradeStatsProps {
    onClose?: (() => void)
    onChangeHeight?: (newHeight: number) => void
}

interface TradeStatsState {
    uniqueKey: string
    hover: boolean;
    stats: GeneralStatsObject,
    mainRef: RefObject<HTMLDivElement>,
    height: number,
}

class TradeStats extends Component<TradeStatsProps, TradeStatsState>{
    eventSubscriptionStats: FBEmitter.EventSubscription | undefined;
    constructor(props: TradeStatsProps) {
        super(props);
        this.state = {
            uniqueKey: v4(),
            hover: false,
            stats: {} as GeneralStatsObject,
            mainRef: React.createRef(),
            height: 150,
        }
        this.onStatsUpdate = this.onStatsUpdate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionStats = generalStatsStoreInstance.addChangeListener(() => this.onStatsUpdate());
        await SubscribeToStats("Trade");
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionStats) {
            this.eventSubscriptionStats.remove();
            this.eventSubscriptionStats = undefined;
        }
        await UnSubscribeFromStats("Trade");
    }

    private onStatsUpdate() {
        var stats = generalStatsStoreInstance.GetStats("Trade");
        this.setState({ stats });
    }

    private renderStatsTable = () => {
        const { stats } = this.state;
        if (stats && stats.stats) {
            return <table style={{borderCollapse:"collapse", width:"90%"}}>
                    <thead>
                        <tr>
                            <th className="TradeStatsHeaderCell" align="center">Period</th>
                            <th className="TradeStatsHeaderCell" align="center"># Trades</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="TradeStatsCell" align="center" style={{border:"none"}}>Today</td>
                            <td className="TradeStatsCell" align="center" style={{border:"none"}}>{stats.stats["Today"]}</td>
                        </tr>
                        <tr>
                            <td className="TradeStatsCell" align="center" style={{border:"none"}}>Week</td>
                            <td className="TradeStatsCell" align="center" style={{border:"none"}}>{stats.stats["Week"]}</td>
                        </tr>
                        <tr>
                            <td className="TradeStatsCell" align="center" style={{border:"none"}}>Total</td>
                            <td className="TradeStatsCell" align="center" style={{border:"none"}}>{stats.stats["Total"]}</td>
                        </tr>
                    </tbody>
                </table>
        }
        else {
            return <div>...Loading...</div>
        }
    }
    render() {
        return (
            <div ref={this.state.mainRef} className="TradeStats">
                {this.renderStatsTable()}
            </div>
        );
    }
}
export default TradeStats;