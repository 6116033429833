import { AppDispatcher, TypedEvent } from "../dispatcher/appDispatcher"
import { UpdateLoadingStatus } from "../loading/loadingActions";
import { LoadingStatus } from "../loading/loadingModels";
import { get, post } from "../utils/httpUtils";
import { ScheduleLog, ScheduleSummary } from "./schedulesModels"

export class SchedulesLoadedEvent extends TypedEvent<ScheduleSummary[]> {}
export class ScheduleUpdateEvent extends TypedEvent<ScheduleSummary> {}
export class ScheduleLogLoadedEvent extends TypedEvent<ScheduleLog[]> {}


export async function GetSchedules() {
    const schedules = await get<ScheduleSummary[]>("Schedule");
    if(schedules.payload != null){
        AppDispatcher.dispatch(new SchedulesLoadedEvent(schedules.payload));
    }

    UpdateLoadingStatus("Schedules", LoadingStatus.Completed);
}

export function UpdateSchedules(schedules: ScheduleSummary[]){
    AppDispatcher.dispatch(new SchedulesLoadedEvent(schedules));
}

export async function AddOrUpdateSchedule(item: ScheduleSummary) {
    var response = await post<ScheduleSummary>("Schedule/addOrUpdate", item);
    if (response?.payload != null) {
        AppDispatcher.dispatch(new ScheduleUpdateEvent(response.payload));
        return response.payload;
    }
}

export async function GetScheduleLogs(scheduleId: number) {
    var response = await get<ScheduleLog[]>(`Schedule/${scheduleId}/history`);
    if (response?.payload != null) {
        return response.payload;
    }
}

export async function UpdateScheduleLogs(scheduleId: number) {
    var response = await GetScheduleLogs(scheduleId)
    if (response != null) {
        AppDispatcher.dispatch(new ScheduleLogLoadedEvent(response));
    }
}