import { CloseOutlined } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, Grid, IconButton, Typography, TextField, Button, DialogContentText, DialogActions } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import userStoreInstance from "../user/userStore";
import { getFormTheme } from "../inputs/formCommon";
import marketDataLiveStoreInstance from "../marketData/marketDataLiveStore";
import { EventSubscription } from "fbemitter";
import { LivePosition, SlTpRecord } from "./positionSummaryModels";
import positionStoreInstance from "./positionSummaryStore";
import { getPositionKey } from "./positionsCommon";
import { UpdatePosition, UpdateStops } from "./positionSummaryActions";
import { num2String } from "../utils/helpers";
import { BookStr, StrategyStr } from "../globalConstants";

export interface SlTpBoxProps {
    showBox: boolean;
    positionKey: string;
    onClose: () => void;
}

interface SlTpBoxState {
    position: LivePosition;
    sl: string;
    tp: string;
    bid: number;
    ask: number;
    lastUpdate: number;
    lastActionResult: string;
    showAreYouSure: boolean;
}

const updateThrottleMs = 250;

export class SlTpBox extends React.Component<SlTpBoxProps, SlTpBoxState>
{
    eventSubscriptionMarketDataTicks: EventSubscription
    constructor(props: SlTpBoxProps) {
        super(props)
        this.state = {
            sl: undefined,
            tp: undefined,
            bid: undefined,
            ask: undefined,
            position: undefined,
            lastActionResult: undefined,
            lastUpdate: (new Date()).valueOf(),
            showAreYouSure: false
        }

        this.onTick = this.onTick.bind(this);
        this.canUpdate = this.canUpdate.bind(this);
        this.onClickUpdate = this.onClickUpdate.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionMarketDataTicks = marketDataLiveStoreInstance.addChangeListener(this.onTick);

        var positions = positionStoreInstance.getValuationsByMeta(undefined, [BookStr,StrategyStr], false);
        var position = positions.positions.filter(p => getPositionKey(p) === this.props.positionKey)[0];

        this.setState({ position, sl: position?.stops?.stopLevel?.toString(), tp: position?.stops?.tpLevel?.toString(), bid: position?.livePrice, ask: position?.livePrice });
    }

    componentWillUnmount(): void {
        if (this.eventSubscriptionMarketDataTicks) {
            this.eventSubscriptionMarketDataTicks.remove();
        }
    }

    onTick() {
        const { bid, ask, lastUpdate, position } = this.state;

        if ((new Date()).valueOf() - lastUpdate > updateThrottleMs && position?.aggregatedPosition?.instrumentId) {
            var bidask = marketDataLiveStoreInstance.getBidAsk(position.aggregatedPosition.instrumentId);
            if (bidask.bid !== undefined && bidask.bid !== bid)
                this.setState({ bid: bidask.bid, lastUpdate: new Date().valueOf() });
            if (bidask.ask !== undefined && bidask.ask !== ask)
                this.setState({ ask: bidask.ask, lastUpdate: new Date().valueOf() });
        }
    }

    canUpdate() {
        const { sl, tp, position } = this.state;

        var noChange = sl === position?.stops?.stopLevel?.toString() && tp === position?.stops?.tpLevel?.toString();
        var invalidNumber = isNaN(Number(sl)) || isNaN(Number(tp))
        return noChange || invalidNumber;
    }

    async onClickUpdate() {
        const { sl, tp, position } = this.state;
        if (position) {
            var record = (position.stops) ? JSON.parse(JSON.stringify(position.stops)) as SlTpRecord : {} as SlTpRecord;
            record.stopLevel = Number(sl);
            record.tpLevel = Number(tp);
            record.deleted = false;
            record.listedInstrumentId = position.aggregatedPosition.instrumentId;

            var result = await UpdateStops(record);
            if (result) {
                if (!(position.stops))
                    position.stops = record;
                position.stops.stopLevel = record.stopLevel;
                position.stops.tpLevel = record.tpLevel;
                this.setState({ lastActionResult: "SL/TP updated successfully" });
                UpdatePosition(position);
            }
            else {
                this.setState({ lastActionResult: "SL/TP update FAILED" });
            }

        }
    }

    async onClickDelete() {
        const { sl, tp, position } = this.state;
        if (position?.stops) {
            var record = JSON.parse(JSON.stringify(position.stops)) as SlTpRecord;
            record.stopLevel = Number(sl);
            record.tpLevel = Number(tp);
            record.deleted = true;
            record.listedInstrumentId = position.aggregatedPosition.instrumentId;

            var result = await UpdateStops(record);
            if (result) {
                position.stops = undefined;
                this.setState({ lastActionResult: "SL/TP deleted successfully", showAreYouSure: false });
                UpdatePosition(position);
            }
            else {
                this.setState({ lastActionResult: "SL/TP delete FAILED", showAreYouSure: false });
            }
        }
    }

    renderDeleteConfirm() {
        const { showAreYouSure } = this.state;
        return (
            <Dialog open={showAreYouSure} onClose={() => this.setState({ showAreYouSure: false })}  >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Are you sure?</DialogTitle>
                <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the stop record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                    <Button variant="outlined" onClick={() => this.onClickDelete()}>Delete</Button>
                    <Button variant="outlined" onClick={() => this.setState({ showAreYouSure: false })} autoFocus>Cancel</Button>
                </DialogActions>
            </Dialog >);
    }

    render() {
        const { sl, tp, bid, ask, position, lastActionResult } = this.state;
        const { showBox, onClose } = this.props;
        const ins = listedInstrumentStoreInstance.getInstrumentById(position?.aggregatedPosition?.instrumentId);
        const mid = (bid + ask) / 2.0;
        const sign = position?.aggregatedPosition?.openPosition ? Math.sign(position.aggregatedPosition.openPosition) : 0;
        const col = userStoreInstance.GetTheme().color;
        //var colContrast = userStoreInstance.GetTheme().contrast_border_color;
        return (<React.Fragment>
            {this.renderDeleteConfirm()}
            <Dialog open={showBox} onClose={() => onClose()} fullWidth maxWidth="md" sx={{ borderWidth: 2, borderColor: "text.primary", borderStyle: "solid" }} >
                <DialogTitle key="slTp-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque, color: col }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%" }}>
                        <div>{`${ins?.description}`}</div>
                        <div>{`Latest: ${num2String(bid) ?? "-"} / ${num2String(ask) ?? "-"}`}</div>
                    </div>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque, borderTop: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, paddingTop: "0.5em" }} >
                    <ThemeProvider theme={getFormTheme()}>
                        <div style={{ display: "flex" }}>
                            <Grid container direction="column" justifyContent="space-around" height="40vh" marginTop="0.5em">
                                <Grid item container direction="row" spacing={2}>
                                    <Grid item>
                                        <Typography textAlign="center" height="2em" variant="subtitle1" color={col} width="120px">Open Position</Typography>
                                    </Grid>
                                    <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "120px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="stopCntcts"
                                        name="stopCntcts"
                                        label="Contracts"
                                        value={`${(position?.aggregatedPosition?.openPosition)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>
                                    <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "120px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="stopEntryAvg"
                                        name="stopEntryAvg"
                                        label="Entry Price"
                                        value={`${num2String(position?.aggregatedPosition.averagePrice)}`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>
                                </Grid>
                                <Grid item container direction="row" spacing={2}>
                                    <Grid item>
                                        <Typography textAlign="center" height="2em" variant="subtitle1" color={col} width="120px">Stop Loss</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            style={{ width: "120px", color: col }}
                                            classes={{ root: "ListedInstrumentEditorFormField" }}
                                            id="stopLevelX"
                                            name="stopLevelX"
                                            size="small"
                                            label="Stop Level"
                                            value={sl ?? ""}
                                            onChange={(e) => this.setState({ sl: e.target.value })}
                                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, style: { color: col } }}
                                        />
                                    </Grid>
                                    {isNaN(Number(sl)) || !Boolean(mid) ? null : <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "100px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="stopPctX"
                                        name="stopPctX"
                                        label="Stop (%Live)"
                                        value={`${(((Number(sl) / mid) - 1) * 100 * sign).toLocaleString(undefined, { maximumFractionDigits: 2 })} %`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>}
                                    {isNaN(Number(sl)) || !Boolean(mid) ? null : <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "140px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="stopUSDX"
                                        name="stopUSDX"
                                        label="Stop Amount (Live)"
                                        value={`${(((Number(sl) - mid) * position.aggregatedPosition.openPosition * ins?.multiplier)).toLocaleString(undefined, { maximumFractionDigits: 2 })} ${ins?.ccy}`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>}
                                    {isNaN(Number(sl)) ? null : <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "100px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="stopPctX"
                                        name="stopPctX"
                                        label="Stop (%Entry)"
                                        value={`${(((Number(sl) / position?.aggregatedPosition.averagePrice) - 1) * sign * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })} %`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>}
                                </Grid>
                                <Grid item container direction="row" spacing={2}>
                                    <Grid item>
                                        <Typography textAlign="center" height="2em" variant="subtitle1" color={col} width="120px">Take Profit</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            style={{ width: "120px", color: col }}
                                            classes={{ root: "ListedInstrumentEditorFormField" }}
                                            id="tpLevelX"
                                            name="tpLevelX"
                                            size="small"
                                            label="TP Level"
                                            value={tp ?? ""}
                                            onChange={(e) => this.setState({ tp: e.target.value })}
                                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, style: { color: col } }}
                                        />
                                    </Grid>
                                    {isNaN(Number(tp)) || !Boolean(mid) ? null : <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "100px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="tpPctX"
                                        name="tpPctX"
                                        label="TP (%Live)"
                                        value={`${(((Number(tp) / mid) - 1) * sign * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })} %`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>}
                                    {isNaN(Number(tp)) || !Boolean(mid) ? null : <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "140px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="toUSDX"
                                        name="tppUSDX"
                                        label="TP Amount (Live)"
                                        value={`${(((Number(tp) - mid) * position.aggregatedPosition.openPosition * ins?.multiplier)).toLocaleString(undefined, { maximumFractionDigits: 2 })} ${ins?.ccy}`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>}
                                    {isNaN(Number(tp)) ? null : <Grid item><TextField
                                        variant="standard"
                                        size="small"
                                        style={{ width: "100px" }}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="tpPctX"
                                        name="tpPctX"
                                        label="TP (%Live)"
                                        value={`${(((Number(tp) / position?.aggregatedPosition.averagePrice) - 1) * sign * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })} %`}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }}
                                    /></Grid>}
                                </Grid>
                                <Grid item container direction="row" justifyContent="center" spacing={2}>
                                    <Grid item>
                                        <Button className="MuiButton-outlined PltfmButtonLite" onClick={this.onClickUpdate} disabled={this.canUpdate()}>Update</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className="MuiButton-outlined PltfmButtonLite" onClick={() => onClose()}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className="MuiButton-outlined PltfmButtonLite" onClick={() => this.setState({ showAreYouSure: true })}>Delete</Button>
                                    </Grid>
                                </Grid>
                                {lastActionResult ?
                                    <Grid item container direction="row" justifyContent="center" spacing={2}>
                                        <Grid item >
                                            <Typography variant="body1">{lastActionResult}</Typography>
                                        </Grid>
                                    </Grid>
                                    : null}
                            </Grid>
                        </div>
                    </ThemeProvider>
                </DialogContent>
            </Dialog >
        </React.Fragment>
        );
    }
}

