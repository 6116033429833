import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import FBEmitter from 'fbemitter';
import React from 'react';
import userStoreInstance from '../user/userStore';
import { ClientEditorForm } from './clientEditorForm';
import { Firm } from '../user/userModel';
import { getFormTheme } from '../inputs/formCommon';

interface ClientEditorTabState {
    selectedFirms: Firm[],
    matchingFirms: Firm[],
    allFirms: Firm[],
    checked: number[];
    checkAll: boolean
}

export class ClientEditorTab extends React.Component<{}, ClientEditorTabState>{
    eventSubscriptionFirms: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        this.state = {
            allFirms: [],
            selectedFirms: [],
            matchingFirms: [],
            checked: [],
            checkAll: false
        };;

        this.filterInsOptions = this.filterInsOptions.bind(this);
        this.firmMatchesQuery = this.firmMatchesQuery.bind(this);
        this.onFirmSelect = this.onFirmSelect.bind(this);
        this.onChangeSearchString = this.onChangeSearchString.bind(this);
        this.handleCheckAllToggle = this.handleCheckAllToggle.bind(this);
        this.updateFirms = this.updateFirms.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionFirms = userStoreInstance.addChangeListener(this.updateFirms);
        let allFirms = userStoreInstance.GetClientFirms();
        this.setState({ allFirms, selectedFirms: [allFirms[0]] });
    }

    onSubmit(values: Firm, { setSubmitting }: FormikHelpers<Firm>) {
        console.log(JSON.stringify(values, null, 2));
        //UpdateListedInstrument(values);
        setSubmitting(false);
    }

    updateFirms(){
        let allFirms = userStoreInstance.GetClientFirms();
        this.setState({ allFirms });
    }

    private filterInsOptions(options: Firm[], state: any): Firm[] {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.firmMatchesQuery(o, query))
        return relevant;
    }

    private firmMatchesQuery(firm: Firm, query: string): boolean {
        const normalizedTitle = firm.displayName.toLowerCase();
        const code = firm.shortName.toLowerCase();
        const name = firm.name.toLowerCase();
        const allMetaData = firm.metaData ? firm.metaData.map(fm => fm.data.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${firm.firmId}. ${normalizedTitle}. ${name} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${firm.firmId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    private onFirmSelect(ins: Firm | string) {
        var solidFirm= ins as Firm;
        if (solidFirm) {
            this.setState({ selectedFirms: [solidFirm] });
        }
    }

    private onChangeSearchString(e: React.ChangeEvent<HTMLInputElement>) {
        const { allFirms } = this.state;
        var str = e.target.value;
        var matches = new Array<Firm>();
        allFirms.forEach(firm => {
            if (this.firmMatchesQuery(firm, str))
                matches.push(firm);
        });

        this.setState({ matchingFirms: matches });
    }

    private handleToggle = (value: number) => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checked: newChecked });
    };

    private handleCheckAllToggle(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        const { matchingFirms } = this.state;
        var idsChecked = new Array<number>();
        if (checked) { //moving to all checked
            idsChecked = matchingFirms.map(i => i.firmId);
        }
        this.setState({ checkAll: checked, checked: idsChecked });
    }

    render() {
        const { matchingFirms, checked, checkAll } = this.state;
        const selectedFirms = matchingFirms.filter(i=>checked.includes(i.firmId));
        
        return (
            <div className="ListedInstrumentEditorTab">
                <div className="ListedInstrumentEditorTabSearch">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <div className="ListedInstrumentEditorTabSearchTop">
                                <FormControl style={{ width: "80%", margin:"5px" }}>
                                    <InputLabel variant="outlined">Search</InputLabel>
                                    <OutlinedInput label="Search" classes={{ root: "ListedInstrumentEditorFormFieldInner" }} onChange={this.onChangeSearchString} />
                                </FormControl>
                                <FormControl style={{ width: "20%" }}>
                                    <FormControlLabel
                                        value="All?"
                                        label="All?"
                                        labelPlacement="top"
                                        control={<Checkbox checked={checkAll} onChange={this.handleCheckAllToggle} style={{color:userStoreInstance.GetTheme().border_color}}/>} />
                                </FormControl>
                            </div>
                            <List key="ListedInstrumentEditorTabSearch">
                                {matchingFirms.slice(0, 50).map(s => {
                                    const labelId = `checkbox-list-label-${s.displayName}`;
                                    return (
                                        <ListItem key={s.firmId} role={undefined} dense button onClick={this.handleToggle(s.firmId)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(s.firmId) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    style={{color:userStoreInstance.GetTheme().border_color}}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={s.displayName} />
                                        </ListItem>);
                                })}
                            </List>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
                <div className="ListedInstrumentEditorTabDisplay">
                    {selectedFirms.length > 0 ?
                        <Paper elevation={1} >
                            <Formik
                                onSubmit={this.onSubmit}
                                enableReinitialize={true}
                                initialValues={selectedFirms[0]}
                                validate={() => ({})}>
                                {props => <ClientEditorForm className="ListedInstrumentEditorTabForm" {...props} />}
                            </Formik>
                        </Paper> : null}
                </div>
            </div>
        );
    }
}
