import React from 'react';
import FBEmitter from 'fbemitter';
import { Button, MenuItem, Paper, SelectChangeEvent } from '@mui/material';
import userStoreInstance from '../user/userStore';
import { DashboardOutlined, MapOutlined, PeopleAltOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { UserInfo } from '../user/userModel';
import { UserForm } from './userForm';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik';
import { WrappedSelect } from '../inputs/wrappedSelect';

type AdminConsoleTabState = {
    currentMode: AdminConsoleMode,
    selectedUser: UserInfo | undefined
}

enum AdminConsoleMode {
    None,
    CreateUser,
    CreateSquad,
    ActivityConsole,
    MapActions
}

const NullUser = "Select user..."

class AdminConsoleTab extends React.Component<{}, AdminConsoleTabState> {
    eventSubscriptionCredit: FBEmitter.EventSubscription | undefined;

    constructor(props: any) {
        super(props)
        this.state = {
            currentMode: AdminConsoleMode.None,
            selectedUser: undefined
        };
        this.onClickCreateUser = this.onClickCreateUser.bind(this);
        this.onClickCreateSquad = this.onClickCreateSquad.bind(this);
        this.onClickActivtyConsole = this.onClickActivtyConsole.bind(this);
        this.onClickMapActions = this.onClickMapActions.bind(this);
    }

    onClickCreateUser() {
        this.setState({ currentMode: AdminConsoleMode.CreateUser });
    }
    onClickCreateSquad() {
        this.setState({ currentMode: AdminConsoleMode.CreateSquad });
    }
    onClickActivtyConsole() {
        this.setState({ currentMode: AdminConsoleMode.ActivityConsole });
    }
    onClickMapActions() {
        this.setState({ currentMode: AdminConsoleMode.MapActions });
    }


    public async componentDidMount() {

    }

    public componentWillUnmount() {

    }

    onSubmitUser(values: UserInfo, { setSubmitting }: FormikHelpers<UserInfo>) {
        console.log(JSON.stringify(values, null, 2));
        //UpdateListedInstrument(values);
        setSubmitting(false);
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case AdminConsoleMode.None:
                return (<div><p>Please select from the menu on the left</p></div>);
            case AdminConsoleMode.CreateUser:
                return (<div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: "0.5em" }}>
                        <WrappedSelect
                            style={{ minWidth: "200px" }}
                            id="user"
                            name="user"
                            label="User"
                            value={this.state.selectedUser ? this.state.selectedUser.name : NullUser}
                            onChange={(event: SelectChangeEvent<any>, child: React.ReactNode) =>
                                this.setState({ selectedUser: userStoreInstance.GetUsers().filter(u => u.userInfo && u.userInfo.name === (event.target.value as string))[0]?.userInfo })}>
                            {[NullUser, ...userStoreInstance.GetUsers().filter(u => u.userInfo).map(u => u.userInfo.name)].map(a =>
                                <MenuItem key={"usr" + a} value={a}>{a}</MenuItem>)}
                        </WrappedSelect>
                    </div>
                    <Paper elevation={1} >
                        <Formik
                            onSubmit={this.onSubmitUser}
                            enableReinitialize={true}
                            initialValues={this.state.selectedUser ?? {} as UserInfo}
                            validate={() => ({})}>
                            {props => <UserForm className="UserEditorTabForm"{...props} />}
                        </Formik>
                    </Paper>
                </div>);

            case AdminConsoleMode.CreateSquad:
                return (<div><p>{this.state.currentMode.toString()}</p></div>);
            case AdminConsoleMode.ActivityConsole:
                return (<div><p>{this.state.currentMode.toString()}</p></div>);
            case AdminConsoleMode.MapActions:
                return (<div><p>{this.state.currentMode.toString()}</p></div>);
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: AdminConsoleMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: AdminConsoleMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    render() {
        return (<div style={{ height: "90vh", position: "absolute" }}><table style={{ height: "100%" }}>
            <thead></thead>
            <tbody>
                <tr>
                    <td style={{ borderRight: "2px solid darkgrey", verticalAlign: "top", paddingRight: "5px", height: "100%", width:"200px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <div style={{ height: "20px" }} />
                            {userStoreInstance.actionSwitchMulti(["CreateUserOwnFirm", "CreateUserGlobal"],
                                <Button
                                    className={this.getButtonClass(AdminConsoleMode.CreateUser)}
                                    variant={this.getButtonVariant(AdminConsoleMode.CreateUser)}
                                    startIcon={<VerifiedUserOutlined />}
                                    onClick={this.onClickCreateUser}>Edit User</Button>, <div></div>)}

                            <div style={{ height: "20px" }} />
                            {userStoreInstance.actionSwitchMulti(["CreateSquadOwnFirm", "CreateSquadGlobal"],
                                <Button
                                    className={this.getButtonClass(AdminConsoleMode.CreateSquad)}
                                    variant={this.getButtonVariant(AdminConsoleMode.CreateSquad)}
                                    startIcon={<PeopleAltOutlined />}
                                    onClick={this.onClickCreateSquad}>Edit Squad</Button>, <div></div>)}

                            <div style={{ height: "20px" }} />
                            {userStoreInstance.actionSwitchMulti(["ViewActivityGlobal", "ViewActivityOwnFirm"],
                                <Button
                                    className={this.getButtonClass(AdminConsoleMode.ActivityConsole)}
                                    variant={this.getButtonVariant(AdminConsoleMode.ActivityConsole)}
                                    startIcon={<DashboardOutlined />}
                                    onClick={this.onClickActivtyConsole}>Activity Console</Button>, <div></div>)}

                            <div style={{ height: "20px" }} />
                            {userStoreInstance.actionSwitchMulti(["MapActionsGlobal"],
                                <Button
                                    className={this.getButtonClass(AdminConsoleMode.MapActions)}
                                    variant={this.getButtonVariant(AdminConsoleMode.MapActions)}
                                    startIcon={<MapOutlined />}
                                    onClick={this.onClickMapActions}>Map Actions</Button>, <div></div>)}
                        </div>
                    </td>
                    <td style={{ verticalAlign: "top", paddingLeft: "5px", width:"calc(100vw - 205px)" }}>
                        {this.renderContent()}
                    </td>
                </tr>
            </tbody>
        </table>
        </div>);

    }
}



export default AdminConsoleTab;