import { Button, Typography } from '@mui/material';
import { CurrencyExchangeOutlined, SettingsAccessibilityOutlined, SpeedOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import layoutStoreInstance from '../layout/layoutStore';
import { AcceleratorSettings } from './acceleratorSettings';
import { UserSettings } from './userSettings';
import { MatronSettings } from './matron/matronSettings';

interface SettingsTabState {
    currentMode: SettingsTabMode,
    collapsed: boolean
}

enum SettingsTabMode {
    None,
    UserPreferences,
    Accelerator,
    NAV,
    Trading
}

export interface SettingsTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class SettingsTab extends React.Component<SettingsTabProps, SettingsTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: SettingsTabProps) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: SettingsTabMode = tabModeStr ? SettingsTabMode[tabModeStr] : SettingsTabMode.None;
        this.state = {
            currentMode: tabMode,
            collapsed: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case SettingsTabMode.Accelerator:
                return <AcceleratorSettings />
            case SettingsTabMode.UserPreferences:
                return <UserSettings />
            case SettingsTabMode.Trading:
                return <MatronSettings />
            case SettingsTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: SettingsTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: SettingsTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }


    onClick(mode: SettingsTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: SettingsTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>

                                <div style={{ height: "20px" }} />
                                {this.renderButton(SettingsTabMode.UserPreferences, <SettingsAccessibilityOutlined />, "User")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(SettingsTabMode.Accelerator, <SpeedOutlined />, "Accelerator")}

                                {/* <div style={{ height: "20px" }} />
                                {this.renderButton(SettingsTabMode.NAV, <BuildCircleOutlined />, "NAV Builder")} */}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(SettingsTabMode.Trading, <CurrencyExchangeOutlined />, "Trading")}

                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}