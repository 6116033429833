import moment from 'moment';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import userSessionConnection from '../userSession/userSessionConnection';
import { get, put } from '../utils/httpUtils';
import { Order, OrderAlert, OrderHistory, OrderQuestionResponse, OrderResponse, Venue } from './orderModels';

export class LoadOrdersEvent extends TypedEvent<Order[]> { }
export class LoadOrderVenuesEvent extends TypedEvent<{ [venue: string]: Venue }> { }
export class UpdateOrderEvent extends TypedEvent<Order> { }
export class OrderAlertEvent extends TypedEvent<OrderAlert> { }
export class OrderHistoryUpdateEvent extends TypedEvent<{ orderId: number, history: OrderHistory[] }> { }
export class LoadOrderAlertsEvent extends TypedEvent<OrderAlert[]> { }
export class CancelOrderEvent extends TypedEvent<Order> { }
export class CancelOrderBulkEvent extends TypedEvent<Order[]> { }
export class LoadOrdersFailedEvent extends TypedEvent<string> { }


export async function LoadOrders() {
    await listedInstrumentStoreInstance.InstrumentsAreLoaded;
    
    const orders = await get<Order[]>('Orders');
    var success = true;

    if (orders.payload == null) {
        AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to load orders from server"));
        success = false;
    } else {
        AppDispatcher.dispatch(new LoadOrdersEvent(orders.payload));
    }

    if (success) {
        const venues = await get<{ [venue: string]: Venue }>('Orders/OrderVenues');
        if (venues.payload == null) {
            AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to load order venues from server"));
            success = false;
        } else {
            AppDispatcher.dispatch(new LoadOrderVenuesEvent(venues.payload));
        }
    }

    await LoadActiveOrderAlerts();

    if (!success) {
        await UpdateLoadingStatus("Orders", LoadingStatus.Failed);
    } else {
        await UpdateLoadingStatus("Orders", LoadingStatus.Completed);
    }
}

export async function RefreshOrders() {
    const orders = await get<Order[]>('Orders');
    if (orders.payload == null) {
        AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to load orders from server"));
    } else {
        AppDispatcher.dispatch(new LoadOrdersEvent(orders.payload));
        return orders.payload;
    }
}

export async function LoadActiveOrderAlerts() {
    const alerts = await get<OrderAlert[]>('Orders/ActiveAlerts');
    if (alerts.payload == null) {
        AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to load orders from server"));
    } else {
        AppDispatcher.dispatch(new LoadOrderAlertsEvent(alerts.payload));
    }
}

export async function GetOrderHistory(orderId: number, lastUpdate?: Date) {
    var url = `Orders/History/${orderId}`;
    if (lastUpdate)
        url += `?lastUpdate=${moment(lastUpdate).format('yyyy-MM-DD')}`;
    const history = await get<OrderHistory[]>(url);
    if (history.payload == null) {
        AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to load order history from server"));
    } else {
        AppDispatcher.dispatch(new OrderHistoryUpdateEvent({ orderId, history: history.payload }));
    }
    return history.payload;
}

export async function AddOrUpdateOrder(order: Order) {
    userSessionConnection.connectIfNeeded();
    const response = await put<OrderResponse>('Orders/AddOrUpdateOrder', order);
    if (response.payload == null) {
        AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to add or update order"));
    } else if (!response.payload.order) {
        AppDispatcher.dispatch(new LoadOrdersFailedEvent(response.payload.errorMessage))
    } else {
        AppDispatcher.dispatch(new UpdateOrderEvent(response.payload.order));
    }

    return response?.payload;
}

export async function CancelOrder(order: Order) {
    userSessionConnection.connectIfNeeded();
    await put<Order>('Orders/CancelOrder', order);
    AppDispatcher.dispatch(new CancelOrderEvent(order));
}

export async function CancelOrders(orders: Order[]) {
    userSessionConnection.connectIfNeeded();
    await put<Order>('Orders/CancelOrders', orders);
    AppDispatcher.dispatch(new CancelOrderBulkEvent(orders));
}

export async function ReconSingleOrder(order: Order) {
    userSessionConnection.connectIfNeeded();
    await get(`Orders/SingleOrderRecon/${order.orderId}/${order.orderVenue}`);
}

export async function FillOrder(order: Order, price: number, amount: number) {
    var response = await put<Order>(`Orders/FillOrder/{amount}/{price}`, order);
    if (response.payload)
        AppDispatcher.dispatch(new UpdateOrderEvent(response.payload));
    else
        AppDispatcher.dispatch(new LoadOrdersFailedEvent("Failed to fill order"));
}

export function OrderUpdated(order: Order) {
    AppDispatcher.dispatch(new UpdateOrderEvent(order));
}

export function NewOrderAlert(alert: OrderAlert) {
    AppDispatcher.dispatch(new OrderAlertEvent(alert));
}


export async function ApproveOrDenyOrderQuestion(order: Order, approve: boolean) {
    await put<OrderQuestionResponse>('Orders/AnswerOrderApproval', { order: order, allow: approve } as OrderQuestionResponse);
}

export async function SubcribeToOrderUpdates() {
    await userSessionConnection.connectIfNeeded();
    await userSessionConnection.subscribeToOrders();
}

export async function UnSubcribeFromOrderUpdates() {
    await userSessionConnection.connectIfNeeded();
    await userSessionConnection.unSubscribeFromOrders();
}

export async function SubscribeToOrderAlerts() {
    await userSessionConnection.connectIfNeeded();
    await userSessionConnection.subscribeToOrderAlerts();
}

export async function UnSubcribeFromOrderAlerts() {
    await userSessionConnection.connectIfNeeded();
    await userSessionConnection.unSubscribeFromOrderAlerts();
}