import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { LiveOrderStatuses, Order, OrdersState, OrderStatus, OrderType } from './orderModels';
import { CancelOrderBulkEvent, CancelOrderEvent, LoadOrderAlertsEvent, LoadOrdersEvent, LoadOrderVenuesEvent, OrderAlertEvent, OrderHistoryUpdateEvent, UpdateOrderEvent } from './orderActions';
import _ from 'lodash';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import moment from 'moment';

class OrdersStore extends FluxStore<OrdersState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            orders: [],
            alerts: [],
            venues: {},
            history: {}
        }));
    }

    private addPath(order: Order) {
        if (order.parentOrderId) {
            order.path = order.parentOrderId.toString() + '/';
        }
        else {
            order.path = '';
        }
        order.path += order.orderId.toString();
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadOrdersEvent) {
            const { payload } = action;
            this.state.orders = payload;
            this.state.orders.forEach(order => {
                this.addPath(order);
                if (order.goodUntil !== null && order.goodUntil !== undefined)
                    order.goodUntil = new Date(order.goodUntil);
                order.orderStatusString = OrderStatus[order.orderStatus];
                if (!order.isNativeSpread) {
                    order.insDescription = listedInstrumentStoreInstance.getInstrumentById(order.listedInstrumentId)?.description;
                    order.orderTypeString = OrderType[order.orderType];
                }
                else {
                    order.insDescription = listedInstrumentStoreInstance.getInstrumentById(order.listedInstrumentId)?.ticker + " / " + listedInstrumentStoreInstance.getInstrumentById(order.leg2InstrumentId)?.ticker;
                    order.orderTypeString = OrderType[order.orderType] + " (Spread)";
                }
            })

            this.emitChange();
        }
        else if (action instanceof UpdateOrderEvent) {
            const { payload } = action;
            const item = this.state.orders.findIndex(e => e.orderId === payload.orderId);
            if (payload.goodUntil !== null && payload.goodUntil !== undefined) {
                payload.goodUntil = new Date(payload.goodUntil);
            }
            this.addPath(payload);
            payload.orderStatusString = OrderStatus[payload.orderStatus];
            if (!payload.isNativeSpread) {
                payload.insDescription = listedInstrumentStoreInstance.getInstrumentById(payload.listedInstrumentId)?.description;
                payload.orderTypeString = OrderType[payload.orderType];
            }
            else {
                payload.insDescription = listedInstrumentStoreInstance.getInstrumentById(payload.listedInstrumentId)?.ticker + " / " + listedInstrumentStoreInstance.getInstrumentById(payload.leg2InstrumentId)?.ticker;
                payload.orderTypeString = OrderType[payload.orderType] + " (Spread)";
            }
            if (item === -1) {
                this.state.orders.push(payload);
            }
            else {
                this.state.orders[item] = payload;
                this.state.orders = [...this.state.orders];
            }

            this.emitChange();
        }
        else if (action instanceof CancelOrderEvent) {
            const { payload } = action;
            if (this.state.orders.some(o => o.orderId === payload.orderId)) {
                this.state.orders.filter(e => e.orderId === payload.orderId)[0].orderStatus = OrderStatus.Cancelled;
            }
            // this.state.orders = this.state.orders.filter(e => e.orderId !== payload.orderId);
            this.emitChange();
        }
        else if (action instanceof CancelOrderBulkEvent) {
            const { payload } = action;
            var cancelledOrderIds = payload.map(o => o.orderId);
            cancelledOrderIds.forEach(id => {
                if (this.state.orders.some(o => o.orderId === id)) {
                    this.state.orders.filter(e => e.orderId === id)[0].orderStatus = OrderStatus.Cancelled;
                }
            });
            this.emitChange();
        }
        else if (action instanceof LoadOrderVenuesEvent) {
            const { payload } = action;
            this.state.venues = payload;
            this.emitChange();
        }
        else if (action instanceof LoadOrderAlertsEvent) {
            const { payload } = action;
            this.state.alerts = payload;
            this.emitChange();
        }
        else if (action instanceof OrderAlertEvent) {
            const { payload } = action;
            this.state.alerts = this.state.alerts.filter(a => a.pltfmOrderId !== payload.pltfmOrderId);
            this.state.alerts.push(payload);
            this.emitChange();
        }
        else if (action instanceof OrderHistoryUpdateEvent) {
            const { payload } = action;
            for (var i = 0; i < payload.history.length; i++) {
                if (!payload.history[i].isNativeSpread) {
                    payload.history[i].insDescription = listedInstrumentStoreInstance.getInstrumentById(payload.history[i].listedInstrumentId)?.description;
                    payload.history[i].orderTypeString = OrderType[payload.history[i].orderType];
                }
                else {
                    payload.history[i].insDescription = listedInstrumentStoreInstance.getInstrumentById(payload.history[i].listedInstrumentId)?.ticker + " / " + listedInstrumentStoreInstance.getInstrumentById(payload.history[i].leg2InstrumentId)?.ticker;
                    payload.history[i].orderTypeString = OrderType[payload.history[i].orderType] + " (Spread)";
                }
            }
            if (!this.state.history[payload.orderId])
                this.state.history[payload.orderId] = payload.history;
            else {
                var firstUpdate = moment(payload.history[0]?.periodStart);
                this.state.history[payload.orderId] = this.state.history[payload.orderId].filter(x => moment(x?.periodStart).isBefore(firstUpdate)).concat(payload.history);
            }
            this.emitChange();
        }
    }

    getOrderTypes() {
        return Object.keys(OrderType).filter(value => isNaN(Number(value)) === false).map(ot => {
            return this.getOrderType(Number(ot));
        });
    }

    getOrderType(id: number) {
        if (id !== undefined && id !== null && id >= 0) {
            return { id: id, value: OrderType[id].replace(/([A-Z])/g, ' $1').trim() };
        }
        return { id: -1, value: "" };
    }

    getOrderStatuses() {
        return Object.keys(OrderStatus).filter(value => isNaN(Number(value)) === false).map(ot => {
            return this.getOrderStatus(Number(ot));
        });
    }

    getOrderStatus(id: number) {
        return { id: id, value: OrderStatus[id]?.replace(/([A-Z])/g, ' $1')?.trim() };
    }

    getOrders() {
        return this.state.orders;
    }

    getOrderHistory(orderId: number) {
        return this.state.history[orderId];
    }

    getOrdersForInstrument(insId: number, onlyLive: boolean = true) {
        return this.state.orders.filter(o => o.listedInstrumentId === insId && (!onlyLive || LiveOrderStatuses.includes(o.orderStatus)));
    }

    getOrdersByInstrument() {
        return _.groupBy(this.state.orders, o => o.listedInstrumentId);
    }

    getOrderVenues() {
        return this.state.venues;
    }

    getOrderVenuesReverse() {
        var venues = this.getOrderVenues();
        var venuesRev: { [metaKey: string]: string } = {}
        Object.keys(venues).forEach(k => {
            venuesRev[venues[k].name] = k;
        });
        return venuesRev;
    }

    getOrderAlerts() {
        return this.state.alerts;
    }
}


const ordersStoreInstance = new OrdersStore(AppDispatcher);
export default ordersStoreInstance;