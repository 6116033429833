
import { DatePeriodType } from "./datePeriodType";

export class Frequency {
    public periodType: DatePeriodType = DatePeriodType.b;
    public periodCount: number = 0;

    constructor(frequency?: string) {
        if (frequency)
            this.SplitPeriod(frequency);
    }

    public toString = (): string => `${this.periodCount}${Frequency.convertPeriodType(this.periodType)}`;
    public static toStringStatic(val: Frequency): string {
        if (val)
            return `${val.periodCount}${Frequency.convertPeriodType(val.periodType)}`
        else
            return null;
    };

    private static convertPeriodType(periodType: DatePeriodType) {
        if (DatePeriodType[periodType])
            return DatePeriodType[periodType];

        let period = periodType.toString();

        switch (period) {
            case "Year":
                return "y";
            case "Month":
                return "m";
            case "Week":
                return "w";
            case "Day":
                return "d";
            case "BusinessDay":
                return "b";
            default:
                return period;
        }
    }

    private SplitPeriod = (period: string) => {
        var periodType = period[period.length - 1];

        switch (periodType) {
            case 'D':
            case 'd':
                this.periodType = DatePeriodType.d;
                break;
            case 'Y':
            case 'y':
                this.periodType = DatePeriodType.y;
                break;
            case 'M':
            case 'm':
                this.periodType = DatePeriodType.m;
                break;
            case 'B':
            case 'b':
                this.periodType = DatePeriodType.b;
                break;
            case 'w':
            case 'W':
                this.periodType = DatePeriodType.w;
                break;
            default:
                this.periodType = DatePeriodType.d;
        }
        this.periodCount = parseInt(period.substring(0, period.length - 1));
    }

    private static GetPeriodType = (period: string): DatePeriodType | undefined => {
        var periodType = period[period.length - 1];

        switch (periodType) {
            case 'D':
            case 'd':
                return DatePeriodType.d;
            case 'Y':
            case 'y':
                return DatePeriodType.y;
            case 'M':
            case 'm':
                return DatePeriodType.m;
            case 'B':
            case 'b':
                return DatePeriodType.b;
            case 'w':
            case 'W':
                return DatePeriodType.w;
            default:
                return undefined;
        }

    }

    public static TryParse = (val: string | undefined): Frequency | undefined => {
        if (val === undefined || val === null || val.length < 2)
            return undefined;

        var periodType = Frequency.GetPeriodType(val);
        var periodCount = parseInt(val.substring(0, val.length - 1));

        if (periodType===undefined || !periodCount) {
            return undefined;
        }

        return new Frequency(val);
    }
}