import React from 'react';
import FBEmitter from 'fbemitter';
import marketDataStoreInstance from '../marketDataStore';
import userStoreInstance from '../../user/userStore';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { Autocomplete, CircularProgress, AutocompleteChangeReason, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, Typography, Tabs, Paper, Tab } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { Moment } from 'moment';
import { getFormTheme } from '../../inputs/formCommon';
import { ListedInstrument } from '../../listedInstruments/listedInstrumentModels';
import { AvailableMarketDataReponse, MarketDataExplorerEditRequest } from '../marketDataModels';
import { GetAvailableDataForInsId } from '../marketDataActions';
import { isCashIns } from '../../positions/positionsCommon';
import { CandlestickChartOutlined, CloseOutlined, ShowChartOutlined, ViewInArOutlined } from '@mui/icons-material';
import marketDataExplorerStoreInstance from '../marketDataExplorerStore';
import { WrappedSelect } from '../../inputs/wrappedSelect';
import { WrappedDatePicker } from '../../inputs/wrappedDatePicker';
import { TabContext, TabPanel } from '@mui/lab';

export type MarketDataExplorerProps = {
    onChangeState?: (key: string, value: string) => void;
    getState?: (key: string) => string;
    isOpen: boolean;
    launchComponent: (componentName: string, props?: any) => void;
    editComponent: (tabId: number, componentKey: string, props?: any) => void;
    onClose: () => void;
}

type MarketDataExplorerState = {
    selectedInstrument: ListedInstrument,
    selectedChildInstrument: ListedInstrument,
    rootInstruments: ListedInstrument[],
    dataForSelectedIns: AvailableMarketDataReponse,
    editRequest: MarketDataExplorerEditRequest;
    loading: boolean,
    open: boolean;
    isEditMode: boolean;
    selectedTabId: number,

    selectedDate: Date,
    selectedSmileDate: Date,
    availableDates: string[],

    currentTheme: string,

    selectedSetVols: number;
    selectedSetCandles: number;
    selectedSetFixings: number;

    selectedCandleSize: number;

    selectedChartType: string;
}


export class MarketDataExplorer extends React.Component<MarketDataExplorerProps, MarketDataExplorerState>{
    eventSubscriptionMarketData: FBEmitter.EventSubscription | undefined;
    eventSubscriptionThemes: FBEmitter.EventSubscription | undefined;
    eventSubscriptionExplorer: FBEmitter.EventSubscription | undefined;

    constructor(props: MarketDataExplorerProps) {
        super(props)
        this.state = {
            open: this.props.isOpen,
            selectedDate: undefined,
            loading: false,
            isEditMode: false,
            editRequest: undefined,
            selectedSmileDate: undefined,
            availableDates: [],
            rootInstruments: [],
            selectedInstrument: undefined,
            selectedChildInstrument: undefined,
            currentTheme: userStoreInstance.GetThemeName(),
            dataForSelectedIns: undefined,
            selectedTabId: 0,
            selectedSetVols: undefined,
            selectedSetCandles: undefined,
            selectedSetFixings: undefined,
            selectedCandleSize: undefined,
            selectedChartType: undefined
        };

        this.setTheme = this.setTheme.bind(this);
        this.marketDataUpdate = this.marketDataUpdate.bind(this);
        this.shouldDisableDate = this.shouldDisableDate.bind(this);
        this.filterInsOptions = this.filterInsOptions.bind(this);
        this.onChangeSelectedIns = this.onChangeSelectedIns.bind(this);
        this.onChangeSelectedChildIns = this.onChangeSelectedChildIns.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onEditEvent = this.onEditEvent.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.launch = this.launch.bind(this);
    }

    async componentDidMount() {
        console.log("MktD")
        this.eventSubscriptionThemes = userStoreInstance.addChangeListener(this.setTheme);
        this.eventSubscriptionMarketData = marketDataStoreInstance.addChangeListener(this.marketDataUpdate);
        this.eventSubscriptionExplorer = marketDataExplorerStoreInstance.addChangeListener(this.onEditEvent);
        //await this.marketDataUpdate();
        //var availableDates = marketDataStoreInstance.getAvailableVolDates();

        var rootIns = Array.from(listedInstrumentStoreInstance.getRootInstruments()).filter(i => !(isCashIns(i.listedInstrumentId))).sort((a, b) => a.type > b.type ? 1 : -1);
        this.setState({ rootInstruments: rootIns });
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        if (this.eventSubscriptionMarketData) {
            this.eventSubscriptionMarketData.remove();
            this.eventSubscriptionMarketData = undefined;
        }
        if (this.eventSubscriptionThemes) {
            this.eventSubscriptionThemes.remove();
            this.eventSubscriptionThemes = undefined;
        }
        if (this.eventSubscriptionExplorer) {
            this.eventSubscriptionExplorer.remove();
            this.eventSubscriptionExplorer = undefined;
        }
    }

    launch(componentName: string, props?: any) {
        if (this.state.isEditMode) {
            var req = this.state.editRequest;
            this.props.editComponent(req.tabId, req.componentId, props);
            this.setState({ editRequest: undefined });
            this.onClose();
        }
        else {
            this.props.launchComponent(componentName, props);
            this.onClose();
        }
    }

    async marketDataUpdate() {
        const { selectedInstrument, dataForSelectedIns } = this.state;
        if (this.state.selectedInstrument) {
            var dataForIns = marketDataStoreInstance.getDataTypesForInsId(selectedInstrument.listedInstrumentId);
            if (dataForIns !== dataForSelectedIns)
                this.setState({ dataForSelectedIns: dataForIns, loading: false });
        }
    }

    async onEditEvent() {
        var req = marketDataExplorerStoreInstance.getRequestDetails();
        if (!req.componentId) {
            this.setState({ open: true, selectedInstrument: undefined, isEditMode: false });
        }
        else {
            var selectedTabId = req.chartType === "Candles" ? 0 : (
                req.chartType === "Fixings" ? 1 : 2
            );
            var ins = listedInstrumentStoreInstance.getInstrumentById(req.insId);
            var childIns = undefined;
            if(ins.underlying)
            {
                childIns=ins;
                ins = ins.underlying;
            }
                

            this.setState({ open: true, selectedInstrument: ins, selectedDate: req.valDate, isEditMode: true, selectedChartType: req.chartType, selectedTabId, selectedChildInstrument:childIns });
            var dataForIns = marketDataStoreInstance.getDataTypesForInsId(ins.listedInstrumentId);
            if (!dataForIns) {
                this.setState({ dataForSelectedIns: undefined, loading: true, editRequest: req });
                await GetAvailableDataForInsId(ins.listedInstrumentId);
            }
            else
                this.setState({ dataForSelectedIns: dataForIns, editRequest: req });
        }

    }

    setTheme() {
        this.setState({ currentTheme: userStoreInstance.GetThemeName() });
    }

    async onChange() { }


    shouldDisableDate(m: Moment) {
        return !marketDataStoreInstance.getAvailableVolDatesByIns(this.state.selectedInstrument?.listedInstrumentId).map(d => d.toDateString()).includes(m.toDate().toDateString())
    }


    filterInsOptions(options: ListedInstrument[], state: any): ListedInstrument[] {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.insMatchesQuery(o, query))
        return relevant;
    };

    insMatchesQuery(ins: ListedInstrument, query: string): boolean {
        if (!query || query === "")
            return true;
        const normalizedTitle = ins?.description?.toLowerCase();
        const code = ins?.ticker?.toLowerCase();
        const allMetaData = ins?.metaData ? ins.metaData.map(am => am.data.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${ins.listedInstrumentId}. ${normalizedTitle} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    async onChangeSelectedIns(event: React.SyntheticEvent<Element, Event>, value: string | ListedInstrument, reason: AutocompleteChangeReason) {
        var ins = value as ListedInstrument;
        this.setState({ selectedInstrument: ins, selectedChildInstrument: undefined });
        if (ins) {
            var dataForIns = marketDataStoreInstance.getDataTypesForInsId(ins.listedInstrumentId);
            if (!dataForIns) {
                this.setState({ dataForSelectedIns: undefined, loading: true });
                await GetAvailableDataForInsId(ins.listedInstrumentId);
            }
            else
                this.setState({ dataForSelectedIns: dataForIns });
        }
    }

    async onChangeSelectedChildIns(event: React.SyntheticEvent<Element, Event>, value: string | ListedInstrument, reason: AutocompleteChangeReason) {
        var ins = value as ListedInstrument;
        this.setState({ selectedChildInstrument: ins });
    }

    onClose() {
        this.props.onClose();
        this.setState({ open: false, isEditMode: false, selectedChartType: undefined });
    }

    getOptionLabel(o: string | ListedInstrument) {
        var ins = (o as ListedInstrument);
        if (ins) {
            var label = ins.description.split(' Underlying Index')[0];
            var dupes = ["Mexican Peso", "Japanese Yen", "Dnr 9 05/15/21", "Chinese Renminbi (Offshore)", "Chinese Yuan", "Swiss Franc", "REPSOL SA", "Sigma Lithium Corp"]
            if (dupes.includes(label))
                label += "-" + ins.primaryExchange;
            if (label === "Chinese Yuan-IDEALFX")
                label += `-(${ins.ticker})`
            return label;
        }
        return "Select Instrument...";
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }


    render() {
        const { selectedInstrument, rootInstruments, dataForSelectedIns, loading, open, selectedSetVols, selectedDate, selectedTabId, selectedSetCandles, selectedSetFixings, isEditMode, selectedCandleSize, selectedChildInstrument } = this.state;

        var col = userStoreInstance.GetTheme().color;
        const childIns = dataForSelectedIns?.children ? Object.keys(dataForSelectedIns?.children).map(c => listedInstrumentStoreInstance.getInstrumentById(Number(c))) : undefined;

        var selectedInsData = dataForSelectedIns === undefined ? undefined :
            (selectedChildInstrument && dataForSelectedIns.children[selectedChildInstrument.listedInstrumentId] ? dataForSelectedIns.children[selectedChildInstrument.listedInstrumentId] : dataForSelectedIns)

        //var boxProps = { item: true, border: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, borderRadius: "5px", minHeight: "20vh", minWidth: "10vw", padding: "0.5em" };
        return (
            <Dialog open={open} onClose={this.onClose} fullWidth maxWidth="xl" sx={{ borderWidth: 2, borderColor: "text.primary", borderStyle: "solid" }} >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque, color: col }}>
                    Market Data Explorer
                    <IconButton
                        aria-label="close"
                        onClick={this.onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque, borderTop: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, paddingTop: "0.5em" }} >
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container direction="column" spacing={2} height="50vh" marginTop="0.5em">
                            <Grid item container spacing={2} direction="row">
                                <Grid item>
                                    <Autocomplete
                                        size="small"
                                        style={{ width: "350px" }}
                                        options={rootInstruments}
                                        getOptionLabel={this.getOptionLabel}
                                        groupBy={(ins: ListedInstrument) => ins.type}
                                        renderGroup={(params) => <div><Typography variant="h6">{params.group}</Typography>{params.children}</div>}
                                        filterOptions={this.filterInsOptions}
                                        id={`rootInsPicker`}
                                        value={selectedInstrument ?? null}
                                        autoSelect
                                        disableClearable
                                        selectOnFocus
                                        // isOptionEqualToValue={this.insMatchesQuery}
                                        onChange={this.onChangeSelectedIns}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label="Instrument"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />);
                                        }}
                                    />
                                </Grid>
                                {loading ? <Grid item>
                                    <CircularProgress />
                                </Grid> : null}
                                {childIns === undefined ? null :
                                    <Grid item>
                                        <Autocomplete
                                            size="small"
                                            style={{ width: "350px" }}
                                            options={childIns}
                                            getOptionLabel={this.getOptionLabel}
                                            groupBy={(ins: ListedInstrument) => ins?.type ?? "Unknown Group"}
                                            renderGroup={(params) => <div><Typography variant="h6">{params.group}</Typography>{params.children}</div>}
                                            filterOptions={this.filterInsOptions}
                                            id={`rootInsPicker`}
                                            value={selectedChildInstrument ?? null}
                                            autoSelect
                                            disableClearable
                                            selectOnFocus
                                            // isOptionEqualToValue={this.insMatchesQuery}
                                            onChange={this.onChangeSelectedChildIns}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        label="Child Instrument"
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />);
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid item container direction="row" justifyContent="flex-start">
                                <TabContext value={selectedTabId.toString()} >
                                    <Tabs orientation='vertical' key='mktDtahTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)}>
                                        <Tab
                                            disabled={isEditMode && selectedTabId !== 0}
                                            classes={{ root: "MarketDataExplorerTabTitle", selected: "MarketDataExplorerTabSelected" }}
                                            value={"0"}
                                            key={"tabCandles"}
                                            component={Paper}
                                            id={"tab0"}
                                            label="Candles"
                                            icon={<CandlestickChartOutlined />} />
                                        <Tab
                                            disabled={isEditMode && selectedTabId !== 1}
                                            classes={{ root: "MarketDataExplorerTabTitle", selected: "MarketDataExplorerTabSelected" }}
                                            value={"1"}
                                            key={"tabFixings"}
                                            component={Paper}
                                            id={"tab1"}
                                            label="Fixings"
                                            icon={<ShowChartOutlined />} />
                                        <Tab
                                            disabled={isEditMode && selectedTabId !== 2}
                                            classes={{ root: "MarketDataExplorerTabTitle", selected: "MarketDataExplorerTabSelected" }}
                                            value={"2"}
                                            key={"tabSurfaces"}
                                            component={Paper}
                                            id={"tab2"}
                                            label="Surfaces"
                                            icon={<ViewInArOutlined />} />
                                    </Tabs>
                                    <TabPanel
                                        style={{ height: "100%" }}
                                        key={"tabPQuery0"}
                                        value={"0"}
                                        //index={0}
                                        children={selectedInsData?.setIdsWithCandles && Object.keys(selectedInsData?.setIdsWithCandles)?.length > 0 ?
                                            <Grid container direction="column" alignContent="center" rowSpacing={2} justifyContent="space-around" component="div">
                                                <Grid item>
                                                    <Typography textAlign='center' variant='h6'>Candles</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <WrappedSelect label="Set Name" value={selectedSetCandles ?? -1} onChange={(v) => this.setState({ selectedSetCandles: Number(v.target.value) })}>
                                                        <MenuItem key={'mktd-setCndlZZZ'} value={-1} disabled>Select Set...</MenuItem>
                                                        {Object.keys(selectedInsData?.setIdsWithCandles).map((i, ix) => {
                                                            return <MenuItem key={'mktd-setCdl-' + ix} value={i}>
                                                                {marketDataStoreInstance.getSetName(Number(i))?.setName}
                                                            </MenuItem>
                                                        })}
                                                    </WrappedSelect>
                                                </Grid>
                                                <Grid item>
                                                    <WrappedSelect label="Candle Size" value={selectedCandleSize ?? -1} onChange={(v) => this.setState({ selectedCandleSize: Number(v.target.value) })}>
                                                        <MenuItem key={'mktd-cndlSizelZZZ'} value={-1} disabled>Select Size...</MenuItem>
                                                        {selectedInsData.setIdsWithCandles[selectedSetCandles] ? selectedInsData.setIdsWithCandles[selectedSetCandles].map((i, ix) => {
                                                            return <MenuItem key={'mktd-cdlsize-' + ix} value={i}>
                                                                {i}
                                                            </MenuItem>
                                                        }) : null}
                                                    </WrappedSelect>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disabled={!Boolean(selectedInsData.listedInstrumentId) || !Boolean(selectedCandleSize)}
                                                        onClick={() => { this.launch("CandleChart", { insId: selectedInsData.listedInstrumentId, candleSize: selectedCandleSize, setId: selectedSetCandles, compactMode: true }) }}>Launch</Button>
                                                </Grid>
                                            </Grid>
                                            : <Typography textAlign='center' variant='h6' component="div">No Candles Found</Typography>} />
                                    <TabPanel
                                        style={{ height: "100%" }}
                                        key={"tabPQuery1"}
                                        value={"1"}
                                        //index={1}
                                        children={selectedInsData?.setIdsWithFixings?.length > 0 ?
                                            <Grid container direction="column" alignContent="center" rowSpacing={2} justifyContent="space-around" component="div">
                                                <Grid item>
                                                    <Typography textAlign='center' variant='h6'>Fixings</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <WrappedSelect label="Set" value={selectedSetFixings ?? -1} onChange={(v) => this.setState({ selectedSetFixings: Number(v.target.value) })}>
                                                        <MenuItem key={'mktd-setVolZZZ'} value={-1} disabled>Select Set...</MenuItem>
                                                        {selectedInsData.setIdsWithFixings.map((i, ix) => {
                                                            return <MenuItem key={'mktd-setVols-' + ix} value={i}>
                                                                {marketDataStoreInstance.getSetName(i)?.setName}
                                                            </MenuItem>
                                                        })}
                                                    </WrappedSelect>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disabled={!selectedSetFixings || !selectedInsData.listedInstrumentId}
                                                        onClick={() => { this.launch("CandleChart", { insId: selectedInsData.listedInstrumentId, candleSize: 0, setId: selectedSetFixings, compactMode: true }) }}>Launch</Button>
                                                </Grid>
                                            </Grid>
                                            : <Typography textAlign='center' variant='h6' component="div">No Fixings Found</Typography>} />
                                    <TabPanel
                                        style={{ height: "100%" }}
                                        key={"tabPQuery2"}
                                        value={"2"}
                                        //index={1}
                                        children={selectedInsData?.setIdsWithVolSurfaces?.length > 0 ?
                                            <Grid container direction="column" alignContent="center" rowSpacing={2} justifyContent="space-around" component="div">
                                                <Grid item>
                                                    <Typography textAlign='center' variant='h6'>Surfaces</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <WrappedSelect label="Set" value={selectedSetVols ?? -1} onChange={(v) => this.setState({ selectedSetVols: Number(v.target.value) })}>
                                                        <MenuItem key={'mktd-setVolZZZ'} value={-1} disabled>Select Set...</MenuItem>
                                                        {selectedInsData.setIdsWithVolSurfaces.map((i, ix) => {
                                                            return <MenuItem key={'mktd-setVols-' + ix} value={i}>
                                                                {marketDataStoreInstance.getSetName(i)?.setName}
                                                            </MenuItem>
                                                        })}
                                                    </WrappedSelect>
                                                </Grid>
                                                <Grid item>
                                                    <WrappedDatePicker
                                                        style={{ width: "120px" }}
                                                        value={selectedDate}
                                                        onChange={(d: Moment) => this.setState({ selectedDate: d?.toDate() })}
                                                        label="Value Date"
                                                        shouldDisableDate={this.shouldDisableDate}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={() => { this.launch("SmileChart", { insId: selectedInstrument?.listedInstrumentId, valDate: selectedDate, compactMode: true }) }}>Smiles</Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={() => { this.launch("SurfaceChart", { insId: selectedInstrument?.listedInstrumentId, valDate: selectedDate, compactMode: true }) }}>Surface</Button>
                                                </Grid>
                                            </Grid>
                                            : <Typography textAlign='center' variant='h6' component="div">No Surfaces Found</Typography>}
                                    />
                                </TabContext>
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </DialogContent>
            </Dialog >
        );
    }
}