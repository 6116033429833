import { DateFixedRelative } from "../../../payoff/models/dateFixedRelative";
import { BasketDefinition } from "../../../qwack/basketDefinition";
import { BasketType } from "../../../qwack/basketType";

export function basketOk(basket: BasketDefinition): boolean {
    let initial = basket && basket.basketWeights && Object.keys(basket.basketWeights).length > 0;

    if (!initial) return false;

    if (basket.basketType === BasketType.WeightedBasket) {
        Object.keys(basket.basketWeights).forEach(k => {
            initial = initial && (basket.basketWeights[k] !== 0)
        });
    }
    if (basket.basketType === BasketType.NthBestOf || basket.basketType === BasketType.NthWorstOf) {
        initial = initial && basket.nthModifier > 0;
    }
    return initial;
}

export function dateOk(d: DateFixedRelative): boolean {
    if (d === undefined)
        return false;
    if (d.isRelative) {
        return d.relative !== undefined && d.relative.relativeTenor !== undefined
    }
    else {
        return d.absolute !== undefined && d.absolute.absoluteDate !== undefined
    }
}