import React, { FormEvent, ReactElement } from 'react';
import FBEmitter from 'fbemitter';
import { TextField, Tooltip } from '@mui/material';

export type ValidatedTextInputProps = {
    onChange: (value: string) => boolean;
    initialValue: string;
    placeholder: string | undefined;
    keyName: string;
    large: boolean;
    disabled?: boolean;
    helperText?: string;
    label?: string;
    toolTip?: ReactElement;
    className?: string
}

type ValidatedTextInputState = {
    currentValue: string,
    isValid: boolean,
    placeholder: string,
    initialValue: string,
    disabled: boolean,
}

class ValidatedTextInput extends React.Component<ValidatedTextInputProps, ValidatedTextInputState> {
    eventSubscriptionCredit: FBEmitter.EventSubscription | undefined;

    constructor(props: any) {
        super(props)
        this.state =
        {
            placeholder: this.props.placeholder ? this.props.placeholder : "Enter value...",
            isValid: this.props.onChange(this.props.initialValue),
            currentValue: this.props.initialValue,
            initialValue: this.props.initialValue,
            disabled: this.props.disabled
        };
    }

    public async componentDidMount() {
        var value = this.props.initialValue;
        var isValid = this.props.onChange(value);
        this.setState({ isValid });
    }

    public updateValue(value: string) {
        var isValid = this.props.onChange(value);
        this.setState(
            {
                currentValue: value,
                isValid: isValid,
            });
    }

    public reset = () => {
        this.updateValue(this.state.initialValue);
    };


    public componentWillUnmount() {

    }

    onChange = (event?: FormEvent<HTMLElement>) => {
        if (event) {
            var val = (event.target as HTMLInputElement).value;
            this.updateValue(val);
        }
    }

    renderControl() {
        const { currentValue, isValid, disabled } = this.state;

        return (
            <TextField
                variant="outlined"
                size="small"
                error={!isValid}
                key={this.props.keyName + "2"}
                id={this.props.keyName + "2"}
                value={currentValue ?? ""}
                onChange={this.onChange}
                helperText={this.props.helperText}
                label={this.props.label}
                InputProps={{
                    readOnly: disabled,
                    classes: { root: (this.props.className ? this.props.className + " " : "") + "ValidatedTextInputInner" }
                }}
            />
        );
    }

    render() {
        if (this.props.toolTip) {
            return <Tooltip title={this.props.toolTip}>{this.renderControl()}</Tooltip>
        }
        else {
            return this.renderControl();
        }
    }
}

export default ValidatedTextInput;