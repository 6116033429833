import React from "react";
import { ScheduleInfo, ScheduleLog, ScheduleSummary } from "./schedulesModels";
import FBEmitter from 'fbemitter';
import schedulesStoreInstance from "./schedulesStore";
import {
    Button,
    Grid,
    MenuItem,
    TextField,
    ThemeProvider,
    Typography,
    SelectChangeEvent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { renderInput } from "../jobs/jobCommon";
import jobResultStoreInstance from "../jobs/jobResultStore";
import { AddOutlined, CancelOutlined, SaveOutlined } from "@mui/icons-material";
import { AddOrUpdateSchedule, GetScheduleLogs, UpdateSchedules } from "./schedulesActions";
import moment from "moment";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { JobSpec, NewJobRequest } from "../jobs/jobModels";
import { nullJob } from "../jobs/runJobTab";
import { DaysToIncludeControl } from "./daysToIncludeControl";
import { WrappedTimePicker } from "../inputs/wrappedTimePicker";


type ScheduleTabState = {
    selectedScheduleSummary: ScheduleSummary | undefined;
    schedules: ScheduleSummary[];
    logs: ScheduleLog[];
    showNewDialog: boolean,
    newScheduleItemName: string,
    newScheduleItemSpec: JobSpec;
}

export interface ScheduleTabProps {

}

const nullSchedule = { jobName: "Select Schedule...", scheduleId: 0 } as ScheduleSummary

export class ScheduleTab extends React.Component<ScheduleTabProps, ScheduleTabState> {
    eventSubscriptionSchedules: FBEmitter.EventSubscription | undefined;

    constructor(props: ScheduleTabProps) {
        super(props);
        this.state = {
            schedules: [],
            selectedScheduleSummary: nullSchedule,
            logs: [],
            showNewDialog: false,
            newScheduleItemName: undefined,
            newScheduleItemSpec: undefined
        }
        this.onScheduleSelect = this.onScheduleSelect.bind(this);
        this.onClickSaveParams = this.onClickSaveParams.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onSchedulesUpdate = this.onSchedulesUpdate.bind(this);
        this.onClickCreateNewItem = this.onClickCreateNewItem.bind(this);
    }

    public async componentDidMount() {
        this.eventSubscriptionSchedules = schedulesStoreInstance.addChangeListener(this.onSchedulesUpdate);
        const schedules = schedulesStoreInstance.getSchedules();
        this.setState({ schedules: schedules });
    }

    public componentWillUnmount() {
        if (this.eventSubscriptionSchedules) {
            this.eventSubscriptionSchedules.remove();
            this.eventSubscriptionSchedules = null;
        }
    }

    onSchedulesUpdate() {
        const schedules = schedulesStoreInstance.getSchedules();
        this.setState({ schedules: schedules });
    }

    private async onScheduleSelect(e: SelectChangeEvent<string>) {
        const { schedules } = this.state;
        var scheduleStr = e.target.value as string === `${nullSchedule.scheduleId}¬${nullSchedule.jobName}` ? null : e.target.value as string;
        if (scheduleStr === null)
            this.setState({ selectedScheduleSummary: null, logs: [] });
        else {
            var parts = scheduleStr.split('¬');
            if (parts[0] === "undefined") {
                var schedule = schedules.filter(s => s.jobDetails.scheduleName === parts[1])[0];
                if (schedule) {
                    this.setState({ selectedScheduleSummary: schedule, logs: [] });
                }
            }
            else {
                var scheduleId = Number(parts[0]);
                var schedule2 = schedules.filter(s => s.scheduleId === scheduleId)[0];
                if (schedule2) {
                    var logs = await GetScheduleLogs(schedule2.scheduleId);
                    this.setState({ selectedScheduleSummary: schedule2, logs });
                }
            }
        }
    }

    getScheduleSelectKey = (s: ScheduleSummary) => `${s.scheduleId}¬${s.jobDetails?.scheduleName ?? s.jobName}`;

    renderScheduleDetail(s: ScheduleSummary) {
        var d = new Date("1970-01-01");
        if(s.jobDetails?.utcTimeOfDay)
        {
            var parts = s.jobDetails.utcTimeOfDay.split(":");
            d.setHours(Number(parts[0]));
            d.setMinutes(Number(parts[1]));
            d.setSeconds(Number(parts[2]));
        }
        return (
            <Grid container spacing={2} justifyContent="center">
                <Grid container item spacing={2} justifyContent="center">
                    <Grid item>
                        <TextField
                            size="small"
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="scheduleId"
                            name="scheduleId"
                            label="Id"
                            value={s.scheduleId ?? ''}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly:true }} />
                    </Grid>
                    <Grid item>
                        <TextField
                            size="small"
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="jobName"
                            name="jobName"
                            label="Job Name"
                            value={s.jobName ?? ''}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner"}, readOnly:true   }} />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            size="small"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="lastRun"
                            name="lastRun"
                            label="Last Run"
                            value={s.lastRunTime?.toLocaleString() ?? ''}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner"}, readOnly:true  }} />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            size="small"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="nextRun"
                            name="nextRun"
                            label="Next Run"
                            value={s.nextRunTime?.toLocaleString() ?? ''}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly:true  }} />
                    </Grid>
                </Grid>
                <Grid container item spacing={2} justifyContent="center">
                    <Grid item>
                        <TextField
                            variant="outlined"
                            size="small"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="retries"
                            name="retries"
                            label="Retries"
                            value={s.jobDetails?.retryTimes ?? ''}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                    </Grid>
                    <Grid item>
                        <WrappedTimePicker
                            size="small"
                            key="utcTime"
                            name="utcTime"
                            label="Time (UTC)"
                            value={s.jobDetails?.utcTimeOfDay?d:null}
                            onChange={d=> {s.jobDetails.utcTimeOfDay=d.format("HH:mm:ss"); this.setState({selectedScheduleSummary:s})}}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            size="small"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="freq"
                            name="freq"
                            label="Frequency"
                            value={s.jobDetails?.intervalFrequency ?? ''}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                    </Grid>
                </Grid>
                <Grid container item spacing={2} justifyContent="center">
                    <Grid item>
                        <DaysToIncludeControl value={s.jobDetails?.daysToInclude} onChange={(v => { s.jobDetails.daysToInclude = v; this.setState({ selectedScheduleSummary: s }) })} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private setValue(key: string, value: string) {
        const { selectedScheduleSummary } = this.state;
        const s = selectedScheduleSummary.jobDetails?.jobInfo;
        if (key) {
            s.params[key] = value;
        }

        this.setState({ selectedScheduleSummary });
    }

    renderParameterDetail() {
        const { selectedScheduleSummary } = this.state;
        const s = selectedScheduleSummary.jobDetails?.jobInfo;

        if (!s)
            return null;
        var spec = jobResultStoreInstance.getSpec(s.jobName);
        return (
            <Grid container spacing={2} justifyContent="center">
                {Object.keys(s.params).map(k => {
                    //var p = s.params[k];
                    var t = spec.paramTypes[k];
                    var d = spec.paramDefaults[k];
                    return (
                        <Grid item>
                            {renderInput(k, t, d, this.setValue, spec, s)}
                        </Grid>);
                })}
            </Grid>
        );
    }

    async onClickSaveParams() {
        const { selectedScheduleSummary, schedules } = this.state;
        var updated = await AddOrUpdateSchedule(selectedScheduleSummary);
        schedules[schedules.indexOf(selectedScheduleSummary)] = updated;
        this.setState({ selectedScheduleSummary: updated, schedules });
        UpdateSchedules(schedules);
    }

    renderLogDetail(logs: ScheduleLog[]) {
        return (
            <Grid container spacing={2} justifyContent="center" style={{ height: "100%" }}>
                {logs.sort((a, b) => b.scheduleLogId - a.scheduleLogId).slice(0, 20).map(l => {
                    return (
                        <Grid item style={{ width: "calc(100vw - 250px)" }}>
                            <span style={{ width: "100%" }}>{moment(l.runStarted).format("yyyy-MM-DD hh:mm:ss")} ({l.scheduleLogId}) - {l.log}</span>
                        </Grid>);
                })}
            </Grid>
        );
    }

    private onJobSelect(e: SelectChangeEvent<string>) {
        var jobStr = e.target.value === nullJob.jobName ? null : e.target.value;
        var spec = jobResultStoreInstance.getSpecs().filter(j => j.jobName === jobStr)[0];
        this.setState({ newScheduleItemSpec: spec });
    }

    onClickCreateNewItem() {
        const { schedules, newScheduleItemName, newScheduleItemSpec } = this.state;

        var newItem = {
            jobName: newScheduleItemSpec.jobName,
            jobDetails: {
                scheduleName: newScheduleItemName,
                daysToInclude: [],
                jobInfo: {
                    created: new Date(),
                    jobName: newScheduleItemSpec.jobName,
                    params: {}
                } as NewJobRequest
            } as ScheduleInfo
        } as ScheduleSummary;

        Array.from(Object.keys(newScheduleItemSpec.paramDefaults)).forEach(paramName => {
            var val = newScheduleItemSpec.paramDefaults[paramName];
            newItem.jobDetails.jobInfo.params[paramName] = val;
        });

        schedules.push(newItem);

        this.setState({ showNewDialog: false, newScheduleItemName: undefined, newScheduleItemSpec: undefined, selectedScheduleSummary: newItem, schedules })
    }

    render() {
        const { schedules, selectedScheduleSummary, logs, showNewDialog, newScheduleItemName, newScheduleItemSpec } = this.state;
        var preventCreateNewItem = !Boolean(newScheduleItemName) || newScheduleItemName === "" || !Boolean(newScheduleItemSpec);
        return (
            <div className="SchedulesTab">
                <ThemeProvider theme={getFormTheme()}>
                    <div className="SchedulesTabInner">
                        <Dialog key="addNewScheduleItem"
                            open={showNewDialog}
                            onClose={() => this.setState({ showNewDialog: true })}>
                            <DialogTitle>Create New Schedule Item</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2} style={{ padding: "1em" }}>
                                    <Grid item>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            label="Item Name"
                                            value={newScheduleItemName ?? ""}
                                            onChange={(e) => this.setState({ newScheduleItemName: (e.target.value as string) })} />
                                    </Grid>
                                    <Grid item>
                                        <WrappedSelect
                                            style={{ minWidth: "200px" }}
                                            id="jobSelect"
                                            name="jobSelect"
                                            label="Job"
                                            value={newScheduleItemSpec?.jobName ?? nullJob.jobName}
                                            onChange={(e) => this.onJobSelect(e)}>
                                            <MenuItem key={"jobxNull"} value={nullJob.jobName}>{nullJob.jobName}</MenuItem>
                                            {jobResultStoreInstance.getSpecs().map(j =>
                                                <MenuItem key={"jobx" + j.jobName} value={j.jobName}>{j.jobName}</MenuItem>)}
                                        </WrappedSelect>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ showNewDialog: false })} startIcon={<CancelOutlined />}>Cancel</Button>
                                <Button disabled={preventCreateNewItem} onClick={() => this.onClickCreateNewItem()} startIcon={<AddOutlined />}>Add Item</Button>
                            </DialogActions>
                        </Dialog>
                        <Grid container spacing={2} className="SchedulesTabSelector" justifyContent="flex-start" alignContent="center">
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "200px" }}
                                    id="scheduleSelect"
                                    name="scheduleSelect"
                                    label="Scheduled Job"
                                    value={this.getScheduleSelectKey(selectedScheduleSummary ?? nullSchedule)}
                                    onChange={this.onScheduleSelect}>
                                    <MenuItem key={"shedxNull"} value={this.getScheduleSelectKey(nullSchedule)}>{`${nullSchedule.jobName}`}</MenuItem>
                                    {schedules.sort((a, b) => a.scheduleId - b.scheduleId).map(s =>
                                        <MenuItem key={"schedule_" + s.scheduleId} value={this.getScheduleSelectKey(s)}>{`${s.scheduleId}:${s.jobDetails?.scheduleName ?? s.jobName}`}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            {selectedScheduleSummary && selectedScheduleSummary.scheduleId !== 0 ?
                                <Grid item >
                                    <Button variant="outlined" className="PltfmButtonLite" startIcon={<SaveOutlined />} onClick={this.onClickSaveParams}>Save</Button>
                                </Grid> : null}
                            <Grid item >
                                <Button variant="outlined" className="PltfmButtonLite" startIcon={<AddOutlined />} onClick={() => this.setState({ showNewDialog: true })}>New</Button>
                            </Grid>
                        </Grid>
                        <div className="SchedulesTabDetail">
                            <Typography variant="h6">Schedule Detail</Typography>
                            {selectedScheduleSummary ? this.renderScheduleDetail(selectedScheduleSummary) : null}
                        </div>
                        <div className="SchedulesTabParameterDetail">
                            <Typography variant="h6">Parameters</Typography>
                            {selectedScheduleSummary ? this.renderParameterDetail() : null}
                        </div>
                        <div className="SchedulesTabParameterDetail" style={{ overflowY: "auto" }}>
                            <Typography variant="h6">Logs</Typography>
                            {logs && logs.length > 0 ? this.renderLogDetail(logs) : null}
                        </div>
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}