import { GridSpacing, Grid } from "@mui/material";
import React from "react";
import userStoreInstance from "../user/userStore";

export interface FormSectionProps {
    label?: React.ReactNode,
    children: React.ReactElement<any>[];
    spacing?: GridSpacing,
    justifyContent?: string,
    alignContent?: string,
    width?: string,
    height?: string,
    hideBottomBorder?: boolean
}

export class FormSection extends React.Component<FormSectionProps, {}>
{
    render() {
        if (this.props.label) {
            return (<Grid item container
                direction="column"
                spacing={this.props.spacing ?? 2}
                justifyContent={this.props.justifyContent ?? "flex-start"}
                alignContent={this.props.alignContent ?? "flex-start"}
                style={{ borderBottom: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, margin: "0.25em", padding: "0.25em", width: this.props.width ?? "90%", height: this.props.height }}>
                <Grid item key={`formSection-label-${this.props.label}`} height="100%">{this.props.label}</Grid>
                <Grid item container spacing={this.props.spacing ?? 2} direction="row">
                    {this.props.children.filter(c => c !== null && c !== undefined).map((c, ix) => <Grid key={`formSection-${c?.key}-${ix}`} item>{c}</Grid>)}
                </Grid>
            </Grid>)
        }
        else {
            return (<Grid item container 
                spacing={this.props.spacing ?? 2}
                justifyContent={this.props.justifyContent ?? "flex-start"}
                alignContent={this.props.alignContent ?? "flex-start"}
                style={this.props.hideBottomBorder ? { margin: "0.0em", padding: "0.25em", paddingTop:"0px", paddingBottom:"0.75em", width: this.props.width ?? "90%", height: this.props.height } : { borderBottom: "1px solid", borderColor: userStoreInstance.GetTheme().border_color, margin: "0.0em", padding: "0.25em", paddingTop:"0px", paddingBottom:"0.75em", width: this.props.width ?? "90%", height: this.props.height }}>
                {this.props.children.filter(c => c !== null && c !== undefined).map((c, ix) => <Grid key={`formSection-${c?.key}-${ix}`} item>{c}</Grid>)}
            </Grid>)
        }

    }
}

