//
export enum PriceCurveType { 
    linear = 0,
    lme = 0,
    next = 1,
    nymex = 1,
    nextButOnExpiry = 2,
    ice = 2,
    flat = 3,
    constant = 3
}

export enum SparsePriceCurveType { 
    coal = 0
}