import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { TradeStoreState, Trade, LoanDepo, TradeHistoryRecord } from './tradeModels';
import { CashTransactionLoadEvent, LoanDepoLoadEvent, NewTradeHistoryResultEvent, StrategiesAndBooksLoadEvent, TradeBatchEvent, TradeQueryBatchEvent, TradeUpdatedEvent } from './tradeActions';
import { CashTransaction } from '../nav/navModels';

class TradesStore extends FluxStore<TradeStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            trades: new Map<number, Trade>(),
            rfqToTrade: new Map<number, Trade>(),
            books: [],
            strategies: [],
            tradeQueries: new Map<string, Trade[]>(),
            errors: new Map<string, string>(),
            cashTransactions: [],
            loanDepos: [],
            tradeHistory: []
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof TradeUpdatedEvent) {
            const { payload } = action;
            this.state.trades.set(payload.tradeId, payload);
            if (payload.rfqId !== null)
                this.state.rfqToTrade.set(payload.rfqId, payload);
            this.emitChange();
        }
        else if (action instanceof TradeBatchEvent) {
            const { payload } = action;
            payload.forEach(trade => {
                this.state.trades.set(trade.tradeId, trade);
                if (trade.rfqId !== null)
                    this.state.rfqToTrade.set(trade.rfqId, trade);
            });
            this.emitChange();
        }
        else if (action instanceof TradeQueryBatchEvent) {
            const { payload } = action;
            if (payload.success) {
                this.state.tradeQueries.set(payload.uniqueId, payload.trades);
                this.state.errors.delete(payload.uniqueId);
            }
            else {
                this.state.tradeQueries.delete(payload.uniqueId);
                this.state.errors.set(payload.uniqueId, payload.status);
            }
            this.emitChange();
        }
        else if (action instanceof StrategiesAndBooksLoadEvent) {
            const { payload } = action;
            if (payload.metaValues) {
                if (payload.metaValues["Data"]["Books"])
                    this.state.books = payload.metaValues["Data"]["Books"];
                if (payload.metaValues["Data"]["Strategies"])
                    this.state.strategies = payload.metaValues["Data"]["Strategies"];
                this.emitChange();
            }

        }
        else if (action instanceof LoanDepoLoadEvent) {
            const { payload } = action;
            this.state.loanDepos = payload;
            this.emitChange();
        }
        else if (action instanceof CashTransactionLoadEvent) {
            const { payload } = action;
            this.state.cashTransactions = payload;
            this.emitChange();
        }
        else if (action instanceof NewTradeHistoryResultEvent) {
            const { payload } = action;
            this.state.tradeHistory = payload;
            this.emitChange();
        }
        //
    }

    getLatestForRfq(rfqId: number) { return this.state.rfqToTrade.get(rfqId); }

    getLatestForTradeId(tradeId: number) { return this.state.trades.get(tradeId); }

    getAllTrades(): Trade[] { return Array.from(this.state.trades.values()); }

    getStrategies(): string[] { return this.state.strategies; }

    getBooks(): string[] { return this.state.books; }

    getTradeQuery(uniqueId: string) { return this.state.tradeQueries.get(uniqueId); }

    getTradeQueryErrors(uniqueId: string) { return this.state.errors.get(uniqueId); }

    clearTradeQuery(uniqueId: string) { this.state.tradeQueries.delete(uniqueId); }

    getLoanDepos(): LoanDepo[] { return this.state.loanDepos };

    getCashTransactions(): CashTransaction[] { return this.state.cashTransactions };

    getTradeHistory(): TradeHistoryRecord[] { return this.state.tradeHistory };
}

const tradesStoreInstance = new TradesStore(AppDispatcher);
export default tradesStoreInstance;