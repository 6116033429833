import {
  Button,
  MenuItem,
  Grid,
  TextField,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SelectChangeEvent,
} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { BondCallFeatureType, BondCallScheduleRow, BondRedemptionType, BondSinkingScheduleRow } from "./listedInstrumentModels";
import { FieldArray, FieldArrayRenderProps, Form } from 'formik';
import { AddOutlined, CancelOutlined, CheckCircleOutline, ExpandMoreOutlined, RemoveOutlined } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import { Moment } from "moment";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { NumberFormatCustom } from "../inputs/numberFormatInput";

export const BondEditorForm = props => {
  const {
    values: {
      listedInstrumentId,
      description,
      ticker,
      isin,
      primaryExchange,
      ccy,
      callFeatureType,
      callSchedule,
      callNoticeDays,
      redemptionType,
      sinkingSchedule,
      maturity },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    className,
    handleSubmit,
    metaCollapsable
  } = props;

  const change = (name, e) => {
    e?.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onCallDateChange = (val: string, ix: number) => {
    var name = `callSchedule[${ix}].date`;
    setFieldValue(name, val)
    setFieldTouched(name, true, false);
  }

  const onCallPriceChange = (val: string, ix: number) => {
    var name = `callSchedule[${ix}].callPrice`;
    setFieldValue(name, Number(val))
    setFieldTouched(name, true, false);
  }

  const onSinkDateChange = (val: string, ix: number) => {
    var name = `sinkingSchedule[${ix}].date`;
    setFieldValue(name, val)
    setFieldTouched(name, true, false);
  }

  const onSinkAmountChange = (val: string, ix: number) => {
    var name = `sinkingSchedule[${ix}].sinkAmount`;
    setFieldValue(name, Number(val))
    setFieldTouched(name, true, false);
  }

  const onSinkPercentChange = (val: string, ix: number) => {
    var name = `sinkingSchedule[${ix}].sinkPercent`;
    setFieldValue(name, Number(val))
    setFieldTouched(name, true, false);
  }


  const onCallNoticeChange = (val: string) => {
    setFieldValue("callNoticeDays", Number(val))
    setFieldTouched("callNoticeDays", true, false);
  }

  const onDateChange = (date: Moment, name: string) => {
    setFieldValue(name, date?.toDate())
    setFieldTouched(name, true, false);
  }

  function onChangeCallFeatureType(e: SelectChangeEvent<any>) {
    var asStr = e.target.value;
    var asEnum: BondCallFeatureType = BondCallFeatureType[asStr as keyof typeof BondCallFeatureType];
    setFieldValue("callFeatureType", asEnum);
  }

  function onChangeRedemptionType(e: SelectChangeEvent<any>) {
    var asStr = e.target.value;
    var asEnum: BondRedemptionType = BondRedemptionType[asStr as keyof typeof BondRedemptionType];
    setFieldValue("redemptionType", asEnum);
  }

  let somethingChanged = touched && Array.from(Object.keys(touched)).length > 0;
  return (
    <Form onSubmit={handleSubmit} className={className}>
      <ThemeProvider theme={getFormTheme()}>
        <Grid container spacing={2} justifyContent="center" >
          <Grid item>
            <TextField
              style={{ width: "100px" }}
              size="small"
              variant="standard"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="Id"
              name="Id"
              helperText={errors.listedInstrumentId ? errors.listedInstrumentId : ""}
              error={errors.listedInstrumentId || Boolean(errors.listedInstrumentId)}
              label="Id"
              value={listedInstrumentId}
              onChange={() => { }}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
          <Grid item>
            <TextField
              style={{ width: "200px" }}
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="ticker"
              name="ticker"
              helperText={touched.ticker ? errors.ticker : ""}
              error={touched.ticker || Boolean(errors.ticker)}
              label="Ticker"
              value={ticker}
              onChange={change.bind(null, "ticker")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              style={{ minWidth: "350px" }}
              size="small"
              variant="outlined"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="description"
              name="description"
              helperText={touched.description ? errors.description : ""}
              error={touched.description || Boolean(errors.description)}
              label="Description"
              value={description}
              onChange={change.bind(null, "description")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="exchange"
              name="primaryExchange"
              helperText={touched.primaryExchange ? errors.primaryExchange : ""}
              error={touched.primaryExchange && Boolean(errors.primaryExchange)}
              label="Exchange"
              value={primaryExchange}
              onChange={change.bind(null, "primaryExchange")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              style={{ width: "100px" }}
              id="currency"
              name="currency"
              helperText={touched.ccy ? errors.ccy : ""}
              error={touched.ccy && Boolean(errors.ccy)}
              label="Currency"
              value={ccy}
              onChange={change.bind(null, "ccy")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="iSIN"
              name="isin"
              helperText={touched.isin ? errors.isin : ""}
              error={touched.isin || Boolean(errors.isin)}
              label="ISIN"
              value={isin ?? ""}
              onChange={change.bind(null, "isin")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <WrappedDatePicker
              size="small"
              id="maturity"
              name="maturity"
              helperText={touched.maturity ? errors.maturity : ""}
              error={touched.maturity || Boolean(errors.maturity)}
              label="Maturity"
              value={maturity}
              onChange={(d) => onDateChange(d, "maturity")} /></Grid>
          <GridBreak />
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <WrappedSelect
                variant="outlined"
                style={{ width: "140px" }}
                id="RedemptionType"
                name="RedemptionType"
                label="Redemption Type"
                value={redemptionType === undefined ? BondRedemptionType[0] : BondRedemptionType[redemptionType]}
                onChange={(e, c) => onChangeRedemptionType(e)}>
                {Object.keys(BondRedemptionType)
                  .filter(x => isNaN(Number(x)))
                  .map((v, ixx) => <MenuItem key={"brt" + ixx} value={v}>{v}</MenuItem>)}
              </WrappedSelect>
            </Grid>
            {(redemptionType===undefined || redemptionType===BondRedemptionType.Callable) && <Grid item>
              <WrappedSelect
                variant="outlined"
                id="CallType"
                name="CallType"
                label="Call Type"
                value={callFeatureType === undefined ? '' : BondCallFeatureType[callFeatureType]}
                onChange={(e, c) => onChangeCallFeatureType(e)}>
                {Object.keys(BondCallFeatureType)
                  .filter(x => isNaN(Number(x)))
                  .map((v, ixx) => <MenuItem key={"cft" + ixx} value={v}>{v}</MenuItem>)}
              </WrappedSelect>
            </Grid>}
            {(redemptionType===undefined || redemptionType===BondRedemptionType.Callable) && <Grid item>
              <TextField
                variant="outlined"
                classes={{ root: "ListedInstrumentEditorFormField" }}
                id={`callNoticeDays`}
                name={`callNoticeDays`}
                size="small"
                style={{ width: "140px" }}
                helperText={errors.callNoticeDays}
                error={errors.callNoticeDays}
                label="Call Notice (days)"
                value={callNoticeDays ?? ""}
                onFocus={event => {
                  event.target.select();
                }}
                onChange={(e) => onCallNoticeChange(e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: { input: "ListedInstrumentEditorFormFieldInner" },
                  inputComponent: NumberFormatCustom as any,
                }} />
            </Grid>}
            {redemptionType !== BondRedemptionType.Sinking &&
              redemptionType !== BondRedemptionType.Regular &&
              callFeatureType !== undefined &&
              callFeatureType !== BondCallFeatureType.Never
              && <Grid item width="30%">
                <Accordion className="ListedInstrumentEditorFormMetaDataContainer" defaultExpanded={true} disabled={true} >
                  <AccordionSummary
                    expandIcon={metaCollapsable ? <ExpandMoreOutlined className="ListedInstrumentEditorFormFieldInner" /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"                >
                    <Typography variant="h6">Call Schedule</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FieldArray name="callSchedule" render={(arrayHelpers: FieldArrayRenderProps) => (
                      <div className="InsMetaDataBox">
                        {callSchedule && callSchedule.length > 0 ? (
                          callSchedule.map((m: BondCallScheduleRow, ix) => {
                            return <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                              <Grid container spacing={1}>
                                <Grid item>
                                  <WrappedDatePicker
                                    size="small"
                                    id={`callSchedule[${ix}].date`}
                                    name={`callSchedule[${ix}].date`}
                                    helperText={errors.callSchedule ? errors.callSchedule[ix]?.date : ""}
                                    error={errors.callSchedule ? errors.callSchedule[ix]?.date : ""}
                                    label="CallDate"
                                    value={m.date}
                                    style={{ width: "140px" }}
                                    onChange={(d) => onCallDateChange(d?.format("yyyy-MM-DD"), ix)} />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id={`callSchedule[${ix}].callPrice`}
                                    name={`callSchedule[${ix}].callPrice`}
                                    size="small"
                                    style={{ width: "140px" }}
                                    helperText={errors.callSchedule ? errors.callSchedule[ix]?.callPrice : ""}
                                    error={errors.callSchedule ? errors.callSchedule[ix]?.callPrice : ""}
                                    label="Call Price"
                                    value={m.callPrice ?? ""}
                                    onFocus={event => {
                                      event.target.select();
                                    }}
                                    onChange={(e) => onCallPriceChange(e.target.value, ix)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                      inputComponent: NumberFormatCustom as any,
                                    }} />
                                </Grid>
                                <IconButton
                                  onClick={() => { arrayHelpers.remove(ix) }}
                                  className="ListedInstrumentEditorFormFieldInner"
                                  size="large">
                                  <RemoveOutlined color="inherit" />
                                </IconButton>
                              </Grid>
                            </div>
                          })
                        ) : null}
                        <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                          <Button variant="outlined" onClick={() => arrayHelpers.push({} as BondCallScheduleRow)} className="PltfmButtonLite">
                            <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                            <ListItemText>Add Call Date</ListItemText>
                          </Button>
                        </div>
                      </div>
                    )} />
                  </AccordionDetails>
                </Accordion>
              </Grid>}
              {redemptionType === BondRedemptionType.Sinking && <Grid item width="50%">
                <Accordion className="ListedInstrumentEditorFormMetaDataContainer" defaultExpanded={true} disabled={true} >
                  <AccordionSummary
                    expandIcon={metaCollapsable ? <ExpandMoreOutlined className="ListedInstrumentEditorFormFieldInner" /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography variant="h6">Sinking Schedule</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FieldArray name="sinkingSchedule" render={(arrayHelpers: FieldArrayRenderProps) => (
                      <div className="InsMetaDataBox">
                        {sinkingSchedule && sinkingSchedule.length > 0 ? (
                          sinkingSchedule.map((m: BondSinkingScheduleRow, ix) => {
                            return <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                              <Grid container spacing={1}>
                                <Grid item>
                                  <WrappedDatePicker
                                    size="small"
                                    id={`sinkingSchedule[${ix}].date`}
                                    name={`sinkingSchedule[${ix}].date`}
                                    helperText={errors.sinkingSchedule ? errors.sinkingSchedule[ix]?.date : ""}
                                    error={errors.sinkingSchedule ? errors.sinkingSchedule[ix]?.date : ""}
                                    label="Sink Date"
                                    value={m.date}
                                    style={{ width: "140px" }}
                                    onChange={(d) => onSinkDateChange(d?.format("yyyy-MM-DD"), ix)} />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id={`sinkingSchedule[${ix}].sinkAmount`}
                                    name={`sinkingSchedule[${ix}].sinkAmount`}
                                    size="small"
                                    style={{ width: "140px" }}
                                    helperText={errors.sinkingSchedule ? errors.sinkingSchedule[ix]?.sinkAmount : ""}
                                    error={errors.sinkingSchedule ? errors.sinkingSchedule[ix]?.sinkAmount : ""}
                                    label="Sink Amount"
                                    value={m.sinkAmount ?? ""}
                                    onFocus={event => {
                                      event.target.select();
                                    }}
                                    onChange={(e) => onSinkAmountChange(e.target.value, ix)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                      inputComponent: NumberFormatCustom as any,
                                    }} />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id={`sinkingSchedule[${ix}].sinkPercent`}
                                    name={`sinkingSchedule[${ix}].sinkPercent`}
                                    size="small"
                                    style={{ width: "140px" }}
                                    helperText={errors.sinkingSchedule ? errors.sinkingSchedule[ix]?.sinkAmount : ""}
                                    error={errors.sinkingSchedule ? errors.sinkingSchedule[ix]?.sinkAmount : ""}
                                    label="Sink Percent"
                                    value={m.sinkPercent ?? ""}
                                    onFocus={event => {
                                      event.target.select();
                                    }}
                                    onChange={(e) => onSinkPercentChange(e.target.value, ix)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                      inputComponent: NumberFormatCustom as any,
                                    }} />
                                </Grid>
                                <IconButton
                                  onClick={() => { arrayHelpers.remove(ix) }}
                                  className="ListedInstrumentEditorFormFieldInner"
                                  size="large">
                                  <RemoveOutlined color="inherit" />
                                </IconButton>
                              </Grid>
                            </div>
                          })
                        ) : null}
                        <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                          <Button variant="outlined" onClick={() => arrayHelpers.push({} as BondSinkingScheduleRow)} className="PltfmButtonLite">
                            <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                            <ListItemText>Add Sink Date</ListItemText>
                          </Button>
                        </div>
                      </div>
                    )} />
                  </AccordionDetails>
                </Accordion>
              </Grid>}
          </Grid>
          {/* <GridBreak /> */}
          <Grid item container spacing={2} justifyContent="center" >
            <Grid item>     <Button
              className="PltfmButtonLite"
              type="submit"
              variant="outlined"
              disabled={!isValid || !somethingChanged || isSubmitting}
              endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
            <Grid item> <Button
              className="PltfmButtonLite"
              type="reset"
              variant="outlined"
              endIcon={<CancelOutlined />}>Revert</Button></Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Form >
  );
};