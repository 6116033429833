import { Menu, MenuItem, TextField, Typography } from "@mui/material";
import moment from "moment";
import { Moment } from "moment";
import React, { ChangeEvent } from "react";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";

export type PltfmDateTime = {
    absolute: Date | undefined;
    relative: string | undefined;
}

type PltfmDateTimeState = {
    date: PltfmDateTime,
    anchorEl: any;
    mode: "absolute" | "relative"
}

export type PltfmDateTimePickerProps = {
    onChange: (date: PltfmDateTime) => void,
    onChangeMode?: (mode: "absolute" | "relative") => void,
    mode?: "absolute" | "relative",
    selectedDate: PltfmDateTime | undefined,
    disabled: boolean,
    label: string,
    minDate: Date,
    width?: string,
    size?: "small" | "medium",
    disableFuture?: boolean,
    emptyLabel?: string,
    clearLabel?: React.ReactNode,
}

export class PltfmDateTimePicker extends React.Component<PltfmDateTimePickerProps, PltfmDateTimeState>
{
    constructor(props) {
        super(props);
        if (this.props.selectedDate) {
            this.state = { date: this.props.selectedDate, anchorEl: undefined, mode: this.props.mode ?? (this.props.selectedDate.absolute || !this.props.selectedDate.relative ? "absolute" : "relative") };
        }
        else {
            this.state = { date: { relative: undefined, absolute: undefined }, anchorEl: undefined, mode: this.props.mode ?? "absolute" };
        }

        this.updateAbsoluteValue = this.updateAbsoluteValue.bind(this);
        this.updateRelativeValue = this.updateRelativeValue.bind(this);
        this.toggleMode = this.toggleMode.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    private updateAbsoluteValue(date: Moment, value?: string) {
        if (date && date.isValid) {
            this.setState({ date: { absolute: date.toDate(), relative: undefined } });
            this.props.onChange({ absolute: date.toDate(), relative: undefined });
        }
        else if (date === undefined || date === null) {
            this.setState({ date: { absolute: undefined, relative: undefined } });
            this.props.onChange({ absolute: undefined, relative: undefined });
        }
    }
    private updateRelativeValue(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        var val = e.currentTarget.value;
        this.setState({ date: { absolute: undefined, relative: val } });
        this.props.onChange({ absolute: undefined, relative: val });
    }

    onRightClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ anchorEl: e.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }

    toggleMode() {
        var date = this.state.date;
        var newMode = this.state.mode === "absolute" ? "relative" : "absolute" as "absolute" | "relative";
        if (newMode === "absolute")
            date.relative = null;
        else
            date.absolute = null;
        this.setState({ mode: newMode, anchorEl: null });
        if (this.props.onChangeMode)
            this.props.onChangeMode(newMode);
    }

    render() {
        const { anchorEl, date, mode } = this.state;
        const isAbsolute = mode === "absolute";
        return (
            <div onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onRightClick(e)}>
                {isAbsolute ?
                    <WrappedDatePicker
                        style={{ width: this.props.width ?? "200px" }}
                        disableFuture={this.props.disableFuture}
                        size={this.props.size}
                        value={date.absolute}
                        onChange={this.updateAbsoluteValue}
                        label={this.props.label}
                        emptyLabel={this.props.emptyLabel ?? "(None)"}
                        clearLabel={this.props.clearLabel ?? "(Clear)"}
                        minDate={this.props.minDate}
                    /> :
                    <TextField
                        variant="outlined"
                        classes={{ root: "ListedInstrumentEditorFormField" }}
                        style={{ width: this.props.width ?? "200px" }}
                        size={this.props.size}
                        id="relative"
                        name="relative"
                        placeholder={this.props.emptyLabel ?? "(None)"}
                        label={this.props.label}
                        value={date.relative ?? ""}
                        onChange={this.updateRelativeValue}
                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                }
                <Menu id="dateSwitchMenu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}                >
                    <MenuItem onClick={this.toggleMode}><Typography variant="subtitle1">Switch to {isAbsolute ? "Relative" : "Absolute"}</Typography> </MenuItem>
                </Menu>
            </div>
        )
    }
}

export function getMomentFromPltfmDate(asOf?: PltfmDateTime) {
    if (asOf === null || asOf === undefined || (asOf.absolute === undefined && asOf.relative === undefined))
        return undefined;


    if (asOf.absolute !== undefined && moment(asOf.absolute))
        return moment(asOf.absolute).startOf("day");

    //must be relative
    var r = asOf.relative;
    var n = 0;
    var t = "d";
    if (r?.toLowerCase().endsWith("d")) {
        n = Number(r.toLowerCase().split('d'))[0];
        t = "d";
    }
    else if (r?.toLowerCase().endsWith("b")) {
        n = Number(r.toLowerCase().split('b'))[0];
        t = "b";
    }
    else if (r?.toLowerCase().endsWith("m")) {
        n = Number(r.toLowerCase().split('m'))[0];
        t = "M";
    }
    else if (r?.toLowerCase().endsWith("w")) {
        n = Number(r.toLowerCase().split('w'))[0];
        t = "w";
    }

    var tdy = moment().startOf("d");
    if (t === "b") {
        var i = 0;
        if (n > 0)
            for (i = 0; i < n; n++) {
                tdy.add(1, "day");
                if (tdy.isoWeekday() > 5) //its a weekend
                {
                    tdy.add(1, "day");
                }
            }
        else
            for (i = 0; i > n; n--) {
                tdy.subtract(1, "day");
                if (tdy.isoWeekday() > 5) //its a weekend
                {
                    tdy.subtract(1, "day");
                }
            }
        return tdy
    }
    else
        tdy.add(n as moment.DurationInputArg1, t as moment.unitOfTime.DurationConstructor);

    return tdy.startOf("day");
}

export function getDateStrFromPltfmDate(asOf?: PltfmDateTime) {
    var d = getMomentFromPltfmDate(asOf);
    return d?.format("yyyy-MM-DD")
}

export function addWeekDays(d: Moment, n: number): Moment {
    var tdy = d.clone();
    var i = 0;
    if (n > 0)
        for (i = 0; i < n; i++) {
            tdy.add(1, "day");
            if (tdy.isoWeekday() === 6) //its a saturday
            {
                tdy.add(2, "day");
            }
            else if (tdy.isoWeekday() === 7) //its a a 
            {
                tdy.add(1, "day");
            }
        }
    else
        for (i = 0; i > n; i--) {
            tdy.subtract(1, "day");
            if (tdy.isoWeekday() === 6) //its a saturday
            {
                tdy.subtract(1, "day");
            }
            else if (tdy.isoWeekday() === 7) //its a sunday
            {
                tdy.subtract(2, "day");
            }
        }
    return tdy;
}

export function numberOfWeekDays(d: Moment, rd: Moment): number {
    var diffCalDays = d.startOf('day').diff(rd.startOf('day'), 'day');
    var cd = rd.clone(); //current date
    var i = 0;
    if (diffCalDays > 0) // date is later in time than reference date
    {
        while (cd.isBefore(d, 'days')) {
            i++;
            cd.add(1, 'day');
            if (cd.isoWeekday() === 6) //its a saturday
            {
                cd.add(2, "day");
            }
            else if (cd.isoWeekday() === 7) //its a a 
            {
                cd.add(1, "day");
            }
        }
    }
    else {
        while (cd.isAfter(d, 'days')) {
            i--;
            cd.subtract(1, 'day');

            if (cd.isoWeekday() === 6) //its a saturday
            {
                cd.subtract(1, "day");
            }
            else if (cd.isoWeekday() === 7) //its a sunday
            {
                cd.subtract(2, "day");
            }
        }
    }

    return i;
}
