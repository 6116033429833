import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import moment from 'moment';
import {
    Button,
    Grid,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import userStoreInstance from '../user/userStore';
import { RefreshOutlined } from '@mui/icons-material';
import { getFormTheme } from '../inputs/formCommon';
import { momentUtcToOADate, renderDate } from '../utils/helpers';
import { GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, DataGridPro, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import { TradeHistoryRecord } from './tradeModels';
import { GetTradeHistory } from './tradeActions';
import tradesStoreInstance from './tradeStore';
import { loading } from '../utils/loading';


interface TradeHistoryTableState {
    records: TradeHistoryRecord[],
    mainRef: RefObject<HTMLDivElement>,
    awaitingRefresh: boolean,
}

export interface TradeHistoryTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class TradeHistoryTable extends React.Component<TradeHistoryTableProps, TradeHistoryTableState>{
    eventSubscriptionPositions: FBEmitter.EventSubscription | undefined;
    constructor(props: TradeHistoryTableProps) {
        super(props)
        this.state = {
            records: [],
            mainRef: React.createRef(),
            awaitingRefresh: false,
        };;
        this.updateHistory = this.updateHistory.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionPositions = tradesStoreInstance.addChangeListener(this.updateHistory);
        this.updateHistory();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionPositions) {
            this.eventSubscriptionPositions.remove();
            this.eventSubscriptionPositions = undefined;
        }
    }


    async onClickRefresh() {
        this.setState({ awaitingRefresh: true, records: [] });
        await GetTradeHistory();
    }

    customToolbar(props: any) {
        const { awaitingRefresh } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "5px" }}>
                            <Grid container spacing={2} justifyContent="flex-start" alignContent="center">
                                <Grid item>
                                    <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} startIcon={<RefreshOutlined />} onClick={() => this.onClickRefresh()}>Calculate</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end" alignContent="center">
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" csvOptions={{ fileName: "pltfmTradeHistory", allColumns: true }} />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarDensitySelector {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    updateHistory() {
        let records = tradesStoreInstance.getTradeHistory();
        this.setState({ records, awaitingRefresh: false });
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
        }
        else
            return <div></div>;
    }

    renderPriceCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 6 })}</div>
        }
        else
            return <div></div>;
    }

    renderNumberCellInt(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toFixed(0)}</div>
        }
        else
            return <div></div>;
    }

    renderPercentCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, {style:'percent'})}</div>
        }
        else
            return <div></div>;
    }

    renderDateTimeCell(params: GridCellParams) {
        return Boolean(params.value) ? <span>{renderDate(new Date(params.value as string))}</span> : <span />;
    }

    getCellRenderer(key: string) {
        switch (key) {
            case "assetAttribution":
            case "attribution":
            case "asset":
            case "book":
            case "theme":
            case "code":
            case "ccy":
            case "tradeIndicator":
            case "bloombergTicker":
            case "name":
            case "instrumentType":
            case "exit":
            case "profitOrLoss":
            case "my":
            case "underlyingBeta":
                return null;
            case "tradeDate":
            case "openDate":
            case "closedDate":
                return this.renderDateTimeCell;
            case "pnLPct":
                return this.renderPercentCell;
            case "year":
            case "instrumentId":
                return this.renderNumberCellInt;
            case "openPx":
            case "closePx":
            case "fxClose":
            case "fxPaid":
                return this.renderPriceCell;
            default:
                return this.renderNumberCell;
        }
    }

    getCellType(key: string) {
        switch (key) {
            case "assetAttribution":
            case "attribution":
            case "asset":
            case "book":
            case "theme":
            case "code":
            case "ccy":
            case "tradeIndicator":
            case "bloombergTicker":
            case "name":
            case "instrumentType":
            case "exit":
            case "profitOrLoss":
            case "my":
            case "underlyingBeta":
                return "string";
            case "tradeDate":
            case "openDate":
            case "closedDate":
                return "date";
            default:
                return "number";
        }
    }

    getValueFormatter(key: string) {
        switch (key) {
            case "tradeDate":
            case "openDate":
            case "closedDate":
                return (({ value }) => value ? momentUtcToOADate(moment(value)) : null);
            default:
                return undefined;
        }
    }

    getWidth(key: string) {
        switch (key) {
            case "year":
            case "month":
            case "day":
            case "ccy":
            case "my":
            case "asset":
            case "tradeNumber":
            case "tradeIndicator":
            case "book":
                return 80;
            case "bloombergTicker":
            case "instrumentType":
            case "theme":
                return 175;
            case "name":
                return 200;
            case "code":
                return 150;
            default:
                return 120;
        }
    }

    getTitle(key: string) {
        switch (key) {
            case "my":
                return "M/Y";
            case "tradeIndicator":
                return "TI";
            case "tradeNumber":
                return "T#";
            default:
                return this.capitalizeFirstLetter(key);
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getColumnDef(field: string, headerName: string, width: number) {
        return {
            field: field,
            width: width,
            headerName: headerName,
            cellClassName: "PositionSummaryTabTableCell",
            headerClassName: "PositionSummaryTabTableCellHeader",
        };
    }

    render() {
        const { records, mainRef, awaitingRefresh } = this.state;

        var obj = new TradeHistoryRecord();
        const columns: GridColDef[] = [
            ...Array.from(Object.keys(obj)).map(k => {
                return {
                    field: k,
                    width: this.getWidth(k),
                    headerName: this.getTitle(k),
                    cellClassName: "PositionSummaryTabTableCell",
                    headerClassName: "PositionSummaryTabTableCellHeader",
                    renderCell: this.getCellRenderer(k),
                    valueFormatter: this.getValueFormatter(k),
                    type: this.getCellType(k)
                }
            }),
        ];

        const rows = records.map((r, ix) => {
            var row = { id: ix };
            Array.from(Object.keys(r)).forEach(k => {
                row[k] = r[k];
            });
            return row;
        }).filter(r => r !== null);

        return (
            <div className="TradeBlotterGrid" ref={mainRef}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getPositionTableTheme()}>
                        <DataGridPro
                            className="TradeBlotterDataGrid"
                            rows={rows}
                            columns={columns}
                            density={userStoreInstance.GetGridDensity()}
                            components={{
                                Toolbar: this.customToolbar,
                                LoadingOverlay: () => loading(undefined)
                            }}
                            loading={awaitingRefresh}
                            hideFooter
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}
