import { GridDensity } from '@mui/x-data-grid-pro';
import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore'
import { PltfmThemes, PltfmTheme } from '../themes/themes';
import { LoadClientFirmsEvent, LoadFirmsEvent } from './firmActions';
import { ChangeDateFormatEvent, ChangeDefaultGridDensityEvent, ChangeEcbotDisplayEvent, ChangeFallbackPriceWidthEvent, ChangeLiveThrottleEvent, ConfigDeletedEvent, LoadSqaudsEvent, LoadUserInfoEvent, LoadUserMetaEvent, LoadUserSummariesEvent, NewBulkConfigEvent, NewConfigEvent } from './userActions';
import { UserState, Firm, UserInfo, ConfigBlob } from './userModel';

class UserStore extends FluxStore<UserState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            userInfo: { name: "", roles: [], defaultSquad: null, firmId: null, userId: 0, environment: "" } as UserInfo,
            firmMap: new Map<number, Firm>(),
            firms: [],
            clientFirms: [],
            loaded: false,
            settings: { 
                themeName: "pltfm", 
                theme: PltfmThemes.find(t => t.themeName === "pltfm"), 
                dateFormat: 'DD-MM-yy', 
                gridDensity: "standard", 
                liveThrottle: 500, 
                fallbackPriceWidth: 0.02,
                displayEcbotPriceFractional: false },
            users: [],
            squads: [],
            existingMetaData: new Map<string, Map<string, string[]>>(),
            configs: new Map<string, ConfigBlob[]>()
        }))
        this.WriteThemeVarsToDom(PltfmThemes.find(t => t.themeName === "pltfm"));
    }

    public Themes = PltfmThemes.map(t=>t.themeName);
    public DateFormats = ['DD-MM-yy', 'DD-MMM-yy', 'yyyy-MM-DD', "DD/MM/yyyy", "DD/MM/yy", "MM/DD/yyyy", "MM/DD/yy"];

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadUserInfoEvent) {
            this.state.userInfo = action.payload;
            this.emitChange();
        } else if (action instanceof LoadFirmsEvent) {
            this.state.firms = action.payload;
            this.state.firmMap = new Map(action.payload.map(i => [i.firmId, i]))
            this.emitChange();
        } else if (action instanceof LoadClientFirmsEvent) {
            this.state.clientFirms = action.payload;
            this.emitChange();
        } else if (action instanceof LoadUserSummariesEvent) {
            this.state.users = action.payload;
            this.emitChange();
        } else if (action instanceof LoadSqaudsEvent) {
            this.state.squads = action.payload;
            this.emitChange();
        } else if (action instanceof LoadUserMetaEvent) {
            const { payload } = action;
            var metaCategories = new Array<string>();
            Object.keys(payload.metaValues).forEach(c => { metaCategories.push(c) });
            for (var i = 0; i < metaCategories.length; i++) {
                let c = metaCategories[i];
                this.state.existingMetaData.set(c, new Map<string, string[]>());
                var metaTypes = Object.keys(payload.metaValues[c]).map(k => k);
                for (var j = 0; j < metaTypes.length; j++) {
                    let k = metaTypes[j];
                    this.state.existingMetaData.get(c).set(k, payload.metaValues[c][k]);
                }
            }
            this.emitChange();
        }
        else if (action instanceof ChangeDateFormatEvent) {
            const { payload } = action;
            this.state.settings.dateFormat = payload;
            this.emitChange();
        }
        else if (action instanceof ChangeDefaultGridDensityEvent) {
            const { payload } = action;
            this.state.settings.gridDensity = payload;
            this.emitChange();
        } 
        else if (action instanceof ChangeLiveThrottleEvent) {
            const { payload } = action;
            this.state.settings.liveThrottle = payload;
            this.emitChange();
        } 
        else if (action instanceof ChangeFallbackPriceWidthEvent) {
            const { payload } = action;
            this.state.settings.fallbackPriceWidth = payload;
            this.emitChange();
        }
        else if (action instanceof ChangeEcbotDisplayEvent) {
            const { payload } = action;
            this.state.settings.displayEcbotPriceFractional = payload;
            this.emitChange();
        }  
        else if (action instanceof NewBulkConfigEvent) {
            action.payload.forEach(cfg => {
                var cfgsForType = this.state.configs.get(cfg.objectType);
                if (!cfgsForType) {
                    cfgsForType = new Array<ConfigBlob>();
                }
                cfgsForType = cfgsForType.filter(c => c.configId !== cfg.configId);
                cfgsForType.push(cfg);
                this.state.configs.set(cfg.objectType, cfgsForType);
            });
            this.emitChange();
        }
        else if (action instanceof NewConfigEvent) {
            const cfg = action.payload;
            var cfgsForType = this.state.configs.get(cfg.objectType);
            if (!cfgsForType) {
                cfgsForType = new Array<ConfigBlob>();
            }
            cfgsForType = cfgsForType.filter(c => c.configId !== cfg.configId);
            cfgsForType.push(cfg);
            this.state.configs.set(cfg.objectType, cfgsForType);
            this.emitChange();
        }
        else if (action instanceof ConfigDeletedEvent) {
            const cfg = action.payload;
            var cfgsForTypd = this.state.configs.get(cfg.objectType);
            if (!cfgsForTypd) {
                cfgsForTypd = new Array<ConfigBlob>();
            }
            cfgsForTypd = cfgsForTypd.filter(c => c.configId !== cfg.configId);
            this.state.configs.set(cfg.objectType, cfgsForTypd);
            this.emitChange();
        }
    }

    public GetUsers() {
        return this.state.users;
    }

    public GetUserNameForId(userId: number) {
        var u = this.state.users.filter(u=>u.userId===userId)[0];
        return u?.userInfo?.displayName ?? u?.name;
    }

    public GetSquads() {
        return this.state.squads;
    }

    public GetFirmMap() {
        return this.state.firmMap;
    }

    public GetFirms() {
        return this.state.firms;
    }

    public GetFirmById(firmId: number) {
        return this.state.firms.filter(f=>f.firmId===firmId)[0];
    }

    public GetClientFirms() {
        return this.state.clientFirms;
    }

    public GetUserInfo() {
        return this.state.userInfo;
    }

    public UserHasRole(role: string) {
        return this.GetUserInfo().roles.includes(role);
    }

    public IsInternalUser(): boolean {
        return this.state.userInfo.firmId === null || this.state.userInfo.firmId === undefined || this.state.userInfo.firmId === 0;
    }

    public GetSettings() {
        return this.state.settings;
    }

    public GetDateFormat() {
        return this.state.settings.dateFormat;
    }

    public GetGridDensity() {
        return this.state.settings.gridDensity;
    }

    public GetLiveThrottleMs() {
        return this.state.settings.liveThrottle;
    }

    public GetFallbackPriceWidth() {
        return this.state.settings.fallbackPriceWidth ?? 0.02;
    }

    public GetFractionalEcbotDisplay() {
        return this.state.settings.displayEcbotPriceFractional ?? false;
    }


    public SetTheme(theme: string) {
        this.state.settings.themeName = theme;
        this.state.settings.theme = PltfmThemes.filter(t => t.themeName === theme)[0];
        this.WriteThemeVarsToDom(this.state.settings.theme);
        this.emitChange();
    }

    public SetDateFormat(format: string) {
        this.state.settings.dateFormat = format;
        this.emitChange();
    }

    public SetGridDensity(density: GridDensity) {
        this.state.settings.gridDensity = density;
        this.emitChange();
    }

    public SetLiveThrottleMs(throttleMs: number) {
        this.state.settings.liveThrottle = throttleMs;
        this.emitChange();
    }

    public SetFallbackPriceWidth(width: number) {
        this.state.settings.fallbackPriceWidth = width;
        this.emitChange();
    }

    private WriteThemeVarsToDom(theme: PltfmTheme) {
        let root = document.documentElement;
        var keys = Object.keys(theme);
        root.style.setProperty(`--theme`, theme.themeName);
        keys.forEach(key => {
            var modifiedKey = key.replace(/_/g, "-");
            var value = theme[key];
            root.style.setProperty(`--${modifiedKey}`, value);
        });
    }

    public GetThemeName() {
        return this.state.settings.themeName;
    }

    public GetTheme() {
        return this.state.settings.theme ?? PltfmThemes.filter(t=>t.themeName==="pltfm")[0];
    }

    public GetStyle() {

    }

    getMetaData() {
        return this.state.existingMetaData;
    }

    getMetaDataCategories() {
        var categories = this.state.existingMetaData?.keys();
        return categories ? Array.from(categories) : new Array<string>();
    }

    getMetaDataTypesForCategory(category: string) {
        var types = this.state.existingMetaData?.get(category)?.keys();
        return types ? Array.from(types) : new Array<string>();
    }

    getMetaDataByType(category: string, type: string) {
        return this.state.existingMetaData?.get(category)?.get(type) ?? new Array<string>();
    }
    actionSwitchOrNull(action: string | undefined, element: JSX.Element) {
        return !action || (action && this.actionAllowed(action)) ? element : null;
    }

    actionSwitchMulti(action: (string | undefined)[], element: JSX.Element, altElement: JSX.Element) {
        return !action.every(a => !a) || action.some(a => this.actionAllowed(a)) ? element : altElement;
    }

    actionAllowed(action: string): boolean {
        return true;
        /*if (this.state.allowedActions)
            return this.state.allowedActions.includes(action);
        else
            return false;*/
    }

    isUserInRole(role: string): boolean {
        return this.state.userInfo.roles.includes(role);
    }

    getConfigsForType(type: string){
        return this.state.configs.get(type) ?? new Array<ConfigBlob>();
    }
}

const userStoreInstance = new UserStore(AppDispatcher);
export default userStoreInstance;

export function FirmName(firmId: number) {
    const firm = userStoreInstance.GetFirmMap().get(firmId);
    if (firm) {
        return firm.name;
    }
    return "";
}

export function FirmDisplayName(firmId: number) {
    const firm = userStoreInstance.GetFirmMap().get(firmId);
    if (firm) {
        return firm.displayName;
    }
    return "";
}

export function FirmShortName(firmId: number) {
    const firm = userStoreInstance.GetFirmMap().get(firmId);
    if (firm) {
        return firm.shortName;
    }
    return "";
}

export function Username() {
    return userStoreInstance.GetUserInfo().name;
}

export function IsDevEnvironment() {
    return userStoreInstance.GetUserInfo().environment === "Development";
}
