import {
  Button,
  Grid,
  TextField,
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import { Field, FieldArray, FieldArrayRenderProps, Form } from "formik";
import { AddOutlined, CancelOutlined, CheckCircleOutline, ExpandMoreOutlined, RemoveOutlined } from "@mui/icons-material";
import { FirmMetaData } from "../user/userModel";
import { getFormTheme, GridBreak } from "../inputs/formCommon";

export const ClientEditorForm = props => {
  const {
    values: { firmId,
      displayName,
      name,
      shortName,
      metaData },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    className,
    handleSubmit,
    metaCollapsable
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <CssBaseline>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={getFormTheme()}>
            <Grid container spacing={2} justifyContent="center" classes={{ root: "ListedInstrumentEditorFormGrid" }} >
              <Grid item>
                <TextField
                  variant="outlined"
                  classes={{ root: "ListedInstrumentEditorFormField" }}
                  id="displayName"
                  name="displayName"
                  helperText={touched.displayName ? errors.displayName : ""}
                  error={touched.displayName && Boolean(errors.displayName)}
                  label="Display Name"
                  value={displayName}
                  onChange={change.bind(null, "displayName")}
                  InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
             <Grid item>
                <TextField
                  variant="outlined"
                  classes={{ root: "ListedInstrumentEditorFormField" }}
                  id="name"
                  name="name"
                  helperText={touched.name ? errors.name : ""}
                  error={touched.name && Boolean(errors.name)}
                  label="Full Name"
                  value={name}
                  onChange={change.bind(null, "name")}
                  InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
             <Grid item>
                <TextField
                  variant="outlined"
                  classes={{ root: "ListedInstrumentEditorFormField" }}
                  id="shortName"
                  name="shortName"
                  helperText={touched.shortName ? errors.shortName : ""}
                  error={touched.shortName && Boolean(errors.shortName)}
                  label="Short Name"
                  value={shortName}
                  onChange={change.bind(null, "shortName")}
                  InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
              <GridBreak />
              <Accordion className="ListedInstrumentEditorFormMetaDataContainer" defaultExpanded={!metaCollapsable} disabled={!metaCollapsable} >
                <AccordionSummary
                  expandIcon={metaCollapsable ? <ExpandMoreOutlined className="ListedInstrumentEditorFormFieldInner" /> : null}
                  aria-controls="panel1a-content"
                  id="panel1a-header"                >
                  <Typography variant="h6">Meta Data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FieldArray name="metaData" render={(arrayHelpers: FieldArrayRenderProps) => (
                    <div>
                      {metaData && metaData.length > 0 ? (
                        metaData.map((m: FirmMetaData, ix) => (
                          <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                            <Grid container spacing={1}>
                              <Grid item><Field
                                component={TextField}
                                variant="outlined"
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id={`metaData[${ix}].type`}
                                name={`metaData[${ix}].type`}
                                helperText={touched.type ? errors.type : ""}
                                error={touched.type && Boolean(errors.type)}
                                label={"Label"}
                                value={m.type ?? ""}
                                onChange={change.bind(null, `metaData[${ix}].type`)}
                                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                              <Grid item><Field
                                component={TextField}
                                variant="outlined"
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id={`metaData[${ix}].data`}
                                name={`metaData[${ix}].data`}
                                helperText={touched.type ? errors.type : ""}
                                error={touched.type && Boolean(errors.type)}
                                label={"Data"}
                                value={m.data ?? ""}
                                onChange={change.bind(null, `metaData[${ix}].data`)}
                                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                              <IconButton
                                onClick={() => arrayHelpers.remove(ix)}
                                className="ListedInstrumentEditorFormFieldInner"
                                size="large">
                                <RemoveOutlined color="inherit" />
                              </IconButton>
                            </Grid>
                          </div>
                        ))
                      ) : null}
                      <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                        <Button variant="outlined" onClick={() => arrayHelpers.push({ firmId: firmId, lastUpdated: new Date() } as FirmMetaData)} className="PltfmButton">
                          <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                          <ListItemText>Add Meta Data</ListItemText>
                        </Button>
                      </div>
                    </div>
                  )} />
                </AccordionDetails>
              </Accordion>
              <GridBreak />
              <div style={{ display: "flex", justifyContent: "center", width: "80%", bottom: "0%" }}>
                <Button
                  className="PltfmButton"
                  type="submit"
                  variant="outlined"
                  disabled={!isValid}
                  endIcon={<CheckCircleOutline/>}>Submit</Button>
                <Button
                  className="PltfmButton"
                  type="reset"
                  variant="outlined"
                  endIcon={<CancelOutlined/>}>Revert</Button>
              </div>
            </Grid>
          </ThemeProvider>
        </StyledEngineProvider>
      </CssBaseline>
    </Form>
  );
};