import { Viewer  } from "@grapecity/activereports-react";
import { ReportDefinition } from "@grapecity/activereports/reportdesigner"
import { DownloadOutlined, CancelOutlined } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, Grid, MenuItem, DialogActions, Button, SelectChangeEvent } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import React, {useState} from "react";
import { getFormTheme } from "../inputs/formCommon";
import { WrappedSelect } from "../inputs/wrappedSelect";
import userStoreInstance from "../user/userStore";
import reportStoreInstance from "./reportStore";
import { Core } from "@grapecity/activereports";
import { GetToken } from "../utils/httpUtils";
import { ApiLocation } from "../globalConstants";

Core.setLicenseKey("galena.pltfm.app,349518242346337#B0EkXwYUT9sEaBRDb0pUSBplMiFDM7AzK64GavsmW5dFaPdkWDpFOQN4L7xmS9ImVmtWeT3yVDt4QJNkd4UkbHVnQIplehlke0ZDWah7TiNGS4k7ZrEHOqp5VnNVTvw4VX9GN4tUclZDaOllatJjeUtGMplVUnBXUDJmUkxEUapWaTZkboZFZPZGSWJ6Uh3kMClkRuVTQjRWVWF7dwY7ZQN4cCBVdwUzY5wkNn9Ec89mYwAnM0h7bwITQCBVMElnYrlzT72kauhGd7w4MrVlSrckWrEUbVRHU9YGNOR7NCl4YQNzMDpkMDdnWVdEMtt6ZLhEZiojITJCLiQEM5Q4NBFjI0ICSiwSMwYDNykjNwUTM0IicfJye&Qf35Vfi24QRpkI0IyQiwiIzYFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsICN4YDM9ADIxAzMwIjMwIjI0ICdyNkIsICcwFmLtZGdsBnLh9WZsF6ZiojIz5GRiwiIwBXYu4mZ4xGcAZXYnJiOiEmTDJCLiczMzYDNzIDNygTM5kDNzIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7h6RDFlYxdFZt34REVFaTZWQShVOlVFMuFGOB3WV9skbBZHR92CMR3COkV6UWJjN8QzZvsURqFVMrN6TxtkMyQDOyhTZDRjWV96Rlp7U5MTSv5We58EcysWdlpGV6siQ7n48v");

export const ReportViewerTab: React.FC = () => {
    const [loadDialogBoxOpen, setLoadDialogBoxOpen ] = useState(false);
    const [loadReportId, setLoadReportId ] = useState(undefined);

    const viewerRef = React.useRef<Viewer>();
    const token = React.useRef(undefined);

    React.useEffect(() => {
        token.current = GetToken();
        const viewerInstance = viewerRef.current?.Viewer;
        viewerInstance?.toolbar.addItem({
            key: "$load",
            //iconCssClass: "mdi mdi-help-circle",
            icon: {type: 'svg', content:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={userStoreInstance.GetTheme().color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"/></svg>},
            text: "Load Report",
            enabled: true,
            title: "Load Report",
            action: function () {
                setLoadDialogBoxOpen(true);
            },
        });
    }, []);

    function onClickLoad() {
        var report = reportStoreInstance.getReports().filter(r=>r.reportId===loadReportId)[0];
        if(report)
        {
            const tokenStr = ";Header$Authorization=Bearer " + token;
            report.blob = report.blob.replaceAll("[¬%API%¬]", ApiLocation);
            report.blob = report.blob.replaceAll("[¬%TOKEN%¬]", tokenStr);
            var arReport = JSON.parse(report.blob) as ReportDefinition;
            arReport.id = report.reportId.toString();
            
            const viewerInstance = viewerRef.current?.Viewer;
            viewerInstance?.open(arReport.definition);
        }
        
        setLoadDialogBoxOpen(false)
    }

    function onChangeSelectedReport(event: SelectChangeEvent<any>, child: React.ReactNode) {
        var v = event.target.value as number;
        if (v) {
            setLoadReportId(v);
        }
        else if (v === 0) {
            setLoadReportId(undefined);
        }
    }

    function renderLoadDialog (){
        var allReports = reportStoreInstance.getReports();
        return <ThemeProvider theme={getFormTheme()}>
            <Dialog open={loadDialogBoxOpen} >
                <DialogTitle>Load Report</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} paddingTop="0.5em">
                        <Grid item>
                            <WrappedSelect
                                label="Report"
                                value={loadReportId}
                                onChange={onChangeSelectedReport}
                                style={{width:"200px"}}
                            >
                                {allReports.map(r => <MenuItem key={r.reportId} value={r.reportId}>{r.name}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="PltfmButtonLite" onClick={onClickLoad} endIcon={<DownloadOutlined />}>Load</Button>
                    <Button className="PltfmButtonLite" onClick={() => setLoadDialogBoxOpen(false)} endIcon={<CancelOutlined />}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    }

    return (
        <div id="viewer-host" style={{ paddingTop: "5px", width: "100%", height: "calc(100vh - 100px)" }}>
            {renderLoadDialog()}
            <Viewer
                ref={viewerRef}
            />
        </div>
    );
};