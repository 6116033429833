import { TypedEvent, AppDispatcher } from "../dispatcher/appDispatcher";
import { get, post } from '../utils/httpUtils';
import { Alert } from './alertsModels';

export class LoadAlertsEvent extends TypedEvent<Alert[]> { }
export class CloseAlertEvent extends TypedEvent<Alert> { }
export class CloseAlertFailedEvent extends TypedEvent<Alert> { }
export class AlertUpdateEvent extends TypedEvent<Alert> { }

export async function LoadAlerts() {
    try {
        const alerts = await get<Alert[]>('Alerts');
        if (alerts.payload) {
            AppDispatcher.dispatch(new LoadAlertsEvent(alerts.payload));
        }
    }
    catch (err) {
        console.log("Failed to load alerts");
    }
}

export async function AlertUpdate(alert: Alert) {
    AppDispatcher.dispatch(new AlertUpdateEvent(alert));
}

export async function CloseAlert(alertId: number) {
    const response = await post<Alert>('Alerts/' + alertId.toString() + "/close", "");
    if (response.payload) {
        AppDispatcher.dispatch(new CloseAlertEvent(response.payload));
    }
    else {

        AppDispatcher.dispatch(new CloseAlertFailedEvent(response.payload));
    }
    return response.payload;
}