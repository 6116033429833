import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { ConversionFactorCollection, ProductSumamryPayload, ProductSummary } from './productModels'
import { get, put } from '../utils/httpUtils';

export class LoadCommonProductSummariesEvent extends TypedEvent<ProductSummary[]> { }
export class LoadProductSummariesEvent extends TypedEvent<ProductSummary[]> { }
export class LoadCommonProductSummariesFailedEvent extends TypedEvent<string> { }
export class LoadProductSummaryEvent extends TypedEvent<ProductSummary> { }
export class LoadProductSummaryFailedEvent extends TypedEvent<string>{ }
export class LoadConversionFactorsEvent extends TypedEvent<ConversionFactorCollection> { }
export class LoadConversionFactorsFailedEvent extends TypedEvent<string>{ }

export async function LoadCommonProductSummaries() {
    const products = await get<ProductSummary[]>("Product/Common");
    if (products.payload == null) {
        AppDispatcher.dispatch(new LoadCommonProductSummariesFailedEvent("No products returned from service"));
    }
    else {
        AppDispatcher.dispatch(new LoadCommonProductSummariesEvent(products.payload));
    }
}

export async function LoadConversionFactors() {
    const factors = await get<ConversionFactorCollection>("QuickRequestParser/standardUnitFactors");
    if (factors.payload == null) {
        AppDispatcher.dispatch(new LoadConversionFactorsFailedEvent("No conversion factors returned from service"));
    }
    else {
        AppDispatcher.dispatch(new LoadConversionFactorsEvent(factors.payload));
    }
}

export async function LoadProductSummary(productId: number) {
    const summary = await get<ProductSumamryPayload>("Product/" + productId + "/Summary");
    if (summary.payload == null) {
        AppDispatcher.dispatch(new LoadCommonProductSummariesFailedEvent("Failed to get product summary"));
    } else {
        AppDispatcher.dispatch(new LoadProductSummaryEvent({ spec: summary.payload.spec, productId: summary.payload.productId, description: summary.payload.description  } as ProductSummary));
    }
    return summary.payload;
}

export async function LoadProductSummaries(productIds: number[], signal?: AbortSignal): Promise<ProductSummary[]> {
    const summary = await put<ProductSumamryPayload[]>("Product/Summaries", productIds, signal);
    if (summary.payload === undefined) {
        AppDispatcher.dispatch(new LoadCommonProductSummariesFailedEvent("Failed to get product summary"));
        return new Array<ProductSummary>();
    } else {
        var sumz = summary.payload.map(s => { return { spec: s.spec, productId: s.productId, description: s.description } as ProductSummary });
        AppDispatcher.dispatch(new LoadProductSummariesEvent(sumz));
        // summary.payload.forEach(s => {
        //     AppDispatcher.dispatch(new LoadProductSummaryEvent({ spec: s.spec, productId: s.productId } as ProductSummary));
        // });
        return sumz;
    }

}
