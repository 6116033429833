import React, { FormEvent } from 'react';
import FBEmitter from 'fbemitter';
import { PltfmYellow } from '../globalConstants';
import { TextField } from '@mui/material';

export type ValidatedTextInputProps = {
    onChange: (value: string) => boolean;
    initialValue: string;
    placeholder: string | undefined;
    keyName: string,
    className: string | undefined;
    disabled?: boolean;
    style?: React.CSSProperties;
}

type ValidatedTextInputState = {
    currentValue: string,
    isValid: boolean,
    placeholder: string,
    initialValue: string
    borderString: string
}

const normalBorder = "1px lightgrey solid";
const invalidBorder = "2px red solid";
const changedBorder = "2px "+ PltfmYellow+" solid";

class ValidatedTextInputLarge extends React.Component<ValidatedTextInputProps, ValidatedTextInputState> {
    eventSubscriptionCredit: FBEmitter.EventSubscription | undefined;

    constructor(props: any) {
        super(props)
        this.state =
        {
            placeholder: this.props.placeholder ? this.props.placeholder : "Enter value...",
            isValid: this.props.onChange(this.props.initialValue),
            currentValue: this.props.initialValue,
            initialValue: this.props.initialValue,
            borderString: normalBorder
        };
    }

    public async componentDidMount() {

    }

    public updateValue(value: string) {
        var isValid = this.props.onChange(value);
        this.setState(
            {
                currentValue: value,
                isValid: isValid,
                borderString: !isValid ? invalidBorder : (value === this.state.currentValue ? normalBorder : changedBorder)
            });
    }

    public reset = () => {
        this.updateValue(this.state.initialValue);
        this.setState({ borderString: normalBorder });
    };


    public componentWillUnmount() {

    }

    onChange = (event?: FormEvent<HTMLElement>) => {
        if (event) {
            var val = (event.target as HTMLInputElement).value;
            this.updateValue(val);
        }
    }

    render() {
        return (
                <TextField
                    style={this.props.style}
                    size="medium"
                    variant="outlined"
                    className={this.props.className}
                    id={this.props.keyName}
                    value={this.state.currentValue}
                    onChange={this.onChange}
                    placeholder={this.state.placeholder}
                    disabled={this.props.disabled}
                    inputProps={{style: {fontSize: 50}}}/>
        );
    }
}

export default ValidatedTextInputLarge;