import {
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    ThemeProvider,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import FBEmitter from 'fbemitter';
import React from 'react';
import { UpdateListedInstrument } from './listedInstrumentActions';
import { ListedInstrumentEditorForm } from './listedInstrumentForm';
import { ListedInstrument } from './listedInstrumentModels';
import listedInstrumentStoreInstance from './listedInstrumentStore';
import { getFormTheme } from '../inputs/formCommon';
import { InstrumentTabProps } from './instrumentTab';

interface ListedInstrumentEditorSearchState {
    selectedInstrument: ListedInstrument | undefined,
    matchingInstruments: ListedInstrument[],
    allInstruments: ListedInstrument[],
    searchString: string
}

export class ListedInstrumentEditorSearch extends React.Component<InstrumentTabProps, ListedInstrumentEditorSearchState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: InstrumentTabProps) {
        super(props)
        this.state = {
            allInstruments: [],
            matchingInstruments: [],
            selectedInstrument: undefined,
            searchString: props.getState("insSimpleSearchString")
        };;

        this.filterInsOptions = this.filterInsOptions.bind(this);
        this.instrumentMatchesQuery = this.instrumentMatchesQuery.bind(this);
        this.onInsSelect = this.onInsSelect.bind(this);
        this.onChangeSearchString = this.onChangeSearchString.bind(this);
        this.updateInstruments = this.updateInstruments.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.updateInstruments);
        let allInstruments = listedInstrumentStoreInstance.getInstruments();
        this.onChangeSearchString(this.state.searchString, allInstruments);
        this.setState({ allInstruments });
    }

    async onSubmit(values: ListedInstrument, { setSubmitting, setTouched }: FormikHelpers<ListedInstrument>) {
        //console.log(JSON.stringify(values, null, 2));
        values.metaData.forEach(m => {
            listedInstrumentStoreInstance.insertMetaValueByType(m.category, m.type, m.data);
        });
        await UpdateListedInstrument(values);
        setSubmitting(false);
        setTouched({}, false);
    }

    updateInstruments() {
        let allInstruments = listedInstrumentStoreInstance.getInstruments();
        var selectedInstrument = allInstruments.filter(i => i?.listedInstrumentId === this.state.selectedInstrument?.listedInstrumentId)[0];
        var str = this.state.searchString;
        if (str) {
            var matches = new Array<ListedInstrument>();
            allInstruments.forEach(ins => {
                if (this.instrumentMatchesQuery(ins, str))
                    matches.push(ins);
            });

            this.setState({ allInstruments, selectedInstrument, matchingInstruments: matches });
        }
        else
            this.setState({ allInstruments, selectedInstrument });
    }

    private filterInsOptions(options: ListedInstrument[], state: any): ListedInstrument[] {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.instrumentMatchesQuery(o, query))
        return relevant;
    }

    private instrumentMatchesQuery(ins: ListedInstrument, query: string): boolean {
        if(ins===undefined)
            return false;
        const normalizedTitle = ins.description?.toLowerCase();
        const code = ins.ticker?.toLowerCase();
        const isin = ins.isin?.toLowerCase();
        const allMetaData = ins.metaData ? ins.metaData.map(am => am.data?.toLowerCase()).join(".") : "";
        const normalizedQuery = query?.toLowerCase();

        return `${ins.listedInstrumentId}. ${normalizedTitle}. ${isin} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    private onInsSelect(ins: ListedInstrument | string) {
        const { selectedInstrument } = this.state;
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            if (selectedInstrument && selectedInstrument.listedInstrumentId === solidIns.listedInstrumentId)
                this.setState({ selectedInstrument: undefined });
            else
                this.setState({ selectedInstrument: solidIns });
        }
    }

    private onChangeSearchString(str: string, ins?: ListedInstrument[]) {
        if (!ins)
            ins = this.state.allInstruments;
        var matches = new Array<ListedInstrument>();
        if (str) {
            ins.forEach(ins => {
                if (this.instrumentMatchesQuery(ins, str))
                    matches.push(ins);
            });

        }
        this.setState({ matchingInstruments: matches, searchString: str });
        this.props.onChangeState("insSimpleSearchString", str);
    }

    render() {
        const { matchingInstruments, selectedInstrument, searchString } = this.state;

        return (
            <div className="ListedInstrumentEditorTab">
                <div className="ListedInstrumentEditorTabSearch">
                        <ThemeProvider theme={getFormTheme()}>
                            <div className="ListedInstrumentEditorTabSearchTop">
                                <FormControl style={{ width: "80%" }}>
                                    <InputLabel variant="outlined">Search</InputLabel>
                                    <OutlinedInput label="Search" classes={{ root: "ListedInstrumentEditorFormFieldInner" }} onChange={(e) => this.onChangeSearchString(e.target.value)} value={searchString ?? ''} />
                                </FormControl>
                            </div>
                            <List key="ListedInstrumentEditorTabSearch">
                                {matchingInstruments.slice(0, 50).map(s => {
                                    const labelId = `checkbox-list-label-${s.description}`;
                                    return (
                                        <ListItem key={s.listedInstrumentId} selected={selectedInstrument && s.listedInstrumentId === selectedInstrument.listedInstrumentId} role={undefined} button onClick={() => this.onInsSelect(s)}>
                                            <ListItemText id={labelId} primary={s.description} />
                                        </ListItem>);
                                })}
                            </List>
                        </ThemeProvider>
                </div>
                <div className="ListedInstrumentEditorTabDisplay">
                    {selectedInstrument ?
                            <Formik
                                key={`insForm-${selectedInstrument.listedInstrumentId}`}
                                onSubmit={this.onSubmit}
                                enableReinitialize={true}
                                initialValues={selectedInstrument}
                                validate={() => ({})}>
                                {props => <ListedInstrumentEditorForm className="ListedInstrumentEditorTabForm" {...props} />}
                            </Formik>
                        : null}
                </div>
            </div>
        );
    }
}
