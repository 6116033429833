export type OrdersState = {
    orders: Order[];
    venues: { [venue: string]: Venue };
    alerts: OrderAlert[];
    history: { [orderId: number]: OrderHistory[] };
}

export interface Venue {
    name: string,
    capabilities: { [capability: string]: string },
    venueStatus: VenuStatus,
}

export interface Order {
    orderId: number,
    externalSystemId: string,
    externalSystem: string,
    lastAction: string,
    orderSource: OrderSource,
    productId: number | null,
    listedInstrumentId: number | null,
    orderType: OrderType,
    orderStatus: OrderStatus,
    orderVenue: string,
    isBuyOrder: boolean,
    timeInForce: GoodUntil,
    goodUntil: Date | null,
    partialFillsAllowed: boolean,
    partialFillBlockSize: number,
    size: number,
    filled: number,
    limitLevel: number,
    stopLevel: number,
    sizeFilled: number,
    createdById: number,
    created: Date,
    lastUpdatedById: number,
    blobData: string,
    lastUpdated: Date,
    path: string,
    parentOrderId: number | undefined,
    orderReferenceId: string | undefined,
    approvalMessage: string | undefined,
    comments: OrderComment[] | undefined,
    referencePrice: number,
    isTerminalState: number,
    rowVersion: number,
    account: string,
    orderInstructions: string,
    leg2InstrumentId: number,
    leg2SizeRatio: number,
    leg2Account: string,
    leg2OrderVenue: string,
    isNativeSpread: boolean;
    trailingStop: number;
    trailingStopPercentage: boolean;
    
    //UI-side only
    insDescription: string;
    orderTypeString: string;
    orderStatusString: string;

    orderUnderlying: string;
}

export interface OrderHistory extends Order {
    periodStart: Date,
    periodEnd: Date
}

export interface OrderResponse {
    order: Order;
    errorMessage: string;
}

export enum OrderType {
    Invalid = -1,
    Limit = 0,
    Market = 1,
    Stop = 2,
    StopLimit = 3,
    LimitOnClose = 4,
    MarketOnClose = 5,
    TrailingStop = 6,
    TrailingStopLimit = 7,
    FixingOrder = 8,
    MarketIfTouched = 9, //as stop, but buying below/selling above the market
    LimitIfTouched = 10, //as stop-limit, but buying below/selling above the market
    TrailingLimitIfTouched = 11,
    TrailingMarketIfTouched = 12,
    LeggedSpreadLimit = 14,
    LeggedSpreadMarket = 16,
}

export enum OrderSource {
    ExternalOrder,
    PltfmOrder
}

export enum VenuStatus {
    Healthy = 0,
    NotInitialised = 1,
    Disconnected = 2,
}

export enum OrderStatus {
    Created = 0,
    Filled = 1,
    PartiallyFilled = 2,
    Active = 3,
    Waiting = 4,
    Paused = 5,
    Rejected = 6,
    Cancelled = 7,
    PendingCancel = 8,
    PendingManualApproval = 9,
    Inactive = 10,
}

export interface OrderComment {
    text: string;
    userId: number;
    created: string;
}

export const LiveOrderStatuses: OrderStatus[] = [OrderStatus.PartiallyFilled, OrderStatus.Active, OrderStatus.Waiting, OrderStatus.Paused];

export interface OrderQuestionResponse {
    order: Order;
    allow: boolean;
}

export interface OrderAlert {
    orderAlertId: number;
    venue: string;
    message: string;
    extraData: string;
    alertType: OrderAlertType;
    pltfmOrderId: number;
    orderVenueDetails: string;
    created: string;
    handled: string;
    handledBy: number;
    handledComment: string;
    severity: LogLevel;
    action: OrderAlertAction;
}

export enum OrderAlertType {
    Recon,
    PretradeCheck
}

export enum OrderAlertAction {
    AutomaticallyFixed,
    RequiresIntervention
}

export enum GoodUntil {
    GoodUntilCancelled = 0,
    GoodForDay = 1,
    ImmediateOrCancel = 2,
    FillOrKill = 3,
    Time = 4
}

export enum LogLevel {
    Trace,
    Debug,
    Information,
    Warning,
    Error,
    Critical,
    None
}

export interface LeggedOrderDetails {
    leg1SliceSize: number;
    aggressiveness: LeggedOrderAggressiveness;
}

export enum LeggedOrderAggressiveness {
    Passive,
    Aggressive
}