import React from 'react';
import FBEmitter from 'fbemitter';
import { WrappedPayoff } from '../../payoff/models/wrappedPayoff';
import productStoreInstance from '../../product/productStore';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { CurrencyConversionType } from '../../qwack/currencyConversionType';
import { expandNumberAbbreviationTerms, nanStringToEmptyString } from './productDesignerCommon';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Typography } from '@mui/material';
import { subRowProps, subCellProps, subTitleProps, subTitleVariant, subValueProps } from './helpers';

export type NotionalAndCurrencyBoxProps = {
    updateWrapperDetail: (detail: WrappedPayoff) => void,
    initialDetail: WrappedPayoff,
    disabled: boolean
}

export type NotionalAndCurrencyBoxState = {
    detail: WrappedPayoff,
    availableCurrencies: string[]
    availableConversionTypes: string[]
}


export class NotionalAndCurrencyBox extends React.Component<NotionalAndCurrencyBoxProps, NotionalAndCurrencyBoxState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: NotionalAndCurrencyBoxProps) {
        super(props);
        this.state = {
            detail: this.props.initialDetail ?? {} as WrappedPayoff,
            availableCurrencies: [],
            availableConversionTypes: []
        }

        this.onChangeNominal = this.onChangeNominal.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.onChangeFxConversion = this.onChangeFxConversion.bind(this);
    }

    public componentWillUnmount() {

    }

    componentDidMount() {
        let availableCurrencies = productStoreInstance.getAvailableCurrencies();
        this.setState({ availableCurrencies });
        this.fillPossibleWrappers();

        const { detail } = this.state;
        let somethingChanged = false;
        if (!detail.currencyConversionType) {
            detail.currencyConversionType = CurrencyConversionType.Quanto;
            somethingChanged = true;
        }
        if (!detail.nominal) {
            detail.nominal = 1e6;
            somethingChanged = true;
        }
        if (!detail.payoffCurrency) {
            detail.payoffCurrency = "USD";
            detail.currencyConversionType = CurrencyConversionType.None;
            somethingChanged = true;
        }

        if (somethingChanged)
            this.setState({ detail });
    }

    fillPossibleWrappers = () => {
        const availableConversionTypes = Object.keys(CurrencyConversionType).filter(k => typeof CurrencyConversionType[k as any] === "number");
        this.setState({ availableConversionTypes });
    }

    onChangeNominal(valueAsNumber: number, valueAsString?: string) {
        let result = valueAsString;
        result = expandNumberAbbreviationTerms(result);
        result = nanStringToEmptyString(result);
        var val = parseFloat(result).valueOf();
        if (!isNaN(val)) {
            const { detail } = this.state;
            detail.nominal = val;
            this.setState({ detail });
            this.props.updateWrapperDetail(detail);
        }
    }

    onChangeCurrency(val: string) {
        const { detail } = this.state;
        detail.payoffCurrency = val;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }

    onChangeFxConversion(val: string) {
        const { detail } = this.state;
        var ct = (CurrencyConversionType as any)[val];
        detail.currencyConversionType = ct;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }

    render() {
        const { detail, availableCurrencies, availableConversionTypes } = this.state;

        if (availableCurrencies.length === 0 || availableConversionTypes.length === 0)
            return <CircularProgress />

        return (<Grid item container paddingTop="0.25em">
        <Grid {...subRowProps} >
            <Grid {...subCellProps} width={310}>
                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Notional</Typography></Grid>
                <Grid {...subValueProps}><ValidatedNumericInput style={{width:"200px"}} units={detail.payoffCurrency} keyName="notional" placeholder="1m" initialValue={detail.nominal} onChange={(v) => { this.onChangeNominal(v); return true; }} disabled={this.props.disabled} /></Grid>
            </Grid>
            <Grid {...subCellProps} width={200}>
                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Currency</Typography></Grid>
                <Grid {...subValueProps} width={90}><StringTypeDropDown style={{width:"90px"}} key={"ccyDD"} possibles={availableCurrencies} onChange={this.onChangeCurrency} selected={detail.payoffCurrency} disabled={this.props.disabled} /></Grid>
            </Grid>
            <Grid {...subCellProps}>
                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Fx Convert</Typography></Grid>
                <Grid {...subValueProps}><StringTypeDropDown key={"fxDD"} possibles={availableConversionTypes} onChange={this.onChangeFxConversion} selected={CurrencyConversionType[detail.currencyConversionType]} disabled={this.props.disabled} /></Grid>
            </Grid>
        </Grid>
    </Grid>);
    }

}

export default NotionalAndCurrencyBox;