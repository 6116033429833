import React from 'react';
import FBEmitter from 'fbemitter';
import { InboxItem } from './inboxModels';
import inboxStoreInstance from './inboxStore';
import { InboxComponentProps, InboxComponentState } from './inbox';
import moment, { Moment } from 'moment-timezone';
import StringEnumSelector from '../inputs/stringEnumSelector';
import { Button, ButtonGroup, Collapse, Divider } from '@mui/material';


export class InboxDualPane extends React.Component<InboxComponentProps, InboxComponentState>{
    eventSubscriptionInbox: FBEmitter.EventSubscription | undefined;

    constructor(props: InboxComponentProps) {
        super(props);
        this.state = {
            inboxItems: [],
            loading: true,
            onlyShowCurrent: false,
            selectedItem: undefined,
            showFilterPanel: false,
            tagsToFilterOn: new Array<string>(),
            updated: false,
            height: 0,
            mainRef: React.createRef()
        }
    }

    public async componentWillMount() {
        this.eventSubscriptionInbox = inboxStoreInstance.addChangeListener(() => this.onChange());
        this.onChange();
    }

    componentDidMount() {
        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionInbox) {
            this.eventSubscriptionInbox.remove();
            this.eventSubscriptionInbox = undefined;
        }
    }

    public componentDidUpdate() {
        if (this.props.onChangeHeight) {
            let newHeight = this.state.mainRef?.current?.clientHeight;
            if (newHeight && newHeight !== this.state.height) {
                this.props.onChangeHeight(newHeight);
                this.setState({ height: newHeight });
            }
        }
    }

    onChange() {
        var itemsNew = inboxStoreInstance.getInboxItems();
        if (this.state.selectedItem) {
            var selectedId = this.state.selectedItem.inboxId;
            var newSelectedItem = itemsNew.filter(i => i.inboxId === selectedId)[0];
            this.setState({ inboxItems: itemsNew, selectedItem: newSelectedItem })
        }
        else {
            this.setState({ inboxItems: itemsNew })
        }
    }

    onClickOnlyShowCurrent = () => {
        this.setState({ onlyShowCurrent: !this.state.onlyShowCurrent });
    }

    onRightClick(mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>, inboxItem: InboxItem) {
        // ContextMenu.show((<Menu><MenuItem text={inboxItem.subject}></MenuItem></Menu>), {left: mouseEvent.clientX, top : mouseEvent.clientY});
        // mouseEvent.preventDefault();
    }

    renderRow(inboxItem: InboxItem) {
        return (
            <div key={`inbox_${inboxItem.uniqueKey}`} className="InboxRow" style={{ border: inboxItem === this.state.selectedItem ? "lightblue 1px solid" : "none", cursor: "pointer" }} onContextMenu={e => this.onRightClick(e, inboxItem)} onClick={() => this.setState({ selectedItem: inboxItem })}>
                <div>{inboxItem.subject}</div>
                <div style={{ textAlign: "right", fontSize: "smaller" }}>{this.renderTimeStamp(moment.tz(inboxItem.lastActionStampUtc, "UTC"))}</div>
            </div>
        )
    }

    renderTimeStamp(stamp: Moment) {
        if (stamp.date() === moment().date())
            return stamp.format("HH:mm:ss")
        else
            return stamp.format("HH:mm:ss DD-MM")
    }

    onChangeTags = (tags: string[]) => {
        if (tags.length === 0) {
            this.setState({ tagsToFilterOn: new Array<string>() });
        }
        else {
            this.setState({ tagsToFilterOn: tags });
        }
    }

    getTags = () => {
        let allTags = this.state.inboxItems.flatMap(x => x.tags);
        let uniqueTags = Array.from(new Set(allTags));
        return uniqueTags;
    }

    onClickRespond = (verb: string) => {
        if (this.state.selectedItem) {
            //RespondToRequest(verb,this.state.selectedItem.actionKey);
        }
    }

    renderSelectedItem = () => {
        if (this.state.selectedItem) {
            return (<div style={{ padding: "2px" }}>
                <pre>{this.state.selectedItem.payload}</pre>
                {this.state.selectedItem.actionKey === null || this.state.selectedItem.actionKey === "" ?
                    null :
                    <ButtonGroup>
                        <Button color="primary" variant="text" onClick={() => this.onClickRespond("Reject")}>Reject</Button>
                        <Button color="secondary" variant="text" onClick={() => this.onClickRespond("Approve")}>Approve</Button>
                    </ButtonGroup>}
            </div>);
        }
        else {
            return (<div></div>);
        }
    }

    renderItemList = () => {
        const items = this.state.inboxItems;
        if (items.length === 0) {
            return (<div className="InboxRow">No messages to display</div>)
        }
        else {
            if (this.state.tagsToFilterOn.length > 0) {
                var filteredItems = items.filter(f => {
                    return f.tags.some(t => {
                        let include = false;
                        if (this.state.tagsToFilterOn && this.state.tagsToFilterOn.includes(t)) {
                            include = true;
                        }
                        return include;
                    });

                });
                return filteredItems.sort((a, b) => new Date(a.lastActionStampUtc) > new Date(b.lastActionStampUtc) ? -1 : 1).map(i => this.renderRow(i));
            }
            else {
                return items.sort((a, b) => new Date(a.lastActionStampUtc) > new Date(b.lastActionStampUtc) ? -1 : 1).map(i => this.renderRow(i));
            }
        }

    }

    render() {
        let headerHeight = this.state.showFilterPanel ? 36 + 30 : 30;
        return (
            <div className="Inbox" ref={this.state.mainRef}>
                <div className="InboxHeader">
                    <div className="InboxHeader FilterButton" >
                        <Button variant={"text"} onClick={() => this.setState({ showFilterPanel: !this.state.showFilterPanel })}>
                            <div className="FilterButtonInner">Filter</div>
                        </Button>
                    </div>
                </div>
                <div >
                    <Collapse in={this.state.showFilterPanel}>
                        <div style={{ display: "inline-flex", width: "100%", backgroundColor: "whitesmoke", height: "36px", padding: "2px" }}>
                            <div style={{ width: "50%", paddingLeft: "5px" }}><StringEnumSelector
                                onChange={this.onChangeTags}
                                fetchValueList={this.getTags}
                                initiallySelected={this.state.tagsToFilterOn}
                                placeholder="Select tags to filter on..." /></div>
                        </div>
                    </Collapse>
                </div>
                <div style={{ display: "inline-flex", width: "100%", height: `calc(100% - ${headerHeight}px)` }}>
                    <div style={{ display: "block", width: "49%", height: "100%", float: "left", overflowY: "auto", alignItems: "center" }}>
                        {this.renderItemList()}
                    </div>
                    <Divider />
                    <div style={{ display: "block", width: "49%", float: "right" }}>
                        {this.renderSelectedItem()}
                    </div>
                </div>
            </div>
        );
    }
}