import { Component } from 'react';
import FBEmitter from 'fbemitter';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import marketDataLiveStoreInstance from '../../marketData/marketDataLiveStore';

export interface PriceTickerProps {
  onChangeState: (key: string, value: string) => void;
}

interface PriceTickerState {
  lastTick: number,
  assetKey: string,
  lastUpdated: Date,
  updateSpeedMs: number,
  availableAssetKeys: string[]
}

const slowUpdateMs = 500;
const fastUpdateMs = 10;

const tickerToInsId = { BTC: 200565, ETH: 196722}
//const testTickers = ["BTC", "ETH"];

class PriceTicker extends Component<PriceTickerProps, PriceTickerState>{
  eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
  constructor(props: PriceTickerProps) {
    super(props);
    this.state = {
      lastTick: undefined,
      assetKey: null,
      lastUpdated: new Date(),
      updateSpeedMs: slowUpdateMs,
      availableAssetKeys: []
    }
    this.onChangeAsset = this.onChangeAsset.bind(this);
  }

  async componentDidMount() {
    //let tickers = await userSessionConnection.getAvailableInsIds();
    let tickers = ["BTC","ETH"];
    this.setState({availableAssetKeys:tickers, assetKey:tickers[0]});
    this.eventSubscriptionTicks = marketDataLiveStoreInstance.addChangeListener(() => this.onChange());
    marketDataLiveStoreInstance.SubscribeToTickStream(tickerToInsId[tickers[0]]);
  }

  componentWillUnmount() {
    if (this.eventSubscriptionTicks) {
      this.eventSubscriptionTicks.remove();
      this.eventSubscriptionTicks = undefined;
    }
    marketDataLiveStoreInstance.UnSubscribeToTickStream(tickerToInsId[this.state.assetKey]);
  }

  onChange() {
    var currentTick = marketDataLiveStoreInstance.getTick(tickerToInsId[this.state.assetKey]);
    var now = new Date();
    var diff = now.getTime() - this.state.lastUpdated.getTime();
    if (currentTick !== this.state.lastTick && diff > this.state.updateSpeedMs) {

      this.setState({ lastTick: currentTick, lastUpdated: now });
    }
  }

  onChangeAsset(newAsset: string) {
    marketDataLiveStoreInstance.UnSubscribeToTickStream(tickerToInsId[this.state.assetKey]);
    this.setState({ assetKey: newAsset });
    marketDataLiveStoreInstance.SubscribeToTickStream(tickerToInsId[newAsset]);
  }

  render() {
    if (this.state.lastTick) {
      return (
        <div className="PriceTicker" onMouseEnter={() => this.setState({ updateSpeedMs: fastUpdateMs })} onMouseLeave={() => this.setState({ updateSpeedMs: slowUpdateMs })}>
          <div className="PriceTickerInner">
            <span className="PriceTickerPrice">{this.state.lastTick?.toFixed(2)}</span>
          </div>
          <div className="PriceTickerPicker">
            <StringTypeDropDown key={"tickerPicker"} possibles={this.state.availableAssetKeys} onChange={this.onChangeAsset} selected={this.state.assetKey} disabled={false} />
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="PriceTicker">
          <div className="PriceTicker">
            <span className="PriceTickerPrice">Waiting...</span>
          </div>
        </div>);
    }

  }
}
export default PriceTicker;