import { Interpolator1DType } from "../interpolator1DType";
import LinearInterpolator from "./linearInterpolator";

export default class LinearFlatExtrapInterpolator extends LinearInterpolator {

    Type: Interpolator1DType = Interpolator1DType.linearFlatExtrap;

    public Interpolate(t: number) {
        if (this._x.length === 1) {
            return this._y[0];
        }
        else if (t < this._minX) {
            return this._y[0];
        }
        else if (t > this._maxX) {
            return this._y[this._y.length - 1];
        }
        else {
            var k = this.findFloorPoint(t);
            return this._y[k] + (t - this._x[k]) * this._slope[k];
        }
    }

    public FirstDerivative(t: number) {
        if (t <= this._minX) {
            return 0;
        }
        if (t >= this._maxX) {
            return 0;
        }
        else {
            var k = this.findFloorPoint(t);
            return this._slope[k];
        }
    }

    public SecondDerivative(x: number): number {
        if (!this._x.includes(x))
            return 0;
        var k = this.findFloorPoint(x);
        if (k === 0)
            return 0.5 * this._slope[0];
        if (k === this._x.length)
            return 0.5 * this._slope[this._slope.length];

        return (this._slope[k] + this._slope[k - 1]) / 2.0;
    }
}