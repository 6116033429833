import { GridDensity } from '@mui/x-data-grid-pro';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import { MetaDataQueryResponse } from '../trade/tradeModels';
import { get, put } from '../utils/httpUtils';
import { ConfigBlob, Squad, UserInfo, UserSummary } from './userModel';

export class LoadUserInfoEvent extends TypedEvent<UserInfo> { }
export class LoadUserInfoFailedEvent extends TypedEvent<string> { }
export class LoadUserSummariesEvent extends TypedEvent<UserSummary[]> { }
export class LoadSqaudsEvent extends TypedEvent<Squad[]> { }
export class LoadUserMetaEvent extends TypedEvent<MetaDataQueryResponse> { }
export class ChangeDateFormatEvent extends TypedEvent<string> { }
export class ChangeDefaultGridDensityEvent extends TypedEvent<GridDensity> { }
export class ChangeLiveThrottleEvent extends TypedEvent<number> { }
export class ChangeFallbackPriceWidthEvent extends TypedEvent<number> { }
export class ChangeEcbotDisplayEvent extends TypedEvent<boolean> { }
export class NewConfigEvent extends TypedEvent<ConfigBlob> { }
export class NewBulkConfigEvent extends TypedEvent<ConfigBlob[]> { }
export class ConfigDeletedEvent extends TypedEvent<ConfigBlob> { }

export async function LoadUserInfo() {
    const userInfo = await get<UserInfo>("User");
    if (userInfo.payload == null) {
        AppDispatcher.dispatch(new LoadUserInfoFailedEvent("There was an error loading the user info"));
        UpdateLoadingStatus("UserInfo", LoadingStatus.Failed);
    } else {
        AppDispatcher.dispatch(new LoadUserInfoEvent(userInfo.payload))
        UpdateLoadingStatus("UserInfo", LoadingStatus.Completed);
    }

    const userSummary = await get<UserSummary[]>("User/all");
    if (userSummary.payload == null) {
        AppDispatcher.dispatch(new LoadUserInfoFailedEvent("There was an error loading user summaries"));
    } else {
        AppDispatcher.dispatch(new LoadUserSummariesEvent(userSummary.payload));
        
    }

    const squads = await get<Squad[]>("User/squads");
    if (squads.payload == null) {
        AppDispatcher.dispatch(new LoadUserInfoFailedEvent("There was an error loading squads"));
    } else {
        AppDispatcher.dispatch(new LoadSqaudsEvent(squads.payload));       
    }

    const meta = await get<MetaDataQueryResponse>("User/ExistingMetaValue");
    if (meta.payload == null) {
        AppDispatcher.dispatch(new LoadUserInfoFailedEvent("Failed to load the existing user meta data"));
    }
    else {
        AppDispatcher.dispatch(new LoadUserMetaEvent(meta.payload));
    }
}

export function SetDateFormat(dateFormat: string) {
    AppDispatcher.dispatch(new ChangeDateFormatEvent(dateFormat));
}

export function SetDefaultGridDensity(density: GridDensity) {
    AppDispatcher.dispatch(new ChangeDefaultGridDensityEvent(density));
}

export function SetLiveThrottleMs(throttle: number) {
    AppDispatcher.dispatch(new ChangeLiveThrottleEvent(throttle));
}

export function SetFallbackPriceWidth(width: number) {
    AppDispatcher.dispatch(new ChangeFallbackPriceWidthEvent(width));
}

export function SetEcbotDisplay(showFractions: boolean) {
    //console.log("ECBOT show fractions", showFractions)
    AppDispatcher.dispatch(new ChangeEcbotDisplayEvent(showFractions));
}

export async function AddOrUpdateConfig(blob: ConfigBlob){
    const result = await put<ConfigBlob>("User/AddOrUpdateConfig", blob);
    if (result.payload != null) {
        AppDispatcher.dispatch(new NewConfigEvent(result.payload));
    }
}

export async function DeleteConfig(blob: ConfigBlob){
    const result = await put<boolean>("User/DeleteConfig", blob);
    if (result.payload) {
        AppDispatcher.dispatch(new ConfigDeletedEvent(blob));
    }
}

export async function GetConfigsForType(type: string){
    const result = await get<ConfigBlob[]>(`User/GetConfigsForType/${type}`);
    if (result.payload != null) {
        AppDispatcher.dispatch(new NewBulkConfigEvent(result.payload));
    }
}
