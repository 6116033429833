import React from 'react';
import FBEmitter from 'fbemitter';
import { CashTrade } from '../trade/tradeModels';
import { BookCashTransaction, } from '../trade/tradeActions';
import moment from 'moment';
import {
    Button,
    Grid,
    MenuItem,
    StyledEngineProvider,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { Formik, FormikHelpers } from 'formik';
import { NewCashTradeForm } from '../trade/newCashTradeForm';
import { TradeBlotterTabProps } from '../trade/tradeBlotterTab';
import * as Yup from 'yup';
import { CashTransaction } from './navModels';
import { GridColDef, GridRowData, GridRowParams, DataGridPro, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridCellParams } from '@mui/x-data-grid-pro';
import navStoreInstance from './navStore';
import { ComputeCashTransactions, UpdatedCashTransaction } from './navActions';
import { EarliestDate } from '../globalConstants';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';
import _ from 'lodash';
import { colCommon } from '../positions/positionsCommon';
import { dateFromOADate, momentUtcToOADate, renderDate } from '../utils/helpers';
import { RefreshOutlined } from '@mui/icons-material';

const renderDateTimeCell = (params: GridCellParams) => {
    return <span>{renderDate(dateFromOADate(params.value as number))}</span>;
}

const renderIdCell = (params: GridCellParams) => {
    return <span>{(params.value as number).toFixed(0)}</span>;
}

const CashTransactionColumns: GridColDef[] = [
    { field: "id", headerName: "Id", type: "number", flex: 1, ...colCommon, renderCell: renderIdCell },
    { field: "bookedUtc", headerName: "Booked", type: "dateTime", flex: 1, ...colCommon, renderCell: renderDateTimeCell },
    { field: "valueDate", headerName: "Value", type: "dateTime", flex: 1, ...colCommon, renderCell: renderDateTimeCell },
    { field: "account", headerName: "Account", type: "string", flex: 2, ...colCommon },
    { field: "category", headerName: "Category", type: "string", flex: 2, ...colCommon },
    { field: "amount", headerName: "Amount", type: "number", flex: 1.5, ...colCommon },
    { field: "ccy", headerName: "Ccy", type: "string", flex: 0.75, ...colCommon },
    { field: "description", headerName: "Description", type: "string", flex: 3, ...colCommon },
    { field: "externalId", headerName: "External Id", type: "string", flex: 2, ...colCommon },
    { field: "bookingEntity", headerName: "Booking Entity", type: "string", flex: 2, ...colCommon },
    { field: "accrualKey", headerName: "Accrual Key", type: "string", flex: 2, ...colCommon },
]

type AddUpdateCashComponentState = {
    hasBeenSubmitted: boolean,
    lastBookedId: number | undefined,
    rows: GridRowData[],
    cols: GridColDef[],
    cashTransactionData: CashTransaction,
    filterDateStart: moment.Moment,
    filterDateEnd: moment.Moment,
    selectedAccount: string,
    loadedId: number | undefined,
    loading: boolean
}

const NewCashTradeValidationSchema = Yup.object().shape({
    amount: Yup.number()
        .required('Required')
        .typeError('Valid amount required, no column seperators allowed'),
    account: Yup.string()
        .required('Required'),
    category: Yup.string()
        .required('Required'),
    currency: Yup.string()
        .min(3, 'ISO currency code required')
        .max(3, 'ISO currency code required')
        .required('Required')
        .typeError('Valid currency required'),
    executedUtc: Yup.date()
        .required('Required'),
    valueDate: Yup.date()
        .required('Required'),
});


export class AddUpdateCashComponent extends React.Component<{}, AddUpdateCashComponentState>{
    eventSubscriptionTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: TradeBlotterTabProps) {
        super(props)
        this.state = {
            hasBeenSubmitted: false,
            lastBookedId: undefined,
            cols: [],
            rows: [],
            cashTransactionData: undefined,
            filterDateStart: undefined,
            filterDateEnd: undefined,
            selectedAccount: undefined,
            loadedId: undefined,
            loading: true
        };

        this.onDuplicatePressed = this.onDuplicatePressed.bind(this);
        this.onSubmitNewCashTrade = this.onSubmitNewCashTrade.bind(this);
        this.onResetCashTrade = this.onResetCashTrade.bind(this);
        this.onTradeUpdate = this.onTradeUpdate.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
        this.onClickRefresh = this.onClickRefresh.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionTrades = navStoreInstance.addChangeListener(this.onTradeUpdate)
        this.onTradeUpdate(true);
    }

    componentWillUnmount() {
        if (this.eventSubscriptionTrades) {
            this.eventSubscriptionTrades.remove();
        }
    }

    onClickRefresh() {
        this.setState({ loading: true });
        ComputeCashTransactions(undefined, undefined, true);
    }

    async onTradeUpdate(forceFetch: boolean = false, selectedAcc: string = undefined, filterStart: moment.Moment = undefined, filterEnd: moment.Moment = undefined) {
        const { selectedAccount, filterDateEnd, filterDateStart } = this.state;
        let cashTrans = navStoreInstance.getCashTransactions()?.filter(x => x.transId);
        if (!cashTrans || cashTrans.length === 0) {
            await ComputeCashTransactions(undefined, undefined, true);
            return;
        }

        const accFilter = selectedAcc === null ? undefined : selectedAcc ?? selectedAccount
        if (accFilter) {
            cashTrans = cashTrans.filter(x => x.account === accFilter);
        }
        const startFilter = filterStart === null ? undefined : filterStart ?? filterDateStart;
        if (startFilter) {
            cashTrans = cashTrans.filter(x => x.bookedUtc?.isSameOrAfter(startFilter));
        }
        const endFilter = filterEnd === null ? undefined : filterEnd ?? filterDateEnd;
        if (endFilter) {
            cashTrans = cashTrans.filter(x => x.bookedUtc?.isBefore(endFilter?.endOf('day')));
        }

        var rows2 = cashTrans.sort((a, b) => b.transId - a.transId).map(t => {
            var r = { id: t.transId } as GridRowData;
            Object.keys(t).forEach(k => {
                switch (k) {
                    case "transId":
                        break;
                    case "valueDate":
                    case "bookedUtc":
                        r[k] = momentUtcToOADate(t[k]);
                        break;
                    default:
                        r[k] = t[k];
                        break;
                }

            });
            return r;
        });
        this.setState({ cols: CashTransactionColumns, rows: rows2, loading: false });
    }

    async onSubmitNewCashTrade(values: CashTrade, { setSubmitting }: FormikHelpers<CashTrade>) {
        const { loadedId } = this.state;
        var transaction = {
            account: values.account,
            amount: values.amount,
            ccy: values.currency,
            updatedUtc: moment.utc(),
            bookedUtc: moment(values.executedUtc),
            valueDate: moment(values.valueDate),
            category: values.category,
            description: values.description,
            externalId: values.externalId,
            homepartyId: values.bookingEntityId === 2 ? 2 : null,
            accrualKey: values.accrualKey,
            transId: loadedId
        } as CashTransaction;
        this.setState({ hasBeenSubmitted: true });
        var trans = await BookCashTransaction(transaction);
        var lastBookedId = trans?.transId;
        UpdatedCashTransaction(trans);
        setSubmitting(false);

        this.setState({ lastBookedId });
    }

    onResetCashTrade(values: CashTrade, { resetForm }: FormikHelpers<CashTrade>) {
        console.log("onReset")
        if (this.state.loadedId)
            this.setState({ hasBeenSubmitted: false, lastBookedId: undefined });
        else
            this.setState({ hasBeenSubmitted: false, lastBookedId: undefined, cashTransactionData: undefined });
        //resetForm();
    }


    onDuplicatePressed = () => this.setState({ hasBeenSubmitted: false, lastBookedId: undefined, loadedId: undefined });

    renderAddTradeForm() {
        const { hasBeenSubmitted, lastBookedId, cashTransactionData } = this.state;
        var data = cashTransactionData ?
            {
                account: cashTransactionData.account,
                accrualKey: cashTransactionData.accrualKey,
                amount: cashTransactionData.amount,
                bookingEntityId: cashTransactionData.homepartyId,
                category: cashTransactionData.category,
                createdUtc: moment(cashTransactionData?.bookedUtc)?.toDate(),
                currency: cashTransactionData.ccy,
                description: cashTransactionData.description,
                executedUtc: moment(cashTransactionData?.bookedUtc)?.toDate(),
                valueDate: moment(cashTransactionData?.valueDate)?.toDate(),
                externalId: cashTransactionData.externalId,
            } as CashTrade : {} as CashTrade;
        return (<Formik
            onSubmit={this.onSubmitNewCashTrade}
            onReset={this.onResetCashTrade}
            enableReinitialize={true}
            initialValues={data}
            validationSchema={NewCashTradeValidationSchema}>
            {props => <NewCashTradeForm className="NewTradeForm" {...props} canDuplicate={hasBeenSubmitted} onDuplicatePressed={this.onDuplicatePressed} lastBookedId={lastBookedId} />}
        </Formik>);
    }

    onClickRow(param: GridRowParams, event: React.MouseEvent<Element, MouseEvent>) {
        var idVal = param.getValue(param.id, "id");
        var data2 = navStoreInstance.getCashTransactions().filter(l => l.transId === idVal)[0];
        if (data2) {
            this.setState({ cashTransactionData: data2, hasBeenSubmitted: true, loadedId: idVal });
        }
    }

    customToolbar(props: any) {
        const { filterDateStart, filterDateEnd, selectedAccount, loading } = this.state;
        const transactions = navStoreInstance.getCashTransactions()?.filter(x => x.transId);
        var accounts = transactions ? _.uniq(transactions.map(t => t.account)) : new Array<string>();
        var csvOptions = {} as GridCsvExportOptions;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "0.5em", position: "relative" }}>
                            <Grid container spacing={1} justifyContent="flex-start" alignContent="center" width="150%">
                                <Grid item>
                                    <WrappedSelect
                                        id="account"
                                        name="account"
                                        label="Account"
                                        size='small'
                                        value={selectedAccount ?? "Select Account..."}
                                        onChange={(d) => {
                                            var acc = d.target.value as string;
                                            if (acc === "Select Account...")
                                                acc = null;
                                            this.setState({ selectedAccount: acc });
                                            this.onTradeUpdate(undefined, acc);
                                        }}>
                                        <MenuItem key={"acc1def"} value={"Select Account..."}>{"Select Account..."}</MenuItem>
                                        {accounts.map(a =>
                                            <MenuItem key={"Acc1_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        size='small'
                                        value={filterDateStart}
                                        onChange={(d) => {
                                            this.setState({ filterDateStart: d });
                                            this.onTradeUpdate(undefined, undefined, d);
                                        }}
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="(None)"
                                        clearLabel="(None)"
                                        label={"Filter Start"} />
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        size='small'
                                        value={filterDateEnd}
                                        onChange={(d) => {
                                            this.setState({ filterDateEnd: d });
                                            this.onTradeUpdate(undefined, undefined, undefined, d);
                                        }}
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="(None)"
                                        clearLabel="(None)"
                                        label={"Filter End"} />
                                </Grid>
                                <Grid item>
                                    <Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" disabled={loading} startIcon={<RefreshOutlined />} onClick={this.onClickRefresh}>Refresh</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="flex-end" alignContent="center">
                                <Grid item>{transactions && <Typography style={{ paddingTop: "0.5em" }} variant='subtitle2'>{transactions.length} rows</Typography>}</Grid>
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" csvOptions={csvOptions} />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider >
        );
    }

    render() {
        const { rows, cols, loading } = this.state;
        return (
            <div className="AddUpdateCashComponent">
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container item spacing={2} direction="column" justifyContent="space-between" alignItems="flex-start" height="calc(100% - 40px)">
                        <Grid item className="AddTradeMain">
                            {this.renderAddTradeForm()}
                        </Grid>
                        {cols ? <Grid item className="AddEditCashTransGrid">
                            <DataGridPro
                                columns={cols}
                                rows={rows}
                                className="TradeBlotterDataGrid"
                                density="compact"
                                components={{
                                    Toolbar: this.customToolbar
                                }}
                                onRowClick={this.onClickRow}
                                loading={loading}
                                hideFooter />
                        </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }
}