import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import { AuditSubscribe, FetchAuditLog } from '../rfqActions';
import rfqAuditStoreInstance from '../rfqAuditStore';
import { RfqStateChangeRecord } from '../rfqModels';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { renderDate, renderFullDateTime } from '../../utils/helpers';

type RfqAuditLogViewerProps = {
    rfqId: number
    className?: string
    pageSize?: number
}

type RfqAuditLogViewerState = {
    log: RfqStateChangeRecord[]
    mainRef: RefObject<HTMLDivElement>,
    widthDate: number,
    widthEvent: number,
    widthComment: number,
    widthTotal: number,
}

export class RfqAuditLogViewer extends React.Component<RfqAuditLogViewerProps, RfqAuditLogViewerState>{
    eventSubscriptionRfqs: FBEmitter.EventSubscription | undefined;

    constructor(props: RfqAuditLogViewerProps) {
        super(props)
        this.state = {
            log: [],
            mainRef: React.createRef(),
            widthDate: 90,
            widthEvent: 205,
            widthComment: 200,
            widthTotal: 495
        };
    }

    async componentDidMount() {
        this.eventSubscriptionRfqs = rfqAuditStoreInstance.addChangeListener(() => this.onChange());
        AuditSubscribe(this.props.rfqId);
        FetchAuditLog(this.props.rfqId);
        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionRfqs) {
            this.eventSubscriptionRfqs.remove();
            this.eventSubscriptionRfqs = undefined;
        }
    }

    public componentDidUpdate() {
        if (this.state.mainRef.current) {
            let width = this.state.mainRef.current.clientWidth;
            if (width !== this.state.widthTotal) {
                if (width > 495) {
                    let widthDate = 120;
                    let widthEvent = Math.floor((width - 1 - widthDate) / 2);
                    this.setState({ widthTotal: width, widthEvent, widthDate, widthComment: widthEvent });
                }
            }
        }
    }

    onChange() {
        this.setState({ log: rfqAuditStoreInstance.GetLogs(this.props.rfqId) });
    }

    render() {
        const { log, widthEvent, widthComment, widthDate, mainRef } = this.state;
        const columns: GridColDef[] = [
            { field: 'Time', flex: 1, headerName: 'Time', width: widthDate, cellClassName: "RfqAuditLogViewerCell", headerClassName: "RfqAuditLogViewerHeader" },
            { field: 'Event', flex: 1, headerName: 'Event', width: widthEvent, cellClassName: "RfqAuditLogViewerCell", headerClassName: "RfqAuditLogViewerHeader" },
            { field: 'Comment', flex: 2, headerName: 'Comment', width: widthComment, cellClassName: "RfqAuditLogViewerCell", headerClassName: "RfqAuditLogViewerHeader" },
        ];
        if (log) {
            let rowData = log.sort((a, b) => b.eventId - a.eventId).map((row, ix) => {
                return { id: ix, Time: renderDate(row.stampUtc), Event: row.event, Comment: row.comment, FullDateTime: renderFullDateTime(row.stampUtc) };
            })
            return (<div ref={mainRef} className={this.props.className ? this.props.className : "RfqAuditLogViewer"}>
                <DataGridPro
                    className="RfqAuditLogViewerGrid"
                    rows={rowData}
                    columns={columns}
                    pageSize={this.props.pageSize ?? Math.min(5, rowData.length)}
                    pagination
                    paginationMode="client"
                    density='compact'
                />
            </div>);
        }
        else
            return null;
    }
}