import { isEqual } from "lodash";
import { OptionStructure } from "../../../payoff/models/optionStructure";
import { OptionType } from "../../../qwack/optionType";
import { basketOk, dateOk } from "./validateCommon";

export function validateBondPlusCall(o: OptionStructure): boolean {
    if (o.vanillaLegs && o.vanillaLegs.length === 1 && (!o.barrierOptionLegs || o.barrierOptionLegs.length === 0)) {
        return validateBondPlusSingleVanillaCall(o);
    }
    else if (o.vanillaLegs && o.vanillaLegs.length === 2 && (!o.barrierOptionLegs || o.barrierOptionLegs.length === 0)) {
        return validateBondPlusCallSpread(o);
    }
    else if (o.barrierOptionLegs && o.barrierOptionLegs.length === 1 && (!o.vanillaLegs || o.vanillaLegs.length === 0)) {
        return validateBondPlusSingleBarrierCall(o);
    }

    return false;
}

function validateBondPlusCallSpread(o: OptionStructure): boolean {
    let c1 = o.vanillaLegs[0];
    let c2 = o.vanillaLegs[1];

    let initial = c1.underlying !== undefined && isEqual(c1.underlying, c2.underlying);
    initial = initial && basketOk(c1.underlying);
    initial = initial && dateOk(c1.expiryDate) && isEqual(c1.expiryDate, c2.expiryDate);
    initial = initial && c1.callPutStraddle === OptionType.call && c2.callPutStraddle === OptionType.call;
    initial = initial && c1.legWeight === -c2.legWeight;
    return initial;
}

function validateBondPlusSingleVanillaCall(o: OptionStructure): boolean {
    let c1 = o.vanillaLegs[0];
    let initial = basketOk(c1.underlying) && dateOk(c1.expiryDate) && c1.callPutStraddle === OptionType.call && c1.legWeight === 1;
    return initial;
}

function validateBondPlusSingleBarrierCall(o: OptionStructure): boolean {
    let c1 = o.barrierOptionLegs[0];
    let initial = basketOk(c1.underlying) && dateOk(c1.expiryDate) && c1.barrier1Detail !== undefined && c1.callPutStraddle === OptionType.call && c1.legWeight === 1;
    return initial;
}
