import { Component } from 'react';
import FBEmitter from 'fbemitter';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CssBaseline, CircularProgress } from '@mui/material';
import positionStoreInstance from '../../positions/positionSummaryStore';
import marketDataLiveStoreInstance from '../../marketData/marketDataLiveStore';
import { LiveSummary, PositionSummary } from '../../positions/positionSummaryModels';
import _ from 'lodash';
import { AssetStr, DefaultSetName } from '../../globalConstants';
import marketDataStoreInstance from '../../marketData/marketDataStore';
import { getMetaFromInstrument, getIns } from '../../positions/positionsCommon';
import { addWeekDays } from '../../utils/dates';
import { SubscribeToTicksByIdBulk } from '../../accelerator/acceleratorActions';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import moment from 'moment';
import navStoreInstance from '../../nav/navStore';
import { ComputeFeederMasterNavs } from '../../nav/navActions';
import { ThemeProvider } from '@mui/styles';
import { getFormTheme } from '../../inputs/formCommon';
import userStoreInstance from '../../user/userStore';

const DrawDelayMs = 200;

export interface NavWidgetProps {
  onChangeHeight?: (newHeight: number) => void
}

interface NavWidgetState {
  live: PositionSummary;
  base: PositionSummary;
  baseNav: number;
  yesterday: string;
  lastUpdated: moment.Moment;
}

class NavWidget extends Component<NavWidgetProps, NavWidgetState>{
  eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
  constructor(props: NavWidgetProps) {
    super(props);
    this.state = {
      live: undefined,
      base: undefined,
      baseNav: undefined,
      yesterday: undefined,
      lastUpdated: moment()
    };
    this.updatePnL = this.updatePnL.bind(this);
  }

  async componentDidMount() {
    this.eventSubscriptionTicks = marketDataLiveStoreInstance.addChangeListener(() => this.updatePnL());

    if (!navStoreInstance.navsAreLoaded()) {
      await ComputeFeederMasterNavs(DefaultSetName)
    }

    var today = moment().startOf('day');
    var storageKeyYesterday = `PnL-Yesterday-${today.format("yyyy-MM-DD")}`;
    var ytd = sessionStorage.getItem(storageKeyYesterday);

    if (!ytd) {
      var ytdDate = addWeekDays(today, -1);
      ytd = ytdDate.format("yyyy-MM-DD");
      sessionStorage.setItem(storageKeyYesterday, ytd);
      var baseValuation = await positionStoreInstance.getValuationsAsync(ytd, false);
      var baseSummary = this.generateSummaryData(baseValuation);
      var baseNav = navStoreInstance.getCalculatedNav(ytdDate.toDate(), "MasterFund")?.totalInBase;
      this.setState({ base: baseSummary, baseNav: baseNav });
    }

    var posSummary = positionStoreInstance.getValuations("now", false);
    if (posSummary) {
      await this.updatePnL();
    }
  }

  componentWillUnmount() {
    if (this.eventSubscriptionTicks)
      this.eventSubscriptionTicks.remove();
  }

  async updatePnL() {
    var posSummary = positionStoreInstance.getValuations("now", true);
    var summary = this.generateSummaryData(posSummary);

    var today = moment().startOf('day');
    var storageKeyYesterday = `PnL-Yesterday-${today.format("yyyy-MM-DD")}`;
    var ytd = sessionStorage.getItem(storageKeyYesterday);
    if (ytd && !this.state.base) {
      var baseValuation = await positionStoreInstance.getValuationsAsync(ytd, false);
      var baseSummary = this.generateSummaryData(baseValuation);
      this.setState({ base: baseSummary });
    }

    if (ytd && !this.state.baseNav) {
      var baseNav = navStoreInstance.getCalculatedNav(new Date(ytd), "MasterFund")?.totalInBase;
      this.setState({ baseNav: baseNav });
    }

    if (posSummary) {
      var insIds = posSummary.positions.filter(p => p.aggregatedPosition.openPosition !== 0 && !marketDataLiveStoreInstance.checkSubscribed(p.aggregatedPosition.instrumentId)).map(p => p.aggregatedPosition.instrumentId);
      if (insIds.length > 0) {
        var ulIndIds = insIds.map(i => {
          var ins = listedInstrumentStoreInstance.getInstrumentById(i);
          return ins.type === "Option" ? ins.underlyingId : null;
        }).filter(i => i !== null);
        var idSet = new Set([...insIds, ...ulIndIds]);

        await SubscribeToTicksByIdBulk(Array.from(idSet));
      }
    }

    var timeSinceLastDraw = moment().diff(this.state.lastUpdated, "milliseconds");
    if (timeSinceLastDraw > DrawDelayMs) {
      this.setState({ live: summary, lastUpdated: moment() });
    }
  }

  getPrice(insId: number, lastPrice: number): number {
    var tick = marketDataLiveStoreInstance.getTick(Number(insId));
    return tick ?? lastPrice;
  }

  generateSummaryData(sum: LiveSummary) {
    if (!sum || !sum.positions)
      return null;
    var isLive = sum.asOf?.toLowerCase() === "now";

    var posByAsset = _.groupBy(sum.positions, p => getMetaFromInstrument(getIns(p.aggregatedPosition.instrumentId), AssetStr));

    var realizedByAsset: { [asset: string]: number } = {};
    var unRealizedByAsset: { [asset: string]: number } = {};

    var realized = 0;
    var unrealized = 0;
    Object.keys(posByAsset).filter(k => k !== "null").forEach(asset => {
      //TODO - make this rely on live fx rates
      var realizedForAsset = _.sum(posByAsset[asset].map(p => p.aggregatedPosition.realizedPnLBase));
      realizedByAsset[asset] = realizedForAsset;
      realized += realizedForAsset;
      if (!isLive) {
        var unRealizedForAsset = _.sum(posByAsset[asset].map(p => p.unrealizedPnLBase));
        if (isNaN(unRealizedForAsset)) {
          var badAssets = posByAsset[asset].filter(p => isNaN(p.unrealizedPnLBase));
          badAssets.forEach(badAss => {
            console.error(`NaN unrealized PnL for ins ${badAss?.aggregatedPosition?.instrumentId} and date ${sum.asOf}`);
          });
          unRealizedForAsset = _.sum(posByAsset[asset].filter(p => isNaN(p.unrealizedPnLBase)).map(p => p.unrealizedPnLBase));
        }

        unRealizedByAsset[asset] = unRealizedForAsset;
        unrealized += unRealizedForAsset;
      }
    });

    sum.positions.filter(p => p.aggregatedPosition.openPosition !== 0).forEach(p => {
      var a = p.aggregatedPosition;
      var ins = getIns(a.instrumentId);
      var price = isLive ? this.getPrice(a.instrumentId, p.livePrice) : p.livePrice;
      var asset = getMetaFromInstrument(ins, AssetStr);
      var size = a.openPosition * ins.multiplier;

      if (isLive) {
        if (unRealizedByAsset[asset] === undefined)
          unRealizedByAsset[asset] = 0;
        var unRealizedForPos = size * (price - p.aggregatedPosition.averagePrice) * (p.unrealizedCcy === "USD" ? 1.0 : marketDataLiveStoreInstance.getFxRate(p.unrealizedCcy) ?? marketDataStoreInstance.getFxRate(p.unrealizedCcy) ?? 1.0);
        if (isNaN(unRealizedForPos)) {
          console.error(`NaN unrealized PnL for ins ${p?.aggregatedPosition?.instrumentId} / ${ins?.description} and date ${sum.asOf} (LIVE)`);
          unRealizedForPos = 0;
        }

        unrealized += unRealizedForPos;
        unRealizedByAsset[asset] += unRealizedForPos;
      }
    });

    return {
      realized: realized,
      unRealized: unrealized,
      realizedByAsset: realizedByAsset,
      unRealizedByAsset: unRealizedByAsset,
    } as PositionSummary
  }

  render() {
    const { live, base, baseNav } = this.state;

    var realized = 0;
    var unrealized = 0;

    if (live && base) {
      realized = live.realized - base.realized;
      unrealized = live.unRealized - base.unRealized;
    }

    var col = userStoreInstance.GetTheme().color;
    var upCol = userStoreInstance.GetTheme().chart_color_up;
    var downCol = userStoreInstance.GetTheme().chart_color_down;

    var cellStyle = { border: "none" } as React.CSSProperties;

    var pnL = realized + unrealized;
    var pnLPct = pnL / baseNav;
    var liveNaV = baseNav + pnL;
    
    var pnlCol = pnLPct >= 0 ? upCol : downCol;

    if (baseNav) {
      return (
        <CssBaseline>
          <ThemeProvider theme={getFormTheme()}>
            <div className="NavWidget">
              <TableContainer style={{ width: "100%", overflow: "hidden" }} >
                <Table size='small' sx={{ width: "100%", height: "140px", tableLayout: "auto", overflow: "hidden" }} >
                  <TableHead />
                  <TableBody >
                    <TableRow>
                      <TableCell width={"40%"} style={cellStyle}><Typography variant="subtitle2" align="center" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>NAV</Typography></TableCell>
                      <TableCell width={"60%"} style={cellStyle}><Typography variant="subtitle2" align="center" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>{liveNaV.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"40%"} style={cellStyle}><Typography variant="subtitle2" align="center" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>PnL (USD)</Typography></TableCell>
                      <TableCell width={"60%"} style={cellStyle}><Typography variant="subtitle2" align="center" color={pnlCol} fontFamily={userStoreInstance.GetTheme().font_family}>{pnL.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width={"40%"} style={cellStyle}><Typography variant="subtitle2" align="center" fontWeight="bold" color={col} fontFamily={userStoreInstance.GetTheme().font_family}>PnL (%)</Typography></TableCell>
                      <TableCell width={"60%"} style={cellStyle}><Typography variant="subtitle2" align="center" fontWeight="bold" color={pnlCol} fontFamily={userStoreInstance.GetTheme().font_family}>{(pnLPct*100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</Typography></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </ThemeProvider>
        </CssBaseline>
      );
    }
    else {
      return (
        <div className="NavWidget">
          <div style={{width:"100%", height: "140px", display:"flex", justifyContent:"center", alignContent:"center"}}>
          <CircularProgress />
          </div>
        </div>
      );
    }

  }
}
export default NavWidget;