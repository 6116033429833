import React, { ReactNode } from 'react';
import FBEmitter from 'fbemitter';
import { Chip, ListItemIcon, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';

export type StringEnumSelectorProps = {
    onChange: (Actions: string[]) => void;
    fetchValueList: () => string[];
    initiallySelected: string[];
    placeholder: string | undefined;
    id?: string;
}

type StringEnumSelectorState = {
    selectedValues: Map<string, boolean>,
    valueList: string[],
    placeholder: string
    activeValue: string | null
}

class StringEnumSelector extends React.Component<StringEnumSelectorProps, StringEnumSelectorState> {
    eventSubscriptionCredit: FBEmitter.EventSubscription | undefined;

    constructor(props: any) {
        super(props)
        this.state =
        {
            placeholder: this.props.placeholder ? this.props.placeholder : "Select...",
            selectedValues: new Map<string, boolean>(),
            valueList: [],
            activeValue: null
        };
        this.isValueSelected = this.isValueSelected.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }

    public async componentDidMount() {
        var valueList = this.props.fetchValueList();
        if (this.props.initiallySelected && this.props.initiallySelected.length > 0) {
            this.setState(
                {
                    valueList: valueList,
                    selectedValues: new Map<string, boolean>(valueList.map(r => [r, this.props.initiallySelected.includes(r)])),
                });
        }
        else {
            this.setState(
                {
                    valueList: valueList,
                    selectedValues: new Map<string, boolean>(valueList.map(r => [r, false])),
                });
        }
    }

    public updateValues(selectedValues: string[]) {
        var valList = this.props.fetchValueList();
        this.setState(
            {
                valueList: valList,
                selectedValues: new Map<string, boolean>(valList.map(r => [r, selectedValues.includes(r)])),
            });
    }

    public componentWillUnmount() {

    }

    selectedValues = () => {
        var o = new Array<string>();
        if (this.state) {
            this.state.selectedValues.forEach((v, k) => {
                if (v)
                    o.push(k);
            });
        }
        return o;
    }

    numSelectedValues = () => {
        return this.selectedValues().length;
    }

    clearAllSelectedValues = () => {
        this.state.selectedValues.forEach((v, k) => {
            this.state.selectedValues.set(k, false);
        });
        this.props.onChange(this.selectedValues());
        this.setState(this.state);
    }

    private isValueSelected(value: string) { return this.state.selectedValues.get(value) }

    onChangeSelect(event: SelectChangeEvent<string[]>, child: ReactNode): void {
        const { selectedValues } = this.state;
        const values = event.target.value as string[];

        for (const [key, ] of selectedValues.entries()) {
            selectedValues.set(key, false);
        }

        for (let i = 0, l = values.length; i < l; i += 1) {
            selectedValues.set(values[i], true);
        }
        this.props.onChange(this.selectedValues());
        this.setState(this.state);
    }

    render() {
        return (<Select
            multiple={true}
            key={"stringEnumSelect" + this.props.id}
            children={this.state.valueList.map(v =>
                <MenuItem key={v} value={v}>{v}{this.isValueSelected(v)?<ListItemIcon><CheckOutlined /></ListItemIcon>:null}</MenuItem>)}
            onChange={this.onChangeSelect}
            value={this.selectedValues()}
            placeholder={this.state.placeholder}
            renderValue={(selected) => (
                <div>
                    {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} />
                    ))}
                </div>)} />);
    }
}

export default StringEnumSelector;