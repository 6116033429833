import React from 'react';
import FBEmitter from 'fbemitter';
import { DateFixedRelative } from '../../payoff/models/dateFixedRelative'
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { get } from '../../utils/httpUtils';
import moment from 'moment';
import { Frequency } from '../../qwack/frequency';
import { Button, FormControlLabel, Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { subRowProps, subCellProps, subTitleProps, subValueProps, subTitleVariant } from './helpers';

export type AutocallCallDateGeneratorProps = {
    updateCallDates: (dates: DateFixedRelative[]) => void,
    updateCoupons: (coupons: number[]) => void,
    initialCallDates: DateFixedRelative[],
    initialCoupons: number[],
    disabled: boolean,
    startDate: DateFixedRelative
    endDate: DateFixedRelative,
}

export type AutocallCallDateGeneratorState = {
    callDates: DateFixedRelative[],
    coupons: number[],
    hasFlatCoupon: boolean,
    callFrequency: string,
    initialCoupon: number,
    finalCoupon: number
}

const CallFrequncies = ['Annual', 'Quarterly', 'Monthly']

export class AutocallCallDateGenerator extends React.Component<AutocallCallDateGeneratorProps, AutocallCallDateGeneratorState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: AutocallCallDateGeneratorProps) {
        super(props);
        this.state = {
            callDates: this.props.initialCallDates,
            coupons: this.props.initialCoupons,
            hasFlatCoupon: this.props.initialCoupons !== undefined && this.props.initialCoupons.length > 0 && this.props.initialCoupons.every(c => c === this.props.initialCoupons[0]),
            callFrequency: CallFrequncies[0],
            initialCoupon: this.props.initialCoupons && this.props.initialCoupons.length > 0 ? this.props.initialCoupons[0] : 0,
            finalCoupon: this.props.initialCoupons && this.props.initialCoupons.length > 0 ? this.props.initialCoupons[this.props.initialCoupons.length - 1] : 0
        }

        this.onChangeCallFrequency = this.onChangeCallFrequency.bind(this);
        this.onChangFlatCoupon = this.onChangFlatCoupon.bind(this);
        this.onClickGenCallDates = this.onClickGenCallDates.bind(this);
        this.onChangeInitialCoupon = this.onChangeInitialCoupon.bind(this);
        this.onChangeFinalCoupon = this.onChangeFinalCoupon.bind(this);
        this.onChangeFinalCoupon = this.onChangeFinalCoupon.bind(this);
    }

    public componentWillUnmount() {

    }

    componentDidMount() {

    }

    public componentDidUpdate() {

    }

    onChangeCallFrequency(val: string) {
        this.setState({ callFrequency: val });
    }

    onChangFlatCoupon() {
        const { hasFlatCoupon } = this.state;
        this.setState({ hasFlatCoupon: !hasFlatCoupon });
    }

    onChangeInitialCoupon(val: number) {
        const { hasFlatCoupon } = this.state;
        if (hasFlatCoupon)
            this.setState({ initialCoupon: val, finalCoupon: val });
        else
            this.setState({ initialCoupon: val });
    }

    onChangeFinalCoupon(val: number) {
        this.setState({ finalCoupon: val });
    }

    renderFixedRelDate = (val: DateFixedRelative) => {
        if (val.isRelative) {
            let rt = val.relative.relativeTenor;
            return Frequency.toStringStatic(rt);
        }
        else
            return moment(val.absolute.absoluteDate).format("yyyy-MM-DD ddd")
    }

    renderCallDateRow(ix: number) {
        const { callDates, coupons } = this.state;
        return (
            <TableRow key={`callDateRow${ix}`}>
                <TableCell size='small' align='center' key={`callDateRow${ix}-callDates`}>{this.renderFixedRelDate(callDates[ix])}</TableCell>
                <TableCell size='small' align='center' key={`callDateRow${ix}-coupons`}>{coupons[ix].toPrecision(3)}</TableCell>
            </TableRow>
        );
    }

    async onClickGenCallDates() {
        const { callFrequency, hasFlatCoupon, initialCoupon, finalCoupon } = this.state;
        const { startDate, endDate } = this.props;
        let start = "";
        let end = ""
        if (startDate.isRelative) {
            start = startDate.relative.relativeTenor.toString();
            end = endDate.relative.relativeTenor.toString();
        }
        else {
            start = (startDate.absolute?.absoluteDate ?? new Date()).toISOString();
            end = endDate.absolute.absoluteDate.toISOString();
        }
        let response = await get<DateFixedRelative[]>(`Product/ScheduleGenerate/${start}/${end}/${callFrequency}/USD`)
        if (response.payload) {
            let coupons = new Array<number>();
            if (hasFlatCoupon)
                coupons = response.payload.map<number>(r => initialCoupon);
            else {
                var nCoupons = response.payload.length;
                var coupSlope = (finalCoupon - initialCoupon) / (nCoupons - 1);
                coupons = response.payload.map<number>((r, ix) => initialCoupon + coupSlope * ix);
            }
            this.setState({ callDates: response.payload, coupons: coupons });
            this.props.updateCoupons(coupons);
            this.props.updateCallDates(response.payload);
        }
    }

    render() {
        const { callDates, callFrequency, hasFlatCoupon, initialCoupon, finalCoupon, coupons } = this.state;
        return (
            <Grid item container paddingTop="0.25em"  >
                <Grid {...subRowProps} >
                    <Grid {...subCellProps}>
                        <Grid {...subTitleProps} width={80}><Typography textAlign="right" variant={subTitleVariant}>Frequency</Typography></Grid>
                        <Grid {...subValueProps}>
                            <StringTypeDropDown possibles={CallFrequncies} onChange={this.onChangeCallFrequency} selected={callFrequency} disabled={this.props.disabled} />
                        </Grid>
                    </Grid>
                    <Grid {...subCellProps}>
                        <Grid {...subTitleProps} width={80}><Typography textAlign="right" variant={subTitleVariant}>Type</Typography></Grid>
                        <Grid {...subValueProps} width={80}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={hasFlatCoupon}
                                        onChange={this.onChangFlatCoupon}
                                        name="hasFlatCoupon"
                                        color="primary"
                                        disabled={this.props.disabled}
                                    />
                                }
                                label={hasFlatCoupon ? "Flat" : "Grow"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid {...subRowProps} >
                    <Grid {...subCellProps}>
                        <Grid {...subTitleProps} width={80}><Typography textAlign="right" variant={subTitleVariant}>{hasFlatCoupon ? "Flat Coupon" : "Initial Coupon"}</Typography></Grid>
                        <Grid {...subValueProps} width={80}>
                            <ValidatedNumericInput style={{ width: "80px" }} units="%" keyName="acInitialCoupon" placeholder={hasFlatCoupon ? "Coupon" : "Initial"} className="AutocallDateGenCoupon" initialValue={initialCoupon} onChange={(v) => { this.onChangeInitialCoupon(v); return true; }} disabled={this.props.disabled} />
                        </Grid>
                    </Grid>
                    {hasFlatCoupon ? null :
                        <Grid {...subCellProps} >
                            <Grid {...subTitleProps} width={80}><Typography textAlign="right" variant={subTitleVariant}>Final Coupon</Typography></Grid>
                            <Grid {...subValueProps} width={80}>
                                <ValidatedNumericInput style={{ width: "80px" }} units="%" keyName="acFinalCoupon" placeholder="Final" className="AutocallDateGenCoupon" initialValue={finalCoupon} onChange={(v) => { this.onChangeFinalCoupon(v); return true; }} disabled={this.props.disabled} />
                            </Grid>
                        </Grid>}
                </Grid>
                {this.props.disabled ? null :
                    <Grid {...subRowProps} paddingTop="0.25em" justifyContent="center" >
                        <Button className="MuiButtonOutlined PltfmButtonLite" value={"Generate Call Dates"} onClick={this.onClickGenCallDates} disabled={this.props.disabled}>Generate Call Dates</Button>
                    </Grid>}
                {!callDates || !coupons ? null :
                    <Grid {...subRowProps} paddingTop="0.25em" justifyContent="center" >
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell variant='head' align='center'><Typography>Date</Typography></TableCell>
                                        <TableCell variant='head' align='center'><Typography>Coupon</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {callDates.map((cd, ix) => this.renderCallDateRow(ix))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>}
            </Grid>)
    }

}
export default AutocallCallDateGenerator;