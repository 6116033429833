import { connectionBase, ConnectionStatus } from '../connections/connectionStatusModel';
import { ProductSpec } from '../product/productModels';
import { Rfq, RfqResponse, RfqWithProduct } from './rfq';
import { RfqAuditEvent, RfqSummaryUpdated } from './rfqActions';
import { Price, RfqStateChangeRecord, RfqSummary, TradeAttempt } from './rfqModels';

const hubLocation = "requestForQuotesHub";

class RfqConnection extends connectionBase {
    priceSubcriptions: Map<number, Map<string, (rfqId: number, qs: Price) => void>>;
    rfqSubscriptions: Map<number, Map<string, (rfqId: number, qs: RfqSummary) => void>>;

    constructor() {
        super(hubLocation, "RfqConnection");
        this.priceSubcriptions = new Map<number, Map<string, (rfqId: number, qs: Price) => void>>();
        this.rfqSubscriptions = new Map<number, Map<string, (rfqId: number, qs: RfqSummary) => void>>();
        this.onQuoteUpdate = this.onQuoteUpdate.bind(this);
        this.afterReconnect = this.afterReconnect.bind(this);
    }

    public onStatusChange(status: ConnectionStatus){
        
    }

    public async subscribeToPrices(rfqId: number, subscriptionId: string, callback: (rfqId: number, qs: Price) => void) {
        let currentCallbacks = this.priceSubcriptions.get(rfqId);
        if (currentCallbacks) {
            currentCallbacks.set(subscriptionId, callback);
        } else {
            currentCallbacks = new Map<string, (rfqId: number, qs: Price) => void>();
            currentCallbacks.set(subscriptionId, callback);
            this.priceSubcriptions.set(rfqId, currentCallbacks);
            try {
                await this.hubConnection.invoke("SubscribeToPrices", rfqId);
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    public async createRfq(rfq: Rfq): Promise<RfqResponse> {
        let response = await this.hubConnection.invoke<RfqResponse>("Create", rfq);
        return response;
    }

    public async createRfqWithProduct(rfq: Rfq, productSpec: ProductSpec): Promise<RfqResponse> {
        var obj = { rfq, productSpec } as RfqWithProduct;
        console.log("About to send RFQ...");
        console.log("Connection State" + this.hubConnection.state.toString());
        let response = await this.hubConnection.invoke<RfqResponse>("CreateWithProduct",
            obj).catch((r) => {
                console.log(r);
            });
        console.log("RFQ response received...");
        if (response) {
            return response;
        }
        return null;
    }

    public async createRfqWithProductAndCallback(rfq: Rfq, product: ProductSpec, subscriptionId: string, callback: (rfqId: number, qs: Price) => void): Promise<RfqResponse> {
        let response = await this.hubConnection.invoke<RfqResponse>("CreateWithProduct", rfq, product);
        await this.subscribeToPrices(response.rfqId, subscriptionId, callback);
        return response;
    }

    public async cancelRfq(rfqId: number) {
        try {
            await this.hubConnection.invoke("Cancel", rfqId);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async pickUpRfq(rfqId: number) {
        try {
            await this.hubConnection.invoke("Pickup", rfqId);
        }
        catch (error) {
            console.log(error);
        }
    }
    public async dropRfq(rfqId: number) {
        try {
            await this.hubConnection.invoke("Drop", rfqId);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async refreshRfq(rfqId: number) {
        try {
            await this.hubConnection.invoke("Refresh", rfqId);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async sendPriceForRfq(rfqId: number, price: Price) {
        try {
            await this.hubConnection.invoke("SendPrice", rfqId, price);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async yankPriceForRfq(rfqId: number, uniqueKey: string) {
        try {
            await this.hubConnection.invoke("YankPrice", rfqId, uniqueKey);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async attemptTrade(rfqId: number, attempt: TradeAttempt) {
        try {
            await this.hubConnection.invoke("AttemptTrade", rfqId, attempt);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async fillOrder(rfqId: number, price: number, size: number) {
        try {
            await this.hubConnection.invoke("FillOrder", rfqId, price, size);
        }
        catch (error) {
            console.log(error);
        }
    }

    public afterConnect() {
    }

    public beforeConnect() {
        this.hubConnection.on("priceUpdate", this.onQuoteUpdate);
        this.hubConnection.on("rfqUpdate", this.onSummaryUpdate);
        this.hubConnection.on("rfqAuditUpdate", this.onRfqAuditUpdate);
        this.hubConnection.on("ping", () => this.hubConnection.send("pong"));
    }

    public afterReconnect() {
        // this.priceSubcriptions.forEach((value, key) => {
        //     this.hubConnection.send("SubscribeToPrices", key);
        // });
    }

    private async onRfqAuditUpdate(record: RfqStateChangeRecord) {
        RfqAuditEvent(record);
    }

    public async subscribeToRfqAudit(rfqId: number) {
        try {
            await this.hubConnection.send("AuditSubscribe", rfqId)
        }
        catch (error) {
            console.log(error);
        }
    }

    public async unSubscribeFromRfqAudit(rfqId: number) {
        try {
            await this.hubConnection.send("AuditUnSubscribe", rfqId)
        }
        catch (error) {
            console.log(error);
        }
    }

    private async onSummaryUpdate(rfqSummary: RfqSummary) {
        RfqSummaryUpdated(rfqSummary);
    }

    public async unsubscribe(rfqId: number, subscription: string) {
        let currentCallbacks = this.priceSubcriptions.get(rfqId);
        if (currentCallbacks) {
            currentCallbacks.delete(subscription);
            if (currentCallbacks.size === 0) {
                this.priceSubcriptions.delete(rfqId);
                try {
                    this.hubConnection.invoke("UnsubscribeFromPrices", rfqId);
                }
                catch (error) {
                    console.log(error);
                }
            }
        } else {
            console.log("Failed to remove subscription for rfq id " + rfqId.toString() + " because there were no subscriptions");
        }

    }

    onQuoteUpdate(rfqId: number, quote: Price) {
        console.log("Received quote for " + rfqId.toString());
    }
}

const rfqConnection = new RfqConnection();
export default rfqConnection;