import { OrderType } from "../src/orders/orderModels";

export const PltfmYellow = "rgb(254,189,17)";
export const PltfmBlue = "rgb(26, 62, 91)";
export const PltfmGrey = "rgb(147, 149, 152)";

export const BondPlusCall = "BondPlusCall";
export const ReverseConvertible = "ReverseConvertible";
export const ReverseReverseConvertible = "ReverseReverseConvertible";

export const Swap = "Swap";
export const AsianOption = "AsianOption";
export const EuropeanOption = "EuropeanOption";
export const UnpricedAverage = "UnpricedAverage";

export const BookStr : string = "CetusBook";
export const StrategyStr : string = "CetusStrategy";
export const AssetStr : string = "CetusAsset";
export const AttributionStr : string = "CetusAttribution";
export const SubAttributionStr : string = "CetusSubAttribution";
export const BetaStr : string = "Underlying Beta";
export const InstrumentStr : string = "CetusInstrument";
export const ApiLocation = "https://api.cetus.pltfm.app/api/";

export const RfqInstallation = false;
export const PmsInstallation = true;

export const defaultInstrumentQueryMetaTags = ["CetusAsset","CetusAttribution","CetusSubAttribution"];

export const EarliestDate = new Date(2018,10,1);
export const StartNav = 100000;
export const DefaultSetName = "Cetus-EoD";

export const assetSort = ["FX", "CM", "EQ", "FI", null];

export const EnableOrderEntry = true;
export const AllowedOrderTypes : OrderType[] = [];
export const OrderTemplates = ["Outright", "Native Spread", "Legged Spread"];

export const AccountToInstitutionMap = {
    Unknown: "Unknown",
}

export const Everysk = "Everysk";
export const Pltfm = "Pltfm";
export const PltfmMc = "PltfmMc";
export const VaRProviders = [Pltfm, PltfmMc];