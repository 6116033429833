import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore'
import { ConnectionStatusChangeEvent, NewConnectionStatsEvent } from './userSessionActions';
import { ConnectionStats, ConnectionStatsStoreState } from './pingModels';
import { ConnectionStatus } from '../connections/connectionStatusModel';

class ConnectionStatsStore extends FluxStore<ConnectionStatsStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            stats: {} as ConnectionStats,
            status: ConnectionStatus.Disconnected
        }))
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewConnectionStatsEvent) {
            this.state.stats = action.payload;
            this.emitChange();
        }
        else if (action instanceof ConnectionStatusChangeEvent) {
            this.state.status = action.payload;
            this.emitChange();
        }
    }

    public GetStats() {
        return this.state.stats;
    }

    public GetStatus() {
        return this.state.status;
    }
}

const connectionStatsStoreInstance = new ConnectionStatsStore(AppDispatcher);
export default connectionStatsStoreInstance;

