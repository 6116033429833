import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
    Paper,
    Tab,
    Tabs,
} from '@mui/material';
import moment from 'moment';
import { GetParam } from '../trade/tradeBlotterTabComponent';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import _ from 'lodash';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { NavAccrualPosition, NavCashPosition, NavCfdPosition, NavDiffReport, NavMarginedPosition, NavMoneyMarketPosition, NavSecurityPosition, NavSummary } from './navModels';
import navStoreInstance from './navStore';
import { ComputeFeederMasterNavDiff, FlushNavCache } from './navActions';
import { LivePosition } from '../positions/positionSummaryModels';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { getIns, isCashBalance, isCashIns } from '../positions/positionsCommon';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { TabContext, TabPanel } from '@mui/lab';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';

interface NavDiffTableState {
    report: NavDiffReport,
    mainRef: RefObject<HTMLDivElement>,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    awaitingRefresh: boolean,
    hideClosedPositions: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    selectedEntity: number | undefined,
    showAreYouSure: boolean,
    selectedTabId: number;
    ccyConversion: "local" | "base"
}

export interface NavDiffTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const formatOps2 = { maximumFractionDigits: 2 };

export class NavDiffTable extends React.Component<NavDiffTableProps, NavDiffTableState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: NavDiffTableProps) {
        super(props)
        var startDate = props.getState("nd_startDate");
        var endDate = props.getState("nd_endDate");
        var set = props.getState("nd_set");
        var selectedEntity = props.getState("nd_selectedEntity") ? Number(props.getState("nd_selectedEntity")) : undefined;
        this.state = {
            report: {} as NavDiffReport,
            mainRef: React.createRef(),
            startDate: startDate ? moment(startDate) : null,
            endDate: endDate ? moment(endDate) : null,
            awaitingRefresh: false,
            hideClosedPositions: GetParam(this.props.getState, "hideClosedPositions") ?? true,
            rowExpanded: undefined,
            selectedSet: set,
            selectedEntity: selectedEntity,
            showAreYouSure: false,
            selectedTabId: 0,
            ccyConversion: "base"
        };;
        this.updateDiff = this.updateDiff.bind(this);
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = navStoreInstance.addChangeListener(this.updateDiff);
        this.updateDiff();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeStartDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("nd_startDate", dStr);
        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var diff = navStoreInstance.getNavDiff(d, this.state.endDate, fund?.displayName, this.state.selectedSet);
        if (diff)
            this.setState({ startDate: d, report: diff, awaitingRefresh: false });
        else
            this.setState({ startDate: d });
    }

    onChangeEndDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("nd_endDate", dStr);
        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var diff = navStoreInstance.getNavDiff(this.state.startDate, d, fund?.displayName, this.state.selectedSet);
        if (diff)
            this.setState({ endDate: d, report: diff, awaitingRefresh: false });
        else
            this.setState({ endDate: d });
    }

    async calcDiff() {
        this.setState({ report: null, awaitingRefresh: true });
        const { selectedSet, startDate, endDate } = this.state;
        await ComputeFeederMasterNavDiff(startDate, endDate, selectedSet);
    }

    async flushServer() {
        await FlushNavCache();
        this.setState({ showAreYouSure: false });
    }

    updateDiff() {
        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var diff = navStoreInstance.getNavDiff(this.state.startDate, this.state.endDate, fund?.displayName, this.state.selectedSet);
        if (diff)
            this.setState({ report: diff, awaitingRefresh: false });
    }


    getCashRowValue(positions: NavCashPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.amount * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    getSecurityRowValue(positions: NavSecurityPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.value * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    getCfdRowValue(positions: NavCfdPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.value * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    getLoanDepoRowValue(positions: NavMoneyMarketPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.value * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    getAccrualRowValue(positions: NavAccrualPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => (p.amountToDate) * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    getMarginedRowValue(positions: NavMarginedPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.value * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }

    renderRowTitles() {
        return (<TableRow>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color, width: "50px" }} />
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Type</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Group</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Ccy</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Value (CCY)</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Value (USD)</TableCell>
        </TableRow>);
    }

    renderControls() {
        const { startDate, endDate, selectedSet, selectedEntity, awaitingRefresh } = this.state;

        return (<Grid container spacing={2} justifyContent="flex-start" alignContent="center" style={{ padding: "0.5em" }}>
            <Grid item>
                <WrappedDatePicker
                    style={{ width: "120px" }}
                    value={startDate?.toDate()}
                    onChange={this.onChangeStartDate}
                    disableFuture
                    minDate={EarliestDate}
                    emptyLabel="Live"
                    clearLabel="Live"
                    label={"Start"} />
            </Grid>
            <Grid item>
                <WrappedDatePicker
                    style={{ width: "120px" }}
                    value={endDate?.toDate()}
                    onChange={this.onChangeEndDate}
                    disableFuture
                    minDate={EarliestDate}
                    emptyLabel="Live"
                    clearLabel="Live"
                    label={"End"} />
            </Grid>
            <Grid item>
                <MarketDataSetSelector
                    id="set"
                    name="set"
                    label="Set"
                    value={selectedSet ?? "Select Set..."}
                    onChange={(set) => {
                        if (set === "Select Set...")
                            set = null;
                        this.props.onChangeState("nd_set", set)
                        this.setState({ selectedSet: set });
                    }} />
            </Grid>
            <Grid item>
                <WrappedSelect
                    id="entity"
                    name="entity"
                    label="Entity"
                    value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === selectedEntity)[0]?.displayName ?? "Select Entity..."}
                    onChange={(d) => {
                        var entity = d.target.value as string;
                        if (entity === "Select Entity...")
                            entity = null;
                        var entityId = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === entity)[0]?.firmId
                        this.props.onChangeState("nd_selectedEntity", entityId.toString())
                        var nav = navStoreInstance.getNavDiff(startDate, endDate, entity, selectedSet ?? DefaultSetName);
                        this.setState({ report: nav, selectedEntity: entityId });
                    }}>
                    {["Select Entity...", ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)].map(a =>
                        <MenuItem key={"Entity_" + a} value={a}>{a}</MenuItem>)}
                </WrappedSelect>
            </Grid>
            <Grid item >
                <Button size="small" variant="outlined" disabled={awaitingRefresh || !(selectedSet && selectedEntity && startDate && endDate)} onClick={() => this.calcDiff()}>Compute</Button>
            </Grid>
            <Grid item >
                <Button size="small" variant="outlined" onClick={() => this.setState({ showAreYouSure: true })}>Clear Cache</Button>
            </Grid>
            {awaitingRefresh ? <Grid item>
                <CircularProgress size="small" />
            </Grid> : null}
        </Grid>);
    }

    renderPositionSummary(report: NavDiffReport) {
        var start = report.positionStart;
        var end = report.positionEnd;
        var fxRatesStart = report.navStart.fxRatesToBase;
        var fxRatesEnd = report.navEnd.fxRatesToBase;

        // var startRealized = _.sum(start.positions.filter(s => !isCashBalance(s)).map(s => s.aggregatedPosition.realizedPnL * (fxRatesStart[s.aggregatedPosition.realizedPnLCcy] ?? 1.0)));
        // var endRealized = _.sum(end.positions.filter(s => !isCashBalance(s)).map(s => s.aggregatedPosition.realizedPnL * (fxRatesEnd[s.aggregatedPosition.realizedPnLCcy] ?? 1.0)));
        // var startUnRealized = _.sum(start.positions.filter(s => !isCashBalance(s)).map(s => s.unrealizedPnL * (fxRatesStart[s.unrealizedCcy] ?? 1.0)));
        // var endUnRealized = _.sum(end.positions.filter(s => !isCashBalance(s)).map(s => s.unrealizedPnL * (fxRatesEnd[s.unrealizedCcy] ?? 1.0)));

        var startRealized = _.sum(start.positions.filter(s => !isCashBalance(s)).map(s => s.aggregatedPosition.realizedPnLBase));
        var endRealized = _.sum(end.positions.filter(s => !isCashBalance(s)).map(s => s.aggregatedPosition.realizedPnLBase));
        var startUnRealized = _.sum(start.positions.filter(s => !isCashBalance(s)).map(s => s.unrealizedPnLBase));
        var endUnRealized = _.sum(end.positions.filter(s => !isCashBalance(s)).map(s => s.unrealizedPnLBase));


        var cashStart = _.sum(Object.keys(report.navStart.cashByType).map(k => {
            var keySplit = k.split('~');
            var ccy = keySplit[1];
            return report.navStart.cashByType[k] * (fxRatesStart[ccy] ?? 1.0);
        }));

        var cashEnd = _.sum(Object.keys(report.navEnd.cashByType).map(k => {
            var keySplit = k.split('~');
            var ccy = keySplit[1];
            return report.navEnd.cashByType[k] * (fxRatesEnd[ccy] ?? 1.0);
        }));

        var accrualsStart = _.sum(report.navStart.navAccruals.map(a => a.amountToDate * (fxRatesStart[a.ccy] ?? 1.0)));
        var accrualsEnd = _.sum(report.navEnd.navAccruals.map(a => a.amountToDate * (fxRatesEnd[a.ccy] ?? 1.0)));

        var totalStart = startRealized + startUnRealized + report.navStart.netSubsReds + cashStart + accrualsStart;
        var totalEnd = endRealized + endUnRealized + report.navEnd.netSubsReds + cashEnd + accrualsEnd;

        return (
            <div className="NavCalcStrategyTable" style={{ width: "70%" }}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getPositionTableTheme()}>
                        <TableContainer style={{ height: "100%", width: "100%", margin: "auto" }}>
                            <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell variant="head">Metric</TableCell>
                                        <TableCell variant="head">Start</TableCell>
                                        <TableCell variant="head">End</TableCell>
                                        <TableCell variant="head">Diff</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="navCalcTableTotalRowRe" hover>
                                        <TableCell>Realized</TableCell>
                                        <TableCell>{startRealized?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{endRealized?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{report.realizedDiffInBase?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                    <TableRow key="navCalcTableTotalRowUn" hover>
                                        <TableCell>UnRealized</TableCell>
                                        <TableCell>{startUnRealized?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{endUnRealized?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{report.unRealizedDiffInBase?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                    <TableRow key="navCalcTableTotalRowSr" hover>
                                        <TableCell>Subs/Reds</TableCell>
                                        <TableCell>{report.navStart.netSubsReds?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{report.navEnd.netSubsReds?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{report.netSubsReds?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                    <TableRow key="navCalcTableTotalRowOc" hover>
                                        <TableCell>Other Cash</TableCell>
                                        <TableCell>{cashStart.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{cashEnd.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{report.otherCashDiffInBase?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                    <TableRow key="navCalcTableTotalRowAcc" hover>
                                        <TableCell>Accruals</TableCell>
                                        <TableCell>{accrualsStart.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{accrualsEnd.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{report.accrualsDiffInBase?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                    <TableRow key="navCalcTableTotalRowT" hover>
                                        <TableCell>Total</TableCell>
                                        <TableCell>{totalStart?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{totalEnd?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell>{(totalEnd - totalStart)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }

    totalPnLInBase(pos: LivePosition, fxRates: { [ccy: string]: number }) {
        var realizedInBase = pos.aggregatedPosition.realizedPnLBase;
        var unRealizedInBase = pos.unrealizedPnLBase;
        return [realizedInBase, unRealizedInBase];
    }

    totalPnLInLocal(pos: LivePosition) {
        var realizedInBase = pos.aggregatedPosition.realizedPnL;
        var unRealizedInBase = pos.unrealizedPnL;
        return [realizedInBase, unRealizedInBase];
    }

    totalNavValueInBase(insId: number, nav: NavSummary, fxRates: { [ccy: string]: number }) {
        var isCash = isCashIns(insId);
        var ins = getIns(insId);
        var cash = this.totalCashInBase(insId, nav, fxRates);
        var sec = _.sum(nav.navSecurityPositions.filter(n => n.listedInstrumentId === insId).map(s => s.value * (fxRates[s.ccy] ?? 1.0)));
        var cfd = _.sum(nav.navCfdPositions.filter(n => n.listedInstrumentId === insId).map(s => s.value * (fxRates[s.ccy] ?? 1.0)));
        var fut = _.sum(nav.navMarginedPositions.filter(n => n.listedInstrumentId === insId).map(s => s.value * (fxRates[s.ccy] ?? 1.0)));
        var cashBalance = (!isCash ? 0 : nav.cashPositions.filter(c => c.ccy === ins.ccy)[0]?.amount ?? 0) * (fxRates[ins.ccy] ?? 1.0);

        return (cash + sec + cfd + fut + cashBalance);
    }

    totalCashInBase(insId: number, nav: NavSummary, fxRates: { [ccy: string]: number }) {
        var cashRecs = nav.cashByInstrument[insId];
        var cash = 0;
        if (cashRecs) {
            Object.keys(cashRecs).forEach(k => {
                cash += nav.cashByInstrument[insId][k] * (fxRates[k] ?? 1.0);
            });
        }
        return cash;
    }

    instrumentType(insId: number) {
        var ins = listedInstrumentStoreInstance.getInstrumentById(insId);
        switch (ins?.type) {
            case "Option":
                var ul = listedInstrumentStoreInstance.getInstrumentById(ins.underlyingId);
                return `${ul?.type} Option`;
            default:
                return ins?.type ?? "Unknown";

        }
    }

    underlying(insId: number) {
        var ins = listedInstrumentStoreInstance.getInstrumentById(insId);
        if (ins?.underlyingId)
            ins = listedInstrumentStoreInstance.getInstrumentById(ins.underlyingId);
        if (ins?.underlyingId)
            ins = listedInstrumentStoreInstance.getInstrumentById(ins.underlyingId);
        return ins?.description;
    }

    renderInstrumentSummary(report: NavDiffReport) {
        var fxRatesStart = report.navEnd.fxRatesToBase;
        var fxRatesEnd = report.navEnd.fxRatesToBase;
        var allInsIds = new Set<number>();

        var byInsStart = new Map<number, number>();
        var byInsEnd = new Map<number, number>();
        var costEnd = new Map<number, number>();
        var marketEnd = new Map<number, number>();

        report.positionStart.positions.map(pos => {
            var insId = pos.aggregatedPosition.instrumentId;
            allInsIds.add(insId);
            byInsStart[insId] = this.totalPnLInBase(pos, fxRatesStart);
            return null;
        });
        //report.positionEnd.positions.filter(p => p.aggregatedPosition.instrument.type !== "Cash").map(pos => {
        report.positionEnd.positions.map(pos => {
            var insId = pos.aggregatedPosition.instrumentId;
            allInsIds.add(insId);
            var ins = getIns(insId);
            byInsEnd[insId] = this.totalPnLInBase(pos, fxRatesEnd);
            costEnd[insId] = pos.aggregatedPosition.averagePrice * pos.aggregatedPosition.openPosition * ins.multiplier * (fxRatesEnd[pos.aggregatedPosition.realizedPnLCcy] ?? 1.0);
            marketEnd[insId] = pos.livePrice * pos.aggregatedPosition.openPosition * ins.multiplier * (fxRatesEnd[pos.aggregatedPosition.realizedPnLCcy] ?? 1.0);
            return null;
        });

        var totalDiff = 0;
        var totalStartNav = 0;
        var totalEndNav = 0;
        var totalDiffNav = 0;
        var totalStartPos = 0;
        var totalEndPos = 0;
        var totalDiffPos = 0;
        var totalCost = 0;
        var totalMarket = 0;
        var totalMarketCostDiff = 0;
        return (
            <div>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getPositionTableTheme()}>
                        <TableContainer style={{ height: "100%", width: "90%", margin: "auto" }}>
                            <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell variant="head" align="center" colSpan={3}></TableCell>
                                        <TableCell variant="head" align="center" colSpan={3} className="BLeft">Nav</TableCell>
                                        <TableCell variant="head" align="center" colSpan={3} className="BLeft">Position</TableCell>
                                        <TableCell variant="head" align="center" colSpan={1} className="BLeft"></TableCell>
                                        <TableCell variant="head" align="center" colSpan={3} className="BLeft">Cost vs. Market</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head" align="center" >Type</TableCell>
                                        <TableCell variant="head" align="center" >Id</TableCell>
                                        <TableCell variant="head" align="center" >Description</TableCell>
                                        <TableCell variant="head" align="center" className="BLeft" >Start</TableCell>
                                        <TableCell variant="head" align="center" >End</TableCell>
                                        <TableCell variant="head" align="center" >Diff</TableCell>
                                        <TableCell variant="head" align="center" className="BLeft">Start</TableCell>
                                        <TableCell variant="head" align="center" >End</TableCell>
                                        <TableCell variant="head" align="center" >Diff</TableCell>
                                        <TableCell variant="head" align="center" className="BLeft">Balance</TableCell>
                                        <TableCell variant="head" align="center" className="BLeft">Cost</TableCell>
                                        <TableCell variant="head" align="center" >Market</TableCell>
                                        <TableCell variant="head" align="center" >Diff</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.from(allInsIds).filter(i => i !== 0).sort((a, b) => this.instrumentType(a) > this.instrumentType(b) ? 1 : -1).map(i => {
                                        var startNav = this.totalNavValueInBase(i, report.navStart, fxRatesStart) ?? 0;
                                        var endNav = this.totalNavValueInBase(i, report.navEnd, fxRatesEnd) ?? 0
                                        var totalNav = endNav - startNav;
                                        var startPos = (byInsStart[i] ?? 0);
                                        var endPos = (byInsEnd[i] ?? 0);
                                        var totalPos = endPos - startPos;
                                        totalDiff += totalPos - totalNav;
                                        totalStartNav += startNav;
                                        totalEndNav += endNav;
                                        totalStartPos += startPos;
                                        totalEndPos += endPos;
                                        totalDiffPos += (endPos - startPos);
                                        totalDiffNav += (endNav - startNav);
                                        totalCost += (costEnd[i] ?? 0);
                                        totalMarket += (marketEnd[i] ?? 0);
                                        var mDiffEnd = (marketEnd[i] ?? 0) - (costEnd[i] ?? 0);
                                        totalMarketCostDiff += mDiffEnd;
                                        if (mDiffEnd === 0 && totalNav === 0 && totalPos === 0)
                                            return null;
                                        return (
                                            <TableRow key={`navDiffRowXX-${i}`} hover>
                                                <TableCell align="center">{this.instrumentType(i)}</TableCell>
                                                <TableCell>{i}</TableCell>
                                                <TableCell>{listedInstrumentStoreInstance.getInstrumentById(i)?.description}</TableCell>
                                                <TableCell className="BLeft">{this.totalNavValueInBase(i, report.navStart, fxRatesStart)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell>{this.totalNavValueInBase(i, report.navEnd, fxRatesEnd)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell>{totalNav?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell className="BLeft">{startPos.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell>{endPos.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell>{totalPos?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell className="BLeft">{(totalPos - totalNav)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell className="BLeft">{costEnd[i]?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell>{marketEnd[i]?.toLocaleString(undefined, formatOps2)}</TableCell>
                                                <TableCell>{mDiffEnd?.toLocaleString(undefined, formatOps2)}</TableCell>
                                            </TableRow>)
                                    })}
                                    <TableRow key={`navDiffRowXX-Total`} hover>
                                        <TableCell variant="head" align="center" colSpan={3}>Total</TableCell>
                                        <TableCell variant="head" className="BLeft">{(totalStartNav)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head">{(totalEndNav)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head">{(totalDiffNav)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head" className="BLeft">{(totalStartPos)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head">{(totalEndPos)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head">{(totalDiffPos)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head" className="BLeft">{(totalDiff)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head" className="BLeft">{(totalCost)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head">{(totalMarket)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                        <TableCell variant="head">{(totalMarketCostDiff)?.toLocaleString(undefined, formatOps2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }

    customToolbar(props: any) {
        const fileName = `PltfmDiffByInstrument~${this.state.startDate.format("yyyy-MM-DD")}~${this.state.endDate.format("yyyy-MM-DD")}`;
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer>
                    <Grid container spacing={1} style={{ paddingBottom: "0.3em", paddingTop: "0.3em" }}>
                        <Grid item>
                            <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" csvOptions={{ fileName: fileName }} />
                        </Grid>
                        <Grid item>
                            <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                        </Grid>
                        <Grid item>
                            <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                        </Grid>
                        <Grid item>
                            <GridToolbarDensitySelector {...props} className="MuiButton-outlined PltfmButtonLite" />
                        </Grid>
                    </Grid>
                    <WrappedSelect
                        key="localOrBase"
                        value={this.state.ccyConversion}
                        onChange={(e, c) => this.setState({ ccyConversion: (e.target.value as string) as "local" | "base" })}
                        size="small"
                        label="Currency"
                        style={{ width: "100px" }}                         >
                        <MenuItem value="base">Base</MenuItem>
                        <MenuItem value="local">Local</MenuItem>
                    </WrappedSelect>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    renderCcyCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
        }
        else
            return <div></div>;
    }

    renderInstrumentSummary2(report: NavDiffReport, ccyConversion: "local" | "base") {
        const isLocal = ccyConversion === "local";
        var fxRatesStart = isLocal ? { "USD": 1.0 } : report.navStart.fxRatesToBase;
        var fxRatesEnd = isLocal ? { "USD": 1.0 } : report.navEnd.fxRatesToBase;
        var allInsIds = new Set<number>();

        var byInsStart = new Map<number, number[]>();
        var byInsEnd = new Map<number, number[]>();
        var valueStart = new Map<number, number>();
        var valueEnd = new Map<number, number>();

        var priceStart = new Map<number, number>();
        var priceEnd = new Map<number, number>();
        var sizeStart = new Map<number, number>();
        var sizeEnd = new Map<number, number>();

        var fxTranslation = new Map<number, number>();
        var changeInCash = new Map<number, number>();

        const columns: GridColDef[] = [
            { field: 'type', width: 130, headerName: 'Type', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'insId', width: 130, headerName: 'InsId', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'insDescription', width: 200, headerName: 'Description', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'insUnderlying', width: 200, headerName: 'Underlying', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'insCcy', width: 150, headerName: 'Currency', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'insMultiplier', width: 150, headerName: 'Factor', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number" },
            { field: 'startNav', width: 160, headerName: 'Start NAV', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'endNav', width: 160, headerName: 'End NAV', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'navDiff', width: 160, headerName: 'Diff NAV', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'startPos', width: 160, headerName: 'Start Pos', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'endPos', width: 160, headerName: 'End Pos', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'posDiff', width: 160, headerName: 'Diff Pos', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'cashMove', width: 160, headerName: 'Cash Moves', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'startRealized', width: 200, headerName: 'Start Realized', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'endRealized', width: 200, headerName: 'End Realized', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'startUnRealized', width: 200, headerName: 'Start UnRealized', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'endUnRealized', width: 200, headerName: 'End UnRealized', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'startQuantity', width: 200, headerName: 'Start Quantity', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'endQuantity', width: 200, headerName: 'End Quantity', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'startPrice', width: 200, headerName: 'Start Price', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number" },
            { field: 'endPrice', width: 200, headerName: 'End Price', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number" },
            { field: 'posVsNavDiff', width: 200, headerName: 'Pos vs NAV Diff', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'valueStart', width: 200, headerName: 'Start Value', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'valueEnd', width: 200, headerName: 'End Value', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'valueDiff', width: 200, headerName: 'Diff Value', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
        ];

        //var cnyPosS = report.positionStart.positions.filter(x=>x.aggregatedPosition.instrumentId===149119);
        //onsole.log(JSON.stringify(cnyPosS));
        //var cnyPosE = report.positionEnd.positions.filter(x=>x.aggregatedPosition.instrumentId===149119);
        //console.log(JSON.stringify(cnyPosE));

        report.positionStart.positions.map(pos => {
            var insId = pos.aggregatedPosition.instrumentId;
            var ins = getIns(insId);
            allInsIds.add(insId);
            if (byInsStart[insId] === undefined)
                byInsStart[insId] = [0, 0];
            var pnl = isLocal ? this.totalPnLInLocal(pos) : this.totalPnLInBase(pos, fxRatesStart);
            //var pnl = this.totalPnLInBase(pos, fxRatesStart);
            byInsStart[insId][0] += pnl[0];
            byInsStart[insId][1] += pnl[1];

            priceStart[insId] = pos.livePrice;
            if (sizeStart[insId] === undefined)
                sizeStart[insId] = 0;
            sizeStart[insId] += pos.aggregatedPosition.openPosition;

            valueStart[insId] = pos.livePrice * pos.aggregatedPosition.openPosition * ins.multiplier * (fxRatesStart[pos.aggregatedPosition.realizedPnLCcy] ?? 1.0);

            fxTranslation[insId] = this.totalCashInBase(insId, report.navStart, fxRatesEnd) - this.totalCashInBase(insId, report.navStart, fxRatesStart);
            changeInCash[insId] = this.totalCashInBase(insId, report.navEnd, fxRatesEnd) - this.totalCashInBase(insId, report.navStart, fxRatesStart);

            return null;
        });

        report.positionEnd.positions.map(pos => {
            var insId = pos.aggregatedPosition.instrumentId;
            var ins = getIns(insId);
            allInsIds.add(insId);
            if (byInsEnd[insId] === undefined)
                byInsEnd[insId] = [0, 0];
            var pnlEnd = isLocal ? this.totalPnLInLocal(pos) : this.totalPnLInBase(pos, fxRatesEnd);
            //var pnlEnd =  this.totalPnLInBase(pos, fxRatesEnd);
            byInsEnd[insId][0] += pnlEnd[0];
            byInsEnd[insId][1] += pnlEnd[1];

            priceEnd[insId] = pos.livePrice;
            if (sizeEnd[insId] === undefined)
                sizeEnd[insId] = 0;
            sizeEnd[insId] += pos.aggregatedPosition.openPosition;


            valueEnd[insId] = pos.livePrice * pos.aggregatedPosition.openPosition * ins.multiplier * (fxRatesEnd[pos.aggregatedPosition.realizedPnLCcy] ?? 1.0);
            return null;
        });

        const rows = Array.from(allInsIds).filter(i => i !== 0).sort((a, b) => this.instrumentType(a) > this.instrumentType(b) ? 1 : -1).map((i, ix) => {
            var startNav = this.totalNavValueInBase(i, report.navStart, fxRatesStart) ?? 0;
            var endNav = this.totalNavValueInBase(i, report.navEnd, fxRatesEnd) ?? 0
            var fxT = fxTranslation[i] ?? 0;
            var totalNav = endNav - startNav - fxT;
            var startPos = (byInsStart[i] ? (byInsStart[i][0] + byInsStart[i][1]) : 0);
            var endPos = (byInsEnd[i] ? (byInsEnd[i][0] + byInsEnd[i][1]) : 0);
            var totalPos = endPos - startPos;
            var startPosRealized = (byInsStart[i] ? (byInsStart[i][0]) : 0);
            var endPosRealized = (byInsEnd[i] ? (byInsEnd[i][0]) : 0);
            var startPosUnRealized = (byInsStart[i] ? (byInsStart[i][1]) : 0);
            var endPosUnRealized = (byInsEnd[i] ? (byInsEnd[i][1]) : 0);
            var urDiff = endPosUnRealized - startPosUnRealized;

            var mDiffEnd = (valueEnd[i] ?? 0) - (valueStart[i] ?? 0);

            if (mDiffEnd === 0 && totalNav === 0 && totalPos === 0 && urDiff === 0)
                return null;
            var row = {
                id: ix,
                type: this.instrumentType(i),
                insId: i,
                insDescription: listedInstrumentStoreInstance.getInstrumentById(i)?.description,
                insUnderlying: this.underlying(i),
                insCcy: listedInstrumentStoreInstance.getInstrumentById(i)?.ccy,
                insMultiplier: listedInstrumentStoreInstance.getInstrumentById(i)?.multiplier,
                startNav: startNav,
                endNav: endNav,
                navDiff: totalNav,
                startPos: startPos,
                endPos: endPos,
                posDiff: totalPos,
                posVsNavDiff: totalPos - totalNav,
                valueStart: valueStart[i],
                valueEnd: valueEnd[i],
                valueDiff: mDiffEnd,
                startRealized: startPosRealized,
                endRealized: endPosRealized,
                startUnRealized: startPosUnRealized,
                endUnRealized: endPosUnRealized,
                startQuantity: sizeStart[i],
                endQuantity: sizeEnd[i],
                startPrice: priceStart[i],
                endPrice: priceEnd[i],
                cashMove: changeInCash[i] ?? 0
            };
            return row;
        }).filter(r => r !== null);


        return (
            <div className="NavDiffTableInstrumentInner">
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    hideFooter
                    components={{
                        Toolbar: this.customToolbar,
                    }} />
            </div>);
    }

    renderPctCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{(val * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
        }
        else
            return <div></div>;
    }

    renderStrategySummary(report: NavDiffReport) {

        if (!report.pctDiffInBaseByMetaKey || !report.diffInBaseByMetaKey)
            return null;

        const columns: GridColDef[] = [
            { field: 'strategy', width: 200, headerName: 'Strategy', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" },
            { field: 'usdPnL', width: 200, headerName: 'USD Day', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderCcyCell },
            { field: 'pctDay', width: 200, headerName: '% Day', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderPctCell },
            { field: 'pctItd', width: 200, headerName: '% ITD', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderPctCell },
        ];


        const rows = Array.from(Object.keys(report.diffInBaseByMetaKey)).sort((a, b) => a > b ? 1 : -1).map((i, ix) => {
            var row = {
                id: ix,
                strategy: i.split('~')[1],
                usdPnL: report.diffInBaseByMetaKey[i],
                pctDay: report.diffInBaseByMetaKey[i] / report.navStart.totalInBase,
                pctItd: report.pctDiffInBaseByMetaKey[i],
            };
            return row;
        });


        return (<div className="NavDiffTableInstrumentInner">
            <DataGridPro
                key="strategyDiffTable"
                rows={rows}
                columns={columns}
                hideFooter
                components={{
                    Toolbar: this.customToolbar,
                    //LoadingOverlay: this.loading,
                }} />
        </div>);
    }

    renderFlushCacheConfirm() {
        const { showAreYouSure } = this.state;
        return (
            <Dialog open={showAreYouSure} onClose={() => this.setState({ showAreYouSure: false })}  >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Are you sure?</DialogTitle>
                <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to flush the server-side NAV cache?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                    <Button variant="outlined" onClick={() => this.flushServer()}>Flush Cache</Button>
                    <Button variant="outlined" onClick={() => this.setState({ showAreYouSure: false })} autoFocus>Cancel</Button>
                </DialogActions>
            </Dialog >);
    }

    renderNavAttribution(report: NavDiffReport) {
        var cashByInstoCcy = _.groupBy(report?.cashPositionsDiff, (c) => c.institution + "~" + c.ccy);
        var securitiesByTypeCcy = _.groupBy(report?.navSecurityPositionsDiff, (c) => listedInstrumentStoreInstance.getInstrumentById(c.listedInstrumentId)?.type + "~" + c.ccy);
        var marginedSecuritiesByTypeCcy = _.groupBy(report?.navMarginedPositionsDiff, (c) => listedInstrumentStoreInstance.getInstrumentById(c.listedInstrumentId)?.type + "~" + c.ccy);
        var cfdsByCcy = _.groupBy(report?.navCfdPositionsDiff, (c) => c.ccy);
        var loansByCcy = _.groupBy(report?.navMoneyMarketPositionsDiff, (c) => c.counterparty + "~" + c.ccy);
        var acrualsByCategoryCcy = _.groupBy(report?.navAccrualsDiff, (c) => c.category + "~" + c.ccy);

        var totalCash = _.sum(Object.keys(cashByInstoCcy).map((k) => this.getCashRowValue(cashByInstoCcy[k], report.navEnd.fxRatesToBase)));
        var totalSecurity = _.sum(Object.keys(securitiesByTypeCcy).map((k) => this.getSecurityRowValue(securitiesByTypeCcy[k], report.navEnd.fxRatesToBase)));
        var totalMargined = _.sum(Object.keys(marginedSecuritiesByTypeCcy).map((k) => this.getMarginedRowValue(marginedSecuritiesByTypeCcy[k], report.navEnd.fxRatesToBase)));
        var totalCfd = _.sum(Object.keys(cfdsByCcy).map((k) => this.getCfdRowValue(cfdsByCcy[k], report.navEnd.fxRatesToBase)));
        var totalLoans = _.sum(Object.keys(loansByCcy).map((k) => this.getLoanDepoRowValue(loansByCcy[k], report.navEnd.fxRatesToBase)));
        var totalAccurals = _.sum(Object.keys(acrualsByCategoryCcy).map((k) => this.getAccrualRowValue(acrualsByCategoryCcy[k], report.navEnd.fxRatesToBase)));

        var total = report?.totalDiffInBase ?? 0;
        var formatOps = { maximumFractionDigits: 0, currency: report?.baseCcy };
        var totalStr = total?.toLocaleString(undefined, formatOps);

        return <div className="NavCalcStrategyTable">
            <ThemeProvider theme={getPositionTableTheme()}>
                <TableContainer style={{ height: "100%" }}>
                    <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                        <TableHead>
                            {this.renderRowTitles()}
                        </TableHead>
                        <TableBody>
                            <TableRow key="navCalcTableTotalCashRow" hover>
                                <TableCell colSpan={2} />
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Cash Total</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalCash.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow key="navCalcTableTotalLoanRow" hover>
                                <TableCell colSpan={2} />
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Money Market Total</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalLoans.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow key="navCalcTableTotalAccrualRow" hover>
                                <TableCell colSpan={2} />
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Accruals Total</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalAccurals.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow key="navCalcTableTotalSecRow" hover>
                                <TableCell colSpan={2} />
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Securities Total</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalSecurity.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow key="navCalcTableTotalCfdRow" hover>
                                <TableCell colSpan={2} />
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>CFD Total</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalCfd.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow key="navCalcTableTotalMarginedRow" hover>
                                <TableCell colSpan={2} />
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Margined Total</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalMargined.toLocaleString()}</TableCell>
                            </TableRow>
                            <TableRow key="navCalcTableTotalRow" hover>
                                <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} />
                                <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} align="center" colSpan={3}>Total</TableCell>
                                <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} align="center" colSpan={1}>USD</TableCell>
                                <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} align="center">{totalStr}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </div>
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    render() {
        const { report, mainRef, selectedTabId, ccyConversion } = this.state;
        var showPositionDetail = report && report.positionStart;

        return (<ThemeProvider theme={getFormTheme()}>
            <div className="NavDiffTableContainer" ref={mainRef}>
                {this.renderFlushCacheConfirm()}


                <TabContext value={selectedTabId.toString()}>
                    <Tabs key='navDiffTabs' value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                        <Tab
                            classes={{ root: "tabTitle" }}
                            value={"0"}
                            key={"tabQuery1"}
                            component={Paper}
                            id={"tab0"}
                            label="Summary" />
                        <Tab
                            classes={{ root: "tabTitle" }}
                            value={"1"}
                            key={"tabQuery2"}
                            component={Paper}
                            id={"tab1"}
                            label="Instrument" />
                        <Tab
                            classes={{ root: "tabTitle" }}
                            value={"2"}
                            key={"tabQuery3"}
                            component={Paper}
                            id={"tab2"}
                            label="Strategy" />
                    </Tabs>
                    <TabPanel
                        style={{ minHeight: "75px", backgroundColor: userStoreInstance.GetTheme().background_color, padding: "0px" }}
                        key={"tabPQuery1"}
                        value={"0"}
                        //index={0}
                        children={<div className="NavDiffTableSummaryTab">
                            <div className="NavCalcTableControls">
                                {this.renderControls()}
                            </div>
                            {!showPositionDetail ? null :
                                <div className="NavDiffTablePosAttribution">
                                    <Typography variant="h6" style={{ paddingLeft: "0.5em" }}>Position Attribution</Typography>
                                    {this.renderPositionSummary(report)}
                                </div>
                            }
                            {!report ? this.renderSpinner :
                                <div className="NavCalcTable">
                                    <Typography variant="h6" style={{ paddingLeft: "0.5em" }}>NAV Attribution</Typography>
                                    {this.renderNavAttribution(report)}
                                </div>}
                        </div>} />
                    <TabPanel
                        style={{ minHeight: "75px", backgroundColor: userStoreInstance.GetTheme().background_color, padding: "0px" }}
                        key={"tabPQuery2"}
                        value={"1"}
                        //index={1}
                        children={!showPositionDetail ? null :
                            <div className="NavDiffTableInstrumentAttribution">
                                <Typography variant="h6" style={{ paddingLeft: "0.5em" }}>Instrument Attribution</Typography>
                                {this.renderInstrumentSummary2(report, ccyConversion)}
                            </div>} />
                    <TabPanel
                        style={{ minHeight: "75px", backgroundColor: userStoreInstance.GetTheme().background_color, padding: "0px" }}
                        key={"tabPQuery3"}
                        value={"2"}
                        //index={1}
                        children={!showPositionDetail ? null :
                            <div className="NavDiffTableInstrumentAttribution">
                                <Typography variant="h6" style={{ paddingLeft: "0.5em" }}>Strategy Attribution</Typography>
                                {this.renderStrategySummary(report)}
                            </div>} />
                </TabContext>
            </div>
        </ThemeProvider>);
    }
}
