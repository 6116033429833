import moment from 'moment';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { AssetStr, AttributionStr, VaRProviders } from '../globalConstants';
import { get, post } from '../utils/httpUtils';
import { BetaAnalysisResult, QwackRiskResult, VaRResult, WhatIfTrade } from './riskModels';

export class NewQwackRiskEvent extends TypedEvent<QwackRiskResult> { }
export class NewQwackRiskEventError extends TypedEvent<string> { }

export async function ComputeQwackPV(date: Date, setName: string = undefined) {
    var dateStr = moment(date).format("yyyy-MM-DD");
    const pvs = setName ? await get<QwackRiskResult>(`Risk/QwackPV/${dateStr}/${setName}`) : await get<QwackRiskResult>(`Risk/QwackPV/${dateStr}/default`);
    if (pvs?.payload) {
        AppDispatcher.dispatch(new NewQwackRiskEvent(pvs.payload));
    }
}

export async function ComputeQwackRisk(date: Date, metric: string, setName: string = undefined) {
    var dateStr = moment(date).format("yyyy-MM-DD");
    var promise = setName ? get<QwackRiskResult>(`Risk/QwackRisk/${metric}/${dateStr}/${setName}`) : get<QwackRiskResult>(`Risk/QwackRisk/${metric}/${dateStr}/default`);
    // promise.catch((r => {
    //     var key = `${moment(date).format("yyyy-MM-DD")}-${setName ? setName : "default"}-${metric}`;
    //     AppDispatcher.dispatch(new NewQwackRiskEventError(key));
    // }))
    const pvs = await promise;
    if (pvs?.payload)
        AppDispatcher.dispatch(new NewQwackRiskEvent(pvs.payload));
    // var promise = setName ? get<QwackRiskResult>(`Nav/QwackRisk/${metric}/${dateStr}/${setName}`) : get<QwackRiskResult>(`Nav/QwackRisk/${metric}/${dateStr}/default`);

    // const pvs = await promise;
    // if (pvs.payload)
    //     AppDispatcher.dispatch(new NewQwackRiskEvent(pvs.payload));
}

export async function ComputeQwackPnL(startDate: Date, endDate: Date, setName: string = undefined) {
    var startDateStr = moment(startDate).format("yyyy-MM-DD");
    var endDateStr = moment(endDate).format("yyyy-MM-DD");

    const pnl = setName ? await get<QwackRiskResult>(`Risk/QwackPnL/${startDateStr}/${endDateStr}/${setName}`) : await get<QwackRiskResult>(`Risk/QwackPnL/${startDateStr}/${endDateStr}/default`);
    if (pnl?.payload) {
        AppDispatcher.dispatch(new NewQwackRiskEvent(pnl.payload));
    }
}

export async function ComputeQwackGammaLadder(date: Date, assetId: string, stepSize: number, nSteps: number, setName: string = undefined, metric: string = "QwackGamma") {
    var dateStr = moment(date).format("yyyy-MM-DD");
    setName = setName ?? "default";
    var promise = get<QwackRiskResult>(`Risk/${metric}/${assetId}/${stepSize}/${nSteps}/${dateStr}/${setName}`);

    const pvs = await promise;
    if (pvs?.payload) {
        pvs.payload.cacheKey = `${moment(date).format("yyyy-MM-DD")}-${setName ? setName : "default"}-${metric}-${assetId}`;
        AppDispatcher.dispatch(new NewQwackRiskEvent(pvs.payload));
    }
}

export async function ComputeQwackGammaMatrix(date: Date, assetId: string, stepSize: number, nSteps: number, nStepsTime: number, setName: string = undefined, metric: string = "QwackGamma") {
    var dateStr = moment(date).format("yyyy-MM-DD");
    setName = setName ?? "default";
    var promise = get<QwackRiskResult>(`Risk/${metric}/${assetId}/${stepSize}/${nSteps}/${dateStr}/${setName}/${nStepsTime}`);

    const pvs = await promise;
    if (pvs?.payload) {
        pvs.payload.cacheKey = `${moment(date).format("yyyy-MM-DD")}-${setName ? setName : "default"}-${metric}-${assetId}`;
        AppDispatcher.dispatch(new NewQwackRiskEvent(pvs.payload));
    }
}

export async function ComputeVaR(date: Date, ci?: number, windowSize?: string, method:string = VaRProviders[0], jobUpdateKey?: string, computeStress?: boolean) {
    var dateStr = moment(date).format("yyyy-MM-DD");
    var diffKeysCsv = `${AssetStr},${AttributionStr}`;
    var queryStr = `Risk/VaR/${dateStr}?diffKeysCsv=${diffKeysCsv}&method=${method}&jobKey=${jobUpdateKey}`;
    if (ci !== undefined)
        queryStr += `&ci=${ci}`;
    if (windowSize !== undefined) {
        queryStr += `&windowSize=${windowSize}`;
    }
    if (computeStress !== undefined) {
        queryStr += `&computeStress=${computeStress}`;
    }

    var result = await get<VaRResult>(queryStr);
    return result?.payload;
}

export async function ComputeVaRWhatIf(date: Date, insId: number, insQuantity: number, insPrice: number,  ci?: number, windowSize?: string, jobUpdateKey?: string, method:string = VaRProviders[0]) {
    var dateStr = moment(date).format("yyyy-MM-DD");
    //"VaR/WhatIf/{date}/{insId}/{insQuantity}/{insPrice}"
    var queryStr = `Risk/VaR/WhatIf/${dateStr}/${insId}/${insQuantity}/${insPrice}?method=${method}`;
    if (ci !== undefined)
        queryStr += `&ci=${ci}`;
    if (windowSize !== undefined) {
        queryStr += (queryStr.includes('?') ? '&' : '?') + `windowSize=${windowSize}`;
    }
    if (jobUpdateKey !== undefined) {
        queryStr += (queryStr.includes('?') ? '&' : '?') + `jobKey=${jobUpdateKey}`;
    }
    var result = await get<VaRResult>(queryStr);
    return result?.payload;
}


export async function ComputeVaRWhatIfMulti(date: Date, trades: WhatIfTrade[],  ci?: number, windowSize?: string, jobUpdateKey?: string, method:string = VaRProviders[0]) {
    var dateStr = moment(date).format("yyyy-MM-DD");
    //"VaR/WhatIf/{date}/{insId}/{insQuantity}/{insPrice}"
    var queryStr = `Risk/VaR/WhatIf/${dateStr}?method=${method}`;
    if (ci !== undefined)
        queryStr += `&ci=${ci}`;
    if (windowSize !== undefined) {
        queryStr += (queryStr.includes('?') ? '&' : '?') + `windowSize=${windowSize}`;
    }
    if (jobUpdateKey !== undefined) {
        queryStr += (queryStr.includes('?') ? '&' : '?') + `jobKey=${jobUpdateKey}`;
    }
    var result = await post<VaRResult>(queryStr, trades);
    return result?.payload;
}

export async function ComputeBetaAnalysis(insId: number, windowSize?: string) {
    var queryStr = `Risk/Beta/${insId}`;
    if (windowSize !== undefined) {
        queryStr += (queryStr.includes('?') ? '&' : '?') + `windowSize=${windowSize}`;
    }
    var result = await get<BetaAnalysisResult>(queryStr);
    return result?.payload;
}

export async function ComputeBetaAnalysisMulti(insIds: number[], windowSize?: string) {
    var queryStr = `Risk/BetaMulti/?insIdsCsv=${insIds.join(",")}`;
    if (windowSize !== undefined) {
        queryStr += (queryStr.includes('?') ? '&' : '?') + `windowSize=${windowSize}`;
    }
    var result = await get<{[insId:number]:BetaAnalysisResult}>(queryStr);
    return result?.payload;
}