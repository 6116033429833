import React, { FunctionComponent } from 'react';
import { AssetGroup, AssetClass, Asset } from '../assets/assetsModels';
import { Payoff, Period } from '../payoff/payoffModels';
//import moment from 'moment-timezone';
//import * as Common from '../homepage/productDesigner/productDesignerCommon';
import { Relationship } from '../relationship/relationship';
import { ScheduleRow } from '../product/productModels';
import { CandleSize } from '../marketData/marketDashboard/marketDashboard';
import { Select, MenuItem, Divider, Button } from '@mui/material';
import { WrappedSelect } from './wrappedSelect';

type AssetGroupDropDownProps = {
    onChange: (assetGroup: AssetGroup | undefined) => void,
    assetGroups: AssetGroup[],
    selectedAssetGroup: AssetGroup | undefined;
    disabled: boolean;
}
export const AssetGroupDropDown: FunctionComponent<AssetGroupDropDownProps> = ({ onChange, assetGroups, selectedAssetGroup, disabled }) =>
    <Select disabled={disabled} style={{ width: "150px" }} onChange={(e) => onChange(assetGroups.find(uc => uc.name === e.target.value))} value={selectedAssetGroup ? selectedAssetGroup.name : ''}>
        {assetGroups.map(uc => <option key={uc.name} value={uc.name} disabled={uc.disabled}>{uc.name}</option>)}
    </Select>;

type AssetClassDropdownProps = {
    onChange: (assetClass: AssetClass | undefined) => void,
    assetClasses: AssetClass[],
    selectedAssetClass: AssetClass | undefined;
    disabled: boolean;
}
export const AssetClassDropdown: FunctionComponent<AssetClassDropdownProps> = ({ onChange, assetClasses, selectedAssetClass, disabled }) =>
    <Select disabled={disabled} style={{ width: "150px" }} className="productDesignerSelect" value={selectedAssetClass ? selectedAssetClass.name : ''} onChange={(e) => onChange(FindOrDefault(assetClasses, ac => ac.name === e.target.value))}>
        {assetClasses.map(ac => <option className="productDesignerSelect option" key={ac.name} value={ac.name} disabled={ac.disabled}>{ac.name}</option>)}
    </Select>;

type AssetDropDownProps = {
    onChange: (asset: Asset | undefined) => void,
    assets: Asset[],
    selectedAsset: Asset | undefined;
    disabled: boolean;
};
export const AssetDropDown: FunctionComponent<AssetDropDownProps> = ({ onChange, assets, selectedAsset, disabled }) => <Select disabled={disabled} style={{ width: "150px" }} value={selectedAsset ? selectedAsset.assetId : ''} onChange={(e) => onChange(FindOrDefault(assets, a => a.assetId.toString() === e.target.value))}>
    {assets.map(a => <option className="StringTypeEnum Option" key={a.assetId} value={a.assetId} disabled={a.disabled}>{a.name}</option>)}
</Select>;

type PayoffDropDownProps = {
    onChange: (payoff: Payoff | undefined) => void,
    payoffs: Payoff[],
    selectedPayoff: Payoff | undefined;
    disabled: boolean;
    className?: string;
}

export const PayoffDropDown: FunctionComponent<PayoffDropDownProps> = ({ onChange, payoffs, selectedPayoff, disabled, className }) =>
    <Select
        variant="outlined"
        className={(className ? className + " " : "") + "StringTypeEnumClosed"}
        classes={{ iconOutlined: "StringTypeEnumIcon" }}
        disabled={disabled}
        value={selectedPayoff ? selectedPayoff.payoffId : ''}
        onChange={(e, name) => onChange(FindOrDefault(payoffs, p => { return p.payoffId.toString() === e.target.value.toString(); }))}>
        {payoffs.map(p => <MenuItem className="StringTypeEnumOption" key={p.payoffId} value={p.payoffId} disabled={p.disabled} >{p.name}</MenuItem>)}
    </Select>;

type StringTypeDropDownProps = {
    onChange: (value: string) => void,
    possibles: string[],
    selected: string;
    disabled: boolean;
    className?: string;
    label?: string;
    style?: React.CSSProperties;
    size?: 'small' | 'medium'
}
export const StringTypeDropDown: FunctionComponent<StringTypeDropDownProps> = ({ onChange, possibles, selected, disabled, style, label, size }) =>
    <WrappedSelect
        style={style}
        label={label}
        disabled={disabled}
        size={size}
        value={selected ? selected : ''}
        onChange={disabled ? null : (e) => onChange(FindOrDefault(possibles, p => { return p === e.target.value; }))}>
        {possibles.map(p => <MenuItem  key={p} value={p} >{p}</MenuItem>)}
    </WrappedSelect>;


type CandleSizeDropDownProps = {
    onChange: (payoff: CandleSize | undefined) => void,
    sizes: CandleSize[],
    selectedSize: CandleSize | undefined;
    disabled: boolean;
    className?: string;
}
export const CandleSizeDropDown: FunctionComponent<CandleSizeDropDownProps> = ({ onChange, sizes, selectedSize, disabled, className }) =>
    <Select
        variant="outlined"
        className={(className ? className + " " : "") + "StringTypeEnumClosed"}
        classes={{ iconOutlined: "StringTypeEnumIcon" }}
        disabled={disabled}
        value={selectedSize ? selectedSize.sizeInSec : ''}
        onChange={(e, name) => onChange(FindOrDefault(sizes, p => { return p.sizeInSec.toString() === e.target.value.toString(); }))}>
        {sizes.map(p => <MenuItem className="StringTypeEnumOption" key={p.sizeInSec} value={p.sizeInSec} >{p.label}</MenuItem>)}
    </Select>;

type CurrencyDropDownProps = {
    onChange: (currency: string | undefined) => void,
    currencies: string[],
    selectedCurrency: string | undefined;
    disabled: boolean;
}

type RelationshipDropDownProps = {
    onChange: (relationship: Relationship | undefined) => void,
    relationships: Relationship[],
    selectedRelationship: Relationship | undefined;
}

type PeriodDropDownProps = {
    onChange: (period: Period | undefined, isPeriod2: boolean) => void,
    periods: Period[],
    selectedPeriod: Period | undefined;
    strip: boolean;
    selectedPeriod2: Period | undefined;
    disabled: boolean;
}

type PeriodRowControlProps = {
    onChange: (period: Period | undefined) => void,
    onDateChange: (date: Date, isStartDate: boolean) => void,
    periods: Period[],
    selectedPeriod: Period | undefined,
    startDateCustom: Date | null,
    endDateCustom: Date | null,
    isCustom: boolean,
    keyy: string,
    showSize: boolean,
    size: number,
    onSizeChange: (newSize: number) => void,
    disabled: boolean
}

type PeriodStripGeneratorProps = {
    onChange: (period: Period | undefined, isPeriod2: boolean) => void,
    onClickGenerate: () => void,
    periods: Period[],
    selectedPeriod: Period | undefined;
    selectedPeriod2: Period | undefined;
    onChangeOption: (optionName: string, state: boolean) => void,
    priceAsOne: boolean,
    profile: boolean,
    disabled: boolean
}

type PeriodControlProps = {
    onChangeRows: (selectedPeriods: ScheduleRow[]) => void,
    onStartEndChange: (period: Period | undefined, isStartPeriod: boolean) => void,
    onDateChange: (periodIx: number, date: Date, isStartDate: boolean) => void,
    onClickGenerate: () => void,
    periods: Period[],
    scheduleRows: ScheduleRow[],
    startPeriod: Period | undefined,
    endPeriod: Period | undefined,
    isStrip: boolean;
    onChangeOption: (optionName: string, state: boolean) => void,
    priceAsOne: boolean,
    profile: boolean,
    rfqSize: number,
    disabled: boolean
}



export const CurrencyDropDown: FunctionComponent<CurrencyDropDownProps> = ({ onChange, currencies, selectedCurrency, disabled }) => <Select disabled={disabled} style={{ width: "150px" }} value={selectedCurrency ? selectedCurrency : ''}>  
    {currencies.map(c => <option className="productDesignerSelect option" key={c} value={c}>{c}</option>)}
</Select>;

export const RelationshipDropDown: FunctionComponent<RelationshipDropDownProps> = ({ onChange, relationships, selectedRelationship }) =>
    <Select style={{ width: "300px", alignSelf: "center" }} value={selectedRelationship ? selectedRelationship.fullName : ''} onChange={(e) => onChange(relationships.filter(r => r.fullName === e.target.value)[0])}>
        <option selected>Select Client...</option>
        {relationships.map(c => <option className="productDesignerSelect option" key={c.partyId} value={c.fullName}>{c.fullName}</option>)}
    </Select>;



export const PeriodDropDown: FunctionComponent<PeriodDropDownProps> = ({ onChange, periods, selectedPeriod, strip, selectedPeriod2, disabled }) => {
    if (strip) {
        return (<div>
            <Select disabled={disabled}  value={selectedPeriod ? selectedPeriod.name : ''} onChange={(e) => onChange(FindOrDefault(periods, p => p.name === e.target.value), false)}>
                {periods.map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
            </Select>
            <Divider />
            <Select disabled={disabled}  value={selectedPeriod2 ? selectedPeriod2.name : ''} onChange={(e) => onChange(FindOrDefault(periods, p => p.name === e.target.value), true)}>
                {periods.filter(p => selectedPeriod ? p.t > selectedPeriod.t : true).map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
            </Select>
        </div>);
    }
    else {
        return (<div>
            <Select disabled={disabled} value={selectedPeriod ? selectedPeriod.name : ''} onChange={(e) => onChange(FindOrDefault(periods, p => p.name === e.target.value), false)}>
                {periods.map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
            </Select></div>);
    }
};

export const PeriodRowControl: FunctionComponent<PeriodRowControlProps> = ({ onChange, onDateChange, periods, selectedPeriod, keyy, size, onSizeChange, showSize, disabled }) => {
    return (<div style={{ display: "inline-flex" }}>
        <Select disabled={disabled} key={`${keyy}selecta`} value={selectedPeriod ? selectedPeriod.name : ''} onChange={(e) => onChange(FindOrDefault(periods, p => p.name === e.target.value))}>
            {periods.map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
        </Select>
        <Divider />
        {/* <DateRangeInput
            disabled={disabled}
            key={`${keyy}datePicker`}
            formatDate={date => moment(date).format("YYYY-MM-DD")}
            onChange={(e) => handleDateRangeChange(e, onDateChange)}
            parseDate={str => new Date(str)}
            value={[selectedPeriod && selectedPeriod.startDate !== null ? new Date(selectedPeriod.startDate) : null, selectedPeriod && selectedPeriod.endDate !== null ? new Date(selectedPeriod.endDate) : null]}
            closeOnSelection={true}
            allowSingleDayRange={true}
            endInputProps={{ small: true, round: true, fill: false, style: { width: "100px", textAlign: "center" } }}
            startInputProps={{ small: true, round: true, fill: false, style: { width: "100px", textAlign: "center" } }}
            maxDate={new Date("2050-12-31")}
            minDate={new Date("2020-01-01")} />
        {showSize ? <div style={{ display: "inline-flex" }}><Divider /><NumericInput
            disabled={disabled}
            fill={true}
            key={keyy + "size"}
            id={keyy + "rfq-size"}
            style={{ alignSelf: "center" }}
            placeholder="100"
            value={size}
            onValueChange={(valueAsNumber: number, valueAsString: string) => onSizeChange(handleSizeValueChange(valueAsNumber, valueAsString))}
            allowNumericCharactersOnly={false}
            buttonPosition={"none"} /></div> : null} */}
    </div>);
};

// function handleDateRangeChange(selectedRange: DateRange, onDateChange: (date: Date, isStartDate: boolean) => void) {
//     if (selectedRange[0] !== null) {
//         onDateChange(selectedRange[0], true);
//     }
//     if (selectedRange[1] !== null) {
//         onDateChange(selectedRange[1], false);
//     }
// }

// function handleSizeValueChange(valueAsNumber: number, valueAsString: string) {
//     let result = valueAsString;
//     result = Common.expandNumberAbbreviationTerms(result);
//     result = Common.nanStringToEmptyString(result);
//     var k = parseFloat(result).valueOf();
//     if (!isNaN(k)) {
//         return k;
//     }
//     else {
//         return NaN;
//     }
// }

export const PeriodStripGenerator: FunctionComponent<PeriodStripGeneratorProps> = ({ onChange, periods, selectedPeriod, onClickGenerate, selectedPeriod2, onChangeOption, priceAsOne, profile, disabled }) => {
    let filteredPeriodsFarLeg = periods.filter(p => selectedPeriod ? p.t > selectedPeriod.t : true);
    return (<div className="PeriodStripGenerator">
        <div className="PeriodStripGeneratorTopSection">
            <Select disabled={disabled} value={selectedPeriod ? selectedPeriod.name : ''} onChange={(e) => onChange(FindOrDefault(periods, p => p.name === e.target.value), false)}>
                {periods.map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
            </Select>
            <Select disabled={disabled} value={selectedPeriod2 ? selectedPeriod2.name : ''} onChange={(e) => onChange(FindOrDefault(filteredPeriodsFarLeg, p => p.name === e.target.value), true)}>
                {filteredPeriodsFarLeg.map(p => <option key={p.name} value={p.name}>{p.name}</option>)}
            </Select>
            <Button disabled={disabled} onClick={onClickGenerate}>Generate</Button>
        </div>
        {/* <div className="PeriodStripGeneratorBottomSection">
            <Checkbox disabled={disabled} inline={true} label="Notional Profile" checked={profile} onChange={() => onChangeOption("Profile", !profile)} />
            <Checkbox disabled={disabled} inline={true} label="Price as One" checked={priceAsOne} onChange={() => onChangeOption("PriceAsOne", !priceAsOne)} />
        </div> */}
    </div>);
};

export const PeriodControl: FunctionComponent<PeriodControlProps> =
    ({ onChangeRows, periods, scheduleRows, isStrip, onDateChange, onClickGenerate, endPeriod, onStartEndChange, startPeriod, onChangeOption, priceAsOne, profile, rfqSize, disabled }) => {
        if (isStrip) {
            return (<div>
                <PeriodStripGenerator
                    disabled={disabled}
                    onChange={(period: Period | undefined, isPeriod2: boolean) => onStartEndChange(period, !isPeriod2)}
                    onClickGenerate={onClickGenerate}
                    periods={periods}
                    selectedPeriod={startPeriod}
                    selectedPeriod2={endPeriod}
                    onChangeOption={onChangeOption}
                    priceAsOne={priceAsOne}
                    profile={profile} />
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "5px" }}>
                    {scheduleRows.map((s, ix) =>
                        <PeriodRowControl
                            disabled={disabled}
                            isCustom={false}
                            endDateCustom={s.period.endDate}
                            startDateCustom={s.period.startDate}
                            onChange={(p: Period | undefined) => onChangeRows(UpdateRows(scheduleRows, p, ix))}
                            onDateChange={(date: Date, isStartDate: boolean) => onDateChange(ix, date, isStartDate)}
                            periods={periods}
                            selectedPeriod={s.period}
                            size={s.quantity}
                            onSizeChange={(newSize: number) => onChangeRows(UpdateRowSize(scheduleRows, newSize, ix, rfqSize))}
                            keyy={`${ix}RowPeriodControl`}
                            key={`${ix}RowPeriodControl`}
                            showSize={profile} />)}
                </div>
            </div>)
        }
        else {
            return (<div style={{ display: "inline-block" }}>
                <PeriodRowControl
                    disabled={disabled}
                    isCustom={false}
                    endDateCustom={scheduleRows[0] ? scheduleRows[0].period.endDate : null}
                    startDateCustom={scheduleRows[0] ? scheduleRows[0].period.startDate : null}
                    onChange={(p: Period | undefined) => onChangeRows(UpdateRows(scheduleRows, p, 0))}
                    onDateChange={(date: Date, isStartDate: boolean) => onDateChange(0, date, isStartDate)}
                    periods={periods}
                    size={scheduleRows[0] ? scheduleRows[0].quantity : 0}
                    onSizeChange={(newSize: number) => onChangeRows(UpdateRowSize(scheduleRows, newSize, 0, rfqSize))}
                    selectedPeriod={scheduleRows[0] ? scheduleRows[0].period : undefined}
                    keyy={"dummyKey"}
                    showSize={false} />
            </div>);
        }
    };

function UpdateRows(scheduleRows: ScheduleRow[], periodToUpdate: Period | undefined, ix: number) {
    if (periodToUpdate) {
        scheduleRows[ix].period = periodToUpdate;
    }
    return scheduleRows;
}

function UpdateRowSize(scheduleRows: ScheduleRow[], size: number, ix: number, rfqSize: number) {
    scheduleRows[ix].quantity = size / rfqSize;
    return scheduleRows;
}

function FindOrDefault<T>(list: T[], comparer: (this: void, input: T) => boolean) {
    const returnValue = list.find(comparer);
    if (returnValue) return returnValue;
    return list[0];
}