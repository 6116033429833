import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import FBEmitter from 'fbemitter';
import React from 'react';
import { FetchInstrumentWithQuery, UpdateListedInstrument } from './listedInstrumentActions';
import { ListedInstrument, ListedInstrumentQuery } from './listedInstrumentModels';
import listedInstrumentStoreInstance from './listedInstrumentStore';
import { getFormTheme } from '../inputs/formCommon';
import { QueryBuilderOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import { ListedInstrumentEditorForm } from './listedInstrumentForm';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface ListedInstrumentAdvSearchState {
    selectedInstruments: ListedInstrument[],
    matchingInstruments: ListedInstrument[],
    checked: number[];
    checkAll: boolean;
    queryRunning: boolean;
    query: ListedInstrumentQuery;
    showQuery: boolean;
}

export const NullType = "Select Type..."
export const InstrumentTypes = ["Index", "CFDFee", "Option", "Equity", "Bond", "CFD", "Cash", "Future", "TokenCross", "Perpetual"];
export const UlInstrumentTypes = ["Index", "Equity", "Bond", "Future", "TokenCross"];
export const InsWithUlTypes = ["CFD", "Option", "Future", "Perpetual"];
export const InsWithMaturity = ["Option", "Future"];

export class ListedInstrumentAdvSearch extends React.Component<{}, ListedInstrumentAdvSearchState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        this.state = {
            selectedInstruments: [],
            matchingInstruments: [],
            checked: [],
            checkAll: false,
            queryRunning: false,
            query: { uniqueId: v4() } as ListedInstrumentQuery,
            showQuery: true
        };;

        this.onInsSelect = this.onInsSelect.bind(this);
        this.handleCheckAllToggle = this.handleCheckAllToggle.bind(this);
        this.queryUpdate = this.queryUpdate.bind(this);
        this.onEnterPressed = this.onEnterPressed.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.queryUpdate);
    }

    async onSubmit(values: ListedInstrument, { setSubmitting, setTouched }: FormikHelpers<ListedInstrument>) {
        console.log(JSON.stringify(values, null, 2));
        values.metaData.forEach(m => {
            listedInstrumentStoreInstance.insertMetaValueByType(m.category, m.type, m.data);
        });
        await UpdateListedInstrument(values);
        setSubmitting(false);
        setTouched({}, false);
    }

    queryUpdate() {
        const { query } = this.state;
        var ins = listedInstrumentStoreInstance.getInstrumentQuery(query.uniqueId);
        if (!ins)
            return;
        this.setState({ matchingInstruments: ins, queryRunning: false, showQuery: false });
    }

    private onInsSelect(ins: ListedInstrument | string) {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            this.setState({ selectedInstruments: [solidIns] });
        }
    }

    private handleToggle = (value: number) => () => {
        // const { checked } = this.state;
        // const currentIndex = checked.indexOf(value);
        // const newChecked = [...checked];

        // if (currentIndex === -1) {
        //     newChecked.push(value);
        // } else {
        //     newChecked.splice(currentIndex, 1);
        // }

        // this.setState({ checked: newChecked });
        const checked  = [value];
        this.setState({ checked });
    };

    private handleCheckAllToggle(checked: boolean) {
        const { matchingInstruments } = this.state;
        var idsChecked = new Array<number>();
        if (checked) { //moving to all checked
            idsChecked = matchingInstruments.map(i => i.listedInstrumentId);
        }
        this.setState({ checkAll: checked, checked: idsChecked });
    }

    async runQuery(query: ListedInstrumentQuery) {
        this.setState({ queryRunning: true });
        await FetchInstrumentWithQuery(query);
    }

    renderInstrumentQuery() {
        const { query, queryRunning } = this.state;
        const showMaturity = InsWithMaturity.includes(query.type);
        const showUlType = InsWithUlTypes.includes(query.type);
        const showOptionFields = query.type === "Option";

        return (
            <div><StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2}>
                        <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect
                                id="type"
                                name="type"
                                label="Type"
                                value={query.type ?? NullType}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.type = d.target.value as string;
                                    if (q.type === NullType)
                                        q.type = null;
                                    this.setState({ query: q });
                                }}>
                                {[NullType, ...InstrumentTypes].map(a =>
                                    <MenuItem key={"insType_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                        {showUlType ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect
                                autoWidth
                                id="ulType"
                                name="ulType"
                                label="UnderlyingType"
                                value={query.underlyingType ?? NullType}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.underlyingType = d.target.value as string;
                                    if (q.underlyingType === NullType)
                                        q.underlyingType = null;
                                    this.setState({ query: q });
                                }}>
                                {[NullType, ...UlInstrumentTypes].map(a =>
                                    <MenuItem key={"insType_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid> : null}
                        {showMaturity ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.maturityFromDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.maturityFromDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                label={"Maturity From"}
                                emptyLabel="(None)" />
                        </Grid> : null}
                        {showMaturity ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.maturityToDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.maturityToDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                label={"Maturity To"}
                                emptyLabel="(None)" />
                        </Grid> : null}

                        {showOptionFields ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect
                                id="callPut"
                                name="callPut"
                                label="Call/Put"
                                value={query.callPut ?? NullType}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.callPut = d.target.value as string;
                                    if (q.callPut === NullType)
                                        q.callPut = null;
                                    this.setState({ query: q });
                                }}>
                                {[NullType, "Call", "Put"].map(a =>
                                    <MenuItem key={"insCp_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid> : null}

                        {showOptionFields ? <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Strike"}
                                placeholder="Strike"
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.strike = parseFloat(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid> : null}

                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Description"}
                                placeholder="Description"
                                onKeyDown={this.onEnterPressed}
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.description = e.target.value;
                                    this.setState({ query: q });
                                }} />
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Instrument Id"}
                                placeholder="Instrument Id"
                                onKeyDown={this.onEnterPressed}
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.listedInstrumentId = parseInt(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid>
                        {showUlType || query.type == null ? <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Underlying Id"}
                                placeholder="Underlying Id"
                                onKeyDown={this.onEnterPressed}
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.underlyingId = parseInt(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid> : null}

                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Exchange"}
                                placeholder="Exchange"
                                onKeyDown={this.onEnterPressed}
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.exchange = e.target.value;
                                    this.setState({ query: q });
                                }} />
                        </Grid>

                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.query)} disabled={queryRunning}>Run Query</Button>
                        </Grid>
                        {queryRunning ?
                            <Grid item className="ListedInstrumentQueryItem">
                                <CircularProgress />
                            </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider></div>
        );
    }

    onEnterPressed(e) {
        if (e.keyCode === 13)
            this.runQuery(this.state.query);

    }

    render() {
        const { matchingInstruments, checked, showQuery } = this.state;
        const selectedInstruments = matchingInstruments.filter(i => checked.includes(i.listedInstrumentId));

        return (
            <div className="ListedInstrumentAdvSearchTab">
                <div className="ListedInstrumentAdvSearchTabSearch">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <Accordion expanded={showQuery} onChange={() => this.setState({ showQuery: !showQuery })} className="ListedInstrumentAdvSearchTabSearchTop">
                                <AccordionSummary>Search Query</AccordionSummary>
                                <AccordionDetails>{this.renderInstrumentQuery()}</AccordionDetails>
                            </Accordion>
                            <div className="ListedInstrumentAdvSearchTabSearchBottomSection">
                                <div className="ListedInstrumentAdvSearchTabSearchResults">
                                    <List key="ListedInstrumentAdvSearchTabSearch">
                                        {/* {matchingInstruments && matchingInstruments.length > 1 ?
                                            <ListItem key={"selectAll"} divider role={undefined} dense button onClick={(e) => this.handleCheckAllToggle(!checkAll)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checkAll}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        style={{ color: userStoreInstance.GetTheme().border_color }}
                                                        inputProps={{ 'aria-labelledby': "Check all" }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={"checkAll"} primary={"Select All"} />
                                            </ListItem> : null} */}
                                        {matchingInstruments.slice(0, 50).map(s => {
                                            const labelId = `checkbox-list-label-${s.description}`;
                                            return (
                                                <ListItem key={s.listedInstrumentId} role={undefined} selected={checked.indexOf(s.listedInstrumentId) !== -1} dense button onClick={this.handleToggle(s.listedInstrumentId)}>
                                                    {/* <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(s.listedInstrumentId) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            style={{ color: userStoreInstance.GetTheme().border_color }}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon> */}
                                                    <ListItemText id={labelId} primary={s.description} />
                                                </ListItem>);
                                        })}
                                    </List>
                                </div>
                                <div className="ListedInstrumentAdvSearchTabResultForm">
                                    {selectedInstruments && selectedInstruments.length > 0 ?
                                        <Paper elevation={1} >
                                            <Formik
                                                onSubmit={this.onSubmit}
                                                enableReinitialize={true}
                                                initialValues={selectedInstruments[0]}
                                                validate={() => ({})}>
                                                {props => <ListedInstrumentEditorForm className="ListedInstrumentAdvSearchTabResultFormInner" {...props} />}
                                            </Formik>
                                        </Paper> : null}
                                </div>
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            </div>
        );
    }
}
