    /// <summary>
    /// BestOf - most positive performance
    /// </summary>
    export enum BasketType
    {
        SingleAsset,
        EquallyWeightedBasket,
        WeightedBasket,
        BestOf,
        WorstOf,
        NthBestOf,
        NthWorstOf
    }