import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { ReportStoreState } from './reportModels';
import { DeleteReportEvent, LoadDataSetsEvent, LoadReportsEvent, UpdateReportEvent } from './reportActions';

class ReportStore extends FluxStore<ReportStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            reports: [],
            dataSources: [],
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadReportsEvent) {
            const { payload } = action;
            this.state.reports = payload;
            this.emitChange();
        }
        else if (action instanceof LoadDataSetsEvent) {
            const { payload } = action;
            this.state.dataSources = payload;
            this.emitChange();
        }
        else if (action instanceof UpdateReportEvent) {
            const { payload } = action;
            const item = this.state.reports.findIndex(e => e.reportId === payload.reportId);

            if (item === -1) {
                this.state.reports.push(payload);
            }
            else {
                this.state.reports[item] = payload;
            }
            console.log("Update Report - " + payload.name)
            this.emitChange();
        }
        else if (action instanceof DeleteReportEvent) {
            const { payload } = action;
            this.state.reports = this.state.reports.filter(e => e.reportId !== payload.reportId);
            this.emitChange();
        }
    }

    getReports() {
        return this.state.reports;
    }

    getDataSets() {
        return this.state.dataSources;
    }
}


const reportStoreInstance = new ReportStore(AppDispatcher);
export default reportStoreInstance;