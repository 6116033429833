import { get } from '../utils/httpUtils';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { Rfq } from './rfq';
import { Price, RfqStateChangeRecord, RfqSummary } from './rfqModels';
import rfqConnection from './rfqConnection';

export class RfqSummaryUpdatedEvent extends TypedEvent<RfqSummary> { }
export class RfqSummariesUpdatedEvent extends TypedEvent<RfqSummary[]> { }
export class RfqAuditUpdatedEvent extends TypedEvent<RfqStateChangeRecord> { }
export class NewRfqEvent extends TypedEvent<Rfq> { }

export function RfqSummaryUpdated(summary: RfqSummary) {
    AppDispatcher.dispatch(new RfqSummaryUpdatedEvent(summary));
}

export function NewRfqRecieved(rfq: Rfq) {
    AppDispatcher.dispatch(new NewRfqEvent(rfq));
}

export function RfqAuditEvent(event: RfqStateChangeRecord) {
    AppDispatcher.dispatch(new RfqAuditUpdatedEvent(event));
}

export async function RefreshRfqSummary(rfqId: number) {
    const summary = await get<RfqSummary>(`Rfq/refreshSummary/${rfqId}`);
    if (summary.payload != null) {
        AppDispatcher.dispatch(new RfqSummaryUpdatedEvent(summary.payload));
    }
}

export async function RefreshRfqSummaries() {
    const summary = await get<RfqSummary[]>("Rfq/activeSummaries");
    if (summary.payload != null) {
        // summary.payload.forEach(element => {
        //     AppDispatcher.dispatch(new RfqSummaryUpdatedEvent(element));
        // });
        AppDispatcher.dispatch(new RfqSummariesUpdatedEvent(summary.payload));
    }
}

export async function AuditSubscribe(rfqId: number) {
    rfqConnection.subscribeToRfqAudit(rfqId);
}
export async function AuditUnSubscribe(rfqId: number) {
    rfqConnection.unSubscribeFromRfqAudit(rfqId);
}

export async function FetchAuditLog(rfqId: number) {
    const summary = await get<RfqStateChangeRecord[]>(`Rfq/audit/fetch/${rfqId}`);
    if (summary.payload != null) {
        summary.payload.forEach(record => {
            AppDispatcher.dispatch(new RfqAuditUpdatedEvent(record));
        });
    }
}

export class QuotesUpdatedEvent extends TypedEvent<Price> { }

export function QuotesUpdated(newQuotes: Price) {
    AppDispatcher.dispatch(new QuotesUpdatedEvent(newQuotes));
}

