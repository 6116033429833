import FluxStore from '../../FluxStore';
import { Event, AppDispatcher } from '../../dispatcher/appDispatcher';
import { MatronFatFingerControlType, MatronFatFingerLimitType, MatronSettingsStoreState, MatronTradingDefaults } from './matronSettingsModels';
import { NewFatFingerControlsEvent, NewTradingDefaultsEvent } from './matronSettingsActions';

class MatronSettingsStore extends FluxStore<MatronSettingsStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            fatFingerSettings: [],
            tradingDefaults: {} as MatronTradingDefaults
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewFatFingerControlsEvent) {
            const { payload } = action;
            this.state.fatFingerSettings = payload;
            this.emitChange();
        }
        else if (action instanceof NewTradingDefaultsEvent) {
            const { payload } = action;
            this.state.tradingDefaults = payload;
            this.emitChange();
        }
    }

    getFatFingerControls() {
        return this.state.fatFingerSettings;
    }

    getTradingDefaults() {
        return this.state.tradingDefaults;
    }

    areControlsInPlace(insId: number) {
        return this.state.fatFingerSettings.some(f => f.instrumentId === insId);
    }

    wouldTradePass(insId: number, contracts: number, nominal: number) {
        var controls = this.state.fatFingerSettings.filter(f => f.instrumentId === insId);
        var pass = true;
        controls.forEach(control => {
            if (control.controlType === MatronFatFingerControlType.PerOrder) {
                if (control.limitType === MatronFatFingerLimitType.Contracts)
                    pass = pass && Math.abs(contracts) < control.limit;
                else if (control.limitType === MatronFatFingerLimitType.Notional)
                    pass = pass && Math.abs(nominal) < control.limit;
            }
        });
        return pass;
    }
}

const matronSettingsStoreInstance = new MatronSettingsStore(AppDispatcher);
export default matronSettingsStoreInstance;