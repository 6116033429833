import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, CircularProgress, CssBaseline, Grid, GridProps, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { ComputeVaR } from './riskActions';
import { VaRResult } from './riskModels';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { assetSort, AssetStr, AttributionStr, BetaStr, EarliestDate, InstrumentStr, PltfmMc, SubAttributionStr, VaRProviders } from '../globalConstants';
import { TabContext, TabPanel } from '@mui/lab';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { colCommon, getIns, getMetaFromInstrument, getUltimateParentIdFromIns } from '../positions/positionsCommon';
import _ from 'lodash';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import userStoreInstance from '../user/userStore';
import moment from 'moment';
import { renderDateCell } from '../utils/helpers';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { v4 } from 'uuid';
import taskUpdateStoreInstance from '../userSession/taskUpdateStore';
import { SubscribeToJobUpdates, UnSubscribeFromJobUpdates } from '../userSession/userSessionActions';

interface VaRViewState {
    varrr: VaRResult;
    date: Date;
    waiting: boolean;
    ci: string;
    windowSize: string;
    selectedTabId: number;
    method: string;
    jobUpdate: string;
    jobUpdateKey: string;
    calcType: string;
}

export interface VaRViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class VaRView extends React.Component<VaRViewProps, VaRViewState>{
    eventSubscriptionJobUpdates: FBEmitter.EventSubscription | undefined;
    constructor(props: VaRViewProps) {
        super(props)
        this.state = {
            varrr: undefined,
            waiting: false,
            ci: "0.95",
            date: new Date(),
            windowSize: "2y",
            selectedTabId: 0,
            method: "Everysk",
            jobUpdate: undefined,
            jobUpdateKey: v4(),
            calcType: "VaR"
        };;
        this.calcVaR = this.calcVaR.bind(this);
        this.onJobUpdate = this.onJobUpdate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionJobUpdates = taskUpdateStoreInstance.addChangeListener(this.onJobUpdate);
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionJobUpdates?.remove)
            this.eventSubscriptionJobUpdates.remove();
    }

    async onJobUpdate() {
        var latest = taskUpdateStoreInstance.GetLatestStatus(this.state.jobUpdateKey);
        if (latest?.update !== this.state.jobUpdate) {
            if (latest.isFinished)
                this.setState({ jobUpdate: undefined });
            else {
                this.setState({ jobUpdate: latest?.update });
                await UnSubscribeFromJobUpdates(this.state.jobUpdateKey);
            }
        }

    }

    async calcVaR() {
        this.setState({ varrr: undefined, waiting: true });
        await SubscribeToJobUpdates(this.state.jobUpdateKey);
        const varrr = await ComputeVaR(this.state.date, Number(this.state.ci), this.state.windowSize, this.state.method, this.state.jobUpdateKey, this.state.calcType==="VaR+Stress");
        this.setState({ varrr, waiting: false });
    }

    customToolbar(props: any) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                            <Grid item container spacing={1} justifyContent="flex-end" style={{ width: "50%" }}>
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }


    renderTimings(timings: { [step: string]: number }) {
        const rowProps = { align: 'center', style: { borderBottomColor: userStoreInstance.GetTheme().contrastBorderColorLight } } as Partial<TableCellProps>;
        return timings && <CssBaseline>
            <ThemeProvider theme={getFormTheme()}>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' variant='head' style={{ fontWeight: "bold" }}>Step</TableCell>
                                <TableCell align='center' variant='head' style={{ fontWeight: "bold" }}>Time (s)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(timings).map(s => {
                                return <TableRow key={s}>
                                    <TableCell {...rowProps}>{s}</TableCell>
                                    <TableCell {...rowProps}>{Number(timings[s]).toFixed(3)}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </CssBaseline>
    }

    renderStresses(stresses: { [key: string]: number }) {
        if(!stresses)
            return <div/>;

        const rowProps = { align: 'center', style: { borderBottomColor: userStoreInstance.GetTheme().contrastBorderColorLight } } as Partial<TableCellProps>;
        var magnitudes = _.uniq(Object.keys(stresses).map(k => k.split(' ')[1]));
        var indices = _.uniq(Object.keys(stresses).map(k => k.split(' ')[0]));
        //console.log(Object.keys(stresses));
        //console.log(indices);
        return <CssBaseline>
            <ThemeProvider theme={getFormTheme()}>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' variant='head' style={{ fontWeight: "bold" }}>Index</TableCell>
                                {magnitudes.map(m =>
                                    <TableCell align='center' variant='head' style={{ fontWeight: "bold" }}>{m}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {indices.map(s => {
                                return <TableRow key={s}>
                                    <TableCell {...rowProps}>{s}</TableCell>
                                    {magnitudes.map(m => {
                                        var key = `${s} ${m}`;
                                        return <TableCell {...rowProps} key={key}>{Number(stresses[key])?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>
                                    }
                                    )}
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        </CssBaseline>
    }

    renderControls() {
        const { varrr, waiting, ci, date, windowSize, method, jobUpdate, calcType } = this.state;
        const gridItemProps = { item: true, sm: 6, xl: 6, alignContent: "center", justifyContent: "center" }; //height:"calc(40vh - 100px)"

        const chartData = varrr?.vaRChartPoints && [
            {
                name: "VaR",
                x: varrr?.vaRChartPoints?.map(d => d.ci),
                y: varrr?.vaRChartPoints?.map(d => d.vaR),
                type: 'scattergl',
                mode: 'lines',
            },
            {
                name: "VaR-95%",
                x: [0.95],
                y: [varrr?.vaR],
                type: 'scattergl',
                mode: 'markers',
                marker: {
                    color: "red",
                    size: 10,
                    symbol: "circle"
                }
            },
        ] as Data[];

        var chartLayout: Partial<Layout> = {
            title: {
                text: "VaR by Confidence Interval",
                font: { color: userStoreInstance.GetTheme().color, family: userStoreInstance.GetTheme().font_family },
                yanchor: "top",
                yref: "paper",
                y: 0.95,

            },
            showlegend: false,
            dragmode: false,
            plot_bgcolor: userStoreInstance.GetTheme().background_color,
            paper_bgcolor: userStoreInstance.GetTheme().background_color,
            xaxis: {

                range: [0, 1],
                rangeslider: {
                    visible: false
                },
                tickformat: "~%",
                linecolor: userStoreInstance.GetTheme().contrastBorderColorLight,
                tickfont: { color: userStoreInstance.GetTheme().color }
            },
            yaxis: {
                linecolor: userStoreInstance.GetTheme().contrastBorderColorLight,
                tickfont: { color: userStoreInstance.GetTheme().color }
            },
            margin: {
                pad: 1,
                l: 40,
                r: 20,
                t: 5,
                b: 30,
            },
        };

        var showStresses = varrr?.stresses !== undefined;
        var showTimings = !showStresses && varrr?.timings !== undefined;

        const boxProps = { border: "1px solid", borderColor: userStoreInstance.GetTheme().contrastBorderColorLight, borderRadius: "7px" } as Partial<GridProps>;
        return (<ThemeProvider theme={getFormTheme()}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-start", width: "calc(100% - 10px)", height: "calc(100% - 10px)" }}>
                <Grid container spacing={2} justifyContent="flex-start" alignContent="flex-start" direction="column" columns={{ xs: 1, sm: 1, md: 1, l: 1, xl: 1 }} >
                    <Grid container item spacing={2} justifyContent="flex-start" alignContent="center" direction="row">
                        <Grid item><TextField size="small" style={{ width: "150px" }} value={ci} label="Confidence Interval" onChange={(e) => this.setState({ ci: e.target.value })} /></Grid>
                        {method === "Pltfm" && <Grid item><TextField size="small" style={{ width: "150px" }} value={windowSize} label="History Length" onChange={(e) => this.setState({ windowSize: e.target.value })} /></Grid>}
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={date}
                                onChange={(d) => this.setState({ date: d.toDate() })}
                                disableFuture
                                minDate={EarliestDate}
                                emptyLabel="Live"
                                clearLabel="Live"
                                label={"As-Of"} />
                        </Grid>
                        <Grid item>
                            <WrappedSelect
                                key="varMethodSelect"
                                label="Provider"
                                name="provider"
                                value={method}
                                onChange={(e) => this.setState({ method: e.target.value })}>
                                {VaRProviders.map(v => <MenuItem key={"prov_" + v} value={v}>{v}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                        <Grid item>
                            <WrappedSelect
                                key="calcTypeSelect"
                                label="Calculate"
                                name="Calculate"
                                value={calcType}
                                onChange={(e) => this.setState({ calcType: e.target.value })}>
                                <MenuItem key="typVaR" value="VaR">VaR</MenuItem>
                                <MenuItem key="typVaRStress" value="VaR+Stress">VaR + Stress</MenuItem>
                            </WrappedSelect>
                        </Grid>
                        <Grid item><Button variant="outlined" className="PltfmButtonLite" onClick={this.calcVaR} disabled={waiting}>Calculate</Button></Grid>
                    </Grid>
                    {waiting ? <Grid container item spacing={2} justifyContent="center" >
                        <Grid item><CircularProgress /></Grid>
                        <Grid item><Typography align='center' variant='h6'>{jobUpdate}</Typography></Grid>
                    </Grid> :
                        varrr ? <Grid container item spacing={2} height="calc(100vh - 250px)">
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>USD VaR @ {varrr.confidenceInterval * 100}%</Typography></Grid>
                                <Grid item><Typography variant='h4'>{varrr?.vaR.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></Grid>
                            </Grid>
                            {showTimings && <Grid container item direction="column" {...gridItemProps} >
                                <Grid item><Typography align='center' variant='h6'>Timing Data</Typography></Grid>
                                <Grid item {...gridItemProps}>{this.renderTimings(varrr.timings)}</Grid>
                            </Grid>}
                            {showStresses && <Grid container item direction="column" {...gridItemProps} >
                                <Grid item><Typography align='center' variant='h6'>Stresses</Typography></Grid>
                                <Grid item {...gridItemProps}>{this.renderStresses(varrr.stresses)}</Grid>
                            </Grid>}
                            <Grid container item direction="column" {...gridItemProps} >
                                <Grid item><Typography align='center' variant='h6'>{varrr?.basePortfolioDate ? "Portfolio Date" : "Scenario Date"}</Typography></Grid>
                                <Grid item><Typography variant='h4'>{moment(varrr?.basePortfolioDate ?? varrr?.vaRSecnarioDate).format(userStoreInstance.GetDateFormat())}</Typography></Grid>
                            </Grid>
                            {chartData && <Grid container item direction="column" {...gridItemProps} >
                                <Grid item {...boxProps} >
                                    <Plot
                                        data={chartData}
                                        layout={chartLayout}
                                        config={{ responsive: true, displaylogo: false, autosizable: false, fillFrame: false, showAxisDragHandles: false, displayModeBar: false }}
                                        style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                        useResizeHandler={true}
                                    />
                                </Grid>
                            </Grid>}
                        </Grid> : null}
                </Grid>
            </div>
        </ThemeProvider>)
    }

    renderCcyCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
        }
        else
            return <div></div>;
    }

    renderBreakdown() {
        const { varrr } = this.state;

        var columns: GridColDef[] = [
            { field: 'asset', align: "center", headerAlign: "center", width: 100, headerName: 'Asset', cellClassName: (params: GridCellParams) => params.value ? "PositionSummaryTabTableCell--" + params.value : "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", },
            { field: 'attribution', align: "center", headerAlign: "center", width: 250, headerName: 'Attribution', type: "number", ...colCommon },
            { field: 'var', width: 250, headerName: 'Marginal VaR (USD)', type: "number", renderCell: this.renderCcyCell, ...colCommon },
            { field: 'netVar', width: 250, headerName: 'Fractional VaR (USD)', type: "number", renderCell: this.renderCcyCell, ...colCommon },
        ];

        var rows = !varrr?.differentials ? new Array<any>() : Object.keys(varrr.differentials).map((key, ix) => {
            var asset = key.split("¬")[0];
            var attrib = key.split("¬")[1];
            return {
                id: ix,
                asset: asset,
                attribution: attrib,
                var: varrr.differentials[key]?.vaR,
                netVar: varrr.contributions[key],
            }
        });

        rows.sort((a, b) => (a.attribution as string ?? "Z").localeCompare(b.attribution as string ?? "Z"))
            .sort((a, b) => assetSort.indexOf(a.asset) - assetSort.indexOf(b.asset));

        rows.push({
            id: rows.length,
            attribution: "Total",
            var: _.sum(rows.map(r => r.var)),
            netVar: _.sum(rows.map(r => r.netVar))
        })

        return <ThemeProvider theme={getFormTheme()}>
            <DataGridPro
                key='varDifferentialGrid'
                className="PositionSummaryTabTable"
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: this.customToolbar,
                }}
                hideFooter />
        </ThemeProvider>
    }

    renderInsBreakdown() {
        const { varrr, method } = this.state;

        var columns: GridColDef[] = [
            { field: 'asset', align: "center", headerAlign: "center", width: 100, headerName: 'Asset', cellClassName: (params: GridCellParams) => params.value ? "PositionSummaryTabTableCell--" + params.value : "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", },
            { field: 'attribution', align: "center", headerAlign: "center", width: 200, headerName: 'Attribution', type: "number", ...colCommon },
            { field: 'ins', align: "center", headerAlign: "center", width: 350, headerName: 'Instrument', type: "number", ...colCommon },
            { field: 'position', align: "center", headerAlign: "center", width: 200, headerName: 'Position', type: "number", ...colCommon },
            { field: 'baseVal', align: "center", headerAlign: "center", width: 150, headerName: 'Base Value', type: "number", renderCell: this.renderCcyCell, ...colCommon },
            { field: 'var', width: 150, headerName: 'Marginal VaR (USD)', type: "number", renderCell: this.renderCcyCell, ...colCommon },
            { field: 'netVar', width: 150, headerName: 'Fractional VaR (USD)', type: "number", renderCell: this.renderCcyCell, ...colCommon },
            { field: 'grossVar', width: 150, headerName: 'Individual VaR (USD)', type: "number", renderCell: this.renderCcyCell, ...colCommon },

        ];

        if (method !== PltfmMc)
            columns.push({ field: 'grossVarDate', width: 150, headerName: 'Individual VaR (Date)', type: "dateTime", renderCell: renderDateCell, ...colCommon });

        var rows = !varrr?.differentialsByUnderlying ? new Array<any>() : Object.keys(varrr.differentialsByUnderlying).map((key, ix) => {
            var ins = listedInstrumentStoreInstance.getInstrumentById(varrr.nameToIdMap[key]);
            return {
                id: ix,
                asset: getMetaFromInstrument(ins, AssetStr),
                attribution: getMetaFromInstrument(ins, AttributionStr),
                ins: key,
                var: varrr.differentialsByUnderlying[key]?.vaR,
                position: varrr.positions[key],
                //netVar: varrr.differentialsByUnderlying[key]?.vaR / grossMarginalVaR * varrr.vaR,
                netVar: varrr.contributionByUnderlying[key],
                grossVar: varrr.grossByUnderlying[key]?.vaR,
                grossVarDate: method !== PltfmMc && varrr.grossByUnderlying[key]?.vaRSecnarioDate,
                baseVal: varrr.baseValuationByUnderlying[key],
                maturity: ins?.maturity,
                underlying: getUltimateParentIdFromIns(ins)
            }
        });

        rows.sort((a, b) => a.maturity > b.maturity ? 1.0 : -1.0)
            .sort((a, b) => a.underlying > b.underlying ? 1.0 : -1.0)
            .sort((a, b) => (a.attribution as string ?? "Z").localeCompare(b.attribution as string ?? "Z"))
            .sort((a, b) => assetSort.indexOf(a.asset) - assetSort.indexOf(b.asset));

        rows.push({
            id: rows.length,
            ins: "Total",
            var: _.sum(rows.map(r => r.var)),
            netVar: _.sum(rows.map(r => r.netVar)),
            grossVar: _.sum(rows.map(r => r.grossVar))
        })

        return <ThemeProvider theme={getFormTheme()}>
            <DataGridPro
                key='varDifferentialGrid'
                className="PositionSummaryTabTable"
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: this.customToolbar,
                }}
                hideFooter />
        </ThemeProvider>
    }

    renderDetail() {
        const { varrr, method } = this.state;

        var columns: GridColDef[] = [
            { field: 'asset', width: 50, headerName: 'Asset', cellClassName: (params: GridCellParams) => params.value ? "PositionSummaryTabTableCell--" + params.value : "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", },
            { field: 'instrumentId', hide: true, width: 150, headerName: 'InsId', type: "number", ...colCommon },
            { field: 'instrument', width: 300, headerName: 'Description', ...colCommon },
            { field: 'nPoints', width: 100, headerName: '#Points', type: "number", ...colCommon },
        ];

        if (method === PltfmMc)
            columns.push({ field: 'vol', width: 100, headerName: 'Vol', type: "number", ...colCommon });

        var rows = !varrr?.numPointsByUnderlying ? new Array<any>() : Object.keys(varrr.numPointsByUnderlying).map((insId, ix) => {
            var ins = getIns(Number(insId));
            var vol = varrr.volByUnderlying ? (varrr.volByUnderlying[insId] * 100) : undefined;
            return {
                id: ix,
                asset: getMetaFromInstrument(ins, AssetStr),
                attribution: getMetaFromInstrument(ins, AttributionStr),
                subAttribution: getMetaFromInstrument(ins, SubAttributionStr),
                beta: getMetaFromInstrument(ins, BetaStr),
                insMetaStr: getMetaFromInstrument(ins, InstrumentStr),
                instrument: ins?.description?.replaceAll(ins?.type, ""),
                instrumentId: insId,
                type: ins?.type,
                nPoints: varrr.numPointsByUnderlying[insId],
                vol: vol ? (vol.toFixed(1) + "%") : undefined
            }
        });

        rows.sort((a, b) => (a.attribution as string ?? "Z").localeCompare(b.attribution as string ?? "Z"))
            .sort((a, b) => assetSort.indexOf(a.asset) - assetSort.indexOf(b.asset));

        return <ThemeProvider theme={getFormTheme()}>
            <DataGridPro
                className="PositionSummaryTabTable"
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: this.customToolbar,
                }}
                hideFooter />
        </ThemeProvider>
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    render() {
        const { selectedTabId, method } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistory"}
                        component={Paper}
                        id={"tab0"}
                        label="Calculation" />
                    {method.includes("Pltfm") && <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabUpdate"}
                        component={Paper}
                        id={"tab1"}
                        label="Asset/Attrib" />}
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"2"}
                        key={"tabIns"}
                        component={Paper}
                        id={"tab2"}
                        label="Instrument" />
                    {method.includes("Pltfm") && <Tab
                        classes={{ root: "tabTitle" }}
                        value={"4"}
                        key={"tabData"}
                        component={Paper}
                        id={"tab4"}
                        label="Data" />}
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabPQuery1"}
                    value={"0"}
                    //index={0}
                    children={this.renderControls()} />
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabPQuery2"}
                    value={"1"}
                    //index={1}
                    children={this.renderBreakdown()} />
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabPQuery3"}
                    value={"2"}
                    //index={1}
                    children={this.renderInsBreakdown()} />
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabPQuery4"}
                    value={"4"}
                    //index={1}
                    children={this.renderDetail()} />
            </TabContext>
        </ThemeProvider>
        );
    }
}
