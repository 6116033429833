import { MenuItem, SelectChangeEvent } from "@mui/material";
import React from "react";
import marketDataStoreInstance from "../marketData/marketDataStore";
import { WrappedSelect } from "./wrappedSelect";

export interface MarketDataSetSelectorProps {
    onChange?: (set: string) => void,
    value: any,
    style?: React.CSSProperties,
    label?: React.ReactNode
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    autoWidth?: boolean
}

const SelectSet = "Select Set...";

export class MarketDataSetSelector extends React.Component<MarketDataSetSelectorProps, {}>
{
    constructor(props: MarketDataSetSelectorProps) {
        super(props)
        this.onChangeLocal = this.onChangeLocal.bind(this);
    }

    onChangeLocal(event: SelectChangeEvent<any>, child: React.ReactNode) {
        var str = event.target.value as string;
        this.props.onChange(str === SelectSet ? null : str);
    }
    render() {
        return (<WrappedSelect
            style={this.props.style}
            id={this.props.id}
            name={this.props.name}
            label={this.props.label}
            value={this.props.value ?? SelectSet}
            onChange={this.onChangeLocal}>
            {[SelectSet, ...marketDataStoreInstance.getSetNames()].map(a =>
                <MenuItem key={"set_" + a} value={a}>{a}</MenuItem>)}
        </WrappedSelect>)
    }
}

