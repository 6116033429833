import React, { Component, RefObject } from 'react';
import FBEmitter from 'fbemitter';
import moment from 'moment-timezone';
import { get } from '../../utils/httpUtils';
import userStoreInstance from '../../user/userStore';


const rowHeight = 30;

interface LmeDatesResponse {
  datesTitles: string[];
  dates: string[];
}

export interface LmeDatesWidgetProps {
  onChangeHeight?: (newHeight: number) => void
}

interface LmeDatesWidgetState {
  currentDate: string;
  datesTitles: string[];
  dates: string[];
  mainRef: RefObject<HTMLDivElement>;
  height: number;
}


class LmeDatesWidget extends Component<LmeDatesWidgetProps, LmeDatesWidgetState>{
  eventSubscriptionUser: FBEmitter.EventSubscription | undefined;
  constructor(props: LmeDatesWidgetProps) {
    super(props);
    var dateStr = moment().format("YYYY-MM-DD");
    this.state = {
      currentDate: dateStr,
      datesTitles: [],
      dates: [],
      height: 0,
      mainRef: React.createRef()
    };
    this.fetchDates = this.fetchDates.bind(this);
  }

  async componentDidMount() {
    this.eventSubscriptionUser = userStoreInstance.addChangeListener(() => this.fetchDates());
    await this.fetchDates();
  }

  componentWillUnmount() {
      if(this.eventSubscriptionUser){
        this.eventSubscriptionUser.remove();
      }
  }

  async fetchDates() {
    var storageKeyDates = `LME-Dates-${moment().startOf('day').format("yyyy-MM-DD")}`;
    var storageKeyTitles = `LME-Dates-Titles-${moment().startOf('day').format("yyyy-MM-DD")}`;
    var storedDates = sessionStorage.getItem(storageKeyDates);
    var storedTitles = sessionStorage.getItem(storageKeyTitles);

    if(!storedDates){
      let response = await get<LmeDatesResponse>('Static/getLmeDates');
      if (response.payload) {
        storedDates = response.payload.dates.join(',');
        sessionStorage.setItem(storageKeyDates,storedDates);
        storedTitles = response.payload.datesTitles.join(',');
        sessionStorage.setItem(storageKeyTitles,storedTitles);
      }
    }

    if(storedDates && storedTitles){
      var dates = storedDates.split(',').map(d=>moment(d).format(userStoreInstance.GetDateFormat()))
      var titles = storedTitles.split(',');
      this.setState({ datesTitles: titles, dates });
    }
  }


  private renderDatesTable = () => {
    const { dates, datesTitles } = this.state;

    if (dates.length > 0)
      return dates.slice(0,8).map((i, ix) => this.mapDatesTableRow(i, datesTitles[ix]));
    else
      return <tr><td className="HolidayPanelBody" colSpan={2} style={{ height: `${rowHeight}px` }}>(...Loading...)</td></tr>
  }

  private mapDatesTableRow = (date: string, dateTitle: string) => {
    var borderStr = dateTitle === "3m" ? "1px solid" : "none";
    return (<tr key={`lmeDatz-${date}`}>
      <td style={{ fontSize:"0.95em", width: "50%", textAlign: "center", borderTop: borderStr, borderBottom: borderStr, borderColor: userStoreInstance.GetTheme().contrastBorderColorLight }}>{dateTitle}</td >
      <td style={{ fontSize:"0.95em", width: "50%", overflow: "none", textAlign: "center", borderTop: borderStr, borderBottom: borderStr, borderColor: userStoreInstance.GetTheme().contrastBorderColorLight }}>{date}</td>
    </tr>);
  }

  render() {
    return (
      <div ref={this.state.mainRef} className="HolidayPanel">
        <table style={{ margin: "auto", width: "99%", borderCollapse: "collapse", marginTop:"20px" }}>
          <thead>
          </thead>
          <tbody>
            {this.renderDatesTable()}
          </tbody>
        </table>
      </div>
    );
  }
}
export default LmeDatesWidget;