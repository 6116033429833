import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { FormSection } from '../inputs/formSection';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { CheckCircleOutline, UndoOutlined } from '@mui/icons-material';
import { UpdatePreference } from '../layout/layoutActions';
import userStoreInstance from '../user/userStore';
import { GridDensity } from '@mui/x-data-grid-pro';

interface UserSettingsState {
    theme: string;
    dateFormat: string;
    gridDensity: GridDensity;
}

export interface UserSettingsProps {

}

export class UserSettings extends React.Component<UserSettingsProps, UserSettingsState>{
    eventSubscriptionUserSettings: FBEmitter.EventSubscription | undefined;
    constructor(props: UserSettingsProps) {
        super(props)
        this.state = {
            theme: userStoreInstance.GetThemeName(),
            dateFormat: userStoreInstance.GetDateFormat(),
            gridDensity: userStoreInstance.GetGridDensity()
        };

        this.onChangeConfig = this.onChangeConfig.bind(this);
        this.onClickUpdate = this.onClickUpdate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionUserSettings = userStoreInstance.addChangeListener(this.onChangeConfig)
    }

    async componentWillUnmount() {
    }

    async onChangeConfig() {
        var theme = userStoreInstance.GetThemeName();
        var dateFormat = userStoreInstance.GetDateFormat();
        var gridDensity = userStoreInstance.GetGridDensity();
        this.setState({ theme, dateFormat, gridDensity });
    }

    async onClickUpdate() {
        const { theme, dateFormat, gridDensity } = this.state;
        if (theme !== userStoreInstance.GetThemeName()) {
            userStoreInstance.SetTheme(theme);
            UpdatePreference("Theme", theme);
        }

        if (dateFormat !== userStoreInstance.GetDateFormat()) {
            userStoreInstance.SetDateFormat(dateFormat);
            UpdatePreference("DateFormat", dateFormat);
        }

        if (gridDensity !== userStoreInstance.GetGridDensity()) {
            userStoreInstance.SetGridDensity(gridDensity);
            UpdatePreference("GridDensity", gridDensity);
        }
    }

    anyChanges() {
        const { theme, dateFormat, gridDensity } = this.state;
        return (theme !== userStoreInstance.GetThemeName()) || (dateFormat !== userStoreInstance.GetDateFormat()) || (gridDensity !== userStoreInstance.GetGridDensity())
    }

    render() {
        const { theme, dateFormat, gridDensity } = this.state;
        const titleStyle = { width: "175px", textAlign: "right", paddingRight: "1em" } as React.CSSProperties;
        var anyChanges = this.anyChanges()
        return (
            <ThemeProvider theme={getFormTheme()}>
                <Grid container spacing={2} justifyContent="center" alignContent="space-around" width={"95%"} height={"100%"} >
                    <FormSection label={<Typography variant="h6" style={titleStyle}>UI Preferences</Typography>}>
                        <Grid item>
                            <WrappedSelect
                                name="Theme"
                                label="Theme"
                                value={theme}
                                onChange={(v)=>this.setState({theme:v.target.value})}>
                                {userStoreInstance.Themes.map(o => <MenuItem key={o} value={o}>{o}</MenuItem >)}
                            </WrappedSelect>
                        </Grid>
                        <Grid item>
                            <WrappedSelect
                                name="Date Format"
                                label="Date Format"
                                value={dateFormat}
                                onChange={(v)=>this.setState({dateFormat:v.target.value})}>
                                {userStoreInstance.DateFormats.map(o => <MenuItem key={o} value={o}>{o}</MenuItem >)}
                            </WrappedSelect>
                        </Grid>
                        <Grid item>
                            <WrappedSelect
                                style={{ width: "150px" }}
                                name="Grid Density"
                                label="Grid Density"
                                value={gridDensity}
                                onChange={(v)=>this.setState({dateFormat:v.target.value})}>
                                <MenuItem key="compact" value="compact">Compact</MenuItem >
                                <MenuItem key="standard" value="standard">Standard</MenuItem>
                                <MenuItem key="comfortable" value="comfortable">Comfortable</MenuItem >
                            </WrappedSelect>
                        </Grid>
                    </FormSection>

                    <Grid item container spacing={2} justifyContent="center">
                        <Grid item>
                            <Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                disabled={!anyChanges}
                                onClick={this.onClickUpdate}
                                endIcon={<CheckCircleOutline />}>Update</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                disabled={!anyChanges}
                                onClick={this.onChangeConfig}
                                endIcon={<UndoOutlined />}>Revert</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </ThemeProvider>)

    }
}
