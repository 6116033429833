import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore';
import { LoadTemplatesEvent } from './layoutActions';
import { TabTemplateStoreState } from './layoutModels';

class TemplateStore extends FluxStore<TabTemplateStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            templates: [],
        }))
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadTemplatesEvent){
            this.state.templates = action.payload;
        }   
    }

    public GetTemplates() {
        return this.state.templates;
    }

    public GetTemplateById(id: number) {
        return this.state.templates.filter(t=>t.tabTemplateId===id)[0];
    }
}

const templateStoreInstance = new TemplateStore(AppDispatcher);
export default templateStoreInstance;