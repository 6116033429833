import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackRisk } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { GridCellParams, GridOverlay } from '@mui/x-data-grid-pro';
import riskStoreInstance from './riskStore';
import { AggregateCube, CubeKeys, FilterCube } from '../qwack/cubeUtils';
import _ from 'lodash';
import { TO_ResultCubeRow } from '../qwack/to_ResultCubeRow';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, ReferenceLine, Bar, Tooltip } from 'recharts';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface VegaThetaViewState {
    resultCubeVega: TO_ResultCube | undefined,
    resultCubeTheta: TO_ResultCube | undefined,
    mainRef: RefObject<HTMLDivElement>,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    selectedUnderlying: string | undefined,
    units: string,

}

export interface VegaThetaViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const Metrics = ["AssetVega", "Theta"];
const MetricUnits = ["Currency"];
export class VegaThetaView extends React.Component<VegaThetaViewProps, VegaThetaViewState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: VegaThetaViewProps) {
        super(props)
        var asOf = props.getState("vt_asOf");
        this.state = {
            resultCubeVega: undefined,
            resultCubeTheta: undefined,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            rowExpanded: undefined,
            selectedSet: DefaultSetName,
            selectedUnderlying: undefined,
            units: MetricUnits[0]
        };;
        this.updatePVs = this.updatePVs.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.controls = this.controls.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = riskStoreInstance.addChangeListener(this.updatePVs);
        this.updatePVs();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("vt_asOf", dStr);
        this.updatePVs(d);
        this.setState({ asOf: d, });
    }

    async calcPVs() {
        this.setState({ awaitingRefresh: true, resultCubeVega: null });
        const { selectedSet, asOf } = this.state;
        var t0 = ComputeQwackRisk(asOf.toDate(), Metrics[0], selectedSet);
        var t1 = ComputeQwackRisk(asOf.toDate(), Metrics[1], selectedSet);
        await Promise.all([t0, t1]);
    }

    updatePVs(asOf?: Moment, set?: string) {
        if (!asOf)
            asOf = this.state.asOf;

        if (!set)
            set = this.state.selectedSet;

        let cube0 = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, Metrics[0])
        let cube1 = riskStoreInstance.getQwackRisk(asOf?.toDate(), set, Metrics[1])
        var refreshDone = (!this.state.awaitingRefresh) || (cube0 && cube1);
        this.setState({ resultCubeVega: cube0, resultCubeTheta: cube1, awaitingRefresh: !refreshDone });
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }


    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toFixed(4)}</div>
        }
        else
            return <div></div>;
    }

    controls() {
        const { awaitingRefresh, asOf, selectedSet, resultCubeVega: resultCube, selectedUnderlying } = this.state;
        var availableUnderlyings = resultCube ? CubeKeys(resultCube, "Underlying") : new Array<string>();
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                        <Grid item container spacing={1} style={{ width: "50%" }}>
                            <Grid item>
                                <WrappedDatePicker
                                    style={{ width: "120px" }}
                                    value={asOf?.toDate()}
                                    onChange={this.onChangeDate}
                                    disableFuture
                                    minDate={EarliestDate}
                                    emptyLabel="Live"
                                    clearLabel="Live"
                                    label={"As-Of"} />
                            </Grid>
                            <Grid item>
                                <MarketDataSetSelector
                                    id="set"
                                    name="set"
                                    label="Set"
                                    value={selectedSet}
                                    onChange={(set: string) => {
                                        this.props.onChangeState("selectedSet", set)
                                        this.props.onChangeState("selectedSet", set)
                                        this.updatePVs(null, set);
                                        this.setState({ selectedSet: set });
                                    }} />
                            </Grid>
                            <Grid item>
                                <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcPVs()}>Compute</Button>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "200px" }}
                                    id="underlying"
                                    name="underlying"
                                    label="Underlying"
                                    value={selectedUnderlying ?? "Underlying..."}
                                    onChange={(d) => {
                                        var ul = d.target.value as string;
                                        if (ul === "Underlying...")
                                            ul = null;
                                        this.props.onChangeState("selectedUnderlying", ul)
                                        this.setState({ selectedUnderlying: ul });
                                    }}>
                                    {["Underlying...", ...availableUnderlyings].map(a =>
                                        <MenuItem key={"ulx_" + a} value={a}>{a.replaceAll(" Underlying Index", "")}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    render() {
        const { resultCubeVega, resultCubeTheta, selectedUnderlying } = this.state;

        var aggregatedVegasByPoint: TO_ResultCube = {} as TO_ResultCube;
        var aggregatedVegasByUl: TO_ResultCube = {} as TO_ResultCube;
        var aggregatedThetasByUl = new Map<string, TO_ResultCubeRow>();
        let chartData = new Array<any>();

        if (resultCubeVega) {
            aggregatedVegasByUl = AggregateCube(resultCubeVega, ["Underlying"]);
            var underlyings = CubeKeys(resultCubeVega, "Underlying");
            var filter: { [field: string]: string } = {};
            filter["Underlying"] = selectedUnderlying;
            var filtered = FilterCube(resultCubeVega, filter);
            aggregatedVegasByPoint = AggregateCube(filtered, ["PointLabel"]);

            var thetasByUl = AggregateCube(resultCubeTheta, ["Underlying"]);
            thetasByUl?.rows.forEach(row => {
                if (Math.abs(row.value) > 0.001)
                    aggregatedThetasByUl.set(row.metaData[0], row);
            });

            for (var i = 0; i < underlyings.length; i++) {
                const ul = underlyings[i];
                var s = { Underlying: ul.replaceAll(" Underlying Index", ""), Vega: Math.round(aggregatedVegasByUl.rows.filter(r => r.metaData[0] === ul)[0].value) }
                chartData.push(s);
            }
        }

        const formatOps = { maximumFractionDigits: 0 };
        var vegaColor = userStoreInstance.GetTheme().border_color;

        var thetaTotal = 0;

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="DeltaGammaContainer">
                        <div className="RiskControls">{this.controls()}</div>
                        <div className="DeltaGammaLowerContainer">
                            <div className="DeltaGammaByUlTable">
                                <Typography variant="h6">By Underlying</Typography>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead />
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="HeaderRow">Underlying</TableCell>
                                                <TableCell className="HeaderRow">Vega (USD/1%)</TableCell>
                                                <TableCell className="HeaderRow">Theta (USD/1b)</TableCell>
                                            </TableRow>
                                            {aggregatedVegasByUl?.rows?.map((a, ix) => {
                                                thetaTotal += aggregatedThetasByUl.get(a.metaData[0])?.value ?? 0;
                                                return a ? (
                                                    <TableRow key={a.metaData[0]} hover onDoubleClick={() => this.setState({ selectedUnderlying: selectedUnderlying === a.metaData[0] ? undefined : a.metaData[0] })}>
                                                        <TableCell className={selectedUnderlying === a.metaData[0] ? "SelectedRow" : "NonSelectedRow"}>{a.metaData[0].replaceAll(" Underlying Index", "")}</TableCell>
                                                        <TableCell className={selectedUnderlying === a.metaData[0] ? "SelectedRow" : "NonSelectedRow"}>{a.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                        <TableCell className={selectedUnderlying === a.metaData[0] ? "SelectedRow" : "NonSelectedRow"}>{aggregatedThetasByUl.get(a.metaData[0])?.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                    </TableRow>) : null;
                                            })}
                                            {aggregatedVegasByUl?.rows ? <TableRow key={"totalVega"}>
                                                <TableCell className="HeaderRow">Total</TableCell>
                                                <TableCell className="HeaderRow">{_.sum(aggregatedVegasByUl.rows.map(r => r.value)).toLocaleString(undefined, formatOps)}</TableCell>
                                                <TableCell className="HeaderRow">{thetaTotal.toLocaleString(undefined, formatOps)}</TableCell>
                                            </TableRow> : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            {selectedUnderlying ? <div className="DeltaGammaPointTable">
                                <Typography variant="h6">{selectedUnderlying.replaceAll(" Underlying Index", "")}</Typography>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead />
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="HeaderRow">Expiry</TableCell>
                                                <TableCell className="HeaderRow">Vega (USD/1%)</TableCell>
                                            </TableRow>
                                            {aggregatedVegasByPoint?.rows?.map((a, ix) => {
                                                return a ? (
                                                    <TableRow key={a.metaData[0]} hover>
                                                        <TableCell className="NonSelectedRow">{a.metaData[0]}</TableCell>
                                                        <TableCell className="NonSelectedRow">{a?.value?.toLocaleString(undefined, formatOps)}</TableCell>
                                                    </TableRow>) : null;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div> : <Typography variant="h6" style={{ width: "49%" }}>Underlying Detail</Typography>}
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", textAlign: "center", paddingRight: "10px" }}>
                            <Typography>Vega (USD/1%)</Typography>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart key="vegaChart" width={500} height={400} data={chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="Underlying" stroke={userStoreInstance.GetTheme().color} />
                                    <YAxis stroke={userStoreInstance.GetTheme().color} />
                                    <Tooltip />
                                    <ReferenceLine y={0} stroke="#000" />
                                    <Bar dataKey="Vega" fill={vegaColor} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div></ThemeProvider>
            </StyledEngineProvider>
        );
    }
}
