import React from 'react';
import FBEmitter from 'fbemitter';
import BasketDesigner from './basketDesigner';
import { Asset } from '../../assets/assetsModels';
import { BasketDefinition } from '../../qwack/basketDefinition';
import { Wrapper } from '../../payoff/models/wrapper';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import BarrierDetailDesigner from './barrierDetails';
import { BarrierDetail } from '../../payoff/barrierDetail';
import AutocallDatesDesigner from './autocallDatesDesigner';
import { DateFixedRelative } from '../../payoff/models/dateFixedRelative'
import WrapperDetailBox, { AvailableIssuers } from './wrapperDetailBox';
import { WrappedPayoff } from '../../payoff/models/wrappedPayoff';
import NotionalAndCurencyBox from './notionalAndCurrencyBox';
import { OptionStructure } from '../../payoff/models/optionStructure';
import { VanillaOptionLeg } from '../../payoff/models/vanillaOptionLeg';
import { OptionStructureType } from '../../payoff/models/optionStructureType';
import { BarrierOptionLeg } from '../../payoff/models/barrierOptionLeg';
import { OptionType } from '../../qwack/optionType';
import { BarrierObservationType, BarrierSide, BarrierType } from '../../qwack/barrierEnums';
import { NoteDetail } from '../../payoff/models/noteDetail';
import { BasketType } from '../../qwack/basketType';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { Grid } from '@mui/material';
import { InputSection } from './helpers';


export type BondPlusCallPanelProps = {
    definitionUpdate: (state: OptionStructure) => void,
    initialDefinition: OptionStructure,
    allAssets: Asset[],
    disabled: boolean,
    extraKey: string
}

export type BondPlusCallPanelState = {
    definition: OptionStructure;
    basket: BasketDefinition;
    startDate: DateFixedRelative;
    endDate: DateFixedRelative;
    subType: string;
    possibleWrappers: string[];
    callStrike: number,
    capStrike: number,
    barrierDetail: BarrierDetail
}

const typeCall = "Call";
const typeCallSpread = "Call Spread";
const typeCallKO = "Call Up&Out";
const subTypes = [typeCall, typeCallSpread, typeCallKO]

export class BondPlusCallPanel extends React.Component<BondPlusCallPanelProps, BondPlusCallPanelState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: BondPlusCallPanelProps) {
        super(props);
        let def = this.props.initialDefinition;
        let barrierDetail = {
            level: 150,
            barrierObservationType: BarrierObservationType.Continuous,
            barrierSide: BarrierSide.Up,
            barrierType: BarrierType.KnockOut
        } as BarrierDetail
        if (def &&
            def.barrierOptionLegs &&
            def.barrierOptionLegs.length > 0 &&
            def.barrierOptionLegs[0].barrier1Detail)
            barrierDetail = def.barrierOptionLegs[0].barrier1Detail;

        let basket = { basketType: BasketType.SingleAsset, basketWeights: {} } as BasketDefinition;
        if (def.vanillaLegs && def.vanillaLegs.length > 0)
            basket = def.vanillaLegs[0].underlying;
        else if (def.barrierOptionLegs && def.barrierOptionLegs.length > 0)
            basket = def.barrierOptionLegs[0].underlying;

        let definition = def ?? { optionStructureType: OptionStructureType.SingleOption } as OptionStructure;

        let startDate = def?.strikeDate ?? { isRelative: true } as DateFixedRelative;
        let endDate = { isRelative: true } as DateFixedRelative;
        if (def.vanillaLegs && def.vanillaLegs.length > 0) {
            endDate = def.vanillaLegs[0].expiryDate;
        }
        else if (def.barrierOptionLegs && def.barrierOptionLegs.length > 0) {
            endDate = def.barrierOptionLegs[0].expiryDate;
        }

        this.state = {
            definition: definition,
            possibleWrappers: [],
            basket: basket,
            subType: typeCall,
            startDate: startDate,
            endDate: endDate,
            callStrike: 100,
            capStrike: 200,
            barrierDetail: barrierDetail
        }

        this.basketDefinitionUpdate = this.basketDefinitionUpdate.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.updateWrapperDetail = this.updateWrapperDetail.bind(this);
        this.onChangeSubtype = this.onChangeSubtype.bind(this);
        this.onChangeCallStrike = this.onChangeCallStrike.bind(this);
        this.onChangeCapStrike = this.onChangeCapStrike.bind(this);
        this.barrierDetailUpdate = this.barrierDetailUpdate.bind(this);

    }

    public componentWillUnmount() {

    }

    async componentDidMount() {
        this.fillPossibleWrappers();
        const { definition } = this.state;

        if (!this.state.definition.wrapperType) {
            definition.wrapperType = Wrapper.Note;
            if (!definition.noteDetail) {
                definition.noteDetail = { redemptionPercentage: 100, issuer: AvailableIssuers[0] } as NoteDetail;
            }
        }

        if (!definition.digitalOptionLegs && !definition.vanillaLegs) {
            this.updateDefOnSubtypeChange(typeCall, definition);
        }

        this.setState({ definition });
    }

    public componentDidUpdate() {

    }

    fillPossibleWrappers = () => {
        const possibleWrappers = Object.keys(Wrapper).filter(k => typeof Wrapper[k as any] === "number");
        this.setState({ possibleWrappers });
    }

    basketDefinitionUpdate(basket: BasketDefinition) {
        const { definition, subType } = this.state;

        if (subType === typeCall || subType === typeCallSpread) {
            definition.vanillaLegs[0].underlying = basket;
            if (subType === typeCallSpread)
                definition.vanillaLegs[1].underlying = basket;
        }
        else if (subType === typeCallKO) {
            definition.barrierOptionLegs[0].underlying = basket;
        }

        this.setState({ definition, basket });
        this.props.definitionUpdate(definition);
    }

    updateDefOnSubtypeChange(newSubType: string, definition: OptionStructure) {
        const { callStrike, capStrike, barrierDetail, basket, endDate } = this.state;
        switch (newSubType) {
            case typeCall:
                definition.optionStructureType = OptionStructureType.SingleOption;
                definition.barrierOptionLegs = [];
                definition.vanillaLegs = [{
                    strike: callStrike,
                    underlying: basket,
                    callPutStraddle: OptionType.call,
                    expiryDate: endDate,
                    isCashSettled: true,
                    legWeight: 1.0
                } as VanillaOptionLeg];
                break;
            case typeCallSpread:
                definition.optionStructureType = OptionStructureType.CallSpread;
                definition.barrierOptionLegs = [];
                definition.vanillaLegs = [{
                    strike: callStrike,
                    underlying: basket,
                    callPutStraddle: OptionType.call,
                    expiryDate: endDate,
                    isCashSettled: true,
                    legWeight: 1.0
                } as VanillaOptionLeg,
                {
                    strike: capStrike,
                    underlying: basket,
                    callPutStraddle: OptionType.call,
                    expiryDate: endDate,
                    isCashSettled: true,
                    legWeight: -1.0
                } as VanillaOptionLeg];
                break;
            case typeCallKO:
                definition.optionStructureType = OptionStructureType.SingleOption;
                definition.barrierOptionLegs = [{
                    strike: callStrike,
                    underlying: basket,
                    callPutStraddle: OptionType.call,
                    expiryDate: endDate,
                    isCashSettled: true,
                    legWeight: 1.0,
                    hasBarrier1: true,
                    barrier1Detail: barrierDetail
                } as BarrierOptionLeg,];
                definition.vanillaLegs = [];
                break;
        }
    }

    onChangeSubtype(newSubType: string) {
        const { subType, definition } = this.state;
        if (subType !== newSubType) {
            this.updateDefOnSubtypeChange(newSubType, definition);
            this.setState({ subType: newSubType, definition });
        }

    }

    onChangeCallStrike(val: number) {
        const { definition, subType } = this.state;
        if (subType === typeCall || subType === typeCallSpread) {
            if (definition.vanillaLegs)
                definition.vanillaLegs[0].strike = val;
        }
        else if (subType === typeCallKO) {
            if (definition.barrierOptionLegs)
                definition.barrierOptionLegs[0].strike = val;
        }
        this.setState({ definition, callStrike: val });
        this.props.definitionUpdate(definition);
    }

    onChangeCapStrike(val: number) {
        const { definition } = this.state;
        definition.vanillaLegs[1].strike = val;
        this.setState({ definition, capStrike: val });
        this.props.definitionUpdate(definition);
    }


    setWrapperType = (wrapperType: string) => {
        var wt = (Wrapper as any)[wrapperType];
        const { definition } = this.state;
        definition.wrapperType = wt;
        this.setState({ definition });
    }

    barrierDetailUpdate(deets: BarrierDetail) {
        const { definition } = this.state;
        definition.barrierOptionLegs[0].hasBarrier1 = true;
        definition.barrierOptionLegs[0].barrier1Detail = deets;

        this.setState({ definition, barrierDetail: deets });
        this.props.definitionUpdate(definition);
    }

    updateStartDate(startDate: DateFixedRelative) {
        const { definition } = this.state;
        definition.strikeDate = startDate;
        this.setState({ startDate, definition });
        this.props.definitionUpdate(definition);
    }
    updateEndDate(endDate: DateFixedRelative) {
        const { definition } = this.state;
        if (definition.vanillaLegs)
            definition.vanillaLegs.forEach(vl => {
                vl.expiryDate = endDate
            });
        if (definition.barrierOptionLegs)
            definition.barrierOptionLegs.forEach(bl => {
                bl.expiryDate = endDate
            });
        this.setState({ endDate, definition });
        this.props.definitionUpdate(definition);
    }

    updateWrapperDetail(detail: WrappedPayoff) {
        const { definition } = this.state;
        definition.structuredSwapDetail = detail.structuredSwapDetail;
        definition.otcOptionDetail = detail.otcOptionDetail;
        definition.noteDetail = detail.noteDetail;
        definition.nominal = detail.nominal;
        definition.payoffCurrency = detail.payoffCurrency;
        definition.currencyConversionType = detail.currencyConversionType;
        definition.solveFor = detail.solveFor;
        this.setState({ definition });
        this.props.definitionUpdate(definition);
    }

    render() {
        const { definition, possibleWrappers, subType, basket, startDate, endDate } = this.state;
        const { allAssets } = this.props;

        let callStrike = this.state.callStrike;
        switch (subType) {
            case typeCallKO:
                callStrike = definition.barrierOptionLegs && definition.barrierOptionLegs.length > 0 ? definition.barrierOptionLegs[0].strike : callStrike
                break;
            case typeCall:
            case typeCallSpread:
                callStrike = definition.vanillaLegs && definition.vanillaLegs.length > 0 ? definition.vanillaLegs[0].strike : callStrike
                break;
        }
        let capStrike = definition.vanillaLegs && definition.vanillaLegs.length > 1 ? definition.vanillaLegs[1].strike : this.state.capStrike;
        let barrierDetails = definition.barrierOptionLegs && definition.barrierOptionLegs.length > 0 ? definition.barrierOptionLegs[0].barrier1Detail : { barrierSide: BarrierSide.Up, barrierType: BarrierType.KnockOut } as BarrierDetail;
        let showCapStrike = subType === typeCallSpread;
        let showbarrierDetail = subType === typeCallKO;


        return (
            <Grid item container>
                <InputSection title="Wrapper">
                    <StringTypeDropDown key={"wp"+this.props.extraKey} possibles={possibleWrappers} selected={possibleWrappers[definition.wrapperType]} onChange={this.setWrapperType} disabled={this.props.disabled} />
                </InputSection>
                <InputSection title="Basket">
                    <BasketDesigner key={"bs"+this.props.extraKey} extraKey={this.props.extraKey} keyName="bpcBasket" initialBasketDefinition={basket} allAssets={allAssets} stateUpdate={this.basketDefinitionUpdate} disabled={this.props.disabled} />
                </InputSection>
                <InputSection title="Option Type">
                    <StringTypeDropDown key={"ott"+this.props.extraKey} possibles={subTypes} selected={subType} onChange={this.onChangeSubtype} disabled={this.props.disabled} />
                </InputSection>
                <InputSection title="Call Strike">
                    <ValidatedNumericInput key={"css"+this.props.extraKey} units="%" keyName="callStrikePct" placeholder="Strike" style={{ width: "120px" }} initialValue={callStrike} onChange={(v) => { this.onChangeCallStrike(v); return true; }} disabled={this.props.disabled} />
                </InputSection>
                {
                    !showCapStrike ? null :
                        <InputSection title="Cap Strike">
                            <ValidatedNumericInput key={"ccss"+this.props.extraKey} units="%" keyName="callStrikePct" placeholder="Cap" className="AutocallDateGenCoupon" initialValue={capStrike} onChange={(v) => { this.onChangeCapStrike(v); return true; }} disabled={this.props.disabled} />
                        </InputSection>
                }
                {
                    !showbarrierDetail ? null :
                        <InputSection title="Barrier">
                            <BarrierDetailDesigner key={"bscs"+this.props.extraKey} disabled={this.props.disabled} stateUpdate={this.barrierDetailUpdate} initialBarrierDefinition={barrierDetails} />
                        </InputSection>
                }
                <InputSection title="Dates">
                    <AutocallDatesDesigner key={"dts"+this.props.extraKey}initialStartDate={startDate} initialEndDate={endDate} updateStartDate={this.updateStartDate} updateEndDate={this.updateEndDate} disabled={this.props.disabled} />
                </InputSection>
                <InputSection title="Wrapper Details">
                    <WrapperDetailBox key={"wdd"+this.props.extraKey} initialDetail={definition} updateWrapperDetail={this.updateWrapperDetail} disabled={this.props.disabled} />
                </InputSection>
                <InputSection title="Currency">
                    <NotionalAndCurencyBox key={"ccy"+this.props.extraKey} initialDetail={definition} updateWrapperDetail={this.updateWrapperDetail} disabled={this.props.disabled} />
                </InputSection>
            </Grid >)
    }

}
export default BondPlusCallPanel;