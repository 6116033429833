import React from 'react';
import FBEmitter from 'fbemitter';
import marketDataStoreInstance from '../marketDataStore';
import userStoreInstance from '../../user/userStore';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { GetCandles, NewMarketDataExplorerEdit } from '../marketDataActions';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { Candle } from '../models/candle';
import { CloseOutlined, FullscreenOutlined, SettingsOutlined } from '@mui/icons-material';
import { Menu, MenuItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogActions, IconButton, Typography } from '@mui/material';
import { MarketDataExplorerEditRequest } from '../marketDataModels';
import { ThemeProvider } from "@mui/material/styles";
import { getFormTheme } from '../../inputs/formCommon';

export type CandleChartProps = {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
    insId: number;
    setId: number;
    candleSize?: number;
    compactMode?: boolean;
    componentKey?: string;
    tabId?: number;
}

type CandleChartState = {
    currentTheme: string,
    candles: Candle[]
    chartData: any[],
    chartLayout: Partial<Layout>
    contextMenu: { mouseX: number; mouseY: number; } | null
    fullScreen: boolean;
}


export class CandleChart extends React.Component<CandleChartProps, CandleChartState>{
    eventSubscriptionMarketData: FBEmitter.EventSubscription | undefined;
    eventSubscriptionThemes: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: CandleChartProps) {
        super(props)
        this.state = {
            candles: [],
            chartData: [],
            chartLayout: {},
            currentTheme: userStoreInstance.GetThemeName(),
            contextMenu: null,
            fullScreen: false
        };

        this.setTheme = this.setTheme.bind(this);
        this.marketDataUpdate = this.marketDataUpdate.bind(this);
        this.onClickSettings = this.onClickSettings.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionThemes = userStoreInstance.addChangeListener(this.setTheme);
        this.eventSubscriptionMarketData = marketDataStoreInstance.addChangeListener(this.marketDataUpdate);
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.onInstrumentChange);

        const { insId, candleSize, setId } = this.props;
        var setName = marketDataStoreInstance.getSetName(setId)?.setName;
        var cached: Candle[];
        if (candleSize === 0) {
            var fixings = await marketDataStoreInstance.getFixingsAsync(setName, insId, false);
            if (fixings) {
                cached = fixings.map(f => { return { time: f.date, close: f.value } as Candle });
            }
        }
        else {
            cached = marketDataStoreInstance.getCandles(setName, insId, candleSize);
            if (!cached) {
                cached = await GetCandles(setName, insId, candleSize);
            }
        }


        var chartLayout: Partial<Layout> = {
            title: this.props.compactMode ? undefined : {
                text: listedInstrumentStoreInstance.getInstrumentById(this.props.insId)?.description,
                font: { color: userStoreInstance.GetTheme().color },
            },
            dragmode: "zoom",
            plot_bgcolor: userStoreInstance.GetTheme().background_color,
            paper_bgcolor: userStoreInstance.GetTheme().background_color,
            xaxis: {
                color: userStoreInstance.GetTheme().color,
                autorange: true,
                rangeslider: {
                    visible: false
                }
            },
            yaxis: {
                color: userStoreInstance.GetTheme().color
            },
            margin: !this.props.compactMode ? undefined : {
                pad: 1,
                l: 35,
                r: 10,
                t: 5,
                b: 33,
            },
            uirevision: `${this.props.insId}-${this.props.candleSize}`
        };

        if (cached) {
            let mainColor = userStoreInstance.GetTheme().color;
            let borderColor = userStoreInstance.GetTheme().border_color;
            let chartColorUp = userStoreInstance.GetTheme().chart_color_up;
            let chartColorDown = userStoreInstance.GetTheme().chart_color_down;
            const maxCandles = 1000;
            const data = this.props.candleSize ? cached.slice(-maxCandles) : cached;

            var chartData = this.props.candleSize ?
                [
                    {
                        x: data.map(d => d.time),
                        close: data.map(d => d.close),
                        high: data.map(d => d.high),
                        low: data.map(d => d.low),
                        open: data.map(d => d.open),
                        type: 'ohlc',
                        line: { color: mainColor },
                        increasing: { line: { color: chartColorUp } },
                        decreasing: { line: { color: chartColorDown } },
                        xaxis: 'x',

                        //yaxis: 'y',
                    }
                ] as any[] :
                [
                    {
                        x: data.map(d => d.time),
                        y: data.map(d => d.close),
                        type: 'scattergl',
                        mode: 'lines+markers',
                        line: { color: mainColor },
                        marker: { color: borderColor, size: 2 },
                    }
                ] as Data[];

            this.setState({ candles: cached, chartData, chartLayout });

        }

    }


    componentDidUpdate() {

    }

    componentWillUnmount() {
        if (this.eventSubscriptionMarketData) {
            this.eventSubscriptionMarketData.remove();
            this.eventSubscriptionMarketData = undefined;
        }
        if (this.eventSubscriptionThemes) {
            this.eventSubscriptionThemes.remove();
            this.eventSubscriptionThemes = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
    }

    async marketDataUpdate() {

    }

    onInstrumentChange() {

    }

    setTheme() {
        this.setState({ currentTheme: userStoreInstance.GetThemeName() });
    }

    async onChange() { }

    onClickSettings() {
        this.setState({ contextMenu: null })
        const { insId, componentKey, tabId, candleSize } = this.props;
        NewMarketDataExplorerEdit({ insId, chartType: candleSize === 0 ? "Fixings" : "Candles", componentId: componentKey, tabId } as MarketDataExplorerEditRequest);
    }

    onContextMenu(event: React.MouseEvent) {
        event.preventDefault();
        this.setState({
            contextMenu: this.state.contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        });
    };

    getCandleSizeStr(candleSize: number) {
        if (candleSize === 0)
            return "Daily Fixings";
        if (candleSize === 86400)
            return "Daily Candles";
        if (candleSize === 3600)
            return "Hourly Candles";
        if (candleSize > 3600)
            return `${candleSize / 3600} Hour Candles`;

        return `${candleSize / 60} Minute Candles`;
    }

    public render() {
        const { chartData, chartLayout, contextMenu, fullScreen } = this.state;

        const chartTitle = `${listedInstrumentStoreInstance.getInstrumentById(this.props.insId)?.description?.split(" Underlying Index")[0]} ${this.getCandleSizeStr(this.props.candleSize)}`;

        return (<ThemeProvider theme={getFormTheme()}>
            <React.Fragment>
                {this.props.compactMode ? <Menu
                    open={contextMenu !== null}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    onClose={() => this.setState({ contextMenu: null })}
                >
                    <MenuItem key={`smileKK-settingz`} onClick={this.onClickSettings}>
                        <ListItemIcon>
                            <SettingsOutlined />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                    </MenuItem>
                    {fullScreen ? null : <MenuItem key={`smileKK-settingz`} onClick={() => this.setState({ fullScreen: true })}>
                        <ListItemIcon>
                            <FullscreenOutlined />
                        </ListItemIcon>
                        <ListItemText>Full Screen</ListItemText>
                    </MenuItem>}
                </Menu> : null}
                <Dialog fullScreen key="tradeDetail"
                    open={fullScreen}
                    classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                    onKeyDown={(e => { if (e.key === "Escape") { this.setState({ fullScreen: false }) } })}>
                    {/* <AppBar sx={{ position: 'relative' }}>
                        <Typography variant='h6'>{chartTitle}</Typography>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => this.setState({ fullScreen: false })}
                                aria-label="close">
                                <CloseOutlined />
                            </IconButton>
                        </Toolbar>
                    </AppBar> */}
                    <DialogTitle style={{ display: "flex", flexDirection: "row", width: "100vw", justifyContent: "space-between" }}>
                        <Typography variant='h6'>{chartTitle}</Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => this.setState({ fullScreen: false })}
                            aria-label="close">
                            <CloseOutlined />
                        </IconButton>
                    </DialogTitle>
                    <DialogActions></DialogActions>
                    <div style={{ height: "calc(100vh - 100px)" }} onContextMenu={this.onContextMenu}>
                        <Plot
                            data={chartData}
                            layout={chartLayout}
                            config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                            style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                            useResizeHandler={true} />
                    </div>
                </Dialog>
                <div className="SmileChartGraph" onContextMenu={this.onContextMenu}>
                    <Plot
                        data={chartData}
                        layout={chartLayout}
                        config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                        style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                        useResizeHandler={true}
                    />
                </div>
            </React.Fragment >
        </ThemeProvider>);
    }
}