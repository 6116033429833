import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import { IDBPDatabase, openDB } from "idb";

class DataCache {
  dbLoaded : Promise<IDBPDatabase>;
  
  constructor() {
    this.dbLoaded = openDB("pltfm", 5, {
      upgrade(db, oldVersion, newVersion, transaction) {
        // 
        if (oldVersion === newVersion) return;
        if(db.objectStoreNames.contains("instruments"))
        {
          db.deleteObjectStore("instruments");
        }
        db.createObjectStore("instruments", { keyPath: "listedInstrumentId" });
        if(db.objectStoreNames.contains("storageBlobs")){
          db.deleteObjectStore("storageBlobs");
        }
        db.createObjectStore("storageBlobs", { keyPath: "key" });
      },
      blocked() {
        // …
      },
      blocking() {
        // …
      },
      terminated() {
        // …
      },
    });
  }

  public async SaveInstrument(instrument : ListedInstrument){
    const db = await this.dbLoaded;
    await db.put("instruments", instrument);
  }

  public async SaveInstruments(instruments: ListedInstrument[]) {
    const db = await this.dbLoaded;
    for(var i = 0; i < instruments.length;i++){
      await db.put("instruments", instruments[i]);
    }
  }


  public async getInstruments() {
    const allInsts = (await (await this.dbLoaded).getAll("instruments")) as ListedInstrument[];
    if(allInsts) return allInsts;
    return Array<ListedInstrument>();
  }
 
  public async deleteDB(){
    const db = await this.dbLoaded;
    db.close();
    indexedDB.deleteDatabase("pltfm");
    window.location.reload();
  }
}

const dataCacheInstance = new DataCache();

export default dataCacheInstance;