import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { AccountBalance, AggregatedSummary, DayTradeAnalysisRecord, LivePosition, LiveSummary, PositionAnalysisRecord, PositionStatus, PositionSummaryStoreState } from './positionSummaryModels';
import { LoadPositions, LoadPositionsEvent, LoadValuations, LoadValuationsByMeta, LoadValuationsEvent, NewDayTradeAnalysisEvent, NewPositionAnalysisEvent, UpdatedAccountBalancesEvent, UpdateLivePositionEvent } from './positionSummaryActions';
import _ from 'lodash';

const Now = "now";

class PositionSummaryStore extends FluxStore<PositionSummaryStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            summary: {} as { [asOf: string]: AggregatedSummary },
            liveSummary: {} as { [asOf: string]: LiveSummary },
            accountBalances: new Array<AccountBalance>(),
            positionAnalysis: new Array<PositionAnalysisRecord>(),
            dayTradeAnalysis: new Array<DayTradeAnalysisRecord>()
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadPositionsEvent) {
            const { payload } = action;
            if (payload.asOf === '01/01/3000 00:00:00')
                payload.asOf = Now;
            this.state.summary[payload.asOf] = payload;
            this.emitChange();
        }
        else if (action instanceof LoadValuationsEvent) {
            const { payload } = action;
            var key = this.getValKey(payload.asOf, payload.metaKeys, payload.fxAsPositions);
            this.state.liveSummary[key] = payload;
            this.emitChange();
        }
        else if (action instanceof UpdateLivePositionEvent) {
            const { payload } = action;
            var key2 = this.getValKey(Now, undefined, false);
            if (!this.state.liveSummary[key2])
                this.state.liveSummary[key2] = { positions: new Array<LivePosition>(), positionStatus: { quoteAges: {} } as PositionStatus } as LiveSummary;
            this.state.liveSummary[key2].positions = this.state.liveSummary[key2].positions.filter(p =>
                p.aggregatedPosition.instrumentId !== payload.aggregatedPosition.instrumentId);

            this.state.liveSummary[key2].positions.push(payload);
            if (payload.priceAge && payload.aggregatedPosition.instrumentId)
                this.state.liveSummary[key2].positionStatus.quoteAges[payload.aggregatedPosition.instrumentId] = payload.priceAge;
            this.emitChange();
        }
        else if (action instanceof UpdatedAccountBalancesEvent) {
            const { payload } = action;
            this.state.accountBalances = payload;
            this.emitChange();
        }
        else if (action instanceof NewPositionAnalysisEvent) {
            const { payload } = action;
            this.state.positionAnalysis = payload;
            this.emitChange();
        }
        else if (action instanceof NewDayTradeAnalysisEvent) {
            const { payload } = action;
            payload.forEach(d => {
                d.positionDate = new Date(d.positionDate);
            })
            this.state.dayTradeAnalysis = payload;
            this.emitChange();
        }
    }

    getAccountBalances() {
        return this.state.accountBalances;
    }

    getPositionAnalysis() {
        return this.state.positionAnalysis;
    }

    getDayTradeAnalysis() {
        return this.state.dayTradeAnalysis;
    }

    getPositionSummary(asOf?: string, preventAutoLoad: boolean = false) {
        var sum = asOf ? this.state.summary[asOf] : this.state.summary[Now];
        if (!sum && !preventAutoLoad)
            LoadPositions(asOf);
        return sum;
    }

    private getValKey(asOf?: string, metaKeys?: string[], showFxAsPosition: boolean = false, attachCash: boolean = false) {
        if (metaKeys && metaKeys.length > 0)
            return `${asOf ?? Now}¬${metaKeys.join("-")}¬${showFxAsPosition}¬${attachCash}`;
        else
            return `${asOf ?? Now}¬${showFxAsPosition}¬${attachCash}`;
    }

    getPositionForInstrument(asOf: string, insId: number) {
        var key = this.getValKey(asOf ?? Now, undefined, false);
        var sum = this.state.liveSummary[key];
        var pos = _.sum(sum?.positions.filter(x => x.aggregatedPosition.instrumentId === insId).map(p => p.aggregatedPosition.openPosition));
        return pos;
    }

    getValuations(asOf?: string, preventAutoLoad: boolean = false, asOfForFetch: string = null, showFxAsPosition: boolean = false, attachCash: boolean = false) {
        var key = this.getValKey(asOf ?? Now, undefined, showFxAsPosition, attachCash);
        var sum = this.state.liveSummary[key];
        if (!sum && !preventAutoLoad)
            LoadValuations(asOfForFetch ?? asOf, false, "", showFxAsPosition, attachCash);
        return sum;
    }

    hasValuation(asOf?: string, showFxAsPosition: boolean = false, attachCash: boolean = false) {
        var key = this.getValKey(asOf ?? Now, undefined, showFxAsPosition, attachCash);
        var sum = this.state.liveSummary[key];
        return sum !== undefined;
    }

    async getValuationsAsync(asOf?: string, preventAutoLoad: boolean = false, showFxAsPosition: boolean = false, attachCash: boolean = false) {
        var key = this.getValKey(asOf ?? Now, undefined, showFxAsPosition, attachCash);
        var sum = this.state.liveSummary[key];
        if (!sum && !preventAutoLoad) {
            return await LoadValuations(asOf, false, "", showFxAsPosition, attachCash);
        }
        return sum;
    }

    getValuationsByMeta(asOf?: string, metaKeys: string[] = new Array<string>(), preventAutoLoad: boolean = false, showFxAsPosition: boolean = false) {
        var key = this.getValKey(asOf ?? Now, metaKeys, showFxAsPosition);
        var sum = this.state.liveSummary[key];
        if (!sum && !preventAutoLoad)
            LoadValuationsByMeta(asOf, metaKeys, false, showFxAsPosition);
        return sum;
    }

    async getValuationsByMetaAsync(asOf?: string, metaKeys: string[] = new Array<string>(), preventAutoLoad: boolean = false, showFxAsPosition: boolean = false, attachCash: boolean = false) {
        var key = this.getValKey(asOf ?? Now, metaKeys, showFxAsPosition, attachCash);
        var sum = this.state.liveSummary[key];
        if (!sum && !preventAutoLoad) {
            return await LoadValuationsByMeta(asOf, metaKeys, false, showFxAsPosition, undefined, attachCash);
        }
        return sum;
    }
}

const positionStoreInstance = new PositionSummaryStore(AppDispatcher);
export default positionStoreInstance;