import { Autocall } from "../payoff/models/autoCall"
import { OptionStructure } from "../payoff/models/optionStructure"
import { Period } from "../payoff/payoffModels"

export type ProductState = {
    commonProductSummaries: ProductSummary[];
    conversionFactors: ConversionFactorCollection;
    availableCurrencies: string[];
    products: {[productId:number]:ProductSummary};
}

export type ProductSumamryPayload = {
    productId: number,
    spec: ProductSpec,
    description: string
}

export type ProductSummary = {
    identifier: string;
    productId: number;
    description: string;
    currency: string;
    units: string;
    spec: ProductSpec;
    payoffId: number;
}

export enum ProductPayoffType {
    Autocall,
    OptionStructure,
    CommodityVanilla
}

export enum StrikeType {
    Absolute = 1,
    ForwardDelta = 2,
    SpotDelta = 3
}

export enum TradeDirection {
    CustomerBuys = 1,
    CustomerSells = 2,
}

export enum FxConversionType {
    None = 0,
    SettleOtherCurrency = 1,
    AverageThenConvert = 2,
    ConvertThenAverage = 3,
    Quanto = 4,
}

export enum PeriodType {
    Bullet = 1,
    Monthly = 2,
    Quarterly = 3,
    SemiAnnual = 4,
    Yearly = 5,
    Custom = 6,
}

export enum DateGenerationType {
    BusinessDays = 1,
    FridaysOnly = 2,
}


export type ConversionFactorCollection = {
    factors: ConversionFactors[]
}

export type ConversionFactors = {
    assetId: number,
    factorsForAsset: ConversionFactor[]
}

export type ConversionFactor = {
    unitName: string,
    factor: number
}

export interface TradeSpec {
    uniqueId: string,
    productSpec: ProductSpec,
    size: number,
    price: number,
    units: string
}

export interface ProductSpec {
    payoffType: ProductPayoffType,
    payoffSubType: string,
    payoffId: number,
    autocall: Autocall,
    optionStructure: OptionStructure,
    commodityVanilla: CommodityVanillaPayoff,
}

export interface CommodityVanillaPayoff {
    assetName: string,
    uniqueKey: string,
    type: string,
    priceAsOne: boolean,
    legs: CommodityVanillaLeg[],
    solveFor: string,
    priceUnits: string,
    priceCurrency: string,
    premium: ProductSpecPremium
}

export interface CommodityVanillaLeg {
    uniqueKey: string,
    type: string,
    fixingIndex: string,
    assetId: number,
    schedule: ScheduleRow[],
    period: string,
    periodType: PeriodType,
    isCall: boolean
    strike: number,
    currency: string,
    settlement: SettlementConvention | undefined,
    fxConvention: FxConvention | undefined
}

export interface ProductSpecPremium {
    settlement: SettlementConvention,
    premiumAmount: number
    premiumCurrency: string
    premiumUnits: string | undefined,
}

export type ScheduleRow = {
    period: Period,
    quantity: number,
    units: string,
    strike: number,
    settlement: SettlementConvention,
    fixingCalendars: string
}

export type SettlementConvention = {
    date: string | undefined,
    lag: string | undefined,
    calendars: string | undefined,
    relativeTo: string | undefined,
}

export type FxConvention = {
    conversionType: string | undefined, //atc, cta etc
    fxFixingIndex: string | undefined,
    fxFixingCalendars: string | undefined,
    isCommonPricing: boolean,
}