import React from 'react';
import FBEmitter from 'fbemitter';
import StructuredProductDesigner from '../productDesigner/structuredProductDesigner';
import { RfqResponse } from '../../rfq/rfq';
import rfqSummaryStoreInstance from '../../rfq/rfqSummaryStore';
import { RfqSummary } from '../../rfq/rfqModels';
import { RefreshRfqSummary } from '../../rfq/rfqActions';
import rfqConnection from '../../rfq/rfqConnection';
import { CloneProduct, EditProduct } from '../productDesigner/productCloning/productCloneActions';
import { ProductSpec } from '../../product/productModels';
import { LoadProductSummary } from '../../product/productActions';
import Executor from '../../rfq/rfqDashboard/executor';
import { Button, ButtonGroup, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { gridRowHeight } from '../../layout/layoutControls';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../../inputs/formCommon';

export type PricingPanelProps = {
    onClose?: () => void;
    onChangeHeight?: (newHeight: number) => void;
    onChangeWidth?: (newWidth: number) => void;
    rfqResponse?: RfqResponse;
    height?: number
}

type PricingPanelState = {
    productDesignerMode: boolean,
    editExisting: boolean,
    rfqResponse: RfqResponse,
    rfqSummary: RfqSummary,
    designerRef: React.RefObject<StructuredProductDesigner>,
    productSpec: ProductSpec | undefined
    mainRef: React.RefObject<HTMLDivElement>,
    height: number,
    width: number
}

export class PricingPanel extends React.Component<PricingPanelProps, PricingPanelState> {
    eventSubscriptionRfqSummary: FBEmitter.EventSubscription | undefined;
    eventSubscriptionProduct: FBEmitter.EventSubscription | undefined;
    constructor(props: PricingPanelProps) {
        super(props);
        this.state = {
            productDesignerMode: this.props.rfqResponse === undefined,
            rfqResponse: this.props.rfqResponse ?? {} as RfqResponse,
            designerRef: React.createRef(),
            rfqSummary: {} as RfqSummary,
            productSpec: undefined,
            editExisting: false,
            mainRef: React.createRef(),
            height: (this.props.height * gridRowHeight) ?? 250,
            width: 250
        };

        this.onSubmitRfq = this.onSubmitRfq.bind(this);
        this.onChangeHeight = this.onChangeHeight.bind(this);
        this.onChangeWidth = this.onChangeWidth.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onClickClone = this.onClickClone.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickRefresh = this.onClickRefresh.bind(this);
        this.onChangeSummary = this.onChangeSummary.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionRfqSummary = rfqSummaryStoreInstance.addChangeListener(() => this.onChangeSummary());
        this.onChangeSummary();
        if (this.state.rfqResponse && this.state.rfqResponse.rfqId && this.state.rfqResponse.rfqId) {
            await RefreshRfqSummary(this.state.rfqResponse.rfqId);
        }
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionRfqSummary !== null) {
            this.eventSubscriptionRfqSummary.remove();
        }
    }

    componentDidUpdate() {
        // if (!this.state.productDesignerMode && this.state.mainRef.current) {
        //     var newHeight = this.state.mainRef.current.clientHeight;
        // var newWidth = this.state.mainRef.current.clientWidth;
        // if (newHeight !== this.state.height) {
        //     this.onChangeHeight(newHeight);
        // }
        // if (newWidth !== this.state.width) {
        //     this.onChangeWidth(newWidth);
        // }
        // }
    }

    public async cleanUpOnClose() {
        if (this.state.rfqResponse) {
            await rfqConnection.cancelRfq(this.state.rfqResponse.rfqId)
        }
        if (this.eventSubscriptionRfqSummary !== null) {
            this.eventSubscriptionRfqSummary.remove();
        }
    }

    async onChangeSummary() {
        if (this.state.rfqResponse?.rfqId) {
            var rfqSummary = rfqSummaryStoreInstance.GetRfq(this.state.rfqResponse.rfqId);
            if (rfqSummary) {
                var productSummary = await LoadProductSummary(rfqSummary.productId)
                this.setState({ rfqSummary, productSpec: productSummary.spec });
            }
        }
    }

    onSubmitRfq = (rfqResponse: RfqResponse, productSpec: ProductSpec) => {
        this.setState({ productDesignerMode: false, editExisting: false, rfqResponse, productSpec });
    };
    onCancelEdit = () => {
        this.setState({ productDesignerMode: false, editExisting: false });
    };

    onChangeHeight(newHeight: number) {
        if (this.props.onChangeHeight) {
            this.props.onChangeHeight(newHeight);
        }
        this.setState({ height: newHeight });
    }

    onChangeWidth(newWidth: number) {
        var cappedWidth = Math.min(newWidth, 600);
        if (cappedWidth !== this.state.width) {
            if (this.props.onChangeWidth) {
                this.props.onChangeWidth(cappedWidth);
            }
            this.setState({ width: cappedWidth });
        }
    }

    onClickEdit() {
        EditProduct(this.state.productSpec, this.state.rfqResponse.rfqId, this.state.rfqSummary.requestingFirm);
    }

    onClickClone() {
        CloneProduct(this.state.productSpec)
    }

    async onClickCancel() {
        if (this.state.rfqResponse)
            await rfqConnection.cancelRfq(this.state.rfqResponse.rfqId);
    }

    async onClickRefresh() {
        if (this.state.rfqResponse)
            await rfqConnection.refreshRfq(this.state.rfqResponse.rfqId);
    }

    renderPricingMode() {
        return (<div ref={this.state.mainRef}>
            {/* <TableContainer component={Paper}> */}
            <ThemeProvider theme={getFormTheme()}>
                <Table classes={{ root: "PricingPanelTable" }} >
                    <TableHead />
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2} size='small'>
                                <ButtonGroup className="PricingPanelButtonGroup" fullWidth={true} size='small'>
                                    {/* <Button className="PltfmButtonLiteGrouped" title="Edit" onClick={this.onClickEdit}>Edit</Button> */}
                                    <Button className="PltfmButtonLiteGrouped" title="Clone" onClick={this.onClickClone}>Clone</Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                        {/* <TableRow hover>
                            <TableCell align='center'>{this.state.rfqSummary?.state}</TableCell>
                            <TableCell align='center'>Rfq Id {this.state.rfqResponse.rfqId}</TableCell>
                        </TableRow> */}
                        <TableRow hover>
                            <TableCell colSpan={2} align='center'>{this.state.rfqSummary?.description}</TableCell>
                        </TableRow>
                        {this.state.rfqSummary !== null ? <TableRow>
                            <TableCell colSpan={2}>
                                <Executor
                                    onClose={null}
                                    size={0}
                                    key={`executor_pp_${this.state.rfqSummary.rfqId}`}
                                    rfqId={this.state.rfqSummary.rfqId}
                                    productId={this.state.rfqSummary.productId}
                                    uniqueKey={`executor_pp_${this.state.rfqSummary.rfqId}`} />
                            </TableCell>
                        </TableRow> : null}
                        <TableRow>
                            <TableCell colSpan={2}>
                                <ButtonGroup className="PricingPanelButtonGroup" fullWidth={true}>
                                    <Button className="PltfmButtonLiteGrouped" title="Refresh" onClick={this.onClickRefresh}>Refresh</Button>
                                    <Button className="PltfmButtonLiteGrouped" title="Cancel" onClick={this.onClickCancel}>Cancel</Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </ThemeProvider>
            {/* </TableContainer> */}
            {/* <div className="PricingPanelButtonRow">

            </div>
            <div className="PricingPanelRow">
                <div className="PricingPanelLeftTitles"><div className="PricingPanelLeftTitlesText">Rfq Id</div></div>
                <div className="PricingPanelRightContent"> </div>
            </div>
            <div className="PricingPanelRow">
                <div className="PricingPanelLeftTitles"><div className="PricingPanelLeftTitlesText">Description</div></div>
                <div className="PricingPanelRightContent">{this.state.rfqSummary?.description}</div>
            </div>
            <div className="PricingPanelRow">
                <div className="PricingPanelLeftTitles"><div className="PricingPanelLeftTitlesText">Status</div></div>
                <div className="PricingPanelRightContent">{this.state.rfqSummary?.state}</div>
            </div>
            {this.state.rfqSummary !== null ?
                <div className="PricingPanelRow">
                    <Executor onClose={null} size={0} key={`executor_pp_${this.state.rfqSummary.rfqId}`} rfqId={this.state.rfqSummary.rfqId} productId={this.state.rfqSummary.productId} uniqueKey={`executor_pp_${this.state.rfqSummary.rfqId}`} />
                </div> : null}
            <div className="PricingPanelButtonRow">
                <ButtonGroup className="PricingPanelButtonGroup" fullWidth={true}>
                    <Button className="PltfmButton" title="Refresh" onClick={this.onClickRefresh}>Refresh</Button>
                    <Button className="PltfmButton" title="Cancel" onClick={this.onClickCancel}>Cancel</Button>
                </ButtonGroup>
            </div> */}
        </div>);
    }

    renderDesignMode(editMode: boolean) {
        return (<StructuredProductDesigner 
            ref={this.state.designerRef}
             onSubmitRfq={this.onSubmitRfq} 
             onCancelEdit={editMode ? this.onCancelEdit : undefined} 
             counterpartyId={null} 
             onChangeHeight={this.onChangeHeight} 
             existingSpec={this.state.productSpec} 
             existingRfqSummary={this.state.rfqSummary}/>);
    }

    render() {
        const { productDesignerMode, editExisting } = this.state;
        return (
            <div className='PricingPanelContainer'>
                {productDesignerMode || editExisting ? this.renderDesignMode(editExisting) : this.renderPricingMode()}
            </div>
        );
    }
}

export default PricingPanel;