import React from 'react';
import FBEmitter from 'fbemitter';
import marketDataStoreInstance from '../marketDataStore';
import userStoreInstance from '../../user/userStore';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { ListItemIcon, Menu, MenuItem, ListItemText, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { GetVolSurface, NewMarketDataExplorerEdit } from '../marketDataActions';
import { TO_GridVolSurface } from '../../qwack/to_GridVolSurface';
import GridVolSurface from '../../qwack/gridVolSurface';
import moment from 'moment';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { getFormTheme } from '../../inputs/formCommon';
import _ from 'lodash';
import { CloseOutlined, FullscreenOutlined, SettingsOutlined } from '@mui/icons-material';
import { MarketDataExplorerEditRequest } from '../marketDataModels';

export type SurfaceChartProps = {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
    insId: number;
    valDate: Date;
    compactMode?: boolean;
    componentKey?: string;
    tabId?: number;
}

type SurfaceChartState = {
    selectedDate: Date,
    selectedSmileDate: Date,
    currentTheme: string,
    surfaceTo: TO_GridVolSurface
    surface: GridVolSurface,
    selectedExpiries: number[],
    fullScreen: boolean;
    contextMenu: { mouseX: number; mouseY: number; } | null
}


export class SurfaceChart extends React.Component<SurfaceChartProps, SurfaceChartState>{
    eventSubscriptionMarketData: FBEmitter.EventSubscription | undefined;
    eventSubscriptionThemes: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: SurfaceChartProps) {
        super(props)
        this.state = {
            selectedDate: undefined,
            selectedSmileDate: undefined,
            currentTheme: userStoreInstance.GetThemeName(),
            surfaceTo: undefined,
            surface: undefined,
            selectedExpiries: [],
            contextMenu: null,
            fullScreen: false
        };

        this.setTheme = this.setTheme.bind(this);
        this.marketDataUpdate = this.marketDataUpdate.bind(this);
        this.handleExpirySelect = this.handleExpirySelect.bind(this);
        this.onClickSettings = this.onClickSettings.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionThemes = userStoreInstance.addChangeListener(this.setTheme);
        this.eventSubscriptionMarketData = marketDataStoreInstance.addChangeListener(this.marketDataUpdate);
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.onInstrumentChange);

        const { insId, valDate } = this.props;
        var cached = marketDataStoreInstance.getVolSurface(insId, valDate);
        if (!cached) {
            cached = await GetVolSurface(insId, valDate);
        }

        if (cached) {
            var surf = new GridVolSurface(cached);
            this.setState({ surfaceTo: cached, surface: surf });
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        if (this.eventSubscriptionMarketData) {
            this.eventSubscriptionMarketData.remove();
            this.eventSubscriptionMarketData = undefined;
        }
        if (this.eventSubscriptionThemes) {
            this.eventSubscriptionThemes.remove();
            this.eventSubscriptionThemes = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
    }

    async marketDataUpdate() {

    }

    onInstrumentChange() {

    }

    setTheme() {
        this.setState({ currentTheme: userStoreInstance.GetThemeName() });
    }

    async onChange() { }

    handleExpirySelect(value: number) {
        const { selectedExpiries } = this.state;
        const currentIndex = selectedExpiries.indexOf(value);
        const newChecked = [...selectedExpiries];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ selectedExpiries: newChecked });
    }

    onClickSettings() {
        this.setState({ contextMenu: null })
        const { insId, valDate, componentKey, tabId } = this.props;
        NewMarketDataExplorerEdit({ insId, chartType: "Surface", valDate, componentId: componentKey, tabId } as MarketDataExplorerEditRequest);
    }

    onContextMenu(event: React.MouseEvent) {
        event.preventDefault();
        this.setState({
            contextMenu: this.state.contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        });
    };

    render() {
        const { surface, contextMenu, fullScreen } = this.state;

        var SurfaceChartData: Data[];
        var SurfaceChartLayout: Partial<Layout>;

        if (surface) {
            var strikes5d = _.range(0.05, 0.95, 0.05);
            //var strikes = [-0.1, -0.25, -0.5, -0.75, -0.9];
            var dates = surface.Expiries.map(e => new Date(e));
            var points = new Array<number[]>();
            surface.Expiries.map((e, eix) => {
                points[eix] = strikes5d.map((k) => {
                    return surface.GetVolForDeltaStrikeExpAsDate(-k, e, 100);
                });
                return null;
            });
            SurfaceChartData = [
                {
                    //x: strikes,
                    //y: dates,
                    z: points,
                    type: 'surface',
                    //mode: 'lines+markers',
                    marker: { color: userStoreInstance.GetTheme().chart_color_indic_0 },
                }
            ] as Data[];

            var nExpiryTicks = 8;
            var expiryStep = Math.floor(dates.length / nExpiryTicks);
            var filteredDates = dates.filter((d, ix) => ix === 0 || ix / expiryStep === Math.floor(ix / expiryStep));
            var filteredDateIxs = dates.map((d, ix) => ix === 0 || ix / expiryStep === Math.floor(ix / expiryStep) ? ix : null).filter(x => x !== null);
            SurfaceChartLayout = {
                title: this.props.compactMode ? undefined : { text: "Delta-Space Implied Volatility Surface", font: { color: userStoreInstance.GetTheme().color, family: userStoreInstance.GetTheme().font_family } },
                paper_bgcolor: userStoreInstance.GetTheme().background_color,
                scene: {
                    camera: { eye: { x: 1.5, y: -1.5, z: 0.8 } },
                    xaxis: {
                        title: "Put Delta",
                        ticktext: strikes5d.map(k => `${(k * 100).toFixed(0)}%`),
                        tickvals: strikes5d.map((k, ix) => ix),
                        color: userStoreInstance.GetTheme().color
                    },
                    yaxis: {
                        title: "Expiry",
                        ticktext: filteredDates.map(d => moment(d).format("DDMMMYY")),
                        tickvals: filteredDateIxs,
                        range: [0, dates.length],
                        color: userStoreInstance.GetTheme().color
                    },
                    zaxis: {
                        title: "Implied Vol",
                        nticks: 5,
                        tickformat:"~%",
                        color: userStoreInstance.GetTheme().color
                    },
                    bgcolor: userStoreInstance.GetTheme().background_color
                },
                margin: !this.props.compactMode ? undefined : {
                    pad: 1,
                    l: 5,
                    r: 25,
                    t: 0,
                    b: 5,
                }
            }
        }

        const chartTitle = `${listedInstrumentStoreInstance.getInstrumentById(this.props.insId)?.description?.split(" Underlying Index")[0]} implied volatility surface as of ${moment(this.props.valDate).format(userStoreInstance.GetDateFormat())}`;

        return (
            <div className={`SurfaceChart`}>
                <ThemeProvider theme={getFormTheme()}>
                    {this.props.compactMode ? <Menu
                        open={contextMenu !== null}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                        onClose={() => this.setState({ contextMenu: null })}
                    >
                        <MenuItem key={`smileKK-settingz`} onClick={this.onClickSettings}>
                            <ListItemIcon>
                                <SettingsOutlined />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </MenuItem>
                        {fullScreen ? null : <MenuItem key={`smileKK-fullscrn`} onClick={() => this.setState({ fullScreen: true })}>
                            <ListItemIcon>
                                <FullscreenOutlined />
                            </ListItemIcon>
                            <ListItemText>Full Screen</ListItemText>
                        </MenuItem>}
                    </Menu> : null}
                    <Dialog fullScreen key="tradeDetail"
                        open={fullScreen}
                        classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                        onKeyDown={(e => { if (e.key === "Escape") { this.setState({ fullScreen: false }) } })}>
                        <DialogTitle style={{ display: "flex", flexDirection: "row", width: "100vw", justifyContent: "space-between" }}>
                            <Typography variant='h6'>{chartTitle}</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => this.setState({ fullScreen: false })}
                                aria-label="close">
                                <CloseOutlined />
                            </IconButton>
                        </DialogTitle>
                        <DialogActions></DialogActions>
                        <div style={{ height: "calc(100vh - 100px)" }} onContextMenu={this.onContextMenu}>
                            <Plot
                                data={SurfaceChartData}
                                layout={SurfaceChartLayout}
                                config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                useResizeHandler={true}
                            />
                        </div>
                    </Dialog>
                    {SurfaceChartData?.length > 0 ?
                        <div className="SurfaceChartGraph" onContextMenu={this.onContextMenu}>
                            <Plot
                                data={SurfaceChartData}
                                layout={SurfaceChartLayout}
                                config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                useResizeHandler={true}
                            />
                        </div> : null}
                </ThemeProvider>
            </div>
        );
    }
}