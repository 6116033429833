import { Button, Typography } from '@mui/material';
import { AddBoxOutlined, ChangeHistoryOutlined, ListAltOutlined, LocationSearchingOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import { AddTradeComponent } from './addTradeComponent';
import { TradeBlotterTabComponent } from './tradeBlotterTabComponent';
import { TradeMetaUpdater } from './tradeMetaUpdater';
import { TradeHistoryTable } from './tradeHistoryTable';
import layoutStoreInstance from '../layout/layoutStore';

interface TradeBlotterTabState {
    currentMode: TradeTabMode,
    collapsed: boolean
}

export interface TradeBlotterTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
    id?: any
}

enum TradeTabMode {
    None,
    Blotter,
    MetaEditor,
    AddTrade,
    TradeHistory
}

export class TradeBlotterTab extends React.Component<TradeBlotterTabProps, TradeBlotterTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: TradeTabMode = !isNaN(Number(tabModeStr)) ? TradeTabMode[TradeTabMode[Number(tabModeStr)]] : TradeTabMode.None;
        this.state = {
            currentMode: tabMode,
            collapsed: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }


    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case TradeTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case TradeTabMode.Blotter:
                return <TradeBlotterTabComponent id="123xxx" {...this.props} />
            case TradeTabMode.MetaEditor:
                return <TradeMetaUpdater  {...this.props} />
            case TradeTabMode.AddTrade:
                return <AddTradeComponent  {...this.props} />
            case TradeTabMode.TradeHistory:
                return <TradeHistoryTable  {...this.props} />
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: TradeTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: TradeTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    onClick(mode: TradeTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: TradeTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>

                                <div style={{ height: "20px" }} />
                                {this.renderButton(TradeTabMode.Blotter, <ListAltOutlined />, "Blotter")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(TradeTabMode.MetaEditor, <LocationSearchingOutlined />, "Meta")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(TradeTabMode.AddTrade, <AddBoxOutlined />, "Add")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(TradeTabMode.TradeHistory, <ChangeHistoryOutlined />, "History")}
                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}
