import { NewTickEvent, NewTicksEvent, NewTickSubscriptionEvent, SubscribeToTicksById } from "../accelerator/acceleratorActions";
import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import userSessionConnection from "../userSession/userSessionConnection";
import { MarketDataNewTickEvent } from "./marketDataActions";
import { MarketDataLiveStoreState } from "./marketDataModels";

class MarketDataLiveStore extends FluxStore<MarketDataLiveStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            latestTickTimes: new Map<number, Date>(),
            latestTicks: new Map<number, number>(),
            latestTickBids: new Map<number, number>(),
            latestTickAsks: new Map<number, number>(),
            subscribedIds: new Set<number>()
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof (NewTickEvent)) {
            const { payload } = action;
            this.state.latestTicks.set(payload.insId, payload.last);
            this.state.latestTickBids.set(payload.insId, payload.bid)
            this.state.latestTickAsks.set(payload.insId, payload.ask);
            this.state.latestTickTimes.set(payload.insId, new Date(payload.stamp));
            this.emitChange();
        }
        else if (action instanceof (NewTicksEvent)) {
            const { payload } = action;
            var anyUpdates = false;
            for (var i = 0; i < payload.length; i++) {
                var tick = payload[i];
                this.state.latestTicks.set(tick.insId, tick.last);
                this.state.latestTickBids.set(tick.insId, tick.bid)
                this.state.latestTickAsks.set(tick.insId, tick.ask);
                this.state.latestTickTimes.set(tick.insId, new Date(tick.stamp));
                anyUpdates = true;
            }
            if (anyUpdates)
                this.emitChange();
        }
        else if (action instanceof (MarketDataNewTickEvent)) {
            const { payload } = action;

            this.state.latestTicks.set(payload.instrumentId, payload.trade);
            this.state.latestTickBids.set(payload.instrumentId, payload.bid)
            this.state.latestTickAsks.set(payload.instrumentId, payload.ask);
            this.state.latestTickTimes.set(payload.instrumentId, new Date(payload.stampUtc));
            this.emitChange();
        }
        else if (action instanceof (NewTickSubscriptionEvent)) {
            const { payload } = action;
            payload.forEach(i => { this.state.subscribedIds.add(i) });
            this.emitChange();
        }
    }

    checkSubscribed(insId: number) {
        return this.state.subscribedIds.has(insId);
    }

    getTick(insId: number): number {
        return this.state.latestTicks.get(insId);
    }

    getBidAsk(insId: number) {
        return { bid: this.state.latestTickBids.get(insId), ask: this.state.latestTickAsks.get(insId) };
    }

    getTickTime(insId: number) {
        return this.state.latestTickTimes.get(insId);
    }

    getFxTicker(ccy: string) {
        var invertedPairs = ["EUR", "GBP", "AUD", "NZD"];
        if (invertedPairs.includes(ccy))
            return `${ccy}.USD`;
        else
            return `USD.${ccy}`;
    }
    fxInsMap = new Map<string, number>();
    getFxRate(ccy: string) {
        if (ccy === "USD") //TODO: put base ccy in consts/settings
            return 1.0;
        var insId = this.fxInsMap.get(ccy);
        if (!insId) {
            var ins = listedInstrumentStoreInstance.getInstruments().filter(x => x?.type === "Cash" && x.ticker === this.getFxTicker(ccy))[0];
            insId = ins?.listedInstrumentId;
            this.fxInsMap.set(ccy, insId);
        }
        var rate = this.getFxTicker(ccy).startsWith("USD") ? 1.0 / this.state.latestTicks.get(insId) : this.state.latestTicks.get(insId);

        return !rate || isNaN(rate) ? undefined : rate;
    }

    getFxIns(ccy: string) {
        if (ccy === "USD") //TODO: put base ccy in consts/settings
            return 1.0;
        var insId = this.fxInsMap.get(ccy);
        if (!insId) {
            var ins = listedInstrumentStoreInstance.getInstruments().filter(x => x?.type === "Cash" && x.ticker === this.getFxTicker(ccy))[0];
            insId = ins?.listedInstrumentId;
            this.fxInsMap.set(ccy, insId);
        }
        return insId;
    }

    SubscribeToTickStream(insId: number) {
        userSessionConnection.subscribeToMarketDataTicks(insId);
        SubscribeToTicksById(insId);
    }

    UnSubscribeToTickStream(insId: number) {
        userSessionConnection.unSubscribeToMarketDataTicks(insId);
    }

}

const marketDataLiveStoreInstance = new MarketDataLiveStore(AppDispatcher);
export default marketDataLiveStoreInstance;