import React from 'react';
import FBEmitter from 'fbemitter';
import { DateFixedRelative } from '../../payoff/models/dateFixedRelative';
import FixedRelativeDateInput from '../../inputs/fixedRelativeDateInput';
import AutocallCallDateGenerator from './autocallCallDateGenerator';
import { Frequency } from '../../qwack/frequency';
import { TenorDateRelative } from '../../qwack/tenorDateRelative';
import { TenorDateAbsolute } from '../../qwack/tenorDateAbsolute';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { subRowProps, subCellProps, subTitleProps, subTitleVariant, subValueProps } from './helpers';

export type AutocallDatesDesignerProps = {
    updateStartDate: (date: DateFixedRelative) => void,
    updateEndDate: (date: DateFixedRelative) => void,
    updateCallDates?: (dates: DateFixedRelative[]) => void,
    updateCoupons?: (coupons: number[]) => void,
    initialStartDate: DateFixedRelative,
    initialEndDate: DateFixedRelative,
    initialCallDates?: DateFixedRelative[],
    initialCoupons?: number[],
    disabled: boolean,
}

export type AutocallDatesDesignerState = {
    startDate: DateFixedRelative,
    endDate: DateFixedRelative,
    callDates: DateFixedRelative[],
    coupons: number[],
    datesAreRelative: boolean,
}


export class AutocallDatesDesigner extends React.Component<AutocallDatesDesignerProps, AutocallDatesDesignerState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: AutocallDatesDesignerProps) {
        super(props);
        this.state = {
            callDates: this.props.initialCallDates,
            startDate: this.props.initialStartDate ?? { isRelative: true, relative: { relativeTenor: new Frequency("1m") } as TenorDateRelative } as DateFixedRelative,
            endDate: this.props.initialEndDate ?? { isRelative: true, relative: { relativeTenor: new Frequency("5y") } as TenorDateRelative } as DateFixedRelative,
            coupons: this.props.initialCoupons,
            datesAreRelative: true,
        }
        this.onChangeStartDate = this.onChangeStartDate.bind(this);
        this.onChangeEndDate = this.onChangeEndDate.bind(this);
        this.onChangeCoupons = this.onChangeCoupons.bind(this);
        this.onChangeCallDates = this.onChangeCallDates.bind(this);
        this.onChangeRelAbs = this.onChangeRelAbs.bind(this);
        this.onChangeSingleCoupon = this.onChangeSingleCoupon.bind(this);
    }

    public componentWillUnmount() {

    }

    componentDidMount() {
    }

    public componentDidUpdate() {

    }

    onChangeStartDate(value: DateFixedRelative) {
        this.setState({ startDate: value });
        this.props.updateStartDate(value);
    }

    onChangeEndDate(value: DateFixedRelative) {
        this.setState({ endDate: value });
        this.props.updateEndDate(value);
    }

    onChangeCallDates(value: DateFixedRelative[]) {
        this.setState({ callDates: value });
        this.props.updateCallDates(value);
    }
    onChangeCoupons(coupons: number[]) {
        this.setState({ coupons });
        this.props.updateCoupons(coupons);
    }
    onChangeRelAbs() {
        const { datesAreRelative, startDate, endDate } = this.state;
        startDate.isRelative = !datesAreRelative;
        endDate.isRelative = !datesAreRelative;
        if (!startDate.isRelative && !startDate.absolute)
            startDate.absolute = { absoluteDate: new Date() } as TenorDateAbsolute
        if (!endDate.isRelative && !endDate.absolute)
            endDate.absolute = { absoluteDate: new Date() } as TenorDateAbsolute
        this.setState({ datesAreRelative: !datesAreRelative, startDate: startDate, endDate: endDate });
    }
    onChangeSingleCoupon(val: number) {
        let coupons = [val];
        this.setState({ coupons });
        this.props.updateCoupons(coupons);
    }

    render() {
        const { startDate, endDate, callDates, coupons, datesAreRelative } = this.state;
        let nonCallMode = !this.props.updateCallDates;
        let singleCouponMode = nonCallMode && this.props.updateCoupons !== undefined;
        let coupon = coupons && coupons.length > 0 ? coupons[0] : 0;
        return (
            <Grid item container paddingTop="0.25em">
                <Grid {...subRowProps} >
                    <Grid {...subCellProps} width={"100%"}>
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={subTitleVariant}>Date Type</Typography></Grid>
                        <Grid {...subValueProps}><FormControlLabel
                            control={
                                <Switch
                                    checked={datesAreRelative}
                                    onChange={this.onChangeRelAbs}
                                    name="hasFlatCoupon"
                                    color="primary"
                                    disabled={this.props.disabled}
                                />
                            }
                            label={datesAreRelative ? "Relative" : "Absolute"}
                        />
                        </Grid>
                    </Grid>
                    <Grid {...subCellProps}>
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={subTitleVariant}>Start Date</Typography></Grid>
                        <Grid {...subValueProps} width={130}>
                            <FixedRelativeDateInput
                                toolTip={datesAreRelative ? <Typography variant="subtitle1">Relative to Today</Typography> : null}
                                keyName="AcDesignerStartDate"
                                key="AcDesignerStartDate"
                                onChange={this.onChangeStartDate}
                                initialValue={startDate}
                                placeholder="Start date..."
                                disabled={this.props.disabled}
                                forceFixed={!datesAreRelative}
                                forceRelative={datesAreRelative} />
                        </Grid>
                    </Grid>
                    <Grid {...subCellProps} justifyContent="flex-end">
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={subTitleVariant}>End Date</Typography></Grid>
                        <Grid {...subValueProps} width={130}>
                            <FixedRelativeDateInput
                                toolTip={datesAreRelative ? <Typography variant="subtitle1">Relative to Start Date</Typography> : null}
                                keyName="AcDesignerEndDate"
                                key="AcDesignerEndDate"
                                onChange={this.onChangeEndDate}
                                initialValue={endDate}
                                placeholder="End date..."
                                disabled={this.props.disabled}
                                forceFixed={!datesAreRelative}
                                forceRelative={datesAreRelative} />
                        </Grid>
                    </Grid>
                    {nonCallMode ? null :
                        <Grid {...subCellProps} width="100%">
                            <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={subTitleVariant}>Coupons &amp; Call Dates</Typography></Grid>
                            <Grid {...subValueProps} width="calc(100% - 100px)">
                                <AutocallCallDateGenerator key={"acdGen"} startDate={startDate} endDate={endDate} initialCallDates={callDates} initialCoupons={coupons} updateCallDates={this.onChangeCallDates} updateCoupons={this.onChangeCoupons} disabled={this.props.disabled} />
                            </Grid>
                        </Grid>
                    }
                    {!singleCouponMode ? null :
                        <Grid {...subCellProps} width="100%">
                            <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={subTitleVariant}>Coupon</Typography></Grid>
                            <Grid {...subValueProps}>
                                <ValidatedNumericInput units="%" keyName="acDD-singleCoupon" placeholder="Coupon" style={{width:"120px"}} initialValue={coupon} onChange={(v) => { this.onChangeSingleCoupon(v); return true; }} disabled={this.props.disabled} />
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>)
    }

}
export default AutocallDatesDesigner;