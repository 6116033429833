import * as React from "react";
import { Candle } from "../models/candle";
import userStoreInstance from "../../user/userStore";
import Plot from 'react-plotly.js';
import { Data } from "plotly.js";

export interface StockChartProps {
    readonly data: Candle[];
    readonly height: number;
    readonly dateTimeFormat?: string;
    readonly width: number;
    readonly ratio: number;
    readonly isCandlePlot?: boolean;

}

export class StockChart extends React.Component<StockChartProps> {

    public render() {
        let mainColor = userStoreInstance.GetTheme().color;;
        let chartColorUp = userStoreInstance.GetTheme().chart_color_up;
        let chartColorDown = userStoreInstance.GetTheme().chart_color_down;
        // let gridColor = userStoreInstance.GetTheme().chart_color_grid;
        let indicatorColors = new Map<number, string>();

        indicatorColors.set(0, userStoreInstance.GetTheme().chart_color_indic_0);
        indicatorColors.set(1, userStoreInstance.GetTheme().chart_color_indic_1);
        indicatorColors.set(2, userStoreInstance.GetTheme().chart_color_indic_2);
        indicatorColors.set(3, userStoreInstance.GetTheme().chart_color_indic_3);
        indicatorColors.set(4, userStoreInstance.GetTheme().chart_color_indic_4);
        indicatorColors.set(5, userStoreInstance.GetTheme().chart_color_indic_5);

        const { data } = this.props;

        var chartData = this.props.isCandlePlot ?
            [
                {
                    x: data.map(d => d.time),
                    close: data.map(d => d.close),
                    high: data.map(d => d.high),
                    low: data.map(d => d.low),
                    open: data.map(d => d.open),
                    type: 'candlestick',
                    line: { color: mainColor },
                    increasing: { line: { color: chartColorUp } },
                    decreasing: { line: { color: chartColorDown } },
                    xaxis: 'x',
                    yaxis: 'y'
                }
            ] as Data[] :
            [
                {
                    x: data.map(d => d.time),
                    y: data.map(d => d.close),
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: { color: mainColor },
                }
            ] as Data[];

        return <Plot
            data={chartData}
            layout={{
                plot_bgcolor: userStoreInstance.GetTheme().background_color,
                paper_bgcolor: userStoreInstance.GetTheme().background_color,
                xaxis: {
                    color: userStoreInstance.GetTheme().color,
                    autorange: true,
                    rangeslider: {
                        visible: false
                    }
                },
                yaxis: {
                    color: userStoreInstance.GetTheme().color
                },
                dragmode: "zoom",
            }}
            config={{
                responsive: true,
                displaylogo: false,
                autosizable: true,
                fillFrame: false,
                showAxisDragHandles: true
            }}
            style={{ width: "100%", height: "100%", backgroundColor: "none" }}
            useResizeHandler={true}
        />
    }
}
