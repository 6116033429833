import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import { NewTradeEvent } from "./tradeActions";
import { NewTradeEventStoreState } from "./tradeModels";


class NewTradeEventStore extends FluxStore<NewTradeEventStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatcherHandler(e), () => ({
            events: new Map<number, boolean>(),
        }))
    }

    private onDispatcherHandler(action: Event) {
        if (action instanceof NewTradeEvent) {
            this.state.events.set(action.payload, true);
            this.emitChange();
        }
    }

    getTradeEvent(rfqId: number) {
        return this.state.events.get(rfqId);
    }
}

const newTradeEventStoreInstance = new NewTradeEventStore(AppDispatcher);
export default newTradeEventStoreInstance;