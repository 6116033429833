import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { AlertStoreState } from './alertsModels';
import { AlertUpdateEvent, CloseAlertEvent, LoadAlertsEvent } from './alertsActions';

class AlertStore extends FluxStore<AlertStoreState>{
    constructor(dispatcher: typeof AppDispatcher){
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            alerts : []
        }));
    }
    
    private onDispatchHandler(action : Event){
        if(action instanceof(LoadAlertsEvent)){
            const { payload } = action;
            this.state.alerts = payload;
            this.emitChange();
        }
        else if (action instanceof(CloseAlertEvent)){
            const {payload} = action;
            this.state.alerts = this.state.alerts.filter(a => a.alertId !== payload.alertId);
            this.emitChange();
        }
        else if (action instanceof(AlertUpdateEvent)){
            const {payload} = action;
            if(payload){
                this.state.alerts = this.state.alerts.filter(a => a.alertId !== payload.alertId);
                if(!payload.solvedBy){
                    this.state.alerts.push(payload);    
                }
                this.emitChange();
            }
        }
    }

    getAlerts(){
        return this.state.alerts;
    }
}

const alertStoreInstance = new AlertStore(AppDispatcher);
export default alertStoreInstance;