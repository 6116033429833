import {
    Button,
    Grid,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    CssBaseline,
    Typography,
} from "@mui/material";
import { Form } from "formik";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import Autocomplete from '@mui/material/Autocomplete';
import { Moment } from "moment";
import navStoreInstance from "./navStore";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";

export const OfficialNavForm = (props) => {
    const {
        values: {
            navId,
            fund,
            shareClass,
            value,
            sharesPreSubsReds,
            sharesPostSubsReds,
            navPerShare,
            gavPerShare,
            date,
        },
        errors,
        //touched,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        canDuplicate,
        onDuplicatePressed,
        lastBookedId
        //metaCollapsable,
        //balanceTrade
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    const filterCcyOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue?.toLowerCase();
        var relevant = options?.filter(o => ccyMatchesQuery(o, query))
        return relevant;
    };

    const ccyMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins?.toLowerCase();
        const normalizedQuery = query?.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const onShareClassSelect = (shareClass: string) => {
        setFieldValue("shareClass", shareClass);
    }

    const onBookingEntitySelect = (firm: string) => {
        setFieldValue("fund", firm);
    }

    const onChangeAsOf = (d: Moment) => {
        setFieldValue("date", d.utc().startOf('day').toDate());
    }

    const NullShareClass = "Select Class...";
    const NullFund = "Select Fund...";

    return (
        <Form onSubmit={handleSubmit} className={className}>
            <CssBaseline>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} justifyContent="center" >
                            <Grid container item spacing={2} justifyContent="center" >
                                <Grid item>
                                    <TextField
                                        style={{ width: "100px" }}
                                        variant="outlined"
                                        disabled={true}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="Id"
                                        name="Id"
                                        label="Id"
                                        value={navId ?? ""}
                                        onChange={() => { }}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        id="asOf"
                                        name="asOf"
                                        helperText={errors.date ? errors.date : ""}
                                        error={errors.date !== undefined || Boolean(errors.date)}
                                        label="NAV Date"
                                        value={date}
                                        onChange={(d) => onChangeAsOf(d)} />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            //input: "BasketDesignerAutocompleteInput",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={[NullFund, ...navStoreInstance.getFunds().map(f => f.name)]}
                                        value={fund ?? NullFund}
                                        id="fund"
                                        getOptionLabel={(firm: string) => firm}
                                        style={{ width: 250 }}
                                        filterOptions={filterCcyOptions}
                                        onChange={(e, v) => onBookingEntitySelect(v)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={errors.fund ? errors.fund : ""}
                                            error={errors.fund !== undefined || Boolean(errors.fund)}
                                            label="Fund"
                                            variant="outlined" />} />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            //input: "BasketDesignerAutocompleteInput",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={[NullShareClass, ...navStoreInstance.getShareClasses(fund)]}
                                        value={shareClass ?? NullShareClass}
                                        id="shareClass"
                                        getOptionLabel={(firm: string) => firm}
                                        style={{ width: 150 }}
                                        filterOptions={filterCcyOptions}
                                        onChange={(e, v) => onShareClassSelect(v)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={errors.shareClass ? errors.shareClass : ""}
                                            error={errors.shareClass !== undefined || Boolean(errors.shareClass)}
                                            label="Share Class"
                                            variant="outlined" />} />
                                </Grid>
                            </Grid>
                            <Grid container item spacing={2} justifyContent="center" >
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="value"
                                        name="value"
                                        helperText={errors.value ? errors.value : ""}
                                        error={Boolean(errors.value)}
                                        label="Total NAV"
                                        value={value ?? ""}
                                        onChange={change.bind(null, "value")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="navPerShare"
                                        name="navPerShare"
                                        helperText={errors.navPerShare ? errors.navPerShare : ""}
                                        error={Boolean(errors.navPerShare)}
                                        label="NAV/Share"
                                        value={navPerShare ?? ""}
                                        onChange={change.bind(null, "navPerShare")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="gavPerShare"
                                        name="gavPerShare"
                                        helperText={errors.gavPerShare ? errors.gavPerShare : ""}
                                        error={Boolean(errors.gavPerShare)}
                                        label="GAV/Share"
                                        value={gavPerShare ?? ""}
                                        onChange={change.bind(null, "gavPerShare")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="sharesPreSubsReds"
                                        name="sharesPreSubsReds"
                                        helperText={errors.sharesPreSubsReds ? errors.sharesPreSubsReds : ""}
                                        error={Boolean(errors.sharesPreSubsReds)}
                                        label="Shares (pre)"
                                        value={sharesPreSubsReds ?? ""}
                                        onChange={change.bind(null, "sharesPreSubsReds")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="sharesPostSubsReds"
                                        name="sharesPostSubsReds"
                                        helperText={errors.sharesPostSubsReds ? errors.sharesPostSubsReds : ""}
                                        error={Boolean(errors.sharesPostSubsReds)}
                                        label="Shares (post)"
                                        value={sharesPostSubsReds ?? ""}
                                        onChange={change.bind(null, "sharesPostSubsReds")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>

                            </Grid>
                            <GridBreak />
                            <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="submit"
                                    variant="outlined"
                                    disabled={!isValid || canDuplicate}
                                    endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="reset"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Revert</Button></Grid>
                                {canDuplicate ? <Grid item><Button
                                    className="PltfmButtonLite"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Duplicate</Button></Grid> : null}
                            </Grid>
                            {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Typography variant="subtitle2">Last booked id {lastBookedId}</Typography></Grid>
                            </Grid> : null}
                        </Grid>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CssBaseline>
        </Form >
    );
};