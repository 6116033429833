import {
    Typography,
    MenuItem,
    ThemeProvider,
    StyledEngineProvider,
    Grid,
    Link,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { Component, ReactNode } from 'react';
import { getFormTheme } from '../inputs/formCommon';
import userStoreInstance from '../user/userStore';
import { UpdatePreference } from './layoutActions';
import { SetDateFormat, SetDefaultGridDensity, SetEcbotDisplay, SetFallbackPriceWidth, SetLiveThrottleMs } from '../user/userActions';
import dataCacheInstance from '../cache/dataCache';
import { FlushAllCaches } from '../nav/navActions';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { GridDensity } from '@mui/x-data-grid-pro';
import { NumberFormatCustom } from '../inputs/numberFormatInput';

export interface UserSettingsControlProps {
    onClose: () => void
}

interface UserSettingsControlState {
    showAreYouSure: boolean;
    showAreYouSureFlushAll: boolean;
    hover: boolean;
    actionInProgress: boolean;
    liveThrottleMs: number;
    fallbackWidth: number;
}

class UserSettingsControl extends Component<UserSettingsControlProps, UserSettingsControlState>{
    constructor(props: UserSettingsControlProps) {
        super(props);
        this.state = {
            showAreYouSure: false,
            showAreYouSureFlushAll: false,
            hover: false,
            actionInProgress: false,
            liveThrottleMs: userStoreInstance.GetLiveThrottleMs(),
            fallbackWidth: userStoreInstance.GetFallbackPriceWidth()
        }
        this.onChangeTheme = this.onChangeTheme.bind(this);
        this.onChangeDateFormat = this.onChangeDateFormat.bind(this);
        this.onChangeGridDensity = this.onChangeGridDensity.bind(this);
        this.flushServerCaches = this.flushServerCaches.bind(this);
    }

    componentDidMount() {

    }
    componentWillUnmount() {

    }

    onChangeTheme(e: SelectChangeEvent<string>, child: ReactNode) {
        userStoreInstance.SetTheme(e.target.value);
        UpdatePreference("Theme", e.target.value);
        this.props.onClose()
    }

    onChangeDateFormat(e: SelectChangeEvent<string>, child: ReactNode) {
        SetDateFormat(e.target.value);
        UpdatePreference("DateFormat", e.target.value);
        this.props.onClose()
    }

    onChangeGridDensity(e: SelectChangeEvent<string>, child: ReactNode) {
        SetDefaultGridDensity(e.target.value as GridDensity);
        UpdatePreference("GridDensity", e.target.value);
        this.props.onClose();
    }

    onChangeThrottleMs() {
        const { liveThrottleMs } = this.state;
        SetLiveThrottleMs(liveThrottleMs);
        UpdatePreference("LiveThrottleMs", liveThrottleMs.toString());
        this.props.onClose();
    }

    onChangeFallbackWidth() {
        const { fallbackWidth } = this.state;
        SetFallbackPriceWidth(fallbackWidth);
        UpdatePreference("FallbackPriceWidth", fallbackWidth.toString());
        this.props.onClose();
    }

    flushLocalCache() {
        this.setState({ actionInProgress: true });
        dataCacheInstance.deleteDB();
        this.setState({ showAreYouSure: false, actionInProgress: false });
        this.props.onClose();
    }

    async flushServerCaches() {
        this.setState({ actionInProgress: true });
        await FlushAllCaches();
        this.setState({ showAreYouSureFlushAll: false, actionInProgress: false });
        this.props.onClose();
    }

    render() {
        const { showAreYouSure, showAreYouSureFlushAll, actionInProgress, liveThrottleMs, fallbackWidth } = this.state;
        const theme = userStoreInstance.GetSettings().themeName;
        const dateFormat = userStoreInstance.GetDateFormat();
        const gridDensity = userStoreInstance.GetGridDensity();
        const showCbotFractions = userStoreInstance.GetFractionalEcbotDisplay();

        return (
            <div className="UserSettingsControl" style={{ height: "100%" }} onMouseOver={() => { this.setState({ hover: true }) }} onMouseLeave={() => { this.setState({ hover: false }) }}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} direction="column" style={{ height: "100%" }} >
                            <Grid item>
                                <Typography style={{ width: "100%", textAlign: "center", borderBottomColor: userStoreInstance.GetTheme().border_color, borderBottom: "1px solid", marginBottom: "0.5em" }} variant="h4">
                                    Preferences
                                </Typography>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    name="Theme"
                                    label="Theme"
                                    value={theme}
                                    onChange={this.onChangeTheme}>
                                    {userStoreInstance.Themes.map(o => <MenuItem key={o} value={o}>{o}</MenuItem >)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    name="Date Format"
                                    label="Date Format"
                                    value={dateFormat}
                                    onChange={this.onChangeDateFormat}>
                                    {userStoreInstance.DateFormats.map(o => <MenuItem key={o} value={o}>{o}</MenuItem >)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "150px" }}
                                    name="Grid Density"
                                    label="Grid Density"
                                    value={gridDensity}
                                    onChange={this.onChangeGridDensity}>
                                    <MenuItem key="compact" value="compact">Compact</MenuItem >
                                    <MenuItem key="standard" value="standard">Standard</MenuItem>
                                    <MenuItem key="comfortable" value="comfortable">Comfortable</MenuItem >
                                </WrappedSelect>
                            </Grid>
                            <Grid item container direction="row" spacing={1}>
                                <Grid item><TextField
                                        variant="outlined"
                                        style={{width:"150px"}}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="LiveUpdateDelay"
                                        name="LiveUpdateDelay"
                                        size="small"
                                        helperText={"Grid update speed"}
                                        label="Live Throttle (ms)"
                                        value={liveThrottleMs ?? ""}
                                        onChange={(e) => this.setState({ liveThrottleMs: Number(e.target.value) })}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                                </Grid>
                                <Grid item><Button variant="outlined" onClick={() => this.onChangeThrottleMs()}>Save</Button></Grid>
                            </Grid>
                            <Grid item container direction="row" spacing={1}>
                                <Grid item><TextField
                                        variant="outlined"
                                        style={{width:"150px"}}
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="FallbackWidth"
                                        name="FallbackWidth"
                                        size="small"
                                        helperText={"Bid/Ask width to trigger fallback"}
                                        label="Fallback Width"
                                        value={fallbackWidth ?? ""}
                                        onChange={(e) => this.setState({ fallbackWidth: Number(e.target.value) })}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                                </Grid>
                                <Grid item><Button variant="outlined" onClick={() => this.onChangeFallbackWidth()}>Save</Button></Grid>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "200px" }}
                                    name="ECBOT Display"
                                    label="ECBOT Display"
                                    value={showCbotFractions.toString()}
                                    onChange={(v)=>SetEcbotDisplay(v.target.value==="true")}>
                                    <MenuItem key="ShowFractions" value="true">Show Fractions</MenuItem >
                                    <MenuItem key="ShowDecimals" value="false">Show Decimals</MenuItem>
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => this.setState({ showAreYouSure: true })}>Reload Static</Button>
                                <Dialog open={showAreYouSure} onClose={() => this.setState({ showAreYouSure: false })}  >
                                    <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Are you sure?</DialogTitle>
                                    <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                                        <DialogContentText id="alert-dialog-description" color={userStoreInstance.GetTheme().color}>
                                            Are you sure you want reload all static data?
                                            (Please close all other Pltfm tabs before attempting this)
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                                        <Button variant="outlined" className="PltfmButtonLite" disabled={actionInProgress} onClick={() => this.flushLocalCache()}>Reload All</Button>
                                        <Button variant="outlined" className="PltfmButtonLite" disabled={actionInProgress} onClick={() => this.setState({ showAreYouSure: false })} autoFocus>Cancel</Button>
                                    </DialogActions>
                                </Dialog >
                            </Grid>
                            {userStoreInstance.isUserInRole("SystemAdmin") ? <Grid item>
                                <Button variant="outlined" onClick={() => this.setState({ showAreYouSureFlushAll: true })}>Flush Caches</Button>
                                <Dialog open={showAreYouSureFlushAll} onClose={() => this.setState({ showAreYouSureFlushAll: false })}  >
                                    <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Are you sure?</DialogTitle>
                                    <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                                        <DialogContentText id="alert-dialog-description" color={userStoreInstance.GetTheme().color}>
                                            Are you sure you want flush all server caches?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                                        <Button variant="outlined" className="PltfmButtonLite" disabled={actionInProgress} onClick={this.flushServerCaches}>Flush Caches</Button>
                                        <Button variant="outlined" className="PltfmButtonLite" disabled={actionInProgress} onClick={() => this.setState({ showAreYouSureFlushAll: false })} autoFocus>Cancel</Button>
                                    </DialogActions>
                                </Dialog >
                            </Grid> : null}
                        </Grid>
                    </ThemeProvider>
                </StyledEngineProvider>
                <div style={{ bottom: "0%", display: "flex", flexDirection: "column", transform: "translate(0%, -70%)" }}>
                    <Typography style={{ width: "100%", textAlign: "center", borderColor: userStoreInstance.GetTheme().border_color, borderBottom: "1px solid", marginBottom: "0.5em" }} variant="h4">
                        Links
                    </Typography>
                    <Link align="center" color="inherit" href={`https://pltfm.blob.core.windows.net/exceladdin/ExcelAddInDeploy.zip?verz=${(new Date()).valueOf()}`}>Download Excel Add-in</Link>
                    <Link align="center" color="inherit" href={`https://accelerator.galena.pltfm.app/`} target="_blank">Download Accelerator</Link>
                </div>
            </div>
        );
    }
}

export default UserSettingsControl;