import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import AssetsStore from '../../assets/assetsStore';
import { AssetsState, AssetGroup, Asset } from '../../assets/assetsModels';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { Payoff, Period } from '../../payoff/payoffModels';
import payoffStoreInstance from '../../payoff/payoffStore';
import { BondPlusCall, ReverseConvertible, ReverseReverseConvertible, Swap, UnpricedAverage } from '../../globalConstants';
import AutocallPanel from './autocallPanel';
import { NullAssetClass, NullPayoff } from './productDesigner';
import { Autocall } from '../../payoff/models/autoCall';
import BondPlusCallPanel from './bondPlusCallPanel';
import { OptionStructure } from '../../payoff/models/optionStructure';
import ReverseConvertiblePanel from './reverseConvertiblePanel';
import ReverseReverseConvertiblePanel from './reverseReverseConvertiblePanel';
import { Rfq, RfqResponse } from '../../rfq/rfq';
import { CommodityVanillaPayoff, ProductPayoffType, ProductSpec } from '../../product/productModels';
import rfqConnection from '../../rfq/rfqConnection';
import userStoreInstance from '../../user/userStore';
import { validateAutocall } from './validation/validateAutocall';
import { validateBondPlusCall } from './validation/validateBondPlusCall';
import { validateReverseConvertible } from './validation/validateReverseConvertible';
import { validateReverseReverseConvertible } from './validation/validateReverseReverseConvertible';
import { Autocomplete, Button, CircularProgress, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Firm } from '../../user/userModel';
import { SwapEditor } from './payoffForms/swapEditor';
import { FormSection } from '../../inputs/formSection';
import { ThemeProvider } from '@mui/styles';
import { getFormTheme } from '../../inputs/formCommon';
import _ from 'lodash';
import { WrappedSelect } from '../../inputs/wrappedSelect';
import { GoodUntil, OrderType } from '../../orders/orderModels';
import { NumberFormatCustom } from '../../inputs/numberFormatInput';
import { WrappedDateTimePicker } from '../../inputs/wrappedDateTimePicker';
import { Frequency } from '../../qwack/frequency';
import { RfqSummary } from '../../rfq/rfqModels';
import { WrappedDatePicker } from '../../inputs/wrappedDatePicker';
import { UnpricedAverageEditor } from './payoffForms/unpricedAverageEditor';

export type ProductDesignerProps = {
    onClose?: () => void,
    counterpartyId: string,
    onSubmitRfq?: (rfqResponse: RfqResponse, productSpec: ProductSpec) => void
    onChangeHeight?: (height: number) => void,
    existingSpec?: ProductSpec,
    existingRfqSummary?: RfqSummary,
    disabled?: boolean,
    onCancelEdit?: () => void,
    dashboardMode?: boolean;
    editMode?: boolean;
    clientFirm?: Firm;
    extraKey?: string;
}

export type ProductDesignerState = {
    products: AssetsState;
    allAssets: Asset[];
    allAssetGroups: AssetGroup[];
    payoffs: { [category: string]: Payoff[] },
    periods: Period[],
    selectedPayoffType: string | undefined,
    selectedPayoff: Payoff | undefined,
    readyToCreate: boolean;
    requested: boolean;
    autoCallState: Autocall;
    optionStructureState: OptionStructure,
    commodityState: CommodityVanillaPayoff,
    closing: boolean,
    mainRef: RefObject<HTMLDivElement>,
    height: number,
    selectedFirm: Firm | undefined;
    rfqSize: number;

    selectedPortfolio: string;
    selectedOrderType: OrderType;
    rfqLimitLevel: number;
    rfqStopLevel: number;
    rfqFixingIndexId: number;
    rfqGoodUntil: GoodUntil;
    rfqGoodUntilTime: Date;
}

export class StructuredProductDesigner extends React.Component<ProductDesignerProps, ProductDesignerState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: ProductDesignerProps) {
        super(props);

        let acState = props.existingSpec?.autocall ?? {} as Autocall;
        let osState = props.existingSpec?.optionStructure ?? {} as OptionStructure;
        let cmState = props.existingSpec?.commodityVanilla ?? {} as CommodityVanillaPayoff;
        let payoff = payoffStoreInstance.getPayoffById(props.existingSpec?.payoffId) ?? undefined;

        var selectedPortfolio: string;

        if (props.existingRfqSummary?.metaData) {
            selectedPortfolio = props.existingRfqSummary.metaData["Portfolio"]
        }

        this.state = {
            products: AssetsStore.getState(),
            allAssets: [],
            allAssetGroups: [],
            payoffs: {},
            periods: [],
            selectedPayoff: payoff,
            readyToCreate: false,
            requested: false,
            autoCallState: acState,
            optionStructureState: osState,
            commodityState: cmState,
            closing: false,
            mainRef: React.createRef(),
            height: 0,
            selectedFirm: this.props.clientFirm,
            selectedPayoffType: payoff?.category,
            rfqSize: props.existingRfqSummary?.size,
            selectedPortfolio: selectedPortfolio,
            selectedOrderType: props.existingRfqSummary?.orderType ?? OrderType.Limit,
            rfqLimitLevel: props.existingRfqSummary?.limitLevel,
            rfqStopLevel: props.existingRfqSummary?.stopLevel,
            rfqFixingIndexId: props.existingRfqSummary?.fixingIndex,
            rfqGoodUntil: props.existingRfqSummary?.timeInForce ?? GoodUntil.GoodUntilCancelled,
            rfqGoodUntilTime: props.existingRfqSummary?.goodUntil,
        }

        this.onClickRFQ = this.onClickRFQ.bind(this);
        this.onPayoffChange = this.onPayoffChange.bind(this);
        this.onClose = this.onClose.bind(this);

    }

    public componentDidUpdate() {
        if (this.props.onChangeHeight) {
            let newHeight = this.state.mainRef.current.clientHeight;
            if (newHeight !== this.state.height) {
                this.props.onChangeHeight(newHeight);
                this.setState({ height: newHeight });
            }
        }
    }

    private onProductsChange = () => {
        var products = AssetsStore.getState();
        products.AssetInfos = [NullAssetClass, ...products.AssetInfos];

        this.setState({ products: products });
    }

    public componentWillUnmount() {
        if (this.eventSubscriptionProducts !== undefined) {
            this.eventSubscriptionProducts.remove();
        }
    }

    async componentDidMount() {
        this.eventSubscriptionProducts = AssetsStore.addChangeListener(this.onProductsChange);
        if (this.state.products && this.state.products.AssetInfos.length > 0) {
            const { products } = this.state;
            let payoffs = _.groupBy(payoffStoreInstance.getPayoffs(), x => x.category)
            let selectedPayoffType = "Commodities"; // Object.keys(payoffs)[0];
            let periods = payoffStoreInstance.getPeriods(
                products.AssetInfos[0].assetGroups[0].assets[0].assetId,
                products.AssetInfos[0].assetGroups[0].assetGroupId,
                products.AssetInfos[0].assetClassId,
                payoffs[selectedPayoffType] ? payoffs[selectedPayoffType][0].name : "");

            let allAssets = new Array<Asset>();
            let allAssetGroups = new Array<AssetGroup>();
            products.AssetInfos.forEach(ai => {
                ai.assetGroups.forEach(ag => {
                    allAssetGroups.push(ag);
                    ag.assets.forEach(a => {
                        a.assetClass = ai.name;
                        a.assetGroup = ag.name;
                        allAssets.push(a);
                    });
                });
            });

            let selectedPayoff = payoffs[selectedPayoffType] ? payoffs[selectedPayoffType][0] : undefined;
            if (this.props.existingSpec) {
                switch (this.props.existingSpec.payoffType) {
                    case ProductPayoffType.Autocall:
                        selectedPayoffType = "Structured"
                        selectedPayoff = payoffs[selectedPayoffType].filter(p => p.name === "Autocall")[0];
                        break;
                    case ProductPayoffType.OptionStructure:
                        selectedPayoffType = "Structured"
                        selectedPayoff = payoffs[selectedPayoffType].filter(p => p.name === this.props.existingSpec.payoffSubType)[0];
                        break;
                }
            }
            this.setState(
                {
                    allAssets,
                    allAssetGroups,
                    selectedPayoff,
                    payoffs,
                    periods,
                    selectedPayoffType
                });

        }
    }

    onPayoffChange = (e: any, v: Payoff | string) => {
        var payoff = v as Payoff;
        if (payoff)
            this.setState({ selectedPayoff: payoff === NullPayoff ? undefined : payoff });

    }

    readyToCreate = (): boolean => {
        let readyToCreate = this.state.selectedPayoff !== undefined && this.validate();
        return readyToCreate;
    }

    isValidOrder() {
        const { selectedOrderType, selectedPayoff, rfqLimitLevel, rfqStopLevel, rfqFixingIndexId } = this.state;
        if (selectedPayoff.name === "Swap") {
            switch (selectedOrderType) {
                case OrderType.Limit:
                    return rfqLimitLevel > 0;
                case OrderType.Stop:
                    return rfqStopLevel > 0;
                case OrderType.StopLimit:
                    return rfqStopLevel > 0 && rfqLimitLevel > 0;
                case OrderType.FixingOrder:
                    return Boolean(rfqFixingIndexId);
                default:
                    return true;
            }
        }
        else {//unpriced average
            switch (selectedOrderType) {
                case OrderType.Limit:
                    return rfqLimitLevel !== undefined;
                case OrderType.Stop:
                    return rfqStopLevel !== undefined;
                case OrderType.StopLimit:
                    return rfqStopLevel !== undefined && rfqLimitLevel !== undefined;
                case OrderType.FixingOrder:
                    return Boolean(rfqFixingIndexId);
                default:
                    return true;
            }
        }
    }

    isValidSettlement() {
        const { commodityState } = this.state;
        return commodityState?.legs?.length > 0 && (Boolean(commodityState.legs[0].settlement?.date) || Frequency.TryParse(commodityState.legs[0].settlement?.lag) !== undefined)
    }

    validate(): boolean {
        const { selectedPayoff, autoCallState, optionStructureState, commodityState, selectedFirm, rfqSize, selectedPortfolio } = this.state;
        var isSalesUser = userStoreInstance.GetUserInfo().name === "Sales User";
        if (isSalesUser && !selectedFirm)
            return false;
        switch (selectedPayoff.name) {
            case "Autocall":
                return validateAutocall(autoCallState);
            case BondPlusCall:
                return validateBondPlusCall(optionStructureState);
            case ReverseConvertible:
                return validateReverseConvertible(optionStructureState);
            case ReverseReverseConvertible:
                return validateReverseReverseConvertible(optionStructureState);
            case "Swap":
                return commodityState?.legs?.length > 0 && Boolean(commodityState.legs[0].assetId) && Boolean(commodityState.legs[0].period) && rfqSize !== 0 && Boolean(selectedPortfolio) && this.isValidOrder() && this.isValidSettlement();
            case "UnpricedAverage":
                return commodityState?.legs?.length === 2 && Boolean(commodityState.legs[0].assetId) && Boolean(commodityState.legs[0].period) && Boolean(commodityState.legs[1].period) && rfqSize !== 0 && Boolean(selectedPortfolio) && this.isValidOrder() && this.isValidSettlement();
            default:
                return true;
        }
    }

    async onClickRFQ() {
        const { selectedPayoff, autoCallState, optionStructureState, commodityState, selectedFirm, rfqFixingIndexId, selectedOrderType, rfqGoodUntil, rfqGoodUntilTime, rfqLimitLevel, rfqSize, rfqStopLevel, selectedPortfolio } = this.state;
        this.setState({ requested: true });
        let product = { payoffSubType: selectedPayoff.name, payoffId: selectedPayoff.payoffId } as ProductSpec;
        switch (selectedPayoff.name) {
            case "Autocall":
                product.autocall = autoCallState;
                product.payoffType = ProductPayoffType.Autocall;
                break;
            case BondPlusCall:
                product.optionStructure = optionStructureState;
                product.payoffType = ProductPayoffType.OptionStructure;
                product.payoffSubType = selectedPayoff.name;
                break;
            case ReverseConvertible:
                product.optionStructure = optionStructureState;
                product.payoffType = ProductPayoffType.OptionStructure;
                product.payoffSubType = selectedPayoff.name;
                break;
            case ReverseReverseConvertible:
                product.optionStructure = optionStructureState;
                product.payoffType = ProductPayoffType.OptionStructure;
                product.payoffSubType = selectedPayoff.name;
                break;
            case "Swap":
                product.commodityVanilla = commodityState;
                product.payoffType = ProductPayoffType.CommodityVanilla;
                product.payoffSubType = selectedPayoff.name;
                break;
            case "UnpricedAverage":
                product.commodityVanilla = commodityState;
                product.payoffType = ProductPayoffType.CommodityVanilla;
                product.payoffSubType = selectedPayoff.name;
                break;
            default:
                this.setState({ requested: false });
                return;
        }
        let rfq = {
            fixingIndex: rfqFixingIndexId,
            limitLevel: rfqLimitLevel,
            stopLevel: rfqStopLevel,
            size: rfqSize,
            orderType: selectedOrderType,
            timeInForce: rfqGoodUntil,
            goodUntil: rfqGoodUntilTime
        } as Rfq

        if (selectedPortfolio) {
            rfq.metaData = { Portfolio: selectedPortfolio };
        }

        if (selectedFirm)
            rfq.firmId = selectedFirm.firmId;

        let rfqResponse = await rfqConnection.createRfqWithProduct(rfq, product);
        this.setState({ requested: false });

        if (this.props.onSubmitRfq) {
            this.props.onSubmitRfq(rfqResponse, product);
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    }



    renderPayoffPanel() {
        const { selectedPayoff, payoffs, selectedPayoffType } = this.state;
        //var isSalesUser = userStoreInstance.IsInternalUser();
        const isSalesUser = userStoreInstance.UserHasRole("HybridSales")
        var firms = userStoreInstance.GetClientFirms().map(f => f.name);
        var selectedFirm = isSalesUser || this.props.dashboardMode ? (this.state.selectedFirm ? this.state.selectedFirm.name : "Select Firm...") : null;

        if (!payoffs || Object.keys(payoffs).length === 0)
            return <div></div>

        return (<FormSection key="payofffpanell" justifyContent="space-between">
            {this.props.disabled ?
                <TextField
                    label="Payoff"
                    size='small'
                    value={selectedPayoff?.name}
                    InputProps={{ readOnly: this.props.disabled }}
                    variant="outlined" /> :
                <Grid key="kkjh1" item container direction="row" spacing={1}>
                    <Grid item>
                        <WrappedSelect label="Type" id="payofType" key="payoffType" value={selectedPayoffType ?? "Commodities" ?? null} onChange={(e) => this.setState({ selectedPayoffType: e.target.value, selectedPayoff: payoffs[e.target.value][0] })}>
                            {Object.keys(payoffs).map(k => <MenuItem key={k} value={k}>{k}</MenuItem>)}
                        </WrappedSelect>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            classes={{
                                inputRoot: "BasketDesignerAutocomplete",
                                popupIndicator: "BasketDesignerAutocompleteIcon",
                                clearIndicator: "BasketDesignerAutocompleteIcon",
                                popper: "AutocompleteGroupLabel",
                            }}
                            autoComplete
                            size="small"
                            options={payoffs[selectedPayoffType]}
                            value={selectedPayoff}
                            key="payoffSelector"
                            id="payoffSelector"
                            getOptionLabel={(p: Payoff) => p.name}
                            style={{ width: "275px" }}
                            filterOptions={this.filterPayoffOptions}
                            onChange={this.onPayoffChange}
                            renderInput={(params) => <TextField {...params}
                                label="Payoff"
                                variant="outlined" />} />
                    </Grid>
                </Grid>}
            {isSalesUser ? (this.props.disabled ?
                <TextField
                    size='small'
                    label="Client Firm"
                    value={selectedFirm}
                    InputProps={{ readOnly: this.props.disabled }}
                    variant="outlined" /> :
                <StringTypeDropDown
                    size='small'
                    possibles={["Select Firm...", ...firms]}
                    selected={selectedFirm}
                    onChange={(e) => this.setState({ selectedFirm: userStoreInstance.GetClientFirms().filter(f => f.name === e)[0] })} disabled={this.props.disabled} />)
                : null}
        </FormSection>)
    }

    renderRfqPropsPanel() {
        const { allAssets, selectedPayoff, commodityState, selectedPortfolio, selectedOrderType, rfqFixingIndexId, rfqGoodUntil, rfqGoodUntilTime, rfqLimitLevel, rfqStopLevel } = this.state;
        const { disabled } = this.props;
        if (!selectedPayoff)
            return <div></div>

        const portfolios = ["Portfolio A", "Portfolio B"];
        const allowedOrderTypes = [OrderType.FixingOrder, OrderType.Limit, OrderType.Stop, OrderType.StopLimit];
        const isLimit = [OrderType.Limit, OrderType.StopLimit].includes(selectedOrderType);
        const isStop = [OrderType.Stop, OrderType.StopLimit].includes(selectedOrderType);
        const asset = commodityState?.legs?.length > 0 && commodityState.legs[0]?.assetId ? allAssets.filter(a => a.assetId === commodityState.legs[0].assetId)[0] : undefined;
        const titleProps = { width: "110px", borderRight: "1px solid", borderColor: userStoreInstance.GetTheme().border_color };

        return (<FormSection key="ccv2">
            {null}
            <Grid key="renderRfqPropsPanel" item container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={1}>
                <Grid item {...titleProps} ><Typography align='center' variant="subtitle1">Portfolio</Typography></Grid>
                <Grid item>
                    <WrappedSelect
                        disabled={disabled}
                        label="Portfolio"
                        id="portfolio"
                        key="portfolio"
                        value={selectedPortfolio ?? "Select Portfolio..."}
                        onChange={(e) => this.setState({ selectedPortfolio: e.target.value })}>
                        <MenuItem disabled key={"Select Portfolio..."} value={"Select Portfolio..."}>{"Select Portfolio..."}</MenuItem>
                        {portfolios.map(k => <MenuItem key={k} value={k}>{k}</MenuItem>)}
                    </WrappedSelect>
                </Grid>
            </Grid>
            <Grid item container direction="row" spacing={1}>
                <Grid item {...titleProps} ><Typography align='center' variant="subtitle1">Order Type</Typography></Grid>
                <Grid item>
                    <WrappedSelect
                        disabled={disabled}
                        id={"orderType"}
                        name="orderType"
                        label="Order Type"
                        style={{ width: "130px" }}
                        value={selectedOrderType === undefined ? '' : OrderType[selectedOrderType]}
                        onChange={(e, c) => {
                            var asEnum: OrderType = OrderType[e.target.value as keyof typeof OrderType];
                            this.setState({ selectedOrderType: asEnum });
                        }}>
                        {allowedOrderTypes.map(a => OrderType[a]).map((v, ixx) => <MenuItem key={"oRtype" + ixx} value={v}>{v}</MenuItem>)}
                    </WrappedSelect>
                </Grid>
                {isLimit && <Grid item>
                    <TextField
                        style={{ width: "130px" }}
                        variant="outlined"
                        classes={{ root: "ListedInstrumentEditorFormField" }}
                        id="limit"
                        name="limit"
                        size="small"
                        label={"Limit Level"}
                        value={rfqLimitLevel ?? ""}
                        error={rfqLimitLevel === undefined}
                        onFocus={event => {
                            event.target.select();
                        }}
                        onChange={(e) => {
                            const x = Number(e.target.value);
                            this.setState({ rfqLimitLevel: x });
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: disabled,
                            classes: { input: "ListedInstrumentEditorFormFieldInner" },
                            inputComponent: NumberFormatCustom as any,
                        }} />
                </Grid>}
                {isStop && <Grid item>
                    <TextField
                        style={{ width: "130px" }}
                        variant="outlined"
                        classes={{ root: "ListedInstrumentEditorFormField" }}
                        id="stop"
                        name="stop"
                        size="small"
                        label={"Stop Level"}
                        value={rfqStopLevel ?? ""}
                        error={rfqStopLevel === undefined}
                        onFocus={event => {
                            event.target.select();
                        }}
                        onChange={(e) => {
                            const x = Number(e.target.value);
                            this.setState({ rfqStopLevel: x });
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: disabled,
                            classes: { input: "ListedInstrumentEditorFormFieldInner" },
                            inputComponent: NumberFormatCustom as any,
                        }} />
                </Grid>}
                {selectedOrderType === OrderType.FixingOrder && asset && <Grid item>
                    <WrappedSelect
                        disabled={disabled}
                        label="Fixing Index"
                        placeholder='Select Index...'
                        value={rfqFixingIndexId ?? -1}
                        onChange={(e => this.setState({ rfqFixingIndexId: Number(e.target.value) }))}>
                        <MenuItem key={"selectCF"} disabled value={-1}>{'Select Index...'}</MenuItem>
                        {asset.fixingIndices.map(q => <MenuItem key={q.name} value={q.fixingIndexId}>{q.name}</MenuItem>)}
                    </WrappedSelect>
                </Grid>}
                {selectedOrderType === OrderType.FixingOrder && <Grid item>
                    <WrappedDatePicker
                        readOnly={disabled}
                        style={{ width: "120px" }}
                        id="fixingDate"
                        name="fixingDate"
                        disablePast
                        error={!Boolean(rfqGoodUntilTime)}
                        label="Fixing Date"
                        value={rfqGoodUntilTime}
                        onChange={(d) => this.setState({ rfqGoodUntilTime: d?.toDate() })} />
                </Grid>}
            </Grid>
            {selectedOrderType !== OrderType.FixingOrder && <Grid item container direction="row" spacing={1}>
                <Grid item {...titleProps} ><Typography align='center' variant="subtitle1">Validity</Typography></Grid>
                <Grid item>
                    <WrappedSelect
                        disabled={disabled}
                        id="Good"
                        name="Good"
                        label="Time In Force"
                        style={{ width: "200px" }}
                        value={rfqGoodUntil === undefined ? '' : GoodUntil[rfqGoodUntil]}
                        onChange={(e, c) => {
                            var asEnum: GoodUntil = GoodUntil[e.target.value as keyof typeof GoodUntil];
                            this.setState({ rfqGoodUntil: asEnum });
                        }}>
                        {Object.keys(GoodUntil)
                            .filter(x => isNaN(Number(x)))
                            .map((v, ixx) => <MenuItem key={"tif" + ixx} value={v}>{v}</MenuItem>)}
                    </WrappedSelect>
                </Grid>
                {rfqGoodUntil === GoodUntil.Time && <Grid item>
                    <WrappedDateTimePicker
                        readOnly={disabled}
                        id="gooduntil"
                        name="gooduntil"
                        disablePast
                        error={!Boolean(rfqGoodUntilTime)}
                        label="Good Until (UTC)"
                        value={rfqGoodUntilTime}
                        onChange={(d) => this.setState({ rfqGoodUntilTime: d?.toDate() })} />
                </Grid>}
            </Grid>}
        </Grid></FormSection>)
    }


    filterPayoffOptions = (options: Payoff[], state: any): Payoff[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.payoffMatchesQuery(o, query))
        return relevant;
    };

    payoffMatchesQuery = (payoff: Payoff, query: string): boolean => {
        const normalizedTitle = payoff.name.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    renderCreateRfqButton() {
        if (this.props.onCancelEdit) {
            return (<Grid item container direction="row" justifyContent="space-around" alignItems="space-around" width="100%">
                <Grid item><Button className="MuiButton-Outlined PltfmButtonLite" title="Submit Change" disabled={this.state.requested || !this.readyToCreate()} onClick={this.onClickRFQ}>Submit Change</Button></Grid>
                <Grid item><Button className="MuiButton-Outlined PltfmButtonLite" title="Cancel Edit" onClick={this.props.onCancelEdit}>Cancel Edit</Button></Grid>
            </Grid>)
        }
        else {
            return (<Grid item container direction="row" justifyContent="center" alignItems="center" width="100%" paddingTop="0.5em">
                <Grid item paddingBottom="0.5em" >
                    <Button className="MuiButton-Outlined PltfmButtonLite" title="Create RFQ" disabled={this.state.requested || !this.readyToCreate()} onClick={this.onClickRFQ}>Create RFQ</Button>
                </Grid>
            </Grid>)
        }

    }

    renderPayoffDetail() {
        if (this.state.selectedPayoff) {
            switch (this.state.selectedPayoff.name) {
                case "Autocall":
                    return (<AutocallPanel
                        key={this.props.extraKey + "acPanel"}
                        definitionUpdate={(acState) => { this.setState({ autoCallState: acState }) }}
                        allAssets={this.state.allAssets}
                        initialAutocallDefinition={this.state.autoCallState}
                        disabled={this.props.disabled}
                        updateStatus={(ok) => this.setState({ readyToCreate: ok })}
                        extraKey={this.props.extraKey}
                    />);
                case BondPlusCall:
                    return (<BondPlusCallPanel
                        key={this.props.extraKey + "bcPanel"}
                        definitionUpdate={(osState) => { this.setState({ optionStructureState: osState }) }}
                        allAssets={this.state.allAssets}
                        initialDefinition={this.state.optionStructureState}
                        disabled={this.props.disabled}
                        extraKey={this.props.extraKey}
                    />);
                case ReverseConvertible:
                    return (<ReverseConvertiblePanel
                        key={this.props.extraKey + "rcbPanel"}
                        definitionUpdate={(osState) => { this.setState({ optionStructureState: osState }) }}
                        allAssets={this.state.allAssets}
                        initialDefinition={this.state.optionStructureState}
                        disabled={this.props.disabled}
                        extraKey={this.props.extraKey}
                    />);
                case ReverseReverseConvertible:
                    return (<ReverseReverseConvertiblePanel
                        key={this.props.extraKey + "rrcbPanel"}
                        definitionUpdate={(osState) => { this.setState({ optionStructureState: osState }) }}
                        allAssets={this.state.allAssets}
                        initialDefinition={this.state.optionStructureState}
                        disabled={this.props.disabled}
                        extraKey={this.props.extraKey}
                    />);
                case Swap:
                    return <SwapEditor
                        allAssets={this.state.allAssets}
                        key="swapEditor"
                        extraKey={this.props.extraKey}
                        disabled={this.props.disabled}
                        initialDefinition={this.state.commodityState}
                        definitionUpdate={(cmState) => { this.setState({ commodityState: cmState }) }}
                        sizeUpdate={(size) => this.setState({ rfqSize: size })}
                        size={this.state.rfqSize}
                    />
                // case EuropeanOption:
                //     return <div>Euro option designer</div>
                // case AsianOption:
                //     return <div>Asian Option designer</div>
                case UnpricedAverage:
                    return <UnpricedAverageEditor
                        allAssets={this.state.allAssets}
                        key="unpricedAvgEditor"
                        extraKey={this.props.extraKey}
                        disabled={this.props.disabled}
                        initialDefinition={this.state.commodityState}
                        definitionUpdate={(cmState) => { this.setState({ commodityState: cmState }) }}
                        sizeUpdate={(size) => this.setState({ rfqSize: size })}
                        size={this.state.rfqSize}
                    />
                default:
                    return (<div key="emptyDiv"></div>)
            }
        }
        else
            return (<div key="emptyDiv"></div>);
    }

    onClose() {
        this.setState({ closing: true });
        this.props.onClose();
        this.setState({ closing: false });
    }

    renderCloseButton() {
        if (this.props.onClose) {
            return (
                <div className="ProductDesignerCloseButton" key="prodDgnrCls" >
                    <IconButton color="inherit" size="small" onClick={this.onClose}><CloseOutlined /></IconButton>
                </div>);
        }
        else
            return null;
    }

    renderFirmPanel() {
        const isSalesUser = userStoreInstance.UserHasRole("HybridSales")
        //var isSalesUser = userStoreInstance.IsInternalUser();
        if (isSalesUser) {
            var selectedFirm = this.state.selectedFirm ? this.state.selectedFirm.name : "Select Firm...";
            var firms = userStoreInstance.GetClientFirms().map(f => f.name);
            return (<div className="ProductDesignerPayoffRow">
                <div className="ProductDesignerPayoffRowLeftTitles"><div className="AutocallPanelLeftTitlesText">Client Firm</div></div>
                <div className="ProductDesignerRightContent">
                    <StringTypeDropDown
                        possibles={["Select Firm...", ...firms]}
                        selected={selectedFirm}
                        onChange={(e) => this.setState({ selectedFirm: userStoreInstance.GetClientFirms().filter(f => f.name === e)[0] })} disabled={this.props.disabled} />
                </div>
            </div>)
        }
        else
            return null;
    }


    render() {
        const productsList = this.state.products.AssetInfos;
        const theme = userStoreInstance.GetSettings().themeName;
        if (productsList.length === 0) {
            return (<div key="prgsss"><CircularProgress color="inherit" /></div>
            );
        }
        else {

            return (
                <div key="ccv0"
                    ref={this.state.mainRef}
                    className={`ProductDesignerContainer theme-${theme}`}
                    style={{ filter: this.state.closing ? "blur(2px)" : "none", maxHeight: this.props.dashboardMode ? "80vh" : "90vh", height: "100%" }}>
                    {this.renderCloseButton()}
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid key="ccv1" container spacing={0} justifyContent="center">
                            {this.renderPayoffPanel()}
                            {this.renderRfqPropsPanel()}
                            <FormSection key="ccv3" hideBottomBorder={this.props.dashboardMode}>{[this.renderPayoffDetail()]}</FormSection>
                            {this.props.dashboardMode ? null : this.renderCreateRfqButton()}
                        </Grid>
                    </ThemeProvider>
                </div >)
        }
    }
}

export default StructuredProductDesigner;