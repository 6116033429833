import React from 'react';
import { rowProps, rowPropsLast, subValueProps, titleProps, titleVariant, valueProps } from '../helpers';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import BasketDesigner from '../basketDesigner';
import { BasketDefinition } from '../../../qwack/basketDefinition';
import { Asset } from '../../../assets/assetsModels';
import { BasketType } from '../../../qwack/basketType';
import assetsStoreInstance from '../../../assets/assetsStore';
import { v4 } from 'uuid';
import { WrappedDatePicker } from '../../../inputs/wrappedDatePicker';
import moment from 'moment';
import { NumberFormatCustom } from '../../../inputs/numberFormatInput';
import { WrappedSelect } from '../../../inputs/wrappedSelect';
import productStoreInstance from '../../../product/productStore';
import { CommodityVanillaLeg, CommodityVanillaPayoff, FxConvention, PeriodType, SettlementConvention } from '../../../product/productModels';
import { Frequency } from '../../../qwack/frequency';

export type SwapEditorState = {
    lastBookedId: number;
    basketDef: BasketDefinition;
    periodType: PeriodType;
    period: string;
    commoFixingIndex: string;
    fxFixingIndex: string;
    startDate: Date;
    endDate: Date;
    quantity: number;
    settleLagOrDate: string;
    ccy: string;
    uniqueKey: string;
    product: CommodityVanillaPayoff
}

export interface SwapEditorProps {
    definitionUpdate: (state: CommodityVanillaPayoff) => void,
    sizeUpdate: (size: number) => void,
    initialDefinition: CommodityVanillaPayoff,
    allAssets: Asset[];
    disabled: boolean;
    extraKey: string;
    uniqueKey?: string;
    size?: number
}

export class SwapEditor extends React.Component<SwapEditorProps, SwapEditorState>{
    constructor(props: SwapEditorProps) {
        super(props);
        var leg = props.initialDefinition?.legs ? props.initialDefinition?.legs[0] : undefined;
        var asset = leg?.assetId ? assetsStoreInstance.getAsset(Number(leg.assetId)) : undefined;
        var basketWeights: { [asset: string]: number } = {};
        if (asset) {
            basketWeights[asset.key] = 1.0
        }

        this.state = {
            lastBookedId: undefined,
            basketDef: asset ? { basketType: BasketType.SingleAsset, basketWeights: basketWeights } as BasketDefinition : undefined,
            periodType: leg?.periodType ?? PeriodType.Monthly,
            period: leg?.period ?? undefined,
            startDate: undefined,
            endDate: undefined,
            quantity: props.size,
            settleLagOrDate: leg?.settlement?.date ?? leg?.settlement?.lag ,
            commoFixingIndex: leg?.fixingIndex,
            fxFixingIndex: undefined,
            ccy: leg?.currency,
            uniqueKey: props.uniqueKey ?? v4(),
            product: props.initialDefinition ?? {} as CommodityVanillaPayoff
        };

        this.createState = this.createState.bind(this);
    }

    componentDidUpdate() {
        var product = this.createState();
        if (JSON.stringify(product) !== JSON.stringify(this.state.product)){
            this.setState({product});
            this.props.definitionUpdate(product);
        }
          
    }

    generateQuarters(): string[] {
        var nQuarters = 16;
        var currentQStartMonth = moment().startOf('quarter').month();
        var currentQStartYear = moment().startOf('quarter').year();
        var o = new Array<string>();

        for (var i = 0; i < nQuarters; i++) {
            var q = (currentQStartMonth - currentQStartMonth % 3) / 3;
            var code = `Q${q + 1}-${currentQStartYear - 2000}`;
            o.push(code);
            currentQStartMonth += 3;
            if (currentQStartMonth >= 12) {
                currentQStartMonth = 1;
                currentQStartYear++;
            }
        }

        return o;
    }

    onDuplicatePressed = () => this.setState({ lastBookedId: undefined });

    fxPairsToInvert: string[] = ["EUR", "GBP", "AUD", "NZD"];

    getFxPairName(assetCcy: string, payoutCcy: string) {
        if (assetCcy === "USD") {
            if (this.fxPairsToInvert.includes(payoutCcy))
                return `${payoutCcy}USD`;
            else
                return `USD${payoutCcy}`;
        }
        else if (payoutCcy === "USD") {
            if (this.fxPairsToInvert.includes(assetCcy))
                return `${assetCcy}USD`;
            else
                return `USD${assetCcy}`;
        }
        else
            return `${assetCcy}${payoutCcy}`;
    }

    createState() {
        const { basketDef, periodType, period, startDate, endDate, settleLagOrDate, commoFixingIndex, ccy, fxFixingIndex, uniqueKey } = this.state;
        const { allAssets } = this.props;

        var asset = basketDef ? allAssets.filter(a => a.key === Object.keys(basketDef?.basketWeights)[0])[0] : undefined;
        const settleDateValid = moment(settleLagOrDate)?.isValid();

        return {
            legs: [{
                assetId: asset?.assetId,
                fixingIndex: commoFixingIndex,
                currency: ccy,
                period: periodType === PeriodType.Custom ? `${moment(startDate)?.format("yyyy-MM-DD")}~${moment(endDate)?.format("yyyy-MM-DD")}` : period,
                fxConvention: {
                    conversionType: ccy === asset?.defaultCcy ? "None" : "ConvertThenAverage",
                    fxFixingCalendars: ccy,
                    fxFixingIndex: fxFixingIndex,
                    isCommonPricing: true
                } as FxConvention,
                periodType: periodType,
                isCall: false,
                schedule: undefined,
                settlement: {
                    date: settleDateValid ? moment(settleLagOrDate).format("yyyy-MM-DD") : undefined,
                    lag: !settleDateValid ? settleLagOrDate : undefined,
                    relativeTo: "LastFixing",
                    calendars: ccy
                } as SettlementConvention,
                strike: undefined,
                type: "Swap",
                uniqueKey: uniqueKey + "--Leg0"
            } as CommodityVanillaLeg],
            priceAsOne: true,
            priceCurrency: ccy,
            solveFor: "Strike",
            type: "SinglePeriodSwap",
            uniqueKey: uniqueKey,
            assetName: asset?.name
        } as CommodityVanillaPayoff

    }

    isValidSettlement(){
        const {settleLagOrDate} = this.state;
        var isM = moment(settleLagOrDate)?.isValid();
        return Boolean(settleLagOrDate) && (isM || Frequency.TryParse(settleLagOrDate)!==undefined) 
    }

    render() {
        const { basketDef, periodType, period, startDate, endDate, quantity, settleLagOrDate, commoFixingIndex, ccy, fxFixingIndex } = this.state;
        const { allAssets, disabled, extraKey } = this.props;
        var commoAssets = allAssets.filter(a => ["Precious Metals", "Base Metals"].includes(a.assetGroup))
        var asset = basketDef ? allAssets.filter(a => a.key === Object.keys(basketDef?.basketWeights)[0])[0] : undefined;
        var fxAsset = !asset || !ccy || asset.defaultCcy === ccy ? undefined : allAssets.filter(a => a.key === this.getFxPairName(asset.defaultCcy, ccy))[0];
        return (<Grid item container>
            <Grid {...rowProps}>
                <Grid {...titleProps} width="100px"><Typography textAlign='center' variant={titleVariant} padding="0.25em" >Underlying</Typography></Grid>
                <Grid {...valueProps} width="calc(100% - 100px)">
                    <BasketDesigner
                        lockedType={BasketType.SingleAsset}
                        hideLabel={true}
                        controlLabel="Underlying"
                        extraKey={extraKey}
                        keyName="cssBasket"
                        initialBasketDefinition={basketDef}
                        allAssets={commoAssets}
                        stateUpdate={(basket) => {
                            this.setState({ basketDef: basket });
                            var a = basket ? allAssets.filter(a => a.key === Object.keys(basket?.basketWeights)[0])[0] : undefined;
                            if (a?.fixingIndices)
                                this.setState({ commoFixingIndex: a.fixingIndices[0].name });
                            if (!ccy && a?.defaultCcy) {
                                this.setState({ ccy: a.defaultCcy });
                            }
                        }}
                        disabled={disabled} />
                    {asset && <Grid {...subValueProps} paddingTop="0.25em">
                        <WrappedSelect
                            disabled={disabled}
                            label="Fixing Index"
                            placeholder='Select Index...'
                            value={commoFixingIndex ?? 'Select Index...'}
                            onChange={(e => this.setState({ commoFixingIndex: e.target.value }))}>
                            <MenuItem key={"selectCF"} disabled value={'Select Index...'}>{'Select Index...'}</MenuItem>
                            {asset.fixingIndices.map(q => <MenuItem key={q.name} value={q.name}>{q.name}</MenuItem>)}
                        </WrappedSelect>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid {...rowProps}>
                <Grid {...titleProps} width="100px"><Typography textAlign='center' variant={titleVariant} padding="0.25em" >Currency</Typography></Grid>
                <Grid {...valueProps} width="calc(100% - 100px)">
                    <Grid {...subValueProps} paddingTop="0.25em">
                        <WrappedSelect
                            disabled={disabled}
                            label="Currency"
                            placeholder='Select Ccy..'
                            value={ccy ?? 'Select Ccy...'}
                            onChange={(e => this.setState({ ccy: e.target.value }))}>
                            <MenuItem key={"selectCcy"} disabled value={'Select Ccy...'}>{'Select Ccy...'}</MenuItem>
                            {productStoreInstance.getAvailableCurrencies().map(q => <MenuItem key={q} value={q}>{q}</MenuItem>)}
                        </WrappedSelect>
                    </Grid>
                    {fxAsset && <Grid {...subValueProps} paddingTop="0.25em">
                        <WrappedSelect
                            disabled={disabled}
                            label="Fx Fixing Index"
                            placeholder='Select Fx Index...'
                            value={fxFixingIndex ?? 'Select Fx Index...'}
                            onChange={(e => this.setState({ fxFixingIndex: e.target.value }))}>
                            <MenuItem key={"selectCF"} disabled value={'Select Fx Index...'}>{'Select Fx Index...'}</MenuItem>
                            {fxAsset?.fixingIndices.map(q => <MenuItem key={q.name} value={q.name}>{q.name}</MenuItem>)}
                        </WrappedSelect>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid {...rowProps} paddingTop="0.75em">
                <Grid {...titleProps} width="100px"><Typography textAlign='center' variant={titleVariant} padding="0.25em">Quantity</Typography></Grid>
                <Grid {...valueProps} width="calc(100% - 100px)">
                    <Grid {...subValueProps}>
                        <TextField
                            style={{ width: "150px" }}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="size"
                            name="size"
                            size="small"
                            label={"Quantity"}
                            error={quantity===undefined || quantity===0}
                            value={quantity ?? ""}
                            onFocus={event => {
                                event.target.select();
                            }}
                            onChange={(e) => {
                                const size = Number(e.target.value);
                                this.setState({ quantity: size });
                                this.props.sizeUpdate(size);
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: disabled,
                                classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                inputComponent: NumberFormatCustom as any,
                            }} />
                    </Grid>
                    {asset?.defaultUnits && <Grid {...subValueProps}><TextField
                        style={{ width: "50px" }}
                        variant="standard"
                        classes={{ root: "ListedInstrumentEditorFormField" }}
                        id="Unit"
                        name="Unit"
                        size="small"
                        label={"Unit"}
                        value={asset?.defaultUnits ?? ""}
                        onFocus={event => {
                            event.target.select();
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                            classes: { input: "ListedInstrumentEditorFormFieldInner" },
                        }} /></Grid>}
                </Grid>
            </Grid>
            <Grid {...rowProps} paddingTop="0.75em">
                <Grid {...titleProps} width="100px"><Typography textAlign='center' variant={titleVariant} padding="0.25em" >Fixing Period</Typography></Grid>
                <Grid {...valueProps} width="calc(100% - 100px)">
                    <Grid {...subValueProps}>
                        <WrappedSelect
                            autoWidth
                            disabled={disabled}
                            value={periodType ?? 0}
                            onChange={(d) => {
                                var asEnum: PeriodType = PeriodType[PeriodType[Number(d.target.value)] as keyof typeof PeriodType];
                                this.setState({ periodType: asEnum });
                            }}
                            label="Period Type">
                            {Object.keys(PeriodType).filter(x => !isNaN(Number(x))).map(p => <MenuItem key={p} value={p}>{PeriodType[p]}</MenuItem>)}
                        </WrappedSelect>
                    </Grid>
                    {(periodType === PeriodType.Monthly) && <Grid {...subValueProps}>
                        <WrappedDatePicker
                            //disabled={disabled}
                            readOnly={disabled}
                            error={period===undefined}
                            style={{ width: "120px" }}
                            views={['year', 'month']}
                            value={Boolean(period) ? moment(`01-${period}`)?.toDate() : null}
                            disablePast
                            inputFormat='MMM-yy'
                            label="Month"
                            onChange={(d) => this.setState({ period: d.format("MMMyy") })} />
                    </Grid>}
                    {(periodType === PeriodType.Bullet) && <Grid {...subValueProps}>
                        <WrappedDatePicker
                            //disabled={disabled}
                            readOnly={disabled}
                            style={{ width: "120px" }}
                            value={Boolean(period) ? moment(period)?.toDate() : null}
                            label="Fixing Date"
                            disablePast
                            onChange={(d) => this.setState({ period: d?.format("yyyy-MM-DD") })} />
                    </Grid>}
                    {(periodType === PeriodType.Custom) && <React.Fragment>
                        <Grid {...subValueProps}>
                            <WrappedDatePicker
                                //disabled={disabled}
                                readOnly={disabled}
                                style={{ width: "120px" }}
                                value={moment(startDate)?.toDate()}
                                maxDate={moment(endDate)?.toDate()}
                                label="Start Date"
                                onChange={(d) => this.setState({ startDate: d.toDate() })} />
                        </Grid>
                        <Grid {...subValueProps}>
                            <WrappedDatePicker
                                //disabled={disabled}
                                readOnly={disabled}
                                style={{ width: "120px" }}
                                value={moment(endDate)?.toDate()}
                                minDate={moment(startDate)?.toDate()}
                                label="End Date"
                                onChange={(d) => this.setState({ endDate: d.toDate() })} />
                        </Grid>
                    </React.Fragment>}
                    {(periodType === PeriodType.Quarterly) && <Grid {...subValueProps}>
                        <WrappedSelect
                            disabled={disabled}
                            label="Quarter"
                            placeholder='Select Quarter...'
                            value={period ?? 'Select Quarter...'}
                            onChange={(e => this.setState({ period: e.target.value }))}>
                            <MenuItem key={"selectQ"} disabled value={'Select Quarter...'}>{'Select Quarter...'}</MenuItem>
                            {this.generateQuarters().map(q => <MenuItem key={q} value={q}>{q}</MenuItem>)}
                        </WrappedSelect>
                    </Grid>}
                </Grid>
            </Grid>
            <Grid {...rowPropsLast} paddingTop="0.75em" >
                <Grid {...titleProps} width="100px"><Typography textAlign='center' variant={titleVariant} padding="0.25em" >Settlement</Typography></Grid>
                <Grid {...valueProps} width="calc(100% - 100px)">
                    <Grid {...subValueProps}>
                        <TextField
                            style={{ width: "150px" }}
                            variant="outlined"
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="size"
                            name="size"
                            size="small"
                            label={"Lag or Date"}
                            value={settleLagOrDate ?? ""}
                            error={!this.isValidSettlement()}
                            onFocus={event => {
                                event.target.select();
                            }}
                            onChange={(e) => this.setState({ settleLagOrDate: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: disabled,
                                classes: { input: "ListedInstrumentEditorFormFieldInner" },
                            }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >)
    }
}