import { OptionStructure } from "../../../payoff/models/optionStructure";
import { OptionType } from "../../../qwack/optionType";
import { basketOk, dateOk } from "./validateCommon";

export function validateReverseConvertible(o: OptionStructure): boolean {
    if (o.vanillaLegs && o.vanillaLegs.length === 1 && (!o.barrierOptionLegs || o.barrierOptionLegs.length === 0)) {
        return validateBondMinusVanillaPut(o);
    }
    else if (o.barrierOptionLegs && o.barrierOptionLegs.length === 1 && (!o.vanillaLegs || o.vanillaLegs.length === 0)) {
        return validateBondMinusBarrierPut(o);
    }

    return false;
}

function validateBondMinusVanillaPut(o: OptionStructure): boolean {
    let c1 = o.vanillaLegs[0];
    let initial = basketOk(c1.underlying) && dateOk(c1.expiryDate) && c1.callPutStraddle === OptionType.put && c1.legWeight === -1;
    return initial;
}

function validateBondMinusBarrierPut(o: OptionStructure): boolean {
    let c1 = o.barrierOptionLegs[0];
    let initial = basketOk(c1.underlying) && dateOk(c1.expiryDate) && c1.barrier1Detail !== undefined && c1.callPutStraddle === OptionType.put && c1.legWeight === -1;
    return initial;
}
