import userMessageStoreInstance from '../userMessages/userMessagesStore';
import { SnackbarKey, withSnackbar } from 'notistack';
import { Component } from 'react';
import FBEmitter from 'fbemitter';

export interface UserMessagesComponentProps {
    enqueueSnackbar: (message: string) => SnackbarKey,
    closeSnackbar: () => void
}

class UserMessagesComponent extends Component<UserMessagesComponentProps, {}> {
    messageSubscription: FBEmitter.EventSubscription | undefined;

    componentDidMount() {
        this.messageSubscription = userMessageStoreInstance.addChangeListener(() => this.props.enqueueSnackbar(userMessageStoreInstance.getLatestMessage()));
    }

    componentWillUnmount() {
        if (this.messageSubscription) {
            this.messageSubscription.remove();
            this.messageSubscription = null;
        }
    }

    render() {
        return null;
    }

};

export default withSnackbar(UserMessagesComponent);