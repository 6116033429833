import { connectionBase, ConnectionStatus } from '../connections/connectionStatusModel';
import { NewTickRecieved } from '../marketData/marketDataActions';
import { MarketDataTick } from '../marketData/marketDataModels';
import { NewTrade } from '../trade/tradeActions';
import { ConnectionStatusChange, GeneralStatsUpdated, NewConnectionStats, NewMessage, NewTaskUpdate, NewUserActivity, NewUserActivityBatch } from './userSessionActions';
import { ConnectionStats, GeneralStatsObject, LongRunningTaskUpdate, PingPacket, UserActivityEvent } from './pingModels';
import { JobResultUpdate, NewJobRequest } from '../jobs/jobModels';
import { NewJobUpdate } from '../jobs/jobActions';
import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import { InstrumentsUpdated, InstrumentUpdated } from '../listedInstruments/listedInstrumentActions';
import { LivePosition } from '../positions/positionSummaryModels';
import { UpdatePosition } from '../positions/positionSummaryActions';
import { Alert } from '../alerts/alertsModels';
import { AlertUpdate } from '../alerts/alertsActions';
import { Order, OrderAlert, OrderStatus } from '../orders/orderModels';
import { NewOrderAlert, OrderUpdated } from '../orders/orderActions';
import { Report } from '../reports/reportModels';
import { UpdatedReport } from '../reports/reportActions';

const hubLocation = "userSessionHub";

class UserSessionConnection extends connectionBase {
    pingCount: number = 0;
    subscriptions: Map<string, number> = new Map<string, number>();

    constructor() {
        super(hubLocation, "UserSession");
    }

    public afterConnect() {
        this.unSubscribeFromOrders().then(() => this.subscribeToOrders());
        this.unSubscribeFromOrderAlerts().then(() => this.subscribeToOrderAlerts());
    }

    public async connectIfNeeded() {
        if (this.status === ConnectionStatus.Disconnected)
            await this.connect();
    }

    public beforeConnect() {
        this.hubConnection.on("tickUpdate", this.onMarketDataTickUpdate);
        this.hubConnection.on("newTrade", this.onNotifyTrade);
        this.hubConnection.on("ping", (s) => this.onPing(s));
        this.hubConnection.on("newUserEvent", (s) => this.onNewUserEvent(s));
        this.hubConnection.on("statsUpdate", (s) => this.onStatsUpdate(s));
        this.hubConnection.on("newMessage", (s) => this.onNewMessage(s));
        this.hubConnection.on("jobUpdate", (s) => this.onJobUpdate(s));
        this.hubConnection.on("instrumentUpdate", (s) => this.onInsUpdate(s));
        this.hubConnection.on("instrumentUpdates", (s) => this.onInsUpdates(s));
        this.hubConnection.on("positionUpdate", (s) => this.onPositionUpdate(s));
        this.hubConnection.on("alertUpdate", (a) => this.onAlertUpdate(a));
        this.hubConnection.on("orderUpdate", (a) => this.onOrderUpdate(a));
        this.hubConnection.on("orderAlert", (a) => this.onOrderAlert(a));
        this.hubConnection.on("taskUpdate", (a) => this.onTaskUpdate(a));
        this.hubConnection.on("reportUpdate", (a) => this.onReportUpdate(a));
    }

    private async Subscribe(subscriptionKey: string) {
        try {
            var subCount = this.subscriptions.get(subscriptionKey);
            if (!subCount) {
                subCount = 0;
            }
            subCount++;
            this.subscriptions.set(subscriptionKey, subCount);
            if (subCount === 1) {
                await this.hubConnection.invoke("Subscribe", subscriptionKey);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    private async Unsubscribe(subscriptionKey: string) {
        try {
            var subCount = this.subscriptions.get(subscriptionKey);
            if (!subCount || subCount === 0) return;
            subCount--;
            if (subCount === 0) {
                await this.hubConnection.invoke("Unsubscribe", subscriptionKey);
            }
            this.subscriptions.set(subscriptionKey, subCount);
        }
        catch (error) {
            console.log(error);
        }
    }

    public afterReconnect() {
        // resubscribe after a restart        
        this.subscriptions.forEach(async (value, key) => {
            if (value > 0) {
                await this.hubConnection.invoke("Subscribe", key);
            }
        });
        this.unSubscribeFromOrders().then(() => this.subscribeToOrders());
        this.unSubscribeFromOrderAlerts().then(() => this.subscribeToOrderAlerts());
    }

    public onStatusChange(status: ConnectionStatus) {
        ConnectionStatusChange(status);
        if (status === ConnectionStatus.Disconnected)
            this.connectIfNeeded();
    }

    public async subscribeToMarketDataTicks(insId: number) {
        try {
            this.Subscribe("MarketDataTicks-" + insId);
            await this.hubConnection.invoke("SubscribeToMarketDataTicks", insId);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async unSubscribeToMarketDataTicks(insId: number) {
        try {
            this.Unsubscribe("MarketDataTicks-" + insId);
            await this.hubConnection.invoke("UnSubscribeToMarketDataTicks", insId);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async subscribeToStats(type: string) {
        try {
            await this.Subscribe("Stats-" + type);
            await this.hubConnection.invoke("SubscribeToStats", type);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async unSubscribeToStats(type: string) {
        try {
            await this.Unsubscribe("Stats-" + type);
            await this.hubConnection.invoke("UnSubscribeToStats", type);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async subscribeToPositions() {
        await this.Subscribe("Positions");
    }

    public async unSubscribeToPositions() {
        await this.Unsubscribe("Positions");
    }

    public async subscribeToOrders() {
        await this.Subscribe("Orders");
    }

    public async unSubscribeFromOrders() {
        await this.Unsubscribe("Orders");
    }

    public async subscribeToOrderAlerts() {
        await this.Subscribe("OrderAlerts");
    }

    public async unSubscribeFromOrderAlerts() {
        await this.Unsubscribe("OrderAlerts");
    }

    public async getAvailableInsIds() {
        try {
            let tickers = await this.hubConnection.invoke<number[]>("GetAvailableInsIds");
            return tickers;
        }
        catch (error) {
            console.log(error);
        }
    }

    public async startJob(jobRequest: NewJobRequest) {
        try {
            await this.hubConnection.invoke("newJob", jobRequest);
        }
        catch (error) {
            console.log(error);
        }
    }

    public async onMarketDataTickUpdate(tick: MarketDataTick) {
        NewTickRecieved(tick);
    }

    public async onAlertUpdate(alert: Alert) {
        AlertUpdate(alert);
    }

    public async onStatsUpdate(stats: GeneralStatsObject) {
        GeneralStatsUpdated(stats);
    }

    public async onNotifyTrade(rfqId: number) {
        NewTrade(rfqId);
    }

    public async onNewMessage(message: string) {
        NewMessage(message);
    }

    public async onJobUpdate(update: JobResultUpdate) {
        NewJobUpdate(update);
    }

    public onInsUpdate(update: ListedInstrument) {
        InstrumentUpdated(update);
    }

    public onInsUpdates(updates: ListedInstrument[]) {
        InstrumentsUpdated(updates);
    }

    public async onPositionUpdate(update: LivePosition) {
        console.log("PositionUpdate", update)
        await UpdatePosition(update);
    }

    public async onOrderUpdate(update: Order) {
        OrderUpdated(update);
        if ((update.orderStatus === OrderStatus.Filled || update.orderStatus === OrderStatus.Rejected) && update.orderVenue === "Pltfm")
            NewMessage(`Order ${update.orderId} ${OrderStatus[update.orderStatus]}`);
    }

    public onOrderAlert(alert: OrderAlert) {
        NewOrderAlert(alert)
    }

    public async onTaskUpdate(update: LongRunningTaskUpdate) {
        await NewTaskUpdate(update);
    }

    public async onReportUpdate(update: Report) {
        console.log("Report Update A :", update)
        UpdatedReport(update);
    }

    async onPing(packet: PingPacket) {
        packet.clientTimestamp = (new Date()).toISOString();
        await this.hubConnection.send("replyToPing", packet);
        this.pingCount++;
        if (this.pingCount > 10) {
            await this.getStats();
        }
    }

    async getStats() {
        try {
            this.pingCount = 0;
            var stats = await this.hubConnection.invoke<ConnectionStats>("getStats");
            NewConnectionStats(stats);
        }
        catch (error) {
            console.log(error);
        }
    }

    async getUserEvents() {
        try {
            this.pingCount = 0;
            var events = await this.hubConnection.invoke<UserActivityEvent[]>("getUserEvents");
            await NewUserActivityBatch(events);
        }
        catch (error) {
            console.log(error);
        }
    }

    async onNewUserEvent(event: UserActivityEvent) {
        await NewUserActivity(event)
    }

    public async subscribeToUserEvents() {
        try {
            await this.Subscribe("UserEvents");
            await this.hubConnection.invoke("SubscribeToUserEvents");
        }
        catch (error) {
            console.log(error);
        }
    }

    public async subscribeToAlerts() {
        try {
            await this.Subscribe("Alert");
        }
        catch (error) {
            console.log(error);
        }
    }

    public async unsubscribeFromAlerts() {
        try {
            await this.Unsubscribe("Alert");
        }
        catch (error) {
            console.log(error);
        }
    }

    public async unSubscribeFromUserEvents() {
        try {
            await this.Unsubscribe("UserEvents");
            await this.hubConnection.invoke("UnSubscribeFromUserEvents");
        }
        catch (error) {
            console.log(error);
        }
    }

    public async subscribeToJobUpdates(key: string) {
        await this.Subscribe("LRJ-" + key);
    }

    public async unSubscribeFromJobUpdates(key: string) {
        await this.Unsubscribe("LRJ-" + key);
    }
 
    public async subscribeToReportUpdates() {
        await this.Subscribe("Reports");
    }
    
    public async unSubscribeFromReportUpdates() {
        await this.Unsubscribe("Reports");
    }
}

const userSessionConnection = new UserSessionConnection();
export default userSessionConnection;