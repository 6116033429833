import React from 'react';
import NumberFormat from 'react-number-format';

export interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<number> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const NumberFormatCustom = React.forwardRef((props: NumberFormatCustomProps, ref: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            //onChange={(e) => onChangeQuantity(e.target.value)}
            //customInput={TextField}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
        //isNumericString
        //prefix="$"
        />
    );
})