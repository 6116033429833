import { Grid, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Typography, CssBaseline } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { assetSort, AssetStr, DefaultSetName } from '../globalConstants';
import { LiveSummary, PositionSummary } from './positionSummaryModels';
import { getIns, getMetaFromInstrument } from './positionsCommon';
import moment from 'moment';
import { BlackDelta, BlackImpliedVol, BlackVega } from '../qwack/blackFunctions';
import { EventSubscription } from 'fbemitter';
import _ from 'lodash';
import marketDataStoreInstance from '../marketData/marketDataStore';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';
import userStoreInstance from '../user/userStore';
import { ThemeProvider } from '@mui/material/styles';
import { getPositionTableTheme } from './positionSummaryTable';
import { ShouldInvertPriceFast } from './positionLiveGrid';
//import userStoreInstance from '../user/userStore';

export const PositionLiveSummary = props => {
    const { summary, baselineSummary, asOf, summaryHeight, baseNav, pnlInPctNav } = props;
    const [baselineSummaryData, setBaselineSummaryData] = useState(undefined);
    const [summaryData, setSummaryData] = useState(undefined);

    var eventSubscriptionMarketDataTicks = useRef({} as EventSubscription);
    var lastDraw = useRef(moment());


    useEffect(() => {
        if (asOf === null) {
            eventSubscriptionMarketDataTicks.current = marketDataLiveStoreInstance.addChangeListener(onTick);
        }

        var sub = eventSubscriptionMarketDataTicks.current;

        var positionSummary = generateSummaryData(summary);
        setSummaryData(positionSummary);
        var baseLinePositionSummary = generateSummaryData(baselineSummary)
        setBaselineSummaryData(baseLinePositionSummary);


        function onTick() {
            var timeDiff = moment().diff(lastDraw.current, "millisecond");
            const drawInterval = userStoreInstance.GetLiveThrottleMs();
            if (asOf === null && Boolean(summary) && timeDiff > drawInterval) { //live mode
                var positionSummary = generateSummaryData(summary);
                setSummaryData(positionSummary);
                // var baseLinePositionSummary = generateSummaryData(baselineSummary)
                // setBaselineSummaryData(baseLinePositionSummary);
                lastDraw.current = moment();
            }
        }
        function getPrice(insId: number, lastPrice: number, fallbackWidth: number): number {
            var liveMode = asOf === null;
            if (liveMode) {
                var tick = marketDataLiveStoreInstance.getTick(insId);
                var bidAsk = marketDataLiveStoreInstance.getBidAsk(insId);
                if(!tick)
                    tick = (bidAsk.bid + bidAsk.ask) / 2;

                if (!bidAsk.ask || !bidAsk.bid || Math.abs(bidAsk.ask / bidAsk.bid - 1) > fallbackWidth) {
                    tick = bidAsk.bid ? Math.max(bidAsk.bid,lastPrice) : lastPrice;
                    if(bidAsk.ask){
                        tick = Math.min(bidAsk.ask,tick);
                    }
                }
                else {
                    tick = (bidAsk.bid + bidAsk.ask) / 2;
                }
                return tick && !isNaN(tick) ? tick : lastPrice;
            }
            return lastPrice;
        }
        function generateSummaryData(sum: LiveSummary) {
            if (!sum || !sum.positions)
                return null;

            var divisor = (pnlInPctNav && baseNav) ? baseNav : 1.0;

            var isLive = sum.asOf?.toLowerCase() === "now";

            var posByAsset = _.groupBy(sum.positions, p => getMetaFromInstrument(getIns(p.aggregatedPosition.instrumentId), AssetStr));

            var realizedByAsset: { [asset: string]: number } = {};
            var unRealizedByAsset: { [asset: string]: number } = {};

            var realized = 0;
            var unrealized = 0;
            Object.keys(posByAsset).filter(k => k !== "null").forEach(asset => {
                //TODO - make this rely on live fx rates
                var realizedForAsset = _.sum(posByAsset[asset].map(p => p.aggregatedPosition.realizedPnLBase)) / divisor;
                realizedByAsset[asset] = realizedForAsset;
                realized += realizedForAsset;
                if (!isLive) {
                    var unRealizedForAsset = _.sum(posByAsset[asset].map(p => p.unrealizedPnLBase)) / divisor;
                    unRealizedByAsset[asset] = unRealizedForAsset;
                    unrealized += unRealizedForAsset;
                }
            });

            var netExp = 0;
            var grossExp = 0;
            var delta = 0;
            var vega = 0;
            var deltaByAsset = new Map<string, number>();
            var vegaByAsset = new Map<string, number>();

            var slDict: { [key: string]: number } = {};
            var tpDict: { [key: string]: number } = {};
            var slBreachesDict: { [key: string]: number } = {};
            var tpBreachesDict: { [key: string]: number } = {};
            var slTotal = 0;
            var tpTotal = 0;
            var slTotalBreaches = 0;
            var tpTotalBreaches = 0;

            const fallbackWidth = userStoreInstance.GetFallbackPriceWidth();

            sum.positions.filter(p => p.aggregatedPosition.openPosition !== 0).forEach(p => {
                var a = p.aggregatedPosition;
                var ins = getIns(a.instrumentId);
                var price = isLive ? getPrice(a.instrumentId, p.livePrice, fallbackWidth) : p.livePrice;
                if (ShouldInvertPriceFast(a.instrumentId))
                    price = 1 / price;
                var asset = getMetaFromInstrument(ins, AssetStr);
                var size = a.openPosition * ins.multiplier;

                if (slDict[asset] === undefined) {
                    slDict[asset] = 0;
                    tpDict[asset] = 0;
                    slBreachesDict[asset] = 0;
                    tpBreachesDict[asset] = 0;
                }
                var fxRate = (p.unrealizedCcy === "USD" ? 1.0 : (marketDataLiveStoreInstance.getFxRate(p.unrealizedCcy) ?? marketDataStoreInstance.getFxRate(p.unrealizedCcy) ?? p.liveFxRateToBase ?? summary.fxRatesToBase[p.unrealizedCcy]));
                if (isNaN(fxRate))
                    fxRate = p.liveFxRateToBase;
                // var slForPos = p.stops?.stopLevel ? size * (p.stops.stopLevel - price) * fxRate : 0;
                // var tpForPos = p.stops?.takeProfitLevel ? size * (p.stops.takeProfitLevel - price) * fxRate : 0;
                var slForPos = p.stops?.stopLevel ? (size * (p.stops.stopLevel - price) * fxRate / divisor) : 0;
                var tpForPos = p.stops?.tpLevel ? (size * (p.stops.tpLevel - price) * fxRate / divisor) : 0;
                if (size > 0) {// long
                    if (price < p.stops?.stopLevel) {
                        slBreachesDict[asset]++;
                        slTotalBreaches++;
                    }
                    if (price > p.stops?.tpLevel) {
                        tpBreachesDict[asset]++;
                        tpTotalBreaches++;
                    }
                }
                else {
                    if (price > p.stops?.stopLevel) {
                        slBreachesDict[asset]++;
                        slTotalBreaches++;
                    }
                    if (price < p.stops?.tpLevel) {
                        tpBreachesDict[asset]++;
                        tpTotalBreaches++;
                    }
                }

                slDict[asset] += slForPos;
                tpDict[asset] += tpForPos;
                slTotal += slForPos;
                tpTotal += tpForPos;


                if (isLive) {
                    if (unRealizedByAsset[asset] === undefined)
                        unRealizedByAsset[asset] = 0;
                    var unRealizedForPos = size * (price - p.aggregatedPosition.averagePrice) * fxRate / divisor;
                    unrealized += unRealizedForPos;
                    unRealizedByAsset[asset] += unRealizedForPos;
                }
                var exp = price * size * fxRate / divisor;
                netExp += exp;
                grossExp += Math.abs(exp);
                if (ins?.type === "Option") {
                    var existingUlPrice = marketDataStoreInstance.getPrice(DefaultSetName, ins.underlyingId, sum.asOf?.toLowerCase() === "now" ? undefined : new Date(sum.asOf));
                    var ulPrice = getPrice(ins.underlyingId, existingUlPrice, fallbackWidth);
                    if (ulPrice) {
                        var tExp = moment(ins.maturity).diff(moment(new Date()), "hour") / (365 * 24);
                        var vol = BlackImpliedVol(ulPrice, Number(ins.optionStrike), 0, tExp, price, Boolean(ins.optionIsCall));
                        var d0 = BlackDelta(ulPrice, Number(ins.optionStrike), 0, tExp, vol, Boolean(ins.optionIsCall));
                        d0 *= size * ulPrice / divisor;
                        delta += d0;
                        var DbyA = deltaByAsset.get(asset) ?? 0;
                        DbyA += d0;
                        deltaByAsset.set(asset, DbyA)

                        var v0 = BlackVega(ulPrice, Number(ins.optionStrike), 0, tExp, vol);
                        v0 *= size / divisor;
                        vega += v0;

                        var VbyA = vegaByAsset.get(asset) ?? 0;
                        VbyA += vega;
                        vegaByAsset.set(asset, VbyA);
                    }
                }
                else {
                    delta += exp;
                    var DbyA2 = deltaByAsset.get(asset) ?? 0;
                    DbyA2 += exp;
                    deltaByAsset.set(asset, DbyA2);

                    var VbyA2 = vegaByAsset.get(asset) ?? 0;
                    vegaByAsset.set(asset, VbyA2);
                }
            });

            var deltaDict: { [key: string]: number } = {};
            var vegaDict: { [key: string]: number } = {};

            Array.from(deltaByAsset.keys()).forEach(k => {
                deltaDict[k] = deltaByAsset.get(k);
            });

            Array.from(vegaByAsset.keys()).forEach(k => {
                vegaDict[k] = vegaByAsset.get(k);
            });

            // var slDict: { [key: string]: number } = {};
            // var tpDict: { [key: string]: number } = {};

            // Array.from(slByAsset.keys()).forEach(k => {
            //     slDict[k] = slByAsset.get(k);
            //     tpDict[k] = tpByAsset.get(k);
            // });



            return {
                realized: realized,
                unRealized: unrealized,
                realizedByAsset: realizedByAsset,
                unRealizedByAsset: unRealizedByAsset,
                delta: delta,
                deltaByAsset: deltaDict,
                grossExp: grossExp,
                netExp: netExp,
                vega: vega,
                vegaByAsset: vegaDict,
                stopLossByAsset: slDict,
                totalSl: slTotal,
                takeProfitByAsset: tpDict,
                totalTp: tpTotal,
                stopLossBreachesByAsset: slBreachesDict,
                takeProfitBreachesByAsset: tpBreachesDict,
                totalSlBreaches: slTotalBreaches,
                totalTpBreaches: tpTotalBreaches,
            } as PositionSummary

        }

        return function cleanup() {
            //acceleratorStoreInstance.removeAllListeners();
            if (sub && sub.remove)
                sub.remove();
        };
    }, [summary, baselineSummary, asOf, baseNav, pnlInPctNav])

    function RenderNumber(num: number) {
        if (pnlInPctNav && baseNav)
            return (num * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "%";
        else
            return num?.toLocaleString(undefined, { maximumFractionDigits: 0 });
    }

    function RenderSummary(positionSummary: PositionSummary, baselineSummary?: PositionSummary) {
        var totalR = 0;
        var totalUR = 0;
        var totalRnUR = 0;
        var totalDiffR = 0;
        var totalDiffUR = 0;
        var totalDiffRnUR = 0;
        var totalD = 0;
        var totalV = 0;
        //var totalDiffD = 0;
        //var totalDiffV = 0;

        var hasBase = baselineSummary !== undefined && baselineSummary !== null;

        var posPnLTotal = positionSummary?.unRealized + positionSummary?.realized;
        var basePnLTotal = hasBase ? (baselineSummary.unRealized + baselineSummary.realized) : 0;

        const colorFunc = (val: number) => val === 0 ? userStoreInstance.GetTheme().color :
            (val > 0 ? userStoreInstance.GetTheme().chart_color_up : userStoreInstance.GetTheme().chart_color_down);

        return <CssBaseline><ThemeProvider theme={getPositionTableTheme()}><Grid item container direction="row" alignContent="center" justifyContent="space-around" wrap="nowrap">
            {/* <Grid item >
                <TableContainer>
                    <Table component={Paper} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head">Metric</TableCell>
                                <TableCell variant="head">Current</TableCell>
                                {!hasBase ? null : <TableCell variant="head">(Change)</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell variant="body">Realized</TableCell>
                                <TableCell >{positionSummary?.realized?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>
                                {!hasBase ? null : <TableCell variant="body">{(positionSummary?.realized - (baselineSummary?.realized ?? 0))?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell variant="body">UnRealized</TableCell>
                                <TableCell variant="body">{positionSummary?.unRealized?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>
                                {!hasBase ? null : <TableCell variant="body">{(positionSummary?.unRealized - (baselineSummary?.unRealized ?? 0))?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>}
                            </TableRow>
                            <TableRow key="rzk-totalz">
                                <TableCell variant="head">Total PnL</TableCell>
                                <TableCell variant="head">{posPnLTotal?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>
                                {!hasBase ? null : <TableCell variant="head">{(posPnLTotal - basePnLTotal).toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid> */}
            <Grid item>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head">Metric</TableCell>
                                <TableCell variant="head">Current</TableCell>
                                {hasBase && <TableCell variant="head">(Change)</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell variant="body">Gross Exp</TableCell>
                                <TableCell variant="body" key="grssExp">{RenderNumber(positionSummary?.grossExp)}</TableCell>
                                {hasBase && <TableCell variant="body">{RenderNumber(positionSummary?.grossExp - (baselineSummary?.grossExp ?? 0))}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell variant="body">Net Exp</TableCell>
                                <TableCell variant="body" key="netExp">{RenderNumber(positionSummary?.netExp)}</TableCell>
                                {hasBase && <TableCell variant="body">{RenderNumber(positionSummary?.netExp - (baselineSummary?.netExp ?? 0))}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableCell variant="body" style={{ borderBottom: "1px solid" }}>Net Delta</TableCell>
                                <TableCell variant="body" style={{ borderBottom: "1px solid" }} key="netDlta">{RenderNumber(positionSummary?.delta)}</TableCell>
                                {hasBase && <TableCell variant="body" style={{ borderBottom: "1px solid" }}>{RenderNumber(positionSummary?.delta - (baselineSummary?.delta ?? 0))}</TableCell>}
                            </TableRow>
                            <TableRow key="rzk-totalz">
                                <TableCell variant="head">Total PnL</TableCell>
                                <TableCell variant="head">{RenderNumber(posPnLTotal)}</TableCell>
                                {hasBase && <TableCell variant="head">{RenderNumber(posPnLTotal - basePnLTotal)}</TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head">Asset</TableCell>
                                <TableCell variant="head">Realized</TableCell>
                                {hasBase && <TableCell variant="head">(Change)</TableCell>}
                                <TableCell variant="head">Unrealized</TableCell>
                                {hasBase && <TableCell variant="head">(Change)</TableCell>}
                                <TableCell variant="head">Total PnL</TableCell>
                                {hasBase && <TableCell variant="head">(Change)</TableCell>}

                                <TableCell variant="head" style={{ borderLeft: "1px solid" }}>Delta</TableCell>
                                {/* {!hasBase ? null : <TableCell variant="head">(Change)</TableCell>} */}
                                <TableCell variant="head">Vega</TableCell>
                                {/* {!hasBase ? null : <TableCell variant="head">(Change)</TableCell>} */}

                                <TableCell variant="head" style={{ borderLeft: "1px solid" }}>Stop Loss</TableCell>
                                <TableCell variant="head">#SL</TableCell>
                                <TableCell variant="head">Take Profit</TableCell>
                                <TableCell variant="head">#TP</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetSort.map((k, ix) => {
                                var realised = positionSummary?.realizedByAsset[k] ?? 0;
                                var unRealised = positionSummary?.unRealizedByAsset[k] ?? 0;
                                totalR += realised;
                                totalUR += unRealised;
                                totalRnUR += realised + unRealised;

                                var diffR = hasBase ? (realised - (baselineSummary?.realizedByAsset[k] ?? 0)) : 0;
                                var diffUR = hasBase ? (unRealised - (baselineSummary?.unRealizedByAsset[k] ?? 0)) : 0;
                                var diffRnUR = hasBase ? (realised + unRealised) - ((baselineSummary?.realizedByAsset[k] ?? 0) + (baselineSummary?.unRealizedByAsset[k] ?? 0)) : 0;
                                totalDiffR += diffR;
                                totalDiffUR += diffUR;
                                totalDiffRnUR += diffRnUR;

                                var delta = positionSummary?.deltaByAsset[k] ?? 0;
                                var vega = positionSummary?.vegaByAsset[k] ?? 0;
                                totalD += delta;
                                totalV += vega;
                                //var diffD = hasBase ? ((positionSummary?.deltaByAsset[k] ?? 0) - (baselineSummary?.deltaByAsset[k] ?? 0)) : 0;
                                //var diffV = hasBase ? ((positionSummary?.vegaByAsset[k] ?? 0) - (baselineSummary?.vegaByAsset[k] ?? 0)) : 0;
                                //totalDiffD += diffD;
                                //totalDiffV += diffV;
                                if (realised === 0 && unRealised === 0 && delta === 0 && vega === 0)
                                    return <TableRow key={"noooDataRowz" + ix} />

                                return <TableRow key={`rzk-${ix}`}>
                                    <TableCell variant="body" className={"PositionSummaryTabTableCell--" + k}>{k ?? "(Ux)"}</TableCell>
                                    <TableCell variant="body">{RenderNumber(positionSummary?.realizedByAsset[k])}</TableCell>
                                    {hasBase && <TableCell variant="body">{RenderNumber(diffR)}</TableCell>}
                                    <TableCell variant="body">{RenderNumber(positionSummary?.unRealizedByAsset[k])}</TableCell>
                                    {hasBase && <TableCell variant="body">{RenderNumber(diffUR)}</TableCell>}
                                    <TableCell variant="body">{RenderNumber(realised + unRealised)}</TableCell>
                                    {hasBase && <TableCell variant="body">{RenderNumber(diffRnUR)}</TableCell>}

                                    <TableCell variant="body" style={{ borderLeft: "1px solid" }}>{RenderNumber(positionSummary?.deltaByAsset[k] ?? 0)}</TableCell>
                                    {/* {!hasBase ? null : <TableCell variant="body">{diffD?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>} */}
                                    <TableCell variant="body">{RenderNumber(positionSummary?.vegaByAsset[k] ?? 0)}</TableCell>
                                    {/* {!hasBase ? null : <TableCell variant="body">{diffV?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</TableCell>} */}

                                    <TableCell variant="body" style={{ borderLeft: "1px solid" }}>{RenderNumber(positionSummary?.stopLossByAsset[k] ?? 0)}</TableCell>
                                    <TableCell variant="body"><Typography variant="body2" color={colorFunc(-(positionSummary?.stopLossBreachesByAsset[k] ?? 0))}>{positionSummary?.stopLossBreachesByAsset[k] ?? 0}</Typography></TableCell>
                                    <TableCell variant="body" >{RenderNumber(positionSummary?.takeProfitByAsset[k] ?? 0)}</TableCell>
                                    <TableCell variant="body"><Typography variant="body2" color={colorFunc((positionSummary?.takeProfitBreachesByAsset[k] ?? 0))}>{positionSummary?.takeProfitBreachesByAsset[k] ?? 0}</Typography></TableCell>
                                </TableRow>
                            })}
                            <TableRow key="rzk-totalz">
                                <TableCell variant="head">Total</TableCell>
                                <TableCell variant="head">{RenderNumber(totalR)}</TableCell>
                                {hasBase && <TableCell variant="head">{RenderNumber(totalDiffR)}</TableCell>}
                                <TableCell variant="head">{RenderNumber(totalUR)}</TableCell>
                                {hasBase && <TableCell variant="head">{RenderNumber(totalDiffUR)}</TableCell>}
                                <TableCell variant="head">{RenderNumber(totalRnUR)}</TableCell>
                                {hasBase && <TableCell variant="head">{RenderNumber(totalDiffRnUR)}</TableCell>}

                                <TableCell variant="head" style={{ borderLeft: "1px solid" }}>{RenderNumber(totalD)}</TableCell>
                                {/* {!hasBase ? null : <TableCell variant="head">{totalDiffD)}</TableCell>} */}
                                <TableCell variant="head">{RenderNumber(totalV)}</TableCell>
                                {/* {!hasBase ? null : <TableCell variant="head">{totalDiffV)}</TableCell>} */}

                                <TableCell variant="head" style={{ borderLeft: "1px solid" }}>{RenderNumber(positionSummary?.totalSl)}</TableCell>
                                <TableCell variant="head"><Typography variant="body2" color={colorFunc(-(positionSummary?.totalSlBreaches ?? 0))}>{positionSummary?.totalSlBreaches ?? 0}</Typography></TableCell>
                                <TableCell variant="head">{RenderNumber(positionSummary?.totalTp)}</TableCell>
                                <TableCell variant="head"><Typography variant="body2" color={colorFunc((positionSummary?.totalTpBreaches ?? 0))}>{positionSummary?.totalTpBreaches ?? 0}</Typography></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* <Grid item>
                <TableContainer>
                    <Table component={Paper} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head">Asset</TableCell>
                                <TableCell variant="head">Delta</TableCell>
                                {!hasBase ? null : <TableCell variant="head">(Change)</TableCell>}
                                <TableCell variant="head">Vega</TableCell>
                                {!hasBase ? null : <TableCell variant="head">(Change)</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetSort.filter(k => k !== null).map((k, ix) => {
                                totalD += positionSummary?.deltaByAsset[k] ?? 0;
                                totalV += positionSummary?.vegaByAsset[k] ?? 0;
                                var diffD = hasBase ? (positionSummary?.deltaByAsset[k] ?? 0) - (baselineSummary?.deltaByAsset[k] ?? 0) : 0;
                                var diffV = hasBase ? (positionSummary?.vegaByAsset[k] ?? 0) - (baselineSummary?.vegaByAsset[k] ?? 0) : 0;
                                totalDiffD += diffD;
                                totalDiffV += diffV;
                                return <TableRow key={`riskz-${ix}`}>
                                    <TableCell variant="body" className={"PositionSummaryTabTableCell--" + k}>{k}</TableCell>
                                    <TableCell variant="body">{(positionSummary?.deltaByAsset[k] ?? 0))}</TableCell>
                                    {!hasBase ? null : <TableCell variant="body">{diffD?)}</TableCell>}
                                    <TableCell variant="body">{(positionSummary?.vegaByAsset[k] ?? 0))}</TableCell>
                                    {!hasBase ? null : <TableCell variant="body">{diffV?)}</TableCell>}
                                </TableRow>
                            })}
                            <TableRow key="rizzzk-totalz">
                                <TableCell variant="head">Total</TableCell>
                                <TableCell variant="head">{totalD?)}</TableCell>
                                {!hasBase ? null : <TableCell variant="head">{totalDiffD)}</TableCell>}
                                <TableCell variant="head">{totalV?)}</TableCell>
                                {!hasBase ? null : <TableCell variant="head">{totalDiffV)}</TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid> */}
            {/* <Grid item>
                <TableContainer>
                    <Table component={Paper} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell variant="head">Asset</TableCell>
                                <TableCell variant="head">Stop Loss</TableCell>
                                <TableCell variant="head">Take Profit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetSort.filter(k => k !== null).map((k, ix) => {
                                return <TableRow key={`sltpsss-${ix}`}>
                                    <TableCell variant="body" className={"PositionSummaryTabTableCell--" + k}>{k}</TableCell>
                                    <TableCell variant="body">{(positionSummary?.stopLossByAsset[k] ?? 0))}</TableCell>
                                    <TableCell variant="body">{(positionSummary?.takeProfitByAsset[k] ?? 0))}</TableCell>
                                </TableRow>
                            })}
                            <TableRow key="sltpszz-totalz">
                                <TableCell variant="head">Total</TableCell>
                                <TableCell variant="head">{positionSummary?.totalSl?)}</TableCell>
                                <TableCell variant="head">{positionSummary?.totalTp?)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid> */}
        </Grid></ThemeProvider></CssBaseline>
    }

    return <div style={{ display: "flex", height: `${summaryHeight}px`, flexDirection: "column", justifyContent: "center" }}>{RenderSummary(summaryData, baselineSummaryData)}</div>
}