import moment from 'moment';
import { Moment } from 'moment';
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import { get, put } from '../utils/httpUtils';
import { CalculatedAccountBalance, CashTransaction, Fund, NavBuilderConfig, NavDiffReport, NavRecord, NavSummary, SubsReds } from './navModels';

export class LoadFundsEvent extends TypedEvent<Fund[]> { }
export class LoadSubsRedsEvent extends TypedEvent<SubsReds[]> { }
export class LoadNavsEvent extends TypedEvent<NavRecord[]> { }
export class LoadNavConfigsEvent extends TypedEvent<{ [firmId: number]: NavBuilderConfig }> { }
export class NewNavSummaryEvent extends TypedEvent<NavSummary[]> { }
export class NewNavDiffEvent extends TypedEvent<NavDiffReport> { }
export class NewFeederMasterNavSummaryEvent extends TypedEvent<{ [fund: string]: NavSummary[] }> { }
export class NewFeederMasterNavDiffEvent extends TypedEvent<{ [fund: string]: NavDiffReport }> { }
export class NewCashTransactionsEvent extends TypedEvent<CashTransaction[]> { }
export class NewCashTransactionEvent extends TypedEvent<CashTransaction> { }
export class NewAccountBalanceEvent extends TypedEvent<CalculatedAccountBalance[]> { }
export class NavsRequestedEvent extends TypedEvent<boolean> { }

export async function LoadFunds() {
    const funds = await get<Fund[]>(`Nav/GetFunds`);
    if (funds.payload) {
        AppDispatcher.dispatch(new LoadFundsEvent(funds.payload));
    }
    const configs = await get<{ [firmId: number]: NavBuilderConfig }>(`Nav/GetNavConfigs`);
    if (configs.payload) {
        AppDispatcher.dispatch(new LoadNavConfigsEvent(configs.payload));
    }
    await GetSubsReds();
    await GetAllOfficialNavs();
    UpdateLoadingStatus("FundDefinitions", LoadingStatus.Completed);
}

export async function FetchAccountBalances(asOf: Date, entity: number) {
    const balances = await get<CalculatedAccountBalance[]>(`Nav/GetAccountBalances?asOfDate=${moment(asOf).format("yyyy-MM-DD")}&entityId=${entity}&excludeImplied=false`);
    if (balances.payload) {
        AppDispatcher.dispatch(new NewAccountBalanceEvent(balances.payload));
        return balances.payload;
    }
}

export async function GetSubsReds() {
    const navs = await get<SubsReds[]>(`Nav/GetSubsReds`);
    if (navs.payload) {
        AppDispatcher.dispatch(new LoadSubsRedsEvent(navs.payload));
    }
}

export async function GetAllOfficialNavs() {
    const navs = await get<NavRecord[]>(`Nav/GetAllNavs`);
    if (navs.payload) {
        AppDispatcher.dispatch(new LoadNavsEvent(navs.payload));
        return navs.payload;
    }
}

export async function GetNavsForFund(fund: string) {
    const navs = await get<NavRecord[]>(`Nav/GetNavsForFund/${fund}`);
    if (navs.payload) {
        AppDispatcher.dispatch(new LoadNavsEvent(navs.payload));
        return navs.payload;
    }
}

export async function GetNavsForShareClass(fund: string, shareClass: string) {
    const navs = await get<NavRecord[]>(`Nav/GetNavsForShareClass/${fund}/${shareClass}`);
    if (navs.payload) {
        AppDispatcher.dispatch(new LoadNavsEvent(navs.payload));
        return navs.payload;
    }
}

export async function ComputeNavs(setName: string = undefined, partyId: number = undefined) {
    var url = setName ? `Nav/BuildNavs/${setName}` : `Nav/BuildNavs/default`;
    if (partyId)
        url = `${url}?partyId=${partyId}`;
    const navs = await get<NavSummary[]>(url);
    if (navs.payload) {
        AppDispatcher.dispatch(new NewNavSummaryEvent(navs.payload));
    }
}

export async function ComputeFeederMasterNavs(setName: string = undefined) {
    var url = setName ? `Nav/BuildFeederMasterNavs/${setName}` : `Nav/BuildFeederMasterNavs/default`;
    const navs = await get<{ [fund: string]: NavSummary[] }>(url);
    if (navs.payload) {
        AppDispatcher.dispatch(new NewFeederMasterNavSummaryEvent(navs.payload));
    }
}

export function NavsRequested() {
    AppDispatcher.dispatch(new NavsRequestedEvent(true));
}

export async function ComputeNavDiff(startDate: Date, endDate: Date, setName: string = undefined) {
    var url = setName ? `Nav/ComputeNavDiff/${setName}/${startDate}/${endDate}` : `Nav/ComputeNavDiff/default/${startDate}/${endDate}`;
    const navs = await get<NavDiffReport>(url);
    if (navs.payload) {
        AppDispatcher.dispatch(new NewNavDiffEvent(navs.payload));
    }
}

export async function ComputeFeederMasterNavDiff(startDate: Moment, endDate: Moment, setName: string = undefined) {
    var url = setName ? `Nav/ComputeFeederMasterNavDiff/${setName}/${startDate.format("yyyy-MM-DD")}/${endDate.format("yyyy-MM-DD")}` : `Nav/ComputeFeederMasterNavDiff/default/${startDate.format("yyyy-MM-DD")}/${endDate.format("yyyy-MM-DD")}`;
    const navs = await get<{ [fund: string]: NavDiffReport }>(url);
    if (navs.payload) {
        AppDispatcher.dispatch(new NewFeederMasterNavDiffEvent(navs.payload));
    }
}

export async function FlushNavCache() {
    await get<boolean>(`Nav/FlushNavCache`);
}

export async function FlushAllCaches() {
    await get<boolean>(`Positions/FlushAllCaches`);
}

export async function ComputeCashTransactions(setName: string = undefined, partyId: number = undefined, excludeImplied: boolean = true) {
    var url = setName ? `Nav/BuildCash/${setName}` : `Nav/BuildCash/default`;
    url += `?excludeImplied=${excludeImplied}`
    if (partyId)
        url += `&partyId=${partyId}`;
    const cash = await get<CashTransaction[]>(url);
    if (cash.payload) {
        AppDispatcher.dispatch(new NewCashTransactionsEvent(cash.payload));
    }
}

export async function AddNavRecord(record: NavRecord) {
    var response = await put<number>(`Nav/AddNavRecord`, record);
    return (response.payload) ? response.payload : -1;
}

export async function AddOrUpdateSubsReds(record: SubsReds) {
    var response = await put<number>(`Nav/AddOrUpdateSubsReds`, record);
    return (response.payload) ? response.payload : -1;
}

export function UpdatedCashTransaction(trans: CashTransaction) {
    AppDispatcher.dispatch(new NewCashTransactionEvent(trans));
}