import { connectionBase, ConnectionStatus } from '../connections/connectionStatusModel';
import { NewCandlesRecieved, NewCandleSummariesRecieved } from './marketDataActions';
import { Candle } from './models/candle';
import { GetCandleTickersByAssetResponse, GetCandleTickersResponse, NewCandlesObject } from './models/getCandleTickersResponse';

const hubLocation = "marketDataHub";

class MarketDataConnection extends connectionBase {

    constructor() {
        super(hubLocation, "MarketDataConnection");
    }

    public beforeConnect() { }
    public afterConnect() { }
    public afterReconnect() { }
    public onStatusChange(status: ConnectionStatus) { }


    public async loadTickersByAsset(setName: string) {
        var tickers = await this.getTickersByAsset(setName);
        NewCandleSummariesRecieved(tickers);
    }

    public async loadCandles(setName: string, candleLength: number, ticker: string) {
        var candles = await this.getCandles(setName, candleLength, ticker);
        NewCandlesRecieved({
            candles: candles,
            candleLength: candleLength,
            ticker: ticker
        } as NewCandlesObject);
    }

    private async getTickersByAsset(setName: string): Promise<GetCandleTickersByAssetResponse> {
        try {
            await this.connectPromise;
            return await this.hubConnection.invoke("GetTickersByAsset", setName);
        }
        catch (error) {
            console.log(error);
        }
        return null;
    }

    private async getTickers(setName: string): Promise<GetCandleTickersResponse> {
        try {
            return await this.hubConnection.invoke("GetTickers", setName);
        }
        catch (error) {
            console.log(error);
        }

        return null;
    }

    private async getCandles(setName: string, candleLength: number, ticker: string): Promise<Candle[]> {
        try {
            return await this.hubConnection.invoke("GetCandles", setName, candleLength, ticker);
        }
        catch (error) {
            console.log(error);
        }

        return null;
    }
}

const marketDataConnection = new MarketDataConnection();
export default marketDataConnection;