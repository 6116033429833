import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../authConfig";
import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import FluxStore from "../FluxStore";
import { BootStrapAuthLoadedEvent } from "./bootstrapActions";
import { BootStrapState } from "./bootstrapModels";

class BootStrapStore extends FluxStore<BootStrapState>{
    constructor(dispatcher : typeof AppDispatcher){
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            AuthInfo : undefined,
            PublicClientInfo : undefined,
            BootStrapUrl : { apiLocation : "./"}
        }));
    }

    private onDispatchHandler(action: Event){
        if(action instanceof BootStrapAuthLoadedEvent){
            const {payload} = action;
            this.state.AuthInfo = payload;
            msalConfig.auth = this.state.AuthInfo;
            loginRequest.scopes = this.state.AuthInfo.scopes;
            this.state.PublicClientInfo = new PublicClientApplication(msalConfig);
            this.emitChange();
        }
    }

    public getState(){
        return this.state;
    }
}

const bootStrapStoreInstance = new BootStrapStore(AppDispatcher);
export default bootStrapStoreInstance;