import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

export interface WrappedCheckBoxProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    value: boolean,
    style?: React.CSSProperties,
    label?: string,
    size?: "small" | "medium",
    id?: string,
    name?: string,
    disabled?: boolean
}

export class WrappedCheckBox extends React.Component<WrappedCheckBoxProps, {}>
{
    render() {
        return (<FormGroup >
            <FormControlLabel
                style={this.props.style}
                label={this.props.label}
                control={
                    <Checkbox
                        size={this.props.size ?? "small"}
                        id={this.props.id}
                        name={this.props.name}
                        //value={this.props.value}
                        checked={this.props.value}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                    />
                } />
        </FormGroup>)
    }
}

