import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackGammaMatrix } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { GridCellParams, GridOverlay } from '@mui/x-data-grid-pro';
import riskStoreInstance from './riskStore';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { CubeKeys, CubeToMatrix, FilterCube } from '../qwack/cubeUtils';
import _ from 'lodash';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { Data, Layout } from 'plotly.js';
import Plot from 'react-plotly.js';

interface RiskMatrixViewState {
    resultCube: TO_ResultCube | undefined,
    mainRef: RefObject<HTMLDivElement>,
    nStepsPrice: number | undefined,
    nStepsTime: number | undefined,
    stepSizePrice: number | undefined,
    assetId: number | undefined,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    units: string,
    selectedMetric: string
}

export interface RiskMatrixViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const MetricUnits = ["Currency"];

const Metrics = ["Delta", "PV"];

export class RiskMatrixView extends React.Component<RiskMatrixViewProps, RiskMatrixViewState>{
    eventSubscriptionRisk: FBEmitter.EventSubscription | undefined;
    constructor(props: RiskMatrixViewProps) {
        super(props)
        var asOf = props.getState("gl_asOf");
        var nSteps = props.getState("gl_nSteps");
        var nStepsTime = props.getState("gl_nStepsTime");
        var stepSize = props.getState("gl_stepSize");
        var assetId = props.getState("gl_assetId");
        this.state = {
            resultCube: undefined,
            nStepsPrice: nSteps ? Number(nSteps) : undefined,
            nStepsTime: nStepsTime ? Number(nStepsTime) : undefined,
            stepSizePrice: stepSize ? Number(stepSize) : undefined,
            assetId: assetId ? Number(assetId) : undefined,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            rowExpanded: undefined,
            selectedSet: DefaultSetName,
            units: MetricUnits[0],
            selectedMetric: Metrics[0]
        };;
        this.updateLadder = this.updateLadder.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.controls = this.controls.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionRisk = riskStoreInstance.addChangeListener(this.updateLadder);
        this.updateLadder();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionRisk) {
            this.eventSubscriptionRisk.remove();
            this.eventSubscriptionRisk = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("gl_asOf", dStr);
        this.updateLadder(d);
        this.setState({ asOf: d, });
    }

    async calcMatrix() {
        this.setState({ awaitingRefresh: true, resultCube: null });
        const { selectedSet, asOf, stepSizePrice, nStepsPrice, nStepsTime, assetId, selectedMetric } = this.state;
        var metricStr = selectedMetric === "Delta" ? "QwackGammaVsTime" : "QwackPvMatrixVsTime";
        await ComputeQwackGammaMatrix(asOf.toDate(), assetId.toString(), stepSizePrice, nStepsPrice, nStepsTime, selectedSet, metricStr);
    }

    updateLadder(asOf?: Moment, set?: string, assetId?: number) {
        if (!asOf)
            asOf = this.state.asOf;

        if (!set)
            set = this.state.selectedSet;

        if (!assetId)
            assetId = this.state.assetId;

        var metricStr = this.state.selectedMetric === "Delta" ? "QwackGammaVsTime" : "QwackPvMatrixVsTime";

        let cube0 = riskStoreInstance.getQwackLadder(asOf?.toDate(), assetId, set, metricStr);
        var refreshDone = (!this.state.awaitingRefresh) || cube0;
        this.setState({ resultCube: cube0, awaitingRefresh: !refreshDone });
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }


    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toFixed(4)}</div>
        }
        else
            return <div></div>;
    }

    controls() {
        const { awaitingRefresh, asOf, selectedSet, assetId, stepSizePrice, nStepsPrice, nStepsTime, selectedMetric } = this.state;
        var availableInsIds = listedInstrumentStoreInstance.getInstruments().filter(l => l?.type === "Index").map(l => l.listedInstrumentId);
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                        <Grid item container spacing={1} style={{ width: "90%" }}>
                            <Grid item>
                                <WrappedDatePicker
                                    style={{ width: "120px" }}
                                    value={asOf?.toDate()}
                                    onChange={this.onChangeDate}
                                    minDate={EarliestDate}
                                    emptyLabel="Live"
                                    clearLabel="Live"
                                    label={"As-Of"} />
                            </Grid>
                            <Grid item>
                                <MarketDataSetSelector
                                    id="set"
                                    name="set"
                                    label="Set"
                                    value={selectedSet}
                                    onChange={(set: string) => {
                                        this.props.onChangeState("selectedSet", set)
                                        this.props.onChangeState("selectedSet", set)
                                        this.updateLadder(null, set);
                                        this.setState({ selectedSet: set });
                                    }} />
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "100px" }}
                                    id="metric"
                                    name="metric"
                                    label="Metric"
                                    value={selectedMetric}
                                    onChange={(m) => {
                                        var metric = m.target.value;
                                        this.props.onChangeState("gl_selectedMetric", metric)
                                        this.setState({ selectedMetric: metric });
                                    }}>
                                    {Metrics.map(a =>
                                        <MenuItem key={"mex_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    style={{ width: "200px" }}
                                    id="underlying"
                                    name="underlying"
                                    label="Underlying"
                                    value={assetId}
                                    onChange={(d) => {
                                        var ul = d.target.value as number;
                                        this.props.onChangeState("gl_assetId", ul.toString())
                                        this.setState({ assetId: ul });
                                    }}>
                                    {availableInsIds.map(a =>
                                        <MenuItem key={"ulx_" + a} value={a}>{listedInstrumentStoreInstance.getInstrumentById(a)?.description?.replaceAll(" Underlying Index", "")}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "120px", height: "30px" }}
                                    label={"Price Step Size"}
                                    value={stepSizePrice}
                                    placeholder="Price Step Size"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        var stepS = parseFloat(e.target.value);
                                        this.props.onChangeState("gl_stepSize", stepS.toString())
                                        this.setState({ stepSizePrice: stepS });
                                    }} />
                            </Grid>
                            <Grid item >
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "120px", height: "30px" }}
                                    label={"# Price Steps"}
                                    value={nStepsPrice}
                                    placeholder="# Price Steps"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        var stepS = parseInt(e.target.value);
                                        this.props.onChangeState("gl_nSteps", stepS.toString())
                                        this.setState({ nStepsPrice: stepS });
                                    }} />
                            </Grid>
                            <Grid item >
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "120px", height: "30px" }}
                                    label={"# Time Steps"}
                                    value={nStepsTime}
                                    placeholder="# Time Steps"
                                    onChange={(e) => {
                                        var stepS = parseInt(e.target.value);
                                        this.props.onChangeState("gl_nStepsTime", stepS.toString())
                                        this.setState({ nStepsTime: stepS });
                                    }} />
                            </Grid>
                            <Grid item>
                                <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcMatrix()}>Compute</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    formatNumberVal(val: any) {
        if (!val)
            return null;
        const valAsNum = Number(val);
        if (!isNaN(valAsNum)) {
            const formatOps = { maximumFractionDigits: 0 };

            return Math.abs(valAsNum) < 0.1 ? 0 : valAsNum.toLocaleString(undefined, formatOps);
        }

        var valAsString = val as string;
        if (valAsString)
            return valAsString.includes("~") ? valAsString.split("~")[1] : valAsString

        return val;
    }


    render() {
        const { resultCube, selectedMetric } = this.state;
        var metricCol = resultCube?.fieldNames?.indexOf("Metric");
        var metric = resultCube ? resultCube.rows[0].metaData[metricCol] ?? "PV" : selectedMetric;
        var gridTitle = metric === "PV" ? "PV Matrix" : "Gamma Matrix"
        var chartTitle = metric === "PV" ? "Change in PV" : "Change in Δ"
        let matrix = new Array<Array<any>>();
        let chartData = new Array<any>();
        let pointLabels = new Array<string>();

        var SurfaceChartData: Data[];
        var SurfaceChartLayout: Partial<Layout>;

        if (resultCube) {
            matrix = CubeToMatrix(resultCube, "AxisA", "AxisB");
            pointLabels = CubeKeys(resultCube, "AxisA");
            var scenarios = CubeKeys(resultCube, "AxisB");
            for (var i = 0; i < scenarios.length; i++) {
                var s = { scenario: scenarios[i] };
                for (var p = 0; p < pointLabels.length; p++) {
                    s[pointLabels[p]] = _.sum(FilterCube(resultCube, { AxisB: scenarios[i], AxisA: pointLabels[p] }).rows.map(r => r.value));
                }
                s[chartTitle] = _.sum(FilterCube(resultCube, { Scenario: scenarios[i] }).rows.map(r => r.value));
                chartData.push(s);
            }

            var points = new Array<number[]>();

            scenarios.map((t, eix) => {
                points[eix] = pointLabels.map((p, pix) => {
                    return _.sum(FilterCube(resultCube, { AxisB: t, AxisA: p }).rows.map(r => r.value));
                });
                return null;
            });

            SurfaceChartData = [
                {
                    z: points,
                    type: 'surface',
                    marker: { color: userStoreInstance.GetTheme().chart_color_indic_0 },
                }
            ] as Data[];

            SurfaceChartLayout = {
                title: undefined,
                paper_bgcolor: userStoreInstance.GetTheme().background_color,
                scene: {
                    camera: { eye: { x: 1.5, y: -1.5, z: 0.8 } },
                    xaxis: {
                        title: "Price Change",
                        ticktext: pointLabels.map(p => p.split('~')[1]),
                        tickvals: pointLabels.map((p, pix) => pix),
                        color: userStoreInstance.GetTheme().color
                    },
                    yaxis: {
                        title: "T",
                        ticktext: scenarios,
                        tickvals: scenarios.map((p, pix) => pix),
                        range: [0, scenarios.length],
                        color: userStoreInstance.GetTheme().color
                    },
                    zaxis: {
                        title: chartTitle,
                        //tickformat: "~%",
                        color: userStoreInstance.GetTheme().color
                    },
                    bgcolor: userStoreInstance.GetTheme().background_color
                },
                margin: {
                    pad: 1,
                    l: 5,
                    r: 25,
                    t: 0,
                    b: 5,
                }
            }
        }

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="DeltaGammaContainer">
                        <div className="RiskControls">{this.controls()}</div>
                        <div className="RiskMatrixLowerContainer">
                            <div className="RiskMatrix">
                                <Typography variant="h6">{gridTitle}</Typography>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead />
                                        <TableBody>
                                            {matrix.map((r, ixr) => {
                                                return <TableRow>
                                                    {r.map((c, ixc) => <TableCell align="center" className={ixr === 0 || ixc === 0 ? "HeaderRow" : "CenteredCell"}>{this.formatNumberVal(c)}</TableCell>)}
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="RiskMatrixChart">
                                {SurfaceChartData?.length > 0 && <Plot
                                    data={SurfaceChartData}
                                    layout={SurfaceChartLayout}
                                    config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                    style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                    useResizeHandler={true}
                                />}
                            </div>
                        </div>
                    </div></ThemeProvider>
            </StyledEngineProvider >
        );
    }
}
