import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, Grid } from '@mui/material';
import moment from 'moment';
import { SubscribeToTicksById } from '../accelerator/acceleratorActions';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';

interface EngineeringMarketDataState {
    latestTick: number,
    latestTickTime: Date
}

export interface EngineeringMarketDataProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const insId = 604;

export class EngineeringMarketDataTab extends React.Component<EngineeringMarketDataProps, EngineeringMarketDataState>{
    eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
    constructor(props: EngineeringMarketDataProps) {
        super(props)
        this.state = {
            latestTick: undefined,
            latestTickTime: undefined
        };;
        this.onTick = this.onTick.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionTicks = marketDataLiveStoreInstance.addChangeListener(this.onTick);
    }

    async componentWillUnmount() {
        if(this.eventSubscriptionTicks){
            this.eventSubscriptionTicks.remove();
        }
    }

    onTick(){
        var latest = marketDataLiveStoreInstance.getTick(insId);
        var latestStamp = marketDataLiveStoreInstance.getTickTime(insId);
        this.setState({latestTick:latest, latestTickTime: latestStamp});
    }

    async connect() {
        await SubscribeToTicksById(604);
    }

    render() {
        const {latestTick, latestTickTime} = this.state;
        return (<div style={{display:"flex", justifyContent:"center", alignContent:"center", width: "calc(100% - 10px)", height:"calc(100% - 10px)"}}>
            <Grid container spacing={2} justifyContent="center" alignContent="center">
                <Grid item><Button variant="outlined" onClick={() => this.connect()}>Connect To Local</Button></Grid>
                <Grid item><div>{moment(latestTickTime).format("hh:MM:ss")} - {latestTick}</div></Grid>
            </Grid>
        </div>)
        
    }
}
