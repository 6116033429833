import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Button,
    Grid,
    LinearProgress,
    MenuItem,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { DefaultSetName, EarliestDate } from '../globalConstants';
import { ComputeQwackPnL } from './riskActions';
import { TO_ResultCube } from '../qwack/to_ResultCube';
import { GridCellParams, GridColDef, GridOverlay, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, DataGridPro } from '@mui/x-data-grid-pro';
import { DefaultColumns } from './riskReportDefaults';
import riskStoreInstance from './riskStore';
import { AggregateCube } from '../qwack/cubeUtils';
import _ from 'lodash';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { MarketDataSetSelector } from '../inputs/marketDataSetSelector';
import { WrappedSelect } from '../inputs/wrappedSelect';

interface QwackPnLTableState {
    resultCube: TO_ResultCube,
    mainRef: RefObject<HTMLDivElement>,
    asOfStart: moment.Moment | null,
    asOfEnd: moment.Moment | null,
    awaitingRefresh: boolean,
    selectedSet: string | undefined,
    currentError: string | undefined,
    aggregationLevel: string | undefined,
}

export interface QwackPnLTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

const DateColumns = ["PointDate"]
const formatOps = { maximumFractionDigits: 0 };
const AggregationLevels = ["StepSubStep", "Step", "Raw"];

export class QwackPnLTable extends React.Component<QwackPnLTableProps, QwackPnLTableState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: QwackPnLTableProps) {
        super(props)
        var asOfStart = props.getState("qp_asOfStart");
        var asOfEnd = props.getState("qp_asOfEnd");
        this.state = {
            resultCube: {} as TO_ResultCube,
            mainRef: React.createRef(),
            asOfStart: asOfStart && asOfStart !== "now" ? moment(asOfStart) : null,
            asOfEnd: asOfEnd && asOfEnd !== "now" ? moment(asOfEnd) : null,
            awaitingRefresh: false,
            selectedSet: DefaultSetName,
            currentError: undefined,
            aggregationLevel: AggregationLevels[0]
        };;
        this.updatePnL = this.updatePnL.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = riskStoreInstance.addChangeListener(this.updatePnL);
        this.updatePnL();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeDate(d: moment.Moment, isStart: boolean) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState(isStart ? "qp_asOfStart" : "qp_asOfEnd", dStr)
        if (isStart) {
            this.setState({ asOfStart: d, });
            this.updatePnL(d);
        }
        else {
            this.setState({ asOfEnd: d, });
            this.updatePnL(null, d);
        }
    }

    async calcPnL() {
        this.setState({ awaitingRefresh: true, resultCube: null });
        const { selectedSet, asOfStart, asOfEnd } = this.state;
        await ComputeQwackPnL(asOfStart.toDate(), asOfEnd.toDate(), selectedSet);
    }

    updatePnL(asOfStart?: Moment, asOfEnd?: Moment, set?: string) {
        if (!asOfStart)
            asOfStart = this.state.asOfStart;
        if (!asOfEnd)
            asOfEnd = this.state.asOfEnd;
        if (!set)
            set = this.state.selectedSet;

        let errors = riskStoreInstance.getQwackRiskError(asOfEnd?.toDate(), set, "PnLAttribution");
        if (errors) {
            this.setState({ currentError: "There was an error in calculation", awaitingRefresh: false });
        }
        else {
            let cube = riskStoreInstance.getQwackPnLAttribution(asOfStart?.toDate(), asOfEnd?.toDate(), set);
            this.props.onChangeState("awaitingRefresh", "false");
            this.setState({ resultCube: cube, awaitingRefresh: false, currentError: undefined });
        }
    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, formatOps)}</div>
        }
        else
            return <div></div>;
    }
    customToolbar(props: any) {
        const { awaitingRefresh, asOfStart, asOfEnd, selectedSet, currentError, aggregationLevel } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                            <Grid item container spacing={1} style={{ width: "50%" }}>
                                <Grid item>
                                    <WrappedDatePicker
                                        key="startPicker"
                                        style={{ width: "120px" }}
                                        value={asOfStart?.toDate()}
                                        disableFuture
                                        onChange={(d) => this.onChangeDate(d, true)}
                                        minDate={EarliestDate}
                                        emptyLabel="Live"
                                        clearLabel="Live"
                                        label={"Start"} />
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        key="endPicker"
                                        style={{ width: "120px" }}
                                        value={asOfEnd?.toDate()}
                                        onChange={(d) => this.onChangeDate(d, false)}
                                        disableFuture
                                        minDate={EarliestDate}
                                        emptyLabel="Live"
                                        clearLabel="Live"
                                        label={"End"}
                                    />
                                </Grid>
                                <Grid item>
                                    <MarketDataSetSelector
                                        id="set"
                                        name="set"
                                        label="Set"
                                        value={selectedSet}
                                        onChange={(set: string) => {
                                            this.props.onChangeState("selectedSet", set)
                                            this.updatePnL(null, null, set);
                                            this.setState({ selectedSet: set });
                                        }} />
                                </Grid>
                                <Grid item>
                                    <Button className="MuiButton-outlined PltfmButtonLite" disabled={awaitingRefresh} onClick={() => this.calcPnL()}>Compute</Button>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="Aggregation"
                                        name="Aggregation"
                                        label="Aggregation"
                                        value={aggregationLevel}
                                        onChange={(d) => {
                                            var set = d.target.value as string;
                                            this.props.onChangeState("aggregationLevel", set)
                                            this.setState({ aggregationLevel: set });
                                        }}>
                                        {AggregationLevels.map(a =>
                                            <MenuItem key={"aggre_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                {currentError ? <Grid item>
                                    <Typography>{currentError}</Typography>
                                </Grid> : null}
                            </Grid>
                            <Grid item container spacing={1} justifyContent="flex-end" style={{ width: "50%" }}>
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    getColDef(name: string) {
        if (DateColumns.includes(name))
            return { field: name, hide: !DefaultColumns("PnLAttribution").includes(name), filterable: true, width: 150, flex: 1, headerName: name, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" } as GridColDef;

        return { field: name, hide: !DefaultColumns("PnLAttribution").includes(name), filterable: true, width: 150, flex: 1, headerName: name, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" } as GridColDef;
    }

    render() {
        const { resultCube, awaitingRefresh, aggregationLevel } = this.state;

        var cube = _.cloneDeep(resultCube);
        if (cube?.rows && aggregationLevel === "StepSubStep")
            cube = AggregateCube(cube, ["Step", "SubStep", "SubSubStep"]);
        else if (cube?.rows && aggregationLevel === "Step")
            cube = AggregateCube(cube, ["Step"]);

        const columns: GridColDef[] = cube && cube.fieldNames ? [
            ...cube.fieldNames.map(f => this.getColDef(f)),
            { field: 'value', width: 175, filterable: true, flex: 1, headerName: 'Value', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: this.renderNumberCell },
        ] : new Array<GridColDef>();

        const rows = cube && cube.fieldNames ? cube.rows.filter(r => Math.abs(r.value) > 0.001).map((r, ix) => {
            var rowA = { id: ix, value: r.value };
            for (var i = 0; i < cube.fieldNames.length; i++) {
                if (cube.fieldNames[i] === "Underlying")
                    rowA[cube.fieldNames[i]] = r.metaData[i].replaceAll(" Underlying Index", "")
                else if (!isNaN(Number(r.metaData[i])) && listedInstrumentStoreInstance.getInstrumentById(Number(r.metaData[i])))
                    rowA[cube.fieldNames[i]] = listedInstrumentStoreInstance.getInstrumentById(Number(r.metaData[i]))?.description?.replaceAll(" Underlying Index", "")
                else
                    rowA[cube.fieldNames[i]] = r.metaData[i];
            }
            return rowA;
        }) : new Array<any>();
        rows.push({ id: 99999, Step: "Total", value: _.sum(cube?.rows?.map(r => r.value)) });
        // var matrix = cube?.rows ? CubeToTable(cube, "SubStep", "Step", "PnLTableTitle", "PnLTableCell") : null;

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="NavCalcTableContainer">
                        <div className="QwackPnLTable">
                            <DataGridPro
                                className="QwackPvTableGrid"
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: this.customToolbar,
                                    LoadingOverlay: this.loading,
                                }}
                                hideFooter
                                loading={awaitingRefresh} />
                        </div>
                        {/* <div className="QwackPvTable">
                            {matrix}
                        </div> */}
                    </div></ThemeProvider>
            </StyledEngineProvider>
        );
    }
}
