import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
    Tabs,
    Tab,
    Paper,
} from '@mui/material';
import FBEmitter from 'fbemitter';
import React from 'react';
import userStoreInstance from '../user/userStore';
import { getFormTheme } from '../inputs/formCommon';
import { AutorenewOutlined, QueryBuilderOutlined, SaveAltOutlined, UpdateOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import moment from 'moment';
import { BookStr, EarliestDate, StrategyStr } from '../globalConstants';
import { Autocomplete } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { Trade, TradeMetaData } from './tradeModels';
import { MissingMetaDataQuery } from '../listedInstruments/listedInstrumentModels';
import tradesStoreInstance from './tradeStore';
import { GetMetaSuggestions, GetTradesNeedingMetaData, UpdateTradeMetaData } from './tradeActions';
import { InstrumentTypes, NullType } from '../listedInstruments/listedInstrumentAdvSearch';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { WrappedDateTimePicker } from '../inputs/wrappedDateTimePicker';
//import _ from 'lodash';

interface TradeMetaUpdaterState {
    selectedTrades: Trade[],
    matchingTrades: Trade[],
    checked: number[];
    checkAll: boolean;
    queryRunning: boolean;
    commitRunning: boolean;
    query: MissingMetaDataQuery;
    massUpdateValues: Map<string, string>;
    updateMap: Map<number, string[]>;
    queryError: string | undefined;
    showRunningTotal: boolean;
    selectedTabId: number;
    aggregatedTradeMap: Map<number, number[]>
}

const Category = "AdditionalData";

export class TradeMetaUpdater extends React.Component<{}, TradeMetaUpdaterState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        this.state = {
            selectedTrades: [],
            matchingTrades: [],
            checked: [],
            checkAll: false,
            queryRunning: false,
            commitRunning: false,
            query: { uniqueId: v4(), toDate: moment().toDate(), fromDate: moment().subtract(1, "week").toDate(), metaTypes: [BookStr, StrategyStr], aggregateFills: false } as MissingMetaDataQuery,
            massUpdateValues: new Map<string, string>(),
            updateMap: new Map<number, string[]>(),
            queryError: undefined,
            showRunningTotal: false,
            selectedTabId: 0,
            aggregatedTradeMap: new Map<number, number[]>()
        };;

        this.handleCheckAllToggle = this.handleCheckAllToggle.bind(this);
        this.queryUpdate = this.queryUpdate.bind(this);
        this.renderTradeRow = this.renderTradeRow.bind(this);
        this.onEnterPressed = this.onEnterPressed.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionInstruments = tradesStoreInstance.addChangeListener(this.queryUpdate);
    }

    queryUpdate() {
        const { query, aggregatedTradeMap } = this.state;
        var trades = tradesStoreInstance.getTradeQuery(query.uniqueId);
        if (!trades) {
            var error = tradesStoreInstance.getTradeQueryErrors(query.uniqueId);
            if (error) {
                this.setState({ queryError: error, queryRunning: false });
            }
            return;
        }
        // if(query.aggregateFills){
        //     aggregatedTradeMap.clear();
        //     var tradesByInsAndSide = _.groupBy(ins,(t)=>`${t.listedInstrumentId}:${t.size>0?1:-1}:${t.currency}:${moment(t.executedUtc).startOf('day').format("yyyy-MM-DD")}`);
        //     ins = [];
        //     var count=0;
        //     Object.keys(tradesByInsAndSide).forEach(k => {
        //         //console.log(`${k} ${tradesByInsAndSide[k].length}`)
        //         var insId = Number(k.split(":")[0]);
        //         var ccy = k.split(":")[2];
        //         var date = moment(k.split(":")[3]);
        //         var totalSize = _.sum(tradesByInsAndSide[k].map(t=>t.size));
        //         var price = _.sum(tradesByInsAndSide[k].map(t=>t.size*t.price))/totalSize;
        //         if(isNaN(price))
        //             price = 0;

        //         ins.push({
        //             bookedUtc:date.toDate(),
        //             currency:ccy,
        //             executedUtc:date.toDate(),
        //             listedInstrumentId:insId,
        //             listedInstrument:tradesByInsAndSide[k][0].listedInstrument,
        //             size:totalSize,
        //             price:price,
        //             tradeId:count
        //         } as Trade);
        //         aggregatedTradeMap.set(count,tradesByInsAndSide[k].map(t=>t.tradeId));
        //         count++;
        //     });
        // }
        trades.sort((a,b)=>a.tradeIdModified.localeCompare(b.tradeIdModified)).sort((a,b)=>new Date(a.executedUtc).valueOf()-new Date(b.executedUtc).valueOf());
        this.setState({ matchingTrades: trades, queryRunning: false, selectedTabId: trades.length === 0 ? 0 : 1, queryError: undefined, aggregatedTradeMap });
    }

    private handleToggle = (value: number) => () => {
        const { checked, matchingTrades } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        var selectedTrades = matchingTrades.filter(m => newChecked.includes(m.tradeId));

        this.setState({ checked: newChecked, selectedTrades });
    };

    private handleCheckAllToggle(checked: boolean) {
        const { matchingTrades } = this.state;
        var idsChecked = new Array<number>();
        if (checked) { //moving to all checked
            idsChecked = matchingTrades.map(i => i.tradeId);
        }
        var selectedTrades = checked ? matchingTrades : [];
        this.setState({ checkAll: checked, checked: idsChecked, selectedTrades });
    }

    async runQuery(query: MissingMetaDataQuery) {
        const { massUpdateValues } = this.state;
        massUpdateValues.clear();
        this.setState({ queryRunning: true, checkAll: false, massUpdateValues, checked: [] });
        await GetTradesNeedingMetaData(query);
    }

    renderInstrumentQuery() {
        const { query, queryRunning } = this.state;
        return (<div><ThemeProvider theme={getFormTheme()}>
            <Grid container spacing={2}>
                <Grid item className="ListedInstrumentQueryItem">
                    <TextField
                        size="small"
                        variant="outlined"
                        label={"Meta Filter"}
                        placeholder="Meta Filter"
                        value={query.metaFilter ?? ""}
                        onKeyDown={this.onEnterPressed}
                        onChange={(e) => {
                            var q = this.state.query;
                            q.metaFilter = e.target.value;
                            this.setState({ query: q });
                        }} />
                </Grid>
                <Grid item className="ListedInstrumentQueryItem">
                    <WrappedSelect
                        id="type"
                        name="type"
                        label="InsType"
                        value={query.insType ?? NullType}
                        onChange={(d) => {
                            var q = this.state.query;
                            q.insType = d.target.value as string;
                            if (q.insType === NullType)
                                q.insType = null;
                            this.setState({ query: q });
                        }}>
                        {[NullType, ...InstrumentTypes].map(a =>
                            <MenuItem key={"insTypeX_" + a} value={a}>{a}</MenuItem>)}
                    </WrappedSelect>
                </Grid>
                <Grid item className="ListedInstrumentQueryItem">
                    {query.includeTime ?
                        <WrappedDateTimePicker
                            style={{ width: "180px" }}
                            value={query.fromDate}
                            onChange={(d) => {
                                var q = this.state.query;
                                q.fromDate = d.toDate();
                                this.setState({ query: q });
                            }}
                            disableFuture
                            minDate={EarliestDate}
                            label={"Since"}
                            emptyLabel="(None)" /> :
                        <WrappedDatePicker
                            style={{ width: "120px" }}
                            value={query.fromDate}
                            onChange={(d) => {
                                var q = this.state.query;
                                q.fromDate = d.toDate();
                                this.setState({ query: q });
                            }}
                            disableFuture
                            minDate={EarliestDate}
                            label={"Since"}
                            emptyLabel="(None)" />}
                </Grid>
                <Grid item className="ListedInstrumentQueryItem">
                    {query.includeTime ?
                        <WrappedDateTimePicker
                            style={{ width: "180px" }}
                            value={query.toDate}
                            onChange={(d) => {
                                var q = this.state.query;
                                q.toDate = d.toDate();
                                this.setState({ query: q });
                            }}
                            disableFuture
                            minDate={query.fromDate ?? EarliestDate}
                            label={"Until"}
                            emptyLabel="(None)" /> :
                        <WrappedDatePicker
                            style={{ width: "120px" }}
                            value={query.toDate}
                            onChange={(d) => {
                                var q = this.state.query;
                                q.toDate = d.toDate();
                                this.setState({ query: q });
                            }}
                            disableFuture
                            minDate={query.fromDate ?? EarliestDate}
                            label={"Until"}
                            emptyLabel="(None)" />}
                </Grid>
                <Grid item className="ListedInstrumentQueryItem">
                    <FormControl>
                        <FormControlLabel label="Time?" control={<Checkbox checked={query.includeTime !== undefined ? query.includeTime : false} onChange={(e, c) => { var q = this.state.query; q.includeTime = c; this.setState({ query }) }} />} />
                    </FormControl>
                </Grid>
                <Grid item className="ListedInstrumentQueryItem">
                    <TextField
                        size="small"
                        variant="outlined"
                        label={"Description"}
                        onKeyDown={this.onEnterPressed}
                        value={query.description}
                        placeholder="Description"
                        onChange={(e) => {
                            var q = this.state.query;
                            q.description = e.target.value;
                            this.setState({ query: q });
                        }} />
                </Grid>
                <Grid item className="ListedInstrumentQueryItem">
                    <WrappedSelect
                        id="onlyMissing"
                        name="onlyMissing"
                        label="Results"
                        value={query.returnMissingOnly ? "Only Missing" : "All Trades"}
                        onChange={(d) => {
                            var q = this.state.query;
                            q.returnMissingOnly = (d.target.value as string) === "Only Missing";
                            this.setState({ query: q });
                        }}>
                        <MenuItem key={"insTypeX_All Trades"} value={"All Trades"}>All Trades</MenuItem>
                        <MenuItem key={"insTypeX_Only Missing"} value={"Only Missing"}>Only Missing</MenuItem>
                    </WrappedSelect>
                </Grid>
                {/* <Grid item className="ListedInstrumentQueryItem">
                    <WrappedSelect
                        id="aggregateFills"
                        name="aggregateFills"
                        label="Fills"
                        value={query.aggregateFills ?  "Aggregate" : "All"}
                        onChange={(d) => {
                            var q = this.state.query;
                            q.aggregateFills = (d.target.value as string) === "Aggregate";
                            this.setState({ query: q });
                        }}>
                        <MenuItem key={"insTypeY_All"} value={"All"}>All</MenuItem>
                        <MenuItem key={"insTypeY_Agg"} value={"Aggregate"}>Aggregate</MenuItem>
                    </WrappedSelect>
                </Grid> */}
                <Grid item className="ListedInstrumentQueryItem">
                    <Button variant="outlined" className="PltfmButtonLite" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.query)} disabled={queryRunning}>Run Query</Button>
                </Grid>
                {queryRunning ?
                    <Grid item className="ListedInstrumentQueryItem">
                        <CircularProgress />
                    </Grid> : null}
            </Grid>
        </ThemeProvider></div>);
    }

    async commitChanges() {
        this.setState({ commitRunning: true });
        const { updateMap, matchingTrades } = this.state;
        var touchedIns = Array.from(updateMap.keys());
        var updatedData = touchedIns.flatMap(ti => {
            var touchedTypesForIns = updateMap.get(ti);
            var ins = matchingTrades.filter(m => m.tradeId === ti)[0];
            return touchedTypesForIns.map(tt => {
                var data = ins?.tradeMetaData?.filter(m => m.category === Category && m.type === tt)[0];
                return data;
            });
        });
        await UpdateTradeMetaData(updatedData)
        updateMap.clear();
        this.setState({ updateMap, commitRunning: false });
    }

    private massUpdate() {
        const { query, selectedTrades, massUpdateValues } = this.state;
        query.metaTypes.map(m => {
            var data = massUpdateValues.get(m);
            if (data && data !== null) {
                selectedTrades.map(s => {
                    this.updateOrAddMetaData(s, m, data);
                    return true;
                });
            }
            return true;
        });
        this.setState({ selectedTrades });
    }

    private async suggest() {
        const { selectedTrades, matchingTrades } = this.state;
        var tradeIds = matchingTrades.map(m => m.tradeId);
        var suggestions = await GetMetaSuggestions(tradeIds);
        suggestions.map(m => {
            var trade = matchingTrades.filter(t => t.tradeId === m.tradeId)[0];
            if (m.book)
                this.updateOrAddMetaData(trade, BookStr, m.book);
            if (m.strategy)
                this.updateOrAddMetaData(trade, StrategyStr, m.strategy);
            return true;
        });
        this.setState({ selectedTrades });
    }

    renderUpdateBar() {
        const { massUpdateValues, commitRunning, checked, updateMap, showRunningTotal, matchingTrades } = this.state;
        const nothingSelected = !checked || checked.length === 0;
        const nothingChanged = Array.from(updateMap.keys()).length === 0;

        return (
            <div key="updateBar"><StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2}>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Autocomplete
                                size="small"
                                freeSolo
                                style={{ width: "200px" }}
                                options={Array.from(tradesStoreInstance.getBooks())}
                                id={`mass_update_book`}
                                value={massUpdateValues.get(BookStr) ?? ""}
                                autoSelect
                                onChange={(e, v) => { massUpdateValues.set(BookStr, v); this.setState({ massUpdateValues }); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Book"}
                                        variant="outlined"
                                        error={massUpdateValues.get(BookStr) !== undefined && massUpdateValues.get(BookStr) !== null}
                                        InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                            /></Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Autocomplete
                                size="small"
                                freeSolo
                                style={{ width: "200px" }}
                                options={Array.from(tradesStoreInstance.getStrategies())}
                                id={`mass_update_strat`}
                                value={massUpdateValues.get(StrategyStr) ?? ""}
                                autoSelect
                                onChange={(e, v) => { massUpdateValues.set(StrategyStr, v); this.setState({ massUpdateValues }); }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Theme"}
                                        variant="outlined"
                                        error={massUpdateValues.get(StrategyStr) !== undefined && massUpdateValues.get(StrategyStr) !== null}
                                        InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                            /></Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <FormControl size="small">
                                <FormControlLabel
                                    control={<Checkbox checked={showRunningTotal} onChange={() => this.setState({ showRunningTotal: !showRunningTotal })} name="Running Total" />}
                                    label="Running Total" />
                            </FormControl>
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<AutorenewOutlined />} disabled={(!matchingTrades) || matchingTrades.length === 0} onClick={() => this.suggest()}>Suggest</Button>
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<UpdateOutlined />} disabled={nothingSelected} onClick={() => this.massUpdate()}>Update Selected</Button>
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<SaveAltOutlined />} disabled={commitRunning || nothingChanged} onClick={() => this.commitChanges()}>Commit</Button>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider></div>
        );
    }

    private updateOrAddMetaData(trade: Trade, type: string, data: string) {
        var updated = true;
        if (!trade.tradeMetaData)
            trade.tradeMetaData = [];
        if (!trade.tradeMetaData.some(md => md.type === type && md.category === Category))
            trade.tradeMetaData.push({ category: Category, data: data, type: type, lastUpdated: moment.utc().toDate(), tradeId: trade.tradeId } as TradeMetaData);
        else {
            var existing = trade.tradeMetaData.filter(md => md.type === type && md.category === Category)[0];
            if (existing.data !== data) {
                existing.data = data;
                existing.lastUpdated = moment.utc().toDate();
            }
            else
                updated = false;
        }

        if (updated) {
            var m = this.state.updateMap.get(trade.tradeId);
            if (!m)
                this.state.updateMap.set(trade.tradeId, [type]);
            else
                if (!m.includes(type))
                    m.push(type);
        }
    }

    private hasBeenUpdated(trade: Trade, type: string): boolean {
        return this.state.updateMap.get(trade.tradeId)?.includes(type);
    }

    private getMetaValue(trade: Trade, type: string) {
        if (!trade.tradeMetaData || !trade.tradeMetaData.some(md => md.type === type && md.category === Category))
            return "";

        var existing = trade.tradeMetaData.filter(md => md.type === type && md.category === Category)[0];
        return existing.data
    }

    renderTradeRow(props: ListChildComponentProps, checked: number[]) {
        const { index, style } = props;
        const key = `${index}-tradeRowMeta`
        const { matchingTrades, showRunningTotal } = this.state;
        const s = matchingTrades[index];
        if (s === undefined || s === null)
            return null;
        var runningTotal = showRunningTotal ?
            (index === 0 ? s.size : matchingTrades.slice(0, index + 1).map(m => m.size).reduce((p, v) => p + v))
            : 0;
        const labelId = `checkbox-list-label-${s.tradeId}`;
        return (
            <div className="TradeMetaEditorResultRow" style={style} key={key}>
                <Grid container spacing={2}>
                    <Grid item >
                        <ListItem key={s.tradeId} role={undefined} selected={checked.indexOf(s.tradeId) !== -1} dense button onClick={this.handleToggle(s.tradeId)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(s.tradeId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{ color: userStoreInstance.GetTheme().border_color }}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={s.tradeId} />
                        </ListItem></Grid>
                    <Grid item><TextField size="small" value={moment(s.executedUtc).format("yyyy-MM-DD HH:mm:ss")} variant="filled" label="Time" style={{ width: "175px" }} /></Grid>
                    <Grid item><TextField size="small" value={s.listedInstrument?.description} variant="filled" label="Description" style={{ width: "300px" }} /></Grid>
                    <Grid item><TextField size="small" value={s.size} variant="filled" label="Size" style={{ width: "120px" }} /></Grid>
                    {showRunningTotal ? <Grid item><TextField value={runningTotal} size="small" variant="filled" label="Running" style={{ width: "100px" }} /></Grid> : null}
                    <Grid item><TextField size="small" value={s.price} variant="filled" label="Price" style={{ width: "120px" }} /></Grid>
                    <Grid item><Autocomplete
                        style={{ width: "100px" }}
                        size="small"
                        options={tradesStoreInstance.getBooks()}
                        key={`book-${s.tradeId}`}
                        value={this.getMetaValue(s, BookStr)}
                        autoSelect
                        disableClearable
                        onChange={(e, v) => {
                            this.updateOrAddMetaData(s, BookStr, v);
                            this.setState({ matchingTrades });
                        }}
                        renderInput={(params) => (
                            <TextField
                                key={`book-${s.tradeId}-inner`}
                                {...params}
                                InputLabelProps={{ shrink: true, disableAnimation: true }}
                                label={"Book"}
                                margin="dense"
                                error={this.hasBeenUpdated(s, BookStr)}
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                    /></Grid>
                    <Grid item><Autocomplete
                        style={{ minWidth: "300px" }}
                        size="small"
                        options={tradesStoreInstance.getStrategies()}
                        key={`strat-${s.tradeId}`}
                        value={this.getMetaValue(s, StrategyStr)}
                        autoSelect
                        onChange={(e, v) => {
                            this.updateOrAddMetaData(s, StrategyStr, v);
                            this.setState({ matchingTrades });
                        }}
                        renderInput={(params) => (
                            <TextField
                                key={`strat-${s.tradeId}-inner`}
                                {...params}
                                InputLabelProps={{ shrink: true, disableAnimation: true }}
                                label={"Theme"}
                                margin="dense"
                                error={this.hasBeenUpdated(s, StrategyStr)}
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                    /></Grid>
                </Grid></div>);
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    onEnterPressed(e) {
        if (e.keyCode === 13)
            this.runQuery(this.state.query);
    }

    render() {
        const { matchingTrades, checkAll, checked, queryError, selectedTabId } = this.state;
        return (
            <div className="TradeMetaEditorTabSearch">
                <ThemeProvider theme={getFormTheme()}>
                    <TabContext value={selectedTabId.toString()}>
                        <Tabs key='metaSearchTab' value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                            <Tab
                                classes={{ root: "tabTitle", selected: "tabTitleSelected" }}
                                value={"0"}
                                key={"tabQuery1"}
                                component={Paper}
                                id={"tab0"}
                                label="Search Query" />
                            <Tab
                                classes={{ root: "tabTitle", selected: "tabTitleSelected"  }}
                                value={"1"}
                                key={"tabQuery2"}
                                component={Paper}
                                id={"tab1"}
                                label="Batch Update" />
                        </Tabs>
                        <TabPanel
                            style={{ minHeight: "75px", backgroundColor: userStoreInstance.GetTheme().background_color }}
                            key={"tabPQuery1"}
                            value={"0"}
                            //index={0}
                            children={this.renderInstrumentQuery()} />
                        <TabPanel
                            style={{ minHeight: "75px", backgroundColor: userStoreInstance.GetTheme().background_color }}
                            key={"tabPQuery2"}
                            value={"1"}
                            //index={1}
                            children={this.renderUpdateBar()} />
                    </TabContext>

                    <div className="TradeMetaEditorTabSearchBottomSection">
                        <div className="TradeMetaEditorTabSearchResults">
                            {matchingTrades && matchingTrades.length === 0 ?
                                <div className="TradeMetaEditorNoResults">
                                    {queryError === undefined ?
                                        <Typography variant="h4">No Matches Found</Typography> :
                                        <Typography variant="h4" color="error">{queryError}</Typography>}
                                </div> : null}
                            {matchingTrades && matchingTrades.length > 1 ?
                                <div className="TradeMetaEditorResultRow">
                                    <Grid container spacing={2}>
                                        <Grid item className="TradeMetaEditorListItem"><ListItem key={"selectAll"} divider role={undefined} dense button onClick={(e) => this.handleCheckAllToggle(!checkAll)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checkAll}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    style={{ color: userStoreInstance.GetTheme().border_color }}
                                                    inputProps={{ 'aria-labelledby': "Check all" }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={"checkAll"} primary={"Select All"} secondary={`${matchingTrades.length} Trades`} />
                                        </ListItem></Grid>
                                    </Grid></div>
                                : null}
                            {matchingTrades ? <div className="TradeMetaResultsBlock">
                                <AutoSizer >
                                    {({ height, width }) =>
                                        <FixedSizeList height={height} width={width} itemSize={60} itemCount={matchingTrades.length} key="metaTradeList">
                                            {(props) => this.renderTradeRow(props, checked)}
                                        </FixedSizeList>}
                                </AutoSizer>
                            </div> : null}
                        </div>
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}
