import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { ListedInstrument, ListedInstrumentStoreState, ListedInstrumentSummary } from './listedInstrumentModels';
import { GetInstrumentById, GetInstrumentsById, ListedInstrumentBatchEvent, ListedInstrumentLoadedEvent, ListedInstrumentMetaDataLoadedEvent, ListedInstrumentRootInsLoadedEvent, ListedInstrumentSummaryLoadedEvent, ListedInstrumentUpdatedBatchEvent, ListedInstrumentUpdatedEvent } from './listedInstrumentActions';


class ListedInstrumentStore extends FluxStore<ListedInstrumentStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            ins: new Map<number, ListedInstrument>(),
            existingMetaData: new Map<string, Map<string, string[]>>(),
            insQueries: new Map<string, ListedInstrument[]>(),
            summaries: new Map<number, ListedInstrumentSummary>(),
            rootIns: [],
            requestedInsIds: new Set<number>()
        }));
    }

    private r : (value : any) => void = undefined;
    
    public InstrumentsAreLoaded : Promise<boolean> = new Promise<boolean>((resolve, reject) => {
        this.r = resolve;
    });


    private onDispatchHandler(action: Event) {
        if (action instanceof ListedInstrumentLoadedEvent) {
            const { payload } = action;
            this.state.ins = payload;
            this.emitChange();
            this.r(undefined);
        }
        else if (action instanceof ListedInstrumentSummaryLoadedEvent) {
            const { payload } = action;
            payload.forEach(s => {
                this.state.summaries.set(s.id, s);
            });
            this.emitChange();
        }
        else if (action instanceof ListedInstrumentUpdatedEvent) {
            const { payload } = action;

            if (payload.underlyingId && !payload.underlying)
                payload.underlying = this.state.ins.get(payload.underlyingId);

            this.state.ins.set(payload.listedInstrumentId, payload);

            if (payload.metaData) {
                this.updateMetaDataCache(payload);
            }
            this.emitChange();
        }
        else if (action instanceof ListedInstrumentMetaDataLoadedEvent) {
            const { payload } = action;
            var metaCategories = new Array<string>();
            Object.keys(payload.metaValues).forEach(c => { metaCategories.push(c) });
            for (var i = 0; i < metaCategories.length; i++) {
                let c = metaCategories[i];
                this.state.existingMetaData.set(c, new Map<string, string[]>());
                var metaTypes = Object.keys(payload.metaValues[c]).map(k => k);
                for (var j = 0; j < metaTypes.length; j++) {
                    let k = metaTypes[j];
                    this.state.existingMetaData.get(c).set(k, payload.metaValues[c][k]);
                }
            }
            this.emitChange();
        }
        else if (action instanceof ListedInstrumentUpdatedBatchEvent) {
            const { payload } = action;
            payload.forEach(ins => {

                if (ins.underlyingId && !ins.underlying)
                    ins.underlying = this.state.ins.get(ins.underlyingId);
                this.state.ins.set(ins.listedInstrumentId, ins);

                // if (ins.metaData) {
                //     this.updateMetaDataCache(ins);
                // }
            })
            this.updateMetaDataCacheMulti(payload);
            this.emitChange();
        }
        else if (action instanceof ListedInstrumentBatchEvent) {
            const { payload } = action;
            this.state.insQueries.set(payload.uniqueId, payload.instruments);
            payload.instruments.forEach(ins => {
                if (ins.underlyingId && !ins.underlying)
                    ins.underlying = this.state.ins.get(ins.underlyingId);

                this.state.ins.set(ins.listedInstrumentId, ins);
            });
            this.emitChange();
        }
        else if (action instanceof ListedInstrumentRootInsLoadedEvent) {
            const { payload } = action;
            this.state.rootIns = payload;
            payload.forEach(ins => {
                this.state.ins.set(ins.listedInstrumentId, ins);
            });
            this.emitChange();
        }
        //

    }

    updateMetaDataCache(ins: ListedInstrument) {
        if (ins.metaData) {
            var metaCategories = Array.from(new Set(ins.metaData.map(c => c.category)));
            for (var i = 0; i < metaCategories.length; i++) {
                let c = metaCategories[i];
                var existing = this.state.existingMetaData.get(c);
                if (!existing) {
                    existing = new Map<string, string[]>();
                    this.state.existingMetaData.set(c, existing);
                }
                var metaTypes = Array.from(new Set(ins.metaData.map(c => c.type)));
                for (var j = 0; j < metaTypes.length; j++) {
                    let k = metaTypes[j];
                    var existingForType = existing.get(k);
                    if (!existingForType) {
                        existingForType = new Array<string>();
                        existing.set(k, existingForType);
                    }
                    existingForType = Array.from(new Set(existingForType.concat(ins.metaData.filter(m => m.category === c && m.type === k).map(m => m.data))));
                    existing.set(k, existingForType);
                }
            }
        }
    }

    updateMetaDataCacheMulti(ins: ListedInstrument[]) {

        var metaCategories = Array.from(new Set(ins.flatMap(i => i.metaData.map(c => c.category))));
        for (var i = 0; i < metaCategories.length; i++) {
            let c = metaCategories[i];
            var existing = this.state.existingMetaData.get(c);
            if (!existing) {
                existing = new Map<string, string[]>();
                this.state.existingMetaData.set(c, existing);
            }
            var metaTypesForCategory = Array.from(new Set(ins.flatMap(i => i.metaData.filter(m => m.category === c).map(m => m.type))));
            for (var j = 0; j < metaTypesForCategory.length; j++) {
                let k = metaTypesForCategory[j];
                var existingForType = existing.get(k);
                if (!existingForType) {
                    existingForType = new Array<string>();
                    existing.set(k, existingForType);
                }
                existingForType = Array.from(new Set(existingForType.concat(ins.flatMap(i => i.metaData.filter(m => m.category === c && m.type === k).map(m => m.data)))));
                existing.set(k, existingForType);
            }
        }

    }

    getInstruments() {
        return Array.from(this.state.ins.values());
    }

    getInstrumentById(listedInstrumentId: number) {
        var ins = this.state.ins.get(listedInstrumentId);
        if (!ins && !this.state.requestedInsIds.has(listedInstrumentId) && this.state.ins.size!==0) {
            this.state.requestedInsIds.add(listedInstrumentId);
            GetInstrumentById(listedInstrumentId);
        }

        return ins;
    }

    getRootInstruments(){
        return this.state.rootIns;
    }

    getInstrumentsByIds(listedInstrumentIds: number[]) {
        var toFetch = new Array<number>();
        var o = new Array<ListedInstrument>();
        for (var i = 0; i < listedInstrumentIds.length; i++) {
            var ins = this.state.ins.get(listedInstrumentIds[i]);
            if (!ins) {
                this.state.ins.set(listedInstrumentIds[i], {} as ListedInstrument)
                toFetch.push(listedInstrumentIds[i]);
            }
            o.push(ins);
        }

        if (toFetch.length > 0)
            GetInstrumentsById(toFetch);

        return o;
    }

    async getInstrumentsByIdsAsync(listedInstrumentIds: number[]) {
        var toFetch = new Array<number>();
        var o = new Array<ListedInstrument>();
        for (var i = 0; i < listedInstrumentIds.length; i++) {
            var ins = this.state.ins.get(listedInstrumentIds[i]);
            if (!ins) {
                this.state.ins.set(listedInstrumentIds[i], {} as ListedInstrument)
                toFetch.push(i);
            }
            o.push(ins);
        }

        if (toFetch.length > 0) {
            var missing = await GetInstrumentsById(toFetch);
            missing.forEach(m => {
                o.push(m)
            })
        }

        return o;
    }


    getInstrumentByIdOrNull(listedInstrumentId: number) {
        var val = this.state.ins.get(listedInstrumentId);
        if (val === undefined) return null;
        return val;
    }

    getInstrumentSummary(listedInstrumentId: number) {
        return this.state.summaries.get(listedInstrumentId);
    }

    getMetaData() {
        return this.state.existingMetaData;
    }

    getMetaDataCategories() {
        var categories = this.state.existingMetaData?.keys();
        return categories ? Array.from(categories) : new Array<string>();
    }

    getMetaDataTypesForCategory(category: string) {
        var types = this.state.existingMetaData?.get(category)?.keys();
        return types ? Array.from(types) : new Array<string>();
    }

    getMetaDataByType(category: string, type: string) {
        return this.state.existingMetaData?.get(category)?.get(type)?.filter(x=>x!==null).slice(0, 50) ?? new Array<string>();
    }

    insertMetaValueByType(category: string, type: string, data: string) {
        var existingC = this.state.existingMetaData.get(category);
        if (!existingC)
            this.state.existingMetaData.set(category, new Map<string, string[]>());
        var existingT = this.state.existingMetaData.get(category).get(type);
        if (!existingT)
            this.state.existingMetaData.get(category).set(type, new Array<string>());
        var existingD = this.state.existingMetaData.get(category).get(type).some(d => d === data);
        if (!existingD)
            this.state.existingMetaData.get(category).get(type).push(data);
    }

    getInstrumentQuery(uniqueId: string) { return this.state.insQueries.get(uniqueId); }

    clearInstrumentQuery(uniqueId: string) { this.state.insQueries.delete(uniqueId); }
}

const listedInstrumentStoreInstance = new ListedInstrumentStore(AppDispatcher);
export default listedInstrumentStoreInstance;