import {TypedEvent, AppDispatcher} from '../dispatcher/appDispatcher';
import { get } from '../utils/httpUtils';
import { BootStrapAuthInfo } from './bootstrapModels';

export class BootStrapAuthLoadedEvent extends TypedEvent<BootStrapAuthInfo> {}
export class BootStrapAuthLoadedFailedEvent extends TypedEvent<string> {}

export async function LoadBootStrapAuth() {
    const info = await get<BootStrapAuthInfo>("ClientBootStrap?clientType=Web");
    if(info.payload == null){
        AppDispatcher.dispatch(new BootStrapAuthLoadedFailedEvent("There was an error loading from the server"));
    }
    else {
        AppDispatcher.dispatch(new BootStrapAuthLoadedEvent(info.payload));
    }
}