import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import { LivePosition, LiveSummary } from './positionSummaryModels';
import positionStoreInstance from './positionSummaryStore';
import { LoadValuationsByMeta, SubcribeToLivePositionUpdates, UnSubcribeFromLivePositionUpdates } from './positionSummaryActions';
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { GetParam } from '../trade/tradeBlotterTabComponent';
import { getPositionTableTheme } from './positionSummaryTable';
import _ from 'lodash';
import { BarChart, Bar, CartesianGrid, Legend, Pie, PieChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis, Sector, Cell, LabelList } from 'recharts';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { getIns } from './positionsCommon';
import { StrategyStr } from '../globalConstants';

interface StrategySummaryTableState {
    summary: LiveSummary,
    mainRef: RefObject<HTMLDivElement>,
    widthTotal: number,
    widthEven: number,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    showStatusPanel: boolean,
    hideClosedPositions: boolean,
    showTotals: boolean
    rowHover: string | undefined
}

export interface StrategySummaryTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class StrategySummaryTable extends React.Component<StrategySummaryTableProps, StrategySummaryTableState>{
    eventSubscriptionPositions: FBEmitter.EventSubscription | undefined;
    constructor(props: StrategySummaryTableProps) {
        super(props)
        var asOf = props.getState("asOf");
        this.state = {
            summary: { positions: new Array<LivePosition>() } as LiveSummary,
            mainRef: React.createRef(),
            widthTotal: 1000,
            widthEven: 200,
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: GetParam(this.props.getState, "awaitingRefresh") ?? true,
            showStatusPanel: false,
            hideClosedPositions: GetParam(this.props.getState, "hideClosedPositions") ?? true,
            showTotals: GetParam(this.props.getState, "showTotals") ?? false,
            rowHover: undefined
        };;
        this.updatePositions = this.updatePositions.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionPositions = positionStoreInstance.addChangeListener(this.updatePositions);
        this.updatePositions();
        await SubcribeToLivePositionUpdates();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionPositions) {
            this.eventSubscriptionPositions.remove();
            this.eventSubscriptionPositions = undefined;
        }
        await UnSubcribeFromLivePositionUpdates();
    }

    public componentDidUpdate() {
        if (this.state.mainRef.current) {
            let width = this.state.mainRef.current.clientWidth;
            if (width !== this.state.widthTotal) {
                this.setState({ widthTotal: width, widthEven: (width - 3) / 14 });
            }
        }
    }

    async onClickRefresh() {
        this.setState({ awaitingRefresh: true });
        this.props.onChangeState("awaitingRefresh", "true");
        await LoadValuationsByMeta(this.state.asOf?.format("yyyy-MM-DD") ?? "now", [StrategyStr]);
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("asOf", dStr);
        var positions = positionStoreInstance.getValuations(dStr, true);
        if (positions)
            this.setState({ asOf: d, summary: positions, awaitingRefresh: false });
        else {
            LoadValuationsByMeta(dStr, [StrategyStr]);
            this.setState({ asOf: d, summary: positions, awaitingRefresh: true });
        }
    }

    updatePositions(asOf?: Moment) {
        if (!asOf)
            asOf = this.state.asOf;
        let positions = positionStoreInstance.getValuationsByMeta(asOf?.format("yyyy-MM-DD") ?? "now", [StrategyStr]);
        this.props.onChangeState("awaitingRefresh", "false");
        this.setState({ summary: positions, awaitingRefresh: false });
    }



    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name, index }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" enableBackground={""} style={{ backgroundColor: "pink" }} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {name}
            </text>
        );
    };

    renderActiveLabel = (props) => {
        const { rowHover } = this.state;
        const { x, y, width, value, name } = props;

        return name === rowHover ? (
            <g>
                <text x={x + width / 2} y={y - Math.sign(value) * 10} fill={userStoreInstance.GetTheme().color} textAnchor="middle" dominantBaseline="middle">
                    {name}
                </text>
            </g>
        ) : null;
    };

    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, value, name } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={userStoreInstance.GetTheme().chart_color_indic_1}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={userStoreInstance.GetTheme().chart_color_indic_0}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={userStoreInstance.GetTheme().border_color}>{`${Number(value).toLocaleString()}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">{`${name}`}</text>
            </g>
        );
    };

    render() {
        const { summary, mainRef, hideClosedPositions, rowHover, asOf } = this.state;

        if (!summary)
            return <div style={{ position: "relative", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>;

        var positions = hideClosedPositions ? summary.positions.filter(p => p.aggregatedPosition.openPosition !== 0) : summary.positions;
        var byStrategy = _.groupBy(positions, (p) => { return p.aggregatedPosition.metaData[StrategyStr] ?? "Unknown" });

        var deltaArry = new Array<{ name: string, delta: number }>();
        var pnlArray = new Array<{ name: string, realized: number, unrealized: number }>();
        var deltas: { [strategy: string]: number } = {};
        var realized: { [strategy: string]: number } = {};
        var unRealized: { [strategy: string]: number } = {};
        Object.keys(byStrategy).forEach(g => {
            var posi = byStrategy[g];
            realized[g] = Math.floor(_.sum(posi.map(p => p.aggregatedPosition.realizedPnLBase)));
            unRealized[g] = Math.floor(_.sum(posi.map(p => p.unrealizedPnL * (p.unrealizedCcy !== "USD" ? summary.fxRatesToBase[p.unrealizedCcy] : 1.0))));
            deltas[g] = Math.floor(_.sum(posi.map(p => p.aggregatedPosition.openPosition * getIns(p.aggregatedPosition.instrumentId)?.multiplier * p.livePrice * p.delta)));
            deltaArry.push({ name: g, delta: deltas[g] });
            pnlArray.push({ name: g, realized: realized[g] / 1000000, unrealized: unRealized[g] / 1000000 });
        });

        deltaArry.sort((a, b) => Math.abs(b.delta) - Math.abs(a.delta));
        var top3Deltas = deltaArry.slice(0, 3).map(d => d.name);

        pnlArray.sort((a, b) => Math.abs(b.realized + b.unrealized) - Math.abs(a.realized + a.unrealized));

        var deltasChartData = Object.keys(deltas).map(k => {
            return { name: k, value: Math.abs(deltas[k]) };
        });

        var deltaColor = userStoreInstance.GetTheme().border_color;
        var pnlColorA = userStoreInstance.GetTheme().chart_color_indic_0;
        var pnlColorB = userStoreInstance.GetTheme().chart_color_indic_1;

        return (
            <div className="StrategySummaryTab" ref={mainRef}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getPositionTableTheme()}>
                        <div style={{ overflowY: "auto", maxHeight: "50vh", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                        <TableContainer component={Card}>
                            <Table stickyHeader={true} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Theme{asOf ? ` (${asOf.format("yyyy-MM-DD")})` : " (Live)"}</TableCell>
                                        <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Realized</TableCell>
                                        <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">UnRealized</TableCell>
                                        <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Total PnL</TableCell>
                                        <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Delta (USD)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(byStrategy).map(g => {
                                        return <TableRow key={g} hover onMouseOver={() => this.setState({ rowHover: g })} onMouseOut={() => this.setState({ rowHover: undefined })}>
                                            <TableCell align="center">{g}</TableCell>
                                            <TableCell align="center">{realized[g].toLocaleString()}</TableCell>
                                            <TableCell align="center">{unRealized[g].toLocaleString()}</TableCell>
                                            <TableCell align="center">{(realized[g] + unRealized[g]).toLocaleString()}</TableCell>
                                            <TableCell align="center">{deltas[g].toLocaleString()}</TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", alignContent: "center", height: "35vh", width: "100%", paddingTop: "5px" }}>
                            <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", textAlign: "center" }}>
                                <Typography>Gross Delta (USD)</Typography>
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart key="deltaChart" width={400} height={400} >
                                        <Pie
                                            activeIndex={rowHover ? deltasChartData.findIndex((v) => v.name === rowHover) : top3Deltas.map(d => deltasChartData.findIndex((v) => v.name === d))}
                                            activeShape={this.renderActiveShape}
                                            data={deltasChartData}
                                            dataKey="value"
                                            labelLine={false}
                                            fill={deltaColor}
                                            minAngle={1}
                                            paddingAngle={2} />
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", textAlign: "center", paddingRight: "10px" }}>
                                <Typography>PnL (USD)</Typography>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart key="pnlChart" width={500} height={400} data={pnlArray}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" stroke={userStoreInstance.GetTheme().color} />
                                        <YAxis unit="m" stroke={userStoreInstance.GetTheme().color} />
                                        <Tooltip />
                                        <Legend verticalAlign="top" />
                                        <ReferenceLine y={0} stroke="#000" />
                                        <Bar dataKey="realized" fill={pnlColorA}>
                                            {pnlArray.map((entry, index) => (
                                                <Cell cursor="pointer" strokeWidth={3} stroke={pnlArray.findIndex((v) => v.name === rowHover) === index ? deltaColor : pnlColorA} key={`cell-${index}`} />
                                            ))}
                                        </Bar>
                                        <Bar dataKey="unrealized" fill={pnlColorB}>
                                            {pnlArray.map((entry, index) => (
                                                <Cell cursor="pointer" strokeWidth={3} stroke={pnlArray.findIndex((v) => v.name === rowHover) === index ? deltaColor : pnlColorB} key={`cell-${index}`} />
                                            ))}
                                            <LabelList content={this.renderActiveLabel} />
                                        </Bar>
                                        {/* <Bar dataKey="realized" fill={pnlColorA} />
                                        
                                        <Bar dataKey="unrealized" fill={pnlColorB} /> */}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        );
    }
}
