import React, { Component, RefObject } from 'react';
import { v4 } from 'uuid';
import { get, put } from '../../utils/httpUtils';
import moment from 'moment-timezone';

//const CalendarSelect = MultiSelect.ofType<string>();

export type WebCalendarRequest = {
    calendarNames: string[],
    startDate: Date,
    endDate: Date
}

export type WebCalendarResponse = {
    holidays: { [key: string]: Date[]; };
}

export interface HolidayPanelProps {
    onClose?: (() => void)
    onChangeHeight?: (newHeight: number) => void
}

const baseHeight = 30;
const rowHeight = 30;

interface HolidayPanelState {
    uniqueKey: string
    hover: boolean;
    calendarNames: string[];
    selectedCalendars: string[];
    startDate: Date;
    endDate: Date;
    holidays: Map<string, string[]>;
    height: number;
    mainRef: RefObject<HTMLDivElement>,
}

class HolidayPanel extends Component<HolidayPanelProps, HolidayPanelState>{
    constructor(props: HolidayPanelProps) {
        super(props);
        var today = new Date();
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        var endDate = moment(startDate, "DD-MM-YYYY").add(40, 'days').toISOString();
        this.state = {
            uniqueKey: v4(),
            hover: false,
            calendarNames: [],
            selectedCalendars: ['LON', 'NYC', 'JHB', 'ICE', 'NYMEX', 'LME'],
            startDate: startDate,
            endDate: new Date(endDate),
            holidays: new Map<string, string[]>(),
            height: baseHeight,
            mainRef: React.createRef()
        }
    }

    async componentDidMount() {
        let response = await get<string[]>('Static/getCalendarNames');
        if (response.payload) {
            this.setState({ calendarNames: response.payload });
        }

        await this.getHolidays();
    }

    componentWillUnmount() {

    }

    public componentDidUpdate() {
        // if (this.props.onChangeHeight) {
        //     let newHeight = this.state.mainRef.current.clientHeight;
        //     if (newHeight !== this.state.height) {
        //         this.setState({ height: newHeight });
        //         this.props.onChangeHeight(newHeight);
        //     }
        // }
    }

    private async getHolidays() {
        const { startDate, endDate, selectedCalendars } = this.state;
        var request = {
            calendarNames: selectedCalendars,
            startDate: startDate,
            endDate: endDate
        } as WebCalendarRequest;

        let response = await put<WebCalendarResponse>('Static/getHolidays', request);
        if (response.payload) {
            let dict = new Map<string, string[]>();
            Object.keys(response.payload.holidays).forEach(k => {
                if (response.payload) {
                    var dates = response.payload.holidays[k];
                    dates.forEach(dd => {
                        let d = new Date(dd);
                        if (!dict.has(d.toISOString())) {
                            dict.set(d.toISOString(), new Array<string>());
                        }
                        var arr = dict.get(d.toISOString());
                        if (arr) {
                            arr.push(k);
                        }
                    });
                }
            });
            this.setState({ holidays: dict });
        }
    }

    private renderHolidayTable = () => {
        let items = new Array<[Date, string[]]>();
        for (const [key, value] of this.state.holidays.entries()) {
            items.push([new Date(key), value]);
        }

        if (items.length > 0)
            return items.map(i => this.mapHolidayTableRow(i[0], i[1]));
        else
            return <tr><td className="HolidayPanelBody" colSpan={2} style={{ height: `${rowHeight}px` }}>(no holidays in next 40 days)</td></tr>


    }
    private mapHolidayTableRow = (date: Date, holidays: string[]) => {
        return (<tr key={`holz-${date}`}>
            <td style={{ width: "30px" }}>{date.toISOString().split('T')[0].substring(5)}</td>
            <td style={{ width: "70%", overflow:"none",  }}><div>{holidays.slice(0,3).join(',')}{holidays.length>3?"...":""}</div></td>
        </tr>);
    }

    render() {
        return (
            <div ref={this.state.mainRef} className="HolidayPanel">
                <table style={{ margin: "auto", width: "95%" }}>
                    <thead>
                        <tr>
                            <th colSpan={2} className="HolidayPanelHeader" style={{ textAlign: "center", height: `${baseHeight}px` }}>Upcoming Holidays</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderHolidayTable()}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default HolidayPanel;