import React, { Component } from 'react';
import moment from 'moment-timezone'
import Clock from 'react-clock';
import { v4 } from 'uuid';
import { timezoneKey } from './digitalClock';
import { PltfmTimeZonePicker } from '../../utils/timezones';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../../inputs/formCommon';

const internalFormat = "HH:mm:ss";
export interface AnalogueClockProps {
  onChangeState: (key: string, value: string) => void;
  getState: (key: string) => string;
}

interface AnalogueClockState {
  time: string
  timezone: string | undefined
  uniqueKey: string
}

let runner: any;
class AnalogueClock extends Component<AnalogueClockProps, AnalogueClockState>{
  constructor(props: AnalogueClockProps) {
    super(props);
    var now = moment((new Date()));
    var timeInitial = now.format(internalFormat);
    var tzInitial = props.getState(timezoneKey) ?? moment.tz.guess();
    this.state = {
      time: timeInitial,
      timezone: tzInitial,
      uniqueKey: v4(),
    }
  }

  componentDidMount() {
    runner = setInterval(() => {
      if (this.state.timezone) {
        var utc = moment.utc();
        var local = utc.tz(this.state.timezone);
        var time = local.format(internalFormat);
        this.setState({ time: time });
      }
      else {
        var now = moment((new Date()));
        var time2 = now.format(internalFormat);
        this.setState({ time: time2 });
      }
    }, 1000);
  }
  componentWillUnmount() {
    if (runner) {
      clearInterval(runner);
    }
  }

  private handleTimezoneChange = (timezone: string) => {
    this.setState({ timezone });
    this.props.onChangeState(timezoneKey, timezone);
  }



  render() {
    return (
      <ThemeProvider theme={getFormTheme()}>
        <div className="AnalgoueClock">
          <div className="AnalgoueClockInner">
            <div className="AnalgoueClockTime"><Clock className="AnalgoueClockTime" key={`${this.state.uniqueKey}_clock`} size={225} value={this.state.time} /></div>
            <div className="AnalgoueClockPicker">
              <PltfmTimeZonePicker
                selectedTimezone={this.state.timezone}
                onChange={this.handleTimezoneChange}
                disabled={false} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
export default AnalogueClock;