export interface ListedInstrument {
    listedInstrumentId: number,
    underlyingId: number,
    assetId: number,
    type: string,
    netable: boolean,
    ticker: string,
    isin: string,
    primaryExchange: string,
    ccy: string,
    optionStrike: number,
    underlyingQuantityPerContract: number,
    optionIsCall: boolean,
    optionType: string,
    multiplier: number,
    maturity: Date,
    firstNoticeDay: Date,
    firstDeliveryDay: Date,
    createdUtc: Date,
    description: string,
    underlying: ListedInstrument,
    humanEdited: boolean,
    linkedInstruments: ListedInstrument[],
    metaData: ListedInstrumentMetaData[],
    rowVersion : number,
    bondAdditionalData: BondAdditionalData
}

export interface ListedInstrumentSummary {
    id: number,
    description : string,
    type : string,
}

export interface ListedInstrumentMetaData {
    id: number,
    listedInstrumentId: number,
    type: string,
    data: string,
    category: string,
    lastUpdated: Date,
    deleted: boolean
}

export interface BondAdditionalData {
    callFeatureType: BondCallFeatureType;
    schedule: BondCallScheduleRow[];
    callNoticeDays: number | null;
    redemptionType: BondRedemptionType;
    sinkingSchedule: BondSinkingScheduleRow[];
    firstCallDate: string
}

export interface BondCallScheduleRow {
    date: string;
    callPrice: number;
}

export interface BondSinkingScheduleRow {
    date: string;
    sinkAmount: number;
    sinkPercent: number;
}

export enum BondCallFeatureType
{
    Never,
    Anytime,
    Annual,
    AtMaturity,
    BiMonthly,
    Monthly,
    Onetime,
    Quarterly,
    SemiAnnual,
    SemiMonthly,
    Weekly,
    OnlyOnSchedule
}

export enum BondRedemptionType
{
    Regular,
    Callable,
    Sinking
}


export interface ListedInstrumentStoreState {
    ins: Map<number,ListedInstrument>;
    existingMetaData: Map<string,Map<string,string[]>>;
    insQueries: Map<string, ListedInstrument[]>;
    summaries : Map<number, ListedInstrumentSummary>;
    rootIns: ListedInstrument[];
    requestedInsIds: Set<number>;
}

export interface ListedInstrumentMetaDataUpdate{
    listedInstrumentIds: number[],
    metaToAdd : ListedInstrumentMetaData[]
}

export type ListedInstrumentQuery = {
    maturityFromDate: Date;
    maturityToDate: Date;
    listedInstrumentId: number;
    type: string;
    underlyingType: string;
    description: string;
    exchange: string;
    callPut: string;
    strike: number;
    underlyingId: number;
    uniqueId: string;
}

export type ListedInstrumentQueryResponse = {
    uniqueId: string;
    instruments: ListedInstrument[]
}

export type MissingMetaDataQuery = {
    uniqueId: string;
    fromDate: Date;
    toDate: Date;
    includeTime: boolean,
    metaTypes: string[];
    metaFilter: string;
    insType: string;
    description: string;
    returnMissingOnly: boolean;
    aggregateFills: boolean;
}