import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import { NewsItem } from './newsTickerModels';
import newsStoreInstance from './newsTickerStore';
import { v4 } from 'uuid';
import { Link, MenuItem } from '@mui/material';

export type NewsTickerProps = {
    onChangeHeight?: (newHeight: number) => void
}
type NewsTickerComponentState = {
    loading: boolean,
    updated: boolean,
    newsItems: NewsItem[],
    sourcesToFilterOn: string[],
    allSources: string[],
    hover: boolean;
    height: number;
    mainRef: RefObject<HTMLDivElement>,
}

const headlineBreak = "             .....            ";
const scrollSpeed = 5;

export class NewsTicker extends React.Component<NewsTickerProps, NewsTickerComponentState>{
    eventSubscriptionInbox: FBEmitter.EventSubscription | undefined;

    constructor(props: NewsTickerProps) {
        super(props);
        this.state = {
            newsItems: [],
            allSources: [],
            loading: true,
            sourcesToFilterOn: new Array<string>(),
            updated: false,
            hover: false,
            height: 50,
            mainRef: React.createRef()
        }
    }

    componentDidMount() {
        this.eventSubscriptionInbox = newsStoreInstance.addChangeListener(() => this.onChange());
        newsStoreInstance.refreshNews();
        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionInbox) {
            this.eventSubscriptionInbox.remove();
            this.eventSubscriptionInbox = undefined;
        }
    }

    public componentDidUpdate() {
        if (this.props.onChangeHeight) {
            let newHeight = this.state.mainRef.current.clientHeight;
            if (newHeight !== this.state.height) {
                this.props.onChangeHeight(newHeight);
                this.setState({ height: newHeight });
            }
        }
    }

    onChange() {
        let items = newsStoreInstance.getNewsItems();
        let sources = [...new Set(items.map(i => i.itemSource))];
        this.setState({ newsItems: items, allSources: sources });
        this.forceUpdate();
    }

    onChangeSources = (sources: string[]) => {
        if (sources.length === 0) {
            this.setState({ sourcesToFilterOn: new Array<string>() });
        }
        else {
            this.setState({ sourcesToFilterOn: sources });
        }
    }

    getSources = () => {
        let allTags = this.state.newsItems.map(x => x.itemSource);
        let uniqueTags = Array.from(new Set(allTags));
        return uniqueTags;
    }

    onClickItem = (uniqueKey: string) => {

    }

    getElements = () => {
        let filteredSources = this.state.sourcesToFilterOn.length>0?this.state.newsItems.filter(n=>this.state.sourcesToFilterOn.some(s=>n.itemSource===s)):this.state.newsItems;
        return filteredSources.length === 0 ?
            new Array(<p className="NewsTickerText" key="NewsHeadlinePlaceholder" style={{ verticalAlign: "middle", fontSize: "16pt", whiteSpace: "nowrap" }}>.... Loading news feeds ....</p>):
            filteredSources.map(n => { return (<Link classes={{root:"NewsTickerText"}} key={`${n.link}+${v4()}`} href={n.link} rel="noopener noreferrer" target="_blank" style={{ verticalAlign: "middle", fontSize: "16pt", whiteSpace: "nowrap" }}>{n.itemSource}: {n.headline} {headlineBreak}</Link>) });
    }

    renderItem = (item: NewsItem) => {
        return (<div className="ticker__item" key={item.uniqueKey}>{item.headline}</div>)
    }

    private isValueSelected(value: string) { return this.state.sourcesToFilterOn.some(c => c === value) }
    private renderSourceItem = (value: string) => {

        return (
            <MenuItem id={"src_" + value}
                // icon={this.isValueSelected(value) ? "tick" : "blank"}
                key={value}
                onClick={()=>this.onSourceSelect(value)}>
                    {value}
            </MenuItem>
        );
    };

    private onSourceSelect = (value: string) => {
        var selectedSources = this.state.sourcesToFilterOn;
        if (!this.isValueSelected(value)) {
            selectedSources.push(value);
        } else {
            selectedSources = this.state.sourcesToFilterOn.filter(x => x !== value);
        }
        this.setState({ sourcesToFilterOn: selectedSources });
    };


    onRightClick(mouseEvent : React.MouseEvent<HTMLDivElement, MouseEvent>) {
        // ContextMenu.show((
        //     <Menu key="srcMenu" title="News Sources">
        //     {this.state.allSources.map(s=>this.renderSourceItem(s))}
        //     </Menu>
        //     ), { left: mouseEvent.clientX, top: mouseEvent.clientY });
        // mouseEvent.preventDefault();
    }

    render() {
        var textElement = this.getElements();
        var mTime = scrollSpeed * textElement.length;
        var animationState = this.state.hover ? "Paused" : "Running";
        return (
            <div className="NewsTicker" ref={this.state.mainRef}
                onMouseEnter={() => { this.setState({ hover: true }) }} 
                onMouseLeave={() => { this.setState({ hover: false }) }}
                onContextMenu={(e : React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onRightClick(e)}>
                <div className="NewsTickerInner">
                    <div className="marquee">
                        <span style={{ display: "inline-block", paddingLeft: "100%", animationName: "marquee", animationDuration: `${mTime}s`, animationTimingFunction: "linear", animationIterationCount: "infinite", animationPlayState: animationState }} >{textElement}</span>
                    </div>
                </div>
            </div>
        );
    }
}