import React, { FormEvent } from 'react';
import FBEmitter from 'fbemitter';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { expandNumberAbbreviationTerms, nanStringToEmptyString } from '../homepage/productDesigner/productDesignerCommon';
import { NumberFormatCustom } from './numberFormatInput';

export type validatedNumericPctInputProps = {
    onChange: (valueAsNumber: number, valueAsString?: string) => boolean;
    initialValue?: number;
    placeholder: string | undefined;
    keyName: string;
    disabled?: boolean;
    helperText?: string;
    label?: string;
    className?: string;
    units?: string;
    style?: React.CSSProperties;
}

type validatedNumericPctInputState = {
    currentValueAsString: string,
    currentValueAsNumber: number,
    isValid: boolean,
    placeholder: string,
    initialValue: number,
    disabled: boolean,
}

class ValidatedNumericPctInput extends React.Component<validatedNumericPctInputProps, validatedNumericPctInputState> {
    eventSubscriptionCredit: FBEmitter.EventSubscription | undefined;

    constructor(props: validatedNumericPctInputProps) {
        super(props)
        this.state =
        {
            placeholder: this.props.placeholder ? this.props.placeholder : "Enter value...",
            isValid: this.props.onChange(this.props.initialValue),
            currentValueAsNumber: this.props.initialValue,
            currentValueAsString: this.props.initialValue ? this.props.initialValue.toString() : null,
            initialValue: this.props.initialValue,
            disabled: this.props.disabled
        };
    }

    public async componentDidMount() {
        var value = this.props.initialValue;
        var isValid = this.props.onChange(value);
        this.setState({ isValid });
    }

    public updateValue(value: string) {
        let result = value;
        result = expandNumberAbbreviationTerms(result);
        result = nanStringToEmptyString(result);
        var asNumber = parseFloat(result);
        if (isNaN(asNumber)) {
            this.setState({ isValid: false, currentValueAsString: value });
            return;
        }

        var isValid = this.props.onChange(asNumber, value);
        this.setState(
            {
                currentValueAsString: asNumber.toString(),
                currentValueAsNumber: asNumber,
                isValid: isValid,
            });
    }

    public reset = () => {
        this.updateValue(this.state.initialValue.toString());
    };


    public componentWillUnmount() {

    }

    onChange = (event?: FormEvent<HTMLElement>) => {
        if (event) {
            var val = (event.target as HTMLInputElement).value;
            this.updateValue(val);
        }
    }


    render() {
        const { currentValueAsString } = this.state;
        return (<TextField
            variant="outlined"
            size="small"
            error={!this.state.isValid}
            key={this.props.keyName + "2"}
            id={this.props.keyName + "2"}
            value={currentValueAsString ?? ""}
            onChange={this.onChange}
            placeholder={this.state.placeholder}
            helperText={this.props.helperText}
            label={this.props.label}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Typography>{this.props.units}</Typography>
                        {/* <Chip
                                label={this.props.units}
                                classes={{ sizeSmall: "ValidatedTextInputChip" }}
                                size="small" /> */}
                    </InputAdornment>
                ),
                style: this.props.style,
                readOnly: this.props.disabled,
                inputComponent: NumberFormatCustom as any,
                classes: { root: (this.props.className ? this.props.className + " " : "") + "ValidatedTextInputInner" }
            }}
        />
        );
    }
}

export default ValidatedNumericPctInput;