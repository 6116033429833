import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { ConnectionStatus, IConnection, RetryPolicy } from "../connections/connectionStatusModel";
import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";
import { Tick } from "../marketData/models/tick";
import { AcceleratorConnectionStatusChange, NewTick, NewTicks, UpdateAcceleratorSettings } from "./acceleratorActions";

export class AcceleratorConnection implements IConnection {
    connectPromise: Promise<void> | null;
    status: ConnectionStatus;
    readonly hubLocation: string;
    readonly connectionName: string;
    hubConnection: HubConnection;

    constructor(connectionName: string) {
        this.status = ConnectionStatus.Disconnected;
        this.hubLocation = "http://localhost:28026/api/marketdata";
        this.connectionName = connectionName;
    }

    public connect = async () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(this.hubLocation)
            .withAutomaticReconnect(new RetryPolicy())
            .build();

        this.hubConnection.serverTimeoutInMilliseconds = 40000;
        this.hubConnection.onreconnected((id) => this.connectionOnReconnect(id));
        this.hubConnection.onreconnecting((error) => this.connectionOnReconnecting(error));
        this.hubConnection.onclose((error) => this.connectionOnClose(error));

        await this.beforeConnect();

        this.connectPromise = this.hubConnection.start().then(() => {
            this.afterConnect();
            this.status = ConnectionStatus.Connected;
            this.onStatusChange(this.status);
        });
    }

    onStatusChange(status: ConnectionStatus) {
        AcceleratorConnectionStatusChange(status);
    }

    async beforeConnect() {
        const port = await UpdateAcceleratorSettings();
        const location = `http://localhost:${port}/api/marketdata`;
        console.log("Connecting to " + location);
        if (this.hubConnection.state === HubConnectionState.Disconnected || this.hubConnection.state === HubConnectionState.Reconnecting) {
            if (this.hubConnection.baseUrl !== location)
                this.hubConnection.baseUrl = location;
        }
        else if (this.hubConnection.baseUrl !== location) {
            console.log(`Unable to set base Url as connection is in state ${this.hubConnection.state}`)
        }
        this.hubConnection.on("marketDataTick", (message) => this.onTick(message));
        this.hubConnection.on("marketDataTicks", (message) => this.onTicks(message));
    }
    afterConnect() { }
    afterReconnect() { }

    connectionOnReconnect(connectionId: string | undefined) {
        this.status = ConnectionStatus.Connected;
        this.onStatusChange(this.status);
    }
    connectionOnReconnecting(error?: Error) {
        this.status = ConnectionStatus.Reconnecting;
        this.onStatusChange(this.status);
    }
    connectionOnClose(error?: Error) {
        this.status = ConnectionStatus.Disconnected;
        this.onStatusChange(this.status);
    }

    public async connectIfNeeded() {
        if (this.status === ConnectionStatus.Disconnected)
            await this.connect();
    }

    onTick(tick: Tick) {
        //console.log(JSON.stringify(tick));
        NewTick(tick);
    }

    
    onTicks(ticks: Tick[]) {
        //console.log(JSON.stringify(tick));
        NewTicks(ticks);
    }

    public async subscribeToTicks(ins: ListedInstrument) {
        try {
            await this.connectIfNeeded();
            if (this.hubConnection.state === HubConnectionState.Connected) {
                await this.hubConnection.invoke("SubscribeToMarketDataTicks", ins);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    public async subscribeToTicksBulk(ins: ListedInstrument[]) {
        try {
            await this.connectIfNeeded();
            if (this.hubConnection.state === HubConnectionState.Connected) {
                await this.hubConnection.invoke("SubscribeToMarketDataTicksMulti", ins);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    
    public async produceEoDReport(toAddresses: string) {
        try {
            await this.connectIfNeeded();
            //await this.connectPromise;
            if (this.hubConnection.state === HubConnectionState.Connected) {
                await this.hubConnection.invoke("ProduceEoDReport", toAddresses);
            }
        }
        catch (error) {
            console.log(error);
        }
    }
}

const acceleratorConnectionInstance = new AcceleratorConnection("AcceleratorConnection");
export default acceleratorConnectionInstance;