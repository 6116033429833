import FluxStore from '../../../FluxStore';
import { Event, AppDispatcher } from '../../../dispatcher/appDispatcher';
import { ProductCloneStoreState, ProductEditRequest } from './productCloneModels';
import { ProductSpec } from '../../../product/productModels';
import { ProductCloneEvent, ProductEditEvent, ProductViewEvent } from './productCloneActions';


class ProductCloneStore extends FluxStore<ProductCloneStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            productToClone: {} as ProductSpec,
            productToEdit: {} as ProductEditRequest,
            productToView: {} as ProductEditRequest
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof ProductCloneEvent) {
            const { payload } = action;
            this.state.productToClone = payload;
            this.state.productToEdit = undefined;
            this.state.productToView = undefined;
            this.emitChange();
        }
        else if (action instanceof ProductEditEvent) {
            const { payload } = action;
            this.state.productToEdit = payload;
            this.state.productToClone = undefined;
            this.state.productToView = undefined;
            this.emitChange();
        }
        else if (action instanceof ProductViewEvent) {
            const { payload } = action;
            this.state.productToView = payload;
            this.state.productToEdit = undefined;
            this.state.productToClone = undefined;
            this.emitChange();
        }
    }

    getProductToClone() {
        return this.state.productToClone;
    }

    getProductToEdit() {
        return this.state.productToEdit;
    }

    getProductToView() {
        return this.state.productToView;
    }
}

const productCloneStoreInstance = new ProductCloneStore(AppDispatcher);
export default productCloneStoreInstance;