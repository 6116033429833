import React from 'react';
import FBEmitter from 'fbemitter';
import { RfqSummary } from '../rfqModels';
import rfqSummaryStoreInstance from '../rfqSummaryStore';
import { Button, ButtonGroup } from '@mui/material';
import userStoreInstance from '../../user/userStore';

type RfqControlsProps = {
    selectedRfq: RfqSummary | undefined,
    onCancel: () => void;
    onRefresh: () => void;
    onClone: () => void;
    onPickup: () => void;
    onDrop: () => void;
}


type RfqControlsState = {
    selectedRfq: RfqSummary | undefined,
}

const statesCanCancel = ["Created", "Stale", "WaitingForTrading", "FinalPreTradeChecks", "PickedUp", "PriceToClient"];

export class RfqControls extends React.Component<RfqControlsProps, RfqControlsState>{
    eventSubscriptionRfqs: FBEmitter.EventSubscription | undefined;

    constructor(props: RfqControlsProps) {
        super(props)
        this.state = {
            selectedRfq: this.props.selectedRfq
        };
        this.onChangeRfq = this.onChangeRfq.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionRfqs = rfqSummaryStoreInstance.addChangeListener(this.onChangeRfq);
    }

    componentWillUnmount() {
        if (this.eventSubscriptionRfqs) {
            this.eventSubscriptionRfqs.remove();
            this.eventSubscriptionRfqs = undefined;
        }
    }

    onChangeRfq() {
        var summary = rfqSummaryStoreInstance.GetRfq(this.props.selectedRfq.rfqId);
        if (summary !== this.state.selectedRfq) {
            this.setState({ selectedRfq: summary });
        }
    }

    render() {
        const { selectedRfq } = this.state;
        const isDealerView = userStoreInstance.GetUserInfo().firmId===null || (selectedRfq!==undefined && !(selectedRfq.parentRfq === undefined || selectedRfq.parentRfq === null));
        const isReadyToPickup = isDealerView && selectedRfq.state === "WaitingForTrading";
        const canRefresh = !isDealerView && selectedRfq.state === "Stale";
        const canCancel = !isDealerView && statesCanCancel.some(s => s === selectedRfq.state);
        const isPickedUp = isDealerView && (selectedRfq.state === "PickedUp" || selectedRfq.state === "PriceToClient");
        if (selectedRfq) {
            if (!isDealerView) {
                return (<div className="RfqDashboardControls">
                    <ButtonGroup variant="outlined">
                        {canCancel ? <Button className="MuiButton-outlined PltfmButtonLite" onClick={this.props.onCancel}>Cancel</Button> : null}
                        {canRefresh ? <Button className="MuiButton-outlined PltfmButtonLite" onClick={this.props.onRefresh}>Refresh</Button> : null}
                        <Button className="MuiButton-outlined PltfmButtonLite" onClick={this.props.onClone}>Clone</Button>
                    </ButtonGroup>
                </div>);
            }
            else {
                return (
                    <div className="RfqDashboardControls">
                        <ButtonGroup variant="outlined">
                            {!isReadyToPickup ? null : <Button className="MuiButton-outlined PltfmButtonLite" onClick={this.props.onPickup}>Pick Up</Button>}
                            {!isPickedUp ? null : <Button className="MuiButton-outlined PltfmButtonLite" onClick={this.props.onDrop}>Drop</Button>}
                        </ButtonGroup>
                    </div>);
            }
        }
        else
            return null;
    }
}