import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import { Trade, TradeQuery } from './tradeModels';
import tradesStoreInstance from './tradeStore';
import { FetchTradesWithQuery } from './tradeActions';
import { ProductPayoffType, ProductSummary } from '../product/productModels';
import productStoreInstance from '../product/productStore';
import { LoadProductSummaries } from '../product/productActions';
import StructuredProductDesigner from '../homepage/productDesigner/structuredProductDesigner';
import { RfqAuditLogViewer } from '../rfq/rfqDashboard/rfqAuditLogViewer';
import moment from 'moment';
import { ConfigBlob, UserSummary } from '../user/userModel';
import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Toolbar,
    Typography,
} from '@mui/material';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarContainer, GridToolbarExport, GridToolbarDensitySelector, GridColumnOrderChangeParams, GridColumnResizeParams, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import { renderDateCell, renderDateTimeCell } from '../utils/helpers';
import { AccessTimeOutlined, CloseOutlined, Filter1Outlined, QueryBuilderOutlined, RotateLeftOutlined, SaveOutlined } from '@mui/icons-material';
import { getFormTheme, getTableTheme } from '../inputs/formCommon';
import userStoreInstance from '../user/userStore';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { EarliestDate } from '../globalConstants';
import { v4 } from 'uuid';
import { TradeBlotterTabProps } from './tradeBlotterTab';
import { AddOrUpdateConfig, DeleteConfig, GetConfigsForType } from '../user/userActions';
import { PltfmDateTimePicker } from '../utils/dates';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { LoadItemSpec, SaveLoadDeleteBox } from '../inputs/saveLoadDeleteBox';
import rfqSummaryStoreInstance from '../rfq/rfqSummaryStore';
import { RefreshRfqSummary } from '../rfq/rfqActions';

export type TradeBlotterState = {
    trades: Trade[],
    productsById: Map<number, ProductSummary>;
    rowData: BlotterDataRow[];
    availableColumnsNames: string[];
    selectedColumns: string[];
    tradeDetailVisible: boolean;
    selectedRow: BlotterDataRow;
    availableUsers: Map<number, UserSummary>;
    mainRef: RefObject<HTMLDivElement>;
    toolbarRef: RefObject<HTMLDivElement>;
    width: number,
    columns: GridColDef[],
    showQuery: boolean,
    tradeQuery: TradeQuery,
    queryRunning: boolean,
    querySingleId: boolean,
    selectedConfig: ConfigBlob,
    showSaveQuery: boolean,
    gridMenuOpen: boolean,
    anchorElement?: Element,
    toolbarHeight: number,
    showFullDateTime: boolean,
    waitingForRfqUpdate: number,
}

export interface BlotterDataRow {
    Traded: Date,
    Trader: string,
    Last: Date,
    id: number,
    RfqId: number,
    ProductId: number,
    Description: string,
    Payoff: string,
    PayoffSubType: string,
    Size: number,
    Ccy: string,
    Status: string,
    [key: string]: any,
}

export interface TradeBlotterWrapperProps {
    id: string;
}
const TradeQueryStr = "TradeQuery";

const titleStyle = { fontWeight: "bold", borderColor: userStoreInstance.GetTheme().contrastBorderColorLight } as React.CSSProperties;
const cellStyle = { borderColor: userStoreInstance.GetTheme().contrastBorderColorLight } as React.CSSProperties;
const defaultColumns = ["Id", "Status", "Traded", "Trader", "Description", "Payoff", "PayoffSubType", "Size", "Price", "Ccy", "ExternalId"];
export const dateTimeColumns = ["Traded", "Last", "Maturity"];
export const numberColumns = ["Size", "Price", "Multiplier"];
export const nameMapColumns = [{ name: "Traded", map: "Date" }, { name: "Payoff", map: "Type" }, { name: "PayoffSubType", map: "U.Type" }];
export const sizeMapColumns = [
    { name: "Id", size: 100 },
    { name: "Traded", size: 120 },
    { name: "Status", size: 120 },
    { name: "Payoff", size: 120 },
    { name: "PayoffSubType", size: 120 },
    { name: "Size", size: 120 },
    { name: "Price", size: 120 },
    { name: "Ccy", size: 120 }];
export const nullUser = "Select User...";
export const nullConfig = { displayName: "Select Query..." } as ConfigBlob;
export const nullInsType = "Select Type...";
export const validInsTypes = ["Future", "Option", "Bond", "Equity", "FX", "Cash", "Index", "CFD", "TokenCross", "Perpetual"];

export function GetParam<T>(getState: (key: string) => string, key: string): T {
    var str = getState(key);
    var result = str ? JSON.parse(str) : null;
    return result;
}

export function GetSessionState<T>(key: string): T {
    var str = sessionStorage.getItem(key);
    var result = str ? JSON.parse(str) : null;
    return result;
}

export function SetSessionState(key: string, item: any) {
    sessionStorage.setItem(key, JSON.stringify(item));
}

export class TradeBlotterTabComponent extends React.Component<TradeBlotterTabProps, TradeBlotterState>{
    eventSubscriptionTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    eventSubscriptionRfqs: FBEmitter.EventSubscription | undefined;

    constructor(props: TradeBlotterTabProps) {
        super(props)

        var colStr = GetSessionState<string>("tradeBlotterColumns");
        var columns = colStr ? colStr.split(',') : defaultColumns;

        this.state = {
            trades: [],
            productsById: new Map<number, ProductSummary>(),
            rowData: [],
            availableColumnsNames: [],
            selectedColumns: columns,
            tradeDetailVisible: false,
            selectedRow: {} as BlotterDataRow,
            availableUsers: new Map<number, UserSummary>(),
            columns: [],
            mainRef: React.createRef(),
            toolbarRef: React.createRef(),
            width: 0,
            showQuery: GetParam(this.props.getState, "tradeBlotterShowQuery") ?? false,
            tradeQuery: GetSessionState("tradeBlotterQuery") ?? { fromDate: { absolute: moment.utc().startOf('day').toDate() }, uniqueId: v4(), columns: defaultColumns, dailyAggregate: true } as TradeQuery,
            queryRunning: false,
            querySingleId: true,
            selectedConfig: undefined,
            showSaveQuery: false,
            gridMenuOpen: false,
            toolbarHeight: 30,
            showFullDateTime: false,
            waitingForRfqUpdate: undefined
        };

        this.onChangeColumns = this.onChangeColumns.bind(this);
        this.onChangeColumnsWidth = this.onChangeColumnsWidth.bind(this);
        this.onChangeColumnsOrder = this.onChangeColumnsOrder.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTradeDetailClosed = this.onTradeDetailClosed.bind(this);
        this.onCellDoubleClick = this.onCellDoubleClick.bind(this);
        this.CustomToolbar = this.CustomToolbar.bind(this);
        this.onEnterPressed = this.onEnterPressed.bind(this);
        this.onSaveQuery = this.onSaveQuery.bind(this);
        this.onDeleteQuery = this.onDeleteQuery.bind(this);
        this.onLoadQuery = this.onLoadQuery.bind(this);
        this.onChangeDateTimeFlag = this.onChangeDateTimeFlag.bind(this);
    }

    async componentDidMount() {
        const { availableUsers } = this.state;
        if (availableUsers.size === 0) {
            userStoreInstance.GetUsers().forEach(user => {
                availableUsers.set(user.userId, user);
            });
        }

        this.eventSubscriptionTrades = tradesStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionRfqs = rfqSummaryStoreInstance.addChangeListener(() => this.onChange());

        var trades = tradesStoreInstance.getTradeQuery(this.state.tradeQuery.uniqueId);
        if (trades)
            await this.onChange();
        else
            await this.runQuery(this.state.tradeQuery);


        GetConfigsForType(TradeQueryStr);
    }

    componentDidUpdate() {
        if (this.state.toolbarRef?.current && this.state.toolbarRef.current.clientHeight !== this.state.toolbarHeight) {
            this.setState({ toolbarHeight: this.state.toolbarRef.current.clientHeight });
        }
    }

    async runQuery(query: TradeQuery) {
        this.setState({ queryRunning: true });
        SetSessionState("tradeBlotterQueryRunning", true);
        await FetchTradesWithQuery(query);
    }

    filterQueryOptions = (options: ConfigBlob[], state: any): ConfigBlob[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.cfgMatchesQuery(o, query))
        return relevant;
    };

    cfgMatchesQuery = (cfg: ConfigBlob, query: string): boolean => {
        const normalizedTitle = cfg.displayName.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    onChangeQuery = (e: any, v: ConfigBlob | string) => {
        var vAsBlob = v as ConfigBlob;
        if (vAsBlob)
            this.setState({ selectedConfig: vAsBlob });
    }

    CustomToolbar(props: any) {
        const { showQuery, toolbarRef, showFullDateTime } = this.state;
        return (
            <ThemeProvider theme={getPositionTableTheme()}>
                <GridToolbarContainer style={{ padding: "0.5em" }} ref={toolbarRef}>
                    <Grid container spacing={0.5} width="80%">
                        <Grid item><GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" /></Grid>
                        <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" inputprops={{ size: "small" }} /></Grid>
                        <Grid item><GridToolbarDensitySelector {...props} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" /></Grid>
                        <Grid item><GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" /></Grid>
                        <Grid item>
                            <Button
                                className="MuiButton-outlined PltfmButtonLite"
                                size="small"
                                variant="outlined"
                                startIcon={< RotateLeftOutlined />}
                                onClick={() => {
                                    var query = this.state.tradeQuery;
                                    query.columns = defaultColumns;
                                    this.setState({ selectedColumns: defaultColumns, tradeQuery: query });
                                    this.updateQuery(query);
                                    this.props.onChangeState("tradeBlotterColumns", defaultColumns.join(","));
                                    this.onChange(undefined, defaultColumns);
                                }}>
                                Reset Columns
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="MuiButton-outlined PltfmButtonLite"
                                size="small"
                                variant="outlined"
                                startIcon={<Filter1Outlined />}
                                onClick={() => { this.setState({ showQuery: !this.state.showQuery }); this.props.onChangeState("tradeBlotterShowQuery", this.state.showQuery ? "false" : "true"); }}>
                                {showQuery ? "Hide Query" : "Show Query"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="MuiButton-outlined PltfmButtonLite"
                                size="small"
                                variant="outlined"
                                startIcon={<AccessTimeOutlined />}
                                onClick={this.onChangeDateTimeFlag}>
                                {showFullDateTime ? "Show Dates" : "Show Date/Time"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0.5} width="20%" justifyContent={"flex-end"}>
                        <Grid item><Button
                            size="small"
                            className="MuiButton-outlined PltfmButtonLite"
                            onClick={() => this.setState({ showSaveQuery: true })}
                            startIcon={<SaveOutlined />}>
                            Queries
                        </Button></Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    async onChangeDateTimeFlag() {
        this.setState({ showFullDateTime: !this.state.showFullDateTime });
        this.props.onChangeState("showFullDateTime", this.state.showFullDateTime ? "false" : "true");
        await this.onChange(undefined, undefined, !this.state.showFullDateTime)
    }

    onLoadQuery(id: number) {
        var cfg = userStoreInstance.getConfigsForType(TradeQueryStr).filter(cfg => cfg.configId === id)[0];
        if (cfg) {
            var query = JSON.parse(cfg.blob) as TradeQuery;
            if (query) {
                this.setState({ tradeQuery: query });
                this.onChange(undefined, query.columns);
            }
        }
    }

    componentWillUnmount() {
        if (this.eventSubscriptionTrades) {
            this.eventSubscriptionTrades.remove();
            this.eventSubscriptionTrades = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
        if (this.eventSubscriptionRfqs) {
            this.eventSubscriptionRfqs.remove();
            this.eventSubscriptionRfqs = undefined;
        }
        //tradesStoreInstance.clearTradeQuery(this.state.tradeQuery.uniqueId);
    }

    onCellDoubleClick(param: GridCellParams) {
        const { rowData } = this.state;
        this.setState({ selectedRow: rowData.filter(r => r.id === param.row.id)[0], tradeDetailVisible: true });
    }

    onEnterPressed(e) {
        if (e.keyCode === 13)
            this.runQuery(this.state.tradeQuery);
    }

    async onChange(newWidth?: number, selectedColumns?: string[], showfullDates?: boolean) {

        const { productsById, availableUsers, tradeQuery, showFullDateTime, waitingForRfqUpdate } = this.state;

        if (waitingForRfqUpdate) {
            var rfq = rfqSummaryStoreInstance.GetRfq(waitingForRfqUpdate);
            if (rfq)
                this.setState({ waitingForRfqUpdate: undefined })
        }
        if (showfullDates === undefined)
            showfullDates = showFullDateTime;

        var trades = tradesStoreInstance.getTradeQuery(tradeQuery.uniqueId);
        if (!trades)
            return;
        trades = trades.sort((a, b) => a.executedUtc.valueOf() - b.executedUtc.valueOf());
        if (!newWidth)
            newWidth = this.state.width;
        if (!selectedColumns)
            selectedColumns = this.state.selectedColumns;

        let productsNeeded = new Array<number>();

        trades.forEach(trade => {
            if (!productsById.has(trade.productId)) {
                let product = productStoreInstance.getProductById(trade.productId);
                if (product !== undefined && product !== null)
                    productsById.set(trade.productId, product);
                else {
                    productsNeeded.push(trade.productId);
                }
            }
        });
        if (productsNeeded.length > 0) {
            var summaries = await LoadProductSummaries(productsNeeded);
            summaries.forEach(summary => {
                productsById.set(summary.productId, summary);
            });
        }

        if (availableUsers.size === 0) {
            userStoreInstance.GetUsers().forEach(user => {
                availableUsers.set(user.userId, user);
            });
        }
        let rowData = this.getData(trades, productsById, availableUsers);
        //let maxSizes = this.calcColumnSizes(rowData);
        let availableColumnsSet = new Set<string>();
        rowData.forEach(row => {
            var names = Object.keys(row);
            names.forEach(name => {
                availableColumnsSet.add(name)
            });
        });

        let availableColumnsNames = Array.from(availableColumnsSet);
        let colWidth = 100;
        if (!tradeQuery.columns)
            tradeQuery.columns = [...defaultColumns];

        let columns = availableColumnsNames
            .filter(a => tradeQuery?.columns?.includes(a)).sort((a, b) => tradeQuery?.columns?.indexOf(a) - tradeQuery?.columns?.indexOf(b))
            .concat(availableColumnsNames.filter(a => !tradeQuery?.columns?.includes(a)))
            .map(c => {
                return {
                    flex: c === "Description" ? 2 : null,
                    width: (Boolean(tradeQuery?.columnWidths) && tradeQuery?.columnWidths[c] ? tradeQuery?.columnWidths[c] : null) ?? sizeMapColumns.filter(n => n.name === c)[0]?.size ?? colWidth,
                    field: c,
                    headerName: nameMapColumns.filter(n => n.name === c)[0]?.map ?? c,
                    type: dateTimeColumns.includes(c) ? "dateTime" : (numberColumns.includes(c) ? "number" : null),
                    renderCell: dateTimeColumns.includes(c) ? (showfullDates ? renderDateTimeCell : renderDateCell) : null,
                    //renderHeader: this.renderColHeader,
                    cellClassName: "PositionSummaryTabTableCell",
                    headerClassName: "TradeBlotterTableCellHeader",
                    hide: !tradeQuery?.columns?.includes(c),
                    resizable: true,
                } as GridColDef;
            });
        this.props.onChangeState("tradeBlotterQueryRunning", "false");
        this.setState({ trades, productsById, rowData, availableColumnsNames, columns, queryRunning: false });
    }

    getData(trades: Trade[], productsById: Map<number, ProductSummary>, availableUsers: Map<number, UserSummary>) {
        let rowData = trades.sort((a, b) => b.executedUtc.valueOf() - a.executedUtc.valueOf()).map(row => {
            var isListed = row.listedInstrumentId && row.listedInstrumentId > 0;
            var lins = isListed ? listedInstrumentStoreInstance.getInstrumentById(row.listedInstrumentId) : undefined;
            var hasUl = isListed && lins && lins.underlyingId;
            var ullins = hasUl ? listedInstrumentStoreInstance.getInstrumentById(lins.underlyingId) : undefined;
            var user = availableUsers.get(row.createdBy);
            var dataRow = {
                id: row.tradeId,
                Traded: row.executedUtc,
                Trader: user?.userInfo?.displayName ?? user?.name,
                Last: row.lastUpdatedUtc,
                Id: row.tradeId,
                Description: isListed ? lins?.description : productsById.get(row.productId)?.description,
                Multiplier: isListed ? lins?.multiplier ?? 1.0 : 1.0,
                Payoff: isListed ? lins?.type : ProductPayoffType[productsById.get(row.productId)?.spec?.payoffSubType],
                PayoffSubType: hasUl ? ullins?.type : productsById.get(row.productId)?.spec?.payoffSubType,
                Size: row.size,
                Ccy: row.currency,
                Price: row.price,
                Status: row.status,
                RfqId: row.rfqId,
                ProductId: row.productId,
                Brokerage: row.brokerage,
                BrokerageCcy: row.brokerageCurrency,
            } as BlotterDataRow;
            if (row.tradeMetaData)
                row.tradeMetaData.forEach(meta => {
                    if (meta.category == null)
                        dataRow[meta.type] = meta.data;
                    else
                        dataRow[`${this.mapMetaCategory(meta.category)}-${meta.type}`] = meta.data;
                });
            if (lins?.metaData)
                lins.metaData.forEach(meta => {
                    if (meta.category == null)
                        dataRow[`I-${meta.type}`] = meta.data;
                    else
                        dataRow[`I-${this.mapMetaCategory(meta.category)}-${meta.type}`] = meta.data;
                });
            return dataRow;
        });
        return rowData;
    }

    mapMetaCategory(c: string) {
        switch (c) {
            case "AdditionalData":
                return "AD";
            case "ExternalId":
                return "EX";
            case "SourceSystemId":
                return "ID";
            default:
                return c;
        }
    }

    onChangeColumns(params: any) {
        var selectedColumns = this.state.selectedColumns;
        var query = this.state.tradeQuery;
        if (params.isVisible) {
            selectedColumns.push(params.field);
        }
        else {
            selectedColumns = selectedColumns.filter(c => c !== params.field);
        }

        query.columns = selectedColumns;
        SetSessionState("tradeBlotterColumns", selectedColumns.join(","));
        this.updateQuery(query);
        this.setState({ selectedColumns, tradeQuery: query });
    }

    onChangeColumnsOrder(params: GridColumnOrderChangeParams) {
        var selectedColumns = this.state.selectedColumns;
        var query = this.state.tradeQuery;
        var newIxWhole = params.targetIndex;
        var fieldNewIxWhole = this.state.columns[newIxWhole].field;
        var oldIxSelected = selectedColumns.indexOf(params.field);
        var newIxSelected = selectedColumns.indexOf(fieldNewIxWhole);
        var col = selectedColumns.splice(oldIxSelected, 1);
        selectedColumns = [...selectedColumns.slice(0, newIxSelected), col[0], ...selectedColumns.slice(newIxSelected)];
        query.columns = selectedColumns;
        SetSessionState("tradeBlotterColumns", selectedColumns.join(","));
        this.updateQuery(query);
        this.setState({ selectedColumns, tradeQuery: query });
    }
    onChangeColumnsWidth(params: GridColumnResizeParams) {
        var query = this.state.tradeQuery;
        if (!Boolean(query.columnWidths))
            query.columnWidths = {};
        query.columnWidths[params.colDef.field] = params.width;
        this.updateQuery(query);
        this.setState({ tradeQuery: query });
    }

    onTradeDetailClosed() {
        this.setState({ tradeDetailVisible: false })
    }


    renderTicketDetail(trade: Trade) {
        const { availableUsers } = this.state;
        const hasMeta = trade.tradeMetaData && trade.tradeMetaData.length > 0;

        return (
            <ThemeProvider theme={getFormTheme()}>
                <TableContainer>
                    <Table component={Paper}>
                        <TableHead />
                        <TableBody>
                            <TableRow>
                                <TableCell align="right" style={titleStyle}>Trade Id</TableCell>
                                <TableCell style={cellStyle}>{trade.tradeId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right" style={titleStyle}>Trader</TableCell>
                                <TableCell style={cellStyle}>{availableUsers.get(trade.createdBy)?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right" style={titleStyle}>Traded</TableCell>
                                <TableCell style={cellStyle}>{moment(trade.executedUtc).format("yyyy-MM-DD HH:mm:ss")}</TableCell>
                            </TableRow>
                            {hasMeta ?
                                <TableRow>
                                    <TableCell align="center" colSpan={2} style={titleStyle}>Meta Data</TableCell>
                                </TableRow> : null}
                            {hasMeta ? trade.tradeMetaData.map(tm =>
                                <TableRow>
                                    <TableCell align="right" style={titleStyle}>{tm.type}</TableCell>
                                    <TableCell style={cellStyle}>{tm.data}</TableCell>
                                </TableRow>) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ThemeProvider>
        );
    }



    renderTradeDetail() {
        const { trades, productsById, selectedRow, tradeDetailVisible, waitingForRfqUpdate } = this.state;
        var row = selectedRow;
        if (row !== undefined) {
            var tradeId = row.Id;
            var trade = trades.filter(x => x.tradeId === tradeId)[0];
            var rfqId = row.RfqId;
            var rfqSummary = row.RfqId ? rfqSummaryStoreInstance.GetRfq(row.RfqId) : undefined;
            if (row.RfqId && !rfqSummary && waitingForRfqUpdate !== row.RfqId) {
                this.setState({ waitingForRfqUpdate: row.RfqId });
                RefreshRfqSummary(row.RfqId);
            }
            var productId = row.ProductId;
            var product = productsById.get(productId);
            return (
                <ThemeProvider theme={getFormTheme()}>
                    <div className="TradeDetailBox">
                        <AppBar className="LayoutTopAppBar">
                            <Toolbar style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => this.setState({ tradeDetailVisible: !tradeDetailVisible })}
                                    aria-label="close"
                                    size="large">
                                    <CloseOutlined />
                                </IconButton>
                                <Typography variant="h6">Trade Detail</Typography>
                            </Toolbar>
                        </AppBar>
                        <div className="TradeDetailBoxTicketDetail">
                            <div className="TradeDetailBoxSectionHeader">Ticket Detail</div>
                            {this.renderTicketDetail(trade)}
                        </div>
                        <div className="TradeDetailBoxProductDetail">
                            <div className="TradeDetailBoxSectionHeader">Product Detail</div>
                            {trade.productId && trade.productId > 0 ?
                                rfqSummary && <StructuredProductDesigner counterpartyId="" existingSpec={product.spec} existingRfqSummary={rfqSummary} disabled={true} dashboardMode={true} /> :
                                this.renderListedInsDetail(trade)}
                        </div>
                        {rfqId ?
                            <div className="TradeDetailBoxRfqAuditDetail">
                                <div className="TradeDetailBoxSectionHeader">Event Detail</div>
                                <RfqAuditLogViewer key={`${this.props.id}+blotterRfq`} pageSize={20} rfqId={rfqId} className="TradeDetailBoxRfqAuditDetailInner" /></div> : null}
                    </div>
                </ThemeProvider>
            );
        }
        return null;
    }

    renderListedInsDetail(trade: Trade) {
        const ins = listedInstrumentStoreInstance.getInstrumentById(trade.listedInstrumentId);
        const ulins = ins.underlyingId ? listedInstrumentStoreInstance.getInstrumentById(ins.underlyingId) : undefined;
        const hasMeta = ins.metaData && ins.metaData.length > 0;
        if (ins)
            return (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getTableTheme()}>
                        <TableContainer>
                            <Table component={Paper}>
                                <TableHead />
                                <TableBody>
                                    <TableRow><TableCell align="right" style={titleStyle}>Instrument Id</TableCell><TableCell>{ins.listedInstrumentId}</TableCell></TableRow>
                                    <TableRow><TableCell align="right" style={titleStyle}>Type</TableCell><TableCell>{ins.type}</TableCell></TableRow>
                                    <TableRow><TableCell align="right" style={titleStyle}>Ticker</TableCell><TableCell>{ins.ticker}</TableCell></TableRow>
                                    <TableRow><TableCell align="right" style={titleStyle}>Description</TableCell><TableCell>{ins.description}</TableCell></TableRow>
                                    {ins.isin ? <TableRow><TableCell align="right" style={titleStyle}>ISIN</TableCell><TableCell>{ins.isin}</TableCell></TableRow> : null}
                                    {ulins ? <TableRow><TableCell align="right" style={titleStyle}>Underlying</TableCell><TableCell>{ulins.description}</TableCell></TableRow> : null}
                                    {ins.maturity ? <TableRow><TableCell align="right" style={titleStyle}>Maturity</TableCell><TableCell>{moment(ins.maturity).format(userStoreInstance.GetDateFormat())}</TableCell></TableRow> : null}
                                    {ins.optionStrike ? <TableRow><TableCell align="right" style={titleStyle}>Strike</TableCell><TableCell>{ins.optionStrike}</TableCell></TableRow> : null}
                                    {ins.type === "Option" ? <TableRow><TableCell align="right" style={titleStyle}>Call/Put</TableCell><TableCell>{ins.optionIsCall ? "Call" : "Put"}</TableCell></TableRow> : null}
                                    {ins.multiplier !== 1 ? <TableRow><TableCell align="right" style={titleStyle}>Multiplier</TableCell><TableCell>{ins.multiplier}</TableCell></TableRow> : null}
                                    {hasMeta ?
                                        <TableRow>
                                            <TableCell variant="head" align="center" style={titleStyle} colSpan={2}>Meta Data</TableCell>
                                        </TableRow> : null}
                                    {hasMeta ? ins.metaData.map(tm =>
                                        <TableRow>
                                            <TableCell align="right" style={titleStyle}>{tm.type}</TableCell>
                                            <TableCell>{tm.data}</TableCell>
                                        </TableRow>) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ThemeProvider>
                </StyledEngineProvider>
            );
        else
            return <div>No Data</div>;
    }

    updateQuery(query: TradeQuery) {
        SetSessionState("tradeBlotterQuery", query)
    }

    renderTradeQuery() {
        const { showQuery, tradeQuery, queryRunning, querySingleId } = this.state;
        if (showQuery) {
            return (
                <div className="TradeBlotterQuery" style={{ minWidth: "1350px", overflowX: "auto" }}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <div className="TradeBlotterQueryItem">
                                <PltfmDateTimePicker
                                    key={"tqFromDate" + this.state.tradeQuery?.uniqueId}
                                    selectedDate={this.state.tradeQuery.fromDate}
                                    onChange={(date) => {
                                        var q = this.state.tradeQuery;
                                        q.fromDate = date;
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q })
                                    }}
                                    disabled={false}
                                    label="From Date"
                                    minDate={EarliestDate}
                                    size="small"
                                    width="120px" />
                            </div>
                            <div className="TradeBlotterQueryItem">
                                <PltfmDateTimePicker
                                    key={"tqToDate" + this.state.tradeQuery?.uniqueId}
                                    selectedDate={this.state.tradeQuery.toDate}
                                    onChange={(date) => {
                                        var q = this.state.tradeQuery;
                                        q.toDate = date;
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q })
                                    }}
                                    disabled={false}
                                    label="To Date"
                                    minDate={EarliestDate}
                                    size="small"
                                    width="120px" />
                            </div>
                            <div className="TradeBlotterQueryItem">
                                <WrappedSelect
                                    id="trader"
                                    name="trader"
                                    label="Trader"
                                    value={tradeQuery.trader ?? nullUser}
                                    onChange={(d) => {
                                        var q = this.state.tradeQuery;
                                        q.trader = d.target.value as string;
                                        if (q.trader === nullUser)
                                            q.trader = null;
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q });
                                    }}>
                                    {[nullUser, ...userStoreInstance.GetUsers().map(a => a.name)].map(a =>
                                        <MenuItem key={"user_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </div>
                            <div className="TradeBlotterQueryItem">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    style={{ width: "120px" }}
                                    label={querySingleId ? "Trade Id" : "Start Trade Id"}
                                    value={this.state.tradeQuery.tradeId ?? ""}
                                    placeholder="Trade Id"
                                    onKeyDown={this.onEnterPressed}
                                    onChange={(e) => {
                                        var q = this.state.tradeQuery;
                                        q.tradeId = parseInt(e.target.value);
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q });
                                    }} />
                            </div>
                            <div className="TradeBlotterQueryItem">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label={"Description"}
                                    placeholder="Description"
                                    value={this.state.tradeQuery.description ?? ""}
                                    onKeyDown={this.onEnterPressed}
                                    onChange={(e) => {
                                        var q = this.state.tradeQuery;
                                        q.description = e.target.value;
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q });
                                    }} />
                            </div>
                            <div className="TradeBlotterQueryItem">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label={"ExternalId"}
                                    placeholder="ExternalId"
                                    value={this.state.tradeQuery.externalId ?? ""}
                                    onKeyDown={this.onEnterPressed}
                                    onChange={(e) => {
                                        var q = this.state.tradeQuery;
                                        q.externalId = e.target.value;
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q });
                                    }} />
                            </div>
                            <div className="TradeBlotterQueryItem" style={{ minWidth: "150px" }}>
                                <WrappedSelect
                                    style={{ minWidth: "145px" }}
                                    id="type"
                                    name="type"
                                    label="Instrument Type"
                                    value={tradeQuery.payoff ?? nullInsType}
                                    onChange={(d) => {
                                        var q = this.state.tradeQuery;
                                        q.payoff = d.target.value as string;
                                        if (q.payoff === nullInsType)
                                            q.payoff = null;
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q });
                                    }}>
                                    {[nullInsType, ...validInsTypes].map(a =>
                                        <MenuItem key={"insType_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </div>
                            <div className="TradeBlotterQueryItem">
                                <WrappedSelect
                                    id="onlyMissing"
                                    name="onlyMissing"
                                    label="Fills"
                                    value={tradeQuery.dailyAggregate ? "Daily" : "All Fills"}
                                    onChange={(d) => {
                                        var q = this.state.tradeQuery;
                                        q.dailyAggregate = (d.target.value as string) === "Daily";
                                        this.updateQuery(q);
                                        this.setState({ tradeQuery: q });
                                    }}>
                                    <MenuItem key={"insTypeX_Daily"} value={"Daily"}>Daily</MenuItem>
                                    <MenuItem key={"insTypeX_All Fills"} value={"All Fills"}>All Fills</MenuItem>
                                </WrappedSelect>
                            </div>

                            <div className="TradeBlotterQueryItem">
                                <Button className="MuiButton-outlined PltfmButtonLite" style={{ height: "2.75em" }} size="small" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.tradeQuery)} disabled={queryRunning}>Run Query</Button>
                            </div>
                            {queryRunning ?
                                <div className="TradeBlotterQueryItem">
                                    <CircularProgress />
                                </div> :
                                <div className="TradeBlotterQueryItem">
                                    <Typography variant="subtitle2">{this.state.rowData?.length > 0 ? `${this.state.rowData?.length} rows` : null}</Typography>
                                </div>}
                        </ThemeProvider>
                    </StyledEngineProvider>

                </div>
            );
        }
        return null;
    }

    async onSaveQuery(name: string, category: string, isPublic: boolean) {
        const { tradeQuery, selectedConfig } = this.state;
        if (name) {
            var blob = (selectedConfig?.displayName === name) ? selectedConfig :
                {
                    displayName: name,
                    isPrivate: !isPublic,
                    objectType: TradeQueryStr,
                    ownerId: userStoreInstance.GetUserInfo().userId,
                } as ConfigBlob;
            tradeQuery.uniqueId = v4();
            blob.blob = JSON.stringify(tradeQuery);
            await AddOrUpdateConfig(blob);
            await GetConfigsForType(TradeQueryStr);
            this.setState({ showSaveQuery: false });
        }
    }

    async onDeleteQuery(id: number) {
        const toDelete = userStoreInstance.getConfigsForType(TradeQueryStr).filter(t => t.configId === id)[0];
        if (toDelete) {
            await DeleteConfig(toDelete);
            await GetConfigsForType(TradeQueryStr);
        }
    }

    renderQueryOptions() {
        return userStoreInstance.getConfigsForType(TradeQueryStr).map(c => { return { name: c.displayName, id: c.configId } as LoadItemSpec });
    }

    render() {
        const { rowData, tradeDetailVisible, showSaveQuery, columns, mainRef, showQuery, toolbarHeight } = this.state;

        var columnVisibility: GridColumnVisibilityModel = {};
        columns.forEach(col => {
            columnVisibility[col.field] = !col.hide;
        });

        if (rowData) {
            return (
                <div className="TradeBlotterTab" ref={this.state.mainRef}>
                    <Dialog fullScreen key="tradeDetail"
                        open={tradeDetailVisible}
                        classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                        onKeyDown={(e => { if (e.key === "Escape") { this.setState({ tradeDetailVisible: !tradeDetailVisible }) } })}>
                        <DialogTitle>Trade Detail</DialogTitle>
                        <DialogActions></DialogActions>
                        <div>{tradeDetailVisible ? this.renderTradeDetail() : null}</div>
                    </Dialog>
                    <SaveLoadDeleteBox
                        title='Load / Save Queries'
                        showBox={showSaveQuery}
                        onClose={() => this.setState({ showSaveQuery: false })}
                        onDelete={this.onDeleteQuery}
                        onLoad={this.onLoadQuery}
                        onSave={this.onSaveQuery}
                        onUpdate={(id, name, category, isPublic) => { }}
                        options={this.renderQueryOptions()}
                        checkItemExists={(name) => false} />
                    {this.renderTradeQuery()}
                    <div className="TradeBlotter" style={{ height: showQuery ? `calc( 100% - ${(toolbarHeight + 30)}px )` : "100%" }}>
                        <div className="TradeBlotterGrid">
                            <div key={`agf-${this.props.id}`} ref={mainRef} style={{ height: "100%", width: "100%" }}>
                                {columns && columns.length > 0 && <DataGridPro
                                    className="TradeBlotterDataGrid"
                                    //columnVisibilityModel={columnVisibility}
                                    initialState={{
                                        columns: { columnVisibilityModel: columnVisibility },
                                    }}
                                    key={`ag-${this.props.id}`}
                                    rows={rowData}
                                    columns={columns}
                                    hideFooter
                                    density={userStoreInstance.GetGridDensity()}
                                    onCellDoubleClick={this.onCellDoubleClick}
                                    onColumnVisibilityChange={this.onChangeColumns}
                                    onColumnOrderChange={this.onChangeColumnsOrder}
                                    onColumnWidthChange={this.onChangeColumnsWidth}
                                    components={{
                                        Toolbar: this.CustomToolbar,
                                    }}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else
            return null;
    }
}