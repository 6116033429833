import { HubConnection, HubConnectionBuilder, IRetryPolicy, RetryContext } from "@microsoft/signalr";
import { UpdateLoadingStatus } from "../loading/loadingActions";
import { LoadingStatus } from "../loading/loadingModels";
import userToken, { GetAPIUrl, GetToken } from '../utils/httpUtils';

export enum ConnectionStatus {
    Connected = 1,
    Disconnected = 2,
    Reconnecting = 3,
    WaitingForReconnection = 4,
}

export type ConnectionState = {
    allConnected: boolean,
    connections: Map<string, IConnection>
}

export interface IConnection {
    onStatusChange: (status: ConnectionStatus) => void;
    status: ConnectionStatus;
}

export class RetryPolicy implements IRetryPolicy {
    nextRetryDelayInMilliseconds(retryContext: RetryContext) {
        return (retryContext.previousRetryCount ^2) * 1000;
    }
}

export abstract class connectionBase implements IConnection {
    connectPromise: Promise<void> | null;
    status: ConnectionStatus;
    readonly hubLocation: string;
    hubConnection: HubConnection;
    readonly connectionName: string;

    constructor(hubLocation: string, connectionName: string) {
        this.status = ConnectionStatus.Disconnected;
        this.hubLocation = hubLocation;
        this.connectionName = connectionName;
    }

    public connect = async () => {

        let location = this.hubLocation;
        if(userToken.userId)
        {
            location += "?PltfmUser=" + userToken.userId.toString();
        }
        
        let token = await GetToken();

        this.hubConnection = new HubConnectionBuilder()
        .withUrl(GetAPIUrl() + location , {accessTokenFactory: () => token})
        .withAutomaticReconnect(new RetryPolicy())
        .build();
        this.hubConnection.serverTimeoutInMilliseconds = 40000;
        this.hubConnection.onreconnected((id) => { this.connectionOnReconnect(id) });
        this.hubConnection.onreconnecting((error) => this.connectionOnReconnecting(error));
        this.beforeConnect();

        this.connectPromise = this.hubConnection.start().then(() => {
            this.afterConnect();
            UpdateLoadingStatus(this.connectionName, LoadingStatus.Completed);
            this.status = ConnectionStatus.Connected;
            this.onStatusChange(this.status);
        })
    }

    abstract onStatusChange(status: ConnectionStatus): void;
    abstract beforeConnect(): void;
    abstract afterConnect(): void;
    abstract afterReconnect(): void;

    connectionOnReconnect(connectionId: string | undefined) {
        if (this.status === ConnectionStatus.Connected) return;
        this.status = ConnectionStatus.Connected;
        this.onStatusChange(this.status);
        this.afterReconnect();
    }

    connectionOnReconnecting(error?: Error) {
        if (this.status === ConnectionStatus.Reconnecting) return;
        this.status = ConnectionStatus.Reconnecting;
        this.onStatusChange(this.status);
    }
}