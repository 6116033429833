import _ from "lodash";

export class MultiDimArray<T>  {
	 length0: number;
	 length1: number;
	 jaggedLengths: number[];
	 backingArray: T[];
	}

export function MultiDimToJaggedArray<T>(m: MultiDimArray<T>) : T[][]{
	var o = new Array<T[]>(m.length0);		
	for (var i = 0; i < m.length0; i++)
	{
		const ci = i; //remove dumb linter warnings
		o[i] = new Array<T>(m.jaggedLengths[i]);
		var lengthsSoFar = i === 0 ? 0 : _.sum(m.jaggedLengths.filter((u, x) => x < ci));
		for (var j = 0; j < m.jaggedLengths[i]; j++)
			o[ci][j] = m.backingArray[lengthsSoFar + j];
	}
	return o;
 }