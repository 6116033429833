import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import { NewsItem } from './newsTickerModels';
import newsStoreInstance from './newsTickerStore';
import { MenuItem, Typography } from '@mui/material';
import userStoreInstance from '../user/userStore';
import _ from 'lodash';

export type NewsListProps = {
    onChangeHeight?: (newHeight: number) => void
}
type NewsListComponentState = {
    loading: boolean,
    updated: boolean,
    newsItems: NewsItem[],
    sourcesToFilterOn: string[],
    allSources: string[],
    hover: boolean;
    height: number;
    mainRef: RefObject<HTMLDivElement>,
}

export class NewsList extends React.Component<NewsListProps, NewsListComponentState>{
    eventSubscriptionInbox: FBEmitter.EventSubscription | undefined;

    constructor(props: NewsListProps) {
        super(props);
        this.state = {
            newsItems: [],
            allSources: [],
            loading: true,
            sourcesToFilterOn: new Array<string>(),
            updated: false,
            hover: false,
            height: 400,
            mainRef: React.createRef()
        }
    }

    componentDidMount() {
        this.eventSubscriptionInbox = newsStoreInstance.addChangeListener(() => this.onChange());
        newsStoreInstance.refreshNews();
        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionInbox) {
            this.eventSubscriptionInbox.remove();
            this.eventSubscriptionInbox = undefined;
        }
    }

    // public componentDidUpdate() {
    //     if (this.props.onChangeHeight) {
    //         let newHeight = this.state.mainRef.current.clientHeight;
    //         if (newHeight !== this.state.height) {
    //             this.props.onChangeHeight(newHeight);
    //             this.setState({ height: newHeight });
    //         }
    //     }
    // }

    onChange() {
        let items = newsStoreInstance.getNewsItems();
        let sources = [...new Set(items.map(i => i.itemSource))];
        this.setState({ newsItems: items, allSources: sources });
        this.forceUpdate();
    }

    onChangeSources = (sources: string[]) => {
        if (sources.length === 0) {
            this.setState({ sourcesToFilterOn: new Array<string>() });
        }
        else {
            this.setState({ sourcesToFilterOn: sources });
        }
    }

    getSources = () => {
        let allTags = this.state.newsItems.map(x => x.itemSource);
        let uniqueTags = Array.from(new Set(allTags));
        return uniqueTags;
    }

    onClickItem = (uniqueKey: string) => {

    }


    renderItem = (item: NewsItem) => {
        return (<div className="ticker__item" key={item.uniqueKey}>{item.headline}</div>)
    }

    private isValueSelected(value: string) { return this.state.sourcesToFilterOn.some(c => c === value) }
    private renderSourceItem = (value: string) => {

        return (
            <MenuItem id={"src_" + value}
                // icon={this.isValueSelected(value) ? "tick" : "blank"}
                key={value}
                onClick={()=>this.onSourceSelect(value)}>
                    {value}
            </MenuItem>
        );
    };

    private onSourceSelect = (value: string) => {
        var selectedSources = this.state.sourcesToFilterOn;
        if (!this.isValueSelected(value)) {
            selectedSources.push(value);
        } else {
            selectedSources = this.state.sourcesToFilterOn.filter(x => x !== value);
        }
        this.setState({ sourcesToFilterOn: selectedSources });
    };


    onRightClick(mouseEvent : React.MouseEvent<HTMLDivElement, MouseEvent>) {
        // ContextMenu.show((
        //     <Menu key="srcMenu" title="News Sources">
        //     {this.state.allSources.map(s=>this.renderSourceItem(s))}
        //     </Menu>
        //     ), { left: mouseEvent.clientX, top: mouseEvent.clientY });
        // mouseEvent.preventDefault();
    }

    render() {
        const {newsItems} = this.state;
        const newsBySource = _.groupBy(newsItems,n=>n.itemSource)
        return (
            <div className="NewsList" ref={this.state.mainRef}
                onMouseEnter={() => { this.setState({ hover: true }) }} 
                onMouseLeave={() => { this.setState({ hover: false }) }}
                onContextMenu={(e : React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onRightClick(e)}>
                <div className="NewsListInner">
                    {Object.keys(newsBySource).map(source=>{
                        return <div key={`srcGrp-${source}`}>
                            <div className="NewsListItem" key={`src-${source}`}><Typography variant='subtitle1'>Source: {source}</Typography></div>
                            {newsBySource[source]?.map((n,ix)=>{return (<div key={`${source}-${ix}`} className="NewsListItem"><a style={{color:userStoreInstance.GetTheme().color}} href={n.link} rel="noopener noreferrer" target="_blank">{n.headline}</a></div>);})}
                        </div>
                    })}
                </div>
            </div>
        );
    }
}