import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { get } from '../utils/httpUtils';
import { UpcomingEvent } from './eventsModels';

export class LoadUpcomingEventsEvent extends TypedEvent<UpcomingEvent[]> { }

export async function LoadUpcomingEvents() {
    const events = await get<UpcomingEvent[]>(`Events/GetEvents`);
    if (events.payload) { 
        AppDispatcher.dispatch(new LoadUpcomingEventsEvent(events.payload));
    }
}