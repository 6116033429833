import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import LayoutStore from './layoutStore';
import { AddLayoutObject, DeleteTab, SaveLayout, SaveTemplate, UpdateLayout, UpdateLayoutObjectProps } from './layoutActions';
import { LayoutComponent, LayoutTab, MainLayout, TabTemplate } from './layoutModels';
import GridLayout, { Layout } from 'react-grid-layout';
import { v4 } from 'uuid';
import userStoreInstance from '../user/userStore';
import HomepageControls from '../homepage/homepageControls';
import { Relationship } from '../relationship/relationship';
import ValidatedTextInput from '../inputs/validatedTextInput';
import layoutStoreInstance from './layoutStore';
import { colWidthPx, componentMap, gridRowHeight, gridWidth, margin } from './layoutControls';
import Impersonate from '../user/impersonateControl';
import UserSettingsControl from './userSettingsControl';
import { RfqDashboard } from '../rfq/rfqDashboard/rfqDashboard';
import SessionStatusWidget from '../userSession/sessionStatusWidget';
import { AppBar, Button, ButtonGroup, Dialog, Divider, Drawer, FormGroup, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Tab, Tabs, TextField, Toolbar, Typography } from '@mui/material';
import { AddCircleOutlineOutlined, CancelOutlined, CloseOutlined, DynamicFeedOutlined, EditOutlined, PostAddOutlined, SaveOutlined } from '@mui/icons-material';
import { TabPanel } from './tabPanel';
import { InstrumentTab } from '../listedInstruments/instrumentTab';
import { PositionTab } from '../positions/positionTab';
import { ClientEditorTab } from '../client/clientEditorTab';
import UserMessagesComponent from '../userMessages/userMessagesComponent';
import AdminConsoleTab from '../admin/adminConsoleTab';
import { NewTabItem, newTabItems } from './newTabItems';
import { TradeBlotterTab } from '../trade/tradeBlotterTab';
import { JobTab } from '../jobs/jobTab';
import { NavTab } from '../nav/navTab';
import { RiskTab } from '../risk/riskTab';
import { OrderTab } from '../orders/orderTab';
import { EngineeringTab } from '../engineering/engineeringTab';
import { ReportTab } from '../reports/reportTab';
import templateStoreInstance from './templateStore';
import rfqSummaryStoreInstance from '../rfq/rfqSummaryStore';
import { ThemeProvider } from '@mui/material/styles';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import { MarketDataTab } from '../marketData/marketDashboard/marketDataTab';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import moment from 'moment';
import { SettingsTab } from '../settings/settingsTab';

const autoSaveInterval = 1000;
export const tabNameRoleMap = new Map<string, string>([
    ["Home", "LoginToUi"],
    ["Trade", "ViewTabTrade"],
    ["Settings", "ViewTabSettings"],
    ["Rfq Dashboard", "ViewTabRfqDashboard"],
    ["Blotter", "ViewTabBlotter"],
    ["Credit", "ViewTabCredit"],
    ["Admin", "ViewTabAdmin"],
    ["Pricing", "ViewTabPricing"],
    ["Orders", "ViewTabOrders"],
    ["RFQ", "ViewTabRfq"]
]);

let runner: any;

export type LayoutControlProps = {
    username: string,
    isDev: boolean
}

type LayoutState = {
    theme: string;
    layout: MainLayout;
    jumpToTabId: number | undefined;
    messageDrawerOpen: boolean;
    layoutMap: Map<number, Map<string, GridLayout.Layout>>;
    tabLocks: Map<number, boolean>;
    elementPermanentLocks: Map<string, boolean>;
    uniqueKey: string;
    layoutDirty: boolean;
    showSaveTemplateDialogue: boolean;
    saveTemplateName: string | null;
    saveTemplateTabId: number;
    showLoadTemplateDialogue: boolean;
    showAddTabDialog: boolean;
    addTabName: string | null;
    loadTemplateReplaceCurrentTab: boolean;
    loadTemplateNewTabName: string | null;
    loadTemplateCurrentTabId: number;
    loadTemplateNewTabObject: TabTemplate | undefined;
    templates: TabTemplate[];
    controlsRef: RefObject<HomepageControls>;
    showOboDialogue: boolean;
    selectedOboClient: Relationship | undefined;
    gridRef: RefObject<GridLayout>;
    mainPageRef: RefObject<HTMLDivElement>;
    forceReLayout: boolean;
    selectedTabId: number;
    previousTabId: number;
    addTabMenuOpen: boolean;
    menuAnchor?: { mouseX: number; mouseY: number; };
    tabTitleContextId: number;
    tabTitleRenameActive: boolean;
    tabTitleNewName: string;

    mainWidth: number;
}

class LayoutControl extends React.Component<LayoutControlProps, LayoutState>{
    eventSubscription: FBEmitter.EventSubscription | undefined;
    eventSubscriptionTemplates: FBEmitter.EventSubscription | undefined;

    constructor(props: LayoutControlProps) {
        super(props);
        this.state = {
            theme: "pltfm",
            layout: LayoutStore.GetLayout(),
            jumpToTabId: undefined,
            messageDrawerOpen: false,
            layoutMap: new Map<number, Map<string, GridLayout.Layout>>(),
            uniqueKey: "",
            tabLocks: new Map<number, boolean>(),
            elementPermanentLocks: new Map<string, boolean>(),
            layoutDirty: false,
            showSaveTemplateDialogue: false,
            saveTemplateName: null,
            saveTemplateTabId: -1,
            loadTemplateNewTabName: null,
            loadTemplateNewTabObject: undefined,
            loadTemplateReplaceCurrentTab: false,
            showLoadTemplateDialogue: false,
            loadTemplateCurrentTabId: -1,
            templates: [],
            controlsRef: React.createRef(),
            showOboDialogue: false,
            selectedOboClient: undefined,
            gridRef: React.createRef(),
            mainPageRef: React.createRef(),
            forceReLayout: false,
            selectedTabId: 0,
            previousTabId: 0,
            addTabMenuOpen: false,
            showAddTabDialog: false,
            addTabName: null,
            tabTitleContextId: undefined,
            tabTitleRenameActive: false,
            tabTitleNewName: undefined,
            mainWidth: gridWidth
        };
        this.onComponentClosed = this.onComponentClosed.bind(this);
        this.onCloseMessageDrawer = this.onCloseMessageDrawer.bind(this);
        this.onClickAddTab = this.onClickAddTab.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onChangeHeight = this.onChangeHeight.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTitleKeyPressed = this.onTitleKeyPressed.bind(this);
    }

    async componentDidMount() {
        this.eventSubscription = layoutStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionTemplates = templateStoreInstance.addChangeListener(() => this.onChangeTemplates());
        runner = setInterval(() => {
            if (this.state.layoutDirty) {
                SaveLayout(this.state.layout);
                this.setState({ layoutDirty: false });
            }

        }, autoSaveInterval);

        this.onChangeTemplates();
    }

    onChange() {
        const state = layoutStoreInstance.GetLayout();
        this.setState({ layout: state, layoutDirty: true, selectedTabId: state.currentTabId });
    }

    onChangeTemplates() {
        const templates = templateStoreInstance.GetTemplates();
        this.setState({ templates });
    }

    async componentWillUnmount() {
        if (this.eventSubscription !== undefined) {
            this.eventSubscription.remove();
        }
        if (this.eventSubscriptionTemplates !== undefined) {
            this.eventSubscriptionTemplates.remove();
        }
        if (runner) {
            clearInterval(runner);
        }
    }

    public componentDidUpdate() {
        let newWidth = this.state.mainPageRef.current.clientWidth;
        if (newWidth !== this.state.mainWidth) {
            this.setState({ mainWidth: newWidth });
        }
    }

    public switchTab = (tabToSwitchTo: number) => {
        this.setState({ jumpToTabId: tabToSwitchTo });
        this.forceUpdate();
    }

    private onCloseMessageDrawer() {
        this.setState({ messageDrawerOpen: false });
    }

    onChangeLoadTemplateName = (tabName: string) => {
        this.setState({ loadTemplateNewTabName: tabName });
        return tabName !== null && tabName !== "" && this.state.layout.tabs.filter(t => t.name === tabName).length === 0;
    }

    onClickSaveCurrentTemplate = () => {
        this.setState({ showSaveTemplateDialogue: true, saveTemplateTabId: this.state.selectedTabId })
    }

    onClickLoadTemplate = (template: TabTemplate) => {
        this.setState({ showLoadTemplateDialogue: true, loadTemplateNewTabObject: template, loadTemplateNewTabName: null, loadTemplateReplaceCurrentTab: false, loadTemplateCurrentTabId: this.state.selectedTabId })
    }


    onClickAddTab(elementKey: string) {
        if (elementKey === "OBO") {
            this.setState({ showOboDialogue: true });
            return;
        }

        this.setState({ addTabMenuOpen: false, menuAnchor: undefined })

        var nextTabId = this.state.layout.tabs.map(t => t.tabId).reduce((p, i) => Math.max(p, i), 0) + 1;
        let component = {
            key: v4(),
            type: elementKey
        } as LayoutComponent;

        let t = {
            canDelete: true,
            name: elementKey,
            tabId: nextTabId,
            isGridTab: elementKey !== "Pricing",
            components: [component],
        } as LayoutTab;
        this.state.layout.tabs.push(t);
        this.setState({ layout: this.state.layout, jumpToTabId: nextTabId, layoutDirty: true, addTabMenuOpen: false, menuAnchor: undefined });
    }

    onAbandonSaveTemplate = () => {
        this.setState({ showSaveTemplateDialogue: false });
    }

    async onSaveTemplate(fileName: string, category?: string, isPublic?: boolean) {
        if (fileName != null) {
            //var tab = this.state.layout.tabs.filter(t => t.tabId === this.state.saveTemplateTabId)[0];
            var tab = this.state.layout.tabs.filter(t => t.tabId === this.state.layout.currentTabId)[0];
            await SaveTemplate(fileName, tab);
        }

        this.setState({ saveTemplateName: null, showSaveTemplateDialogue: false });
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        const { selectedTabId } = this.state;
        this.setState({ selectedTabId: newValue, previousTabId: selectedTabId });
        layoutStoreInstance.SetCurrentTabId(newValue);
    }

    onChangeSaveTemplateName = (fileName: string) => {
        this.setState({ saveTemplateName: fileName });
        return fileName != null && fileName !== "";
    }

    onClickAddTabButton(event: any) {
        const { addTabMenuOpen } = this.state;
        var menuAnchor = this.state.menuAnchor === undefined ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : undefined;
        this.setState({ addTabMenuOpen: !addTabMenuOpen, menuAnchor })
    }


    renderTabMenuItem(item: NewTabItem) {
        var isTabAllowed = item.allowedRoles.some(r => userStoreInstance.GetUserInfo().roles.includes(r));

        return (!isTabAllowed) ? null :
            <MenuItem key={item.label} onClick={(event: React.FormEvent) => { this.onClickAddTab(item.actionName); }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.label}</ListItemText></MenuItem>;
    }

    renderAddTabMenu(text: string) {
        const { addTabMenuOpen, menuAnchor } = this.state;
        return (<div>
            <PostAddOutlined onClick={(e) => this.onClickAddTabButton(e)} />
            <Menu
                anchorReference="anchorPosition"
                anchorPosition={menuAnchor !== undefined ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX } : undefined}
                open={addTabMenuOpen}
                onClose={() => this.setState({ addTabMenuOpen: false })}>
                {newTabItems.map(i => this.renderTabMenuItem(i))}
                <MenuItem onClick={(event: React.FormEvent) => { this.setState({ showAddTabDialog: true }) }}>
                    <ListItemIcon><DynamicFeedOutlined /></ListItemIcon>
                    <ListItemText>Flexible Tab</ListItemText></MenuItem>
            </Menu>
        </div>
        );
    }

    getNextAvailableTabId = () => {
        for (let i = 0; i < this.state.layout.tabs.length + 1; i++) {
            var tabsThisI = this.state.layout.tabs.filter(t => t.tabId === i);
            if (tabsThisI.length === 0) {
                return i;
            }
        }

        return this.state.layout.tabs.length + 1;
    }

    onLoadTemplate = () => {
        if (this.state.loadTemplateNewTabObject) {
            var newTab = this.state.loadTemplateNewTabObject.tabObject;
            var newTabs = this.state.loadTemplateReplaceCurrentTab ? this.state.layout.tabs.filter(t => t.tabId !== this.state.loadTemplateCurrentTabId) : this.state.layout.tabs;
            if (this.state.loadTemplateReplaceCurrentTab) {
                var currentTab = this.state.layout.tabs.filter(t => t.tabId === this.state.loadTemplateCurrentTabId)[0];
                newTab.name = currentTab.name;
                newTab.tabId = currentTab.tabId;
            }
            else {
                newTab.tabId = this.getNextAvailableTabId();
                if (this.state.loadTemplateNewTabName) {
                    newTab.name = this.state.loadTemplateNewTabName;
                }
            }
            newTab.canDelete = true;
            var newlayout = this.state.layout;

            newlayout.tabs = [newTab, ...newTabs];
            this.setState({ layout: newlayout });
        }
        this.setState({ loadTemplateNewTabName: null, showLoadTemplateDialogue: false });
    }

    onLoadTemplate2 = (template: TabTemplate) => {
        if (template) {
            var newTab = template.tabObject;
            var newTabs = this.state.layout.tabs.filter(t => t.tabId !== this.state.layout.currentTabId);
            var currentTab = this.state.layout.tabs.filter(t => t.tabId === this.state.layout.currentTabId)[0];
            newTab.name = currentTab.name;
            newTab.tabId = currentTab.tabId;
            var newlayout = this.state.layout;
            newlayout.tabs = [newTab, ...newTabs];
            this.setState({ layout: newlayout });
        }
    }

    onAbandonLoadTemplate = () => {
        this.setState({ showLoadTemplateDialogue: false });
    }

    renderLoadTemplateName = () => {
        if (!this.state.loadTemplateReplaceCurrentTab && this.state.loadTemplateNewTabObject) {
            return (
                <FormGroup>
                    <ValidatedTextInput
                        label="Tab name"
                        helperText="Name of new tab to create..."
                        large={false}
                        initialValue={this.state.loadTemplateNewTabObject.templateName}
                        placeholder="New tab name"
                        keyName="newTabNameInput"
                        key="newTabNameInput"
                        onChange={this.onChangeLoadTemplateName} />
                </FormGroup>
            );
        }
    }

    canLoadTemplate = (): boolean => {
        if (this.state.loadTemplateReplaceCurrentTab) {
            return true;
        }
        else {
            return this.state.loadTemplateNewTabName !== null && this.state.loadTemplateNewTabName !== "" && this.state.layout.tabs.filter(t => t.name === this.state.loadTemplateNewTabName).length === 0;
        }
    }

    onRightClickTabTitle(e: React.MouseEvent<HTMLDivElement, MouseEvent>, tabId: number) {
        e.stopPropagation();
        e.preventDefault();
        var position = this.state.menuAnchor === undefined ? { mouseX: e.clientX - 2, mouseY: e.clientY - 4 } : undefined;
        this.setState({ menuAnchor: position, tabTitleContextId: tabId });
    }


    onTitleKeyPressed(e) {
        if (e.keyCode === 13) {
            var layout = layoutStoreInstance.GetLayout();
            var tab = layout.tabs.filter(t => t.tabId === this.state.tabTitleContextId)[0];
            if (tab) {
                tab.displayName = this.state.tabTitleNewName;
                UpdateLayout(layout);
                this.setState({ tabTitleContextId: undefined, tabTitleRenameActive: false, layoutDirty: true, tabTitleNewName: undefined });
            }
        }
        else if (e.keyCode === 27) {
            this.stopEditingTabTitle();
        }
    }

    stopEditingTabTitle() {
        this.setState({ tabTitleContextId: undefined, tabTitleRenameActive: false, tabTitleNewName: undefined });
    }

    renderTabTitle(name: string, canBeClosed: boolean, tabId: number) {
        const { menuAnchor, tabTitleRenameActive, tabTitleContextId, tabTitleNewName } = this.state;
        if (canBeClosed) {
            return (<span className="tabTitle" style={{ cursor: 'context-menu' }} key={`${name}_titl`} onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onRightClickTabTitle(e, tabId)}>
                {tabTitleRenameActive && tabTitleContextId === tabId ? <TextField value={tabTitleNewName ?? name} onBlur={() => this.stopEditingTabTitle()} onKeyDown={this.onTitleKeyPressed} onChange={(e) => this.setState({ tabTitleNewName: e.currentTarget.value })} /> : name}
                <ThemeProvider theme={getPositionTableTheme()}>
                    <Menu
                        id={"tabTitleContext" + tabId}
                        key={"tabTitleContext" + tabId}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            menuAnchor !== undefined
                                ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX }
                                : undefined
                        }
                        open={Boolean(menuAnchor)}
                        onClose={this.handleCloseTabTitleContextMenu}
                        classes={{ list: "GridOptionMenu" }}>
                        <MenuItem onClick={() => this.onTabClosedClicked()}>
                            <ListItemIcon>
                                <CloseOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Close" />
                        </MenuItem>
                        <MenuItem onClick={() => this.setState({ tabTitleRenameActive: true, menuAnchor: undefined })}>
                            <ListItemIcon>
                                <EditOutlined fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Rename" />
                        </MenuItem>
                    </Menu>
                </ThemeProvider>
            </span>)
        }
        else {
            return (<span className="tabTitle" key={`${name}_titl`}>{name === "Blotter" ? "Trades" : name}</span>);
        }
    }

    handleCloseTabTitleContextMenu = () => {
        this.setState({ menuAnchor: undefined });
    }

    onTabClosedClicked() {
        DeleteTab(this.state.tabTitleContextId);
        this.handleCloseTabTitleContextMenu();
    }

    onClickAddComponent(componentName: string, props?: any) {
        if (props)
            AddLayoutObject(this.state.selectedTabId, componentName, props);
        else
            AddLayoutObject(this.state.selectedTabId, componentName, {});
        this.setState({ layoutDirty: true })
    }

    onClickChangeComponentProps(tabId: number, componentKey: string, props?: any) {
        UpdateLayoutObjectProps(componentKey, tabId, props);
        this.setState({ layoutDirty: true })
    }

    registerOnGrid = (grid: boolean[][], row: number, col: number, width: number, height: number) => {
        for (var rr = 0; rr < height; rr++) {
            for (var cc = 0; cc < width; cc++) {
                if (row + rr < grid.length)
                    if (col + cc < grid[row].length)
                        grid[row + rr][col + cc] = true;
            }
        }
    }

    onLayoutChange(tabId: number, newLayout: Layout[]) {
        const { layout } = this.state;
        var tabComponents = layout.tabs[tabId]?.components;
        if (!tabComponents)
            return;
        var map = this.state.layoutMap.get(tabId);
        if (!map) {
            map = new Map<string, GridLayout.Layout>();
            this.state.layoutMap.set(tabId, map);
        }
        newLayout.forEach(l => {
            if (map) {
                var existing = map.get(l.i);
                if (existing) {
                    existing.x = l.x;
                    existing.y = l.y;
                }
                else
                    map.set(l.i, l);
                if (tabComponents) {
                    var comp = tabComponents.filter(x => x.key === l.i);
                    if (comp && comp.length > 0) {
                        if (comp[0].props.gridLayout) {
                            comp[0].props.gridLayout.x = l.x;
                            comp[0].props.gridLayout.y = l.y;
                        }
                        else
                            comp[0].props.gridLayout = l;
                    }
                }
            }
        });
        this.setState({ layoutDirty: true, layout })
    }

    onComponentClosed(componentKey: string, tabId: number) {
        if (this.state.tabLocks.get(tabId)) {
            return;
        }
        var tab = this.state.layout.tabs.filter(t => t.tabId === tabId)[0];
        var components = tab.components;
        if (components) {
            components = components.filter(c => c.key !== componentKey);
            var map = this.state.layoutMap.get(tabId);
            if (map) {
                map.delete(componentKey);
            }
            let newLayout = this.state.layout;
            newLayout.tabs.filter(t => t.tabId === tabId)[0].components = components;
            this.setState({ layout: newLayout, layoutDirty: true })
        }
    }

    onTabLockToggle(isLocked: boolean) {
        this.state.tabLocks.set(this.state.selectedTabId, isLocked);
        // this.state.layout.tabs[this.state.selectedTabId].components = [...this.state.layout.tabs[this.state.selectedTabId].components];
        this.setState(this.state);
    }

    onChangeState(componentKey: string, tabId: number, key: string, value: string) {
        var tab = this.state.layout.tabs.filter(t => t.tabId === tabId)[0];
        var components = tab.components;
        if (components) {
            if (components.length > 0 && components.some(c => c.key === componentKey)) {
                let component = components.filter(c => c.key === componentKey)[0];
                if (component) {
                    if (!component.state)
                        component.state = {};
                    component.state[key] = value;
                }
            }
            else { //assume its tab state
                if (!tab.tabState)
                    tab.tabState = {};
                tab.tabState[key] = value;
            }
            this.setState({ layoutDirty: true })
        }
    }

    getState(componentKey: string, tabId: number, key: string) {
        var tab = this.state.layout.tabs.filter(t => t.tabId === tabId)[0];
        var components = tab.components;
        if (components) {
            if (components.length > 0 && components.some(c => c.key === componentKey)) {
                let component = components.filter(c => c.key === componentKey)[0];
                if (component && component.state) {
                    return component.state[key];
                }
            }
            else if (tab.tabState) { //assume its tab state
                return tab.tabState[key];
            }
        }
        return undefined;
    }

    onChangeHeight(componentKey: string, tabId: number, newHeight: number) {
        console.log("KKKKKK")
        const { layout } = this.state;
        const newHeightScaled = Math.max(1, Math.ceil((newHeight + margin) / gridRowHeight));
        var tab = layout.tabs.filter(t => t.tabId === tabId)[0];

        var map = this.state.layoutMap.get(tabId);
        if (!map) {
            map = new Map<string, GridLayout.Layout>();
            this.state.layoutMap.set(tabId, map);
        }

        var components = tab.components;
        if (components) {
            components = [...components];
            let component = components.filter(c => c.key === componentKey)[0];
            if (component) {
                // if (component.props.height !== newHeightScaled)
                //     component.key = uuid();
                var l = component.props.gridLayout as GridLayout.Layout;
                if (l) {
                    if (l.h === newHeightScaled)
                        return;
                    l = {
                        i: component.key,
                        h: newHeightScaled,
                        w: l.w,
                        x: l.x,
                        y: l.y,
                        isDraggable: l.isDraggable,
                        isResizable: l.isResizable,
                        maxH: l.maxH,
                        maxW: l.maxW,
                        minH: l.minH,
                        minW: l.minW,
                        static: l.static
                    } as GridLayout.Layout;
                    component.props.height = newHeightScaled;
                    if (map) {
                        map.set(l.i, l);
                    }
                    component.props.gridLayout = l;
                }
                else {
                    component.props.height = newHeightScaled;
                    component.props.gridLayout = { i: component.key, h: newHeightScaled } as GridLayout.Layout;
                    if (map) {
                        map.set(component.key, component.props.gridLayout);
                    }
                }
            }
            this.setState({ layoutDirty: true, layout, forceReLayout: true })
        }
    }

    onChangeWidth(componentKey: string, tabId: number, newWidth: number) {
        const { layout } = this.state;
        const newWidthScaled = Math.max(1, Math.ceil(newWidth / (colWidthPx + margin)));
        var tab = layout.tabs.filter(t => t.tabId === tabId)[0];

        var map = this.state.layoutMap.get(tabId);
        if (!map) {
            map = new Map<string, GridLayout.Layout>();
            this.state.layoutMap.set(tabId, map);
        }

        var components = tab.components;
        if (components) {
            components = [...components];
            let component = components.filter(c => c.key === componentKey)[0];
            if (component) {
                var l = component.props.gridLayout as GridLayout.Layout;
                if (l) {
                    if (l.w === newWidthScaled)
                        return;
                    l = {
                        i: component.key,
                        h: l.h,
                        w: newWidthScaled,
                        x: l.x,
                        y: l.y,
                        isDraggable: l.isDraggable,
                        isResizable: l.isResizable,
                        maxH: l.maxH,
                        maxW: l.maxW,
                        minH: l.minH,
                        minW: l.minW,
                        static: l.static
                    } as GridLayout.Layout;
                    component.props.width = newWidthScaled;
                    component.props.gridLayout = l;
                    if (map) {
                        map.set(l.i, l);
                    }
                }
                else {
                    component.props.width = newWidthScaled;
                    component.props.gridLayout = { w: newWidthScaled } as GridLayout.Layout;
                    if (map) {
                        map.set(component.key, component.props.gridLayout);
                    }
                }
            }
            this.setState({ layoutDirty: true, layout, forceReLayout: true })
        }
    }

    getCandleSizeStr(candleSize: number) {
        if (candleSize === 0)
            return "Daily Fixings";
        if (candleSize === 86400)
            return "Daily Candles";
        if (candleSize === 3600)
            return "Hourly Candles";
        if (candleSize > 3600)
            return `${candleSize / 3600} Hour Candles`;

        return `${candleSize / 60} Minute Candles`;
    }


    contentRenderer(components: LayoutComponent[] | undefined, tabId: number, name: string) {
        if (!components || components.length === 0 || (components.length === 1 && components[0].type === name)) {
            var tabKey = `${name}+${tabId}`;
            switch (name) {
                case "RfqDashboard":
                    return <RfqDashboard key={tabKey} />
                case "Trades":
                case "Blotter":
                    return <TradeBlotterTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Markets":
                    return <MarketDataTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Instruments":
                    return <InstrumentTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Positions":
                    return <PositionTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Client":
                    return <ClientEditorTab key={tabKey} />
                case "AdminConsole":
                    return <AdminConsoleTab key={tabKey} />
                case "Jobs":
                    return <JobTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "NAV":
                    return <NavTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Risk":
                    return <RiskTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Orders":
                    return <OrderTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Engineering":
                    return <EngineeringTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Reports":
                    return <ReportTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                case "Settings":
                    return <SettingsTab
                        key={tabKey}
                        onChangeState={(key: string, value: string) => this.onChangeState(tabKey, tabId, key, value)}
                        getState={(key: string) => this.getState(tabKey, tabId, key)} />
                default:
                    return (<div></div>);
            }

        }
        const { layoutMap } = this.state;
        var tabIsLocked = this.state.tabLocks.get(this.state.selectedTabId) ?? false;
        var layout = components.filter(c => c.props !== null).map(c => layoutMap?.get(tabId)?.get(c.key) ?? componentMap.get(c.type)?.getLayoutProps(c, tabIsLocked));

        layout.forEach(l => {
            if (l) {
                l.static = tabIsLocked;
                l.isDraggable = !tabIsLocked;
            }
        })

        components.forEach(component => {
            if (component.props !== null) {
                if (!component.props.onClose)
                    component.props.onClose = () => this.onComponentClosed(component.key, tabId);
                if (!component.props.onChangeState)
                    component.props.onChangeState = (key: string, value: string) => this.onChangeState(component.key, tabId, key, value);
                if (!component.props.getState)
                    component.props.getState = (key: string) => this.getState(component.key, tabId, key);
                if (!component.props.onChangeHeight)
                    component.props.onChangeHeight = (newHeight: number) => this.onChangeHeight(component.key, tabId, newHeight);
                if (!component.props.onChangeWidth)
                    component.props.onChangeWidth = (newWidth: number) => this.onChangeWidth(component.key, tabId, newWidth);
                if (component.props.gridLayout) {
                    component.props.gridLayout.isDraggable = !tabIsLocked;
                    component.props.gridLayout.static = tabIsLocked;
                }
            }
            if (component.type === "PricingPanel") {
                component.title = `Rfq ${component.props?.rfqResponse?.rfqId} / ${rfqSummaryStoreInstance.GetRfq(component.props?.rfqResponse?.rfqId)?.state}`;
            }
            if (component.type === "SmileChart") {
                component.title = `Smile ${listedInstrumentStoreInstance.getInstrumentById(component.props?.insId)?.description?.split(" Underlying Index")[0]} / ${moment(component.props?.valDate)?.format(userStoreInstance.GetDateFormat())}`;
            }
            if (component.type === "SurfaceChart") {
                component.title = `Surface ${listedInstrumentStoreInstance.getInstrumentById(component.props?.insId)?.description?.split(" Underlying Index")[0]} / ${moment(component.props?.valDate)?.format(userStoreInstance.GetDateFormat())}`;
            }
            if (component.type === "CandleChart") {
                component.title = `${listedInstrumentStoreInstance.getInstrumentById(component.props?.insId)?.description?.split(" Underlying Index")[0]} ${this.getCandleSizeStr(component.props?.candleSize)}`;
            }
        });

        var currentWidth = this.state.mainWidth;
        const numCols = Math.floor(currentWidth / (colWidthPx + 2 * margin));

        return (<div>
            <GridLayout className="layout"
                ref={this.state.gridRef}
                draggableHandle={".DragMe"}
                layout={layout}
                rowHeight={gridRowHeight}
                cols={numCols}
                width={currentWidth}
                verticalCompact={true}
                //margin={[margin, margin]}
                preventCollision={false}
                onLayoutChange={(layout: Layout[]) => this.onLayoutChange(tabId, layout)}
                isDraggable={!tabIsLocked}>
                {components.filter(c => c.props !== null).map(c => componentMap.get(c.type)?.drawFunction(c))}
                {/* <div style={{border:"1px solid black", background:"white"}} key="c" data-grid={{x: 4, y: 0, w: 1, h: 2}}>c</div> */}
            </GridLayout>
        </div>)
    }

    render() {
        const settings = userStoreInstance.GetSettings();
        const { layout, previousTabId, mainPageRef } = this.state;
        var selectedTabId = this.state.selectedTabId;
        if (!layout.tabs.some(t => t.tabId.toString() === selectedTabId.toString()))
            selectedTabId = previousTabId;
        const sortedTabs = layout.tabs.sort((a, b) => a.tabId > b.tabId ? 1 : -1);
        return (<div id="mainLayoutDiv" className={`MainLayoutContainerBackdrop theme-${settings.themeName}`}>
            <div className={`MainLayoutContainer theme-${settings.themeName}`} >
                {/*sidebar*/}
                <Drawer open={this.state.messageDrawerOpen} onClose={this.onCloseMessageDrawer} anchor="right">
                    <div className="MainSettingsPopout">
                        <UserSettingsControl onClose={this.onCloseMessageDrawer} />
                    </div>
                </Drawer>
                <UserMessagesComponent />
                {/*load/save dialogues*/}
                <Dialog open={this.state.showSaveTemplateDialogue} onClose={this.onAbandonSaveTemplate}>
                    <div style={{ padding: "5px" }}>
                        <Typography style={{ width: "100%", textAlign: "center" }} variant="h4">Save template?</Typography>
                        <ValidatedTextInput
                            label="Template name"
                            helperText="Name to save the template under..."
                            large={false}
                            initialValue=""
                            placeholder="Template name"
                            keyName="templateNameInput"
                            key="templateNameInput"
                            onChange={this.onChangeSaveTemplateName} />
                        <ButtonGroup variant="text" >
                            <Button
                                startIcon={<SaveOutlined />}
                                disabled={this.state.saveTemplateName === null}
                                onClick={() => this.onSaveTemplate(this.state.saveTemplateName)}>Save</Button>
                            <Button
                                startIcon={<CancelOutlined />}
                                onClick={this.onAbandonSaveTemplate}>Cancel</Button>
                        </ButtonGroup>
                    </div>
                </Dialog>

                {/*add tab dialog*/}
                <Dialog open={this.state.showAddTabDialog} onClose={() => this.setState({ showAddTabDialog: false })}>
                    <div className="AddTabDialogue" style={{ padding: "5px", height: "200px" }}>
                        <Typography style={{ width: "100%", textAlign: "center" }} variant="h4">Add a tab?</Typography>
                        <ValidatedTextInput
                            label="Tab name"
                            helperText="Name for the new tab"
                            large={false}
                            initialValue=""
                            placeholder="Tab name"
                            keyName="tabNameInput"
                            key="tabNameInput"
                            onChange={(val) => { this.setState({ addTabName: val }); return val !== null; }} />
                        <ButtonGroup variant="text" fullWidth={true} >
                            <Button
                                className="PltfmButton"
                                startIcon={<AddCircleOutlineOutlined />}
                                disabled={this.state.addTabName === null}
                                onClick={() => { this.onClickAddTab(this.state.addTabName); this.setState({ showAddTabDialog: false }); }}>Create</Button>
                            <Button
                                className="PltfmButton"
                                startIcon={<CancelOutlined />}
                                onClick={() => this.setState({ showAddTabDialog: false })}>Cancel</Button>
                        </ButtonGroup>
                    </div>
                </Dialog>

                {/*main visible section*/}
                <div className="mainLayout" ref={mainPageRef}>
                    <AppBar position="static" className="LayoutTopAppBar" style={{ alignContent: "center" }}>
                        <HomepageControls
                            tabTemplates={this.state.templates}
                            onLoadTemplate={(template: TabTemplate) => this.onLoadTemplate2(template)}
                            onSaveTemplate={async (name: string, category: string, isPublic: boolean) => await this.onSaveTemplate(name, category, isPublic)}
                            onAddItem={(componentName: string, propz?: any) => { this.onClickAddComponent(componentName, propz) }}
                            onToggleLock={(isLocked: boolean) => { this.onTabLockToggle(isLocked) }}
                            onClickChangeComponentProps={(tabId: number, componentKey: string, props?: any) => this.onClickChangeComponentProps(tabId, componentKey, props)} />
                        <Tabs
                            className="LayoutTab"
                            key='mainTab'
                            value={selectedTabId}
                            onChange={this.onTabChange}
                            scrollButtons="auto"
                            variant="scrollable"
                            TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                            {sortedTabs.map((t) => userStoreInstance.actionSwitchOrNull(tabNameRoleMap.get(t.name),
                                <Tab
                                    classes={{ root: "tabTitle", selected: "tabTitleSelected" }}
                                    key={"tab" + t.tabId}
                                    value={t.tabId}
                                    component={Paper}
                                    id={t.tabId.toString()}
                                    label={this.renderTabTitle(t.displayName ?? t.name, t.canDelete, t.tabId)} />))}
                            {/* <Tab id={"9999"} label={this.renderAddTabMenu("")} /> */}
                        </Tabs>
                    </AppBar>
                    {sortedTabs.map((t) => userStoreInstance.actionSwitchOrNull(tabNameRoleMap.get(t.name),
                        <TabPanel
                            key={"tab" + t.tabId}
                            value={selectedTabId}
                            index={t.tabId}
                            children={this.contentRenderer(t.components, t.tabId, t.name)} />
                    ))}
                </div>
            </div>

            <div className="LayoutFooter">
                <AppBar position="static" className="LayoutBottomAppBar">
                    <Toolbar variant="dense" disableGutters={true}>
                        <div className="LayoutFooterStatus"><Grid container spacing={2}><Grid item><SessionStatusWidget userName={this.props.username} /></Grid>  {this.props.isDev ? <React.Fragment><Grid item><Divider orientation="vertical" /></Grid><Grid item><Impersonate /></Grid></React.Fragment> : null}</Grid></div>
                        <div className="LayoutFooterLogo" onClick={() => this.setState({ messageDrawerOpen: true })}></div>
                    </Toolbar>
                </AppBar>
            </div>
        </div>);
        //   
    }
}

export default LayoutControl;