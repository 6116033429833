import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { InboxState } from './inboxModels';
import { InboxLoadedEvent, InboxUpdatedEvent } from './inboxActions';

class InboxStore extends FluxStore<InboxState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            inboxItems: []
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof InboxLoadedEvent) {
            const { payload } = action;
            this.state = { inboxItems: payload };
            this.emitChange();
        }
        if(action instanceof InboxUpdatedEvent){
            const {payload} = action;
            const item = this.state.inboxItems.findIndex(e => e.inboxId === payload.inboxId);
            if(item === -1)
            {
                this.state.inboxItems.push(payload);
            }
            else
            {
                this.state.inboxItems[item] = payload;
            }
            this.emitChange();
        }
    }

    getInboxItems() {
        return this.state.inboxItems;
    }
}

const inboxStoreInstance = new InboxStore(AppDispatcher);
export default inboxStoreInstance;