import React, { RefObject } from 'react';
import FBEmitter from 'fbemitter';
import { UpcomingEvent } from './eventsModels';
import eventsStoreInstance from './eventsStore';
import { LoadUpcomingEvents } from './eventsActions';
import _ from 'lodash';
import moment from 'moment';
import { Typography } from '@mui/material';
import userStoreInstance from '../user/userStore';

export type EventsListProps = {
    onChangeHeight?: (newHeight: number) => void
}
type EventsListComponentState = {
    loading: boolean,
    upcomingEvents: UpcomingEvent[],
    eventTypes: string[],
    hover: boolean;
    height: number;
    mainRef: RefObject<HTMLDivElement>,
}

export class EventsList extends React.Component<EventsListProps, EventsListComponentState>{
    eventSubscriptionEvents: FBEmitter.EventSubscription | undefined;
    eventSubscriptionUser: FBEmitter.EventSubscription | undefined;
    constructor(props: EventsListProps) {
        super(props);
        this.state = {
            upcomingEvents: [],
            eventTypes: [],
            loading: true,
            hover: false,
            height: 500,
            mainRef: React.createRef()
        }
    }

    async componentDidMount() {
        this.eventSubscriptionEvents = eventsStoreInstance.addChangeListener(() => this.onChange());
        this.eventSubscriptionUser = userStoreInstance.addChangeListener(() => this.onChange());
        await LoadUpcomingEvents();
        this.onChange();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionEvents) {
            this.eventSubscriptionEvents.remove();
            this.eventSubscriptionEvents = undefined;
        }
        if (this.eventSubscriptionUser) {
            this.eventSubscriptionUser.remove();
            this.eventSubscriptionUser = undefined;
        }
    }

    public componentDidUpdate() {
        // if (this.props.onChangeHeight) {
        //     let newHeight = this.state.mainRef?.current?.clientHeight;
        //     if (newHeight && newHeight !== this.state.height) {
        //         this.props.onChangeHeight(newHeight);
        //         this.setState({ height: newHeight });
        //     }
        // }
    }

    onChange() {
        let items = eventsStoreInstance.getEvents();
        let types = [...new Set(items.map(i => i.eventType))];
        this.setState({ upcomingEvents: items, eventTypes: types });
        this.forceUpdate();
    }

    onClickItem = (uniqueKey: string) => {

    }

    onRightClick(mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        // ContextMenu.show((
        //     <Menu key="srcMenu" title="News Sources">
        //     {this.state.allSources.map(s=>this.renderSourceItem(s))}
        //     </Menu>
        //     ), { left: mouseEvent.clientX, top: mouseEvent.clientY });
        // mouseEvent.preventDefault();
    }

    renderDateGroup(events: UpcomingEvent[], date: string){
            const byType = _.groupBy(events, e => e.eventType);
            return (
                <div className="EventsListDateBlock" key={date}>
                    <span><Typography variant="h6" align="right">{moment(date).format(userStoreInstance.GetDateFormat())}</Typography></span>
                    {Object.keys(byType).map(k => this.renderTypeGroup(byType[k],k, date))}
                </div>);
    }

    renderTypeGroup(events: UpcomingEvent[], type: string, date: string){
        return (
            <div key={type}>
                <span><Typography variant="subtitle1">{type}</Typography></span>
                {events.map((n,ix) => {
                    return <div className="EventsSubItem" key={`${date}-${type}-${ix}`}>
                        <span>{n.description.replaceAll("Earnings report for ","")}</span>
                    </div>;
                })}
            </div>);
    }

    render() {
        const { upcomingEvents } = this.state;
        const byDate = _.groupBy(upcomingEvents, e => e.date);
        return (
            <div className="EventsList" 
                onMouseEnter={() => { this.setState({ hover: true }) }}
                onMouseLeave={() => { this.setState({ hover: false }) }}
                onContextMenu={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onRightClick(e)}>
                <div className="EventsListInner">
                    {Object.keys(byDate).map(k => this.renderDateGroup(byDate[k],k))}
                </div>
            </div>
        );
    }
}