import { binarySearch } from "../../utils/math";
import { IInterpolator1D } from "../iInterpolator1d";
import { Interpolator1DType } from "../interpolator1DType";

export default class LinearInterpolator implements IInterpolator1D {

    _x: number[];
    _y: number[];
    _slope: number[];
    _minX: number;
    _maxX: number;

    Type: Interpolator1DType = Interpolator1DType.linear;

    MinX() { return this._minX };
    MaxX() { return this._maxX };

    constructor(x: number[], y: number[], slope?: number[]) {
        this._x = x;
        this._y = y;
        this._minX = x[0];
        this._maxX = x[x.length - 1];

        if (slope)
            this._slope = slope;
        else
            this.calculateSlope();
    }

    findFloorPoint(t: number) {
        var index = binarySearch(this._x, t);
        if (index < 0) {
            index = ~index - 1;
        }

        return Math.min(Math.max(index, 0), this._x.length - 2);
    }

    calculateSlope() {
        this._slope = new Array<number>(this._x.length - 1);
        for (var i = 0; i < this._slope.length; i++) {
            this._slope[i] = (this._y[i + 1] - this._y[i]) / (this._x[i + 1] - this._x[i]);
        }
    }

    public Interpolate(t: number) {
        if (this._x.length === 1) {
            return this._y[0];
        }
        else if (t < this._minX) {
            var tDiff = t - this._minX;
            return this._y[0] + this._slope[0] * tDiff;
        }
        else if (t > this._maxX) {
            tDiff = t - this._maxX;
            return this._y[this._y.length - 1] + this._slope[this._slope.length - 1] * tDiff;
        }
        else {
            var k = this.findFloorPoint(t);
            return this._y[k] + (t - this._x[k]) * this._slope[k];
        }
    }

    public FirstDerivative(t: number) {
        if (t <= this._minX) {
            return this._slope[0];
        }
        if (t >= this._maxX) {
            return this._slope[this._slope.length - 1];
        }
        else {
            var k = this.findFloorPoint(t);
            return this._slope[k];
        }
    }

    public SecondDerivative(x: number): number {
        if (!this._x.includes(x))
            return 0;
        var k = this.findFloorPoint(x);
        if (this._slope.length === 1 || k === 0)
            return this._slope[0];
        return (this._slope[k] - this._slope[k - 1]) / 2.0;
    }
}