import React, { ReactElement } from 'react';
import FBEmitter from 'fbemitter';
import { DateFixedRelative } from '../payoff/models/dateFixedRelative';
import moment, { Moment } from 'moment';
import { Frequency } from '../qwack/frequency';
import { TenorDateAbsolute } from '../qwack/tenorDateAbsolute';
import { TenorDateRelative } from '../qwack/tenorDateRelative';
import ValidatedTextInput from './validatedTextInput';
import { Switch, FormControlLabel, ThemeProvider } from '@mui/material';
import { WrappedDatePicker } from './wrappedDatePicker';
import { getFormTheme } from './formCommon';

export type FixedRelativeDateInputProps = {
    onChange: (value: DateFixedRelative) => void;
    initialValue: DateFixedRelative;
    placeholder?: string;
    keyName: any,
    forceFixed?: boolean;
    forceRelative?: boolean;
    disabled?: boolean;
    toolTip?: ReactElement
}

type FixedRelativeDateInputState = {
    currentValue: DateFixedRelative,
    placeholder: string,
    isRelative: boolean;
    disabled: boolean;
    ok: boolean;
}

class FixedRelativeDateInput extends React.Component<FixedRelativeDateInputProps, FixedRelativeDateInputState> {
    eventSubscriptionCredit: FBEmitter.EventSubscription | undefined;

    constructor(props: FixedRelativeDateInputProps) {
        super(props)
        var isRel = this.props.forceRelative || (this.props.forceFixed !== undefined && !this.props.forceFixed);
        this.state =
        {
            placeholder: this.props.placeholder ? this.props.placeholder : "Enter value...",
            currentValue: this.props.initialValue ?? { isRelative: isRel } as DateFixedRelative,
            isRelative: isRel,
            disabled: this.props.disabled,
            ok: false
        };

        this.onChangeRelAbs = this.onChangeRelAbs.bind(this);
        this.onChangeAbsoluteDate = this.onChangeAbsoluteDate.bind(this);
        this.onChangeRelativeDate = this.onChangeRelativeDate.bind(this);
    }

    public async componentDidMount() {
    }

    public componentWillUnmount() {

    }

    static getDerivedStateFromProps(props: FixedRelativeDateInputProps, state: FixedRelativeDateInputState) {
        var isRel = props.forceRelative || (props.forceFixed !== undefined && !props.forceFixed);
        if (isRel !== state.isRelative) {
            state.isRelative = isRel;
            return state;
        }
        return null;
    }

    onChangeRelAbs(e: React.FormEvent<HTMLInputElement>) {
        const { currentValue, isRelative } = this.state;
        currentValue.isRelative = (e.target as HTMLInputElement).checked;
        this.setState({ isRelative: !isRelative });
        this.props.onChange(currentValue);
    }

    onChangeRelativeDate(e: string): boolean {
        var newFreq = Frequency.TryParse(e);
        if (newFreq) {
            const { currentValue } = this.state;
            if (!currentValue.relative) {
                currentValue.relative = {} as TenorDateRelative
            }
            currentValue.relative.relativeTenor = newFreq;
            currentValue.isRelative = true;
            this.props.onChange(currentValue);
            this.setState({ currentValue: currentValue, ok: true });
            return true;
        }
        return false;
    }

    onChangeAbsoluteDate(selectedDate: Moment) {
        const { currentValue } = this.state;
        if (!currentValue.absolute) {
            currentValue.absolute = {} as TenorDateAbsolute
        }
        currentValue.absolute.absoluteDate = selectedDate.toDate();
        currentValue.isRelative = false;
        let ok = selectedDate !== undefined;
        this.setState({ currentValue: currentValue, ok });
        this.props.onChange(currentValue);
    }

    formatDate = (val: Date, locale: string): string => {
        return moment(val).format("yyyy-MM-DD ddd");
    }

    parseDate = (val: string, locale: string): false | Date => {
        return new Date(val);
    }

    render() {
        const { isRelative, currentValue, disabled } = this.state;
        const { forceFixed, forceRelative } = this.props;
        return (<ThemeProvider theme={getFormTheme()}>
            <div key={`${this.props.keyName}-divA`} className="FixedRelativeDateInput">

                {forceFixed || forceRelative ? null :
                    <div className="SwitchPadd">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isRelative}
                                    onChange={this.onChangeRelAbs}
                                    name="dateRelativeX"
                                    color="primary"
                                    disabled={this.props.disabled}
                                />
                            }
                            label={isRelative ? "Relative" : "Absolute"}
                        />
                    </div>
                }
                {isRelative ?
                    <ValidatedTextInput toolTip={this.props.toolTip} large={false} keyName={`${this.props.keyName}-vdi`} placeholder="1w, 2m, 3y, etc" initialValue={Frequency.toStringStatic(currentValue.relative?.relativeTenor) ?? undefined} onChange={this.onChangeRelativeDate} disabled={disabled} /> :
                    <WrappedDatePicker
                        id={"date-picker-inline" + this.props.keyName}
                        maxDate={new Date("2100-01-01")}
                        minDate={new Date()}
                        value={currentValue.absolute?.absoluteDate ?? new Date()}
                        onChange={this.onChangeAbsoluteDate} />
                }
                {/* <DateInput canClearSelection={false} shortcuts={pickerShortCuts} showActionsBar={true} maxDate={new Date("2100-01-01")} minDate={new Date()} key={`${this.props.keyName}-di`} placeholder={this.props.placeholder} onChange={this.onChangeAbsoluteDate} value={currentValue.absolute?.absoluteDate ?? new Date()} formatDate={this.formatDate} parseDate={this.parseDate} disabled={disabled} />} */}
            </div>
        </ThemeProvider>
        );
    }
}

export default FixedRelativeDateInput;