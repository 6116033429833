import React from 'react';
import FBEmitter from 'fbemitter';
import { Grid, ThemeProvider } from '@mui/material';
import { renderDateCell } from '../utils/helpers';
import { getFormTheme } from '../inputs/formCommon';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { SubsReds } from './navModels';
import { GridColDef, GridRowData, GridRowParams, DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import navStoreInstance from './navStore';
import { AddOrUpdateSubsReds, GetSubsReds } from './navActions';
import { SubsRedsForm } from './subsRedsForm';
import userStoreInstance from '../user/userStore';

const baseCol = { flex: 1, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
const SubsRedsColumns: GridColDef[] = [
    { field: "subsRedsId", headerName: "Id", type: "number", flex: 0.5, ...baseCol },
    { field: "fund", headerName: "Fund", type: "string", flex: 2, ...baseCol },
    { field: "shareClass", headerName: "ShareClass", type: "string", flex: 2, ...baseCol },
    { field: "totalValue", headerName: "Value", type: "number", flex: 2, ...baseCol },
    { field: "nav", headerName: "NAV", type: "number", flex: 1, ...baseCol },
    { field: "gav", headerName: "GAV", type: "number", flex: 1, ...baseCol },
    { field: "official", headerName: "Is Official", type: "boolean", flex: 1.5, ...baseCol },
    { field: "investor", headerName: "Investor", type: "string", flex: 2, ...baseCol },
    { field: "underlyingInvestor", headerName: "U/L Investor", type: "string", flex: 2, ...baseCol },
    { field: "externalId", headerName: "External Id", type: "string", flex: 2, ...baseCol },
    { field: "administrator", headerName: "Administrator", type: "string", flex: 2, ...baseCol },
    { field: "asOfDate", headerName: "As-Of Date", type: "date", flex: 2, renderCell: renderDateCell, ...baseCol },
]

type SubsRedsComponentState = {
    hasBeenSubmitted: boolean,
    lastBookedId: number | undefined,
    rows: GridRowData[],
    cols: GridColDef[],
    subsRedsData: SubsReds,
    loadedId: number | undefined,
}

const SubsRedsValidationSchema = Yup.object().shape({
    fund: Yup.string()
        .required('Required'),
    // shareClass: Yup.string()
    //     .required('Required'),
    totalValue: Yup.number()
        .required('Required')
        .typeError('Valid amount required, no column seperators allowed'),
    nav: Yup.number()
        .required('Required')
        .positive('NAV must be positive')
        .typeError('Valid NAV required, no column seperators allowed'),
    gav: Yup.number()
        .required('Required')
        .positive('GAV must be positive')
        .typeError('Valid GAV required, no column seperators allowed'),
    asOfDate: Yup.date()
        .required('Required'),
    numberOfShares: Yup.number()
        .required('Required')
});


export class SubsRedsComponent extends React.Component<{}, SubsRedsComponentState>{
    eventSubscriptionTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: any) {
        super(props)
        this.state = {
            hasBeenSubmitted: false,
            lastBookedId: undefined,
            cols: [],
            rows: [],
            subsRedsData: undefined,
            loadedId: undefined
        };

        this.onDuplicatePressed = this.onDuplicatePressed.bind(this);
        this.onResetSubsReds = this.onResetSubsReds.bind(this);
        this.onSubmitSubsReds = this.onSubmitSubsReds.bind(this);
        this.onSubsRedsUpdate = this.onSubsRedsUpdate.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionTrades = navStoreInstance.addChangeListener(this.onSubsRedsUpdate)
        this.onSubsRedsUpdate();
    }

    componentWillUnmount() {
        if (this.eventSubscriptionTrades) {
            this.eventSubscriptionTrades.remove();
        }
    }

    onSubsRedsUpdate() {
        var sr = navStoreInstance.getSubsReds();
        var rows = sr.sort((a, b) => b.subsRedsId - a.subsRedsId).map(t => {
            var r = { id: t.subsRedsId } as GridRowData;
            Object.keys(t).forEach(k => {
                r[k] = t[k];
            });
            return r;
        });
        this.setState({ cols: SubsRedsColumns, rows });
    }

    async onSubmitSubsReds(values: SubsReds, { setSubmitting }: FormikHelpers<SubsReds>) {
        this.setState({ hasBeenSubmitted: true });
        var transaction = {
            administrator: values.administrator,
            asOfDate: new Date(values.asOfDate),
            externalId: values.externalId,
            fund: values.fund,
            gav: Number(values.gav),
            nav: Number(values.nav),
            totalValue: Number(values.totalValue),
            investor: values.investor,
            numberOfShares: Number(values.numberOfShares),
            official: values.official,
            shareClass: values.shareClass,
            subsRedsId: values.subsRedsId,
            underlyingInvestor: values.underlyingInvestor
        } as SubsReds

        var lastBookedId = await AddOrUpdateSubsReds(transaction);
        setSubmitting(false);
        await GetSubsReds();
        this.setState({ lastBookedId });
    }

    onResetSubsReds(values: SubsReds, { resetForm, }: FormikHelpers<SubsReds>) {
        if (this.state.loadedId)
            this.setState({ lastBookedId: undefined, loadedId: undefined });
        else
            this.setState({ lastBookedId: undefined, subsRedsData: undefined });
        //resetForm();
    }


    onDuplicatePressed = () => this.setState({ hasBeenSubmitted: false, lastBookedId: undefined });

    renderAddTradeForm() {
        const { hasBeenSubmitted, lastBookedId, subsRedsData } = this.state;

        return (<div style={{overflow: "hidden"}}>
            <Formik
                onSubmit={this.onSubmitSubsReds}
                onReset={this.onResetSubsReds}
                enableReinitialize={true}
                initialValues={subsRedsData ?? {} as SubsReds}
                validationSchema={SubsRedsValidationSchema}>
                {props => <SubsRedsForm className="NewTradeForm" {...props} canDuplicate={hasBeenSubmitted} onDuplicatePressed={this.onDuplicatePressed} lastBookedId={lastBookedId} />}
            </Formik>
        </div>);
    }


    onClickRow(param: GridRowParams, event: React.MouseEvent<Element, MouseEvent>) {
        var idVal = param.getValue(param.id, "id");
        var data = navStoreInstance.getSubsReds().filter(l => l.subsRedsId === idVal)[0];
        if (data) {
            this.setState({ subsRedsData: data, loadedId: data.subsRedsId, hasBeenSubmitted: false, lastBookedId: undefined });
        }
    }

    customToolbar(props: any) {
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer>
                    <Grid container spacing={1} alignContent="center" style={{ padding: "5px" }}>
                        <Grid item><GridToolbarExport {...props} size="small" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarFilterButton {...props} style={{ borderColor: userStoreInstance.GetTheme().border_color, height: "2.4em" }} className="MuiButton-outlined PltfmButtonLite" />                            </Grid>
                        <Grid item><GridToolbarColumnsButton {...props} size="small" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarDensitySelector {...props} size="small" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    render() {
        const { rows, cols } = this.state;
        return (
            <div style={{ width: "calc(100% - 20px)", height: "calc(100vh - 120px)", display: "flex" }}>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center" direction="column" wrap='nowrap'>
                        <Grid item>
                            {this.renderAddTradeForm()}
                        </Grid>
                        {cols && cols.length > 0 ? <Grid item className="SubsRedsGrid">
                            <DataGridPro
                                columns={cols}
                                rows={rows}
                                density="compact"
                                components={{
                                    Toolbar: this.customToolbar,
                                }}
                                className="SubsRedsDataGrid"
                                onRowClick={this.onClickRow}
                                hideFooter />
                        </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }
}