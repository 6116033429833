import { FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

export interface WrappedSelectProps {
    onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void,
    value: any,
    style?: React.CSSProperties,
    label?: React.ReactNode
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    autoWidth?: boolean
    disabled?: boolean,
    multiple?: boolean
    placeholder?: string
    variant?: "outlined" | "standard" | "filled"
}

export class WrappedSelect extends React.Component<WrappedSelectProps, {}>
{
    render() {
        return (<FormControl size={this.props.size ?? "small"}>
            <InputLabel variant="outlined">{this.props.label}</InputLabel>
            <Select
                size={this.props.size ?? "small"}
                style={this.props.style}
                multiple={this.props.multiple}
                variant={this.props.variant ?? "outlined"}
                classes={{ outlined: "ListedInstrumentEditorFormFieldInner", disabled: "PltfmDisabled" }}
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                value={this.props.value}
                onChange={this.props.onChange}
                error={this.props.error}
                autoWidth={this.props.autoWidth}
                placeholder={this.props.placeholder}
                readOnly={this.props.disabled}                >
                {this.props.children}
            </Select>
        </FormControl>)
    }
}

