import React from 'react';
import FBEmitter from 'fbemitter';
import BasketDesigner from './basketDesigner';
import { Asset } from '../../assets/assetsModels';
import { BasketDefinition } from '../../qwack/basketDefinition';
import { Autocall, AutoCallFinalPayoff } from '../../payoff/models/autoCall';
import { Wrapper } from '../../payoff/models/wrapper';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import BarrierDetailDesigner from './barrierDetails';
import { BarrierDetail } from '../../payoff/barrierDetail';
import AutocallDatesDesigner from './autocallDatesDesigner';
import { DateFixedRelative } from '../../payoff/models/dateFixedRelative'
import WrapperDetailBox from './wrapperDetailBox';
import { WrappedPayoff } from '../../payoff/models/wrappedPayoff';
import NotionalAndCurencyBox from './notionalAndCurrencyBox';
import { Grid, Typography } from '@mui/material';
import { rowProps, titleProps, titleVariant, valueProps } from './helpers';

export type AutocallPanelProps = {
    definitionUpdate: (state: Autocall) => void,
    initialAutocallDefinition: Autocall,
    allAssets: Asset[],
    disabled: boolean,
    updateStatus?: (ok: boolean) => void,
    extraKey: string
}

export type AutocallPanelState = {
    definition: Autocall;
    barrierBasketSameAsTrigger: boolean;
    possibleWrappers: string[];
    possibleFinalPayoffs: string[];
    selectedBarrierOption: string;
}

const barrierOptNone = "None";
const barrierOptAsCall = "As call trigger";
const barrierOptCustomIx = "Custom index";
const barrierOptions = [barrierOptNone, barrierOptAsCall, barrierOptCustomIx]

export class AutocallPanel extends React.Component<AutocallPanelProps, AutocallPanelState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: AutocallPanelProps) {
        super(props);
        this.state = {
            definition: this.props.initialAutocallDefinition ?? {} as Autocall,
            barrierBasketSameAsTrigger: true,
            possibleWrappers: [],
            possibleFinalPayoffs: [],
            selectedBarrierOption: barrierOptions[0],
        }
        this.setBarrierOption = this.setBarrierOption.bind(this);
        this.updateCallDates = this.updateCallDates.bind(this);
        this.updateCoupons = this.updateCoupons.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.updateWrapperDetail = this.updateWrapperDetail.bind(this);
        this.triggerBasketDefinitionUpdate = this.triggerBasketDefinitionUpdate.bind(this);
        this.barrierBasketDefinitionUpdate = this.barrierBasketDefinitionUpdate.bind(this);
        this.barrierDetailUpdate = this.barrierDetailUpdate.bind(this);
    }

    public componentWillUnmount() {

    }

    async componentDidMount() {
        this.fillPossibleWrappers();
        const { definition: autocallDefinition } = this.state;
        if (!autocallDefinition.wrapperType) {
            this.setWrapperType("StructuredSwap");
        }
    }

    public componentDidUpdate() {

    }


    fillPossibleWrappers = () => {
        const keys = Object.keys(Wrapper).filter(k => typeof Wrapper[k as any] === "number");
        const keysFp = Object.keys(AutoCallFinalPayoff).filter(k => typeof AutoCallFinalPayoff[k as any] === "number");
        this.setState({ possibleWrappers: keys, possibleFinalPayoffs: keysFp });
    }



    triggerBasketDefinitionUpdate(basket: BasketDefinition) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.callTriggerIndex = basket;
        if (this.state.barrierBasketSameAsTrigger) {
            autocallDefinition.barrierTriggerIndex = basket;
        }
        this.setState({ definition: autocallDefinition });
        this.props.definitionUpdate(autocallDefinition);
    }

    barrierBasketDefinitionUpdate(basket: BasketDefinition) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.barrierTriggerIndex = basket;
        this.setState({ definition: autocallDefinition });
        this.props.definitionUpdate(autocallDefinition);
    }

    barrierDetailUpdate(deets: BarrierDetail) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.barrierDetail = deets;
        this.setState({ definition: autocallDefinition });
        this.props.definitionUpdate(autocallDefinition);
    }

    setWrapperType = (wrapperType: string) => {
        const { definition: autocallDefinition } = this.state;
        var wt = (Wrapper as any)[wrapperType];
        autocallDefinition.wrapperType = wt;
        this.setState({ definition: autocallDefinition });
        this.props.definitionUpdate(autocallDefinition);
    }

    setFinalPayoff = (finalPayoff: string) => {
        const { definition: autocallDefinition } = this.state;
        var fp = (AutoCallFinalPayoff as any)[finalPayoff];
        autocallDefinition.finalPayoffType = fp;
        this.setState({ definition: autocallDefinition });
        this.props.definitionUpdate(autocallDefinition);
    }

    setBarrierOption(opt: string) {
        let showBarrierIndexDesigner = opt === barrierOptCustomIx;
        this.setState({ selectedBarrierOption: opt, barrierBasketSameAsTrigger: !showBarrierIndexDesigner });
    }

    updateStartDate(startDate: DateFixedRelative) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.startDate = startDate;
        this.setState({ definition: autocallDefinition })
        this.props.definitionUpdate(autocallDefinition);
    }
    updateEndDate(endDate: DateFixedRelative) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.endDate = endDate;
        this.setState({ definition: autocallDefinition })
        this.props.definitionUpdate(autocallDefinition);
    }
    updateCallDates(callDates: DateFixedRelative[]) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.callDates = callDates;
        this.setState({ definition: autocallDefinition })
        this.props.definitionUpdate(autocallDefinition);
    }
    updateCoupons(coupons: number[]) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.coupons = coupons;
        this.setState({ definition: autocallDefinition })
        this.props.definitionUpdate(autocallDefinition);
    }
    updateWrapperDetail(detail: WrappedPayoff) {
        const { definition: autocallDefinition } = this.state;
        autocallDefinition.structuredSwapDetail = detail.structuredSwapDetail;
        autocallDefinition.otcOptionDetail = detail.otcOptionDetail;
        autocallDefinition.noteDetail = detail.noteDetail;
        autocallDefinition.nominal = detail.nominal;
        autocallDefinition.payoffCurrency = detail.payoffCurrency;
        autocallDefinition.currencyConversionType = detail.currencyConversionType;
        autocallDefinition.solveFor = detail.solveFor;
        this.setState({ definition: autocallDefinition })
        this.props.definitionUpdate(autocallDefinition);
    }


    render() {
        const { barrierBasketSameAsTrigger, definition, possibleWrappers, possibleFinalPayoffs, selectedBarrierOption } = this.state;
        const triggerBasket = definition.callTriggerIndex;
        const barrierBasket = definition.barrierTriggerIndex;
        const hideBarrierDetailDesigner = selectedBarrierOption === barrierOptNone;
        const { allAssets } = this.props;

        return (
            <Grid item container>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Wrapper</Typography></Grid>
                    <Grid {...valueProps}><StringTypeDropDown possibles={possibleWrappers} selected={possibleWrappers[definition.wrapperType]} onChange={this.setWrapperType} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Trigger Basket</Typography></Grid>
                    <Grid {...valueProps}><BasketDesigner extraKey={this.props.extraKey} keyName="accBasket" initialBasketDefinition={triggerBasket} allAssets={allAssets} stateUpdate={this.triggerBasketDefinitionUpdate} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Barrier</Typography></Grid>
                    <Grid {...valueProps}>
                        <Grid item><StringTypeDropDown style={{ width: 150 }} possibles={barrierOptions} selected={selectedBarrierOption} onChange={this.setBarrierOption} disabled={this.props.disabled} /></Grid>
                        {barrierBasketSameAsTrigger ? null : <Grid item><BasketDesigner extraKey={this.props.extraKey} keyName={"brDesigner"} initialBasketDefinition={barrierBasket} allAssets={allAssets} stateUpdate={this.barrierBasketDefinitionUpdate} disabled={this.props.disabled} /></Grid>}
                        {hideBarrierDetailDesigner ? null : <Grid item><BarrierDetailDesigner disabled={false} stateUpdate={this.barrierDetailUpdate} initialBarrierDefinition={definition.barrierDetail} /></Grid>}
                    </Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Dates &amp; Coupons</Typography></Grid>
                    <Grid {...valueProps}><AutocallDatesDesigner initialStartDate={definition.startDate} initialEndDate={definition.endDate} initialCallDates={definition.callDates} initialCoupons={definition.coupons} updateStartDate={this.updateStartDate} updateEndDate={this.updateEndDate} updateCallDates={this.updateCallDates} updateCoupons={this.updateCoupons} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Final Payoff</Typography></Grid>
                    <Grid {...valueProps}><StringTypeDropDown possibles={possibleFinalPayoffs} selected={possibleFinalPayoffs[definition.finalPayoffType]} onChange={this.setFinalPayoff} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Wrapper Details</Typography></Grid>
                    <Grid {...valueProps}><WrapperDetailBox initialDetail={definition} updateWrapperDetail={this.updateWrapperDetail} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Notional &amp; Currency</Typography></Grid>
                    <Grid {...valueProps}><NotionalAndCurencyBox initialDetail={definition} updateWrapperDetail={this.updateWrapperDetail} disabled={this.props.disabled} /></Grid>
                </Grid>
            </Grid>)
    }

}
export default AutocallPanel;