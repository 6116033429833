import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import userSessionConnection from '../userSession/userSessionConnection';
import { get } from '../utils/httpUtils';
import { JobResultUpdate, JobSpec, NewJobRequest } from './jobModels';

export class NewJobUpdateEvent extends TypedEvent<JobResultUpdate> { }
export class JobSpecsLoadedEvent extends TypedEvent<JobSpec[]> { }

export async function StartJob(request: NewJobRequest) {
    await userSessionConnection.startJob(request);
}

export function NewJobUpdate(update: JobResultUpdate) {
    AppDispatcher.dispatch(new NewJobUpdateEvent(update));
}

export async function GetJobList() {
    const assets = await get<JobSpec[]>("Jobs");
    if (assets.payload != null) {
        AppDispatcher.dispatch(new JobSpecsLoadedEvent(assets.payload));
    }

    UpdateLoadingStatus("JobSpecs", LoadingStatus.Completed);
}
