import { Paper, TextField, ThemeProvider, StyledEngineProvider } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, FormikHelpers } from 'formik';
import React, { RefObject } from 'react';
import { getFormTheme } from '../inputs/formCommon';
import { UpdateListedInstrument } from './listedInstrumentActions';
import { ListedInstrumentEditorForm } from './listedInstrumentForm';
import { ListedInstrument } from './listedInstrumentModels';
import listedInstrumentStoreInstance from './listedInstrumentStore';

interface ListedInstrumentEditorState {
    selectedInstrument: ListedInstrument,
    allInstruments: ListedInstrument[],
    mainRef: RefObject<HTMLDivElement>,
    height: number;
}

export interface ListedInstrumentEditorProps {
    onChangeHeight?: (newHeight: number) => void
}

export class ListedInstrumentEditor extends React.Component<ListedInstrumentEditorProps, ListedInstrumentEditorState>{

    constructor(props: ListedInstrumentEditorProps) {
        super(props)
        this.state = {
            mainRef: React.createRef(),
            allInstruments: [],
            height: 400,
            selectedInstrument: {
                assetId: 0,
                description: '',
                ticker: '',
                isin: '',
                maturity: new Date(),
                metaData: [],
                primaryExchange: '',
                type: '',
                underlyingQuantityPerContract: 0,
                netable: true,
                multiplier: 1,
                firstDeliveryDay: new Date(),
                firstNoticeDay: new Date(),
                optionIsCall: true,
                optionStrike: 0,
                optionType: "OptionOnFuture",
                createdUtc: new Date()
            } as ListedInstrument
        };;

        this.filterInsOptions = this.filterInsOptions.bind(this);
        this.instrumentMatchesQuery = this.instrumentMatchesQuery.bind(this);
        this.onInsSelect = this.onInsSelect.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let allInstruments = listedInstrumentStoreInstance.getInstruments();
        this.setState({ allInstruments, selectedInstrument: allInstruments[0] });
    }

    public componentDidUpdate() {
        if (this.props.onChangeHeight) {
            let newHeight = this.state.mainRef.current.clientHeight;
            if (newHeight !== this.state.height) {
                this.props.onChangeHeight(newHeight);
                this.setState({ height: newHeight });
            }
        }
    }

    async onSubmit(values: ListedInstrument, { setSubmitting }: FormikHelpers<ListedInstrument>) {
        console.log(JSON.stringify(values, null, 2));
        UpdateListedInstrument(values);
        setSubmitting(false);
    }

    private filterInsOptions(options: ListedInstrument[], state: any): ListedInstrument[] {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => this.instrumentMatchesQuery(o, query))
        return relevant;
    }

    private instrumentMatchesQuery(ins: ListedInstrument, query: string): boolean {
        const normalizedTitle = ins.description?.toLowerCase();
        const code = ins.ticker.toLowerCase();
        const isin = ins.isin?.toLowerCase();
        const allMetaData = ins.metaData ? ins.metaData.map(am => am.data.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${ins.listedInstrumentId}. ${normalizedTitle}. ${isin} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    private onInsSelect(ins: ListedInstrument | string) {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            this.setState({ selectedInstrument: solidIns });
        }
    }

    render() {
        const { selectedInstrument, allInstruments, mainRef } = this.state;
        return (
            <div className="ListedInstrumentEditor" ref={mainRef}>
                <div className="ListedInstrumentEditorControl">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <Autocomplete
                                multiple={false}
                                classes={{
                                    inputRoot: "BasketDesignerAutocomplete",
                                    input: "BasketDesignerAutocompleteInput",
                                    popupIndicator: "BasketDesignerAutocompleteIcon",
                                    clearIndicator: "BasketDesignerAutocompleteIcon",
                                }}
                                id="listedInstrumentSelector"
                                options={allInstruments}
                                value={selectedInstrument}
                                groupBy={(option: ListedInstrument) => option.primaryExchange}
                                getOptionLabel={(option: ListedInstrument) => option.description}
                                style={{ width: 300, color: "white" }}
                                filterOptions={this.filterInsOptions}
                                onChange={(e, ins: ListedInstrument) => this.onInsSelect(ins)}
                                renderInput={(params) => <TextField {...params} label={<div className="BasketDesignerAutocomplete">Instrument</div>} variant="outlined" />} />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
                <div className="ListedInstrumentEditorFormSection">
                    <Paper elevation={1} >
                        <Formik
                            onSubmit={this.onSubmit}
                            enableReinitialize={true}
                            initialValues={selectedInstrument}
                            validate={() => ({})}>
                            {props => <ListedInstrumentEditorForm metaCollapsable={true} className="ListedInstrumentEditorForm" {...props} />}
                        </Formik>
                    </Paper>
                </div>
            </div>
        );
    }
}
