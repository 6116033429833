import {
  Button,
  MenuItem,
  Grid,
  TextField,
  ThemeProvider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import assetsStoreInstance from "../assets/assetsStore";
import { ListedInstrumentMetaData } from "./listedInstrumentModels";
import { FieldArray, FieldArrayRenderProps, Form } from 'formik';
import { AddOutlined, CancelOutlined, CheckCircleOutline, ExpandMoreOutlined, RemoveOutlined } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import listedInstrumentStoreInstance from "./listedInstrumentStore";
import Autocomplete from '@mui/material/Autocomplete';
import { Moment } from "moment";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { BookStr } from "../globalConstants";

const instrumentTypes = ["Bond", "Equity", "Cash", "Future", "Option", "CFD", "CFDFee", "Index", "Token", "TokenCross", "Perpetual"];

export const ListedInstrumentEditorForm = props => {
  const {
    values: { id,
      listedInstrumentId,
      underlyingId,
      assetId,
      type,
      description,
      ticker,
      isin,
      primaryExchange,
      ccy,
      optionStrike,
      optionIsCall,
      multiplier,
      maturity,
      metaData },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    className,
    handleSubmit,
    metaCollapsable
  } = props;

  const change = (name, e) => {
    e?.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onMetaCategoryChange = (val: string, ix: number) => {
    var name = `metaData[${ix}].category`;
    setFieldValue(name, val)
    setFieldTouched(name, true, false);
  }
  const onMetaTypeChange = (val: string, ix: number) => {
    var name = `metaData[${ix}].type`;
    setFieldValue(name, val)
    setFieldTouched(name, true, false);
  }
  const onMetaDataChange = (val: string, ix: number) => {
    var name = `metaData[${ix}].data`;
    setFieldValue(name, val)
    setFieldTouched(name, true, false);
  }
  const onMetaDataDelete = (ix: number) => {
    var name = `metaData[${ix}].deleted`;
    setFieldValue(name, true)
    setFieldTouched(name, true, false);
  }
  const onNumberChange = (valStr: string, name: string) => {
    var val = Number(valStr);
    if (!isNaN(val)) {
      setFieldValue(name, val)
      setFieldTouched(name, true, false);
    }
  }
  const onDateChange = (date: Moment, name: string) => {
    setFieldValue(name, date?.toDate())
    setFieldTouched(name, true, false);
  }

  const onTypeChange = (type: string) => {
    setFieldValue("type", type)
    setFieldTouched("type", true, false);
  }

  const onCallPutChange = (cp: string) => {
    setFieldValue("optionIsCall", cp === "Call")
    setFieldTouched("optionIsCall", true, false);
  }

  let showMaturity = type !== "Index" && type !== "Equity" && type !== "Cash" && type !== "TokenCross" && type !== "Perpetual";
  let showUnderlying = type !== "Index" && type !== "Equity" && type !== "Cash" && type !== "Bond" && type !== "TokenCross";
  let showIsin = type !== "Index" && type !== "Future" && type !== "Cash" && type !== "Option" && type !== "TokenCross" && type !== "Perpetual";
  let showOption = type === "Option";
  let showAsset = assetId > 0;
  let somethingChanged = touched && Array.from(Object.keys(touched)).length > 0;
  return (
    <Form onSubmit={handleSubmit} className={className}>
      <ThemeProvider theme={getFormTheme()}>
        <Grid container spacing={2} justifyContent="center" >
          <Grid item>
            <TextField
              style={{ width: "100px" }}
              size="small"
              variant="standard"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="Id"
              name="Id"
              helperText={errors.listedInstrumentId ? errors.listedInstrumentId : ""}
              error={errors.listedInstrumentId || Boolean(errors.listedInstrumentId)}
              label="Id"
              value={listedInstrumentId}
              onChange={() => { }}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
          <Grid item>
            <TextField
              style={{ width: "200px" }}
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="ticker"
              name="ticker"
              helperText={touched.ticker ? errors.ticker : ""}
              error={touched.ticker || Boolean(errors.ticker)}
              label="Ticker"
              value={ticker}
              onChange={change.bind(null, "ticker")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          {showAsset ? <Grid item>
            <WrappedSelect
              id="assetId"
              name="assetId"
              //helperText={touched.assetId ? errors.assetId : ""}
              error={touched.assetId || Boolean(errors.assetId)}
              label="Linked Asset"
              value={assetId}
              onChange={change.bind(null, "assetId")}>
              {assetsStoreInstance.allAssets().map(a =>
                <MenuItem key={"aki" + a.assetId} value={a.assetId}>{a.name}</MenuItem>)}
            </WrappedSelect></Grid> : null}
          <Grid item>
            <WrappedSelect
              id="newTradeTemplate"
              name="newTradeTemplate"
              label="Type"
              value={type}
              onChange={(e) => onTypeChange(e.target.value as string)}>
              {instrumentTypes.map(a =>
                <MenuItem key={"insT" + a} value={a}>{a}</MenuItem>)}
            </WrappedSelect>
          </Grid>
          <Grid item>
            <TextField
              style={{ minWidth: "350px" }}
              size="small"
              variant="outlined"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="description"
              name="description"
              helperText={touched.description ? errors.description : ""}
              error={touched.description || Boolean(errors.description)}
              label="Description"
              value={description}
              onChange={change.bind(null, "description")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="exchange"
              name="primaryExchange"
              helperText={touched.primaryExchange ? errors.primaryExchange : ""}
              error={touched.primaryExchange && Boolean(errors.primaryExchange)}
              label="Exchange"
              value={primaryExchange}
              onChange={change.bind(null, "primaryExchange")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              style={{ width: "100px" }}
              id="currency"
              name="currency"
              helperText={touched.ccy ? errors.ccy : ""}
              error={touched.ccy && Boolean(errors.ccy)}
              label="Currency"
              value={ccy}
              onChange={change.bind(null, "ccy")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="multiplier"
              name="multiplier"
              helperText={touched.multiplier ? errors.multiplier : ""}
              error={touched.multiplier && Boolean(errors.multiplier)}
              label="Multiplier"
              value={multiplier}
              onChange={(e) => onNumberChange(e.target.value, "multiplier")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
          {showIsin ? <Grid item>
            <TextField
              variant="outlined"
              size="small"
              classes={{ root: "ListedInstrumentEditorFormField" }}
              id="iSIN"
              name="isin"
              helperText={touched.isin ? errors.isin : ""}
              error={touched.isin || Boolean(errors.isin)}
              label="ISIN"
              value={isin ?? ""}
              onChange={change.bind(null, "isin")}
              InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid> : null}
          {showMaturity && !showOption ? <Grid item>
            <WrappedDatePicker
              size="small"
              id="maturity"
              name="maturity"
              helperText={touched.maturity ? errors.maturity : ""}
              error={touched.maturity || Boolean(errors.maturity)}
              label="Maturity"
              value={maturity}
              onChange={(d) => onDateChange(d, "maturity")} /></Grid> : null}
          {showUnderlying ? <Grid item>
            <WrappedSelect
              size="small"
              id="underlyingId"
              name="underlyingId"
              error={touched.underlyingId || Boolean(errors.underlyingId)}
              label="Underlying"
              value={underlyingId}
              onChange={change.bind(null, "underlying")}>
              {listedInstrumentStoreInstance.getInstruments().filter(x => Boolean(x)).map(i =>
                <MenuItem key={"uli" + i?.listedInstrumentId} value={i.listedInstrumentId}>{i.description}</MenuItem>)}
            </WrappedSelect> </Grid> : null}
          {showOption ? <Grid item container spacing={2} justifyContent="space-around" alignContent="space-around" classes={{ root: "ListedInstrumentEditorFormGrid" }}>
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                classes={{ root: "ListedInstrumentEditorFormField" }}
                id="optionStrike"
                name="optionStrike"
                helperText={touched.optionStrike ? errors.optionStrike : ""}
                error={touched.optionStrike || Boolean(errors.optionStrike)}
                label="Strike"
                value={optionStrike}
                onChange={change.bind(null, "optionStrike")}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
            <Grid item>
              <WrappedSelect
                size="small"
                id="callput"
                name="callput"
                label="Call/Put"
                value={optionIsCall ? "Call" : "Put"}
                onChange={(e) => onCallPutChange(e.target.value as string)}>
                <MenuItem key={"cp_Call"} value={"Call"}>Call</MenuItem>
                <MenuItem key={"cp_Put"} value={"Put"}>Put</MenuItem>
              </WrappedSelect>
            </Grid>
            <Grid item>
              <WrappedDatePicker
                size="small"
                id="maturity"
                name="maturity"
                helperText={touched.maturity ? errors.maturity : ""}
                error={touched.maturity || Boolean(errors.maturity)}
                label="Maturity"
                value={maturity}
                onChange={(d) => onDateChange(d, "maturity")} /></Grid>
          </Grid> : null}
          <GridBreak />
          <Accordion className="ListedInstrumentEditorFormMetaDataContainer" defaultExpanded={!metaCollapsable} disabled={!metaCollapsable} >
            <AccordionSummary
              expandIcon={metaCollapsable ? <ExpandMoreOutlined className="ListedInstrumentEditorFormFieldInner" /> : null}
              aria-controls="panel1a-content"
              id="panel1a-header"                >
              <Typography variant="h6">Meta Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FieldArray name="metaData" render={(arrayHelpers: FieldArrayRenderProps) => (
                <div className="InsMetaDataBox">
                  {metaData && metaData.length > 0 ? (
                    metaData.map((m: ListedInstrumentMetaData, ix) => {
                      if (m?.deleted || (BookStr!=="DemoBook" && m?.type?.startsWith("Demo")))
                        return null;
                      return <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                        <Grid container spacing={1}>
                          <Grid item><Autocomplete
                            freeSolo
                            size="small"
                            style={{ width: "200px" }}
                            options={Array.from(listedInstrumentStoreInstance.getMetaDataCategories())}
                            id={`metaData[${ix}].category`}
                            value={m.category ?? ""}
                            autoSelect
                            onChange={(e, v) => onMetaCategoryChange(v, ix)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={touched.metaData ? touched.metaData[ix]?.category : false}
                                label="Category"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                          />
                          </Grid>
                          <Grid item><Autocomplete
                            freeSolo
                            size="small"
                            style={{ width: "200px" }}
                            options={Array.from(listedInstrumentStoreInstance.getMetaDataTypesForCategory(m?.category))}
                            //classes={{ root: "ListedInstrumentEditorFormField" }}
                            id={`metaData[${ix}].type`}
                            value={m.type ?? ""}
                            autoSelect
                            onChange={(e, v) => onMetaTypeChange(v, ix)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={touched.metaData ? touched.metaData[ix]?.type : false}
                                label="Label"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                          />
                          </Grid>
                          <Grid item><Autocomplete
                            freeSolo
                            size="small"
                            style={{ width: "200px" }}
                            options={m === undefined || m.type === undefined || m.type === null ? new Array<string>() : Array.from(listedInstrumentStoreInstance.getMetaDataByType(m?.category, m?.type))}
                            //classes={{ root: "ListedInstrumentEditorFormField" }}
                            id={`metaData[${ix}].data`}
                            value={m.data ?? ""}
                            autoSelect
                            onChange={(e, v) => onMetaDataChange(v, ix)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={touched.metaData ? touched.metaData[ix]?.data : false}
                                label="Data"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                          />
                          </Grid>
                          <IconButton
                            onClick={() => { onMetaDataDelete(ix) }}
                            className="ListedInstrumentEditorFormFieldInner"
                            size="large">
                            <RemoveOutlined color="inherit" />
                          </IconButton>
                        </Grid>
                      </div>})
                  ) : null}
                  <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                    <Button variant="outlined" onClick={() => arrayHelpers.push({ listedInstrumentId: id, lastUpdated: new Date() } as ListedInstrumentMetaData)} className="PltfmButtonLite">
                      <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                      <ListItemText>Add Meta Data</ListItemText>
                    </Button>
                  </div>
                </div>
              )} />
            </AccordionDetails>
          </Accordion>
          {/* <GridBreak /> */}
          <Grid item container spacing={2} justifyContent="center" >
            <Grid item>     <Button
              className="PltfmButtonLite"
              type="submit"
              variant="outlined"
              disabled={!isValid || !somethingChanged || isSubmitting}
              endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
            <Grid item> <Button
              className="PltfmButtonLite"
              type="reset"
              variant="outlined"
              endIcon={<CancelOutlined />}>Revert</Button></Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Form >
  );
};