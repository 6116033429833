import {TypedEvent, AppDispatcher} from '../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import {get} from '../utils/httpUtils';
import { Firm } from './userModel';

export class LoadFirmsEvent extends TypedEvent<Firm[]> {}
export class LoadClientFirmsEvent extends TypedEvent<Firm[]> {}
export class LoadFirmsFailedEvent extends TypedEvent<string> {}

export async function LoadFirms(){
    const userInfo = await get<Firm[]>("Firm");
    if(userInfo.payload == null)
    {
        AppDispatcher.dispatch(new LoadFirmsFailedEvent("There was an error loading the firm info"));
        
    } else {
        AppDispatcher.dispatch(new LoadFirmsEvent(userInfo.payload))
        
    }

    await LoadClientFirms();
}

export async function LoadClientFirms(){
    const clientFirms = await get<Firm[]>("Firm/clientFirms");
    if(clientFirms.payload == null)
    {
        AppDispatcher.dispatch(new LoadFirmsFailedEvent("There was an error loading the list of client firms"));
        UpdateLoadingStatus("Firms", LoadingStatus.Failed);
    } else {
        AppDispatcher.dispatch(new LoadClientFirmsEvent(clientFirms.payload))
        UpdateLoadingStatus("Firms", LoadingStatus.Completed);
    }
}