import IconButton from '@mui/material/IconButton';
import {
    useGridApiRef,
    DataGridPremium,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridCellParams,
    GridToolbarDensitySelector,
    GridRowData,
    GridCsvExportOptions,
    GridSortItem,
} from '@mui/x-data-grid-premium';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Grid, Button, Typography, AppBar, Dialog, Toolbar, Menu, MenuItem, FormControlLabel, Switch, Tooltip, DialogTitle, DialogContent } from '@mui/material';
import { RefreshOutlined, CloseOutlined, StarRateOutlined, SaveOutlined, TuneOutlined, PlayCircleFilledOutlined, PauseCircleFilledOutlined, SortOutlined, AccountTreeOutlined, WarningAmberOutlined, FavoriteOutlined } from '@mui/icons-material';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import userStoreInstance from '../user/userStore';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { assetSort, AssetStr, AttributionStr, BetaStr, BookStr, DefaultSetName, EarliestDate, InstrumentStr, StrategyStr, SubAttributionStr } from '../globalConstants';
import { LivePosition, PositionView } from './positionSummaryModels';
import { colCommon, getBbgTickerFromInstrument, getColCommon, getFilters, getIns, getMetaFromInstrument, getMetaFromPosition, getPositionKey, getUltimateParent, isCashFx, isTreasury } from './positionsCommon';
import moment from 'moment';
import { BlackDelta, BlackGamma, BlackImpliedVol, BlackVega } from '../qwack/blackFunctions';
import { EventSubscription } from 'fbemitter';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { getFormTheme } from '../inputs/formCommon';
import { num2String, renderDateCell, renderDateTimeCell } from '../utils/helpers';
import { PositionLiveSummary } from './positionLiveSummary';
import { ConfigBlob } from '../user/userModel';
import { AddOrUpdateConfig, DeleteConfig, GetConfigsForType } from '../user/userActions';
import { addWeekDays, numberOfWeekDays } from '../utils/dates';
import positionStoreInstance from './positionSummaryStore';
import { GetSessionState, SetSessionState } from '../trade/tradeBlotterTabComponent';
import { LoadItemSpec, maxFavourites, SaveLoadDeleteBox } from '../inputs/saveLoadDeleteBox';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';
import ordersStoreInstance from '../orders/orderStore';
import { OrdersBox } from '../orders/ordersBox';
import { loading } from '../utils/loading';
import { SlTpBox } from './slTpBox';
import navStoreInstance from '../nav/navStore';
import { ComputeFeederMasterNavs, NavsRequested } from '../nav/navActions';
import _ from 'lodash';
import layoutStoreInstance from '../layout/layoutStore';
import { UpdatePreference } from '../layout/layoutActions';
import acceleratorStoreInstance from '../accelerator/acceleratorStore';
import { ConnectionStatus } from '../connections/connectionStatusModel';
import TransferList from '../inputs/transferList';
import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import marketDataStoreInstance from '../marketData/marketDataStore';

const viewKey = "positionActiveView";
const PositionViewStr = "PositionView";
const PositionViewFavStr = "PositionViewFavourite";
const DefaultViewPrefName = "Positions-DefaultView";
const defaultTree = ["asset", "ultimateUnderlying"];

export const ShouldInvertPrice = (ins: ListedInstrument) => {
    return ins.primaryExchange === "Deribit" && ins.ccy !== "USD" && ins.description.includes(`${ins.ccy}/USD`);
}

export const ShouldInvertPriceFast = (insId: number) => {
    return insId === 200565;
}

const NaNorZero = (x: number) => {
    return isNaN(x) || x === undefined ? 0 : x;
}

const groupSumColumns = [
    "deltaEquivBase", "deltaEquivBasePct", "unRealizedPnLBase", "unRealizedPnLBasePct", "realizedPnLBase",
    "realizedPnLBasePct", "totalPnLBase", "totalPnLBasePct", "openPosition", "openPositionInUnits", "openPositionInUnitsDeltaAdj",
    "tpAmountBase", "tpAmountPct", "tpAmountFromAvgBase", "tpAmountFromAvgPct",
    "stopLossAmountBase", "stopLossAmountPct", "stopLossAmountFromAvgBase", "stopLossAmountFromAvgPct",
    "valUsd", "valPct", "costUsd", "costPct",
    "hardStopAmountPct", "hardStopAmountBase", "hardStopLevelPct",
    "xFactor"
];

export const PositionLiveGrid = props => {
    const { summary, baselineSummary, jobUpdateMessage, asOf, onChangeAsOfDate, baseline, onChangeBaselineDate, awaitingRefresh, onClickRefresh, showFxAsPosition, onChangeShowFxAsPosition } = props;
    const [hideClosedPositions, sethideClosedPositions] = useState(true);
    const [showStatus, setShowStatus] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [rows, setRows] = useState(new Array<any>());
    const [columns, setColumns] = useState(new Array<GridColDef>());
    const [gridShowsDiff, setGridShowsDiff] = useState(false);
    const [pnlInPctNav, setPnlInPctNav] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [menuAnchorFav, setMenuAnchorFav] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [showSaveView, setShowSaveView] = useState(false);
    const [groupByTree, setGroupByTree] = useState(false);
    const [showDefineTree, setShowDefineTree] = useState(false);
    const [activeView, setActiveView] = useState<PositionView>({} as PositionView);
    const [initialized, setInitialized] = useState(false);
    const [baselineMap, setBaselineMap] = useState(new Map<string, LivePosition>());
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [selectedRow, setSelectedRow] = useState<number>();
    const [editOrdersId, setEditOrdersId] = useState<number>(undefined);
    const [editSlTpId, setEditSlTpId] = useState<string>(undefined);
    const [baseLineNav, setBaseLineNav] = useState<number>(undefined);
    const [pauseLiveUpdated, setPauseLiveUpdates] = useState(false);
    const [positionToClose, setPositionToClose] = useState<number>(undefined);
    const [orderMeta, setOrderMeta] = useState<string[]>(undefined);
    const [treeFields, setTreeFields] = useState<string[]>(defaultTree);
    const [viewOptions, setViewOptions] = useState<LoadItemSpec[]>();

    var eventSubscriptionMarketDataTicks = useRef({} as EventSubscription);
    var eventSubscriptionPositions = useRef({} as EventSubscription);
    var eventSubscriptionNav = useRef({} as EventSubscription);
    var eventSubscriptionOrders = useRef({} as EventSubscription);

    var apiRef = useRef(useGridApiRef());

    const tpCellClassNameFn = (params: GridCellParams) => params.row.tpLevelPct < 0 ? "PositionSummaryTabTableCell--Breach" : "PositionSummaryTabTableCell";
    const slCellClassNameFn = (params: GridCellParams) => params.row.stopLevelPct > 0 ? "PositionSummaryTabTableCell--Breach" : "PositionSummaryTabTableCell";
    const signedClassNameFn = (params: GridCellParams) => params.value < 0 ? "PositionSummaryTabTableCell--Negative" : "PositionSummaryTabTableCell--Positive";

    const baseColDefs: GridColDef[] = useMemo(() => [
        { field: 'asset', width: 100, headerName: 'Asset', cellClassName: (params: GridCellParams) => params.value ? "PositionSummaryTabTableCell--" + params.value.split(' ')[0] : "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", },
        { field: 'orders', width: 100, headerName: 'Orders', type: "number", ...getColCommon("number") },
        { field: 'instrumentId', hide: true, width: 150, headerName: 'InsId', type: "number", ...getColCommon("number") },
        { field: 'instrument', width: 300, headerName: 'Description', ...colCommon, filterOperators: getFilters("string") },
        { field: 'type', width: 120, headerName: 'Type', ...colCommon, filterOperators: getFilters("string") },
        { field: 'openPosition', width: 200, headerName: 'Contracts', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'openPositionInUnits', width: 175, headerName: 'Quantity', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.multiplier * value.row.openPosition); } },
        { field: 'deltaEquivBase', width: 175, headerName: 'Delta (USD)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: renderCcyCell, filterOperators: getFilters("number") },
        { field: 'deltaEquivBasePct', width: 150, headerName: 'Delta (%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.deltaEquivBase / value.row.nav); } },
        { field: 'openPositionInUnitsDeltaAdj', width: 175, headerName: 'Delta (Units)', cellClassName: signedClassNameFn, renderCell: renderNumberCell2dp, headerClassName: "PositionSummaryTabTableCellHeader", type: "number", filterOperators: getFilters("number") },
        { field: 'lastPrice', width: 150, headerName: 'Last', ...colCommon, renderCell: renderNumberCell, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'bidPrice', width: 100, headerName: 'Bid', ...colCommon, renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'askPrice', width: 100, headerName: 'Ask', ...colCommon, renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'priceChangePct', width: 100, headerName: 'Change %', ...colCommon, cellClassName: signedClassNameFn, renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.lastPrice / value.row.priceBaseline - 1.0); } },
        { field: 'priceBaseline', width: 100, headerName: 'Prev Price', ...colCommon, renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'underlying', width: 200, headerName: 'Underlying', ...colCommon, hide: true, filterOperators: getFilters("string") },
        { field: 'underlyingType', width: 130, headerName: 'U. Type', ...colCommon, hide: true, filterOperators: getFilters("string") },
        { field: 'underlyingId', width: 150, headerName: 'U. Id', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'ultimateUnderlying', width: 150, headerName: 'Parent', ...colCommon, hide: true, filterOperators: getFilters("string") },
        { field: 'ccy', width: 110, headerName: 'CCY', ...colCommon, filterOperators: getFilters("string") },
        { field: 'fxToBase', width: 100, headerName: 'Fx', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'multiplier', width: 100, headerName: '(x)', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'bbgTicker', width: 200, headerName: 'BBG Ticker', ...colCommon, filterOperators: getFilters("string") },
        { field: 'attribution', width: 150, headerName: 'Attribution', ...colCommon, filterOperators: getFilters("string") },
        { field: 'subAttribution', width: 150, headerName: 'Sub-Attrib', ...colCommon, hide: true, filterOperators: getFilters("string") },
        { field: 'insMetaStr', width: 150, headerName: 'Instrument', ...colCommon, filterOperators: getFilters("string") },
        { field: 'beta', width: 150, headerName: 'U/L Beta', ...colCommon, filterOperators: getFilters("string") },
        { field: 'numTrades', width: 150, headerName: '# Trades', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'averagePrice', width: 150, headerName: 'Avg (LCY)', ...colCommon, renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'averagePriceBase', width: 150, headerName: 'Avg Price', ...colCommon, renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'averagePriceBuy', width: 150, headerName: 'Avg Buy (LCY)', ...colCommon, renderCell: renderNumberCell, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'averagePriceSell', width: 150, headerName: 'Avg Sell (LCY)', ...colCommon, renderCell: renderNumberCell, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'averagePriceExBro', width: 150, headerName: 'Avg (LCY ExBro)', ...colCommon, renderCell: renderNumberCell, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'quantityBought', width: 150, headerName: 'Size Bought', ...colCommon, renderCell: renderNumberCell, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'quantitySold', width: 150, headerName: 'Size Sold', ...colCommon, renderCell: renderNumberCell, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'realizedPnL', width: 150, headerName: 'Realized (LCY)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
        { field: 'realizedPnLBase', width: 150, headerName: 'Realized', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
        { field: 'realizedPnLBasePct', width: 150, headerName: 'Realized (%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.realizedPnLBase / value.row.nav); } },
        { field: 'unRealizedPnL', width: 150, headerName: 'UnRealised (LCY)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
        { field: 'unRealizedPnLBase', width: 150, headerName: 'UnRealised', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
        { field: 'unRealizedPnLBasePct', width: 150, headerName: 'UnRealized (%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.unRealizedPnLBase / value.row.nav); } },
        { field: 'totalPnL', width: 150, headerName: 'PnL (LCY)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
        { field: 'totalPnLBase', width: 150, headerName: 'PnL', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
        { field: 'totalPnLBasePct', width: 150, headerName: 'PnL (%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.totalPnLBase / value.row.nav) ?? 0; } },
        { field: 'firstTraded', width: 200, headerName: 'First Trade', ...colCommon, type: "dateTime", renderCell: renderDateTimeCell, hide: true, filterOperators: getFilters("dateTime") },
        { field: 'lastTraded', width: 200, headerName: 'Last Trade', ...colCommon, type: "dateTime", renderCell: renderDateTimeCell, hide: true, filterOperators: getFilters("dateTime") },
        { field: 'daysOpen', width: 150, headerName: 'Days Open', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number") },
        { field: 'delta', width: 150, headerName: 'δ (%)', ...colCommon, renderCell: renderPctCell, type: "number", filterOperators: getFilters("number") },
        { field: 'gamma', width: 150, headerName: 'γ (%/%)', ...colCommon, renderCell: renderPctCell, type: "number", filterOperators: getFilters("number") },
        { field: 'vega', width: 150, headerName: 'ν (CCY/%)', ...colCommon, renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLevel', width: 150, headerName: 'Stop Level', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLevelPct', width: 150, headerName: 'Stop % Live', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLossAmount', width: 150, headerName: 'SL to Live (LCY)', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLossAmountPct', width: 150, headerName: 'SL to Live (%NAV)', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.stopLossAmountBase / value.row.nav); } },
        { field: 'stopLossAmountBase', width: 150, headerName: 'SL to Live', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLossAmountFromAvg', width: 150, headerName: 'SL to Entry (LCY)', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLossAmountFromAvgBase', width: 150, headerName: 'SL to Entry', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'stopLossAmountFromAvgPct', width: 150, headerName: 'SL to Entry (%NAV)', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.stopLossAmountFromAvgBase / value.row.nav); } },
        { field: 'tpLevel', width: 150, headerName: 'TP Level', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell, type: "number", filterOperators: getFilters("number") },
        { field: 'tpLevelPct', width: 150, headerName: 'TP % Live', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number") },
        { field: 'tpAmount', width: 150, headerName: 'TP to Live (LCY)', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'tpAmountPct', width: 150, headerName: 'TP to Live (%NAV)', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.tpAmountBase / value.row.nav); } },
        { field: 'tpAmountBase', width: 150, headerName: 'TP to Live', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'tpAmountFromAvg', width: 150, headerName: 'TP to Entry (LCY)', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'tpAmountFromAvgBase', width: 150, headerName: 'TP to Entry', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'tpAmountFromAvgPct', width: 150, headerName: 'TP to Entry (%NAV)', cellClassName: tpCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number") },
        { field: 'xFactor', width: 100, headerName: 'X Factor', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell2dp, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(-value.row.tpAmountBase / value.row.stopLossAmountBase); } },
        { field: 'posKey', width: 250, headerName: 'Key', ...colCommon, hide: true, filterOperators: getFilters("string") },
        { field: 'book', width: 100, headerName: 'Book', ...colCommon, hide: true, filterOperators: getFilters("string") },
        { field: 'strategy', width: 250, headerName: 'Theme', ...colCommon, hide: true, filterOperators: getFilters("string") },

        { field: 'costLcy', width: 150, headerName: 'Cost (LCY)', ...colCommon, hide: true, renderCell: renderCcyCell, type: "number" },
        { field: 'costUsd', width: 150, headerName: 'Cost', ...colCommon, hide: true, renderCell: renderCcyCell, type: "number" },
        { field: 'costPct', width: 150, headerName: 'Cost (%NAV)', ...colCommon, hide: true, renderCell: renderPctCell, type: "number", valueGetter: (value) => { return value.row.costUsd / value.row.nav; } },
        { field: 'valLcy', width: 150, headerName: 'Value (LCY)', ...colCommon, hide: true, renderCell: renderCcyCell, type: "number" },
        { field: 'valUsd', width: 150, headerName: 'Value', ...colCommon, hide: true, renderCell: renderCcyCell, type: "number" },
        { field: 'valPct', width: 150, headerName: 'Value (%NAV)', ...colCommon, hide: true, renderCell: renderPctCell, type: "number", valueGetter: (value) => { return value.row.valUsd / value.row.nav; } },

        { field: 'expiry', width: 150, headerName: 'Expiry', ...colCommon, type: "date", renderCell: renderDateCell, hide: true, filterOperators: getFilters("dateTime") },
        { field: 'expiryDays', width: 150, headerName: 'Expiry (Days)', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number") },

        { field: 'strike', width: 150, headerName: 'Strike', ...colCommon, type: "number", hide: true, filterOperators: getFilters("number"), renderCell: renderNumberCell },
        { field: 'isCall', width: 150, headerName: 'IsCall', ...colCommon, type: "boolean", hide: true, filterOperators: getFilters("boolean") },

        { field: 'hardStopAmount', width: 150, headerName: 'Hard Stop (LCY)', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'hardStopAmountPct', width: 150, headerName: 'Hard Stop (%NAV)', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.hardStopAmountBase / value.row.nav); } },
        { field: 'hardStopAmountBase', width: 150, headerName: 'Hard Stop ', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number", filterOperators: getFilters("number") },
        { field: 'hardStopLevelPct', width: 150, headerName: 'Hard Stop % Live', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.openPositionInUnitsAbsolute < 0 ? (value.row.lastPrice / value.row.hardStopAvgPrice - 1) : (value.row.hardStopAvgPrice / value.row.lastPrice - 1)); } }, //
        { field: 'stopCoverage', width: 150, headerName: 'Stop Coverage', cellClassName: slCellClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number", filterOperators: getFilters("number"), valueGetter: (value) => { return NaNorZero(value.row.stopLossAmountBase / value.row.hardStopAmountBase); } },
    ], []);

    useEffect(() => {

        if (asOf === null) { //only in "live" mode
            if (eventSubscriptionMarketDataTicks.current?.remove)
                eventSubscriptionMarketDataTicks.current.remove();
            if (eventSubscriptionPositions.current?.remove)
                eventSubscriptionPositions.current.remove();

            eventSubscriptionMarketDataTicks.current = marketDataLiveStoreInstance.addChangeListener(onTick);
            eventSubscriptionPositions.current = positionStoreInstance.addChangeListener(onPositionChange);
            eventSubscriptionOrders.current = ordersStoreInstance.addChangeListener(onOrderUpdate);
        }

        eventSubscriptionNav.current = navStoreInstance.addChangeListener(onNavUpdate);

        function onOrderUpdate() {
            generateRowData();
        }

        async function onNavUpdate(bs?: Date) {
            if (!navStoreInstance.navsAreLoaded() && !navStoreInstance.navsHaveBeenRequested()) {
                NavsRequested();
                await ComputeFeederMasterNavs(DefaultSetName)
            }

            if (!bs)
                bs = baseline;

            if (bs) {
                var baseLineNav = 0;
                var officialNav = navStoreInstance.getNavsForFundSync("Galena Master Fund")?.filter(n => moment(n.date).isSame(moment(bs)))[0];
                if (officialNav)
                    baseLineNav = officialNav.navPerShare * officialNav.sharesPostSubsReds;
                else {
                    baseLineNav = navStoreInstance.getCalculatedNav(new Date(bs), "MasterFund")?.totalInBase;
                    var subsReds = navStoreInstance.getSubsReds().filter(sr => moment(sr.asOfDate).startOf('day').isSame(moment(bs).startOf('day')) && sr.fund === "Galena Master Fund");
                    if (subsReds && subsReds.length > 0) {
                        baseLineNav += _.sum(subsReds.map(sr => sr.totalValue));
                    }
                }

                if (baseLineNav) {
                    setBaseLineNav(baseLineNav);
                }
            }
            else {
                var today = moment().startOf('day');
                var storageKeyYesterday = `PnL-Yesterday-${today.format("yyyy-MM-DD")}`;
                var ytd = sessionStorage.getItem(storageKeyYesterday);

                if (!ytd) {
                    var ytdDate = addWeekDays(today, -1);
                    ytd = ytdDate.format("yyyy-MM-DD");
                    sessionStorage.setItem(storageKeyYesterday, ytd);
                }

                var baseNav = navStoreInstance.getCalculatedNav(new Date(ytd), "MasterFund")?.totalInBase;

                if (baseNav) {
                    setBaseLineNav(baseNav);
                }
            }
        }

        onNavUpdate();

        function loadViewInner(view: PositionView) {
            if (view) {
                if (view.density)
                    apiRef.current.current.setDensity(view.density);

                if (view.datesAreRelative) {
                    var today = moment().startOf('day');
                    onChangeAsOfDate(view.asOfRelative !== undefined ? addWeekDays(today, view.asOfRelative) : null);
                    onChangeBaselineDate(view.baselineRelative !== undefined ? addWeekDays(today, view.baselineRelative) : null);
                }
                else {
                    if (view.asOf) {
                        onChangeAsOfDate(moment(view.asOf));
                    }
                    if (view.baseline) {
                        onChangeBaselineDate(moment(view.baseline));
                        onNavUpdate(moment(view.baseline).toDate());
                    }
                }

                if (view.showSummary !== undefined)
                    setShowSummary(view.showSummary)
                if (view.showDifference !== undefined)
                    setGridShowsDiff(view.showDifference)

                if (view.gridState)
                    apiRef.current.current.restoreState(view.gridState);

                if (view.treeDefinition)
                    setTreeFields(view.treeDefinition);
                if (view.treeView) {
                    setGroupByTree(view.treeView);
                }
                // setSaveViewRelativeDates(view.datesAreRelative);
                // setSaveViewName(view.name);
            }
        }

        function onPositionChange() {
            var vals = positionStoreInstance.getValuations(null, true);

            var rowsToUpdate = new Array<any>();
            setRows(rr => {
                vals?.positions?.forEach(p => {
                    var row = rr.filter(r => r.posKey === getPositionKey(p))[0];
                    if (row && row.openPosition !== p.aggregatedPosition.openPosition) {
                        var ins = getIns(p.aggregatedPosition.instrumentId);
                        row.openPosition = p.aggregatedPosition.openPosition - (baselineMap.get(getPositionKey(p))?.aggregatedPosition?.openPosition ?? 0);
                        row.openPositionInUnits = (p.aggregatedPosition.openPosition - (baselineMap.get(getPositionKey(p))?.aggregatedPosition?.openPosition ?? 0)) * (ins?.multiplier ?? 1.0)
                        row.openPositionInUnitsAbsolute = p.aggregatedPosition.openPosition * (ins?.multiplier ?? 1.0)

                        rowsToUpdate.push(row);
                    }
                });
                return rr;
            });

            if (rowsToUpdate.length > 0) {
                apiRef.current.current.updateRows(rowsToUpdate);
            }
        }

        if (!initialized) {
            GetConfigsForType("PositionView");
        }

        function onTick() {
            //console.log("Tick");
            if (asOf === null && Boolean(summary) && !pauseLiveUpdated) { //live mode
                var calcDiff = gridShowsDiff && baseline;
                var fallbackWidth = userStoreInstance.GetFallbackPriceWidth();
                var rowsToUpdate = new Array<any>();
                setRows(rr => {
                    rr.forEach(r => {
                        if (r.isLeaf) {
                            if (!gridShowsDiff && r.openPosition === 0) {
                                if (r.unRealizedPnL !== 0 || r.unRealizedPnLBase !== 0 || r.deltaEquivBase !== 0 || r.openPositionInUnitsDeltaAdj !== 0) {
                                    r.unRealizedPnL = 0;
                                    r.unRealizedPnLBase = 0;
                                    r.deltaEquivBase = 0;
                                    r.openPositionInUnitsDeltaAdj = 0;
                                    r.delta = undefined;
                                    r.stopLossAmount = 0;
                                    r.tpAmount = 0;
                                    rowsToUpdate.push(r);
                                }
                            }
                            else {
                                var insId = Number(r.instrumentId);
                                var lastPrice = marketDataLiveStoreInstance.getTick(insId);
                                var bidAsk = marketDataLiveStoreInstance.getBidAsk(insId);
                                if (!lastPrice)
                                    lastPrice = (bidAsk.bid + bidAsk.ask) / 2;

                                var tick: number;
                                if (!bidAsk.ask || !bidAsk.bid || Math.abs(bidAsk.ask / bidAsk.bid - 1) > fallbackWidth) {
                                    tick = bidAsk.bid ? Math.max(bidAsk.bid, lastPrice) : lastPrice;
                                    if (bidAsk.ask) {
                                        tick = Math.min(bidAsk.ask, tick);
                                    }
                                }
                                else {
                                    tick = (bidAsk.bid + bidAsk.ask) / 2;
                                }

                                if (ShouldInvertPriceFast(insId)) {
                                    tick = 1 / tick;
                                }

                                var fxRate = marketDataLiveStoreInstance.getFxRate(r.ccy);
                                if (tick && (tick !== r.lastPrice || fxRate !== r.fxToBase)) {
                                    r.lastPrice = lastPrice;
                                    r.fxToBase = fxRate ?? r.fxToBase ?? 1.0;
                                    r.bidPrice = bidAsk?.bid ?? tick;
                                    r.askPrice = bidAsk?.ask ?? tick;
                                    var ur0 = (Number(r.openPositionInUnitsAbsolute) * (tick - Number(r.averagePrice)));
                                    r.unRealizedPnL = ur0 - (!calcDiff ? 0 : (baselineMap.get(r.posKey)?.unrealizedPnL ?? 0));
                                    r.unRealizedPnLBase = (ur0 * r.fxToBase) - (!calcDiff ? 0 : (baselineMap.get(r.posKey)?.unrealizedPnLBase ?? 0));
                                    r.realizedPnL = r.r0 - (!calcDiff ? 0 : (baselineMap.get(r.posKey)?.aggregatedPosition.realizedPnL ?? 0));
                                    r.realizedPnLBase = r.r0b - (!calcDiff ? 0 : (baselineMap.get(r.posKey)?.aggregatedPosition.realizedPnLBase ?? 0));
                                    r.totalPnL = r.realizedPnL + r.unRealizedPnL;
                                    r.totalPnLBase = r.realizedPnLBase + r.unRealizedPnLBase;
                                    r.valLcy = r.lastPrice * r.openPositionInUnitsAbsolute;
                                    r.valUsd = r.valLcy * r.fxToBase;

                                    rowsToUpdate.push(r);
                                    if (r.type === "Option") {
                                        var ulTick = marketDataLiveStoreInstance.getTick(Number(r.underlyingId));
                                        if (ulTick) {
                                            var tExp = moment(r.maturity).diff(moment(new Date()), "hour") / (365 * 24);
                                            var vol = BlackImpliedVol(ulTick, Number(r.strike), 0, tExp, tick, Boolean(r.isCall));
                                            var delta = BlackDelta(ulTick, Number(r.strike), 0, tExp, vol, Boolean(r.isCall));
                                            r.delta = delta;
                                            var gamma = BlackGamma(ulTick, Number(r.strike), 0, tExp, vol);
                                            r.gamma = gamma;
                                            var vega = BlackVega(ulTick, Number(r.strike), 0, tExp, vol);
                                            r.vega = vega * Number(r.openPositionInUnitsAbsolute);

                                            r.openPositionInUnitsDeltaAdj = r.openPositionInUnitsAbsolute * r.delta;
                                            r.deltaEquivBase = r.openPositionInUnitsDeltaAdj * ulTick * r.fxToBase;
                                        }
                                    }
                                    else {
                                        r.deltaEquivBase = r.openPositionInUnitsDeltaAdj * tick * r.fxToBase;
                                    }
                                    r.deltaEquivBase -= (!calcDiff ? 0 : (baselineMap.get(getPositionKey(r.posKey))?.deltaBaseEquiv ?? 0));

                                    if (r.stopLevel) {
                                        r.stopLevelPct = NaNorZero(r.openPositionInUnitsAbsolute > 0 ? r.stopLevel / tick - 1.0 : tick / r.stopLevel - 1.0);
                                        r.stopLossAmount = NaNorZero(r.openPositionInUnitsAbsolute * (r.stopLevel - tick));
                                        r.stopLossAmountBase = NaNorZero(r.stopLossAmount * r.fxToBase);
                                    }
                                    if (r.tpLevel) {
                                        r.tpLevelPct = NaNorZero(r.openPositionInUnitsAbsolute > 0 ? r.tpLevel / tick - 1.0 : tick / r.tpLevel - 1.0);
                                        r.tpAmount = NaNorZero(r.openPositionInUnitsAbsolute * (r.tpLevel - tick));
                                        r.tpAmountBase = NaNorZero(r.tpAmount * r.fxToBase);
                                    }
                                    if (r.hardStopSize) {
                                        r.hardStopAmount = NaNorZero(-r.hardStopSize * r.multiplier * (r.hardStopAvgPrice - tick));
                                        r.hardStopAmountBase = NaNorZero(r.hardStopAmount * r.fxToBase);
                                    }
                                }
                            }
                        }
                    })
                    return rr;
                })

                if (rowsToUpdate.length > 0) {
                    apiRef.current.current.updateRows(rowsToUpdate);
                }
            }
        }

        function generateRowData() {

            const blMap = new Map<string, LivePosition>();
            if (baselineSummary && gridShowsDiff) {
                baselineSummary?.positions?.forEach(p => {
                    blMap.set(getPositionKey(p), p);
                });
            }

            var rawPositions = summary?.positions;
            var liveMode = (asOf === null);
            var calcDiff = gridShowsDiff && baseline;
            var rows = rawPositions ?
                rawPositions.filter(p => hideClosedPositions ? p.aggregatedPosition.openPosition !== 0 : true)
                    .sort((a, b) => getIns(a.aggregatedPosition.instrumentId)?.description < getIns(b.aggregatedPosition.instrumentId)?.description ? 1 : -1)
                    // .sort((a, b) => getIns(a.aggregatedPosition.instrumentId)?.type > getIns(b.aggregatedPosition.instrumentId)?.type ? 1 : -1)
                    // .sort((a, b) => getUltimateParent(a) - getUltimateParent(b))
                    .sort((a, b) => getMetaFromInstrument(getIns(a.aggregatedPosition.instrumentId), AttributionStr) > getMetaFromInstrument(getIns(b.aggregatedPosition.instrumentId), AttributionStr) ? 1 : -1)
                    .sort((a, b) => (isTreasury(b) ? 1 : -1) - (isTreasury(a) ? 1 : -1))
                    .sort((a, b) => (isCashFx(b.aggregatedPosition.instrumentId) ? 1 : -1) - (isCashFx(a.aggregatedPosition.instrumentId) ? 1 : -1))
                    .sort((a, b) => assetSort.indexOf(getMetaFromInstrument(getIns(a.aggregatedPosition.instrumentId), AssetStr)) - assetSort.indexOf(getMetaFromInstrument(getIns(b.aggregatedPosition.instrumentId), AssetStr)))
                    .map((pos: LivePosition, ix: number) => {
                        var ins = getIns(pos.aggregatedPosition.instrumentId);
                        var ulIns = getIns(getUltimateParent(pos));
                        var lastPrice = (liveMode ? (marketDataLiveStoreInstance.getTick(pos.aggregatedPosition.instrumentId) ?? pos.livePrice) : pos.livePrice) ?? pos.aggregatedPosition.averagePrice;
                        var bidPrice = (liveMode ? (marketDataLiveStoreInstance.getBidAsk(pos.aggregatedPosition.instrumentId)?.bid ?? pos.livePrice) : pos.livePrice) ?? pos.aggregatedPosition.averagePrice;
                        var askPrice = (liveMode ? (marketDataLiveStoreInstance.getBidAsk(pos.aggregatedPosition.instrumentId)?.ask ?? pos.livePrice) : pos.livePrice) ?? pos.aggregatedPosition.averagePrice;
                        var fxToBase = (liveMode && pos.unrealizedCcy !== "USD") ? (marketDataLiveStoreInstance.getFxRate(pos.unrealizedCcy) ?? pos.liveFxRateToBase) : pos.liveFxRateToBase;
                        var priceBaseline = blMap.get(getPositionKey(pos))?.livePrice ?? marketDataStoreInstance.getPrice(DefaultSetName, pos.aggregatedPosition?.instrumentId, baseline ?? (asOf ? moment(asOf).startOf('day').subtract(1, 'd')?.toDate() : moment().startOf('day').subtract(1, 'd').toDate()));
                        var openPositionInUnitsAbsolute = pos.aggregatedPosition.openPosition * ins?.multiplier;
                        var unRealizedPnL = openPositionInUnitsAbsolute * (lastPrice - pos.aggregatedPosition.averagePrice);
                        var unRealizedPnLBase = unRealizedPnL * fxToBase;
                        var rowDelta = 1;
                        var rowGamma = 0;
                        var rowVega = 0;
                        var openPositionInUnitsDeltaAdj = openPositionInUnitsAbsolute * rowDelta;
                        var deltaEquivBase = (openPositionInUnitsDeltaAdj * lastPrice * fxToBase);

                        if (ins?.type === "Option" && pos.aggregatedPosition.openPosition !== 0) {
                            var ulTick = liveMode ? (marketDataLiveStoreInstance.getTick(ins?.underlyingId) ?? pos.underlyingLivePrice) :
                                (pos.underlyingLivePrice ?? marketDataStoreInstance.getPrice(DefaultSetName, ins?.underlyingId, asOf));

                            if (ulTick) {
                                var tExp = moment(ins?.maturity).diff(moment(new Date()), "hour") / (365 * 24);
                                var vol = BlackImpliedVol(ulTick, Number(ins?.optionStrike), 0, tExp, lastPrice, Boolean(ins?.optionIsCall));
                                rowDelta = BlackDelta(ulTick, Number(ins?.optionStrike), 0, tExp, vol, Boolean(ins?.optionIsCall));
                                rowGamma = BlackGamma(ulTick, Number(ins?.optionStrike), 0, tExp, vol);
                                var vega = BlackVega(ulTick, Number(ins?.optionStrike), 0, tExp, vol);
                                rowVega = vega * Number(openPositionInUnitsAbsolute);

                                openPositionInUnitsDeltaAdj = openPositionInUnitsAbsolute * rowDelta;
                                deltaEquivBase = (openPositionInUnitsDeltaAdj * ulTick * fxToBase);
                            }
                        }
                        else {

                        }

                        var row = {
                            id: ix,
                            isLeaf: true,
                            nav: baseLineNav ?? undefined,
                            posKey: getPositionKey(pos),
                            orders: ordersStoreInstance.getOrdersForInstrument(ins?.listedInstrumentId).filter(o => o.orderVenue === "Pltfm").length === 0 ? undefined : ordersStoreInstance.getOrdersForInstrument(ins?.listedInstrumentId).filter(o => o.orderVenue === "Pltfm").length,
                            instrument: ins?.description?.replaceAll(ins?.type, ""),
                            instrumentId: pos.aggregatedPosition.instrumentId,
                            type: ins?.type,
                            underlying: ins?.underlying?.ticker,
                            ultimateUnderlying: ulIns?.description?.replaceAll(" Underlying Index", ""),
                            underlyingType: ins?.underlying?.type,
                            underlyingId: ins?.underlyingId,
                            ccy: pos.aggregatedPosition.realizedPnLCcy,
                            openPosition: pos.aggregatedPosition.openPosition - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.aggregatedPosition?.openPosition ?? 0)),
                            openPositionInUnits: (pos.aggregatedPosition.openPosition - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.aggregatedPosition?.openPosition ?? 0))) * ins?.multiplier,
                            openPositionInUnitsDeltaAdj: (pos.aggregatedPosition.openPosition - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.aggregatedPosition?.openPosition ?? 0))) * ins?.multiplier * rowDelta,
                            openPositionInUnitsAbsolute: openPositionInUnitsAbsolute,
                            averagePrice: pos.aggregatedPosition.averagePrice,
                            averagePriceBase: pos.aggregatedPosition.averagePriceBase,
                            averagePriceBuy: pos.aggregatedPosition.averagePriceBuy,
                            averagePriceSell: pos.aggregatedPosition.averagePriceSell,
                            quantityBought: pos.aggregatedPosition.quantityBought,
                            quantitySold: pos.aggregatedPosition.quantitySold,
                            averagePriceExBro: pos.aggregatedPosition.averagePriceExBro,
                            realizedPnL: pos.aggregatedPosition.realizedPnL - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.aggregatedPosition.realizedPnL ?? 0)),
                            r0: pos.aggregatedPosition.realizedPnL,
                            r0b: pos.aggregatedPosition.realizedPnLBase,
                            realizedPnLBase: (pos.aggregatedPosition.realizedPnLBase - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.aggregatedPosition.realizedPnLBase ?? 0))),
                            bbgTicker: getBbgTickerFromInstrument(ins),
                            multiplier: ins?.multiplier ?? 1.0,
                            strike: ins?.optionStrike,
                            isCall: ins?.optionIsCall,
                            numTrades: pos.aggregatedPosition.totalTrades - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.aggregatedPosition.totalTrades ?? 0)),
                            asset: getMetaFromInstrument(ins, AssetStr),
                            attribution: getMetaFromInstrument(ins, AttributionStr),
                            subAttribution: getMetaFromInstrument(ins, SubAttributionStr),
                            beta: getMetaFromInstrument(ins, BetaStr),
                            insMetaStr: getMetaFromInstrument(ins, InstrumentStr),
                            lastTraded: new Date(pos.aggregatedPosition.lastTraded),
                            firstTraded: new Date(pos.aggregatedPosition.firstTraded),
                            daysOpen: pos.aggregatedPosition.openPosition === 0 ? (moment(pos.aggregatedPosition.lastTraded).diff(moment(pos.aggregatedPosition.firstTraded), 'days')) : (moment(new Date()).diff(moment(pos.aggregatedPosition.firstTraded), 'days')),
                            unRealizedPnL: unRealizedPnL - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.unrealizedPnL ?? 0)),
                            unRealizedPnLBase: (unRealizedPnLBase - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.unrealizedPnLBase ?? 0))),
                            totalPnL: 0,
                            totalPnLBase: 0,
                            lastPrice: lastPrice,
                            bidPrice: bidPrice,
                            askPrice: askPrice,
                            fxToBase: fxToBase,
                            priceChangePct: 0,
                            priceBaseline: priceBaseline,
                            delta: rowDelta,
                            deltaEquivBase: (deltaEquivBase - (!calcDiff ? 0 : (blMap.get(getPositionKey(pos))?.deltaBaseEquiv ?? 0))),
                            gamma: rowGamma,
                            vega: rowVega,
                            stopLevel: pos.stops?.stopLevel,
                            stopLevelPct: NaNorZero(pos.aggregatedPosition.openPosition > 0 ? (pos.stops?.stopLevel / lastPrice - 1.0) : (lastPrice / pos.stops?.stopLevel - 1.0)) ?? 0,
                            stopLossAmountFromAvg: NaNorZero(pos.aggregatedPosition.openPosition * (pos.stops?.stopLevel - pos?.aggregatedPosition.averagePrice) * ins?.multiplier) ?? 0,
                            stopLossAmountFromAvgBase: 0,
                            stopLossAmount: NaNorZero(pos.aggregatedPosition.openPosition * (pos.stops?.stopLevel - lastPrice) * ins?.multiplier) ?? 0,
                            tpLevel: pos.stops?.tpLevel,
                            tpLevelPct: NaNorZero(pos.aggregatedPosition.openPosition > 0 ? (pos.stops?.tpLevel / lastPrice - 1.0) : (lastPrice / pos.stops?.tpLevel - 1.0)),
                            tpAmountFromAvg: NaNorZero(pos.aggregatedPosition.openPosition * (pos.stops?.tpLevel - pos?.aggregatedPosition.averagePrice) * ins?.multiplier),
                            tpAmountFromAvgBase: 0,
                            tpAmount: NaNorZero(pos.aggregatedPosition.openPosition * (pos.stops?.tpLevel - lastPrice) * ins?.multiplier),
                            tpAmountBase: 0,
                            stopLossAmountBase: 0,
                            book: getMetaFromPosition(pos, BookStr),
                            strategy: getMetaFromPosition(pos, StrategyStr),

                            costLcy: 0,
                            costUsd: 0,
                            valLcy: 0,
                            valUsd: 0,

                            expiry: ins?.maturity,
                            expiryDays: ins?.maturity ? moment(ins.maturity).diff(asOf ? moment(asOf) : moment(), "days") : undefined,

                            hardStopSize: _.sum(pos.stops?.marketStopOrders?.map(o => o.stopSizeContracts)),
                            hardStopAvgPrice: _.sum(pos.stops?.marketStopOrders?.map(o => o.stopSizeContracts * o.stopLevel)),
                            hardStopAmount: -_.sum(pos.stops?.marketStopOrders?.map(o => o.stopSizeContracts * ins.multiplier * (o.stopLevel - lastPrice))),
                            hardStopAmountBase: 0,
                        };

                        row.stopLossAmountFromAvgBase = NaNorZero(row.stopLossAmountFromAvg * fxToBase);
                        row.tpAmountFromAvgBase = NaNorZero(row.tpAmountFromAvg * fxToBase);

                        row.hardStopAmountBase = NaNorZero(row.hardStopAmount * fxToBase);
                        row.hardStopAvgPrice /= row.hardStopSize;

                        row.tpAmountBase = NaNorZero(row.tpAmount * fxToBase) ?? 0;
                        row.stopLossAmountBase = NaNorZero(row.stopLossAmount * fxToBase) ?? 0;

                        row.totalPnL = NaNorZero(row.unRealizedPnL ?? 0) + (row.realizedPnL ?? 0);
                        row.totalPnLBase = NaNorZero(row.unRealizedPnLBase ?? 0) + (row.realizedPnLBase ?? 0);

                        row.costLcy = row.averagePrice * row.openPositionInUnitsAbsolute;
                        row.costUsd = row.costLcy * row.fxToBase;
                        row.valLcy = row.lastPrice * row.openPositionInUnitsAbsolute;
                        row.valUsd = row.valLcy * row.fxToBase;

                        return row;
                    }) : new Array<GridRowData>();

            setRows(rows);
            //
        }

        function generateColumns(view: PositionView) {

            var columns = [...baseColDefs];

            if (view?.columnWidths) {
                Object.keys(view?.columnWidths).forEach(k => {
                    var col = columns.filter(c => c.field === k)[0];
                    if (col)
                        col.width = view.columnWidths[k];
                });
            }
            if (view?.columnsIndices) {
                columns = columns.sort((a, b) => view.columnsIndices[a.field] - view.columnsIndices[b.field]);
            }
            if (view?.columnVisibility) {
                Object.keys(view.columnVisibility).forEach(k => {
                    var col = columns.filter(c => c.field === k)[0];
                    if (col)
                        col.hide = !view.columnVisibility[k];
                });
            }

            setColumns(columns);
        }

        var defaultViewName = layoutStoreInstance.GetPreference(DefaultViewPrefName);
        var existingView = GetSessionState<PositionView>(viewKey)
        if ((!existingView || Object.keys(existingView).length === 0) && defaultViewName) {
            var cfg = userStoreInstance.getConfigsForType(PositionViewStr).filter(v => v.displayName === defaultViewName)[0];
            if (cfg) {
                existingView = JSON.parse(cfg?.blob) as PositionView;
                if (existingView) {
                    existingView.name = defaultViewName;
                    SetSessionState(viewKey, existingView)
                }
            }
        }

        generateColumns(existingView);
        generateRowData();

        var existingCols = apiRef?.current?.current?.getAllColumns();
        var hasExistingCols = existingCols.length > 0;
        if (hasExistingCols) {

            if (existingView && !initialized) {
                setActiveView(existingView);
                loadViewInner(existingView);
            }
        }

        var sub = eventSubscriptionMarketDataTicks.current;
        var sub2 = eventSubscriptionPositions.current;
        var sub3 = eventSubscriptionOrders.current;

        if (hasExistingCols && initialized === false)
            setInitialized(true);

        return function cleanup() {
            //acceleratorStoreInstance.removeAllListeners();
            if (sub && sub.remove)
                sub.remove();
            if (sub2 && sub2.remove)
                sub2.remove();
            if (sub3 && sub3.remove)
                sub3.remove();
        };

    }, [summary, baseline, baselineSummary, hideClosedPositions, asOf, gridShowsDiff, baselineMap, initialized, onChangeAsOfDate, onChangeBaselineDate, baseLineNav, groupByTree, pauseLiveUpdated, treeFields, baseColDefs])

    function renderViewOptions() {
        return userStoreInstance.getConfigsForType(PositionViewStr).sort((a,b)=>a.displayName>b.displayName?1:-1).map(c => { return { name: c.displayName, id: c.configId } as LoadItemSpec });
    }

    useEffect(() => {
        setViewOptions(renderViewOptions())
    }, [])

    useEffect(() => {
        return function cleanup() {
            if (activeView && Object.keys(activeView).length !== 0) {
                SetSessionState(viewKey, activeView);
            }
        }
    }, [activeView])

    const canTrade = userStoreInstance.UserHasRole("Trader");


    function renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = Number(params.value.toString());
            return <div>{num2String(val)}</div>
        }
        else
            return <div></div>;
    }

    function renderNumberCell2dp(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
        }
        else
            return <div></div>;
    }

    function renderCcyCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
        }
        else
            return <div></div>;
    }

    function renderPctCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{(val * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</div>
        }
        else
            return <div></div>;
    }

    function renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    function changeAsOfDate(date: moment.Moment) {
        activeView.asOf = date?.toDate();
        onChangeAsOfDate(date);
        setActiveView(activeView);
    }

    function changeBaselineDate(date: moment.Moment) {
        activeView.baseline = date?.toDate();
        onChangeBaselineDate(date);
        setActiveView(activeView);
    }

    function toggleSummary(visible: boolean) {
        if (activeView)
            activeView.showSummary = visible;
        setShowSummary(visible)
        setActiveView(activeView);
        //SetSessionState(viewKey, activeView)
    }

    function toggleTree(grouped: boolean) {
        if (activeView)
            activeView.treeView = grouped;
        setGroupByTree(grouped)
        setActiveView(activeView);
        //SetSessionState(viewKey, activeView)
    }

    function toggleDifference(showDiff: boolean) {
        if (activeView)
            activeView.showDifference = showDiff;
        setGridShowsDiff(showDiff)
        setActiveView(activeView);
        //SetSessionState(viewKey, activeView)
    }

    function closeMenu() {
        setMenuAnchor(null);
    }

    function closeMenuFav() {
        setMenuAnchorFav(null);
    }

    function resetSort() {
        setMenuAnchor(null);
        var model = [
            { field: "id", sort: "asc" }
        ] as GridSortItem[];
        apiRef.current.current.setSortModel(model);
    }

    function customToolbar(props: any) {
        var csvOptions = { allColumns: true, fileName: `${asOf ? moment(asOf).format("yyyy-MM-DD") : "Live"}¬PltfmPositions` } as GridCsvExportOptions;
        const treeIsUnique = true;// _.uniq(rows.map(r => r.path)).length === rows.length;
        if (!treeIsUnique) {
            console.log('Tree not unique ', rows.map(r => r.path));
        }
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer>
                    <Grid container spacing={0.5} style={{ padding: "5px" }} width="50%">
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={asOf}
                                onChange={changeAsOfDate}
                                disableFuture
                                minDate={EarliestDate}
                                emptyLabel="Live"
                                clearLabel="Live"
                                label={asOf ? "As-Of" : "Live"} />
                        </Grid>
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={baseline}
                                onChange={changeBaselineDate}
                                disableFuture
                                minDate={EarliestDate}
                                emptyLabel="(None)"
                                clearLabel="(None)"
                                label={baseline ? "Baseline" : "(No Base)"} />
                        </Grid>
                        <Grid item>
                            <Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" disabled={awaitingRefresh} startIcon={<RefreshOutlined />} onClick={() => onClickRefresh()}>Refresh</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                className="MuiButton-outlined PltfmButtonLite"
                                startIcon={<TuneOutlined />}
                                onClick={handleOptionsMenuClick}>
                                Options
                            </Button>
                            <Menu
                                key="posGridOptionMenu"
                                id="posGridOptionMenu"
                                anchorReference="anchorPosition"
                                anchorPosition={
                                    menuAnchor !== null
                                        ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX }
                                        : undefined
                                }
                                open={menuAnchor !== null} onClose={closeMenu}
                                classes={{ list: "GridOptionMenu" }}
                            >
                                <MenuItem><GridToolbarFilterButton {...props} fullWidth className="MuiButton-outlined PltfmButtonLite" /></MenuItem>
                                <MenuItem><GridToolbarDensitySelector {...props} fullWidth variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></MenuItem>
                                <MenuItem><GridToolbarColumnsButton {...props} fullWidth variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></MenuItem>
                                <MenuItem><Button
                                    variant="outlined"
                                    className="MuiButton-outlined PltfmButtonLite"
                                    size="small"
                                    fullWidth
                                    disabled={awaitingRefresh || showStatus}
                                    startIcon={<StarRateOutlined />}
                                    onClick={() => { setMenuAnchor(null); setShowStatus(!showStatus) }}>
                                    Report Status
                                </Button></MenuItem>
                                <MenuItem><Button
                                    variant="outlined"
                                    className="MuiButton-outlined PltfmButtonLite"
                                    size="small"
                                    fullWidth
                                    disabled={awaitingRefresh}
                                    startIcon={<SortOutlined />}
                                    onClick={() => resetSort()}>
                                    Reset Sorting
                                </Button></MenuItem>
                                {<MenuItem><Button
                                    variant="outlined"
                                    className="MuiButton-outlined PltfmButtonLite"
                                    size="small"
                                    fullWidth
                                    disabled={awaitingRefresh}
                                    startIcon={<AccountTreeOutlined />}
                                    onClick={() => { setMenuAnchor(null); setShowDefineTree(true); }}>
                                    Tree Definition
                                </Button></MenuItem>}
                                <MenuItem><FormControlLabel control={<Switch checked={hideClosedPositions} onChange={() => { sethideClosedPositions(!hideClosedPositions); closeMenu(); }} />} label="Hide Closed Positions" /></MenuItem>
                                <MenuItem><FormControlLabel control={<Switch checked={showSummary} onChange={() => { toggleSummary(!showSummary); closeMenu(); }} />} label="Show Summary Panel" /></MenuItem>
                                <MenuItem><FormControlLabel control={<Switch disabled={!treeIsUnique} checked={groupByTree} onChange={() => { toggleTree(!groupByTree); closeMenu(); }} />} label="Group Positions" /></MenuItem>
                                {baseLineNav ? <MenuItem><FormControlLabel control={<Switch checked={pnlInPctNav} onChange={() => { setPnlInPctNav(!pnlInPctNav); closeMenu(); }} />} label="Summary in %NAV" /></MenuItem> : null}
                                {baselineSummary ? <MenuItem><FormControlLabel control={<Switch checked={gridShowsDiff} onChange={() => { toggleDifference(!gridShowsDiff); closeMenu(); }} />} label="Show Difference To Baseline" /></MenuItem> : null}
                                <MenuItem><FormControlLabel control={<Switch checked={showFxAsPosition} onChange={() => { onChangeShowFxAsPosition(!showFxAsPosition); closeMenu(); }} />} label="Show FX as Position" /></MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item><GridToolbarExport {...props} size="small" variant="outlined" className="MuiButton-outlined PltfmButtonLite" csvOptions={csvOptions} /></Grid>
                    </Grid>
                    <Grid container spacing={0.5} style={{ padding: "5px" }} width="50%" justifyContent={"flex-end"}>
                        {!treeIsUnique && <Grid item><Tooltip title={<Typography variant="subtitle1">Grouping not unique</Typography>}><IconButton><WarningAmberOutlined /></IconButton></Tooltip></Grid>}
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                className="MuiButton-outlined PltfmButtonLite"
                                startIcon={<FavoriteOutlined />}
                                onClick={handleFavouritesMenuClick}>
                                Favourites
                            </Button>
                            <Menu
                                key="posGridOptionMenu"
                                id="posGridOptionMenu"
                                anchorReference="anchorPosition"
                                anchorPosition={
                                    menuAnchorFav !== null
                                        ? { top: menuAnchorFav.mouseY, left: menuAnchorFav.mouseX }
                                        : undefined
                                }
                                open={menuAnchorFav !== null} onClose={closeMenuFav}
                                classes={{ list: "GridOptionMenu" }}>
                                {_.range(0, maxFavourites).filter((f, ix) => getFavouriteNameForId(ix)).map((ix) => <MenuItem key={`posFavOpt` + ix} ><Button
                                    variant="outlined"
                                    className="MuiButton-outlined PltfmButtonLite"
                                    size="small"
                                    fullWidth
                                    disabled={awaitingRefresh}
                                    onClick={() => { onLoadView(getViewForFavouriteId(ix)?.configId); closeMenuFav(); }}>
                                    {getFavouriteNameForId(ix) ?? "(Empty)"}
                                </Button></MenuItem>)}
                            </Menu>
                        </Grid>
                        {acceleratorStoreInstance.getConnectionStatus() === ConnectionStatus.Connected && <Grid item>
                            <Tooltip title={<Typography variant="subtitle1">{(pauseLiveUpdated ? "Start" : "Pause") + " Live Price Feed"}</Typography>}>
                                <Button
                                    size="small"
                                    className="MuiButton-outlined PltfmButtonLite"
                                    onClick={() => setPauseLiveUpdates(!pauseLiveUpdated)}
                                    startIcon={pauseLiveUpdated ? <PlayCircleFilledOutlined /> : <PauseCircleFilledOutlined />}>
                                    {pauseLiveUpdated ? "Start" : "Pause"}
                                </Button>
                            </Tooltip>
                        </Grid>}
                        <Grid item><Button
                            size="small"
                            className="MuiButton-outlined PltfmButtonLite"
                            onClick={() => setShowSaveView(true)}
                            startIcon={<SaveOutlined />}>
                            Views
                        </Button></Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    function onLoadView(id: number) {
        const toLoad = userStoreInstance.getConfigsForType(PositionViewStr).find(t => t.configId === id);
        if (toLoad) {
            var view = JSON.parse(toLoad?.blob) as PositionView;
            if (view) {
                view.name = toLoad.displayName;
                loadViewInner2(view);
            }
        }
    }

    function loadViewInner2(view: PositionView) {
        if (view) {
            setActiveView(view);
            var availableColumns = apiRef.current.current.getAllColumns().map(x => x.field);

            if (apiRef.current.current.getSortModel())
                apiRef.current.current.setSortModel([]);

            if (view.gridState)
                apiRef.current.current.restoreState(view.gridState);
            else {
                if (view.columnWidths) {
                    Object.keys(view?.columnWidths).filter(x => availableColumns.includes(x)).forEach(k => {
                        apiRef.current.current.setColumnWidth(k, view.columnWidths[k]);
                    });
                }
                if (view.columnsIndices) {
                    Object.keys(view.columnsIndices).filter(x => availableColumns.includes(x)).forEach(k => {
                        apiRef.current.current.setColumnIndex(k, view.columnsIndices[k]);
                    });
                }
                if (view.columnVisibility) {
                    Object.keys(view.columnVisibility).filter(x => availableColumns.includes(x)).forEach(k => {
                        apiRef.current.current.setColumnVisibility(k, view.columnVisibility[k]);
                    });
                }
            }

            if (view.datesAreRelative) {
                var today = moment().startOf('day');
                onChangeAsOfDate(view.asOfRelative !== undefined ? addWeekDays(today, view.asOfRelative) : null);
                onChangeBaselineDate(view.baselineRelative !== undefined ? addWeekDays(today, view.baselineRelative) : null);
            }
            else {
                onChangeAsOfDate(view.asOf ? moment(view.asOf) : null);
                onChangeBaselineDate(view.baseline ? moment(view.baseline) : null);
            }

            if (view.showSummary !== undefined)
                setShowSummary(view.showSummary)
            if (view.showDifference !== undefined)
                setGridShowsDiff(view.showDifference)



            if (view.density)
                apiRef.current.current.setDensity(view.density);
            else
                apiRef.current.current.setDensity(userStoreInstance.GetGridDensity());

            if (view.treeDefinition)
                setTreeFields(view.treeDefinition);
            else
                setTreeFields(defaultTree);

            if (view.treeView)
                setGroupByTree(view.treeView);
            else
                setGroupByTree(false);

            SetSessionState(viewKey, view);
        }
    }

    function renderStatusPage() {
        var ages = summary?.positionStatus?.quoteAges;
        if (ages) {
            var insIds = Array.from(Object.keys(ages));
            var insAgeRecords = insIds.map(i => { return { age: ages[Number(i)], description: listedInstrumentStoreInstance.getInstrumentById(Number(i)).description } });
            var noPrice = insAgeRecords.filter(x => x.age === null);
            var withPrice = insAgeRecords.filter(x => x.age !== null);//.sort((a, b) => moment(a.age).valueOf() - moment(b.age).valueOf());
            return <div style={{ display: "flex", flexDirection: "row", marginTop: "65px", padding: "5px" }}>
                {noPrice.length > 0 ? <div>
                    <Typography>No prices found for</Typography>
                    {noPrice.map(i => <div>{i.description}</div>)}
                </div> : null}
                {withPrice.length > 0 ? <div>
                    <Typography>With prices</Typography>
                    {withPrice.map(i => <div> {i.description} - {renderDate(i.age)} </div>)}
                </div> : null}
                {summary.fxRatesToBase ? <div>
                    <Typography>Fx Rates</Typography>
                    {Object.keys(summary.fxRatesToBase).map(k => <div> {k} - {summary.fxRatesToBase[k].toPrecision(6)} </div>)}
                </div> : null}
            </div>
        }
        else
            return null;
    }

    async function onSaveView(name: string, category: string, isPublic: boolean, datesRelative: boolean) {
        if (name) {
            var existing = userStoreInstance.getConfigsForType(PositionViewStr).filter(t => t.displayName === name)[0];

            activeView.name = name;
            activeView.datesAreRelative = datesRelative;

            var today = moment().startOf('day');
            activeView.referenceDate = today.toDate();
            if (datesRelative) {
                activeView.asOfRelative = asOf ? numberOfWeekDays(asOf, today) : undefined;
                activeView.baselineRelative = baseline ? numberOfWeekDays(baseline, today) : undefined;
            }

            var blob = {
                displayName: name,
                objectType: PositionViewStr,
                ownerId: userStoreInstance.GetUserInfo().userId,
                configId: existing?.configId,
                lastUpdatedUtc: moment.utc().toDate()
            } as ConfigBlob;
            blob.blob = JSON.stringify(activeView);
            await AddOrUpdateConfig(blob);
            setViewOptions(renderViewOptions());
            setShowSaveView(false);
        }
    }

    async function onDeleteView(id: number) {
        const toDelete = userStoreInstance.getConfigsForType(PositionViewStr).filter(t => t.configId === id)[0];
        if (toDelete) {
            await DeleteConfig(toDelete);
            await GetConfigsForType(PositionViewStr);
        }
    }

    function onSetDefaultView(id: number) {
        const viewToDefault = userStoreInstance.getConfigsForType(PositionViewStr).filter(t => t.configId === id)[0];
        if (viewToDefault) {
            UpdatePreference(DefaultViewPrefName, viewToDefault.displayName);
        }
    }

    function isDefaultView(id: number) {
        var defaultViewName = layoutStoreInstance.GetPreference(DefaultViewPrefName);
        return userStoreInstance.getConfigsForType(PositionViewStr).find(t => t.configId === id)?.displayName === defaultViewName;
    }

    function getFavouriteNameForId(id: number) {
        return getViewForFavouriteId(id)?.displayName;
    }

    function getFavouriteIdForViewId(id: number) {
        for (var i = 0; i <= maxFavourites; i++) {
            if (getViewForFavouriteId(i)?.configId === id)
                return i;
        }
        return undefined;
    }

    function getViewForFavouriteId(favId: number) {
        var viewId = layoutStoreInstance.GetPreference(`${PositionViewFavStr}-${favId}`);
        if (viewId === null || viewId === undefined)
            return undefined;
        return userStoreInstance.getConfigsForType(PositionViewStr).find(v => v.configId.toString() === viewId);
    }

    async function onSetFavourite(id: number, favIx: number) {
        UpdatePreference(`${PositionViewFavStr}-${favIx}`, id.toString());
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const handleOptionsMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        //setPauseLiveUpdates(menuAnchor == null ? true : false);
        setMenuAnchor(
            menuAnchor === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const handleFavouritesMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        //setPauseLiveUpdates(menuAnchor == null ? true : false);
        setMenuAnchorFav(
            menuAnchorFav === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    function onGridStateUpdate(state?: any, event?: any, details?: any) {
        if (initialized) {
            var gridState = apiRef.current.current.exportState();
            activeView.gridState = gridState;
            var anyChange = true;
            activeView.showSummary = showSummary;
            activeView.showDifference = gridShowsDiff;

            if (!Boolean(activeView?.columnWidths))
                activeView.columnWidths = {};
            if (!Boolean(activeView?.columnsIndices))
                activeView.columnsIndices = {};
            if (!Boolean(activeView?.columnVisibility))
                activeView.columnVisibility = {};

            var allCols = apiRef.current.current.getAllColumns();

            for (var i = 0; i < allCols.length; i++) {
                var col = allCols[i];
                if (activeView.columnWidths[col.field] !== col.width) {
                    anyChange = true;
                    activeView.columnWidths[col.field] = col.width;
                }
                if (activeView.columnsIndices[col.field] !== i) {
                    anyChange = true;
                    activeView.columnsIndices[col.field] = i;
                }
                if (gridState?.columns?.columnVisibilityModel && gridState.columns.columnVisibilityModel[col.field] !== undefined && activeView?.columnVisibility && activeView.columnVisibility[col.field] !== (gridState.columns.columnVisibilityModel[col.field])) {
                    anyChange = true;
                    activeView.columnVisibility[col.field] = gridState.columns?.columnVisibilityModel[col.field];
                }
            }

            if (activeView.density !== apiRef.current.current.state.density.value) {
                anyChange = true;
                activeView.density = apiRef.current.current.state.density.value;
            }

            if (anyChange) {
                setActiveView(activeView);
                //SetSessionState(viewKey, activeView)
            }
        }
    }

    function checkViewItemExists(name: string) {
        return userStoreInstance.getConfigsForType(PositionViewStr).some(x => x.displayName === name);
    }

    function setTreeDefinition(fields: string[]) {
        const fieldNames = fields.map(t => columns.filter(c => c.headerName === t)[0]?.field);
        setTreeFields(fieldNames);
        activeView.treeDefinition = fieldNames;
        setActiveView(activeView);
        //SetSessionState(viewKey, activeView)
    }

    if (baselineMap.size === 0 && baselineSummary?.positions?.length > 0 && gridShowsDiff && baselineSummary) {
        const blMap = new Map<string, LivePosition>();
        if (gridShowsDiff) {
            baselineSummary?.positions?.forEach(p => {
                blMap.set(getPositionKey(p), p);
            });
        }
        setBaselineMap(blMap);
    }
    else if (baselineMap.size > 0 && !gridShowsDiff) {
        setBaselineMap(new Map<string, LivePosition>());
    }

    function customFooter() {
        return <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}><Typography variant='h6' color="red">Awaiting NAV data...</Typography></div>
    }

    const summaryHeight = !showSummary || summary === null ? 0 : 250;
    const theme = userStoreInstance.GetTheme();

    const aggregationModel: any = {};
    groupSumColumns.forEach(element => {
        if(activeView?.columnVisibility && activeView.columnVisibility[element])
            aggregationModel[element] = "sum";
    });

    return (
        <div className="PositionSummaryTab">
            <Dialog fullScreen
                open={showStatus}
                classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                onClose={() => setShowStatus(false)}>
                <AppBar className="LayoutTopAppBar">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setShowStatus(false)}
                            aria-label="close"
                            size="large">
                            <CloseOutlined />
                        </IconButton>
                        <Typography variant="h6">Report Status</Typography>
                    </Toolbar>
                </AppBar>
                <div>{showStatus ? renderStatusPage() : null}</div>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="md"
                open={showDefineTree}
                onClose={() => setShowDefineTree(false)}>
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: theme.background_color_opaque, color: theme.color, borderBottom: "1px solid", borderColor: theme.border_color }}>
                    <Typography variant="h6">Grouping Tree Definition</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowDefineTree(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ backgroundColor: theme.background_color_opaque, color: theme.color, display: "flex", justifyContent: "center", width: "100%" }}>
                    {showDefineTree && <div style={{ paddingTop: "1em", backgroundColor: theme.background_color }}>
                        <TransferList
                            chosen={treeFields.map(t => columns.filter(c => c.field === t)[0]?.headerName)}
                            choices={[...columns].sort((a, b) => a.headerName > b.headerName ? 1 : -1).map(x => x.headerName)}
                            onChange={(chosen: string[]) => { setTreeDefinition(chosen); }}
                            onClose={() => setShowDefineTree(false)}
                        />
                    </div>}
                </DialogContent>
            </Dialog>
            <SaveLoadDeleteBox
                title='Load / Save Views'
                showBox={showSaveView}
                showDatesAsRelative
                onClose={() => setShowSaveView(false)}
                onDelete={onDeleteView}
                onLoad={onLoadView}
                onSave={onSaveView}
                onSetDefault={onSetDefaultView}
                isDefault={isDefaultView}
                onUpdate={(id, name, category, isPublic) => { }}
                options={viewOptions}
                onSetFavourite={onSetFavourite}
                getFavouriteNameForId={getFavouriteNameForId}
                getFavouriteId={getFavouriteIdForViewId}
                checkItemExists={checkViewItemExists} />

            {editOrdersId !== undefined || positionToClose !== undefined ? <OrdersBox
                showBox={editOrdersId !== undefined || positionToClose !== undefined}
                instrumentId={editOrdersId}
                positionToClose={positionToClose}
                book={orderMeta[0]}
                strategy={orderMeta[1]}
                onClose={() => { setEditOrdersId(undefined); setPositionToClose(undefined); handleClose() }} /> : null}

            {editSlTpId !== undefined ? <SlTpBox
                key={"sltp--" + editSlTpId}
                showBox={editSlTpId !== undefined}
                positionKey={editSlTpId}
                onClose={() => { setEditSlTpId(undefined); handleClose() }} /> : null}

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container direction="column">
                        {/* {!showSummary || positionSummary === null ? null : RenderSummary(positionSummary, baseLinePositionSummary)} */}
                        {!showSummary || summary === null ? null : <PositionLiveSummary summary={summary} baselineSummary={baselineSummary} asOf={asOf} summaryHeight={summaryHeight} baseNav={baseLineNav} pnlInPctNav={pnlInPctNav} />}
                        <Grid item style={{ height: `Calc(100% - ${summaryHeight}px)` }}>
                            <DataGridPremium
                                className="PositionSummaryTabTable"
                                initialState={{
                                    columns: { columnVisibilityModel: activeView?.columnVisibility },
                                    aggregation: { model: aggregationModel }
                                }}
                                rows={rows}
                                columns={columns}
                                hideFooter={baseLineNav !== undefined}
                                density={userStoreInstance.GetGridDensity()}
                                components={{
                                    Toolbar: customToolbar,
                                    LoadingOverlay: (() => loading(jobUpdateMessage)),
                                    Footer: customFooter
                                }}
                                classes={{
                                    panelWrapper: "GridFilterForm",
                                    panelContent: "GridFilterForm",
                                    panel: "GridFilterForm",
                                    filterForm: "GridFilterForm",
                                    columnsPanel: "GridFilterForm",

                                }}
                                componentsProps={{
                                    row: {
                                        onContextMenu: handleContextMenu,
                                        style: { cursor: 'context-menu' },
                                    },
                                    columnsPanel: {
                                        sort: 'asc'
                                    }
                                }}
                                throttleRowsMs={pauseLiveUpdated ? 10000 : userStoreInstance.GetLiveThrottleMs()}
                                loading={awaitingRefresh}
                                apiRef={apiRef.current}
                                onStateChange={onGridStateUpdate}
                                disableRowGrouping={!groupByTree}
                                rowGroupingModel={treeFields}
                                rowGroupingColumnMode="multiple"
                                experimentalFeatures={{ aggregation: true, }}
                            />
                            <Menu
                                open={contextMenu !== null}
                                onClose={handleClose}
                                anchorReference="anchorPosition"
                                anchorPosition={
                                    contextMenu !== null
                                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                        : undefined
                                }
                                componentsProps={{
                                    root: {
                                        onContextMenu: (e) => {
                                            e.preventDefault();
                                            handleClose();
                                        },
                                    },
                                }}
                                classes={{ list: "GridOptionMenu" }}>
                                <MenuItem disabled>{rows.filter(x => x.id === selectedRow)[0]?.instrument}</MenuItem>
                                <MenuItem onClick={() => setEditSlTpId(rows.filter(x => x.id === selectedRow)[0]?.posKey)}>Update SL/TP</MenuItem>
                                {canTrade && <MenuItem
                                    onClick={() => {
                                        var activeRow = rows.find(x => x.id === selectedRow);
                                        setOrderMeta([activeRow.book, activeRow.strategy]);
                                        setPositionToClose(positionStoreInstance.getPositionForInstrument(null, activeRow?.instrumentId));
                                        setEditOrdersId(rows.filter(x => x.id === selectedRow)[0]?.instrumentId);
                                    }}>
                                    Close Position
                                </MenuItem>}
                                {canTrade && <MenuItem
                                    onClick={() => {
                                        var activeRow = rows.find(x => x.id === selectedRow);
                                        setOrderMeta([activeRow.book, activeRow.strategy]);
                                        setPositionToClose(undefined);
                                        setEditOrdersId(rows.filter(x => x.id === selectedRow)[0]?.instrumentId);
                                    }}>
                                    Orders
                                </MenuItem>}
                                {/* {rows.filter(x => x.id === selectedRow)[0]?.orders > 0 ? 
                                <MenuItem 
                                onClick={() => {
                                    setEditOrdersId(rows.filter(x => x.id === selectedRow)[0]?.instrumentId)}}>Open Orders</MenuItem> : null} */}
                            </Menu>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}