import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { NewQwackRiskEvent, NewQwackRiskEventError } from './riskActions';
import moment from 'moment';
import { RiskStoreState } from './riskModels';

class RiskStore extends FluxStore<RiskStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            qwackResults: {},
            errors: {}
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewQwackRiskEvent) {
            const { payload } = action;

            var key = payload.cacheKey ?? (payload.startDate ?
                `${moment(payload.startDate).format("yyyy-MM-DD")}-${moment(payload.valDate).format("yyyy-MM-DD")}-${payload.set ? payload.set : "default"}-${payload.metric}` :
                `${moment(payload.valDate).format("yyyy-MM-DD")}-${payload.set ? payload.set : "default"}-${payload.metric}`);

            this.state.qwackResults[key] = payload.cube;
            this.state.errors[key] = undefined;
            this.emitChange();
        }
        else if (action instanceof NewQwackRiskEventError) {
            this.state.errors[action.payload] = action.payload;
            this.emitChange();
        }
    }

    getQwackRisk(date: Date, setName: string = undefined, metric: string = "PV") {
        var key = `${moment(date).format("yyyy-MM-DD")}-${setName ? setName : "default"}-${metric}`;
        return this.state.qwackResults[key];
    }
    getQwackPnLAttribution(startDate: Date, endDate: Date, setName: string = undefined) {
        var key = `${moment(startDate).format("yyyy-MM-DD")}-${moment(endDate).format("yyyy-MM-DD")}-${setName ? setName : "default"}-PnLAttribution`;
        return this.state.qwackResults[key];
    }
    getQwackRiskError(date: Date, setName: string = undefined, metric: string = "PV") {
        var key = `${moment(date).format("yyyy-MM-DD")}-${setName ? setName : "default"}-${metric}`;
        return this.state.errors[key];
    }
    getQwackLadder(date: Date, assetId: number, setName: string = undefined, metric: string = "QwackGamma") {
        var key = `${moment(date).format("yyyy-MM-DD")}-${setName ? setName : "default"}-${metric}-${assetId}`;
        return this.state.qwackResults[key];
    }
}

const riskStoreInstance = new RiskStore(AppDispatcher);
export default riskStoreInstance;