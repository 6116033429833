import { Button, Typography } from '@mui/material';
import { AccountBalanceWalletOutlined, AppsOutlined, AutoGraphOutlined, EuroSymbolOutlined, FormatAlignCenterOutlined, JoinLeftOutlined, LocationSearchingOutlined, QuestionMarkOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import { DeltaGammaView } from './deltaGammaView';
import { FxView } from './fxView';
import { GammaLadderView } from './gammaLadderView';
import { QwackPnLTable } from './qwackPnLTable';
import { QwackRiskTable } from './qwackRiskTable';
import { VegaThetaView } from './vegaThetaView';
import layoutStoreInstance from '../layout/layoutStore';
import { VaRView } from './varView';
import { BetaView } from './betaView';
import { RiskMatrixView } from './riskMatrixView';
import { WhatIfView } from './whatIfView';

interface RiskTabState {
    currentMode: RiskTabMode,
    collapsed: boolean
}

enum RiskTabMode {
    None,
    Risk,
    PnL,
    DeltaGamma,
    Vega,
    Fx,
    GammaLadder,
    RiskMatrix,
    VaR,
    Beta,
    WhatIf
}

export interface RiskTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class RiskTab extends React.Component<RiskTabProps, RiskTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: RiskTabProps) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: RiskTabMode = tabModeStr ? RiskTabMode[tabModeStr] : RiskTabMode.None;
        this.state = {
            currentMode: tabMode,
            collapsed: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case RiskTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case RiskTabMode.PnL:
                return <QwackPnLTable {...this.props} />
            case RiskTabMode.Risk:
                return <QwackRiskTable {...this.props} />
            case RiskTabMode.DeltaGamma:
                return <DeltaGammaView {...this.props} />
            case RiskTabMode.Vega:
                return <VegaThetaView {...this.props} />
            case RiskTabMode.Fx:
                return <FxView {...this.props} />
            case RiskTabMode.GammaLadder:
                return <GammaLadderView {...this.props} />
            case RiskTabMode.VaR:
                return <VaRView {...this.props} />
            case RiskTabMode.Beta:
                return <BetaView {...this.props} />
            case RiskTabMode.RiskMatrix:
                return <RiskMatrixView {...this.props} />
            case RiskTabMode.WhatIf:
                return <WhatIfView {...this.props} />
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: RiskTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: RiskTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }


    onClick(mode: RiskTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: RiskTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>


                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.Risk, <LocationSearchingOutlined />, "Risk")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.PnL, <AccountBalanceWalletOutlined />, "PnL")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.DeltaGamma, <Typography variant="subtitle1">Δ</Typography>, "Delta")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.Vega, <Typography variant="subtitle1">ν</Typography>, "Vega")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.Fx, <EuroSymbolOutlined />, "Fx")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.GammaLadder, <FormatAlignCenterOutlined />, "Ladder")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.RiskMatrix, <AppsOutlined />, "Matrix")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.VaR, <JoinLeftOutlined />, "VaR")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.Beta, <AutoGraphOutlined />, "Beta")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(RiskTabMode.WhatIf, <QuestionMarkOutlined />, "WhatIf")}
                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}