import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, CircularProgress, Grid, GridProps, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { ComputeBetaAnalysis, ComputeBetaAnalysisMulti } from './riskActions';
import { BetaAnalysisResult } from './riskModels';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { TabContext, TabPanel } from '@mui/lab';
import { GridCellParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import userStoreInstance from '../user/userStore';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import InstrumentSelection from '../inputs/instrumentSelection';
import _ from 'lodash';
import { InstrumentMultiSelection } from '../inputs/instrumentMultiSelection';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';

interface BetaViewState {
    beta: BetaAnalysisResult;
    ins: ListedInstrument;
    insMulti: ListedInstrument[];
    betaMulti: { [insId: number]: BetaAnalysisResult };
    waiting: boolean;
    windowSize: string;
    selectedTabId: number;
}

export interface BetaViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class BetaView extends React.Component<BetaViewProps, BetaViewState>{
    eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
    constructor(props: BetaViewProps) {
        super(props)
        this.state = {
            beta: undefined,
            waiting: false,
            ins: undefined,
            insMulti: undefined,
            betaMulti: undefined,
            windowSize: "1y",
            selectedTabId: 0
        };;
        this.calcBeta = this.calcBeta.bind(this);
        this.calcBetaMulti = this.calcBetaMulti.bind(this);
    }

    async componentDidMount() {
    }

    async componentWillUnmount() {
    }

    async calcBeta() {
        if (this.state.ins) {
            this.setState({ beta: undefined, waiting: true });
            const beta = await ComputeBetaAnalysis(this.state.ins?.listedInstrumentId, this.state.windowSize);
            this.setState({ beta: beta, waiting: false });
        }
    }


    async calcBetaMulti() {
        if (this.state.insMulti) {
            this.setState({ betaMulti: undefined, waiting: true });
            const beta = await ComputeBetaAnalysisMulti(this.state.insMulti.map(i => i.listedInstrumentId), this.state.windowSize);
            this.setState({ betaMulti: beta, waiting: false });
        }
    }

    customToolbar(props: any) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container justifyContent="space-between" direction="row" style={{ paddingTop: "5px" }}>
                            <Grid item container spacing={1} justifyContent="flex-end" style={{ width: "50%" }}>
                                <Grid item>
                                    <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                                <Grid item>
                                    <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    renderControls() {
        const { beta, waiting, windowSize, ins } = this.state;
        const gridItemProps = { item: true, sm: 6, xl: 6, alignContent: "center", justifyContent: "center" }; //height:"calc(40vh - 100px)"

        var scale = Math.max(_.max(beta?.benchmarkReturns?.map(x => Math.abs(x))), _.max(beta?.portfolioReturns?.map(x => Math.abs(x)))) * 1.1;
        const chartData = [
            {
                name: "VaR",
                x: beta?.benchmarkReturns,
                y: beta?.portfolioReturns,
                type: 'scatter',
                mode: 'markers',
            },
        ] as Data[];

        var chartLayout: Partial<Layout> = {
            shapes: [{
                type: "line",
                name: "Regression",
                x0: -scale,
                x1: scale,
                y0: beta?.lrResult?.alpha + beta?.lrResult?.beta * -scale,
                y1: beta?.lrResult?.alpha + beta?.lrResult?.beta * scale,
                line: {
                    color: "red"
                }
            }],
            title: {
                text: "Portfolio vs Benchmark Returns",
                font: { color: userStoreInstance.GetTheme().color, family: userStoreInstance.GetTheme().font_family },
                yanchor: "top",
                yref: "paper",
                y: 0.95,

            },
            showlegend: false,
            dragmode: false,
            plot_bgcolor: userStoreInstance.GetTheme().background_color,
            paper_bgcolor: userStoreInstance.GetTheme().background_color,
            xaxis: {
                title: "Benchmark",
                range: [-scale, scale],
                rangeslider: {
                    visible: false
                },
                tickformat: "~%",
                linecolor: userStoreInstance.GetTheme().contrastBorderColorLight,
                tickfont: { color: userStoreInstance.GetTheme().color }
            },
            yaxis: {
                title: "Portfolio",
                linecolor: userStoreInstance.GetTheme().contrastBorderColorLight,
                tickfont: { color: userStoreInstance.GetTheme().color },
                range: [-scale, scale],
                tickformat: "~%",
            },
            margin: {
                pad: 1,
                l: 50,
                r: 10,
                t: 10,
                b: 50,
            },
        };

        const boxProps = { border: "1px solid", borderColor: userStoreInstance.GetTheme().contrastBorderColorLight, borderRadius: "7px" } as Partial<GridProps>;
        return (<ThemeProvider theme={getFormTheme()}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-start", width: "calc(100% - 10px)", height: "calc(100% - 10px)" }}>
                <Grid container spacing={2} justifyContent="flex-start" alignContent="flex-start" direction="column" columns={{ xs: 1, sm: 1, md: 1, l: 1, xl: 1 }} >
                    <Grid container item spacing={2} justifyContent="flex-start" alignContent="center" direction="row">
                        <Grid item><InstrumentSelection listedInstrumentId={ins?.listedInstrumentId} onInstrumentSelect={(i) => this.setState({ ins: i })} filterType={"Index"} /></Grid>
                        <Grid item><TextField size="small" style={{ width: "150px" }} value={windowSize} label="History Length" onChange={(e) => this.setState({ windowSize: e.target.value })} /></Grid>
                        <Grid item><Button variant="outlined" onClick={this.calcBeta} disabled={waiting}>Calculate</Button></Grid>
                    </Grid>
                    {waiting ? <Grid container item justifyContent="center" ><Grid item><CircularProgress /></Grid></Grid> :
                        beta ? <Grid container item spacing={2} height="calc(100vh - 250px)">
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>Alpha</Typography></Grid>
                                <Grid item><Typography align='center' variant='h4'>{(beta?.lrResult?.alpha * 100)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}%</Typography></Grid>
                            </Grid>
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>Beta</Typography></Grid>
                                <Grid item><Typography align='center' variant='h4'>{beta?.lrResult?.beta?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography></Grid>
                            </Grid>
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>R<sup>2</sup></Typography></Grid>
                                <Grid item><Typography align='center' variant='h4'>{beta?.lrResult?.r2?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography></Grid>
                            </Grid>
                            <Grid container item direction="column" {...gridItemProps} >
                                <Grid item {...boxProps} >
                                    <Plot
                                        data={chartData}
                                        layout={chartLayout}
                                        config={{ responsive: true, displaylogo: false, autosizable: false, fillFrame: false, showAxisDragHandles: false, displayModeBar: false }}
                                        style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                        useResizeHandler={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> : null}
                </Grid>
            </div>
        </ThemeProvider>)
    }

    renderCcyCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
        }
        else
            return <div></div>;
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    renderControlsMulti() {
        const { betaMulti, waiting, windowSize, insMulti } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-start", width: "calc(100% - 10px)", height: "calc(100% - 10px)" }}>
                <Grid container spacing={2} justifyContent="flex-start" alignContent="flex-start" direction="row" width="100%">
                    <Grid container item spacing={2} justifyContent="flex-start" alignContent="flex-start" direction="column" height="100%" width="25%">
                        <Grid item><Button variant="outlined" onClick={this.calcBetaMulti} disabled={waiting}>Calculate</Button></Grid>
                        <Grid item><TextField size="small" style={{ width: "150px" }} value={windowSize} label="History Length" onChange={(e) => this.setState({ windowSize: e.target.value })} /></Grid>
                        <Grid item><InstrumentMultiSelection listedInstrumentIds={insMulti?.map(i => i.listedInstrumentId)} onInstrumentSelect={(i) => this.setState({ insMulti: i })} filterType={"Index"} /></Grid>
                    </Grid>
                    {waiting ? <Grid container item justifyContent="center" width="75%"><Grid item><CircularProgress /></Grid></Grid> :
                        betaMulti ? <Grid item container height="100%" width="75%">
                            <Grid item>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: "bold" }}>Instrument</TableCell>
                                                <TableCell style={{ fontWeight: "bold" }}>Alpha</TableCell>
                                                <TableCell style={{ fontWeight: "bold" }}>Beta</TableCell>
                                                <TableCell style={{ fontWeight: "bold" }}>R<sup>2</sup></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(betaMulti).map((k) => <TableRow key={`betaMultiRow${k}`}>
                                                <TableCell>{listedInstrumentStoreInstance.getInstrumentById(Number(k))?.description}</TableCell>
                                                <TableCell>{(betaMulti[k].lrResult.alpha * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</TableCell>
                                                <TableCell>{betaMulti[k].lrResult.beta.toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
                                                <TableCell>{betaMulti[k].lrResult.r2.toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
                                            </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid> : null}
                </Grid>
            </div>
        </ThemeProvider>)
    }

    render() {
        const { selectedTabId } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabBetaCalc"}
                        component={Paper}
                        id={"tabBetaCalc"}
                        label="Single" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabBetaCalcM"}
                        component={Paper}
                        id={"tabBetaCalcM"}
                        label="Multi" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabB1"}
                    value={"0"}
                    //index={0}
                    children={this.renderControls()} />
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabB2"}
                    value={"1"}
                    //index={0}
                    children={this.renderControlsMulti()} />
            </TabContext>
        </ThemeProvider>
        );
    }
}
