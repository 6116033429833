import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { LoadNews, NewsLoadedEvent, NewsUpdatedEvent } from './newsTickerActions';
import { NewsTickerState } from './newsTickerModels';

class NewsTickerStore extends FluxStore<NewsTickerState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            newsItems: []
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewsLoadedEvent) {
            const { payload } = action;
            this.state = { newsItems: payload };
            this.emitChange();
        }
        if(action instanceof NewsUpdatedEvent){
            const {payload} = action;
            const item = this.state.newsItems.findIndex(e => e.uniqueKey === payload.uniqueKey);
            if(item === -1)
            {
                this.state.newsItems.push(payload);
            }
            else
            {
                this.state.newsItems[item] = payload;
            }
            this.emitChange();
        }
    }

    getNewsItems() {
        return this.state.newsItems;
    }

    async refreshNews(){
        await LoadNews();
    }
}

const newsTickerStoreInstance = new NewsTickerStore(AppDispatcher);
export default newsTickerStoreInstance;