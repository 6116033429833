import { ListedInstrument } from "../listedInstruments/listedInstrumentModels"
import { CashTransaction } from "../nav/navModels"
import { PltfmDateTime } from "../utils/dates"

export interface ClientTradeAttempt {
    quoteId: number,
    size: number,
    price: number,
    isBuy: boolean,
    stamp: number,
    tradeId: number,
    uniqueKey: string,
}

export interface TradeAttemptResponse {
    tradeId: number,
    sizeFilled: number | undefined,
    priceFilled: number | undefined,
    responseCode: TradeAttemptResponseCode,
    productDescription: string,
    productId: number,
    isBuy: boolean,
    uniqueKey: string,
    rfqId: number,
    quoteUniqueKey: string
    dealerPartyId: string
}

export enum TradeAttemptResponseCode {
    Filled = 1,
    PartialFill = 2,
    RejectedOnStalePrice = 3,
    RejectedOnSize = 4,
    RejectedOnCredit = 5,
    RejectedOther = 6
}

export interface TradeStoreState {
    rfqToTrade: Map<number, Trade>,
    trades: Map<number, Trade>,
    books: string[],
    strategies: string[],
    tradeQueries: Map<string, Trade[]>,
    errors: Map<string, string>,
    loanDepos: LoanDepo[],
    cashTransactions: CashTransaction[],
    tradeHistory: TradeHistoryRecord[],
}

export interface NewTradeEventStoreState {
    events: Map<number, boolean>,
}

export type Trade = {
    tradeId: number;
    productId: number;
    rfqId: number;
    size: number;
    price: number;
    currency: string;
    payload: string;
    status: string;
    createdBy: number;
    createdUtc: Date;
    executedUtc: Date;
    bookedUtc: Date;
    lastUpdatedBy: number;
    lastUpdatedUtc: Date;
    counterpartyId: number;
    homepartyId: number;
    tradeMetaData: TradeMetaData[];
    isInternal: boolean;
    listedInstrumentId: number;
    listedInstrument: ListedInstrument;
    brokerage: number;
    brokerageCurrency: string;
    tradeIdModified : string;
}

export type SimpleTrade = {
    size: number;
    price: number;
    currency: string;
    createdBy: number;
    createdUtc: Date;
    executedUtc: Date;
    tradeMetaData: TradeMetaData[];
    listedInstrumentId: number;
    strategy: string;
    book: string;
    account: string;
    forceBondsOnly: boolean;
    bookingEntityId: number;
}

export type CashTrade = {
    amount: number;
    currency: string;
    createdBy: number;
    createdUtc: Date;
    executedUtc: Date;
    valueDate: Date;
    tradeMetaData: TradeMetaData[];
    account: string;
    isBalanceTrade: boolean;
    description: string;
    category: string;
    externalId: string;
    bookingEntityId: number;
    accrualKey: string;
}

export type TradeMetaData = {
    tradeMetaDataId: number;
    tradeId: number;
    type: string;
    data: string;
    category: string;
    lastUpdated: Date;
}

export type MetaDataQueryResponse = {
    metaValues: { [category: string]: { [key: string]: string[] } }
}

export type TradeQuery = {
    fromDate: PltfmDateTime;
    toDate: PltfmDateTime;
    tradeId: number;
    tradeIdTo: number;
    status: string;
    trader: string;
    payoff: string;
    externalId: string;
    description: string;
    uniqueId: string;
    dailyAggregate: boolean;
    columns: string[];
    columnWidths: {[col: string]: number}
}

export type TradeQueryResponse = {
    uniqueId: string;
    trades: Trade[];
    success: boolean;
    status: string;
}

export interface TradeMetaDataUpdate {
    tradeIds: number[],
    metaToAdd: TradeMetaData[]
}

export interface TradeMetaDataSuggestion {
    tradeId: number,
    book: string,
    strategy: string
}

export interface LoanDepo {
    loanDepoId: number,
    account: string,
    counterparty: string,
    nominal: number,
    fixedRate: number,
    ccy: string,
    description: string,
    externalId: string,
    startDate: string,
    endDate: string,
    bookedUtc: string,
    updatedUtc: string
}

export class TradeHistoryRecord {
    tradeNumber: number;
    tradeDate: string;
    year: number;
    month: number;
    day: number;
    my: string;
    asset: string;
    attribution: string;
    assetAttribution: string;
    code: string;
    openDate: Date;
    closedDate: Date;
    holdingPeriod: number;
    tradeIndicator: string;
    bloombergTicker: string;
    name: string;
    book: string;
    theme: string;
    shares: number;
    openShares: number;
    openPx: number;
    ccy: string;
    costLocal: number;
    fxPaid: number;
    costUSD: number;
    absCostUSD: number;
    closePx: number;
    fxClose: number;
    uSDExp: number;
    pnLPct: number;
    realizedPnL: number;
    exit: string;
    instrumentType: string;
    profitOrLoss: string;
    underlyingBeta: string;
    instrumentId: number;
    brokerage: number;
    brokerageUSD: number;
}