import { configureStore, createAsyncThunk, getDefaultMiddleware } from "@reduxjs/toolkit";
import moment from "moment";
import thunkMiddleware from 'redux-thunk';
import { GasEstimateResponse } from "./homepage/widgets/gasPriceEstimates";
import { LiveSummary, PositionSummary } from "./positions/positionSummaryModels";
import { get } from "./utils/httpUtils";

const initialGasState = { lastResponse: {}, lastUpdate: moment().toISOString() } as GasReducerState;
const initialLivePriceState = { liveSummary: {}, lastUpdate: moment().toISOString() } as LivePriceReducerState;

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })
  
export const reduxStore = configureStore({
    reducer: {
        gasEstimates: gasEstimateReducer,
        livePosition: livePositionReducer
    },
    middleware:customizedMiddleware.prepend(thunkMiddleware),
});

//Gas price widget code
export interface GasReducerState {
    lastResponse: GasEstimateResponse,
    lastUpdate: string
}

export interface GasEstimateUpdateAction {
    type: 'update',
    payload: {estimate: GasEstimateResponse, stamp: string}
}

export const fetchGasPriceEstimates = createAsyncThunk("gasEstimates/fetch", async () => {
    let response = await get<GasEstimateResponse>('https://api.gasprice.io/v1/estimates', undefined, true);
    if (response?.payload) {
        return  {estimate: response.payload, stamp: moment().toISOString()} ;
    }
})

function gasEstimateReducer(state: GasReducerState = initialGasState, action) : GasReducerState {
    //console.log(action)
    switch (action.type) {
        case 'gasEstimates/fetch/fulfilled':
            return { ...state, lastResponse: action.payload.estimate, lastUpdate: action.payload.stamp }
        case 'update':
            return { ...state, lastResponse: action.payload.estimate, lastUpdate:  action.payload.stamp  }
        default:
            return state
    }
}

//live position code
export interface LivePriceReducerState {
    liveSummary: LiveSummary,
    positionSummary: PositionSummary,
    lastUpdate: string
}

function livePositionReducer(state: LivePriceReducerState = initialLivePriceState, action) : LivePriceReducerState {
    //console.log(action)
    switch (action.type) {
        case 'update':
            return { ...state, liveSummary: action.payload.liveSummary, lastUpdate:  action.payload.stamp  }
        default:
            return state
    }
}

export type AppDispatch = typeof reduxStore.dispatch