import {
    useGridApiRef,
    DataGridPremium,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridCellParams,
    GridToolbarDensitySelector,
    GridCsvExportOptions,
} from '@mui/x-data-grid-premium';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Grid, Button, Menu, MenuItem, FormControlLabel, Switch, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { CloseOutlined, GroupAddOutlined, RefreshOutlined, TuneOutlined } from '@mui/icons-material';
import userStoreInstance from '../user/userStore';
import React, { useState, useEffect, useRef } from 'react';
import { AssetStr, AttributionStr, BetaStr, DefaultSetName, EarliestDate, InstrumentStr, StartNav, SubAttributionStr } from '../globalConstants';
import { LivePosition, LiveSummary } from './positionSummaryModels';
import { colCommon, getBbgTickerFromInstrument, getFilters, getIns, getMetaFromInstrument, getPositionKey, getUltimateParent } from './positionsCommon';
import { EventSubscription } from 'fbemitter';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { getFormTheme } from '../inputs/formCommon';
import positionStoreInstance from './positionSummaryStore';
import navStoreInstance from '../nav/navStore';
import { Moment } from 'moment';
import moment from 'moment';
import { ComputeFeederMasterNavs } from '../nav/navActions';
import _ from 'lodash';
import { loading } from '../utils/loading';
import TransferList from '../inputs/transferList';
import { LoadValuations } from './positionSummaryActions';
import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import { renderDateTimeCell } from '../utils/helpers';

const signedClassNameFn = (params: GridCellParams) => params.value < 0 ? "PositionSummaryTabTableCell--Negative" : "PositionSummaryTabTableCell--Positive";
const renderNumberCell = (params: GridCellParams) => {
    if (params.value) {
        var val: number = parseFloat(params.value.toString());
        return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 8 })}</div>
    }
    else
        return <div></div>;
}

const renderNumberCell2dp = (params: GridCellParams) => {
    if (params.value) {
        var val: number = parseFloat(params.value.toString());
        return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
    }
    else
        return <div></div>;
}

const renderCcyCell = (params: GridCellParams) => {
    if (params.value) {
        var val: number = parseFloat(params.value.toString());
        return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 0 })}</div>
    }
    else
        return <div></div>;
}

const renderPctCell = (params: GridCellParams) => {
    if (params.value) {
        var val: number = parseFloat(params.value.toString());
        return <div>{(val * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}%</div>
    }
    else
        return <div></div>;
}

const columns: GridColDef[] = [
    { field: 'asset', hide: true, width: 75, headerName: 'Asset', cellClassName: (params: GridCellParams) => params.value ? "PositionSummaryTabTableCell--" + params.value : "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", },
    { field: 'instrumentId', hide: true, width: 125, headerName: 'InsId', type: "number", ...colCommon },
    { field: 'instrument', hide: true, width: 200, headerName: 'Description', ...colCommon },
    { field: 'ultimateUnderlying', hide: true, width: 200, headerName: 'Underlying / Parent', ...colCommon },
    { field: 'type', hide: true, width: 120, headerName: 'Type', ...colCommon },
    { field: 'realizedPnL', hide: true, width: 130, headerName: 'Realized (CCY)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
    { field: 'realizedPnLBase', width: 130, headerName: 'Realized(Base)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
    { field: 'realizedPnLBasePct', width: 130, headerName: 'Realized(%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number" },
    { field: 'unRealizedPnL', hide: true, width: 135, headerName: 'UnRealised', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
    { field: 'unRealizedPnLBase', width: 135, headerName: 'UnRealised(Base)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
    { field: 'unRealizedPnLBasePct', width: 135, headerName: 'UnRealised(%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number" },
    { field: 'totalPnL', hide: true, width: 130, headerName: 'Total', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
    { field: 'totalPnLBase', width: 130, headerName: 'Total(Base)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderCcyCell, type: "number" },
    { field: 'totalPnLBasePct', width: 130, headerName: 'Total(%NAV)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number" },
    { field: 'totalPnLBaseProp', width: 130, headerName: 'Total(Prop)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderPctCell, type: "number" },
    { field: 'openPosition', hide: true, width: 150, headerName: 'Contracts', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell, type: "number" },
    { field: 'openPositionInUnits', hide: true, width: 175, headerName: 'Quantity', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", renderCell: renderNumberCell, type: "number" },
    { field: 'openPositionInUnitsDeltaAdj', hide: true, width: 175, headerName: 'Delta', cellClassName: signedClassNameFn, renderCell: renderNumberCell2dp, headerClassName: "PositionSummaryTabTableCellHeader", type: "number" },
    { field: 'deltaEquivBase', hide: true, width: 175, headerName: 'Delta (Base)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: renderCcyCell },
    { field: 'deltaEquivBasePct', hide: true, width: 175, headerName: 'Delta (Base)', cellClassName: signedClassNameFn, headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: renderPctCell },
    { field: 'underlying', hide: true, width: 150, headerName: 'Underlying Ins', ...colCommon },
    { field: 'underlyingType', hide: true, width: 130, headerName: 'U. Type', ...colCommon },
    { field: 'underlyingId', hide: true, width: 150, headerName: 'U. Id', ...colCommon, type: "number" },
    { field: 'ccy', hide: true, width: 110, headerName: 'CCY', ...colCommon },
    { field: 'multiplier', hide: true, width: 100, headerName: '(x)', ...colCommon, type: "number" },
    { field: 'bbgTicker', hide: true, width: 150, headerName: 'BBG Ticker', ...colCommon },
    { field: 'attribution', hide: true, width: 150, headerName: 'Attribution', ...colCommon },
    { field: 'subAttribution', hide: true, width: 150, headerName: 'Sub-Attrib', ...colCommon },
    { field: 'insMetaStr', hide: true, width: 150, headerName: 'Instrument', ...colCommon },
    { field: 'beta', hide: true, width: 150, headerName: 'U/L Beta', ...colCommon },
    { field: 'posKey', hide: true, width: 150, headerName: 'Position Key', ...colCommon },
    { field: 'firstTraded', width: 200, headerName: 'First Trade', ...colCommon, type: "dateTime", renderCell: renderDateTimeCell, hide: true, filterOperators: getFilters("dateTime") },
    { field: 'lastTraded', width: 200, headerName: 'Last Trade', ...colCommon, type: "dateTime", renderCell: renderDateTimeCell, hide: true, filterOperators: getFilters("dateTime") },
];

export const PerfAnalysis = props => {
    //const { } = props;
    const [startDate, setStartDate] = useState<Moment>(undefined);
    const [endDate, setEndDate] = useState<Moment>(undefined);
    const [startPositions, setStartPositions] = useState<LiveSummary>(undefined);
    const [endPositions, setEndPositions] = useState<LiveSummary>(undefined);
    const [rows, setRows] = useState(new Array<any>());
    const [menuAnchor, setMenuAnchor] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [baselineMap, setBaselineMap] = useState(new Map<string, LivePosition>());
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [awaitingRefresh, setAwaitingRefresh] = useState(false);
    const [showUnexplained, setShowUnexplained] = useState(false);
    const [fxAsPosition, setFxAsPosition] = useState(true);
    const [showDefineTree, setShowDefineTree] = useState(false);
    const [treeFields, setTreeFields] = useState<string[]>(["asset", "attribution", "ultimateUnderlying", "instrument"]);
    const [loadingMessage, setLoadingMessage] = useState(undefined);
    //
    const attachCash = false;

    const DefaultColumns = ["realizedPnLBase", "realizedPnLBasePct", "unRealizedPnLBase", "unRealizedPnLBasePct", "totalPnLBase", "totalPnLBasePct", "totalPnLBaseProp"];
    const DefaultSumColumns = ["unRealizedPnLBase", "unRealizedPnLBasePct", "realizedPnLBase", "realizedPnLBasePct", "totalPnLBase", "totalPnLBasePct", 'totalPnLBaseProp'];

    const [startNav, setStartNav] = useState<number>(undefined);

    var eventSubscriptionPositions = useRef({} as EventSubscription);
    var eventSubscriptionNav = useRef({} as EventSubscription);

    var apiRef = useRef(useGridApiRef());

    if (!baselineMap)
        setBaselineMap(new Map<string, LivePosition>());

    useEffect(() => {
        eventSubscriptionPositions.current = positionStoreInstance.addChangeListener(onPositionChange);
        eventSubscriptionNav.current = navStoreInstance.addChangeListener(onNavUpdate);

        if (!startDate) {
            var val = sessionStorage.getItem("perfAnalysisStartDate");
            if (val && moment(val)) {
                setStartDate(moment(val));
            }
        }

        if (!endDate) {
            var val2 = sessionStorage.getItem("perfAnalysisEndDate");
            if (val2 && moment(val2)) {
                setEndDate(moment(val2));
            }
        }

        async function onNavUpdate() {
            if (!navStoreInstance.navsAreLoaded()) {
                await ComputeFeederMasterNavs(DefaultSetName)
            }

            if (startDate) {
                var baseNav = startDate.isSame(moment(EarliestDate),'day') ? StartNav : navStoreInstance.getCalculatedNav(startDate.toDate(), "MasterFund")?.totalInBase;
                if (baseNav) {
                    setStartNav(baseNav);
                }
            }
        }

        onNavUpdate();

        function onPositionChange() {
            if (startDate && endDate) {
                var valStart = positionStoreInstance.getValuations(startDate?.format("yyyy-MM-DD"), true, undefined, fxAsPosition, attachCash);
                var valEnd = positionStoreInstance.getValuations(endDate?.format("yyyy-MM-DD"), true, undefined, fxAsPosition, attachCash);
                setEndPositions(valEnd);
                setStartPositions(valStart);
                setAwaitingRefresh(!(Boolean(valStart) && Boolean(valEnd)));
            }
        }

        const getParent = (ulIns: ListedInstrument, ins: ListedInstrument)=> {
            return getMetaFromInstrument(ins,"Parent") ?? (ulIns?.underlying ?? ulIns ?? ins)?.description?.replaceAll(" Underlying Index", "")
        }

        function genRowsForTimeSegment(rowIn: any[], divisorNav: number, startPositions: LivePosition[], endPositions: LivePosition[], endNav: number) {
            var blMap = new Map<string, LivePosition>();
            var endMap = new Map<string, LivePosition>();
            startPositions.forEach(p => {
                blMap.set(getPositionKey(p), p);
            });
            endPositions.forEach(p => {
                endMap.set(getPositionKey(p), p);
            });

            //var totalAllocated = 0;

            rowIn.forEach(r => {
                r.unRealizedPctThisPeriod = 0;
                r.realizedPctThisPeriod = 0;
                r.unRealizedBaseThisPeriod = 0;
                r.realizedBaseThisPeriod = 0;
            });

            endPositions.forEach(endPos => {
                var posKey = getPositionKey(endPos);
                var existing = rowIn.filter(r => r.posKey === posKey)[0];
                var ins = getIns(endPos.aggregatedPosition.instrumentId);
                var ulIns = getIns(getUltimateParent(endPos));

                if (!existing) {
                    var r = {
                        id: 0,
                        posKey: posKey,
                        instrument: ins?.description?.replaceAll(ins?.type, ""),
                        instrumentId: endPos.aggregatedPosition.instrumentId,
                        type: ins?.type,
                        underlying: ins?.underlying?.ticker,
                        ultimateUnderlying: getParent(ulIns, ins),
                        underlyingType: ins?.underlying?.type,
                        underlyingId: ins?.underlyingId,
                        ccy: endPos.aggregatedPosition.realizedPnLCcy,
                        openPosition: endPos.aggregatedPosition.openPosition - (blMap.get(posKey)?.aggregatedPosition?.openPosition ?? 0),
                        openPositionInUnits: (endPos.aggregatedPosition.openPosition - (blMap.get(posKey)?.aggregatedPosition?.openPosition ?? 0)) * ins?.multiplier,
                        openPositionInUnitsDeltaAdj: (endPos.aggregatedPosition.openPosition * endPos.delta - ((blMap.get(posKey)?.aggregatedPosition?.openPosition ?? 0) * (blMap.get(posKey)?.delta ?? 0))) * ins?.multiplier,
                        realizedPnL: endPos.aggregatedPosition.realizedPnL - (blMap.get(posKey)?.aggregatedPosition.realizedPnL ?? 0),
                        realizedPnLBase: (endPos.aggregatedPosition.realizedPnLBase - (blMap.get(posKey)?.aggregatedPosition.realizedPnLBase ?? 0)),
                        realizedPnLBasePct: (endPos.aggregatedPosition.realizedPnLBase - (blMap.get(posKey)?.aggregatedPosition.realizedPnLBase ?? 0)) / divisorNav,
                        bbgTicker: getBbgTickerFromInstrument(ins),
                        multiplier: ins?.multiplier ?? 1.0,
                        asset: getMetaFromInstrument(ins, AssetStr),
                        attribution: getMetaFromInstrument(ins, AttributionStr),
                        subAttribution: getMetaFromInstrument(ins, SubAttributionStr),
                        beta: getMetaFromInstrument(ins, BetaStr),
                        insMetaStr: getMetaFromInstrument(ins, InstrumentStr),
                        unRealizedPnL: endPos.unrealizedPnL - (blMap.get(posKey)?.unrealizedPnL ?? 0),
                        unRealizedPnLBase: (endPos.unrealizedPnLBase - (blMap.get(posKey)?.unrealizedPnLBase ?? 0)),
                        unRealizedPnLBasePct: (endPos.unrealizedPnLBase - (blMap.get(posKey)?.unrealizedPnLBase ?? 0)) / divisorNav,
                        totalPnL: 0,
                        totalPnLBase: 0,
                        totalPnLBasePct: 0,
                        realizedPctThisPeriod: 0,
                        unRealizedPctThisPeriod: 0,
                        realizedBaseThisPeriod: 0,
                        unRealizedBaseThisPeriod: 0,
                        lastTraded: new Date(endPos.aggregatedPosition.lastTraded),
                        firstTraded: new Date(endPos.aggregatedPosition.firstTraded),
                    };
                    r.totalPnL = r.unRealizedPnL + r.realizedPnL;
                    r.totalPnLBase = r.unRealizedPnLBase + r.realizedPnLBase;
                    r.totalPnLBasePct = r.unRealizedPnLBasePct + r.realizedPnLBasePct;
                    r.realizedPctThisPeriod = r.realizedPnLBasePct;
                    r.unRealizedPctThisPeriod += r.unRealizedPnLBasePct;
                    r.realizedBaseThisPeriod = r.realizedPnLBase;
                    r.unRealizedBaseThisPeriod = r.unRealizedPnLBase;

                    //totalAllocated += r.totalPnLBasePct;
                    rowIn.push(r);
                }
                else {
                    var realized = endPos.aggregatedPosition.realizedPnL - (blMap.get(posKey)?.aggregatedPosition.realizedPnL ?? 0);
                    var unRealized = endPos.unrealizedPnL - (blMap.get(posKey)?.unrealizedPnL ?? 0);

                    var realizedInBase = endPos.aggregatedPosition.realizedPnLBase - (blMap.get(posKey)?.aggregatedPosition.realizedPnLBase ?? 0);
                    var unRealizedInBase = endPos.unrealizedPnLBase - (blMap.get(posKey)?.unrealizedPnLBase ?? 0);

                    var realizedInPct = realizedInBase / divisorNav;
                    var unRealizedInPct = unRealizedInBase / divisorNav;
                    existing.openPosition = endPos.aggregatedPosition.openPosition;
                    existing.openPositionInUnits = endPos.aggregatedPosition.openPosition * ins?.multiplier;
                    existing.openPositionInUnitsDeltaAdj = endPos.aggregatedPosition.openPosition * endPos.delta * ins?.multiplier;
                    existing.realizedPnL += realized;
                    existing.realizedPnLBase += realizedInBase;
                    existing.realizedPnLBasePct = (1 + existing.realizedPnLBasePct) * (1 + realizedInPct) - 1;
                    existing.unRealizedPnL += unRealized;
                    existing.unRealizedPnLBase += unRealizedInBase;
                    existing.unRealizedPnLBasePct = (1 + existing.unRealizedPnLBasePct) * (1 + unRealizedInPct) - 1;
                    existing.totalPnL = existing.unRealizedPnL + existing.realizedPnL;
                    existing.totalPnLBase = existing.unRealizedPnLBase + existing.realizedPnLBase;
                    existing.totalPnLBasePct = existing.unRealizedPnLBasePct + existing.realizedPnLBasePct;

                    existing.realizedPctThisPeriod = realizedInPct;
                    existing.unRealizedPctThisPeriod = unRealizedInPct;
                    existing.realizedBaseThisPeriod = realizedInBase;
                    existing.unRealizedBaseThisPeriod = unRealizedInBase;

                    //totalAllocated += realizedInPct + unRealizedInPct;
                }
            });
        }

        function generateRowData() {

            baselineMap.clear();
            startPositions?.positions?.forEach(p => {
                baselineMap.set(getPositionKey(p), p);
            });

            //var endPos = endPositions?.positions;

            var navs = navStoreInstance.getNavsForFundSync("Galena Master Fund");
            var navDates = navs?.map(n => moment(n.date).startOf('day').toDate()).filter(d => startDate?.isBefore(d) && endDate?.isAfter(d));
            var periodDates = (navDates ?
                [startDate?.startOf('day')?.toDate(), endDate?.startOf('day')?.toDate(), ...navDates] :
                [startDate?.startOf('day')?.toDate(), endDate?.startOf('day')?.toDate()])
                .filter(x => x !== null && x !== undefined)
                .sort((a, b) => a.valueOf() > b.valueOf() ? 1 : -1);
            var valsForPeriodDates = periodDates.map(d => positionStoreInstance.getValuations(moment(d)?.format("yyyy-MM-DD"), true, undefined, fxAsPosition, attachCash)).filter(v => v !== undefined).map(v => _.cloneDeep(v));
            var rowz = new Array<any>();

            if (periodDates.length > 1 && valsForPeriodDates.length === periodDates.length && startNav) {
                for (var i = 0; i < periodDates.length - 1; i++) {
                    var sd = periodDates[i];
                    var ed = periodDates[i + 1];
                    const sd0 = sd;
                    const ed0 = ed;
                    var startP = valsForPeriodDates.find(v => v.asOf === moment(sd0)?.format("yyyy-MM-DD"))
                    var endP = valsForPeriodDates.find(v => v.asOf === moment(ed0)?.format("yyyy-MM-DD"))

                    var navStartRec = navs?.filter(n => moment(n.date).startOf("day").isSame(moment(sd0).startOf("day")))[0];
                    var navDivisor = navStartRec ? (navStartRec.navPerShare * navStartRec.sharesPostSubsReds) : startNav;

                    if (!navDivisor) {
                        var cd = navStoreInstance.getCalculatedNav(startDate.toDate(), "MasterFund")?.classDetail;
                        if (cd) {
                            var x = cd[Object.keys(cd)[0]];
                            navDivisor = x.numberOfSharesPostClose * x.navPerShare;
                        }
                    }

                    var navEndRec = navs?.filter(n => moment(n.date).startOf("day").isSame(moment(ed0).startOf("day")))[0];
                    var navEnd = navEndRec ? (navEndRec.navPerShare * navEndRec.sharesPreSubsReds) : undefined;
                    var filteredStartPos = startP.positions;
                    var filteredEndPos = endP.positions.filter(p => p.aggregatedPosition.openPosition !== 0 || moment(p.aggregatedPosition.lastTraded).isAfter(moment(sd0).endOf("day")));
                    genRowsForTimeSegment(rowz, navDivisor, filteredStartPos, filteredEndPos, navEnd);
                }

                //adjust to match official
                var navPerShareStart = navs?.find(n => moment(n.date).startOf('day').isSame(startDate))?.navPerShare;
                if (!navPerShareStart) {
                    var calcNavRec = navStoreInstance.getCalculatedNav(startDate.toDate(), "MasterFund");
                    if (calcNavRec) {
                        navPerShareStart = calcNavRec.classDetail[Object.keys(calcNavRec.classDetail)[0]]?.navPerShare;
                    }
                }
                var navPerShareEnd = navs?.find(n => moment(n.date).startOf('day').isSame(endDate))?.navPerShare;
                if (!navPerShareEnd) {
                    var calcNavRec2 = navStoreInstance.getCalculatedNav(endDate.toDate(), "MasterFund");
                    if (calcNavRec2) {
                        console.log(calcNavRec2)
                        navPerShareEnd = calcNavRec2.classDetail[Object.keys(calcNavRec2.classDetail)[0]]?.navPerShare;
                    }
                }

                const officialPerf = navPerShareEnd / navPerShareStart - 1.0;
                const totalAllocated = _.sum(rowz.map(r => r.totalPnLBasePct));

                if (showUnexplained) {
                    var r = {
                        id: 0,
                        path: "Unexplained",
                        instrument: "Unexplained",
                        instrumentId: -1,
                        ccy: "USD",
                        openPosition: 0,
                        openPositionInUnits: 0,
                        openPositionInUnitsDeltaAdj: 0,
                        totalPnLBasePct: officialPerf - totalAllocated,
                        unRealizedPnLBasePct: officialPerf - totalAllocated,
                    };
                    rowz.push(r);
                }
                else {
                    const returnFactor = officialPerf / totalAllocated;
                    rowz.forEach(r => {
                        r.unRealizedPnLBasePct *= returnFactor;
                        r.realizedPnLBasePct *= returnFactor
                        r.totalPnLBasePct *= returnFactor;
                    });
                }


            }

            rowz = rowz.filter(x => !(x.realizedPnLBasePct === 0 && x.unRealizedPnLBasePct === 0) && !(x.realizedPnLBasePct === undefined && x.unRealizedPnLBasePct === undefined));

            //rowz = groupRows(rowz, treeFields, DefaultSumColumns)

            var totalTotal = _.sum(rowz.map(r => r.totalPnLBasePct));
            for (i = 0; i < rowz.length; i++) {
                rowz[i].id = i;
                rowz[i].totalPnLBaseProp = rowz[i].totalPnLBasePct / totalTotal;
            }

            setRows(rowz);
        }

        if(!awaitingRefresh)
            generateRowData();

        var sub = eventSubscriptionNav.current;
        var sub2 = eventSubscriptionPositions.current;

        return function cleanup() {
            if (sub && sub.remove)
                sub.remove();
            if (sub2 && sub2.remove)
                sub2.remove();
        };

    }, [startPositions, endPositions, startDate, endDate, baselineMap, startNav, showUnexplained, attachCash, fxAsPosition, treeFields, awaitingRefresh])


    function changeEndDate(date: moment.Moment) {
        sessionStorage.setItem("perfAnalysisEndDate", date.format("yyyy-MM-DD"));
        setEndDate(date);
    }

    function changeStartDate(date: moment.Moment) {
        sessionStorage.setItem("perfAnalysisStartDate", date.format("yyyy-MM-DD"));
        setStartDate(date);
    }

    async function fetchPositionDate(missingValDates: Date[]){
        setAwaitingRefresh(true);
        for(var i=0; i<missingValDates.length;i++)
        {
            var d = missingValDates[i];
            setLoadingMessage(`Fetching data for ${moment(d).format("yyyy-MM-DD")}`);
            await LoadValuations(moment(d).format("yyyy-MM-DD"), false, "", fxAsPosition, attachCash);
        };
        setAwaitingRefresh(false);
    }

    function onClickCalculate() {
        setAwaitingRefresh(true);
        var navs = navStoreInstance.getNavsForFundSync("Galena Master Fund");
        var navDates = navs?.map(n => moment(n.date).startOf('day').toDate()).filter(d => startDate?.isBefore(d) && endDate?.isAfter(d));
        var periodDates = (navDates ?
            [startDate?.startOf('day')?.toDate(), endDate?.startOf('day')?.toDate(), ...navDates] :
            [startDate?.startOf('day')?.toDate(), endDate?.startOf('day')?.toDate()])
            .filter(x => x !== null && x !== undefined)
            .sort((a, b) => a.valueOf() > b.valueOf() ? 1 : -1);

        var missingValDates = periodDates.filter(d => !positionStoreInstance.hasValuation(moment(d).format("yyyy-MM-DD"), fxAsPosition, attachCash));
        if (missingValDates.length > 0) {
            fetchPositionDate(missingValDates);
        }
        else{
            setLoadingMessage(undefined);
            setAwaitingRefresh(false);
        }

        //var valStart = positionStoreInstance.getValuations(startDate?.format("yyyy-MM-DD"), false, undefined, fxAsPosition, attachCash);
        //refreshDone = Boolean(valStart);
        //setStartPositions(valStart);

        //var valEnd = positionStoreInstance.getValuations(endDate?.format("yyyy-MM-DD"), false, undefined, fxAsPosition, attachCash)
        //setEndPositions(valEnd);
        //refreshDone = refreshDone && Boolean(valEnd);

        //setAwaitingRefresh(!refreshDone);
    }

    function customToolbar(props: any) {
        var csvOptions = { allColumns: true, fileName: `${moment(startDate).format("yyyy-MM-DD")}¬${moment(endDate).format("yyyy-MM-DD")}¬PltfmPerformance` } as GridCsvExportOptions;
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer>
                    <Grid container spacing={0.5} style={{ padding: "5px" }}>
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={startDate?.toDate()}
                                onChange={changeStartDate}
                                disableFuture
                                minDate={EarliestDate}
                                disableWeekends
                                //shouldDisableDate={shouldDisableDate}
                                //views={['year','month']}
                                label={"Period Start"} />
                        </Grid>
                        <Grid item>
                            <WrappedDatePicker
                                style={{ width: "120px" }}
                                value={endDate?.toDate()}
                                onChange={changeEndDate}
                                disableFuture
                                minDate={EarliestDate}
                                disableWeekends
                                label={"Period End"} />
                        </Grid>
                        <Grid item>
                            <Button
                                className="MuiButton-outlined PltfmButtonLite"
                                variant="outlined"
                                size="small"
                                disabled={awaitingRefresh}
                                startIcon={<GroupAddOutlined />}
                                onClick={() => { setShowDefineTree(true) }}>
                                Grouping
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className="MuiButton-outlined PltfmButtonLite"
                                variant="outlined"
                                size="small"
                                disabled={!startDate || !endDate || awaitingRefresh}
                                startIcon={<RefreshOutlined />}
                                onClick={() => { setAwaitingRefresh(true); onClickCalculate(); }}>
                                Calculate
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                className="MuiButton-outlined PltfmButtonLite"
                                startIcon={<TuneOutlined />}
                                onClick={handleOptionsMenuClick}>
                                Options
                            </Button>
                            <Menu
                                key="posGridOptionMenu"
                                id="posGridOptionMenu"
                                anchorReference="anchorPosition"
                                anchorPosition={
                                    menuAnchor !== null
                                        ? { top: menuAnchor.mouseY, left: menuAnchor.mouseX }
                                        : undefined
                                }
                                open={menuAnchor !== null} onClose={() => setMenuAnchor(null)}
                                classes={{ list: "GridOptionMenu" }}
                            >
                                <MenuItem><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" /></MenuItem>
                                <MenuItem><GridToolbarDensitySelector {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></MenuItem>
                                <MenuItem><GridToolbarColumnsButton {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></MenuItem>
                                <MenuItem><FormControlLabel control={<Switch checked={showUnexplained} onChange={() => { setShowUnexplained(!showUnexplained); setMenuAnchor(null); }} />} label="Show Unexplained" /></MenuItem>
                                <MenuItem><FormControlLabel control={<Switch checked={fxAsPosition} onChange={() => { setFxAsPosition(!fxAsPosition); setMenuAnchor(null); }} />} label="Fx as position" /></MenuItem>
                            </Menu>
                        </Grid>
                        <Grid item><GridToolbarExport {...props} size="small" variant="outlined" className="MuiButton-outlined PltfmButtonLite" csvOptions={csvOptions} /></Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    function setTreeDefinition(fields: string[]) {
        const fieldNames = fields.map(t => columns.filter(c => c.headerName === t)[0]?.field);
        setTreeFields(fieldNames);
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };
    const handleOptionsMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setMenuAnchor(
            menuAnchor === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    // var navs = navStoreInstance.getNavsForFundSync("Galena Master Fund");
    // var navDates = navs?.map(n => moment(n.date).startOf('day').toDate())?.filter(d => startDate?.isBefore(d) && endDate?.isAfter(d));
    // var periodDates = (navDates ? [startDate?.startOf('day')?.toDate(), endDate?.startOf('day')?.toDate(), ...navDates] : [startDate?.startOf('day')?.toDate(), endDate?.startOf('day')?.toDate()])
    //     ?.filter(x => x !== null && x !== undefined)
    //     ?.sort((a, b) => a.valueOf() > b.valueOf() ? 1 : -1);

    //var missingVals = periodDates.map(d => positionStoreInstance.getValuations(moment(d)?.format("yyyy-MM-DD"), true, undefined, fxAsPosition, attachCash)).filter(v => v === undefined);

    const columnVisibility: any = {};
    columns.forEach(col => {
        columnVisibility[col.field] = DefaultColumns.includes(col.field) && !treeFields.includes(col.field)
    });

    const aggregationModel: any = {};
    DefaultSumColumns.forEach(element => {
        aggregationModel[element] = "sum";
    });

    const theme = userStoreInstance.GetTheme();
    return (
        <div className="PositionSummaryTab">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={showDefineTree}
                        onClose={() => setShowDefineTree(false)}>
                        <DialogTitle id="alert-dialog-title" style={{ backgroundColor: theme.background_color_opaque, color: theme.color, borderBottom: "1px solid", borderColor: theme.border_color }}>
                            <Typography variant="h6">Grouping Tree Definition</Typography>
                            <IconButton
                                aria-label="close"
                                onClick={() => setShowDefineTree(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}>
                                <CloseOutlined />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent style={{ backgroundColor: theme.background_color_opaque, color: theme.color, display: "flex", justifyContent: "center", width: "100%" }}>
                            {showDefineTree && <div style={{ paddingTop: "1em", backgroundColor: theme.background_color }}>
                                <TransferList
                                    chosen={treeFields.map(t => columns.filter(c => c.field === t)[0]?.headerName)}
                                    choices={[...columns].sort((a,b)=>a.headerName>b.headerName?1:-1).map(x => x.headerName)}
                                    onChange={(chosen: string[]) => { setTreeDefinition(chosen); }}
                                    onClose={() => setShowDefineTree(false)}
                                />
                            </div>}
                        </DialogContent>
                    </Dialog>
                    <Grid container direction="column">
                        <Grid item style={{ maxHeight: `Calc(100% - 1px)`, minHeight: `Calc(100% - 1px)` }}>
                            {columns && <DataGridPremium
                                className="PositionSummaryTabTable"
                                key="perfTblYYT"
                                //treeData={rows && rows[0]?.path !== undefined}
                                //getTreeDataPath={(row) => row?.path?.split('¬') ?? new Array<string>()}
                                //groupingColDef={groupingColDef}
                                rows={awaitingRefresh ? new Array<any>() : rows}
                                columns={columns}
                                hideFooter
                                density={userStoreInstance.GetGridDensity()}
                                components={{
                                    Toolbar: customToolbar,
                                    LoadingOverlay: () => loading(loadingMessage)
                                }}
                                initialState={{
                                    columns: { columnVisibilityModel: columnVisibility },
                                    aggregation: { model: aggregationModel }
                                }}
                                classes={{
                                    panelWrapper: "GridFilterForm",
                                    panelContent: "GridFilterForm",
                                    panel: "GridFilterForm",
                                    filterForm: "GridFilterForm",
                                    columnsPanel: "GridFilterForm",
                                    footerCell: "GridFilterForm",
                                    pinnedRows: "GridFilterForm"
                                }}
                                componentsProps={{
                                    row: {
                                        onContextMenu: handleContextMenu,
                                        style: { cursor: 'context-menu' },
                                    },
                                    columnsPanel: {
                                        sort: 'asc'
                                    }
                                }}
                                throttleRowsMs={1000}
                                loading={awaitingRefresh}
                                apiRef={apiRef.current}

                                rowGroupingModel={treeFields}
                                rowGroupingColumnMode="multiple"
                                experimentalFeatures={{ aggregation: true }}
                            />}
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}