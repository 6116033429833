import * as React from 'react';
import {
    useGridApiRef,
    DataGridPremium,
    GridRowParams,
    MuiEvent,
    GridColDef,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridRenderEditCellParams,
    GridToolbarDensitySelector,
    GridSelectionModel,
    DataGridPremiumProps,
    GridRowModelUpdate,
    GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium';

import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { renderDateCell } from '../utils/helpers';
import { GoodUntil, LiveOrderStatuses, Order, OrderStatus } from './orderModels';
import { OrderEditor } from './orderEditor';
import { useState, useEffect, useRef, useCallback } from 'react';
import { AddOrUpdateOrder, ApproveOrDenyOrderQuestion, CancelOrder, CancelOrders, ReconSingleOrder, RefreshOrders, SubcribeToOrderUpdates } from './orderActions';
import ordersStoreInstance from './orderStore';
import positionStoreInstance from '../positions/positionSummaryStore';
import { EventSubscription } from 'fbemitter';
import { RefreshOutlined, DeleteOutlined, CancelOutlined, RestoreOutlined, EditOutlined, SaveOutlined, CloseOutlined, AddOutlined, DoneAllOutlined, DoneOutline, ContentCopyOutlined, DoneOutlineOutlined, WatchLaterOutlined } from '@mui/icons-material';
import {
    Grid,
    Button,
    ButtonGroup,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    AppBar,
    Toolbar,
    DialogContent,
    DialogActions,
    Tooltip,
    Menu,
    MenuItem,
    CircularProgress,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BuySellSelector } from '../inputs/buySellSelector';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';
import moment from 'moment';
import userStoreInstance from '../user/userStore';
import { EnableOrderEntry } from '../globalConstants';
import { getFormTheme } from '../inputs/formCommon';
import { OrderHistoryWidget } from './orderHistoryWidget';
import { v4 } from 'uuid';
import _ from 'lodash';
import { WrappedSelect } from '../inputs/wrappedSelect';
import userSessionConnection from '../userSession/userSessionConnection';
import { SubscribeToTicksByIdBulk } from '../accelerator/acceleratorActions';
import fractionUnicode from 'fraction-unicode';

export const NoCancelStates = [OrderStatus.Cancelled, OrderStatus.Filled, OrderStatus.Rejected, OrderStatus.PendingCancel];

const colBase = { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: 'Order Id',
    flex: 1.3,
    width: 180,
    ...colBase
};

export const OrderDashboard = props => {
    const [orders, setOrders] = useState(undefined);
    const [showNewOrder, setShowNewOrder] = useState(false);
    const [cancelOrderId, setCancelOrderId] = useState(undefined);
    const [approveOrderId, setApproveOrderId] = useState(undefined);
    const [orderHistoryId, setOrderHistoryId] = useState<number>(undefined);
    const [approveOrderMessage, setApproveOrderMessage] = useState(undefined);
    const [selectedRows, setSelectedRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [multiSelect, setMultiSelect] = useState(false);
    const [showOnlyLiveAndRecent, setShowOnlyLiveAndRecent] = useState<string>("Recent");
    const [currentlyShowingOnlyLiveAndRecent, setCurrentlyShowingOnlyLiveAndRecent] = useState<string>("Recent");
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [selectedRow, setSelectedRow] = useState<number>();
    const [orderToDuplicate, setOrderToDuplicate] = useState<Order>();
    const [awaitingRefresh, setAwaitingRefresh] = useState(false);
    const [uniqueOrderRef, setUniqueOrderRef] = useState<string>(v4());

    const insNameMap = useRef(new Map<number, string>());
    var eventSubscriptionMarketDataTicks = useRef({} as EventSubscription);
    var eventSubscriptionPositions = useRef({} as EventSubscription);
    var eventSubscriptionOrders = useRef({} as EventSubscription);
    const apiRef = useRef(useGridApiRef());

    const onTick = useCallback(() => {
        var rowsToUpdate = new Array<GridRowModelUpdate>();
        var allRowIds = apiRef.current.current.getAllRowIds();
        const activeOrderStatuses = ['Active', 'Waiting', 'Created', 'Paused'];
        allRowIds.forEach(rId => {
            var row = apiRef.current.current.getRow(rId);
            if (activeOrderStatuses.includes(row.orderStatusString)) {
                var order = orders.filter(o => o.orderId === rId)[0];
                var insId = Number(order?.listedInstrumentId);
                var tick = marketDataLiveStoreInstance.getTick(insId);
                var bidAsk = marketDataLiveStoreInstance.getBidAsk(insId);
                if (tick && tick !== row.lastPrice) {
                    row.lastPrice = tick;
                    row.bidPrice = bidAsk?.bid ?? tick;
                    row.askPrice = bidAsk?.ask ?? tick;
                    rowsToUpdate.push(row);
                }
            }
        })

        if (rowsToUpdate.length > 0) {
            apiRef.current.current.updateRows(rowsToUpdate);
        }
    }, [apiRef, orders]);

    const updateRowsA = useCallback((o: Order[]) => {
        if (Boolean(o) && (showOnlyLiveAndRecent === "Recent" || showOnlyLiveAndRecent === "Live")) {
            var parentChildMap = _.groupBy(o, x => x.parentOrderId);
            o = o.filter(x =>
                LiveOrderStatuses.includes(x.orderStatus) ||
                (showOnlyLiveAndRecent === "Recent" && moment(x.lastUpdated).isAfter(moment().startOf('day'))) ||
                (parentChildMap[x.orderId] && (LiveOrderStatuses.includes(parentChildMap[x.orderId][0]?.orderStatus) || (showOnlyLiveAndRecent === "Recent" && moment(parentChildMap[x.orderId][0]?.lastUpdated).isAfter(moment().startOf('day'))))))

        }

        if (currentlyShowingOnlyLiveAndRecent !== showOnlyLiveAndRecent)
            setCurrentlyShowingOnlyLiveAndRecent(showOnlyLiveAndRecent);

        const rowsI = o ? o.sort((a, b) => b.orderId - a.orderId).map((r) => {
            if (!insNameMap.current.get(r.listedInstrumentId))
                insNameMap.current.set(r.listedInstrumentId, listedInstrumentStoreInstance.getInstrumentById(r.listedInstrumentId)?.description);
            var rowA = {
                id: r.orderId,
                openPosition: positionStoreInstance.getPositionForInstrument(null, Number(r.listedInstrumentId)),
                ...r
            };

            if (!rowA.insDescription)
                rowA.insDescription = insNameMap.current.get(r.listedInstrumentId);

            return rowA;
        }) : new Array<any>();
        setRows(rowsI);
        setAwaitingRefresh(false);
    }, [showOnlyLiveAndRecent, currentlyShowingOnlyLiveAndRecent]);

    const onOrderUpdate = useCallback(() => {
        var orders = ordersStoreInstance.getOrders();
        var forApproval = orders.filter(o => o.orderStatus === OrderStatus.PendingManualApproval && o.orderVenue === 'Pltfm');
        if (forApproval.length > 0 && approveOrderId === undefined) {
            forApproval.every(a => {
                var key = `LastOrderMessage-${a.orderId}`;
                var lastMsg = sessionStorage.getItem(key);
                if (lastMsg !== a.approvalMessage) {
                    sessionStorage.setItem(key, a.approvalMessage)
                    setApproveOrderMessage(a.approvalMessage);
                    setApproveOrderId(a.orderId);
                    return false;
                }
                return true;
            });

        }
        setOrders([...orders]);
        updateRowsA(orders);
    }, [approveOrderId, updateRowsA]);

    useEffect(() => {
        if (orders) {
            var insIds: number[] = _.uniq(orders.map((o: Order) => o.listedInstrumentId).filter(x => x > 0));
            SubscribeToTicksByIdBulk(insIds);
        }
    }, [orders])

    useEffect(() => {
        eventSubscriptionPositions.current = positionStoreInstance.addChangeListener(onPositionUpdate);
        function onPositionUpdate() {
            var rowsToUpdate = new Array<any>();
            var allRowIds = apiRef?.current?.current?.getAllRowIds();
            if (allRowIds) {
                allRowIds.forEach(rId => {
                    var row = apiRef.current.current.getRow(rId);
                    var insId = row.listedInstrumentId;
                    var pos = positionStoreInstance.getPositionForInstrument(null, Number(insId));
                    if (pos && row.openPosition !== pos) {
                        row.openPosition = pos;
                        rowsToUpdate.push(row);
                    }
                })

                if (rowsToUpdate.length > 0) {
                    apiRef.current.current.updateRows(rowsToUpdate);
                }
            }
        }
        userSessionConnection.subscribeToPositions();
        var subscriptionPositions = eventSubscriptionPositions;
        return function CleanUp() {
            subscriptionPositions?.current?.remove();
            userSessionConnection.unSubscribeToPositions();
        }
    }, [apiRef])

    useEffect(() => {
        eventSubscriptionOrders.current = ordersStoreInstance.addChangeListener(onOrderUpdate);
        SubcribeToOrderUpdates();
        var subscriptionOrders = eventSubscriptionOrders;
        return function CleanUp() {
            //UnSubcribeFromOrderUpdates();
            subscriptionOrders?.current?.remove();
        }
    }, [onOrderUpdate])

    useEffect(() => {
        eventSubscriptionMarketDataTicks.current = marketDataLiveStoreInstance.addChangeListener(onTick);
        var subscriptionTicks = eventSubscriptionMarketDataTicks;
        return function CleanUp() {
            subscriptionTicks?.current?.remove();
        }
    }, [onTick])

    useEffect(() => {
        if (!Boolean(orders) || currentlyShowingOnlyLiveAndRecent !== showOnlyLiveAndRecent) {
            onOrderUpdate();
            //RefreshOrders().catch(console.error);
        }
        else if (!rows)
            updateRowsA(orders);

    }, [orders, rows, onOrderUpdate, updateRowsA, showOnlyLiveAndRecent, currentlyShowingOnlyLiveAndRecent])

    const canCancel = useCallback((orderId: string) => {
        var order = orders.filter(o => o.orderId.toString() === orderId)[0] as Order;
        if (order) {
            return order.orderVenue === "Pltfm" && !NoCancelStates.includes(order.orderStatus);
        }
    }, [orders]);

    const canEdit = useCallback((orderId: string) => {
        return canCancel(orderId);
    }, [canCancel]);

    const canDuplicate = useCallback((orderId: string) => {
        var order = orders.filter(o => o.orderId.toString() === orderId)[0] as Order;
        if (order) {
            return order.orderVenue === "Pltfm";
        }
    }, [orders]);

    const onDuplicate = useCallback((orderId: string) => {
        setContextMenu(null);
        var oid = Number(orderId);
        var order = orders.filter(o => o.orderId === oid)[0];
        if (order) {
            var clone = JSON.parse(JSON.stringify(order)) as Order;
            setUniqueOrderRef(v4());
            setOrderToDuplicate(clone);
            setShowNewOrder(true);
        }
    }, [orders]);

    const onRecon = useCallback(async (orderId: string) => {
        setContextMenu(null);
        var oid = Number(orderId);
        var order = orders.filter(o => o.orderId === oid)[0];
        if (order) {
            await ReconSingleOrder(order);
        }
    }, [orders]);


    const isOrderTerminalState = useCallback((orderId: string) => {
        var order = orders.filter(o => o.orderId.toString() === orderId)[0];
        return order?.isTerminalState;
    }, [orders]);

    const canTrade = userStoreInstance.UserHasRole("Trader");


    useEffect(() => {

        async function handleSaveClick(event, api, id) {
            event.stopPropagation();
            api.current.commitRowChange(id);
            api.current.setRowMode(id, 'view');

            const row = api.current.getRow(id);
            api.current.updateRows([{ ...row, isNew: false }]);
            console.log(JSON.stringify(row));

            var order = orders.filter(o => o.orderId.toString() === id)[0] as Order;
            order.listedInstrumentId = row.listedInstrumentId;
            order.orderType = row.orderType;
            order.size = row.size;
            order.isBuyOrder = row.isBuyOrder
            order.stopLevel = row.stopLevel;
            order.goodUntil = row.goodUntil;
            order.limitLevel = row.limitLevel;
            await AddOrUpdateOrder(order);
        }

        const renderFractionalPrice = (params: GridRenderCellParams) => {
            const showCbotFractions = userStoreInstance.GetFractionalEcbotDisplay();

            if (showCbotFractions && (params.row.insDescription as string).includes("ECBOT")) {
                var fractions = Number(params.value % 1) * 32;
                return Math.floor(params.value) + (fractions > 0 ? fractionUnicode(fractions, 32) : null)
            }
            else {
                return params.row.trailingStopPercentage ? `${params.value}%` : (params.value ? Number(params.value)?.toLocaleString() : null)
            }
        }
        const cols: GridColDef[] = [
            {
                field: "orderTypeString",
                headerName: "Type",
                type: "string",
                flex: 1,
                ...colBase
            },
            {
                field: "orderStatusString",
                headerName: "Status",
                type: "string",
                flex: 1.5,
                ...colBase
            },
            {
                field: "orderVenue",
                headerName: "Venue",
                type: "string",
                flex: 1,
                ...colBase
            },
            {
                field: "insDescription",
                headerName: "Instrument",
                flex: 2.5,
                ...colBase
            },
            {
                field: "openPosition",
                headerName: "Position",
                flex: 1.0,
                type: "number",
                ...colBase
            },
            {
                field: "approvalMessage",
                headerName: "Message",
                type: "string",
                flex: 1,
                ...colBase
            },
            { field: "size", headerName: "Order Size", type: "number", flex: 1.1, editable: true, ...colBase },
            {
                field: "isBuyOrder",
                headerName: "B/S",
                flex: 0.8,
                renderCell: (params: GridRenderCellParams) => Boolean(params.value) ? "Buy" : "Sell",
                renderEditCell: (params: GridRenderEditCellParams) => <div style={{ paddingTop: "5px", width: "100%" }}><BuySellSelector style={{ width: "100px" }} value={params.value ? "Buy" : "Sell"} onChange={(b) => onBuySellSelect(params.id.toString(), b, params.api)} /></div>,
                ...colBase
            },
            { field: "stopLevel", headerName: "Stop", type: "number", flex: 1, editable: true, ...colBase, renderCell: renderFractionalPrice },
            { field: "limitLevel", headerName: "Limit", type: "number", flex: 1, editable: true, ...colBase },
            { field: "trailingStopPercentage", headerName: "Trail Is %?", type: "boolean", flex: 0.5, hide: true, ...colBase },
            { field: "bidPrice", headerName: "Bid", type: "number", flex: 1, editable: false, ...colBase },
            { field: "askPrice", headerName: "Ask", type: "number", flex: 1, editable: false, ...colBase },
            { field: "lastPrice", headerName: "Last", type: "number", flex: 1, editable: false, ...colBase },
            { field: "timeInForce", headerName: "TIF", flex: 1, renderCell: (params: GridRenderCellParams) => TifToString(GoodUntil[Number(params.value)]), ...colBase },
            { field: "lastUpdated", headerName: "Last Update", type: "dateTime", flex: 1, renderCell: (params: GridRenderCellParams) => renderDateCell(params, "GTC"), ...colBase },
            { field: "externalSystemId", headerName: "External Id", type: "string", flex: 1, editable: false, ...colBase, hide: true },
            {
                field: "actions", headerName: "Actions", type: "actions", sortable: false, disableColumnMenu: true, flex: 1.2, width: 200, renderCell: (params: GridRenderCellParams) => <ButtonGroup size="small" variant="text">
                    {canTrade && apiRef.current.current.getRowMode(params.id.toString()) === 'view' && canCancel(params.id.toString()) ? <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Cancel Order</Typography>} >
                        <span><IconButton
                            disabled={isOrderTerminalState(params.id.toString())}
                            //disabled
                            onClick={() => cancelOrderInitial(params.id.toString())}
                            size="medium">
                            <CancelOutlined />
                        </IconButton></span>
                    </Tooltip> : null}
                    {canTrade && apiRef.current.current.getRowMode(params.id.toString()) === 'view' && canDuplicate(params.id.toString()) ? <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Duplicate</Typography>} >
                        <span><IconButton
                            onClick={() => onDuplicate(params.id.toString())}
                            size="medium">
                            <ContentCopyOutlined />
                        </IconButton></span>
                    </Tooltip> : null}
                    {canTrade && canEdit(params.id.toString()) ? <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>{apiRef.current.current.getRowMode(params.id.toString()) === 'edit' ? "Revert" : "Edit"}</Typography>}>
                        <span><IconButton
                            disabled={isOrderTerminalState(params.id.toString())}
                            //disabled
                            onClick={() => { apiRef.current.current.setRowMode(params.id.toString(), apiRef.current.current.getRowMode(params.id.toString()) === 'edit' ? 'view' : 'edit') }}
                            size="medium">
                            {apiRef.current.current.getRowMode(params.id.toString()) === 'edit' ? <RestoreOutlined /> : <EditOutlined />}
                        </IconButton></span>
                    </Tooltip> : null}
                    {!canTrade || apiRef.current.current.getRowMode(params.id.toString()) === 'view' ? null :
                        <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Save Changes</Typography>}>
                            <span><IconButton
                                disabled={isOrderTerminalState(params.id.toString())}
                                //disabled
                                onClick={(event) => handleSaveClick(event, apiRef.current, params.id.toString())}
                                size="medium">
                                <SaveOutlined />
                            </IconButton> </span>
                        </Tooltip>}
                </ButtonGroup>,
                ...colBase
            }];
        setColumns(cols);
    }, [canCancel, canDuplicate, canEdit, canTrade, isOrderTerminalState, onDuplicate, orders])

    function customToolbar(props: any) {
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer>
                    <Grid container direction="row" flexDirection="row" justifyContent="space-between">
                        <Grid container item width="70%" paddingBottom={"3px"} spacing={1}>
                            <Grid item><Button disabled={awaitingRefresh} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<RefreshOutlined />} onClick={() => onClickRefresh()}>Refresh</Button></Grid>
                            <Grid item><GridToolbarExport {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                            <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" style={{ height: "2.4em" }} /></Grid>
                            <Grid item><GridToolbarDensitySelector {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                            <Grid item><GridToolbarColumnsButton {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                            <Grid item><Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={multiSelect ? <DoneOutline /> : <DoneAllOutlined />} onClick={() => setMultiSelect(!multiSelect)}>{multiSelect ? "Single" : "Multi"}</Button></Grid>
                            <Grid item><WrappedSelect
                                id={"showOnlyLiveAndRecent"}
                                name={"showOnlyLiveAndRecent"}
                                label={"Show"}
                                size="small"
                                style={{ height: "2em", marginLeft: "1px", marginTop: "2px", backgroundColor: userStoreInstance.GetTheme().background_color }}
                                value={showOnlyLiveAndRecent}
                                onChange={v => setShowOnlyLiveAndRecent(v.target.value)}>
                                <MenuItem id={"Live"} value="Live">Live</MenuItem>
                                <MenuItem id={"Recent"} value="Recent">Recent</MenuItem>
                                <MenuItem id={"All"} value="All">All</MenuItem>
                            </WrappedSelect></Grid>
                        </Grid>
                        <Grid container item width="20%" justifyContent="flex-end">
                            {EnableOrderEntry && canTrade ? <Grid item>
                                <Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<AddOutlined />} onClick={() => { setUniqueOrderRef(v4()); setShowNewOrder(true) }}>New Order</Button>
                            </Grid> : null}
                            {!canTrade || selectedRows.length <= 1 ? null :
                                <Grid item>
                                    <Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<DeleteOutlined />} onClick={() => cancelOrderInitial("-1")}>Cancel {selectedRows.length}</Button>
                                </Grid>}
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    function onClickRefresh() {
        RefreshOrders();
        setAwaitingRefresh(true);
    }

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    };

    async function confimCancelOrder(orderId: string) {
        setCancelOrderId(undefined);

        if (orderId.toString() === "-1") //multi-mode
        {
            var ordersToDelete = orders.filter(o => selectedRows.includes(o.orderId));
            await CancelOrders(ordersToDelete);
            setSelectedRows([]);
        }
        else {
            var order = orders.filter(o => o.orderId.toString() === orderId)[0];
            if (order)
                await CancelOrder(order);
        }
    }

    function cancelOrderInitial(orderId: string) {
        setCancelOrderId(orderId);
    }

    function onBuySellSelect(orderId: string, isBuy: boolean, api) {
        if (api) {
            api.setEditCellValue({ id: orderId, field: "isBuyOrder", value: isBuy });
        }
    }

    function onSelectionChange(selectionModel: GridSelectionModel, details?: any) {
        var rowsSelected = [...selectionModel];
        setSelectedRows(rowsSelected);
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    async function onShowHistory(orderId: string) {
        setContextMenu(null);
        var oid = Number(orderId);
        setOrderHistoryId(oid);
    }

    const approveOrderQuestion = async (orderId: number) => {
        setApproveOrderId(undefined);
        var o = orders.filter(oo => oo.orderId === orderId)[0];
        if (o) {
            await ApproveOrDenyOrderQuestion(o, true);
        }

    }

    const denyOrderQuestion = async (orderId: number) => {
        setApproveOrderId(undefined);
        var o = orders.filter(oo => oo.orderId === orderId)[0];
        if (o) {
            await ApproveOrDenyOrderQuestion(o, false);
        }
    }

    const setApprovalDialog = (orderId: number, forceApproval: boolean = false) => {
        setContextMenu(null);
        var a = orders.filter(o => o.orderId === orderId)[0]
        if (a) {
            var key = `LastOrderVersionSeen-${a.orderId}`;
            var lastVer = sessionStorage.getItem(key);
            if (forceApproval || !lastVer || isNaN(Number(lastVer)) || Number(lastVer) < a.rowVersion) {
                sessionStorage.setItem(key, a.rowVersion.toString())
                setApproveOrderMessage(a.approvalMessage);
                setApproveOrderId(a.orderId);

            }
        }
    }

    const TifToString = (tif: string) => {
        switch (tif) {
            case "GoodForDay":
                return "Day";
            case "GoodUntilCancelled":
                return "GTC";
            case "ImmediateOrCancel":
                return "IoC";
            case "FillOrKill":
                return "FoK";
            case "Time":
                return "Time";
        }
    }

    if (!columns || columns.length === 0) {
        return <div><CircularProgress /></div>
    }

    var columnVisibility: GridColumnVisibilityModel = {};
    columns.forEach(col => {
        columnVisibility[col.field] = !col.hide;
    });

    var cancelOrderString = cancelOrderId === "-1" ? `Are you sure you want to cancel ${selectedRows.length} orders?` : `Are you sure you want to cancel order ${cancelOrderId}?`
    return (
        <ThemeProvider theme={getFormTheme()}>
            <div className="PositionSummaryTab">
                <Dialog //fullScreen
                    fullWidth
                    maxWidth="xl"
                    style={{ top: "-10%", overflowX: "hidden" }}
                    open={showNewOrder && canTrade}
                    //classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                    onClose={() => setShowNewOrder(false)}>
                    <DialogTitle>
                        <AppBar className="LayoutTopAppBar" position="sticky">
                            <Toolbar variant="dense">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setShowNewOrder(false)}
                                    aria-label="close"
                                    size="large">
                                    <CloseOutlined />
                                </IconButton>
                                <Typography variant="h6">New Order</Typography>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "40vh" }}>{showNewOrder && <OrderEditor orderRefId={uniqueOrderRef} onSubmit={() => setShowNewOrder(false)} gtc={true} initialValue={orderToDuplicate} />}</div>
                    </DialogContent>
                </Dialog>
                <Dialog //fullScreen
                    fullScreen
                    open={orderHistoryId !== undefined}
                    //classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                    onClose={() => setOrderHistoryId(undefined)}>
                    <DialogTitle>
                        <AppBar className="LayoutTopAppBar" position="sticky">
                            <Toolbar variant="dense">
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setOrderHistoryId(undefined)}
                                    aria-label="close"
                                    size="large">
                                    <CloseOutlined />
                                </IconButton>
                                <Typography variant="h6">Order {orderHistoryId} History ({orders?.filter(o => o.orderId === orderHistoryId)[0]?.insDescription})</Typography>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ minHeight: "40vh" }}><OrderHistoryWidget orderId={orderHistoryId} /></div>
                    </DialogContent>
                </Dialog>
                <Dialog key="cancelOrderAreYouSure"
                    open={canTrade && cancelOrderId !== undefined}
                    onClose={() => setCancelOrderId(undefined)}>
                    <DialogTitle>Cancel Order</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">{cancelOrderString}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setCancelOrderId(undefined)} startIcon={<CancelOutlined />}>Abort</Button>
                        <Button onClick={() => confimCancelOrder(cancelOrderId)} startIcon={<DeleteOutlined />}>Proceed</Button>
                    </DialogActions>
                </Dialog>
                <Dialog key="answerOrderApproval"
                    open={canTrade && approveOrderId !== undefined}
                    onClose={() => setApproveOrderId(undefined)}>
                    <DialogTitle>Approve Order {approveOrderId} ({listedInstrumentStoreInstance.getInstrumentById(Number(orders?.filter(o => o.orderId === approveOrderId)[0]?.listedInstrumentId))?.description})</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">{approveOrderMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setApproveOrderId(undefined)} startIcon={<WatchLaterOutlined />}>Postpone</Button>
                        <Button onClick={() => denyOrderQuestion(approveOrderId)} startIcon={<CancelOutlined />}>Deny</Button>
                        <Button onClick={() => approveOrderQuestion(approveOrderId)} startIcon={<DoneOutlineOutlined />}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <DataGridPremium
                    style={{ border: "none" }}
                    initialState={{
                        columns: { columnVisibilityModel: columnVisibility },
                    }}
                    rows={rows}
                    columns={columns}
                    apiRef={apiRef.current}
                    editMode="row"
                    hideFooter
                    disableSelectionOnClick
                    throttleRowsMs={showNewOrder ? 500000 : userStoreInstance.GetLiveThrottleMs() ?? 500}
                    density={userStoreInstance.GetGridDensity()}
                    checkboxSelection={multiSelect}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    onSelectionModelChange={onSelectionChange}
                    treeData
                    groupingColDef={groupingColDef}
                    getTreeDataPath={(row) => row.path.split('/')}
                    components={{
                        Toolbar: customToolbar,
                    }}
                    componentsProps={{
                        row: {
                            onContextMenu: handleContextMenu,
                            style: { cursor: 'context-menu' },
                        },
                    }}
                    classes={{
                        panelWrapper: "GridFilterForm",
                        panel: "GridFilterForm",
                        filterForm: "GridFilterForm",
                        columnsPanel: "GridFilterForm",

                    }} />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            },
                        },
                    }}
                    classes={{ list: "GridOptionMenu" }}>
                    <MenuItem disabled>Order {selectedRow} - {listedInstrumentStoreInstance.getInstrumentById(rows.filter(x => x.id === selectedRow)[0]?.listedInstrumentId)?.description}</MenuItem>
                    {canTrade && selectedRow && canDuplicate(selectedRow.toString()) ? <MenuItem onClick={() => onDuplicate(rows.filter(x => x.id === selectedRow)[0]?.orderId)}>Duplicate</MenuItem> : null}
                    <MenuItem onClick={() => onShowHistory(rows.filter(x => x.id === selectedRow)[0]?.orderId)}>History</MenuItem>
                    {canTrade && selectedRow && canCancel(selectedRow.toString()) ? <MenuItem onClick={() => cancelOrderInitial(selectedRow?.toString())}>Cancel</MenuItem> : null}
                    {canTrade && rows.filter(x => x.id === selectedRow)[0]?.orderStatusString === "PendingManualApproval" ? <MenuItem onClick={() => setApprovalDialog(rows.filter(x => x.id === selectedRow)[0]?.orderId, true)}>Respond</MenuItem> : null}
                    {canTrade && selectedRow ? <MenuItem onClick={() => onRecon(selectedRow?.toString())}>Recon</MenuItem> : null}
                </Menu>
            </div>
        </ThemeProvider>
    );
}