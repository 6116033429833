import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, CircularProgress, Grid, Paper, Tab, Tabs, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { TabContext, TabPanel } from '@mui/lab';
import acceleratorConnectionInstance from '../accelerator/acceleratorConnection';

interface EngineeringEoDReportTabState {
    selectedTabId: number;
    toAddresses: string;
    waiting: boolean;
}

export interface EngineeringEoDReportTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class EngineeringEoDReportTab extends React.Component<EngineeringEoDReportTabProps, EngineeringEoDReportTabState>{
    eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
    constructor(props: EngineeringEoDReportTabProps) {
        super(props)
        this.state = {
            selectedTabId: 0,
            waiting: false,
            toAddresses: "gav@pltfm.app"
        };
        this.runReport = this.runReport.bind(this);
    }

    async componentDidMount() {
    }

    async componentWillUnmount() {
    }

    async runReport() {
        const {toAddresses} = this.state;
        this.setState({ waiting: true })
        await acceleratorConnectionInstance.produceEoDReport(toAddresses);
        this.setState({ waiting: false })
    }

    renderControls() {
        const { waiting, toAddresses } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", width: "calc(100% - 10px)", height: "calc(100% - 10px)" }}>
                <Grid container spacing={2} justifyContent="center" alignContent="center" direction="column">
                    <Grid item container justifyContent="center">
                        <Grid item><TextField
                            variant="standard"
                            size="small"
                            style={{ width: "200px" }}
                            classes={{ root: "ListedInstrumentEditorFormField" }}
                            id="toAddresses"
                            name="toAddresses"
                            label="To"
                            onChange={(e) => this.setState({ toAddresses: e.target.value })}
                            value={toAddresses ?? ""}
                            InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="center"><Grid item><Button variant="outlined" onClick={this.runReport} disabled={waiting}>Run Report</Button></Grid></Grid>
                    {waiting ? <Grid item container justifyContent="center"><Grid item><CircularProgress /></Grid></Grid> : null}
                </Grid>
            </div>
        </ThemeProvider>)
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    render() {
        const { selectedTabId } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistory"}
                        component={Paper}
                        id={"tab0"}
                        label="Calculation" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery1"}
                    value={"0"}
                    //index={0}
                    children={this.renderControls()} />
            </TabContext>
        </ThemeProvider>
        );
    }
}
