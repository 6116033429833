import { Grid, GridDirection, Typography, TypographyVariant } from "@mui/material"
import { FunctionComponent, ReactChild } from "react";
import userStoreInstance from "../../user/userStore";

export type InputSectionProps = {title: string, children?: ReactChild}

export const InputSection : FunctionComponent<InputSectionProps> = ({title, children}) => {
    return (
        <Grid {...rowProps}>
            <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>{title}</Typography></Grid>
            <Grid {...valueProps}>{children}</Grid>
        </Grid>
    )
}


export const rowProps = {
    item: true,
    container: true,
    direction: "row" as GridDirection,
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
    borderBottom: "0.5px dashed",
    borderColor: userStoreInstance.GetTheme().contrastBorderColorLight
};
export const rowPropsLast = {
    item: true,
    container: true,
    direction: "row" as GridDirection,
    paddingTop: "0.25em",
};
export const subRowProps = {
    container: true,
    //direction: "row" as GridDirection, 
    //paddingTop:"0.1em", 
    //paddingBottom:"0.1em", 
    justifyContent: "space-between",
};
export const titleProps = {
    item: true,
    container: true,
    width: 75,
    borderRight: "1px solid",
    direction: "row" as GridDirection,
    alignContent: "flex-start",
    justifyContent: "center",
    paddingRight: "0.5em"
};
export const subTitleProps = {
    item: true,
    borderRight: "1px solid",
    paddingRight: "0.5em",
};
export const valueProps = {
    item: true,
    container: true,
    direction: "row" as GridDirection,
    alignContent: "center",
    justifyContent: "flex-start",
    paddingLeft: "0.5em",
    width: "calc(100% - 75px)",
}
export const subValueProps = {
    item: true,
    paddingLeft: "0.5em",
    paddingBottom: "0.25em",
}
export const subCellProps = {
    //item: true,
    container: true,
    width: "50%",
    direction: "row" as GridDirection,
    alignContent: "center",
    justifyContent: "flex-start",
}
export const titleVariant = "subtitle1" as TypographyVariant;
export const subTitleVariant = "subtitle2" as TypographyVariant;