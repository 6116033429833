import { AcceleratorConfigPackage, AcceleratorConfiguration, AcceleratorLocalSetup } from "./acceleratorModels";
import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { get, post } from "../utils/httpUtils";
import { Tick } from "../marketData/models/tick";
import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";
import acceleratorConnectionInstance from "./acceleratorConnection";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import { ConnectionStatus } from "../connections/connectionStatusModel";
import { UpdateLoadingStatus } from "../loading/loadingActions";
import { LoadingStatus } from "../loading/loadingModels";

export class AcceleratorSettingsEvent extends TypedEvent<AcceleratorConfigPackage> { }
export class AcceleratorClassNamesEvent extends TypedEvent<string[]> { }
export class AcceleratorSettingsFailedEvent extends TypedEvent<string> { }
export class NewTickEvent extends TypedEvent<Tick> { }
export class NewTicksEvent extends TypedEvent<Tick[]> { }
export class NewTickSubscriptionEvent extends TypedEvent<number[]> { }
export class NewTickDeSubscriptionEvent extends TypedEvent<number[]> { }
export class AcceleratorConnectionStatusChangeEvent extends TypedEvent<ConnectionStatus> { }

export async function UpdateAcceleratorSettings() {
    const localSettings = await get<AcceleratorLocalSetup>("Accelerator");
    if (localSettings.payload == null) {
        AppDispatcher.dispatch(new AcceleratorSettingsFailedEvent("Failed to load settings"));
    }
    else {
        const accConfig = await post<AcceleratorConfiguration>("Accelerator", localSettings.payload);
        AppDispatcher.dispatch(new AcceleratorSettingsEvent({ localSetup: localSettings.payload, configuration: accConfig.payload } as AcceleratorConfigPackage));
    }
    return localSettings.payload.localPort;
}

export async function GetAcceleratorClassNames() {
    const names = await get<string[]>("Accelerator/names");
    var success = true;
    if (names.payload == null) {
        AppDispatcher.dispatch(new AcceleratorSettingsFailedEvent("Failed to load accelerator class names"));
        success = false;
    }
    else {
        AppDispatcher.dispatch(new AcceleratorClassNamesEvent(names.payload));
    }

    await UpdateLoadingStatus("Accelerator", success ? LoadingStatus.Completed : LoadingStatus.Failed);
}

export async function SaveAcceleratorSettings(localSettings: AcceleratorLocalSetup, config: AcceleratorConfiguration) {
    const accConfig = await post<AcceleratorConfigPackage>("Accelerator/update", { localSetup: localSettings, configuration: config } as AcceleratorConfigPackage);
    if (accConfig.payload == null) {
        AppDispatcher.dispatch(new AcceleratorSettingsFailedEvent("Failed to load settings"));
    }
    else {
        AppDispatcher.dispatch(new AcceleratorSettingsEvent(accConfig.payload));
    }
}

export function NewTick(tick: Tick) {
    AppDispatcher.dispatch(new NewTickEvent(tick));
}

export function NewTicks(ticks: Tick[]) {
    AppDispatcher.dispatch(new NewTicksEvent(ticks));
}

export async function SubscribeToTicks(ins: ListedInstrument) {
    await acceleratorConnectionInstance.connectIfNeeded();
    if (!ins.underlying && ins.underlyingId)
        ins.underlying = listedInstrumentStoreInstance.getInstrumentById(ins.underlyingId)
    await acceleratorConnectionInstance.subscribeToTicks(ins);
    AppDispatcher.dispatch(new NewTickSubscriptionEvent([ins.listedInstrumentId]));
}

export async function SubscribeToTicksById(insId: number) {
    var ins = listedInstrumentStoreInstance.getInstrumentById(insId);
    if (ins) {
        if (!ins.underlying && ins.underlyingId)
            ins.underlying = listedInstrumentStoreInstance.getInstrumentById(ins.underlyingId)
        await acceleratorConnectionInstance.connectIfNeeded();
        await acceleratorConnectionInstance.subscribeToTicks(ins);
        AppDispatcher.dispatch(new NewTickSubscriptionEvent([ins.listedInstrumentId]));
    }
}

export async function SubscribeToTicksBulk(ins: ListedInstrument[]) {
    await acceleratorConnectionInstance.connectIfNeeded();
    var requestedInsIds = new Array<number>();
    ins.forEach(i => {
        if (i) {
            requestedInsIds.push(i.listedInstrumentId);
            if (!i.underlying && i.underlyingId)
            {
               i.underlying = listedInstrumentStoreInstance.getInstrumentById(i.underlyingId)
               requestedInsIds.push(i.underlyingId);
            }
        }
    });
    await acceleratorConnectionInstance.connectIfNeeded();
    await acceleratorConnectionInstance.subscribeToTicksBulk(ins);
    AppDispatcher.dispatch(new NewTickSubscriptionEvent(requestedInsIds));
}

export async function SubscribeToTicksByIdBulk(insIds: number[]) {
    var ins = insIds.map(insId => listedInstrumentStoreInstance.getInstrumentById(insId)).filter(i => i !== null);
    if (ins) {
        await SubscribeToTicksBulk(ins);
    }
}

export function AcceleratorConnectionStatusChange(status: ConnectionStatus) {
    AppDispatcher.dispatch(new AcceleratorConnectionStatusChangeEvent(status));
}