import { Button, Typography } from '@mui/material';
import { DashboardOutlined, ErrorOutlineOutlined, HandymanOutlined} from '@mui/icons-material';
import React from 'react';
import FBEmitter from 'fbemitter';
import { OrderEditor } from './orderEditor';
import { OrderDashboard } from './orderDashboard';
import layoutStoreInstance from '../layout/layoutStore';
import { OrderAlertsDashboard } from './orderAlertsDashboard';
import { RfqModeDashboard } from './rfqModeDashboard';
import { RfqInstallation } from '../globalConstants';
import { FixingAggregator } from './fixingAggregator';
import userStoreInstance from '../user/userStore';
//import userStoreInstance from '../user/userStore';

interface OrderTabState {
    currentMode: OrderTabMode,
    collapsed: boolean
}

enum OrderTabMode {
    None,
    Dashboard,
    NewOrder,
    Alerts,
    RfqModeDashboard,
    FixingAggregator
}

export interface OrderTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class OrderTab extends React.Component<OrderTabProps, OrderTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: OrderTabProps) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: OrderTabMode = tabModeStr ? OrderTabMode[tabModeStr] : OrderTabMode.Dashboard;
        this.state = {
            currentMode: tabMode,
            collapsed: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case OrderTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case OrderTabMode.Dashboard:
                return <OrderDashboard {...this.props} />
            case OrderTabMode.NewOrder:
                return <OrderEditor />;
            case OrderTabMode.Alerts:
                return <OrderAlertsDashboard />;
            case OrderTabMode.RfqModeDashboard:
                return <RfqModeDashboard />;
            case OrderTabMode.FixingAggregator:
                return <FixingAggregator />;
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: OrderTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: OrderTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    onClick(mode: OrderTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: OrderTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        const canTrade = userStoreInstance.UserHasRole("Trader");

        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>

                                <div style={{ height: "20px" }} />
                                {!RfqInstallation && this.renderButton(OrderTabMode.Dashboard, <DashboardOutlined />, "Dashboard")}
                                {RfqInstallation && this.renderButton(OrderTabMode.RfqModeDashboard, <DashboardOutlined />, "Dashboard")}

                                {/* {EnableOrderEntry && canTrade ?
                                    <React.Fragment>
                                        <div style={{ height: "20px" }} />
                                        {this.renderButton(OrderTabMode.NewOrder, <SmsOutlined />, "New Order")}
                                    </React.Fragment>
                                    : null} */}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(OrderTabMode.Alerts, <ErrorOutlineOutlined />, "Alerts")}

                                <div style={{ height: "20px" }} />
                                {RfqInstallation && canTrade && this.renderButton(OrderTabMode.FixingAggregator, <HandymanOutlined />, "Fixings")}

                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}