export function deferred() {
	var deferred = {
		promise: null,
		resolve: null,
		reject: null
	};

	deferred.promise = new Promise((resolve, reject) => {
		deferred.resolve = resolve;
		deferred.reject = reject;
	});

	return deferred;
}

export function typedDeferred<T>() {
	var deferred = {
		promise: null,
		resolve: null,
		reject: null
	} as DeferredParams<T>;

	deferred.promise = new Promise((resolve : (value: T) => void, reject: (reason?: any) => void) => {
		deferred.resolve = resolve;
		deferred.reject = reject;
	});

	return deferred;
}

export interface DeferredParams<T>{
    promise: Promise<T>,
    resolve: (value: T) => void,
    reject: (reason?: any) => void
}