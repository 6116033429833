export enum MatronFatFingerControlType {
    PerOrder,
    PerHour,
    PerDay,
    PerWeek,
    PerMonth,
    OpenPosition,
}

export enum MatronFatFingerLimitType {
    Contracts,
    Notional
}

export enum MatronFatFingerInstrumentScope {
    All,
    Spot,
    Futures,
    Options,
}

export interface MatronFatFingerRecord {
    recordId: number;
    instrumentId: number;
    limit: number;
    limitType: MatronFatFingerLimitType;
    controlType: MatronFatFingerControlType;
    limitScope : MatronFatFingerInstrumentScope;
    deleted: boolean;
    isSoftLimit: boolean;
}

export interface MatronTradingDefaults {
    defaultAccount : string;
    defaultToOutsideRth: boolean;
    suppressIbPriceCapWarning: boolean;
    suppressIbPriceDeviationWarning: boolean;
    suppressIbMarketDataWarning: boolean;
    suppressIbUnitSizeLimit: boolean;
    suppressIbValueLimit: boolean;
    suppressStopWarning:  boolean;
    suppressIbMarketOrderWarning: boolean;
}

export interface MatronSettingsStoreState {
    fatFingerSettings: MatronFatFingerRecord[]
    tradingDefaults: MatronTradingDefaults
}