import React from 'react';
import FBEmitter from 'fbemitter';
import * as Common from './productDesignerCommon';
import AssetsStore from '../../assets/assetsStore';
import { AssetsState, AssetClass, AssetGroup, Asset } from '../../assets/assetsModels';
//import { AssetGroupDropDown, AssetClassDropdown, AssetDropDown, PayoffDropDown, PeriodControl, CurrencyDropDown } from '../../inputs/assetGroupDropDown';
import { Payoff, Period } from '../../payoff/payoffModels';
import payoffStoreInstance from '../../payoff/payoffStore';
import * as Product from '../../product/productModels';
import { ProductSpec, CommodityVanillaLeg, ScheduleRow, TradeSpec, CommodityVanillaPayoff } from '../../product/productModels'
import { get, post, put } from '../../utils/httpUtils';
import { PricerResponseBatch, PricerRequest } from '../../pricer/pricerModels';
import { OptionType } from '../../qwack/optionType';
import { QuickRequestResponse } from '../quickRequestBar/quickRequestModels';
import { AddLayoutObject } from '../../layout/layoutActions';
import productStoreInstance from '../../product/productStore';
import { FixingIndex } from '../../assets/assetsModels'
import assetsStoreInstance from '../../assets/assetsStore';
//import { PltfmGrey } from '../../globalConstants';

export type ProductDesignerProps = {
    onClose: () => void,
    name: string,
    uniqueKey: string,
    pricerMode: boolean,
    counterpartyId: string
}

export type ProductDesignerFxState = {
    isAtc: boolean,
    fxFixingIndex: string,
    commonPricing: boolean,
    settlementCalendars: string,
}

export type ProductDesignerLeg = {
    selectedStartPeriod: Period | undefined,
    selectedEndPeriod: Period | undefined,
    scheduleRows: ScheduleRow[],
    selectedAssetClass: AssetClass | undefined;
    selectedAssetGroup: AssetGroup | undefined;
    selectedAsset: Asset | undefined;
    size: number | undefined,
    strike: number | undefined,
    callPut: OptionType | undefined,
    periodIsStrip: boolean;
    currency: string;
    units: string;
    optionPanelVisible: boolean;
    fxPanelVisible: boolean;
    fxState: ProductDesignerFxState | undefined;
    priceAsOne: boolean;
    sizeProfile: boolean;
    availableUnits: string[];
    assetDetailVisible: boolean;
    assetFixingIndexes: FixingIndex[];
    selectedAssetFixingIndex: FixingIndex | undefined;
    conversionFactors: Product.ConversionFactors | undefined;
}
export type ProductDesignerState = {
    products: AssetsState;
    allAssets: Asset[];
    allAssetGroups: AssetGroup[];
    payoffs: Payoff[],
    periods: Period[],
    selectedPayoff: Payoff | undefined,
    readyToCreate: boolean;
    uniqueKey: string;
    requested: boolean;
    legs: ProductDesignerLeg[];
}

//export const AssetSuggest = Suggest.ofType<Asset>();

export const defaultSize = 100;

export const NullAssetClass = {
    name: "Select Class...",
    assetClassId: -1,
    disabled: false
} as AssetClass

export const NullAssetGroup = {
    name: "Select Group...",
    assetGroupId: -1,
    disabled: false
} as AssetGroup

export const NullAsset = {
    name: "Select Asset...",
    assetId: -1,
    disabled: false
} as Asset

export const NullPayoff = {
    name: "Select Payoff...",
    payoffId: -1,
    disabled: false
} as Payoff

export class ProductDesigner extends React.Component<ProductDesignerProps, ProductDesignerState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: ProductDesignerProps) {
        super(props);
        this.state = {
            products: AssetsStore.getState(),
            allAssets: [],
            allAssetGroups: [],
            payoffs: [],
            periods: [],
            selectedPayoff: undefined,
            readyToCreate: false,
            uniqueKey: props.uniqueKey,
            requested: false,
            legs: new Array({
                selectedAssetGroup: { name: '', assets: [], assetGroupId: 0, disabled: false },
                selectedAssetClass: { assetClassId: 0, name: '', assetGroups: [], disabled: false, fixingIndexes: [] },
                selectedAsset: { assetId: 0, disabled: false, assetMetaData: undefined } as Asset,
                scheduleRows: [],
                selectedStartPeriod: undefined,
                selectedEndPeriod: undefined,
                priceAsOne: false,
                sizeProfile: false,
                conversionFactors: undefined,
                currency: "USD",
                units: "",
                availableUnits: [],
                assetDetailVisible: false,
                assetFixingIndexes: [],
                selectedAssetFixingIndex: undefined,
                size: undefined,
                strike: undefined,
                callPut: undefined,
                periodIsStrip: false,
                optionPanelVisible: false,
                fxPanelVisible: false,
                fxState: undefined,
            })
        }

        this.handleStrikeValueChange = this.handleStrikeValueChange.bind(this);
        this.interceptMagicStrikes = this.interceptMagicStrikes.bind(this);
        this.callPutChange = this.callPutChange.bind(this);
        this.onClickRFQ = this.onClickRFQ.bind(this);
        this.onClickStripGenerate = this.onClickStripGenerate.bind(this);
        this.onChangeGeneratorOption = this.onChangeGeneratorOption.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.onChangeUnits = this.onChangeUnits.bind(this);
        this.getUnits = this.getUnits.bind(this);
        this.onChangePeriodType = this.onChangePeriodType.bind(this);
        this.onPayoffChange = this.onPayoffChange.bind(this);
    }

    private onProductsChange = () => {
        var products = AssetsStore.getState();
        products.AssetInfos = [NullAssetClass, ...products.AssetInfos];

        this.setState({ products: products });
    }

    public componentWillMount() {
        this.eventSubscriptionProducts = AssetsStore.addChangeListener(this.onProductsChange);
    }

    public componentWillUnmount() {
        if (this.eventSubscriptionProducts !== undefined) {
            this.eventSubscriptionProducts.remove();
        }
    }

    async componentDidMount() {
        if (this.state.products && this.state.products.AssetInfos.length > 0) {
            const { products } = this.state;
            let payoffs = [NullPayoff, ...payoffStoreInstance.getPayoffs()]
            let periods = payoffStoreInstance.getPeriods(
                products.AssetInfos[0].assetGroups[0].assets[0].assetId,
                products.AssetInfos[0].assetGroups[0].assetGroupId,
                products.AssetInfos[0].assetClassId,
                payoffs[0].name);

            let leg0 = this.state.legs[0];
            leg0.scheduleRows = await this.generateStripSchedule(periods[2].startDate, periods[2].endDate);

            let allAssets = new Array<Asset>();
            let allAssetGroups = new Array<AssetGroup>();
            products.AssetInfos.forEach(ai => {
                ai.assetGroups.forEach(ag => {
                    allAssetGroups.push(ag);
                    ag.assets.forEach(a => {
                        allAssets.push(a);
                    });
                });
            });

            leg0.selectedAssetClass = products.AssetInfos[1];
            leg0.selectedAssetGroup = products.AssetInfos[1].assetGroups[0];
            leg0.selectedAsset = products.AssetInfos[1].assetGroups[0].assets[0];
            leg0.selectedStartPeriod = periods[2];
            leg0.selectedEndPeriod = periods[2];
            leg0.currency = products.AssetInfos[1].assetGroups[0].assets[0].defaultCcy;
            leg0.units = products.AssetInfos[1].assetGroups[0].assets[0].defaultUnits;
            this.getUnits(products.AssetInfos[1].assetGroups[0].assets[0], 0);
            this.setState(
                {
                    allAssets: allAssets,
                    allAssetGroups: allAssetGroups,
                    selectedPayoff: payoffs[1],
                    payoffs: payoffs,
                    periods: periods,
                    legs: [leg0]
                }
            );

            this.onAssetChange(products.AssetInfos[1].assetGroups[0].assets[0], 0);
        }
    }

    private async generateStripSchedule(startDate: Date | null, endDate: Date | null) {
        let initialPeriods = await get<Period[]>(`Payoff/renderStrip/${startDate}/${endDate}`);
        if (initialPeriods.payload) {
            let rows = initialPeriods.payload.map<ScheduleRow>(p => { return { period: p, quantity: 1 } as ScheduleRow; })
            return rows;
        }
        return new Array<ScheduleRow>();
    }

    public componentDidUpdate() {
        //this.props.onStateChange(this.state);
    }

    getUnits(asset: Asset | undefined, legId: number) {
        if (!asset)
            asset = this.state.legs[legId].selectedAsset;

        if (asset) {
            let units = productStoreInstance.getConversionFactorsForAsset(asset.assetId);
            if (units) {
                if (units.factorsForAsset.filter(f => asset && f.unitName === asset.defaultUnits).length === 0)
                    units.factorsForAsset.push({ unitName: asset.defaultUnits, factor: 1 });
            }
            else {
                units = { factorsForAsset: Array<Product.ConversionFactor>(), assetId: asset.assetId };
                units.factorsForAsset.push({ unitName: asset.defaultUnits, factor: 1 });
            }
            let unitKeys = units.factorsForAsset.map(u => u.unitName);
            let legs = this.state.legs;
            legs[legId].conversionFactors = units;
            legs[legId].availableUnits = unitKeys;

            this.setState({ legs: legs });
        }
    }

    onAssetClassChange = (assetClass: AssetClass | undefined, legId: number) => {
        if (assetClass) {
            var payoffs = payoffStoreInstance.getPayoffs();
            var periods = payoffStoreInstance.getPeriods(assetClass.assetGroups[0].assets[0].assetId, assetClass.assetGroups[0].assetGroupId, assetClass.assetClassId, payoffs[0].name);
            let legs = this.state.legs;
            legs[legId].selectedAssetClass = assetClass;
            legs[legId].selectedAssetGroup = assetClass.assetGroups[0];
            legs[legId].selectedAsset = assetClass.assetGroups[0].assets[0];
            legs[legId].selectedStartPeriod = periods[2];
            legs[legId].selectedEndPeriod = periods[5];

            this.setState(
                {
                    legs: legs,
                    payoffs: payoffs,
                    selectedPayoff: payoffs[0],
                    periods: periods,
                    readyToCreate: this.readyToCreate()
                });
        }
        else {
            let legs = this.state.legs;
            legs[legId].selectedAssetClass = assetClass;
            legs[legId].selectedAssetGroup = undefined;
            legs[legId].selectedAsset = undefined;
            this.setState({ legs: legs, readyToCreate: false });
        }
    }

    onAssetGroupChange = (underlyingCategory: AssetGroup | undefined, legId: number) => {
        if (underlyingCategory) {
            let legs = this.state.legs;
            legs[legId].selectedAssetGroup = underlyingCategory;
            legs[legId].selectedAsset = underlyingCategory.assets[0];
            this.setState({ legs: legs, readyToCreate: this.readyToCreate() });
        }
        else {
            let legs = this.state.legs;
            legs[legId].selectedAssetGroup = underlyingCategory;
            legs[legId].selectedAsset = undefined;
            this.setState(
                {
                    legs: legs,
                    readyToCreate: false
                });

        }
    }

    onAssetChange = (asset: Asset | undefined, legId: number) => {
        let legs = this.state.legs;
        legs[legId].selectedAsset = asset;
        const { selectedAsset, selectedAssetClass, selectedAssetGroup, units } = this.state.legs[legId];
        let payoffs: Payoff[] = [];
        if (selectedAsset && selectedAssetClass && selectedAssetGroup) {
            payoffs = payoffStoreInstance.getPayoffs();
        }
        this.getUnits(asset, legId);
        let fixingIndexes = assetsStoreInstance.getFixingIndexesForAsset(asset ? asset.assetId : -1);
        legs[legId].units = asset ? asset.defaultUnits : units;
        legs[legId].assetFixingIndexes = fixingIndexes;
        this.setState({ payoffs: payoffs, selectedPayoff: payoffs[0], legs: legs, readyToCreate: this.readyToCreate() });
    }

    async onPayoffChange(payoff: Payoff | undefined) {
        this.setState({ selectedPayoff: payoff });
        let legs = this.state.legs;
        const { selectedPayoff, products } = this.state;

        if (payoff) {
            switch (payoff.name) {
                case "Swap":
                case "AsianOption":
                    if (legs.length !== 1)
                        legs = [legs[0]];
                    break;
                case "BasisSwapCalendar":
                    let newLegCal = {
                        selectedAssetClass: legs[0].selectedAssetClass,
                        selectedAssetGroup: legs[0].selectedAssetGroup,
                        selectedAsset: legs[0].selectedAsset,
                        selectedStartPeriod: legs[0].selectedStartPeriod,
                        selectedEndPeriod: legs[0].selectedEndPeriod,
                        currency: legs[0].selectedAsset ? legs[0].selectedAsset.defaultCcy : "",
                        units: legs[0].selectedAsset ? legs[0].selectedAsset.defaultUnits : "",
                        scheduleRows: await this.generateStripSchedule(legs[0].selectedStartPeriod ? legs[0].selectedStartPeriod.startDate : null, legs[0].selectedEndPeriod ? legs[0].selectedEndPeriod.endDate : null)
                    } as ProductDesignerLeg;
                    if (legs.length === 1)
                        legs = [legs[0], newLegCal];
                    break;
                case "BasisSwapCommodity":
                    let newLegCom = {
                        selectedAssetClass: products.AssetInfos[1],
                        selectedAssetGroup: products.AssetInfos[1].assetGroups[0],
                        selectedAsset: products.AssetInfos[1].assetGroups[0].assets[0],
                        selectedStartPeriod: legs[0].selectedStartPeriod,
                        selectedEndPeriod: legs[0].selectedEndPeriod,
                        currency: products.AssetInfos[1].assetGroups[0].assets[0].defaultCcy,
                        units: products.AssetInfos[1].assetGroups[0].assets[0].defaultUnits,
                        scheduleRows: await this.generateStripSchedule(legs[0].selectedStartPeriod ? legs[0].selectedStartPeriod.startDate : null, legs[0].selectedEndPeriod ? legs[0].selectedEndPeriod.endDate : null)
                    } as ProductDesignerLeg;
                    if (legs.length === 1)
                        legs = [legs[0], newLegCom];
                    break;
            }
        }
        let periods: Period[] = [];

        legs.forEach(leg => {
            const { selectedAsset, selectedAssetClass, selectedAssetGroup, selectedStartPeriod, selectedEndPeriod } = leg;
            let periodsForLeg: Period[] = [];
            if (selectedAsset && selectedAssetClass && selectedAssetGroup && selectedPayoff) {
                periodsForLeg = payoffStoreInstance.getPeriods(selectedAsset.assetId, selectedAssetGroup.assetGroupId, selectedAssetClass.assetClassId, selectedPayoff.name);
            }
            if (leg === legs[0]) {
                periods = periodsForLeg;
            }
            else {
                periods = periods.filter(value => periodsForLeg.includes(value));
            }
            leg.selectedStartPeriod = selectedStartPeriod ? selectedStartPeriod : periods[0];
            leg.selectedEndPeriod = selectedEndPeriod ? selectedEndPeriod : periods[1];
            leg.optionPanelVisible = payoff !== undefined && payoff.name === "AsianOption";
        });

        this.setState(
            {
                periods: periods,
                readyToCreate: this.readyToCreate(),
                legs: legs
            });
    }

    onScheduleRowsChange = (rows: ScheduleRow[], legId: number) => {
        let legs = this.state.legs;
        legs[legId].scheduleRows = rows;
        this.setState({ legs: legs, readyToCreate: this.readyToCreate() });
    }

    onStartEndPeriodChange = (period: Period | undefined, isStartPeriod: boolean, legId: number) => {
        let legs = this.state.legs;
        if (isStartPeriod) {
            legs[legId].selectedStartPeriod = period;
        }
        else {
            legs[legId].selectedEndPeriod = period;
        }
        this.setState({ legs: legs, readyToCreate: this.readyToCreate() });
    }

    readyToCreate = (): boolean => {
        let readyToCreate = this.state.selectedPayoff !== undefined &&
            this.state.selectedPayoff.name === "Swap";
        this.state.legs.forEach(leg => {
            readyToCreate = readyToCreate &&
                leg.selectedAsset !== undefined &&
                leg.selectedAssetClass !== undefined &&
                leg.selectedAssetGroup !== undefined &&
                leg.scheduleRows.length > 0 &&
                leg.size !== undefined;
        });
        return readyToCreate;
    }

    handleSizeValueChange = (valueAsNumber: number, valueAsString: string, legId: number) => {
        let result = valueAsString;
        result = Common.expandNumberAbbreviationTerms(result);
        result = Common.nanStringToEmptyString(result);
        var k = parseFloat(result).valueOf();
        if (!isNaN(k)) {
            let legs = this.state.legs;

            let rows = legs[legId].scheduleRows.map<ScheduleRow>(r => {
                return { period: r.period, quantity: 1, fixingCalendars: r.fixingCalendars, settlement: r.settlement, strike: r.strike, units: r.units };
            });
            legs[legId].scheduleRows = rows;
            legs[legId].size = k;
            this.setState({ legs: legs, readyToCreate: this.readyToCreate() });
        }
    }

    async interceptMagicStrikes(strikeCode: string, legId: number): Promise<number> {
        var specs = new Array<ProductSpec>(this.getProductSpec());
        if (specs[0].commodityVanilla.type === "AsianOption")
            specs[0].commodityVanilla.type = "AsianSwap";
        var request = {
            currency: this.state.legs[legId].currency,
            rfqs: specs.map(s => {
                return {
                    productSpec: s,
                    size: 1,
                    uniqueId: this.props.uniqueKey
                } as TradeSpec;
            }),
            valDate: (new Date()).toISOString()
        } as PricerRequest;

        let response = await put<PricerResponseBatch>('Pricing/parRate', request);
        if (response.payload) {
            return parseFloat(response.payload.responses[0].pv.toPrecision(8)).valueOf();
        }
        return 0;
    }

    async handleStrikeValueChange(valueAsNumber: number, valueAsString: string, legId: number) {

        if (valueAsString.toLowerCase() === "f") {
            var par = await this.interceptMagicStrikes(valueAsString, legId);
            let legs = this.state.legs;
            legs[legId].strike = par;
            this.setState({ legs: legs, readyToCreate: this.readyToCreate() });
            return;
        }
        let result = valueAsString;
        result = Common.expandNumberAbbreviationTerms(result);
        result = Common.nanStringToEmptyString(result);

        var k = parseFloat(result).valueOf();
        if (!isNaN(k)) {
            let legs = this.state.legs;
            legs[legId].strike = k;
            this.setState({ legs: legs, readyToCreate: this.readyToCreate() });
        }
    }

    onChangePeriodType(selected: string | undefined, legId: number) {
        const { legs, periods } = this.state;
        let leg = legs[legId];
        leg.periodIsStrip = selected === "Strip";
        if (leg.periodIsStrip && leg.selectedStartPeriod && leg.selectedStartPeriod === leg.selectedEndPeriod) {
            let existingPeriodIx = periods.indexOf(leg.selectedStartPeriod);
            leg.selectedEndPeriod = periods[existingPeriodIx + 1];
        }
        this.setState({ legs: legs });
    }



    public getProductSpec = (): ProductSpec => {
        let legId = 0;
        let spec = { commodityVanilla: { uniqueKey: this.state.uniqueKey } as CommodityVanillaPayoff } as ProductSpec;
        const { selectedPayoff } = this.state;
        const { strike, selectedAsset, scheduleRows, currency, callPut } = this.state.legs[legId];
        if (selectedAsset && selectedPayoff) {
            const assetId = selectedAsset?.assetId // ? selectedAsset.altNames.split(',')[0] : "";
            switch (selectedPayoff.name) {
                case "Swap":
                case "BasisSwapCalendar":
                case "AsianOption":
                    spec.commodityVanilla.type = selectedPayoff.name === "Swap" ? "AsianSwap" : selectedPayoff.name;
                    spec.commodityVanilla.legs = new Array<CommodityVanillaLeg>();
                    spec.commodityVanilla.legs.push({
                        assetId: assetId,
                        fixingIndex: undefined,
                        currency: currency,
                        isCall: callPut && callPut.toString() === "Call" ? true : false,
                        strike: strike ? strike : 0,
                        uniqueKey: "",
                        fxConvention: undefined,
                        schedule: scheduleRows,
                        settlement: undefined,
                        type: "AsianSwapLeg",
                        period: undefined,
                        periodType: Product.PeriodType.Custom
                    });
                    break;
            }
        }

        return spec;
    }

    public getTradeSpec = (): TradeSpec => {
        let legId = 0;
        let ps = this.getProductSpec();
        const { size, units, strike } = this.state.legs[legId];
        return {
            productSpec: ps,
            size: size,
            units: units,
            price: strike,
            uniqueId: this.props.uniqueKey
        } as TradeSpec;
    }

    async onClickRFQ() {
        let legId = 0;
        this.setState({ requested: true });
        const { size, units } = this.state.legs[legId];
        var rfq = this.getProductSpec();

        let value = await post<QuickRequestResponse[]>('QuickRequestParser/createRfqFromProduct', rfq);
        if (value.payload && value.payload[0] && value.payload[0].couldParse) {
            /* if (periodIsStrip && !priceAsOne) {
                var rowProps = value.payload.map<PricingLadderElementProps>(p => {
                    return {
                        rowProductId: parseFloat(p.productId).valueOf(),
                        period: p.period,
                        rowRfqIdBid: 0,
                        rowRfqIdAsk: 0,
                    };

                });

                if (selectedPayoff && selectedPayoff.name === "AsianOption") {

                    var ladderPropsOption = {
                        rows: rowProps,
                        assetId: rfq.legs[0].assetId,
                        size: size,
                        units: units
                    } as PricingLadderProps;
                    AddLayoutObject(0, "PricingLadder", ladderPropsOption);
                }
                else {
                    var ladderProps = {
                        rows: rowProps,
                        assetId: rfq.legs[0].assetId,
                        size: size,
                        units: units
                    } as PricingLadderProps;
                    AddLayoutObject(0, "PricingLadder", ladderProps);
                }
            }
            else { */
            AddLayoutObject(0, "PricingBox", { productId: value.payload[0].productId, size: size, units: units })
            //}
            this.setState({ requested: false });
            this.props.onClose();
        }
    }


    callPutChange(legId: number) {
        let legs = this.state.legs;
        legs[legId].callPut = this.state.legs[legId].callPut === OptionType.call ? OptionType.put : OptionType.call;
        this.setState({ legs: legs });
    }

    renderOptionPanel(legId: number) {
        let leg = this.state.legs[legId];
        if (!leg.optionPanelVisible) return null;

        // return (
        //     <table className="ProductDesignerTables">
        //         <thead />
        //         <tbody>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Strike</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}><Tooltip content="Use f to calcualte atm-forward strike">
        //                     <NumericInput
        //                         fill={true}
        //                         key={this.state.uniqueKey + "strike"}
        //                         id="rfq-strike"
        //                         placeholder="100"
        //                         onValueChange={(valueAsNumber: number, valueAsString: string) => this.handleStrikeValueChange(valueAsNumber, valueAsString, legId)}
        //                         value={leg.strike}
        //                         allowNumericCharactersOnly={false} />
        //                 </Tooltip></td>
        //             </tr>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Call/Put</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        //                     <Switch
        //                         key={this.state.uniqueKey + "callPut"}
        //                         id="rfq-cp"
        //                         checked={leg.callPut === OptionType.call}
        //                         innerLabel="Put"
        //                         innerLabelChecked="Call"
        //                         onChange={() => this.callPutChange(legId)} />
        //                 </td>
        //             </tr>
        //         </tbody>
        //     </table>
        // );
    }

    renderExtraStrikePanel(legId: number) {
        let leg = this.state.legs[legId];
        if (leg.optionPanelVisible || !this.props.pricerMode) return null;

        // return (
        //     <table className="ProductDesignerTables">
        //         <thead />
        //         <tbody>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Strike</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}><Tooltip content="Use f to calcualte atm-forward strike">
        //                     <NumericInput
        //                         fill={true}
        //                         key={this.state.uniqueKey + "strike"}
        //                         id="rfq-strike"
        //                         placeholder="100"
        //                         onValueChange={(valueAsNumber: number, valueAsString: string) => this.handleStrikeValueChange(valueAsNumber, valueAsString, legId)}
        //                         value={leg.strike}
        //                         allowNumericCharactersOnly={false} />
        //                 </Tooltip></td>
        //             </tr>
        //         </tbody>
        //     </table>
        // );
    }

    renderFxPanel(legId: number) {
        let leg = this.state.legs[legId];
        if (!leg.fxPanelVisible) return null;
        if (!leg.fxState) return null;
        // return (
        //     <table className="ProductDesignerTables">
        //         <thead />
        //         <tbody>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Conversion</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}><Switch key={this.state.uniqueKey + "fxatc"} id="rfq-atc" checked={leg.fxState.isAtc} innerLabel="Convert-Then-Average" innerLabelChecked="Average-Then-Convert" /></td>
        //             </tr>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Common Pricing</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}><Switch key={this.state.uniqueKey + "fxcommon"} id="rfq-commonfx" checked={leg.fxState.commonPricing} innerLabel="Non-Common Pricing" innerLabelChecked="Common Pricing" /></td>
        //             </tr>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Fx Fixing Index</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}>(Placeholder)</td>
        //             </tr>
        //             <tr>
        //                 <td className="ProductDesignerLeftTitles">Setttle Calendars</td>
        //                 <td style={{ textAlign: "center", verticalAlign: "middle" }}>(Placeholder)</td>
        //             </tr>
        //         </tbody>
        //     </table>
        // );
    }

    onChangeCurrency = (ccy: string | undefined, legId: number) => {
        if (ccy) {
            let legs = this.state.legs;
            legs[legId].currency = ccy;
            this.setState({ legs: legs });
        }
    }

    onChangeUnits = (units: string | undefined, legId: number) => {
        if (units) {
            let legs = this.state.legs;
            legs[legId].units = units;
            this.setState({ legs: legs });
        }
    }

    onChangeAssetFixingIndex = (indexName: string | undefined, legId: number) => {
        if (indexName) {
            let legs = this.state.legs;
            var index = legs[legId].assetFixingIndexes.filter(af => af.name === indexName)[0];
            if (index) {
                legs[legId].selectedAssetFixingIndex = index;
                this.setState({ legs: legs });
            }
        }
    }

    onToggleAssetDetailVisible = (legId: number) => {
        let legs = this.state.legs;
        legs[legId].assetDetailVisible = !legs[legId].assetDetailVisible;
        this.setState({ legs: legs });
    }

    // private renderInputValue = (asset: Asset) => asset.description;
    // private handleSuggestValueChange = (asset: Asset, legId: number) => {
    //     let legs = this.state.legs;
    //     var newGroup = this.state.allAssetGroups.filter(ag => ag.assets.some(a => a === asset))[0];
    //     var newClass = this.state.products.AssetInfos.filter(ac => ac.assetGroups.some(ag => ag === newGroup))[0];
    //     legs[legId].selectedAsset = asset;
    //     legs[legId].selectedAssetClass = newClass;
    //     legs[legId].selectedAssetGroup = newGroup;
    //     this.setState({ legs: legs });
    //     this.onAssetChange(asset, legId);
    // };
    // private isValueSelected(value: Asset, legId: number) { return this.state.legs[legId].selectedAsset === value; }
    // private renderValue = (value: Asset, { modifiers, handleClick }: IItemRendererProps, legId: number) => {
    //     if (!modifiers.matchesPredicate) {
    //         return null;
    //     }
    //     return (
    //         <MenuItem id={"value" + value.assetId}
    //             active={modifiers.active}
    //             icon={this.isValueSelected(value, legId) ? "tick" : "blank"} //TODO - leg hardcoded as zero
    //             key={value.assetId}
    //             text={value.description}
    //             placeholder="Select asset..."
    //             onClick={handleClick}
    //             shouldDismissPopover={false}
    //         />
    //     );
    // };

    // private filterAssetList: ItemPredicate<Asset> = (query, asset, _index, exactMatch) => {
    //     const normalizedTitle = asset.description.toLowerCase();
    //     const normalizedQuery = query.toLowerCase();

    //     if (exactMatch) {
    //         return normalizedTitle === normalizedQuery;
    //     } else {
    //         return `${asset.assetId}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    //     }
    // };

    // private areAssetsEqual(assetA: Asset, assetB: Asset) {
    //     return assetA.assetId === assetB.assetId;
    // }

    renderAssetPanel(legId: number) {
        //const { selectedAssetClass, selectedAssetGroup, selectedAsset, currency, units, assetDetailVisible, assetFixingIndexes, selectedAssetFixingIndex, availableUnits } = this.state.legs[legId];
        // return (<div><table className="ProductDesignerTables">
        //     <thead />
        //     <tbody>
        //         <tr>
        //             <td className="ProductDesignerLeftTitles">Asset</td>
        //             <td style={{ textAlign: "left", verticalAlign: "middle", width: "200px" }}>
        //                 <AssetSuggest
        //                     fill={true}
        //                     items={this.state.allAssets}
        //                     itemsEqual={this.areAssetsEqual}
        //                     inputValueRenderer={this.renderInputValue}
        //                     itemRenderer={(asset, itemRenderProps) => this.renderValue(asset, itemRenderProps, legId)}
        //                     onItemSelect={(item: Asset) => this.handleSuggestValueChange(item, legId)}
        //                     popoverProps={{ minimal: true, position: "right" }}
        //                     selectedItem={selectedAsset}
        //                     itemPredicate={this.filterAssetList}
        //                     inputProps={{ round: true, style: { textAlign: "center" } }}

        //                 /></td>
        //             <td><Button minimal={true} icon={assetDetailVisible ? "chevron-up" : "chevron-down"} onClick={() => this.onToggleAssetDetailVisible(legId)} /></td>
        //         </tr>
        //     </tbody>
        // </table>
        //     {
        //         !assetDetailVisible ? null :
        //             <table className="ProductDesignerTables">
        //                 <thead />
        //                 <tbody>
        //                     <tr>
        //                         <td className="ProductDesignerLeftTitles">Class</td>
        //                         <td><AssetClassDropdown selectedAssetClass={selectedAssetClass} assetClasses={this.state.products.AssetInfos} onChange={(assetClass) => this.onAssetClassChange(assetClass, legId)} disabled={false} /></td>
        //                     </tr>
        //                     <tr>
        //                         <td className="ProductDesignerLeftTitles">Category</td>
        //                         <td><AssetGroupDropDown assetGroups={selectedAssetClass ? selectedAssetClass.assetGroups : []} selectedAssetGroup={selectedAssetGroup} onChange={(assetGroup) => this.onAssetGroupChange(assetGroup, legId)} disabled={false} /></td>
        //                     </tr>
        //                     <tr>
        //                         <td className="ProductDesignerLeftTitles">Asset</td>
        //                         <td><AssetDropDown assets={selectedAssetGroup ? selectedAssetGroup.assets : []} selectedAsset={selectedAsset} onChange={(asset) => this.onAssetChange(asset, legId)} disabled={false} /></td>
        //                     </tr>
        //                     <tr>
        //                         <td className="ProductDesignerLeftTitles">Fixing</td>
        //                         <td><CurrencyDropDown key="ccyDrop" currencies={assetFixingIndexes.map(a => a.name)} onChange={(index) => this.onChangeAssetFixingIndex(index, legId)} selectedCurrency={selectedAssetFixingIndex ? selectedAssetFixingIndex.name : undefined} disabled={false} /></td>
        //                     </tr>
        //                     <tr>
        //                         <td className="ProductDesignerLeftTitles">Currency</td>
        //                         <td><CurrencyDropDown key="ccyDrop" currencies={productStoreInstance.getAvailableCurrencies()} onChange={(ccy) => this.onChangeCurrency(ccy, legId)} selectedCurrency={currency} disabled={false} /></td>
        //                     </tr>
        //                     <tr>
        //                         <td className="ProductDesignerLeftTitles">Units</td>
        //                         <td><CurrencyDropDown key="unitsDrop" currencies={availableUnits} onChange={(units) => this.onChangeUnits(units, legId)} selectedCurrency={units} disabled={false} /></td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //     }<Divider /></div>)
    }


    renderPayoffPanel() {
        // const { selectedPayoff, payoffs } = this.state;
        // return (<div><table className="ProductDesignerTables">
        //     <thead />
        //     <tbody>
        //         <tr>
        //             <td className="ProductDesignerLeftTitles">Payoff</td>
        //             <td><PayoffDropDown payoffs={payoffs} selectedPayoff={selectedPayoff} onChange={this.onPayoffChange} disabled={false} /></td>
        //         </tr>
        //     </tbody>
        // </table>
        //     <Divider /></div>)
    }

    async onClickStripGenerate(legId: number) {
        let legs = this.state.legs;
        var sp = legs[legId].selectedStartPeriod;
        var ep = legs[legId].selectedEndPeriod;
        if (sp && ep) {
            var result = await get<Period[]>(`Payoff/renderStrip/${sp.startDate}/${ep.endDate}`);
            if (result.payload) {
                let rows = result.payload.map<ScheduleRow>(p => { return { period: p, quantity: legs[legId].size } as ScheduleRow; })
                legs[legId].scheduleRows = rows;
                this.setState({ legs: legs });
            }
        }
    }

    onPeriodDateChange = (periodIx: number, date: Date, isStartDate: boolean, legId: number) => {
        let legs = this.state.legs;
        var ps = legs[legId].scheduleRows;

        ps[periodIx].period.name = "Custom";
        if (isStartDate) {
            ps[periodIx].period.startDate = date;
        }
        else {
            ps[periodIx].period.endDate = date;
        }
        legs[legId].scheduleRows = ps;
        this.setState({ legs: legs });
    }

    onChangeGeneratorOption = (optionName: string, state: boolean, legId: number) => {
        let legs = this.state.legs;
        switch (optionName) {
            case "Profile":
                legs[legId].sizeProfile = state;
                return;
            case "PriceAsOne":
                legs[legId].priceAsOne = state;
                return;
        }
        this.setState({ legs: legs });
    }

    renderPeriodPanel(legId: number, renderTitle: boolean = true) {
        //const { periods, } = this.state;
        //const { scheduleRows, periodIsStrip, selectedStartPeriod, selectedEndPeriod, priceAsOne, sizeProfile, size } = this.state.legs[legId];
        // return (<div><table className="ProductDesignerTables">
        //     <thead />
        //     <tbody>
        //         <tr>
        //             {renderTitle ? <td rowSpan={2} className="ProductDesignerLeftTitles">Schedule</td> : null}
        //             <td><CurrencyDropDown disabled={false} currencies={['Single', 'Strip']} onChange={(ccy) => this.onChangePeriodType(ccy, legId)} selectedCurrency={periodIsStrip ? "Strip" : "Single"} /></td>
        //         </tr>
        //         <tr>
        //             <td>
        //                 <PeriodControl
        //                     onStartEndChange={(x, y) => this.onStartEndPeriodChange(x, y, legId)}
        //                     startPeriod={selectedStartPeriod}
        //                     endPeriod={selectedEndPeriod}
        //                     periods={periods}
        //                     scheduleRows={scheduleRows}
        //                     onChangeRows={(rows) => this.onScheduleRowsChange(rows, legId)}
        //                     isStrip={periodIsStrip}
        //                     onClickGenerate={() => this.onClickStripGenerate(legId)}
        //                     onDateChange={(x, y, z) => this.onPeriodDateChange(x, y, z, legId)}
        //                     onChangeOption={(x, y) => this.onChangeGeneratorOption(x, y, legId)}
        //                     priceAsOne={priceAsOne}
        //                     profile={sizeProfile}
        //                     rfqSize={size ? size : defaultSize}
        //                     disabled={false} />
        //             </td>
        //         </tr>
        //     </tbody>
        // </table><Divider /></div>)
    }

    renderQuantityPanel(legId: number) {
        // return (<div><table className="ProductDesignerTables">
        //     <thead />
        //     <tbody>
        //         <tr>
        //             <td className="ProductDesignerLeftTitles">Quantity</td>
        //             <td style={{ textAlign: "center", verticalAlign: "middle" }}>
        //                 <Tooltip content="Works with m, b and k">
        //                     <NumericInput
        //                         fill={true}
        //                         key={this.state.uniqueKey + "size"}
        //                         id="rfq-size"
        //                         style={{ alignSelf: "center" }}
        //                         placeholder={defaultSize.toString()}
        //                         onValueChange={(x, y) => this.handleSizeValueChange(x, y, legId)}
        //                         value={this.state.legs[legId].size}
        //                         allowNumericCharactersOnly={false} />
        //                 </Tooltip></td>
        //         </tr>
        //     </tbody>
        // </table><Divider /></div>)
    }

    renderCreateRfqButton() {
        if (this.props.pricerMode) {
            return null;
        }

        // return (<div style={{ width: "75%", textAlign: "center", alignSelf: "center", margin: "auto" }}>
        //     <ButtonGroup fill={true}>
        //         <Button className="ProductDesignerRfqButton" outlined={true} disabled={this.state.requested || !this.readyToCreate()} color={PltfmGrey} onClick={this.onClickRFQ}><div className="ProductDesignerRfqButtonText">Create RFQ</div></Button>
        //     </ButtonGroup></div>)
    }

    renderLegs() {
        const { legs, selectedPayoff } = this.state;
        if (legs.length === 1) {
            return (<div>
                {this.renderAssetPanel(0)}
                {this.renderPeriodPanel(0)}
                {this.renderOptionPanel(0)}
                {this.renderQuantityPanel(0)}
                {this.renderExtraStrikePanel(0)}
            </div>)
        }
        else if (selectedPayoff) {
            // if (selectedPayoff.name === "BasisSwapCalendar") {
            //     return (<div>
            //         {this.renderAssetPanel(0)}
            //         <div style={{ display: "inline-flex" }}>
            //             {this.renderPeriodPanel(0)}
            //             <Divider />
            //             {this.renderPeriodPanel(1, false)}
            //         </div>
            //         {this.renderQuantityPanel(0)}
            //         {this.renderExtraStrikePanel(0)}
            //     </div>);
            //}
        }

        return (<div></div>);
    }

    render() {
        // const productsList = this.state.products.AssetInfos;
        // const { selectedAssetClass } = this.state.legs[0];
        // if (productsList.length === 0) {
        //     return (<div><Spinner></Spinner></div>
        //     );
        // }
        // else {
        //     if (!selectedAssetClass) {
        //         this.onAssetClassChange(productsList[1], 0);
        //     }
        //     return (
        //         <div className="ProductDesignerContainer">
        //             <div style={{ textAlign: "right", float: "right", position: "relative", right: "2px" }} >
        //                 <Button text={<div className="CloseButtonInner">X</div>} small={true} minimal={true} onClick={this.props.onClose} />
        //             </div>
        //             <div style={{ display: "flex", flexDirection: "column", paddingBottom: "2px" }}>
        //                 {this.renderPayoffPanel()}
        //                 {this.renderLegs()}
        //                 {this.renderCreateRfqButton()}
        //             </div>
        //         </div>)
        // }
        return null;
    }
}

export default ProductDesigner;