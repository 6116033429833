import React from 'react';
import FBEmitter from 'fbemitter';
import marketDataStoreInstance from '../marketDataStore';
import userStoreInstance from '../../user/userStore';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { Checkbox, Grid, ListItemIcon, Menu, MenuItem, MenuList, ListItemText, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { GetVolSurface, NewMarketDataExplorerEdit } from '../marketDataActions';
import { TO_GridVolSurface } from '../../qwack/to_GridVolSurface';
import GridVolSurface from '../../qwack/gridVolSurface';
import moment from 'moment';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';
import { getFormTheme } from '../../inputs/formCommon';
import _ from 'lodash';
import { ChevronRightOutlined, CloseOutlined, FullscreenOutlined, SettingsOutlined } from '@mui/icons-material';
import { MarketDataExplorerEditRequest } from '../marketDataModels';
import NestedMenuItem from '../../utils/nestedMenuItem';


export type SmileChartProps = {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
    insId: number;
    valDate: Date;
    compactMode?: boolean;
    componentKey?: string;
    tabId?: number;
}

type SmileChartState = {
    selectedDate: Date,
    selectedSmileDate: Date,
    currentTheme: string,
    surfaceTo: TO_GridVolSurface
    surface: GridVolSurface,
    selectedExpiries: number[],
    fullScreen: boolean;
    contextMenu: { mouseX: number; mouseY: number; } | null
}


export class SmileChart extends React.Component<SmileChartProps, SmileChartState>{
    eventSubscriptionMarketData: FBEmitter.EventSubscription | undefined;
    eventSubscriptionThemes: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: SmileChartProps) {
        super(props)
        this.state = {
            selectedDate: undefined,
            selectedSmileDate: undefined,
            currentTheme: userStoreInstance.GetThemeName(),
            surfaceTo: undefined,
            surface: undefined,
            selectedExpiries: [],
            contextMenu: null,
            fullScreen: false
        };

        this.setTheme = this.setTheme.bind(this);
        this.marketDataUpdate = this.marketDataUpdate.bind(this);
        this.handleExpirySelect = this.handleExpirySelect.bind(this);
        this.onClickSettings = this.onClickSettings.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionThemes = userStoreInstance.addChangeListener(this.setTheme);
        this.eventSubscriptionMarketData = marketDataStoreInstance.addChangeListener(this.marketDataUpdate);
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.onInstrumentChange);

        const { insId, valDate } = this.props;
        var cached = marketDataStoreInstance.getVolSurface(insId, valDate);
        if (!cached) {
            cached = await GetVolSurface(insId, valDate);
        }

        if (cached) {
            var surf = new GridVolSurface(cached);
            this.setState({ surfaceTo: cached, surface: surf });
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        if (this.eventSubscriptionMarketData) {
            this.eventSubscriptionMarketData.remove();
            this.eventSubscriptionMarketData = undefined;
        }
        if (this.eventSubscriptionThemes) {
            this.eventSubscriptionThemes.remove();
            this.eventSubscriptionThemes = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
    }

    async marketDataUpdate() {

    }

    onInstrumentChange() {

    }

    setTheme() {
        this.setState({ currentTheme: userStoreInstance.GetThemeName() });
    }

    async onChange() { }

    handleExpirySelect(value: number) {
        const { selectedExpiries } = this.state;
        const currentIndex = selectedExpiries.indexOf(value);
        const newChecked = [...selectedExpiries];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ selectedExpiries: newChecked });
    }

    onClickSettings() {
        this.setState({ contextMenu: null })
        const { insId, valDate, componentKey, tabId } = this.props;
        NewMarketDataExplorerEdit({ insId, chartType: "Smile", valDate, componentId: componentKey, tabId } as MarketDataExplorerEditRequest);
    }

    onContextMenu(event: React.MouseEvent) {
        event.preventDefault();
        this.setState({
            contextMenu: this.state.contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        });
    };

    render() {
        const { surface, selectedExpiries, contextMenu, fullScreen } = this.state;

        const expires = selectedExpiries?.length > 0 ? selectedExpiries : [0];
        var smileChartData: Data[];
        var smileChartLayout: Partial<Layout>;

        var strikes5d = _.range(0.05, 1, 0.05);
        var strikes = [0.1, 0.25, 0.5, 0.75, 0.9];
        if (surface) {
            smileChartData = expires.map((e, ix) => {
                return {
                    x: strikes5d.map(k => k),
                    y: strikes5d.map(k => surface.GetVolForDeltaStrikeExpAsDate(-k, surface.Expiries[e], 100)),
                    type: 'scattergl',
                    mode: 'lines+markers',
                    title: moment(surface.Expiries[e]).format(userStoreInstance.GetDateFormat()),
                    name: moment(surface.Expiries[e]).format(userStoreInstance.GetDateFormat()),
                    marker: { color: userStoreInstance.GetTheme()[`chart_color_indic_${ix}`] },
                } as Data
            });

            smileChartLayout = {
                title: this.props.compactMode ? undefined : { text: "Delta-Space Implied Volatility Smile", font: { color: userStoreInstance.GetTheme().color, family: userStoreInstance.GetTheme().font_family } },
                paper_bgcolor: userStoreInstance.GetTheme().background_color,
                plot_bgcolor: userStoreInstance.GetTheme().background_color,
                xaxis: {
                    title: {
                        text: "Put Delta",
                        standoff: 10
                    },
                    ticktext: strikes.map(k => `${(k * 100).toFixed(0)}%`),
                    tickvals: strikes,
                    color: userStoreInstance.GetTheme().color,
                    automargin: true
                },
                yaxis: {
                    title: {
                        text: "Implied Vol",
                        standoff: 15
                    },
                    nticks: 8,
                    tickformat:"~%",
                    color: userStoreInstance.GetTheme().color,
                    autotick: true,
                    automargin: true,
                },
                scene: {
                    bgcolor: userStoreInstance.GetTheme().background_color
                },
                legend: {
                    x: 0.5,
                    xanchor: 'center',
                    y: 1,
                    font: { color: userStoreInstance.GetTheme().color, }
                },
                margin: !this.props.compactMode ? undefined : {
                    pad: 1,
                    l: 25,
                    r: 25,
                    t: 0,
                    b: 5,
                }
            }
        }

        var smileDates = surface?.Expiries;

        const chartTitle = `${listedInstrumentStoreInstance.getInstrumentById(this.props.insId)?.description?.split(" Underlying Index")[0]} implied volatility smiles as of ${moment(this.props.valDate).format(userStoreInstance.GetDateFormat())}`;

        return (
            <div className={`SmileChart`}>
                <ThemeProvider theme={getFormTheme()}>
                    {this.props.compactMode ? null : <Grid item sm={2}>
                        <MenuList >
                            {smileDates?.map((d, ix) => {
                                return <MenuItem key={`smileKK${ix}`} onClick={() => this.handleExpirySelect(ix)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedExpiries.indexOf(ix) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `$Expiry {ix}` }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText>{moment(d).format(userStoreInstance.GetDateFormat())}</ListItemText>
                                </MenuItem>
                            })}
                        </MenuList>
                    </Grid>}
                    {this.props.compactMode ? <Menu
                        open={contextMenu !== null}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                        onClose={() => this.setState({ contextMenu: null })}
                    >
                        <MenuItem key={`smileKK-settingz`} onClick={this.onClickSettings}>
                            <ListItemIcon>
                                <SettingsOutlined />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </MenuItem>
                        {fullScreen ? null : <MenuItem key={`smileKK-fullscrn`} onClick={() => this.setState({ fullScreen: true })}>
                            <ListItemIcon>
                                <FullscreenOutlined />
                            </ListItemIcon>
                            <ListItemText>Full Screen</ListItemText>
                        </MenuItem>}
                        <NestedMenuItem key='nTime' rightIcon={<ChevronRightOutlined />} label={"Expiries"} parentMenuOpen={contextMenu !== null}>
                            {smileDates?.map((d, ix) => {
                                return <MenuItem key={`smileKK${ix}`} onClick={() => this.handleExpirySelect(ix)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedExpiries.indexOf(ix) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `$Expiry {ix}` }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText>{moment(d).format(userStoreInstance.GetDateFormat())}</ListItemText>
                                </MenuItem>
                            })}
                        </NestedMenuItem>
                    </Menu> : null}
                    <Dialog fullScreen key="tradeDetail"
                        open={fullScreen}
                        classes={{ paperFullScreen: "TradeDetailBackdrop" }}
                        onKeyDown={(e => { if (e.key === "Escape") { this.setState({ fullScreen: false }) } })}>
                        <DialogTitle style={{ display: "flex", flexDirection: "row", width: "100vw", justifyContent: "space-between" }}>
                            <Typography variant='h6'>{chartTitle}</Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => this.setState({ fullScreen: false })}
                                aria-label="close">
                                <CloseOutlined />
                            </IconButton>
                        </DialogTitle>
                        <DialogActions></DialogActions>
                        <div style={{ height: "calc(100vh - 100px)" }} onContextMenu={this.onContextMenu}>
                            <Plot
                                data={smileChartData}
                                layout={smileChartLayout}
                                config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                useResizeHandler={true} />
                        </div>
                    </Dialog>
                    {smileChartData?.length > 0 ?
                        <div className="SmileChartGraph" onContextMenu={this.onContextMenu}>
                            <Plot
                                data={smileChartData}
                                layout={smileChartLayout}
                                config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                useResizeHandler={true}
                            />
                        </div> : null}
                </ThemeProvider>
            </div>
        );
    }
}