import {
    Button,
    Grid,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    CssBaseline,
    Typography,
} from "@mui/material";
import { Form } from "formik";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import Autocomplete from '@mui/material/Autocomplete';
import productStoreInstance from "../product/productStore";
import { Moment } from "moment";
import userStoreInstance from "../user/userStore";
import { WrappedDateTimePicker } from "../inputs/wrappedDateTimePicker";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";


export const NewCashTradeForm = props => {
    const {
        values: { amount,
            currency,
            executedUtc,
            account,
            description,
            category,
            bookingEntityId,
            externalId,
            accrualKey,
            valueDate
        },
        errors,
        //touched,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        canDuplicate,
        onDuplicatePressed,
        lastBookedId
        //metaCollapsable,
        //balanceTrade
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };


    const filterCcyOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => ccyMatchesQuery(o, query))
        return relevant;
    };

    const ccyMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const onCcySelect = (ccy: string) => {
        if (productStoreInstance.getAvailableCurrencies().includes(ccy)) {
            setFieldValue("currency", ccy);
        }
        else
            setFieldValue("currency", null);
    }


    const onBookingEntitySelect = (firm: string) => {
        var matches = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === firm);
        if (matches.length > 0) {
            setFieldValue("bookingEntityId", matches[0].firmId);
        }
        else
            setFieldValue("bookingEntityId", null);
    }

    const onChangeExecuted = (d: Moment) => {
        setFieldValue("executedUtc", d);
    }

    const onChangeValueDate = (d: Moment) => {
        setFieldValue("valueDate", d);
    }

    const NullCcy = "Select Currency...";
    const NullEntity = "Select Entity...";

    return (
        <Form onSubmit={handleSubmit} className={className}>
            <CssBaseline>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} justifyContent="center" >
                            <Grid container item spacing={2} justifyContent="center" >
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={[NullCcy, ...productStoreInstance.getAvailableCurrencies()]}
                                        value={currency ?? NullCcy}
                                        id="currency"
                                        size="small"
                                        getOptionLabel={(ccy: string) => ccy}
                                        style={{ width: 150 }}
                                        filterOptions={filterCcyOptions}
                                        onChange={(e, v) => onCcySelect(v)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={errors.currency ? errors.currency : ""}
                                            error={errors.currency !== undefined || Boolean(errors.currency)}
                                            label="Currency"
                                            variant="outlined" />} />
                                </Grid>
                                <Grid item>
                                    <WrappedDateTimePicker
                                        size="small"
                                        id="executedUtc"
                                        name="executedUtc"
                                        format="yyyy-MM-DD HH:mm:ss"
                                        helperText={errors.executedUtc ? errors.executedUtc : ""}
                                        error={errors.executedUtc !== undefined || Boolean(errors.executedUtc)}
                                        label="Executed (UTC)"
                                        value={executedUtc}
                                        onChange={(d) => onChangeExecuted(d)} />
                                </Grid>
                                <Grid item>
                                    <WrappedDatePicker
                                        size="small"
                                        id="valueDate"
                                        name="valueDate"
                                        helperText={errors.valueDate ? errors.valueDate : ""}
                                        error={errors.valueDate !== undefined || Boolean(errors.valueDate)}
                                        label="Value Date"
                                        value={valueDate}
                                        onChange={(d) => onChangeValueDate(d)} />
                                </Grid>

                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="amount"
                                        name="amount"
                                        size="small"
                                        helperText={errors.amount ? errors.amount : ""}
                                        error={Boolean(errors.amount)}
                                        label="Amount"
                                        value={amount ?? ""}
                                        onChange={change.bind(null, "amount")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="account"
                                        name="account"
                                        size="small"
                                        style={{ width: 200 }}
                                        helperText={errors.account ? errors.account : ""}
                                        error={Boolean(errors.account)}
                                        label="Account"
                                        value={account ?? ""}
                                        onChange={change.bind(null, "account")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="category"
                                        name="category"
                                        style={{ width: 250 }}
                                        size="small"
                                        helperText={errors.category ? errors.category : ""}
                                        error={Boolean(errors.category)}
                                        label="Category"
                                        value={category ?? ""}
                                        onChange={change.bind(null, "category")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            </Grid>
                            <Grid container item spacing={2} justifyContent="center" >
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="description"
                                        name="description"
                                        size="small"
                                        helperText={errors.description ? errors.description : ""}
                                        error={Boolean(errors.description)}
                                        label="Description"
                                        style={{ width: 350 }}
                                        value={description ?? ""}
                                        onChange={change.bind(null, "description")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="externalId"
                                        name="externalId"
                                        size="small"
                                        helperText={errors.externalId ? errors.externalId : ""}
                                        error={Boolean(errors.externalId)}
                                        label="External Id"
                                        value={externalId ?? ""}
                                        onChange={change.bind(null, "externalId")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={[NullEntity, ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)]}
                                        value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === bookingEntityId)[0]?.displayName ?? NullEntity}
                                        id="bookingEntity"
                                        size="small"
                                        getOptionLabel={(firm: string) => firm}
                                        style={{ width: 200 }}
                                        filterOptions={filterCcyOptions}
                                        onChange={(e, v) => onBookingEntitySelect(v)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={errors.bookingEntity ? errors.bookingEntity : ""}
                                            error={errors.bookingEntity !== undefined || Boolean(errors.bookingEntity)}
                                            label="Booking Entity"
                                            variant="outlined" />} />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="accrualKey"
                                        name="accrualKey"
                                        size="small"
                                        helperText={errors.accrualKey ? errors.accrualKey : ""}
                                        error={Boolean(errors.accrualKey)}
                                        label="Accrual Key"
                                        value={accrualKey ?? ""}
                                        onChange={change.bind(null, "accrualKey")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            </Grid>
                            <GridBreak />
                            <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="submit"
                                    variant="outlined"
                                    disabled={!isValid || canDuplicate}
                                    endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="reset"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Revert</Button></Grid>
                                {canDuplicate ? <Grid item><Button
                                    className="PltfmButtonLite"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Duplicate</Button></Grid> : null}
                            </Grid>
                            {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Typography variant="subtitle2">Last booked id {lastBookedId}</Typography></Grid>
                            </Grid> : null}
                        </Grid>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CssBaseline>
        </Form>
    );
};