import { Interpolator1DType } from "../interpolator1DType";
import { TO_Interpolator1d } from "../to_Interpolator1d";
import CubicHermiteSplineInterpolator from "./cubicHermiteSplineInterpolator";
import LinearFlatExtrapInterpolator from "./linearFlatExtrapInterpolator";
import LinearInterpolator from "./linearInterpolator";
import LinearInVarianceInterpolator from "./linearInVarianceInterpolator";

export const InterpolatorFactory = (to: TO_Interpolator1d) => {
    switch (to.type) {
        case Interpolator1DType.linear:
            return new LinearInterpolator(to.xs, to.ys);
        case Interpolator1DType.linearFlatExtrap:
            return new LinearFlatExtrapInterpolator(to.xs, to.ys);
        case Interpolator1DType.linearInVariance:
            return new LinearInVarianceInterpolator(to.xs, to.ys);
        case Interpolator1DType.cubicSpline:
            return new CubicHermiteSplineInterpolator(to.xs, to.ys);
        default:
            return new LinearInterpolator(to.xs, to.ys);
    }
}

export const InterpolatorFactory1d = (x: number[], y: number[], kind: Interpolator1DType) => {
    switch (kind) {
        case Interpolator1DType.linear:
            return new LinearInterpolator(x, y);
        case Interpolator1DType.linearFlatExtrap:
            return new LinearFlatExtrapInterpolator(x, y);
        case Interpolator1DType.linearInVariance:
            return new LinearInVarianceInterpolator(x, y);
        case Interpolator1DType.cubicSpline:
            return new CubicHermiteSplineInterpolator(x, y);
        default:
            return new LinearInterpolator(x, y);
    }
}