import {
    Button,
    Grid,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    CssBaseline,
    InputAdornment,
    Typography,
} from "@mui/material";
import { Form } from "formik";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import Autocomplete from '@mui/material/Autocomplete';
import productStoreInstance from "../product/productStore";
import { Moment } from "moment";
import moment from "moment";
import { WrappedDateTimePicker } from "../inputs/wrappedDateTimePicker";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";

export const NewLoanDepoForm = props => {
    const {
        values: { nominal,
            fixedRate,
            ccy,
            bookedUtc,
            startDate,
            endDate,
            account,
            description,
            externalId,
            counterparty
        },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        canDuplicate,
        onDuplicatePressed,
        lastBookedId
        //metaCollapsable,
        //balanceTrade
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };


    const filterCcyOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => ccyMatchesQuery(o, query))
        return relevant;
    };

    const ccyMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const onCcySelect = (ccy: string) => {
        if (productStoreInstance.getAvailableCurrencies().includes(ccy)) {
            setFieldValue("ccy", ccy);
            setFieldTouched("ccy");
        }
    }

    const onChangeDate = (d: Moment, fieldName: string, dateTime: boolean = false) => {
        if (d)
            setFieldValue(fieldName, d.format(dateTime ? "yyyy-MM-DDThh:mm:ss" : "yyyy-MM-DD"));
        setFieldTouched(fieldName);
    }

    const NullCcy = "Select Currency...";

    return (
        <Form onSubmit={handleSubmit} className={className}>
            <CssBaseline>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} justifyContent="center" >
                            <Grid item>
                                <Autocomplete
                                    classes={{
                                        inputRoot: "BasketDesignerAutocomplete",
                                        popupIndicator: "BasketDesignerAutocompleteIcon",
                                        clearIndicator: "BasketDesignerAutocompleteIcon",
                                        popper: "AutocompleteGroupLabel",
                                    }}
                                    autoComplete
                                    options={[NullCcy, ...productStoreInstance.getAvailableCurrencies()]}
                                    value={ccy ?? NullCcy}
                                    id="currency"
                                    getOptionLabel={(ccy: string) => ccy}
                                    style={{ width: 150 }}
                                    filterOptions={filterCcyOptions}
                                    onChange={(e, v) => onCcySelect(v)}
                                    renderInput={(params) => <TextField {...params} label="Currency" variant="outlined" />} />
                            </Grid>
                            <Grid item>
                                <WrappedDateTimePicker
                                    size="medium"
                                    id="bookedUtc"
                                    name="bookedUtc"
                                    format="yyyy-MM-DD HH:mm:ss"
                                    helperText={errors.executedUtc ? errors.executedUtc : ""}
                                    error={Boolean(errors.executedUtc)}
                                    label="Booked (UTC)"
                                    value={bookedUtc}
                                    onChange={(d) => onChangeDate(d, "bookedUtc", true)} />
                            </Grid>
                            <Grid item>
                                <WrappedDatePicker
                                    size="medium"
                                    id="startDate"
                                    name="startDate"
                                    helperText={errors.startDate ? errors.startDate : ""}
                                    error={Boolean(errors.startDate)}
                                    label="Start Date"
                                    value={startDate ?? moment(bookedUtc).startOf('day').toDate()}
                                    onChange={(d) => onChangeDate(d, "startDate")} />
                            </Grid>
                            <Grid item>
                                <WrappedDatePicker
                                    size="medium"
                                    id="endDate"
                                    name="endDate"
                                    helperText={errors.endDate ? errors.endDate : ""}
                                    error={Boolean(errors.endDate)}
                                    label="End Date"
                                    minDate={startDate}
                                    value={endDate ?? startDate ?? moment(bookedUtc).startOf('day').toDate()}
                                    onChange={(d) => onChangeDate(d, "endDate")} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="nominal"
                                    name="nominal"
                                    helperText={touched.nominal ? errors.nominal : ""}
                                    error={touched.nominal && Boolean(errors.nominal)}
                                    label="Nominal"
                                    value={nominal ?? ""}
                                    onChange={change.bind(null, "nominal")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="fixedRate"
                                    name="fixedRate"
                                    helperText={touched.fixedRate ? errors.fixedRate : ""}
                                    error={touched.fixedRate && Boolean(errors.fixedRate)}
                                    label="Fixed Rate (%)"
                                    value={fixedRate ?? ""}
                                    onChange={change.bind(null, "fixedRate")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, endAdornment: <InputAdornment position="end">%</InputAdornment> }} /></Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="account"
                                    name="account"
                                    helperText={touched.account ? errors.account : ""}
                                    error={touched.account && Boolean(errors.account)}
                                    label="Account"
                                    value={account ?? ""}
                                    onChange={change.bind(null, "account")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="counterparty"
                                    name="counterparty"
                                    helperText={touched.counterparty ? errors.counterparty : ""}
                                    error={touched.counterparty && Boolean(errors.counterparty)}
                                    label="Counterparty"
                                    value={counterparty ?? ""}
                                    onChange={change.bind(null, "counterparty")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="description"
                                    name="description"
                                    helperText={touched.description ? errors.description : ""}
                                    error={touched.description && Boolean(errors.description)}
                                    label="Description"
                                    value={description ?? ""}
                                    onChange={change.bind(null, "description")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="externalId"
                                    name="externalId"
                                    helperText={touched.externalId ? errors.externalId : ""}
                                    error={touched.externalId && Boolean(errors.externalId)}
                                    label="External Id"
                                    value={externalId ?? ""}
                                    onChange={change.bind(null, "externalId")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <GridBreak />
                            <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="submit"
                                    variant="outlined"
                                    disabled={!isValid || canDuplicate}
                                    endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="reset"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Revert</Button></Grid>
                                {canDuplicate ? <Grid item><Button
                                    className="PltfmButtonLite"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Duplicate</Button></Grid> : null}
                            </Grid>
                            {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Typography variant="subtitle2">Last booked id {lastBookedId}</Typography></Grid>
                            </Grid> : null}
                        </Grid>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CssBaseline>
        </Form>
    );
};