import React from 'react';
import FBEmitter from 'fbemitter';
import { v4 } from 'uuid';
import { TradeAttemptResponse } from '../../trade/tradeModels';
import ValidatedTextInputLarge from '../../inputs/validatedTextInputLarge';
import { Price, RfqSummary } from '../rfqModels';
import rfqConnection from '../rfqConnection';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { renderRemainingTime } from './executor';
import rfqSummaryStoreInstance from '../rfqSummaryStore';
import newTradeEventStoreInstance from '../../trade/newTradeEventStore';
import { Button, Divider, FormGroup, FormLabel, Grid, Typography } from '@mui/material';
import { EmailOutlined, RedoOutlined } from '@mui/icons-material';
import userStoreInstance from '../../user/userStore';

type PriceProviderProps = {
    selectedRfq: RfqSummary | undefined,
}

type PriceProviderState = {
    bid: number,
    ask: number,
    priceOut: boolean,
    bidOk: boolean,
    askOk: boolean,
    askBiggerThanBid: boolean,
    ttl: number,
    ttlForCurrentPrice: number,
    timePriceOutFor: number,
    timePriceSent: Date,
    quoteUniqueKey: string,
    priceType: string,
    traded: boolean,
    tradePrice: number,
    selectedRfq: RfqSummary | undefined,
}

const TimerInterval = 50;
const TtlOptions = [1, 2, 5, 10, 30, 60, 90, 120, 180, 240, 300];
const Bid = "Bid";
const Ask = "Ask";
const TwoWay = "Two-Way";
const PriceTypeOptions = [Bid, Ask, TwoWay];

let timer: any;

export class PriceProvider extends React.Component<PriceProviderProps, PriceProviderState>{
    eventSubscriptionNewTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionRfqSummary: FBEmitter.EventSubscription | undefined;

    constructor(props: PriceProviderProps) {
        super(props)
        this.state = {
            bid: 0,
            ask: 0,
            ttl: 60000,
            ttlForCurrentPrice: 60000,
            priceOut: false,
            bidOk: false,
            askOk: false,
            askBiggerThanBid: false,
            timePriceOutFor: 0,
            timePriceSent: new Date(),
            quoteUniqueKey: "",
            priceType: Ask,
            traded: false,
            tradePrice: 0,
            selectedRfq: this.props.selectedRfq
        };
        this.onClickSend = this.onClickSend.bind(this);
        this.onClickYank = this.onClickYank.bind(this);
        this.countDown = this.countDown.bind(this);
        this.onTrade = this.onTrade.bind(this);
        this.onNewTradeEvent = this.onNewTradeEvent.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionNewTrades = newTradeEventStoreInstance.addChangeListener(() => this.onNewTradeEvent());
        this.eventSubscriptionRfqSummary = rfqSummaryStoreInstance.addChangeListener(() => this.onChangeSummary());
    }

    onNewTradeEvent() {
        var didTrade = newTradeEventStoreInstance.getTradeEvent(this.props.selectedRfq.rfqId);
        if (didTrade) {
            this.setState({ traded: true });
        }
    }

    componentWillUnmount() {
        if (timer) {
            clearInterval(timer);
        }
        if (this.eventSubscriptionNewTrades) {
            this.eventSubscriptionNewTrades.remove();
        }
        if (this.eventSubscriptionRfqSummary) {
            this.eventSubscriptionRfqSummary.remove();
        }
    }

    async onChangeSummary() {
        if (this.state.selectedRfq !== undefined) {
            var rfqSummary = rfqSummaryStoreInstance.GetRfq(this.state.selectedRfq.rfqId);
            if (rfqSummary) {
                this.setState({ selectedRfq: rfqSummary });
            }
        }
    }

    onChangeBid = (val: string): boolean => {
        var valNum = parseFloat(val)
        if (!isNaN(valNum)) {
            this.setState({ bid: valNum, bidOk: true, askBiggerThanBid: this.state.ask > valNum });
            return true;
        }
        this.setState({ bidOk: false });
        return false;
    }

    onChangeAsk = (val: string): boolean => {
        var valNum = parseFloat(val)
        if (!isNaN(valNum)) {
            this.setState({ ask: valNum, askOk: true, askBiggerThanBid: valNum > this.state.bid });
            return true;
        }
        this.setState({ askOk: false });
        return false;
    }

    checkOkToSend() {
        const { bidOk, askOk, askBiggerThanBid, priceType } = this.state;
        var ok = this.canSendPrice();
        switch (priceType) {
            case TwoWay:
                ok = ok && bidOk && askOk && askBiggerThanBid;
                break;
            case Bid:
                ok = ok && bidOk;
                break;
            case Ask:
                ok = ok && askOk;
                break;
        }

        return ok;
    }

    canSendPrice() {
        let rfq = this.state.selectedRfq;
        return rfq !== undefined && (rfq.state === "PickedUp" || rfq.state === "PriceToClient" || rfq.state === "FinalPreTradeChecks");
    }

    async onClickSend() {
        const { priceType, selectedRfq } = this.state;
        if (selectedRfq) {
            clearInterval(timer)
            var key = v4();
            this.setState({ priceOut: true, timePriceOutFor: 0, ttlForCurrentPrice: this.state.ttl, timePriceSent: new Date(), quoteUniqueKey: key });
            var price = {
                bid: (priceType === Ask ? null : this.state.bid),
                ask: (priceType === Bid ? null : this.state.ask),
                ttlMs: this.state.ttl,
                rfqId: selectedRfq.rfqId,
                stamp: new Date(),
                uniqueKey: key
            } as Price;
            await rfqConnection.sendPriceForRfq(selectedRfq.rfqId, price);
            timer = setInterval(this.countDown, TimerInterval);
        }
    }

    onTrade(attempt: TradeAttemptResponse) {
        const { priceOut, quoteUniqueKey } = this.state;
        if (priceOut && attempt.quoteUniqueKey === quoteUniqueKey) {
            clearInterval(timer);
            this.setState({ priceOut: false, timePriceOutFor: 0 });
        }
        else {
            console.log("Trade attempt came back but no price out on that key")
        }
    }

    countDown() {
        let now = new Date();
        let elapsedMs = now.getTime() - this.state.timePriceSent.getTime();
        if (elapsedMs >= this.state.ttl) {
            //this.onClickYank();
        }
        else {
            this.setState({ timePriceOutFor: elapsedMs });
        }
    }

    onClickYank() {
        const { selectedRfq, quoteUniqueKey } = this.state;
        if (selectedRfq) {
            this.setState({ priceOut: false, timePriceOutFor: 0 });
            rfqConnection.yankPriceForRfq(selectedRfq.rfqId, quoteUniqueKey)
            clearInterval(timer);
        }
    }

    renderTwoWay(disabled: boolean) {
        const { selectedRfq } = this.state;
        return (<div className="RfqDashboardPriceProviderPrices">
            <FormGroup>
                <FormLabel>Bid</FormLabel>
                <ValidatedTextInputLarge
                    initialValue="0"
                    keyName={`bid+${selectedRfq.rfqId}`}
                    onChange={this.onChangeBid}
                    placeholder="Enter Bid..."
                    key={`bid+${selectedRfq.rfqId}`}
                    className="RfqDashboardPriceProviderPricesTextBox"
                    disabled={disabled} />
            </FormGroup>
            <Divider orientation="vertical" flexItem={true} />
            <FormGroup>
                <FormLabel>Ask</FormLabel>
                <ValidatedTextInputLarge
                    initialValue="0"
                    keyName={`ask+${selectedRfq.rfqId}`}
                    key={`ask+${selectedRfq.rfqId}`}
                    onChange={this.onChangeAsk}
                    placeholder="Enter Offer..."
                    className="RfqDashboardPriceProviderPricesTextBox"
                    disabled={disabled} />
            </FormGroup>
        </div>);
    }
    renderOneWay(side: string, disabled: boolean) {
        const { selectedRfq } = this.state;
        return (
            <FormGroup>
                <FormLabel classes={{ root: "RfqDashboardPriceProviderPricesTextLabel" }}>{side}</FormLabel>
                <ValidatedTextInputLarge
                    initialValue="0"
                    keyName={`${side}+${selectedRfq.rfqId}`}
                    onChange={side === "Bid" ? this.onChangeBid : this.onChangeAsk}
                    placeholder={`Enter ${side}...`}
                    key={`bid+${selectedRfq.rfqId}`}
                    className="RfqDashboardPriceProviderPricesTextBox"
                    disabled={disabled} />
            </FormGroup>);

    }

    render() {
        const { priceType, priceOut, ttl, traded, selectedRfq, quoteUniqueKey, ttlForCurrentPrice } = this.state;

        let col1 = userStoreInstance.GetTheme().timer_color_start;
        let col2 = userStoreInstance.GetTheme().timer_color_middle;
        let col3 = userStoreInstance.GetTheme().timer_color_end;

        if (selectedRfq) {
            let okToSend = this.checkOkToSend();
            return (<Grid container direction="column" padding="1em" justifyContent="space-between">
                {traded ?
                    null :
                    <Grid container item direction="row" justifyContent="space-around">
                        <Grid item><StringTypeDropDown className="RfqDashboardPriceProviderActionsElement" possibles={TtlOptions.map(t => t.toString())} selected={(ttl / 1000).toString()} disabled={priceOut} onChange={(e) => this.setState({ ttl: parseInt(e) * 1000 })} label="TTL" /></Grid>
                        <Grid item><StringTypeDropDown className="RfqDashboardPriceProviderActionsElement" possibles={PriceTypeOptions} selected={priceType} disabled={priceOut} onChange={(e) => this.setState({ priceType: e })} label="Side" /></Grid>
                    </Grid>}
                <Grid item container direction="row" padding="1em" justifyContent="center">
                    <Grid item width="70%">
                        {priceType === TwoWay ? this.renderTwoWay(traded) : this.renderOneWay(priceType, traded)}
                    </Grid>
                </Grid>
                <Grid item container padding="1em" direction="row" justifyContent="center">
                    {priceOut && !traded ?
                        <CountdownCircleTimer
                            isPlaying
                            key={quoteUniqueKey}
                            duration={ttlForCurrentPrice / 1000}
                            colors={[
                                [col1, 0.33],
                                [col2, 0.33],
                                [col3, 0.33],
                            ]}>
                            {renderRemainingTime}
                        </CountdownCircleTimer>
                        : null}
                </Grid>
                {traded ?
                    <Grid item>
                        <Typography variant='subtitle2'>Traded</Typography>
                    </Grid>
                    :
                    <Grid container direction="row" justifyContent="space-around" padding="1em" >
                        <Grid item><Button className="MuiButton-Outlined PltfmButtonLite" variant="outlined" disabled={!okToSend} onClick={this.onClickSend}>Send<EmailOutlined /></Button></Grid>
                        <Grid item><Button className="MuiButton-Outlined PltfmButtonLite" variant="outlined" disabled={!priceOut} onClick={this.onClickYank}>Yank<RedoOutlined /></Button></Grid>
                    </Grid>}
            </Grid>
            );
        }
        else {
            return (<div className="RfqDashboardPriceProvider" />);
        }

    }
}