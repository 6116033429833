export function NormSDist(d: number): number { return FiNormSDist(d) };
export function Phi(x: number): number { return (Math.exp((-x * x) / 2.0) / Math.sqrt(6.28318530717958)); }

function FiNormSDist(z: number): number {

    var RTWO = 1.4142135623731;

    var a0 = 0.6101430819232;
    var a1 = -0.434841272712578;
    var a2 = 0.176351193643605;
    var a3 = -6.07107956092494E-02;
    var a4 = 1.77120689956941E-02;
    var a5 = -4.32111938556729E-03;
    var a6 = 8.54216676887099E-04;
    var a7 = -1.27155090609163E-04;
    var a8 = 1.12481672436712E-05;
    var a9 = 3.13063885421821E-07;
    var a10 = -2.70988068537762E-07;
    var a11 = 3.07376227014077E-08;
    var a12 = 2.51562038481762E-09;
    var a13 = -1.02892992132032E-09;
    var a14 = 2.99440521199499E-11;
    var a15 = 2.60517896872669E-11;
    var a16 = -2.63483992417197E-12;
    var a17 = -6.43404509890636E-13;
    var a18 = 1.12457401801663E-13;
    var a19 = 1.72815333899861E-14;
    var a20 = -4.26410169494238E-15;
    var a21 = -5.45371977880191E-16;
    var a22 = 1.58697607761671E-16;
    var a23 = 2.0899837844334E-17;
    var a24 = -5.900526869409E-18;

    var xa = Math.abs(z) / RTWO;
    var p = 0.0;
    if (xa <= 100.0) {
        var BP = 0.0;
        var T = (8.0 * xa - 30.0) / (4 * xa + 15.0);
        var bm = 0.0;
        var b = 0.0;

        BP = b;
        b = bm;
        bm = T * b - BP + a24;
        BP = b;
        b = bm;
        bm = T * b - BP + a23;
        BP = b;
        b = bm;
        bm = T * b - BP + a22;
        BP = b;
        b = bm;
        bm = T * b - BP + a21;
        BP = b;
        b = bm;
        bm = T * b - BP + a20;
        BP = b;
        b = bm;
        bm = T * b - BP + a19;
        BP = b;
        b = bm;
        bm = T * b - BP + a18;
        BP = b;
        b = bm;
        bm = T * b - BP + a17;
        BP = b;
        b = bm;
        bm = T * b - BP + a16;
        BP = b;
        b = bm;
        bm = T * b - BP + a15;
        BP = b;
        b = bm;
        bm = T * b - BP + a14;
        BP = b;
        b = bm;
        bm = T * b - BP + a13;
        BP = b;
        b = bm;
        bm = T * b - BP + a12;
        BP = b;
        b = bm;
        bm = T * b - BP + a11;
        BP = b;
        b = bm;
        bm = T * b - BP + a10;
        BP = b;
        b = bm;
        bm = T * b - BP + a9;
        BP = b;
        b = bm;
        bm = T * b - BP + a8;
        BP = b;
        b = bm;
        bm = T * b - BP + a7;
        BP = b;
        b = bm;
        bm = T * b - BP + a6;
        BP = b;
        b = bm;
        bm = T * b - BP + a5;
        BP = b;
        b = bm;
        bm = T * b - BP + a4;
        BP = b;
        b = bm;
        bm = T * b - BP + a3;
        BP = b;
        b = bm;
        bm = T * b - BP + a2;
        BP = b;
        b = bm;
        bm = T * b - BP + a1;
        BP = b;
        b = bm;
        bm = T * b - BP + a0;

        p = Math.exp(-xa * xa) * (bm - BP) / 4.0;
    }

    if (z > 0.0) { p = 1.0 - p; }

    return p;
}

export function NormInv(p: number) { return NormInvMuSigma(p, 0.0, 1.0) };

function NormInvMuSigma(p: number, mu: number, sigma: number) {
    if (p < 0 || p > 1) {
        throw new Error("The probality p must be bigger than 0 and smaller than 1");
    }
    if (sigma < 0) {
        throw new Error("The standard deviation sigma must be positive");
    }

    if (p === 0) {
        return Number.NEGATIVE_INFINITY;
    }
    if (p === 1) {
        return Number.POSITIVE_INFINITY;
    }
    if (sigma === 0) {
        return mu;
    }

    var q: number, r: number, val: number;

    q = p - 0.5;

    /*-- use AS 241 --- */
    /* double ppnd16_(double *p, long *ifault)*/
    /*      ALGORITHM AS241  APPL. STATIST. (1988) VOL. 37, NO. 3

            Produces the normal deviate Z corresponding to a given lower
            tail area of P; Z is accurate to about 1 part in 10**16.
    */
    if (Math.abs(q) <= .425) {/* 0.075 <= p <= 0.925 */
        r = .180625 - q * q;
        val =
            q * (((((((r * 2509.0809287301226727 +
                33430.575583588128105) * r + 67265.770927008700853) * r +
                45921.953931549871457) * r + 13731.693765509461125) * r +
                1971.5909503065514427) * r + 133.14166789178437745) * r +
                3.387132872796366608)
            / (((((((r * 5226.495278852854561 +
                28729.085735721942674) * r + 39307.89580009271061) * r +
                21213.794301586595867) * r + 5394.1960214247511077) * r +
                687.1870074920579083) * r + 42.313330701600911252) * r + 1);
    }
    else { /* closer than 0.075 from {0,1} boundary */

        /* r = min(p, 1-p) < 0.075 */
        if (q > 0)
            r = 1 - p;
        else
            r = p;

        r = Math.sqrt(-Math.log(r));
        /* r = sqrt(-log(r))  <==>  min(p, 1-p) = exp( - r^2 ) */

        if (r <= 5) { /* <==> min(p,1-p) >= exp(-25) ~= 1.3888e-11 */
            r += -1.6;
            val = (((((((r * 7.7454501427834140764e-4 +
                .0227238449892691845833) * r + .24178072517745061177) *
                r + 1.27045825245236838258) * r +
                3.64784832476320460504) * r + 5.7694972214606914055) *
                r + 4.6303378461565452959) * r +
                1.42343711074968357734)
                / (((((((r *
                    1.05075007164441684324e-9 + 5.475938084995344946e-4) *
                    r + .0151986665636164571966) * r +
                    .14810397642748007459) * r + .68976733498510000455) *
                    r + 1.6763848301838038494) * r +
                    2.05319162663775882187) * r + 1);
        }
        else { /* very close to  0 or 1 */
            r += -5;
            val = (((((((r * 2.01033439929228813265e-7 +
                2.71155556874348757815e-5) * r +
                .0012426609473880784386) * r + .026532189526576123093) *
                r + .29656057182850489123) * r +
                1.7848265399172913358) * r + 5.4637849111641143699) *
                r + 6.6579046435011037772)
                / (((((((r *
                    2.04426310338993978564e-15 + 1.4215117583164458887e-7) *
                    r + 1.8463183175100546818e-5) * r +
                    7.868691311456132591e-4) * r + .0148753612908506148525)
                    * r + .13692988092273580531) * r +
                    .59983220655588793769) * r + 1);
        }

        if (q < 0.0) {
            val = -val;
        }
    }

    return mu + sigma * val;
}