import {
    Button,
    Grid,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    CssBaseline,
    Typography,
} from "@mui/material";
import { Form } from "formik";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import Autocomplete from '@mui/material/Autocomplete';
import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";
import tradesStoreInstance from "./tradeStore";
import productStoreInstance from "../product/productStore";
import { Moment } from "moment";
import userStoreInstance from "../user/userStore";
import { UserSummary } from "../user/userModel";
import { WrappedDateTimePicker } from "../inputs/wrappedDateTimePicker";

export const NewTradeForm = props => {
    const {
        values: { size,
            price,
            createdBy,
            account,
            executedUtc,
            bookingEntityId,
            listedInstrumentId,
            strategy,
            book,
            currency
        },
        errors,
        touched,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        filterType,
        canDuplicate,
        onDuplicatePressed,
        lastBookedId
    } = props;

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    const onChangeExecuted = (d: Moment) => {
        setFieldValue("executedUtc", d);
    }

    const nullIns = "Select Instrument...";
    const nullCcy = "Select Ccy...";
    const nullEntity = "Select Entity...";

    const onInsSelect = (ins: ListedInstrument | string) => {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            setFieldValue("listedInstrumentId", solidIns.listedInstrumentId)
            setFieldValue("currency", solidIns.ccy)
            setFieldTouched("listedInstrumentId", true, false);
            setFieldTouched("currency", true, false);
        }
    }

    const onTraderSelect = (u: UserSummary | string) => {
        var solidUser = u as UserSummary;
        if (solidUser) {
            setFieldValue("createdBy", solidUser.userId);
            setFieldTouched("createdBy", true, false);
        }
    }

    const onBookingEntitySelect = (firm: string) => {
        var matches = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === firm);
        if (matches.length > 0) {
            setFieldValue("bookingEntityId", matches[0].firmId);
        }
        else
            setFieldValue("bookingEntityId", null);
    }

    const onStringSelect = (val: string, propName: string) => { setFieldValue(propName, val) }

    const filterInsOptions = (options: ListedInstrument[], state: any): ListedInstrument[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => insMatchesQuery(o, query))
        return relevant;
    };

    const filterStringOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => strMatchesQuery(o, query))
        return relevant;
    };

    const insMatchesQuery = (ins: ListedInstrument, query: string): boolean => {
        const normalizedTitle = ins?.description?.toLowerCase();
        const code = ins?.ticker?.toLowerCase();
        const allMetaData = ins?.metaData ? ins.metaData.map(am => am.data.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${ins.listedInstrumentId}. ${normalizedTitle} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    const strMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const getInstruments = () => {
        return filterType ?
            [nullIns, ...listedInstrumentStoreInstance.getInstruments().filter(x => x.type === filterType).sort((a, b) => a.type > b.type ? 1 : -1)] :
            [nullIns, ...listedInstrumentStoreInstance.getInstruments().sort((a, b) => a.type > b.type ? 1 : -1)];
    }

    const filterCcyOptions = (options: string[], state: any): string[] => {
        var query = state.inputValue.toLowerCase();
        var relevant = options.filter(o => ccyMatchesQuery(o, query))
        return relevant;
    };

    const ccyMatchesQuery = (ins: string, query: string): boolean => {
        const normalizedTitle = ins.toLowerCase();
        const normalizedQuery = query.toLowerCase();

        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }

    const onCcySelect = (ccy: string) => {
        if (productStoreInstance.getAvailableCurrencies().includes(ccy)) {
            setFieldValue("currency", ccy)
        }
    }

    return (
        <Form onSubmit={handleSubmit} className={className}>
            <CssBaseline>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} justifyContent="center" >
                            <Grid item>
                                <Autocomplete
                                    classes={{
                                        inputRoot: "BasketDesignerAutocomplete",
                                        popupIndicator: "BasketDesignerAutocompleteIcon",
                                        clearIndicator: "BasketDesignerAutocompleteIcon",
                                        popper: "AutocompleteGroupLabel",
                                    }}
                                    autoComplete
                                    options={getInstruments()}
                                    value={listedInstrumentStoreInstance.getInstrumentByIdOrNull(listedInstrumentId) ?? nullIns}
                                    id="listedInstrumentId"
                                    groupBy={(ins: ListedInstrument) => ins.type}
                                    renderGroup={(params) => <div><Typography variant="h6">{params.group}</Typography>{params.children}</div>}
                                    getOptionLabel={(ins: ListedInstrument) => ins?.description ?? nullIns}
                                    style={{ width: 300 }}
                                    filterOptions={filterInsOptions}
                                    onChange={(e, v) => onInsSelect(v)}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            helperText={touched.listedInstrumentId ? errors.listedInstrumentId : ""}
                                            error={Boolean(errors.listedInstrumentId)}
                                            label="Instrument"
                                            variant="outlined" />} />
                            </Grid>
                            <Grid item>
                                <WrappedDateTimePicker
                                    size="medium"
                                    id="maturity"
                                    name="maturity"
                                    helperText={touched.executedUtc ? errors.executedUtc : ""}
                                    error={Boolean(errors.executedUtc)}
                                    label="Executed (UTC)"
                                    value={executedUtc}
                                    onChange={(d) => onChangeExecuted(d)} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="size"
                                    name="size"
                                    helperText={errors.size ? errors.size : ""}
                                    error={Boolean(errors.size)}
                                    label="Quantity"
                                    value={size}
                                    onChange={change.bind(null, "type")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="price"
                                    name="price"
                                    helperText={errors.price ? errors.price : ""}
                                    error={Boolean(errors.price)}
                                    label="Price"
                                    value={price}
                                    onChange={change.bind(null, "price")}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            <Grid item>
                                <Autocomplete
                                    classes={{
                                        inputRoot: "BasketDesignerAutocomplete",
                                        popupIndicator: "BasketDesignerAutocompleteIcon",
                                        clearIndicator: "BasketDesignerAutocompleteIcon",
                                        popper: "AutocompleteGroupLabel",
                                    }}
                                    autoComplete
                                    options={[nullCcy, ...productStoreInstance.getAvailableCurrencies()]}
                                    value={currency ?? nullCcy}
                                    id="currency"
                                    getOptionLabel={(ccy: string) => ccy}
                                    style={{ width: 150 }}
                                    filterOptions={filterCcyOptions}
                                    onChange={(e, v) => onCcySelect(v)}
                                    renderInput={(params) => <TextField {...params}
                                        label="Currency"
                                        variant="outlined"
                                        helperText={errors.currency ? errors.currency : ""}
                                        error={Boolean(errors.currency)} />} />
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    classes={{
                                        inputRoot: "BasketDesignerAutocomplete",
                                        popupIndicator: "BasketDesignerAutocompleteIcon",
                                        clearIndicator: "BasketDesignerAutocompleteIcon",
                                        popper: "AutocompleteGroupLabel",
                                    }}
                                    autoComplete
                                    options={userStoreInstance.GetUsers()}
                                    value={createdBy ? userStoreInstance.GetUsers().filter(u => u.userId === createdBy)[0] : userStoreInstance.GetUsers().filter(u => u.userId === userStoreInstance.GetUserInfo().userId)[0]}
                                    id="trader"
                                    getOptionLabel={(u: UserSummary) => u.name}
                                    style={{ width: 260 }}
                                    onChange={(e, v) => onTraderSelect(v)}
                                    renderInput={(params) => <TextField {...params}
                                        label="Trader"
                                        variant="outlined"
                                        helperText={errors.trader ? errors.trader : ""}
                                        error={Boolean(errors.trader)} />} />
                            </Grid>
                            <Grid container item spacing={2} justifyContent="center" >
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={["", ...tradesStoreInstance.getBooks()]}
                                        value={book ?? ""}
                                        id="book"
                                        getOptionLabel={(v: string) => v}
                                        style={{ width: 300 }}
                                        filterOptions={filterStringOptions}
                                        onChange={(e, v) => onStringSelect(v, "book")}
                                        renderInput={(params) => <TextField {...params}
                                            label="Book"
                                            variant="outlined"
                                            helperText={errors.book ? errors.book : ""}
                                            error={Boolean(errors.book)} />} />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={["", ...tradesStoreInstance.getStrategies()]}
                                        value={strategy ?? ""}
                                        id="strategy"
                                        getOptionLabel={(v: string) => v}
                                        style={{ width: 300 }}
                                        filterOptions={filterStringOptions}
                                        onChange={(e, v) => onStringSelect(v, "strategy")}
                                        renderInput={(params) => <TextField {...params}
                                            label="Strategy"
                                            variant="outlined"
                                            helperText={errors.strategy ? errors.strategy : ""}
                                            error={Boolean(errors.strategy)} />} />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        classes={{
                                            inputRoot: "BasketDesignerAutocomplete",
                                            popupIndicator: "BasketDesignerAutocompleteIcon",
                                            clearIndicator: "BasketDesignerAutocompleteIcon",
                                            popper: "AutocompleteGroupLabel",
                                        }}
                                        autoComplete
                                        options={[nullEntity, ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)]}
                                        value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === bookingEntityId)[0]?.displayName ?? nullEntity}
                                        id="bookingEntity"
                                        getOptionLabel={(firm: string) => firm}
                                        style={{ width: 150 }}
                                        filterOptions={filterCcyOptions}
                                        onChange={(e, v) => onBookingEntitySelect(v)}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={errors.bookingEntity ? errors.bookingEntity : ""}
                                            error={errors.bookingEntity !== undefined || Boolean(errors.bookingEntity)}
                                            label="Booking Entity"
                                            variant="outlined" />} />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id="account"
                                        name="account"
                                        helperText={errors.account ? errors.account : ""}
                                        error={Boolean(errors.account)}
                                        label="Account"
                                        value={account}
                                        onChange={change.bind(null, "Account")}
                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>
                            </Grid>
                            <GridBreak />
                            <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="submit"
                                    variant="outlined"
                                    disabled={!isValid || canDuplicate}
                                    endIcon={<CheckCircleOutline />}>Submit</Button></Grid>
                                <Grid item><Button
                                    className="PltfmButtonLite"
                                    type="reset"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Revert</Button></Grid>
                                {canDuplicate ? <Grid item><Button
                                    className="PltfmButtonLite"
                                    variant="outlined"
                                    onClick={onDuplicatePressed}
                                    endIcon={<CancelOutlined />}>Duplicate</Button></Grid> : null}
                            </Grid>
                            {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                                <Grid item><Typography variant="subtitle2">Last booked id {lastBookedId}</Typography></Grid>
                            </Grid> : null}
                        </Grid>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CssBaseline>
        </Form >
    );
};