import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { ComputeVaRWhatIfMulti } from './riskActions';
import { VaRResult, WhatIfTrade } from './riskModels';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { TabContext, TabPanel } from '@mui/lab';
import { DataGridPro, GridCellParams, GridColDef, GridRenderCellParams, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { colCommon } from '../positions/positionsCommon';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { Formik, FormikHelpers } from 'formik';
import { WhatIfTradeForm } from './whatIfTradeForm';
import { v4 } from 'uuid'
import { SubscribeToJobUpdates, UnSubscribeFromJobUpdates } from '../userSession/userSessionActions';
import taskUpdateStoreInstance from '../userSession/taskUpdateStore';
import * as Yup from 'yup';
import { CancelOutlined, DeleteOutlined } from '@mui/icons-material';
import { GetSessionState, SetSessionState } from '../trade/tradeBlotterTabComponent';

const renderIdCell = (params: GridCellParams) => {
    return <span>{(params.value as number).toFixed(0)}</span>;
}

export const WhatIfTradeValidationSchema = Yup.object().shape({
    size: Yup.number()
        .required('Required')
        .typeError('Valid size required'),
    price: Yup.number()
        .required('Required')
        .typeError('Valid price required'),
    insId: Yup.number()
        .required('Required')
        .typeError('Instrument required'),
});


interface WhatIfViewState {
    varrr: VaRResult;
    date: Date;
    waiting: boolean;
    ci: string;
    windowSize: string;
    whatIfTrades: WhatIfTrade[];
    activeTrade: WhatIfTrade;
    selectedTabId: number;
    jobKey: string;
    jobMessage: string;
    showAreYouSureClear: boolean;
}

export interface WhatIfViewProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class WhatIfView extends React.Component<WhatIfViewProps, WhatIfViewState>{
    eventSubscriptionUpdates: FBEmitter.EventSubscription | undefined;
    constructor(props: WhatIfViewProps) {
        super(props)
        var trades = GetSessionState<WhatIfTrade[]>("whatIfTrades");
        this.state = {
            varrr: undefined,
            waiting: false,
            ci: "0.95",
            date: new Date(),
            windowSize: "1y",
            selectedTabId: 0,
            whatIfTrades: trades ?? [],
            activeTrade: undefined,
            jobKey: v4(),
            jobMessage: undefined,
            showAreYouSureClear: false
        };;
        this.calcVaR = this.calcVaR.bind(this);
        this.onAddTrade = this.onAddTrade.bind(this);
        this.onJobUpdate = this.onJobUpdate.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.onDuplicateTrade = this.onDuplicateTrade.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionUpdates = taskUpdateStoreInstance.addChangeListener(this.onJobUpdate);
    }

    async onJobUpdate() {
        var status = taskUpdateStoreInstance.GetLatestStatus(this.state.jobKey);
        if (status?.update !== this.state.jobMessage) {
            this.setState({ jobMessage: status?.update });
        }
        if (status?.isFinished) {
            await UnSubscribeFromJobUpdates(this.state.jobKey);
        }
    }

    async componentWillUnmount() {
        SetSessionState("whatIfTrades", this.state.whatIfTrades);
    }


    WhatIfTradeColumns: GridColDef[] = [
        { field: "id", headerName: "Id", type: "number", flex: 1, ...colCommon, renderCell: renderIdCell },
        { field: "insId", headerName: "InsId", type: "number", flex: 1, ...colCommon, renderCell: renderIdCell },
        { field: "instrument", headerName: "Instrument", type: "string", flex: 2, ...colCommon, },
        { field: "size", headerName: "Size", type: "number", flex: 1.5, ...colCommon },
        { field: "price", headerName: "Price", type: "number", flex: 1.5, ...colCommon },
        {
            field: "actions", headerName: "Actions", type: "actions", sortable: false, disableColumnMenu: true, flex: 1.2, width: 200, renderCell: (params: GridRenderCellParams) => <ButtonGroup size="small" variant="text">
                <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Remove</Typography>} >
                    <span><IconButton
                        onClick={() => this.onRemoveTrade(params.id.toString())}
                        size="medium">
                        <CancelOutlined />
                    </IconButton></span>
                </Tooltip>
            </ButtonGroup>,
            ...colCommon
        }
    ]

    async calcVaR() {
        const { whatIfTrades, jobKey } = this.state;
        await SubscribeToJobUpdates(jobKey);
        this.setState({ varrr: undefined, waiting: true, jobMessage: undefined });
        const varrr = await ComputeVaRWhatIfMulti(this.state.date, whatIfTrades, Number(this.state.ci), this.state.windowSize, jobKey);
        this.setState({ varrr, waiting: false });
    }

    onClickClearAll() {
        this.setState({ showAreYouSureClear: false, whatIfTrades: [] })
    }

    customToolbar(props: any) {
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer>
                    <Grid container spacing={1} justifyContent="space-between" direction="row" style={{ padding: "5px" }}>
                        <Grid item container spacing={1} justifyContent="flex-start" style={{ width: "50%" }}>
                            <Grid item>
                                <GridToolbarExport {...props} className="MuiButton-outlined PltfmButtonLite" />
                            </Grid>
                            <Grid item>
                                <GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                            </Grid>
                            <Grid item>
                                <GridToolbarDensitySelector {...props} className="MuiButton-outlined PltfmButtonLite" />
                            </Grid>
                            <Grid item>
                                <GridToolbarColumnsButton {...props} className="MuiButton-outlined PltfmButtonLite" />
                            </Grid>
                        </Grid>

                        <Grid item container spacing={1} justifyContent="flex-end" style={{ width: "50%" }}>
                            <Grid item>
                                <Button className="MuiButton-outlined PltfmButtonLite" onClick={() => this.setState({ showAreYouSureClear: true })}>Clear All</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    onAddTrade(values: WhatIfTrade, formikHelpers: FormikHelpers<WhatIfTrade>) {
        const { whatIfTrades } = this.state;
        if (values.id !== undefined) {
            whatIfTrades[values.id] = values;
        }
        else {
            whatIfTrades.push(values);
        }
        this.setState({ whatIfTrades })
    }

    onRemoveTrade(id: string) {
        var whatIfTrades = this.state.whatIfTrades.filter((w, ix) => ix.toString() !== id);
        this.setState({ whatIfTrades })
    }

    onDuplicateTrade() {
        const { activeTrade } = this.state;
        var duplicate = { ...activeTrade };
        duplicate.id = undefined;
        this.setState({ activeTrade: duplicate });
    }

    renderAddTradeForm() {
        const { activeTrade } = this.state;
        return (<Formik
            onSubmit={this.onAddTrade}
            onReset={() => { }}
            enableReinitialize={true}
            initialValues={activeTrade ?? {} as WhatIfTrade}
            validationSchema={WhatIfTradeValidationSchema}>
            {props => <WhatIfTradeForm className="NewTradeForm" {...props} onDuplicatePressed={() => this.onDuplicateTrade()} />}
        </Formik>);
    }

    onClickRow(param: GridRowParams, event: React.MouseEvent<Element, MouseEvent>) {
        var idVal = Number(param.id);
        var data2 = this.state.whatIfTrades[idVal];
        if (data2) {
            data2.id = idVal;
            this.setState({ activeTrade: data2 });
        }
    }

    renderTrades() {
        const { whatIfTrades, showAreYouSureClear } = this.state;

        const rows = whatIfTrades?.map((w, ix) => {
            return {
                id: ix,
                insId: w.insId,
                instrument: listedInstrumentStoreInstance.getInstrumentById(w.insId)?.description,
                size: w.size,
                price: w.price
            };
        });

        return <div className="AddUpdateCashComponent">
            <ThemeProvider theme={getFormTheme()}>
                <Dialog key="cancelOrderAreYouSure"
                    open={showAreYouSureClear}
                    onClose={() => this.setState({ showAreYouSureClear: false })}>
                    <DialogTitle>Clear all trades?</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2">Are you sure?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ showAreYouSureClear: false })} startIcon={<CancelOutlined />}>Abort</Button>
                        <Button onClick={() => this.onClickClearAll()} startIcon={<DeleteOutlined />}>Proceed</Button>
                    </DialogActions>
                </Dialog>
                <Grid container item spacing={2} direction="column" justifyContent="space-between" alignItems="flex-start" height="calc(100% - 40px)">
                    <Grid item className="AddTradeMain">
                        {this.renderAddTradeForm()}
                    </Grid>
                    <Grid item className="AddEditCashTransGrid">
                        <DataGridPro
                            columns={this.WhatIfTradeColumns}
                            rows={rows}
                            className="TradeBlotterDataGrid"
                            density="compact"
                            components={{
                                Toolbar: this.customToolbar
                            }}
                            onRowClick={this.onClickRow}
                            hideFooter />
                    </Grid>
                </Grid>
            </ThemeProvider>
        </div>
    }

    renderResults() {
        const { waiting, varrr, jobMessage } = this.state;
        const gridItemProps = { item: true, sm: 6, xl: 6, alignContent: "center", justifyContent: "center" }; //height:"calc(40vh - 100px)"

        return <ThemeProvider theme={getFormTheme()}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "flex-start", width: "calc(100% - 10px)", height: "calc(100% - 10px)" }}>
                <Grid container spacing={2} justifyContent="flex-start" alignContent="flex-start" direction="column" columns={{ xs: 1, sm: 1, md: 1, l: 1, xl: 1 }} >
                    <Grid container item spacing={2} justifyContent="flex-start" alignContent="center" direction="row">
                        <Grid item><Button variant="outlined" onClick={this.calcVaR} disabled={waiting}>Calculate</Button></Grid>
                    </Grid>
                    {waiting ? <Grid container item justifyContent="center" direction="row" alignContent={"center"} sm={6} xl={6}>
                        <Grid container item justifyContent="center" direction="column">
                            <Grid item><CircularProgress /></Grid>
                            <Grid item><Typography variant='body2'>{jobMessage}</Typography></Grid>
                        </Grid>
                    </Grid> :
                        varrr ? <Grid container item spacing={2} height="calc(100vh - 250px)" alignContent="space-around" justifyContent="space-around">
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>Baseline</Typography></Grid>
                                <Grid item><Typography align='center' variant='h6'>USD VaR @ {varrr.confidenceInterval * 100}%</Typography></Grid>
                                <Grid item><Typography variant='h4'>{varrr?.baseline.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></Grid>
                            </Grid>
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>New</Typography></Grid>
                                <Grid item><Typography align='center' variant='h6'>USD VaR @ {varrr.confidenceInterval * 100}%</Typography></Grid>
                                <Grid item><Typography variant='h4'>{varrr?.vaR.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></Grid>
                            </Grid>
                            <Grid container item direction="column" {...gridItemProps}>
                                <Grid item><Typography align='center' variant='h6'>Differential</Typography></Grid>
                                <Grid item><Typography align='center' variant='h6'>USD VaR @ {varrr.confidenceInterval * 100}%</Typography></Grid>
                                <Grid item><Typography variant='h4'>{(varrr?.vaR - (varrr?.baseline ?? 0)).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></Grid>
                            </Grid>
                        </Grid> : null}
                </Grid>
            </div>
        </ThemeProvider>;
    }

    render() {
        const { selectedTabId } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistory"}
                        component={Paper}
                        id={"tab0"}
                        label="Trades" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabUpdate"}
                        component={Paper}
                        id={"tab1"}
                        label="Results" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabPQuery1"}
                    value={"0"}
                    //index={0}
                    children={this.renderTrades()} />
                <TabPanel
                    style={{ height: "calc(100vh - 120px)" }}
                    key={"tabPQuery2"}
                    value={"1"}
                    //index={1}
                    children={this.renderResults()} />
            </TabContext>
        </ThemeProvider>
        );
    }
}
