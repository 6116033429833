import { GridCellParams } from "@mui/x-data-grid-pro";
import moment, { Moment } from "moment";
import { BookStr } from "../globalConstants";
import userStoreInstance from "../user/userStore";

export function handleBooleanChange(handler: (checked: boolean) => void) {
    return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).checked);
}

export function renderDate(date: Date) {
    var d = new Date(date);
    var t = new Date();
    if (d.getDate() === t.getDate() && d.getMonth() === t.getMonth() && d.getFullYear() === t.getFullYear()) {
        //return d.toLocaleTimeString();
        return moment(d).format("HH:mm:ss");
    }
    else {
        return moment(d).format(userStoreInstance.GetDateFormat());
    }
}

export function renderFullDateTime(date: Date) {
    var d = new Date(date);
    return d.toISOString();
}

export const renderDateCell = (params: GridCellParams, valIfNull: string = null) => {
    return params.value ? <span>{renderDate(new Date(params.value as string))}</span> : valIfNull;
}

export const renderDateTimeCell = (params: GridCellParams, valIfNull: string = null) => {
    return params.value ? <span>{moment(new Date(params.value as string)).format(userStoreInstance.GetDateFormat() + " HH:mm:ss")}</span> : valIfNull;
}

export function dateFromOADate(oaDate: number) {
    // Treat integer part is whole days
    var days = Math.floor(oaDate);
    // Treat decimal part as part of 24hr day, always +ve
    var ms = Math.abs((oaDate - days) * 8.64e7);
    // Add days and add ms
    return new Date(1899, 11, 30 + days, 0, 0, 0, ms);
}


export function dateToOADate(date: Date) {
    var temp = new Date(date);
    // Set temp to start of day and get whole days between dates,
    var days = Math.round((temp.setHours(0, 0, 0, 0).valueOf() - new Date(1899, 11, 30).valueOf()) / 8.64e7);
    // Get decimal part of day, OADate always assumes 24 hours in day
    var partDay = (Math.abs((date.valueOf() - temp.valueOf()) % 8.64e7) / 8.64e7).toFixed(10);
    return days + partDay.substr(1);
}

const firstMoment = moment.utc({ year: 1899, month: 11, day: 30 });
const firstMomentMs = firstMoment.valueOf();

export function momentUtcToOADate(date: Moment) {
    var temp = moment.utc(date);
    // Set temp to start of day and get whole days between dates,
    var days = Math.round((temp.startOf("day").valueOf() - firstMomentMs) / 8.64e7);
    // Get decimal part of day, OADate always assumes 24 hours in day
    var partDay = (Math.abs((date.valueOf() - temp.valueOf()) % 8.64e7) / 8.64e7).toFixed(10);
    return days + partDay.substr(1);
}

export const isDemo = () => { return BookStr === "DemoBook" }

export const num2String = (val: number) => {
    if(val===undefined)
        return undefined;
        
    var dp = 0;
    if (val < 1)
        dp = 5
    else if (val < 10)
        dp = 4
    else if (val < 10000)
        dp = 2

    return val.toLocaleString(undefined, { maximumFractionDigits: dp })
}