import { LoadAssets } from '../assets/assetsActions';
import {AppDispatcher, Event} from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore';
import { LoadLayout, LoadTemplates } from '../layout/layoutActions';
import { LoadCurrencies, LoadPayoffs } from '../payoff/payoffActions';
import rfqConnection from '../rfq/rfqConnection';
import { LoadFirms } from '../user/firmActions';
import { LoadUserInfo } from '../user/userActions';
import userSessionConnection from '../userSession/userSessionConnection';
import marketDataConnection from '../marketData/marketDataConnection';
import { LoadingStatusChangeEvent } from './loadingActions';
import { LoadingInstance, LoadingState, LoadingStatus } from './loadingModels';
import { LoadListedInstruments, LoadListedInstrumentSummaries } from '../listedInstruments/listedInstrumentActions';
import { FetchStrategiesAndBooks } from '../trade/tradeActions';
import { GetJobList } from '../jobs/jobActions';
import { LoadFunds } from '../nav/navActions';
import { LoadSetSummaries } from '../marketData/marketDataActions';
import { GetSchedules } from '../schedules/schedulesActions';
import { LoadOrders } from '../orders/orderActions';
import { LoadReports } from '../reports/reportActions';
import { GetAcceleratorClassNames } from '../accelerator/acceleratorActions';
import { LoadFatFingerControls } from '../settings/matron/matronSettingsActions';

const loadingItems = new Map<string, LoadingInstance>([
    ["Firms", { name : "Firms", loadAction : () => LoadFirms(), status : LoadingStatus.NotStarted, comment : ""}],
    ["UserInfo", {name : "UserInfo", loadAction : () => LoadUserInfo(), status : LoadingStatus.NotStarted, comment : ""}],
    ["Assets", {name : "Assets", loadAction : () => LoadAssets(), status :LoadingStatus.NotStarted, comment : ""}],
    ["Payoffs", {name : "Payoffs", loadAction : () => LoadPayoffs(), status :LoadingStatus.NotStarted, comment : ""}],
    ["Currencies", {name : "Currencies", loadAction : () => LoadCurrencies(), status :LoadingStatus.NotStarted, comment : ""}],
    ["RfqConnection", {name :"RfqConnection", loadAction : () => rfqConnection.connect(), status :LoadingStatus.NotStarted, comment : ""}],
    ["UserSession", {name : "UserSession", loadAction: () => userSessionConnection.connect(), status: LoadingStatus.NotStarted, comment : ""}],
    ["MarketDataConnection", {name : "Market Data", loadAction : () => marketDataConnection.connect(), status : LoadingStatus.NotStarted, comment : "" }],
    ["Layout", {name : "Layout", loadAction: () => LoadLayout(), status: LoadingStatus.NotStarted, comment :""}],
    ["LayoutTemplates", {name : "Templates", loadAction: () => LoadTemplates(), status : LoadingStatus.NotStarted, comment : ""}],
    ["MarketDataSets", {name : "Market Sets", loadAction: () => LoadSetSummaries(), status : LoadingStatus.NotStarted, comment : ""}],
    ["ListedInstruments", {name : "Instrument Definitions", loadAction: () => LoadListedInstruments(), status : LoadingStatus.NotStarted, comment : ""}],
    ["ListedInstrumentSummaries", {name : "Instrument Summaries", loadAction: () => LoadListedInstrumentSummaries(), status : LoadingStatus.NotStarted, comment : ""}],
    ["StrategiesBooks", {name : "Strategies & Books", loadAction: () => FetchStrategiesAndBooks(), status : LoadingStatus.NotStarted, comment : ""}],
    ["JobSpecs", {name : "Job Specs", loadAction: () => GetJobList(), status : LoadingStatus.NotStarted, comment : ""}],
    ["FundDefinitions", {name : "Fund Definitions", loadAction: () => LoadFunds(), status : LoadingStatus.NotStarted, comment : ""}],
    ["Schedules", {name : "Job Schedules", loadAction: () => GetSchedules(), status : LoadingStatus.NotStarted, comment : ""}],
    ["Orders", {name: "Orders", loadAction: () => LoadOrders(), status : LoadingStatus.NotStarted, comment: ""}],
    ["Reports", {name: "Reports", loadAction: () => LoadReports(), status : LoadingStatus.NotStarted, comment: ""}],
    ["Accelerator", {name: "Accelerator Settings", loadAction: () => GetAcceleratorClassNames(), status : LoadingStatus.NotStarted, comment: ""}],
    ["Trading", {name: "Trading Settings", loadAction: () => LoadFatFingerControls(), status : LoadingStatus.NotStarted, comment: ""}],
]);

class LoadingStore extends FluxStore<LoadingState> {
    started : boolean;

    constructor(dispatcher : typeof AppDispatcher){
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
           loadingItems : loadingItems,
           loaded : false,
        }))
        this.started = false;
    }

    public start()
    {
        if(this.started) return;
        loadingItems.forEach((value) =>{
            if(value.status === LoadingStatus.NotStarted) {
                value.loadAction();
                value.status = LoadingStatus.Loading;
            }
        });
        this.started = true;
    }
    
    private onDispatchHandler(action : Event)
    {
        if(action instanceof LoadingStatusChangeEvent && action.payload){
            let item = this.state.loadingItems.get(action.payload.name);
            if(!item)return;
            item.status = action.payload.status;

            //Here we need to check if we are completed loading
            let allLoaded = true;
            loadingItems.forEach((value) => {
                if(value.status !== LoadingStatus.Completed){allLoaded = false}
            });
            if(!this.state.loaded){
                this.state.loaded = allLoaded;
            }

            this.emitChange();
        }
    }

    public getState(){
        return this.state;
    }
}

const loadingStoreInstance = new LoadingStore(AppDispatcher);
export default loadingStoreInstance;