import { withMsal, WithMsalProps } from '@azure/msal-react';
import { Button, CircularProgress, CssBaseline, Grid, Typography } from '@mui/material';
import React from 'react'
import { loginRequest } from '../authConfig';
import { StringTypeDropDown } from '../inputs/assetGroupDropDown';
import Loading from '../loading/loadingControl';
import { UserSummary } from '../user/userModel'
import userToken, { get } from '../utils/httpUtils'
import bootStrapStoreInstance from '../bootstrap/bootstrapStore';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';

type LoginState = {
    users: UserSummary[],
    userId: string,
    tokenLoaded: boolean,
}

class LoginRaw extends React.Component<WithMsalProps, LoginState>{

    constructor(props) {
        super(props);
        this.state = { users: [], userId: "", tokenLoaded: false };
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }


    async componentDidMount() {
        const account = this.props.msalContext.instance.getAccountByHomeId(this.props.msalContext.accounts[0].homeAccountId);
        this.props.msalContext.instance.setActiveAccount(account);
        userToken.context = this.props.msalContext;
        try
        {
            userToken.accessToken = (await this.props.msalContext.instance.acquireTokenSilent({ ...loginRequest })).accessToken
        }
        catch
        {
            await this.props.msalContext.instance.acquireTokenRedirect({ ...loginRequest });
            userToken.accessToken = (await this.props.msalContext.instance.acquireTokenSilent({ ...loginRequest })).accessToken
        }
        this.setState({ tokenLoaded: true });

        if (bootStrapStoreInstance.getState().AuthInfo.displayImpersonation) {
            var users = await get<UserSummary[]>('user/all');
            if (users.payload) {
                this.setState({ users: users.payload, userId: users.payload[0].userId.toString() });
            }
        }
    }

    onChangeSelect(value: string) {
        this.setState({ userId: this.state.users.filter(u => u.name === value)[0].userId.toString() });
    }

    onLogin() {
        userToken.userId = this.state.userId;
        this.forceUpdate();
    }

    render() {

        if (userToken.userId || (!bootStrapStoreInstance.getState().AuthInfo.displayImpersonation && this.state.tokenLoaded)) {
            return (<Loading></Loading>);
        }
        else if (this.state.users.length === 0 || (!userToken.isDevMode)) {
            return <div className="LoginBox"><div className="LoginBoxInner"><CircularProgress /></div></div>
        }
        else {
            const selectedUser = this.state.users.filter(u => u.userId.toString() === this.state.userId)[0];
            return (<div className="LoginBox">
                <div className="LoginBoxInner">
                    <CssBaseline>
                    <ThemeProvider theme={getFormTheme()}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Typography variant="h6" style={{ textAlign: "center" }}>Select User</Typography>
                            </Grid>
                            <Grid item>
                                <StringTypeDropDown possibles={this.state.users.map(u => u.name)} disabled={false} onChange={this.onChangeSelect} selected={selectedUser.name} key={"login"} />
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" className="PltfmButtonClean" onClick={() => this.onLogin()}>Login</Button>
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                    </CssBaseline>
                </div>
            </div>)
        }
    }
}

const Login = withMsal(LoginRaw);

export default Login;