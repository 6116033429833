import FluxStore from '../FluxStore'
import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import { JobResultStoreState, JobResultUpdate, JobSpec, NewJobRequest } from "./jobModels";
import { JobSpecsLoadedEvent, NewJobUpdateEvent } from './jobActions';

export class JobResultStore extends FluxStore<JobResultStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            results: {},
            specs: new Array<JobSpec>(),
            instances: new Array<NewJobRequest>()
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewJobUpdateEvent) {
            const { payload } = action;
            if (!this.state.results[payload.jobKey])
                this.state.results[payload.jobKey] = new Array<JobResultUpdate>();
            this.state.results[payload.jobKey].push(action.payload);
            this.emitChange();
        }
        else if (action instanceof JobSpecsLoadedEvent) {
            const { payload } = action;
            this.state.specs = payload;
            this.emitChange();
        }
    }

    public getResults(jobKey: string): JobResultUpdate[] {
        return this.state.results[jobKey];
    }

    public getSpecs(): JobSpec[] {
        return this.state.specs;
    }

    public getSpec = (jobName: string) => this.state.specs.filter(s => s.jobName === jobName)[0];

    public addJobInstance(instance: NewJobRequest) {
        this.state.instances.push(instance);
    }

    public getInstances = () => this.state.instances;

    public getCategories = () => Array.from(new Set(this.state.specs.map(s=>s.category)));
}

const jobResultStoreInstance = new JobResultStore(AppDispatcher);
export default jobResultStoreInstance;