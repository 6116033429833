import {TypedEvent, AppDispatcher} from '../dispatcher/appDispatcher';
import {InboxItem} from './inboxModels';
import {get} from '../utils/httpUtils';

export class InboxUpdatedEvent extends TypedEvent<InboxItem> {}
export class InboxLoadedEvent extends TypedEvent<InboxItem[]> {}
export class InboxLoadedFailedEvent extends TypedEvent<string>{}

export function InboxUpdated(inboxItem : InboxItem){
    AppDispatcher.dispatch(new InboxUpdatedEvent(inboxItem));
}

export async function LoadInbox() {
    const assets = await get<InboxItem[]>("Inbox");
    if(assets.payload == null)
    {
        AppDispatcher.dispatch(new InboxLoadedFailedEvent("Failed to load the inbox for the user"));
    }
    else
    {
        AppDispatcher.dispatch(new InboxLoadedEvent(assets.payload));
    }
}