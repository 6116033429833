import { Typography, LinearProgress } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid-pro";
import userStoreInstance from "../user/userStore";

export function loading(jobUpdateMessage: string) {
    return (
        <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
            <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "space-around",
                    paddingTop: "30px",
                    color: userStoreInstance.GetTheme().color + " !important"
                }}>
                    <Typography variant="h5" color={userStoreInstance.GetTheme().color}>{jobUpdateMessage ?? "Loading..."}</Typography>
                </div>
                <div style={{ height: "0.5em" }}> </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }} >
                    <div style={{ width: "50%" }} >
                        <LinearProgress />
                    </div>
                </div>

            </div>
        </GridOverlay>
    );
}

export function blankLoading() {
    return (
        <GridOverlay color={userStoreInstance.GetTheme().background_color_opaque}>
            
        </GridOverlay>
    );
}