import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, CircularProgress, Grid, List, ListItem, Paper, Tab, Tabs, TextField, Typography } from '@mui/material';
import { ComputeVaR } from '../risk/riskActions';
import { VaRResult } from '../risk/riskModels';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { EarliestDate } from '../globalConstants';
import { TabContext, TabPanel } from '@mui/lab';

interface EngineeringVaRTabState {
    varrr: VaRResult;
    date: Date;
    waiting: boolean;
    ci: string;
    windowSize: string;
    selectedTabId: number;
}

export interface EngineeringVaRTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class EngineeringVaRTab extends React.Component<EngineeringVaRTabProps, EngineeringVaRTabState>{
    eventSubscriptionTicks: FBEmitter.EventSubscription | undefined;
    constructor(props: EngineeringVaRTabProps) {
        super(props)
        this.state = {
            varrr: undefined,
            waiting: false,
            ci: "0.95",
            date: new Date(),
            windowSize: "1y",
            selectedTabId: 0
        };;
        this.calcVaR = this.calcVaR.bind(this);
    }

    async componentDidMount() {
    }

    async componentWillUnmount() {
    }

    async calcVaR() {
        this.setState({ varrr: undefined, waiting: true });
        const varrr = await ComputeVaR(this.state.date, Number(this.state.ci), this.state.windowSize);
        this.setState({ varrr, waiting: false });
    }

    renderControls() {
        const { varrr, waiting, ci, date, windowSize } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", width: "calc(100% - 10px)", height: "calc(100% - 10px)" }}>
                <Grid container spacing={2} justifyContent="center" alignContent="center" direction="column">
                    <Grid item container justifyContent="center"><Grid item><TextField value={ci} label="Confidence Interval" onChange={(e) => this.setState({ ci: e.target.value })} /></Grid></Grid>
                    <Grid item container justifyContent="center"><Grid item><TextField value={windowSize} label="History Length" onChange={(e) => this.setState({ windowSize: e.target.value })} /></Grid></Grid>
                    <Grid item container justifyContent="center"><Grid item>
                        <WrappedDatePicker
                            style={{ width: "120px" }}
                            value={date}
                            onChange={(d) => this.setState({ date: d.toDate() })}
                            disableFuture
                            minDate={EarliestDate}
                            emptyLabel="Live"
                            clearLabel="Live"
                            label={"As-Of"} />
                    </Grid>
                    </Grid>
                    <Grid item container justifyContent="center"><Grid item><Button variant="outlined" onClick={this.calcVaR} disabled={waiting}>Calc VaR</Button></Grid></Grid>
                    {varrr ? <Grid item container justifyContent="center"><Grid item><Typography variant='h4'>{varrr?.vaR.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></Grid></Grid> : null}
                    {waiting ? <Grid item container justifyContent="center"><Grid item><CircularProgress /></Grid></Grid> : null}

                </Grid>
            </div>
        </ThemeProvider>)
    }

    renderDetail() {
        const { varrr } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            {varrr ?
                <List style={{ height: "calc(100vw - 200px" }}>
                    {Object.keys(varrr.numPointsByUnderlying).map((insId, ix) => {
                        return <ListItem key={`${ix}-vaRNPointz`}>
                            {listedInstrumentStoreInstance.getInstrumentById(Number(insId))?.description} / {varrr.numPointsByUnderlying[insId]}
                        </ListItem>
                    })}
                </List>
                : null}
        </ThemeProvider>)
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    render() {
        const { selectedTabId } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistory"}
                        component={Paper}
                        id={"tab0"}
                        label="Calculation" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabUpdate"}
                        component={Paper}
                        id={"tab1"}
                        label="Detail" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery1"}
                    value={"0"}
                    //index={0}
                    children={this.renderControls()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery2"}
                    value={"1"}
                    //index={1}
                    children={this.renderDetail()} />
            </TabContext>
        </ThemeProvider>
        );
    }
}
