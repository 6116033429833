import React from 'react';
import FBEmitter from 'fbemitter';
import { WrappedPayoff } from '../../payoff/models/wrappedPayoff';
import { Wrapper } from '../../payoff/models/wrapper';
import { NoteDetail } from '../../payoff/models/noteDetail';
import { StructuredSwapDetail } from '../../payoff/models/structuredSwapDetail';
import { OtcOptionDetail } from '../../payoff/models/otcOptionDetail';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { SolveFor } from '../../payoff/models/solveFor';
import productStoreInstance from '../../product/productStore';
import { CurrencyConversionType } from '../../qwack/currencyConversionType';
import { ProductSpecPremium } from '../../product/productModels';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { CircularProgress, Grid, ThemeProvider, Typography } from '@mui/material';
import { subRowProps, subCellProps, subTitleProps, subTitleVariant, subValueProps } from './helpers';
import { getFormTheme } from '../../inputs/formCommon';

export type WrapperDetailBoxProps = {
    updateWrapperDetail: (detail: WrappedPayoff) => void,
    initialDetail: WrappedPayoff,
    disabled: boolean
}

export type WrapperDetailBoxState = {
    detail: WrappedPayoff,
    availableSolveFors: string[],
    availableCcys: string[]
}

export const AvailableIssuers = ["BBVA", "Commerzbank", "SEB"]

export class WrapperDetailBox extends React.Component<WrapperDetailBoxProps, WrapperDetailBoxState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: WrapperDetailBoxProps) {
        super(props);
        this.state = {
            detail: this.props.initialDetail ?? {
                noteDetail: WrapperDetailBox.getDefaultNoteDetail(),
                structuredSwapDetail: WrapperDetailBox.getDefaultSwapDetail(),
                otcOptionDetail: WrapperDetailBox.getDefaultOtcOptionDetail(),
                wrapperType: Wrapper.Note,
                currencyConversionType: CurrencyConversionType.Quanto,
                solveFor: SolveFor.Premium
            } as WrappedPayoff,
            availableSolveFors: [],
            availableCcys: []
        }

        this.onChangeRedemptionPct = this.onChangeRedemptionPct.bind(this);
        this.onChangeIssuer = this.onChangeIssuer.bind(this);
        this.onChangeSolveFor = this.onChangeSolveFor.bind(this);

        this.onChangeSwapCcy = this.onChangeSwapCcy.bind(this);
        this.onChangeSwapIndex = this.onChangeSwapIndex.bind(this);
        this.onChangeSwapSpread = this.onChangeSwapSpread.bind(this);
    }

    static getDerivedStateFromProps(props: WrapperDetailBoxProps, state: WrapperDetailBoxState) {
        if (props.initialDetail.wrapperType !== state.detail.wrapperType) {
            let somethingChanged = false;
            switch (props.initialDetail.wrapperType) {
                case Wrapper.Note:
                    if (!state.detail.noteDetail)
                        state.detail.noteDetail = this.getDefaultNoteDetail();
                    state.detail.solveFor = SolveFor.BookOpen;
                    break;
                case Wrapper.StructuredSwap:
                    if (!state.detail.structuredSwapDetail)
                        state.detail.structuredSwapDetail = this.getDefaultSwapDetail();
                    state.detail.solveFor = SolveFor.FundingSpread;
                    break;
                case Wrapper.OtcOption:
                    if (!state.detail.otcOptionDetail)
                        state.detail.otcOptionDetail = this.getDefaultOtcOptionDetail();
                    state.detail.solveFor = SolveFor.Premium;
                    break;
            }
            if (somethingChanged)
                return state;
        }
        return null;
    }

    private static getDefaultNoteDetail() {
        return { fixedCouponPercentage: 0, issuer: AvailableIssuers[0], isCallable: false } as NoteDetail;
    }
    private static getDefaultSwapDetail() {
        return { fundingCurrency: "USD", fundingIndex: "USD.LIBOR.3M", fundingSpreads: new Array<number>() } as StructuredSwapDetail;
    }
    private static getDefaultOtcOptionDetail() {
        return { premiumDetail: {} as ProductSpecPremium } as OtcOptionDetail;
    }

    public componentWillUnmount() {

    }

    componentDidMount() {
        this.fillPossibles();

        const { detail } = this.state;
        let somethingChanged = false;
        if (!detail.solveFor) {
            switch (detail.wrapperType) {
                case Wrapper.Note:
                    detail.solveFor = SolveFor.BookOpen;
                    break;
                case Wrapper.StructuredSwap:
                    detail.solveFor = SolveFor.FundingSpread;
                    break;
                case Wrapper.OtcOption:
                    detail.solveFor = SolveFor.Premium;
                    break;
            }
            somethingChanged = true;
        }

        if (somethingChanged)
            this.setState({ detail });
    }

    fillPossibles = () => {
        const availableSolveFors = Object.keys(SolveFor).filter(k => typeof SolveFor[k as any] === "number");
        const availableCcys = productStoreInstance.getAvailableCurrencies();
        this.setState({ availableSolveFors, availableCcys });
    }

    public componentDidUpdate() {
        const { detail } = this.state;
        let ccy = detail.structuredSwapDetail?.fundingCurrency;
        if (!ccy) {
            this.onChangeSwapCcy("USD");
        }
    }

    onChangeRedemptionPct(val: number) {
        const { detail } = this.state;
        if (!detail.noteDetail)
            detail.noteDetail = {} as NoteDetail;
        detail.noteDetail.redemptionPercentage = val;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }
    onChangeIssuer(val: string) {
        const { detail } = this.state;
        if (!detail.noteDetail)
            detail.noteDetail = {} as NoteDetail;
        detail.noteDetail.issuer = val;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }

    onChangeSwapCcy(val: string) {
        const { detail } = this.state;
        if (!detail.structuredSwapDetail)
            detail.structuredSwapDetail = {} as StructuredSwapDetail;
        if (detail.structuredSwapDetail.fundingCurrency !== val) {
            detail.structuredSwapDetail.fundingCurrency = val;
            let fundingIxs = this.getIndicesForCurrency(val);
            detail.structuredSwapDetail.fundingIndex = fundingIxs[0];
            this.setState({ detail });
            this.props.updateWrapperDetail(detail);
        }
    }
    onChangeSwapIndex(val: string) {
        const { detail } = this.state;
        if (!detail.structuredSwapDetail)
            detail.structuredSwapDetail = {} as StructuredSwapDetail;
        detail.structuredSwapDetail.fundingIndex = val;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }
    onChangeSwapSpread(val: number) {
        const { detail } = this.state;
        if (!detail.structuredSwapDetail)
            detail.structuredSwapDetail = {} as StructuredSwapDetail;
        if (!detail.structuredSwapDetail.fundingSpreads)
            detail.structuredSwapDetail.fundingSpreads = new Array<number>();
        detail.structuredSwapDetail.fundingSpreads[0] = val;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }


    onChangeSolveFor(val: string) {
        const { detail } = this.state;
        var sf = (SolveFor as any)[val];
        detail.solveFor = sf;
        this.setState({ detail });
        this.props.updateWrapperDetail(detail);
    }

    getIndicesForCurrency(ccy: string) {
        return [`${ccy}.IBOR.3M`, `${ccy}.IBOR.ON`, `${ccy}.REPO.ON`];
    }

    render() {
        const { availableSolveFors, availableCcys, detail } = this.state;

        if (availableSolveFors.length === 0 || availableCcys.length === 0)
            return <CircularProgress />

        switch (detail.wrapperType) {
            case Wrapper.Note:
                return (  <ThemeProvider theme={getFormTheme()}>
                <Grid item container paddingTop="0.25em">
                    <Grid {...subRowProps} >
                        <Grid {...subCellProps}>
                            <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Redemption</Typography></Grid>
                            <Grid {...subValueProps}><ValidatedNumericInput units="%" keyName="redemptionPct" placeholder="% Nominal" style={{ width: "120px" }} initialValue={detail.noteDetail?.redemptionPercentage} onChange={(v) => { this.onChangeRedemptionPct(v); return true; }} disabled={this.props.disabled} /></Grid>
                        </Grid>
                        <Grid {...subCellProps} width={180}>
                            <Grid {...subTitleProps} width={80}><Typography textAlign='right' variant={subTitleVariant}>Issuer</Typography></Grid>
                            <Grid {...subValueProps}><StringTypeDropDown style={{ width: "90px" }} key={"issuerDD"} possibles={AvailableIssuers} onChange={this.onChangeIssuer} selected={detail.noteDetail?.issuer} disabled={this.props.disabled} /></Grid>
                        </Grid>
                        <Grid {...subCellProps}>
                            <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Solve For</Typography></Grid>
                            <Grid {...subValueProps}><StringTypeDropDown key={"solveForDD1"} possibles={availableSolveFors} onChange={this.onChangeSolveFor} selected={SolveFor[detail.solveFor]} disabled={this.props.disabled} /></Grid>
                        </Grid>
                    </Grid>
                </Grid></ThemeProvider>);
            case Wrapper.OtcOption:
                return (null);
            case Wrapper.StructuredSwap:
                let ccy = detail.structuredSwapDetail?.fundingCurrency;
                let availableIndices = this.getIndicesForCurrency(ccy)
                let fundingSpread = detail.structuredSwapDetail && detail.structuredSwapDetail.fundingSpreads && detail.structuredSwapDetail.fundingSpreads.length > 0 ?
                    detail.structuredSwapDetail.fundingSpreads[0] : 0;
                return (
                    <Grid item container paddingTop="0.25em">
                        <Grid {...subRowProps} >
                            <Grid {...subCellProps}>
                                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Swap Currency</Typography></Grid>
                                <Grid {...subValueProps}><StringTypeDropDown key={"swapCcyDD"} possibles={availableCcys} onChange={this.onChangeSwapCcy} selected={ccy} disabled={this.props.disabled} /></Grid>
                            </Grid>
                            <Grid {...subCellProps}>
                                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Index</Typography></Grid>
                                <Grid {...subValueProps}><StringTypeDropDown key={"issuerDD"} possibles={availableIndices} onChange={this.onChangeSwapIndex} selected={detail.structuredSwapDetail?.fundingIndex} disabled={this.props.disabled} /></Grid>
                            </Grid>
                            <Grid {...subCellProps}>
                                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Spread</Typography></Grid>
                                <Grid {...subValueProps}><ValidatedNumericInput units="bp" keyName="swapSpread" placeholder="Spread" style={{ width: "120px" }} initialValue={fundingSpread} onChange={(v) => { this.onChangeSwapSpread(v); return true; }} disabled={this.props.disabled} /></Grid>
                            </Grid>
                            <Grid {...subCellProps}>
                                <Grid {...subTitleProps} width={100}><Typography textAlign='right' variant={subTitleVariant}>Solve For</Typography></Grid>
                                <Grid {...subValueProps}><StringTypeDropDown key={"solveForDD1"} possibles={availableSolveFors} onChange={this.onChangeSolveFor} selected={SolveFor[detail.solveFor]} disabled={this.props.disabled} /></Grid>
                            </Grid>
                        </Grid>
                    </Grid>);
            case Wrapper.Warrant:
                return null;
            default:
                return null;
        }

    }

}
export default WrapperDetailBox;