import { FilterOptionsState, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { ListedInstrument } from '../listedInstruments/listedInstrumentModels';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';

type InstrumentMultiSelectionProps = {
    filterType?: any | undefined,
    restrictedInsList?: number[] | undefined,
    onInstrumentSelect: (instruments: ListedInstrument[]) => void,
    listedInstrumentIds: number[] | undefined
    width?: string
    size?: "small" | "medium"
    hideExpired?: boolean
    label?: string
}

type InstrumentMultiSelectionState = {
    listedInstrumentIds: number[]
}

const InsNull = { listedInstrumentId: 0, type: "Start typing...", description: "... first two letters" } as ListedInstrument

export class InstrumentMultiSelection extends React.Component<InstrumentMultiSelectionProps, InstrumentMultiSelectionState>
{
    constructor(props: InstrumentMultiSelectionProps) {
        super(props);
        this.state = {
            listedInstrumentIds: props.listedInstrumentIds ?? [],
        }
    }
    nullIns = { description: "Select Instrument..." } as ListedInstrument;

    getInstruments = () => {
        if (this.props.restrictedInsList)
            return [this.nullIns, ...this.props.restrictedInsList.map(i => listedInstrumentStoreInstance.getInstrumentById(i)).sort((a, b) => a.type > b.type ? 1 : -1)];

        var insPopulation = listedInstrumentStoreInstance.getInstruments();
        if (this.props.hideExpired)
            insPopulation = insPopulation.filter(i => i?.maturity === undefined || i?.maturity === null || moment(i?.maturity).isSameOrAfter(moment()));
        if (this.props.filterType)
            insPopulation = insPopulation.filter(x => x.type === this.props.filterType);

        return [this.nullIns, ...insPopulation.sort((a, b) => a.type > b.type ? 1 : -1)];
    }

    onInsSelect = (ins: (string | ListedInstrument)[]) => {
        var solidIns = ins.map(i=>i as ListedInstrument).filter(i=>i);
        if (solidIns) {
            this.setState({ listedInstrumentIds: solidIns.map(i=>i.listedInstrumentId) })
            this.props.onInstrumentSelect(solidIns);
        }
    }

    filterInsOptions = (options: ListedInstrument[], state: FilterOptionsState<ListedInstrument>): ListedInstrument[] => {
        var query = state?.inputValue?.toLowerCase();
        if (query.length < 2)
            return [InsNull];
        var relevant = options.filter(o => this.insMatchesQuery(o, query)).sort((a,b)=>b.description.localeCompare(a.description)).sort((a, b) => a.type > b.type ? 1 : -1);
        return relevant;
    }

    insMatchesQuery = (ins: ListedInstrument, query: string): boolean => {
        const normalizedTitle = ins?.description?.toLowerCase();
        const code = ins?.ticker?.toLowerCase();
        const allMetaData = ins?.metaData ? ins.metaData.map(am => am.data?.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${ins?.listedInstrumentId}. ${normalizedTitle} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins?.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    getOptionLabel(o: string | ListedInstrument) {
        var ins = (o as ListedInstrument);
        if (ins?.description) {
            var label = ins?.description?.split(' Underlying Index')[0];
            var dupes = ["Mexican Peso", "Japanese Yen", "Dnr 9 05/15/21", "Chinese Renminbi (Offshore)", "Chinese Yuan", "Swiss Franc", "REPSOL SA", "Sigma Lithium Corp", "DTB Eurostoxx 50", "BTC/USD Perp"]
            if (dupes.includes(label))
                label += "-" + ins.primaryExchange;
            if (label === "Chinese Yuan-IDEALFX")
                label += `-(${ins.ticker})`
            return label;
        }
        return "Select Instrument...";
    }

    render() {
        return (<Autocomplete
            classes={{
                inputRoot: "BasketDesignerAutocomplete",
                popupIndicator: "BasketDesignerAutocompleteIcon",
                clearIndicator: "BasketDesignerAutocompleteIcon",
                popper: "AutocompleteGroupLabel",
            }}
            size={this.props.size ?? "small"}
            multiple
            autoComplete
            autoHighlight
            autoSelect
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={this.getInstruments()}
            value={this.state.listedInstrumentIds?.map(i=>listedInstrumentStoreInstance.getInstrumentByIdOrNull(i)) ?? [this.nullIns]}
            id={"listedInstrumentId"+this.props.label}
            groupBy={(ins: ListedInstrument) => ins?.type ?? "Unknown group"}
            getOptionDisabled={(i) => i === InsNull}
            renderGroup={(params) => <div>{Boolean(params.group) ? <Typography variant="h6">{params.group}</Typography> : null}{params.children}</div>}
            getOptionLabel={this.getOptionLabel}
            style={{ width: this.props.width ?? 300 }}
            filterOptions={this.filterInsOptions}
            onChange={(e, v) => this.onInsSelect(v)}
            renderInput={(params) =>
                <TextField {...params}
                    //helperText={touched.listedInstrumentId ? errors.listedInstrumentId : ""}
                    //error={Boolean(errors.listedInstrumentId)}
                    label={this.props.label ?? "Instruments"}
                    variant="outlined" />} />)
    }
}

