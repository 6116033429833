export type LoadingState = {
    loadingItems : Map<string, LoadingInstance>;
    loaded : boolean;
}

export type LoadingStatusUpdate = {
    name : string;
    status : LoadingStatus;
}

export type LoadingInstance = {
    name : string;
    loadAction : () => void;
    status : LoadingStatus;
    comment : string;
}

export enum LoadingStatus {
    NotStarted = 1,
    Loading = 2,
    Failed = 3,
    Completed = 4
}