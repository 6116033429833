import { MenuItem, SelectChangeEvent } from "@mui/material";
import React from "react";
import { WrappedSelect } from "./wrappedSelect";

export interface BuySellSelectorProps {
    onChange?: (isBuy: boolean) => void,
    value: any,
    style?: React.CSSProperties,
    label?: React.ReactNode
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    autoWidth?: boolean
}

export class BuySellSelector extends React.Component<BuySellSelectorProps, {}>
{
    constructor(props: BuySellSelectorProps) {
        super(props)
        this.onChangeLocal = this.onChangeLocal.bind(this);
    }

    onChangeLocal(event: SelectChangeEvent<any>, child: React.ReactNode) {
        var bool = event.target.value as string;
        this.props.onChange(bool==="Buy");
    }
    render() {
        return (<WrappedSelect
            style={this.props.style}
            id={this.props.id}
            name={this.props.name}
            label={this.props.label}
            value={this.props.value}
            onChange={this.onChangeLocal}>
            <MenuItem key={"bs_Buy"} value="Buy">Buy</MenuItem>
            <MenuItem key={"bs_Sell"} value="Sell">Sell</MenuItem>
        </WrappedSelect>)
    }
}

