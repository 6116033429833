import * as React from 'react';
import {
    useGridApiRef,
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

import { renderDateTimeCell } from '../utils/helpers';
import { LogLevel, OrderAlert } from './orderModels';
import { useState, useEffect, useRef } from 'react';
import { LoadActiveOrderAlerts, SubscribeToOrderAlerts, UnSubcribeFromOrderAlerts } from './orderActions';
import ordersStoreInstance from './orderStore';
import { EventSubscription } from 'fbemitter';
import { RefreshOutlined, StreamOutlined } from '@mui/icons-material';
import {
    Grid,
    Button,
    Menu,
    MenuItem,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import userStoreInstance from '../user/userStore';
import { getFormTheme } from '../inputs/formCommon';

export const OrderAlertsDashboard = props => {
    const [alerts, setAlerts] = useState<OrderAlert[]>(undefined);
    const [rows, setRows] = useState([]);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [selectedRow, setSelectedRow] = useState<number>();
    const [showOnlySevere, setShowOnlySevere] = useState<boolean>(false);
    var eventSubscriptionAlerts = useRef({} as EventSubscription);
    const apiRef = useGridApiRef();


    useEffect(() => {
        eventSubscriptionAlerts.current = ordersStoreInstance.addChangeListener(onOrderAlertUpdate);

        SubscribeToOrderAlerts();

        function onOrderAlertUpdate() {
            var alerts = ordersStoreInstance.getOrderAlerts();
            setAlerts(alerts);
            updateRows(alerts);
        }

        function updateRows(o?: OrderAlert[]) {
            if (!Boolean(o))
                o = alerts;

            if (Boolean(o) && showOnlySevere) {
                o = o.filter(x => x.severity === LogLevel.Critical)
            }

            const rowsI = o ? o.sort((a, b) => b.pltfmOrderId - a.pltfmOrderId).map((r) => {
                var rowA = {
                    id: r.orderAlertId,
                    ...r
                };
                return rowA;
            }) : new Array<any>();
            setRows(rowsI);
        }

        if (!Boolean(alerts))
            onOrderAlertUpdate();

        updateRows();

   


        var subscription = eventSubscriptionAlerts;

        return function CleanUp() {
            UnSubcribeFromOrderAlerts();
            subscription?.current?.remove();
        }
    }, [alerts, apiRef, showOnlySevere])



    function customToolbar(props: any) {
        return (
            <ThemeProvider theme={getFormTheme()}>
                <GridToolbarContainer style={{ width: "calc(100vw - 200px)" }}>
                    <Grid container spacing={0} style={{ padding: "5px" }}>
                        <Grid item><Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<RefreshOutlined />} onClick={() => onClickRefresh()}>Refresh</Button></Grid>
                        <Grid item><GridToolbarExport {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" style={{ height: "2.4em" }} /></Grid>
                        <Grid item><GridToolbarDensitySelector {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarColumnsButton {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<StreamOutlined />} onClick={() => setShowOnlySevere(!showOnlySevere)}>{showOnlySevere ? "Show All" : "Show Severe"}</Button></Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    async function onClickRefresh() {
        await LoadActiveOrderAlerts();
    }

    const colBase = { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

  
    const columns: GridColDef[] = [
        { field: "pltfmOrderId", headerName: "Order Id", type: "number", flex: 0.5, ...colBase },
        {
            field: "venue",
            headerName: "Venue",
            type: "string",
            flex: 0.5,
            ...colBase
        },
        {
            field: "message",
            headerName: "Message",
            type: "string",
            flex: 1.5,
            ...colBase
        },
        {
            field: "extraData",
            headerName: "Data",
            flex: 1,
            ...colBase
        },
        {
            field: "alertType",
            headerName: "AlertType",
            flex: 1.0,
            ...colBase
        },
        {
            field: "orderVenueDetails",
            headerName: "Venue Details",
            type: "string",
            flex: 1,
            ...colBase
        },
        {
            field: "created",
            headerName: "Created (UTC)",
            type: "dateTime", 
            flex: 1, 
            renderCell: (params: GridRenderCellParams) => renderDateTimeCell(params),
            ...colBase
        },
        {
            field: "handled",
            headerName: "Handled (UTC)",
            type: "dateTime", 
            flex: 1, 
            renderCell: (params: GridRenderCellParams) => renderDateTimeCell(params),
            ...colBase
        },
        {
            field: "handledBy",
            headerName: "Handled By",
            type: "string",
            flex: 1,
            ...colBase
        },
        {
            field: "handledComment",
            headerName: "Handled Comment",
            type: "string",
            flex: 1,
            ...colBase
        },
        {
            field: "severity",
            headerName: "Severity",
            type: "string",
            flex: 1,
            ...colBase
        },
        ];

      return (
        <ThemeProvider theme={getFormTheme()}>
            <div className="PositionSummaryTab">
                <DataGridPro
                    style={{ border: "none" }}
                    rows={rows}
                    columns={columns}
                    apiRef={apiRef}
                    editMode="row"
                    hideFooter
                    disableSelectionOnClick
                    throttleRowsMs={500}
                    density={userStoreInstance.GetGridDensity()}
                    components={{
                        Toolbar: customToolbar,
                    }}
                    componentsProps={{
                        row: {
                            onContextMenu: handleContextMenu,
                            style: { cursor: 'context-menu' },
                        },
                    }}
                    classes={{
                        panelWrapper: "GridFilterForm",
                        panel: "GridFilterForm",
                        filterForm: "GridFilterForm",
                        columnsPanel: "GridFilterForm",

                    }} />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            },
                        },
                    }}
                    classes={{ list: "GridOptionMenu" }}>
                    <MenuItem disabled>Alert {selectedRow}</MenuItem>
                </Menu>
            </div>
        </ThemeProvider>
    );

}