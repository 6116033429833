import React from 'react';
import { Layout } from 'react-grid-layout';
import AnalogueClock from '../homepage/widgets/analogueClock';
import DigitalClock from '../homepage/widgets/digitalClock';
import HolidayPanel from '../homepage/widgets/holidayPanel';
import LmeSessionIndicator from '../homepage/widgets/lmeSessionIndicator';
import { Inbox } from '../inbox/inbox';
import { InboxDualPane } from '../inbox/inboxDualPane';
import { NewsTicker } from '../newsTicker/newsTicker';
import { LayoutComponent } from './layoutModels';
import WidgetComponent from '../homepage/widgets/widgetComponent';
import PricingPanel from '../homepage/pricingPanel/pricingPanel';
import LmeDatesWidget from '../homepage/widgets/lmeDatesWidget';
import PriceTicker from '../homepage/widgets/priceTicker';
import { TradeBlotterWindow } from '../trade/tradeBlotterWindow';
import UserActivityWidget from '../homepage/widgets/userActivityWidget';
import { ListedInstrumentEditor } from '../listedInstruments/listedInstrumentEditor';
import TradeStats from '../homepage/widgets/tradeStats';
import { NewsList } from '../newsTicker/newsList';
import { EventsList } from '../events/eventsList';
import { AlertsList } from '../alerts/alertsList';
import PnlWidget from '../homepage/widgets/pnlWidget';
import { GasEstimateWidget } from '../homepage/widgets/gasPriceEstimates';
import { PriceChartWidget } from '../homepage/widgets/priceChartWidget';
import { SmileChart } from '../marketData/marketDashboard/smileChart';
import { CandleChart } from '../marketData/marketDashboard/candleChart';
import { SurfaceChart } from '../marketData/marketDashboard/surfaceChart';
import NavWidget from '../homepage/widgets/navWidget';

export type LayoutInfo = {
    width: number,
    height: number,
    permLock: boolean,
    isResizable: boolean,
    static: boolean,
    drawFunction: (component: LayoutComponent) => any,
    getLayoutProps: (component: LayoutComponent, isStatic: boolean) => Layout
}

export const margin = 2;
export const gridRowHeight = 50;
export const numCols = 18;
export const colWidthPx = 125;
export const gridWidth = numCols * (colWidthPx + margin);
export const componentWidthInGrids = 2;


export const componentMap = new Map<string, LayoutInfo>([
    ["NewsTicker", getLayout("NewsTicker", 4, 50)],
    ["Inbox", getLayout("Inbox", 2, 450, "Inbox")],
    ["InboxDualPane", getLayout("InboxDualPane", 3, 450, "Inbox")],
    ["AnalogueClock", getLayout("AnalogueClock", 1, 250)],
    ["DigitalClock", getLayout("DigitalClock", 1, 100)],
    ["HolidayPanel", getLayout("HolidayPanel", 1, 150)],
    ["LmeSessionIndicator", getLayout("LmeSessionIndicator", 1, 100, "LME Session")],
    ["LmeDatesWidget", getLayout("LmeDatesWidget", 1, 200, "LME Dates")],
    ["LimitEditor", getLayout("LimitEditor", 4, 50)],
    ["CreditSummaryReport", getLayout("CreditSummaryReport", 4, 50)],
    ["TradeBlotterWindow", getLayout("TradeBlotterWindow", 4, 400, "Trade Blotter")],
    ["ConfigTree", getLayout("ConfigTree", 4, 50)],
    ["RfqDashboard", getLayout("RfqDashboard", 4, 50)],
    ["TradeBlotter", getLayout("TradeBlotter", 4, 50)],
    ["CreditReport", getLayout("CreditReport", 4, 50)],
    ["AdminConsole", getLayout("AdminConsole", 4, 50)],
    ["LimitsTree", getLayout("LimitsTree", 4, 50)],
    ["PricingPanel", getLayout("PricingPanel", 1, 250)],
    ["PriceTicker", getLayout("PriceTicker", 1, 150, "Ticker")],
    ["UserActivity", getLayout("UserActivity", 2, 400, "Activity Stream")],
    ["InstrumentEditor", getLayout("InstrumentEditor", 2, 600, "Instrument Editor")],
    ["TradeStats", getLayout("TradeStats", 1, 150, "Trade Stats")],
    ["NewsList", getLayout("NewsList", 2, 400, "News List")],
    ["EventsList", getLayout("EventsList", 1, 442, "Upcoming Events")],
    ["AlertsList", getLayout("AlertsList", 1, 300, "Active Alerts")],
    ["PnLWidget", getLayout("PnLWidget", 1, 150, "Live PnL")],
    ["NavWidget", getLayout("NavWidget", 1, 150, "Live NAV")],
    ["GasPriceEstimates", getLayout("GasPriceEstimates", 1, 200, "Gas Prices")],
    ["PriceChart", getLayout("PriceChart", 1, 200, "Price Chart")],
    ["SmileChart", getLayout("SmileChart", 2, 300, "Smile Chart")],
    ["SurfaceChart", getLayout("SurfaceChart", 2, 300, "Surface Chart")],
    ["CandleChart", getLayout("CandleChart", 2, 300, "Candle Chart")],
]);


function getChildComponet(type: string, component: LayoutComponent) {
    switch (type) {
        case "DigitalClock":
            return (<DigitalClock key={component.key} {...component.props} format={"HH:mm:ss"} />);
        case "AnalogueClock":
            return (<AnalogueClock key={component.key} {...component.props} />);
        case "Inbox":
            return (<Inbox key={component.key} {...component.props} />);
        case "InboxDualPane":
            return (<InboxDualPane key={component.key} {...component.props} />);
        case "NewsTicker":
            return (<NewsTicker key={component.key} {...component.props} />);
        case "HolidayPanel":
            return (<HolidayPanel key={component.key} {...component.props} />);
        case "LmeSessionIndicator":
            return (<LmeSessionIndicator key={component.key} {...component.props} />);
        case "PricingPanel":
            return (<PricingPanel key={component.key} {...component.props} widgetType={type} />);
        case "LmeDatesWidget":
            return (<LmeDatesWidget key={component.key} {...component.props} />);
        case "PriceTicker":
            return (<PriceTicker key={component.key} {...component.props} />);
        case "TradeBlotterWindow":
            return (<TradeBlotterWindow id={component.key} key={component.key} {...component.props} />);
        case "UserActivity":
            return (<UserActivityWidget id={component.key} key={component.key} {...component.props} />);
        case "InstrumentEditor":
            return (<ListedInstrumentEditor id={component.key} key={component.key} {...component.props} />);
        case "TradeStats":
            return (<TradeStats id={component.key} key={component.key} {...component.props} />);
        case "NewsList":
            return (<NewsList id={component.key} key={component.key} {...component.props} />);
        case "EventsList":
            return (<EventsList id={component.key} key={component.key} {...component.props} />);
        case "AlertsList":
            return (<AlertsList id={component.key} key={component.key} {...component.props} />);
        case "PnLWidget":
            return (<PnlWidget id={component.key} key={component.key} {...component.props} />);
        case "NavWidget":
            return (<NavWidget id={component.key} key={component.key} {...component.props} />);
        case "GasPriceEstimates":
            return (<GasEstimateWidget id={component.key} key={component.key} {...component.props} />);
        case "PriceChart":
            return (<PriceChartWidget id={component.key} key={component.key} {...component.props} />);
        case "SmileChart":
            return (<SmileChart id={component.key} key={component.key} {...component.props} componentKey={component.key} tabId={component.tabId} />);
        case "SurfaceChart":
            return (<SurfaceChart id={component.key} key={component.key} {...component.props} componentKey={component.key} tabId={component.tabId} />);
        case "CandleChart":
            return (<CandleChart id={component.key} key={component.key} {...component.props} componentKey={component.key} tabId={component.tabId} />);
        default:
            return <div></div>;
    }
}

function createComponent(type: string, component: LayoutComponent, width: number, height: number, title?: any, titleIsActive?: boolean) {
    return (<div key={component.key} style={{ width: `${colWidthPx * width}px`, height: `${gridRowHeight * height}px` }} >
        <WidgetComponent key={`widget-${component.key}`} {...component.props} title={component.title ?? title} titleIsActive={titleIsActive}  >
            {getChildComponet(type, component)}
        </WidgetComponent>
    </div>);
}


function getLayout(componentType: string, widthFactor: number, heightInPx: number, title?: any, titleIsActive?: boolean, isStatic: boolean = false): LayoutInfo {
    let width = componentWidthInGrids * widthFactor;
    let height = Math.max(1, Math.ceil(heightInPx / gridRowHeight));
    return {
        width: width,
        height: height,
        permLock: true,
        isResizable: false,
        static: isStatic,
        drawFunction: c => c.props?.gridLayout ? createComponent(componentType, c, c.props.gridLayout.w, c.props.gridLayout.h, title, titleIsActive) : createComponent(componentType, c, width, height, title, titleIsActive),
        getLayoutProps: (c: LayoutComponent) => { return c.props?.gridLayout ? c.props.gridLayout : { x: 2, y: 4, h: height, w: width, i: c.key } as Layout }
    }
}