import {
  Button,
  MenuItem,
  Grid,
  TextField,
  ThemeProvider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { FieldArray, FieldArrayRenderProps, Form } from "formik";
import { AddOutlined, CancelOutlined, CheckCircleOutline, ExpandMoreOutlined, RemoveOutlined } from "@mui/icons-material";
import { getFormTheme, GridBreak } from "../inputs/formCommon";
import Autocomplete from '@mui/material/Autocomplete';
import userStoreInstance from "../user/userStore";
import { UserMetaData } from "../user/userModel";
import { WrappedSelect } from "../inputs/wrappedSelect";

export const UserForm = props => {
  const {
    values: {
      userId,
      firmId,
      name,
      displayName,
      // defaultSquad,
      // roles,
      // environment,
      // salesSquadId,
      squads,
      metaData },
    errors,
    touched,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    className,
    handleSubmit,
    metaCollapsable
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const onMetaCategoryChange = (val: string | null, ix: number) => {
    if (val) {
      setFieldValue(`metaData[${ix}].category`, val)
    }
  }
  const onMetaTypeChange = (val: string | null, ix: number) => {
    if (val) {
      setFieldValue(`metaData[${ix}].type`, val)
    }
  }
  const onMetaDataChange = (val: string | null, ix: number) => {
    if (val) {
      setFieldValue(`metaData[${ix}].data`, val)
    }
  }

  const onSquadsChange = (val: number[]) => {
    if (val) {
      setFieldValue(`squads`, val)
    }
  }

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <ThemeProvider theme={getFormTheme()}>
        <Grid container spacing={2} direction="column" justifyContent="space-between" alignContent="center" classes={{ root: "UserEditorFormGrid" }} height="calc(100vh - 200px)" >
          <Grid item container direction="row" justifyContent="space-around" alignContent="center" >
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                disabled
                classes={{ root: "UserEditorFormField" }}
                style={{ width: "75px" }}
                id="userId"
                name="userId"
                helperText={touched.userId ? errors.userId : ""}
                error={touched.userId && Boolean(errors.userId)}
                label="User Id"
                value={userId ?? ""}
                onChange={change.bind(null, "userId")}
                InputProps={{ classes: { input: "UserEditorFormFieldInner" } }} /></Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                disabled
                classes={{ root: "UserEditorFormField" }}
                style={{ width: "75px" }}
                id="firmId"
                name="firmId"
                helperText={touched.firmId ? errors.firmId : ""}
                error={touched.firmId && Boolean(errors.firmId)}
                label="Firm Id"
                value={firmId ?? ""}
                onChange={change.bind(null, "firmId")}
                InputProps={{ classes: { input: "UserEditorFormFieldInner" } }} /></Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                classes={{ root: "UserEditorFormField" }}
                style={{ width: "200px" }}
                id="name"
                name="name"
                helperText={touched.name ? errors.name : ""}
                error={touched.name && Boolean(errors.name)}
                label="Name"
                value={name ?? ""}
                onChange={change.bind(null, "name")}
                InputProps={{ classes: { input: "UserEditorFormFieldInner" } }} /></Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                classes={{ root: "UserEditorFormField" }}
                style={{ width: "200px" }}
                id="displayName"
                name="displayName"
                helperText={touched.name ? errors.name : ""}
                error={touched.displayName && Boolean(errors.displayName)}
                label="Display Name"
                value={displayName ?? ""}
                onChange={change.bind(null, "displayName")}
                InputProps={{ classes: { input: "UserEditorFormFieldInner" } }} /></Grid>
            <Grid item>
              <WrappedSelect
                multiple
                size="small"
                style={{ width: "250px", marginTop:"5px" }}
                id="squads"
                name="squads"
                error={touched.squads && Boolean(errors.squads)}
                label="Squads"
                value={squads ?? [] as number[]}
                onChange={(e, v) => onSquadsChange(e.target.value as number[])}>
                {userStoreInstance.GetSquads().map(a =>
                  <MenuItem key={"aki" + a.squadId} value={a.squadId}>{a.name}</MenuItem>)}
              </WrappedSelect>
            </Grid>
          </Grid>
          <Grid item><GridBreak /></Grid>
          <Grid item container justifyContent="space-around" alignContent="center" ><Accordion className="UserEditorFormMetaDataContainer" defaultExpanded={!metaCollapsable} disabled={!metaCollapsable} >
            <AccordionSummary
              expandIcon={metaCollapsable ? <ExpandMoreOutlined className="UserEditorFormFieldInner" /> : null}
              aria-controls="panel1a-content"
              id="panel1a-header"                >
              <Typography variant="h6">Meta Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FieldArray name="metaData" render={(arrayHelpers: FieldArrayRenderProps) => (
                <div className="InsMetaDataBox">
                  {metaData && metaData.length > 0 ? (
                    metaData.map((m: UserMetaData, ix) => (
                      <div key={ix} className="UserEditorFormMetaDataRow">
                        <Grid container spacing={1}>
                          <Grid item><Autocomplete
                            freeSolo
                            style={{ width: "300px" }}
                            options={Array.from(userStoreInstance.getMetaDataCategories())}
                            //classes={{ root: "UserEditorFormField" }}
                            id={`metaData[${ix}].category`}
                            value={m.category ?? ""}
                            autoSelect
                            onChange={(e, v) => onMetaCategoryChange(v, ix)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "UserEditorFormFieldInner" } }} />)}
                          />
                          </Grid>
                          <Grid item><Autocomplete
                            freeSolo
                            style={{ width: "300px" }}
                            options={Array.from(userStoreInstance.getMetaDataTypesForCategory(m?.category))}
                            //classes={{ root: "UserEditorFormField" }}
                            id={`metaData[${ix}].type`}
                            value={m.type ?? ""}
                            autoSelect
                            onChange={(e, v) => onMetaTypeChange(v, ix)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Label"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "UserEditorFormFieldInner" } }} />)}
                          />
                          </Grid>
                          <Grid item><Autocomplete
                            freeSolo
                            style={{ width: "250px" }}
                            options={m === undefined || m.type === undefined || m.type === null ? new Array<string>() : Array.from(userStoreInstance.getMetaDataByType(m?.category, m?.type))}
                            //classes={{ root: "UserEditorFormField" }}
                            id={`metaData[${ix}].data`}
                            value={m.data ?? ""}
                            autoSelect
                            onChange={(e, v) => onMetaDataChange(v, ix)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Data"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search', classes: { root: "UserEditorFormFieldInner" } }} />)}
                          />
                          </Grid>
                          <IconButton
                            onClick={() => arrayHelpers.remove(ix)}
                            className="UserEditorFormFieldInner"
                            size="large">
                            <RemoveOutlined color="inherit" />
                          </IconButton>
                        </Grid>
                      </div>
                    ))
                  ) : null}
                  <div key="addMetaDataToIns" className="UserEditorFormAddMeta">
                    <Button variant="outlined" onClick={() => arrayHelpers.push({} as UserMetaData)} className="PltfmButtonLite">
                      <ListItemIcon className="UserEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                      <ListItemText>Add Meta Data</ListItemText>
                    </Button>
                  </div>
                </div>
              )} />
            </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                className="PltfmButtonLite"
                type="submit"
                variant="outlined"
                disabled={!isValid}
                endIcon={<CheckCircleOutline />}>Submit</Button>
            </Grid>
            <Grid item>
              <Button
                className="PltfmButtonLite"
                type="reset"
                variant="outlined"
                endIcon={<CancelOutlined />}>Revert</Button>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Form >
  );
};