import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { MissingMetaDataQuery } from '../listedInstruments/listedInstrumentModels';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';
import { CashTransaction } from '../nav/navModels';
import { GetConfigsForType } from '../user/userActions';
import { get, put } from '../utils/httpUtils';
import { LoanDepo, MetaDataQueryResponse, Trade, TradeHistoryRecord, TradeMetaData, TradeMetaDataSuggestion, TradeQuery, TradeQueryResponse } from './tradeModels';

export class TradeUpdatedEvent extends TypedEvent<Trade> { }
export class TradeBatchEvent extends TypedEvent<Trade[]> { }
export class TradeQueryBatchEvent extends TypedEvent<TradeQueryResponse> { }
export class NewTradeEvent extends TypedEvent<number> { }
export class NewTradeHistoryResultEvent extends TypedEvent<TradeHistoryRecord[]> { }
export class StrategiesAndBooksLoadEvent extends TypedEvent<MetaDataQueryResponse> { }
export class LoanDepoLoadEvent extends TypedEvent<LoanDepo[]> { }
export class CashTransactionLoadEvent extends TypedEvent<CashTransaction[]> { }

export function TradeUpdated(trade: Trade) {
    AppDispatcher.dispatch(new TradeUpdatedEvent(trade));
}

export function NewTrade(rfqId: number) {
    AppDispatcher.dispatch(new NewTradeEvent(rfqId));
}

export async function FetchTrades(signal?: AbortSignal) {
    const tradesResponse = await get<Trade[]>("Trades", signal);
    if (tradesResponse.payload) {
        AppDispatcher.dispatch(new TradeBatchEvent(tradesResponse.payload));
    }
}

export async function FetchTradesWithQuery(query: TradeQuery, signal?: AbortSignal) {
    const tradesResponse = await put<TradeQueryResponse>("Trades/TradeQuery", query, signal);
    if (tradesResponse.payload) {
        var response = tradesResponse.payload;
        AppDispatcher.dispatch(new TradeQueryBatchEvent(response));
    }
}

export async function BookTrade(trade: Trade) {
    var response = await put<number>("Trades/BookTrade", trade);
    return response?.payload;
}
export async function BookCashTransaction(trade: CashTransaction) : Promise<CashTransaction> {
    var response = await put<CashTransaction>("Trades/BookCashTransaction", trade);
    return response.payload;
}
export async function BookLoanDepo(loanDepo: LoanDepo) {
    var tradeId = await put<number>("Trades/AddLoanDepo", loanDepo);
    return tradeId?.payload;
}

export async function GetCashTransactions() {
    var response = await get<CashTransaction[]>("Trades/GetCashTransactions");
    if (response.payload) {
        AppDispatcher.dispatch(new CashTransactionLoadEvent(response.payload));
    }
}
export async function GetLoanDepos() {
    var response = await get<LoanDepo[]>("Trades/GetLoanDepos");
    if (response.payload) {
        AppDispatcher.dispatch(new LoanDepoLoadEvent(response.payload));
    }
}


export async function FlexSync() {
    await get("Trades/FlexSync");
}

export async function FetchStrategiesAndBooks() {
    const statsAndBooks = await get<MetaDataQueryResponse>("Trades/StrategiesAndBooks");
    if (statsAndBooks.payload) {
        AppDispatcher.dispatch(new StrategiesAndBooksLoadEvent(statsAndBooks.payload));
    }
    //load position views
    await GetConfigsForType("PositionView");
    UpdateLoadingStatus("StrategiesBooks", LoadingStatus.Completed);
}

export async function GetTradesNeedingMetaData(query: MissingMetaDataQuery) {
    const tradesResponse = await put<TradeQueryResponse>("Trades/TradesNeedingMetaData", query);
    if (tradesResponse.payload) {
        AppDispatcher.dispatch(new TradeQueryBatchEvent(tradesResponse.payload));
    }
}

export async function UpdateTradeMetaData(data: TradeMetaData[]) {
    var response = await put<Trade[]>("Trades/UpdateTradesMetaData", data);
    if (response.payload != null) {
        AppDispatcher.dispatch(new TradeBatchEvent(response.payload));
    }
}

export async function GetMetaSuggestions(tradeIds: number[]) {
    return (await put<TradeMetaDataSuggestion[]>("Trades/TradeMetaDataSuggestions", tradeIds)).payload;
}

export async function GetMetaSuggestionsForIns(insId: number) {
    return (await get<TradeMetaDataSuggestion>(`Trades/TradeMetaDataSuggestionForInstrument/${insId}`)).payload;
}

export async function GetTradeHistory() {
    var response = await get<TradeHistoryRecord[]>("Positions/GetTradeHistory");
    if (response.payload != null) {
        AppDispatcher.dispatch(new NewTradeHistoryResultEvent(response.payload));
    }
}