import FBEmitter from 'fbemitter';
import React, { RefObject } from 'react';
import {
    Box,
    Button,
    Card,
    Collapse,
    createTheme,
    Grid,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tabs,
    Paper,
    Tab,
    LinearProgress,
} from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import moment, { Moment } from 'moment';
import { getPositionTableTheme } from '../positions/positionSummaryTable';
import _ from 'lodash';
import userStoreInstance from '../user/userStore';
import { CircularProgress } from '@mui/material';
import { ArrowDownwardOutlined, ArrowUpwardOutlined, ZoomInOutlined } from '@mui/icons-material';
import { getFormTheme } from '../inputs/formCommon';
import { EarliestDate } from '../globalConstants';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import { NavAccrualPosition, NavCashPosition, NavCfdPosition, NavMarginedPosition, NavMoneyMarketPosition, NavSecurityPosition, NavSummary } from './navModels';
import navStoreInstance from './navStore';
import { ComputeFeederMasterNavs, FlushNavCache } from './navActions';
import marketDataStoreInstance from '../marketData/marketDataStore';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { TabContext, TabPanel } from '@mui/lab';
import Plot from 'react-plotly.js';
import { Data, Datum, Layout } from 'plotly.js';
import { DataGridPro, GridCellParams, GridColDef, GridOverlay, GridRowData, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { SaturdayDoW, SundayDoW } from '../App';

interface NavCalcTableState {
    summary: NavSummary,
    mainRef: RefObject<HTMLDivElement>,
    asOf: moment.Moment | null,
    awaitingRefresh: boolean,
    rowExpanded: number | undefined,
    selectedSet: string | undefined,
    selectedEntity: number | undefined,
    showAreYouSure: boolean,
    flushing: boolean,
    selectedTabId: number,
    selectedChartField: string,
    navs: { date: string, nav: NavSummary }[]
}

export interface NavCalcTableProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export const getNavTableTheme = () => {
    return createTheme({
        palette: {
            primary: { main: userStoreInstance.GetTheme().color, contrastText: userStoreInstance.GetTheme().color },
            secondary: { main: userStoreInstance.GetTheme().background_color, contrastText: userStoreInstance.GetTheme().color },
            text: { secondary: userStoreInstance.GetTheme().color },
        },
        typography: {
            allVariants: {
                fontFamily: userStoreInstance.GetTheme().font_family
            }
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {

                    },
                    head: {
                        fontWeight: "bold",
                        //borderBottom: "1px solid",
                        //border: "none",
                        //borderColor: userStoreInstance.GetTheme().border_color,
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color + " !important",
                    },
                    body: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                        borderColor: userStoreInstance.GetTheme().contrastBorderColorLight,
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        color: userStoreInstance.GetTheme().color,
                        backgroundColor: userStoreInstance.GetTheme().background_color,
                    }
                }
            },
        }
    });
}

export class NavCalcTable extends React.Component<NavCalcTableProps, NavCalcTableState>{
    eventSubscriptionNavs: FBEmitter.EventSubscription | undefined;
    constructor(props: NavCalcTableProps) {
        super(props)
        var asOf = props.getState("nc_asOf");
        var selectedSet = props.getState("selectedSet");
        var selectedEntity = props.getState("selectedEntity") ? Number(props.getState("selectedEntity")) : undefined;
        this.state = {
            summary: {} as NavSummary,
            mainRef: React.createRef(),
            asOf: asOf && asOf !== "now" ? moment(asOf) : null,
            awaitingRefresh: false,
            rowExpanded: undefined,
            selectedSet: selectedSet,
            selectedEntity: selectedEntity,
            showAreYouSure: false,
            flushing: false,
            selectedTabId: 0,
            selectedChartField: undefined,
            navs: new Array<{ date: string, nav: NavSummary }>()
        };;
        this.updateNavs = this.updateNavs.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.showDetail = this.showDetail.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
        this.renderHistoryChart = this.renderHistoryChart.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionNavs = navStoreInstance.addChangeListener(this.updateNavs);
        if (!navStoreInstance.navsAreLoaded()) {
            this.setState({ awaitingRefresh: true });
            await this.calcNavs();
        }
        this.updateNavs();
    }

    async componentWillUnmount() {
        if (this.eventSubscriptionNavs) {
            this.eventSubscriptionNavs.remove();
            this.eventSubscriptionNavs = undefined;
        }
    }

    onChangeDate(d: moment.Moment) {
        var dStr = d?.format("yyyy-MM-DD") ?? "now";
        this.props.onChangeState("nc_asOf", dStr);
        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var nav = navStoreInstance.getCalculatedNav(d?.toDate(), fund?.displayName);
        if (nav)
            this.setState({ asOf: d, summary: nav, awaitingRefresh: false });
        else
            this.setState({ asOf: d, summary: {} as NavSummary });
    }

    async calcNavs() {
        this.setState({ summary: null, awaitingRefresh: true });
        const { selectedSet } = this.state;
        await ComputeFeederMasterNavs(selectedSet);
    }

    updateNavs(asOf?: Moment) {
        if (!asOf)
            asOf = this.state.asOf;

        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        if (asOf) {
            var nav = navStoreInstance.getCalculatedNav(asOf.toDate(), fund?.displayName);
            this.props.onChangeState("awaitingRefresh", "false");
            this.setState({ summary: nav, awaitingRefresh: !Boolean(nav) });
        }

        var navs = navStoreInstance.getCalculatedNavs(fund?.displayName);
        if (navs)
            this.setState({ navs, awaitingRefresh: false });
    }

    renderCashRow(rowKey: string, positions: NavCashPosition[], fxRates: { [ccy: string]: number }, isExpanded: boolean, index: number) {
        var posVal = this.getCashRowValue(positions, fxRates);
        if (posVal === 0)
            return null;
        var posValCcy = this.getCashRowValue(positions, null);
        var insto = rowKey.split('~')[0];
        var ccy = rowKey.split('~')[1];
        return (<React.Fragment>
            <TableRow key={"row_" + rowKey} hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ rowExpanded: isExpanded ? undefined : index })}>
                        {isExpanded ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">Cash</TableCell>
                <TableCell align="center">{insto}</TableCell>
                <TableCell align="center">{ccy}</TableCell>
                <TableCell align="center">{posValCcy.toLocaleString()}</TableCell>
                <TableCell align="center">{posVal.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow key={"row_sub" + rowKey}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Account</TableCell>
                                        <TableCell align="center">Ccy</TableCell>
                                        <TableCell align="center">Value (Base)</TableCell>
                                        <TableCell align="center">Value (Ccy)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.filter(p => this.getCashRowValue([p], fxRates) !== 0).sort((a, b) => a?.ccy < b?.ccy ? 1.0 : -1.0)
                                        .sort((a, b) => a?.institution > b?.institution ? 1.0 : -1.0).map((p, ix) => {
                                            return (<TableRow key={"row_sub_sub_" + ix}>
                                                <TableCell align="center">{p?.account}</TableCell>
                                                <TableCell align="center">{p?.ccy}</TableCell>
                                                <TableCell align="center">{this.getCashRowValue([p], fxRates)?.toLocaleString()}</TableCell>
                                                <TableCell align="center">{this.getCashRowValue([p], null)?.toLocaleString()}</TableCell>
                                            </TableRow>);
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }

    getCashRowValue(positions: NavCashPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.amount * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    getSecurityRowValue(positions: NavSecurityPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.quantity * p.marketPrice * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    renderSecurityRow(rowKey: string, positions: NavSecurityPosition[], fxRates: { [ccy: string]: number }, isExpanded: boolean, index: number) {
        var posVal = this.getSecurityRowValue(positions, fxRates);
        if (posVal === 0)
            return null;
        var posValCcy = this.getSecurityRowValue(positions, null);
        var secType = rowKey.split('~')[0];
        var ccy = rowKey.split('~')[1];
        return (<React.Fragment>
            <TableRow key={"row_" + rowKey} hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ rowExpanded: isExpanded ? undefined : index })}>
                        {isExpanded ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">Security</TableCell>
                <TableCell align="center">{secType}</TableCell>
                <TableCell align="center">{ccy}</TableCell>
                <TableCell align="center">{posValCcy.toLocaleString()}</TableCell>
                <TableCell align="center">{posVal.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow key={"row_sub" + rowKey}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Account</TableCell>
                                        <TableCell align="center">Instrument</TableCell>
                                        <TableCell align="center">Ccy</TableCell>
                                        <TableCell align="center">Price</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        <TableCell align="center">Value (Base)</TableCell>
                                        <TableCell align="center">Value (Ccy)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.filter(p => p.quantity !== 0).sort((a, b) => a?.ccy < b?.ccy ? 1.0 : -1.0).sort((a, b) => listedInstrumentStoreInstance.getInstrumentById(a?.listedInstrumentId)?.description < listedInstrumentStoreInstance.getInstrumentById(b?.listedInstrumentId)?.description ? 1.0 : -1.0)
                                        .sort((a, b) => a?.institution > b?.institution ? 1.0 : -1.0).map((p, ix) => {
                                            return (<TableRow key={"row_sub_sub_" + ix}>
                                                <TableCell align="center">{p?.account}</TableCell>
                                                <TableCell align="center">{listedInstrumentStoreInstance.getInstrumentById(p?.listedInstrumentId)?.description}</TableCell>
                                                <TableCell align="center">{p?.ccy}</TableCell>
                                                <TableCell align="center">{p?.marketPrice?.toFixed(4)}</TableCell>
                                                <TableCell align="center">{p?.quantity}</TableCell>
                                                <TableCell align="center">{this.getSecurityRowValue([p], fxRates)?.toLocaleString()}</TableCell>
                                                <TableCell align="center">{this.getSecurityRowValue([p], null)?.toLocaleString()}</TableCell>
                                            </TableRow>);
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }

    getCfdRowValue(positions: NavCfdPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => p.quantity * (p.marketPrice - p.strikePrice) * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    renderLoanDepoRow(rowKey: string, positions: NavMoneyMarketPosition[], fxRates: { [ccy: string]: number }, isExpanded: boolean, index: number) {
        var posVal = this.getLoanDepoRowValue(positions, fxRates);
        if (posVal === 0)
            return null;
        var posValCcy = this.getLoanDepoRowValue(positions, null);
        var insto = rowKey.split('~')[0];
        var ccy = rowKey.split('~')[1];
        return (<React.Fragment>
            <TableRow key={"row_" + rowKey} hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ rowExpanded: isExpanded ? undefined : index })}>
                        {isExpanded ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">Money Market</TableCell>
                <TableCell align="center">{insto}</TableCell>
                <TableCell align="center">{ccy}</TableCell>
                <TableCell align="center">{posValCcy.toLocaleString()}</TableCell>
                <TableCell align="center">{posVal.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow key={"row_sub" + rowKey}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Account</TableCell>
                                        <TableCell align="center">Ccy</TableCell>
                                        <TableCell align="center">Value (Base)</TableCell>
                                        <TableCell align="center">Value (Ccy)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.filter(p => this.getLoanDepoRowValue([p], fxRates) !== 0).sort((a, b) => a?.ccy < b?.ccy ? 1.0 : -1.0)
                                        .sort((a, b) => a?.counterparty > b?.counterparty ? 1.0 : -1.0).map((p, ix) => {
                                            return (<TableRow key={"row_sub_sub_" + ix}>
                                                <TableCell align="center">{p?.account}</TableCell>
                                                <TableCell align="center">{p?.ccy}</TableCell>
                                                <TableCell align="center">{this.getLoanDepoRowValue([p], fxRates)?.toLocaleString()}</TableCell>
                                                <TableCell align="center">{this.getLoanDepoRowValue([p], null)?.toLocaleString()}</TableCell>
                                            </TableRow>);
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }


    getLoanDepoRowValue(positions: NavMoneyMarketPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => (p.nominal + p.accruedInterest) * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    renderAccrualRow(rowKey: string, positions: NavAccrualPosition[], fxRates: { [ccy: string]: number }, isExpanded: boolean, index: number) {
        var posVal = this.getAccrualRowValue(positions, fxRates);
        if (posVal === 0)
            return null;
        var posValCcy = this.getAccrualRowValue(positions, null);
        var category = rowKey.split('~')[0];
        var ccy = rowKey.split('~')[1];
        return (<React.Fragment>
            <TableRow key={"row_" + rowKey} hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ rowExpanded: isExpanded ? undefined : index })}>
                        {isExpanded ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">Accrual</TableCell>
                <TableCell align="center">{category}</TableCell>
                <TableCell align="center">{ccy}</TableCell>
                <TableCell align="center">{posValCcy.toLocaleString()}</TableCell>
                <TableCell align="center">{posVal.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow key={"row_sub" + rowKey}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Account</TableCell>
                                        <TableCell align="center">Ccy</TableCell>
                                        <TableCell align="center">Value (Base)</TableCell>
                                        <TableCell align="center">Value (Ccy)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.filter(p => this.getAccrualRowValue([p], fxRates) !== 0).sort((a, b) => a?.ccy < b?.ccy ? 1.0 : -1.0)
                                        .sort((a, b) => a?.name > b?.name ? 1.0 : -1.0).map((p, ix) => {
                                            return (<TableRow key={"row_sub_sub_" + ix}>
                                                <TableCell align="center">{p?.name}</TableCell>
                                                <TableCell align="center">{p?.ccy}</TableCell>
                                                <TableCell align="center">{this.getAccrualRowValue([p], fxRates)?.toLocaleString()}</TableCell>
                                                <TableCell align="center">{this.getAccrualRowValue([p], null)?.toLocaleString()}</TableCell>
                                            </TableRow>);
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }


    getAccrualRowValue(positions: NavAccrualPosition[], fxRates: { [ccy: string]: number }) {
        return Math.floor(_.sum(positions.map(p => (p.amountToDate) * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
    }

    renderCfdRow(rowKey: string, positions: NavCfdPosition[], fxRates: { [ccy: string]: number }, isExpanded: boolean, index: number) {
        var posVal = this.getCfdRowValue(positions, fxRates);
        if (posVal === 0)
            return null;
        var posValCcy = this.getCfdRowValue(positions, null);
        var secType = rowKey.split('~')[0];
        var ccy = rowKey.split('~')[1];
        return (<React.Fragment>
            <TableRow key={"row_" + rowKey} hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ rowExpanded: isExpanded ? undefined : index })}>
                        {isExpanded ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">CFD</TableCell>
                <TableCell align="center">{secType}</TableCell>
                <TableCell align="center">{ccy}</TableCell>
                <TableCell align="center">{posValCcy.toLocaleString()}</TableCell>
                <TableCell align="center">{posVal.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow key={"row_sub" + rowKey}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Account</TableCell>
                                        <TableCell align="center">Instrument</TableCell>
                                        <TableCell align="center">Ccy</TableCell>
                                        <TableCell align="center">Price</TableCell>
                                        <TableCell align="center">Strike</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        <TableCell align="center">Value (Base)</TableCell>
                                        <TableCell align="center">Value (Ccy)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.filter(p => p.quantity !== 0).sort((a, b) => a?.ccy < b?.ccy ? 1.0 : -1.0).sort((a, b) => listedInstrumentStoreInstance.getInstrumentById(a?.listedInstrumentId)?.description < listedInstrumentStoreInstance.getInstrumentById(b?.listedInstrumentId)?.description ? 1.0 : -1.0)
                                        .sort((a, b) => a?.institution > b?.institution ? 1.0 : -1.0).map((p, ix) => {
                                            return (<TableRow key={"row_sub_sub_" + ix}>
                                                <TableCell align="center">{p?.account}</TableCell>
                                                <TableCell align="center">{listedInstrumentStoreInstance.getInstrumentById(p?.listedInstrumentId)?.description}</TableCell>
                                                <TableCell align="center">{p?.ccy}</TableCell>
                                                <TableCell align="center">{p?.marketPrice?.toFixed(4)}</TableCell>
                                                <TableCell align="center">{p?.strikePrice?.toFixed(4)}</TableCell>
                                                <TableCell align="center">{p?.quantity}</TableCell>
                                                <TableCell align="center">{this.getCfdRowValue([p], fxRates)?.toLocaleString()}</TableCell>
                                                <TableCell align="center">{this.getCfdRowValue([p], null)?.toLocaleString()}</TableCell>
                                            </TableRow>);
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }

    getMarginedRowValue(positions: NavMarginedPosition[], fxRates: { [ccy: string]: number }) {
        var val = Math.floor(_.sum(positions.map(p => p.quantity * (p.marketPrice - p.strikePrice) * (p.ccy !== "USD" && fxRates && fxRates[p.ccy] ? fxRates[p.ccy] : 1.0))));
        return val;
    }

    renderMarginedRow(rowKey: string, positions: NavMarginedPosition[], fxRates: { [ccy: string]: number }, isExpanded: boolean, index: number) {
        if (positions.every(p => p.contractQuantity === 0))
            return null;
        var posVal = this.getMarginedRowValue(positions, fxRates);
        var posValCcy = this.getMarginedRowValue(positions, null);
        var secType = rowKey.split('~')[0];
        var ccy = rowKey.split('~')[1];
        return (<React.Fragment>
            <TableRow key={"row_" + rowKey} hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ rowExpanded: isExpanded ? undefined : index })}>
                        {isExpanded ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">Margined</TableCell>
                <TableCell align="center">{secType}</TableCell>
                <TableCell align="center">{ccy}</TableCell>
                <TableCell align="center">{posValCcy.toLocaleString()}</TableCell>
                <TableCell align="center">{posVal.toLocaleString()}</TableCell>
            </TableRow>
            <TableRow key={"row_sub" + rowKey}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Account</TableCell>
                                        <TableCell align="center">Instrument</TableCell>
                                        <TableCell align="center">Ccy</TableCell>
                                        <TableCell align="center">Price</TableCell>
                                        <TableCell align="center">Quantity</TableCell>
                                        <TableCell align="center">Value (Base)</TableCell>
                                        <TableCell align="center">Value (Ccy)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {positions.filter(p => p.quantity !== 0).sort((a, b) => a?.ccy < b?.ccy ? 1.0 : -1.0)
                                        .sort((a, b) => a?.institution > b?.institution ? 1.0 : -1.0).map((p, ix) => {
                                            return (<TableRow key={"row_sub_sub_" + ix}>
                                                <TableCell align="center">{p?.account}</TableCell>
                                                <TableCell align="center">{listedInstrumentStoreInstance.getInstrumentById(p?.listedInstrumentId)?.description}</TableCell>
                                                <TableCell align="center">{p?.ccy}</TableCell>
                                                <TableCell align="center">{p?.marketPrice}</TableCell>
                                                <TableCell align="center">{p?.quantity}</TableCell>
                                                <TableCell align="center">{this.getMarginedRowValue([p], fxRates)?.toLocaleString()}</TableCell>
                                                <TableCell align="center">{this.getMarginedRowValue([p], null)?.toLocaleString()}</TableCell>
                                            </TableRow>);
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>);
    }

    renderSpinner() {
        return <div style={{ top: "50%", position: "absolute", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", width: "100%", height: "100%" }}><CircularProgress /></div>
    }

    renderRowTitles() {
        return (<TableRow>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color, width: "50px" }} />
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Type</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Group</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Ccy</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Value (CCY)</TableCell>
            <TableCell variant="head" style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">Value (USD)</TableCell>
        </TableRow>);
    }

    prevWeekDay(d: Moment) {
        var prev = moment(d).subtract(1, "d");
        while (prev.weekday() === SaturdayDoW || prev.weekday() === SundayDoW) {
            prev = prev.subtract(1, "d");
        }
        return prev;
    }
    lastMonthEnd(d: Moment) {
        var prev = moment(d).date(1).subtract(1, "d");
        while (prev.weekday() === SaturdayDoW || prev.weekday() === SundayDoW) {
            prev = prev.subtract(1, "d");
        }
        return prev;
    }
    lastYearEnd(d: Moment) {
        var prev = moment(d).dayOfYear(1).subtract(1, "d");
        while (prev.weekday() === SaturdayDoW || prev.weekday() === SundayDoW) {
            prev = prev.subtract(1, "d");
        }
        return prev;
    }

    async flushServer() {
        this.setState({ flushing: true });
        await FlushNavCache();
        this.setState({ showAreYouSure: false, flushing: false });
        await this.calcNavs();
    }

    renderFlushCacheConfirm() {
        const { showAreYouSure, flushing } = this.state;
        return (
            <Dialog open={showAreYouSure} onClose={() => this.setState({ showAreYouSure: false })}  >
                <DialogTitle id="alert-dialog-title" style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>Are you sure?</DialogTitle>
                <DialogContent style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to flush the server-side NAV cache?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}>
                    <Button variant="outlined" disabled={flushing} onClick={() => this.flushServer()}>Flush Cache</Button>
                    <Button variant="outlined" disabled={flushing} onClick={() => this.setState({ showAreYouSure: false })} autoFocus>Cancel</Button>
                </DialogActions>
            </Dialog >);
    }

    renderDetailTable() {
        const { summary, mainRef, asOf, rowExpanded, selectedSet, selectedEntity, awaitingRefresh } = this.state;

        if (!summary) {
            return <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="NavCalcTableContainer">
                        {this.renderFlushCacheConfirm()}
                        <div className="NavCalcTableControls">
                            <Grid container spacing={2} justifyContent="flex-start" alignContent="center" style={{ padding: "0.5em" }}>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        value={asOf?.toDate()}
                                        onChange={this.onChangeDate}
                                        disableFuture
                                        // shouldDisableDate={(d) => !navStoreInstance.hasNavData(d.toDate(), fund?.displayName)}
                                        minDate={EarliestDate}
                                        emptyLabel="Live"
                                        clearLabel="Live"
                                        label={"As-Of"} />
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="set"
                                        name="set"
                                        label="Set"
                                        value={selectedSet ?? "Select Set..."}
                                        onChange={(d) => {
                                            var set = d.target.value as string;
                                            if (set === "Select Set...")
                                                set = null;
                                            this.props.onChangeState("selectedSet", set)
                                            this.setState({ selectedSet: set });
                                        }}>
                                        {["Select Set...", ...marketDataStoreInstance.getSetNames()].map(a =>
                                            <MenuItem key={"set_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="entity"
                                        name="entity"
                                        label="Entity"
                                        value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === selectedEntity)[0]?.displayName ?? "Select Entity..."}
                                        onChange={(d) => {
                                            var entity = d.target.value as string;
                                            if (entity === "Select Entity...")
                                                entity = null;

                                            var entityId = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === entity)[0]?.firmId;
                                            this.props.onChangeState("selectedEntity", entityId.toString())
                                            var nav = navStoreInstance.getCalculatedNav(asOf?.toDate(), entity);
                                            this.setState({ summary: nav, selectedEntity: entityId });
                                        }}                                    >
                                        {["Select Entity...", ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)].map(a =>
                                            <MenuItem key={"Entity_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                {/* <Grid item>
                                    <Button size="small" variant="outlined" disabled={awaitingRefresh} onClick={() => this.calcNavs()}>Compute</Button>
                                </Grid> */}
                                <Grid item >
                                    <Button size="small" variant="outlined" className='PltfmButtonLite' disabled={awaitingRefresh} onClick={() => this.setState({ showAreYouSure: true })}>Refresh</Button>
                                </Grid>
                                {awaitingRefresh ? <Grid item>
                                    <CircularProgress size="small" />
                                </Grid> : null}
                            </Grid>
                        </div>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        }

        var cashByInstoCcy = _.groupBy(summary?.cashPositions, (c) => c.institution + "~" + c.ccy);
        var securitiesByTypeCcy = _.groupBy(summary?.navSecurityPositions, (c) => listedInstrumentStoreInstance.getInstrumentById(c.listedInstrumentId)?.type + "~" + c.ccy);
        var marginedSecuritiesByTypeCcy = _.groupBy(summary?.navMarginedPositions, (c) => listedInstrumentStoreInstance.getInstrumentById(c.listedInstrumentId)?.type + "~" + c.ccy);
        var cfdsByCcy = _.groupBy(summary?.navCfdPositions, (c) => c.ccy);
        var loansByCcy = _.groupBy(summary?.navMoneyMarketPositions, (c) => c.counterparty + "~" + c.ccy);
        var acrualsByCategoryCcy = _.groupBy(summary?.navAccruals, (c) => c.category + "~" + c.ccy);

        var totalCash = _.sum(Object.keys(cashByInstoCcy).map((k) => this.getCashRowValue(cashByInstoCcy[k], summary.fxRatesToBase)));
        var totalSecurity = _.sum(Object.keys(securitiesByTypeCcy).map((k) => this.getSecurityRowValue(securitiesByTypeCcy[k], summary.fxRatesToBase)));
        var totalMargined = _.sum(Object.keys(marginedSecuritiesByTypeCcy).map((k) => this.getMarginedRowValue(marginedSecuritiesByTypeCcy[k], summary.fxRatesToBase)));
        var totalCfd = _.sum(Object.keys(cfdsByCcy).map((k) => this.getCfdRowValue(cfdsByCcy[k], summary.fxRatesToBase)));
        var totalLoans = _.sum(Object.keys(loansByCcy).map((k) => this.getLoanDepoRowValue(loansByCcy[k], summary.fxRatesToBase)));
        var totalAccurals = _.sum(Object.keys(acrualsByCategoryCcy).map((k) => this.getAccrualRowValue(acrualsByCategoryCcy[k], summary.fxRatesToBase)));
        //var total = totalCash + totalSecurity + totalMargined + totalCfd + totalLoans + totalAccurals;
        var total = summary?.totalInBase ?? 0;
        var formatOps = { maximumFractionDigits: 0, currency: summary?.baseCcy };
        var formatOpsPrice = { maximumFractionDigits: 4, currency: summary?.baseCcy };
        var formatOpsPrice2 = { maximumFractionDigits: 2, currency: summary?.baseCcy };
        var formatOpsPct = { maximumFractionDigits: 2, minimumFractionDigits: 2, unit: "percent", style: "unit" } as Intl.NumberFormatOptions;
        var formatOpsPct4 = { maximumFractionDigits: 4, minimumFractionDigits: 2, unit: "percent", style: "unit" } as Intl.NumberFormatOptions;
        var totalStr = (summary?.classDetail ? _.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.closingNAV)) : total)?.toLocaleString(undefined, formatOps);

        var totalProp = summary?.classDetail ? _.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.fundProportionAtLastOfficial)) * 100 : 0;
        var totalPropStr = totalProp?.toLocaleString(undefined, formatOpsPct4);


        var loansBaseIx = Object.keys(cashByInstoCcy).length;
        var accrualsBaseIx = loansBaseIx + Object.keys(loansByCcy).length;
        var securitiesBaseIx = accrualsBaseIx + Object.keys(acrualsByCategoryCcy).length;
        var cfdBaseIx = securitiesBaseIx + Object.keys(securitiesByTypeCcy).length;
        var marginedBaseIx = cfdBaseIx + Object.keys(cfdsByCcy).length;
        // var chartData = Object.keys(byType).map((k) => { return { name: k, total: this.getValue(byType[k], summary.fxRatesToBase) / 1000000 }; });
        // var totalColor = userStoreInstance.GetTheme().border_color;
        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var prevDayNav = navStoreInstance.getCalculatedNav(this.prevWeekDay(asOf).toDate(), fund?.displayName);
        console.log("prevWeekDay", this.prevWeekDay(asOf))
        console.log("prevDayNav", prevDayNav)
        var lastMonthNav = navStoreInstance.getCalculatedNav(this.lastMonthEnd(asOf).toDate(), fund?.displayName);
        var lastYearNav = navStoreInstance.getCalculatedNav(this.lastYearEnd(asOf).toDate(), fund?.displayName);

        const totalColStyle = { borderLeft: "1px solid", textAlign: "center" } as Partial<React.CSSProperties>;

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="NavCalcTableContainer">
                        {this.renderFlushCacheConfirm()}
                        <div className="NavCalcTableControls">
                            <Grid container spacing={2} justifyContent="flex-start" alignContent="center" style={{ padding: "0.5em" }}>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        value={asOf?.toDate()}
                                        onChange={this.onChangeDate}
                                        disableFuture
                                        // shouldDisableDate={(d) => !navStoreInstance.hasNavData(d.toDate(), fund?.displayName)}
                                        minDate={EarliestDate}
                                        emptyLabel="Live"
                                        clearLabel="Live"
                                        label={"As-Of"} />
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="set"
                                        name="set"
                                        label="Set"
                                        value={selectedSet ?? "Select Set..."}
                                        onChange={(d) => {
                                            var set = d.target.value as string;
                                            if (set === "Select Set...")
                                                set = null;
                                            this.props.onChangeState("selectedSet", set)
                                            this.setState({ selectedSet: set });
                                        }}>
                                        {["Select Set...", ...marketDataStoreInstance.getSetNames()].map(a =>
                                            <MenuItem key={"set_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id="entity"
                                        name="entity"
                                        label="Entity"
                                        value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === selectedEntity)[0]?.displayName ?? "Select Entity..."}
                                        onChange={(d) => {
                                            var entity = d.target.value as string;
                                            if (entity === "Select Entity...")
                                                entity = null;

                                            var entityId = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === entity)[0]?.firmId;
                                            this.props.onChangeState("selectedEntity", entityId.toString())
                                            var nav = navStoreInstance.getCalculatedNav(asOf?.toDate(), entity);
                                            this.setState({ summary: nav, selectedEntity: entityId });
                                        }}                                    >
                                        {["Select Entity...", ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)].map(a =>
                                            <MenuItem key={"Entity_" + a} value={a}>{a}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                {/* <Grid item>
                                    <Button size="small" variant="outlined" disabled={awaitingRefresh} onClick={() => this.calcNavs()}>Compute</Button>
                                </Grid> */}
                                <Grid item >
                                    <Button size="small" variant="outlined" disabled={awaitingRefresh} onClick={() => this.setState({ showAreYouSure: true })}>Refresh</Button>
                                </Grid>
                                {awaitingRefresh ? <Grid item>
                                    <CircularProgress size="small" />
                                </Grid> : null}
                            </Grid>
                        </div>
                        {awaitingRefresh || !summary ? (awaitingRefresh ? this.renderSpinner() : null) :
                            <div className="NavCalcTable" ref={mainRef}>
                                <Typography variant="h6">NAV Summary</Typography>
                                <div className="NavCalcTableSummary">
                                    <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={getNavTableTheme()}>
                                            <TableContainer style={{ height: "100%" }}>
                                                <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell colSpan={6} />
                                                            <TableCell colSpan={4} align="center" style={{ borderLeft: "1px solid", borderLeftColor: userStoreInstance.GetTheme().border_color }}>Performance</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell variant="head" align="center"></TableCell>
                                                            <TableCell variant="head" align="center">NAV/Share</TableCell>
                                                            <TableCell variant="head" align="center">GAV/Share</TableCell>
                                                            <TableCell variant="head" align="center">Quantity</TableCell>
                                                            <TableCell variant="head" align="center">NAV</TableCell>
                                                            <TableCell variant="head" align="center">Prop</TableCell>
                                                            <TableCell variant="head" align="center" style={{ borderLeft: "1px solid", borderLeftColor: userStoreInstance.GetTheme().border_color }}>Day</TableCell>
                                                            <TableCell variant="head" align="center">Month</TableCell>
                                                            <TableCell variant="head" align="center">Year</TableCell>
                                                            <TableCell variant="head" align="center">Inception</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {summary.classDetail ? Array.from(Object.keys(summary.classDetail)).map(k => {
                                                            var cd = summary.classDetail[k];
                                                            return (
                                                                <TableRow hover>
                                                                    <TableCell align="right" style={{ borderRight: "1px solid", fontWeight: "bold" }}>{cd.spec.name === "" ? "(Default)" : cd.spec.name}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{cd.navPerShare.toLocaleString(undefined, formatOpsPrice)}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{cd.gavWithMgmtFeesPerShare.toLocaleString(undefined, formatOpsPrice)}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{cd.numberOfShares.toLocaleString(undefined, formatOpsPrice)}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{(cd.numberOfShares * cd.navPerShare).toLocaleString(undefined, formatOps)}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{cd.fundProportionAtLastOfficial ? (Math.round(cd.fundProportionAtLastOfficial * 100 * 1000) / 1000).toLocaleString(undefined, formatOpsPct4) : null}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color, borderLeft: "1px solid", borderLeftColor: userStoreInstance.GetTheme().border_color }} align="center">{prevDayNav && prevDayNav.classDetail[k].navPerShare !== 0 ? ((cd.navPerShare / prevDayNav.classDetail[k].navPerShare - 1.0) * 100).toLocaleString(undefined, formatOpsPct) : ""}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{lastMonthNav && lastMonthNav.classDetail[k].navPerShare !== 0 ? ((cd.navPerShare / lastMonthNav.classDetail[k].navPerShare - 1.0) * 100).toLocaleString(undefined, formatOpsPct) : ""}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{lastYearNav && lastYearNav.classDetail[k].navPerShare !== 0 ? ((cd.navPerShare / lastYearNav.classDetail[k].navPerShare - 1.0) * 100).toLocaleString(undefined, formatOpsPct) : ""}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{cd.navPerShare === 0 ? null : ((cd.navPerShare / 1000.0 - 1.0) * 100).toLocaleString(undefined, formatOpsPct)}</TableCell>
                                                                </TableRow>
                                                            );
                                                        }) : null}
                                                        <TableRow>
                                                            <TableCell align="right" variant="head" style={{ borderRight: "1px solid" }}>Total</TableCell>
                                                            <TableCell colSpan={3} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalStr}</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalPropStr}</TableCell>
                                                            <TableCell colSpan={4} style={{ backgroundColor: userStoreInstance.GetTheme().background_color, borderLeft: "1px solid", borderLeftColor: userStoreInstance.GetTheme().border_color }} align="center"></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </ThemeProvider>
                                    </StyledEngineProvider>
                                </div>
                                {!summary?.classDetail ? null : <div className="NavCalcTableDetail">
                                    <Typography variant="h6">NAV Calculation</Typography>
                                    <div className="NavCalcTableDetailBox">
                                        <StyledEngineProvider injectFirst>
                                            <ThemeProvider theme={getNavTableTheme()}>
                                                <TableContainer style={{ height: "100%" }}>
                                                    <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell colSpan={1} />
                                                                {Array.from(Object.keys(summary?.classDetail)).map(k => {
                                                                    return <TableCell variant="head" align="center">{summary?.classDetail[k]?.spec?.name}</TableCell>
                                                                })}
                                                                <TableCell style={totalColStyle} variant="head" align="center">Total</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Start NAV</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].openingNAV.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.closingNAV)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Subscriptions</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].subsThisPeriod.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.subsThisPeriod)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Redemptions</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].redsThisPeriod.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.redsThisPeriod)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Add back Perf Fee YTD</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].addBackPerfFeeYTD.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.addBackPerfFeeYTD)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Add back Equalization</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].addBackEqualization.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.addBackEqualization)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow><TableCell colSpan={Object.keys(summary.classDetail).length + 2} style={{ height: "2px", borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, }} /></TableRow >
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Balance For Allocation</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].balanceForAllocation.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.balanceForAllocation)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">% Allocation</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{(summary.classDetail[k].fundProportionAtLastOfficial * 100).toLocaleString(undefined, formatOpsPct4)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.fundProportionAtLastOfficial * 100)).toLocaleString(undefined, formatOpsPct4)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Fund Net Income</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].netIncomeForClass.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.netIncomeForClass)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">GAV (pre-fees)</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].gav.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.gav)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Mgmt Fee</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{(summary.classDetail[k].managementFeesThisPeriod + summary.classDetail[k].managementFeesToday).toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.managementFeesThisPeriod)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow style={{ height: "2px" }}><TableCell colSpan={Object.keys(summary.classDetail).length + 2} style={{ height: "2px", borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, }} /></TableRow >
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">GAV (pre-perf)</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].gavPrePerf?.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.gavPrePerf)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Perf Fees</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].performanceFeeAccrual.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.performanceFeeAccrual)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Equalization</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].equalizationAmount.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.equalizationAmount)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                            <TableRow><TableCell colSpan={Object.keys(summary.classDetail).length + 2} style={{ height: "2px", borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, }} /></TableRow >
                                                            <TableRow>
                                                                <TableCell variant="head" align="center">Closing NAV</TableCell>
                                                                {Object.keys(summary.classDetail).map(k => { return <TableCell>{summary.classDetail[k].closingNAV?.toLocaleString(undefined, formatOpsPrice2)}</TableCell> })}
                                                                <TableCell style={totalColStyle}>{_.sum(Array.from(Object.keys(summary.classDetail)).map(k => summary?.classDetail[k]?.closingNAV)).toLocaleString(undefined, formatOpsPrice2)}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </ThemeProvider>
                                        </StyledEngineProvider>
                                    </div>
                                </div>}
                                <div className="NavCalcStrategyTable">
                                    <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={getPositionTableTheme()}>
                                            <TableContainer style={{ height: "100%" }}>
                                                <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                                    <TableHead>
                                                        {this.renderRowTitles()}
                                                    </TableHead>
                                                    <TableBody>
                                                        {Object.keys(cashByInstoCcy).sort((a, b) => a > b ? 1 : -1).map((g, ix) => this.renderCashRow(g, cashByInstoCcy[g], summary.fxRatesToBase, rowExpanded === ix, ix))}
                                                        <TableRow key="navCalcTableTotalCashRow" hover>
                                                            <TableCell colSpan={2} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Cash Total</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalCash.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        {Object.keys(loansByCcy).sort((a, b) => a > b ? 1 : -1).map((g, ix) => this.renderLoanDepoRow(g, loansByCcy[g], summary.fxRatesToBase, rowExpanded === ix + loansBaseIx, ix + loansBaseIx))}
                                                        <TableRow key="navCalcTableTotalLoanRow" hover>
                                                            <TableCell colSpan={2} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Money Market Total</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalLoans.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        {Object.keys(acrualsByCategoryCcy).sort((a, b) => a > b ? 1 : -1).map((g, ix) => this.renderAccrualRow(g, acrualsByCategoryCcy[g], summary.fxRatesToBase, rowExpanded === ix + accrualsBaseIx, ix + accrualsBaseIx))}
                                                        <TableRow key="navCalcTableTotalAccrualRow" hover>
                                                            <TableCell colSpan={2} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Accruals Total</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalAccurals.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        {Object.keys(securitiesByTypeCcy).sort((a, b) => a > b ? 1 : -1).map((g, ix) => this.renderSecurityRow(g, securitiesByTypeCcy[g], summary.fxRatesToBase, rowExpanded === ix + securitiesBaseIx, ix + securitiesBaseIx))}
                                                        <TableRow key="navCalcTableTotalSecRow" hover>
                                                            <TableCell colSpan={2} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Securities Total</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalSecurity.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        {Object.keys(cfdsByCcy).sort((a, b) => a > b ? 1 : -1).map((g, ix) => this.renderCfdRow(g, cfdsByCcy[g], summary.fxRatesToBase, rowExpanded === ix + cfdBaseIx, ix + cfdBaseIx))}
                                                        <TableRow key="navCalcTableTotalCfdRow" hover>
                                                            <TableCell colSpan={2} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>CFD Total</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalCfd.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        {Object.keys(marginedSecuritiesByTypeCcy).sort((a, b) => a > b ? 1 : -1).map((g, ix) => this.renderMarginedRow(g, marginedSecuritiesByTypeCcy[g], summary.fxRatesToBase, rowExpanded === ix + marginedBaseIx, ix + marginedBaseIx))}
                                                        <TableRow key="navCalcTableTotalMarginedRow" hover>
                                                            <TableCell colSpan={2} />
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={2}>Margined Total</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }} align="center">{totalMargined.toLocaleString()}</TableCell>
                                                        </TableRow>
                                                        <TableRow key="navCalcTableTotalRow" hover>
                                                            <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} />
                                                            <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} align="center" colSpan={3}>Total</TableCell>
                                                            <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} align="center" colSpan={1}>USD</TableCell>
                                                            <TableCell style={{ borderTop: "2px solid", backgroundColor: userStoreInstance.GetTheme().background_color, fontWeight: "bold" }} align="center">{totalStr}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </ThemeProvider>
                                    </StyledEngineProvider>
                                </div>
                                {summary?.classDetail && <div className="NavCalcInvestorTable">
                                    <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={getPositionTableTheme()}>
                                            <TableContainer style={{ height: "100%" }}>
                                                <Table stickyHeader={true} component={Card} style={{ overflowY: "auto", backgroundColor: userStoreInstance.GetTheme().background_color }}>
                                                    <TableHead>
                                                        <TableCell>Class</TableCell>
                                                        <TableCell>Investor</TableCell>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>NAV/Share</TableCell>
                                                        <TableCell>GAV/Share</TableCell>
                                                        <TableCell>#Shares</TableCell>
                                                        <TableCell>Equalization</TableCell>
                                                        <TableCell>Nominal</TableCell>
                                                        <TableCell>HWM</TableCell>
                                                    </TableHead>
                                                    <TableBody>
                                                        {Object.keys(summary.classDetail).map((c) => {
                                                            return Object.keys(summary.classDetail[c].investorLots).map((i) => {
                                                                var lot = summary.classDetail[c].investorLots[i];
                                                                return lot.investments.map((investment, ix) => <TableRow key={"ivLot" + lot.investor + ix} hover>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{c}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{lot.investor}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{moment(investment.date).format(userStoreInstance.GetDateFormat())}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{investment.navPerShareRef}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{investment.gavPerShareRef}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{investment.shares}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{investment.equalizationCredit}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{investment.nominal}</TableCell>
                                                                    <TableCell style={{ backgroundColor: userStoreInstance.GetTheme().background_color }}>{investment.investmentHwm}</TableCell>
                                                                </TableRow>);
                                                            });
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </ThemeProvider>
                                    </StyledEngineProvider>
                                </div>}
                                {/* <div className="NavCalcChart">
                                    <Typography>NAV Contribution</Typography>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart key="navChart" width={500} height={400} data={chartData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis unit="m" />
                                            <Tooltip />
                                            <ReferenceLine y={0} stroke="#000" />
                                            <Bar dataKey="total" fill={totalColor} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div> */}
                            </div>}
                    </div></ThemeProvider>
            </StyledEngineProvider>
        );
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    renderHistoryChart() {
        const { selectedEntity, asOf, selectedChartField, awaitingRefresh } = this.state;
        let mainColor = userStoreInstance.GetTheme().color;
        let borderColor = userStoreInstance.GetTheme().border_color;
        let chartCol = userStoreInstance.GetTheme().chart_color_up;

        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var navs = navStoreInstance.getCalculatedNavs(fund?.displayName);

        var availableFields = navs && navs.length > 0 ? ["Total NAV", ...Array.from(Object.keys(navs[0].nav.classDetail)).map(c => `${c ?? "Main"} NAV/Share`)] : ["Total NAV"];

        var chartLayout: Partial<Layout> = {
            title: {
                text: "NAV Chart",
                font: { color: userStoreInstance.GetTheme().color }
            },
            dragmode: "zoom",
            plot_bgcolor: userStoreInstance.GetTheme().background_color,
            paper_bgcolor: userStoreInstance.GetTheme().background_color,
            xaxis: {
                type: 'date',
                color: userStoreInstance.GetTheme().color,
                rangeslider: {
                    visible: false
                },
                autotick: true
            },
            yaxis: {
                color: userStoreInstance.GetTheme().color
            },
            legend: {
                x: 0.5,
                xanchor: 'center',
                y: 1,
                orientation: 'h'
            }
        };

        var y: Datum[];
        var sf = selectedChartField ?? availableFields[0];
        if (!availableFields.includes(selectedChartField)) {
            sf = availableFields[0];
            this.setState({ selectedChartField: sf });
        }
        if (sf === "Total NAV") {
            y = navs?.filter(n => n.nav.totalInBase !== 0).map(d => d.nav.totalInBase);
        }
        else if (sf.endsWith(" NAV/Share")) {
            var shrClassName = sf.split(" NAV/Share")[0];
            y = navs?.filter(n => n.nav.totalInBase !== 0).map(d => d.nav.classDetail[shrClassName].navPerShare);
        }

        var yOfficial: Datum[];
        if (sf.endsWith(" NAV/Share")) {
            var shrClassName2 = sf.split(" NAV/Share")[0];
            yOfficial = navs?.filter(n => n.nav.totalInBase !== 0).map(d => {
                if (moment(d.date).isSame(moment(d.nav.classDetail[shrClassName2].lastOfficialNavDate), "D")) {
                    return d.nav.classDetail[shrClassName2].lastOfficialUnitNav;
                }
                return undefined;
            });
        }

        var chartData =
            [
                {
                    name: "NAV/Share",
                    x: navs?.filter(n => n.nav.totalInBase !== 0).map(d => new Date(d.date)),
                    y: y,
                    type: 'scattergl',
                    mode: 'lines',
                    line: { color: mainColor },
                    marker: { color: borderColor, size: 2 },
                }
            ] as Data[];

        if (yOfficial?.length > 0) {
            chartData.push(
                {
                    name: "Official NAV",
                    x: navs?.filter(n => n.nav.totalInBase !== 0).map(d => new Date(d.date)),
                    y: yOfficial,
                    type: 'scattergl',
                    mode: 'markers',
                    marker: { symbol: "circle-open", color: chartCol, size: 8 },
                }
            )
        }

        return <StyledEngineProvider injectFirst>
            <ThemeProvider theme={getFormTheme()}>
                <div className="NavCalcTableContainer">
                    <Grid container direction="column" >
                        <Grid item container direction="row" padding="0.5em" spacing={1}>
                            <Grid item>
                                <WrappedSelect
                                    id="entity"
                                    name="entity"
                                    label="Entity"
                                    value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === selectedEntity)[0]?.displayName ?? "Select Entity..."}
                                    onChange={(d) => {
                                        var entity = d.target.value as string;
                                        if (entity === "Select Entity...")
                                            entity = null;

                                        var entityId = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === entity)[0]?.firmId;
                                        this.props.onChangeState("selectedEntity", entityId.toString())
                                        var nav = navStoreInstance.getCalculatedNav(asOf?.toDate(), entity);
                                        this.setState({ summary: nav, selectedEntity: entityId });
                                    }}                                    >
                                    {["Select Entity...", ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)].map(a =>
                                        <MenuItem key={"Entity_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    id="field"
                                    name="field"
                                    label="Field"
                                    value={selectedChartField ?? availableFields[0]}
                                    onChange={(d) => {
                                        var field = d.target.value as string;
                                        this.setState({ selectedChartField: field });
                                    }}                                    >
                                    {availableFields.map(a =>
                                        <MenuItem key={"Field_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                        </Grid>
                        <Grid item height="calc(100vh - 250px)" >
                            {awaitingRefresh ?
                                <Grid item container direction="column" justifyContent="center" alignContent="center">
                                    <Grid item><LinearProgress /></Grid>
                                    <Grid item><Typography variant='h6'>Loading NAV data...</Typography></Grid>
                                </Grid> :
                                <Plot
                                    data={chartData}
                                    layout={chartLayout}
                                    config={{ responsive: true, displaylogo: false, autosizable: true, fillFrame: false, showAxisDragHandles: true }}
                                    style={{ width: "100%", height: "100%", backgroundColor: "none" }}
                                    useResizeHandler={true}
                                />}
                        </Grid>
                    </Grid>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    }

    renderNumberCell(params: GridCellParams) {
        if (params.value) {
            var val: number = parseFloat(params.value.toString());
            return <div>{val.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>
        }
        else
            return <div></div>;
    }

    renderDateCell(params: GridCellParams) {
        if (params.value) {
            var m = moment.utc(params.value.toString());
            return <div>{m.format("yyyy-MM-DD")}</div>
        }
        else
            return <div></div>;
    }

    customToolbar(props: any) {
        const { selectedEntity, selectedSet, asOf } = this.state;
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container spacing={1} alignContent="center" style={{ padding: "5px" }}>
                            <Grid item>
                                <WrappedSelect
                                    id="set"
                                    name="set"
                                    label="Set"
                                    value={selectedSet ?? "Select Set..."}
                                    onChange={(d) => {
                                        var set = d.target.value as string;
                                        if (set === "Select Set...")
                                            set = null;
                                        this.props.onChangeState("selectedSet", set)
                                        this.setState({ selectedSet: set });
                                    }}>
                                    {["Select Set...", ...marketDataStoreInstance.getSetNames()].map(a =>
                                        <MenuItem key={"set_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedSelect
                                    id="entity"
                                    name="entity"
                                    label="Entity"
                                    value={userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.firmId === selectedEntity)[0]?.displayName ?? "Select Entity..."}
                                    onChange={(d) => {
                                        var entity = d.target.value as string;
                                        if (entity === "Select Entity...")
                                            entity = null;

                                        var entityId = userStoreInstance.GetFirms().filter(f => f.isBookingEntity && f.displayName === entity)[0]?.firmId;
                                        this.props.onChangeState("selectedEntity", entityId.toString())
                                        var nav = navStoreInstance.getCalculatedNav(asOf?.toDate(), entity);
                                        this.setState({ summary: nav, selectedEntity: entityId });
                                    }}                                    >
                                    {["Select Entity...", ...userStoreInstance.GetFirms().filter(f => f.isBookingEntity).map(f => f.displayName)].map(a =>
                                        <MenuItem key={"Entity_" + a} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item><GridToolbarExport {...props} size="small" className="MuiButton-outlined" /></Grid>
                            <Grid item><GridToolbarFilterButton {...props} style={{ borderColor: userStoreInstance.GetTheme().border_color, height: "2.5em" }} className="MuiButton-outlined" />                            </Grid>
                            <Grid item><GridToolbarColumnsButton {...props} size="small" className="MuiButton-outlined" /></Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    loading() {
        return (
            <GridOverlay style={{ backgroundColor: userStoreInstance.GetTheme().contrast_background_color + " !important" }}>
                <div style={{ position: 'absolute', top: 0, width: '100%', opacity: 0.9 }}>
                    <LinearProgress />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "space-around",
                        paddingTop: "30px",
                        color: userStoreInstance.GetTheme().contrast_color + " !important"
                    }}>
                        <Typography variant="h5">Loading...</Typography>
                    </div>
                </div>
            </GridOverlay>
        );
    }

    showDetail(date: Moment) {
        this.onChangeDate(date);
        this.setState({ selectedTabId: 2 })
    }

    renderHistoryTable() {
        var fund = userStoreInstance.GetFirms().filter(f => f.firmId === this.state.selectedEntity)[0];
        var navs = navStoreInstance.getCalculatedNavs(fund?.displayName);


        const baseCol = { flex: 1, cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
        const columns: GridColDef[] = navs && navs.length > 0 ? [
            { field: 'date', width: 150, type: "dateTime", renderCell: this.renderDateCell, ...baseCol },
            { field: 'total', width: 175, headerName: 'Total NAV', type: "number", renderCell: this.renderNumberCell, ...baseCol },
            ...Array.from(Object.keys(navs[0].nav.classDetail)).flatMap(c => {
                return [
                    { field: (c ?? "None") + "Nav", width: 175, headerName: `${c ?? "Main"} NAV/Share`, type: "number", renderCell: this.renderNumberCell, ...baseCol } as GridColDef,
                    { field: (c ?? "None") + "Official", width: 175, headerName: `${c ?? "Main"} Official NAV/Share`, type: "number", renderCell: this.renderNumberCell, ...baseCol } as GridColDef,
                    { field: (c ?? "None") + "Shares", width: 175, headerName: `${c ?? "Main"} #Shares`, type: "number", renderCell: this.renderNumberCell, ...baseCol } as GridColDef,
                ] as GridColDef[];
            }),
            {
                field: 'actions',
                width: 100,
                headerName: 'View Detail',
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => <IconButton onClick={() => this.showDetail(moment(params.getValue(params.id, "date").toString()))} size="large"><ZoomInOutlined /></IconButton>
                , ...baseCol
            },
        ] :
            [{ field: 'date', width: 150, type: "dateTime", renderCell: this.renderDateCell, ...baseCol },
            { field: 'total', width: 175, headerName: 'Total NAV', type: "number", renderCell: this.renderNumberCell, ...baseCol }];

        const rows = navs ? navs
            .sort((a, b) => new Date(b.date).valueOf() > new Date(a.date).valueOf() ? 1 : -1)
            .map((n, ix) => {
                var row = {
                    id: ix,
                    total: n.nav.totalInBase,
                    date: moment.utc(n.nav.navDate),
                };
                Object.keys(n.nav.classDetail).forEach(c => {
                    row[(c ?? "None") + "Nav"] = n.nav.classDetail[c].navPerShare;
                    row[(c ?? "None") + "Shares"] = n.nav.classDetail[c].numberOfShares;
                    row[(c ?? "None") + "Official"] = moment(n.nav.classDetail[c].lastOfficialNavDate).isSame(moment(n.date), "day") ? n.nav.classDetail[c].lastOfficialUnitNav : n.nav.classDetail[c].navPerShare;
                });
                return row;
            })
            : new Array<GridRowData>();


        return <StyledEngineProvider injectFirst>
            <ThemeProvider theme={getFormTheme()}>
                <div className="NavCalcTableContainer">
                    <DataGridPro
                        className="PositionSummaryTabTable"
                        rows={rows}
                        columns={columns}
                        hideFooter
                        components={{
                            Toolbar: this.customToolbar,
                            LoadingOverlay: this.loading,
                        }}
                    //loading={awaitingRefresh}
                    />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    }


    render() {
        const { selectedTabId } = this.state;
        return (<div>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' style={{ paddingTop: "5px" }} value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"tabHistoryChrt"}
                        component={Paper}
                        id={"tab0"}
                        label="Chart" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"tabHistoryTbl"}
                        component={Paper}
                        id={"tab0"}
                        label="Table" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"2"}
                        key={"tabUpdate"}
                        component={Paper}
                        id={"tab1"}
                        label="Detail" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery0"}
                    value={"0"}
                    //index={0}
                    children={this.renderHistoryChart()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery1"}
                    value={"1"}
                    //index={0}
                    children={this.renderHistoryTable()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabPQuery2"}
                    value={"2"}
                    //index={1}
                    children={this.renderDetailTable()} />
            </TabContext>
        </div>
        );
    }
}
