import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore'
import { GeneralStatsUpdatedEvent } from './userSessionActions';
import { GeneralStatsStoreState } from './pingModels';


class GeneralStatsStore extends FluxStore<GeneralStatsStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            stats: {}
        }))
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof GeneralStatsUpdatedEvent) {
            const {payload} = action;
            this.state.stats[payload.type] = payload;
            this.emitChange();
        }
    }

    public GetStats(type: string) {
        return this.state.stats[type];
    }
}

const generalStatsStoreInstance = new GeneralStatsStore(AppDispatcher);
export default generalStatsStoreInstance;

