import { ListedInstrument } from "../listedInstruments/listedInstrumentModels";
import { FieldArray, FieldArrayRenderProps, Form } from "formik";
import { getFormTheme } from "../inputs/formCommon";
import InstrumentSelection from "../inputs/instrumentSelection";
import ordersStoreInstance from "./orderStore";
import moment, { Moment } from "moment";
import listedInstrumentStoreInstance from "../listedInstruments/listedInstrumentStore";
import { NumberFormatCustom } from "../inputs/numberFormatInput";
import positionStoreInstance from "../positions/positionSummaryStore";
import { GoodUntil, Order, OrderComment, OrderStatus, OrderType, Venue } from "./orderModels";
import { WrappedDateTimePicker } from "../inputs/wrappedDateTimePicker";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { FormSection } from "../inputs/formSection";
import { CheckCircleOutline, CancelOutlined, AddOutlined, RemoveOutlined, ControlPointDuplicateOutlined, UndoOutlined, SaveAltOutlined } from "@mui/icons-material";
import {
    CssBaseline,
    ThemeProvider,
    Grid,
    Typography,
    MenuItem,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    TextareaAutosize,
    SelectChangeEvent,
} from "@mui/material";
import marketDataLiveStoreInstance from "../marketData/marketDataLiveStore";
import _ from "lodash";
import userStoreInstance from "../user/userStore";
import { getMetaFromInstrument, getUltimateParentFromIns } from "../positions/positionsCommon";
import React, { ReactElement } from "react";
import { AllowedOrderTypes } from "../globalConstants";

export const LimitOrderTypes = [OrderType.Limit, OrderType.LimitIfTouched, OrderType.StopLimit, OrderType.LimitOnClose, OrderType.TrailingLimitIfTouched];
export const StopOrderTypes = [OrderType.Stop, OrderType.StopLimit];

const countDecimals = (value) => {
    if (Math.floor(value) !== value)
        return value.toString().split(".")[1].length || 0;
    return 0;
}

export const OutrightOrderForm = props => {
    const {
        values: {
            listedInstrumentId,
            orderType,
            isStop,
            stopLevel,
            goodUntil,
            isLimit,
            limitLevel,
            isTrailing,
            trailingAmount,
            isBuyOrder,
            size,
            tempQuantity,
            universe,
            openPosition,
            venuesForIns,
            orderVenue,
            comments,
            orderVenueObject,
            account,
            timeInForce,
        },
        lastBookedId,
        nActiveOrders,
        canDuplicate,
        onDuplicatePressed,
        onClearPressed,
        errors,
        touched,
        isValid,
        setFieldTouched,
        setFieldValue,
        className,
        handleSubmit,
        venuesLookup,
        isSubmitting,
        onUpdateOrder,
        onCancelOrder,
        onSaveOrder,
        anyChangesToSave,
        fixedInsId,
        fixedMeta,
        warning
    } = props;


    const setValueLocal = (name: string, value: any) => {
        setFieldValue(name, value);
        //updateOrder();
    }

    const updateOrderLocal = (listedInstrumentIdX?: number, limitLevelX?: number, stopLevelX?: number, orderTypeX?: OrderType, sizeX?: number, isBuyOrderX?: boolean) => {
        var o = {
            limitLevel: limitLevelX ?? limitLevel as number,
            listedInstrumentId: listedInstrumentIdX ?? listedInstrumentId as number,
            orderType: orderTypeX ?? orderType as OrderType,
            stopLevel: stopLevelX ?? stopLevel as number,
            goodUntil: goodUntil as Date,
            trailingAmount: trailingAmount as number,
            isBuyOrder: isBuyOrderX !== undefined ? isBuyOrderX : (isBuyOrder as boolean),
            size: sizeX ?? size as number,
            orderVenue: orderVenue as string,
            comments: comments as OrderComment[],
            account: account as string,
            timeInForce: timeInForce as GoodUntil,
        } as Partial<Order>;
        onUpdateOrder(o);
    }

    if (fixedInsId && fixedInsId !== listedInstrumentId)
        setValueLocal("listedInstrumentId", fixedInsId);

    const ins = listedInstrumentStoreInstance.getInstrumentById(listedInstrumentId);

    if (!isLimit && LimitOrderTypes.includes(orderType))
        setValueLocal("isLimit", true);
    if (!isStop && StopOrderTypes.includes(orderType))
        setValueLocal("isStop", true);

    if (timeInForce === undefined)
        setValueLocal("timeInForce", GoodUntil.GoodForDay);

    if (orderType === undefined) {
        setValueLocal("orderType", OrderType.Limit);
        updateOrderLocal(undefined, undefined, undefined, OrderType.Limit);
    }

    const instrumentSelected = (instrument: ListedInstrument) => {
        if (listedInstrumentId) {
            marketDataLiveStoreInstance.UnSubscribeToTickStream(listedInstrumentId);
        }
        setFieldValue("listedInstrumentId", instrument.listedInstrumentId);
        setFieldTouched("listedInstrumentId", true, false);

        var venuesMetaForIns = _.uniq(instrument.metaData.filter(x => x.category === 'SourceSystemId').map(x => x.type));
        var venuesForIns = venuesMetaForIns.map(m => venuesLookup[m]).filter(x => Boolean(x));
        if (venuesForIns === undefined || venuesForIns.length === 0)
            venuesForIns = ["(None Available)"]
        setFieldValue("venuesForIns", venuesForIns);
        setFieldValue("orderVenue", venuesForIns[0]);
        setFieldTouched("orderVenue", true, false);
        var orderVenueObject = ordersStoreInstance.getOrderVenues()[venuesForIns[0]];
        setFieldValue("orderVenueObject", orderVenueObject);

        var parentIns = getUltimateParentFromIns(instrument);
        var defaultAcc = getMetaFromInstrument(parentIns, "IbDefaultAccount") ?? orderVenueObject?.capabilities["Accounts"]?.split(",").filter(a => a !== "All")[0];
        setFieldValue("account", defaultAcc);

        var position = getOpenPositionForInstrument(instrument.listedInstrumentId);
        if (position && position !== 0) {
            setFieldValue("openPosition", position);
        }
        updateOrderLocal(instrument.listedInstrumentId);
    }

    if (ins && (!orderVenue || !venuesForIns)) {
        instrumentSelected(ins);
    }

    function getOpenPositionForInstrument(insId: number) {
        var pos = positionStoreInstance.getPositionForInstrument(null, Number(insId));
        return pos;
    }

    function onChangeOrderType(e: SelectChangeEvent<any>) {
        var asStr = e.target.value;
        var asEnum: OrderType = OrderType[asStr as keyof typeof OrderType];
        orderTypeSelected({ id: asEnum.valueOf(), value: asEnum.toString() })
    }

    function onChangeTimeInForce(e: SelectChangeEvent<any>) {
        var asStr = e.target.value;
        var asEnum: GoodUntil = GoodUntil[asStr as keyof typeof GoodUntil];
        setValueLocal("timeInForce", asEnum);
    }

    const orderTypeSelected = (orderType: { id: number, value: string } | string) => {
        if (orderType instanceof String) {
            return;
        }
        else {
            var ot = orderType as { id: number, value: string };
            if (ot?.id !== undefined) {
                setValueLocal("orderType", ot.id);
                setFieldTouched("orderType", true, false);
                if (ot && ot.value) {
                    if (ot.value.includes('Stop') && !ot.value.includes("Trailing")) {
                        setValueLocal("isStop", true);
                        var openPos = getOpenPositionForInstrument(listedInstrumentId);
                        if (openPos)
                            setValueLocal("isBuyOrder", openPos < 0);
                    }
                    else {
                        setValueLocal("isStop", false);
                    }
                    if (ot.value.includes("Limit")) {
                        setValueLocal("isLimit", true);
                    }
                    else {
                        setValueLocal("isLimit", false);
                    }
                    if (ot.value.includes("Trailing")) {
                        setValueLocal("isTrailing", true);
                    }
                    else {
                        setValueLocal("isTrailing", false);
                    }
                }
                else {
                    setValueLocal("isStop", false);
                    setValueLocal("isLimit", false);
                    setValueLocal("isTrailing", false);
                }
            }
            else
                setFieldTouched("orderType", false, false);
            updateOrderLocal(undefined, undefined, undefined, ot.id);
        }
    }

    const directionSelected = (direction: string) => {
        var isBuyOrder = direction === "buy";
        setValueLocal("isBuyOrder", isBuyOrder);
        setFieldTouched("isBuyOrder", true, false);
        updateOrderLocal(undefined, undefined, undefined, undefined, undefined, isBuyOrder);
    }

    const onChangeStopLevel = (val: string) => {
        var asNum = Number(val);
        setValueLocal("stopLevel", isNaN(asNum) ? val : asNum);
        setFieldTouched("stopLevel", true, false);
        updateOrderLocal(undefined, undefined, isNaN(asNum) ? undefined : asNum);
    }

    const onChangeTrailingAmount = (val: string) => {
        var asNum = Number(val);
        setValueLocal("trailingAmount", isNaN(asNum) ? val : asNum);
        setFieldTouched("trailingAmount", true, false);
    }

    const onChangeGoodUntil = (d: Moment) => {
        setValueLocal("goodUntil", d.toDate());
    }

    const onChangeSize = (val: string) => {
        var asNum = Number(val);

        if (val === undefined || val==="" || asNum===0)
            setValueLocal("size", undefined);


        var updateQuantity = false;
        if (val?.toLowerCase() === "o" && Boolean(openPosition)) {
            asNum = Math.abs(openPosition);
            updateQuantity = true;
        }
        else if (val?.endsWith('%') && !isNaN(Number(val.replace('%', ''))) && Boolean(openPosition)) {
            asNum = Math.round(Math.abs(openPosition * Number(val.replace('%', '')) / 100));
            updateQuantity = true;
        }

        if (!isNaN(asNum)) {
            setValueLocal("size", asNum);
            if (updateQuantity)
                setValueLocal("tempQuantity", asNum * (ins?.multiplier ?? 1.0));
        }
        else
            setValueLocal("size", val);
    }

    const onBlurSize = () => {
        if (size === undefined)
            setValueLocal("tempQuantity", undefined);
        else {
            setValueLocal("tempQuantity", size * (ins?.multiplier ?? 1.0));
            updateOrderLocal(undefined, undefined, undefined, undefined, size);
        }
    }
    const onChangeQuantity = (val: string) => {
        if (val === undefined)
            setValueLocal("tempQuantity", undefined);

        var asNum = Number(val);
        if (!isNaN(asNum)) {
            setValueLocal("tempQuantity", asNum);
        }
        else
            setValueLocal("tempQuantity", val);
    }
    const onChangeLimit = (val: string) => {
        var asNum: number;
        if (listedInstrumentId && (val?.toLowerCase().includes("a") || val?.toLowerCase().includes("b") || val?.toLowerCase().includes("m"))) {
            var ba = marketDataLiveStoreInstance.getBidAsk(listedInstrumentId)
            if (val?.toLowerCase().includes("a") && ba?.ask) {
                asNum = ba.ask;
            }
            else if (val?.toLowerCase().includes("b") && ba?.bid) {
                asNum = ba.bid;
            }
            else if (val?.toLowerCase().includes("m") && ba?.bid && ba?.ask) {
                var tickSize = ins?.metaData.find(m => m.type === "TickSize") ?? ins?.underlying?.metaData.find(m => m.type === "TickSize");
                asNum = (ba.bid + ba.ask) / 2;
                if (tickSize) {
                    asNum = asNum - asNum % Number(tickSize.data);
                }
                else {
                    var nDp = Math.max(countDecimals(ba.bid), countDecimals(ba.ask))
                    var fac = Math.pow(10, nDp);
                    asNum = Math.round(asNum * fac) / fac;
                }
            }
            else {
                asNum = Number.NaN;
            }

        }
        else {
            asNum = Number(val);
        }

        if (!isNaN(asNum)) {
            setValueLocal("limitLevel", asNum);
        }
        else
            setValueLocal("limitLevel", val);


        updateOrderLocal(undefined, isNaN(asNum) ? undefined : asNum);
    }

    const onChangeOrderVenue = (venue: string) => {
        var orderVenueObject = ordersStoreInstance.getOrderVenues()[venue];
        setValueLocal("orderVenue", venue);
        setFieldTouched("orderVenue", true, false);
        setValueLocal("orderVenueObject", orderVenueObject);
    }

    const onChangeAccount = (account: string) => {
        setValueLocal("account", account);
        setFieldTouched("account", true, false);
    }

    const onEnterPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13 && tempQuantity) {
            e.preventDefault();
            var inContracts = tempQuantity / (ins?.multiplier ?? 1.0);
            setValueLocal("size", inContracts);
            setFieldTouched("size", true, false);
        }
    }

    const onBlur = () => {
        var inContracts = tempQuantity / (ins?.multiplier ?? 1.0);
        if (!isNaN(inContracts)) {
            setValueLocal("size", inContracts);
            setFieldTouched("size", true, false);
        }
    }

    const onCommentChange = (val: string, ix: number) => {
        var name = `comments[${ix}].text`;
        setValueLocal(name, val)
        setFieldTouched(name, true, false);
    }

    const isValidTifForVenue = (x: string) => {
        return !orderVenueObject?.capabilities || !orderVenueObject.capabilities["ValidTimeInForce"] || orderVenueObject.capabilities["ValidTimeInForce"].split(',').includes(x.toString())
    }

    var insUniverse = undefined;
    if (universe === "Open")
        insUniverse = positionStoreInstance.getPositionSummary(null)?.positions
            .filter(x => x.openPosition !== 0)
            .map(p => p.instrumentId);
    else if (universe === "Missing")
        insUniverse = positionStoreInstance.getPositionSummary(null)?.positions
            .filter(x => x.openPosition !== 0 && !ordersStoreInstance.getOrders().some(o => (o.orderType === OrderType.Stop || o.orderType === OrderType.StopLimit) && o.orderStatus !== OrderStatus.Cancelled && o.listedInstrumentId === x.instrumentId))
            .map(p => p.instrumentId);

    const hasContracts = ins?.multiplier !== 1.0;
    const titleStyle = { width: "175px", textAlign: "right", paddingRight: "1em" } as React.CSSProperties;

    // if (!firstDrawCompleted) {
    //     if (!lastPrice && listedInstrumentId)
    //         instrumentSelected(listedInstrumentStoreInstance.getInstrumentById(listedInstrumentId));
    //     setFieldValue('firstDrawCompleted', true)
    // }
    const showInsPicker = fixedInsId === undefined;
    const allowedOrderTypes = AllowedOrderTypes.length === 0 ? Object.keys(OrderType).filter(x => isNaN(Number(x))) : AllowedOrderTypes.map(a => OrderType[a]);

    const editMode = onSaveOrder !== undefined;

    return (
        <Form onSubmit={handleSubmit} className={className} style={{ width: "100%", padding: "0.5em" }}>
            <CssBaseline>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container item spacing={2} justifyContent="flex-start" >
                        {showInsPicker && <FormSection label={<Typography variant="h6" style={titleStyle}>Instrument</Typography>}>
                            <Grid item>
                                <WrappedSelect
                                    id="Universe"
                                    name="Universe"
                                    label="Universe"
                                    value={universe ?? "All"}
                                    onChange={(d) => { setFieldValue("universe", (d.target.value as string)) }}>
                                    <MenuItem key={"uni_all"} value={"All"}>All Instruments</MenuItem>
                                    <MenuItem key={"uni_open"} value={"Open"}>Open Position</MenuItem>
                                    <MenuItem key={"uni_missing"} value={"Missing"}>Missing Stops</MenuItem>
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <InstrumentSelection hideExpired={true} listedInstrumentId={listedInstrumentId} onInstrumentSelect={instrumentSelected} filterType={undefined} restrictedInsList={insUniverse} size="small"></InstrumentSelection>
                            </Grid>
                            {!Boolean(venuesForIns) ? null : <Grid item>
                                <WrappedSelect
                                    id="Venue"
                                    name="Venue"
                                    label="Venue"
                                    value={orderVenue ?? null}
                                    onChange={(d) => { onChangeOrderVenue(d.target.value) }}>
                                    {venuesForIns.map((v, ix) => <MenuItem key={"odaVnu" + ix} value={v}>{v}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>}
                            {nActiveOrders > 0 && <Grid item><Typography variant="subtitle2">{nActiveOrders} Active Order{nActiveOrders > 1 ? "s" : ""}</Typography></Grid>}
                        </FormSection>}
                        <FormSection label={<Typography variant="h6" style={titleStyle}>Order Size</Typography>}>
                            {!Boolean(openPosition) ? null : <Grid item><TextField
                                variant="standard"
                                size="small"
                                style={{ width: "120px" }}
                                classes={{ root: "ListedInstrumentEditorFormField" }}
                                id="openPosition"
                                name="openPosition"
                                label="Open Position"
                                value={openPosition}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, inputComponent: NumberFormatCustom as any, readOnly: true }}
                            /></Grid>}
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="size"
                                    name="size"
                                    size="small"
                                    helperText={errors.size ? errors.size : ""}
                                    error={Boolean(errors.size)}
                                    label={hasContracts ? "Contracts" : "Quantity"}
                                    value={size===undefined || size===0 ? "" : size}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    onChange={(e) => onChangeSize(e.target.value)}
                                    onBlur={onBlurSize}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                        inputProps: { customNumerals: ['o', '%'] }
                                    }} />
                            </Grid>
                            {!hasContracts ? null : <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="Quantity"
                                    name="Quantity"
                                    size="small"
                                    helperText={errors.size ? errors.size : ""}
                                    error={Boolean(errors.size)}
                                    label="Quantity"
                                    value={tempQuantity===undefined || tempQuantity===0 ? "" : tempQuantity}
                                    onChange={(e) => onChangeQuantity(e.target.value)}
                                    onKeyDown={(e) => onEnterPressed(e)}
                                    onBlur={onBlur}
                                    onFocus={event => {
                                        event.target.select();
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputComponent: NumberFormatCustom as any, classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                            </Grid>}
                        </FormSection>
                        <FormSection label={<Typography variant="h6" style={titleStyle}>Order Type</Typography>}>
                            <Grid item>
                                <WrappedSelect
                                    disabled={editMode}
                                    variant={editMode ? "standard" : "outlined"}
                                    id={"orderType"}
                                    name="orderType"
                                    label="Order Type"
                                    style={{ width: "170px" }}
                                    value={orderType === undefined ? '' : OrderType[orderType]}
                                    onChange={(e, c) => onChangeOrderType(e)}>
                                    {allowedOrderTypes.map((v, ixx) => <MenuItem key={"otype" + ixx} value={v}>{v}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" variant="outlined" size="small">
                                    {/* <FormLabel >Direction</FormLabel> */}
                                    <RadioGroup
                                        row
                                        aria-label="buysell"
                                        name="radio-buttons-buysell-group"
                                        onChange={(e, v) => editMode ? {} : directionSelected(v)}
                                        value={isBuyOrder === undefined ? null : (isBuyOrder ? "buy" : "sell")}>
                                        <FormControlLabel value="buy" control={<Radio color="primary" readOnly={editMode} disabled={!isBuyOrder && editMode} />} label="Buy" />
                                        <FormControlLabel value="sell" control={<Radio color="secondary" readOnly={editMode} disabled={isBuyOrder && editMode} />} label="Sell" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {isStop && <Grid item>
                                <TextField
                                    variant="outlined"
                                    style={{ width: "120px" }}
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="stopLevel"
                                    name="stopLevel"
                                    size="small"
                                    helperText={errors.stopLevel ? errors.stopLevel : ""}
                                    error={Boolean(errors.stopLevel)}
                                    label="Stop Level"
                                    value={stopLevel}
                                    onChange={(e) => onChangeStopLevel(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                    }} /></Grid>}
                            {isLimit && <Grid item>
                                <TextField
                                    variant="outlined"
                                    style={{ width: "120px" }}
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="limitLevel"
                                    name="limitLevel"
                                    size="small"
                                    helperText={errors.limitLevel ? errors.limitLevel : ""}
                                    error={Boolean(errors.limitLevel)}
                                    label="Limit Level"
                                    value={limitLevel}
                                    //onChange={change.bind(null, "limitLevel")}
                                    onChange={(e) => onChangeLimit(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                        inputComponent: NumberFormatCustom as any,
                                        inputProps: { customNumerals: ['a', 'b', 'm'] }
                                    }} /></Grid>}
                            {isTrailing && <Grid item>
                                <TextField
                                    variant="outlined"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="trailingAmount"
                                    name="trailingAmount"
                                    size="small"
                                    helperText={errors.trailingAmount ? errors.trailingAmount : ""}
                                    error={Boolean(errors.trailingAmount)}
                                    label="Trailing Amount"
                                    value={trailingAmount}
                                    onChange={(e) => onChangeTrailingAmount(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} /></Grid>}
                        </FormSection>

                        <FormSection label={<Typography variant="h6" style={titleStyle}>Validity & Account</Typography>}>
                            <Grid item>
                                <WrappedSelect
                                    disabled={editMode}
                                    variant={editMode ? "standard" : "outlined"}
                                    id="Good"
                                    name="Good"
                                    label="Time In Force"
                                    value={timeInForce === undefined || !isValidTifForVenue(GoodUntil[timeInForce]) ? '' : GoodUntil[timeInForce]}
                                    onChange={(e, c) => onChangeTimeInForce(e)}>
                                    {Object.keys(GoodUntil)
                                        .filter(x => isNaN(Number(x)) && isValidTifForVenue(x))
                                        .map((v, ixx) => <MenuItem key={"tif" + ixx} value={v}>{v}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            {timeInForce === GoodUntil.Time ? <Grid item>
                                <WrappedDateTimePicker
                                    id="gooduntil"
                                    name="gooduntil"
                                    disabled={editMode}
                                    disablePast
                                    helperText={touched.gooduntil ? touched.gooduntil : ""}
                                    error={Boolean(errors.gooduntil)}
                                    label="Good Until (UTC)"
                                    value={goodUntil}
                                    onChange={(d) => onChangeGoodUntil(d)} />
                            </Grid> : null}
                            <Grid item>
                                <WrappedSelect
                                    disabled={editMode}
                                    variant={editMode ? "standard" : "outlined"}
                                    id="Account"
                                    name="Account"
                                    label="Account"
                                    error={account === "Select Account..."}
                                    value={account ?? "Select Account..."}
                                    onChange={(d) => { onChangeAccount(d.target.value) }}>
                                    <MenuItem disabled key={"accnull"} value={"Select Account..."}>{"Select Account..."}</MenuItem>
                                    {(orderVenueObject as Venue)?.capabilities['Accounts']?.split(',')?.filter(a => a !== "All").map((a, ix) =>
                                        <MenuItem key={"acc" + ix} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                        </FormSection>

                        {fixedMeta && <FormSection label={<Typography variant="h6" style={titleStyle}>Meta Data</Typography>}>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="fixedBook"
                                    name="fixedBook"
                                    size="small"
                                    label="Book"
                                    value={fixedMeta.book}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="standard"
                                    classes={{ root: "ListedInstrumentEditorFormField" }}
                                    id="fixedStrategy"
                                    name="fixedStrategy"
                                    size="small"
                                    label="Theme"
                                    value={fixedMeta.strategy}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} />
                            </Grid>
                        </FormSection>}


                        <FormSection label={<Typography variant="h6" style={titleStyle}>Comments</Typography>}>
                            <FieldArray name="comments" validateOnChange={false} render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
                                <React.Fragment><div className="InsMetaDataBox">
                                    {comments && comments.length > 0 ? (
                                        comments.map((c: OrderComment, ix) => (
                                            <div key={ix} className="ListedInstrumentEditorFormMetaDataRow">
                                                <Grid container spacing={1}>
                                                    <Grid item>
                                                        <TextareaAutosize
                                                            //value={c.text} 
                                                            className="ListedInstrumentEditorFormFieldInner"
                                                            style={{ backgroundColor: userStoreInstance.GetTheme().background_color_opaque }}
                                                            //onBlur=({})
                                                            onBlur={(event: React.FormEvent<HTMLTextAreaElement>) => { onCommentChange(event.currentTarget.value, ix) }}
                                                        />
                                                    </Grid>
                                                    <IconButton
                                                        onClick={() => { arrayHelpers.remove(ix); setFieldTouched("comments", true, false) }}
                                                        className="ListedInstrumentEditorFormFieldInner"
                                                        size="large">
                                                        <RemoveOutlined color="inherit" />
                                                    </IconButton>
                                                </Grid>
                                            </div>
                                        ))
                                    ) : null}
                                    <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                                        <Button variant="outlined" onClick={() => arrayHelpers.push({ text: "", created: moment().toISOString(), userId: userStoreInstance.GetUserInfo().userId } as OrderComment)} className="PltfmButtonLite">
                                            <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                                            <ListItemText>Add</ListItemText>
                                        </Button>
                                    </div>
                                </div>
                                </React.Fragment>
                            )} />
                            <Grid item></Grid>
                        </FormSection>


                        {warning && <Grid item container spacing={2} justifyContent="center">
                            <Grid item><Typography variant="h6" color={"red"}>{warning}</Typography></Grid>
                        </Grid>}

                        <Grid item container spacing={2} justifyContent="center">
                            {!editMode && <Grid item><Button
                                className="PltfmButtonLite"
                                type="submit"
                                variant="outlined"
                                disabled={warning || !isValid || canDuplicate || isSubmitting}
                                endIcon={<CheckCircleOutline />}>Create</Button></Grid>}
                            {editMode && <Grid item><Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                disabled={warning || !isValid || canDuplicate || isSubmitting || !anyChangesToSave}
                                onClick={onSaveOrder}
                                endIcon={<SaveAltOutlined />}>Update</Button></Grid>}
                            {!canDuplicate && <Grid item><Button
                                className="PltfmButtonLite"
                                type="reset"
                                variant="outlined"
                                onClick={onClearPressed}
                                endIcon={<UndoOutlined />}>Clear</Button></Grid>}
                            {canDuplicate && <Grid item><Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                onClick={onDuplicatePressed}
                                endIcon={<ControlPointDuplicateOutlined />}>Duplicate</Button></Grid>}
                            {onCancelOrder && <Grid item><Button
                                className="PltfmButtonLite"
                                variant="outlined"
                                onClick={onCancelOrder}
                                endIcon={<CancelOutlined />}>Cancel</Button></Grid>}
                        </Grid>
                        {lastBookedId ? <Grid item container spacing={2} justifyContent="center">
                            <Grid item><Typography variant="subtitle2">Last created id {lastBookedId}</Typography></Grid>
                        </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </CssBaseline>
        </Form>
    );
}
