import React from 'react';
import FBEmitter from 'fbemitter';
import { LimitOrderTypes, StopOrderTypes } from './outrightOrderForm';
import { OrderType } from './orderModels';
import { Grid, TextField, Typography } from '@mui/material';
import { num2String, renderDate } from '../utils/helpers';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import marketDataStoreInstance from '../marketData/marketDataStore';
import { DefaultSetName } from '../globalConstants';
import { NumberFormatCustom } from '../inputs/numberFormatInput';
import { DoneOutlineOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { LeggedOrderTypes } from './leggedSpreadOrderForm';

type OrderEditorState = {
    lastPrice: number;
    bid: number;
    ask: number;
    nominal: number;
    lastPriceStamp: Date;
    fetchedFixingIds: Set<number>;
    lastPriceLeg2: number;
    nominalLeg2: number;
    lastPriceStampLeg2: Date;
}

export interface OrderPricePanelProps {
    leg1InsId: number,
    leg2InsId: number,
    orderType: OrderType,
    size: number,
    leg2SizeRatio: number,
    stopLevel: number,
    limitLevel: number,
    isBuyOrder: boolean,
    nominal: number,
    isNativeSpread: boolean;
    nav: number;
}

export class OrderPricePanel extends React.Component<OrderPricePanelProps, OrderEditorState>{
    eventSubscriptionMarketDataTicks: FBEmitter.EventSubscription | undefined;
    eventSubscriptionMarketDataFixings: FBEmitter.EventSubscription | undefined;

    constructor(props: OrderPricePanelProps) {
        super(props);
        this.state = {
            fetchedFixingIds: new Set<number>(),
            lastPrice: undefined,
            lastPriceStamp: undefined,
            nominal: props.nominal,
            lastPriceLeg2: undefined,
            lastPriceStampLeg2: undefined,
            nominalLeg2: undefined,
            bid: undefined,
            ask: undefined,
        };
        this.onTick = this.onTick.bind(this);
        this.onFixingsUpdate = this.onFixingsUpdate.bind(this);

    }

    componentDidMount() {
        this.eventSubscriptionMarketDataTicks = marketDataLiveStoreInstance.addChangeListener(this.onTick);
        this.eventSubscriptionMarketDataFixings = marketDataStoreInstance.addChangeListener(this.onFixingsUpdate);
        this.onTick();
        this.onFixingsUpdate();
    }

    componentWillUnmount(): void {
        if (this.eventSubscriptionMarketDataTicks?.remove) {
            this.eventSubscriptionMarketDataTicks.remove();
        }
        if (this.eventSubscriptionMarketDataFixings?.remove) {
            this.eventSubscriptionMarketDataFixings.remove();
        }
    }

    componentDidUpdate(prevProps: Readonly<OrderPricePanelProps>, prevState: Readonly<OrderEditorState>, snapshot?: any): void {
        if (prevProps.leg1InsId !== this.props.leg1InsId || prevProps.leg2InsId !== this.props.leg2InsId || prevProps.orderType !== this.props.orderType) {
            this.onFixingsUpdate();
            this.onTick();
        }
        if (!this.props.isNativeSpread && this.props.orderType === OrderType.Limit &&
            (prevProps.limitLevel !== this.props.limitLevel || prevProps.size !== this.props.size)) {
            var ins = listedInstrumentStoreInstance.getInstrumentById(this.props.leg1InsId);
            var nominal = Math.round(this.props.limitLevel * (ins?.multiplier ?? 1.0) * this.props.size);
            this.setState({ nominal });
        }
    }

    onTick() {
        const { lastPrice, lastPriceLeg2 } = this.state;
        if (this.props.leg1InsId) {
            var tick = marketDataLiveStoreInstance.getTick(this.props.leg1InsId);
            var bidAsk = marketDataLiveStoreInstance.getBidAsk(this.props.leg1InsId);
            if (tick && tick !== lastPrice) {
                var stamp = marketDataLiveStoreInstance.getTickTime(Number(this.props.leg1InsId));
                this.setState({ lastPrice: tick, lastPriceStamp: stamp, bid: bidAsk?.bid, ask: bidAsk?.ask });

                if (this.props.orderType === OrderType.Market || this.props.orderType === OrderType.LeggedSpreadMarket) {
                    var ins = listedInstrumentStoreInstance.getInstrumentById(this.props.leg1InsId);
                    var nominal = Math.round(tick * (ins?.multiplier ?? 1.0) * this.props.size);
                    this.setState({ nominal });
                }
            }
        }
        if (this.props.leg2InsId) {
            var tick2 = marketDataLiveStoreInstance.getTick(this.props.leg2InsId);
            if (tick2 && tick2 !== lastPriceLeg2) {
                var stamp2 = marketDataLiveStoreInstance.getTickTime(Number(this.props.leg2InsId));
                this.setState({ lastPriceLeg2: tick2, lastPriceStampLeg2: stamp2 })

                if (this.props.orderType === OrderType.Market || this.props.orderType === OrderType.LeggedSpreadMarket) {
                    var ins2 = listedInstrumentStoreInstance.getInstrumentById(this.props.leg2InsId);
                    var nominalLeg2 = Math.round(-tick2 * (ins2?.multiplier ?? 1.0) * this.props.size * this.props.leg2SizeRatio);
                    this.setState({ nominalLeg2 });
                }
            }
        }
    }

    onFixingsUpdate() {
        const { lastPriceStamp, lastPrice, lastPriceStampLeg2, lastPriceLeg2 } = this.state;
        if (this.props.leg1InsId) {
            var fixings = marketDataStoreInstance.getFixings(DefaultSetName, this.props.leg1InsId, true);
            if (Boolean(fixings) && fixings.length > 0) {
                var fixing = fixings[fixings.length - 1];
                if (!lastPriceStamp || (lastPriceStamp <= new Date(fixing.date) && lastPrice !== fixing.value)) {
                    this.setState({ lastPrice: fixing.value, lastPriceStamp: new Date(fixing.date) });
                }
                const isMultiLeg = LeggedOrderTypes.includes(this.props.orderType) || this.props.isNativeSpread;
                if (!isMultiLeg) {
                    var ins = listedInstrumentStoreInstance.getInstrumentById(this.props.leg1InsId);
                    var nominal = LimitOrderTypes.includes(this.props.orderType) ?
                        this.props.limitLevel * this.props.size * ins?.multiplier :
                        lastPrice * this.props.size * ins?.multiplier;
                    if (nominal !== this.state.nominal) {
                        this.setState({ nominal });
                    }
                }
                else {
                    this.setState({ nominal: undefined });
                }
            }
        }
        if (this.props.leg2InsId) {
            var fixings2 = marketDataStoreInstance.getFixings(DefaultSetName, this.props.leg2InsId, true);
            if (Boolean(fixings2) && fixings2.length > 0) {
                var fixing2 = fixings2[fixings2.length - 1];
                if (!lastPriceStampLeg2 || (lastPriceStampLeg2 <= new Date(fixing2.date) && lastPriceLeg2 !== fixing2.value)) {
                    this.setState({ lastPriceLeg2: fixing2.value, lastPriceStampLeg2: new Date(fixing2.date) });
                }
            }
        }
    }


    render() {
        const { lastPrice, lastPriceStamp, nominal, lastPriceLeg2, lastPriceStampLeg2, bid, ask } = this.state;
        const { leg1InsId, limitLevel, stopLevel, orderType, isBuyOrder, nav } = this.props;
        const isStopValid = !Boolean(lastPrice) || (isBuyOrder && stopLevel > lastPrice) || (!isBuyOrder && stopLevel < lastPrice);
        const isLimitValid = orderType === OrderType.StopLimit ?
            !Boolean(lastPrice) || (isBuyOrder && limitLevel > stopLevel) || (!isBuyOrder && limitLevel < stopLevel) :
            !Boolean(lastPrice) || (isBuyOrder && limitLevel < lastPrice) || (!isBuyOrder && limitLevel > lastPrice);

        const ins = leg1InsId ? listedInstrumentStoreInstance.getInstrumentById(leg1InsId) : undefined;
        const lo = LeggedOrderTypes;
        const isMultiLeg = lo.includes(orderType) || this.props.isNativeSpread;
        const nominalToUse = nominal ?? this.props.nominal;
        const nominalInBase = nominalToUse / (marketDataLiveStoreInstance.getFxRate(ins?.ccy) ?? 1.0)
        const nominalPctNav = nominalInBase / nav * 100;
        const spreadPrice = isMultiLeg ? lastPrice - lastPriceLeg2 : undefined;
        const evalPrice = spreadPrice ?? (isBuyOrder ? ask : bid) ?? lastPrice;

        return (<React.Fragment> {Boolean(lastPrice) && Boolean(lastPriceStamp) && <Grid item container spacing={1}>
            <Grid item width="100%" marginBottom="5px"><Typography variant="h6">{"Instrument Price"}</Typography></Grid>
            {bid && <Grid item><TextField
                variant="outlined"
                size="small"
                style={{ width: "120px" }}
                classes={{ root: "ListedInstrumentEditorFormField" }}
                id="bid"
                name="bid"
                label={isMultiLeg ? "Leg Bid" : "Bid"}
                value={bid}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>}
            {ask && <Grid item><TextField
                variant="outlined"
                size="small"
                style={{ width: "120px" }}
                classes={{ root: "ListedInstrumentEditorFormField" }}
                id="ask"
                name="ask"
                label={isMultiLeg ? "Leg 1 Ask" : "Ask"}
                value={ask}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>}
            <Grid item><TextField
                variant="outlined"
                size="small"
                style={{ width: "120px" }}
                classes={{ root: "ListedInstrumentEditorFormField" }}
                id="lastPrice"
                name="lastPrice"
                label={isMultiLeg ? "Leg 1 Mid" : "Mid Price"}
                value={lastPrice}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
            <Grid item><TextField
                variant="outlined"
                size="small"
                style={{ width: "120px" }}
                classes={{ root: "ListedInstrumentEditorFormField" }}
                id="lastPriceStamp"
                name="lastPriceStamp"
                label={isMultiLeg ? "Leg 1 Price" : "Timestamp"}
                value={renderDate(new Date(lastPriceStamp))}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
            {isMultiLeg && Boolean(lastPriceLeg2) && Boolean(lastPriceStampLeg2) && <Grid item container spacing={1}>
                <Grid item><TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "120px" }}
                    classes={{ root: "ListedInstrumentEditorFormField" }}
                    id="lastPrice2"
                    name="lastPrice"
                    label="Leg 2 Price"
                    value={lastPriceLeg2}
                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
                <Grid item><TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "120px" }}
                    classes={{ root: "ListedInstrumentEditorFormField" }}
                    id="lastPriceStamp2"
                    name="lastPriceStamp"
                    label="Leg 2 Stamp"
                    value={renderDate(new Date(lastPriceStampLeg2))}
                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
            </Grid>}
            {isMultiLeg && Boolean(spreadPrice) && <Grid item container spacing={1}>
                <Grid item><TextField
                    variant="outlined"
                    size="small"
                    style={{ width: "120px" }}
                    classes={{ root: "ListedInstrumentEditorFormField" }}
                    id="spreadPrice"
                    name="spreadPrice"
                    label="Implied Spread"
                    value={num2String(spreadPrice)}
                    InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>
            </Grid>}
            {Boolean(stopLevel) && StopOrderTypes.includes(orderType) && Boolean(lastPrice) && <Grid item><TextField
                variant="outlined"
                size="small"
                style={{ width: "87px" }}
                classes={{ root: "ListedInstrumentEditorFormField" }}
                error={!isStopValid}
                id="stopPct"
                name="stopPct"
                label="Stop (%)"
                value={`${((stopLevel / evalPrice - 1) * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })} %`}
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>}
            {Boolean(stopLevel) && StopOrderTypes.includes(orderType) && Boolean(lastPrice) &&
                <Grid item>{isStopValid ? <DoneOutlineOutlined style={{ color: 'green', paddingTop: "5px" }} /> : <WarningAmberOutlined style={{ color: 'orange', paddingTop: "5px" }} />}</Grid>}
            {Boolean(limitLevel) && LimitOrderTypes.includes(orderType) && Boolean(lastPrice) && <Grid item><TextField
                variant="outlined"
                size="small"
                style={{ width: "87px" }}
                classes={{ root: "ListedInstrumentEditorFormField" }}
                error={!isLimitValid}
                id="limitPct"
                name="limitPct"
                label="Limit (%)"
                value={`${((limitLevel / evalPrice - 1) * 100).toLocaleString(undefined, { maximumFractionDigits: 2 })} %`}
                InputLabelProps={{ shrink: true }}
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" }, readOnly: true }} /></Grid>}
            {Boolean(limitLevel) && LimitOrderTypes.includes(orderType) && Boolean(lastPrice) &&
                <Grid item>{isLimitValid ? <DoneOutlineOutlined style={{ color: 'green', paddingTop: "5px" }} /> : <WarningAmberOutlined style={{ color: 'orange', paddingTop: "5px" }} />}</Grid>}
            <Grid item container spacing={1}>
                <Grid item>
                    <TextField
                        variant="outlined"
                        classes={{ root: "ListedInstrumentEditorFormField" }}
                        style={{ width: "140px" }}
                        id="Nominal"
                        name="Nominal"
                        size="small"
                        label={`Nominal (${ins?.ccy})`}
                        value={nominalToUse ?? ""}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            classes: { input: "ListedInstrumentEditorFormFieldInner" },
                            //endAdornment: <Chip variant="outlined" label={ins?.ccy} />,
                            readOnly: true
                        }} />
                </Grid>
                {isNaN(nominalPctNav) ? null : <Grid item>
                    <TextField
                        variant="outlined"
                        classes={{ root: "ListedInstrumentEditorFormField" }}
                        style={{ width: "90px" }}
                        id="NominalPct"
                        name="NominalPct"
                        size="small"
                        label="% NAV"
                        value={nominalPctNav.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "%"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            classes: { input: "ListedInstrumentEditorFormFieldInner" },
                            readOnly: true
                        }}
                    />
                </Grid>}
            </Grid>
        </Grid>}
        </React.Fragment>
        )
    }
}
