import { GridDensity } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { v4 } from 'uuid';
import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore';
import { PmsInstallation, RfqInstallation } from '../globalConstants';
import userStoreInstance from '../user/userStore';
import { AddLayoutObjectEvent, AddTabEvent, DeleteTabEvent, LoadLayoutEvent, MenuCollapseEvent, PreferenceUpdateEvent, SetMenuCollapseFn, UpdateLayoutObjectEvent } from './layoutActions';
import { LayoutComponent, LayoutTab, MainLayout } from './layoutModels';
import { newTabItems } from './newTabItems';

class LayoutStore extends FluxStore<MainLayout>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            tabs: [],
            currentTabId: 0,
            preferences: {},
            menuCollapsed: {} as {[id:number] : boolean}
        }))
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadLayoutEvent) {
            this.state = action.payload;

            var tabsToRemove = new Array<number>();
            this.state.tabs.forEach(tab => {
                var tabInfo = newTabItems.filter(t => t.actionName === tab.name)[0];
                var pmsAllowed = tabInfo ? tabInfo.pmsInstall : true;
                var rfqAllowed = tabInfo ? tabInfo.rfqInstall : true;
                if (!((PmsInstallation && pmsAllowed) || (RfqInstallation && rfqAllowed))) {
                    tabsToRemove.push(tab.tabId);
                }
            });

            this.state.tabs = this.state.tabs.filter(t => !tabsToRemove.includes(t.tabId));

            if (this.state.preferences == null)
                this.state.preferences = {};

            if (this.state.preferences !== null) {
                if (this.state.preferences["Theme"])
                    userStoreInstance.SetTheme(this.state.preferences["Theme"]);
                if (this.state.preferences["DateFormat"])
                    userStoreInstance.SetDateFormat(this.state.preferences["DateFormat"]);
                if (this.state.preferences["GridDensity"])
                    userStoreInstance.SetGridDensity(this.state.preferences["GridDensity"] as GridDensity);
            }
            this.state.currentTabId = this.state.tabs[0]?.tabId;
            this.state.menuCollapsed = {} as {[id:number] : boolean}
            this.emitChange();
        }
        else if (action instanceof AddLayoutObjectEvent) {
            let tabIndex: number;
            if (action.payload.tabId === undefined) {
                if (this.state.tabs[tabIndex])
                    tabIndex = this.state.currentTabId ?? 0;
                else
                    tabIndex = 0;
            }
            else {
                tabIndex = this.state.tabs.findIndex(t => t.tabId === action.payload.tabId);
            }
            action.payload.component.tabId = tabIndex;
            if (tabIndex !== -1) {
                let tab = this.state.tabs[tabIndex];
                if (!tab.components) {
                    tab.components = [];
                }
                tab.components.push(action.payload.component);
                this.emitChange();
                //SaveLayout(this.state);
            }
        }
        else if (action instanceof UpdateLayoutObjectEvent){
            if(action.payload.tabId !== undefined && action.payload.component.key){
                var tab = this.state.tabs[action.payload.tabId];
                var component = tab.components.filter(c=>c.key===action.payload.component.key)[0];
                if(component){
                    component.props = action.payload.component.props;
                }
            }
        }
        else if (action instanceof PreferenceUpdateEvent) {
            this.state.preferences[action.payload[0]] = action.payload[1];
            this.emitChange();
        }
        else if (action instanceof AddTabEvent) {
            var elementKey = action.payload.tabName;
            var nextTabId = _.max(this.state.tabs.map(t => t.tabId)) + 1;
            let component = {
                key: v4(),
                type: elementKey
            } as LayoutComponent;

            let t = {
                canDelete: true,
                name: elementKey,
                tabId: nextTabId,
                isGridTab: action.payload.isFlexibleTab,
                components: [component],
            } as LayoutTab;
            this.state.tabs.push(t);
            this.state.currentTabId = nextTabId;
            this.emitChange();
        }
        else if (action instanceof DeleteTabEvent) {
            var tabId = action.payload;
            this.state.tabs = this.state.tabs.filter(x => x.tabId !== tabId);
            if (this.state.currentTabId === tabId)
                this.state.currentTabId = 0;
            this.emitChange();
        }
        else if (action instanceof MenuCollapseEvent){
            this.state.menuCollapsed[action.payload.tabId] = action.payload.collapsed;
            this.emitChange();
        }
    }

    public SetCurrentTabId(currentTabId: number) {
        this.state.currentTabId = currentTabId;
    }

    public GetCurrentTab() {
        return this.state.tabs.filter(t => t.tabId === this.state.currentTabId)[0];
    }

    public GetLayout() {
        return this.state;
    }

    public GetPreference(key: string) {
        return this.state.preferences[key];
    }

    public GetMenuCollapse(){
        return this.state.menuCollapsed[this.state.currentTabId]??false;
    }

    public SetMenuCollapse(collapsed: boolean){
        SetMenuCollapseFn(this.state.currentTabId,collapsed);
    }

    
}

const layoutStoreInstance = new LayoutStore(AppDispatcher);
export default layoutStoreInstance;