import { createTheme } from "@mui/material";
import userStoreInstance from "../user/userStore";
export const GridBreak = () => <div style={{ width: "100%" }} />


export const getFormTheme = () => {
  return createTheme({
    palette: {
      primary: { main: userStoreInstance.GetTheme().border_color, contrastText: userStoreInstance.GetTheme().background_color },
      secondary: { main: userStoreInstance.GetTheme().contrast_background_color, contrastText: userStoreInstance.GetTheme().color },
      warning: { main: userStoreInstance.GetTheme().border_color },
      error: { main: "rgb(255,0,0)" },
      text: { primary: userStoreInstance.GetTheme().color, secondary: userStoreInstance.GetTheme().contrastBorderColorLight },
      action: { active: userStoreInstance.GetTheme().color },
      background: { paper: userStoreInstance.GetTheme().background_color },

    },
    typography: {
      allVariants: {
        fontFamily: userStoreInstance.GetTheme().font_family,
        color: userStoreInstance.GetTheme().color
      }
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderColor: userStoreInstance.GetTheme().border_color + " !important",
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().border_color,
            "&$focused": {
              color: userStoreInstance.GetTheme().color
            }
          }
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
            borderColor: userStoreInstance.GetTheme().border_color,
            border: "1px solid"
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            backgroundColor: userStoreInstance.GetTheme().background_color_opaque + " !important"
          }
        }
      },
      // MuiList: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: userStoreInstance.GetTheme().background_color_opaque + " !important"
      //     }
      //   }
      // },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().border_color,
          },
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().border_color,
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            },
            "&:disabled": {
              color: userStoreInstance.GetTheme().border_color,
            }
          },
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            backgroundColor: userStoreInstance.GetTheme().background_color,
            margin: "2px",
            borderBottom: "2px solid",
            borderColor: userStoreInstance.GetTheme().background_color,
            "&:hover": {
              borderColor: userStoreInstance.GetTheme().border_color,
              //border: "1px solid",
              backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
              opacity: 1.0,
              margin: "2px"
            },
          },

        }
      },
      MuiSelect: {
        styleOverrides: {
          // root: {
          //   color: userStoreInstance.GetTheme().color,
          //   backgroundColor: userStoreInstance.GetTheme().background_color,
          //   borderColor: userStoreInstance.GetTheme().border_color,
          //   border: "1px solid"
          // },

          outlined: {
            color: userStoreInstance.GetTheme().color,
            borderColor: userStoreInstance.GetTheme().button_border_A,
          },
          nativeInput: {
            color: userStoreInstance.GetTheme().color,
            borderColor: userStoreInstance.GetTheme().button_border_A,
          },
          select: {
            borderColor: userStoreInstance.GetTheme().button_border_A,

            //backgroundColor: userStoreInstance.GetTheme().background_color
          }
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().border_color,
            borderColor: userStoreInstance.GetTheme().border_color + " !important",
            "&$focused": {
              color: userStoreInstance.GetTheme().color,
            },
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            },
          },
          notchedOutline: {
            borderColor: userStoreInstance.GetTheme().border_color,
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            "&$focused": {
              color: userStoreInstance.GetTheme().color,
            },
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            }
          },
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            backgroundColor: userStoreInstance.GetTheme().background_color,
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().border_color,
            "&$checked": {
              color: userStoreInstance.GetTheme().color,
              fill: userStoreInstance.GetTheme().color,
              borderColor: userStoreInstance.GetTheme().border_color,
            },
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            },
          },
        }
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: {
            color: userStoreInstance.GetTheme().color,
            "&.Mui-checked": {
              color: "green",
            }

          },
          colorSecondary: {
            color: userStoreInstance.GetTheme().color,
            "&.Mui-checked": {
              color: "red",
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            backgroundColor: userStoreInstance.GetTheme().background_color,
            borderColor: userStoreInstance.GetTheme().border_color,
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            }
          },
          outlined: {
            borderColor: userStoreInstance.GetTheme().border_color,
          },
          containedSizeSmall: {
            borderColor: userStoreInstance.GetTheme().border_color,
          },
        },
        defaultProps: {
          variant: "outlined"
        }
      },
      MuiTouchRipple: {
        styleOverrides: {
          root: {
            borderColor: userStoreInstance.GetTheme().border_color,
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "&$checked": {
              "backgroundColor": "rgb(26, 62, 91)"
            }
          },
          thumb: {
            backgroundColor: userStoreInstance.GetTheme().border_color,
          },
          track: {
            color: userStoreInstance.GetTheme().border_color,
            backgroundColor: userStoreInstance.GetTheme().border_color,
            borderColor: userStoreInstance.GetTheme().border_color,

          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color + " !important",
            borderColor: userStoreInstance.GetTheme().color + " !important",
          },
          marginDense: {
            position: "relative",
            top: "-8px"
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
          },
          overline: {
            color: userStoreInstance.GetTheme().border_color + " !important",
          },
          subtitle1: {
            ":disabled": {
              color: userStoreInstance.GetTheme().contrastBorderColorLight + " !important",
            }
          },
          caption: {
            color: userStoreInstance.GetTheme().border_color + " !important",
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            fontWeight: 100,
            backgroundColor: userStoreInstance.GetTheme().background_color,
            outline: "none",
            fontFamily: userStoreInstance.GetTheme().font_family,
            "&:hover": {
              fontWeight: "bolder"
            },
            "&.Mui-selected": {
              color: userStoreInstance.GetTheme().color,
              fontWeight: "bolder",
              backgroundColor: userStoreInstance.GetTheme().background_color_opaque,
              outline: "none",
              fontFamily: userStoreInstance.GetTheme().font_family,
            }
          },
        }
      },
      MuiTabs: {
        styleOverrides:{
          indicator:{
            backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
          }
        }
      }
      // MuiPopover: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: userStoreInstance.GetTheme().background_color_opaque + " !important",
      //     }
      //   }
      // },
    }
  });
}

export const getTableTheme = () => {
  return createTheme({
    palette: {
      primary: { main: userStoreInstance.GetTheme().color }
    },
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            backgroundColor: userStoreInstance.GetTheme().background_color,
          },
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            fontFamily: userStoreInstance.GetTheme().font_family,
            fontWeight: "bold",
            textAlign: "center"
          },
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            fontFamily: userStoreInstance.GetTheme().font_family,
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: userStoreInstance.GetTheme().color,
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            }

          },
          body: {
            color: userStoreInstance.GetTheme().color,
            fontFamily: userStoreInstance.GetTheme().font_family,
            borderColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            "&:hover": {
              backgroundColor: userStoreInstance.GetTheme().contrastBorderColorLight,
            }
          }
        }
      },
    }
  });
}