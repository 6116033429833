import { Button, Typography } from '@mui/material';
import { ShowChartOutlined, TrendingDownOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import { EngineeringMarketDataTab } from './engineeringMarketDataTab';
import { EngineeringVaRTab } from './engineeringVaRTab';
import { EngineeringIBTokenRequestTab } from './engineeringIBTokenRequestTab';
import { EngineeringEoDReportTab } from './engineeringEoDReportTab';

interface EngineeringTabState {
    currentMode: EngineeringTabMode,
}

enum EngineeringTabMode {
    None,
    MarketData,
    VaR,
    ApiKeys,
    EoDReport
}

export interface EngineeringTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class EngineeringTab extends React.Component<EngineeringTabProps, EngineeringTabState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: EngineeringTabProps) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: EngineeringTabMode = tabModeStr ? EngineeringTabMode[tabModeStr] : EngineeringTabMode.None;
        this.state = {
            currentMode: tabMode
        };;
    }

    componentDidMount() {
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case EngineeringTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case EngineeringTabMode.MarketData:
                return <EngineeringMarketDataTab {...this.props} />
            case EngineeringTabMode.VaR:
                return <EngineeringVaRTab {...this.props} />
            case EngineeringTabMode.ApiKeys:
                return <EngineeringIBTokenRequestTab {...this.props} />
            case EngineeringTabMode.EoDReport:
                return <EngineeringEoDReportTab {...this.props} />
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: EngineeringTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: EngineeringTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    onClick(mode: EngineeringTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: EngineeringTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        return (<div style={{ height: "Calc(100vh - 95px)", position: "absolute", width: "100vw", right: "0px" }}>
            <table style={{ height: "Calc(100vh - 95px)", width: "100vw", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: "2px solid darkgrey", verticalAlign: "top", paddingRight: "5px", height: "Calc(100vh - 95px)", width: "170px" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ height: "20px" }} />
                                {this.renderButton(EngineeringTabMode.MarketData, <ShowChartOutlined />, "Market Data")}
                                <div style={{ height: "20px" }} />
                                {this.renderButton(EngineeringTabMode.VaR, <TrendingDownOutlined />, "VaR")}
                                <div style={{ height: "20px" }} />
                                {this.renderButton(EngineeringTabMode.ApiKeys, <TrendingDownOutlined />, "Api Keys")}
                                <div style={{ height: "20px" }} />
                                {this.renderButton(EngineeringTabMode.EoDReport, <TrendingDownOutlined />, "EoD Report")}
                            </div>
                        </td>
                        <td style={{ verticalAlign: "top", width: "calc(100vw - 170px)", height: "Calc(100vh - 95px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}
