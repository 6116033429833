import {
    createTheme,
    IconButton,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { CloseOutlined, MenuOutlined } from '@mui/icons-material';
import React, { Component } from 'react';
import rfqConnection from '../../rfq/rfqConnection';
import userStoreInstance from '../../user/userStore';

export interface WidgetComponentProps {
    onClose: (() => void);
    onChangeProp: (key: string, value: any) => void;
    widgetType: string;
    widgetKey: string;
    width: number,
    height: number,
    title?: any,
    titleIsActive?: boolean
}

interface WidgetComponentState {
    hover: boolean,
}

export const getCloseButtonTheme = () => {
    return createTheme({
        palette: {
            primary: { main: userStoreInstance.GetTheme().background_color, contrastText: userStoreInstance.GetTheme().background_color },
            secondary: { main: userStoreInstance.GetTheme().color, contrastText: userStoreInstance.GetTheme().color },
            text: { secondary: userStoreInstance.GetTheme().color },
        },
    });
}



class WidgetComponent extends Component<WidgetComponentProps, WidgetComponentState>{
    constructor(props: WidgetComponentProps) {
        super(props);
        this.state = {
            hover: false,
        }
        this.onClickClose = this.onClickClose.bind(this);
    }

    componentDidMount() {

    }
    componentWillUnmount() {

    }

    async onClickClose() {
        this.cleanUpOnClose();
        this.props.onClose();
    }

    async cleanUpOnClose() {
        let rfqId = 0;
        React.Children.forEach(this.props.children, child => {
            if (React.isValidElement(child)) {
                if (child.props.widgetType && child.props.widgetType === "PricingPanel" && child.props.rfqResponse) {
                    if (child.props.rfqResponse) {
                        rfqId = child.props.rfqResponse.rfqId;
                    }
                }
            }
        });
        if (rfqId !== 0)
            await rfqConnection.cancelRfq(rfqId)
    }
    private renderCloseButton = (invert: boolean) => {
        return this.state.hover ?
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getCloseButtonTheme()} >
                    <IconButton color={invert ? "inherit" : "secondary"} size="small" onClick={this.onClickClose}><CloseOutlined /></IconButton>
                </ThemeProvider>
            </StyledEngineProvider>
            : null;
    }

    private renderDragHandle = (invert: boolean) => { return this.state.hover ? <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getCloseButtonTheme()} ><MenuOutlined color={invert ? "inherit" : "secondary"} /></ThemeProvider>
    </StyledEngineProvider> : null; }

    render() {
        const childrenWithProps = React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { widgetType: this.props.widgetType });
            }
            return child;
        });
        if (this.props.titleIsActive) {
            return (
                <div className="WidgetBox" onMouseEnter={() => { this.setState({ hover: true }) }} onMouseLeave={() => { this.setState({ hover: false }) }}>
                    <div className="WidgetBoxToolbar">
                        <div className="WidgetBoxDragTarget DragMe">{this.renderDragHandle(this.props.title !== undefined)}</div>
                        {this.props.title ? <div className="WidgetBoxTitle"><div className="WidgetBoxTitleText">{this.props.title}</div></div> : null}
                        <div className="WidgetBoxCloseButton">{this.renderCloseButton(this.props.title !== undefined)}</div>
                    </div>
                    {childrenWithProps}
                </div>);
        }
        else {
            return (
                <div className="WidgetBox" onMouseEnter={() => { this.setState({ hover: true }) }} onMouseLeave={() => { this.setState({ hover: false }) }}>
                    <div className="WidgetBoxToolbar">
                        <div className="WidgetBoxDragTarget DragMe">{this.renderDragHandle(this.props.title !== undefined)}</div>
                        {this.props.title ? <div className="WidgetBoxTitle DragMe"><div className="WidgetBoxTitleText">{this.props.title}</div></div> : null}
                        <div className="WidgetBoxCloseButton">{this.renderCloseButton(this.props.title !== undefined)}</div>
                    </div>
                    {childrenWithProps}
                </div>);
        }
    }
}
export default WidgetComponent;