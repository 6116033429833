import { binarySearch } from "../../utils/math";
import { IInterpolator1D } from "../iInterpolator1d";
import { Interpolator1DType } from "../interpolator1DType";

export default class LinearInVarianceInterpolator implements IInterpolator1D {

    private _x: number[];
    private _y: number[];
    private _rawY: number[];
    private _slope: number[];
    private _minX: number;
    private _maxX: number;

    Type: Interpolator1DType = Interpolator1DType.linearInVariance

    MinX() { return this._minX };
    MaxX() { return this._maxX };

    constructor(x: number[], y: number[], slope?: number[]) {
        this._x = x;
        this._rawY = y;
        this._y = x.map((v, ix) => v * y[ix] * y[ix]);
        this._minX = x[0];
        this._maxX = x[x.length - 1];

        if (slope)
            this._slope = slope;
        else
            this.calculateSlope();
    }

    findFloorPoint(t: number) {
        var index = binarySearch(this._x, t);
        if (index < 0) {
            index = ~index - 1;
        }

        return Math.min(Math.max(index, 0), this._x.length - 2);
    }

    calculateSlope() {
        this._slope = new Array<number>(this._x.length - 1);
        for (var i = 0; i < this._slope.length; i++) {
            this._slope[i] = (this._y[i + 1] - this._y[i]) / (this._x[i + 1] - this._x[i]);
        }
    }

    public Interpolate(t: number) {
        if (t <= 0)
            return 0;
        else if (t <= this._minX) {
            return Math.sqrt(this._y[0] / this._x[0]); //extrapolate flat in vol
        }
        else if (t >= this._maxX) {
            return Math.sqrt(this._y[this._y.length - 1] / this._x[this._y.length - 1]);
        }
        else {
            var k = this.findFloorPoint(t);
            var variance = this._y[k] + (t - this._x[k]) * this._slope[k];
            var vol = Math.sqrt(variance / t);
            return vol;
        }
    }

    public FirstDerivative(t: number) {
        if (t <= this._minX || t >= this._maxX)
        {
            return 0;
        }
        else
        {
            var k = this.findFloorPoint(t);
            return 0.5 / this.Interpolate(t) * (this._x[k] * this._slope[k] - this._y[k]) / (t * t);
        }
    }

    public SecondDerivative(x: number): number {
        if (x <=  this._minX || x >=  this._maxX)
        {
            return 0;
        }
        else
        {
            var k =  this.findFloorPoint(x);
            var y =  this.Interpolate(x);
            var f = 0.5 / y;
            var u = y * y;
            var g = ( this._x[k] *  this._slope[k] -  this._y[k]) / (x * x);
            var df = -Math.pow(u, -1.5) / 4.0 * g;
            var dg = -2.0 * g / x;

            var d2ydx = df * g + dg * f;
            return d2ydx;
        }
    }
}