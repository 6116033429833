import { TypedEvent, AppDispatcher } from '../../dispatcher/appDispatcher';
import { UpdateLoadingStatus } from '../../loading/loadingActions';
import { LoadingStatus } from '../../loading/loadingModels';
import { get, put } from '../../utils/httpUtils';
import { MatronFatFingerRecord, MatronTradingDefaults } from './matronSettingsModels';

export class NewFatFingerControlsEvent extends TypedEvent<MatronFatFingerRecord[]> { }
export class NewTradingDefaultsEvent extends TypedEvent<MatronTradingDefaults> { }

export async function LoadFatFingerControls() {
    var controls = await GetFatFingerControls();
    await UpdateLoadingStatus("Trading", controls ? LoadingStatus.Completed : LoadingStatus.Failed);
}

export async function GetFatFingerControls() : Promise<MatronFatFingerRecord[]> {
    const controls = await get<MatronFatFingerRecord[]>(`Matron`);
    if (controls?.payload) {
        AppDispatcher.dispatch(new NewFatFingerControlsEvent(controls.payload));
        return controls?.payload;
    }
}

export async function SetFatFingerControls(records: MatronFatFingerRecord[]) {
    const success = await put<MatronFatFingerRecord[]>(`Matron`, records);
    if (success?.payload) {
        AppDispatcher.dispatch(new NewFatFingerControlsEvent(records));
    }
}

export async function GetTradingDefaults() : Promise<MatronTradingDefaults> {
    const defaults = await get<MatronTradingDefaults>(`Matron/Defaults`);
    if (defaults?.payload) {
        AppDispatcher.dispatch(new NewTradingDefaultsEvent(defaults.payload));
        return defaults?.payload;
    }
}

export async function SetTradingDefaults(defaults: MatronTradingDefaults) {
    const success = await put<MatronTradingDefaults>(`Matron/Defaults`, defaults);
    if (success?.payload) {
        AppDispatcher.dispatch(new NewTradingDefaultsEvent(defaults));
    }
}