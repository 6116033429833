import React from 'react'
import FBEmitter from 'fbemitter';
import { ConnectionStatus } from '../connections/connectionStatusModel';
import { ConnectionStats } from './pingModels';
import userSessionConnection from './userSessionConnection';
import connectionStatsStoreInstance from './connectionStatsStore';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import moment from 'moment';
import { Button, CssBaseline, Divider, Drawer, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CheckCircleOutline, ErrorOutline, FlashOnOutlined, WarningOutlined } from '@mui/icons-material';
import userStoreInstance from '../user/userStore';
import acceleratorStoreInstance from '../accelerator/acceleratorStore';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../inputs/formCommon';

interface ChartPoint {
    x: number,
    y: number
}
export type SessionStatusWidgetProps = {
    userName: string;
}

type SessionStatusWidgetState = {
    sessionStatus: ConnectionStatus;
    acceleratorSessionStatus: ConnectionStatus;
    stats: ConnectionStats;
    drawerOpen: boolean;
}

class SessionStatusWidget extends React.Component<SessionStatusWidgetProps, SessionStatusWidgetState>{
    eventSubscriptionStats: FBEmitter.EventSubscription | undefined;
    eventSubscriptionAccelerator: FBEmitter.EventSubscription | undefined;
    constructor(props: SessionStatusWidgetProps) {
        super(props);
        this.state = {
            sessionStatus: ConnectionStatus.Disconnected,
            acceleratorSessionStatus: ConnectionStatus.Disconnected,
            stats: {} as ConnectionStats,
            drawerOpen: false
        };
        this.onNewStats = this.onNewStats.bind(this);
        this.onCloseDrawer = this.onCloseDrawer.bind(this);
        this.onOpenDrawer = this.onOpenDrawer.bind(this);
        this.onAccUpdate = this.onAccUpdate.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionStats = connectionStatsStoreInstance.addChangeListener(this.onNewStats);
        this.eventSubscriptionAccelerator = acceleratorStoreInstance.addChangeListener(this.onAccUpdate);
        var sessionStatus = userSessionConnection.status;
        userSessionConnection.getStats();
        this.setState({ sessionStatus });
    }

    componentWillUnmount() {
        if (this.eventSubscriptionStats) {
            this.eventSubscriptionStats.remove();
        }
        if (this.eventSubscriptionAccelerator) {
            this.eventSubscriptionAccelerator.remove();
        }
    }

    onNewStats() {
        var stats = connectionStatsStoreInstance.GetStats();
        var sessionStatus = connectionStatsStoreInstance.GetStatus();
        this.setState({ stats, sessionStatus });
    }

    onAccUpdate() {
        var sessionStatus = acceleratorStoreInstance.getConnectionStatus();
        if (this.state.acceleratorSessionStatus !== sessionStatus)
            this.setState({ acceleratorSessionStatus: sessionStatus });
    }

    onCloseDrawer() {
        this.setState({ drawerOpen: false });
    }

    onOpenDrawer() {
        this.setState({ drawerOpen: true });
    }

    getChartData(stats: ConnectionStats): ChartPoint[] {
        let now = moment().utc();
        return stats.recentPings.map(p => {
            return { x: moment.duration(moment(p.serverTimestamp).diff(now)).asMinutes(), y: p.pingMs } as ChartPoint
        });
    }

    render() {
        const { userName } = this.props;
        const { sessionStatus, stats, drawerOpen, acceleratorSessionStatus } = this.state;
        let chartData = new Array<ChartPoint>();
        if (stats !== undefined && stats.recentPings !== undefined) {
            chartData = this.getChartData(stats);
        }


        const icon = sessionStatus === ConnectionStatus.Connected ? (acceleratorSessionStatus === ConnectionStatus.Connected ? <FlashOnOutlined color="inherit" /> : <CheckCircleOutline color="inherit" />) :
            sessionStatus === ConnectionStatus.Disconnected ? <ErrorOutline style={{ color: 'red' }} /> : <WarningOutlined style={{ color: 'orange' }} />

        const rowStyle = { color: userStoreInstance.GetTheme().color } as React.CSSProperties;
        return (
            <CssBaseline>
                <ThemeProvider theme={getFormTheme()}>
                    <div className="ConnectionStatusWidget">
                        <Drawer open={drawerOpen} onClose={this.onCloseDrawer} anchor="bottom">
                            <div className="ConnectionStatusWidgetPopout">
                                <div>
                                    <Typography align="right" variant="h6" >Connection Statistics</Typography>
                                    <Divider />
                                </div>
                                <Grid container justifyContent="space-around" alignContent="center">
                                    <Grid item>
                                        <TableContainer component={Paper} className="ConnectionStatusWidgetTable">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={rowStyle} align="center">Property</TableCell >
                                                        <TableCell style={rowStyle} align="center">Value</TableCell >
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell style={rowStyle} >Last seen</TableCell >
                                                        <TableCell style={rowStyle} >{new Date(stats.lastSeenUtc).toLocaleTimeString()}</TableCell >
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell style={rowStyle} >Connected</TableCell >
                                                        <TableCell style={rowStyle} >{new Date(stats.connectedUtc).toLocaleString()}</TableCell >
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell style={rowStyle} >Connection Id</TableCell >
                                                        <TableCell style={rowStyle} >{stats.connectionId}</TableCell >
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell style={rowStyle} >Server Connection</TableCell >
                                                        <TableCell style={rowStyle} >{ConnectionStatus[userSessionConnection.status]}</TableCell >
                                                    </TableRow>
                                                    {userSessionConnection.status !== ConnectionStatus.Connected ?
                                                        <TableRow hover>
                                                            <TableCell colSpan={2}>
                                                                <Button
                                                                    className="MuiButton-outlined PltfmButtonLite"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => userSessionConnection.connect()}>Reconnect
                                                                </Button>
                                                            </TableCell >
                                                        </TableRow> : null}
                                                    <TableRow hover>
                                                        <TableCell style={rowStyle} >Accelerator Connection</TableCell >
                                                        <TableCell style={rowStyle} >{ConnectionStatus[acceleratorSessionStatus]}</TableCell >
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    {stats.stats !== undefined ?
                                        <Grid item>
                                            <TableContainer className="ConnectionStatusWidgetTable" component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={rowStyle} align="center">Stat</TableCell>
                                                            <TableCell style={rowStyle} align="center">Value</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow hover>
                                                            <TableCell style={rowStyle} >Last</TableCell >
                                                            <TableCell style={rowStyle} >{stats.stats["1hr"].last.toFixed(2)}</TableCell >
                                                        </TableRow>
                                                        <TableRow hover>
                                                            <TableCell style={rowStyle} >Max</TableCell >
                                                            <TableCell style={rowStyle} >{stats.stats["1hr"].max.toFixed(2)}</TableCell >
                                                        </TableRow>
                                                        <TableRow hover>
                                                            <TableCell style={rowStyle} >Min</TableCell >
                                                            <TableCell style={rowStyle} >{stats.stats["1hr"].min.toFixed(2)}</TableCell >
                                                        </TableRow>
                                                        <TableRow hover>
                                                            <TableCell style={rowStyle} >Mean</TableCell >
                                                            <TableCell style={rowStyle} >{stats.stats["1hr"].mean.toFixed(2)}</TableCell >
                                                        </TableRow>
                                                        <TableRow hover>
                                                            <TableCell style={rowStyle} >Count</TableCell >
                                                            <TableCell style={rowStyle} >{stats.stats["1hr"].numSamples}</TableCell >
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid> : null}
                                    {chartData.length > 0 ?
                                        <Grid item>
                                            <Paper style={{ height: "350px" }}>
                                                <ScatterChart
                                                    width={450}
                                                    height={350}
                                                    margin={{
                                                        top: 20,
                                                        right: 20,
                                                        bottom: 20,
                                                        left: 20,
                                                    }}>
                                                    <CartesianGrid />
                                                    <XAxis type="number" dataKey="x" name="time" unit="min" />
                                                    <YAxis type="number" dataKey="y" name="ping" unit="ms" min={0} />
                                                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                                    <Scatter name="A school" data={chartData} fill="#8884d8" />
                                                </ScatterChart>
                                            </Paper></Grid> : null}
                                </Grid>
                            </div>
                        </Drawer>
                        <Button color="inherit" variant="text" endIcon={icon} onClick={this.onOpenDrawer} style={{ fontFamily: userStoreInstance.GetTheme().font_family }}>{userName}</Button>
                    </div>
                </ThemeProvider>
            </CssBaseline>
        );
    }
}



export default SessionStatusWidget;