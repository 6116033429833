import React from 'react';
import FBEmitter from 'fbemitter';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import { BarrierObservationType, BarrierSide, BarrierType } from '../../qwack/barrierEnums';
import { BarrierDetail } from '../../payoff/barrierDetail';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { Grid, Typography } from '@mui/material';
import { subCellProps, subRowProps, subTitleProps, subValueProps, titleVariant } from './helpers';

export type BarrierDetailsProps = {
    stateUpdate: (state: BarrierDetail) => void,
    initialBarrierDefinition: BarrierDetail,
    disabled: boolean
}

export type BarrierDetailsState = {
    possibleBarrierTypes: string[]
    possibleObservationTypes: string[]
    possibleBarrierSides: string[]
    barrierDetails: BarrierDetail;
}


export class BarrierDetailDesigner extends React.Component<BarrierDetailsProps, BarrierDetailsState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: BarrierDetailsProps) {
        super(props);
        this.state = {
            barrierDetails: this.props.initialBarrierDefinition ?? {
                barrierObservationType: BarrierObservationType.Continuous,
                barrierSide: BarrierSide.Down,
                barrierType: BarrierType.KnockIn
            } as BarrierDetail,
            possibleBarrierTypes: [],
            possibleObservationTypes: [],
            possibleBarrierSides: [],
        }
        this.onChangeBarrierLevel = this.onChangeBarrierLevel.bind(this);
        this.setBarrierType = this.setBarrierType.bind(this);
        this.setBarrierSide = this.setBarrierSide.bind(this);
        this.setBarrierObsType = this.setBarrierObsType.bind(this);
    }

    public componentWillUnmount() {

    }

    componentDidMount() {
        this.fillPossibleTypes();
    }

    public componentDidUpdate() {

    }

    fillPossibleTypes = () => {
        const keysBarrierType = Object.keys(BarrierType).filter(k => typeof BarrierType[k as any] === "number");
        const keysBarrierSide = Object.keys(BarrierSide).filter(k => typeof BarrierSide[k as any] === "number");
        const keysBarrierObsType = Object.keys(BarrierObservationType).filter(k => typeof BarrierObservationType[k as any] === "number");
        this.setState({ possibleBarrierTypes: keysBarrierType, possibleBarrierSides: keysBarrierSide, possibleObservationTypes: keysBarrierObsType });
    }

    setBarrierType = (barrierType: string) => {
        var bs = (BarrierType as any)[barrierType];
        var barrierDef = this.state.barrierDetails;
        if (!barrierDef) {
            barrierDef = {} as BarrierDetail;
        }
        barrierDef.barrierType = bs;
        this.setState({ barrierDetails: barrierDef });
        this.props.stateUpdate(barrierDef);
    }
    setBarrierSide = (barrierSide: string) => {
        var bs = (BarrierSide as any)[barrierSide];
        var barrierDef = this.state.barrierDetails;
        if (!barrierDef) {
            barrierDef = {} as BarrierDetail;
        }
        barrierDef.barrierSide = bs;
        this.setState({ barrierDetails: barrierDef });
        this.props.stateUpdate(barrierDef);
    }
    setBarrierObsType = (barrierObsType: string) => {
        var bs = (BarrierObservationType as any)[barrierObsType];
        var barrierDef = this.state.barrierDetails;
        if (!barrierDef) {
            barrierDef = {} as BarrierDetail;
        }
        barrierDef.barrierObservationType = bs;
        this.setState({ barrierDetails: barrierDef });
        this.props.stateUpdate(barrierDef);
    }
    onChangeBarrierLevel(val: number) {
        var barrierDef = this.state.barrierDetails;
        barrierDef.level = val;
        this.setState({ barrierDetails: barrierDef });
        this.props.stateUpdate(barrierDef);
    }

    render() {
        if (!this.state || !this.state.possibleBarrierSides || this.state.possibleBarrierSides.length === 0) {
            return <div>Loading...</div>
        }
        const { possibleBarrierSides, possibleBarrierTypes, possibleObservationTypes, barrierDetails } = this.state;
        return (
            <Grid item container paddingTop="0.25em">
                <Grid {...subRowProps} >
                    <Grid {...subCellProps}>
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={titleVariant}>In/Out</Typography></Grid>
                        <Grid {...subValueProps}><StringTypeDropDown style={{width:"130px"}} possibles={possibleBarrierTypes} selected={possibleBarrierTypes[barrierDetails.barrierType]} onChange={this.setBarrierType} disabled={this.props.disabled} /></Grid>
                    </Grid>
                    <Grid {...subCellProps} justifyContent="flex-end">
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={titleVariant}>Side</Typography></Grid>
                        <Grid {...subValueProps}><StringTypeDropDown style={{width:"130px"}} possibles={possibleBarrierSides} selected={possibleBarrierSides[barrierDetails.barrierSide]} onChange={this.setBarrierSide} disabled={this.props.disabled} /></Grid>
                    </Grid>
                </Grid>
                <Grid {...subRowProps} >
                    <Grid {...subCellProps}>
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={titleVariant}>Observation</Typography></Grid>
                        <Grid {...subValueProps}><StringTypeDropDown style={{width:"130px"}} possibles={possibleObservationTypes} selected={possibleObservationTypes[barrierDetails.barrierObservationType]} onChange={this.setBarrierObsType} disabled={this.props.disabled} /></Grid>
                    </Grid>
                    <Grid {...subCellProps} justifyContent="flex-end">
                        <Grid {...subTitleProps} width={100}><Typography textAlign='center' variant={titleVariant}>Level</Typography></Grid>
                        <Grid {...subValueProps}><ValidatedNumericInput style={{width:"130px"}} units="%" keyName="barrierLevelPct" placeholder="Barrier" className="AutocallDateGenCoupon" initialValue={barrierDetails.level} onChange={(v) => { this.onChangeBarrierLevel(v); return true; }} disabled={this.props.disabled} /></Grid>
                    </Grid>
                </Grid>
            </Grid>)
    }

}
export default BarrierDetailDesigner;