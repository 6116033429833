import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { PayoffState } from './payoffModels';
import { LoadPayoffsEvent } from './payoffActions';

class PayoffStore extends FluxStore<PayoffState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            payoffMap: [],
            payoffs: [],
            periods: [],
            stripPeriods: [],
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadPayoffsEvent) {
            const { payload } = action;
            this.state = { payoffs: payload.payoffs, periods: [], stripPeriods: [] };
            console.log(this.state.payoffs);
            this.emitChange();
        }
    }

    getPayoffs() {
        return this.state.payoffs;
    }

    getPayoffById(payoffId: number){
        return this.state.payoffs.filter(p=>p.payoffId===payoffId)[0];
    }

    getPeriods(assetId: number, assetGroupId: number, assetInfoId: number, payoffName: string) {
        return payoffName === "Strip" ? this.state.stripPeriods : this.state.periods;
    }
}

const payoffStoreInstance = new PayoffStore(AppDispatcher);
export default payoffStoreInstance;