import FBEmitter from 'fbemitter';
import React from 'react';
import { Button, Grid, IconButton, MenuItem, Paper, SelectChangeEvent, Tab, Tabs, TextField, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../../inputs/formCommon';
import { FormSection } from '../../inputs/formSection';
import { AddOutlined, CheckCircleOutline, DoneAllOutlined, RemoveOutlined, UndoOutlined } from '@mui/icons-material';
import { MatronFatFingerControlType, MatronFatFingerInstrumentScope, MatronFatFingerLimitType, MatronFatFingerRecord, MatronTradingDefaults } from './matronSettingsModels';
import InstrumentSelection from '../../inputs/instrumentSelection';
import { ListedInstrument } from '../../listedInstruments/listedInstrumentModels';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { NumberFormatCustom } from '../../inputs/numberFormatInput';
import { WrappedSelect } from '../../inputs/wrappedSelect';
import { GetFatFingerControls, GetTradingDefaults, SetFatFingerControls, SetTradingDefaults } from './matronSettingsActions';
import matronSettingsStoreInstance from './matronSettingsStore';
import { WrappedCheckBox } from '../../inputs/wrappedCheckBox';
import { get } from '../../utils/httpUtils';
import { TabContext, TabPanel } from '@mui/lab';
import ordersStoreInstance from '../../orders/orderStore';
import { Venue } from '../../orders/orderModels';


interface ApiRequest {
    url: string,
}

interface MatronSettingsState {
    fatFingerSettings: MatronFatFingerRecord[],
    tradingDefaults: MatronTradingDefaults,
    selectedTabId: number,
}

export interface MatronSettingsProps {

}

export class MatronSettings extends React.Component<MatronSettingsProps, MatronSettingsState>{
    eventSubscriptionMatronSettings: FBEmitter.EventSubscription | undefined;
    constructor(props: MatronSettingsProps) {
        super(props)
        this.state = {
            fatFingerSettings: [],
            tradingDefaults: undefined,
            selectedTabId: 0
        };

        this.onChangeConfig = this.onChangeConfig.bind(this);
        this.onClickUpdateLimits = this.onClickUpdateLimits.bind(this);
        this.onClickUpdateSettings = this.onClickUpdateSettings.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionMatronSettings = matronSettingsStoreInstance.addChangeListener(this.onChangeConfig);
        var limits = await GetFatFingerControls();
        var settings = await GetTradingDefaults();
        this.setState({ fatFingerSettings: limits, tradingDefaults: settings });
    }

    async componentWillUnmount() {
    }

    async onChangeConfig() {
        var limits = matronSettingsStoreInstance.getFatFingerControls();
        var settings = matronSettingsStoreInstance.getTradingDefaults();
        this.setState({ fatFingerSettings: limits, tradingDefaults: settings });
    }

    async onClickUpdateLimits() {
        await SetFatFingerControls(this.state.fatFingerSettings)
    }

    async onClickUpdateSettings() {
        await SetTradingDefaults(this.state.tradingDefaults)
    }

    anyChanges() {
        const { fatFingerSettings } = this.state;
        return fatFingerSettings.length > 0;
    }

    onAddFFRecord() {
        const { fatFingerSettings } = this.state;
        fatFingerSettings.push({ controlType: MatronFatFingerControlType.PerOrder, limitType: MatronFatFingerLimitType.Contracts, isSoftLimit: false, deleted: false } as MatronFatFingerRecord);
        this.setState({ fatFingerSettings })
    }

    onRemoveFFRecord(ix: number) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        rec.deleted = true;
        this.setState({ fatFingerSettings });
    }

    onChangeFFRecordIns(ix: number, ins: ListedInstrument) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        rec.instrumentId = ins.listedInstrumentId
        this.setState({ fatFingerSettings })
    }

    onChangeFFRecordLimit(ix: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        var asStr = e.target.value;
        var asNum = Number(asStr);
        rec.limit = isNaN(asNum) ? undefined : asNum;
        this.setState({ fatFingerSettings })
    }

    onChangeFFRecordLimitType(ix: number, e: SelectChangeEvent<any>) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        var asStr = e.target.value;
        var asEnum: MatronFatFingerLimitType = MatronFatFingerLimitType[asStr as keyof typeof MatronFatFingerLimitType];
        rec.limitType = asEnum;
        this.setState({ fatFingerSettings })
    }

    onChangeFFRecordControlType(ix: number, e: SelectChangeEvent<any>) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        var asStr = e.target.value;
        var asEnum: MatronFatFingerControlType = MatronFatFingerControlType[asStr as keyof typeof MatronFatFingerControlType];
        rec.controlType = asEnum;
        this.setState({ fatFingerSettings })
    }

    onChangeFFRecordLimitScope(ix: number, e: SelectChangeEvent<any>) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        var asStr = e.target.value;
        var asEnum: MatronFatFingerInstrumentScope = MatronFatFingerInstrumentScope[asStr as keyof typeof MatronFatFingerInstrumentScope];
        rec.limitScope = asEnum;
        this.setState({ fatFingerSettings })
    }

    onChangeFFRecordIsSoftLimit(ix: number, checked: boolean) {
        const { fatFingerSettings } = this.state;
        var rec = fatFingerSettings[ix];
        rec.isSoftLimit = checked;
        this.setState({ fatFingerSettings })
    }

    async clickIb() {
        console.log("getting IB api key");
        var response = await get<ApiRequest>("ApiKey/IB/Request");
        var url = response.payload.url;
        console.log(`Got API Key ${url}`);

        window.open(url, '_blank').focus();
    }

    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    renderSettings() {
        const { tradingDefaults } = this.state;
        const titleStyle = { width: "175px", textAlign: "right", paddingRight: "1em" } as React.CSSProperties;
        var orderVenueObject = ordersStoreInstance.getOrderVenues()["IB"];

        var anyChanges = this.anyChanges()
        return (
            <Grid container spacing={0} justifyContent="center" alignContent="space-around" height="100%" overflow="auto">
                <Grid container spacing={2} justifyContent="center" alignContent="space-around" width={"95%"} height={"calc(100% - 25px)"} >
                    <FormSection label={<Typography variant="h6" style={titleStyle}>API Keys</Typography>} >
                        <Grid item>
                            <Button variant="outlined" onClick={async () => this.clickIb()} className="MuiButton-outlined PltfmButtonLite" size="small" startIcon={<DoneAllOutlined color="inherit" />}>
                                Authorise IB
                            </Button>
                        </Grid>
                        {null}
                    </FormSection>
                    <FormSection label={<Typography variant="h6" style={titleStyle}>IB Defaults</Typography>} >
                        <Grid item container spacing={2}>
                            <Grid item>
                                <WrappedSelect
                                    id="defAccount"
                                    name="defAccount"
                                    label="Account"
                                    style={{ width: "200px" }}
                                    value={tradingDefaults?.defaultAccount ?? "Select Account..."}
                                    onChange={(e, c) => { tradingDefaults.defaultAccount = e.target.value; this.setState({ tradingDefaults }) }}>
                                    <MenuItem disabled key={"defaccnull"} value={"Select Account..."}>{"Select Account..."}</MenuItem>
                                    {(orderVenueObject as Venue)?.capabilities['Accounts']?.split(',')?.filter(a => a !== "All").map((a, ix) =>
                                        <MenuItem key={"acc" + ix} value={a}>{a}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"OutsideRTH"}
                                    name="OutsideRTH"
                                    label="Outside RTH"
                                    style={{ width: "200px" }}
                                    value={tradingDefaults?.defaultToOutsideRth ?? false}
                                    onChange={(e, c) => { tradingDefaults.defaultToOutsideRth = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                        </Grid>
                        {null}
                    </FormSection>
                    <FormSection label={<Typography variant="h6" style={titleStyle}>IB Warning Suppression</Typography>}>
                        <Grid item container spacing={2}>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"SuppressIbPriceCapWarning"}
                                    name="SuppressIbPriceCapWarning"
                                    label="Price Cap"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressIbPriceCapWarning?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressIbPriceCapWarning = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"suppressIbPriceDeviationWarning"}
                                    name="suppressIbPriceDeviationWarning"
                                    label="Price Deviation"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressIbPriceDeviationWarning?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressIbPriceDeviationWarning = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"SuppressIbMarketDataWarning"}
                                    name="SuppressIbMarketDataWarning"
                                    label="Market Data"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressIbMarketDataWarning?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressIbMarketDataWarning = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"suppressIbUnitSizeLimit"}
                                    name="suppressIbUnitSizeLimit"
                                    label="Size Limit"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressIbUnitSizeLimit?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressIbUnitSizeLimit = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"suppressIbValueLimit"}
                                    name="suppressIbValueLimit"
                                    label="Value Limit"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressIbValueLimit?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressIbValueLimit = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"suppressStopWarning"}
                                    name="suppressStopWarning"
                                    label="Stops"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressStopWarning?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressStopWarning = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                            <Grid item>
                                <WrappedCheckBox
                                    id={"suppressMarketOrderWarning"}
                                    name="suppressMarketOrderWarning"
                                    label="Market Order"
                                    style={{ width: "175px" }}
                                    value={tradingDefaults?.suppressIbMarketOrderWarning?? false}
                                    onChange={(e, c) => { tradingDefaults.suppressIbMarketOrderWarning = c; this.setState({ tradingDefaults }) }} />
                            </Grid>
                        </Grid>
                        {null}
                    </FormSection>
                </Grid>
                <Grid item container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button
                            className="PltfmButtonLite"
                            variant="outlined"
                            disabled={!anyChanges}
                            onClick={this.onClickUpdateSettings}
                            endIcon={<CheckCircleOutline />}>Update</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            className="PltfmButtonLite"
                            variant="outlined"
                            disabled={!anyChanges}
                            onClick={this.onChangeConfig}
                            endIcon={<UndoOutlined />}>Revert</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    renderLimits() {
        const { fatFingerSettings } = this.state;
        const titleStyle = { width: "175px", textAlign: "right", paddingRight: "1em" } as React.CSSProperties;
        var anyChanges = this.anyChanges()
        const rootInsIds = listedInstrumentStoreInstance.getRootInstruments().map(i => i.listedInstrumentId);
        return (
            <Grid container spacing={2} justifyContent="center" alignContent="space-around" width={"95%"} height={"calc(100vh - 175px)"}>
                <FormSection label={<Typography variant="h6" style={titleStyle}>Contract Limits</Typography>} height="calc(100vh - 235px)" >
                    <Grid item container spacing={2} maxHeight="calc(100vh - 275px)" overflow="auto">
                        {fatFingerSettings.filter(ff => !ff.deleted).map((r: MatronFatFingerRecord, ix) => {
                            return <Grid item container spacing={1} key={`ffControlz${ix}`}>
                                <Grid item>
                                    <InstrumentSelection listedInstrumentId={r.instrumentId} onInstrumentSelect={(ins) => this.onChangeFFRecordIns(ix, ins)} filterType={undefined} restrictedInsList={rootInsIds} size="small"></InstrumentSelection>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id={"limitScope" + ix}
                                        name="limitScope"
                                        label="Scope"
                                        style={{ width: "150px" }}
                                        value={MatronFatFingerInstrumentScope[r.limitScope] ?? null}
                                        onChange={(e, c) => this.onChangeFFRecordLimitScope(ix, e)}>
                                        {Object.keys(MatronFatFingerInstrumentScope).filter(x => isNaN(Number(x))).map((v, ixx) => <MenuItem key={"insScopeType" + ixx + "-" + ix} value={v}>{v}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                        id={"limit" + ix}
                                        name="limit"
                                        size="small"
                                        style={{ width: "150px" }}
                                        label={"Limit (" + MatronFatFingerLimitType[r.limitType] + ")"}
                                        value={r.limit ?? 0}
                                        onFocus={event => {
                                            event.target.select();
                                        }}
                                        onChange={(e) => this.onChangeFFRecordLimit(ix, e)}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: { input: "ListedInstrumentEditorFormFieldInner" },
                                            inputComponent: NumberFormatCustom as any,
                                        }} />
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id={"limitType" + ix}
                                        name="limitType"
                                        label="Limit Type"
                                        style={{ width: "150px" }}
                                        value={MatronFatFingerLimitType[r.limitType] ?? null}
                                        onChange={(e, c) => this.onChangeFFRecordLimitType(ix, e)}>
                                        {Object.keys(MatronFatFingerLimitType).filter(x => isNaN(Number(x))).map((v, ixx) => <MenuItem key={"lmtType" + ixx + "-" + ix} value={v}>{v}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedSelect
                                        id={"controlType" + ix}
                                        name="controlType"
                                        label="Control Type"
                                        style={{ width: "150px" }}
                                        value={MatronFatFingerControlType[r.controlType] ?? null}
                                        onChange={(e, c) => this.onChangeFFRecordControlType(ix, e)}>
                                        {Object.keys(MatronFatFingerControlType).filter(x => isNaN(Number(x))).map((v, ixx) => <MenuItem key={"ctrlType" + ixx + "-" + ix} value={v}>{v}</MenuItem>)}
                                    </WrappedSelect>
                                </Grid>
                                <Grid item>
                                    <WrappedCheckBox
                                        id={"isSoftLimit" + ix}
                                        name="isSoftLimit"
                                        label="Soft Limit"
                                        style={{ width: "125px" }}
                                        value={r.isSoftLimit}
                                        onChange={(e, c) => this.onChangeFFRecordIsSoftLimit(ix, c)} />
                                </Grid>
                                <IconButton
                                    onClick={() => this.onRemoveFFRecord(ix)}
                                    className="ListedInstrumentEditorFormFieldInner"
                                    style={{ height: "2em" }}
                                    size="large">
                                    <RemoveOutlined color="inherit" />
                                </IconButton>
                            </Grid>
                        })}
                    </Grid>
                    <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta">
                        <Button variant="outlined" onClick={() => this.onAddFFRecord()} className="MuiButton-outlined PltfmButtonLite" size="small" startIcon={<AddOutlined color="inherit" />}>
                            Add
                        </Button>
                    </div>
                </FormSection>

                <Grid item container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button
                            className="PltfmButtonLite"
                            variant="outlined"
                            disabled={!anyChanges}
                            onClick={this.onClickUpdateLimits}
                            endIcon={<CheckCircleOutline />}>Update</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            className="PltfmButtonLite"
                            variant="outlined"
                            disabled={!anyChanges}
                            onClick={this.onChangeConfig}
                            endIcon={<UndoOutlined />}>Revert</Button>
                    </Grid>
                </Grid>
            </Grid>)
    }

    render() {
        const { selectedTabId } = this.state;
        return (<ThemeProvider theme={getFormTheme()}>
            <TabContext value={selectedTabId.toString()} >
                <Tabs key='metaSearchTab' value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"0"}
                        key={"settSettings"}
                        component={Paper}
                        id={"tab0"}
                        label="Settings" />
                    <Tab
                        classes={{ root: "tabTitle" }}
                        value={"1"}
                        key={"settLimits"}
                        component={Paper}
                        id={"tab1"}
                        label="Limits" />
                </Tabs>
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabSettt0"}
                    value={"0"}
                    children={this.renderSettings()} />
                <TabPanel
                    style={{ height: "calc(100vh - 105px)" }}
                    key={"tabSettt1"}
                    value={"1"}
                    children={this.renderLimits()} />
            </TabContext>
        </ThemeProvider>
        );
    }
}
