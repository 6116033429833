import {TypedEvent, AppDispatcher} from '../dispatcher/appDispatcher';
import {PayoffPayload} from './payoffModels';
import {get} from '../utils/httpUtils';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';

export class LoadPayoffsEvent extends TypedEvent<PayoffPayload> {}
export class LoadPayoffsFailedEvent extends TypedEvent<string> {}
export class LoadCurrenciesEvent extends TypedEvent<string[]> {}
export class LoadCurrenciesFailedEvent extends TypedEvent<string> {}

export async function LoadPayoffs(){
    const payoffs = await get<PayoffPayload>("Product/Payoffs");
    if(payoffs.payload == null)
    {
        AppDispatcher.dispatch(new LoadPayoffsFailedEvent("No payoffs returned from service"));
        UpdateLoadingStatus("Payoffs", LoadingStatus.Failed);
    }
    else
    {
        AppDispatcher.dispatch(new LoadPayoffsEvent(payoffs.payload));
        UpdateLoadingStatus("Payoffs", LoadingStatus.Completed);
    }
}

export async function LoadCurrencies(){
    const payoffs = await get<string[]>("Static/getCurrencies");
    if(payoffs.payload == null)
    {
        AppDispatcher.dispatch(new LoadCurrenciesFailedEvent("No currencies returned from service"));
        UpdateLoadingStatus("Currencies", LoadingStatus.Failed);
    }
    else
    {
        AppDispatcher.dispatch(new LoadCurrenciesEvent(payoffs.payload));
        UpdateLoadingStatus("Currencies", LoadingStatus.Completed);
    }
}
