import FBEmitter from 'fbemitter';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { LimitOrderTypes, OutrightOrderForm, StopOrderTypes } from './outrightOrderForm';
import { Order, OrderStatus, OrderType, Venue } from './orderModels';
import * as Yup from 'yup';
import userStoreInstance from '../user/userStore';
import { AddOrUpdateOrder } from './orderActions';
import ordersStoreInstance from './orderStore';
import { v4 } from 'uuid';
import { WrappedSelect } from '../inputs/wrappedSelect';
import { Autocomplete, Button, CircularProgress, CssBaseline, Grid, MenuItem, TextField, Typography } from '@mui/material';
import marketDataLiveStoreInstance from '../marketData/marketDataLiveStore';
import listedInstrumentStoreInstance from '../listedInstruments/listedInstrumentStore';
import matronSettingsStoreInstance from '../settings/matron/matronSettingsStore';
import { getUltimateParentFromIns } from '../positions/positionsCommon';
import marketDataStoreInstance from '../marketData/marketDataStore';
import { BookStr, DefaultSetName, OrderTemplates, StrategyStr } from '../globalConstants';
import { ComputeVaRWhatIf, ComputeVaRWhatIfMulti } from '../risk/riskActions';
import moment from 'moment';
import { getFormTheme } from '../inputs/formCommon';
import ThemeProvider from '@mui/styles/ThemeProvider';
import { LeggedOrderTypes, LeggedSpreadOrderForm } from './leggedSpreadOrderForm';
import { OrderPricePanel } from './orderPricePanel';
import tradesStoreInstance from '../trade/tradeStore';
import { NativeSpreadOrderForm } from './nativeSpreadOrderForm';
import { WhatIfTrade } from '../risk/riskModels';
import { GetMetaSuggestionsForIns } from '../trade/tradeActions';
import taskUpdateStoreInstance from '../userSession/taskUpdateStore';
import { SubscribeToJobUpdates, UnSubscribeFromJobUpdates } from '../userSession/userSessionActions';
import { ComputeFeederMasterNavs } from '../nav/navActions';
import navStoreInstance from '../nav/navStore';
import { addWeekDays } from '../utils/dates';

type OrderEditorState = {
    lastBookedId: number;
    venues: { [venue: string]: Venue };
    venuesRev: { [metaKey: string]: Venue };
    submitting: boolean,
    selectedTemplate: string;
    activeOrder: Order;
    lastPrice: number;
    nominal: number;
    lastPriceStamp: Date;

    fetchedFixingIds: Set<number>;
    awaitingVaR: boolean;
    varrr: number;
    varError: string;

    lastPriceLeg2: number;
    nominalLeg2: number;
    lastPriceStampLeg2: Date;

    orderRefId?: string;

    book: string;
    strategy: string;

    jobKey: string;
    jobMessage: string;

    nav: number;

    nActiveOrders: number;
}

export interface OrderEditorProps {
    onSubmit?: () => void;
    initialValue?: Order;
    gtc?: boolean;
    orderRefId?: string;
    book?: string;
    strategy?: string;
}

export const OrderEditorValidationSchema = Yup.object().shape({
    size: Yup.number()
        .required('Required')
        .typeError('Valid size required'),
    listedInstrumentId: Yup.number()
        .required('Required')
        .typeError('Instrument required'),
    orderVenue: Yup.string()
        .required('Required')
        .not(['(None Available)'])
        .typeError('Venue required'),
    // orderType: Yup.number()
    //     .required('Required')
    //     .typeError('Order type required'),    
});

export const OrderEditorEditModeValidationSchema = Yup.object().shape({
    size: Yup.number()
        .required('Required')
        .typeError('Valid size required'),
    listedInstrumentId: Yup.number()
        .required('Required')
        .typeError('Instrument required'),
});


const DeadOrderStatuses = [OrderStatus.Cancelled, OrderStatus.Filled, OrderStatus.Rejected];

//const OrderTemplates = ["Outright", "Native Spread"];

export class OrderEditor extends React.Component<OrderEditorProps, OrderEditorState>{
    eventSubscriptionUpdates: FBEmitter.EventSubscription | undefined;
    eventSubscriptionNav: FBEmitter.EventSubscription | undefined;
    constructor(props: OrderEditorProps) {
        super(props);
        var template = OrderTemplates[0];
        if (LeggedOrderTypes.includes(props.initialValue?.orderType))
            template = "Legged Spread";
        else if (props.initialValue?.isNativeSpread) {
            template = "Native Spread";
        }

        this.state = {
            lastBookedId: undefined,
            venues: {},
            venuesRev: {},
            submitting: false,
            selectedTemplate: template,
            activeOrder: props.initialValue ?? {} as Order,
            fetchedFixingIds: new Set<number>(),
            lastPrice: undefined,
            lastPriceStamp: undefined,
            nominal: undefined,
            awaitingVaR: false,
            varrr: undefined,
            lastPriceLeg2: undefined,
            lastPriceStampLeg2: undefined,
            nominalLeg2: undefined,
            orderRefId: props.orderRefId ?? v4(),
            book: this.props.book,
            strategy: this.props.strategy,
            varError: undefined,
            jobKey: v4(),
            jobMessage: undefined,
            nav: undefined,
            nActiveOrders: undefined
        };
        this.onSubmitOrder = this.onSubmitOrder.bind(this);
        this.onUpdateOrder = this.onUpdateOrder.bind(this);
        this.onClickVaRCalc = this.onClickVaRCalc.bind(this);
        this.suggestMeta = this.suggestMeta.bind(this);
        this.onJobUpdate = this.onJobUpdate.bind(this);
        this.onNavUpdate = this.onNavUpdate.bind(this);
    }

    componentDidMount() {
        var venues = ordersStoreInstance.getOrderVenues();
        this.eventSubscriptionUpdates = taskUpdateStoreInstance.addChangeListener(this.onJobUpdate);
        this.eventSubscriptionNav = navStoreInstance.addChangeListener(this.onNavUpdate);
        this.onNavUpdate();
        var venuesRev: { [metaKey: string]: Venue } = {}
        Object.keys(venues).forEach(k => {
            venuesRev[venues[k].name] = venues[k];
        });

        this.setState({ venues, venuesRev });
    }

    componentWillUnmount() {
        if (this.eventSubscriptionNav?.remove) {
            this.eventSubscriptionNav.remove();
        }
    }

    onNavUpdate() {
        if (!navStoreInstance.navsAreLoaded()) {
            ComputeFeederMasterNavs(DefaultSetName)
        }

        var today = moment().startOf('day');
        var storageKeyYesterday = `PnL-Yesterday-${today.format("yyyy-MM-DD")}`;
        var ytd = sessionStorage.getItem(storageKeyYesterday);

        if (!ytd) {
            var ytdDate = addWeekDays(today, -1);
            ytd = ytdDate.format("yyyy-MM-DD");
            sessionStorage.setItem(storageKeyYesterday, ytd);
        }

        var baseNav = navStoreInstance.getCalculatedNav(new Date(ytd), "MasterFund")?.totalInBase;

        if (baseNav) {
            this.setState({ nav: baseNav });
        }
    }

    async onJobUpdate() {
        var status = taskUpdateStoreInstance.GetLatestStatus(this.state.jobKey);
        if (status?.update !== this.state.jobMessage) {
            this.setState({ jobMessage: status?.update });
        }
        if (status?.isFinished) {
            await UnSubscribeFromJobUpdates(this.state.jobKey);
        }
    }

    async onSubmitOrder(values: Order, { setSubmitting }: FormikHelpers<Order>) {
        const { lastPrice, orderRefId, book, strategy } = this.state;
        var order = {
            orderReferenceId: orderRefId,
            created: values.created ? new Date(values.created) : new Date(),
            createdById: userStoreInstance.GetUserInfo().userId,
            externalSystemId: values.externalSystemId,
            externalSystem: values.externalSystem,
            limitLevel: LimitOrderTypes.includes(values.orderType) || values.orderType === OrderType.LeggedSpreadLimit ? values.limitLevel : undefined,
            goodUntil: values.goodUntil,
            listedInstrumentId: values.listedInstrumentId,
            isBuyOrder: values.isBuyOrder,
            orderSource: values.orderSource,
            orderStatus: values.orderStatus ?? OrderStatus.Created,
            orderType: values.orderType,
            size: values.size,
            stopLevel: StopOrderTypes.includes(values.orderType) ? values.stopLevel : undefined,
            orderVenue: values.orderVenue,
            referencePrice: lastPrice,
            account: values.account,
            comments: values.comments,
            timeInForce: values.timeInForce,
            leg2Account: values.leg2Account,
            leg2InstrumentId: values.leg2InstrumentId,
            leg2OrderVenue: values.leg2OrderVenue,
            leg2SizeRatio: values.leg2SizeRatio,
            orderInstructions: values.orderInstructions,
            isNativeSpread: values.isNativeSpread
        } as Order;

        if (book || strategy) {
            var metaData: { [key: string]: string } = {};
            if (book)
                metaData[BookStr] = book;
            if (strategy)
                metaData[StrategyStr] = strategy;
            order.blobData = JSON.stringify({ metaData });
        }

        this.setState({ submitting: true });
        AddOrUpdateOrder(order).then(result => {
            setSubmitting(false);
            this.setState({ submitting: false });
            if (this.props.onSubmit !== undefined)
                this.props.onSubmit();

            this.setState({ lastBookedId: result?.order.orderId });
        });
    }

    onDuplicatePressed = () => this.setState({ lastBookedId: undefined, orderRefId: v4() });

    onUpdateOrder(order: Order) {
        //console.log("akakaka")

        this.setState({ activeOrder: order });
        const { fetchedFixingIds, nActiveOrders } = this.state;

        if (order?.listedInstrumentId) {
            if (order?.orderType !== undefined && order.limitLevel !== undefined) {
                if (LimitOrderTypes.includes(order.orderType)) {
                    var ins = listedInstrumentStoreInstance.getInstrumentById(order?.listedInstrumentId);
                    var nominal = order.limitLevel * (ins?.multiplier ?? 1.0) * order.size * (order.isBuyOrder ? 1 : -1);
                    this.setState({ nominal });
                }
            }

            try {
                marketDataLiveStoreInstance.SubscribeToTickStream(order?.listedInstrumentId);
            }
            catch {
                console.error("There was an error subscribing to tick stream")
            }

            var preventPrefetch = fetchedFixingIds.has(order.listedInstrumentId);
            fetchedFixingIds.add(order.listedInstrumentId);
            var fixings = marketDataStoreInstance.getFixings(DefaultSetName, order.listedInstrumentId, preventPrefetch);
            if (Boolean(fixings) && fixings.length > 0) {
                var fixing = fixings[fixings.length - 1];
                this.setState({ lastPrice: fixing.value, lastPriceStamp: fixing.date })
            }

            if ((order.isNativeSpread || LeggedOrderTypes.includes(order.orderType)) && order.leg2InstrumentId) {

                try {
                    marketDataLiveStoreInstance.SubscribeToTickStream(order.leg2InstrumentId);
                }
                catch {
                    console.error("There was an error subscribing to tick stream for leg 2")
                }

                preventPrefetch = fetchedFixingIds.has(order.leg2InstrumentId);
                fetchedFixingIds.add(order.leg2InstrumentId);
                fixings = marketDataStoreInstance.getFixings(DefaultSetName, order.leg2InstrumentId, preventPrefetch);
                if (Boolean(fixings) && fixings.length > 0) {
                    fixing = fixings[fixings.length - 1];
                    this.setState({ lastPriceLeg2: fixing.value, lastPriceStampLeg2: fixing.date })
                }
            }

            var activeOrders = ordersStoreInstance.getOrders().filter(o=>o.listedInstrumentId===order.listedInstrumentId && !o.isTerminalState && o.orderVenue==="Pltfm" &&!DeadOrderStatuses.includes(o.orderStatus));
            this.setState({nActiveOrders:activeOrders.length});
        } else if(nActiveOrders!==undefined){
            this.setState({nActiveOrders:undefined});
        }
    }

    async onClickVaRCalc() {
        const { activeOrder, lastPrice, jobKey } = this.state;
        if (activeOrder?.isNativeSpread) {
            if (activeOrder.listedInstrumentId && activeOrder.leg2InstrumentId && (lastPrice || activeOrder?.limitLevel)) {
                var price = lastPrice;
                if (activeOrder?.listedInstrumentId && activeOrder?.size && price) {
                    this.setState({ awaitingVaR: true, jobMessage: undefined });
                    var trades = new Array<WhatIfTrade>();
                    trades.push({
                        insId: activeOrder.listedInstrumentId,
                        size: activeOrder.size * (activeOrder?.isBuyOrder ? 1 : -1),
                        price: price
                    } as WhatIfTrade);
                    trades.push({
                        insId: activeOrder.leg2InstrumentId,
                        size: activeOrder.size * (activeOrder?.isBuyOrder ? 1 : -1) * activeOrder.leg2SizeRatio,
                        price: price - (activeOrder.limitLevel ?? 0)
                    } as WhatIfTrade);
                    try {
                        await SubscribeToJobUpdates(jobKey);
                        var result = await ComputeVaRWhatIfMulti(moment().startOf('day').toDate(), trades, 0.95, "1y", jobKey);
                        if (result.errors)
                            this.setState({ awaitingVaR: false, varrr: undefined, varError: result.errors[0] });
                        else
                            this.setState({ awaitingVaR: false, varrr: (result.vaR - (result.baseline ?? 0)), varError: undefined });
                    }
                    finally {
                        this.setState({ awaitingVaR: false });
                    }
                }
            }
        }
        else {
            if (activeOrder?.listedInstrumentId && (lastPrice || activeOrder?.limitLevel)) {
                var price2 = activeOrder?.orderType === OrderType.Limit ? activeOrder?.limitLevel : lastPrice;
                if (activeOrder?.listedInstrumentId && activeOrder?.size && price2) {
                    this.setState({ awaitingVaR: true, jobMessage: undefined });
                    try {
                        await SubscribeToJobUpdates(jobKey);
                        var result2 = await ComputeVaRWhatIf(moment().startOf('day').toDate(), activeOrder?.listedInstrumentId, activeOrder?.size * (activeOrder?.isBuyOrder ? 1 : -1), price2, 0.95, "1y", jobKey);
                        if (result2.errors)
                            this.setState({ awaitingVaR: false, varrr: undefined, varError: result2.errors[0] });
                        else
                            this.setState({ awaitingVaR: false, varrr: (result2.vaR - (result2.baseline ?? 0)), varError: undefined });
                    }
                    finally {
                        this.setState({ awaitingVaR: false });
                    }
                }
            }
        }
    }

    async suggestMeta() {
        const { listedInstrumentId } = this.state.activeOrder;
        if (listedInstrumentId) {
            var suggestion = await GetMetaSuggestionsForIns(listedInstrumentId);
            //console.log("Suggestion:", suggestion)
            if (suggestion?.book)
                this.setState({ book: suggestion.book });
            if (suggestion?.strategy)
                this.setState({ strategy: suggestion.strategy });
        }
    }

    render() {
        const { selectedTemplate, lastPrice, activeOrder, nominal, awaitingVaR, varrr, varError, book, strategy, jobMessage, nav, nActiveOrders } = this.state;
        const { isBuyOrder, stopLevel, orderType, limitLevel, listedInstrumentId, size, leg2InstrumentId, leg2SizeRatio } = activeOrder;

        const ins = listedInstrumentId ? listedInstrumentStoreInstance.getInstrumentById(listedInstrumentId) : undefined;
        const parent = ins && getUltimateParentFromIns(ins);
        const areLimitsInPlace = parent && matronSettingsStoreInstance.areControlsInPlace(parent.listedInstrumentId);
        const wouldTradePass = (size === undefined) || (areLimitsInPlace && matronSettingsStoreInstance.wouldTradePass(parent.listedInstrumentId, size, nominal));

        const price = activeOrder?.orderType === OrderType.Limit ? activeOrder?.limitLevel : lastPrice;
        const varPctNav = varrr / nav * 100;

        return (<CssBaseline><ThemeProvider theme={getFormTheme()}>
            <div style={{ height: "100%", width: "calc(100% - 15px)", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                    <div style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                        <WrappedSelect
                            key="newOrderTemplateSelector"
                            label="Template"
                            onChange={(e) => this.setState({ selectedTemplate: e.target.value })}
                            value={selectedTemplate}>
                            {OrderTemplates.map(t => <MenuItem key={`os--${t}`} value={t}>{t}</MenuItem>)}
                        </WrappedSelect>  
                    </div>
                    <div>
                        {selectedTemplate === "Outright" && <Formik key="lrghtFrm" initialValues={this.props.initialValue ?? {} as Order}
                            onSubmit={this.onSubmitOrder}
                            onReset={() => this.onDuplicatePressed()}
                            validationSchema={OrderEditorValidationSchema}
                            enableReinitialize={true}>
                            {props => <OutrightOrderForm
                                key="orFrmInner"
                                listedInstrumentId={undefined}
                                className="NewTradeForm"
                                gtcInitial={this.props.gtc}
                                lastBookedId={this.state.lastBookedId}
                                canDuplicate={Boolean(this.state.lastBookedId)}
                                onDuplicatePressed={this.onDuplicatePressed}
                                venuesLookup={ordersStoreInstance.getOrderVenuesReverse()}
                                onUpdateOrder={this.onUpdateOrder}
                                nActiveOrders={nActiveOrders}
                                {...props}>
                            </OutrightOrderForm>}
                        </Formik>}
                        {selectedTemplate === "Legged Spread" && <Formik key="lgdSprdFrm" initialValues={this.props.initialValue ?? {} as Order}
                            onSubmit={this.onSubmitOrder}
                            onReset={() => this.onDuplicatePressed()}
                            validationSchema={OrderEditorValidationSchema}
                            enableReinitialize={true}>
                            {props => <LeggedSpreadOrderForm
                                key="lgdSprdFrmInner"
                                listedInstrumentId={undefined}
                                className="NewTradeForm"
                                gtcInitial={this.props.gtc}
                                lastBookedId={this.state.lastBookedId}
                                canDuplicate={Boolean(this.state.lastBookedId)}
                                onDuplicatePressed={this.onDuplicatePressed}
                                venuesLookup={ordersStoreInstance.getOrderVenuesReverse()}
                                onUpdateOrder={this.onUpdateOrder}
                                {...props}>
                            </LeggedSpreadOrderForm>}
                        </Formik>}
                        {selectedTemplate === "Native Spread" && <Formik key="nativSprdFrm" initialValues={this.props.initialValue ?? {} as Order}
                            onSubmit={this.onSubmitOrder}
                            onReset={() => this.onDuplicatePressed()}
                            validationSchema={OrderEditorValidationSchema}
                            enableReinitialize={true}>
                            {props => <NativeSpreadOrderForm
                                key="nativeSprdFrmInner"
                                listedInstrumentId={undefined}
                                className="NewTradeForm"
                                gtcInitial={this.props.gtc}
                                lastBookedId={this.state.lastBookedId}
                                canDuplicate={Boolean(this.state.lastBookedId)}
                                onDuplicatePressed={this.onDuplicatePressed}
                                venuesLookup={ordersStoreInstance.getOrderVenuesReverse()}
                                onUpdateOrder={this.onUpdateOrder}
                                {...props}>
                            </NativeSpreadOrderForm>}
                        </Formik>}
                    </div>
                </div>
                <div style={{ width: "20%", borderLeft: "1px solid", padding: "5px", borderLeftColor: userStoreInstance.GetTheme().contrastBorderColorLight }}>
                    <Grid container direction="column" height="100%" spacing={3} justifyContent="flex-start" alignContent="center">
                        <OrderPricePanel
                            isBuyOrder={isBuyOrder}
                            leg1InsId={listedInstrumentId}
                            leg2InsId={leg2InstrumentId}
                            leg2SizeRatio={leg2SizeRatio}
                            limitLevel={limitLevel}
                            stopLevel={stopLevel}
                            orderType={orderType}
                            size={size}
                            nominal={nominal}
                            isNativeSpread={activeOrder?.isNativeSpread ?? false}
                            key="odaPrcPnl"
                            nav={nav}
                        />
                        {ins && !areLimitsInPlace && <Grid item><Typography variant="subtitle1" style={{ color: "red" }}>No limits found for instrument</Typography></Grid>}
                        {areLimitsInPlace && wouldTradePass !== undefined && !wouldTradePass && <Grid item><Typography variant="subtitle1" style={{ color: "red" }}>Warning, trade likely bigger than limits</Typography></Grid>}
                        <Grid item container spacing={1}>
                            <Grid item width="100%" marginBottom="5px" borderTop="1px solid" borderColor={userStoreInstance.GetTheme().contrastBorderColorLight}><Typography variant="h6">Value-At-Risk</Typography></Grid>
                            <Grid item container alignContent="center" justifyContent="center" ><Grid item><Button className="PltfmButtonLite" onClick={this.onClickVaRCalc} disabled={!listedInstrumentId || !size || !price || awaitingVaR}>VaR Impact</Button></Grid></Grid>
                            {awaitingVaR && <Grid item container alignContent="center" justifyContent="center" ><Grid item><CircularProgress /></Grid></Grid>}
                            {awaitingVaR && <Grid item container alignContent="center" justifyContent="center" ><Grid item><Typography variant="body2">{jobMessage}</Typography></Grid></Grid>}
                            {!awaitingVaR && varError && <Grid item container alignContent="center" justifyContent="center" ><Grid item><Typography variant="body2" color="red">{varError}</Typography></Grid></Grid>}
                            {!awaitingVaR && varrr && <Grid item container alignContent="center" justifyContent="center" spacing={1} >
                                <Grid item>
                                    <Typography variant="h6">{(varrr as number)?.toLocaleString(undefined, { maximumFractionDigits: 0 })} USD</Typography>
                                </Grid>
                                {isNaN(varPctNav) ? null : <Grid item container alignContent="center" justifyContent="center">
                                    <Grid item>
                                        <Typography variant="body2">{(varPctNav as number)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}% NAV </Typography>
                                    </Grid>
                                </Grid>}
                            </Grid>}
                            {!awaitingVaR && varrr && <Grid item container alignContent="center" justifyContent="center" ><Grid item><Typography variant="body2">{varrr > 0 ? "(Reduces VaR)" : "(Increases VaR)"}</Typography></Grid></Grid>}
                        </Grid>
                        <Grid item container spacing={1} >
                            <Grid item width="100%" marginBottom="5px" borderTop="1px solid" borderColor={userStoreInstance.GetTheme().contrastBorderColorLight}><Typography variant="h6">Meta Data</Typography></Grid>
                            <Grid item><Autocomplete
                                style={{ width: "150px" }}
                                size="small"
                                options={tradesStoreInstance.getBooks()}
                                key={`book-oda`}
                                value={book ?? null}
                                autoSelect
                                onChange={(e, v) => { this.setState({ book: v }) }}
                                renderInput={(params) => (
                                    <TextField
                                        key={`book-oda-inner`}
                                        {...params}
                                        label={"Book"}
                                        margin="dense"
                                        error={book === undefined}
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                            /></Grid>
                            <Grid item><Button
                                disabled={!(this.state.activeOrder?.listedInstrumentId)}
                                onClick={this.suggestMeta}
                                className="PltfmButtonLite"
                                variant="outlined">
                                Suggest
                            </Button></Grid>
                            <Grid item><Autocomplete
                                style={{ minWidth: "300px" }}
                                size="small"
                                options={tradesStoreInstance.getStrategies()}
                                key={`strat-oda`}
                                value={strategy ?? null}
                                autoSelect
                                freeSolo
                                onChange={(e, v) => { this.setState({ strategy: v }) }}
                                renderInput={(params) => (
                                    <TextField
                                        key={`strat-oda-inner`}
                                        {...params}
                                        label={"Theme"}
                                        margin="dense"
                                        error={strategy === undefined}
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                            /></Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </ThemeProvider></CssBaseline>
        )
    }
}