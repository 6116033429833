import { AssessmentOutlined, DashboardOutlined, EditOutlined, ListAltOutlined, PeopleOutline, TrendingUpOutlined, FunctionsOutlined, WorkOutlined, MultilineChartOutlined, TodayOutlined, ReorderOutlined, CodeOutlined, ReportOutlined, SettingsOutlined, } from "@mui/icons-material";
import { PmsInstallation, RfqInstallation } from "../globalConstants";
import userStoreInstance from "../user/userStore";

export interface NewTabItem {
    label: string,
    icon: JSX.Element,
    actionName: string,
    allowedRoles: string[],
    pmsInstall: boolean,
    rfqInstall: boolean
}

export const newTabItems: NewTabItem[] = [
    { label: "Rfq Dashboard", icon: <DashboardOutlined />, actionName: "RfqDashboard", allowedRoles: ["SystemAdmin", "RfqUser"], pmsInstall: false, rfqInstall: true },
    //{ label: "Blotter", icon: <ListAltOutlined />, actionName: "Blotter", allowedRoles: ["RfqUser", "PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Trades", icon: <ListAltOutlined />, actionName: "Trades", allowedRoles: ["RfqUser", "PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Market Dashboard", icon: <MultilineChartOutlined />, actionName: "Markets", allowedRoles: ["RfqUser", "PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Instrument Editor", icon: <EditOutlined />, actionName: "Instruments", allowedRoles: ["RfqUser", "PmsUser"], pmsInstall: true, rfqInstall: false },
    { label: "Position Summary", icon: <FunctionsOutlined />, actionName: "Positions", allowedRoles: ["PmsUser"], pmsInstall: true, rfqInstall: false },
    { label: "Client Editor", icon: <PeopleOutline />, actionName: "Client", allowedRoles: ["SystemAdmin", "RfqUser"], pmsInstall: false, rfqInstall: true },
    { label: "Admin Console", icon: <AssessmentOutlined />, actionName: "AdminConsole", allowedRoles: ["SystemAdmin", "FirmAdmin"], pmsInstall: true, rfqInstall: true },
    { label: "Jobs", icon: <WorkOutlined />, actionName: "Jobs", allowedRoles: ["PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "NAV", icon: <TrendingUpOutlined />, actionName: "NAV", allowedRoles: ["PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Risk", icon: <TodayOutlined />, actionName: "Risk", allowedRoles: ["PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Orders", icon: <ReorderOutlined />, actionName: "Orders", allowedRoles: ["PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Engineering", icon: <CodeOutlined />, actionName: "Engineering", allowedRoles: ["SystemAdmin"], pmsInstall: true, rfqInstall: true },
    { label: "Reports", icon: <ReportOutlined />, actionName: "Reports", allowedRoles: ["PmsUser"], pmsInstall: true, rfqInstall: true },
    { label: "Settings", icon: <SettingsOutlined />, actionName: "Settings", allowedRoles: ["RfqUser", "PmsUser"], pmsInstall: true, rfqInstall: true },
];

export const tabAllowed = (item: NewTabItem): boolean => {
    var allowedByInstall = (RfqInstallation && item.rfqInstall) || (PmsInstallation && item.pmsInstall);
    if (!allowedByInstall) return false;
    return item.allowedRoles.some(r => userStoreInstance.GetUserInfo().roles.includes(r));
}