import { TextField } from "@mui/material";
import React from "react";
import { NumberFormatCustom } from "./numberFormatInput";

export interface WrappedNumberInputProps {
    onChange?: (val: number) => void,
    value: number,
    style?: React.CSSProperties,
    label?: React.ReactNode
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    autoWidth?: boolean
    disabled?: boolean,
    multiple?: boolean
    placeholder?: string
}

export class WrappedNumberInput extends React.Component<WrappedNumberInputProps, {}>
{
    render() {
        return (<TextField
            style={this.props.style ?? { width: "150px" }}
            variant="outlined"
            classes={{ root: "ListedInstrumentEditorFormField" }}
            id={this.props.id}
            name={this.props.id}
            size="small"
            label={this.props.label}
            error={this.props.error}
            value={this.props.value ?? ""}
            onFocus={event => {
                event.target.select();
            }}
            onChange={(e) => {
                const val = Number(e.target.value);
                this.props.onChange(val);
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                readOnly: this.props.disabled,
                classes: { input: "ListedInstrumentEditorFormFieldInner" },
                inputComponent: NumberFormatCustom as any,
            }} />)
    }
}

