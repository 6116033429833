import { Button, Typography } from '@mui/material';
import { DashboardCustomizeOutlined, DirectionsRunOutlined, ScheduleOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import { ScheduleTab } from '../schedules/scheduleTab';
import { RunJobTab } from './runJobTab';
import { JobDashBoard } from './jobDashboard';
import layoutStoreInstance from '../layout/layoutStore';

interface JobTabState {
    currentMode: JobTabMode,
    collapsed: boolean
}

enum JobTabMode {
    None,
    Dashboard,
    RunJob,
    Scheduler,
}

export interface JobTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class JobTab extends React.Component<JobTabProps, JobTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: JobTabProps) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: JobTabMode = !isNaN(Number(tabModeStr)) ? JobTabMode[JobTabMode[Number(tabModeStr)]] : JobTabMode.Dashboard;
        this.state = {
            currentMode: tabMode,
            collapsed: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case JobTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case JobTabMode.Dashboard:
                return <JobDashBoard {...this.props} />
            case JobTabMode.RunJob:
                return <RunJobTab {...this.props} />
            case JobTabMode.Scheduler:
                return <ScheduleTab {...this.props} />
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: JobTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: JobTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    onClick(mode: JobTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });
    }

    renderButton(mode: JobTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>

                                <div style={{ height: "20px" }} />
                                {this.renderButton(JobTabMode.Dashboard, <DashboardCustomizeOutlined />, "Dashboard")}
                                <div style={{ height: "20px" }} />
                                {this.renderButton(JobTabMode.RunJob, <DirectionsRunOutlined />, "Run Job")}
                                <div style={{ height: "20px" }} />
                                {this.renderButton(JobTabMode.Scheduler, <ScheduleOutlined />, "Scheduler")}
                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}
