import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { EventsStoreState, UpcomingEvent } from './eventsModels';
import { LoadUpcomingEventsEvent } from './eventsActions';

class EventsStore extends FluxStore<EventsStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            events: new Array<UpcomingEvent>(),
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadUpcomingEventsEvent) {
            const { payload } = action;
            this.state.events = payload;
            this.emitChange();
        }
    }

    getEvents() {
        return this.state.events;
    }
}

const eventsStoreInstance = new EventsStore(AppDispatcher);
export default eventsStoreInstance;