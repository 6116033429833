import {TypedEvent, AppDispatcher} from '../dispatcher/appDispatcher';
import {NewsItem} from './newsTickerModels'
import {get} from '../utils/httpUtils';

export class NewsUpdatedEvent extends TypedEvent<NewsItem> {}
export class NewsLoadedEvent extends TypedEvent<NewsItem[]> {}
export class NewsLoadedFailedEvent extends TypedEvent<string>{}

export function NewsUpdated(inboxItem : NewsItem){
    AppDispatcher.dispatch(new NewsUpdatedEvent(inboxItem));
}

export async function LoadNews() {
    const assets = await get<NewsItem[]>("News");
    if(assets.payload == null)
    {
        AppDispatcher.dispatch(new NewsLoadedFailedEvent("Failed to load the news for the user"));
    }
    else
    {
        AppDispatcher.dispatch(new NewsLoadedEvent(assets.payload));
    }
}