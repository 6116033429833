import React, { Component } from 'react';
import { v4 } from 'uuid';
import moment, { Moment } from 'moment-timezone';

const LmeTimezone = "Europe/London";

interface SessionRecord {
  name: string,
  metal: string | undefined,
  end: string
}

interface SortableSessionRecord {
  name: string,
  metal: string | undefined,
  end: Moment
}

const Sessions = [

  { name: "Closed", end: "01:00" } as SessionRecord,
  { name: "Pre-market", end: "11:40" } as SessionRecord,

  { name: "Ring 1", metal: "Steel billet", end: "11:45" } as SessionRecord,
  { name: "Ring 1", metal: "Alloy / NASAAC", end: "11:50" } as SessionRecord,
  { name: "Ring 1", metal: "Tin", end: "11:55" } as SessionRecord,
  { name: "Ring 1", metal: "Primary Aluminium", end: "12:00" } as SessionRecord,
  { name: "Ring 1", metal: "Copper", end: "12:05" } as SessionRecord,
  { name: "Ring 1", metal: "Lead", end: "12:10" } as SessionRecord,
  { name: "Ring 1", metal: "Zinc", end: "12:15" } as SessionRecord,
  { name: "Ring 1", metal: "Nickel", end: "12:20" } as SessionRecord,
  { name: "Ring 1", metal: "Cobalt / Moly", end: "12:25" } as SessionRecord,
  { name: "Interval 1", end: "12:30" } as SessionRecord,

  { name: "Ring 2", metal: "Copper", end: "12:35" } as SessionRecord,
  { name: "Ring 2", metal: "Alloy / NASAAC", end: "12:40" } as SessionRecord,
  { name: "Ring 2", metal: "Tin", end: "12:45" } as SessionRecord,
  { name: "Ring 2", metal: "Lead", end: "12:50" } as SessionRecord,
  { name: "Ring 2", metal: "Zinc", end: "12:55" } as SessionRecord,
  { name: "Ring 2", metal: "Primary Aluminium", end: "13:00" } as SessionRecord,
  { name: "Ring 2", metal: "Nickel", end: "13:05" } as SessionRecord,
  { name: "Ring 2", metal: "Aluminium Premia", end: "13:10" } as SessionRecord,
  { name: "Ring 2", metal: "Steel billet", end: "13:15" } as SessionRecord,
  { name: "Interval 2", end: "13:25" } as SessionRecord,
  { name: "Ring 2", metal: "Kerb Trading", end: "13:35" } as SessionRecord,
  { name: "Interval 3", end: "15:00" } as SessionRecord,

  { name: "Ring 3", metal: "Lead", end: "15:05" } as SessionRecord,
  { name: "Ring 3", metal: "Zinc", end: "15:10" } as SessionRecord,
  { name: "Ring 3", metal: "Copper", end: "15:15" } as SessionRecord,
  { name: "Ring 3", metal: "Primary Aluminium", end: "15:20" } as SessionRecord,
  { name: "Ring 3", metal: "Tin", end: "15:25" } as SessionRecord,
  { name: "Ring 3", metal: "Nickel", end: "15:30" } as SessionRecord,
  { name: "Ring 3", metal: "Alloy / NASAAC", end: "15:35" } as SessionRecord,
  { name: "Interval 4", end: "15:50" } as SessionRecord,

  { name: "Kerb", metal: "Zinc Close", end: "16:00" } as SessionRecord,
  { name: "Kerb", metal: "Tin Close", end: "16:10" } as SessionRecord,
  { name: "Kerb", metal: "Lead / Steel Close", end: "16:20" } as SessionRecord,
  { name: "Kerb", metal: "Cobalt / Moly Close", end: "16:25" } as SessionRecord,
  { name: "Kerb", metal: "Aluminium Close", end: "16:35" } as SessionRecord,
  { name: "Kerb", metal: "Alloy / Ali Premium Close", end: "16:40" } as SessionRecord,
  { name: "Kerb", metal: "Copper Close", end: "16:50" } as SessionRecord,
  { name: "Kerb", metal: "Nickel Close", end: "17:00" } as SessionRecord,

  { name: "Evening", end: "19:00" } as SessionRecord,
  { name: "Closed", end: "23:59" } as SessionRecord,
] as SessionRecord[]

export interface LmeSessionIndicatorProps {
}

interface LmeSessionIndicatorState {
  uniqueKey: string
  sessions: SortableSessionRecord[]
  currentSession: SortableSessionRecord | undefined
}

let runner: any;

class LmeSessionIndicator extends Component<LmeSessionIndicatorProps, LmeSessionIndicatorState>{
  constructor(props: LmeSessionIndicatorProps) {
    super(props);
    var dateStr = moment().format("YYYY-MM-DD");//`${today.getFullYear()}-${(today.getMonth()+1).toLocaleString('en',{minimumIntegerDigits:2})}-${today.getDate()}`;
    this.state = {
      uniqueKey: v4(),
      sessions: Sessions.map<SortableSessionRecord>(s => {
        return { name: s.name, metal: s.metal, end: moment.tz(`${dateStr}T${s.end}:00`, LmeTimezone) } as SortableSessionRecord
      }),
      currentSession: undefined
    }

  }

  componentDidMount() {
    this.setState({ currentSession: this.state.sessions[0] });
    runner = setInterval(() => {
      if (this.state.currentSession) {
        var currentLmeTime = moment().tz(LmeTimezone);
        if (currentLmeTime.isAfter(this.state.currentSession.end)) {
          let newSession = this.getCurrentSession();
          this.setState({ currentSession: newSession });
        }
      }
    }, 1000);
  }
  componentWillUnmount() {
    if (runner) {
      clearInterval(runner);
    }
  }

  private getCurrentSession = () => {
    var currentLmeTime = moment().tz(LmeTimezone);
    var futureSessions = this.state.sessions.filter(s => s.end.isAfter(currentLmeTime));
    var currentSession = futureSessions.sort((a, b) => a > b ? -1 : 1)[0];
    return currentSession;
  }

  render() {
    let s = this.getCurrentSession();
    if (s === undefined) {
      return null;
    }
    let end = moment(s.end).local();
    if (s.metal === undefined) {
      return (
        <div className="LmeSessionIndicator">
          <div className="LmeSessionIndicatorInner">
            <table className="LmeSessionIndicatorTable" >
              <thead>
                <tr>
                  <th className="LmeSessionIndicatorTitle">Session</th>
                  <th className="LmeSessionIndicatorTitle">End</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{s.name}</td>
                  <td>{end.format("HH:mm")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="LmeSessionIndicator">
          <div className="LmeSessionIndicatorInner">
            <table className="LmeSessionIndicatorTable">
              <thead>
                <tr>
                  <th className="LmeSessionIndicatorTitle">Session</th>
                  <th className="LmeSessionIndicatorTitle">Metal</th>
                  <th className="LmeSessionIndicatorTitle">End</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{s.name}</td>
                  <td>{s.metal}</td>
                  <td>{end.format("HH:mm")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

  }
}
export default LmeSessionIndicator;