import { TypedEvent, AppDispatcher } from '../../../dispatcher/appDispatcher';
import { ProductSpec } from '../../../product/productModels';
import { Firm } from '../../../user/userModel';
import { ProductEditRequest } from './productCloneModels';

export class ProductCloneEvent extends TypedEvent<ProductSpec> { };
export class ProductEditEvent extends TypedEvent<ProductEditRequest> { };
export class ProductViewEvent extends TypedEvent<ProductEditRequest> { };

export function CloneProduct(productToClone: ProductSpec) {
    AppDispatcher.dispatch(new ProductCloneEvent(productToClone));
}

export function EditProduct(productToEdit: ProductSpec, rfqId: number, client: Firm) {
    AppDispatcher.dispatch(new ProductEditEvent({productToEdit, rfqId, client}));
}

export function ViewProduct(productToView: ProductSpec, rfqId: number, client: Firm) {
    AppDispatcher.dispatch(new ProductViewEvent({productToEdit: productToView, rfqId, client}));
}


