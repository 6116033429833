import * as React from 'react';
import {
    useGridApiRef,
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridColumnVisibilityModel,
    DataGridProProps,
} from '@mui/x-data-grid-pro';
import { renderDateCell } from '../utils/helpers';
import { GoodUntil, OrderType } from './orderModels';
import { useState, useEffect, useRef, useCallback } from 'react';
import { EventSubscription } from 'fbemitter';
import { RefreshOutlined, EditOutlined, CancelOutlined, FilterAltOutlined, PageviewOutlined, HailOutlined, LogoutOutlined } from '@mui/icons-material';
import {
    Grid,
    Button,
    ButtonGroup,
    Typography,
    IconButton,
    Tooltip,
    MenuItem,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import userStoreInstance from '../user/userStore';
import { getFormTheme } from '../inputs/formCommon';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import productStoreInstance from '../product/productStore';
import rfqSummaryStoreInstance from '../rfq/rfqSummaryStore';
import { RfqSummary } from '../rfq/rfqModels';
import { CommodityVanillaPayoff, PeriodType } from '../product/productModels';
import assetsStoreInstance from '../assets/assetsStore';
import { RefreshRfqSummaries } from '../rfq/rfqActions';
import { LoadProductSummaries } from '../product/productActions';
import { WrappedNumberInput } from '../inputs/wrappedNumberInput';
import rfqConnection from '../rfq/rfqConnection';
import { ViewProduct } from '../homepage/productDesigner/productCloning/productCloneActions';

export const FillableStates = ["PickedUp", "Working", "PartiallyFilled", "PartialFilled"];
export const CancellableStates = ["WaitingForTrading", "Created"];

const canTrade = true; //userStoreInstance.UserHasRole("Trader");
const colBase = { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Date & Underlying',
    flex: 2,
    width: 250,
    ...colBase
};
const defaultHiddenCols = ["prompt", "orderTypeString", "asset", "rfqId",];

export const FixingAggregator = props => {
    const [rfqs, setRfqs] = useState(undefined);
    const [showFillBox, setShowFillBox] = useState(false);
    const [filterDate, setFilterDate] = useState<Moment>(undefined);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState<number>();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const [awaitingRefresh, setAwaitingRefresh] = useState(false);
    const [fillPrice, setFillPrice] = useState<number>(undefined);
    const [fillSize, setFillSize] = useState<number>(undefined);

    const productMap = useRef(new Map<number, CommodityVanillaPayoff>());
    const productsRequested = useRef(new Set<number>());

    var eventSubscriptionRfqs = useRef({} as EventSubscription);
    var eventSubscriptionProducts = useRef({} as EventSubscription);

    const apiRef = useGridApiRef();

    const canRfqBeFilled = useCallback((rfqId: string) => {
        var rfq = (rfqs as RfqSummary[]).filter(o => o.rfqId?.toString() === rfqId)[0];
        return rfq?.state && FillableStates.includes(rfq.state);
    }, [rfqs]);

    const canRfqBePickedUp = useCallback((rfqId: string) => {
        var rfq = (rfqs as RfqSummary[]).filter(o => o.rfqId?.toString() === rfqId)[0];
        return rfq?.state === "WaitingForTrading";
    }, [rfqs]);

    const canRfqBeDropped = useCallback((rfqId: string) => {
        var rfq = (rfqs as RfqSummary[]).filter(o => o.rfqId?.toString() === rfqId)[0];
        return rfq?.state === "PickedUp";
    }, [rfqs]);

    const canRfqBeCancelled = useCallback((rfqId: string) => {
        var rfq = (rfqs as RfqSummary[]).filter(o => o.rfqId?.toString() === rfqId)[0];
        return rfq.userIdCreatedBy === userStoreInstance.GetUserInfo()?.userId && CancellableStates.includes(rfq?.state);
    }, [rfqs]);

    const startFillOrderProcess = useCallback((rfqId: number) => {
        handleClose();
        setSelectedRows([rfqId]);
        var rfq = (rfqs as RfqSummary[]).filter(r => r.rfqId === rfqId)[0]
        if (rfq) {
            if (rfq.orderType === OrderType.Limit)
                setFillPrice(rfq.limitLevel);
            setFillSize(rfq.size);
        }
        setShowFillBox(true);
    }, [rfqs]);

    async function finishFillOrderProcess() {
        for (var i = 0; i < selectedRows.length; i++) {
            var rowId = selectedRows[i];
            await rfqConnection.fillOrder(rowId, fillPrice, fillSize)
        }
        setShowFillBox(false);
    }

    const viewOrder = useCallback((rfqId: number) => {
        handleClose();
        var rfq = (rfqs as RfqSummary[]).filter(r => r?.rfqId === rfqId)[0]
        if (rfq) {
            var product = productStoreInstance.getProductById(rfq.productId);
            if (product) {
                ViewProduct(product.spec, rfq.rfqId, rfq.requestingFirm);
            }
        }
    }, [rfqs]);

    const defineColumns = useCallback(() => {
        const cols: GridColDef[] = [
            { field: "rfqId", headerName: "Id", type: "number", flex: 0.5, ...colBase },
            {
                field: "orderTypeString",
                headerName: "Order Type",
                type: "string",
                flex: 1,
                ...colBase
            },
            {
                field: "state",
                headerName: "Status",
                type: "string",
                flex: 1.5,
                ...colBase
            },
            {
                field: "payoffSubType",
                headerName: "Type",
                type: "string",
                flex: 1.5,
                ...colBase
            },
            {
                field: "portfolio",
                headerName: "Portfolio",
                type: "string",
                flex: 1,
                ...colBase
            },
            {
                field: "asset",
                headerName: "Underlying",
                type: "string",
                flex: 1.5,
                ...colBase
            },
            {
                field: "fixingIndexStr",
                headerName: "Fixing Basis",
                type: "string",
                flex: 1.2,
                ...colBase
            },
            {
                field: "userIdCreatedBy",
                headerName: "Creator",
                flex: 1.1,
                renderCell: (params: GridRenderCellParams) => userStoreInstance.GetUserNameForId(Number(params.value)),
                ...colBase
            },
            {
                field: "periodType",
                headerName: "Period Type",
                flex: 1.0,
                renderCell: (params: GridRenderCellParams) => PeriodType[(Number(params.value))],
                type: "string",
                ...colBase
            },
            {
                field: "period",
                headerName: "Period",
                type: "string",
                flex: 1,
                ...colBase
            },
            {
                field: "prompt",
                headerName: "Prompt",
                type: "dateTime",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => renderDateCell(params),
                ...colBase
            },
            { field: "size", headerName: "Order Size", type: "number", flex: 1.1, ...colBase },
            {
                field: "isBuyOrder",
                headerName: "B/S",
                renderCell: (params: GridRenderCellParams) => params.value === undefined ? undefined : Boolean(params.value) ? "Buy" : "Sell",
                flex: 0.8,
                ...colBase
            },
            { field: "stopLevel", headerName: "Stop", type: "number", flex: 1, editable: true, ...colBase },
            { field: "limitLevel", headerName: "Limit", type: "number", flex: 1, editable: true, ...colBase },

            { field: "tifStr", headerName: "Fixing Date", flex: 1, ...colBase },
            { field: "lastUpdated", headerName: "Last Update", type: "dateTime", flex: 1, renderCell: (params: GridRenderCellParams) => renderDateCell(params), ...colBase },
            {
                field: "actions", headerName: "Actions", type: "actions", sortable: false, disableColumnMenu: true, flex: 1.2, width: 200, renderCell: (params: GridRenderCellParams) => <ButtonGroup size="small" variant="text">
                    {canTrade && canRfqBeFilled(params.id.toString()) ? <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Fill Order</Typography>} >
                        <span><IconButton
                            onClick={() => { startFillOrderProcess(params.row.id) }}
                            size="medium">
                            <EditOutlined />
                        </IconButton></span>
                    </Tooltip> : null}
                    {canTrade && canRfqBePickedUp(params.id.toString()) ? <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Pick-Up</Typography>} >
                        <span><IconButton
                            onClick={async () => { await rfqConnection.pickUpRfq(params.row.id) }}
                            size="medium">
                            <HailOutlined />
                        </IconButton></span>
                    </Tooltip> : null}
                    {canTrade && canRfqBeDropped(params.id.toString()) ? <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>Drop</Typography>} >
                        <span><IconButton
                            onClick={async () => { await rfqConnection.dropRfq(params.row.id) }}
                            size="medium">
                            <LogoutOutlined />
                        </IconButton></span>
                    </Tooltip> : null}
                    <Tooltip TransitionProps={{ timeout: 600 }} title={<Typography>View Order</Typography>} >
                        <span><IconButton
                            onClick={() => { viewOrder(params.row.id) }}
                            size="medium">
                            <PageviewOutlined />
                        </IconButton></span>
                    </Tooltip>
                </ButtonGroup>,
                ...colBase
            }];

        setColumns(cols);
    }, [canRfqBeDropped, canRfqBeFilled, canRfqBePickedUp, startFillOrderProcess, viewOrder]);


    const updateRowsA = useCallback((o: RfqSummary[], fDate?: Moment) => {
        if (fDate === undefined)
            fDate = filterDate;

        var missingProducts = new Array<number>();
        const rowsI = o ?
            o.filter(a => a !== undefined && a.orderType === OrderType.FixingOrder &&
                (fDate === undefined || fDate === null || moment(a.goodUntil)?.startOf('day')?.isSame(fDate?.startOf('day'))))
                .sort((a, b) => b.rfqId - a.rfqId)
                .map((r) => {
                    if (!productMap.current.get(r.productId)) {
                        var spec = productStoreInstance.getProductById(r.productId)?.spec?.commodityVanilla;
                        if (!spec && !productsRequested.current.has(r.productId)) {
                            productsRequested.current.add(r.productId);
                            missingProducts.push(r.productId)
                        }
                        productMap.current.set(r.productId, spec);
                    }

                    var prod = productMap.current.get(r.productId);
                    var leg = prod?.legs[0];
                    var leg2 = prod?.legs.length > 1 ? prod.legs[1] : undefined;
                    var asset = assetsStoreInstance.getAsset(leg?.assetId);
                    var fixingDate = moment(r.goodUntil).format("yyyy-MM-DD");
                    var fixingIndexStr = isNaN(Number(r?.fixingIndex)) ? r.fixingIndex : asset?.fixingIndices?.filter(f => f?.fixingIndexId === Number(r?.fixingIndex))[0]?.name;
                    var rowA = {
                        id: r.rfqId,
                        parentPath: `${fixingDate}¬${asset?.name ?? "Unknown"}¬${fixingIndexStr}`,
                        path: `${fixingDate}¬${asset?.name ?? "Unknown"}¬${fixingIndexStr}¬Rfq ${r.rfqId}`,
                        orderTypeString: OrderType[r.orderType],
                        payoffSubType: prod?.type,
                        periodType: leg?.periodType,
                        period: leg?.period,
                        asset: asset?.name,
                        fixingIndexPayoff: isNaN(Number(leg?.fixingIndex)) ? leg?.fixingIndex : asset?.fixingIndices.filter(f => f.fixingIndexId === Number(leg?.fixingIndex)),
                        fixingIndexStr: fixingIndexStr,
                        isBuyOrder: r.size > 0,
                        tifStr: r.orderType === OrderType.FixingOrder ? moment(r.goodUntil)?.format("yyyy-MM-DD") : TifToString(GoodUntil[r.timeInForce]),
                        portfolio: r.metaData["Portfolio"],
                        prompt: leg2?.period ? moment(leg2.period) : undefined,
                        ...r
                    };

                    return rowA;
                }) : new Array<any>();

        var rowGroups = _.groupBy(rowsI, r => r.parentPath);
        var additionalRowId = _.max(rowsI.map(r => r.id)) + 5000;
        Object.keys(rowGroups).forEach(k => {
            additionalRowId++;
            var x = rowGroups[k];
            var netSize = _.sum(x.map(y => y.size));
            var parts = k.split('¬');
            rowsI.push({
                id: additionalRowId,
                size: netSize,
                isBuyOrder: netSize > 0,
                asset: parts[1],
                tifStr: moment(parts[0]).format("yyyy-MM-DD"),
                fixingIndexStr: parts[2],
                path: k
            })
        });

        if (missingProducts.length > 0) {
            LoadProductSummaries(missingProducts);
        }
        setRows(rowsI);
        setAwaitingRefresh(false);
    }, [filterDate]);

    const onRfqUpdate = useCallback((fDate?: Moment) => {
        var rfqz = rfqSummaryStoreInstance.GetAllRfqs();
        setRfqs(rfqz);
        updateRowsA(rfqz, fDate);
    }, [updateRowsA]);

    const onProductUpdate = useCallback(() => {
        onRfqUpdate();
    }, [onRfqUpdate])

    useEffect(() => {
        if (eventSubscriptionRfqs?.current?.remove)
            eventSubscriptionRfqs.current.remove();
        if (eventSubscriptionProducts?.current?.remove)
            eventSubscriptionProducts.current.remove();

        eventSubscriptionRfqs.current = rfqSummaryStoreInstance.addChangeListener(onRfqUpdate);
        eventSubscriptionProducts.current = productStoreInstance.addChangeListener(onProductUpdate);
        var subscriptionRfqs = eventSubscriptionRfqs;
        var subscriptionProducts = eventSubscriptionProducts;
        defineColumns();
        return function CleanUp() {
            subscriptionRfqs?.current?.remove();
            subscriptionProducts?.current?.remove();
        }
    }, [onRfqUpdate, defineColumns, onProductUpdate])

    useEffect(() => {
        if (!Boolean(rfqs)) {
            onRfqUpdate();
        }
        else if (!rows)
            updateRowsA(rfqs);

    }, [rfqs, rows, onRfqUpdate, updateRowsA])

    function customToolbar(props: any) {
        return (
            <CssBaseline>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <Grid container direction="row" flexDirection="row" justifyContent="space-between" paddingTop="0.5em" paddingBottom="0.5em">
                            <Grid container item width="60%" paddingBottom={"3px"}>
                                <Grid item><Button disabled={awaitingRefresh} className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<RefreshOutlined />} onClick={() => onClickRefresh()}>Refresh</Button></Grid>
                                <Grid item><GridToolbarExport {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                                <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" style={{ height: "2.4em" }} /></Grid>
                                <Grid item><GridToolbarDensitySelector {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                                <Grid item><GridToolbarColumnsButton {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                                {/* <Grid item><Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={multiSelect ? <DoneOutline /> : <DoneAllOutlined />} onClick={() => setMultiSelect(!multiSelect)}>{multiSelect ? "Single" : "Multi"}</Button></Grid> */}
                            </Grid>
                            <Grid container item width="40%" justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <WrappedDatePicker
                                        style={{ width: "120px" }}
                                        value={filterDate}
                                        onChange={(d) => { setFilterDate(d); onRfqUpdate(d ?? null) }}
                                        size='small'
                                        minDate={moment().startOf('day').toDate()}
                                        emptyLabel="All"
                                        clearLabel="All"
                                        label={"Fixing Date"} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </GridToolbarContainer>
                </ThemeProvider>
            </CssBaseline>
        );
    }

    function onClickRefresh() {
        RefreshRfqSummaries();
        setAwaitingRefresh(true);
    }

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null,
        );
    };

    const TifToString = (tif: string) => {
        switch (tif) {
            case "GoodForDay":
                return "Day";
            case "GoodUntilCancelled":
                return "GTC";
            case "ImmediateOrCancel":
                return "IoC";
            case "FillOrKill":
                return "FoK";
            case "Time":
                return "Time";
        }
    }

    const handleClose = () => {
        setContextMenu(null);
    };

    var columnVisibility: GridColumnVisibilityModel = {};
    columns.forEach(col => {
        columnVisibility[col.field] = !defaultHiddenCols.includes(col.field);
    });

    return (<ThemeProvider theme={getFormTheme()}>
        <div className="PositionSummaryTab">
            <Dialog key="fillBox"
                open={canTrade && showFillBox}
                onClose={() => setShowFillBox(false)}>
                <DialogTitle>Fill Order{selectedRows?.length === 1 ? ` ${selectedRows[0]}` : "s"}?</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} paddingTop="1em">
                        <Grid item><WrappedNumberInput
                            value={fillPrice}
                            id="fillPrice"
                            label="Fill Price"
                            onChange={(v) => setFillPrice(v)} />
                        </Grid>
                        <Grid item><WrappedNumberInput
                            value={fillSize}
                            id="fillSize"
                            label="Fill Size"
                            onChange={(v) => setFillSize(v)} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowFillBox(false)} startIcon={<CancelOutlined />}>Cancel</Button>
                    <Button disabled={!(fillPrice > 0 && fillSize > 0)} onClick={finishFillOrderProcess} startIcon={<FilterAltOutlined />}>Fill</Button>
                </DialogActions>
            </Dialog>
            {columns?.length > 0 && <DataGridPro
                style={{ border: "none" }}
                rows={rows}
                columns={columns}
                initialState={{
                    columns: { columnVisibilityModel: columnVisibility },
                }}
                apiRef={apiRef}
                editMode="row"
                hideFooter
                treeData
                groupingColDef={groupingColDef}
                getTreeDataPath={(row) => row.path.split('¬')}
                density={userStoreInstance.GetGridDensity()}
                components={{
                    Toolbar: customToolbar,
                }}
                componentsProps={{
                    row: {
                        onContextMenu: handleContextMenu,
                        style: { cursor: 'context-menu' },
                    },
                }}
                classes={{
                    panelWrapper: "GridFilterForm",
                    panel: "GridFilterForm",
                    filterForm: "GridFilterForm",
                    columnsPanel: "GridFilterForm",

                }} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                            e.preventDefault();
                            handleClose();
                        },
                    },
                }}
                classes={{ list: "GridOptionMenu" }}>
                <MenuItem disabled>Rfq {selectedRow} - {rows.filter(x => x.id === selectedRow)[0]?.description}</MenuItem>
                <MenuItem onClick={() => viewOrder(rows.filter(x => x.id === selectedRow)[0]?.rfqId)}>
                    <ListItemIcon><PageviewOutlined /></ListItemIcon>
                    <ListItemText>View Details</ListItemText>
                </MenuItem>
                {canTrade && selectedRow && canRfqBeDropped(selectedRow.toString()) && <MenuItem onClick={() => { rfqConnection.dropRfq(rows.filter(x => x.id === selectedRow)[0]?.rfqId); handleClose() }}>
                    <ListItemIcon><LogoutOutlined /></ListItemIcon>
                    <ListItemText>Drop</ListItemText>
                </MenuItem>}
                {canTrade && selectedRow && canRfqBePickedUp(selectedRow.toString()) && <MenuItem onClick={() => { rfqConnection.pickUpRfq(rows.filter(x => x.id === selectedRow)[0]?.rfqId); handleClose() }}>
                    <ListItemIcon><HailOutlined /></ListItemIcon>
                    <ListItemText>Pick Up</ListItemText>
                </MenuItem>}
                {canTrade && selectedRow && canRfqBeFilled(selectedRow.toString()) && <MenuItem onClick={() => startFillOrderProcess(rows.filter(x => x.id === selectedRow)[0]?.rfqId)}>
                    <ListItemIcon><EditOutlined /></ListItemIcon>
                    <ListItemText>Fill Order</ListItemText>
                </MenuItem>}
                {canTrade && selectedRow && canRfqBeCancelled(selectedRow.toString()) && <MenuItem onClick={() => { rfqConnection.cancelRfq(rows.filter(x => x.id === selectedRow)[0]?.rfqId); handleClose() }}>
                    <ListItemIcon><CancelOutlined /></ListItemIcon>
                    <ListItemText>Cancel Order</ListItemText>
                </MenuItem>}
            </Menu>
        </div>
    </ThemeProvider>);
}