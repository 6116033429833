import React from 'react';
import FBEmitter from 'fbemitter';
import BasketDesigner from './basketDesigner';
import { Asset } from '../../assets/assetsModels';
import { BasketDefinition } from '../../qwack/basketDefinition';
import { Wrapper } from '../../payoff/models/wrapper';
import { StringTypeDropDown } from '../../inputs/assetGroupDropDown';
import BarrierDetailDesigner from './barrierDetails';
import { BarrierDetail } from '../../payoff/barrierDetail';
import AutocallDatesDesigner from './autocallDatesDesigner';
import { DateFixedRelative } from '../../payoff/models/dateFixedRelative'
import WrapperDetailBox from './wrapperDetailBox';
import { WrappedPayoff } from '../../payoff/models/wrappedPayoff';
import NotionalAndCurencyBox from './notionalAndCurrencyBox';
import { OptionStructure } from '../../payoff/models/optionStructure';
import { VanillaOptionLeg } from '../../payoff/models/vanillaOptionLeg';
import { OptionStructureType } from '../../payoff/models/optionStructureType';
import { BarrierOptionLeg } from '../../payoff/models/barrierOptionLeg';
import { OptionType } from '../../qwack/optionType';
import { NoteDetail } from '../../payoff/models/noteDetail';
import { BarrierObservationType, BarrierSide, BarrierType } from '../../qwack/barrierEnums';
import ValidatedNumericInput from '../../inputs/validatedNumericPctInput';
import { Grid, Typography } from '@mui/material';
import { rowProps, titleProps, titleVariant, valueProps } from './helpers';

export type ReverseConvertiblePanelProps = {
    definitionUpdate: (state: OptionStructure) => void,
    initialDefinition: OptionStructure,
    allAssets: Asset[],
    disabled: boolean,
    extraKey: string
}

export type ReverseConvertiblePanelState = {
    definition: OptionStructure;
    basket: BasketDefinition;
    startDate: DateFixedRelative;
    endDate: DateFixedRelative;
    subType: string;
    possibleWrappers: string[];
    putStrike: number,
    barrierDetail: BarrierDetail
}

const typeNoBarrier = "No Barrier";
const typeBarrier = "Barrier";
const subTypes = [typeNoBarrier, typeBarrier]

export class ReverseConvertiblePanel extends React.Component<ReverseConvertiblePanelProps, ReverseConvertiblePanelState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: ReverseConvertiblePanelProps) {
        super(props);

        const def = this.props.initialDefinition;

        let barrierDetail = {
            level: 50,
            barrierObservationType: BarrierObservationType.Continuous,
            barrierSide: BarrierSide.Down,
            barrierType: BarrierType.KnockIn
        } as BarrierDetail
        if (def && def.barrierOptionLegs &&
            def.barrierOptionLegs.length > 0 &&
            def.barrierOptionLegs[0].barrier1Detail)
            barrierDetail = def.barrierOptionLegs[0].barrier1Detail;

        let basket = {} as BasketDefinition;
        if (def.vanillaLegs && def.vanillaLegs.length > 0)
            basket = def.vanillaLegs[0].underlying;
        else if (def.barrierOptionLegs && def.barrierOptionLegs.length > 0)
            basket = def.barrierOptionLegs[0].underlying;

        let definition = def ?? { optionStructureType: OptionStructureType.SingleOption } as OptionStructure;

        this.state = {
            definition: definition,
            possibleWrappers: [],
            basket: basket,
            subType: typeNoBarrier,
            startDate: {} as DateFixedRelative,
            endDate: {} as DateFixedRelative,
            putStrike: 100,
            barrierDetail: barrierDetail
        }
        this.basketDefinitionUpdate = this.basketDefinitionUpdate.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.updateWrapperDetail = this.updateWrapperDetail.bind(this);
        this.onChangeSubtype = this.onChangeSubtype.bind(this);
        this.onChangePutStrike = this.onChangePutStrike.bind(this);
        this.barrierDetailUpdate = this.barrierDetailUpdate.bind(this);
        this.updateCoupon = this.updateCoupon.bind(this);
    }

    public componentWillUnmount() {

    }

    async componentDidMount() {
        this.fillPossibleWrappers();
       //this.fillPossibleWrappers();
        const { definition } = this.state;

        if (!this.state.definition.wrapperType) {
            definition.wrapperType = Wrapper.Note;
            if (!definition.noteDetail) {
                definition.noteDetail = { redemptionPercentage: 100, fixedCouponPercentage: 5 } as NoteDetail;
            }
        }
        if (!definition.digitalOptionLegs && !definition.vanillaLegs) {
            this.updateDefOnSubtypeChange(typeNoBarrier, definition);
        }

        this.setState({ definition });
    }

    public componentDidUpdate() {

    }

    fillPossibleWrappers = () => {
        const possibleWrappers = Object.keys(Wrapper).filter(k => typeof Wrapper[k as any] === "number");
        this.setState({ possibleWrappers });
    }

    basketDefinitionUpdate(basket: BasketDefinition) {
        const { definition, subType } = this.state;

        if (subType === typeNoBarrier) {
            if (!definition.vanillaLegs)
                definition.vanillaLegs = new Array<VanillaOptionLeg>();
            if (definition.vanillaLegs.length === 0)
                definition.vanillaLegs.push({
                    callPutStraddle: OptionType.put,
                    underlying: {} as BasketDefinition
                } as VanillaOptionLeg)
            definition.vanillaLegs[0].underlying = basket;
        }
        else if (subType === typeBarrier) {
            if (!definition.barrierOptionLegs)
                definition.barrierOptionLegs = new Array<BarrierOptionLeg>();
            if (definition.barrierOptionLegs.length === 0)
                definition.barrierOptionLegs.push({
                    callPutStraddle: OptionType.put,
                    underlying: {} as BasketDefinition
                } as BarrierOptionLeg)
            definition.barrierOptionLegs[0].underlying = basket;
        }

        this.setState({ definition, basket });
        this.props.definitionUpdate(definition);
    }

    onChangeSubtype(newSubType: string) {
        const { subType, definition } = this.state;
        if (subType !== newSubType) {
            this.updateDefOnSubtypeChange(newSubType, definition);
            this.setState({ subType: newSubType, definition });
        }
    }

    updateDefOnSubtypeChange(newSubType: string, definition: OptionStructure) {
        const { putStrike, barrierDetail, basket, endDate } = this.state;
        switch (newSubType) {
            case typeNoBarrier:
                definition.barrierOptionLegs = [];
                definition.vanillaLegs = [{
                    strike: putStrike,
                    underlying: basket,
                    callPutStraddle: OptionType.put,
                    expiryDate: endDate,
                    isCashSettled: true,
                    legWeight: -1.0
                } as VanillaOptionLeg];
                break;
            case typeBarrier:
                definition.barrierOptionLegs = [{
                    strike: putStrike,
                    underlying: basket,
                    callPutStraddle: OptionType.put,
                    expiryDate: endDate,
                    isCashSettled: true,
                    legWeight: -1.0,
                    hasBarrier1: true,
                    barrier1Detail: barrierDetail
                } as BarrierOptionLeg,];
                definition.vanillaLegs = [];
                break;
        }
    }

    onChangePutStrike(val: number) {
        const { definition, subType } = this.state;
        if (subType === typeNoBarrier) {
            definition.vanillaLegs[0].strike = val;
        }
        else if (subType === typeBarrier) {
            definition.barrierOptionLegs[0].strike = val;
        }
        this.setState({ definition, putStrike: val });
        this.props.definitionUpdate(definition);
    }

    setWrapperType = (wrapperType: string) => {
        var wt = (Wrapper as any)[wrapperType];
        const { definition } = this.state;
        definition.wrapperType = wt;
        this.setState({ definition });
    }

    barrierDetailUpdate(deets: BarrierDetail) {
        const { definition } = this.state;
        definition.barrierOptionLegs[0].barrier1Detail = deets;

        this.setState({ definition, barrierDetail: deets });
        this.props.definitionUpdate(definition);
    }

    updateStartDate(startDate: DateFixedRelative) {
        const { definition } = this.state;
        definition.strikeDate = startDate;
        this.setState({ startDate, definition })
        this.props.definitionUpdate(definition);
    }
    updateEndDate(endDate: DateFixedRelative) {
        const { definition } = this.state;
        if (definition.vanillaLegs)
            definition.vanillaLegs.forEach(vl => {
                vl.expiryDate = endDate
            });
        if (definition.barrierOptionLegs)
            definition.barrierOptionLegs.forEach(bl => {
                bl.expiryDate = endDate
            });
        this.setState({ endDate, definition })
        this.props.definitionUpdate(definition);
    }
    updateCoupon(val: number[]) {
        const { definition } = this.state;
        if (!definition.noteDetail)
            definition.noteDetail = {} as NoteDetail;
        definition.noteDetail.fixedCouponPercentage = val[0];
        this.setState({ definition });
        this.props.definitionUpdate(definition);
    }

    updateWrapperDetail(detail: WrappedPayoff) {
        const { definition } = this.state;
        definition.structuredSwapDetail = detail.structuredSwapDetail;
        definition.otcOptionDetail = detail.otcOptionDetail;
        definition.noteDetail = detail.noteDetail;
        definition.nominal = detail.nominal;
        definition.payoffCurrency = detail.payoffCurrency;
        definition.currencyConversionType = detail.currencyConversionType;
        definition.solveFor = detail.solveFor;
        this.setState({ definition })
        this.props.definitionUpdate(definition);
    }

    render() {
        const { definition, possibleWrappers, subType, basket, startDate, endDate } = this.state;
        const { allAssets } = this.props;
        let putStrike = definition.vanillaLegs && definition.vanillaLegs.length > 0 ? definition.vanillaLegs[0].strike : 100;
        let barrierDetails = definition.barrierOptionLegs && definition.barrierOptionLegs.length > 0 ? definition.barrierOptionLegs[0].barrier1Detail : { barrierSide: BarrierSide.Down, barrierType: BarrierType.KnockIn } as BarrierDetail;
        let showbarrierDetail = subType === typeBarrier;
        let coupon = definition.noteDetail?.fixedCouponPercentage ?? 0;

        return (
            <Grid item container>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Wrapper</Typography></Grid>
                    <Grid {...valueProps}><StringTypeDropDown possibles={possibleWrappers} selected={possibleWrappers[definition.wrapperType]} onChange={this.setWrapperType} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Basket</Typography></Grid>
                    <Grid {...valueProps}><BasketDesigner extraKey={this.props.extraKey} keyName="bpcBasket" initialBasketDefinition={basket} allAssets={allAssets} stateUpdate={this.basketDefinitionUpdate} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Option Type</Typography></Grid>
                    <Grid {...valueProps}><StringTypeDropDown possibles={subTypes} selected={subType} onChange={this.onChangeSubtype} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Put Strike</Typography></Grid>
                    <Grid {...valueProps}><ValidatedNumericInput units="%" keyName="putStrikePct" placeholder="Put" style={{width:"120px"}} initialValue={putStrike} onChange={(v) => { this.onChangePutStrike(v); return true; }} disabled={this.props.disabled} /></Grid>
                </Grid>
                {!showbarrierDetail ? null :
                    <Grid {...rowProps}>
                        <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Barier</Typography></Grid>
                        <Grid {...valueProps}><BarrierDetailDesigner disabled={this.props.disabled} stateUpdate={this.barrierDetailUpdate} initialBarrierDefinition={barrierDetails} /></Grid>
                    </Grid>
                }
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Dates &amp; Coupons</Typography></Grid>
                    <Grid {...valueProps}><AutocallDatesDesigner initialStartDate={startDate} initialEndDate={endDate} initialCoupons={[coupon]} updateStartDate={this.updateStartDate} updateEndDate={this.updateEndDate} updateCoupons={this.updateCoupon} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Wrapper Details</Typography></Grid>
                    <Grid {...valueProps}><WrapperDetailBox initialDetail={definition} updateWrapperDetail={this.updateWrapperDetail} disabled={this.props.disabled} /></Grid>
                </Grid>
                <Grid {...rowProps}>
                    <Grid {...titleProps}><Typography textAlign='center' variant={titleVariant}>Notional &amp; Currency</Typography></Grid>
                    <Grid {...valueProps}><NotionalAndCurencyBox initialDetail={definition} updateWrapperDetail={this.updateWrapperDetail} disabled={this.props.disabled} /></Grid>
                </Grid>
            </Grid>)
    }

}
export default ReverseConvertiblePanel;