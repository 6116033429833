import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import FBEmitter from 'fbemitter';
import React from 'react';
import { FetchInstrumentWithQuery, UpdateListedInstrument } from './listedInstrumentActions';
import { BondAdditionalData, ListedInstrument, ListedInstrumentQuery } from './listedInstrumentModels';
import listedInstrumentStoreInstance from './listedInstrumentStore';
import { getFormTheme } from '../inputs/formCommon';
import { QueryBuilderOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { BondEditorForm } from './bondEditorForm';

interface BondEditorState {
    selectedInstruments: ListedInstrument[],
    matchingInstruments: ListedInstrument[],
    checked: number[];
    checkAll: boolean;
    queryRunning: boolean;
    query: ListedInstrumentQuery;
    showQuery: boolean;
}

export class BondEditor extends React.Component<{}, BondEditorState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        this.state = {
            selectedInstruments: [],
            matchingInstruments: [],
            checked: [],
            checkAll: false,
            queryRunning: false,
            query: { uniqueId: v4(), type: "Bond" } as ListedInstrumentQuery,
            showQuery: true
        };;

        this.onInsSelect = this.onInsSelect.bind(this);
        this.queryUpdate = this.queryUpdate.bind(this);
        this.onEnterPressed = this.onEnterPressed.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.queryUpdate);
    }

    async onSubmit(values: any, { setSubmitting, setTouched }: FormikHelpers<ListedInstrument>) {
        console.log(JSON.stringify(values, null, 2));
        values.metaData.forEach(m => {
            listedInstrumentStoreInstance.insertMetaValueByType(m.category, m.type, m.data);
        });

        if (values.callFeatureType || values.callSchedule || values.callNoticeDays || values.sinkingSchedule || values.firstCallDate || values.redemptionType) {
            values.bondAdditionalData = {
                callFeatureType: values.callFeatureType,
                callNoticeDays: values.callNoticeDays,
                schedule: values.callSchedule,
                redemptionType: values.redemptionType,
                firstCallDate: values.firstCallDate,
                sinkingSchedule: values.sinkingSchedule
            } as BondAdditionalData
        }

        await UpdateListedInstrument(values);

        setSubmitting(false);
        setTouched({}, false);
    }

    queryUpdate() {
        const { query } = this.state;
        var ins = listedInstrumentStoreInstance.getInstrumentQuery(query.uniqueId);
        if (!ins)
            return;
        this.setState({ matchingInstruments: ins, queryRunning: false, showQuery: false });
    }

    private onInsSelect(ins: ListedInstrument | string) {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            this.setState({ selectedInstruments: [solidIns] });
        }
    }

    private handleToggle = (value: number) => () => {
        const checked = [value];
        this.setState({ checked });
    };

    async runQuery(query: ListedInstrumentQuery) {
        this.setState({ queryRunning: true });
        await FetchInstrumentWithQuery(query);
    }

    renderInstrumentQuery() {
        const { query, queryRunning } = this.state;
        return (
            <div><StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2}>
                        <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.maturityFromDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.maturityFromDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                label={"Maturity From"}
                                emptyLabel="(None)" />
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.maturityToDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.maturityToDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                label={"Maturity To"}
                                emptyLabel="(None)" />
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Description"}
                                placeholder="Description"
                                onKeyDown={this.onEnterPressed}
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.description = e.target.value;
                                    this.setState({ query: q });
                                }} />
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Instrument Id"}
                                placeholder="Instrument Id"
                                onKeyDown={this.onEnterPressed}
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.listedInstrumentId = parseInt(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.query)} disabled={queryRunning}>Run Query</Button>
                        </Grid>
                        {queryRunning ?
                            <Grid item className="ListedInstrumentQueryItem">
                                <CircularProgress />
                            </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider></div>
        );
    }

    onEnterPressed(e) {
        if (e.keyCode === 13)
            this.runQuery(this.state.query);
    }

    render() {
        const { matchingInstruments, checked, showQuery } = this.state;
        const selectedInstruments = matchingInstruments.filter(i => checked.includes(i.listedInstrumentId));

        if (selectedInstruments && selectedInstruments[0]) {
            selectedInstruments[0]["callFeatureType"] = selectedInstruments[0]?.bondAdditionalData?.callFeatureType;
            selectedInstruments[0]["callNoticeDays"] = selectedInstruments[0]?.bondAdditionalData?.callNoticeDays;
            selectedInstruments[0]["callSchedule"] = selectedInstruments[0]?.bondAdditionalData?.schedule;
            selectedInstruments[0]["redemptionType"] = selectedInstruments[0]?.bondAdditionalData?.redemptionType;
            selectedInstruments[0]["sinkingSchedule"] = selectedInstruments[0]?.bondAdditionalData?.sinkingSchedule;
            selectedInstruments[0]["firstCallDate"] = selectedInstruments[0]?.bondAdditionalData?.firstCallDate;
        }

        console.log("Selected", selectedInstruments[0])
        return (
            <div className="ListedInstrumentAdvSearchTab">
                <div className="ListedInstrumentAdvSearchTabSearch">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <Accordion expanded={showQuery} onChange={() => this.setState({ showQuery: !showQuery })} className="ListedInstrumentAdvSearchTabSearchTop">
                                <AccordionSummary>Search Query</AccordionSummary>
                                <AccordionDetails>{this.renderInstrumentQuery()}</AccordionDetails>
                            </Accordion>
                            <div className="ListedInstrumentAdvSearchTabSearchBottomSection">
                                <div className="ListedInstrumentAdvSearchTabSearchResults">
                                    <List key="ListedInstrumentAdvSearchTabSearch">
                                        {matchingInstruments.slice(0, 50).map(s => {
                                            const labelId = `checkbox-list-label-${s.description}`;
                                            return (
                                                <ListItem key={s.listedInstrumentId} role={undefined} selected={checked.indexOf(s.listedInstrumentId) !== -1} dense button onClick={this.handleToggle(s.listedInstrumentId)}>
                                                    <ListItemText id={labelId} primary={s.description} />
                                                </ListItem>);
                                        })}
                                    </List>
                                </div>
                                <div className="ListedInstrumentAdvSearchTabResultForm">
                                    {selectedInstruments && selectedInstruments.length > 0 ?
                                        <Paper elevation={1} >
                                            <Formik
                                                onSubmit={this.onSubmit}
                                                enableReinitialize={true}
                                                initialValues={selectedInstruments[0]}
                                                validate={() => ({})}>
                                                {props => <BondEditorForm className="ListedInstrumentAdvSearchTabResultFormInner" {...props}
                                                    callNoticeDays={selectedInstruments[0]?.bondAdditionalData?.callNoticeDays}
                                                    callSchedule={selectedInstruments[0]?.bondAdditionalData?.schedule}
                                                />}
                                            </Formik>
                                        </Paper> : null}
                                </div>
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            </div>
        );
    }
}
