import { MenuItem, TextField } from "@mui/material";
import moment from "moment";
import { WrappedDatePicker } from "../inputs/wrappedDatePicker";
import { WrappedSelect } from "../inputs/wrappedSelect";
import { WrappedTimePicker } from "../inputs/wrappedTimePicker";
import { JobSpec, NewJobRequest } from "./jobModels";

export const renderInput = (key: string, type: string, defaultValue: string, setValue: (key: string, value: string) => void, selectedJobSpec: JobSpec, selectedJob: NewJobRequest) => {
    switch (type) {
        case "Integer":
            var valAsNum = parseInt(selectedJob.params[key]);
            return (
                <TextField
                    variant="outlined"
                    size="small"
                    error={Number.isNaN(valAsNum)}
                    key={key}
                    id={key}
                    value={selectedJob.params[key] ?? ""}
                    onChange={(e) => setValue(key, e.target.value as string)}
                    label={key} />
            );
        case "Float":
            var valAsNumF = Number(selectedJob.params[key]);
            return (
                <TextField
                    variant="outlined"
                    size="small"
                    error={Number.isNaN(valAsNumF)}
                    key={key}
                    id={key}
                    value={selectedJob.params[key] ?? ""}
                    onChange={(e) => setValue(key, e.target.value as string)}
                    label={key} />
            );
        case "String":
            return (
                <TextField
                    variant="outlined"
                    size="small"
                    key={key}
                    id={key}
                    value={selectedJob.params[key] ?? ""}
                    onChange={(e) => setValue(key, e.target.value as string)}
                    label={key} />
            );
        case "Boolean":
            return (
                <WrappedSelect
                    style={{ minWidth: "120px" }}
                    id={key}
                    name={key}
                    label={key}
                    value={selectedJob.params[key]}
                    onChange={(e) => setValue(key, e.target.value as string)}>
                    <MenuItem key={"jobboolT" + key} value={"True"}>True</MenuItem>
                    <MenuItem key={"jobboolF" + key} value={"False"}>False</MenuItem>
                </WrappedSelect>);
        case "DateTime":
        case "DateTime?":
            return (
                <WrappedDatePicker
                    size="small"
                    value={selectedJob.params[key] ? new Date(selectedJob.params[key]) : null}
                    onChange={(d) => setValue(key, d?.format("yyyy-MM-DD"))}
                    label={key}
                    emptyLabel="Pick date..."
                />);
        case "Time":
        case "Time?":
            return (
                <WrappedTimePicker
                    size="small"
                    value={selectedJob.params[key] ? moment(selectedJob.params[key]) : null}
                    onChange={(d) => setValue(key, d?.format("HH-mm-ss"))}
                    label={<div>{key}</div>}
                    emptyLabel="Pick time..."
                />);
        case "Enum":
            var possibleValues = selectedJobSpec.paramEnumValues[key];
            if (possibleValues)
                return (
                    <WrappedSelect
                        style={{ minWidth: "150px" }}
                        id={key}
                        name={key}
                        label={key}
                        value={selectedJob.params[key] ?? `Select ${key}...`}
                        onChange={(e) => setValue(key, e.target.value as string)}>
                        <MenuItem key={key + "null"} value={`Select ${key}...`}>{`Select ${key}...`}</MenuItem>
                        {possibleValues.map(pv => <MenuItem key={key + pv} value={pv}>{pv}</MenuItem>)}
                    </WrappedSelect>);
            else
                return null;
        case "File":
        case "File?":
            return (<input type="file" name="key" onChange={async (event) => setValue(key, await event.target.files[0].text())} />);
    }
}