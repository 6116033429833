import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from '@mui/material';
import FBEmitter from 'fbemitter';
import React from 'react';
import { BulkMetaUpdate, FetchInstrumentWithQuery } from './listedInstrumentActions';
import { ListedInstrument, ListedInstrumentMetaData, ListedInstrumentQuery } from './listedInstrumentModels';
import listedInstrumentStoreInstance from './listedInstrumentStore';
import { getFormTheme } from '../inputs/formCommon';
import { AddOutlined, QueryBuilderOutlined, RemoveOutlined } from '@mui/icons-material';
import { v4 } from 'uuid';
import Autocomplete from '@mui/material/Autocomplete';
import { WrappedDatePicker } from '../inputs/wrappedDatePicker';
import { WrappedSelect } from '../inputs/wrappedSelect';


interface InstrumentBulkUpdateState {
    selectedInstrument: ListedInstrument | undefined,
    matchingInstruments: ListedInstrument[],
    queryRunning: boolean;
    query: ListedInstrumentQuery;
    showQuery: boolean;
    updateRunning: boolean;
    metaDatas: ListedInstrumentMetaData[];
    lastUpdateCount: number | undefined;
    lastUpdateIndex: number | undefined;
}

export const NullType = "Select Type..."
export const InstrumentTypes = ["Index", "Option", "Equity", "Bond", "CFD", "Cash", "Future"];
export const UlInstrumentTypes = ["Index", "Equity", "Bond", "Future"];
export const InsWithUlTypes = ["CFD", "Option", "Future"];
export const InsWithMaturity = ["Option", "Future"];

export class InstrumentBulkUpdate extends React.Component<{}, InstrumentBulkUpdateState>{
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        this.state = {
            selectedInstrument: undefined,
            matchingInstruments: [],
            queryRunning: false,
            query: { uniqueId: v4() } as ListedInstrumentQuery,
            showQuery: true,
            metaDatas: [],
            lastUpdateCount: undefined,
            lastUpdateIndex: undefined,
            updateRunning: false
        };;

        this.onInsSelect = this.onInsSelect.bind(this);
        this.queryUpdate = this.queryUpdate.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.queryUpdate);
    }

    queryUpdate() {
        const { query } = this.state;
        var ins = listedInstrumentStoreInstance.getInstrumentQuery(query.uniqueId);
        if (!ins)
            return;
        this.setState({ matchingInstruments: ins, queryRunning: false, showQuery: false });
    }

    private onInsSelect(ins: ListedInstrument | string) {
        const solidIns = ins as ListedInstrument;
        if (solidIns) {
            var metaDatas = this.state.metaDatas;
            if (metaDatas) {
                metaDatas.forEach(m => {
                    m.listedInstrumentId = solidIns.listedInstrumentId;
                })
            }
            this.setState({ selectedInstrument: solidIns, metaDatas, lastUpdateCount: undefined, lastUpdateIndex: undefined });
        }
    }

    async runQuery(query: ListedInstrumentQuery) {
        this.setState({ queryRunning: true });
        await FetchInstrumentWithQuery(query);
    }

    renderInstrumentQuery() {
        const { query, queryRunning } = this.state;
        const showMaturity = InsWithMaturity.includes(query.type);
        const showUlType = InsWithUlTypes.includes(query.type);
        const showOptionFields = query.type === "Option";

        return (
            <div><StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container spacing={2}>
                        <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect id="type"
                                name="type"
                                label="Type"
                                value={query.type ?? NullType}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.type = d.target.value as string;
                                    if (q.type === NullType)
                                        q.type = null;
                                    this.setState({ query: q });
                                }}>
                                {[NullType, ...InstrumentTypes].map(a =>
                                    <MenuItem key={"insType_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                        {showUlType ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect
                                style={{ width: "10em" }}
                                autoWidth
                                id="ulType"
                                name="ulType"
                                label="Underlying Type"
                                value={query.underlyingType ?? NullType}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.underlyingType = d.target.value as string;
                                    if (q.underlyingType === NullType)
                                        q.underlyingType = null;
                                    this.setState({ query: q });
                                }}>
                                {[NullType, ...UlInstrumentTypes].map(a =>
                                    <MenuItem key={"insType_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid> : null}
                        {showMaturity ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.maturityFromDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.maturityFromDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                label={"Maturity From"}
                                emptyLabel="(None)" />
                        </Grid> : null}
                        {showMaturity ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedDatePicker
                                value={query.maturityToDate}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.maturityToDate = d.toDate();
                                    this.setState({ query: q });
                                }}
                                label={"Maturity To"}
                                emptyLabel="(None)" />
                        </Grid> : null}
                        {showOptionFields ? <Grid item className="ListedInstrumentQueryItem">
                            <WrappedSelect
                                id="callPut"
                                name="callPut"
                                label="Call/Put"
                                value={query.callPut ?? NullType}
                                onChange={(d) => {
                                    var q = this.state.query;
                                    q.callPut = d.target.value as string;
                                    if (q.callPut === NullType)
                                        q.callPut = null;
                                    this.setState({ query: q });
                                }}>
                                {[NullType, "Call", "Put"].map(a =>
                                    <MenuItem key={"insCp_" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid> : null}
                        {showOptionFields ? <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Strike"}
                                placeholder="Strike"
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.strike = parseFloat(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid> : null}
                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Description"}
                                placeholder="Description"
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.description = e.target.value;
                                    this.setState({ query: q });
                                }} />
                        </Grid>
                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Instrument Id"}
                                placeholder="Instrument Id"
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.listedInstrumentId = parseInt(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid>
                        {showUlType || query.type == null ? <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Underlying Id"}
                                placeholder="Underlying Id"
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.underlyingId = parseInt(e.target.value);
                                    this.setState({ query: q });
                                }} />
                        </Grid> : null}
                        <Grid item className="ListedInstrumentQueryItem">
                            <TextField
                                size="small"
                                variant="outlined"
                                label={"Exchange"}
                                placeholder="Exchange"
                                onChange={(e) => {
                                    var q = this.state.query;
                                    q.exchange = e.target.value;
                                    this.setState({ query: q });
                                }} />
                        </Grid>

                        <Grid item className="ListedInstrumentQueryItem">
                            <Button variant="outlined" className="PltfmButtonLite" startIcon={<QueryBuilderOutlined />} onClick={() => this.runQuery(this.state.query)} disabled={queryRunning}>Run Query</Button>
                        </Grid>
                        {queryRunning ?
                            <Grid item className="ListedInstrumentQueryItem">
                                <CircularProgress />
                            </Grid> : null}
                    </Grid>
                </ThemeProvider>
            </StyledEngineProvider></div>
        );
    }

    async onClickUpdate(metaData: ListedInstrumentMetaData, ix: number) {
        this.setState({ updateRunning: true });
        var updateCount = await BulkMetaUpdate(metaData);
        this.setState({ updateRunning: false, lastUpdateCount: updateCount, lastUpdateIndex: ix });
    }

    renderUpdateSection() {
        const { metaDatas, lastUpdateCount, lastUpdateIndex, updateRunning } = this.state;
        return (
            <div className="InsMetaDataBox">
                {metaDatas && metaDatas.length > 0 ? (
                    metaDatas.map((m: ListedInstrumentMetaData, ix) => (
                        <div key={ix} className="ListedInstrumentEditorFormMetaDataRow" style={{ paddingLeft: "5px" }}>
                            <Grid container spacing={1}>
                                <Grid item><Autocomplete
                                    disabled
                                    style={{ width: "200px" }}
                                    options={Array.from(listedInstrumentStoreInstance.getMetaDataCategories())}
                                    id={`metaData[${ix}].category`}
                                    value={m.category ?? ""}
                                    autoSelect
                                    onChange={(e, v) => { m.category = v; this.setState({ metaDatas }) }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Category"
                                            margin="normal"
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                />
                                </Grid>
                                <Grid item><Autocomplete
                                    freeSolo
                                    style={{ width: "200px" }}
                                    options={Array.from(listedInstrumentStoreInstance.getMetaDataTypesForCategory(m?.category))}
                                    id={`metaData[${ix}].type`}
                                    value={m.type ?? ""}
                                    autoSelect
                                    onChange={(e, v) => { m.type = v; this.setState({ metaDatas }) }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Label"
                                            margin="normal"
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                />
                                </Grid>
                                <Grid item><Autocomplete
                                    freeSolo
                                    style={{ width: "200px" }}
                                    options={m === undefined || m.type === undefined || m.type === null ? new Array<string>() : Array.from(listedInstrumentStoreInstance.getMetaDataByType(m?.category, m?.type))}
                                    id={`metaData[${ix}].data`}
                                    value={m.data ?? ""}
                                    autoSelect
                                    onChange={(e, v) => { m.data = v; this.setState({ metaDatas }) }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Data"
                                            margin="normal"
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, type: 'search', classes: { root: "ListedInstrumentEditorFormFieldInner" } }} />)}
                                />
                                </Grid>
                                <IconButton
                                    onClick={() => this.setState({ metaDatas: metaDatas.filter((x, ixx) => ixx !== ix), lastUpdateCount: undefined, lastUpdateIndex: undefined })}
                                    className="ListedInstrumentEditorFormFieldInner"
                                    size="large">
                                    <RemoveOutlined color="inherit" />
                                </IconButton>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Button className="MuiButton-outlined" style={{ height: "30px" }} onClick={async (e) => await this.onClickUpdate(m, ix)} disabled={updateRunning}>Update</Button>
                                </div>
                                {lastUpdateIndex === ix ? <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="body1">{lastUpdateCount} records updated</Typography>
                                </div> : null}
                            </Grid>
                        </div>
                    ))
                ) : null}
                <div key="addMetaDataToIns" className="ListedInstrumentEditorFormAddMeta" style={{ paddingBottom: "5px" }}>
                    <Button variant="outlined" onClick={() => this.addNewMetaDataRecord()} className="PltfmButtonLite">
                        <ListItemIcon className="ListedInstrumentEditorFormFieldInner"><AddOutlined color="inherit" /></ListItemIcon>
                        <ListItemText>Add Meta Data</ListItemText>
                    </Button>
                </div>
            </div>
        );
    }

    addNewMetaDataRecord() {
        const { selectedInstrument, metaDatas } = this.state;
        var newRecord = { listedInstrumentId: selectedInstrument.listedInstrumentId, lastUpdated: new Date(), category: "AdditionalData" } as ListedInstrumentMetaData
        metaDatas.push(newRecord)
        this.setState({ metaDatas, lastUpdateCount: undefined, lastUpdateIndex: undefined });
    }

    render() {
        const { matchingInstruments, selectedInstrument, showQuery } = this.state;

        return (
            <div className="ListedInstrumentAdvSearchTab">
                <div className="ListedInstrumentAdvSearchTabSearch">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={getFormTheme()}>
                            <Accordion expanded={showQuery} onChange={() => this.setState({ showQuery: !showQuery })} className="ListedInstrumentAdvSearchTabSearchTop">
                                <AccordionSummary>Search Query</AccordionSummary>
                                <AccordionDetails>{this.renderInstrumentQuery()}</AccordionDetails>
                            </Accordion>
                            <div className="ListedInstrumentAdvSearchTabSearchBottomSection">
                                <div className="ListedInstrumentAdvSearchTabSearchResults">
                                    <List key="ListedInstrumentAdvSearchTabSearch">
                                        {matchingInstruments.slice(0, 50).map(s => {
                                            const labelId = `checkbox-list-label-${s.description}`;
                                            return (
                                                <ListItem key={s.listedInstrumentId} role={undefined} selected={s === selectedInstrument} dense button onClick={(e) => this.onInsSelect(s)}>
                                                    <ListItemText id={labelId} primary={s.description} />
                                                </ListItem>);
                                        })}
                                    </List>
                                </div>
                                {selectedInstrument ?
                                    <div className="ListedInstrumentAdvSearchTabResultForm">
                                        <Typography variant="h6">{selectedInstrument.description}</Typography>
                                        <Paper elevation={1} >
                                            {this.renderUpdateSection()}
                                        </Paper>
                                    </div> : null}
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </div>
            </div>
        );
    }
}
