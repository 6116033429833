import React from 'react';
import FBEmitter from 'fbemitter';
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export type RfqDashboardMainDisplayBoxProps = {
    content: JSX.Element;
    title: string;
    width?: number
}

interface RfqDashboardMainDisplayBoxState {
    hover: boolean;
    collapsed: boolean;
}

export class RfqDashboardMainDisplayBox extends React.Component<RfqDashboardMainDisplayBoxProps, RfqDashboardMainDisplayBoxState> {
    eventSubscriptionProducts: FBEmitter.EventSubscription | undefined;
    constructor(props: RfqDashboardMainDisplayBoxProps) {
        super(props);
        this.state = {
            hover: false,
            collapsed: false
        }
    }


    public componentWillUnmount() {
    }


    async componentDidMount() {
    }



    render() {
        return (
            <div className="RfqDashboardMainDisplayBox" style={this.props.width?{width:this.props.width}:null} onMouseEnter={()=>{this.setState({hover:true})}} onMouseLeave={()=>{this.setState({hover:false})}}>
                <div className="RfqDashboardMainDisplayBoxTitle">
                    <div className="RfqDashboardMainDisplayBoxTitleText">{this.props.title}</div>
                    {/* {this.state.hover?<div className="RfqDashboardMainDisplayBoxTitleCollapse" onClick={()=>{this.setState({collapsed:!this.state.collapsed})}}>{this.state.collapsed?<ArrowDownwardOutlined/>:<ArrowUpwardOutlined/>}</div>:null} */}
                    {this.state.hover?<IconButton className="RfqDashboardMainDisplayBoxTitleCollapse" onClick={()=>{this.setState({collapsed:!this.state.collapsed})}}>{this.state.collapsed?<ArrowDownwardOutlined/>:<ArrowUpwardOutlined/>}</IconButton>:null}
                </div>
                {this.state.collapsed?null:<div className="RfqDashboardMainDisplayBoxContent">{this.props.content}</div>}
            </div>)
    }
}

export default RfqDashboardMainDisplayBox;