import React, { Component } from 'react';
import moment from 'moment-timezone'
import { PltfmTimeZonePicker } from '../../utils/timezones';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from '../../inputs/formCommon';

export interface DigitalClockProps {
  onChangeState: (key: string, value: string) => void;
  getState: (key: string) => string;
  format: string;
}

interface DigitalClockState {
  time: string
  timezone: string | undefined
}

export const timezoneKey = "timezone";

let runner: any;
class DigitalClock extends Component<DigitalClockProps, DigitalClockState>{
  constructor(props: DigitalClockProps) {
    super(props);
    var now = moment((new Date()));
    var timeInitial = now.format(this.props.format);
    var tzInitial = props.getState(timezoneKey) ?? moment.tz.guess();
    this.state = {
      time: timeInitial,
      timezone: tzInitial,
    }
  }

  componentDidMount() {
    runner = setInterval(() => {
      if (this.state.timezone) {
        var utc = moment.utc();
        var local = utc.tz(this.state.timezone);
        var time = local.format(this.props.format);
        this.setState({ time: time });
      }
      else {
        var now = moment((new Date()));
        var time2 = now.format(this.props.format);
        this.setState({ time: time2 });
      }
    }, 1000);
  }
  componentWillUnmount() {
    if (runner) {
      clearInterval(runner);
    }
  }

  private handleTimezoneChange = (timezone: string) => {
    this.setState({ timezone });
    this.props.onChangeState(timezoneKey, timezone);
  }

  render() {
    return (
      <ThemeProvider theme={getFormTheme()}>
        <div className="DigitalClock">
          <div className="DigitalClockInner">
            <span className="DigitalClockTime">{this.state.time}</span>
            <div className="DigitalClockPicker">
              <PltfmTimeZonePicker
                selectedTimezone={this.state.timezone}
                onChange={this.handleTimezoneChange}
                disabled={false} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
export default DigitalClock;