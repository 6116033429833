import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import userSessionConnection from '../userSession/userSessionConnection';
import { get, put } from '../utils/httpUtils';
import { AccountBalance, AggregatedSummary, DayTradeAnalysisRecord, LivePosition, LiveSummary, PositionAnalysisRecord, SlTpRecord } from './positionSummaryModels';

export class LoadPositionsEvent extends TypedEvent<AggregatedSummary> { }
export class LoadPositionsFailedEvent extends TypedEvent<string> { }
export class LoadValuationsEvent extends TypedEvent<LiveSummary> { }
export class LoadValuationsFailedEvent extends TypedEvent<string> { }
export class UpdateLivePositionEvent extends TypedEvent<LivePosition> { }
export class UpdatedAccountBalancesEvent extends TypedEvent<AccountBalance[]> { }
export class NewPositionAnalysisEvent extends TypedEvent<PositionAnalysisRecord[]> { }
export class NewDayTradeAnalysisEvent extends TypedEvent<DayTradeAnalysisRecord[]> { }

export async function LoadPositions(asOf?: string) {
    const payoffs = asOf ? await get<AggregatedSummary>(`Positions/Positions/${asOf}`) : await get<AggregatedSummary>("Positions/Positions/now");
    if (payoffs.payload == null) {
        AppDispatcher.dispatch(new LoadPositionsFailedEvent("No positions returned from service"));
    }
    else {
        AppDispatcher.dispatch(new LoadPositionsEvent(payoffs.payload));
    }
}

export async function LoadValuations(asOf?: string, forceRefresh: boolean = false, jobUpdateKey: string = "", showFxAsPosition: boolean = false, attachCash: boolean = false) {
    const payoffs = asOf ? await get<LiveSummary>(`Positions/Valuations/${asOf}?forceRefresh=${forceRefresh}&jobUpdateKey=${jobUpdateKey}&showFxAsPosition=${showFxAsPosition}&attachCash=${attachCash}`) : await get<LiveSummary>(`Positions/Valuations/now?forceRefresh=${forceRefresh}&jobUpdateKey=${jobUpdateKey}&showFxAsPosition=${showFxAsPosition}&attachCash=${attachCash}`);
    if (payoffs?.payload == null) {
        AppDispatcher.dispatch(new LoadValuationsFailedEvent("No valuations returned from service"));
    }
    else {
        AppDispatcher.dispatch(new LoadValuationsEvent(payoffs.payload));
        return payoffs.payload;
    }
}

export async function LoadValuationsByMeta(asOf?: string, metaKeys: string[] = new Array<string>(), forceRefresh: boolean = false, showFxAsPosition: boolean = false, jobUpdateKey: string = '', attachCash: boolean = false) {
    const payoffs = asOf ? await put<LiveSummary>(`Positions/ValuationsByMeta/${asOf}?forceRefresh=${forceRefresh}&jobUpdateKey=${jobUpdateKey}&showFxAsPosition=${showFxAsPosition}&attachCash=${attachCash}`, metaKeys) : await put<LiveSummary>(`Positions/Valuations/now?forceRefresh=${forceRefresh}&jobUpdateKey=${jobUpdateKey}&showFxAsPosition=${showFxAsPosition}&attachCash=${attachCash}`, metaKeys);
    if (payoffs.payload == null) {
        AppDispatcher.dispatch(new LoadValuationsFailedEvent("No valuations returned from service"));
    }
    else {
        AppDispatcher.dispatch(new LoadValuationsEvent(payoffs.payload));
        return payoffs.payload;
    }
}

export async function LoadAccountBalances() {
    const balances = await get<AccountBalance[]>(`Nav/GetAccountBalances`);
    if (balances.payload == null) {
        AppDispatcher.dispatch(new LoadValuationsFailedEvent("No account balances returned from service"));
    }
    else {
        AppDispatcher.dispatch(new UpdatedAccountBalancesEvent(balances.payload));
    }
}

export async function ProducePositionAnalysis() {
    const response = await get<PositionAnalysisRecord[]>(`Positions/PositionAnalysis`);
    if (response.payload == null) {
        AppDispatcher.dispatch(new LoadValuationsFailedEvent("No position analysis returned from service"));
    }
    else {
        AppDispatcher.dispatch(new NewPositionAnalysisEvent(response.payload));
    }
}

export async function ProduceDayTradeAnalysis() {
    const response = await get<DayTradeAnalysisRecord[]>(`Positions/DayTradeAnalysis`);
    if (response.payload == null) {
        AppDispatcher.dispatch(new LoadValuationsFailedEvent("No day trade analysis returned from service"));
    }
    else {
        AppDispatcher.dispatch(new NewDayTradeAnalysisEvent(response.payload));
    }
}

export async function UpdatePosition(position: LivePosition) {
    AppDispatcher.dispatch(new UpdateLivePositionEvent(position));
}

export async function UpdateStops(record: SlTpRecord) {
    const response = await put<boolean[]>(`Positions/UpdateStopsForPositions`, [record]);
    if (response.payload)
        return response.payload[0];
    return false;
}

export async function SubcribeToLivePositionUpdates() {
    await userSessionConnection.subscribeToPositions();
}

export async function UnSubcribeFromLivePositionUpdates() {
    await userSessionConnection.unSubscribeToPositions();
}