import { CloseOutlined, FavoriteBorderOutlined, FavoriteOutlined, StarOutlineOutlined } from "@mui/icons-material";
import { TextField, Dialog, DialogTitle, DialogContent, Button, Grid, Typography, List, Divider, ListItemText, ListItemButton, FormGroup, Checkbox, FormControlLabel, IconButton, DialogActions, DialogContentText, CssBaseline, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import _ from "lodash";
import React from "react";
import userStoreInstance from "../user/userStore";
import { getFormTheme } from "./formCommon";

export interface LoadItemSpec {
    name: string;
    category: string;
    id: number;
}

export interface SaveLoadDeleteBoxProps {
    showBox: boolean;
    showDatesAsRelative?: boolean;
    showCategory?: boolean;
    title: string;
    options: LoadItemSpec[];
    onClose: () => void;
    onSave: (name: string, category: string, isPublic: boolean, relativeDates: boolean) => void;
    onLoad: (id: number) => void;
    onDelete: (id: number) => void;
    onUpdate: (id: number, name: string, category: string, isPublic: boolean) => void;
    onSetDefault?: (id: number) => void;
    isDefault?: (id: number) => boolean;
    checkItemExists: (name: string) => boolean;
    onSetFavourite?: (id: number, favIx: number) => void;
    getFavouriteNameForId?: (id: number) => string;
    getFavouriteId?: (id: number) => number;
}

interface SaveLoadDeleteBoxState {
    selectedItem: LoadItemSpec;
    saveItemName: string;
    saveItemCategory: string;
    saveItemPublic: boolean;
    saveItemDatesRelative: boolean;
    showAreYouSure: boolean;
    menuAnchorFav: { mouseX: number; mouseY: number; } | null

}

export const maxFavourites = 12;

export class SaveLoadDeleteBox extends React.Component<SaveLoadDeleteBoxProps, SaveLoadDeleteBoxState>
{
    constructor(props: SaveLoadDeleteBoxProps) {
        super(props)
        this.state = {
            selectedItem: undefined,
            saveItemCategory: undefined,
            saveItemName: undefined,
            saveItemPublic: false,
            saveItemDatesRelative: false,
            showAreYouSure: false,
            menuAnchorFav: null
        }

        this.trySaveItem = this.trySaveItem.bind(this);
        this.handleFavouritesMenuClick = this.handleFavouritesMenuClick.bind(this);
        this.getFavouriteAndCategory = this.getFavouriteAndCategory.bind(this);
    }

    trySaveItem() {
        const { saveItemName, saveItemCategory, saveItemPublic, saveItemDatesRelative, showAreYouSure } = this.state;
        var overwrite = this.props.checkItemExists(saveItemName);
        if (!overwrite || (overwrite && showAreYouSure)) {
            this.setState({ showAreYouSure: false });
            this.props.onSave(saveItemName, saveItemCategory, saveItemPublic, saveItemDatesRelative)
        }
        else if (overwrite && !showAreYouSure)
            this.setState({ showAreYouSure: true });
    }

    handleFavouritesMenuClick(event: React.MouseEvent) {
        event.preventDefault();
        this.setState({
            menuAnchorFav:
                this.state.menuAnchorFav === null
                    ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                    : null
        });
    };

    getFavouriteAndCategory(spec: LoadItemSpec) {
        var favouriteId = this.props.getFavouriteId && this.props.getFavouriteId(spec.id);
        if (spec.category && favouriteId !== undefined) {
            return `${spec.category} Favourite ${favouriteId + 1}`
        }
        else if (favouriteId !== undefined) {
            return `Favourite ${favouriteId + 1}`
        }
        else if (spec.category) {
            return `${spec.category}`
        }
        return undefined;
    }

    render() {
        const { selectedItem, saveItemName, saveItemCategory, saveItemPublic, saveItemDatesRelative, showAreYouSure, menuAnchorFav } = this.state;
        const theme = userStoreInstance.GetTheme();
        var col = theme.color;
        var overwrite = this.props.checkItemExists(saveItemName);
        return (
            <CssBaseline>
                <ThemeProvider theme={getFormTheme()}>
                    <React.Fragment>
                        <Dialog open={showAreYouSure} onClose={() => this.setState({ showAreYouSure: false })}  >
                            <DialogTitle id="alert-dialog-title" style={{ backgroundColor: theme.background_color_opaque }}>Are you sure?</DialogTitle>
                            <DialogContent style={{ backgroundColor: theme.background_color_opaque }}>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to overwrite view "{saveItemName}"?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ backgroundColor: theme.background_color_opaque }}>
                                <Button variant="outlined" onClick={() => this.trySaveItem()}>Overwrite</Button>
                                <Button variant="outlined" onClick={() => this.setState({ showAreYouSure: false })} autoFocus>Cancel</Button>
                            </DialogActions>
                        </Dialog >
                        <Dialog open={this.props.showBox} onClose={() => this.props.onClose()} fullWidth maxWidth="xl" sx={{ borderWidth: 2, borderColor: "text.primary", borderStyle: "solid" }} >
                            <DialogTitle id="alert-dialog-title" style={{ backgroundColor: theme.background_color_opaque, color: col }}>
                                {this.props.title}
                                <IconButton
                                    aria-label="close"
                                    onClick={this.props.onClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseOutlined />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent style={{ backgroundColor: theme.background_color_opaque, borderTop: "1px solid", borderColor: theme.border_color, paddingTop: "0.5em" }} >
                                <ThemeProvider theme={getFormTheme()}>
                                    <Grid container direction="row" spacing={1} height="50vh" marginTop="0.5em">
                                        <Grid item container direction="column" width="49%" spacing={1}>
                                            <Grid item>
                                                <Typography textAlign="center" variant="subtitle1" color={col}>Load</Typography>
                                            </Grid>
                                            <Grid item><Divider /></Grid>
                                            {/* Load controls */}
                                            <Grid container item direction="row" width="100%" spacing={1} justifyContent="space-around">
                                                <Grid item>
                                                    <Button
                                                        className="MuiButton-outlined PltfmButtonLite"
                                                        disabled={selectedItem === undefined}
                                                        onClick={() => { this.props.onLoad(selectedItem.id); this.props.onClose() }}>
                                                        Load
                                                    </Button>
                                                </Grid>
                                                {this.props.onSetDefault ? <Grid item>
                                                    <Button
                                                        className="MuiButton-outlined PltfmButtonLite"
                                                        disabled={selectedItem === undefined}
                                                        startIcon={<StarOutlineOutlined />}
                                                        onClick={() => { this.props.onSetDefault(selectedItem.id) }}>
                                                        Set As Default
                                                    </Button>
                                                </Grid> : null}
                                                {this.props.onSetFavourite ? <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        className="MuiButton-outlined PltfmButtonLite"
                                                        disabled={selectedItem === undefined}
                                                        startIcon={<FavoriteOutlined />}
                                                        onClick={this.handleFavouritesMenuClick}>
                                                        Set As Favourite
                                                    </Button>
                                                    <Menu
                                                        key="posGridOptionMenu"
                                                        id="posGridOptionMenu"
                                                        anchorReference="anchorPosition"
                                                        anchorPosition={
                                                            menuAnchorFav !== null
                                                                ? { top: menuAnchorFav.mouseY, left: menuAnchorFav.mouseX }
                                                                : undefined
                                                        }
                                                        open={menuAnchorFav !== null}
                                                        onClose={() => this.setState({ menuAnchorFav: null })}
                                                        classes={{ list: "GridOptionMenu" }}>
                                                        {_.range(0, maxFavourites).map((ix) => <MenuItem key={`setFavOpt` + ix}><Button
                                                            variant="outlined"
                                                            className="MuiButton-outlined PltfmButtonLite"
                                                            size="small"
                                                            startIcon={<FavoriteBorderOutlined />}
                                                            onClick={() => { this.props.onSetFavourite(selectedItem.id, ix); this.setState({ menuAnchorFav: null }) }}>
                                                            {this.props.getFavouriteNameForId(ix) ?? "(Empty)"}
                                                        </Button></MenuItem>)}
                                                    </Menu>
                                                </Grid> : null}
                                                <Grid item>
                                                    <Button className="MuiButton-outlined PltfmButtonLite" disabled={selectedItem === undefined} onClick={() => this.props.onDelete(selectedItem.id)}>
                                                        Delete
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item overflow="auto" height="calc(50vh - 100px)" marginRight="7px">
                                                <List dense >
                                                    {this.props.options?.map((o, ix) => {
                                                        return (<ListItemButton style={{ color: col }} key={`lsi-${ix}`} onClick={() => this.setState({ selectedItem: o, saveItemName: o.name })} selected={selectedItem === o}>
                                                            <ListItemText primary={o.name} secondary={this.getFavouriteAndCategory(o)} secondaryTypographyProps={{ color: theme.border_color, variant:"body2" }} />
                                                            {this.props.isDefault && this.props.isDefault(o.id) ? <ListItemIcon><StarOutlineOutlined /></ListItemIcon> : null}
                                                        </ListItemButton>)
                                                    })}
                                                </List>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column" width="49%" spacing={1} borderLeft="1px solid" borderColor={theme.border_color}>
                                            <Grid item>
                                                <Typography variant="subtitle1" textAlign="center" color={col}>Save</Typography>
                                            </Grid>
                                            <Grid item><Divider /></Grid>
                                            <Grid container item direction="row" width="100%" spacing={1} justifyContent="space-around">
                                                <Grid item>
                                                    <TextField
                                                        key="saveBoxName"
                                                        variant="outlined"
                                                        label="Name"
                                                        size="small"
                                                        value={saveItemName ?? ""}
                                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                                        style={{ color: col + " !important" }}
                                                        //error={saveItemName === undefined || saveItemName === null || saveItemName === ""}
                                                        onChange={(e) => this.setState({ saveItemName: e.target.value })}
                                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                                                </Grid>
                                                {this.props.showCategory ? <Grid item>
                                                    <TextField
                                                        key="saveBoxCategory"
                                                        variant="outlined"
                                                        label="Category"
                                                        size="small"
                                                        value={saveItemCategory ?? ""}
                                                        classes={{ root: "ListedInstrumentEditorFormField" }}
                                                        //error={saveItemCategory === undefined || saveItemCategory === null || saveItemCategory === ""}
                                                        onChange={(e) => this.setState({ saveItemCategory: e.target.value })}
                                                        InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }} />
                                                </Grid> : null}
                                                <Grid item>
                                                    <FormGroup >
                                                        <FormControlLabel style={{ color: col }} control={<Checkbox checked={saveItemPublic} onChange={(e, c) => this.setState({ saveItemPublic: c })} />} label="Public?" />
                                                    </FormGroup>
                                                </Grid>
                                                {this.props.showDatesAsRelative ? <Grid item>
                                                    <FormGroup >
                                                        <FormControlLabel style={{ color: col }} control={<Checkbox checked={saveItemDatesRelative} onChange={(e, c) => this.setState({ saveItemDatesRelative: c })} />} label="Relative Dates?" />
                                                    </FormGroup>
                                                </Grid> : null}
                                            </Grid>
                                            {/* Save controls */}
                                            <Grid container item direction="row" width="100%" spacing={1} justifyContent="space-around">
                                                <Grid item>
                                                    <Button className="MuiButton-outlined PltfmButtonLite" disabled={saveItemName === undefined} onClick={() => this.trySaveItem()}>
                                                        {overwrite ? "Overwrite" : "Save"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ThemeProvider>
                            </DialogContent>
                        </Dialog >
                    </React.Fragment>
                </ThemeProvider>
            </CssBaseline>
        );
    }
}

