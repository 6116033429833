import { AppDispatcher, Event } from '../dispatcher/appDispatcher';
import FluxStore from '../FluxStore'
import { NewUserActivityBatchEvent, NewUserActivityEvent } from './userSessionActions';
import { UserActivityEvent, UserActivityStoreState } from './pingModels';


class UserActivityStore extends FluxStore<UserActivityStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            events: new Array<UserActivityEvent>()
        }))
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewUserActivityEvent) {
            this.state.events.push(action.payload);
            this.emitChange();
        }
        else if (action instanceof NewUserActivityBatchEvent) {
            this.state.events = action.payload;
            this.emitChange();
        }
    }

    public GetEvents() {
        return this.state.events;
    }
}

const userActivityStoreInstance = new UserActivityStore(AppDispatcher);
export default userActivityStoreInstance;

