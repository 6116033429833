//import AdapterMoment from '@mui/lab/AdapterMoment';
//import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Moment } from "moment";
import React from "react";
import userStoreInstance from "../user/userStore";
import { TextField } from "@mui/material";
import { CalendarPickerView, MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {  AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { EarliestDate } from '../globalConstants';

export interface WrappedDatePickerProps {
    onChange: (date: Moment) => void,
    value: Date | string | Moment,
    style?: React.CSSProperties,
    disableFuture?: boolean,
    disablePast?: boolean,
    minDate?: Date,
    maxDate?: any,
    emptyLabel?: string,
    clearLabel?: React.ReactNode,
    label?: string,
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    helperText?: React.ReactNode,
    views?: CalendarPickerView[],
    shouldDisableDate?: (day: Moment) => boolean,
    onOpen?: () => void,
    onClose?: () => void,
    onMouseOver?: () => void,
    onMouseLeave?: () => void,
    disabled?: boolean,
    readOnly?: boolean,
    mask?: string,
    inputFormat?: string,
    disableWeekends?: boolean
}

interface WrappedDatePickerState {
    value: Date | string | Moment,
}

export class WrappedDatePicker extends React.Component<WrappedDatePickerProps, WrappedDatePickerState>
{
    constructor(props: WrappedDatePickerProps) {
        super(props)
        this.state =
        {
            value: props.value
        };
        this.onChangeInternal = this.onChangeInternal.bind(this);
    }

    onChangeInternal(date: Moment, keyboardInputValue?: string) {
        this.setState({ value: date?.toDate() });
        this.props.onChange(date);
    }

    render() {
        return (<LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                    disableOpenPicker={this.props.disabled}
                    readOnly={this.props.readOnly}
                    value={this.state.value === undefined ? null : this.state.value}
                    onAccept={this.onChangeInternal}
                    onChange={(d,v)=>{}}
                    disableFuture={this.props.disableFuture}
                    disablePast={this.props.disablePast}
                    views={this.props.views}
                    closeOnSelect={true}
                    mask={this.props.mask}
                    onOpen={this.props.onOpen}
                    onClose={this.props.onClose}
                    shouldDisableDate={this.props.shouldDisableDate ?? (this.props.disableWeekends ? (d)=> {return d.isoWeekday() >=6;} : undefined)}
                    minDate={this.props.minDate ? moment(this.props.minDate) : moment(EarliestDate)}
                    maxDate={this.props.maxDate ? moment(this.props.maxDate) : undefined}
                    inputFormat={this.props.inputFormat??userStoreInstance.GetDateFormat()}
                    toolbarPlaceholder={this.props.emptyLabel}
                    DialogProps={{ color: "inherit", className: "ListedInstrumentEditorFormFieldInner" }}
                    label={this.props.label}
                    componentsProps = {
                        {actionBar: {actions: [ 'clear', 'cancel', 'accept',  'today']}}
                    }
                    renderInput={(props) => <TextField
                        {...props}
                        style={{ ...this.props.style, color: userStoreInstance.GetTheme().color + " !important" }}
                        InputProps={{ readOnly: this.props.readOnly, classes: { root: "ListedInstrumentEditorFormFieldInner" } }}
                        InputLabelProps={{shrink:true}}
                        placeholder={this.props.label}
                        size={this.props.size ?? "small"}
                        variant="outlined"
                        helperText={this.props.helperText}
                        id={this.props.id}
                        name={this.props.name}
                        error={this.props.error}
                        onMouseOver={this.props.onMouseOver}
                        onMouseOut={this.props.onMouseLeave}
                    />}
                />
            </LocalizationProvider>)
    }
}