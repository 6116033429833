import React from 'react';
import FBEmitter from 'fbemitter';
import {
    Button,
    Paper,
    Tab,
    Tabs,
    StyledEngineProvider,
    Typography,
    AutocompleteRenderOptionState,
    MenuItem,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import marketDataStoreInstance from '../marketDataStore';
import { TrendingUpOutlined } from '@mui/icons-material';
import userStoreInstance from '../../user/userStore';
import { FixingPoint } from '../models/fixingPoint';
import { TabContext, TabPanel } from '@mui/lab';
import listedInstrumentStoreInstance from '../../listedInstruments/listedInstrumentStore';
import { StockChart } from './stockChart';
import { Candle } from '../models/candle';
import { GetFixings } from '../marketDataActions';
import { getPositionTableTheme } from '../../positions/positionSummaryTable';
import moment from 'moment';
import { getFormTheme } from '../../inputs/formCommon';
import { DataGridPro, GridCellParams, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';
import { MarketDataSetSelector } from '../../inputs/marketDataSetSelector';
import { ListedInstrument, ListedInstrumentQuery } from '../../listedInstruments/listedInstrumentModels';
import { FetchInstrumentWithQuery } from '../../listedInstruments/listedInstrumentActions';

interface smallChartData {
    time: Date,
    close: number
}

type FixingDashboardProps = {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
    fullWidth: boolean;
}

type FixingDashboardState = {
    fixings: FixingPoint[],
    selectedInstrument: number | undefined,
    selectedSet: string | undefined,
    chartDivRef: React.RefObject<HTMLDivElement>,
    chartHeight: number,
    chartWidth: number,
    canResize: boolean,
    currentTheme: string,
    smallChartData: Map<string, smallChartData[]>,
    selectedTabId: number;
    matchingInstruments: ListedInstrument[];
    searchString: string
}

export interface CandleSize {
    label: string,
    sizeInSec: number
}

export class FixingDashboard extends React.Component<FixingDashboardProps, FixingDashboardState>{
    eventSubscriptionMarketData: FBEmitter.EventSubscription | undefined;
    eventSubscriptionThemes: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: FixingDashboardProps) {
        super(props)
        this.state = {
            fixings: [],
            selectedInstrument: parseInt(this.props.getState("selectedInstrument")),
            selectedSet: this.props.getState("selectedSet"),
            chartDivRef: React.createRef(),
            chartHeight: 500,
            chartWidth: 1000,
            canResize: false,
            currentTheme: userStoreInstance.GetThemeName(),
            smallChartData: new Map<string, smallChartData[]>(),
            selectedTabId: 0,
            matchingInstruments: [],
            searchString: undefined
        };

        this.setTheme = this.setTheme.bind(this);
        this.marketDataUpdate = this.marketDataUpdate.bind(this);
        this.renderDateCell = this.renderDateCell.bind(this);
        this.renderDate = this.renderDate.bind(this);
        this.onInstrumentChange = this.onInstrumentChange.bind(this);
        this.onClickServerSearch = this.onClickServerSearch.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionThemes = userStoreInstance.addChangeListener(this.setTheme);
        this.eventSubscriptionMarketData = marketDataStoreInstance.addChangeListener(this.marketDataUpdate);
        this.eventSubscriptionInstruments = listedInstrumentStoreInstance.addChangeListener(this.onInstrumentChange);
        await this.marketDataUpdate();
    }

    // componentDidUpdate() {
    //     const { chartDivRef, chartHeight, chartWidth, canResize } = this.state;
    //     if (canResize && chartDivRef.current) {
    //         var newWidth = chartDivRef.current.clientWidth;
    //         var newHeight = chartDivRef.current.clientHeight;
    //         if (chartHeight !== newHeight || chartWidth !== newWidth) {
    //             this.setState({ chartHeight: newHeight, chartWidth: newWidth });
    //         }
    //     }
    // }

    componentWillUnmount() {
        if (this.eventSubscriptionMarketData) {
            this.eventSubscriptionMarketData.remove();
            this.eventSubscriptionMarketData = undefined;
        }
        if (this.eventSubscriptionThemes) {
            this.eventSubscriptionThemes.remove();
            this.eventSubscriptionThemes = undefined;
        }
        if (this.eventSubscriptionInstruments) {
            this.eventSubscriptionInstruments.remove();
            this.eventSubscriptionInstruments = undefined;
        }
    }

    async marketDataUpdate() {
        const { selectedInstrument, selectedSet } = this.state;

        if (selectedInstrument && selectedSet) {
            var fixings = await marketDataStoreInstance.getFixingsAsync(selectedSet, selectedInstrument);
            if (fixings) {
                fixings.forEach(fixing => {
                    fixing.date = new Date(fixing.date);
                });
            }
            this.setState({ fixings });
        }
    }

    onInstrumentChange() {
        const { searchString, selectedSet } = this.state;
        var m = marketDataStoreInstance.getInsIds(selectedSet);
        var ins = listedInstrumentStoreInstance.getInstruments().filter(i => m.includes(i.listedInstrumentId));
        this.onChangeSearchString(searchString, ins);
    }

    setTheme() {
        this.setState({ currentTheme: userStoreInstance.GetThemeName() });
    }

    async onChange() {

    }

    renderDate(date: Date) {
        var m = moment.utc(date);
        var t = moment();
        if (t.dayOfYear() === m.dayOfYear() && t.year() === m.year())
            return m.format("HH:mm:ss");
        else if (m.hour() === 0 && m.minute() === 0 && m.second() === 0)
            return m.format("yyyy-MM-DD");
        else
            return m.format("yyyy-MM-DD HH:mm:ss");
    }

    renderDateCell(params: GridCellParams) {
        if (params.value) {
            var val: Date = new Date(params.value.toString());
            return <div>{this.renderDate(val)}</div>
        }
        else
            return <div></div>;
    }

    customToolbar(props: any) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={getFormTheme()}>
                    <GridToolbarContainer>
                        <div style={{ paddingLeft: "5px" }}>
                            <GridToolbarExport {...props} variant="outlined" size="small" />
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <GridToolbarFilterButton {...props} />
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                            <GridToolbarColumnsButton variant="outlined" size="small" />
                        </div>
                    </GridToolbarContainer>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }


    async selectInstrument(setName: string, instrumentDescription: string) {
        if (instrumentDescription) {
            var ins = marketDataStoreInstance.getInsIds(setName).map(i => listedInstrumentStoreInstance.getInstrumentSummary(i)).filter(i => i.description === instrumentDescription)[0];
            if (ins) {
                var fixings = await marketDataStoreInstance.getFixingsAsync(setName, ins.id, true);
                if (fixings) {
                    fixings.forEach(fixing => {
                        fixing.date = new Date(fixing.date);
                    });
                    this.setState({ fixings, selectedInstrument: ins.id });
                }
                else {
                    GetFixings(setName, ins.id);
                    this.setState({ selectedInstrument: ins.id });
                }
                this.props.onChangeState("selectedInstrument", ins.id.toString())
            };
        }
    }

    renderValue(params: GridCellParams, type: string) {
        if (params.value) {
            var num = Number(params.value);
            switch (type) {
                case "Bond":
                    return <div>{`${(num * 100).toFixed(3)}%`}</div>
                default:
                    return <div>{num.toFixed(4)}</div>
            }
        }
        else
            return <div></div>;
    }
    onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ selectedTabId: newValue });
    }

    renderOption(props: React.HTMLAttributes<HTMLLIElement>, option: string, state: AutocompleteRenderOptionState) {
        return <MenuItem value={option}>{option ?? ""}</MenuItem>;
    }

    private instrumentMatchesQuery(ins: ListedInstrument, query: string): boolean {
        const normalizedTitle = ins.description?.toLowerCase();
        const code = ins?.ticker?.toLowerCase();
        const isin = ins?.isin?.toLowerCase();
        const allMetaData = ins.metaData ? ins.metaData.map(am => am.data.toLowerCase()).join(".") : "";
        const normalizedQuery = query.toLowerCase();

        return `${ins.listedInstrumentId}. ${normalizedTitle}. ${isin} .${allMetaData}`.indexOf(normalizedQuery) >= 0 || `${ins.listedInstrumentId}. ${code}`.indexOf(normalizedQuery) >= 0;
    }

    private onInsSelect(ins: ListedInstrument | string) {
        var solidIns = ins as ListedInstrument;
        if (solidIns) {
            this.setState({ selectedInstrument: solidIns.listedInstrumentId });
            GetFixings(this.state.selectedSet, solidIns.listedInstrumentId);
        }
    }

    private onChangeSearchString(str: string, ins?: ListedInstrument[]) {
        if (!ins)
            ins = listedInstrumentStoreInstance.getInstruments();
        var matches = new Array<ListedInstrument>();
        if (str) {
            ins.forEach(ins => {
                if (this.instrumentMatchesQuery(ins, str))
                    matches.push(ins);
            });

        }
        this.setState({ matchingInstruments: matches, searchString: str });
        this.props.onChangeState("insSimpleSearchString", str);
    }

    private async onClickServerSearch() {
        const { searchString } = this.state;

        var query = { description: searchString } as ListedInstrumentQuery;
        await FetchInstrumentWithQuery(query);
    }

    render() {
        const {
            fixings,
            selectedInstrument,
            selectedSet,
            chartDivRef,
            chartHeight,
            chartWidth,
            selectedTabId,
            matchingInstruments,
            searchString
        } = this.state;

        const chartData = fixings?.map(f => {
            return { open: f.value, high: f.value, low: f.value, close: f.value, time: f.date, volume: 0 } as Candle;
        })
        const instruments = selectedSet ? listedInstrumentStoreInstance.getInstrumentsByIds(marketDataStoreInstance.getInsIds(selectedSet)) : null;

        const columns: GridColDef[] = [
            { field: 'date', width: 200, headerName: 'Date', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "dateTime", renderCell: this.renderDateCell },
            { field: 'value', width: 200, headerName: 'Fixing', cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader", type: "number", renderCell: (p) => this.renderValue(p, listedInstrumentStoreInstance.getInstrumentSummary(selectedInstrument)?.type) },
        ];
        const rows = fixings?.map((f, ix) => {
            return {
                id: ix,
                date: f.date,
                value: f.value
            }
        });

        return (
            <ThemeProvider theme={getFormTheme()}>
                <div className={`MarketDashboard`}>
                    <div className="MarketDashboardLeftSection">

                        <Grid container spacing={2} justifyContent="center" alignContent="center" direction="column">
                            <Grid item container alignContent="center" justifyContent="center" spacing={1} >
                                <Grid item >
                                    <MarketDataSetSelector
                                        style={{ width: "100%" }}
                                        autoWidth={false}
                                        id="set"
                                        name="set"
                                        label="Set"
                                        value={selectedSet}
                                        onChange={(set) => {
                                            this.props.onChangeState("selectedSet", set)
                                            this.setState({ selectedSet: set });
                                        }} />
                                </Grid>
                                <Grid item>
                                    <FormControl size="small">
                                        <InputLabel variant="outlined">Search</InputLabel>
                                        <OutlinedInput label="Search" classes={{ root: "ListedInstrumentEditorFormFieldInner" }} onChange={(e) => this.onChangeSearchString(e.target.value, instruments)} value={searchString ?? ''} />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Button className="PltfmButtonLite" disabled={searchString === undefined} onClick={this.onClickServerSearch}>Server Search</Button>
                                </Grid>
                                <Grid item>
                                    <List className='MarketDashboardTabSearch' key="MarketDashboardTabSearch">
                                        {matchingInstruments.slice(0, 50).map(s => {
                                            const labelId = `checkbox-list-label-${s.description}`;
                                            return (
                                                <ListItem key={s.listedInstrumentId} selected={s.listedInstrumentId === selectedInstrument} role={undefined} button onClick={() => this.onInsSelect(s)}>
                                                    <ListItemText id={labelId} primary={s.description} />
                                                </ListItem>);
                                        })}
                                    </List>
                                </Grid>

                            </Grid>
                            <Grid item container justifyContent="center" alignContent="center" style={{ width: "14vw" }}>
                                <Button size="large" variant="outlined" className="PltfmButtonLite" onClick={async () => await GetFixings(selectedSet, selectedInstrument)}>Refresh</Button>
                            </Grid>
                        </Grid>

                    </div>
                    <div className={"MarketDashboardBigChart"}>
                        {fixings && fixings.length > 0 ?
                            <TabContext value={selectedTabId.toString()}>
                                <Tabs key='mdTabs' value={selectedTabId.toString()} onChange={(e, v) => this.onTabChange(e, v)} TabIndicatorProps={{ className: "LayoutTabSelected" }}>
                                    <Tab
                                        classes={{ root: "tabTitle" }}
                                        value={"0"}
                                        key={"tabQuery1"}
                                        component={Paper}
                                        id={"tab0"}
                                        label="Chart" />
                                    <Tab
                                        classes={{ root: "tabTitle" }}
                                        value={"1"}
                                        key={"tabQuery2"}
                                        component={Paper}
                                        id={"tab1"}
                                        label="Table" />
                                </Tabs>
                                <TabPanel
                                    style={{ minHeight: "calc(100% - 50px)", backgroundColor: userStoreInstance.GetTheme().background_color }}
                                    key={"tabPQuery1"}
                                    value={"0"}
                                    children={<div className="MarketDashboardBigChartContainer" >
                                        <Typography variant="h5">{selectedInstrument}: {listedInstrumentStoreInstance.getInstrumentSummary(selectedInstrument)?.description}</Typography>
                                        <div className="MarketDashboardBigChartMain" ref={chartDivRef} id="mainChartDiv">
                                            {/* <FixingChart dateTimeFormat="%H:%M" data={fixings} height={chartHeight} width={chartWidth - 5} ratio={2} /> */}
                                            {/* <ResponsiveContainer width="100%" height="100%"> */}
                                            <StockChart dateTimeFormat="yyyy-MM-dd" isCandlePlot={false} data={chartData} height={chartHeight} width={chartWidth} ratio={2} />
                                            {/* </ResponsiveContainer> */}
                                        </div>
                                    </div>} />
                                <TabPanel
                                    style={{ minHeight: "100px", backgroundColor: userStoreInstance.GetTheme().background_color }}
                                    key={"tabPQuery2"}
                                    value={"1"}
                                    children={<div className="MarketDashboardBigTable">
                                        <ThemeProvider theme={getPositionTableTheme()}>
                                            {rows && rows.length > 0 && <DataGridPro
                                                className="PositionSummaryTabTable"
                                                classes={{ cell: "GridCellDefault" }}
                                                rows={rows}
                                                columns={columns}
                                                initialState={{
                                                    sorting: {
                                                        sortModel: [{ field: 'date', sort: 'desc' }]
                                                    }
                                                }}
                                                components={{
                                                    Toolbar: this.customToolbar,
                                                }}
                                            />}
                                        </ThemeProvider>
                                    </div>} />
                            </TabContext> : <div className="MarketDashboardBigChartPlaceholder"><TrendingUpOutlined fontSize="inherit" /></div>
                        }
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}