import React from "react";

export interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    if (value === index && children)
        return (<div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            { children}
        </div>);
    else
        return null;
}