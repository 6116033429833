import { useEffect } from 'react';
import userStoreInstance from '../../user/userStore';
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, ThemeProvider } from '@mui/material';
import { getFormTheme } from '../../inputs/formCommon';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, fetchGasPriceEstimates, GasReducerState } from '../../reduxStore';
import moment from 'moment';

const updateSpeedSeconds = 10;

export interface GasEstimateResponse {
  error: string;
  result: GasPriceEstimateResult;
}

export interface GasPriceEstimateResult {
  instant: GasPriceEstimate;
  fast: GasPriceEstimate;
  eco: GasPriceEstimate;
  baseFee: number;
  ethPrice: number;
}

export interface GasPriceEstimate {
  feeCap: number,
  maxPriorityFee: number
}

export const GasEstimateWidget = props => {
  //const [internalState, setInternalState] = useState<{lastUpdate : moment.Moment, lastResponse: GasEstimateResponse}>({lastUpdate : moment(), lastResponse:undefined});

  const reduxUpdates = useSelector((state: any) => state.gasEstimates as GasReducerState)
  const dispatch: AppDispatch = useDispatch();

  // const fetchEstimate = useCallback(async () => {
  //   let response = await get<GasEstimateResponse>('https://api.gasprice.io/v1/estimates', undefined, true);
  //   if (response?.payload) {
  //     //setInternalState({lastUpdate: moment(), lastResponse: response.payload})
  //     dispatch({ type: 'update', payload: { estimate: response.payload, stamp: moment().toISOString() } } as GasEstimateUpdateAction)
  //   }
  // }, [dispatch])

  useEffect(() => {
    dispatch(fetchGasPriceEstimates())
    const runner = setInterval(async () => {
      //await fetchEstimate();
      dispatch(fetchGasPriceEstimates())
    }, updateSpeedSeconds * 1000);

    return () => {
      if (runner) {
        clearInterval(runner);
      }
    }
  }, [dispatch])

  var col = userStoreInstance.GetTheme().color;

  const { lastUpdate, lastResponse } = reduxUpdates;

  if (!lastResponse || !lastResponse.result) {
    return <div className="HolidayPanel">
      <Typography variant="subtitle2" align="center" color={col}>Loading...</Typography>
    </div>
  }

  var instant = lastResponse.result.instant;
  var fast = lastResponse.result.fast;
  var eco = lastResponse.result.eco;
  var stamp = moment(lastUpdate).format("HH:mm:ss");
  var eth = lastResponse.result.ethPrice;

  var cellStyle = { border: "none" } as React.CSSProperties;

  return (
    <div className="HolidayPanel">
      <ThemeProvider theme={getFormTheme()}>
        <Grid container direction="column" justifyContent="center">
          <Grid item>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" align="center">Speed</TableCell>
                    <TableCell variant="head" align="center">FeeCap</TableCell>
                    <TableCell variant="head" align="center">Prem</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>Instant</Typography></TableCell>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>{instant.feeCap.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>{instant.maxPriorityFee.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>Fast</Typography></TableCell>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>{fast.feeCap.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>{fast.maxPriorityFee.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>Eco</Typography></TableCell>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>{eco.feeCap.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                    <TableCell style={cellStyle}><Typography variant="subtitle2" align="center" color={col}>{eco.maxPriorityFee.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={cellStyle}><Typography variant="body2" align="center" color={col}>{stamp}</Typography></TableCell>
                    <TableCell style={cellStyle} colSpan={2}><Typography variant="body2" align="center" color={col}>ETH/USD {eth?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

