import _ from "lodash";
import { TO_ResultCube } from "./to_ResultCube"
import { TO_ResultCubeRow } from "./to_ResultCubeRow";

export const AggregateCube = (cube: TO_ResultCube, fields: string[]) => {
    if (!cube)
        return undefined;
    if (!fields)
        return _.cloneDeep(cube);

    var indices = new Set(fields.map(f => cube.fieldNames.indexOf(f)));
    var data = new Map<string, TO_ResultCubeRow>();
    cube.rows.forEach(row => {
        var metaForRow = row.metaData.filter((m, ix) => indices.has(ix));
        var key = metaForRow.join("~");
        if (!data.has(key))
            data.set(key, { metaData: metaForRow, value: row.value } as TO_ResultCubeRow);
        else {
            var existingRow = data.get(key);
            existingRow.value += row.value;
        }
    });

    var types: { [key: string]: string } = {};

    fields.forEach(field => {
        types[field] = cube.types[field];
    });
    return {
        fieldNames: fields.sort((a, b) => cube.fieldNames.indexOf(a) - cube.fieldNames.indexOf(b)),
        rows: Array.from(data.values()),
        types: types
    } as TO_ResultCube
}

export const CubeToMatrix = (cube: TO_ResultCube, fieldHorizontal: string, fieldVertical: string) => {
    var keysH = CubeKeys(cube, fieldHorizontal);
    var keysV = CubeKeys(cube, fieldVertical);

    var aggregated = AggregateCube(cube, [fieldHorizontal, fieldVertical]);
    var o = new Array<Array<any>>(keysV.length + 1);
    for (var r = 0; r < o.length; r++) {
        o[r] = new Array<any>(keysH.length + 1);
        for (var c = 0; c < o[r].length; c++) {
            if (r === 0 && c === 0) {
                o[r][c] = null;
                continue;
            }
            else if (r === 0)
                o[r][c] = keysH[c - 1];
            else if (c === 0)
                o[r][c] = keysV[r - 1];
            else {
                var filter: { [field: string]: string } = {};
                filter[fieldVertical] = keysV[r - 1];
                filter[fieldHorizontal] = keysH[c - 1];
                var data = FilterCube(aggregated, filter, false);
                if (data?.rows?.length > 0)
                    o[r][c] = _.sum(data.rows.map(r => r.value));
            }
        }
    }
    return o;
}

export const CubeKeys = (cube: TO_ResultCube, field: string) => {
    var colIx = cube.fieldNames.indexOf(field);
    return Array.from(new Set(cube.rows.map(r => r.metaData[colIx])));
}

export const FilterCube = (cube: TO_ResultCube, filters: { [field: string]: string }, filterOut: boolean = false): TO_ResultCube => {
    if (cube === undefined || cube.rows === undefined)
        return undefined;
    if (!filters)
        return _.cloneDeep(cube);

    var fields = filters ? Array.from(Object.keys(filters)) : new Array<string>();
    var indices = Array.from(new Set(fields.map(f => cube?.fieldNames?.indexOf(f))));

    var data = cube.rows.filter(row => {
        var rowIsRelevant = fields.every((f, ix) => row.metaData[indices[ix]] === filters[f]);

        if (filterOut)
            rowIsRelevant = !rowIsRelevant;

        return rowIsRelevant;
    });

    var types: { [key: string]: string } = {};

    Object.keys(filters).forEach(field => {
        types[field] = cube.types[field];
    });

    return {
        fieldNames: _.clone(cube.fieldNames),
        rows: Array.from(data.values()),
        types: types
    } as TO_ResultCube
}

export const MergeCubes = (cubeA: TO_ResultCube, cubeB: TO_ResultCube): TO_ResultCube => {
    if (!cubeA && !cubeB)
        return undefined;
    else if (!cubeA && cubeB)
        return cubeB;
    else if (cubeA && !cubeB)
        return cubeA;

    const allFields = _.uniq(cubeA.fieldNames.concat(cubeB.fieldNames));
    const allTypes: { [f: string]: string } = {};
    allFields.forEach(f => {
        var ixA = cubeA.fieldNames.indexOf(f);
        if (ixA >= 0)
            allTypes[f] = cubeA.types[ixA]
        else
            allTypes[f] = cubeB.types[cubeB.fieldNames.indexOf(f)];
    });

    const mappingA = cubeA.fieldNames.map(f => allFields.indexOf(f));
    const mappingB = cubeB.fieldNames.map(f => allFields.indexOf(f));

    const data: TO_ResultCubeRow[] = [];

    cubeA.rows.forEach(row => {
        var o = { value: row.value, metaData: new Array<string>(allFields.length) } as TO_ResultCubeRow;
        for (var i = 0; i < cubeA.fieldNames.length; i++) {
            o.metaData[mappingA[i]] = row.metaData[i];
        }
        data.push(o);
    });
    cubeB.rows.forEach(row => {
        var o = { value: row.value, metaData: new Array<string>(allFields.length) } as TO_ResultCubeRow;
        for (var i = 0; i < cubeB.fieldNames.length; i++) {
            o.metaData[mappingB[i]] = row.metaData[i];
        }
        data.push(o);
    });

    return {
        fieldNames: allFields,
        rows: data,
        types: allTypes
    } as TO_ResultCube
}

export const SortCube = (cube: TO_ResultCube, fields: string[], specialSortVectors: { [field: string]: string[] } = {}) => {
    if (!cube)
        return undefined;
    if (!fields)
        return _.cloneDeep(cube);

    var o = _.cloneDeep(cube);
    var indices = fields.map(f => cube.fieldNames.indexOf(f));

    for (var i = indices.length - 1; i >= 0; i--) {
        const ix = indices[i];
        const vector = specialSortVectors[fields[ix]];
        if (vector)
            o.rows = o.rows.sort((a, b) => vector.indexOf(a.metaData[ix]) > vector.indexOf(b.metaData[ix]) ? 1 : -1);
        else
            o.rows = o.rows.sort((a, b) => a.metaData[ix] > b.metaData[ix] ? 1 : -1);
    }

    return o;
}

export const CubeToTable = (cube: TO_ResultCube, fieldHorizontal: string, fieldVertical: string, titleClass: string, cellClass: string) => {
    var matrix = CubeToMatrix(cube, fieldHorizontal, fieldVertical);
    var output = "<table><thead/><tbody>";
    for (var r = 0; r < matrix.length; r++) {
        output += "<tr>"
        for (var c = 0; c < matrix[r].length; c++) {
            if (matrix[r][c] !== undefined) {
                if (r === 0 || c === 0)
                    output += `<td className="${titleClass}">${matrix[r][c]}</td>`
                else
                    output += `<td className="${cellClass}">${matrix[r][c]}</td>`
            }
        }
        output += "</tr>"
    }

    output += "</tbody></table>";

    return <div dangerouslySetInnerHTML={{ __html: output }} />;
}