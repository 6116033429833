import React from "react";
import userStoreInstance from "../user/userStore";
import { TextField } from "@mui/material";
import { MobileTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from "moment";
import moment from "moment";

export interface WrappedTimePickerProps {
    onChange: (date: Moment) => void,
    value: Moment | Date | string,
    style?: React.CSSProperties,
    disableFuture?: boolean,
    disablePast?: boolean,
    minDate?: Date,
    maxDate?: any,
    emptyLabel?: string,
    clearLabel?: React.ReactNode,
    label?: React.ReactNode
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    helperText?: React.ReactNode
}

export class WrappedTimePicker extends React.PureComponent<WrappedTimePickerProps, {}>
{
    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileTimePicker
                    value={this.props.value}
                    onChange={(date)=>this.props.onChange(moment(date))}
                    inputFormat="HH:mm:ss"
                    DialogProps={{ color: "inherit", className: "ListedInstrumentEditorFormFieldInner" }}
                    label={this.props.label}
                    renderInput={(props) => <TextField
                        {...props}
                        style={{ ...this.props.style, color: userStoreInstance.GetTheme().color + " !important" }}
                        InputProps={{classes: { root: "ListedInstrumentEditorFormFieldInner"}}}
                        size={this.props.size ?? "small"}
                        variant="outlined"
                        helperText={this.props.helperText}
                        id={this.props.id}
                        name={this.props.name}
                        error={this.props.error}
                    />}
                />
            </LocalizationProvider>)
    }
}