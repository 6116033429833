import React from "react";
import userStoreInstance from "../user/userStore";
import { TextField } from "@mui/material";
import { MobileDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { WrappedDatePickerProps } from './wrappedDatePicker';
import moment from 'moment';

export interface WrappedDateTimePickerProps extends WrappedDatePickerProps {
    format?: string
}

export class WrappedDateTimePicker extends React.Component<WrappedDateTimePickerProps, {}>
{
    render() {
        return (<LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDateTimePicker
                value={this.props.value}
                onChange={this.props.onChange}
                readOnly={this.props.readOnly}
                disableFuture={this.props.disableFuture}
                disablePast={this.props.disablePast}         
                minDate={this.props.minDate ? moment(this.props.minDate) : undefined}
                maxDate={this.props.maxDate ? moment(this.props.maxDate) : undefined}
                inputFormat={this.props.format ?? (userStoreInstance.GetDateFormat() + " HH:mm:ss")}
                componentsProps = {
                    {actionBar: {actions: [ 'clear', 'cancel', 'accept',  'today']}}
                }
                InputProps={{ classes: { input: "ListedInstrumentEditorFormFieldInner" } }}
                //DialogProps={{ color: "inherit", className: "ListedInstrumentEditorFormFieldInner" }}
                label={this.props.label}
                renderInput={(props) => <TextField
                    {...props}
                    style={{ ...this.props.style, color: userStoreInstance.GetTheme().color + " !important" }}
                    InputProps={{classes: { root: "ListedInstrumentEditorFormFieldInner"}}}
                    size={this.props.size ?? "small"}
                    variant="outlined"
                    helperText={this.props.helperText}
                    id={this.props.id}
                    name={this.props.name}
                    error={this.props.error}
                />}
            />
        </LocalizationProvider>)
    }
}