import React from "react";
import { ScheduleSummary } from "../schedules/schedulesModels";
import FBEmitter from 'fbemitter';
import schedulesStoreInstance from "../schedules/schedulesStore";
import { Button, Grid, ThemeProvider } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { getPositionTableTheme } from "../positions/positionSummaryTable";
import moment from "moment";
import { RefreshOutlined } from "@mui/icons-material";

type JobDashboardState = {
    schedules: ScheduleSummary[];
}

export interface JobDashboardProps { }

export class JobDashBoard extends React.Component<JobDashboardProps, JobDashboardState> {
    eventSubscriptionSchedules: FBEmitter.EventSubscription | undefined;

    constructor(props: JobDashboardProps) {
        super(props);
        this.state = {
            schedules: [],
        }
        this.onSchedulesUpdate = this.onSchedulesUpdate.bind(this);
        this.customToolbar = this.customToolbar.bind(this);
        this.onClickRefresh = this.onClickRefresh.bind(this);
    }

    public async componentDidMount() {
        this.eventSubscriptionSchedules = schedulesStoreInstance.addChangeListener(this.onSchedulesUpdate);
        this.onSchedulesUpdate();
    }

    public componentWillUnmount() {
        if (this.eventSubscriptionSchedules) {
            this.eventSubscriptionSchedules.remove();
            this.eventSubscriptionSchedules = null;
        }
    }

    onSchedulesUpdate() {
        const schedules = schedulesStoreInstance.getSchedules();
        schedules?.forEach(s => {
            if (!Boolean(schedulesStoreInstance.getScheduleLogs(s.scheduleId)))
                schedulesStoreInstance.requestScheduleLogs(s.scheduleId);
        });
        this.setState({ schedules: schedules });
    }

    onClickRefresh() {
        const schedules = schedulesStoreInstance.getSchedules();
        schedules?.forEach(s => {
            schedulesStoreInstance.requestScheduleLogs(s.scheduleId);
        });
        this.setState({ schedules: schedules });
    }

    customToolbar(props: any) {
        return (
            <ThemeProvider theme={getPositionTableTheme()}>
                <GridToolbarContainer style={{ width: "calc(100vw - 200px)" }}>
                    <Grid container spacing={0} style={{ padding: "5px" }}>
                        <Grid item><Button className="MuiButton-outlined PltfmButtonLite" variant="outlined" size="small" startIcon={<RefreshOutlined />} onClick={this.onClickRefresh}>Refresh</Button></Grid>
                        <Grid item><GridToolbarExport {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarFilterButton {...props} className="MuiButton-outlined PltfmButtonLite" style={{ height: "2.4em" }} /></Grid>
                        <Grid item><GridToolbarDensitySelector {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                        <Grid item><GridToolbarColumnsButton {...props} variant="outlined" className="MuiButton-outlined PltfmButtonLite" /></Grid>
                    </Grid>
                </GridToolbarContainer>
            </ThemeProvider>
        );
    }

    renderSuccess(last?: boolean) {
        if (last === undefined || last === null) {
            return undefined;
        }
        return last ? "Success" : "FAIL"
    }

    render() {
        const { schedules } = this.state;

        const colBase = { cellClassName: "PositionSummaryTabTableCell", headerClassName: "PositionSummaryTabTableCellHeader" };
        const columns: GridColDef[] = [
            { field: "scheduleId", headerName: "Id", type: "number", flex: 0.25, ...colBase },
            { field: "jobName", headerName: "Name", type: "string", flex: 0.75, ...colBase },
            { field: "lastRun", headerName: "Last Run", type: "dateTime", flex: 0.75, ...colBase },
            { field: "lastRunWasToday", headerName: "Run Today?", type: "boolean", flex: 0.75, ...colBase },
            { field: "lastRunSuccess", headerName: "Last Status", type: "dateTime", flex: 0.75, ...colBase },
            { field: "nextRun", headerName: "Next Run", type: "dateTime", flex: 0.75, ...colBase },
        ]

        const rows = schedules.map((x, ix) => {
            var lastRunDay = moment(x.lastRunTime).startOf("day");
            var today = moment().startOf("day")
            var row = {
                id: ix,
                lastRun: new Date(x.lastRunTime),
                nextRun: new Date(x.nextRunTime),
                lastRunSuccess: this.renderSuccess(schedulesStoreInstance.getScheduleLogsLatest(x.scheduleId)?.wasSuccessful),
                lastRunWasToday: lastRunDay.isSame(today,"day"),
                ...x
            };
            return row;
        });

        return (
            <div className="SchedulesTab">
                <ThemeProvider theme={getPositionTableTheme()}>
                    <DataGridPro
                        density="standard"
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: this.customToolbar,
                        }}
                        hideFooter
                    />
                </ThemeProvider>
            </div>
        );
    }
}