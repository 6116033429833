import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { MarketDataExplorerStoreState } from './marketDataModels';
import { MarketDataExplorerEditEvent } from './marketDataActions';


class MarketDataExplorerStore extends FluxStore<MarketDataExplorerStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            request: undefined 
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof MarketDataExplorerEditEvent) {
            const { payload } = action;
            this.state.request = payload;
            this.emitChange();
        }
    }

    getRequestDetails() {
        return this.state.request;
    }

    clearRequest() {
        return this.state.request = undefined;
    }
}

const marketDataExplorerStoreInstance = new MarketDataExplorerStore(AppDispatcher);
export default marketDataExplorerStoreInstance;