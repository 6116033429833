import React from 'react';
import FBEmitter from 'fbemitter';
import { CashTrade, LoanDepo, SimpleTrade, Trade, TradeMetaData } from './tradeModels';
import { BookCashTransaction, BookLoanDepo, BookTrade, GetCashTransactions, GetLoanDepos, } from './tradeActions';
import moment from 'moment';
import {
    Grid,
    MenuItem,
    ThemeProvider,
    SelectChangeEvent,
} from '@mui/material';
import { renderDateCell } from '../utils/helpers';
import { getFormTheme } from '../inputs/formCommon';
import { NewTradeForm } from './newTradeForm';
import { Formik, FormikHelpers } from 'formik';
import { NewCashTradeForm } from './newCashTradeForm';
import { BookStr, StrategyStr } from '../globalConstants';
import { TradeBlotterTabProps } from './tradeBlotterTab';
import * as Yup from 'yup';
import { CashTransaction } from '../nav/navModels';
import { NewLoanDepoForm } from './newLoanDepoForm';
import { GridColDef, GridRowData, GridRowParams, DataGridPro } from '@mui/x-data-grid-pro';
import tradesStoreInstance from './tradeStore';
import { WrappedSelect } from '../inputs/wrappedSelect';

const newTradeTemplates = ["General", "Bond", "Equity", "Cash Transaction", "Loan/Depo"];

const LoanDepoColumns: GridColDef[] = [
    { field: "id", headerName: "Id", type: "number", flex: 0.75 },
    { field: "account", headerName: "Account", type: "string", flex: 2 },
    { field: "counterparty", headerName: "Counterparty", type: "string", flex: 2 },
    { field: "nominal", headerName: "Nominal", type: "number", flex: 2 },
    { field: "fixedRate", headerName: "Rate", type: "number", flex: 1.5 },
    { field: "ccy", headerName: "Ccy", type: "string", flex: 1.5 },
    { field: "description", headerName: "Description", type: "string", flex: 3 },
    { field: "externalId", headerName: "External Id", type: "string", flex: 2 },
    { field: "startDate", headerName: "Start Date", type: "date", flex: 2, renderCell: renderDateCell },
    { field: "endDate", headerName: "End Date", type: "date", flex: 2, renderCell: renderDateCell },
    { field: "bookedUtc", headerName: "Booked (UTC)", type: "dateTime", flex: 1.5, renderCell: renderDateCell },
    { field: "updatedUtc", headerName: "Updated (UTC)", type: "dateTime", flex: 1.5, renderCell: renderDateCell },
]

const CashTransactionColumns: GridColDef[] = [
    { field: "id", headerName: "Id", type: "number", flex: 0.75 },
    { field: "account", headerName: "Account", type: "string", flex: 2 },
    { field: "category", headerName: "Category", type: "string", flex: 2 },
    { field: "amount", headerName: "Amount", type: "number", flex: 1.5 },
    { field: "ccy", headerName: "Ccy", type: "string", flex: 1.5 },
    { field: "description", headerName: "Description", type: "string", flex: 3 },
    { field: "externalId", headerName: "External Id", type: "string", flex: 2 },
    { field: "bookingEntity", headerName: "Booking Entity", type: "string", flex: 2 },
    { field: "accrualKeyu", headerName: "Accrual Key", type: "string", flex: 2 },
]

type AddTradeComponentState = {
    selectedTemplate: string,
    hasBeenSubmitted: boolean,
    lastBookedId: number | undefined,
    rows: GridRowData[],
    cols: GridColDef[],
    loanDepoData: LoanDepo,
    cashTransactionData: CashTransaction
}

const NewTradeValidationSchema = Yup.object().shape({
    size: Yup.number()
        .required('Required')
        .typeError('Valid amount required, no column seperators allowed'),
    price: Yup.number()
        .min(0, "Price must be positive")
        .required('Required'),
    listedInstrumentId: Yup.number()
        .min(1, "Instrument required")
        .required('Required'),
    currency: Yup.string()
        .min(3, 'ISO currency code required')
        .max(3, 'ISO currency code required')
        .required('Required')
        .typeError('Valid currency required'),
    executedUtc: Yup.date()
        .required('Required'),
});

const NewCashTradeValidationSchema = Yup.object().shape({
    amount: Yup.number()
        .required('Required')
        .typeError('Valid amount required, no column seperators allowed'),
    account: Yup.string()
        .required('Required'),
    category: Yup.string()
        .required('Required'),
    currency: Yup.string()
        .min(3, 'ISO currency code required')
        .max(3, 'ISO currency code required')
        .required('Required')
        .typeError('Valid currency required'),
    executedUtc: Yup.date()
        .required('Required'),
});

const NewLoanDepoValidationSchema = Yup.object().shape({
    fixedRate: Yup.number()
        .required('Required'),
    nominal: Yup.number()
        .required('Required')
        .typeError('Valid amount required, no column seperators allowed'),
    startDate: Yup.date()
        .required('Required'),
    endDate: Yup.date()
        .required('Required'),
    bookedUtc: Yup.date()
        .required('Required'),
    ccy: Yup.string()
        .min(3, 'ISO currency code required')
        .max(3, 'ISO currency code required')
        .required('Required')
        .typeError('Valid currency required'),
    account: Yup.string()
        .required('Required'),
    counterparty: Yup.string()
        .required('Required'),
});

export class AddTradeComponent extends React.Component<{}, AddTradeComponentState>{
    eventSubscriptionTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionInstruments: FBEmitter.EventSubscription | undefined;

    constructor(props: TradeBlotterTabProps) {
        super(props)
        this.state = {
            selectedTemplate: newTradeTemplates[0],
            hasBeenSubmitted: false,
            lastBookedId: undefined,
            cols: [],
            rows: [],
            loanDepoData: undefined,
            cashTransactionData: undefined
        };

        this.onDuplicatePressed = this.onDuplicatePressed.bind(this);
        this.onSubmitNewCashTrade = this.onSubmitNewCashTrade.bind(this);
        this.onResetCashTrade = this.onResetCashTrade.bind(this);
        this.onSubmitNewLoanDepo = this.onSubmitNewLoanDepo.bind(this);
        this.onResetLoanDepo = this.onResetLoanDepo.bind(this);
        this.onTradeUpdate = this.onTradeUpdate.bind(this);
        this.onChangeTemplate = this.onChangeTemplate.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.onSubmitNewTrade = this.onSubmitNewTrade.bind(this);
    }

    async componentDidMount() {
        this.eventSubscriptionTrades = tradesStoreInstance.addChangeListener(this.onTradeUpdate)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionTrades) {
            this.eventSubscriptionTrades.remove();
        }
    }

    onTradeUpdate() {
        const { selectedTemplate } = this.state;
        switch (selectedTemplate) {
            case "Loan/Depo":
                var trades = tradesStoreInstance.getLoanDepos();
                var rows = trades.map(t => {
                    var r = { id: t.loanDepoId } as GridRowData;
                    Object.keys(t).forEach(k => {
                        if (k !== "loanDepoId")
                            r[k] = t[k];
                    });
                    return r;
                });
                this.setState({ cols: LoanDepoColumns, rows });
                break;
            case "Cash Transaction":
                var cashTrans = tradesStoreInstance.getCashTransactions();
                var rows2 = cashTrans.sort((a, b) => b.transId - a.transId).map(t => {
                    var r = { id: t.transId } as GridRowData;
                    Object.keys(t).forEach(k => {
                        if (k !== "transId")
                            r[k] = t[k];
                    });
                    return r;
                });
                this.setState({ cols: CashTransactionColumns, rows: rows2 });
                break;
            default:
                this.setState({ cols: [], rows: [] });
                break;
        }
    }

    async onChangeTemplate(event: SelectChangeEvent<string>) {
        var template = event.target.value as string;
        this.setState({ selectedTemplate: template });
        switch (template) {
            case "Loan/Depo":
                await GetLoanDepos();
                break;
            case "Cash Transaction":
                await GetCashTransactions();
                break;
            default:
                this.setState({ cols: [], rows: [] });
                break;
        }
    }

    async onSubmitNewTrade(values: SimpleTrade, { setSubmitting }: FormikHelpers<SimpleTrade>) {
        //console.log(JSON.stringify(values, null, 2));
        var trade = {
            bookedUtc: moment.utc().toDate(),
            currency: values.currency,
            isInternal: true,
            listedInstrumentId: values.listedInstrumentId,
            size: Number(values.size),
            price: Number(values.price),
            tradeMetaData: [],
            status: "Booked",
            executedUtc: values.executedUtc,
            createdBy: values.createdBy,
            homepartyId: values.bookingEntityId ? Number(values.bookingEntityId) : null
        } as Trade;
        if (values.book) { trade.tradeMetaData.push({ type: BookStr, data: values.book, lastUpdated: moment.utc().toDate() } as TradeMetaData) }
        if (values.strategy) { trade.tradeMetaData.push({ type: StrategyStr, data: values.strategy, lastUpdated: moment.utc().toDate() } as TradeMetaData) }
        if (values.account) { trade.tradeMetaData.push({ type: "Account", data: values.account, lastUpdated: moment.utc().toDate() } as TradeMetaData) }
        var lastBookedId = await BookTrade(trade);
        setSubmitting(false);
        this.setState({ lastBookedId });
    }

    async onSubmitNewCashTrade(values: CashTrade, { setSubmitting }: FormikHelpers<CashTrade>) {
        //console.log(JSON.stringify(values, null, 2));
        var transaction = {
            account: values.account,
            amount: values.amount,
            ccy: values.currency,
            updatedUtc: moment.utc(),
            bookedUtc: moment(values.executedUtc),
            valueDate: moment(values.executedUtc),
            category: values.category,
            description: values.description,
            externalId: values.externalId,
            homepartyId: values.bookingEntityId === 2 ? 2 : null,
            accrualKey: values.accrualKey
        } as CashTransaction;
        this.setState({ hasBeenSubmitted: true });
        var trans = await BookCashTransaction(transaction);
        var lastBookedId = trans?.transId;
        setSubmitting(false);
        this.setState({ lastBookedId });
    }

    onResetCashTrade(values: CashTrade, { resetForm }: FormikHelpers<CashTrade>) {
        this.setState({ hasBeenSubmitted: false, lastBookedId: undefined });
        resetForm();
    }

    async onSubmitNewLoanDepo(values: LoanDepo, { setSubmitting }: FormikHelpers<LoanDepo>) {
        this.setState({ hasBeenSubmitted: true });
        var loanDepo = {
            account: values.account,
            bookedUtc: values.bookedUtc,
            ccy: values.ccy,
            counterparty: values.counterparty,
            description: values.description,
            endDate: values.endDate,
            externalId: values.externalId,
            fixedRate: Number(values.fixedRate),
            nominal: Number(values.nominal),
            startDate: values.startDate,
        } as LoanDepo;
        var lastBookedId = await BookLoanDepo(loanDepo);
        setSubmitting(false);
        this.setState({ lastBookedId });
    }

    onResetLoanDepo(values: LoanDepo, { resetForm }: FormikHelpers<LoanDepo>) {
        this.setState({ hasBeenSubmitted: false, lastBookedId: undefined });
        resetForm();
    }

    onDuplicatePressed = () => this.setState({ hasBeenSubmitted: false, lastBookedId: undefined });

    renderAddTradeForm() {
        const { selectedTemplate: selectedNewTradeTemplate, hasBeenSubmitted, lastBookedId, loanDepoData, cashTransactionData } = this.state;
        switch (selectedNewTradeTemplate) {
            case "General":
            case "Bond":
            case "Equity":
                return (<Formik
                    onSubmit={this.onSubmitNewTrade}
                    enableReinitialize={true}
                    initialValues={{} as SimpleTrade}
                    validationSchema={NewTradeValidationSchema}>
                    {props => <NewTradeForm className="NewTradeForm" {...props} filterType={selectedNewTradeTemplate === "General" ? null : selectedNewTradeTemplate} canDuplicate={hasBeenSubmitted} onDuplicatePressed={this.onDuplicatePressed} lastBookedId={lastBookedId} />}
                </Formik>);
            case "Cash Transaction":
                var data = cashTransactionData ?
                    {
                        account: cashTransactionData.account,
                        accrualKey: cashTransactionData.accrualKey,
                        amount: cashTransactionData.amount,
                        bookingEntityId: cashTransactionData.homepartyId,
                        category: cashTransactionData.category,
                        createdUtc: moment(cashTransactionData?.bookedUtc)?.toDate(),
                        currency: cashTransactionData.ccy,
                        description: cashTransactionData.description,
                        executedUtc: moment(cashTransactionData?.bookedUtc)?.toDate(),
                        externalId: cashTransactionData.externalId,
                    } as CashTrade : {} as CashTrade;
                return (<Formik
                    onSubmit={this.onSubmitNewCashTrade}
                    onReset={this.onResetCashTrade}
                    enableReinitialize={true}
                    initialValues={data}
                    validationSchema={NewCashTradeValidationSchema}>
                    {props => <NewCashTradeForm className="NewTradeForm" {...props} canDuplicate={hasBeenSubmitted} onDuplicatePressed={this.onDuplicatePressed} lastBookedId={lastBookedId} />}
                </Formik>);
            case "Loan/Depo":
                return (<Formik
                    onSubmit={this.onSubmitNewLoanDepo}
                    onReset={this.onResetLoanDepo}
                    enableReinitialize={true}
                    initialValues={loanDepoData ?? {} as LoanDepo}
                    validationSchema={NewLoanDepoValidationSchema}>
                    {props => <NewLoanDepoForm className="NewTradeForm" {...props} canDuplicate={hasBeenSubmitted} onDuplicatePressed={this.onDuplicatePressed} lastBookedId={lastBookedId} />}
                </Formik>);
        }
    }

    onClickRow(param: GridRowParams, event: React.MouseEvent<Element, MouseEvent>) {
        var idVal = param.getValue(param.id, "id");
        const { selectedTemplate } = this.state;
        switch (selectedTemplate) {
            case "Loan/Depo":
                var data = tradesStoreInstance.getLoanDepos().filter(l => l.loanDepoId === idVal)[0];
                if (data) {
                    this.setState({ loanDepoData: data, hasBeenSubmitted: true });
                }
                break;
            case "Cash Transaction":
                var data2 = tradesStoreInstance.getCashTransactions().filter(l => l.transId === idVal)[0];
                if (data2) {
                    this.setState({ cashTransactionData: data2, hasBeenSubmitted: true });
                }
                break;
        }
    }


    render() {
        const { selectedTemplate, rows, cols } = this.state;
        return (
            <div className="AddTradeComponent">
                <ThemeProvider theme={getFormTheme()}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" height="100%">
                        <Grid item>
                            <WrappedSelect
                                id="newTradeTemplate"
                                name="newTradeTemplate"
                                label="Template"
                                value={selectedTemplate}
                                onChange={this.onChangeTemplate}>
                                {newTradeTemplates.map(a =>
                                    <MenuItem key={"affki" + a} value={a}>{a}</MenuItem>)}
                            </WrappedSelect>
                        </Grid>
                        <Grid container item spacing={2} direction="column" justifyContent="space-between" alignItems="flex-start" height="calc(100% - 40px)">
                            <Grid item className="AddTradeMain">
                                {this.renderAddTradeForm()}
                            </Grid>
                            {cols && cols.length > 0 ? <Grid item className="AddTradeBlotterGrid">
                                <DataGridPro
                                    columns={cols}
                                    rows={rows}
                                    className="TradeBlotterDataGrid"
                                    onRowClick={this.onClickRow}
                                    hideFooter />
                            </Grid> : null}
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </div>
        );
    }
}