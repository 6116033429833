import { Button, Typography } from '@mui/material';
import { CandlestickChartOutlined, InsertChartOutlinedOutlined, ViewInArOutlined } from '@mui/icons-material';
import FBEmitter from 'fbemitter';
import React from 'react';
import layoutStoreInstance from '../../layout/layoutStore';
import { FixingDashboard } from './fixingDashboard';
import { SurfaceExplorer } from './surfaceExplorer';
import { MarketDataExplorer } from './marketDataExplorer';
//import { CandleChart } from './candleChart';
import { CandleDashboard } from './candleDashboard';

interface MarketDataTabState {
    currentMode: MarketDataTabMode,
    collapsed: boolean,
    explorerOpen: boolean
}

enum MarketDataTabMode {
    None,
    Price,
    Surface,
    TwoPointOh,
    Candles
}

export interface MarketDataTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export class MarketDataTab extends React.Component<MarketDataTabProps, MarketDataTabState>{
    eventSubscriptionLayout: FBEmitter.EventSubscription | undefined;
    constructor(props: any) {
        super(props)
        var tabModeStr = this.props.getState("tabMode");
        var tabMode: MarketDataTabMode = !isNaN(Number(tabModeStr)) ? MarketDataTabMode[MarketDataTabMode[Number(tabModeStr)]] : MarketDataTabMode.None;
        this.state = {
            currentMode: tabMode,
            collapsed: false,
            explorerOpen: false
        };;

        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.eventSubscriptionLayout = layoutStoreInstance.addChangeListener(this.onLayoutChange)
    }

    componentWillUnmount() {
        if (this.eventSubscriptionLayout) {
            this.eventSubscriptionLayout.remove();
        }
    }

    onLayoutChange() {
        var isCollapsed = layoutStoreInstance.GetMenuCollapse();
        if (isCollapsed !== this.state.collapsed)
            this.setState({ collapsed: isCollapsed });
    }

    renderContent = () => {
        switch (this.state.currentMode) {
            case MarketDataTabMode.None:
                return (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", paddingTop: "50px" }}>
                        <Typography>Please select from the menu on the left</Typography>
                    </div>);
            case MarketDataTabMode.Price:
                return <FixingDashboard {...this.props} fullWidth={this.state.collapsed} />
            case MarketDataTabMode.Surface:
                return <SurfaceExplorer {...this.props} fullWidth={this.state.collapsed} />
            case MarketDataTabMode.TwoPointOh:
                return <MarketDataExplorer {...this.props} isOpen={this.state.explorerOpen} onClose={() => this.setState({ explorerOpen: false })} launchComponent={() => { }} editComponent={()=>{}}/>
            case MarketDataTabMode.Candles:
                return <CandleDashboard {...this.props} fullWidth={this.state.collapsed} />
            default:
                return (<div></div>)
        }
    };

    private getButtonVariant(buttonMode: MarketDataTabMode) {
        return this.state.currentMode === buttonMode ? "contained" : "outlined";
    }

    private getButtonClass(buttonMode: MarketDataTabMode) {
        return this.state.currentMode === buttonMode ? "PltfmButtonLiteInverse" : "PltfmButtonLite";
    }

    onClick(mode: MarketDataTabMode) {
        this.props.onChangeState("tabMode", mode.toString());
        this.setState({ currentMode: mode });

        if (mode === MarketDataTabMode.TwoPointOh)
            this.setState({ explorerOpen: true });
    }

    renderButton(mode: MarketDataTabMode, icon: any, text: string) {
        return <Button
            className={this.getButtonClass(mode)}
            variant={this.getButtonVariant(mode)}
            startIcon={icon}
            onClick={() => this.onClick(mode)}>{text}</Button>
    }

    render() {
        const { collapsed } = this.state;
        var sideWidth = collapsed ? "0px" : "175px";
        return (<div style={{ height: "calc(100vh - 120px)", position: "absolute", width: "100vw" }}>
            <table style={{ height: "100%", width: "100%", borderCollapse: "collapse" }}>
                <thead></thead>
                <tbody>
                    <tr>
                        <td style={{ borderRight: collapsed ? "none" : "2px solid darkgrey", verticalAlign: "top", height: "100%", width: (collapsed ? 0 : sideWidth), transition: "width 0.25s linear", visibility: (collapsed ? "hidden" : "visible") }}>
                            {collapsed ? null : <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>
                                <div style={{ height: "20px" }} />
                                {this.renderButton(MarketDataTabMode.Price, <InsertChartOutlinedOutlined />, "Price")}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(MarketDataTabMode.Surface, <ViewInArOutlined />, "Surface")}

                                {/* <div style={{ height: "20px" }} />
                                {this.renderButton(MarketDataTabMode.TwoPointOh, <RocketLaunchOutlined />, "2.0")} */}

                                <div style={{ height: "20px" }} />
                                {this.renderButton(MarketDataTabMode.Candles, <CandlestickChartOutlined />, "Candles")}
                            </div>}
                        </td>
                        <td style={{ verticalAlign: "top", width: `calc(100vw - ${sideWidth})`, height: "calc(100vh - 200px)" }}>
                            {this.renderContent()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>);

    }
}
