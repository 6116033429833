import React from 'react';
import FBEmitter from 'fbemitter';
import jobResultStoreInstance from './jobResultStore';
import {
    Button,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    Typography,
    SelectChangeEvent,
} from '@mui/material';
import { getFormTheme } from '../inputs/formCommon';
import { JobResultUpdate, JobSpec, NewJobRequest } from './jobModels';
import { LockResetOutlined, PlayCircleFilledOutlined, UndoRounded } from '@mui/icons-material';
import { v4 } from 'uuid';
import { StartJob } from './jobActions';
import { parseInt } from 'lodash';
import moment from 'moment';
import { ListChildComponentProps } from 'react-window';
import userStoreInstance from '../user/userStore';
import { renderInput } from './jobCommon';
import { WrappedSelect } from '../inputs/wrappedSelect';

type RunJobTabState = {
    selectedCategory: string | undefined,
    selectedJobSpec: JobSpec | undefined,
    selectedJob: NewJobRequest | undefined,
    jobInstances: Map<string, NewJobRequest[]>,
    jobResults: Map<string, JobResultUpdate[]>,
    selectedRun: string | undefined
}

export interface RunJobTabProps {
    onChangeState: (key: string, value: string) => void;
    getState: (key: string) => string;
}

export const nullJob = { jobName: "Select Job..." } as JobSpec;

export class RunJobTab extends React.Component<RunJobTabProps, RunJobTabState> {
    eventSubscriptionJobs: FBEmitter.EventSubscription | undefined;

    constructor(props: RunJobTabProps) {
        super(props)
        this.state = {
            selectedJobSpec: undefined,
            selectedRun: undefined,
            selectedJob: undefined,
            selectedCategory: undefined,
            jobResults: new Map<string, JobResultUpdate[]>(),  //keyed by jobKey
            jobInstances: new Map<string, NewJobRequest[]>()   //keyed by jobName
        };

        this.onJobUpdate = this.onJobUpdate.bind(this);
        this.renderInputLocal = this.renderInputLocal.bind(this);
        this.setValue = this.setValue.bind(this);
        this.onChangeSelectedRun = this.onChangeSelectedRun.bind(this);
        this.clearParams = this.clearParams.bind(this);
    }

    public async componentDidMount() {
        this.eventSubscriptionJobs = jobResultStoreInstance.addChangeListener(this.onJobUpdate);
        var instances = jobResultStoreInstance.getInstances();
        var specs = jobResultStoreInstance.getSpecs();
        specs.forEach(spec => {
            this.state.jobInstances.set(spec.jobName, instances.filter(x => x.jobName === spec.jobName));
        });
    }

    public componentWillUnmount() {
        if (this.eventSubscriptionJobs) {
            this.eventSubscriptionJobs.remove();
            this.eventSubscriptionJobs = null;
        }
    }

    onJobUpdate() {
        const { selectedJobSpec, jobInstances, jobResults } = this.state;
        if (selectedJobSpec) {
            var requests = jobInstances.get(selectedJobSpec.jobName);
            if (!requests) {
                requests = new Array<NewJobRequest>();
                requests.push({ jobKey: v4(), jobName: selectedJobSpec.jobName, isRunning: true, created: new Date() } as NewJobRequest);
                jobInstances.set(selectedJobSpec.jobName, requests);
            }

            requests.map(k => {
                var results = jobResultStoreInstance.getResults(k.jobKey);
                if (results) {
                    jobResults.set(k.jobKey, results);
                    if (results[results.length - 1].isComplete) {
                        k.isRunning = false;
                    }
                }
                return null;
            });

            this.setState({ jobInstances: jobInstances, jobResults });
        }
    }


    private async runJob() {
        const { selectedJobSpec, jobInstances, selectedJob, jobResults } = this.state;
        if (selectedJob) {
            var newJob = selectedJob;
            //see if its been run already, if so clone it and run it
            if (jobResults.has(selectedJob.jobKey)) {
                newJob = { jobKey: v4(), jobName: selectedJob.jobName, isRunning: true, created: new Date(), params: selectedJob.params } as NewJobRequest;
            }

            var requests = jobInstances.get(selectedJobSpec.jobName);
            if (!requests) {
                requests = new Array<NewJobRequest>();
                requests.push(newJob);
                jobInstances.set(selectedJobSpec.jobName, requests);
            }
            else {
                requests.push(newJob);
            }

            newJob.isRunning = true;
            jobResultStoreInstance.addJobInstance(newJob);
            await StartJob(newJob);

            this.setState({ jobInstances, selectedJob: newJob, selectedRun: newJob.jobKey });
        }
    }
    //(event: SelectChangeEvent<string[]>, child: ReactNode)
    private onJobSelect(e: SelectChangeEvent<string>) {
        const { jobInstances } = this.state;
        let selectedJob: NewJobRequest | undefined = undefined;
        var jobStr = e.target.value === nullJob.jobName ? null : e.target.value;
        var spec = jobResultStoreInstance.getSpecs().filter(j => j.jobName === jobStr)[0];
        if (spec) {
            var requests = jobInstances.get(spec.jobName);
            if (!requests || requests.length === 0) {
                requests = new Array<NewJobRequest>();
                selectedJob = { jobKey: v4(), jobName: spec.jobName, isRunning: false, created: new Date(), params: { ...spec.paramDefaults } } as NewJobRequest;

                Array.from(Object.keys(spec.paramDefaults)).forEach(paramName => {
                    var val = this.props.getState(`${selectedJob?.jobName}-${paramName}`);
                    if (val)
                        selectedJob.params[paramName] = val;
                });

                jobInstances.set(spec.jobName, requests);
            }
            else {
                selectedJob = requests.sort((a, b) => a.created.valueOf() - b.created.valueOf())[0];
            }
        }

        this.setState({ selectedJobSpec: spec, jobInstances, selectedJob });
    }

    private clearParams() {
        const { selectedJob } = this.state;
        var spec = jobResultStoreInstance.getSpecs().filter(j => j.jobName === selectedJob.jobName)[0];
        Array.from(Object.keys(spec.paramDefaults)).forEach(paramName => {
            selectedJob.params[paramName] = spec.paramDefaults[paramName];
            this.props.onChangeState(`${selectedJob?.jobName}-${paramName}`, null);
        });
        selectedJob.params = { ...spec.paramDefaults };
        this.setState({ selectedJob });
    }

    private setValue(key: string, value: string) {
        const { selectedJob } = this.state;

        if (selectedJob && !selectedJob.params)
            selectedJob.params = {};

        selectedJob.params[key] = value
        this.props.onChangeState(`${selectedJob?.jobName}-${key}`, value);
        this.setState({ selectedJob });
    }

    private validateParams(): boolean {
        const { selectedJob, selectedJobSpec } = this.state;
        const validBools = ["true", "false"];
        if (selectedJob) {
            var params = Array.from(Object.keys(selectedJobSpec.paramTypes));
            var isOk = true;
            for (var i = 0; i < params.length; i++) {
                var p = params[i];
                var typ = selectedJobSpec.paramTypes[p];
                switch (typ) {
                    case "Integer":
                        isOk = isOk && !Number.isNaN(Number(selectedJob.params[p])) && !Number.isNaN(parseInt(selectedJob.params[p]));
                        break;
                    case "Float":
                        isOk = isOk && !Number.isNaN(Number(selectedJob.params[p]));
                        break;
                    case "Boolean":
                        isOk = isOk && validBools.includes(selectedJob.params[p]?.toLowerCase());
                        break;
                    case "DateTime":
                        isOk = isOk && selectedJob.params[p] !== null;
                        break;
                    case "Enum":
                        isOk = isOk && selectedJobSpec.paramEnumValues[p]?.includes(selectedJob.params[p]);
                        break;
                    default:
                        break;
                }
            }
            return isOk;
        }
        return false;
    }

    private renderInputLocal(key: string, type: string, defaultValue: string) {
        return renderInput(key, type, defaultValue, this.setValue, this.state.selectedJobSpec, this.state.selectedJob);
    }

    private renderDate(date: Date) {
        var isToday = moment().format("yyyy-MM-DD") === moment(date).format("yyyy-MM-DD");
        return isToday ? moment(date).format("HH:mm:ss") : moment(date).format("yyyy-MM-DD HH:mm:ss");
    }

    private renderResultRow(props: ListChildComponentProps, results: JobResultUpdate[]) {
        var r = results[props.index];
        return <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
            <div style={{ verticalAlign: "middle" }}>{this.renderDate(r.stamp)}</div>
            <div style={{ padding: "5px" }}> - </div>
            <pre>{r.message}</pre>
        </div>
    }

    onChangeSelectedRun(e: SelectChangeEvent<string>) {
        this.setState({ selectedRun: e.target.value as string });
    }

    render() {
        const { selectedJobSpec, selectedJob, selectedRun, jobInstances, selectedCategory } = this.state;
        const parameterKeys = selectedJob ? Array.from(Object.keys(selectedJobSpec.paramTypes)) : new Array<string>();
        const hasParameters = parameterKeys.length > 0;
        const results = selectedRun && jobResultStoreInstance.getResults(selectedRun) ? jobResultStoreInstance.getResults(selectedRun) : new Array<JobResultUpdate>();
        const jobRunning = selectedJob && selectedJob.isRunning;
        const isValid = this.validateParams();
        const instancesForSpec = jobInstances.get(selectedJobSpec?.jobName) ?? new Array<NewJobRequest>();

        return (
            <div className="JobsTab">
                <ThemeProvider theme={getFormTheme()}>
                    <div className="JobsTabSearch">
                        <Grid container spacing={2} direction="column" alignContent="center">
                            <Grid item><WrappedSelect
                                style={{ minWidth: "200px" }}
                                id="jobSelectCty"
                                name="jobSelectCty"
                                label="Category"
                                value={selectedCategory ?? "Select Category..."}
                                onChange={(e) => {
                                    var val = e.target.value as string;
                                    if (val === "Select Category...")
                                        val = undefined;
                                    this.setState({ selectedCategory: val });
                                }}>
                                <MenuItem key={"jobCtgxNull"} value={"Select Category..."}>Select Category...</MenuItem>
                                {jobResultStoreInstance.getCategories().sort((a,b)=>a>b?1:-1).map(j =>
                                    <MenuItem key={"jobCrtx" + j} value={j}>{j}</MenuItem>)}
                            </WrappedSelect></Grid>
                            <Grid item><WrappedSelect
                                style={{ minWidth: "200px" }}
                                id="jobSelect"
                                name="jobSelect"
                                label="Job"
                                value={selectedJobSpec?.jobName ?? nullJob.jobName}
                                onChange={(e) => this.onJobSelect(e)}>
                                <MenuItem key={"jobxNull"} value={nullJob.jobName}>{nullJob.jobName}</MenuItem>
                                {jobResultStoreInstance.getSpecs().filter(j => Boolean(selectedCategory) ? j.category === selectedCategory : true).map(j =>
                                    <MenuItem key={"jobx" + j.jobName} value={j.jobName}>{j.jobName}</MenuItem>)}
                            </WrappedSelect></Grid>
                        </Grid>
                        {selectedJobSpec ?
                            <div className="JobsTabDetail">
                                <TableContainer>
                                    <Table>
                                        <TableHead />
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="JobDetailTableTitle" style={{ color: userStoreInstance.GetTheme().color }}>Name</TableCell>
                                                <TableCell className="JobDetailTableText">{selectedJobSpec.jobName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="JobDetailTableTitle">Description</TableCell>
                                                <TableCell className="JobDetailTableText">{selectedJobSpec.description}</TableCell>
                                            </TableRow>
                                            {hasParameters ?
                                                <TableRow>
                                                    <TableCell colSpan={2} className="JobDetailTableTitle">Parameters</TableCell>
                                                </TableRow> : null}
                                            {hasParameters ? parameterKeys.map(k =>
                                                <TableRow>
                                                    <TableCell className="JobDetailTableTitle">{k}</TableCell>
                                                    <TableCell className="JobDetailTableText">{this.renderInputLocal(k, selectedJobSpec.paramTypes[k], selectedJobSpec.paramDefaults[k])}</TableCell>
                                                </TableRow>) : null}
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Grid container justifyContent="center" spacing={2}>
                                                        <Grid item><Button variant="outlined" className="PltfmButtonLite" startIcon={<PlayCircleFilledOutlined />} onClick={() => this.runJob()} disabled={jobRunning || !isValid}>Run Job</Button></Grid>
                                                        {!jobRunning && <Grid item><Button variant="outlined" className="PltfmButtonLite" startIcon={<UndoRounded />} onClick={() => this.clearParams()} >Defaults</Button></Grid>}
                                                        {jobRunning && <Grid item><Button className="PltfmButtonLite" variant="outlined" disabled={!jobRunning} startIcon={<LockResetOutlined/>} onClick={() => { selectedJob.isRunning = false; this.setState({selectedJob}) }}>Unlock</Button></Grid>}
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div> : null}

                    </div>
                    <div className="JobsTabDisplay">
                        <Grid container spacing={2} alignContent="center">
                            <Grid item>
                                <WrappedSelect
                                    style={{ minWidth: "200px" }}
                                    id="runSelect"
                                    name="runSelect"
                                    label="Run"
                                    value={selectedRun !== undefined && selectedRun !== null ? selectedRun : ''}
                                    onChange={this.onChangeSelectedRun}>
                                    {instancesForSpec.map(i => <MenuItem key={"instance" + i.jobKey} value={i.jobKey}>{this.renderDate(i.created)} - {i.jobKey}</MenuItem>)}
                                </WrappedSelect>
                            </Grid>
                            <Grid item>
                                <Button className="PltfmButtonLite" variant="outlined" disabled={!results} onClick={() => this.download(results, `${selectedJobSpec.jobName}-${selectedRun}.csv`)}>Download</Button>
                            </Grid>
                        </Grid>
                        <div style={{ padding: "5px" }}><Typography variant="h4">Job Results</Typography></div>
                        {results ? results.slice(-100).sort((a, b) => (a.stamp.valueOf()) > (b.stamp.valueOf()) ? -1 : 1).map(r => {
                            return <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", color: userStoreInstance.GetTheme().color }}>
                                <div style={{ verticalAlign: "middle", color: userStoreInstance.GetTheme().color }}>{this.renderDate(r.stamp)}</div>
                                <div style={{ padding: "2px", color: userStoreInstance.GetTheme().color }}> - </div>
                                <pre>{r.message.includes("<html>") ? <div style={{backgroundColor:"white"}} dangerouslySetInnerHTML={{ __html: r.message }} /> : r.message}</pre>
                            </div>
                        }) : null}
                    </div>
                </ThemeProvider>
            </div>
        );

    }

    download(results: JobResultUpdate[], jobName: string) {
        const element = document.createElement("a");
        var rawData = results.sort((a, b) => (a.stamp.valueOf()) > (b.stamp.valueOf()) ? -1 : 1).map(r => {
            return this.renderDate(r.stamp) + "," + r.message;
        });
        const data = new Blob([rawData.join('\n')], { type: 'text/plain' })
        element.href = URL.createObjectURL(data);
        element.download = jobName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
}
