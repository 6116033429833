import { userMessageServiceState } from "./userMessagesModels";
import FluxStore from '../FluxStore'
import { AppDispatcher, Event } from "../dispatcher/appDispatcher";
import { NewMessageEvent } from "../userSession/userSessionActions";

export class userMessagestore extends FluxStore<userMessageServiceState>{
    constructor(dispatcher : typeof AppDispatcher){
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            messageHistory: []
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof NewMessageEvent) {
            this.state.messageHistory.push(action.payload);
            this.emitChange();
        }
    }

    public getLatestMessage(){
        return this.state.messageHistory.pop();
    }
}

const userMessageStoreInstance = new userMessagestore(AppDispatcher);
export default userMessageStoreInstance;