import { Button } from '@mui/material';
import React from 'react';
import { get } from '../utils/httpUtils';

export interface EngineeringIBTokenRequestProps {
}

interface EngineeringIBTokenRequestState {
}

export class EngineeringIBTokenRequestTab extends React.Component<EngineeringIBTokenRequestProps, EngineeringIBTokenRequestState>
{
 
    async clickIb(){
        console.log("getting api key");
        var response = await get<ApiRequest>("ApiKey/IB/Request");
        var url = response.payload.url;
        console.log(url);
        console.log(`Got API Key ${url}`);
        
        window.open(url, '_blank').focus();
    }
    
    render(){
             return (<div>
                 <Button onClick={() => this.clickIb()}>Get Token</Button>
             </div>)
    }
}

export interface ApiRequest {
    url: string,
} 