import { BrentsMethodSolve } from "./brentSolver";
import { NormInv, NormSDist, Phi } from "./statistics";

export function D1d2(forward: number, strike: number, expTime: number, volatility: number): [number, number] {
    var d1 = (Math.log(forward / strike) + (expTime / 2 * (Math.pow(volatility, 2)))) / (volatility * Math.sqrt(expTime));
    var d2 = d1 - volatility * Math.sqrt(expTime);

    return [d1, d2];
}

export function BlackPV(forward: number, strike: number, riskFreeRate: number, expTime: number, volatility: number, isCall: boolean): number {
    var cpf = !isCall ? -1.0 : 1.0;

    var num2 = (Math.log(forward / strike) + ((expTime / 2.0) * Math.pow(volatility, 2.0))) / (volatility * Math.sqrt(expTime));
    var num3 = num2 - (volatility * Math.sqrt(expTime));
    return (Math.exp(-riskFreeRate * expTime) * (((cpf * forward) * NormSDist(num2 * cpf)) - ((cpf * strike) * NormSDist(num3 * cpf))));
}

export function BlackDigitalPV( forward: number,  strike: number,  riskFreeRate: number,  expTime: number,  volatility: number, isCall: boolean) : number
{
    var cpf = (!isCall) ? -1.0 : 1.0;
    var ds = D1d2(forward, strike, expTime, volatility);
    return Math.exp(-riskFreeRate * expTime) * NormSDist(ds[1] * cpf);
}

export function BlackDelta(forward: number, strike: number, riskFreeRate: number, expTime: number, volatility: number, isCall: boolean): number {
    var dF = Math.exp(-riskFreeRate * expTime);
    var d = D1d2(forward, strike, expTime, volatility);
    var d1 = d[0];
    return dF * (!isCall ? (NormSDist(d1) - 1) : NormSDist(d1));
}

export function BlackGamma(forward: number, strike: number, riskFreeRate: number, expTime: number, volatility: number): number {
    var dF = Math.exp(-riskFreeRate * expTime);
    var d = D1d2(forward, strike, expTime, volatility);
    var d1 = d[0];
    return dF * Phi(d1) / (forward * volatility * Math.sqrt(expTime)) * (0.01 * forward);
}

export function BlackVega(forward: number, strike: number, riskFreeRate: number, expTime: number, volatility: number): number {
    var d = (Math.log(forward / strike) + ((expTime / 2.0) * Math.pow(volatility, 2.0))) / (volatility * Math.sqrt(expTime));
    var num5 = Math.exp(-riskFreeRate * expTime);
    return (((forward * num5) * Phi(d)) * Math.sqrt(expTime)) / 100.0;
}

export function BlackTheta(forward: number, strike: number, riskFreeRate: number, expTime: number, volatility: number, isCall: boolean): number {
    var dF = Math.exp(-riskFreeRate * expTime);
    var d = D1d2(forward, strike, expTime, volatility);
    var d1 = d[0];
    var d2 = d[1];
    return !isCall ?
        -forward * dF * Phi(d1) * volatility / (2.0 * Math.sqrt(expTime)) + riskFreeRate * forward * dF * NormSDist(d1) - riskFreeRate * strike * dF * NormSDist(d2) :
        -forward * dF * Phi(d1) * volatility / (2.0 * Math.sqrt(expTime)) - riskFreeRate * forward * dF * NormSDist(-d1) + riskFreeRate * strike * dF * NormSDist(-d2);
}

export function BlackImpliedVol(forward: number, strike: number, riskFreeRate: number, expTime: number, premium: number, isCall: boolean): number {

    const testBlack = (vol) => {
        return BlackPV(forward, strike, riskFreeRate, expTime, vol, isCall) - premium;
    };

    var impliedVol = BrentsMethodSolve(testBlack, 0.000000001, 5.0000000, 1e-10);
    return impliedVol;
}

export function AbsoluteStrikefromDeltaKAnalytic(forward: number, delta: number, riskFreeRate: number, expTime: number, volatility: number)
{
    var psi = Math.sign(delta);
    var sqrtT = Math.sqrt(expTime);
    var q = NormInv(psi * delta);
    return forward * Math.exp(-psi * volatility * sqrtT * q + 0.5 * Math.pow(volatility, 2) * expTime);
}
