import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel } from "@mui/material";
import React from "react";

export interface DaysToIncludeControlProps {
    onChange?: (daysToInclude: number[]) => void,
    value: number[],
    style?: React.CSSProperties,
    label?: React.ReactNode
    size?: "small" | "medium",
    id?: string,
    name?: string,
    error?: boolean,
    autoWidth?: boolean
}

const Days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const DaysToIncludeControl = props => {

    function onChangeDayCheck(day: number) {
        var val = props.value ?? new Array<number>();
        if (props.value.includes(day))
            props.onChange(val.filter(x => x !== day));
        else
            props.onChange([...val, day]);
    }

    function renderDayControl(day: number) {
        var val = props.value ?? new Array<number>();
        return <FormControlLabel control={<Checkbox key={Days[day]} checked={val.includes(day)} onChange={() => onChangeDayCheck(day)} />} label={Days[day]} />
    }

    return (<FormControl size={props.size ?? "small"}>
        <InputLabel variant="outlined">{props.label}</InputLabel>
        <FormGroup row>
            {Days.map((x, ix) => renderDayControl(ix))}
        </FormGroup>
    </FormControl>)

}