import { TypedEvent, AppDispatcher } from '../dispatcher/appDispatcher';
import { AssetClass} from './assetsModels'
import { get } from '../utils/httpUtils';
import { UpdateLoadingStatus } from '../loading/loadingActions';
import { LoadingStatus } from '../loading/loadingModels';

export class LoadedAssetsEvent extends TypedEvent<AssetClass[]> { }
export class LoadedAssetsFailedEvent extends TypedEvent<string> { }

export async function LoadAssets() {
    const assets = await get<AssetClass[]>("Asset");
    if (assets.payload == null) {
        AppDispatcher.dispatch(new LoadedAssetsFailedEvent("No assets returned from the service"));
        UpdateLoadingStatus("Assets", LoadingStatus.Failed);
    }
    else {
        AppDispatcher.dispatch(new LoadedAssetsEvent(assets.payload));
        UpdateLoadingStatus("Assets", LoadingStatus.Completed);
    }
}