/// <summary>
/// Implementation of Brent's method for root finding
/// </summary>

// C# reference code taken from https://en.wikipedia.org/w/index.php?title=Brent%27s_method&oldid=605428601

export function BrentsMethodSolve(func: (x: number) => number, lowerLimit: number, upperLimit: number, errorTol: number) {
    var a = lowerLimit;
    var b = upperLimit;
    var c = 0.0;
    var d = Number.MAX_VALUE;

    var fa = func(a);
    var fb = func(b);

    var fc = 0.0;
    var s = 0.0;
    var fs = 0.0;

    // if f(a) f(b) >= 0 then error-exit
    if (fa * fb >= 0) {
        if (fa < fb)
            return a;
        else
            return b;
    }

    // if |f(a)| < |f(b)| then swap (a,b) end if
    if (Math.abs(fa) < Math.abs(fb)) { 
        var tmp = a; 
        a = b; 
        b = tmp; 
        tmp = fa; 
        fa = fb; 
        fb = tmp; }

    c = a;
    fc = fa;
    var mflag = true;
    var i = 0;

    while (fb !== 0 && (Math.abs(a - b) > errorTol)) {
        if ((fa !== fc) && (fb !== fc))
            // Inverse quadratic interpolation
            s = a * fb * fc / (fa - fb) / (fa - fc) + b * fa * fc / (fb - fa) / (fb - fc) + c * fa * fb / (fc - fa) / (fc - fb);
        else
            // Secant Rule
            s = b - fb * (b - a) / (fb - fa);

        var tmp2 = (3 * a + b) / 4;
        if ((!(((s > tmp2) && (s < b)) || ((s < tmp2) && (s > b)))) || (mflag && (Math.abs(s - b) >= (Math.abs(b - c) / 2))) || (!mflag && (Math.abs(s - b) >= (Math.abs(c - d) / 2)))) {
            s = (a + b) / 2;
            mflag = true;
        }
        else {
            if ((mflag && (Math.abs(b - c) < errorTol)) || (!mflag && (Math.abs(c - d) < errorTol))) {
                s = (a + b) / 2;
                mflag = true;
            }
            else
                mflag = false;
        }
        fs = func(s);
        d = c;
        c = b;
        fc = fb;
        if (fa * fs < 0) { b = s; fb = fs; }
        else { a = s; fa = fs; }

        // if |f(a)| < |f(b)| then swap (a,b) end if
        if (Math.abs(fa) < Math.abs(fb)) { var tmp3 = a; a = b; b = tmp3; tmp3 = fa; fa = fb; fb = tmp3; }
        i++;
        if (i > 1000)
            throw new Error(`Error is ${fb}`);
    }
    return b;
}