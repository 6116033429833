import React from 'react';
import ProductDesignerPopUp from "./productDesigner/productDesignerPopup";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ThemeProvider,
  StyledEngineProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { ShowChartRounded, MenuOutlined, AddCircleOutlineOutlined, AllInboxOutlined, ArtTrackOutlined, AvTimerOutlined, ChevronRightOutlined, ClearAllOutlined, EditOutlined, EqualizerOutlined, EventNoteOutlined, EvStationOutlined, FlightTakeoffOutlined, InboxOutlined, LabelImportantOutlined, LocalOfferOutlined, LockOpenOutlined, LockOutlined, MailOutline, MultilineChartOutlined, PageviewOutlined, RssFeedOutlined, SaveOutlined, ScheduleOutlined, ShowChartOutlined, TabOutlined, TimelapseOutlined, TodayOutlined, ViewListOutlined, WarningOutlined, MenuOpenOutlined, TrendingUpOutlined } from "@mui/icons-material";
import { TabTemplate } from "../layout/layoutModels";
import { v4 } from 'uuid';
import { RfqInstallation } from '../globalConstants';
import { newTabItems, tabAllowed } from '../layout/newTabItems';
import layoutStoreInstance from '../layout/layoutStore';
import { AddTab } from '../layout/layoutActions';
import { getFormTheme } from '../inputs/formCommon';
import NestedMenuItem from '../utils/nestedMenuItem';
import templateStoreInstance from '../layout/templateStore';
import { LoadItemSpec, SaveLoadDeleteBox } from '../inputs/saveLoadDeleteBox';
import { MarketDataExplorer } from '../marketData/marketDashboard/marketDataExplorer';
import { NewMarketDataExplorerEdit } from '../marketData/marketDataActions';
import { MarketDataExplorerEditRequest } from '../marketData/marketDataModels';

export interface HomepageControlsProps {
  onAddItem: (itemName: string, props?: any) => void;
  onClickChangeComponentProps: (tabId: number, componentKey: string, props?: any) => void;
  onToggleLock: (isLocked: boolean) => void;
  onSaveTemplate: (name: string, category: string, isPublic: boolean) => void;
  onLoadTemplate: (template: TabTemplate) => void;
  tabTemplates: TabTemplate[],
}

interface HomePageControlsState {
  uniqueKey: string,
  locked: boolean,
  tabTemplates: TabTemplate[],
  addComponentMenuOpen: boolean;
  templateMenuOpen: boolean;
  anchorEl?: Element;
  showMdExporer: boolean;
}


class HomepageControls extends React.Component<HomepageControlsProps, HomePageControlsState> {
  constructor(props: HomepageControlsProps) {
    super(props);
    this.state = {
      uniqueKey: v4(),
      locked: false,
      tabTemplates: props.tabTemplates,
      addComponentMenuOpen: false,
      templateMenuOpen: false,
      showMdExporer: false
    }
    this.updateTabTemplates = this.updateTabTemplates.bind(this);
    this.menuItem = this.menuItem.bind(this);
  }

  private toggleLock = () => {
    this.props.onToggleLock(!this.state.locked);
    this.setState({ locked: !this.state.locked });
  }

  public updateTabTemplates = (templates: TabTemplate[]) => {
    this.setState({ tabTemplates: templates });
  }

  private renderTemplateMenuItems2 = () => {
    var templates = templateStoreInstance.GetTemplates();
    if (templates.length > 0) {
      return templates.map(t => { return { id: t.tabTemplateId, name: t.templateName } as LoadItemSpec });
    }
    else {
      return new Array<LoadItemSpec>();
    }
  }

  onAddItem(itemName: string) {
    this.props.onAddItem(itemName);
    this.setState({ addComponentMenuOpen: false });
  }

  menuItem = (name: string, displayName: string, icon: any) => {
    return <MenuItem key={name + 'zz List'} onClick={() => this.onAddItem(name)} >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{displayName}</ListItemText></MenuItem>
  }

  toggleMenuCollapse() {
    const menuCollapsed = layoutStoreInstance.GetMenuCollapse();
    layoutStoreInstance.SetMenuCollapse(!menuCollapsed);
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { addComponentMenuOpen, templateMenuOpen, anchorEl } = this.state;
    const currentTabIsFlexible = layoutStoreInstance.GetCurrentTab()?.isGridTab;
    const menuCollapsed = layoutStoreInstance.GetMenuCollapse();

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getFormTheme()}>
          <div className="HomepageControls">
            <Tooltip title={<Typography variant="subtitle1">{currentTabIsFlexible ? "Add Components" : "Add Tab"}</Typography>}>
              <IconButton size="medium" onClick={(e) => this.setState({ addComponentMenuOpen: !addComponentMenuOpen, anchorEl: e.currentTarget })} color="inherit">
                <AddCircleOutlineOutlined />
              </IconButton>
            </Tooltip>
            {/* {currentTabIsFlexible ?
            
              <Tooltip title={<Typography variant="subtitle1">Load / Save Templates</Typography>}>
                <IconButton
                  onClick={(e) => this.setState({ templateMenuOpen: !templateMenuOpen, anchorEl: e.currentTarget })}
                  color="inherit"
                  size="large">
                  <SaveOutlined />
                </IconButton>
              </Tooltip> : null} */}
            {currentTabIsFlexible ?
              <Tooltip title={<Typography variant="subtitle1">Load / Save Templates</Typography>}>
                <IconButton
                  onClick={(e) => this.setState({ templateMenuOpen: !templateMenuOpen, anchorEl: e.currentTarget })}
                  color="inherit"
                  size="large">
                  <SaveOutlined />
                </IconButton>
              </Tooltip> : null}
            <SaveLoadDeleteBox
              title='Load / Save Templates'
              showBox={templateMenuOpen}
              onClose={() => this.setState({ templateMenuOpen: false })}
              onDelete={(id) => { }}
              onLoad={(id) => { this.props.onLoadTemplate(templateStoreInstance.GetTemplateById(id)) }}
              onSave={this.props.onSaveTemplate}
              onUpdate={(id, name, category, isPublic) => { }}
              checkItemExists={(name) => false}
              options={this.renderTemplateMenuItems2()}
            />
            {RfqInstallation ? <Tooltip title={<Typography variant="subtitle1">Product Designer</Typography>}>
              <div>
                <ProductDesignerPopUp key={`${this.state.uniqueKey}_pdctDsgnr`} compactMode={true} />
              </div>
            </Tooltip> : null}
            {/* {RfqInstallation ? <Tooltip title={<Typography variant="subtitle1">Quick Request</Typography>}>
              <div>
                <QuickRequestBar key={`${this.state.uniqueKey}_pdctDsgnr`} compactMode={true} />
              </div>
            </Tooltip> : null} */}
            {currentTabIsFlexible ? <div><Tooltip title={<Typography variant="subtitle1">Market Data</Typography>}>
              <IconButton onClick={() => NewMarketDataExplorerEdit({} as MarketDataExplorerEditRequest)} color="inherit" size="large">
                <ShowChartRounded />
              </IconButton>
            </Tooltip><MarketDataExplorer onClose={() => this.setState({ showMdExporer: false })} isOpen={this.state.showMdExporer} launchComponent={this.props.onAddItem} editComponent={this.props.onClickChangeComponentProps} /></div> : null}
            {currentTabIsFlexible ? <Tooltip title={<Typography variant="subtitle1">{this.state.locked ? "Unlock Tab" : "Lock Tab"}</Typography>}>
              <IconButton onClick={this.toggleLock} color="inherit" size="large">
                {this.state.locked ? <LockOpenOutlined /> : <LockOutlined />}
              </IconButton>
            </Tooltip> : null}
            {!currentTabIsFlexible ? <Tooltip title={<Typography variant="subtitle1">{menuCollapsed ? "Show Menu" : "Hide Menu"}</Typography>}>
              <IconButton onClick={this.toggleMenuCollapse} color="inherit" size="large">
                {menuCollapsed ? <MenuOutlined /> : <MenuOpenOutlined />}
              </IconButton>
            </Tooltip> : null}
          </div>

          {currentTabIsFlexible ?
            <Menu anchorEl={anchorEl} open={addComponentMenuOpen} onClose={() => this.setState({ addComponentMenuOpen: false })}>
              <MenuItem disabled><ListItemText>Add Component...</ListItemText></MenuItem>
              <NestedMenuItem key="aT" rightIcon={<ChevronRightOutlined />} label={<React.Fragment><ListItemIcon><TabOutlined /></ListItemIcon><ListItemText>Tab</ListItemText></React.Fragment>} parentMenuOpen={addComponentMenuOpen}>
                {newTabItems.filter(t => tabAllowed(t)).map(t => <MenuItem key={"aT" + t.actionName} onClick={() => AddTab(t.actionName, false)}>
                  <ListItemIcon>{t.icon}</ListItemIcon>
                  <ListItemText>{t.label}</ListItemText></MenuItem>)}
              </NestedMenuItem>
              <NestedMenuItem key="n" rightIcon={<ChevronRightOutlined />} label={<React.Fragment><ListItemIcon><RssFeedOutlined /></ListItemIcon><ListItemText>News</ListItemText></React.Fragment>} parentMenuOpen={addComponentMenuOpen}>
                <MenuItem key="nT" onClick={() => this.onAddItem("NewsTicker")}>
                  <ListItemIcon><LabelImportantOutlined /></ListItemIcon>
                  <ListItemText>News Ticker</ListItemText></MenuItem>
                <MenuItem key="nL " onClick={() => this.onAddItem("NewsList")}>
                  <ListItemIcon><ClearAllOutlined /></ListItemIcon>
                  <ListItemText>News List</ListItemText></MenuItem>
              </NestedMenuItem>
              <NestedMenuItem key='nTime' rightIcon={<ChevronRightOutlined />} label={<React.Fragment><ListItemIcon><TimelapseOutlined /></ListItemIcon><ListItemText>Time</ListItemText></React.Fragment>} parentMenuOpen={addComponentMenuOpen}>
                <MenuItem key='nTimeA' onClick={() => this.onAddItem("AnalogueClock")} >
                  <ListItemIcon><AvTimerOutlined /></ListItemIcon>
                  <ListItemText>Analogue Clock</ListItemText></MenuItem>
                <MenuItem key='nTimeD' onClick={() => this.onAddItem("DigitalClock")} >
                  <ListItemIcon><ArtTrackOutlined /></ListItemIcon>
                  <ListItemText>Digital Clock</ListItemText></MenuItem>
              </NestedMenuItem>
              <NestedMenuItem key='nBox' rightIcon={<ChevronRightOutlined />} label={<React.Fragment><ListItemIcon><InboxOutlined /></ListItemIcon><ListItemText>Inbox</ListItemText></React.Fragment>} parentMenuOpen={addComponentMenuOpen}>
                <MenuItem key='nBoxS' onClick={() => this.onAddItem("Inbox")}>
                  <ListItemIcon><MailOutline /></ListItemIcon>
                  <ListItemText>Inbox (Single-Pane)</ListItemText></MenuItem>
                <MenuItem key='nBoxD' onClick={() => this.onAddItem("InboxDualPane")} >
                  <ListItemIcon><AllInboxOutlined /></ListItemIcon>
                  <ListItemText>Inbox (Dual-Pane)</ListItemText></MenuItem>
              </NestedMenuItem>
              <MenuItem key='nHolidays' onClick={() => this.onAddItem("HolidayPanel")} >
                <ListItemIcon><FlightTakeoffOutlined /></ListItemIcon>
                <ListItemText>Holidays</ListItemText></MenuItem>
              <MenuItem key='nLME Session' onClick={() => this.onAddItem("LmeSessionIndicator")} >
                <ListItemIcon><ScheduleOutlined /></ListItemIcon>
                <ListItemText>LME Session</ListItemText></MenuItem>
              <MenuItem key='nLME Dates' onClick={() => this.onAddItem("LmeDatesWidget")} >
                <ListItemIcon><TodayOutlined /></ListItemIcon>
                <ListItemText>LME Dates</ListItemText></MenuItem>
              <MenuItem key='nTrade Blotter' onClick={() => this.onAddItem("TradeBlotterWindow")} >
                <ListItemIcon><ViewListOutlined /></ListItemIcon>
                <ListItemText>Trade Blotter</ListItemText></MenuItem>
              {RfqInstallation ? <MenuItem key='nPricing Panel' onClick={() => this.onAddItem("PricingPanel")} >
                <ListItemIcon><LocalOfferOutlined /></ListItemIcon>
                <ListItemText>Pricing Panel</ListItemText></MenuItem> : null}
              <MenuItem key='nPrice Ticker' onClick={() => this.onAddItem("PriceTicker")} >
                <ListItemIcon><LabelImportantOutlined /></ListItemIcon>
                <ListItemText>Price Ticker</ListItemText></MenuItem>
              <MenuItem key='nActivity Monitor' onClick={() => this.onAddItem("UserActivity")} >
                <ListItemIcon><PageviewOutlined /></ListItemIcon>
                <ListItemText>Activity Monitor</ListItemText></MenuItem>
              <MenuItem key='nInstrument Editor' onClick={() => this.onAddItem("InstrumentEditor")} >
                <ListItemIcon><EditOutlined /></ListItemIcon>
                <ListItemText>Instrument Editor</ListItemText></MenuItem>
              <MenuItem key='nTrade Stats' onClick={() => this.onAddItem("TradeStats")} >
                <ListItemIcon><EqualizerOutlined /></ListItemIcon>
                <ListItemText>Trade Stats</ListItemText></MenuItem>
              <MenuItem key='nEvents List' onClick={() => this.onAddItem("EventsList")} >
                <ListItemIcon><EventNoteOutlined /></ListItemIcon>
                <ListItemText>Events List</ListItemText></MenuItem>
              <MenuItem key='nAlterts List' onClick={() => this.onAddItem("AlertsList")} >
                <ListItemIcon><WarningOutlined /></ListItemIcon>
                <ListItemText>Alerts List</ListItemText></MenuItem>
              <MenuItem key='nPnLz List' onClick={() => this.onAddItem("PnLWidget")} >
                <ListItemIcon><MultilineChartOutlined /></ListItemIcon>
                <ListItemText>Live PnL</ListItemText></MenuItem>
              {this.menuItem("NavWidget", "Live Nav", <TrendingUpOutlined />)}
              {this.menuItem("GasPriceEstimates", "Gas Prices", <EvStationOutlined />)}
              {this.menuItem("PriceChart", "Price Chart", <ShowChartOutlined />)}
            </Menu> :
            <Menu anchorEl={anchorEl} open={addComponentMenuOpen} onClose={() => this.setState({ addComponentMenuOpen: false })}>
              {newTabItems.filter(t => tabAllowed(t)).map(t => <MenuItem key={`nTab${t.actionName}`} onClick={() => { this.setState({ addComponentMenuOpen: false, anchorEl: null }); AddTab(t.actionName, false); }}>
                <ListItemIcon>{t.icon}</ListItemIcon>
                <ListItemText>{t.label}</ListItemText></MenuItem>)}
            </Menu>}

          {/* <Menu anchorEl={anchorEl} open={templateMenuOpen} onClose={() => this.setState({ templateMenuOpen: false })}>
            <MenuItem onClick={() => { this.props.onSaveTemplate(); this.setState({ templateMenuOpen: false }) }}>Save Template <ListItemIcon><SaveOutlined /></ListItemIcon></MenuItem>
            {this.renderTemplateMenuItems()}
          </Menu> */}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}




export default HomepageControls;