import FluxStore from '../FluxStore';
import { Event, AppDispatcher } from '../dispatcher/appDispatcher';
import { CalculatedAccountBalance, CashTransaction, Fund, NavRecord, NavStoreState, SubsReds } from './navModels';
import { FetchAccountBalances, GetNavsForFund, GetNavsForShareClass, LoadFundsEvent, LoadNavConfigsEvent, LoadNavsEvent, LoadSubsRedsEvent, NavsRequestedEvent, NewAccountBalanceEvent, NewCashTransactionEvent, NewCashTransactionsEvent, NewFeederMasterNavDiffEvent, NewFeederMasterNavSummaryEvent } from './navActions';
import moment, { Moment } from 'moment';
import { DefaultSetName } from '../globalConstants';

class NavStore extends FluxStore<NavStoreState>{
    constructor(dispatcher: typeof AppDispatcher) {
        super(dispatcher, (e) => this.onDispatchHandler(e), () => ({
            funds: new Array<Fund>(),
            cashTransactions: new Array<CashTransaction>(),
            shareClasses: {},
            navs: {},
            navSummaries: {},
            navDiffReports: {},
            errors: {},
            accountBalances: {},
            subsReds: new Array<SubsReds>(),
            configs: {},
            navsRequested: false
        }));
    }

    private onDispatchHandler(action: Event) {
        if (action instanceof LoadFundsEvent) {
            const { payload } = action;
            this.state.funds = payload;
            this.emitChange();
        }
        else if (action instanceof LoadNavsEvent) {
            const { payload } = action;
            const { navs } = this.state;
            payload.forEach(nav => {
                if (!navs[nav.fund])
                    navs[nav.fund] = {};
                if (!navs[nav.fund][nav.shareClass])
                    navs[nav.fund][nav.shareClass] = new Array<NavRecord>();
                var existing = navs[nav.fund][nav.shareClass].filter(n => n.navId === nav.navId)[0]
                if (!existing)
                    navs[nav.fund][nav.shareClass].push(nav);
                else {
                    var ix = navs[nav.fund][nav.shareClass].indexOf(existing);
                    navs[nav.fund][nav.shareClass][ix] = nav;
                }
            });
            this.emitChange();
        }
        else if (action instanceof LoadSubsRedsEvent) {
            const { payload } = action;
            this.state.subsReds = payload;
            this.emitChange();
        }
        else if (action instanceof NewFeederMasterNavSummaryEvent) {
            const { payload } = action;
            this.state.navSummaries = {};
            Object.keys(payload).map(k => {
                this.state.navSummaries[k] = {}
                payload[k].map(f => {
                    this.state.navSummaries[k][(new Date(f.navDate)).toDateString()] = f;
                    return null;
                })
                return null;
            });
            this.emitChange();
        }
        else if (action instanceof NewCashTransactionsEvent) {
            const { payload } = action;
            payload.forEach(ct => {
                ct.valueDate = moment(ct.valueDate);
                ct.bookedUtc = moment(ct.bookedUtc);
                ct.updatedUtc = moment(ct.updatedUtc);
            });
            this.state.cashTransactions = payload;
            this.emitChange();
        }
        else if (action instanceof NewCashTransactionEvent) {
            const { payload } = action;
            payload.valueDate = moment(payload.valueDate);
            payload.bookedUtc = moment(payload.bookedUtc);
            payload.updatedUtc = moment(payload.updatedUtc);

            var existing = this.state.cashTransactions.find(f => f.transId === payload.transId);
            if (existing) {
                var ix = this.state.cashTransactions.indexOf(existing);
                this.state.cashTransactions[ix] = payload;
            }
            else {
                this.state.cashTransactions.push(payload);
            }
            this.emitChange();
        }
        //NewCashTransactionEvent
        else if (action instanceof NewFeederMasterNavDiffEvent) {
            const { payload } = action;
            Object.keys(payload).map(k => {
                var key = `${k}-${payload[k].setName}-${moment(payload[k].navStart.navDate).format("yyyy-MM-DD")}-${moment(payload[k].navEnd.navDate).format("yyyy-MM-DD")}`;
                this.state.navDiffReports[key] = payload[k];
                return null;
            });
            this.emitChange();
        }
        else if (action instanceof NewAccountBalanceEvent) {
            const { payload } = action;

            payload.forEach(bal => {
                var forDate = this.state.accountBalances[moment(bal.asOfDate).format("yyyy-MM-DD")];
                if (!forDate) {
                    forDate = new Array<CalculatedAccountBalance>();

                }
                forDate = forDate.filter(b => b.accountName !== bal.accountName);
                forDate.push(bal);
                this.state.accountBalances[moment(bal.asOfDate).format("yyyy-MM-DD")] = forDate;
            })
            this.emitChange();
        }
        else if (action instanceof LoadNavConfigsEvent) {
            const { payload } = action;
            this.state.configs = payload;
            this.emitChange();
        }
        else if (action instanceof NavsRequestedEvent) {
            this.state.navsRequested = true;
            this.emitChange();
        }
    }

    getFunds() {
        return this.state.funds;
    }

    getCalculatedNav(date: Date, fund: string) {
        return this.state.navSummaries[fund]?.[date?.toDateString()];
    }

    getCalculatedNavs(fund: string) {
        if (!this.state.navSummaries[fund])
            return undefined;
        var dates = Object.keys(this.state.navSummaries[fund]);
        return dates.map(d => { return { date: d, nav: this.state.navSummaries[fund][d] } })
    }

    navsAreLoaded(): boolean {
        return Object.keys(this.state.navSummaries).length !== 0;
    }

    navsHaveBeenRequested(): boolean {
        return this.state.navsRequested;
    }

    hasNavData(date: Date, fund: string) {
        return this.state.navSummaries[fund]?.[date?.toDateString()] !== undefined;
    }

    getCashTransactions() {
        return this.state.cashTransactions;
    }

    getShareClasses(fund: string) {
        var classes = this.state.funds.filter(f => f.name === fund)[0]?.classes;
        return classes ? classes : new Array<string>();
    }

    async getNavs(fund: string, shareClass: string) {
        var navsForFund = this.state.navs[fund];
        if (navsForFund) {
            var navsForClass = navsForFund[shareClass];
            if (navsForClass)
                return navsForClass;
        }
        return await GetNavsForShareClass(fund, shareClass);
    }

    getNavDiff(startDate: Moment, endDate: Moment, fund: string, setName: string) {
        var key = `${fund}-${setName ?? DefaultSetName}-${startDate?.format("yyyy-MM-DD")}-${endDate?.format("yyyy-MM-DD")}`;
        return this.state.navDiffReports[key];
    }

    async getNavsForFund(fund: string) {
        var navsForFund = this.state.navs[fund];
        if (navsForFund) {
            var classes = Object.keys(navsForFund);
            var allNavs = classes.flatMap(c => navsForFund[c]);
            return allNavs;
        }
        return await GetNavsForFund(fund);
    }

    getNavsForFundSync(fund: string) {
        var navsForFund = this.state.navs[fund];
        if (navsForFund) {
            var classes = Object.keys(navsForFund);
            var allNavs = classes.flatMap(c => navsForFund[c]);
            return allNavs;
        };
    }

    getSubsReds() {
        return this.state.subsReds;
    }

    getAccountBalances(asOf: Date) {
        return this.state.accountBalances[moment(asOf).format("yyyy-MM-DD")];
    }

    async getOrFetchAccountBalances(asOf: Date) {
        var existing = this.getAccountBalances(asOf);
        return existing ?? await FetchAccountBalances(asOf, undefined);
    }

    getConfigs() {
        return this.state.configs;
    }

    getConfig(firmId: number) {
        return this.state.configs[firmId];
    }
}

const navStoreInstance = new NavStore(AppDispatcher);
export default navStoreInstance;