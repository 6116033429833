import { Autocall } from "../../../payoff/models/autoCall";
import { basketOk, dateOk } from "./validateCommon";

export function validateAutocall(a: Autocall): boolean {
    let initial = basketOk(a.callTriggerIndex);
    if (a.hasBarrierFeature) {
        initial = initial && basketOk(a.barrierTriggerIndex);
        initial = initial && a.barrierDetail!==undefined;
    }

    initial = initial && dateOk(a.startDate) && dateOk(a.endDate);

    initial = initial && a.callDates!==undefined && a.callDates.length>0;

    return initial;
}
