import React from 'react';
import FBEmitter from 'fbemitter';
import { ProductSpec } from '../../product/productModels';
import { LoadProductSummary } from '../../product/productActions';
import { Rfq } from '../rfq';
import rfqSummaryStoreInstance from '../rfqSummaryStore';
import { Price, RfqSummary, TradeAttempt, TradeSide } from '../rfqModels';
import rfqConnection from '../rfqConnection';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import newTradeEventStoreInstance from '../../trade/newTradeEventStore';
import { Button, ButtonGroup, Divider, Dialog, Typography, Grid } from '@mui/material';
import { CheckOutlined, RedoOutlined } from '@mui/icons-material';
import userStoreInstance from '../../user/userStore';

export type ExecutorProps = {
    onClose: () => void,
    uniqueKey: string,
    rfqId: number,
    productId: number,
    size: number
}

export const renderRemainingTime = ({ remainingTime }) => {
    return (<div className="RfqDashboardPriceProviderCountdownTextOuter">Remaining
        <div className="RfqDashboardPriceProviderCountdownText">{`${remainingTime}`}</div>
        Seconds
    </div>);
}

const PriceToClient = "PriceToClient";

interface ExecutorState {
    latestPrice: Price;
    summary: RfqSummary;
    rfq: Rfq;
    productSpec: ProductSpec;
    locked: boolean;
    height: number;
    showConfirmDialog: boolean;
    showAreYouSure: boolean;
    traded: boolean;
    side: TradeSide;
}

export class Executor extends React.Component<ExecutorProps, ExecutorState> {
    eventSubscriptionNewTrades: FBEmitter.EventSubscription | undefined;
    eventSubscriptionRfq: FBEmitter.EventSubscription | undefined;
    constructor(props: ExecutorProps) {
        super(props);
        this.state = {
            productSpec: {} as ProductSpec,
            summary: {} as RfqSummary,
            locked: true,
            height: 0,
            showConfirmDialog: false,
            showAreYouSure: false,
            rfq: {} as Rfq,
            latestPrice: {} as Price,
            traded: false,
            side: TradeSide.Buy
        }
        this.onNewTrade = this.onNewTrade.bind(this);
        this.onClickExecute = this.onClickExecute.bind(this);
        this.onRfqUpdate = this.onRfqUpdate.bind(this);
        this.onConfirmTrade = this.onConfirmTrade.bind(this);
        this.onAbandonTrade = this.onAbandonTrade.bind(this);
    }

    public componentWillUnmount() {
        if (this.eventSubscriptionNewTrades !== undefined) {
            this.eventSubscriptionNewTrades.remove();
        }
        this.eventSubscriptionNewTrades = undefined;

        if (this.eventSubscriptionRfq !== undefined) {
            this.eventSubscriptionRfq.remove();
        }
        this.eventSubscriptionRfq = undefined;
    }

    async componentDidMount() {
        this.eventSubscriptionNewTrades = newTradeEventStoreInstance.addChangeListener(this.onNewTrade);
        this.eventSubscriptionRfq = rfqSummaryStoreInstance.addChangeListener(this.onRfqUpdate);
        let summary = await LoadProductSummary(this.props.productId);
        if (summary) {
            this.setState({ productSpec: summary.spec });
        }
        this.onRfqUpdate();
    }

    public onNewTrade() {
        let didTrade = newTradeEventStoreInstance.getTradeEvent(this.props.rfqId);
        if (didTrade) {
            this.setState({ traded: true });
        }
    }

    public onRfqUpdate() {
        let latest = rfqSummaryStoreInstance.GetRfq(this.props.rfqId);
        if (latest !== undefined && latest !== null && latest.state === PriceToClient && latest.latestPrice !== null) {
            this.setState({ latestPrice: latest.latestPrice, summary: latest });
        }
        else {
            this.setState({ summary: latest });
        }
    }

    async onClickExecute(side: TradeSide) {
        this.setState({ side, showAreYouSure: true });
    }

    async onConfirmTrade() {
        this.setState({ showAreYouSure: false });
        const { latestPrice, side } = this.state;
        let attempt = {
            price: {
                bid: side === TradeSide.Sell ? null : latestPrice.ask,
                ask: side === TradeSide.Buy ? null : latestPrice.bid,
                rfqId: latestPrice.rfqId,
                stamp: new Date(),
            } as Price,
            tradeSide: side
        } as TradeAttempt;
        await rfqConnection.attemptTrade(this.props.rfqId, attempt);
    }
    onAbandonTrade = () => {
        this.setState({ showAreYouSure: false });
    }

    renderTwoWay() {
        const { latestPrice } = this.state;
        return (
            <div className="RfqDashboardExecutor">
                <ButtonGroup key={this.props.uniqueKey + "twoWay"} className="RfqDashboardExecutorButtonGroup">
                    <Button
                        className="RfqDashboardExecutorButton"
                        onClick={() => this.onClickExecute(TradeSide.Sell)}>
                        <div>
                            <div className="RfqDashboardExecutorButtonLabel">Sell</div>
                            <div className="RfqDashboardExecutorButtonText">{latestPrice.bid ? this.state.latestPrice.bid.toPrecision(3) : "..."}</div>
                        </div></Button>
                    <Divider orientation="vertical" flexItem={true} />
                    <Button
                        className="RfqDashboardExecutorButton"
                        onClick={() => this.onClickExecute(TradeSide.Buy)}>
                        <div>
                            <div className="RfqDashboardExecutorButtonLabel">Buy</div>
                            <div className="RfqDashboardExecutorButtonText">{latestPrice.ask ? this.state.latestPrice.ask.toPrecision(3) : "..."}</div>
                        </div></Button>
                </ButtonGroup>
            </div>)
    }
    renderOneWay() {
        const { latestPrice, traded, summary } = this.state;
        const sidePrice = latestPrice.bid !== undefined ? latestPrice.bid : latestPrice.ask;
        const sideLabel = latestPrice.bid !== undefined ? "Bid" : "Ask"
        const ttlExpire = new Date(latestPrice.stamp).valueOf() + latestPrice.ttlMs - (new Date().valueOf())
        const canExecute = summary.state === "PriceToClient";
        let col1 = userStoreInstance.GetTheme().timer_color_start;
        let col2 = userStoreInstance.GetTheme().timer_color_middle;
        let col3 = userStoreInstance.GetTheme().timer_color_end;
        return (<Grid container direction="column" spacing={2}>
            <Grid item>
                <ButtonGroup key={this.props.uniqueKey + "single"} className="RfqDashboardExecutorButtonGroup">
                    <Button
                        disabled={traded || !canExecute}
                        className="RfqDashboardExecutorButton"
                        onClick={() => this.onClickExecute(sideLabel === "Bid" ? TradeSide.Sell : TradeSide.Buy)}>
                        <div>
                            <div className="RfqDashboardExecutorButtonLabel">{sideLabel}</div>
                            <div className="RfqDashboardExecutorButtonText">{sidePrice?.toPrecision(3)}</div>
                        </div></Button>
                </ButtonGroup>
            </Grid>
            {traded ?
                <Grid item>
                    <Typography variant='subtitle2'>Traded</Typography>
                </Grid> :
                canExecute ? <Grid item container justifyContent="center">
                    <Grid item><CountdownCircleTimer
                        isPlaying
                        key={latestPrice.uniqueKey}
                        duration={latestPrice.ttlMs / 1000}
                        initialRemainingTime={ttlExpire / 1000}
                        colors={[
                            [col1, 0.33],
                            [col2, 0.33],
                            [col3, 0.33],
                        ]}>
                        {renderRemainingTime}
                    </CountdownCircleTimer></Grid> 
                </Grid> : null}
        </Grid>)
    }
    render() {
        const { latestPrice, showAreYouSure } = this.state;
        if (latestPrice === undefined || (latestPrice.bid == null && latestPrice.ask == null))
            return <div className="RfqDashboardExecutorAwaitingPrice"> (Awaiting Price) </div>
        return (
            <div className="RfqDashboardExecutorContainer">
                <div className="RfqDashboardExecutorDialogue">
                    <Dialog open={showAreYouSure} onClose={this.onAbandonTrade}>
                        <div className="RfqDashboardCancelConfirm">
                            <Typography className="RfqDashboardCancelConfirmText" variant="h4">Are you sure you want to Trade?</Typography>
                            <ButtonGroup fullWidth className="RfqDashboardCancelConfirmButtons">
                                <Button endIcon={<CheckOutlined />} className="MuiButton-outlined PltfmButtonLite" value="I'm Sure" onClick={this.onConfirmTrade}>Trade</Button>
                                <Button endIcon={<RedoOutlined />} className="MuiButton-outlined PltfmButtonLite" value="Go Back" onClick={this.onAbandonTrade}>Cancel</Button>
                            </ButtonGroup>
                        </div>
                    </Dialog>
                </div>
                {latestPrice.bid == null || latestPrice.ask == null ? this.renderOneWay() : this.renderTwoWay()}
            </div>
        );

    }
}

export default Executor;