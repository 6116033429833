import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { ThemeProvider } from '@mui/material/styles';
import { getFormTheme } from './formCommon';
import { IconButton } from '@mui/material';
import { ArrowBackOutlined, ArrowDownwardOutlined, ArrowForwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material';

export interface TransferListProps {
    choices: string[];
    chosen: string[];
    onChange: (chosen: string[]) => void;
    onClose: () => void;
}

function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly string[], b: readonly string[]) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props: TransferListProps) {
    const [checked, setChecked] = React.useState<string[]>([]);
    const [left, setLeft] = React.useState<string[]>(not(props.choices, props.chosen));
    const [right, setRight] = React.useState<string[]>(props.chosen);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly string[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: readonly string[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const moveSelectedUp = () => {
        for(var i=0;i<right.length-1;i++){
            if(checked.includes(right[i+1])){
                var val = right[i];
                right[i]=right[i+1];
                right[i+1]=val;
            }
        }
        setRight([...right]);
    };

    const moveSelectedDown = () => {
        for(var i=right.length;i>0;i--){
            if(checked.includes(right[i-1])){
                var val = right[i];
                right[i]=right[i-1];
                right[i-1]=val;
            }
        }
        setRight([...right]);
    };


    const customList = (title: React.ReactNode, items: readonly string[]) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 250,
                    height: 230,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: string) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <ThemeProvider theme={getFormTheme()}>
            <Grid container spacing={2} direction="column">
                <Grid container item spacing={2} direction="row" justifyContent="center" alignItems="center">
                    <Grid item>{customList('Choices', left)}</Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <IconButton
                                sx={{ my: 0.5 }}
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right">
                                <ArrowForwardOutlined />
                            </IconButton>
                            <IconButton
                                sx={{ my: 0.5 }}
                                size="small"
                                onClick={moveSelectedUp}
                                disabled={rightChecked.length !== 1 || right.indexOf(rightChecked[0]) === 0}
                                aria-label="move selected up list">
                                <ArrowUpwardOutlined />
                            </IconButton>
                            <IconButton
                                sx={{ my: 0.5 }}
                                size="small"
                                onClick={moveSelectedDown}
                                disabled={rightChecked.length !== 1 || right.indexOf(rightChecked[0]) === (right.length - 1)}
                                aria-label="move selected down list">
                                <ArrowDownwardOutlined />
                            </IconButton>
                            <IconButton
                                sx={{ my: 0.5 }}
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left">
                                <ArrowBackOutlined />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item>{customList('Chosen', right)}</Grid>
                </Grid>
                <Grid container item spacing={1} direction="row" justifyContent="center" alignItems="center">
                    <Grid item><Button size="small" className="MuiButton-outlined PltfmButtonLite" onClick={() => props.onClose()}>Close</Button></Grid>
                    <Grid item><Button size="small" className="MuiButton-outlined PltfmButtonLite" onClick={() => props.onChange([...right])}>Apply</Button></Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
